import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, DropdownItemProps } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums/RouteName';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import usePortal from '../../../../hooks/portal/UsePortal';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchPortalSuccess } from '../../../../store/project-portal';
import {
  updateActiveConnection,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectConnectionPortalSubStage } from '../../../../store/project-stage/initial-state';
import InitialPortalConnection from '../../../../store/project/initial-portal-connection';
import getRandomUserIcon from '../../../../util/user-icon-randomiser/UserIconRandomiser';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const CMS_FIELD = 'cmsIds';
const FORM_FIELD = 'formIds';
// const API_FIELD = 'apiIds';

const NEXT_STAGE = ProjectConnectionPortalSubStage.ACCESS;
const PREV_STAGE = ProjectConnectionPortalSubStage.NAME;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const StyledCheckbox = styled(Checkbox)`
  > label {
    font-weight: bold;
  }
`;

const ProjectItemPortalConnectionsStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject, activeConnection, addPortalConnection } =
    useProject();
  const { portal, portalAccessLevel, addPortal, editPortal } = usePortal();
  const { organisation } = useOrganisation();

  const [cmsOptions, setCmsOptions] = useState<DropdownItemProps[]>([]);
  const [formOptions, setFormOptions] = useState<DropdownItemProps[]>([]);
  // const [apiOptions, setApiOptions] = useState<DropdownItemProps[]>([]);
  const [canProceed, setCanProceed] = useState<boolean>(false);

  const handleChange = (connection: string, val: string | string[]) => {
    const cloned = cloneDeep(portal);
    cloned.connections[connection] = val;
    dispatch(
      fetchPortalSuccess({
        accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleQueryChange = (val: boolean) => {
    const cloned = cloneDeep(portal);
    cloned.allowAggregations = val;
    dispatch(
      fetchPortalSuccess({
        accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  // Map connections to options
  useEffect(() => {
    // CMS
    if (project?.connections[Enums.ConnectionType.CMS].length) {
      setCmsOptions(
        project?.connections[Enums.ConnectionType.CMS].map((c) => {
          return {
            key: c._id,
            value: c._id,
            text: c.name,
          };
        }),
      );
    }
    // Form
    if (project?.connections[Enums.ConnectionType.FORM].length) {
      setFormOptions(
        project.connections[Enums.ConnectionType.FORM].map((f) => {
          return {
            key: f._id,
            value: f._id,
            text: f.name,
          };
        }),
      );
    }
    // Api
    // if (project?.connections[Enums.ConnectionType.API].length) {
    //   setApiOptions(
    //     project.connections[Enums.ConnectionType.API].map((a) => {
    //       return {
    //         key: a._id,
    //         value: a._id,
    //         text: a.name,
    //       };
    //     }),
    //   );
    // }
  }, [project?.connections]);

  useEffect(() => {
    if (portal) {
      setCanProceed(Object.values(portal.connections).some((c) => c.length));
    }
  }, [portal]);

  useEffect(() => {
    if (project?.connections[Enums.ConnectionType.CMS]?.length && portal?._id) {
      const conn = project?.connections[Enums.ConnectionType.PORTAL].find(
        (c) =>
          (c.configuration as Interfaces.PortalConnectionConfiguration)
            .portalId === portal._id,
      );

      if (conn) {
        dispatch(updateActiveConnection(conn));
      }
    }
  }, [dispatch, portal?._id, project?.connections]);

  const processAction = async (preview = false) => {
    if (organisation && project && portal) {
      if (!portal._id) {
        dispatch(showLoading({ text: 'Creating Portal...' }));
        const clonedPortal = cloneDeep(portal);
        const clonedConnection = cloneDeep(InitialPortalConnection);

        clonedPortal.organisationId = organisation._id;

        const createdPortal = clonedPortal.avatar
          ? await addPortal(clonedPortal)
          : await addPortal({ ...clonedPortal, avatar: getRandomUserIcon() });

        if (createdPortal) {
          (
            clonedConnection.configuration as Interfaces.PortalConnectionConfiguration
          ).portalId = createdPortal._id;
          clonedConnection.name = createdPortal.name;

          await addPortalConnection(project, clonedConnection);
          dispatch(updateActiveConnection(clonedConnection));
          dispatch(
            fetchPortalSuccess({
              accessLevel: Enums.AccessLevel.MANAGE,
              entity: createdPortal,
            }),
          );

          if (preview) {
            dispatch(hideLoading());
            return window.open(
              `${process.env['REACT_APP_PORTAL_DOMAIN']}${RouteName.PORTAL}/${createdPortal?._id}`,
            );
          }
        }
      } else {
        dispatch(showLoading({ text: 'Updating Portal...' }));
        const clonePortal = cloneDeep(portal);
        const clonedConnection = cloneDeep(activeConnection);

        const updatedPortal = await editPortal(clonePortal);

        if (updatedPortal) {
          clonedConnection.name = updatedPortal.name;

          const clonedProject = cloneDeep(project);
          clonedProject.connections[Enums.ConnectionType.PORTAL] =
            clonedProject.connections[Enums.ConnectionType.PORTAL].map((c) => {
              if (
                c._id === (clonedConnection as Interfaces.ConnectionOutput)._id
              ) {
                return clonedConnection;
              }

              return c;
            });

          await editProject(clonedProject);
          dispatch(
            fetchPortalSuccess({
              accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
              entity: updatedPortal,
            }),
          );
        }
      }

      dispatch(hideLoading());

      if (preview) {
        dispatch(hideLoading());
        window.open(
          `${process.env['REACT_APP_PORTAL_DOMAIN']}${RouteName.PORTAL}/${portal?._id}`,
        );
      } else {
        dispatch(updateActiveProjectSubStage(NEXT_STAGE));
      }
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StageBodyText>
            Simply add the connections you'd like to be made available within
            this portal.
          </StageBodyText>

          <StyledBodySubHeader>Workspaces</StyledBodySubHeader>

          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select a Workspace'}
            selection
            multiple
            options={cmsOptions}
            value={portal?.connections[CMS_FIELD] || []}
            onChange={(e, data) => handleChange(CMS_FIELD, data.value)}
          />

          <StyledBodySubHeader>Forms</StyledBodySubHeader>

          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select a Form'}
            selection
            multiple
            options={formOptions}
            value={portal?.connections[FORM_FIELD] || []}
            onChange={(e, data) => handleChange(FORM_FIELD, data.value)}
          />

          {/*<StyledBodySubHeader>APIs</StyledBodySubHeader>*/}

          {/*<StyledDropdownUltraWide*/}
          {/*  placeholder={'Select an API'}*/}
          {/*  selection*/}
          {/*  multiple*/}
          {/*  options={apiOptions}*/}
          {/*  value={portal?.connections[API_FIELD] || []}*/}
          {/*  onChange={(e, data) => handleChange(API_FIELD, data.value)}*/}
          {/*/>*/}

          <StyledBodySubHeader>Custom Queries</StyledBodySubHeader>
          <StyledText>
            Portals can also allow users to build their own custom queries.
          </StyledText>
          <StyledText>
            If activated, all of the datasets contained within the included Data
            Views will be available to query.
          </StyledText>

          <StyledCheckbox
            disabled={!portal?.connections[CMS_FIELD].length}
            label={'Allow Custom Queries'}
            checked={portal?.allowAggregations}
            onChange={(e, data) => handleQueryChange(data.checked || false)}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Keep it moving`}
        primaryButton={
          <FeatureButton
            isDisabled={!canProceed}
            action={async () => processAction(false)}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to access'}
          />
        }
        secondaryButton={
          <FeatureButton
            isDisabled={!canProceed}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Preview Portal'}
            action={async () => {
              await processAction(true);
            }}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemPortalConnectionsStage;
