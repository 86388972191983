import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';

import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { HOUR_IDENTIFIER } from '../../../../const/DateConst';
import { RouteName } from '../../../../enums';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getLookupFieldType } from '../../../../util/lookup/GetLookupFieldType';

const QUERY_ROUTE = 'query';
const isQuery =
  location.pathname.includes(RouteName.PROJECT_ITEM) ||
  location.pathname.includes(QUERY_ROUTE);

const dateFormatTypeOptions = Object.entries(Enums.DateFormat).map(
  ([key, val], i) => {
    const type = startCase(key.toLowerCase());

    return {
      key: `date-format-${type}-${i}`,
      text: DateTime.now().toFormat(val),
      value: val,
      description: type,
    };
  },
);

interface DateReformatProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
}

const DateReformat: FC<DateReformatProps> = ({
  field,
  schema,
  setDataValidation,
  setSuperColumnValid,
  loaded,
}) => {
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [columnNameOptions, setColumnNameOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [selectedColumnName, setSelectedColumnName] = useState<string>();

  const [formatType, setFormatType] = useState<Enums.DateFormat>();

  // Set column name options
  useEffect(() => {
    if (!schema || !loaded) {
      return;
    }

    setColumnNameOptions(
      schema
        .filter((s) =>
          formatType?.toLowerCase().includes(HOUR_IDENTIFIER)
            ? s.dataValidation?.dataValidationType &&
              s.dataValidation?.dataValidationType ===
                Enums.ValueDataType.DATE &&
              s.dataValidation?.constraints?.format
                ?.toLowerCase()
                .includes(HOUR_IDENTIFIER)
            : s.dataValidation?.dataValidationType &&
              [Enums.ValueDataType.DATE].includes(
                getLookupFieldType(s, datasetMetas) ||
                  s.dataValidation?.dataValidationType,
              ),
        )
        .map((schemaField, i) => {
          return {
            key: `column-name-${schemaField.name}-${i}`,
            value: isQuery
              ? schemaField.name
              : schemaField.fieldId || schemaField.name,
            text: schemaField.name,
          };
        }),
    );
  }, [schema, formatType, datasetMetas, loaded]);

  // Set values if updating existing
  useEffect(() => {
    if (!field) {
      return;
    }
    const opts = field.dataValidation
      ?.dateConversion as Interfaces.DateExtractionOp;

    if (opts) {
      setSelectedColumnName(opts.params.field);
      setFormatType(opts.params.format);
    }
  }, [field]);

  const resetColumnNames = () => {
    setSelectedColumnName('');
  };

  // Set selected
  useEffect(() => {
    if (formatType && selectedColumnName) {
      setSuperColumnValid(true);
      setDataValidation({
        dataValidationType: Enums.ValueDataType.DATE_CONVERSION,
        dateConversion: {
          type: Enums.DateConversionOperation.REFORMAT,
          params: {
            format: formatType,
            field: selectedColumnName,
          },
        },
      });
    } else {
      setSuperColumnValid(false);
    }
  }, [formatType, selectedColumnName, setDataValidation, setSuperColumnValid]);

  return (
    <>
      <StyledBodySubHeader>Reformat</StyledBodySubHeader>

      <StyledText>Select a date format.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a date format'}
        options={dateFormatTypeOptions}
        selection
        value={formatType}
        search
        onChange={(e, data) => {
          resetColumnNames();
          setFormatType(data.value);
        }}
        style={{ marginTop: 0 }}
      />

      <StyledBodySubHeader>Column</StyledBodySubHeader>

      <StyledText>
        Select which date column you would like to reformat.
      </StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select columns to concatenate'}
        options={columnNameOptions}
        selection
        search
        value={selectedColumnName}
        onChange={(e, data) => setSelectedColumnName(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />
    </>
  );
};

export default DateReformat;
