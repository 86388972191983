import { ChartsItemOutput } from '.';

const InitialsCharts: ChartsItemOutput = {
  data: [],
  pagination: {
    currentPageNum: 1,
    prevPageNum: null,
    nextPageNum: null,
    totalCount: 0,
    totalPages: 1,
  },
};

export default InitialsCharts;
