import { Interfaces } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChartBar,
  faChevronDown,
  faChevronUp,
  faDatabase,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { PAGE_SIZE } from '../../../const/PaginationConst';
import { EntityType, RouteName } from '../../../enums';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import { BackNavigationItem, DefaultPopupStyles } from '../../../main/theme';
import { DEFAULT_PAGE_SIZE } from '../../../pages/CMS/CMSPage';
import { updateActiveConnection } from '../../../store/project-stage';
import { LOCAL_STORAGE_DASHBOARD_URL } from '../../Modal/graph/GraphModal';
import NavTitle from '../../Navigation/NavTitle';

import * as SC from './styled';

const TRUNCATE_LIMIT = 55;

type CMSConfigurationDataGroupItemWithName =
  Interfaces.CMSConfigurationDataGroupItem & {
    name: string;
  };

const getNavItemIcon = (item: CMSConfigurationDataGroupItemWithName) => {
  if (item.datasetMetaId) {
    return faDatabase;
  } else if (item.chartId) {
    return faChartBar;
  }
  return faSearch;
};

const CMSNavigation: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { cms } = useCMS();
  const { datasetMeta } = useDatasetMeta();
  const { project } = useProject();
  const { portal } = usePortal();

  const [dashboardRedirectUrl, setDashboardRedirectUrl] = useState<string>();

  const isViewingDataset = location.pathname.includes('view');

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({});

  const isPortal = location.pathname.includes(RouteName.PORTAL);

  const searchParams = new URLSearchParams(location.search);
  const pageDetailParams = `?page=1&pageSize=${
    searchParams.get(PAGE_SIZE) || DEFAULT_PAGE_SIZE
  }`;

  const config =
    cms?.configuration as Interfaces.CMSConnectionConfigurationOutput;

  // Handle returning to dashboard after tenp filter view
  useEffect(() => {
    const localStorageDashboardUrl = localStorage.getItem(
      LOCAL_STORAGE_DASHBOARD_URL,
    );

    if (!localStorageDashboardUrl) {
      return;
    }
    setDashboardRedirectUrl(JSON.parse(localStorageDashboardUrl));
    localStorage.removeItem(LOCAL_STORAGE_DASHBOARD_URL);
  }, [history.location]);

  useEffect(() => {
    if (portal?._id && config?.groups) {
      const groupNames = config?.groups.map((group) => group.groupName);

      const activeGroup = config?.groups.find(
        (group) => group._id === params?.groupId,
      );

      if (!activeGroup) {
        return;
      }

      const accordionState = groupNames.reduce((acc, groupName) => {
        acc[groupName] = groupName !== activeGroup?.groupName;
        return acc;
      }, {} as Record<string, boolean>);

      setAccordionGroups(accordionState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run on portal change
  }, [portal?._id, config?.groups]);

  return (
    <>
      {!!history.location.state?.filters && (
        <BackNavigationItem
          key={`nav-item-back`}
          onClick={() =>
            history.location?.state?.filterType === EntityType.NOTIFICATION
              ? history.push({
                  pathname: RouteName.NOTIFICATION_CENTRE,
                })
              : dashboardRedirectUrl
              ? history.push({
                  pathname: dashboardRedirectUrl,
                })
              : history.go(-1)
          }
          isActive={false}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={themeContext.colors.system.white}
          />

          <NavTitle
            text={`${
              history.location?.state?.filterType === EntityType.DATASET
                ? `Back To ${EntityType.DATASET}`
                : history.location?.state?.filterType === EntityType.CHART
                ? `Back To ${EntityType.CHART}`
                : history.location?.state?.filterType ===
                  EntityType.NOTIFICATION
                ? `To ${EntityType.NOTIFICATION} Centre`
                : `Back To ${EntityType.AGGREGATION}`
            }`}
          />
        </BackNavigationItem>
      )}

      {isViewingDataset && datasetMeta?.name && (
        <SC.NavigationNavItem
          lightTheme={isPortal}
          key={`nav-item-${`${RouteName.DATASET_ITEM}/${params?.datasetMetaId}/view`}`}
          onClick={async () => {
            dispatch(updateActiveConnection(cms));
            history.push(
              `${RouteName.DATASET_ITEM}/${params?.datasetMetaId}/view`,
            );
          }}
          isActive={true}
        >
          <Popup
            content={datasetMeta.name}
            disabled={datasetMeta.name.length <= TRUNCATE_LIMIT}
            position="right center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <NavTitle
                  text={truncate(datasetMeta.name, {
                    length: TRUNCATE_LIMIT,
                  })}
                  lightTheme={isPortal}
                  isActive={true}
                />

                <FontAwesomeIcon
                  icon={faDatabase}
                  color={themeContext.colors.system.white}
                />
              </div>
            }
          />
        </SC.NavigationNavItem>
      )}

      <SC.NavigationAccordion $lightTheme={isPortal}>
        {!isViewingDataset &&
          config?.groups.map((group, i) => (
            <div key={`group-${group.groupName}-${i}`}>
              <SC.NavigationAccordionTitle
                $isDisabled={history.location.state?.filters}
                $lightTheme={isPortal}
                active={!accordionGroups[group.groupName]}
                index={i}
                onClick={() =>
                  !history.location.state?.filters &&
                  setAccordionGroups({
                    ...accordionGroups,
                    [group.groupName]: !accordionGroups[group.groupName],
                  })
                }
              >
                <Popup
                  disabled={group.groupName.length <= TRUNCATE_LIMIT}
                  content={group.groupName}
                  position="right center"
                  style={DefaultPopupStyles}
                  hideOnScroll={true}
                  trigger={
                    <div style={{ width: '100%', display: 'flex' }}>
                      <NavTitle
                        text={truncate(group.groupName)}
                        lightTheme={isPortal}
                      />

                      <FontAwesomeIcon
                        icon={
                          !accordionGroups[group.groupName]
                            ? faChevronUp
                            : faChevronDown
                        }
                        color={
                          isPortal
                            ? themeContext.colors.system.offBlack
                            : themeContext.colors.system.white
                        }
                      />
                    </div>
                  }
                />
              </SC.NavigationAccordionTitle>

              <SC.NavigationAccordionContent
                active={!accordionGroups[group.groupName]}
              >
                {(group.items as CMSConfigurationDataGroupItemWithName[]).map(
                  (item) => {
                    const doc = {
                      ...item,
                      _id:
                        item.datasetMetaId ||
                        item.aggregationId ||
                        item.chartId,
                    };

                    const id = doc._id;
                    const type = item.datasetMetaId
                      ? EntityType.DATASET
                      : item.aggregationId
                      ? EntityType.AGGREGATION
                      : EntityType.CHART;

                    return (
                      <Popup
                        key={`nav-item-${id}`}
                        disabled={doc.name?.length <= TRUNCATE_LIMIT}
                        content={doc.name}
                        position="right center"
                        style={DefaultPopupStyles}
                        hideOnScroll={true}
                        trigger={
                          <div>
                            <SC.NavigationNavItem
                              lightTheme={isPortal}
                              disabled={history.location.state?.filters}
                              onClick={async () => {
                                dispatch(updateActiveConnection(cms));
                                history.push(
                                  isViewingDataset
                                    ? `${RouteName.DATASET_ITEM}/${id}/view${pageDetailParams}`
                                    : isPortal
                                    ? `${RouteName.PORTAL}/${portal?._id}/cms/${cms?._id}/${group._id}/${type}/${id}${pageDetailParams}`
                                    : `${RouteName.PROJECT_ITEM}/${
                                        project?._id || cms?._id
                                      }/cms/${cms?._id}/${
                                        group._id
                                      }/${type}/${id}${pageDetailParams}`,
                                );
                              }}
                              isActive={
                                (params?.groupId
                                  ? params?.groupId === group._id
                                  : true) &&
                                (params?.datasetMetaId === id ||
                                  params?.aggregationId === id ||
                                  params?.chartId === id)
                              }
                            >
                              <NavTitle
                                text={truncate(doc.name, {
                                  length: TRUNCATE_LIMIT,
                                })}
                                lightTheme={isPortal}
                                isActive={
                                  (params?.groupId
                                    ? params?.groupId === group._id
                                    : true) &&
                                  (params?.datasetMetaId === id ||
                                    params?.aggregationId === id ||
                                    params?.chartId === id)
                                }
                              />

                              <FontAwesomeIcon
                                icon={getNavItemIcon(item)}
                                color={
                                  (params?.groupId
                                    ? params?.groupId === group._id
                                    : true) &&
                                  (params?.datasetMetaId === id ||
                                    params?.aggregationId === id ||
                                    params?.chartId === id)
                                    ? isPortal
                                      ? themeContext.colors.system.white
                                      : themeContext.colors.system.white
                                    : isPortal
                                    ? themeContext.colors.system.offBlack
                                    : themeContext.colors.system.white
                                }
                              />
                            </SC.NavigationNavItem>
                          </div>
                        }
                      />
                    );
                  },
                )}
              </SC.NavigationAccordionContent>
            </div>
          ))}
      </SC.NavigationAccordion>
    </>
  );
};

export default CMSNavigation;
