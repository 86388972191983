export enum IntegrationStage {
  CREATION = 'creation',
  CONFIGURATION = 'configuration',
}

export enum IntegrationCreationSubStage {
  META = 'meta',
  AVATAR = 'avatar',
  ENDPOINTS = 'endpoints',
  ACCESS = 'access',
}

export enum IntegrationConfigurationSubStage {
  ADVANCED = 'advanced',
}

interface IntegrationSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type IntegrationSubStages =
  | IntegrationCreationSubStage
  | IntegrationConfigurationSubStage;

export interface IntegrationItemStages {
  activeStage: IntegrationStage;
  stages: {
    [IntegrationStage.CREATION]: {
      activeSubStage: IntegrationCreationSubStage;
      subStages: IntegrationSubStage[];
    };
    [IntegrationStage.CONFIGURATION]: {
      activeSubStage: IntegrationConfigurationSubStage;
      subStages: IntegrationSubStage[];
    };
  };
}

const InitialIntegrationStage: IntegrationItemStages = {
  activeStage: IntegrationStage.CREATION,
  stages: {
    [IntegrationStage.CREATION]: {
      activeSubStage: IntegrationCreationSubStage.META,
      subStages: [
        {
          subStage: IntegrationCreationSubStage.META,
          stage: 1,
          title: 'Meta',
        },
        {
          subStage: IntegrationCreationSubStage.AVATAR,
          stage: 2,
          title: 'Avatar',
        },
        {
          subStage: IntegrationCreationSubStage.ENDPOINTS,
          stage: 3,
          title: 'Endpoints',
        },
        {
          subStage: IntegrationCreationSubStage.ACCESS,
          stage: 4,
          title: 'Access',
        },
      ],
    },
    [IntegrationStage.CONFIGURATION]: {
      activeSubStage: IntegrationConfigurationSubStage.ADVANCED,
      subStages: [
        {
          subStage: IntegrationConfigurationSubStage.ADVANCED,
          stage: 1,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialIntegrationStage;
