import React, { FC } from 'react';
import styled from 'styled-components';
import usePortal from '../../hooks/portal/UsePortal';
import { FormConfigurationAdditional } from '../../pages/Portal/PortalFormsPage';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const PortalFormNavigation: FC = () => {
  const { portal } = usePortal();

  if (!portal?.connectionItems?.form) {
    return null;
  }

  return (
    <NavigationItems>
      {portal.connectionItems.form.map((form, i) => {
        const config = form.configuration as FormConfigurationAdditional;
        return (
          <NavItem
            lightTheme={true}
            isActive={false}
            key={`form-${form.name}-${i}`}
            onClick={() => {
              window.open(
                `${process.env['REACT_APP_FORM_DOMAIN']}/${form._id}/${config.formId}`,
              );
            }}
          >
            <NavTitle lightTheme={true} text={form.name} />
          </NavItem>
        );
      })}
    </NavigationItems>
  );
};

export default PortalFormNavigation;
