import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UndoLink } from '../../components/NotificationAlert/styled';
import NotificationAlertService, {
  NotificationAlertItemOutput,
  NotificationAlertItemsOutput,
} from '../../services/notification-alert/NotificationAlertService';
import { fetchNotificationAlert } from '../../store/notification-alert';
import { fetchNotificationAlerts } from '../../store/notification-alerts';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useNotificationAlertResult {
  notificationAlert?: Interfaces.NotificationAlertOutput;
  notificationAlertAccessLevel?: Enums.AccessLevel;
  getNotificationAlerts: (query?: Record<string, unknown>) => Promise<void>;
  getNotificationAlert: (notificationAlertId: string) => void;
  addNotificationAlert: (
    notificationAlertObj: Interfaces.NotificationAlert,
  ) => Promise<Interfaces.NotificationAlertOutput>;
  editNotificationAlert: (
    notificationAlertObj: Interfaces.NotificationAlertOutput,
  ) => Promise<Interfaces.NotificationAlertOutput>;
  removeNotificationAlert: (
    notificationAlertId: string,
  ) => Promise<Interfaces.NotificationAlertOutput>;
  recoverNotificationAlert: (
    notificationAlertId: string,
  ) => Promise<Interfaces.NotificationAlertOutput>;
}

const useNotification = (): useNotificationAlertResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const [notificationAlert, setNotificationAlert] =
    useState<Interfaces.NotificationAlertOutput>();
  const [notificationAlertAccessLevel, setNotificationAlertAccessLevel] =
    useState<Enums.AccessLevel>();

  const notificationAlertState = useSelector(
    (state: RootState) => state.notificationAlert,
  );
  const notificationAlertObj =
    notificationAlertState.data as NotificationAlertItemOutput;

  const notificationAlertsState = useSelector(
    (state: RootState) => state.notificationAlerts,
  );
  const notificationAlertsObj: NotificationAlertItemsOutput =
    notificationAlertsState.data;

  const [previousQuery, setPreviousQuery] = useState<Record<string, unknown>>();

  const getNotificationAlerts = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && query) {
        setPreviousQuery(query);
        await dispatch(fetchNotificationAlerts(token, query));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const refreshNotificationAlerts = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (token) {
      await dispatch(fetchNotificationAlerts(token, previousQuery));
    }
  }, [dispatch, getAccessTokenSilently, previousQuery]);

  const getNotificationAlert = useCallback(
    async (notificationAlertId: string) => {
      const token = await getAccessTokenSilently();

      if (token && notificationAlertId) {
        await dispatch(fetchNotificationAlert(token, notificationAlertId));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addNotificationAlert = useCallback(
    async (
      notificationObj: Interfaces.NotificationAlert,
    ): Promise<Interfaces.NotificationAlertOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && notificationObj && loggedInUser) {
        result = await NotificationAlertService.postNotificationAlert(
          token,
          notificationObj,
          loggedInUser._id,
        );
      }

      refreshNotificationAlerts();
      return result;
    },
    [getAccessTokenSilently, loggedInUser, refreshNotificationAlerts],
  );

  const editNotificationAlert = useCallback(
    async (
      notificationObj: Interfaces.NotificationAlertOutput,
    ): Promise<Interfaces.NotificationAlertOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && notificationObj && loggedInUser) {
        result = await NotificationAlertService.putNotificationAlert(
          token,
          loggedInUser._id,
          notificationObj,
        );
      }

      refreshNotificationAlerts();
      return result;
    },
    [getAccessTokenSilently, loggedInUser, refreshNotificationAlerts],
  );

  const recoverNotificationAlert = useCallback(
    async (
      notificationAlertId: string,
    ): Promise<Interfaces.NotificationAlertOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && notificationAlertId && loggedInUser) {
        result = await NotificationAlertService.putNotificationAlert(
          token,
          loggedInUser._id,
          undefined,
          notificationAlertId,
        );
      }

      refreshNotificationAlerts();
      return result;
    },
    [getAccessTokenSilently, loggedInUser, refreshNotificationAlerts],
  );

  const removeNotificationAlert = useCallback(
    async (notificationAlertId: string) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && notificationAlertId && loggedInUser) {
        result = await NotificationAlertService.deleteNotificationAlert(
          token,
          notificationAlertId,
          loggedInUser._id,
        );
      }

      refreshNotificationAlerts();

      toast.success(
        <>
          <span>Notification deleted</span>
          <UndoLink
            onClick={() => recoverNotificationAlert(notificationAlertId)}
          >
            Undo
          </UndoLink>
        </>,
      );

      return result;
    },
    [
      getAccessTokenSilently,
      loggedInUser,
      recoverNotificationAlert,
      refreshNotificationAlerts,
    ],
  );

  useEffect(() => {
    if (notificationAlertObj) {
      // Complete model
      setNotificationAlert(notificationAlertObj.entity);
      setNotificationAlertAccessLevel(notificationAlertObj.accessLevel);
    }
  }, [notificationAlertObj]);

  return {
    notificationAlert,
    notificationAlertAccessLevel,
    getNotificationAlerts,
    getNotificationAlert,
    addNotificationAlert,
    editNotificationAlert,
    removeNotificationAlert,
    recoverNotificationAlert,
  };
};

export default useNotification;
