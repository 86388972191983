import { truncate } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../../main/theme';

const WIDE_HEIGHT = 40;
const WIDE_SMALL_HEIGHT = 30;
const SHADOW_HEIGHT = 7;
const BORDER_BUFFER = 2;
const DEFAULT_COLOR = defaultTheme.colors.general.blue;
const DEFAULT_BORDER_COLOR = 'transparent';
const TRUNCATE_LIMIT = 45;

export enum FeatureButtonSize {
  EXTRA_SMALL = 40,
  SMALL = 60,
  MEDIUM = 80,
  LARGE = 100,
  WIDE = 200,
  WIDE_SMALL = 150,
}

export interface FeatureButtonProps {
  // Action
  action?: () => void;

  // Active styling
  isActive?: boolean;

  // Display only
  isDisplayOnly?: boolean;

  // Disabled styling
  isDisabled?: boolean;

  // Appearance
  size?: FeatureButtonSize | number | 'auto';
  height?: number;
  color?: string; // Hex code
  borderColor?: string; // Hex code

  // Text
  text?: string;
  textStyle?: React.CSSProperties;

  // Image
  image?: string;

  // Icon
  icon?: React.ReactElement;

  // Notification marker
  hasNotification?: boolean;
  notificationColor?: string; // Hex code

  // Face Override
  faceStyle?: React.CSSProperties;

  // Button Override
  style?: React.CSSProperties;

  // Container Override
  containerStyle?: React.CSSProperties;
}

interface WrapperProps {
  size: FeatureButtonSize | number | 'auto';
  height?: number;
  color: string; // Hex code
  borderColor: string; // Hex code
  isActive?: boolean;
  isDisabled?: boolean;
  isDisplayOnly?: boolean;
}

interface FaceProps {
  size: FeatureButtonSize | number | 'auto';
  height?: number;
}

interface NotificationProps {
  isActive: boolean;
  color?: string; // Hex code
}

const getHeight = (size: FeatureButtonSize | number) => {
  switch (size) {
    case FeatureButtonSize.WIDE:
      return WIDE_HEIGHT;
    case FeatureButtonSize.WIDE_SMALL:
      return WIDE_SMALL_HEIGHT;
    default:
      return size;
  }
};

const Notification = styled.div<NotificationProps>`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 99;

  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid white;

  background-color: ${({ color }) => color || DEFAULT_COLOR};
`;

const Face = styled.div<FaceProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => props.size}px;
  height: ${(props) =>
    props.height ||
    getHeight(typeof props.size === 'string' ? 37 : props.size)}px;

  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.standard};

  transition: opacity 0.3s, background 0.3s;
`;

const FaceText = styled.p`
  color: ${({ theme }) => theme.colors.system.white};

  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  ${({ theme }) => theme.typography.header};
  text-transform: uppercase;
`;

const DivWrapper = styled.div<WrapperProps>`
  display: flex;

  box-sizing: border-box;
  position: relative;

  width: ${({ size }) => size}px;
  height: ${({ isActive, size, height }) => {
    const calcHeight =
      height || getHeight(typeof size === 'string' ? 37 : size);
    // BORDER_BUFFER is used here to allow for the increased size due to the active border being added
    return isActive ? calcHeight + BORDER_BUFFER : calcHeight + SHADOW_HEIGHT;
  }}px;

  padding: 0;
  margin: ${({ isActive }) =>
    // BORDER_BUFFER is used here to allow for the increased size due to the active border being added
    isActive ? SHADOW_HEIGHT - BORDER_BUFFER + 'px 0 0' : 0};
  border: 1px solid
    ${({ isActive, borderColor }) => (isActive ? borderColor : 'transparent')};
  border-radius: ${({ theme }) => theme.borders.radius};

  background: linear-gradient(
      ${({ color }) => color}CC,
      ${({ color }) => color}CC
    ),
    #000;

  outline: none;

  transition: border 0.3s, opacity 0.3s;
`;

const ButtonWrapper = styled.button<WrapperProps>`
  display: flex;

  box-sizing: border-box;
  position: relative;

  width: ${({ size }) => size}px;
  height: ${({ isActive, size, height }) => {
    const calcHeight =
      height || getHeight(typeof size === 'string' ? 37 : size);
    // BORDER_BUFFER is used here to allow for the increased size due to the active border being added
    return isActive ? calcHeight + BORDER_BUFFER : calcHeight + SHADOW_HEIGHT;
  }}px;

  padding: 0;
  margin: ${({ isActive }) =>
    // BORDER_BUFFER is used here to allow for the increased size due to the active border being added
    isActive ? SHADOW_HEIGHT - BORDER_BUFFER + 'px 0 0' : 0};
  border: 1px solid
    ${({ isActive, borderColor }) => (isActive ? borderColor : 'transparent')};
  border-radius: ${({ theme }) => theme.borders.radius};

  background: linear-gradient(
      ${({ color }) => color}CC,
      ${({ color }) => color}CC
    ),
    #000;

  outline: none;

  transition: border 0.3s, opacity 0.3s;

  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};

  ${({ isDisabled, isDisplayOnly, size, height }) =>
    !isDisabled && !isDisplayOnly
      ? `
      &:hover {
        cursor: pointer;
      }

      &:hover ${Face} {
        opacity: 0.7;
      }
  
      &:active {
        height: ${
          (height || getHeight(typeof size === 'string' ? 37 : size)) +
          BORDER_BUFFER
        }px;
        margin-top: ${SHADOW_HEIGHT - BORDER_BUFFER}px;
      }`
      : ''};
`;

const FeatureButton: FC<FeatureButtonProps> = ({
  action,
  isActive = false,
  isDisplayOnly = false,
  isDisabled = false,
  size,
  height,
  color,
  borderColor,
  text,
  textStyle,
  image,
  icon,
  hasNotification = false,
  notificationColor,
  faceStyle,
  style,
  containerStyle,
}) => {
  const buttonColor = color || DEFAULT_COLOR;
  const activeBorderColor = borderColor || DEFAULT_BORDER_COLOR;

  const children = (
    <>
      <Face
        className={'feature-btn-face'}
        size={size || FeatureButtonSize.SMALL}
        height={height}
        style={{
          background: `${color} url(${image}) no-repeat center / ${
            image?.includes('http') ? 'contain' : '75%'
          }`,
          ...faceStyle,
        }}
      >
        {icon}
        {text && (
          <FaceText style={textStyle}>
            {truncate(text, { length: TRUNCATE_LIMIT })}
          </FaceText>
        )}
      </Face>
      {hasNotification && (
        <Notification isActive={isActive} color={notificationColor} />
      )}
    </>
  );

  return (
    <div style={containerStyle}>
      {(!action || isDisplayOnly) && (
        <DivWrapper
          style={style}
          className={'feature-btn'}
          isActive={isActive}
          color={buttonColor}
          borderColor={activeBorderColor}
          size={size || FeatureButtonSize.SMALL}
          height={height}
        >
          {children}
        </DivWrapper>
      )}

      {action && (
        <ButtonWrapper
          style={style}
          className={'feature-btn'}
          isActive={isActive}
          color={buttonColor}
          borderColor={activeBorderColor}
          size={size || FeatureButtonSize.SMALL}
          height={height}
          onClick={action}
          isDisabled={isDisabled}
          disabled={isDisabled}
          isDisplayOnly={isDisplayOnly}
        >
          {children}
        </ButtonWrapper>
      )}
    </div>
  );
};

export default FeatureButton;
