import { Interfaces } from '@configur-tech/upit-core-types';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { updateActiveIntegrationSubStage } from '../../../../store/integration-stage';
import { IntegrationCreationSubStage } from '../../../../store/integration-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from '../styled';
import EndpointTable from './EndpointTable';

const PREV_STAGE = IntegrationCreationSubStage.AVATAR;
const NEXT_STAGE = IntegrationCreationSubStage.ACCESS;

const ConfigurationIntegrationStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { integrationTemplate, editIntegrationTemplate } =
    useIntegrationTemplate();

  const [processComplete, setProcessComplete] = useState<boolean>(false);
  const [endpointsState, setEndpointsState] =
    useState<Interfaces.IntegrationTemplateOutput>();

  useEffect(() => {
    if (integrationTemplate) {
      setEndpointsState(integrationTemplate);
    }
  }, [integrationTemplate]);

  useEffect(() => {
    if (processComplete && endpointsState?.endpoints[0].endpoints) {
      // Creation/Update has completed successfully
      // Move on to next stage©
      dispatch(updateActiveIntegrationSubStage(NEXT_STAGE));
    }
  }, [processComplete, dispatch, history, endpointsState]);

  const processAction = async () => {
    if (endpointsState?.endpoints && integrationTemplate) {
      const cloned = cloneDeep(integrationTemplate);
      // Update integration
      dispatch(showLoading({ text: 'Updating Integration...' }));

      await editIntegrationTemplate(cloned);
      dispatch(hideLoading());
      setProcessComplete(true);
    }
  };

  const configurationComplete = (): boolean => {
    return !integrationTemplate?.endpoints[0]?.endpoints.length;
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Endpoints dictate how your integration can be interacted with.
          </StageBodyText>
          <StageBodyText>
            First you'll create a base endpoint, followed by any child
            endpoints.
          </StageBodyText>
          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Add Base Endpoint'}
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.INTEGRATION_ENDPOINT_MAIN_MODAL,
                  additionalProps: { integrationTemplate },
                  fullScreen: true,
                }),
              )
            }
          />
        </SC.Section>
        {(integrationTemplate?.endpoints?.length || 0) > 0 &&
          endpointsState?.endpoints?.map((group, endpointIndex) => (
            <SC.GroupAccordion
              key={`${group.name}-${endpointIndex}`}
              style={{ marginTop: themeContext.margin.xxlarge }}
            >
              <SC.AccordionTitle
                index={0}
                style={{
                  backgroundColor: themeContext.colors.system.offWhite,
                }}
              >
                <SC.AccordionTitleWrapper>
                  <StyledSubHeader>
                    {group.name} ({group.baseUrl})
                  </StyledSubHeader>

                  <SC.AccordionTitleButtons>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          showModal({
                            visible: true,
                            modal: ModalTypes.INTEGRATION_ENDPOINT_MAIN_MODAL,
                            additionalProps: {
                              integrationTemplate,
                              endpointIndex,
                            },
                            fullScreen: true,
                          }),
                        )
                      }
                      size={FeatureButtonSize.EXTRA_SMALL}
                      color={themeContext.colors.general.blue}
                      icon={
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          color={themeContext.colors.system.white}
                        />
                      }
                    />
                  </SC.AccordionTitleButtons>
                </SC.AccordionTitleWrapper>
              </SC.AccordionTitle>
              <SC.AccordionContentWrapper active={true}>
                <StyledText
                  style={{
                    marginBottom: themeContext.margin.xxlarge,
                    textAlign: 'center',
                  }}
                >
                  Add the actual endpoints you want to be made available for
                  this base URL.
                </StyledText>

                <FeatureButton
                  size={FeatureButtonSize.WIDE}
                  color={themeContext.colors.general.blue}
                  text={'Add Request Endpoint'}
                  action={() =>
                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.INTEGRATION_ENDPOINT_REQUEST_MODAL,
                        additionalProps: { integrationTemplate, endpointIndex },
                        fullScreen: true,
                      }),
                    )
                  }
                  style={{
                    marginBottom: themeContext.margin.standard,
                  }}
                />
                {group.endpoints.length > 0 && (
                  <div>
                    <EndpointTable
                      endpoint={group}
                      endpointIndex={endpointIndex}
                    />
                  </div>
                )}
              </SC.AccordionContentWrapper>
            </SC.GroupAccordion>
          ))}
      </StageInner>
      <ActionBar
        text={`Keep it going!`}
        primaryButton={
          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Access'}
            isDisabled={configurationComplete()}
            action={processAction}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveIntegrationSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ConfigurationIntegrationStage;
