import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Label, SemanticICONS } from 'semantic-ui-react';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import DateTimePickerDynamicValues from '../../components/DateTimePicker/DateTimePickerDynamicValues';
import { HELPER_EXTRACT_TYPE } from '../../components/Modal/enhancement/date/DateExtract';
import * as SC from '../../components/Modal/filter/styled';
import { DropdownLabel } from '../../components/styled';
import {
  dateExtractMonthFull,
  dateExtractMonthShort,
} from '../../const/DateConst';
import { ListOptionColor } from '../../enums';
import { StyledDropdown, StyledInput } from '../../main/theme';
import {
  ListItemOutput,
  UserListOutput,
} from '../../services/list/ListService';
import { RootState } from '../../store/rootReducer';
import getDataTypeIcon from '../../util/data-type-icon/DataTypeIcon';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import useList from '../list/UseList';

interface UseFilterInputResult {
  buildFilterInput: (
    field: Interfaces.Field,
    type: Enums.ValueDataType,
    value: unknown,
    onChange: (value: unknown, betweenValue?: number) => void,
    error?: boolean,
    disabled?: boolean,
    isBetweenFilter?: boolean,
    lockValue?: unknown,
    isMeasure?: boolean,
  ) => React.ReactElement;
}

export const LIKE_QUERY_CHARACTER = '%';

const useFilterInput = (): UseFilterInputResult => {
  const { getLists } = useList();

  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  const [fetchingList, setFetchingList] = useState<boolean>(false);
  const [fetchedList, setFetchedList] = useState<boolean>(false);

  /**
   * Build a user input
   */
  const buildFilterInput = useCallback(
    (
      field: Interfaces.Field,
      type: Enums.ValueDataType,
      value: unknown,
      onChange: (value: unknown, betweenValue?: number) => void,
      error?: boolean,
      disabled?: boolean,
      isBetweenFilter?: boolean,
      lockValue?: unknown,
      isMeasure?: boolean,
    ): React.ReactElement => {
      const constraints = field?.dataValidation?.constraints;
      let listVals: Interfaces.ListValue[] | Interfaces.User[] = [];
      let displayColorIndicator;
      let isUserList = false;
      let renderLabel;

      if (Array.isArray(constraints?.listValues)) {
        listVals = constraints?.listValues || [];
      } else {
        const listDoc = (lists?.find(
          (l) =>
            l.entity._id === constraints?.listValues ||
            (field?.fieldId &&
              l.entity.datasetListOptions?.fieldId === field?.fieldId),
        )?.entity as UserListOutput) || { values: [] };

        if (listDoc?.values?.length) {
          // Update lists
          if (!fetchingList) {
            (async () => {
              setFetchingList(true);
              await getLists({
                _id: { $in: lists?.map((l) => l.entity._id) },
              });
              setFetchedList(true);
            })();
          }

          const listType = listDoc?.listType;
          isUserList = listType === Enums.ListType.USER;
          displayColorIndicator = listDoc?.values?.some((lv) => lv.color);
          listVals = isUserList
            ? (listDoc.users as Interfaces.User[])
            : listDoc.values || [];

          // If value locked due to prefilter filter available values
          if (lockValue) {
            if (isUserList) {
              listVals = listDoc.users.filter(
                (user) => user._id === lockValue,
              ) as Interfaces.User[];
            } else {
              listVals =
                listDoc.values?.filter((option) =>
                  (lockValue as string[]).includes(option.value),
                ) || [];
            }
          }
        }

        renderLabel = (label) => {
          const indicatorColor = label?.label?.props?.indicatorcolor;

          const style = indicatorColor
            ? {
                color: '#fff',
                backgroundColor:
                  ListOptionColor[indicatorColor] || indicatorColor,
              }
            : {};

          const avatar = label?.image?.src;

          const LabelWithAvatar = () => (
            <Label style={{ padding: 0, background: 'none' }}>
              <img src={avatar} alt={label.text} /> {label.text}
            </Label>
          );
          return {
            content: avatar ? <LabelWithAvatar /> : label.text,
            style: style,
          };
        };
      }

      if (!isMeasure && listVals.length) {
        return (
          <StyledDropdown
            selectOnBlur={false}
            loading={fetchingList && !fetchedList}
            disabled={disabled}
            placeholder={'Select an option'}
            renderLabel={renderLabel}
            options={listVals?.map((lv, i) => {
              const image = isUserList
                ? {
                    avatar: true,
                    src: UserIconMap[lv.avatar] || lv.avatar,
                  }
                : undefined;

              return {
                key: `list-value-${field?.name}-${
                  isUserList ? lv._id : lv.value
                }-${i}`,
                value: isUserList ? lv._id : lv.value,
                text: isUserList ? `${lv.firstName} ${lv.lastName}` : lv.value,
                label: displayColorIndicator && (
                  <DropdownLabel
                    indicatorcolor={lv.color}
                    circular={true}
                    size={'mini'}
                  />
                ),
                image,
              };
            })}
            clearable
            multiple
            selection
            search
            value={value || []}
            onChange={(e, data) => onChange(data.value)}
            style={{ margin: 0 }}
            upward={true}
          />
        );
      }

      switch (type) {
        case Enums.ValueDataType.NUMBER:
        case Enums.ValueDataType.FORMULA:
          if (!isBetweenFilter) {
            return (
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  (value = (value as string)?.toString()?.length ? value : '')
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
            );
          }

          return (
            <SC.BetweenWrapper>
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  value && typeof (value as number[])[0] !== null
                    ? (value as number[])[0]
                    : ''
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value, 0)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  value && typeof (value as number[])?.[1] !== null
                    ? (value as number[])[1]
                    : ''
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value, 1)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
            </SC.BetweenWrapper>
          );

        case Enums.ValueDataType.DATE: {
          if (!isBetweenFilter) {
            return (
              <DateTimePickerDynamicValues
                value={(value as string) || ''}
                onChange={(date) => onChange(date)}
                dateFormat={field.dataValidation?.constraints?.format}
                disabled={disabled}
              />
            );
          }

          const dateVal = value || ['', ''];
          return (
            <SC.BetweenWrapper>
              <DateTimePicker
                value={(dateVal as string[])[0] || ''}
                onChange={(date) => onChange(date, 0)}
                dateFormat={field.dataValidation?.constraints?.format}
              />
              <DateTimePicker
                value={(dateVal as string[])[1] || ''}
                onChange={(date) => onChange(date, 1)}
                dateFormat={field.dataValidation?.constraints?.format}
              />
            </SC.BetweenWrapper>
          );
        }

        case Enums.ValueDataType.DATE_CONVERSION: {
          const conversionField = field.dataValidation?.dateConversion;

          if (
            conversionField?.type === Enums.DateConversionOperation.REFORMAT
          ) {
            if (!isBetweenFilter) {
              return (
                <DateTimePicker
                  value={(value as string) || ''}
                  onChange={(date) => onChange(date)}
                  dateFormat={
                    (
                      field.dataValidation
                        ?.dateConversion as Interfaces.DateExtractionOp
                    )?.params.format
                  }
                />
              );
            }

            const dateVal = value || ['', ''];
            return (
              <SC.BetweenWrapper>
                <DateTimePicker
                  value={(dateVal as string[])[0] || ''}
                  onChange={(date) => onChange(date, 0)}
                  dateFormat={
                    (
                      field.dataValidation
                        ?.dateConversion as Interfaces.DateExtractionOp
                    )?.params.format
                  }
                />
                <DateTimePicker
                  value={(dateVal as string[])[1] || ''}
                  onChange={(date) => onChange(date, 1)}
                  dateFormat={
                    (
                      field.dataValidation
                        ?.dateConversion as Interfaces.DateExtractionOp
                    )?.params.format
                  }
                />
              </SC.BetweenWrapper>
            );
          }

          let inputType;

          if (
            conversionField?.type &&
            HELPER_EXTRACT_TYPE.includes(conversionField?.type)
          ) {
            switch (
              (conversionField as Interfaces.DateExtractionOp)?.params.format
            ) {
              case Enums.DateFormat.DAY_PADDED:
              case Enums.DateFormat.DAY_UNPADDED:
              case Enums.DateFormat.WEEK_NUM_UNPADDED:
              case Enums.DateFormat.WEEK_NUM_PADDED:
              case Enums.DateFormat.MONTH_NUM_UNPADDED:
              case Enums.DateFormat.MONTH_NUM_PADDED:
              case Enums.DateFormat.YEAR_SHORT:
              case Enums.DateFormat.YEAR_FULL:
              case Enums.DateFormat.HOUR_12_UNPADDED:
              case Enums.DateFormat.HOUR_12_PADDED:
              case Enums.DateFormat.HOUR_24_UNPADDED:
              case Enums.DateFormat.HOUR_24_PADDED:
              case Enums.DateFormat.MINUTE_UNPADDED:
              case Enums.DateFormat.MINUTE_PADDED:
              case Enums.DateFormat.SECOND_UNPADDED:
              case Enums.DateFormat.SECOND_PADDED:
                inputType = (
                  <StyledInput
                    disabled={disabled}
                    error={error}
                    value={value || ''}
                    type={'number'}
                    onChange={(e, data) =>
                      onChange(data.value === '' ? null : +data.value)
                    }
                    icon={
                      <Icon
                        name={getDataTypeIcon(type, true) as SemanticICONS}
                      />
                    }
                    iconPosition={'left'}
                    style={{ height: 38 }}
                  />
                );
                break;
              case Enums.DateFormat.MONTH_SHORT_NAME:
                inputType = (
                  <StyledDropdown
                    selectOnBlur={false}
                    disabled={disabled}
                    placeholder={'Select an option'}
                    options={dateExtractMonthShort(field.name)}
                    value={value || []}
                    onChange={(e, data) => onChange(data.value)}
                    upward={true}
                    style={{ margin: 0 }}
                    clearable
                    multiple
                    selection
                    search
                  />
                );
                break;
              case Enums.DateFormat.MONTH_FULL_NAME:
                inputType = (
                  <StyledDropdown
                    selectOnBlur={false}
                    disabled={disabled}
                    placeholder={'Select an option'}
                    options={dateExtractMonthFull(field.name)}
                    value={value || []}
                    onChange={(e, data) => onChange(data.value)}
                    upward={true}
                    style={{ margin: 0 }}
                    clearable
                    multiple
                    selection
                    search
                  />
                );
                break;
            }
          }

          if (
            conversionField?.type === Enums.DateConversionOperation.DATE_DIFF
          ) {
            inputType = (
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  (value = (value as string)?.toString()?.length ? value : '')
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
            );
          }

          if (!isBetweenFilter) {
            return inputType;
          }

          return (
            <SC.BetweenWrapper>
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  value && typeof (value as number[])[0] !== null
                    ? (value as number[])[0]
                    : ''
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value, 0)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
              <StyledInput
                disabled={disabled}
                error={error}
                value={
                  value && typeof (value as number[])?.[1] !== null
                    ? (value as number[])[1]
                    : ''
                }
                type={'number'}
                onChange={(e, data) =>
                  onChange(data.value === '' ? null : +data.value, 1)
                }
                icon={
                  <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
                }
                iconPosition={'left'}
                style={{ height: 38 }}
              />
            </SC.BetweenWrapper>
          );
        }

        case Enums.ValueDataType.BOOL:
          return (
            <StyledDropdown
              selectOnBlur={false}
              disabled={disabled}
              placeholder={'Select an option'}
              options={[
                {
                  key: `boolean-${field.name}-true`,
                  value: 'true',
                  text: 'True',
                },
                {
                  key: `boolean-${field.name}-false`,
                  value: 'false',
                  text: 'False',
                },
              ]}
              selection
              value={(value as string)?.toString()}
              onChange={(e, data) => onChange(data.value === 'true')}
              upward={true}
            />
          );

        default: {
          let filterValue = value as string;

          // If this is a like query, hide the % character from the input value
          if (
            filterValue &&
            filterValue[0] === LIKE_QUERY_CHARACTER &&
            filterValue[filterValue.length - 1] === LIKE_QUERY_CHARACTER
          ) {
            filterValue = filterValue.slice(1, -1);
          }

          return (
            <StyledInput
              disabled={disabled}
              error={error}
              value={filterValue || ''}
              onChange={(e, data) => onChange(data.value as string)}
              icon={
                <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
              }
              iconPosition={'left'}
              style={{ margin: 0 }}
            />
          );
        }
      }
    },
    [fetchedList, fetchingList, getLists, lists],
  );

  return {
    buildFilterInput,
  };
};

export default useFilterInput;
