import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const ConnectionSelectionWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > div {
    text-align: center;
    margin: ${({ theme }) => `0 ${theme.margin.standard}`};

    > p {
      margin-top: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const ConnectionSelectionSubHeader = styled(StyledText)<{
  color?: string;
}>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  ${({ color }) => color && `color: ${color}`};
`;

export const ConnectionTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const TableWrapper = styled.div`
  width: 100%;
`;
