import { faQuestion, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import defaultLogo from '../../assets/icons/system/codex-icon.svg';
import Card from '../../components/Card/Card';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { SUPPORT_URL } from '../../components/SystemActions/SystemActions';
import WelcomeHeader from '../../components/WelcomeHeader/WelcomeHeader';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePortal from '../../hooks/portal/UsePortal';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import {
  PortalItemOutput,
  PortalItemsOutput,
} from '../../services/portal/PortalService';
import { hideLoading, showLoading } from '../../store/loading';
import { resetStagesAndSubStages } from '../../store/project-stage';
import * as SC from './styled';

const MAXIMUM_RENDERED_PORTAL_ITEMS = 99;

const orderValuesByDate = (values: PortalItemOutput[]) => {
  const cloned = cloneDeep(values);
  return cloned
    .sort((a, b) => {
      return new Date(a.entity.meta?.lastUpdated).getTime() >
        new Date(b.entity.meta?.lastUpdated).getTime()
        ? -1
        : 1;
    })
    .slice(0, MAXIMUM_RENDERED_PORTAL_ITEMS);
};

const PortalUserWelcomePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { organisation } = useOrganisation();
  const { getPortals } = usePortal();

  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [recentPortals, setRecentPortals] = useState<PortalItemOutput[]>();

  useEffect(() => {
    (async () => {
      if (!fetchingData && organisation?._id) {
        setFetchingData(true);

        dispatch(showLoading({ text: 'Loading Data...' }));

        // Get Portals user has access to
        try {
          const portals = (
            (await getPortals(undefined, true)) as PortalItemsOutput
          ).data;

          if (portals.length) {
            const orderedPortals = orderValuesByDate(portals);
            setRecentPortals(orderedPortals);
          }
        } catch {
          setRecentPortals([]);
        }

        dispatch(hideLoading());
      }
    })();
  }, [getPortals, dispatch, fetchingData, organisation?._id]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.WELCOME} />
      <PageContainer>
        <Page>
          <SC.PageInner>
            <WelcomeHeader title="Dashboard" hideCheckList={true} />

            <SC.StyledHeaderWelcome>Quicklinks</SC.StyledHeaderWelcome>
            <SC.SelectionWrapper>
              <div>
                <FeatureButton
                  action={() => history.push(RouteName.USER_ITEM)}
                  size={FeatureButtonSize.MEDIUM}
                  color={themeContext.colors.general.blue}
                  icon={
                    <FontAwesomeIcon
                      icon={faUser}
                      color={themeContext.colors.system.white}
                      size={'3x'}
                    />
                  }
                />

                <SC.SelectionSubHeader>Manage User</SC.SelectionSubHeader>
              </div>

              <div>
                <FeatureButton
                  action={() => window.open(SUPPORT_URL)}
                  size={FeatureButtonSize.MEDIUM}
                  color={themeContext.colors.system.darkBlueHighlight}
                  icon={
                    <FontAwesomeIcon
                      icon={faQuestion}
                      color={themeContext.colors.system.white}
                      size={'3x'}
                    />
                  }
                />
                <SC.SelectionSubHeader>Get Help</SC.SelectionSubHeader>
              </div>
            </SC.SelectionWrapper>
            <SC.StyledHeaderWelcome>Accessible Portals</SC.StyledHeaderWelcome>

            {recentPortals?.length ? (
              <SC.StyledRecentsWrapper>
                {recentPortals.map((portal, i) => {
                  const ent = portal.entity;
                  return (
                    <Card
                      key={`Portal - ${ent.name}-${i}`}
                      title={`${ent.name}`}
                      body={ent.description || ''}
                      image={organisation?.theme?.logo || defaultLogo}
                      action={() => {
                        dispatch(resetStagesAndSubStages());
                        history.push(`${RouteName.PORTAL}/${ent._id}`);
                      }}
                      style={{
                        background: themeContext.colors.system.grey,
                      }}
                    />
                  );
                })}
              </SC.StyledRecentsWrapper>
            ) : (
              <SC.NoResultsFound>No accessible portals found</SC.NoResultsFound>
            )}
          </SC.PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalUserWelcomePage;
