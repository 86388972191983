import { Interfaces } from '@configur-tech/upit-core-types';
import { SearchQuery } from '../../interfaces/Search';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_DATASET_META'] || '';

export interface DatasetMetaItem {
  accessLevel: number;
  entity: Interfaces.DatasetMeta;
}

export interface DatasetMetaItemOutput {
  accessLevel: number;
  entity: Interfaces.DatasetMetaOutput;
}

export interface DatasetMetasItemOutput {
  data: DatasetMetaItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class DatasetMetaService {
  public static async getDatasetMetas(
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum = 1,
    limit?: number,
    orderBy?: Record<string, number>,
    portalId?: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<DatasetMetasItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<DatasetMetasItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { ...query, searchQuery, pageNum, limit, orderBy, portalId });
  }

  public static async getDatasetMeta(
    token: string,
    datasetMetaId: string,
    portalId?: string,
    projectId?: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      _id: datasetMetaId,
      portalId,
      projectId,
      singleDocument: true,
    });

    return result?.data as DatasetMetaItemOutput;
  }

  public static async postDatasetMeta(
    token: string,
    datasetMeta: Interfaces.DatasetMeta,
    userId: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: datasetMeta, userId });

    return result?.data as DatasetMetaItemOutput;
  }

  public static async postDatasetMetaFromCustomIntegration(
    token: string,
    integrationTemplateName: string,
    integrationTemplateId: string,
    actionId: string,
    actionName: string,
    schemaFieldIds: string[],
    avatar: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      data: {
        datasetAvatar: avatar,
        datasetName: `${integrationTemplateName} - ${actionName}`,
        customActionId: actionId,
        schemaFieldIds,
        integrationTemplateId: integrationTemplateId,
      },
    });

    return result?.data as DatasetMetaItemOutput;
  }

  public static async postDatasetMetaFromHttpIntegration(
    token: string,
    integrationTemplateId: string,
    endpointId: string,
    reqEndpointId: string,
    schemaFieldIds: string[],
    avatar: string,
    integrationTemplateName: string,
    endpointName: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      data: {
        datasetAvatar: avatar,
        datasetName: `${integrationTemplateName} - ${endpointName}`,
        endpointId,
        reqEndpointId,
        schemaFieldIds,
        integrationTemplateId: integrationTemplateId,
      },
    });

    return result?.data as DatasetMetaItemOutput;
  }

  public static async putDatasetMeta(
    token: string,
    datasetMeta: Interfaces.DatasetMetaOutput,
    userId: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${datasetMeta._id}`, { data: datasetMeta, userId });

    return result?.data as DatasetMetaItemOutput;
  }

  public static async deleteDatasetMeta(
    token: string,
    datasetMetaId: string,
    userId: string,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${datasetMetaId}`, userId);

    return result?.data as DatasetMetaItemOutput;
  }

  public static async postDatasetMetaCollection(
    token: string,
    datasetMeta: Interfaces.DatasetMetaOutput,
    userId: string,
    collection?: Partial<Interfaces.CollectionOutput>,
  ): Promise<DatasetMetaItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetMetaItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}${datasetMeta._id}/collection`, {
      data: collection || {},
      userId,
    });

    return result?.data as DatasetMetaItemOutput;
  }
}
