import { Enums } from '@configur-tech/upit-core-types';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { faArrowsAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../../../enums/ProjectStage';
import useChart from '../../../../hooks/chart/UseChart';
import { fetchGraphSuccess } from '../../../../store/graph';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectGraphSubStage } from '../../../../store/project-stage/initial-state';
import { RootState } from '../../../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../styled';

export interface DraggableGraphItemProps {
  id: string;
  active: boolean;
  text: string;
}

const DraggableGraphItem: FC<DraggableGraphItemProps> = ({
  id,
  text,
  active,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });
  const dispatch = useDispatch();
  const { chart, editChart } = useChart();
  const chartData = useSelector((state: RootState) => state.chart)?.data;
  const style = { transform: CSS.Translate.toString(transform) };
  const themeContext = useContext(ThemeContext);

  return (
    <SC.DraggableGraphItemWrapper
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <FeatureButton
        action={() => {
          if (chart) {
            editChart(chart);
          }

          const graph = chartData?.entity.graphs.find((g) => g._id === id);
          if (graph) {
            dispatch(
              fetchGraphSuccess({
                accessLevel: Enums.AccessLevel.MANAGE,
                entity: graph,
              }),
            );

            dispatch(updateActiveProjectStage(ProjectStage.GRAPH));
            dispatch(updateActiveProjectSubStage(ProjectGraphSubStage.GRAPH));
          }
        }}
        icon={
          !active ? (
            <FontAwesomeIcon
              icon={faArrowsAlt}
              color={themeContext.colors.system.darkOffBlack}
              {...listeners}
            />
          ) : undefined
        }
        size={FeatureButtonSize.WIDE_SMALL}
        color={
          active
            ? themeContext.colors.general.purple
            : themeContext.colors.system.grey
        }
        text={text}
        containerStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        faceStyle={{
          width: '100%',
        }}
        textStyle={{
          color: active
            ? themeContext.colors.general.white
            : themeContext.colors.system.darkOffBlack,
          width: 'calc(100% - 1em)',
          textAlign: 'center',
          textTransform: 'capitalize',
        }}
      />
    </SC.DraggableGraphItemWrapper>
  );
};
export default DraggableGraphItem;
