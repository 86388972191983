import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
  StyledTextArea,
} from '../../../main/theme';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import RollbackConfirmation from './SnapshotRollbackConfirmation';

const STAGE_DETAILS = 1;
const STAGE_DELETE = 2;
const STAGE_ROLLBACK = 3;

export interface SnapshotItem {
  name: string;
  description?: string;
  _id: string;
}

export interface SnapshotModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingSnapshot?: {
    entityId: string;
    snapshot: SnapshotItem;
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const RollbackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const NameInput = styled(StyledInput)`
  margin: ${({ theme }) => `0 0 ${theme.margin.standard} 0`};
`;

const DescriptionInput = styled(StyledTextArea)`
  margin: ${({ theme }) => `0 0 ${theme.margin.standard} 0`};
`;

const SnapshotModal: FC<SnapshotModalProps> = ({
  setShowModal,
  existingSnapshot,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const [modalStage, setModalStage] = useState<number>(STAGE_DETAILS);

  const [snapshot, setSnapshot] = useState<SnapshotItem>();

  const {
    datasetMeta,
    addDatasetSnapshot,
    editDatasetSnapshot,
    removeDatasetSnapshot,
    rollbackDatasetToSnapshot,
  } = useDatasetMeta();

  const handleNameChange = (value: string) => {
    if (value) {
      const cloned = cloneDeep(snapshot || {});
      cloned.name = value;
      setSnapshot(cloned);
    }
  };

  const handleDescriptionChange = (value: string) => {
    if (value) {
      const cloned = cloneDeep(snapshot || {});
      cloned.description = value;
      setSnapshot(cloned);
    }
  };

  const processAction = async () => {
    if (snapshot) {
      const clonedSnapshot = cloneDeep(snapshot);

      if (existingSnapshot) {
        dispatch(showLoading({ text: 'Updating Snapshot...' }));

        await editDatasetSnapshot(clonedSnapshot);

        dispatch(hideLoading());
        dispatch(hideModal());

        return;
      }

      if (datasetMeta) {
        dispatch(showLoading({ text: 'Creating Snapshot...' }));

        await addDatasetSnapshot(datasetMeta._id, snapshot);

        dispatch(hideLoading());
        dispatch(hideModal());
      }
    }

    dispatch(hideModal());
  };

  const processDelete = async () => {
    if (existingSnapshot) {
      dispatch(showLoading({ text: 'Deleting Snapshot...' }));

      await removeDatasetSnapshot(existingSnapshot.snapshot._id);

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  const processRollback = async () => {
    if (existingSnapshot) {
      dispatch(showLoading({ text: 'Restoring Snapshot...' }));

      await rollbackDatasetToSnapshot(existingSnapshot.snapshot._id);

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  useEffect(() => {
    if (existingSnapshot) {
      setSnapshot(existingSnapshot.snapshot);
    }
  }, [existingSnapshot]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <Wrapper>
      <HeaderWrapper>
        {modalStage === STAGE_DETAILS && (
          <Header>{existingSnapshot ? 'Update' : 'Add'} Snapshot</Header>
        )}

        {modalStage === STAGE_DELETE && <Header>Delete Snapshot</Header>}
        {modalStage === STAGE_ROLLBACK && <Header>Rollback Snapshot</Header>}

        {modalStage !== STAGE_DELETE &&
          modalStage !== STAGE_ROLLBACK &&
          existingSnapshot && (
            <DeleteButton>
              <FeatureButton
                action={() => setModalStage(STAGE_DELETE)}
                size={FeatureButtonSize.WIDE_SMALL}
                color={themeContext.colors.general.red}
                text={'Delete'}
              />
            </DeleteButton>
          )}

        {modalStage !== STAGE_DELETE &&
          modalStage !== STAGE_ROLLBACK &&
          existingSnapshot && (
            <RollbackButton>
              <FeatureButton
                action={() => setModalStage(STAGE_ROLLBACK)}
                size={FeatureButtonSize.WIDE_SMALL}
                color={themeContext.colors.general.blue}
                text={'Rollback'}
              />
            </RollbackButton>
          )}
      </HeaderWrapper>

      {modalStage === STAGE_DETAILS && (
        <>
          {!existingSnapshot && (
            <>
              <StyledText>
                A snapshot is a complete backup of your dataset and its
                configuration.
              </StyledText>
              <StyledText>
                To create one, simply enter a name and a brief description of
                why this snapshot is required.
              </StyledText>
            </>
          )}
          {existingSnapshot && (
            <>
              <StyledText>
                A snapshot is a complete backup of your dataset and its
                configuration.
              </StyledText>
              <StyledText>
                Rolling back to a previous snapshot will mean any changes to its
                configuration or data since the snapshot was created will be
                lost.
              </StyledText>
            </>
          )}

          <StyledBodySubHeader>Name</StyledBodySubHeader>
          <NameInput
            onChange={(e, data) => handleNameChange(data.value)}
            value={snapshot ? snapshot.name : ''}
            placeholder={'Enter a name for your snapshot'}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <DescriptionInput
            minRows={5}
            maxRows={10}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            value={snapshot ? snapshot.description : ''}
            placeholder={'Enter a description for your snapshot'}
          />

          <ActionButtonWrapper>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={!snapshot?.name}
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Save Snapshot'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <DeleteConfirmation
          entityType={'snapshot' as EntityType}
          entityName={existingSnapshot?.snapshot.name}
          cancelAction={() => setModalStage(STAGE_DETAILS)}
          deleteAction={processDelete}
        />
      )}

      {modalStage === STAGE_ROLLBACK && (
        <RollbackConfirmation
          entityType={'snapshot' as EntityType}
          entityName={existingSnapshot?.snapshot.name}
          cancelAction={() => setModalStage(STAGE_DETAILS)}
          rollbackAction={processRollback}
        />
      )}
    </Wrapper>
  );
};

export default SnapshotModal;
