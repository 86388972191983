import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';

const NO_EXISTING_JOBS_TYPES = [
  Enums.PipelineJobType.MAPPING,
  Enums.PipelineJobType.INTEGRATION,
  Enums.PipelineJobType.DATASET,
  Enums.PipelineJobType.CONDITIONAL,
  Enums.PipelineJobType.FILTER,
  Enums.PipelineJobType.QUERY,
];
const LAST_JOB_INTEGRATION_TYPES = [
  Enums.PipelineJobType.MAPPING,
  Enums.PipelineJobType.FILTER,
  Enums.PipelineJobType.CONDITIONAL,
  Enums.PipelineJobType.INTEGRATION,
];
const LAST_JOB_MAPPING_TYPES = [
  Enums.PipelineJobType.INTEGRATION,
  Enums.PipelineJobType.DATASET,
  Enums.PipelineJobType.CONDITIONAL,
  Enums.PipelineJobType.MAPPING,
  Enums.PipelineJobType.FILTER,
  Enums.PipelineJobType.QUERY,
];

const PipelineJobOptionBuilder = (
  trigger: Enums.PipelineTrigger,
  existingJobs: Interfaces.PipelineJob[],
  jobIndex?: number,
): DropdownItemProps[] => {
  // First job must be INTEGRATION or MAPPING
  if (
    !existingJobs.length ||
    (existingJobs.length === 1 && typeof jobIndex !== 'undefined')
  ) {
    return NO_EXISTING_JOBS_TYPES.map((type) => {
      return {
        key: type,
        text: startCase(type.toLowerCase()),
        value: type,
      };
    });
  }

  // If last job is INTEGRATION, next job must be MAPPING
  if (
    existingJobs[jobIndex ? jobIndex - 1 : existingJobs.length - 1].jobType ===
      Enums.PipelineJobType.INTEGRATION ||
    existingJobs[jobIndex ? jobIndex - 1 : existingJobs.length - 1].jobType ===
      Enums.PipelineJobType.QUERY
  ) {
    return LAST_JOB_INTEGRATION_TYPES.map((type) => {
      return {
        key: type,
        text: startCase(type.toLowerCase()),
        value: type,
      };
    });
  }

  return LAST_JOB_MAPPING_TYPES.map((type) => {
    return {
      key: type,
      text: startCase(type.toLowerCase()),
      value: type,
    };
  });
};

export default PipelineJobOptionBuilder;
