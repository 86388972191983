import React, { FC } from 'react';
import styled from 'styled-components';

interface NavErrorCountProps {
  errorCount?: number;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 25px;
  min-width: 25px;
  background: ${({ theme }) => theme.colors.general.red};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colors.system.white};

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.white};

  padding: ${({ theme }) => theme.padding.small};
`;

const NavErrorCount: FC<NavErrorCountProps> = ({ errorCount }) => {
  if (!errorCount) {
    return null;
  }

  return <Wrapper>{errorCount}</Wrapper>;
};

export default NavErrorCount;
