import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../enums';
import { AccessEntityType, AccessType } from '../../enums/Access';
import { EntityType } from '../../enums/EntityType';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import Page from '../../main/Page';
import {
  defaultTheme,
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledH1,
  StyledSubHeader,
  StyledText,
} from '../../main/theme';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import AccessSelection from '../AccessSelection/AccessSelection';
import Banner from '../BannerComponent/Banner';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import PageFeatureHeader from '../PageFeatureHeader/PageFeatureHeader';
import { PopupColorPicker } from '../PopupColorPicker/PopupColorPicker';
import * as SC from './styled';

const OrganisationOverview: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { organisation, editOrganisation } = useOrganisation();

  const [editingDetails, setEditingDetails] = useState<boolean>(false);
  const [updatedName, setUpdatedName] = useState<string>();
  const [savingOrganisation, setSavingOrganisation] = useState<boolean>(false);

  const [primaryColour, setPrimaryColour] = useState<string>();
  const [secondaryColour, setSecondaryColour] = useState<string>();
  const [tertiaryColour, setTertiaryColour] = useState<string>();

  const coloursHaveChanged =
    primaryColour !== organisation?.theme?.colours?.primary ||
    secondaryColour !== organisation?.theme?.colours?.secondary ||
    tertiaryColour !== organisation?.theme?.colours?.tertiary;

  const updateDetails = useCallback(async () => {
    if (
      (updatedName?.length && updatedName !== organisation?.name) ||
      coloursHaveChanged
    ) {
      const cloned = cloneDeep(organisation);
      cloned.name = updatedName;

      if (primaryColour && secondaryColour) {
        cloned.theme = {
          ...cloned.theme,
          colours: {
            primary: primaryColour,
            secondary: secondaryColour,
          },
        };
        if (tertiaryColour) {
          cloned.theme = {
            ...cloned.theme,
            colours: {
              ...cloned.theme.colours,
              tertiary: tertiaryColour,
            },
          };
        }
      } else {
        delete cloned.theme.colours;
      }

      setSavingOrganisation(true);

      await editOrganisation(cloned);

      setSavingOrganisation(false);
      setEditingDetails(false);
    } else {
      if (organisation?.name) {
        setUpdatedName(organisation.name);
      }
      setEditingDetails(false);
    }
  }, [
    coloursHaveChanged,
    editOrganisation,
    organisation,
    primaryColour,
    secondaryColour,
    tertiaryColour,
    updatedName,
  ]);

  const resetOrgColours = useCallback(() => {
    setPrimaryColour(organisation?.theme?.colours?.primary);
    setSecondaryColour(organisation?.theme?.colours?.secondary);
    setTertiaryColour(organisation?.theme?.colours?.tertiary);
  }, [
    organisation?.theme?.colours?.primary,
    organisation?.theme?.colours?.secondary,
    organisation?.theme?.colours?.tertiary,
  ]);

  const updateAvatar = useCallback(
    async (icon) => {
      if (icon) {
        const cloned = cloneDeep(organisation);
        cloned.theme = { ...cloned.theme, logo: icon };

        setSavingOrganisation(true);

        await editOrganisation(cloned);
        setSavingOrganisation(false);
      }
    },
    [organisation, editOrganisation],
  );

  useEffect(() => {
    if (typeof updatedName === 'undefined' && organisation?.name) {
      setUpdatedName(organisation.name);
    }

    resetOrgColours();
  }, [organisation, resetOrgColours, updatedName]);

  const handleAccessChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update organisation
      const cloned: Interfaces.OrganisationOutput = cloneDeep(organisation);
      cloned.access[type][group] = updated;
      await editOrganisation(cloned);
    }
  };

  const OrganisationAvatar =
    organisation?.theme?.logo && organisation?.theme?.logo.includes('http')
      ? organisation?.theme?.logo
      : organisation?.theme?.logo
      ? { ...UserIconMap, ...AvatarIconMap }[organisation?.theme?.logo]
      : getDefaultAvatar(EntityType.ORGANISATION);

  return (
    <>
      <PageFeatureHeader
        pageHeader={'Overview'}
        image={OrganisationAvatar}
        // Deprecated
        // avatarPopupEntityType={EntityType.ORGANISATION}
        // avatarPopupOnChange={(icon) => updateAvatar(icon)}
        // avatarPopupValue={organisation?.theme?.logo}
        // avatarPopupLoading={savingOrganisation}
        title={
          <SC.NameWrapper>
            {!editingDetails ? (
              <StyledH1>{organisation?.name}</StyledH1>
            ) : (
              <>
                <StyledBodySubHeader>Organisation Name</StyledBodySubHeader>
                <SC.NameInput
                  loading={savingOrganisation}
                  disabled={savingOrganisation}
                  onChange={(e, data) => setUpdatedName(data.value)}
                  value={updatedName}
                  placeholder={'Enter your organisation name'}
                  style={{ marginBottom: defaultTheme.margin.xxxlarge }}
                />

                <StyledBodySubHeader>Organisation Colours</StyledBodySubHeader>
                <SC.ColourSelectionWrapper>
                  <SC.ColourSelectionItem>
                    <StyledText>Primary</StyledText>
                    <PopupColorPicker
                      color={
                        primaryColour
                          ? primaryColour
                          : defaultTheme.colors.general.blue
                      }
                      disabled={false}
                      onChange={(colour) => setPrimaryColour(colour)}
                    />
                  </SC.ColourSelectionItem>
                  <SC.ColourSelectionItem>
                    <StyledText>Secondary</StyledText>
                    <PopupColorPicker
                      color={
                        secondaryColour
                          ? secondaryColour
                          : defaultTheme.colors.general.blue
                      }
                      disabled={false}
                      onChange={(colour) => setSecondaryColour(colour)}
                    />
                  </SC.ColourSelectionItem>
                  <SC.ColourSelectionItem>
                    <StyledText>Tertiary</StyledText>
                    <PopupColorPicker
                      color={
                        tertiaryColour
                          ? tertiaryColour
                          : defaultTheme.colors.general.blue
                      }
                      disabled={false}
                      onChange={(colour) => setTertiaryColour(colour)}
                    />
                  </SC.ColourSelectionItem>
                </SC.ColourSelectionWrapper>
                <FeatureButton
                  isDisabled={savingOrganisation}
                  action={() => {
                    setPrimaryColour(undefined);
                    setSecondaryColour(undefined);
                    setTertiaryColour(undefined);
                  }}
                  color={themeContext.colors.general.blue}
                  text={'Reset Colours'}
                  size={FeatureButtonSize.WIDE_SMALL}
                  style={{
                    marginBottom: defaultTheme.margin.xlarge,
                  }}
                />
              </>
            )}

            {/*Deprecated*/}
            {/*<SC.NameActionsWrapper>*/}
            {/*  {!editingDetails ? (*/}
            {/*    <FeatureButton*/}
            {/*      isDisabled={savingOrganisation}*/}
            {/*      action={() => {*/}
            {/*        setUpdatedName(organisation?.name);*/}
            {/*        setEditingDetails(true);*/}
            {/*      }}*/}
            {/*      color={themeContext.colors.general.blue}*/}
            {/*      text={'Edit Info'}*/}
            {/*      size={FeatureButtonSize.WIDE_SMALL}*/}
            {/*    />*/}
            {/*  ) : (*/}
            {/*    <>*/}
            {/*      <FeatureButton*/}
            {/*        isDisabled={savingOrganisation}*/}
            {/*        action={() => {*/}
            {/*          setEditingDetails(false);*/}
            {/*          setUpdatedName(organisation?.name);*/}
            {/*          resetOrgColours();*/}
            {/*        }}*/}
            {/*        color={themeContext.colors.general.sea}*/}
            {/*        text={'Cancel'}*/}
            {/*        size={FeatureButtonSize.WIDE_SMALL}*/}
            {/*      />*/}
            {/*      <FeatureButton*/}
            {/*        isDisabled={*/}
            {/*          savingOrganisation ||*/}
            {/*          !updatedName?.length ||*/}
            {/*          (updatedName === organisation?.name &&*/}
            {/*            !coloursHaveChanged)*/}
            {/*        }*/}
            {/*        action={updateDetails}*/}
            {/*        color={themeContext.colors.general.green}*/}
            {/*        text={'Save'}*/}
            {/*        size={FeatureButtonSize.WIDE_SMALL}*/}
            {/*        containerStyle={{*/}
            {/*          marginLeft: themeContext.margin.standard,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</SC.NameActionsWrapper>*/}
          </SC.NameWrapper>
        }
      />

      <Page>
        <StageWrapper>
          <StageInner>
            <Banner
              message={
                <p>
                  Your Organisation has been made even easier to manage and can
                  now be found{' '}
                  <a
                    href={'https://portal.goconfigur.com/organisation/overview'}
                  >
                    here
                  </a>
                  .
                </p>
              }
              alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
              alertStatus={AlertStatus.INFO}
            />

            <StyledSubHeader style={{ marginTop: 20 }}>
              Organisation Managers
            </StyledSubHeader>
            <StageBodyText>Who can manage your organisation?</StageBodyText>

            <AccessSelection
              disabled={true}
              access={organisation?.access}
              onChange={handleAccessChange}
              hideTypes={[AccessType.EDIT, AccessType.VIEW]}
              hideOrganisation={true}
              hideTeams={true}
            />
          </StageInner>
        </StageWrapper>
      </Page>
    </>
  );
};

export default OrganisationOverview;
