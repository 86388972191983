import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FileHeaderType } from '@configur-tech/upit-core-types/lib/enums';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import { RouteName } from '../../enums';
import { SampleData } from '../../interfaces/SampleData';
import { CreateFileDocumentResponse } from '../../services/file/FileService';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import buildTableData from '../../util/build-table-data/BuildTableData';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useSchema from '../schema/UseSchema';
import useDatasetMeta from './UseDatasetMeta';

interface useCreateDatasetMetaSourceResult {
  createSourceResult: (
    uploadedFileDetail: CreateFileDocumentResponse,
    sourceSheet?: string,
    sourceType?: Enums.DataSourceType,
    headers?: Enums.FileHeaderType,
    delimiter?: string,
  ) => Promise<SampleData | undefined>;
}

const useCreateDatasetMetaSource = (): useCreateDatasetMetaSourceResult => {
  const dispatch = useDispatch();
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();
  const { discoverSchema } = useSchema();
  const { collection } = useDatasetCollection();

  /**
   * Completes dataset source upload and sample generation flow
   *
   * @param {CreateFileDocumentResponse} uploadedFileDetail - Uploaded file response
   * @param {string} [sourceSheet] - Optional sheet to be uploaded
   * @param {Enums['DataSourceType']} sourceType - Source type of file
   * @param {Enums.['FileHeaderType']} headers - Type of headers in file
   * @param {string} delimiter - String to use as the delimiter
   *
   * returns {SampleData} - Object containing schema and sample
   * rows of uploaded file data
   */
  const createSourceResult = useCallback(
    async (
      uploadedFileDetail: CreateFileDocumentResponse,
      sourceSheet,
      sourceType,
      headers,
      delimiter,
    ): Promise<SampleData | undefined> => {
      if (uploadedFileDetail && datasetMeta) {
        dispatch(showLoading({ text: 'Updating Dataset...' }));

        const cloned = cloneDeep(datasetMeta);
        const coll = cloned?.dataCollections.find(
          (c) => c._id === collection?.collectionId,
        );

        coll.dataSource = {
          ...coll.dataSource,
          dataSourceType: sourceType,
          [sourceType]: {
            ...coll.dataSource[sourceType],
            fileId: uploadedFileDetail.fileId,
            sheetName: sourceSheet,
            headers,
            delimiter,
          },
        };

        // Update current status
        if (coll) {
          coll.stages[Enums.DatasetStage.CREATION].status =
            Enums.StageStatus.COMPLETED;

          const lineCountMinusHeaders =
            (headers === FileHeaderType.ROW &&
            [
              Enums.DataSourceType.CSV,
              Enums.DataSourceType.TXT,
              Enums.DataSourceType.XLS,
            ].includes(sourceType as Enums.DataSourceType)
              ? uploadedFileDetail.file.lineCount - 1
              : uploadedFileDetail.file.lineCount) || 0;

          coll.stages[Enums.DatasetStage.CREATION].result = {
            total: lineCountMinusHeaders,
            completed: lineCountMinusHeaders,
            failed: 0,
          };
        }

        // Save datasetMeta
        await editDatasetMeta(cloned);

        try {
          dispatch(showLoading({ text: 'Collating Results...' }));

          // Build and return sample data
          const head =
            headers === Enums.FileHeaderType.NONE ? undefined : headers;
          const { schema, sample } = await discoverSchema(
            uploadedFileDetail.fileId,
            head,
            headers === Enums.FileHeaderType.NONE,
            delimiter,
            sourceSheet,
          );

          // Check for duplicate columns
          const columns: Interfaces.SchemaField[] = [];
          const duplicateColumns = schema.reduce(
            (acc: Interfaces.SchemaField[], col) => {
              if (columns.find((existing) => existing.field === col.field)) {
                return [...acc, col];
              }
              columns.push(col);
              return acc;
            },
            [],
          );

          if (duplicateColumns.length) {
            const dupeColNames = duplicateColumns.map(
              (col) => `"${col.field}"`,
            );
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.ERROR,
                forceOpen: true,
                additionalProps: {
                  errorType: ErrorType.DUPLICATE_COLUMNS,
                  errorData: {
                    duplicateColumns: dupeColNames,
                  },
                },
              }),
            );
            dispatch(hideLoading());
            return;
          }

          dispatch(hideLoading());

          return buildTableData(schema, sample);
        } catch (err: unknown) {
          dispatch(hideLoading());

          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.ERROR,
              forceOpen: true,
              additionalProps: {
                errorType: ErrorType.ENTITY_NOT_FOUND,
                errorHeading: `${
                  (err as AxiosError)?.response?.data?.statusCode || 'Error'
                } - Failed To Create Dataset`,
                errorText:
                  'Failed to create dataset. Please check your source file.',
                errorActionText: 'Return to Datasets',
                errorActionRedirectRoute: RouteName.DATASETS,
              },
            }),
          );
        }
      }
    },
    [
      collection?.collectionId,
      datasetMeta,
      discoverSchema,
      dispatch,
      editDatasetMeta,
    ],
  );

  return {
    createSourceResult,
  };
};

export default useCreateDatasetMetaSource;
