import { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled, { ThemeContext } from 'styled-components';
import QueryConnectionIcon from '../../assets/icons/stages/aggregate-project/query.svg';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { ModalTypes } from '../../components/Modal/Modal';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PortalAggregationNameStage from '../../components/PortalAggregation/1-name/PortalAggregationNameStage';
import PortalAggregationDataStage from '../../components/PortalAggregation/2-datasets/PortalAggregationDataStage';
import PortalAggregationAggregateStage from '../../components/PortalAggregation/3-aggregate/PortalAggregationAggregateStage';
import PortalAggregationMain from '../../components/PortalAggregation/PortalAggregationMain';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import useQuery from '../../hooks/query/UseQuery';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StyledH1 } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import {
  PortalAggregationSubStage,
  PortalStage,
} from '../../store/portal-stage/initial-state';
import { RootState } from '../../store/rootReducer';

const STAGE_TITLE = 'Query And Filter Your Data';

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.xxlarge} 0 0`};

  > h1 {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }

  > textarea {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }
`;

const NameActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;

const PortalQueriesPage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { portalId } = useParams();

  const { portal, getPortal } = usePortal();
  const { query } = useQuery();

  // Fetch portal if on portal page and not in state
  useEffect(() => {
    if (
      location.pathname.includes(RouteName.PORTAL) &&
      portalId &&
      portal &&
      portal._id !== portalId
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Portal...' }));
        await getPortal(portalId, true);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getPortal, portal, portal?._id, portalId]);

  const portalStageState = useSelector((state: RootState) => state.portalStage);
  const aggStage = portalStageState.stages[PortalStage.AGGREGATION];
  const activeSubStage = aggStage.activeSubStage;

  const isAggregating = activeSubStage === PortalAggregationSubStage.AGGREGATE;

  let pageComp;
  switch (activeSubStage) {
    case PortalAggregationSubStage.NAME:
      pageComp = <PortalAggregationNameStage />;
      break;
    case PortalAggregationSubStage.DATASETS:
      pageComp = <PortalAggregationDataStage />;
      break;
    case PortalAggregationSubStage.AGGREGATE:
      pageComp = <PortalAggregationAggregateStage />;
      break;
    default:
      pageComp = <PortalAggregationMain />;
  }

  const stageContentTitle =
    activeSubStage !== PortalAggregationSubStage.NAME || !query?._id ? (
      STAGE_TITLE
    ) : (
      <NameWrapper>
        <StyledH1>{STAGE_TITLE}</StyledH1>

        <NameActionsWrapper>
          <FeatureButton
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.DELETION,
                  additionalProps: {
                    entityType: EntityType.AGGREGATION,
                    entityId: query?._id,
                    entityName: query?.name,
                    isPortal: true,
                  },
                }),
              )
            }
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.red}
            text={'Delete Query'}
          />
        </NameActionsWrapper>
      </NameWrapper>
    );

  return (
    <>
      <PrimaryNavigationContainer
        route={RouteName.PORTAL}
        connectionType={'query'}
      />
      <PageContainer>
        {!isAggregating && (
          <PageFeatureHeader
            pageHeader={'Queries'}
            image={QueryConnectionIcon}
            title={stageContentTitle}
          />
        )}

        <Page style={isAggregating ? { overflow: 'hidden' } : undefined}>
          {pageComp}
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalQueriesPage;
