import React, { FC } from 'react';
import styled from 'styled-components';
import { Cell } from '../../DataSample/DataSample';

const Image = styled.img`
  max-width: 60px;
`;

const ImageTableCell: FC<Cell> = ({ value, column }) => {
  return <Image src={value} alt={`${column.id} image`} />;
};

export default ImageTableCell;
