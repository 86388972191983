import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import { SUPER_COL_TYPES } from '../../components/Modal/pipeline/JobMappingModalComponent';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../services/integration/IntegrationTemplateService';
import { QueryItemOutput, QueryWithDetails } from '../../store/queries';

interface usePipelineEntityHelperResult {
  getLatestEntityId: (
    jobs: Interfaces.PipelineJob[],
    currentJob: Interfaces.PipelineJob,
    datasetId?: string,
    integrationId?: string,
    queryId?: string,
  ) => string | undefined;
  getLastDataJob: (
    jobs: Interfaces.PipelineJob[],
    currentJob: Interfaces.PipelineJob,
  ) => Interfaces.PipelineJob | undefined;
  getEntitySchema: (
    prevDataJob: Interfaces.PipelineJob,
    datasetMetas?: DatasetMetaItemOutput[],
    integrationTemplates?: IntegrationTemplateItem[],
    queries?: QueryItemOutput[],
    filterSuperColumns?: boolean,
    mappingDirection?: MappingDirection,
  ) => Interfaces.SchemaField[] | undefined;
}

export enum MappingDirection {
  INPUT = 'input',
  OUTPUT = 'output',
}

const usePipelineEntityHelper = (): usePipelineEntityHelperResult => {
  /**
   * Finds entityId from last job that has one
   *
   * @param {Interfaces.PipelineJob[]} jobs - All jobs in current pipeline
   * @param {Interfaces.PipelineJob} currentJob - Current job to find previous entityId for
   *
   * @returns {string} Last entityId used in pipeline or undefined if one can't be found
   */
  const findLastUsedEntityId = (
    jobs: Interfaces.PipelineJob[],
    currentJob: Interfaces.PipelineJob,
  ): string | undefined => {
    const dep = currentJob?.jobIdsJobDependsOn?.[0];

    if (!dep) {
      return;
    }

    const depJob = jobs.find((job) => job._id === dep);

    if (!depJob) {
      return;
    }

    const id =
      (depJob?.jobParams as Interfaces.DatasetJobParams)?.datasetMetaId ||
      (depJob?.jobParams as Interfaces.IntegrationJobParams)?.integrationId;

    if (id) {
      return id as string;
    }

    return findLastUsedEntityId(jobs, depJob);
  };

  /**
   * Gets the last used EntityId used in a pipeline
   *
   * @param {Interfaces.PipelineJob[]} jobs - All jobs in current pipeline
   * @param {Interfaces.PipelineJob} currentJob - Current job to find previous entityId for
   * @param {string} [datasetId] - DatasetMetaId to default back to if present
   * @param {string} [integrationId] - IntegrationID to default back to if present
   * @param {string} [queryId] - QueryID to default back to if present
   *
   * @returns {string} Last entityId used in pipeline or undefined if one can't be found
   */
  const getLatestEntityId = (
    jobs: Interfaces.PipelineJob[],
    currentJob: Interfaces.PipelineJob,
    datasetId?: string,
    integrationId?: string,
    queryId?: string,
  ): string => {
    if (currentJob) {
      switch (currentJob.jobType) {
        case Enums.PipelineJobType.QUERY:
          return queryId as string;
        case Enums.PipelineJobType.INTEGRATION:
          return integrationId as string;
        case Enums.PipelineJobType.CONDITIONAL:
        case Enums.PipelineJobType.FILTER: {
          return (
            findLastUsedEntityId(jobs, currentJob) || (datasetId as string)
          );
        }
      }
    }

    return datasetId as string;
  };

  /**
   * Gets the previous Enums.PipelineJobType.DATASET or Enums.PipelineJobType.INTEGRATION job in the pipeline
   *
   * @param {Interfaces.PipelineJob[]} jobs - All jobs in current pipeline
   * @param {Interfaces.PipelineJob} currentJob - Current job to find previous relevant job for
   *
   * @returns {string} Last relevant job from pipeline
   */
  const getLastDataJob = useCallback(
    (
      jobs: Interfaces.PipelineJob[],
      currentJob: Interfaces.PipelineJob,
    ): Interfaces.PipelineJob | undefined => {
      if (
        [
          Enums.PipelineJobType.DATASET,
          Enums.PipelineJobType.INTEGRATION,
          Enums.PipelineJobType.MAPPING,
          Enums.PipelineJobType.QUERY,
        ].includes(currentJob.jobType)
      ) {
        return currentJob;
      }

      const currentIndex = jobs.findIndex((job) => job._id === currentJob._id);

      if (!currentIndex) {
        return;
      }

      const lastJob = jobs[currentIndex - 1];

      if (!lastJob) {
        return;
      }

      return getLastDataJob(jobs, lastJob);
    },
    [],
  );

  /**
   * Gets a DatasetMeta's schema based on an dataset mapping job's params
   *
   * @param {string} datasetMetaId - DatasetMetaId to get schema for
   * @param {DatasetMetaItemOutput[]} datasetMetas - DatasetMetas to retrieve schema from
   * @param {boolean} [filterSuperColumns] - Flag to indicate whether super columns should be removed from output schema
   *
   * @returns {Interfaces.SchemaField[] | undefined} Schema if found, undefined if not
   */
  const getDatasetSchema = (
    datasetMetaId: string,
    datasetMetas: DatasetMetaItemOutput[],
    filterSuperColumns?: boolean,
  ): Interfaces.SchemaField[] | undefined => {
    const inputDSM = datasetMetas.find(
      (dsm) => dsm.entity._id === datasetMetaId,
    );
    const activeCollection = inputDSM?.entity.dataCollections.find(
      (collection) => collection._id === inputDSM.entity.activeDataCollection,
    );

    if (activeCollection) {
      let mappedFields = activeCollection.schemaData;

      if (filterSuperColumns) {
        mappedFields = activeCollection.schemaData.filter(
          (field) =>
            field.dataValidation?.dataValidationType &&
            !SUPER_COL_TYPES.includes(field.dataValidation.dataValidationType),
        );
      }

      return mappedFields.map((field) =>
        Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
      );
    }
  };

  /**
   * Gets an Integration's schema based on an integration mapping job's params
   *
   * @param {Interfaces.IntegrationJobParams} integrationParams - Integration mapping job's params
   * @param {IntegrationTemplateItem[]} integrationTemplates - Integration templates to retrieve schema from
   * @param {boolean} [isRequestPayload] - Whether this should return request payload schema
   *
   * @returns {Interfaces.SchemaField[] | undefined} Schema if found, undefined if not
   */
  const getIntegrationSchema = (
    integrationParams: Interfaces.IntegrationJobParams,
    integrationTemplates: IntegrationTemplateItem[],
    isRequestPayload?: boolean,
  ): Interfaces.SchemaField[] | undefined => {
    const intTemplate = integrationTemplates.find(
      (template) => template.entity._id === integrationParams.integrationId,
    );

    if (integrationParams.integrationCustomActionId) {
      const action = intTemplate?.entity.customActions.find(
        (action) => action._id === integrationParams.integrationCustomActionId,
      );
      return action?.responseSchema;
    } else if (integrationParams.integrationEndpointId) {
      const endpoint = intTemplate?.entity.endpoints.find(
        (endpoint) => endpoint._id === integrationParams.integrationEndpointId,
      );
      const requestEndpoint = endpoint?.endpoints.find(
        (request) =>
          request._id === integrationParams.integrationRequestEndpointId,
      );

      // Use request body if mapping to a PUT/PATCH/POST request
      if (
        requestEndpoint?.httpMethod &&
        [
          Enums.ApiRequestType.PUT,
          Enums.ApiRequestType.POST,
          Enums.ApiRequestType.PATCH,
        ].includes(requestEndpoint.httpMethod) &&
        !isRequestPayload
      ) {
        return requestEndpoint?.requestParams?.payload?.payloadSchema;
      }

      return requestEndpoint?.responseInfo?.responseSchema;
    }
  };

  /**
   * Gets a Query schema based on an query mapping job's params
   *
   * @param {string} queryId - QueryId to get schema for
   * @param {QueryItemOutput[]} queries - QueryItemOutput to retrieve schema from
   * @param {boolean} [filterSuperColumns] - Flag to indicate whether super columns should be removed from output schema
   *
   * @returns {Interfaces.SchemaField[] | undefined} Schema if found, undefined if not
   */
  const getQuerySchema = (
    queryId: string,
    queries: QueryItemOutput[],
    filterSuperColumns?: boolean,
  ): Interfaces.SchemaField[] | undefined => {
    const query = queries.find((q) => q.entity._id === queryId)
      ?.entity as QueryWithDetails;

    if (!query) {
      return [];
    }

    const masterFieldList = query.additionalDetails?.flatMap(
      (dsm) => dsm.schemaData,
    );

    const mappedDisplayFields =
      query.queryParams.displayOrder?.reduce(
        (acc: Interfaces.FieldOutput[], displayField) => {
          const field = masterFieldList?.find(
            (field) =>
              (field.fieldId === displayField.field ||
                field.name === displayField.alias) &&
              !(field as Interfaces.FieldOutput & { isMeasure: boolean })
                .isMeasure,
          );

          if (field) {
            return [...acc, field];
          }

          return acc;
        },
        [],
      ) || [];

    const mappedMeasureFields =
      query.queryParams.measures?.reduce(
        (acc: Interfaces.FieldOutput[], measureField) => {
          const field = masterFieldList?.find(
            (field) =>
              field.fieldId === measureField.field &&
              field.name === measureField.alias,
          );

          if (field) {
            return [...acc, field];
          }

          return acc;
        },
        [],
      ) || [];

    let mappedFields = mappedDisplayFields.concat(mappedMeasureFields);

    if (filterSuperColumns) {
      mappedFields = mappedFields.filter(
        (field) =>
          field.dataValidation?.dataValidationType &&
          !SUPER_COL_TYPES.includes(field.dataValidation.dataValidationType),
      );
    }

    return mappedFields.map((field) =>
      Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
    );
  };

  /**
   * Gets the required schema for an entity based on its job type
   *
   * @param {Interfaces.PipelineJob} prevDataJob - Previous data based pipeline job to find previous relevant schema for
   * @param {DatasetMetaItemOutput[]} datasetMetas - DatasetMeta items to look up schema from if required
   * @param {IntegrationTemplateItem[]} integrationTemplates - IntegrationTemplate items to look up schema from if required
   * @param {QueryItemOutput[]} queries - Query items to look up schema from if required
   * @param {boolean} [filterSuperColumns] - Flag to indicate whether super columns should be removed from output schema
   * @param {MappingDirection} [mappingDirection] - Whether to use input/output params when mapping
   *
   * @returns {Interfaces.SchemaField[] | undefined} Relevant schema if found, undefined if not
   */
  const getEntitySchema = useCallback(
    (
      prevDataJob: Interfaces.PipelineJob,
      datasetMetas: DatasetMetaItemOutput[] = [],
      integrationTemplates: IntegrationTemplateItem[] = [],
      queries: QueryItemOutput[] = [],
      filterSuperColumns?: boolean,
      mappingDirection?: MappingDirection,
    ): Interfaces.SchemaField[] | undefined => {
      switch (prevDataJob.jobType) {
        case Enums.PipelineJobType.MAPPING: {
          const direction = prevDataJob.jobParams[
            mappingDirection || MappingDirection.OUTPUT
          ] as Interfaces.MappingSource;

          if (direction.type === Enums.MappingSourceType.DATASET) {
            return getDatasetSchema(
              direction.entityId,
              datasetMetas,
              filterSuperColumns,
            );
          } else if (direction.type === Enums.MappingSourceType.QUERY) {
            return getQuerySchema(direction.entityId, queries);
          } else if (direction.type === Enums.MappingSourceType.INTEGRATION) {
            return getIntegrationSchema(
              {
                integrationId: direction.entityId,
                integrationEndpointId: direction.baseEndpointId,
                integrationRequestEndpointId: direction.requestEndpointId,
                integrationCustomActionId: direction.customActionId,
              },
              integrationTemplates,
            );
          }

          break;
        }
        case Enums.PipelineJobType.DATASET: {
          return getDatasetSchema(
            (prevDataJob.jobParams as Interfaces.DatasetJobParams)
              .datasetMetaId,
            datasetMetas,
          );
        }
        case Enums.PipelineJobType.INTEGRATION: {
          return getIntegrationSchema(
            prevDataJob.jobParams as Interfaces.IntegrationJobParams,
            integrationTemplates,
            true,
          );
        }
        case Enums.PipelineJobType.QUERY: {
          return getQuerySchema(
            (prevDataJob.jobParams as Interfaces.QueryJobParams).queryId,
            queries,
            filterSuperColumns,
          );
        }
      }
    },
    [],
  );

  return {
    getLatestEntityId,
    getLastDataJob,
    getEntitySchema,
  };
};

export default usePipelineEntityHelper;
