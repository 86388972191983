import { Enums } from '@configur-tech/upit-core-types';
import {
  DuplicationMode,
  DuplicationTarget,
} from '@configur-tech/upit-core-types/lib/enums/ResourceDuplication';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import { RouteName } from '../../../enums/RouteName';
import useDuplication from '../../../hooks/duplication/UseDuplication';
import { StyledBodySubHeader, StyledDropdownWide } from '../../../main/theme';
import { DatasetCopyConfig } from '../../../services/duplication/DuplicationService';
import {
  resetStagesAndSubStages,
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../store/dataset-stage';
import { DatasetStructureSubStage } from '../../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

export interface DuplicationModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  entityId: string;
  entityName: string;
  entityType: EntityType;
}

const DUPLICATE_TYPE_OPTIONS = [
  {
    key: `duplicate-excluding-data`,
    text: 'Structure and configuration only',
    value: DuplicationMode.STRUCTURE,
  },
  {
    key: `duplicate-including-data`,
    text: 'Data, structure and configuration',
    value: DuplicationMode.STRUCTURE_AND_DATA,
  },
];

const DUPLICATE_ACTION = 'duplicate';

const DuplicationModal: FC<DuplicationModalProps> = ({
  setShowModal,
  entityId,
  entityName,
  entityType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { duplicateDataset } = useDuplication();

  const [datasetName, setDatasetName] = useState<string>(
    `${entityName} (copy)`,
  );
  const [duplicationMode, setDuplicationMode] = useState<DuplicationMode>();

  const processAction = async () => {
    if (!duplicationMode || !datasetName) {
      return;
    }

    const datasetConfig = {
      datasetIdToCopy: entityId,
      name: datasetName,
    } as DatasetCopyConfig;

    dispatch(showLoading({ text: 'Duplicating Dataset...' }));

    const duplication = await duplicateDataset(
      duplicationMode,
      entityType === EntityType.DATASET
        ? DuplicationTarget.DATASET
        : DuplicationTarget.PROJECT,
      datasetConfig,
    );

    if (!duplication) {
      return;
    }

    if (duplicationMode === Enums.DuplicationMode.STRUCTURE) {
      history.push(
        `${RouteName.DATASET_ITEM}/${duplication.datasetMeta._id}/${DUPLICATE_ACTION}`,
      );

      dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE));
      dispatch(
        updateActiveDatasetSubStage(DatasetStructureSubStage.CONFIRMATION),
      );
    } else {
      history.push(
        `${RouteName.DATASET_ITEM}/${duplication.datasetMeta._id}/${DUPLICATE_ACTION}`,
      );
      dispatch(resetStagesAndSubStages());
    }

    dispatch(hideLoading());
    dispatch(hideModal());
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderWrapper>
        <SC.Header>Duplicate Dataset</SC.Header>
      </SC.HeaderWrapper>

      <>
        <StyledBodySubHeader>Dataset Name</StyledBodySubHeader>
        <SC.ValueInput
          onChange={(e, data) => setDatasetName(data.value)}
          value={datasetName}
          placeholder={'Enter dataset name'}
          style={{ marginBottom: themeContext.margin.xxlarge }}
        />

        <StyledBodySubHeader>Select a duplication mode</StyledBodySubHeader>

        <StyledDropdownWide
          selectOnBlur={false}
          placeholder={'Select a duplication mode'}
          options={DUPLICATE_TYPE_OPTIONS}
          selection
          value={duplicationMode}
          onChange={(e, data) => setDuplicationMode(data.value)}
          style={{ marginTop: 0 }}
        />
      </>

      <SC.ActionButtonWrapper>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={!datasetName || !duplicationMode}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Duplicate'}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default DuplicationModal;
