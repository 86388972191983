import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_INTEGRATION_OAUTH'] || '';

// TODO - Should be accessed from upit-core-types when available
export interface IntegrationActionResponse {
  result: {
    message?: string;
    data?: unknown;
    error?: string;
  };
}

export default class OAuthService {
  public static async runAction(
    token: string,
    integrationRef: string,
    integrationConfigId: string,
    action: Enums.oAuthActionType,
    params?: Record<string, unknown>,
  ): Promise<IntegrationActionResponse> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<IntegrationActionResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      integrationRef,
      integrationConfigId,
      action,
      params,
    });

    return result?.data as IntegrationActionResponse;
  }
}
