import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChartService from '../../services/chart/ChartService';
import { AppThunk } from '../store';
import InitialCharts from './initial-state';

export interface ChartsState {
  data: ChartsItemOutput;
  loading: boolean;
  error: string | null;
}

export interface ChartItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Chart;
}
export interface ChartItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.ChartOutput;
}

export interface ChartsItemOutput {
  data: ChartItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const initialState: ChartsState = {
  data: InitialCharts,
  loading: false,
  error: null,
};

const ChartSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    fetchChartsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchChartsSuccess(state, action: PayloadAction<ChartsItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchChartsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchChartsStart, fetchChartsSuccess, fetchChartsFailure } =
  ChartSlice.actions;

export default ChartSlice.reducer;

export const fetchCharts =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchChartsStart());
      const fetched = await ChartService.getCharts(token, query);
      dispatch(fetchChartsSuccess(fetched.data as unknown as ChartsItemOutput));
    } catch (err) {
      dispatch(fetchChartsFailure((err as string).toString()));
    }
  };
