import { Interfaces, Enums } from '@configur-tech/upit-core-types';
import { ResourceType } from '../../enums/ResourceType';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_ORG_USAGE'] || '';

export interface UsageQuotas {
  staticResources: StaticResourcesQuotas;
  dynamicResources: DynamicResourcesQuotas;
}

export interface StaticResourcesQuotas {
  datasetMetas: Interfaces.Usage;
  dataPoints: Interfaces.Usage;
  projects: Interfaces.Usage;
  portals: Interfaces.Usage;
  apis: Interfaces.Usage;
  users: Interfaces.Usage;
  teams: Interfaces.Usage;
  snapshots: Interfaces.Usage;
  pipelines: Interfaces.Usage;
  customPipelineIntegrations: Interfaces.Usage;
}

export interface DynamicResourcesQuotas {
  apiCalls: DynamicResourceUsage;
  pipelineRuns: DynamicResourceUsage;
}

export interface DynamicResourceUsage extends Interfaces.Usage {
  period: Enums.QuotaPeriod;
  periodDates: {
    start: Date;
    end: Date;
  };
}

export interface UsageCheckResponse {
  metricName: ResourceType;
  canCreateMore: boolean;
  usagePercentage: number;
  isFeatureEnabled: number;
}

export default class UsageService {
  public static async getUsageMetrics(
    token: string,
    id: string,
  ): Promise<UsageQuotas> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      Interfaces.SuccessResponseBody<UsageQuotas> | Interfaces.ErrorResponseBody
    >(`${endpoint}/usage`, { _id: id });

    return result.data as UsageQuotas;
  }

  public static async checkResourceUsage(
    token: string,
    id: string,
    resourceType: ResourceType,
  ): Promise<UsageCheckResponse> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<UsageCheckResponse>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/check/${resourceType}`, {
      id: id,
    });
    return result.data as UsageCheckResponse;
  }
}
