import { faCog, faPercent } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { RouteName } from '../../enums/RouteName';
import { defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import { useHistory } from 'react-router-dom';

const OrganisationNavigation: FC = () => {
  const history = useHistory();
  return (
    <>
      <NavItem
        isActive={location.pathname === RouteName.ORGANISATION_ITEM}
        key={`organisation-overview`}
        onClick={() => {
          history.push(`${RouteName.ORGANISATION_ITEM}`);
        }}
      >
        <FontAwesomeIcon
          icon={faCog}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Overview'} />
      </NavItem>
      <NavItem
        isActive={
          location.pathname ===
          `${RouteName.ORGANISATION_ITEM}${RouteName.USAGE}`
        }
        key={`organisation-usage`}
        onClick={() => {
          history.push(`${RouteName.ORGANISATION_ITEM}${RouteName.USAGE}`);
        }}
      >
        <FontAwesomeIcon
          icon={faPercent}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Usage'} />
      </NavItem>
    </>
  );
};

export default OrganisationNavigation;
