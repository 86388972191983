import React, { FC, useContext, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums/EntityType';
import { DefaultPopupStyles } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import IconSelector from './IconSelector';
import * as SC from './styled';

const SELECTOR_WIDTH = 640;
const SELECTOR_HEIGHT = 300;
const SELECTOR_Z_INDEX = 500;

interface PopupIconSelectorProps {
  context: React.RefObject<HTMLInputElement>;
  open: boolean;
  setOpenStatus: (status: boolean) => void;
  entityType: EntityType;
  action: (icon) => void;
  selectedIcon?: string;
}

const PopupIconSelector: FC<PopupIconSelectorProps> = ({
  context,
  open,
  setOpenStatus,
  entityType,
  action,
  selectedIcon,
}) => {
  const themeContext = useContext(ThemeContext);
  const [updatedAvatar, setUpdatedAvatar] = useState<string | undefined>(
    selectedIcon,
  );

  return (
    <Popup
      context={context}
      open={open}
      onClose={() => setOpenStatus(false)}
      content={
        <>
          <IconSelector
            type={entityType}
            onChange={(icon) => setUpdatedAvatar(icon)}
            selectedIcon={selectedIcon}
            maxHeight={SELECTOR_HEIGHT}
          />
          <SC.ActionWrapper>
            <FeatureButton
              action={() => setOpenStatus(false)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={!updatedAvatar}
              action={() => {
                action(updatedAvatar);
                setOpenStatus(false);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Update Avatar'}
            />
          </SC.ActionWrapper>
        </>
      }
      position="bottom center"
      style={{
        ...DefaultPopupStyles,
        width: SELECTOR_WIDTH,
        zIndex: SELECTOR_Z_INDEX,
      }}
      flowing
    />
  );
};

export default PopupIconSelector;
