import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faLock,
  faLockOpen,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import { FORMULA_TYPES } from '../../../const/FormulaConst';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useDataset from '../../../hooks/dataset/UseDataset';
import useInput from '../../../hooks/input/useInput';
import usePortal from '../../../hooks/portal/UsePortal';
import { StyledText } from '../../../main/theme';
import { DatasetDeleteRowOutput, DatasetState } from '../../../store/dataset';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import validateValue from '../../../util/data-validator/DataValidator';
import clearSuperColumnValues from '../../../util/entries/ClearSuperColumnValues';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { UndoLink } from '../../NotificationAlert/styled';
import { ROW_ID_FIELD } from './CMSAddRowModal';
import * as SC from './styled';

export interface CMSMultiRowModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cmsId: string;
  rowIds?: string[];
  portalId?: string;
  deletionOnly?: boolean;
  forceRefresh?: () => void;
}

const CMSMultiRowModal: FC<CMSMultiRowModalProps> = ({
  setShowModal,
  rowIds,
  cmsId,
  portalId,
  deletionOnly,
  forceRefresh,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, datasetMetaAccessLevel, activeDataCollectionItem } =
    useDatasetMeta();
  const { addRow, editRows, removeRows } = useDataset();
  const { portalAccessLevel } = usePortal();
  const { getDynamicPreFilterValue, listPreFilters } = useCMS();
  const { buildInput } = useInput();
  const [rowEntries, setRowEntries] = useState<Record<string, string | number>>(
    {},
  );
  const [errorFields, setErrorFields] = useState<Record<string, boolean>>({});
  const [isDeleting, setIsDeleting] = useState<boolean>(deletionOnly || false);
  const [preFilters, setPreFilters] = useState<Interfaces.DynamicConditional[]>(
    [],
  );

  const isViewingDataset = location.pathname.includes('view');

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );

  const [unlockedFields, setUnlockedFields] = useState<number[]>([]);

  const getDatasetRowIds = (rowIds: string[], datasetMetaId: string) =>
    datasetState.data[datasetMetaId].entries
      .filter((e, i) => rowIds.includes(i.toString()))
      .map((e) => e.row_id) as number[];

  const recoverDatasetRows = async (
    deletedEntries: Record<string, unknown>[],
  ) => {
    if (
      deletedEntries &&
      datasetMeta?._id &&
      ((portalId && portalAccessLevel) || datasetMetaAccessLevel) &&
      activeDataCollectionItem?.schemaData
    ) {
      await addRow(
        datasetMeta?._id,
        datasetMetaAccessLevel || (portalAccessLevel as Enums.AccessLevel),
        clearSuperColumnValues(
          deletedEntries,
          activeDataCollectionItem.schemaData,
        ),
        cmsId,
        portalId,
        isViewingDataset,
        true,
        true,
      );

      // refresh entries
      if (forceRefresh) {
        forceRefresh();
      }
    }
  };

  const handleDelete = async () => {
    if (
      rowIds &&
      datasetMeta?._id &&
      ((portalId && portalAccessLevel) || datasetMetaAccessLevel)
    ) {
      dispatch(showLoading({ text: 'Deleting Row...' }));

      const deletedRowsData = (await removeRows(
        datasetMeta._id,
        datasetMetaAccessLevel || (portalAccessLevel as Enums.AccessLevel),
        getDatasetRowIds(rowIds, datasetMeta?._id),
        cmsId,
        portalId,
      )) as unknown as DatasetDeleteRowOutput;

      toast.success(
        <>
          <span>Successfully removed rows.</span>
          <UndoLink
            onClick={() => recoverDatasetRows(deletedRowsData.deletedEntries)}
          >
            Undo
          </UndoLink>
        </>,
      );

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  // Show modal
  useEffect(() => {
    if (datasetMeta?._id && activeDataCollectionItem?.schemaData?.length) {
      setShowModal(true);

      return () => setShowModal(false);
    }
  }, [
    activeDataCollectionItem?.schemaData?.length,
    datasetMeta?._id,
    setShowModal,
  ]);

  // Get Prefilters
  useEffect(() => {
    if (datasetMeta?._id) {
      setPreFilters(listPreFilters(datasetMeta._id));
    }
  }, [datasetMeta?._id, listPreFilters]);

  const handleChange = useCallback(
    (field: string, value: unknown) => {
      const schemaField = activeDataCollectionItem?.schemaData?.find(
        (f) => f.name === field,
      );

      if (schemaField) {
        const dataType = schemaField.dataValidation?.dataValidationType;
        const isValid = dataType
          ? validateValue(
              dataType,
              value,
              schemaField.dataValidation?.constraints,
            )
          : true;

        const cloned = cloneDeep(rowEntries);
        const clonedErrors = cloneDeep(errorFields);

        cloned[field] = value;

        if (isValid) {
          if (clonedErrors[field]) {
            delete clonedErrors[field];
            setErrorFields(clonedErrors);
          }
        } else {
          clonedErrors[field] = true;
          setErrorFields(clonedErrors);
        }

        setRowEntries(cloned);
      }
    },
    [activeDataCollectionItem?.schemaData, errorFields, rowEntries],
  );

  const handleUpdate = async () => {
    if (rowIds && rowEntries && datasetMeta?._id) {
      dispatch(showLoading({ text: 'Updating Rows...' }));

      const updateData = getDatasetRowIds(rowIds, datasetMeta?._id).map(
        (row_id) => ({ row_id: row_id, ...rowEntries }),
      );

      await editRows(
        datasetMeta._id,
        updateData,
        cmsId,
        portalId,
        isViewingDataset,
      );

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  const toggleFieldLock = (rowIndex: number, fieldName: string) => {
    if (unlockedFields.includes(rowIndex)) {
      const filteredRowEntries = Object.fromEntries(
        Object.entries(rowEntries).filter(([key]) => !key.includes(fieldName)),
      );
      setRowEntries(filteredRowEntries);

      const filteredErrorFields = Object.fromEntries(
        Object.entries(errorFields).filter(([key]) => !key.includes(fieldName)),
      );
      setErrorFields(filteredErrorFields);

      setUnlockedFields(unlockedFields.filter((f) => f !== rowIndex));
    } else {
      handleChange(fieldName, '');
      setUnlockedFields([...unlockedFields, rowIndex]);
    }
  };

  return (
    <SC.Wrapper>
      {isDeleting && (
        <SC.FormWrapper>
          <SC.HeaderWrapper>
            <SC.Header>Deleting Rows</SC.Header>
          </SC.HeaderWrapper>

          <StyledText>Are you sure you wish to delete these rows?</StyledText>

          <SC.ActionButtonWrapper buttonMargin={true}>
            <FeatureButton
              action={() =>
                deletionOnly ? dispatch(hideModal()) : setIsDeleting(false)
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              action={handleDelete}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.red}
              text={`Delete Rows`}
            />
          </SC.ActionButtonWrapper>
        </SC.FormWrapper>
      )}

      {!isDeleting && (
        <>
          <SC.FormWrapper>
            <SC.HeaderWrapper>
              <SC.Header>Updating Rows</SC.Header>
              <FeatureButton
                action={() => setIsDeleting(true)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.red}
                icon={
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color={themeContext.colors.system.white}
                  />
                }
              />
            </SC.HeaderWrapper>

            <StyledText>
              Unlock the fields you wish to edit and then hit the Update button
              below. Easy.
            </StyledText>

            <SC.InputsWrapper>
              {activeDataCollectionItem?.schemaData.reduce(
                (acc: React.ReactElement[], field, index) => {
                  if (field.name !== ROW_ID_FIELD) {
                    const type = field?.dataValidation?.dataValidationType;
                    const val = rowEntries[field.name];

                    if (type) {
                      if (FORMULA_TYPES.includes(type)) {
                        return acc;
                      }

                      const preFilterValue = getDynamicPreFilterValue(
                        preFilters,
                        field.fieldId,
                        true,
                      );

                      acc.push(
                        <SC.InputWrapper key={`input-field-${field.name}`}>
                          <SC.InputItem>
                            <SC.InputTitleWrapper>
                              <SC.InputHeader>{field.name}</SC.InputHeader>
                              {field.dataValidation?.constraints
                                ?.isRequired && (
                                <SC.InputHeaderRequired>
                                  Required
                                </SC.InputHeaderRequired>
                              )}
                            </SC.InputTitleWrapper>

                            {buildInput(
                              field,
                              type,
                              val,
                              handleChange,
                              errorFields[field.name],
                              FORMULA_TYPES.includes(type) ||
                                !unlockedFields.includes(index),
                              cmsId ? preFilterValue : undefined,
                            )}
                          </SC.InputItem>
                          <FeatureButton
                            isDisabled={
                              field.dataValidation?.permissions
                                ?.allowManualEdits === false
                            }
                            action={() => {
                              toggleFieldLock(index, field.name);
                            }}
                            size={FeatureButtonSize.EXTRA_SMALL}
                            color={themeContext.colors.general.blue}
                            icon={
                              <FontAwesomeIcon
                                icon={
                                  unlockedFields.includes(index)
                                    ? faLockOpen
                                    : faLock
                                }
                                color={themeContext.colors.system.white}
                              />
                            }
                          />
                        </SC.InputWrapper>,
                      );
                    }
                  }

                  return acc;
                },
                [],
              )}
            </SC.InputsWrapper>

            <SC.ActionButtonWrapper buttonMargin={true}>
              <FeatureButton
                action={() => dispatch(hideModal())}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Cancel'}
              />
              <FeatureButton
                isDisabled={
                  Object.keys(errorFields).length > 0 || !unlockedFields.length
                }
                action={handleUpdate}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.green}
                text={`Update Rows`}
              />
            </SC.ActionButtonWrapper>
          </SC.FormWrapper>
        </>
      )}
    </SC.Wrapper>
  );
};

export default CMSMultiRowModal;
