import { Enums } from '@configur-tech/upit-core-types';
import { truncate } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import { DefaultPopupStyles } from '../../main/theme';
import { updateActiveConnection } from '../../store/project-stage';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const TRUNCATE_LIMIT = 65;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const PortalCMSNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { portal } = usePortal();

  if (!portal?.connectionItems?.cms) {
    return null;
  }

  return (
    <>
      <NavigationItems>
        {portal.connectionItems.cms.map((cms, i) => {
          return (
            <NavItem
              lightTheme={true}
              isActive={false}
              key={`cms-${cms.name}-${i}`}
              onClick={() => {
                dispatch(updateActiveConnection(cms));
                history.push(
                  `${RouteName.PORTAL}/${portal?._id}/${Enums.ConnectionType.CMS}/${cms._id}`,
                );
              }}
            >
              <Popup
                content={cms.name}
                disabled={cms.name.length <= TRUNCATE_LIMIT}
                position="right center"
                style={DefaultPopupStyles}
                trigger={
                  <div>
                    <NavTitle
                      lightTheme={true}
                      text={truncate(cms.name, {
                        length: TRUNCATE_LIMIT,
                      })}
                    />
                  </div>
                }
              />
            </NavItem>
          );
        })}
      </NavigationItems>
    </>
  );
};

export default PortalCMSNavigation;
