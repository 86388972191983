import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import APIKeyService from '../../services/api/APIKeyService';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useApiKeyResult {
  getApiKeys: (
    projectId?: string,
    query?: Record<string, unknown>,
  ) => Promise<Interfaces.ApiKeyOutput[]>;
  addApiKey: (
    apiObj: Interfaces.ApiKey,
    projectId?: string,
  ) => Promise<Interfaces.ApiKeyOutput>;
  editApiKey: (
    apiObj: Interfaces.ApiKeyOutput,
    projectId?: string,
  ) => Promise<Interfaces.ApiKeyOutput>;
  removeApiKey: (
    apiKeyId: string,
    projectId?: string,
  ) => Promise<Interfaces.ApiKeyOutput>;
}

const useApiKey = (): useApiKeyResult => {
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const getApiKeys = useCallback(
    async (
      projectId?: string,
      query?: Record<string, unknown>,
    ): Promise<Interfaces.ApiKeyOutput[]> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && query) {
        result = await APIKeyService.getAPIKeys(token, projectId, query);
      }

      return result?.data;
    },
    [getAccessTokenSilently],
  );

  const addApiKey = useCallback(
    async (
      apiKeyObj: Interfaces.ApiKey,
      projectId?: string,
    ): Promise<Interfaces.ApiKeyOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiKeyObj && loggedInUser) {
        result = await APIKeyService.postAPIKey(
          token,
          apiKeyObj,
          loggedInUser._id,
          projectId,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const editApiKey = useCallback(
    async (
      apiKeyObj: Interfaces.ApiKeyOutput,
      projectId?: string,
    ): Promise<Interfaces.ApiKeyOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiKeyObj && loggedInUser) {
        result = await APIKeyService.putAPIKey(
          token,
          apiKeyObj,
          loggedInUser._id,
          projectId,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const removeApiKey = useCallback(
    async (apiKeyId: string, projectId?: string) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiKeyId && loggedInUser) {
        result = await APIKeyService.deleteAPIKey(
          token,
          apiKeyId,
          loggedInUser._id,
          projectId,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  return {
    getApiKeys,
    addApiKey,
    editApiKey,
    removeApiKey,
  };
};

export default useApiKey;
