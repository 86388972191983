import { cloneDeep, isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataSamplePagination from '../../components/DataSample/DataSamplePagination';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType } from '../../enums';
import { RouteName } from '../../enums/RouteName';
import UseNotificationAlert from '../../hooks/notification-alert/UseNotificationAlert';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { NotificationAlertItemOutput } from '../../services/notification-alert/NotificationAlertService';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import { DEFAULT_PAGE_SIZE } from '../CMS/CMSPage';
import { PageInner } from '../styled';

// TODO - change this to dataset when available
const SEARCH_FIELD = 'channel';

interface NotificationFetchParams {
  [SEARCH_FIELD]: string;
  pageNum: number;
}

export const generatePageArray = (totalCount: number) =>
  Array.from({ length: totalCount }, (_, i) => i + 1);

const NotificationAlertsPage: FC = () => {
  const location = useLocation();
  const { getNotificationAlerts } = UseNotificationAlert();

  const notificationAlertsState = useSelector(
    (state: RootState) => state.notificationAlerts,
  );
  const notificationAlerts: NotificationAlertItemOutput[] =
    notificationAlertsState.data.data;

  const [loadingNotifications, setLoadingNotification] =
    useState<boolean>(false);

  const [fetchParams, setFetchParams] = useState<NotificationFetchParams>({
    [SEARCH_FIELD]: '',
    pageNum: 0,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<
    NotificationFetchParams | undefined
  >();

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get('search');

    setFetchParams((state) => ({
      ...state,
      [SEARCH_FIELD]:
        searchParams === null
          ? ''
          : decodeURI(searchParams as string).toString(),
    }));
  }, [location.search]);

  // Fetch Notification Alerts
  useEffect(() => {
    if (isEqual(fetchParams, lastFetchedParams) || loadingNotifications) {
      return;
    }
    (async () => {
      setLoadingNotification(true);
      setLastFetchedParams(fetchParams);

      const formattedSearchParam = cloneDeep(fetchParams);
      formattedSearchParam.pageNum = fetchParams.pageNum + 1;
      !formattedSearchParam[[SEARCH_FIELD]] &&
        delete formattedSearchParam[[SEARCH_FIELD]];

      await getNotificationAlerts(formattedSearchParam);
    })();
  }, [
    fetchParams,
    getNotificationAlerts,
    lastFetchedParams,
    loadingNotifications,
  ]);

  useEffect(() => {
    setLoadingNotification(false);
  }, [notificationAlerts]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.DATASETS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Notifications'}
          image={getDefaultAvatar(EntityType.DATASET)}
          title={'Get Notified When Things Change'}
        />
        <Page>
          <PageInner>
            <>
              <EntityFilter
                baseRoute={`${RouteName.DATASETS}${RouteName.NOTIFICATIONS}`}
                title={'Search Notifications'}
                search={fetchParams[SEARCH_FIELD]}
                inputPlaceholder={'Search by dataset name'}
                totalResultCount={
                  notificationAlertsState.data.pagination.totalCount
                }
                filteredResultCount={notificationAlerts?.length}
                loading={loadingNotifications}
              />

              {notificationAlerts?.map((item) => {
                const notification = item.entity;
                return <p>{notification.name}</p>;
              })}

              <DataSamplePagination
                canNextPage={
                  !!notificationAlertsState.data.pagination.nextPageNum
                }
                canPreviousPage={
                  !!notificationAlertsState.data.pagination.prevPageNum
                }
                gotoPage={(pageIndex: number) =>
                  setFetchParams({
                    ...fetchParams,
                    pageNum: pageIndex,
                  })
                }
                nextPage={() =>
                  setFetchParams({
                    ...fetchParams,
                    pageNum: fetchParams.pageNum + 1,
                  })
                }
                pageCount={notificationAlertsState.data.pagination.totalPages}
                pageIndex={fetchParams.pageNum}
                pageOptions={generatePageArray(
                  notificationAlertsState.data.pagination.totalPages,
                )}
                pageSize={DEFAULT_PAGE_SIZE}
                previousPage={() =>
                  setFetchParams({
                    ...fetchParams,
                    pageNum: fetchParams.pageNum - 1,
                  })
                }
                totalRows={notificationAlertsState.data.pagination.totalCount}
              />
            </>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default NotificationAlertsPage;
