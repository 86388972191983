import {
  faArrowLeft,
  faListUl,
  faPlusSquare,
  faSearch,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { faDatabase } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useQuery from '../../hooks/query/UseQuery';
import {
  resetStagesAndSubStages,
  updateActivePortalStage,
  updateActivePortalSubStage,
} from '../../store/portal-stage';
import {
  PortalAggregationSubStage,
  PortalStage,
} from '../../store/portal-stage/initial-state';

import { resetQuery } from '../../store/query';
import { RootState } from '../../store/rootReducer';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import usePortal from '../../hooks/portal/UsePortal';
import { RouteName } from '../../enums';

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const NavIcons = {
  [PortalAggregationSubStage.NAME]: faWrench,
  [PortalAggregationSubStage.DATASETS]: faDatabase,
  [PortalAggregationSubStage.AGGREGATE]: faSearch,
};

const PortalQueryNavigation: FC = () => {
  const dispatch = useDispatch();
  const { query } = useQuery();
  const { portal } = usePortal();
  const history = useHistory();

  const portalStageState = useSelector((state: RootState) => state.portalStage);
  const aggStage = portalStageState.stages[PortalStage.AGGREGATION];
  const activeSubStage = aggStage.activeSubStage;

  return (
    <NavigationItems>
      <>
        {!activeSubStage && (
          <>
            <NavItem
              lightTheme={true}
              key={`query-view`}
              onClick={() => dispatch(resetStagesAndSubStages())}
              isActive={!activeSubStage}
            >
              <FontAwesomeIcon
                icon={faListUl}
                color={
                  !activeSubStage
                    ? defaultTheme.colors.system.white
                    : defaultTheme.colors.system.offBlack
                }
              />
              <NavTitle
                lightTheme={true}
                text={'View Queries'}
                isActive={!activeSubStage}
              />
            </NavItem>
            <NavItem
              lightTheme={true}
              key={`query-create`}
              onClick={() => {
                // Reset query and stage data
                dispatch(resetStagesAndSubStages());
                dispatch(resetQuery());
                dispatch(updateActivePortalStage(PortalStage.AGGREGATION));
                dispatch(
                  updateActivePortalSubStage(PortalAggregationSubStage.NAME),
                );
              }}
              isActive={!!activeSubStage && !query?._id}
            >
              <FontAwesomeIcon
                icon={faPlusSquare}
                color={
                  !!activeSubStage && !query?._id
                    ? defaultTheme.colors.system.white
                    : defaultTheme.colors.system.offBlack
                }
              />
              <NavTitle
                lightTheme={true}
                text={'Create New Query'}
                isActive={!!activeSubStage && !query?._id}
              />
            </NavItem>
          </>
        )}
      </>

      {activeSubStage && (
        <BackNavigationItem
          className={'back'}
          key={`aggregation-stage-back`}
          onClick={() => {
            dispatch(resetStagesAndSubStages());
            history.push(`${RouteName.PORTAL}/${portal?._id}/query/`);
          }}
          isActive={false}
          lightTheme={true}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={defaultTheme.colors.system.offBlack}
          />

          <NavTitle text={'Back To Queries'} lightTheme={true} />
        </BackNavigationItem>
      )}

      {Object.values(aggStage.subStages).map((subStage) => {
        const subStageName = subStage.subStage as PortalAggregationSubStage;

        if (activeSubStage) {
          return (
            <>
              <NavItem
                lightTheme={true}
                disabled={
                  (subStageName === PortalAggregationSubStage.DATASETS &&
                    !query?.name) ||
                  (subStageName === PortalAggregationSubStage.AGGREGATE &&
                    !query?._id)
                }
                key={`aggregation-stage-${subStageName}`}
                onClick={() => {
                  dispatch(updateActivePortalSubStage(subStageName));
                }}
                isActive={activeSubStage === subStageName}
              >
                <FontAwesomeIcon
                  icon={NavIcons[subStageName]}
                  color={
                    activeSubStage === subStageName
                      ? defaultTheme.colors.system.white
                      : defaultTheme.colors.system.offBlack
                  }
                />

                <NavTitle
                  isActive={activeSubStage === subStageName}
                  text={subStage.title}
                  lightTheme={true}
                />
              </NavItem>
            </>
          );
        }
      })}
    </NavigationItems>
  );
};

export default PortalQueryNavigation;
