import { Enums } from '@configur-tech/upit-core-types';
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useChart from '../../hooks/chart/UseChart';
import { StyledText } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

export interface EmptyPanelContentProps {
  areaSize: Enums.AreaSize;
  id: string;
  panelId: string;
  areaId: string;
}

const EmptyPanelContent: FC<EmptyPanelContentProps> = ({
  areaSize,
  id,
  areaId,
  panelId,
}) => {
  const { chart, updateChartContent, chartSaving } = useChart();
  const themeContext = useContext(ThemeContext);

  const addContentToPanel = () => {
    const cloned = cloneDeep(chart);

    const areaIndex = cloned.areas.findIndex((a) => a._id === areaId);
    const panelIndex = cloned.areas[areaIndex].panels.findIndex(
      (p) => p._id === panelId,
    );

    cloned.areas[areaIndex].panels[panelIndex] = {
      ...cloned.areas[areaIndex].panels[panelIndex],
      panelType: Enums.PanelType.TEXT,
    };

    updateChartContent(cloned);
  };

  const { setNodeRef } = useDroppable({
    id: id as UniqueIdentifier,
    data: { areaId: areaId, panelId: panelId },
  });

  return (
    <SC.EmptyPanel
      areaSize={areaSize}
      ref={setNodeRef}
      aria-label="Droppable region"
    >
      <StyledText>
        Drag a graph from the sidebar or click to add new content to this area.
      </StyledText>

      <SC.PanelButtons>
        <FeatureButton
          action={addContentToPanel}
          size={FeatureButtonSize.WIDE_SMALL}
          color={themeContext.colors.general.blue}
          text={'Add Content'}
          isDisabled={chartSaving}
        />
      </SC.PanelButtons>
    </SC.EmptyPanel>
  );
};

export default EmptyPanelContent;
