import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ConfigureAccessStage from '../../components/DatasetStages/DatasetConfigureStages/1-access/ConfigureAccessStage';
import ConfigureSnapshotStage from '../../components/DatasetStages/DatasetConfigureStages/2-snapshot/ConfigureSnapshotStage';
import ConfigureAdvancedStage from '../../components/DatasetStages/DatasetConfigureStages/3-advanced/ConfigureAdvancedStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import Page from '../../main/Page';
import { DatasetConfigureSubStage } from '../../store/dataset-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getStageTitle,
  getSubStageContent,
} from '../../util/stage-content/StageHeaderContent';

const DatasetItemConfiguration: FC = () => {
  const { datasetMeta } = useDatasetMeta();
  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = Enums.DatasetStage.CONFIGURATION;
  const activeSubStage = datasetStageState.stages[activeStage].activeSubStage;

  const stageTitle = getStageTitle(activeStage);
  const subStageContent = getSubStageContent(
    activeStage,
    activeSubStage,
    datasetMeta,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case DatasetConfigureSubStage.ACCESS:
        return <ConfigureAccessStage />;
      case DatasetConfigureSubStage.SNAPSHOTS:
        return <ConfigureSnapshotStage />;
      case DatasetConfigureSubStage.ADVANCED:
        return <ConfigureAdvancedStage />;
      default:
        return <ConfigureAccessStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default DatasetItemConfiguration;
