import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GroupItem } from '../../components/Aggregation/AggregationGroup';
import { SortItem } from '../../components/Aggregation/AggregationSort';
import { fetchQuerySuccess } from '../../store/query';
import useFilter from '../filter/UseFilter';
import useQuery from './UseQuery';

const INITIAL_QUERY_OPTIONS = {
  fieldItems: [],
  groupItems: [],
  sortItems: [],
  displayOrderItems: [],
  listIds: [],
};

export interface QueryFieldWithDsmName extends Interfaces.QueryField {
  datasetMetaName: string;
}

export interface QueryOptions {
  fieldItems: QueryFieldWithDsmName[];
  groupItems: GroupItem[];
  sortItems: SortItem[];
  displayOrderItems: SortItem[];
  listIds: string[];
}

interface useQueryBuilderResult {
  queryOptions: QueryOptions;
  // Helpers
  handleGroupChange: (items: GroupItem[]) => void;
  handleSortChange: (items: SortItem[]) => void;
  handleSubtotalChange: (checked: boolean) => void;
  handleTotalChange: (checked: boolean) => void;
  handleDisplayOrderChange: (items: SortItem[]) => void;
  // Sorters
  sortGroupItems: (
    orderedItems: GroupItem[],
    allItems: GroupItem[],
  ) => GroupItem[];
  sortSortItems: (orderedItems: SortItem[], allItems: SortItem[]) => SortItem[];
  // Utils
  checkForMissingFields: () => Interfaces.QueryField[];
  buildQueryOptions: () => QueryOptions;
}

const useQueryBuilder = (): useQueryBuilderResult => {
  const dispatch = useDispatch();

  const { query, queryAccessLevel } = useQuery();
  const { replaceDatasetFilters, filterFieldToConditionalFieldMap } =
    useFilter();

  const [queryOptions, setQueryOptions] = useState<QueryOptions>(
    INITIAL_QUERY_OPTIONS,
  );
  const [groupLength, setGroupLength] = useState<number>(
    query?.queryParams.groups.length || 0,
  );

  /**
   * Sorts group items based on their appearance in the Query
   *
   * @param {GroupItem[]} orderedItems - Selected groups to retain the order of
   * @param {GroupItem[]} allItems - All available groups
   *
   * @returns {GroupItem[]} Sorted group items
   */
  const sortGroupItems = (
    orderedItems: GroupItem[] = [],
    allItems: GroupItem[],
  ): GroupItem[] => {
    const orderArr = orderedItems.concat(
      allItems.filter(
        (item) =>
          !orderedItems.find((queryGroup) => queryGroup.alias === item.alias),
      ),
    );

    return allItems
      .slice()
      .sort(
        (a, b) =>
          orderArr.findIndex((go) => go.field === a.field) -
          orderArr.findIndex((go) => go.field === b.field),
      );
  };

  /**
   * Sorts sort items based on their appearance in the Query
   *
   * @param {SortItem[]} orderedItems - Selected sorts to retain the order of
   * @param {SortItem[]} allItems - All available sorts
   *
   * @returns {SortItem[]} Sorted group items
   */
  const sortSortItems = (
    orderedItems: SortItem[] = [],
    allItems: SortItem[],
  ): SortItem[] => {
    const orderArr = orderedItems.concat(
      allItems.filter(
        (item) =>
          !orderedItems.find((querySort) => querySort.alias === item.alias),
      ),
    );

    return allItems
      .slice()
      .sort(
        (a, b) =>
          orderArr.findIndex((go) => go.field === a.field) -
          orderArr.findIndex((go) => go.field === b.field),
      );
  };

  /**
   * Checks that all fields in a Query exist in their respective DatasetMetas
   *
   * @returns {Interfaces.QueryField[]} Array of missing fields
   */
  const checkForMissingFields = useCallback((): Interfaces.QueryField[] => {
    if (!query?._id) {
      return [];
    }

    return (query?.queryParams.fields || []).reduce(
      (acc: Interfaces.QueryField[], queryField) => {
        const datasetDetails = query.additionalDetails?.find(
          (detail) => detail.datasetMetaId === queryField.datasetMetaId,
        );

        const schemaField = datasetDetails?.schemaData.find(
          (sf) => sf.fieldId === queryField.field,
        );

        if (schemaField) {
          return acc;
        }

        return [...acc, queryField];
      },
      [],
    );
  }, [query?._id, query?.additionalDetails, query?.queryParams.fields]);

  /**
   * Builds query options from available fields present in Query
   *
   * {QueryOptions} Available query options
   */
  const buildQueryOptions = useCallback((): QueryOptions => {
    if (!query?._id) {
      return INITIAL_QUERY_OPTIONS;
    }

    const isMultiple =
      query.queryParams.aggregationType === Enums.AggregationType.MULTIPLE;

    const unorderedOptions = query.queryParams.fields.reduce(
      (acc: QueryOptions, queryField) => {
        const datasetDetails = query.additionalDetails?.find(
          (detail) => detail.datasetMetaId === queryField.datasetMetaId,
        );

        if (!datasetDetails) {
          return acc;
        }

        // Update filters
        if (query.queryParams.filters?.length) {
          const queryFilters = { ...query.queryParams }.filters.map(
            (filterField) =>
              filterFieldToConditionalFieldMap(
                filterField as Interfaces.StandardFilterField,
              ),
          );

          replaceDatasetFilters(query._id, queryFilters);
        }

        // Build query options
        const itemResult = {
          fieldItems: [...acc.fieldItems],
          groupItems: [...acc.groupItems],
          sortItems: [...acc.sortItems],
          displayOrderItems: [...acc.displayOrderItems],
          listIds: [...acc.listIds],
        };

        const schemaField = datasetDetails.schemaData
          ?.filter((f) => !(f as unknown as Record<string, unknown>).isMeasure)
          .find(
            (f) =>
              f.fieldId === queryField.field || f.name === queryField.field,
          );

        if (schemaField) {
          itemResult.fieldItems.push({
            field: schemaField.name,
            fieldId: schemaField.fieldId,
            dataValidation: schemaField.dataValidation,
            datasetMetaName: datasetDetails.name,
            datasetMetaId: datasetDetails.datasetMetaId,
            alias:
              isMultiple && queryField?.alias === schemaField.name
                ? `${schemaField.name} - ${datasetDetails.name}`
                : queryField.alias,
          } as QueryFieldWithDsmName);

          const listValues =
            schemaField.dataValidation?.constraints?.listValues;

          if (listValues && !Array.isArray(listValues)) {
            itemResult.listIds.push(listValues);
          }
        }

        itemResult.groupItems.push({
          field: queryField.field,
          alias: queryField.alias || queryField.field,
          datasetMetaId: datasetDetails.datasetMetaId,
          direction:
            query.queryParams.groups.find(
              (g) =>
                g.field === queryField.field &&
                g.datasetMetaId == queryField.datasetMetaId,
            )?.direction || Enums.ProjectionSortOrder.ASCENDING,
          active: !!query.queryParams.groups.find(
            (gr) =>
              gr.field === queryField.field &&
              gr.datasetMetaId == queryField.datasetMetaId,
          ),
        });

        itemResult.sortItems.push({
          field: queryField.field,
          alias: queryField.alias || queryField.field,
          datasetMetaId: datasetDetails.datasetMetaId,
          direction:
            query.queryParams.sort.find(
              (s) =>
                s.alias === queryField.alias &&
                s.datasetMetaId == queryField.datasetMetaId,
            )?.direction || Enums.ProjectionSortOrder.ASCENDING,
          active: !!query.queryParams.sort.find(
            (s) =>
              s.alias === queryField.alias &&
              s.datasetMetaId == queryField.datasetMetaId,
          ),
        });

        itemResult.displayOrderItems.push({
          field: queryField.field,
          alias: queryField.alias || queryField.field,
          datasetMetaId: datasetDetails.datasetMetaId,
        } as SortItem);

        return itemResult;
      },
      INITIAL_QUERY_OPTIONS,
    );

    return {
      ...unorderedOptions,
      groupItems: sortGroupItems(
        query.queryParams.groups as GroupItem[],
        unorderedOptions.groupItems,
      ),
      sortItems: sortSortItems(
        query.queryParams.sort as SortItem[],
        unorderedOptions.sortItems,
      ),
    };
  }, [
    filterFieldToConditionalFieldMap,
    query?._id,
    query?.additionalDetails,
    query?.queryParams,
    replaceDatasetFilters,
  ]);

  /**
   * Handles a group change interaction
   *
   * @returns {void}
   */
  const handleGroupChange = useCallback(
    (items: GroupItem[] = []) => {
      const clonedItems = cloneDeep(items);
      const cloned = cloneDeep(query);

      if (groupLength === 0 && cloned.queryParams.supercolumns.length) {
        cloned.queryParams.supercolumns = [];
        setGroupLength(cloned.queryParams.groups.length);
      }

      cloned.queryParams.groups = clonedItems
        .filter((g) => g.active)
        .map((g) => {
          delete g.active;
          if (!g.alias) {
            g.alias = g.field;
          }
          return g;
        });

      if (!cloned.queryParams.groups.length) {
        // Remove all fields if no group length
        cloned.queryParams.supercolumns = [];

        cloned.queryParams.sort = cloned.queryParams.sort.filter(
          (s) => !s.isMeasure,
        );

        // Add all fields to displayOrder
        cloned.queryParams.displayOrder = queryOptions.displayOrderItems;
      } else {
        // Remove non-usable sorts
        cloned.queryParams.sort = cloned.queryParams.sort.filter((sort) =>
          sort.isMeasure
            ? true
            : !!cloned.queryParams.groups.find(
                (g) => g.alias === sort.alias || g.field === sort.field,
              ),
        );

        // Remove any supercolumns that are don't have a matching alias in the groups array
        const groupsAliasMap = cloned.queryParams.groups.map(
          (group) => group.alias,
        );
        const groupsFieldsMap = cloned.queryParams.groups.map(
          (group) => group.field,
        );

        const measureMap = cloned.queryParams.measures.map(
          (measure) => measure.alias,
        );

        const groupsAliasAndFields = groupsAliasMap.concat(
          groupsFieldsMap,
          measureMap,
        );

        cloned.queryParams.supercolumns =
          cloned.queryParams.supercolumns?.filter((supercolumn) => {
            if (
              supercolumn.textTransformation &&
              supercolumn.textTransformation.params.fields
            ) {
              const textTransformationFieldMap =
                supercolumn.textTransformation.params.fields.map(
                  (field) => field,
                );
              if (
                textTransformationFieldMap.length === 0 ||
                textTransformationFieldMap.every((alias) =>
                  groupsAliasAndFields.includes(alias),
                )
              ) {
                return true;
              }
            }
            if (supercolumn.formula) {
              const formulaFieldMap = supercolumn.formula.map(
                (formula) => formula.value.field,
              );

              if (
                formulaFieldMap.length === 0 ||
                formulaFieldMap.some((alias) =>
                  groupsAliasAndFields.includes(alias),
                )
              ) {
                return true;
              }
            }

            return (
              groupsAliasAndFields.includes(
                supercolumn.textTransformation?.params?.field,
              ) ||
              groupsAliasAndFields.includes(
                supercolumn.dateConversion?.params?.field,
              ) ||
              groupsAliasAndFields.includes(supercolumn.field) ||
              groupsAliasAndFields.includes(supercolumn.alias)
            );
          });

        // Add in any new groups to displayOrder and remove any non-usable ones
        cloned.queryParams.displayOrder = [
          ...(cloned.queryParams.displayOrder || []),
          ...cloned.queryParams.groups
            .filter(
              (groupItem) =>
                !cloned.queryParams.displayOrder?.find(
                  (displayOrderItem) =>
                    displayOrderItem.alias === groupItem.alias,
                ),
            )
            .map((groupItem) => ({
              alias: groupItem.alias,
              field: groupItem.field,
              datasetMetaId: groupItem.datasetMetaId,
            })),
        ].filter(
          (displayOrder) =>
            !!cloned.queryParams.measures.find(
              (m) => m.alias === (displayOrder.alias || displayOrder.field),
            ) ||
            !!cloned.queryParams.groups.find(
              (g) =>
                g.alias === (displayOrder.alias || displayOrder.field) ||
                g.field === displayOrder.field,
            ) ||
            !!cloned.queryParams.supercolumns.find(
              (m) => m.alias === (displayOrder.alias || displayOrder.field),
            ),
        );
      }

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [
      dispatch,
      groupLength,
      query,
      queryAccessLevel,
      queryOptions.displayOrderItems,
    ],
  );

  /**
   * Handles a sort change interaction
   *
   * @returns {void}
   */
  const handleSortChange = useCallback(
    (items: SortItem[] = []) => {
      const clonedItems = cloneDeep(items);
      const cloned = cloneDeep(query);

      cloned.queryParams.sort = clonedItems
        .filter((s) => s.active)
        .map((s) => {
          delete s.active;
          return s;
        });

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [dispatch, query, queryAccessLevel],
  );

  /**
   * Handles a subtotal change interaction
   *
   * @returns {void}
   */
  const handleSubtotalChange = useCallback(
    (checked: boolean) => {
      const cloned = cloneDeep(query);

      cloned.queryParams.includeGrandTotals = false;
      cloned.queryParams.includeSubtotals = checked;

      // If checked remove sorts
      if (checked) {
        cloned.queryParams.sort = [];
        cloned.queryParams.groups.map((group) => {
          group.direction = Enums.ProjectionSortOrder.DESCENDING;
          return group;
        });
      }

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [dispatch, query, queryAccessLevel],
  );

  /**
   * Handles a total change interaction
   *
   * @returns {void}
   */
  const handleTotalChange = useCallback(
    (checked: boolean) => {
      const cloned = cloneDeep(query);

      cloned.queryParams.includeSubtotals = false;
      cloned.queryParams.includeGrandTotals = checked;

      // If checked remove sorts
      if (checked) {
        cloned.queryParams.sort = [];
        cloned.queryParams.groups.map((group) => {
          group.direction = Enums.ProjectionSortOrder.DESCENDING;
          return group;
        });
      }

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [dispatch, query, queryAccessLevel],
  );

  /**
   * Handles a display order change interaction
   *
   * @returns {void}
   */
  const handleDisplayOrderChange = useCallback(
    (items: Interfaces.SortField[] = []) => {
      const clonedItems = cloneDeep(items);
      const cloned = cloneDeep(query);

      cloned.queryParams.displayOrder = clonedItems.map((g) => {
        return {
          alias: g.alias || g.field,
          field: g.alias,
          datasetMetaId: g.datasetMetaId,
        };
      });

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [dispatch, query, queryAccessLevel],
  );

  useEffect(() => {
    setQueryOptions(buildQueryOptions());
  }, [buildQueryOptions]);

  return {
    queryOptions,
    // Helpers
    handleGroupChange,
    handleSortChange,
    handleSubtotalChange,
    handleTotalChange,
    handleDisplayOrderChange,
    // Sorters
    sortGroupItems,
    sortSortItems,
    // Utils
    checkForMissingFields,
    buildQueryOptions,
  };
};

export default useQueryBuilder;
