import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ApiConnectionIcon from '../../assets/icons/connections/api-connection-icon.svg';
import DataSample from '../../components/DataSample/DataSample';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import { updateActiveConnection } from '../../store/project-stage';

export interface APIConfigurationAdditional
  extends Interfaces.APIConnectionConfiguration {
  name: string;
  description: string;
  allowedMethods: string[];
}

const ID_FIELD = '_id';
const NAME_FIELD = 'API Name';
const DESCRIPTION_FIELD = 'Description';
const ALLOWED_METHODS_FIELD = 'Allowed Methods';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: ALLOWED_METHODS_FIELD },
];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const PortalAPIsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { portalId } = useParams();

  const { portal, getPortal } = usePortal();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  // Fetch portal if on portal page and not in state
  useEffect(() => {
    if (
      location.pathname.includes(RouteName.PORTAL) &&
      portalId &&
      portal &&
      portal._id !== portalId
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Portal...' }));
        await getPortal(portalId, true);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getPortal, portal, portal?._id, portalId]);

  useEffect(() => {
    if (portal?.connectionItems?.api) {
      const tableData: SampleDataRow[] = [];

      portal?.connectionItems?.api.map((connection) => {
        const config = connection.configuration as APIConfigurationAdditional;
        tableData.push({
          [ID_FIELD]: { value: connection._id },
          [NAME_FIELD]: { value: config.name },
          [DESCRIPTION_FIELD]: { value: config.description },
          [ALLOWED_METHODS_FIELD]: { value: config.allowedMethods.join(', ') },
        });
      });

      setTableData(tableData);
    }
  }, [portal]);

  return (
    <>
      <PrimaryNavigationContainer
        route={RouteName.PORTAL}
        connectionType={Enums.ConnectionType.API}
      />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={ApiConnectionIcon}
          title={'APIs'}
        />

        <Page>
          <PageInner>
            <StageBodyText>
              Select a workspace below to access datasets and queries.
            </StageBodyText>

            <TableWrapper>
              <DataSample
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (apiId: string) => {
                    dispatch(
                      updateActiveConnection(
                        portal?.connectionItems?.api.find(
                          (c) => c._id === apiId,
                        ),
                      ),
                    );
                    history.push(
                      `${RouteName.PORTAL}/${portal?._id}/${Enums.ConnectionType.API}/${apiId}`,
                    );
                  },
                }}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalAPIsPage;
