import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserService, {
  UserItem,
  UserItemOutput,
} from '../../services/user/UserService';
import { AppThunk } from '../store';
import InitialUser from './initial-user';

type User = UserItem | UserItemOutput;

export interface UserState {
  data: User;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: InitialUser,
  loading: false,
  error: null,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Fetch
    fetchUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    createUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
  createUserStart,
  createUserSuccess,
  createUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
} = UserSlice.actions;

export default UserSlice.reducer;

export const fetchUser =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchUserStart());
      const fetched = await UserService.getUser(token, id);
      dispatch(fetchUserSuccess(fetched));
    } catch (err) {
      dispatch(fetchUserFailure((err as string).toString()));
    }
  };

export const createUser =
  (token: string, user: Interfaces.User, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserStart());
      const created = await UserService.postUser(token, user, userId);
      dispatch(createUserSuccess(created));
    } catch (err) {
      dispatch(createUserFailure((err as string).toString()));
    }
  };

export const updateUser =
  (
    token: string,
    user: Partial<Interfaces.UserOutput>,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateUserStart());
      const updated = await UserService.putUser(token, user, userId);
      dispatch(updateUserSuccess(updated));
    } catch (err) {
      dispatch(updateUserFailure((err as string).toString()));
    }
  };

export const deleteUser =
  (token: string, userToDeleteId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteUserStart());
      const deleted = await UserService.deleteUser(
        token,
        userToDeleteId,
        userId,
      );
      dispatch(deleteUserSuccess(deleted));
    } catch (err) {
      dispatch(deleteUserFailure((err as string).toString()));
    }
  };
