import 'gantt-task-react/dist/index.css';
import { ReactElement } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import 'semantic-ui-css/semantic.min.css';
import Auth0ProviderWithHistory from '../auth/auth0-provider-with-history';
import store from '../store/store';
import AppContainer from './AppContainer';
import Theme from './theme';

function App(): ReactElement {
  return (
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithHistory>
          <Theme>
            <AppContainer />
          </Theme>
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>
  );
}

export default App;
