import { Interfaces } from '@configur-tech/upit-core-types';
import { ValueDataType } from '@configur-tech/upit-core-types/lib/enums';
import { SampleDataRow } from '../../interfaces/SampleData';
import validateValue from '../data-validator/DataValidator';

const buildTableDataFromValidation = (
  schema: Interfaces.Field[],
  failedEntries: Interfaces.InvalidEntry[],
): SampleDataRow[] => {
  return failedEntries.map((item) => {
    const rowObj = Object.entries(item.entry).reduce((rowObj, [k, v]) => {
      return {
        ...rowObj,
        [k]: {
          value: v,
        },
      };
    }, {});

    rowObj['row_id'] = { value: item.rowId.toString() };

    item.fields.forEach((f) => {
      if (!rowObj[f.fieldName]) {
        rowObj[f.fieldName] = {
          value: undefined,
        };
      }

      rowObj[f.fieldName].isEditable = true;
      rowObj[f.fieldName].isError = true;
      rowObj[f.fieldName].isComplete = false;

      // Set updated value if required
      if (typeof f.updatedValue !== 'undefined') {
        rowObj[f.fieldName].value = f.updatedValue;

        // Validate updated value
        const schemaField = schema.find((s) => s.name === f.fieldName);
        const dataType =
          schemaField?.dataValidation?.dataValidationType ||
          ValueDataType.UNKNOWN;
        const isValid = validateValue(
          dataType,
          typeof f.updatedValue !== 'undefined'
            ? f.updatedValue
            : f.originalValue,
          schemaField?.dataValidation?.constraints,
        );

        rowObj[f.fieldName].isError = !isValid;
        rowObj[f.fieldName].isComplete = isValid;
      }
    });

    return rowObj;
  });
};

export default buildTableDataFromValidation;
