import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  NotificationAlertOutputWithDetails,
  NotificationAlertWithDetails,
} from '../../store/notification-alert';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_NOTIFICATION_ALERTS'] || '';

export interface NotificationAlertItem {
  accessLevel: Enums.AccessLevel;
  entity: NotificationAlertWithDetails;
}

export interface NotificationAlertItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: NotificationAlertOutputWithDetails;
}

export interface NotificationAlertItemsOutput {
  data: NotificationAlertItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class NotificationAlertService {
  public static async getNotificationAlerts(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<NotificationAlertItemsOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<NotificationAlertItemsOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getNotificationAlert(
    token: string,
    id: string,
  ): Promise<NotificationAlertItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<NotificationAlertItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as NotificationAlertItemOutput;
  }

  public static async postNotificationAlert(
    token: string,
    notification: Interfaces.NotificationAlert,
    userId: string,
  ): Promise<NotificationAlertItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<NotificationAlertItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: notification, userId });

    return result?.data as NotificationAlertItemOutput;
  }

  public static async putNotificationAlert(
    token: string,
    userId: string,
    notification?: Interfaces.NotificationAlertOutput,
    restoreId?: string,
  ): Promise<NotificationAlertItemOutput> {
    const ah = new HttpHelper(token);
    let result;

    if (!restoreId && notification) {
      result = await ah.put<
        | Interfaces.SuccessResponseBody<NotificationAlertItemOutput>
        | Interfaces.ErrorResponseBody
      >(`${endpoint}/${notification._id}`, {
        data: notification,
        userId,
      });
    } else if (restoreId) {
      result = await ah.put<
        | Interfaces.SuccessResponseBody<NotificationAlertItemOutput>
        | Interfaces.ErrorResponseBody
      >(`${endpoint}/undo/${restoreId}`, {});
    }

    return result?.data as NotificationAlertItemOutput;
  }

  public static async deleteNotificationAlert(
    token: string,
    notificationId: string,
    userId: string,
  ): Promise<NotificationAlertItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<NotificationAlertItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${notificationId}`, userId);

    return result?.data as NotificationAlertItemOutput;
  }
}
