import UserIconMan001 from '../../assets/icons/user/001-man.svg';
import UserIconMan002 from '../../assets/icons/user/002-man.svg';
import UserIconMan003 from '../../assets/icons/user/003-man.svg';
import UserIconMan004 from '../../assets/icons/user/004-man.svg';
import UserIconMan005 from '../../assets/icons/user/005-man.svg';
import UserIconMan006 from '../../assets/icons/user/006-man.svg';
import UserIconMan007 from '../../assets/icons/user/007-man.svg';
import UserIconMan008 from '../../assets/icons/user/008-man.svg';
import UserIconMan009 from '../../assets/icons/user/009-man.svg';
import UserIconWoman010 from '../../assets/icons/user/010-woman.svg';
import UserIconWoman011 from '../../assets/icons/user/011-woman.svg';
import UserIconWoman012 from '../../assets/icons/user/012-woman.svg';
import UserIconWoman013 from '../../assets/icons/user/013-woman.svg';
import UserIconWoman014 from '../../assets/icons/user/014-woman.svg';
import UserIconWoman015 from '../../assets/icons/user/015-woman.svg';
import UserIconWoman016 from '../../assets/icons/user/016-woman.svg';
import UserIconWoman017 from '../../assets/icons/user/017-woman.svg';
import UserIconMan018 from '../../assets/icons/user/018-man.svg';
import UserIconMan019 from '../../assets/icons/user/019-man.svg';
import UserIconMan020 from '../../assets/icons/user/020-man.svg';
import UserIconMan021 from '../../assets/icons/user/021-man.svg';
import UserIconMan022 from '../../assets/icons/user/022-man.svg';
import UserIconMan023 from '../../assets/icons/user/023-man.svg';
import UserIconMan024 from '../../assets/icons/user/024-man.svg';
import UserIconMan025 from '../../assets/icons/user/025-man.svg';
import UserIconMan026 from '../../assets/icons/user/026-man.svg';
import UserIconWoman027 from '../../assets/icons/user/027-woman.svg';
import UserIconWoman028 from '../../assets/icons/user/028-woman.svg';
import UserIconWoman029 from '../../assets/icons/user/029-woman.svg';
import UserIconWoman030 from '../../assets/icons/user/030-woman.svg';
import UserIconWoman031 from '../../assets/icons/user/031-woman.svg';
import UserIconWoman032 from '../../assets/icons/user/032-woman.svg';
import UserIconWoman033 from '../../assets/icons/user/033-woman.svg';
import UserIconWoman034 from '../../assets/icons/user/034-woman.svg';
import UserIconMan035 from '../../assets/icons/user/035-man.svg';
import UserIconMan036 from '../../assets/icons/user/036-man.svg';
import UserIconMan037 from '../../assets/icons/user/037-man.svg';
import UserIconMan038 from '../../assets/icons/user/038-man.svg';
import UserIconMan039 from '../../assets/icons/user/039-man.svg';
import UserIconMan040 from '../../assets/icons/user/040-man.svg';
import UserIconMan041 from '../../assets/icons/user/041-man.svg';
import UserIconMan042 from '../../assets/icons/user/042-man.svg';
import UserIconWoman043 from '../../assets/icons/user/043-woman.svg';
import UserIconWoman044 from '../../assets/icons/user/044-woman.svg';
import UserIconWoman045 from '../../assets/icons/user/045-woman.svg';
import UserIconWoman046 from '../../assets/icons/user/046-woman.svg';
import UserIconWoman047 from '../../assets/icons/user/047-woman.svg';
import UserIconWoman048 from '../../assets/icons/user/048-woman.svg';
import UserIconWoman049 from '../../assets/icons/user/049-woman.svg';
import UserIconWoman050 from '../../assets/icons/user/050-woman.svg';
import UserIconCat from '../../assets/icons/user/cat-user-icon.svg';
import UserIconChicken from '../../assets/icons/user/chicken-user-icon.svg';
import UserIconDog from '../../assets/icons/user/dog-user-icon.svg';
import UserIconFox from '../../assets/icons/user/fox-user-icon.svg';
import UserIconLion from '../../assets/icons/user/lion-user-icon.svg';
import UserIconMonkey from '../../assets/icons/user/monkey-user-icon.svg';
import UserIconMouse from '../../assets/icons/user/mouse-user-icon.svg';
import UserIconOctopus from '../../assets/icons/user/octopus-user-icon.svg';
import UserIconOwl from '../../assets/icons/user/owl-user-icon.svg';
import UserIconPanda from '../../assets/icons/user/panda-user-icon.svg';
import UserIconPig from '../../assets/icons/user/pig-user-icon.svg';
import UserIconSquid from '../../assets/icons/user/squid-user-icon.svg';
import UserIconUnicorn from '../../assets/icons/user/unicorn-user-icon.svg';

export enum UserIcon {
  CAT = 'cat',
  CHICKEN = 'chicken',
  DOG = 'dog',
  FOX = 'fox',
  LION = 'lion',
  MONKEY = 'monkey',
  MOUSE = 'mouse',
  OCTOPUS = 'octopus',
  OWL = 'owl',
  PANDA = 'panda',
  PIG = 'pig',
  SQUID = 'squid',
  UNICORN = 'unicorn',
  MAN_001 = 'man-001',
  MAN_002 = 'man-002',
  MAN_003 = 'man-003',
  MAN_004 = 'man-004',
  MAN_005 = 'man-005',
  MAN_006 = 'man-006',
  MAN_007 = 'man-007',
  MAN_008 = 'man-008',
  MAN_009 = 'man-009',
  WOMAN_010 = 'woman-010',
  WOMAN_011 = 'woman-011',
  WOMAN_012 = 'woman-012',
  WOMAN_013 = 'woman-013',
  WOMAN_014 = 'woman-014',
  WOMAN_015 = 'woman-015',
  WOMAN_016 = 'woman-016',
  WOMAN_017 = 'woman-017',
  MAN_018 = 'man-018',
  MAN_019 = 'man-019',
  MAN_020 = 'man-020',
  MAN_021 = 'man-021',
  MAN_022 = 'man-022',
  MAN_023 = 'man-023',
  MAN_024 = 'man-024',
  MAN_025 = 'man-025',
  MAN_026 = 'man-026',
  WOMAN_027 = 'woman-027',
  WOMAN_028 = 'woman-028',
  WOMAN_029 = 'woman-029',
  WOMAN_030 = 'woman-030',
  WOMAN_031 = 'woman-031',
  WOMAN_032 = 'woman-032',
  WOMAN_033 = 'woman-033',
  WOMAN_034 = 'woman-034',
  MAN_035 = 'man-035',
  MAN_036 = 'man-036',
  MAN_037 = 'man-037',
  MAN_038 = 'man-038',
  MAN_039 = 'man-039',
  MAN_040 = 'man-040',
  MAN_041 = 'man-041',
  MAN_042 = 'man-042',
  WOMAN_043 = 'woman-043',
  WOMAN_044 = 'woman-044',
  WOMAN_045 = 'woman-045',
  WOMAN_046 = 'woman-046',
  WOMAN_047 = 'woman-047',
  WOMAN_048 = 'woman-048',
  WOMAN_049 = 'woman-049',
  WOMAN_050 = 'woman-050',
}

const UserIconMap: Record<UserIcon, string> = {
  [UserIcon.CAT]: UserIconCat,
  [UserIcon.CHICKEN]: UserIconChicken,
  [UserIcon.DOG]: UserIconDog,
  [UserIcon.FOX]: UserIconFox,
  [UserIcon.LION]: UserIconLion,
  [UserIcon.MONKEY]: UserIconMonkey,
  [UserIcon.MOUSE]: UserIconMouse,
  [UserIcon.OCTOPUS]: UserIconOctopus,
  [UserIcon.OWL]: UserIconOwl,
  [UserIcon.PANDA]: UserIconPanda,
  [UserIcon.PIG]: UserIconPig,
  [UserIcon.SQUID]: UserIconSquid,
  [UserIcon.UNICORN]: UserIconUnicorn,
  [UserIcon.MAN_001]: UserIconMan001,
  [UserIcon.MAN_002]: UserIconMan002,
  [UserIcon.MAN_003]: UserIconMan003,
  [UserIcon.MAN_004]: UserIconMan004,
  [UserIcon.MAN_005]: UserIconMan005,
  [UserIcon.MAN_006]: UserIconMan006,
  [UserIcon.MAN_007]: UserIconMan007,
  [UserIcon.MAN_008]: UserIconMan008,
  [UserIcon.MAN_009]: UserIconMan009,
  [UserIcon.WOMAN_010]: UserIconWoman010,
  [UserIcon.WOMAN_011]: UserIconWoman011,
  [UserIcon.WOMAN_012]: UserIconWoman012,
  [UserIcon.WOMAN_013]: UserIconWoman013,
  [UserIcon.WOMAN_014]: UserIconWoman014,
  [UserIcon.WOMAN_015]: UserIconWoman015,
  [UserIcon.WOMAN_016]: UserIconWoman016,
  [UserIcon.WOMAN_017]: UserIconWoman017,
  [UserIcon.MAN_018]: UserIconMan018,
  [UserIcon.MAN_019]: UserIconMan019,
  [UserIcon.MAN_020]: UserIconMan020,
  [UserIcon.MAN_021]: UserIconMan021,
  [UserIcon.MAN_022]: UserIconMan022,
  [UserIcon.MAN_023]: UserIconMan023,
  [UserIcon.MAN_024]: UserIconMan024,
  [UserIcon.MAN_025]: UserIconMan025,
  [UserIcon.MAN_026]: UserIconMan026,
  [UserIcon.WOMAN_027]: UserIconWoman027,
  [UserIcon.WOMAN_028]: UserIconWoman028,
  [UserIcon.WOMAN_029]: UserIconWoman029,
  [UserIcon.WOMAN_030]: UserIconWoman030,
  [UserIcon.WOMAN_031]: UserIconWoman031,
  [UserIcon.WOMAN_032]: UserIconWoman032,
  [UserIcon.WOMAN_033]: UserIconWoman033,
  [UserIcon.WOMAN_034]: UserIconWoman034,
  [UserIcon.MAN_035]: UserIconMan035,
  [UserIcon.MAN_036]: UserIconMan036,
  [UserIcon.MAN_037]: UserIconMan037,
  [UserIcon.MAN_038]: UserIconMan038,
  [UserIcon.MAN_039]: UserIconMan039,
  [UserIcon.MAN_040]: UserIconMan040,
  [UserIcon.MAN_041]: UserIconMan041,
  [UserIcon.MAN_042]: UserIconMan042,
  [UserIcon.WOMAN_043]: UserIconWoman043,
  [UserIcon.WOMAN_044]: UserIconWoman044,
  [UserIcon.WOMAN_045]: UserIconWoman045,
  [UserIcon.WOMAN_046]: UserIconWoman046,
  [UserIcon.WOMAN_047]: UserIconWoman047,
  [UserIcon.WOMAN_048]: UserIconWoman048,
  [UserIcon.WOMAN_049]: UserIconWoman049,
  [UserIcon.WOMAN_050]: UserIconWoman050,
};

export default UserIconMap;
