import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import {
  ConnectionType,
  UserType,
} from '@configur-tech/upit-core-types/lib/enums';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { Normalize } from 'styled-normalize';
import Loading from '../components/Loading/Loading';
import { ErrorType } from '../components/Modal/error/ErrorModal';
import Modal, { ModalTypes } from '../components/Modal/Modal';
import NotificationPanel from '../components/NotificationPanel/NotificationPanel';
import SystemNavigationContainer from '../components/SystemNavigationContainer/SystemNavigationContainer';
import { V2_URL } from '../const/SiteUrl';
import { PortalConnections } from '../enums/Connections';
import { QuotaLimits } from '../enums/QuotaLimits';
import { RouteName } from '../enums/RouteName';
import useLoggedInUser from '../hooks/logged-in-user/UseLoggedInUser';
import useOrganisation from '../hooks/organisation/UseOrganisation';
import usePortal from '../hooks/portal/UsePortal';
import useSiteWideBanner from '../hooks/sitewide-banner/useSitewideBanner';
import useUsage from '../hooks/usage/useUsage';
import useUser from '../hooks/user/UseUser';
import PortalUserRoutes from '../routes/PortalUserRoutes';
import Routes from '../routes/Routes';
import { MeItemOutput } from '../services/user/UserService';
import { hideLoading, showLoading } from '../store/loading';
import { fetchLoggedInUserSuccess } from '../store/logged-in-user';
import { showModal } from '../store/modal';
import { fetchOrganisationSuccess } from '../store/organisation';
import { RootState } from '../store/rootReducer';
import { defaultTheme } from './theme';

const USER_FETCH_RETRY_LIMIT = 3;
const TOAST_DEFAULT_TIMEOUT = 5000;

const Wrapper = styled.div<{ hideNavigation: boolean; showBanner?: boolean }>`
  background-color: ${defaultTheme.colors.system.white};
  display: grid;
  grid-template-rows: 1fr;
  ${({ showBanner }) =>
    showBanner ? ` height: calc(100vh - 58px);` : `height: 100vh;`};
  ${({ hideNavigation }) =>
    hideNavigation
      ? `grid-template-columns: 70px 0px 1fr;`
      : `grid-template-columns: 70px 250px 1fr;`};
`;

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { getLoggedInUser, loggedInUser } = useLoggedInUser();
  const { portalId } = useParams();
  const { getMe } = useUser();
  const { organisation } = useOrganisation();
  const { portal, portalAccessLevel } = usePortal();
  const { isSitewideBanner, setSitewideBanner } = useSiteWideBanner();
  const { datapointQuota, checkDatapointQuota, checkResourceUsage } =
    useUsage();

  const location = useLocation().pathname;

  const [notificationPanelOpen, setNotificationPanelOpen] =
    useState<boolean>(false);
  const [fetchingUserCount, setFetchingUserCount] = useState<number>(0);
  const [retryingLoggedInUser, setRetryingLoggedInUser] =
    useState<boolean>(false);
  const [fetchingLoggedInUser, setFetchingLoggedInUser] =
    useState<boolean>(false);
  const [userData, setUserData] = useState<MeItemOutput>();

  const modalState = useSelector((state: RootState) => state.modal);
  const loadingState = useSelector((state: RootState) => state.loading);

  const loggedInUserState = useSelector(
    (state: RootState) => state.loggedInUser,
  );

  const organisationState = useSelector(
    (state: RootState) => state.organisation,
  );

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const isPortalHomepage =
    location.includes(RouteName.PORTAL) &&
    ![...Object.values(PortalConnections), 'query'].some((c) =>
      location.includes(c),
    );

  const isPortalForm =
    location.includes(RouteName.PORTAL) && location.includes('form');

  const isFormsHomepage =
    location.includes(RouteName.FORMS) &&
    projectStageState.activeStage === Enums.ConnectionType.FORM &&
    !projectStageState.stages[ConnectionType.FORM].activeSubStage;

  useEffect(() => {
    if (location.includes(RouteName.PORTAL)) {
      if (portalId) {
        window.location.href = `${V2_URL}${portalId}`;
      } else {
        window.location.href = `${V2_URL}`;
      }
    }
  }, [location, portalId]);

  const shouldHideNavigation =
    location === RouteName.WELCOME ||
    isPortalHomepage ||
    isPortalForm ||
    (loggedInUser?.userType === UserType.PORTAL &&
      !location.includes(RouteName.PORTAL)) ||
    location === RouteName.APIS ||
    location === RouteName.NOTIFICATIONS ||
    location === RouteName.NOTIFICATION_CENTRE ||
    isFormsHomepage;

  // Fetch user
  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      !loggedInUser?._id &&
      !fetchingLoggedInUser
    ) {
      (async () => {
        try {
          setFetchingLoggedInUser(true);
          dispatch(showLoading({ text: 'Loading Account...' }));
          const accountData = await getMe();
          setUserData(accountData as unknown as MeItemOutput);
          dispatch(hideLoading());
        } catch (error) {
          dispatch(hideLoading());
          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.ERROR,
              forceOpen: true,
              additionalProps: {
                errorType: ErrorType.NO_USER_FOUND,
              },
            }),
          );
        }
      })();
    }
  }, [
    dispatch,
    fetchingLoggedInUser,
    getLoggedInUser,
    getMe,
    isAuthenticated,
    loggedInUser?._id,
    user,
  ]);

  const retryUserLookup = useCallback(() => {
    setTimeout(() => {
      setFetchingLoggedInUser(false);
      setFetchingUserCount(fetchingUserCount + 1);
      setRetryingLoggedInUser(false);
    }, 2000);
  }, [fetchingUserCount]);

  // No user found
  useEffect(() => {
    if (loggedInUserState.error) {
      if (!retryingLoggedInUser && fetchingUserCount < USER_FETCH_RETRY_LIMIT) {
        // Try again in case of signup delay
        setRetryingLoggedInUser(true);
        return retryUserLookup();
      }

      if (fetchingUserCount === USER_FETCH_RETRY_LIMIT) {
        dispatch(hideLoading());
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.ERROR,
            forceOpen: true,
            additionalProps: {
              errorType: ErrorType.NO_USER_FOUND,
            },
          }),
        );
      }
    }
  }, [
    dispatch,
    fetchingUserCount,
    loggedInUserState.error,
    retryUserLookup,
    retryingLoggedInUser,
  ]);

  useEffect(() => {
    if (userData?.entity && userData?.entity._id) {
      dispatch(
        fetchLoggedInUserSuccess({
          accessLevel: userData.accessLevel,
          entity: userData.entity,
        }),
      );
      if (userData.entity.organisation) {
        dispatch(
          fetchOrganisationSuccess({
            accessLevel: userData.accessLevel,
            entity: userData.entity.organisation,
          }),
        );
      }
    }
  }, [dispatch, userData?.accessLevel, userData?.entity]),
    // Get usage and show banner if approaching quota
    useEffect(() => {
      if (
        loggedInUser?._id &&
        organisation?._id &&
        loggedInUser.userType !== UserType.PORTAL &&
        organisation?.subscriptionDetails.status ===
          Enums.SubscriptionStatus.ACTIVE
      ) {
        (async () => {
          checkDatapointQuota(loggedInUser.organisationId);
          if (
            (datapointQuota?.percentageUsed as number) >= QuotaLimits.WARNING
          ) {
            setSitewideBanner(true);
          }
        })();
      }
    }, [
      loggedInUser?._id,
      loggedInUser?.organisationId,
      organisation?._id,
      organisation?.subscriptionDetails.status,
      checkResourceUsage,
      setSitewideBanner,
      loggedInUser?.userType,
      dispatch,
      datapointQuota?.percentageUsed,
      checkDatapointQuota,
    ]);

  // No organisation found
  useEffect(() => {
    if (organisationState.error) {
      dispatch(hideLoading());
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.NO_ORGANISATION_FOUND,
          },
        }),
      );
    }
  }, [dispatch, organisationState.error]);

  // Non-active subscription
  useEffect(() => {
    if (
      loggedInUser?._id &&
      organisation?._id &&
      organisation?.subscriptionDetails.status !==
        Enums.SubscriptionStatus.ACTIVE
    ) {
      dispatch(hideLoading());
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.NON_ACTIVE_SUBSCRIPTION,
          },
        }),
      );
    }
  }, [
    dispatch,
    loggedInUser?._id,
    organisation?._id,
    organisation?.subscriptionDetails.status,
  ]);

  // Needs to complete onboarding
  useEffect(() => {
    if (
      loggedInUser?._id &&
      organisation?._id &&
      (!organisation?.signupComplete || !loggedInUser?.signupComplete)
    ) {
      dispatch(hideLoading());
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ONBOARDING,
          forceOpen: true,
        }),
      );
    }
  }, [
    dispatch,
    loggedInUser?._id,
    loggedInUser?.signupComplete,
    organisation?._id,
    organisation?.signupComplete,
  ]);

  if (!isAuthenticated && !isLoading) {
    const domain = process.env['REACT_APP_DOMAIN'] || window.location.pathname;

    loginWithRedirect({
      screen_hint: location === RouteName.SIGNUP ? 'signup' : 'login',
      appState: {
        redirectUrl: window.location.href.replace(domain, ''),
      },
    });
  }

  // Portal User - Limited Routes
  if (
    (loggedInUser?._id && loggedInUser.userType === UserType.PORTAL) ||
    (location.includes(RouteName.PORTAL) &&
      portal?._id &&
      portalAccessLevel !== Enums.AccessLevel.NONE)
  ) {
    return (
      <Wrapper
        className="App"
        hideNavigation={shouldHideNavigation}
        showBanner={isSitewideBanner}
      >
        <Normalize />
        <Modal
          visible={modalState.visible}
          modal={modalState.modal}
          additionalProps={modalState.additionalProps}
          style={modalState.style}
          fullScreen={modalState.fullScreen}
          forceOpen={modalState.forceOpen}
        />

        <Loading
          loading={isLoading || loadingState.loading}
          image={loadingState.image}
          text={loadingState.text}
        />

        {loggedInUser?._id &&
          organisation?._id &&
          organisation?.subscriptionDetails.status ===
            Enums.SubscriptionStatus.ACTIVE && (
            <>
              <SystemNavigationContainer />
              <PortalUserRoutes />
            </>
          )}

        <ToastContainer autoClose={TOAST_DEFAULT_TIMEOUT} />
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper
        className="App"
        hideNavigation={shouldHideNavigation}
        showBanner={isSitewideBanner}
      >
        <Normalize />
        <Modal
          visible={modalState.visible}
          modal={modalState.modal}
          additionalProps={modalState.additionalProps}
          style={modalState.style}
          fullScreen={modalState.fullScreen}
          forceOpen={modalState.forceOpen}
        />
        <Loading
          loading={isLoading || loadingState.loading}
          image={loadingState.image}
          text={loadingState.text}
        />
        {/* TODO - TEMP FIX FOR QUOTA BANNER */}
        {/* {(datapointQuota?.isDatapointQuotaLimit ||
          (datapointQuota?.percentageUsed as number) >=
            QuotaLimits.WARNING) && (
          <SitewideBanner
            message={
              datapointQuota?.isDatapointQuotaLimit
                ? 'You have reached your datapoint limit, Please contact your Account Manager to upgrade.'
                : 'You are approaching your datapoint limit, Please contact your Account Manager to upgrade.'
            }
            alertStatus={
              datapointQuota?.isDatapointQuotaLimit
                ? AlertStatus.ERROR
                : AlertStatus.WARNING
            }
            isLoading={isLoading}
          />
        )} */}
        {loggedInUser?._id &&
          organisation?._id &&
          organisation?.subscriptionDetails.status ===
            Enums.SubscriptionStatus.ACTIVE && (
            <>
              <SystemNavigationContainer
                toggleNotificationPanel={() =>
                  setNotificationPanelOpen(!notificationPanelOpen)
                }
              />
              <NotificationPanel
                notificationPanelOpen={notificationPanelOpen}
                hideNotificationPanel={() => setNotificationPanelOpen(false)}
                siteWideBannerActive={isSitewideBanner}
              />
              <Routes />
            </>
          )}
        <ToastContainer autoClose={TOAST_DEFAULT_TIMEOUT} />
      </Wrapper>
    </>
  );
};

export default AppContainer;
