import AvatarIconMap from '../icon-helpers/AvatarMap';
import UserIconMap from '../icon-helpers/UserIconMap';

const resolveAvatar = (avatar: string): string => {
  return avatar.includes('http')
    ? avatar
    : { ...UserIconMap, ...AvatarIconMap }[avatar] || '';
};

export default resolveAvatar;
