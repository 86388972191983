import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SearchQuery } from '../../interfaces/Search';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface IntegrationTemplateItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.IntegrationTemplate;
}

export interface IntegrationTemplateOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.IntegrationTemplateOutput;
}

export interface IntegrationTemplatesOutput {
  data: IntegrationTemplateOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const endpoint = process.env['REACT_APP_CRUD_INTEGRATION_TEMPLATE'] || '';

export default class IntegrationTemplateService {
  public static async getIntegrationTemplates(
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum = 1,
    limit?: number,
    orderBy?: Record<string, number>,
  ): Promise<
    | Interfaces.SuccessResponseBody<IntegrationTemplatesOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<IntegrationTemplatesOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { ...query, searchQuery, pageNum, limit, orderBy });
  }

  public static async getIntegrationTemplate(
    token: string,
    id: string,
  ): Promise<IntegrationTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<IntegrationTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as IntegrationTemplateOutput;
  }

  public static async postIntegrationTemplate(
    token: string,
    integrationTemplate: Interfaces.IntegrationTemplate,
    userId: string,
  ): Promise<IntegrationTemplateOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<IntegrationTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: integrationTemplate, userId });

    return result?.data as IntegrationTemplateOutput;
  }

  public static async putIntegrationTemplate(
    token: string,
    integrationTemplate: Interfaces.IntegrationTemplateOutput,
    userId: string,
  ): Promise<IntegrationTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<IntegrationTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${integrationTemplate._id}`, {
      data: integrationTemplate,
      userId,
    });

    return result?.data as IntegrationTemplateOutput;
  }

  public static async deleteIntegrationTemplate(
    token: string,
    integrationTemplateId: string,
    userId: string,
  ): Promise<IntegrationTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<IntegrationTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${integrationTemplateId}`, userId);

    return result?.data as IntegrationTemplateOutput;
  }
}
