import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { DefaultPopupStyles } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../store/rootReducer';
import DroppableComp from '../DragAndDrop/DroppableComp';
import { AggregateStage } from '../ProjectItem/ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import AggregationOptionButton from './AggregationOptionButton';
import { SortItem } from './AggregationSort';
import * as SC from './styled';

export interface GroupItem {
  field: string;
  alias: string;
  datasetMetaId: string;
  direction: Enums.ProjectionSortOrder;
  active?: boolean;
  isMeasure?: boolean;
}

interface AggregationGroupProps {
  groupItems: GroupItem[];
  onChange: (sortItems: GroupItem[]) => void;
  disabled?: boolean;
}

const AggregationGroup: FC<AggregationGroupProps> = ({
  groupItems,
  onChange,
  disabled,
}) => {
  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [orderedGroupItems, setOrderedGroupItems] = useState<GroupItem[]>([]);

  useEffect(() => {
    if (groupItems.length) {
      setOrderedGroupItems(groupItems.filter((item) => item.active));
    }
  }, [groupItems]);

  if (!groupItems?.length) {
    return null;
  }

  const onCheckedChange = (itemAlias: string, checked: boolean) => {
    const cloned: GroupItem[] = cloneDeep(groupItems);
    const item = cloned.find(
      (item) => item.alias === itemAlias || item.field === itemAlias,
    );

    if (item) {
      item.active = checked;
      onChange(cloned);
    }
  };

  const onDirChange = (
    itemAlias: string,
    direction: Enums.ProjectionSortOrder,
  ) => {
    const cloned: SortItem[] = cloneDeep(groupItems);
    const item = cloned.find(
      (item) => item.alias === itemAlias || item.field === itemAlias,
    );
    if (item) {
      item.direction = direction;
      onChange(cloned);
    }
  };

  const reorderItems = (list, startIndex, endIndex): GroupItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result as GroupItem[];
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const groupedItems = reorderItems(
      orderedGroupItems,
      result.source.index,
      result.destination.index,
    );

    setOrderedGroupItems(groupedItems);
    onChange(groupedItems);
  };

  const buildGroupItemButton = (
    item: GroupItem,
    index: number,
    dragContextProvided?,
  ) => {
    return (
      <Popup
        content={
          datasetMetas?.find((d) => d.entity._id === item.datasetMetaId)?.entity
            .name
        }
        position="left center"
        style={DefaultPopupStyles}
        hideOnScroll={true}
        trigger={
          <div>
            {dragContextProvided && (
              <SC.GroupButton
                disabled={disabled}
                active={item.active}
                ref={dragContextProvided.innerRef}
                {...dragContextProvided.draggableProps}
                {...dragContextProvided.dragHandleProps}
              >
                <AggregationOptionButton
                  type={AggregateStage.GROUP}
                  item={item}
                  index={index}
                  onCheckedChange={onCheckedChange}
                  onDirChange={onDirChange}
                  disabled={disabled}
                />
              </SC.GroupButton>
            )}

            {!dragContextProvided && (
              <SC.GroupButton disabled={disabled} active={item.active}>
                <AggregationOptionButton
                  type={AggregateStage.GROUP}
                  item={item}
                  index={index}
                  onCheckedChange={onCheckedChange}
                  onDirChange={onDirChange}
                  disabled={disabled}
                />
              </SC.GroupButton>
            )}
          </div>
        }
      />
    );
  };

  return (
    <>
      {orderedGroupItems.length > 0 && (
        <div>
          <SC.StyledPanelSubHeader>Grouped</SC.StyledPanelSubHeader>

          <DragDropContext onDragEnd={onDragEnd}>
            <DroppableComp
              dropComp={
                <Droppable droppableId="droppable-group">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {orderedGroupItems.map((item, index) => (
                        <Draggable
                          isDragDisabled={disabled}
                          key={`${item.field}-${index}`}
                          draggableId={`${item.field}-${index}`}
                          index={index}
                        >
                          {(provided) =>
                            buildGroupItemButton(item, index, provided)
                          }
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              }
            />
          </DragDropContext>
        </div>
      )}
      <div>
        {groupItems.filter((item) => !item.active).length > 0 && (
          <>
            <SC.StyledPanelSubHeader>Ungrouped</SC.StyledPanelSubHeader>
            {groupItems
              .filter((item) => !item.active)
              .map((item, index) => buildGroupItemButton(item, index))}
          </>
        )}
      </div>
    </>
  );
};

export default AggregationGroup;
