import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums/EntityType';
import { RouteName } from '../../../../enums/RouteName';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import useProject from '../../../../hooks/project/UseProject';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchProjectSuccess } from '../../../../store/project';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectCreationSubStage } from '../../../../store/project-stage/initial-state';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import ActionBar from '../../../ActionBar/ActionBar';
import IconSelector from '../../../IconSelector/IconSelector';

const AVATAR_FIELD = 'avatar';
const PREV_STAGE = ProjectCreationSubStage.NAME;
const NEXT_STAGE = Enums.ProjectStage.CREATION;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemAvatarStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { project, projectAccessLevel, addProject } = useProject();

  const [processComplete, setProcessComplete] = useState<boolean>(false);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(project);
    cloned[field] = val;
    dispatch(
      fetchProjectSuccess({
        entity: cloned,
        accessLevel: projectAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  useEffect(() => {
    if (processComplete && project?._id) {
      // ProcessAction has completed successfully
      // Move on to next stage
      dispatch(updateActiveProjectStage(NEXT_STAGE));
      history.push(`${RouteName.PROJECT_ITEM}/${project._id}`);
    }
  }, [processComplete, project?._id, dispatch, history]);

  const processAction = async () => {
    if (loggedInUser) {
      dispatch(
        showLoading({
          text: `Creating Project...`,
        }),
      );

      // Create project
      const cloned = cloneDeep(project);

      // Add organisation
      cloned.organisation = loggedInUser.organisationId;
      // Add user to access
      cloned.access.manage.user.push(loggedInUser._id);

      // Update current status
      cloned.stages[Enums.ProjectStage.CREATION].status =
        Enums.StageStatus.COMPLETED;

      // Save project
      await addProject(cloned);

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StageBodyText>
            Pick the perfect avatar to represent your project.
          </StageBodyText>

          <StageBodyText>
            This will make it easier to identify your project across the
            organisation.
          </StageBodyText>

          <IconSelector
            type={EntityType.PROJECT}
            onChange={(icon) => handleChange(AVATAR_FIELD, icon)}
            selectedIcon={project?.avatar}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            isDisabled={!project?.avatar?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Create Project'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAvatarStage;
