import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import DataSample from '../../components/DataSample/DataSample';
import DatasetsActions from '../../components/DatasetsActions/DatasetsActions';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { ACTION_ROW_HEADER } from '../../const/DataSample';
import {
  AlertStatus,
  BannerType,
  EntityType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePagination, {
  DEFAULT_META_PAGE_SIZE,
  SortByItem,
} from '../../hooks/pagination/UsePagination';
import useUsage from '../../hooks/usage/useUsage';
import { SampleDataRow } from '../../interfaces/SampleData';
import { FetchParams } from '../../interfaces/Search';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import InitialDatasetMeta from '../../store/dataset-meta/initial-dataset-meta';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const SEARCH_FIELD = 'name';

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Dataset Name';
const TAGS_FIELD = 'Tags';
const LAST_UPDATED_FIELD = 'Last Updated';
const PUBLISHED_FIELD = 'Publish Status';
const ROW_COUNT_FIELD = 'Row Count';
const SORT_FIELDS_MAP = {
  [NAME_FIELD]: 'name',
  [TAGS_FIELD]: 'tag',
  [LAST_UPDATED_FIELD]: 'meta.lastUpdated',
  [PUBLISHED_FIELD]: 'activeDataCollection',
};

const TABLE_SCHEMA: Interfaces.Field[] = [
  { name: NAME_FIELD },
  { name: TAGS_FIELD },
  {
    name: LAST_UPDATED_FIELD,
    dataValidation: {
      dataValidationType: Enums.ValueDataType.DATE,
    },
  },
  { name: PUBLISHED_FIELD },
  { name: ROW_COUNT_FIELD },
];

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const DatasetsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const { getDatasetMetas } = useDatasetMeta();
  const { calculateRowNumber, updateSortBy } = usePagination();
  const { datapointQuota, checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [loadingDatasets, setLoadingDatasets] = useState<boolean>(false);

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 1,
    sortBy: undefined,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 0,
    sortBy: undefined,
  });
  const [datasetMetaUsage, setDatasetMetaUsage] = useState<number>(0);

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get('search');
    setFetchParams((state) => ({
      ...state,
      searchQuery: {
        ...state.searchQuery,
        query:
          searchParams === null
            ? ''
            : decodeURI(searchParams as string).toString(),
      },
    }));
  }, [location.search]);

  // Fetch Dataset Metas
  useEffect(() => {
    (async () => {
      if (
        fetchParams.searchQuery.query !== lastFetchedParams.searchQuery.query &&
        fetchParams.pageNum !== 1
      ) {
        return;
      }

      if (!isEqual(fetchParams, lastFetchedParams)) {
        setLastFetchedParams(fetchParams);

        setLoadingDatasets(true);
        await getDatasetMetas(
          undefined,
          fetchParams.searchQuery?.query?.length
            ? fetchParams.searchQuery
            : undefined,
          fetchParams.pageNum,
          undefined,
          fetchParams.sortBy,
        );
        setLoadingDatasets(false);
      }
    })();
  }, [
    fetchParams,
    fetchParams.pageNum,
    fetchParams.searchQuery,
    fetchParams.sortBy,
    getDatasetMetas,
    lastFetchedParams,
  ]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const datasetUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.DATASET_METAS,
        );
        setDatasetMetaUsage(datasetUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  const tableData: SampleDataRow[] = useMemo(() => {
    return datasetMetas.reduce((acc: SampleDataRow[], dsm, i) => {
      if (!dsm.entity._id || !dsm.entity.dataCollections) {
        return acc;
      }

      const ent = dsm.entity;
      const collection = ent.activeDataCollection
        ? ent.dataCollections.find((c) => c._id === ent.activeDataCollection)
        : ent.dataCollections[0];

      if (!collection) {
        return acc;
      }

      return acc.concat({
        [ROW_NUMBER]: {
          value: calculateRowNumber(
            fetchParams.pageNum,
            i,
            DEFAULT_META_PAGE_SIZE,
          ),
        },
        [ID_FIELD]: { value: ent._id },
        [NAME_FIELD]: { value: ent.name, avatar: ent.avatar },
        [TAGS_FIELD]: {
          value: ent.tags?.map((tag) => tag.value).toString(),
          tags: {
            activeTagValue: fetchParams.searchQuery.query,
          },
        },
        [LAST_UPDATED_FIELD]: {
          value: DateTime.fromISO(ent.meta.lastUpdated).toFormat(
            'dd/MM/yyyy HH:mm:ss',
          ),
        },
        [PUBLISHED_FIELD]: {
          value: ent.activeDataCollection ? 'Published' : 'In Progress',
        },
        [ROW_COUNT_FIELD]: {
          value: collection.rowCount?.toString() || '0',
        },
      });
    }, []);
  }, [
    calculateRowNumber,
    datasetMetas,
    fetchParams.pageNum,
    fetchParams.searchQuery.query,
  ]);

  const onPageNumberChange = useCallback(
    (page: number) => {
      if (page !== fetchParams.pageNum) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.pageNum = page;

        setFetchParams(cloned);
      }
    },
    [fetchParams],
  );

  const onSortChange = useCallback(
    (sortBy: SortByItem) => {
      if (!isEqual(updateSortBy(SORT_FIELDS_MAP, sortBy), fetchParams.sortBy)) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.sortBy = updateSortBy(SORT_FIELDS_MAP, sortBy);
        setFetchParams(cloned);
      }
    },
    [fetchParams, updateSortBy],
  );

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.DATASETS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'View Datasets'}
          image={getDefaultAvatar(EntityType.DATASET)}
          title={'A Dataset Of Your Datasets'}
        />

        <Page>
          <PageInner>
            {datasetMetas.length > 0 && (
              <>
                <StageBodyText>
                  Tap the button below to add a new dataset or click on a row in
                  the table below if you'd like to manage one of your existing
                  datasets.
                </StageBodyText>
              </>
            )}

            {datasetMetas.length === 0 && (
              <>
                <StageBodyText>
                  First time here? No problem, let's get you started with a
                  shiny new Dataset.
                </StageBodyText>
                <StageBodyText>
                  Just tap the button below and we'll kick things off.
                </StageBodyText>
              </>
            )}

            {datasetMetaUsage >= QuotaLimits.WARNING && (
              <div style={{ marginBottom: themeContext.margin.xlarge }}>
                {BuildBanner.generateBanner(
                  BannerType.QUOTA,
                  datasetMetaUsage >= QuotaLimits.FULL
                    ? AlertStatus.ERROR
                    : AlertStatus.WARNING,
                  ResourceType.DATASET_METAS,
                )}
              </div>
            )}

            <FeatureButton
              action={() => {
                // Reset datasetMeta and stage data
                dispatch(resetStagesAndSubStages());
                dispatch(fetchDatasetMetaSuccess(InitialDatasetMeta));
                history.push(RouteName.DATASET_ITEM);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create new dataset'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
              isDisabled={
                datapointQuota?.isDatapointQuotaLimit ||
                datasetMetaUsage >= QuotaLimits.FULL
              }
            />

            <EntityFilter
              baseRoute={RouteName.DATASETS}
              title={'Search Datasets'}
              search={fetchParams.searchQuery.query}
              inputPlaceholder={'Enter a dataset name or tag'}
              totalResultCount={datasetMetasState.data.pagination.totalCount}
              filteredResultCount={datasetMetas?.length}
              loading={loadingDatasets}
            />
            <TableWrapper>
              <DataSample
                loading={loadingDatasets}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                iconMap={AvatarIconMap}
                showPagination={true}
                paginationAction={onPageNumberChange}
                resetPagination={
                  lastFetchedParams.searchQuery.query !==
                  fetchParams.searchQuery.query
                }
                pageSize={DEFAULT_META_PAGE_SIZE}
                totalRows={datasetMetasState?.data.pagination.totalCount}
                sortAction={onSortChange}
                actions={(entityId: string) => (
                  <DatasetsActions entityId={entityId} />
                )}
                hideSortOnCols={[
                  ROW_NUMBER,
                  PUBLISHED_FIELD,
                  ROW_COUNT_FIELD,
                  TAGS_FIELD,
                  ACTION_ROW_HEADER,
                ]}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default DatasetsPage;
