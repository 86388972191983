import React, { FC } from 'react';
import styled from 'styled-components';

interface NavTitleProps {
  text: string;
  lightTheme?: boolean;
  isActive?: boolean;
}

const Wrapper = styled.p<{ lightTheme?: boolean; isActive?: boolean }>`
  flex-grow: 2;

  text-align: left;
  text-transform: uppercase;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme, isActive, lightTheme }) =>
    isActive
      ? lightTheme
        ? theme.colors.system.white
        : theme.colors.system.white
      : lightTheme
      ? theme.colors.system.offBlack
      : theme.colors.system.white};
`;

const NavTitle: FC<NavTitleProps> = ({ text, lightTheme, isActive }) => {
  return (
    <Wrapper lightTheme={lightTheme} isActive={isActive}>
      {text}
    </Wrapper>
  );
};

export default NavTitle;
