export const ROW_ID_COL_WIDTH = 55;
export const SELECTION_COL_WIDTH = 55;
export const COMMENT_COUNT_COL_WIDTH = 55;
export const MIN_COL_WIDTH = 150;
export const MAX_COL_WIDTH = 400;
export const TAG_COL_WIDTH = 250;
export const ACTIONS_COL_WIDTH = 120;
export const MIN_INTERACTIVE_COL_WIDTH = 175;
export const CELL_HORI_PADDING = 25;

export const CELL = 'Cell';
export const CELL_LINK = 'cell-link';
export const ROW_NUMBER_HEADER = 'row_id';
export const DELETE_ROW_HEADER = 'DELETE_ROW';
export const SELECTION_HEADER = 'selection';
export const ACTION_ROW_HEADER = 'Action Header';
export const COMMENT_COUNT_HEADER = 'rowMeta.commentCount';
export const ENTITY_ID = '_id';
export const STRING_TYPE = 'string';
export const BOOLEAN_TYPE = 'boolean';
export const STICKY = 'sticky';
export const STICKY_LEFT = 'left';
export const STICKY_RIGHT = 'right';
export const RELATIVE = 'relative';
export const UNDEFINED = 'undefined';
