import {
  faBullhorn,
  faClipboard,
  faDatabase,
  faGlobe,
  faRocket,
} from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePermission from '../../hooks/permission/UsePermission';
import { DefaultPopupStyles } from '../../main/theme';
import { resetFormStage } from '../../store/project-stage';
import FAButton from '../FaButton/FAButton';

const Wrapper = styled.div`
  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
`;

const SystemShortcuts: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();
  const { isOrgManager, isProTier } = usePermission();
  const { hasAccessToHouston } = useOrganisation();

  return (
    <Wrapper>
      {/*Deprecated*/}
      {/*<Popup*/}
      {/*  content={'Projects'}*/}
      {/*  position="right center"*/}
      {/*  style={DefaultPopupStyles}*/}
      {/*  trigger={*/}
      {/*    <div>*/}
      {/*      <FAButton*/}
      {/*        action={() => history.push(RouteName.PROJECTS)}*/}
      {/*        icon={faProjectDiagram}*/}
      {/*        color={*/}
      {/*          location.pathname === RouteName.PROJECTS ||*/}
      {/*          location.pathname.includes(RouteName.PROJECT_ITEM)*/}
      {/*            ? themeContext.colors.general.purple*/}
      {/*            : themeContext.colors.system.white*/}
      {/*        }*/}
      {/*        size={'2x'}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}
      <Popup
        content={'Datasets'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FAButton
              action={() => history.push(RouteName.DATASETS)}
              icon={faDatabase}
              color={
                !location.pathname.includes(RouteName.PROJECT_ITEM) &&
                (location.pathname === RouteName.DATASETS ||
                  location.pathname.includes(RouteName.DATASET_ITEM))
                  ? themeContext.colors.general.green
                  : themeContext.colors.system.white
              }
              size={'2x'}
            />
          </div>
        }
      />

      <Popup
        content={'APIs'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FAButton
              action={() => history.push(RouteName.APIS)}
              icon={faGlobe}
              color={
                location.pathname === RouteName.APIS ||
                location.pathname.includes(RouteName.APIS)
                  ? themeContext.colors.general.sea
                  : themeContext.colors.system.white
              }
              size={'2x'}
            />
          </div>
        }
      />

      <Popup
        content={'Forms'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FAButton
              action={() => {
                dispatch(resetFormStage());
                history.push(RouteName.FORMS);
              }}
              icon={faClipboard}
              color={
                location.pathname === RouteName.FORMS ||
                location.pathname.includes(RouteName.FORMS)
                  ? themeContext.colors.general.sea
                  : themeContext.colors.system.white
              }
              size={'2x'}
            />
          </div>
        }
      />

      <Popup
        content={'Notifications'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FAButton
              action={() => {
                history.push(RouteName.NOTIFICATIONS);
              }}
              icon={faBullhorn}
              color={
                location.pathname === RouteName.NOTIFICATIONS ||
                location.pathname.includes(RouteName.NOTIFICATIONS)
                  ? themeContext.colors.general.sea
                  : themeContext.colors.system.white
              }
              size={'2x'}
            />
          </div>
        }
      />

      {hasAccessToHouston && (
        <Popup
          content={'Integration Centre'}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FAButton
                action={() => history.push(RouteName.INTEGRATION_CENTRE)}
                icon={faRocket}
                color={
                  [
                    RouteName.INTEGRATION_CENTRE,
                    RouteName.INTEGRATION_ITEM,
                  ].includes(location.pathname)
                    ? themeContext.colors.general.red
                    : themeContext.colors.system.white
                }
                size={'2x'}
              />
            </div>
          }
        />
      )}
      {/*Deprecated*/}
      {/*{isProTier && (*/}
      {/*  <Popup*/}
      {/*    content={'Teams'}*/}
      {/*    position="right center"*/}
      {/*    style={DefaultPopupStyles}*/}
      {/*    trigger={*/}
      {/*      <div>*/}
      {/*        <FAButton*/}
      {/*          action={() => history.push(RouteName.TEAMS)}*/}
      {/*          icon={faUsers}*/}
      {/*          color={*/}
      {/*            location.pathname === RouteName.TEAMS ||*/}
      {/*            location.pathname.includes(RouteName.TEAM_ITEM)*/}
      {/*              ? themeContext.colors.general.yellow*/}
      {/*              : themeContext.colors.system.white*/}
      {/*          }*/}
      {/*          size={'2x'}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}

      {/*Deprecated*/}
      {/*{isOrgManager && (*/}
      {/*  <>*/}
      {/*    <Popup*/}
      {/*      content={'Users'}*/}
      {/*      position="right center"*/}
      {/*      style={DefaultPopupStyles}*/}
      {/*      trigger={*/}
      {/*        <div>*/}
      {/*          <FAButton*/}
      {/*            action={() => history.push(RouteName.USERS)}*/}
      {/*            icon={faUser}*/}
      {/*            color={*/}
      {/*              location.pathname === RouteName.USERS ||*/}
      {/*              location.pathname.includes(RouteName.USER_ITEM)*/}
      {/*                ? themeContext.colors.general.blue*/}
      {/*                : themeContext.colors.system.white*/}
      {/*            }*/}
      {/*            size={'2x'}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      }*/}
      {/*    />*/}

      {/*    <Popup*/}
      {/*      content={'Organisation'}*/}
      {/*      position="right center"*/}
      {/*      style={DefaultPopupStyles}*/}
      {/*      trigger={*/}
      {/*        <div>*/}
      {/*          <FAButton*/}
      {/*            action={() => history.push(RouteName.ORGANISATION_ITEM)}*/}
      {/*            icon={faBuilding}*/}
      {/*            color={*/}
      {/*              location.pathname === RouteName.ORGANISATION_ITEM ||*/}
      {/*              location.pathname.includes(RouteName.ORGANISATION_ITEM)*/}
      {/*                ? themeContext.colors.general.sea*/}
      {/*                : themeContext.colors.system.white*/}
      {/*            }*/}
      {/*            size={'2x'}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
    </Wrapper>
  );
};

export default SystemShortcuts;
