import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import createNameIcon from '../../assets/icons/stages/create-dataset/name.svg';
import createAvatarIcon from '../../assets/icons/stages/create-user/avatar.svg';
import {
  UserConfigureSubStage,
  UserCreationSubStage,
  UserStage,
  UserSubStages,
} from '../../store/user-stage/initial-state';
import {
  StageMeta,
  SubStageContent,
  SubStageMeta,
} from '../stage-content/StageHeaderContent';

const userStageMeta: StageMeta = {
  [UserStage.CREATION]: {
    title: 'Create User',
  },
  [UserStage.CONFIGURATION]: {
    title: 'Configuration',
  },
};

export const getUserStageTitle = (stage: UserStage): string => {
  return userStageMeta[stage].title;
};

export const getUserSubStageContent = (
  stage: UserStage,
  subStage: UserSubStages,
): SubStageContent => {
  const subStageContent: SubStageMeta = {
    [UserStage.CREATION]: {
      subStages: {
        [UserCreationSubStage.NAME]: {
          stage: 1,
          image: createNameIcon,
          title: `Introduce Yourself`,
        },
        [UserCreationSubStage.AVATAR]: {
          stage: 2,
          image: createAvatarIcon,
          title: `Pick The Perfect Picture`,
        },
      },
    },
    [UserStage.CONFIGURATION]: {
      subStages: {
        [UserConfigureSubStage.ADVANCED]: {
          stage: 1,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
