import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
} from '../../../enums';
import useApi from '../../../hooks/api/UseApi';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import useProject from '../../../hooks/project/UseProject';
import useUsage from '../../../hooks/usage/useUsage';
import { SampleDataRow } from '../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../main/theme';
import { showModal } from '../../../store/modal';
import { updateActiveProjectStage } from '../../../store/project-stage';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../Modal/Modal';
import * as SC from './styled';

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const STATUS_FIELD = 'Status';
const DATASETS_FIELD = 'Available Datasets';

const API_TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: STATUS_FIELD },
  { name: DATASETS_FIELD },
];

const PREV_STAGE = Enums.ProjectStage.CREATION;

const ProjectItemAPIStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { connections, project, projectAccessLevel } = useProject();
  const { getDatasetMetas } = useDatasetMeta();
  const { getApis } = useApi();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const [fetchedAPIs, setFetchedAPIs] =
    useState<Interfaces.DatasetApiOutput[]>();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  const [apiConnections, setApiConnections] =
    useState<Interfaces.ConnectionOutput[]>();

  const [apiUsage, setApiUsage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const datasetMetas = useSelector((state: RootState) => state.datasetMetas)
    ?.data.data;
  const charts = useSelector((state: RootState) => state.charts)?.data.data;
  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  // Get datasetMetas
  useEffect(() => {
    if (project?.datasetMetaId?.length) {
      (async () => {
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          undefined,
          undefined,
          500,
        );
      })();
    }
  }, [getDatasetMetas, project?._id, project?.datasetMetaId]);

  // Get APIs
  useEffect(() => {
    if (project?._id && apiConnections) {
      if (apiConnections.length) {
        (async () => {
          let apis;
          try {
            apis = await getApis(project?._id, {
              projectId: project._id,
              _id: {
                $in: apiConnections.map(
                  (a) =>
                    (a.configuration as Interfaces.APIConnectionConfiguration)
                      .apiId,
                ),
              },
            });
          } catch (err) {
            apis = [];
          }

          setFetchedAPIs(apis);
        })();
      } else {
        setFetchedAPIs([]);
      }
    }
  }, [apiConnections, getApis, project]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const apiUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.APIS,
        );
        setApiUsage(apiUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, organisation?._id]);

  // Hide Loader
  useEffect(() => {
    if (fetchedAPIs) {
      setIsLoading(false);
    }
  }, [fetchedAPIs]);

  // Get connections
  useEffect(() => {
    if (connections) {
      setApiConnections(connections.api || []);
    }
  }, [connections]);

  useEffect(() => {
    if (apiConnections) {
      const tableData: SampleDataRow[] = [];

      apiConnections?.map((api) => {
        const apiId = (
          api.configuration as Interfaces.APIConnectionConfiguration
        ).apiId;
        const apiDataset = fetchedAPIs?.find(
          (a) => a._id === apiId,
        )?.datasetMetaId;
        const dataset = datasetMetas.find(
          (dsm) => dsm.entity._id === apiDataset,
        )?.entity.name;

        if (dataset) {
          tableData.push({
            [ID_FIELD]: { value: api._id },
            [NAME_FIELD]: { value: api.name },
            [STATUS_FIELD]: { value: startCase(api.status) },
            [DATASETS_FIELD]: { value: dataset },
          });
        }
      });

      setTableData(tableData);
    }
  }, [apiConnections, charts, datasetMetas, fetchedAPIs, queries]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.ConnectionTypeWrapper>
          <StyledSubHeader>
            Application Programming Interface (API)
          </StyledSubHeader>

          <StageBodyText>
            Build REST APIs in minutes and connect with infinite platforms,
            chatbots, voice assistants and more.
          </StageBodyText>

          {projectAccessLevel === Enums.AccessLevel.MANAGE && (
            <>
              {apiUsage >= QuotaLimits.WARNING && (
                <div style={{ marginBottom: themeContext.margin.xlarge }}>
                  {BuildBanner.generateBanner(
                    BannerType.QUOTA,
                    apiUsage >= QuotaLimits.FULL
                      ? AlertStatus.ERROR
                      : AlertStatus.WARNING,
                    ResourceType.APIS,
                  )}
                </div>
              )}

              <FeatureButton
                action={() => {
                  dispatch(
                    showModal({
                      visible: true,
                      modal: ModalTypes.CONNECTION_API,
                    }),
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Add API'}
                containerStyle={{ marginBottom: themeContext.margin.large }}
                isDisabled={apiUsage >= QuotaLimits.FULL}
              />
            </>
          )}

          {projectAccessLevel !== Enums.AccessLevel.MANAGE &&
            !tableData.length &&
            !isLoading && (
              <StageBodyText>
                <strong>No APIs found</strong>
              </StageBodyText>
            )}

          {(tableData.length > 0 || isLoading) && (
            <SC.TableWrapper>
              <DataSample
                totalRows={tableData.length}
                sampleColumns={API_TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (connectionId: string) => {
                    const existingConnection = apiConnections?.find(
                      (c) => c._id === connectionId,
                    );
                    const existingAPI = fetchedAPIs?.find(
                      (a) =>
                        a._id ===
                        (
                          existingConnection?.configuration as Interfaces.APIConnectionConfiguration
                        ).apiId,
                    );
                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.CONNECTION_API,
                        additionalProps: { existingConnection, existingAPI },
                      }),
                    );
                  },
                }}
                loading={isLoading}
              />
            </SC.TableWrapper>
          )}
        </SC.ConnectionTypeWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAPIStage;
