import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

export const CardContainer = styled.div`
  margin: ${({ theme }) => theme.margin.large};
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border: 1px solid ${({ theme }) => theme.colors.system.midGrey};
  padding: ${({ theme }) => theme.padding.xlarge};
  text-align: center;
  flex: 1 1 46%;
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

export const StyledProgress = styled(Progress)<{ completion: number }>`
  & .bar {
    ${({ theme, completion }) =>
      completion > 75 &&
      `
    background-color: ${theme.colors.general.red} !important;
  `};
    ${({ theme, completion }) =>
      completion > 50 &&
      completion < 75 &&
      `
    background-color: ${theme.colors.general.yellow} !important;
  `};
    ${({ theme, completion }) =>
      completion < 50 &&
      `
    background-color: ${theme.colors.general.green} !important;
  `};
  }
  & .label {
    margin-top: ${({ theme }) => theme.margin.standard} !important;
  }
`;

export const LargeMetric = styled.span`
  font-size: 2.25rem;
  font-weight: bold;
`;
export const SmallMetric = styled.span`
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  color: ${({ theme }) => theme.colors.system.darkGrey};
`;
