import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DatasetStage } from '@configur-tech/upit-core-types/lib/enums';
import { cloneDeep, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import CmsConnectionIcon from '../../assets/icons/connections/cms-connection-icon.svg';
import logo from '../../assets/icons/system/codex-icon.svg';
import { EntityType, RouteName } from '../../enums';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledH1,
  StyledH5,
  StyledSubHeader,
  StyledText,
  StyledTextArea,
} from '../../main/theme';
import { DEFAULT_PAGE_SIZE } from '../../pages/CMS/CMSPage';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { DatasetEventsItemOutput } from '../../services/events/EventsService';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../store/dataset-stage';
import {
  AdditionalDatasetStages,
  DatasetConfigureSubStage,
  DatasetEventsSubStage,
  DatasetStructureSubStage,
} from '../../store/dataset-stage/initial-state';
import { hideLoading } from '../../store/loading';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import { getSubStageContent } from '../../util/stage-content/StageHeaderContent';
import DataSample from '../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import PageFeatureHeader from '../PageFeatureHeader/PageFeatureHeader';
import TagComponent from '../TableCell/TagTableCell/TagComponent';
import * as SC from './styled';

const DEFAULT_USER_NAME = 'Form User';
const DEFAULT_USER_AVATAR = 'configur-logo';
const DEFAULT_TAG_GROUP = 'group';

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const CREATED_BY_FIELD = 'User';
const DATE_FIELD = 'Date';
const EVENT_TYPE_FIELD = 'Event Type';

const TABLE_SCHEMA_EVENTS = [
  { name: CREATED_BY_FIELD },
  { name: EVENT_TYPE_FIELD },
  { name: DATE_FIELD },
];

const buildDatasetDropdownOptions = (
  tag: Interfaces.TagV1[],
): DropdownItemProps[] =>
  tag.map((d) => {
    return {
      key: d.value,
      text: d.value,
      value: d.value,
    };
  });

const DatasetOverview: FC = () => {
  const { datasetMetaId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const {
    datasetMeta,
    datasetMetaAccessLevel,
    activeDataCollectionItem,
    editDatasetMeta,
    getDatasetEvents,
  } = useDatasetMeta();

  const [editingDetails, setEditingDetails] = useState<boolean>(false);
  const [updatedName, setUpdatedName] = useState<string>();
  const [updatedDesc, setUpdatedDesc] = useState<string>();
  const [savingDatasetMeta, setSavingDatasetMeta] = useState<boolean>(false);

  const [eventTableData, setEventTableData] = useState<SampleDataRow[]>([]);

  const [datasetEvents, setDatasetEvents] = useState<DatasetEventsItemOutput>();
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [loadedEvents, setLoadedEvents] = useState<boolean>(false);
  const [datasetTags, setDatasetTags] = useState<Interfaces.TagV1[]>([]);
  const [datasetTagOptions, setDatasetTagOptions] = useState<
    DropdownItemProps[]
  >([]);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );

  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  // Collate tags from existing datasetMetas
  useEffect(() => {
    const dsmTags = datasetMetas?.reduce((acc: Interfaces.TagV1[], dsm) => {
      if (!dsm.entity.tags) {
        return acc;
      }

      return [
        ...acc,
        ...dsm.entity.tags.filter(
          (tag) => !acc.find((t) => t.value === tag.value),
        ),
      ];
    }, []);

    // Create format and append existing tags for Semantic UI

    setDatasetTagOptions(
      buildDatasetDropdownOptions(dsmTags.concat(datasetTags)),
    );
  }, [datasetMetas, datasetTags]);

  useEffect(() => {
    if (
      datasetMeta?._id &&
      datasetMeta._id === datasetMetaId &&
      !loadingEvents &&
      !loadedEvents
    ) {
      (async () => {
        setLoadingEvents(true);

        try {
          setDatasetEvents(await getDatasetEvents(datasetMeta._id, 0, 5));
        } finally {
          setLoadedEvents(true);
          setLoadingEvents(false);
          await dispatch(hideLoading());
        }
      })();
    }
  }, [
    datasetMeta,
    getDatasetEvents,
    dispatch,
    loadedEvents,
    loadingEvents,
    datasetMetaId,
  ]);

  useEffect(() => {
    if (datasetMeta?.tags.length) {
      setDatasetTags(datasetMeta?.tags);
    }
  }, [datasetMeta?.tags]);

  const handleTagAdd = (value: string) => {
    const cloned = cloneDeep(datasetTags);
    cloned.push({ key: DEFAULT_TAG_GROUP, value, text: value });
    setDatasetTags(cloned);
  };

  // Handle Tag Change and Correct formatting for DatasetMeta
  const handleTagChange = (field, value) => {
    const cloned = cloneDeep(datasetMeta);
    const concatTags = value.map((tag) => {
      return { key: DEFAULT_TAG_GROUP, value: tag.toString() };
    });
    cloned[field] = concatTags;
    dispatch(
      fetchDatasetMetaSuccess({
        entity: cloned,
        accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
    setDatasetTags(concatTags);
  };

  useEffect(() => {
    if (datasetEvents) {
      const tableData: SampleDataRow[] = [];

      datasetEvents.data.map((i) => {
        const ent = i.entity;

        tableData.push({
          [ID_FIELD]: { value: ent._id },
          [EVENT_TYPE_FIELD]: { value: startCase(ent.eventType) || '' },
          [CREATED_BY_FIELD]: {
            value: `${ent.createdByUser?.[0]?.firstName || ''} ${
              ent.createdByUser?.[0]?.lastName || DEFAULT_USER_NAME
            }`,
            avatar: ent.createdByUser?.[0]?.avatar || DEFAULT_USER_AVATAR,
          },
          [DATE_FIELD]: {
            value: DateTime.fromISO(ent.meta.created).toFormat(
              'dd/MM/yyyy HH:mm:ss',
            ),
          },
        });
      });

      setEventTableData(tableData);
    }
  }, [datasetEvents]);

  const updateDetails = useCallback(async () => {
    if (
      updatedName?.length &&
      (updatedName !== datasetMeta?.name ||
        updatedDesc !== datasetMeta?.description ||
        datasetTagOptions !== datasetMeta?.tags)
    ) {
      const cloned = cloneDeep(datasetMeta);
      cloned.name = updatedName;
      cloned.description = updatedDesc;
      cloned.tags = datasetTags;
      setSavingDatasetMeta(true);

      await editDatasetMeta(cloned);
      setSavingDatasetMeta(false);
      setEditingDetails(false);
    } else {
      if (datasetMeta?.name) {
        setUpdatedName(datasetMeta.name);
      }
      if (datasetMeta?.description) {
        setUpdatedDesc(datasetMeta.description);
      }
      setEditingDetails(false);
    }
  }, [
    datasetMeta,
    editDatasetMeta,
    updatedDesc,
    updatedName,
    datasetTags,
    datasetTagOptions,
  ]);

  const updateAvatar = useCallback(
    async (icon) => {
      if (icon) {
        const cloned = cloneDeep(datasetMeta);
        cloned.avatar = icon;
        setSavingDatasetMeta(true);

        await editDatasetMeta(cloned);
        setSavingDatasetMeta(false);
      }
    },
    [datasetMeta, editDatasetMeta],
  );

  useEffect(() => {
    if (typeof updatedName === 'undefined' && datasetMeta?.name) {
      setUpdatedName(datasetMeta.name);
    }
  }, [datasetMeta, updatedName]);

  useEffect(() => {
    if (typeof updatedDesc === 'undefined' && datasetMeta?.description) {
      setUpdatedDesc(datasetMeta.description);
    }
  }, [datasetMeta, updatedDesc]);

  const datasetAvatar =
    datasetMeta?.avatar && datasetMeta?.avatar.includes('http')
      ? datasetMeta?.avatar
      : datasetMeta?.avatar
      ? { ...UserIconMap, ...AvatarIconMap }[datasetMeta?.avatar]
      : getDefaultAvatar(EntityType.DATASET);

  return (
    <>
      {datasetMetaAccessLevel !== Enums.AccessLevel.MANAGE && (
        <PageFeatureHeader
          pageHeader={'Overview'}
          image={datasetAvatar}
          hideAvatarPopup={true}
          title={
            <SC.NameWrapper>
              <StyledH1>{datasetMeta?.name}</StyledH1>

              {datasetMeta?.description &&
                datasetMeta.description.length > 0 && (
                  <StyledText>{datasetMeta?.description}</StyledText>
                )}

              {datasetMeta?.tags && datasetMeta.tags.length > 0 && (
                <SC.TagWrapper>
                  {datasetMeta.tags.map((tag: Interfaces.TagV1, i) => (
                    <TagComponent
                      route={RouteName.DATASETS}
                      tag={tag.value}
                      key={`${tag.value}-${i}`}
                      active={false}
                    />
                  ))}
                </SC.TagWrapper>
              )}
            </SC.NameWrapper>
          }
          content={
            <SC.StatWrapper>
              <SC.StatItem>
                <SC.ItemHeader>Total Rows</SC.ItemHeader>
                <StyledText>{activeDataCollectionItem?.rowCount}</StyledText>
              </SC.StatItem>
              <SC.StatItem>
                <SC.ItemHeader>Total Columns</SC.ItemHeader>
                <StyledText>{activeDataCollectionItem?.columnCount}</StyledText>
              </SC.StatItem>
            </SC.StatWrapper>
          }
        />
      )}

      {datasetMetaAccessLevel === Enums.AccessLevel.MANAGE && (
        <PageFeatureHeader
          pageHeader={'Overview'}
          image={datasetAvatar}
          avatarPopupEntityType={EntityType.DATASET}
          avatarPopupOnChange={(icon) => updateAvatar(icon)}
          avatarPopupValue={datasetMeta?.avatar}
          avatarPopupLoading={savingDatasetMeta}
          title={
            <SC.NameWrapper>
              {!editingDetails ? (
                <>
                  <StyledH1>{datasetMeta?.name}</StyledH1>

                  {datasetMeta?.description &&
                    datasetMeta.description.length > 0 && (
                      <StyledText>{datasetMeta?.description}</StyledText>
                    )}

                  {datasetMeta?.tags && datasetMeta.tags.length > 0 && (
                    <SC.TagWrapper>
                      {datasetMeta.tags.map((tag: Interfaces.TagV1, i) => (
                        <TagComponent
                          route={RouteName.DATASETS}
                          tag={tag.value}
                          key={`${tag.value}-${i}`}
                          active={false}
                        />
                      ))}
                    </SC.TagWrapper>
                  )}
                </>
              ) : (
                <>
                  <StyledBodySubHeader>Dataset Name</StyledBodySubHeader>
                  <SC.NameInput
                    loading={savingDatasetMeta}
                    disabled={savingDatasetMeta}
                    onChange={(e, data) => setUpdatedName(data.value)}
                    value={updatedName}
                    placeholder={'Enter a name for your dataset'}
                  />
                  <StyledBodySubHeader>Dataset Description</StyledBodySubHeader>
                  <StyledTextArea
                    minRows={5}
                    maxRows={10}
                    disabled={savingDatasetMeta}
                    onChange={(e) => setUpdatedDesc(e.target.value)}
                    value={updatedDesc}
                    placeholder={'Enter a description for your dataset'}
                  />
                  <StyledH5 style={{ marginTop: themeContext.margin.large }}>
                    Tags
                  </StyledH5>
                  <StyledDropdownWide
                    style={{
                      marginTop: themeContext.margin.small,
                    }}
                    selectOnBlur={false}
                    placeholder={`Enter your tags`}
                    noResultsMessage={'Type to add a new tag'}
                    text={'Add tags to easily group and search your datasets.'}
                    search
                    allowAdditions
                    selection
                    multiple
                    disabled={savingDatasetMeta}
                    options={datasetTagOptions}
                    value={datasetMeta?.tags?.map((tag) => tag.value) || []}
                    onAddItem={(event, data) => handleTagAdd(data.value)}
                    onChange={(e, { value }) => handleTagChange('tags', value)}
                  />
                </>
              )}

              <SC.NameActionsWrapper>
                {!editingDetails ? (
                  <>
                    <FeatureButton
                      isDisabled={savingDatasetMeta}
                      action={() => {
                        setUpdatedName(datasetMeta?.name);
                        setUpdatedDesc(datasetMeta?.description);
                        setEditingDetails(true);
                      }}
                      color={themeContext.colors.general.blue}
                      text={'Edit Info'}
                      size={FeatureButtonSize.WIDE_SMALL}
                    />
                  </>
                ) : (
                  <>
                    <FeatureButton
                      isDisabled={savingDatasetMeta}
                      action={() => {
                        setEditingDetails(false);
                        setUpdatedName(datasetMeta?.name);
                        setUpdatedDesc(datasetMeta?.description);
                      }}
                      color={themeContext.colors.general.sea}
                      text={'Cancel'}
                      size={FeatureButtonSize.WIDE_SMALL}
                    />
                    <FeatureButton
                      isDisabled={savingDatasetMeta || !updatedName?.length}
                      action={updateDetails}
                      color={themeContext.colors.general.green}
                      text={'Save'}
                      size={FeatureButtonSize.WIDE_SMALL}
                      containerStyle={{
                        marginLeft: themeContext.margin.standard,
                      }}
                    />
                  </>
                )}
              </SC.NameActionsWrapper>
            </SC.NameWrapper>
          }
          content={
            <SC.StatWrapper>
              <SC.StatItem>
                <SC.ItemHeader>Total Rows</SC.ItemHeader>
                <StyledText>{activeDataCollectionItem?.rowCount}</StyledText>
              </SC.StatItem>
              <SC.StatItem>
                <SC.ItemHeader>Total Columns</SC.ItemHeader>
                <StyledText>{activeDataCollectionItem?.columnCount}</StyledText>
              </SC.StatItem>
            </SC.StatWrapper>
          }
        />
      )}

      <Page>
        <StageWrapper>
          <StageInner>
            {datasetMetaAccessLevel !== Enums.AccessLevel.MANAGE && (
              <>
                <StageBodyText>
                  You currently have <strong>View</strong> access to this
                  dataset.
                </StageBodyText>

                <StageBodyText>
                  If you need to make any changes, please contact your
                  organisation manager.
                </StageBodyText>

                <FeatureButton
                  size={FeatureButtonSize.WIDE}
                  action={() =>
                    history.push(
                      `${RouteName.DATASET_ITEM}/${datasetMeta?._id}/view`,
                    )
                  }
                  color={themeContext.colors.general.green}
                  text={'View Dataset'}
                />
              </>
            )}

            {datasetMetaAccessLevel == Enums.AccessLevel.MANAGE && (
              <>
                <StageBodyText>
                  Your dataset is published, but you still have complete control
                  of how it's set up and accessed.
                </StageBodyText>
                <StyledSubHeader marginBottom={themeContext.margin.xlarge}>
                  What would you like to do next?
                </StyledSubHeader>

                <SC.ActionWrapper>
                  <div>
                    <FeatureButton
                      action={() =>
                        history.push(
                          `${RouteName.DATASET_ITEM}/${datasetMeta?._id}/view?page=1&pageSize=${DEFAULT_PAGE_SIZE}`,
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={CmsConnectionIcon}
                    />
                    <SC.ItemHeader>View Data</SC.ItemHeader>
                  </div>

                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveDatasetStage(
                            Enums.DatasetStage.STRUCTURE,
                          ),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getSubStageContent(
                          Enums.DatasetStage.STRUCTURE,
                          DatasetStructureSubStage.CONFIRMATION,
                        ).image
                      }
                    />
                    <SC.ItemHeader>Structure</SC.ItemHeader>
                  </div>

                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveDatasetStage(
                            AdditionalDatasetStages.EVENTS,
                          ),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getSubStageContent(
                          AdditionalDatasetStages.EVENTS,
                          DatasetEventsSubStage.VIEW,
                        ).image
                      }
                    />
                    <SC.ItemHeader>Events</SC.ItemHeader>
                  </div>

                  <div>
                    <FeatureButton
                      action={() => {
                        dispatch(
                          updateActiveDatasetStage(DatasetStage.CONFIGURATION),
                        );
                        dispatch(
                          updateActiveDatasetSubStage(
                            DatasetConfigureSubStage.ACCESS,
                          ),
                        );
                      }}
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getSubStageContent(
                          Enums.DatasetStage.CONFIGURATION,
                          DatasetConfigureSubStage.ACCESS,
                        ).image
                      }
                    />
                    <SC.ItemHeader>Secure</SC.ItemHeader>
                  </div>
                </SC.ActionWrapper>

                <SC.RecentEventsWrapper>
                  <StyledSubHeader marginBottom={themeContext.margin.xlarge}>
                    Recent Events
                  </StyledSubHeader>

                  <DataSample
                    loading={loadingEvents}
                    sampleColumns={TABLE_SCHEMA_EVENTS}
                    sampleRows={eventTableData}
                    iconMap={{ ...UserIconMap, [DEFAULT_USER_AVATAR]: logo }}
                    hideSortOnCols={[ROW_NUMBER]}
                  />
                </SC.RecentEventsWrapper>
              </>
            )}
          </StageInner>
        </StageWrapper>
      </Page>
    </>
  );
};

export default DatasetOverview;
