import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import {
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledText,
} from '../../main/theme';
import DataSampleDataTypeHeaderCell from '../DataSampleHeaderCell/DataSampleDataTypeHeaderCell';
import { SUPER_COL_TYPES } from '../Modal/pipeline/JobMappingModalComponent';
import * as SC from '../Modal/pipeline/styled';

export interface SchemaMappingProps {
  outputSchema: Interfaces.SchemaField[];
  inputSchema: Interfaces.SchemaField[];
  onChange: (data) => void;
  existingMappings?: Interfaces.Mapping[];
  loading?: boolean;
  isQueryJob?: boolean;
}

const ARRAY_VAL_RE = /(-[0-9])/;

const filterInputDropdown = (
  options: DropdownItemProps[],
  filterType: Enums.ValueDataType = Enums.ValueDataType.TEXT,
) => {
  return options.filter((opt) => {
    const optType = (opt.description
      ?.toString()
      ?.toUpperCase()
      .replace(' ', '_') || '') as Enums.ValueDataType;

    if (SUPER_COL_TYPES.includes(optType)) {
      switch (optType) {
        case Enums.ValueDataType.FIELD_LOOKUP:
          return true;
        case Enums.ValueDataType.FORMULA:
          return filterType === Enums.ValueDataType.NUMBER;
        case Enums.ValueDataType.DATE_CONVERSION:
          return filterType === Enums.ValueDataType.DATE;
        case Enums.ValueDataType.TEXT_TRANSFORMATION:
          return true;
      }
    }

    switch (filterType) {
      case Enums.ValueDataType.DATE:
        return optType === Enums.ValueDataType.DATE;
      case Enums.ValueDataType.NUMBER:
        return optType === Enums.ValueDataType.NUMBER;
      case Enums.ValueDataType.EMAIL:
      case Enums.ValueDataType.POSTCODE:
        return [
          Enums.ValueDataType.EMAIL,
          Enums.ValueDataType.POSTCODE,
        ].includes(optType);
      case Enums.ValueDataType.PHONE_NUM:
        return [
          Enums.ValueDataType.PHONE_NUM,
          Enums.ValueDataType.NUMBER,
          Enums.ValueDataType.TEXT,
        ].includes(optType);
      default:
        return true;
    }
  });
};

// Build Schema Dropdown Options
const buildInputSchemaDropdownOptions = (items, isQueryJob) =>
  items.reduce((acc, item) => {
    const entry = {
      key: item.fieldId || item.field,
      text: item.field,
      value: isQueryJob ? item.field : item.fieldId || item.field,
      description: item.type ? startCase(item.type[0].toLowerCase()) : '',
    };

    if (new RegExp(ARRAY_VAL_RE).test(item.field)) {
      const titleText = `${item.field.split(ARRAY_VAL_RE)[0]}-disabled-title`;
      if (!acc.find((option) => option.key === titleText)) {
        return [
          ...acc,
          {
            key: titleText,
            text: startCase(item.field.split(ARRAY_VAL_RE)[0]),
            disabled: true,
          },
          entry,
        ];
      }
    }

    return [...acc, entry];
  }, []);

const SchemaMapper: FC<SchemaMappingProps> = ({
  inputSchema,
  outputSchema,
  onChange,
  existingMappings,
  loading,
  isQueryJob,
}) => {
  const themeContext = useContext(ThemeContext);

  const [inputDropdownOptions, setInputDropdownOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [mappings, setMappings] = useState<Interfaces.Mapping[]>([]);

  // If editing existing, set mappings
  useEffect(() => {
    if (existingMappings) {
      setMappings(existingMappings);
    }
  }, [existingMappings]);

  // Build dropdown options
  useEffect(() => {
    setInputDropdownOptions(
      buildInputSchemaDropdownOptions(inputSchema, isQueryJob),
    );
  }, [inputSchema, isQueryJob]);

  const handleValueChange = (value: string, outputFieldId: string) => {
    let cloned: Interfaces.Mapping[] = cloneDeep(mappings);

    // Value has been cleared
    if (!value) {
      cloned = cloned.filter(
        (mapping) => mapping.outputField !== outputFieldId,
      );

      setMappings(cloned);
      onChange(cloned);
      return;
    }

    // Check if editing existing
    const existing = cloned.find(
      (mapping) => mapping.outputField === outputFieldId,
    );

    if (existing) {
      existing.inputField = value;
    } else {
      cloned.push({
        outputField: outputFieldId,
        inputField: value,
      });
    }

    setMappings(cloned);
    onChange(cloned);
  };

  return (
    <>
      <StyledBodySubHeader style={{ marginTop: themeContext.margin.xlarge }}>
        Schema Mapping
      </StyledBodySubHeader>
      <StyledText style={{ marginBottom: themeContext.margin.xxxlarge }}>
        Select which fields from your input data you would like to output to the
        next step in your pipeline.
      </StyledText>

      <SC.MappingHeaders>
        <StyledBodySubHeader>Input Fields</StyledBodySubHeader>
        <StyledBodySubHeader>Output Fields</StyledBodySubHeader>
      </SC.MappingHeaders>

      {outputSchema.map((row, index) => {
        return (
          <SC.InputWrapper
            key={index}
            style={{ marginBottom: themeContext.margin.xlarge }}
          >
            <SC.InputContainer>
              <StyledDropdownWide
                search
                loading={loading}
                selectOnBlur={false}
                selection
                clearable
                placeholder={'Select an Input Column'}
                value={
                  mappings.find(
                    (mapping) =>
                      mapping.outputField === (row.fieldId || row.field),
                  )?.inputField
                }
                onChange={(e, { value }) =>
                  handleValueChange(value, row.fieldId || row.field)
                }
                options={filterInputDropdown(
                  inputDropdownOptions,
                  row.type[0] as Enums.ValueDataType,
                )}
                upward={true}
                style={{ marginTop: 0, marginBottom: 0 }}
              />
            </SC.InputContainer>
            <FontAwesomeIcon icon={faArrowRight} size={'lg'} />
            <SC.SchemaContainer>
              <DataSampleDataTypeHeaderCell
                isDisabled={true}
                field={row.field}
                type={
                  (row.type[0] as Enums.ValueDataType) ||
                  Enums.ValueDataType.TEXT
                }
                dateComplete={
                  (row.type[0] as Enums.ValueDataType) ===
                  Enums.ValueDataType.DATE
                }
              />
            </SC.SchemaContainer>
          </SC.InputWrapper>
        );
      })}
    </>
  );
};

export default SchemaMapper;
