import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import usePermission from '../hooks/permission/UsePermission';
import NotAuthorisedPage from '../pages/NotAuthorised/NotAuthorisedPage';

interface ProTierRouteProps {
  path: string;
  exact: boolean;
  component: FC;
}

const ProTierRoute: FC<ProTierRouteProps> = ({ path, exact, component }) => {
  const { isProTier } = usePermission();

  return (
    <Route
      path={path}
      exact={exact}
      component={isProTier ? component : NotAuthorisedPage}
    />
  );
};

export default ProTierRoute;
