import { Interfaces } from '@configur-tech/upit-core-types';

export const getActiveDataCollection = (
  datasetMeta: Interfaces.DatasetMetaOutput,
): Interfaces.CollectionOutput | undefined =>
  datasetMeta?.activeDataCollection
    ? datasetMeta.dataCollections.find(
        (c) => c._id === datasetMeta.activeDataCollection,
      )
    : datasetMeta?.dataCollections[0];
