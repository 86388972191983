import { Interfaces } from '@configur-tech/upit-core-types';
import {
  faArrowDown,
  faArrowUp,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useChart, { AreaMoveDirection } from '../../hooks/chart/UseChart';
import FeatureButton from '../FeatureButton/FeatureButton';
import * as SC from './styled';

export interface AreaControlsProps {
  area: Interfaces.ChartAreaOutput;
}

const AREA_CONTROL_BUTTON_SIZE = 20;

const AreaControls: FC<AreaControlsProps> = ({ area }) => {
  const { chart, chartSaving, deleteChartArea, moveChartArea } = useChart();
  const themeContext = useContext(ThemeContext);

  const isTopArea =
    chart && chart.areas?.length ? chart.areas[0]._id === area._id : false;

  const isBottomArea =
    chart && chart.areas?.length
      ? chart.areas[chart.areas.length - 1]._id === area._id
      : false;

  return (
    <SC.AreaControls>
      <FeatureButton
        action={() => {
          moveChartArea(AreaMoveDirection.DOWN, area._id);
        }}
        size={AREA_CONTROL_BUTTON_SIZE}
        color={themeContext.colors.general.purple}
        icon={
          <FontAwesomeIcon
            icon={faArrowDown}
            color={themeContext.colors.general.white}
            size={'sm'}
          />
        }
        style={{ width: '100%' }}
        containerStyle={{ marginRight: themeContext.margin.small }}
        isDisabled={isBottomArea || chartSaving}
      />

      <FeatureButton
        action={() => {
          moveChartArea(AreaMoveDirection.UP, area._id);
        }}
        size={AREA_CONTROL_BUTTON_SIZE}
        color={themeContext.colors.general.purple}
        icon={
          <FontAwesomeIcon
            icon={faArrowUp}
            color={themeContext.colors.general.white}
            size={'sm'}
          />
        }
        style={{ width: '100%' }}
        containerStyle={{ marginRight: themeContext.margin.small }}
        isDisabled={isTopArea || chartSaving}
      />

      <FeatureButton
        action={() => {
          deleteChartArea(area._id);
        }}
        size={AREA_CONTROL_BUTTON_SIZE}
        color={themeContext.colors.general.red}
        icon={
          <FontAwesomeIcon
            icon={faTrash}
            color={themeContext.colors.general.white}
            size={'sm'}
          />
        }
        style={{ width: '100%' }}
        isDisabled={chartSaving}
      />
    </SC.AreaControls>
  );
};

export default AreaControls;
