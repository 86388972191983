import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { ModalTypes } from '../../components/Modal/Modal';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemGraphNameStage from '../../components/ProjectItem/ProjectItemGraphStage/1-name/ProjectItemGraphNameStage';
import ProjectitemGraphDataStage from '../../components/ProjectItem/ProjectItemGraphStage/2-datasets-fields/ProjectItemGraphDataStage';
import ProjectItemGraphAggregateStage from '../../components/ProjectItem/ProjectItemGraphStage/3-aggregate/ProjectItemGraphAggregateStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import { EntityType } from '../../enums/EntityType';
import { ProjectStage } from '../../enums/ProjectStage';
import useGraph from '../../hooks/graph/UseGraph';
import Page from '../../main/Page';
import { StyledH1 } from '../../main/theme';
import { showModal } from '../../store/modal';
import { ProjectGraphSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.xxlarge} 0 0`};

  > h1 {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }

  > textarea {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }
`;

const NameActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;

const ProjectItemAggregation: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { graph } = useGraph();
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = ProjectStage.GRAPH;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);
  const isGraphBuilder = activeSubStage === ProjectGraphSubStage.GRAPH;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectGraphSubStage.NAME:
        return <ProjectItemGraphNameStage />;
      case ProjectGraphSubStage.DATA:
        return <ProjectitemGraphDataStage />;
      case ProjectGraphSubStage.GRAPH:
        return <ProjectItemGraphAggregateStage />;
      default:
        return <ProjectItemGraphNameStage />;
    }
  };

  const stageContentTitle =
    activeSubStage !== ProjectGraphSubStage.NAME || !graph?._id ? (
      stageContent.title
    ) : (
      <NameWrapper>
        <StyledH1>{stageContent.title}</StyledH1>

        <NameActionsWrapper>
          <FeatureButton
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.DELETION,
                  additionalProps: {
                    entityType: EntityType.GRAPH,
                    entityId: graph?._id,
                    entityName: graph?.name,
                  },
                }),
              )
            }
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.red}
            text={'Delete Graph'}
          />
        </NameActionsWrapper>
      </NameWrapper>
    );

  return (
    <>
      {!isGraphBuilder && (
        <PageFeatureHeader
          pageHeader={stageTitle}
          subStages={
            activeSubStage
              ? {
                  activeSubStage: activeSubStage,
                  subStageItems: projectStageState.stages[activeStage]
                    .subStages as SubStageItem[],
                }
              : undefined
          }
          image={stageContent.image}
          title={stageContentTitle}
        />
      )}

      <Page style={isGraphBuilder ? { overflow: 'hidden' } : undefined}>
        {activeSubStage ? (
          getSubStageComponent()
        ) : (
          <ProjectItemGraphAggregateStage />
        )}
      </Page>
    </>
  );
};

export default ProjectItemAggregation;
