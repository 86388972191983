import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledBodySubHeader, StyledSubHeader } from '../../../../main/theme';

export interface MergeDatasetHeadingProps {
  smallHeading?: boolean;
  titleText?: string;
}

const MergeDatasetHeading: FC<MergeDatasetHeadingProps> = ({
  smallHeading,
  titleText,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      {smallHeading ? (
        <StyledBodySubHeader
          style={{
            marginBottom: themeContext.margin.standard,
            marginTop: themeContext.margin.standard,
          }}
        >
          {titleText}
        </StyledBodySubHeader>
      ) : (
        <StyledSubHeader
          marginBottom={smallHeading ? themeContext.margin.standard : '0'}
        >
          {titleText}
        </StyledSubHeader>
      )}
    </>
  );
};

export default MergeDatasetHeading;
