import { Interfaces } from '@configur-tech/upit-core-types';
import { Pagination } from '../../const/PaginationConst';
import HttpHelper from '../../util/http-helper/HttpHelper';

interface ValidationCompleteResult {
  datasetMeta: Interfaces.DatasetMetaOutput;
  validationResult: Interfaces.ValidationResult;
  pagination: Pagination;
}

const endpoint = process.env['REACT_APP_VALIDATION'] || '';

export default class ValidationService {
  public static async validateDataset(
    token: string,
    organisationId: string,
    userId: string,
    datasetMetaId: string,
    collectionId: string,
    pageNum: number,
    fileId?: string,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    sheetName?: string,
    copyDataMetaId?: string,
  ): Promise<ValidationCompleteResult> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<ValidationCompleteResult>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      fileId,
      organisationId,
      pageNum,
      userId,
      datasetMetaId,
      collectionId,
      entryType,
      hasNoHeadings,
      delimiter,
      sheetName,
      copyDataMetaId,
    });

    return result.data as ValidationCompleteResult;
  }
}
