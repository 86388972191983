import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import SchemaService, {
  DiscoveryResponse,
} from '../../services/schema/SchemaService';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useSchemaResult {
  discoverSchema: (
    fileId: string,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    sheetName?: string,
  ) => Promise<DiscoveryResponse>;
}

const useSchema = (): useSchemaResult => {
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const discoverSchema = useCallback(
    async (
      fileId: string,
      entryType?: string,
      hasNoHeadings?: boolean,
      delimiter?: string,
      sheetName?: string,
    ) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && loggedInUser) {
        try {
          result = await SchemaService.discoverSchema(
            token,
            fileId,
            loggedInUser.organisationId,
            loggedInUser._id,
            entryType,
            hasNoHeadings,
            delimiter,
            sheetName,
          );

          const emptySchemaFields = result.schema.some((s) => !s.field);
          if (!emptySchemaFields) {
            return result;
          }
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to create dataset.`,
          );
        }
      }
    },
    [getAccessTokenSilently, loggedInUser],
  );

  return {
    discoverSchema,
  };
};

export default useSchema;
