export interface DatasetCollectionState {
  datasetMetaId?: string;
  collectionId?: string;
  collectionLocked?: boolean;
}

const InitialDatasetCollection: DatasetCollectionState = {
  datasetMetaId: '',
  collectionId: '',
  collectionLocked: true,
};

export default InitialDatasetCollection;
