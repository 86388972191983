export enum PortalStage {
  AGGREGATION = 'aggregation',
}

export enum PortalAggregationSubStage {
  NAME = 'name',
  DATASETS = 'datasets',
  AGGREGATE = 'aggregate',
}

interface ProjectSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type PortalSubStages = PortalAggregationSubStage;

export interface PortalStages {
  activeStage: PortalStage;
  stages: {
    [PortalStage.AGGREGATION]: {
      activeSubStage: PortalAggregationSubStage | undefined;
      subStages: ProjectSubStage[];
    };
  };
}

const InitialPortalStage: PortalStages = {
  activeStage: PortalStage.AGGREGATION,
  stages: {
    [PortalStage.AGGREGATION]: {
      activeSubStage: undefined,
      subStages: [
        {
          subStage: PortalAggregationSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: PortalAggregationSubStage.DATASETS,
          stage: 2,
          title: 'Select',
        },
        {
          subStage: PortalAggregationSubStage.AGGREGATE,
          stage: 3,
          title: 'Query',
        },
      ],
    },
  },
};

export default InitialPortalStage;
