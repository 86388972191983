import {
  FormOutput,
  FormQuestionOutput,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';
import useForm from '../../hooks/form/UseForm';
import { showModal } from '../../store/modal';
import { fetchFormSuccess } from '../../store/project-form';
import DroppableComp from '../DragAndDrop/DroppableComp';
import { ModalTypes } from '../Modal/Modal';
import { PageType } from '../ProjectItem/ProjectItemFormStages/3-build-form/ProjectItemFormBuildStage';
import AddQuestionButton from './AddQuestionButton';
import DraggableItem from './DraggableItem';

type DroppableId = 'droppable' | 'droppable2';

interface Droppable {
  index: number;
  droppableId: DroppableId;
}
interface Droppables {
  newSource: FormQuestionOutput[];
  newDest: FormQuestionOutput[];
}
interface DragabbleInput {
  destination: Droppable;
  draggableId: string;
  reason: string;
  source: Droppable;
  type: string;
}
interface Item {
  groupName: string;
  questions: FormQuestionOutput[];
}

interface FormDragAndDropProps {
  items: { groupName: string; questions: FormQuestionOutput[] }[];
  setPageType: (value: PageType) => void;
  activeQuestionId: string;
  setActiveQuestionGroup: (value: string) => void;
  setActiveQuestionId: (value: string) => void;
}
interface Lists {
  [key: string]: FormQuestionOutput[];
}

const StyledDragAndDropContainer = styled.div`
  > p {
    padding: ${({ theme }) => `${theme.padding.standard}`};
  }
`;

const StyledGroupHeading = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.system.white};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => `${theme.padding.standard}`};
    > svg {
      opacity: 0;
    }
    :hover {
      > svg {
        transition: 0.5s;
        opacity: 1;
      }
    }
  }
`;

const QuestionGroupHeader = styled.div`
  font-weight: bold;
  > p {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledDroppable = styled.div`
  min-height: ${({ theme }) => `${theme.padding.standard}`};
`;

const QuestionItem = styled.div<{ isActive?: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.general.blue : theme.colors.system.offWhite};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.system.white : theme.colors.system.offBlack};

  svg {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.system.white : theme.colors.system.offBlack};

    transition: color 0.25s;
  }

  transition: background-color 0.25s, color 0.25s;
`;

// reorders result
const reorder = (
  list: FormQuestionOutput[],
  startIndex: number,
  endIndex: number,
): FormQuestionOutput[] => {
  const result = (list && Array.from(list)) || [];

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// moves an item from one list to another
const move = (
  source: FormQuestionOutput[],
  destination: FormQuestionOutput[],
  droppableSource: Droppable,
  droppableDestination: Droppable,
): Droppables => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);
  return { newSource: sourceClone, newDest: destClone } as Droppables;
};

const getListStyle = (isDraggingOver: boolean, themeContext: DefaultTheme) => ({
  background: isDraggingOver
    ? themeContext.colors.system.grey
    : themeContext.colors.system.offWhite,
  opacity: isDraggingOver ? '0.5' : '1',
  padding: `${({ theme }) => `${theme.padding.standard}`}`,
});

const getLists = (items: Item[]) => {
  const id2List = {} as { [key: string]: FormQuestionOutput[] };
  items.forEach((item) => (id2List[item.groupName] = item.questions));
  return id2List;
};

const getList = (id: DroppableId, lists: Lists) => (lists ? lists[id] : []);

const FormDragAndDrop: React.FC<FormDragAndDropProps> = ({
  items,
  setPageType,
  activeQuestionId,
  setActiveQuestionGroup,
  setActiveQuestionId,
}) => {
  const { form } = useForm();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const updateForm = useCallback(
    (lists: Lists) => {
      const groupNames = Object.keys(lists);
      const questions = Object.values(lists);
      const cloned = cloneDeep(form);

      const questionGroups = groupNames.map((groupName, index) => {
        return { groupName, questions: questions[index] };
      });

      const newForm = { ...cloned, questionGroups } as FormOutput;

      dispatch(fetchFormSuccess(newForm));
    },
    [dispatch, form],
  );

  const getGroupQuestionCount = (index: number) => {
    return form?.questionGroups[index]?.questions.length;
  };

  const getQuestionGroupName = (index: number) => {
    return Object.keys(lists)[index];
  };

  const [lists, setLists] = useState<Lists>(getLists(items));
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    if (changesMade) {
      form && updateForm(lists);
      setChangesMade(false);
    }
  }, [changesMade, form, lists, updateForm]);

  useEffect(() => {
    setLists(getLists(items));
  }, [items]);

  const onDragEnd = (result: DragabbleInput) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const newList = reorder(
        getList(source.droppableId, lists),
        source.index,
        destination.index,
      );
      setChangesMade(true);
      setLists({ ...lists, [source.droppableId]: newList });
    } else {
      const result = move(
        getList(source.droppableId, lists),
        getList(destination.droppableId, lists),
        source,
        destination,
      );
      setLists({
        ...lists,
        [source.droppableId]: result.newSource,
        [destination.droppableId]: result.newDest,
      });
      setChangesMade(true);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {lists &&
        Object.values(lists).map((list, index) => (
          <StyledDragAndDropContainer key={index}>
            <StyledGroupHeading
              key={index}
              onClick={() =>
                dispatch(
                  showModal({
                    visible: true,
                    modal: ModalTypes.FORM_ADD_GROUP,
                    additionalProps: {
                      existingGroup: {
                        index,
                        groupName: getQuestionGroupName(index),
                      },
                    },
                  }),
                )
              }
            >
              <QuestionGroupHeader>
                <p>{getQuestionGroupName(index)}</p> (
                {getGroupQuestionCount(index)})
                <FontAwesomeIcon
                  key={index}
                  icon={faPencilAlt}
                  color={themeContext.colors.system.offBlack}
                />
              </QuestionGroupHeader>
            </StyledGroupHeading>
            <DroppableComp
              dropComp={
                <Droppable droppableId={getQuestionGroupName(index)}>
                  {(provided, snapshot) => (
                    <StyledDroppable
                      ref={provided.innerRef}
                      style={getListStyle(
                        snapshot.isDraggingOver,
                        themeContext,
                      )}
                    >
                      {list.map((item, index) => (
                        <QuestionItem
                          isActive={activeQuestionId === item._id}
                          onClick={() => {
                            setPageType(PageType.UPDATE_QUESTION);
                            setActiveQuestionId(item._id);
                          }}
                          key={index}
                        >
                          <DraggableItem
                            item={item}
                            index={index}
                            key={index}
                          />
                        </QuestionItem>
                      ))}
                      {provided.placeholder}
                    </StyledDroppable>
                  )}
                </Droppable>
              }
            />
            <AddQuestionButton
              handleClick={() => {
                setPageType(PageType.CREATE_QUESTION);
                setActiveQuestionGroup(getQuestionGroupName(index));
              }}
            />
          </StyledDragAndDropContainer>
        ))}
    </DragDropContext>
  );
};

export default FormDragAndDrop;
