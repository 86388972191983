import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import { UserItemOutput } from '../../services/user/UserService';
import { RootState } from '../../store/rootReducer';
import { UsersState } from '../../store/users';
import { ROW_ID_FIELD } from '../Modal/cms/CMSAddRowModal';
import { cloneDeep } from 'lodash';

export interface NotificationAlertActions {
  channel: Enums.NotificationChannel;
  frequency: Enums.NotificationFrequency;
  recipients: Interfaces.Recipient[];
}

interface FormattedNotificationContentProps {
  createdBy: string;
  notificationAlertParams: Interfaces.NotificationAlertParams;
  notificationAlertActions?: NotificationAlertActions;
  isInfoMessage?: boolean;
}

export type Trigger = {
  name: string;
  condition: string;
  value: string;
};

const NOTIFICATION_CHANNEL = 'channel';
const NOTIFICATION_RECIPIENT = 'recipient';
const PERCENT_CHAR = '%';
const UNDERSCORE_CHAR = '_';

export const friendlyOperator = (operator: Enums.AggregationFilterOperator) =>
  Object.entries(Enums.AggregationFilterOperator)
    .filter(([key, val]) => val === operator)
    ?.map(([key, val]) => {
      if (
        operator === Enums.AggregationFilterOperator.EQUAL ||
        operator === Enums.AggregationFilterOperator.GREATER_THAN_EQUAL ||
        operator === Enums.AggregationFilterOperator.LESS_THAN_EQUAL
      ) {
        return key.replaceAll('_', ' ').toLowerCase().concat(' to');
      }
      return key.replaceAll('_', ' ').toLowerCase();
    })[0];

const FormattedNotificationContent: FC<FormattedNotificationContentProps> = ({
  createdBy,
  notificationAlertParams,
  notificationAlertActions,
  isInfoMessage,
}) => {
  const resource = notificationAlertParams.resource;

  const usersState: UsersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  const FormattedActions = () => {
    if (!notificationAlertActions) {
      return <></>;
    }

    const { channel, frequency, recipients } = notificationAlertActions;

    const userCount = recipients.filter(
      (r) => (r as Interfaces.UserRecipient).type === Enums.RecipientType.USER,
    ).length;

    const teamCount = recipients.filter(
      (r) => (r as Interfaces.UserRecipient).type === Enums.RecipientType.TEAM,
    ).length;

    return (
      <>
        , send a notification <strong>{frequency}</strong> by{' '}
        <strong>{channel}</strong> to{' '}
        {userCount > 0 && <strong>{userCount} recipients</strong>}{' '}
        {teamCount > 0 && (
          <strong>
            {userCount > 0 && 'and'} {teamCount} teams
          </strong>
        )}{' '}
        {userCount === 0 && teamCount === 0 && (
          <strong>
            {recipients.length}{' '}
            {channel === Enums.NotificationChannel.SLACK ||
            channel === Enums.NotificationChannel.MS_TEAMS
              ? NOTIFICATION_CHANNEL
              : NOTIFICATION_RECIPIENT}
            {recipients.length > 1 && 's'}
          </strong>
        )}
      </>
    );
  };

  const FormattedConditions = () => {
    const triggers: Trigger[] = [];
    const { queryConditions } = notificationAlertParams.conditions;
    let additionalCount = 0;

    const queryConditionsFilteredValue = (
      queryConditions?.value as Interfaces.StandardFilterField[]
    ).filter((f) => f.field !== ROW_ID_FIELD);

    const filteredQueryConditions = cloneDeep(queryConditions);
    filteredQueryConditions.value = queryConditionsFilteredValue;

    switch (resource.resourceType) {
      case Enums.SchemaName.DATASET_META:
        if (
          (filteredQueryConditions?.value as Interfaces.StandardFilterField[])
            .length === 1
        ) {
          const condition = friendlyOperator(
            (
              filteredQueryConditions?.value as unknown as Interfaces.StandardFilter[]
            )[0]?.operator,
          );
          const name = (
            filteredQueryConditions?.value as unknown as DynamicConditionalField[]
          )[0].alias;

          const value = (
            (
              (
                filteredQueryConditions?.value as unknown as Interfaces.DynamicConditional[]
              )[0].value as Interfaces.ConstantConditionalValue
            ).value as string
          )
            ?.toString()
            .replaceAll(PERCENT_CHAR, ' ')
            .replaceAll(UNDERSCORE_CHAR, ' ')
            .toLowerCase();

          name && triggers.push({ condition, name, value });
        } else {
          (
            filteredQueryConditions?.value as unknown as Interfaces.StandardFilterField[]
          ).map((row) => {
            if (triggers.length == 2) {
              additionalCount++;
              return;
            }
            const name = (row as DynamicConditionalField).alias;
            const condition = friendlyOperator(row.operator);
            const value = (
              (row.value as DynamicConditionalField).value as string
            )
              ?.toString()
              .replaceAll(`${PERCENT_CHAR}|${UNDERSCORE_CHAR}`, ' ')
              .replaceAll(UNDERSCORE_CHAR, ' ')
              .toLowerCase();

            name && triggers.push({ condition, name, value });
          });
        }
    }

    const formatUserName = (createdBy: string) => {
      const createdUser = users.find((u) => u.entity._id === createdBy);
      return (
        `${createdUser?.entity?.firstName} ${createdUser?.entity?.lastName}` ||
        'A user'
      );
    };

    return (
      <>
        {isInfoMessage &&
          createdBy &&
          `${formatUserName(
            createdBy,
          )} has created a new dataset notification for you. `}
        {notificationAlertActions || isInfoMessage ? 'When' : 'A'}{' '}
        {triggers.map((c, index) => {
          const actionText = (
            <>
              {c.name} is {c.condition} {c.value}
            </>
          );
          return (
            <span key={`trigger-${c.name}-${index}`}>
              {notificationAlertActions && <strong>{actionText}</strong>}
              {!notificationAlertActions && actionText}
              {triggers.length > 1 && index === 0 && ' and '}
              {additionalCount > 0 &&
                index !== 0 &&
                ` plus ${additionalCount} other ${
                  additionalCount > 1 ? 'conditions' : 'condition'
                }`}
            </span>
          );
        })}
      </>
    );
  };

  return (
    <>
      <FormattedConditions />
      {notificationAlertActions && <FormattedActions />}
      {isInfoMessage
        ? ', you will be notified.'
        : !notificationAlertActions && '. Click to view the data.'}
    </>
  );
};

export default FormattedNotificationContent;
