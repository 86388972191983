import {
  faCircleExclamation,
  faTriangleExclamation,
  faCircleCheck,
  faCircleInfo,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactElement } from 'react';
import Banner from '../../components/BannerComponent/Banner';
import { AlertStatus, BannerType } from '../../enums';
import { ResourceType } from '../../enums/ResourceType';
import { startCase } from 'lodash';

export default class BuildBanner {
  /**
   * Formats resource usage into string for banner message
   *
   * @param {ResourceType | string} resourceType - Resource Type to Format
   *
   * @returns {string} - Formatted Resource Type
   */
  static formatResourceType(resourceType: ResourceType | string): string {
    return startCase(
      resourceType
        ?.toString()
        .replace(/([A-Z])/g, ' $1')
        .trim(),
    );
  }

  /**
   * Formats resource usage into string for banner message
   *
   * @param {resourceType} ResourceType - Resource Type to Format
   *
   * @returns {string} - Messae
   */
  static generateResourceText(resourceType: ResourceType): string {
    switch (resourceType) {
      case ResourceType.APIS:
        return this.formatResourceType('APIS');
      case ResourceType.DATASET_METAS:
        return this.formatResourceType('Dataset');
      default:
        return resourceType.toString();
    }
  }

  /**
   * Generates icon for banner based on alert status
   *
   * @param {AlertStatus} alertStatus - Alert Status to generate icon from
   *
   * @returns {IconDefinition} - Icon for banner
   */
  static generateIcon(alertStatus: AlertStatus): IconDefinition {
    switch (alertStatus) {
      case AlertStatus.ERROR:
        return faCircleExclamation;
      case AlertStatus.WARNING:
        return faTriangleExclamation;
      case AlertStatus.SUCCESS:
        return faCircleCheck;
      case AlertStatus.INFO:
        return faCircleInfo;
      default:
        return faCircleExclamation;
    }
  }

  /**
   * Generates message for banner
   *
   * @param {AlertStatus} alertStatus - Alert Status to generate message from
   * @param {ResourceType} resourceType - Resource type to include in message
   *
   * @returns {string} - Message for Banner Component
   */
  static generateQuotaMessage(
    alertStatus: AlertStatus,
    resourceType?: ResourceType,
  ): string {
    switch (alertStatus) {
      case AlertStatus.ERROR:
        return `You have reached your ${BuildBanner.generateResourceText(
          resourceType as ResourceType,
        )} limit, Please contact your Account Manager to upgrade.`;
      case AlertStatus.WARNING:
        return `You are approaching your ${BuildBanner.generateResourceText(
          resourceType as ResourceType,
        )} limit, Please contact your Account Manager to upgrade.`;
      default:
        return '';
    }
  }

  /**
   * Generates Banner Component
   *
   * @param {BannerType} bannerType - Category of Banner to create
   * @param {AlertStatus} alertStatus - Alert Status to generate banner from
   * @param {ResourceType} [resourceType] - Resource type to include in message if needed
   * @param {string} [message] - Message to display in banner
   *
   * @returns {ReactElement} - Banner Component
   */
  static generateBanner(
    bannerType: BannerType,
    alertStatus: AlertStatus,
    resourceType?: ResourceType,
    message?: string,
  ): ReactElement | null {
    switch (bannerType) {
      case BannerType.QUOTA:
        return (
          <Banner
            message={
              message ||
              BuildBanner.generateQuotaMessage(alertStatus, resourceType)
            }
            alertIcon={BuildBanner.generateIcon(alertStatus)}
            alertStatus={alertStatus}
          />
        );
      default:
        return null;
    }
  }
}
