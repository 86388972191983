import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SearchQuery } from '../../interfaces/Search';
import {
  DefaultPopupStyles,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
} from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../store/rootReducer';

const SEARCH_TYPING_TIMEOUT = 1000;
const SEARCH_FIELD = 'name';

const datasetOptions: Map<string, DropdownItemProps> = new Map();

interface DatasetSelectDropdownProps {
  onChange: (val: string) => void;
  initialDatasetMetaId?: string;
}

const DatasetSelectDropdown: FC<DatasetSelectDropdownProps> = ({
  onChange,
  initialDatasetMetaId,
}) => {
  const dispatch = useDispatch();
  const { getDatasetMetas } = useDatasetMeta();

  const [loadingDatasetMetas, setLoadingDatasetMetas] =
    useState<boolean>(false);
  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });
  const [projectDSMId, setProjectDSMId] = useState<string>();

  const [opts, setOpts] = useState<DropdownItemProps[]>([]);

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (value !== searchQuery.query) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      setLoadingDatasetMetas(true);
      if (!searchQuery?.query?.length) {
        await getDatasetMetas({
          activeDataCollection: { $ne: null },
        });
      } else {
        await getDatasetMetas(
          { activeDataCollection: { $ne: null } },
          searchQuery?.query?.length ? searchQuery : undefined,
        );
      }

      setLoadingDatasetMetas(false);
    })();
  }, [dispatch, getDatasetMetas, searchQuery]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // Map datasetMetas to options - add to array as users may search for more options
  useEffect(() => {
    if (datasetMetas?.length) {
      datasetMetas.map((d) => {
        const ent = d.entity;

        datasetOptions.set(ent._id, {
          key: ent._id,
          value: ent._id,
          text:
            d.accessLevel === Enums.AccessLevel.NONE ? (
              <Popup
                content={'You do not have direct access to this Dataset.'}
                position="top center"
                style={DefaultPopupStyles}
                trigger={
                  <div style={{ display: 'inline-block' }}>
                    <Icon name={'attention'} />
                    {ent.name}
                  </div>
                }
              />
            ) : (
              ent.name
            ),
        });
      });

      setOpts(Array.from(datasetOptions, ([, opt]) => opt));
    }
  }, [datasetMetas]);

  const handleDatasetSelection = (val: string) => {
    setProjectDSMId(val);
    onChange(val);
  };

  return (
    <>
      <StyledBodySubHeader>Select a Dataset</StyledBodySubHeader>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={loadingDatasetMetas}
        placeholder={'Select a Dataset'}
        noResultsMessage={'Type to search Datasets'}
        selection
        search
        onSearchChange={(e, data) => onSearchValueChange(data.searchQuery)}
        clearable
        options={opts}
        value={initialDatasetMetaId || projectDSMId}
        onChange={(e, data) => handleDatasetSelection(data.value || undefined)}
      />
    </>
  );
};

export default DatasetSelectDropdown;
