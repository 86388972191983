import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialDatasetCollection, {
  DatasetCollectionState,
} from './initial-state';

const DatasetCollectionSlice = createSlice({
  name: 'datasetCollection',
  initialState: InitialDatasetCollection,
  reducers: {
    updateDatasetCollection(
      state,
      action: PayloadAction<DatasetCollectionState>,
    ) {
      state.datasetMetaId = action.payload.datasetMetaId;
      state.collectionId = action.payload.collectionId;
    },
    lockDatasetCollection(state) {
      state.collectionLocked = true;
    },
    unlockDatasetCollection(state) {
      state.collectionLocked = false;
    },
  },
});

export const {
  updateDatasetCollection,
  lockDatasetCollection,
  unlockDatasetCollection,
} = DatasetCollectionSlice.actions;

export default DatasetCollectionSlice.reducer;
