import React, { FC } from 'react';
import styled from 'styled-components';

interface PageProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Wrapper = styled.div<{ style?: React.CSSProperties }>`
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.system.white};

  ${({ style }) => `${style}`};
`;

const Page: FC<PageProps> = ({ children, style }) => {
  return <Wrapper style={style}>{children}</Wrapper>;
};

export default Page;
