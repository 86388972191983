import styled from 'styled-components';
import { StyledH3, StyledText } from '../../main/theme';

export const EmptyNotifications = styled.div`
  text-align: center;
`;

export const EmptyNotificationsImage = styled.img`
  margin-left: ${({ theme }) => theme.margin.xxlarge};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const EmptyNotificationsTitle = styled(StyledH3)`
  color: ${({ theme }) => theme.colors.system.darkGrey};
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const EmptyNotificationsText = styled(StyledText)`
  color: ${({ theme }) => theme.colors.system.darkGrey};
`;
