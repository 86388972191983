import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import { updateActiveConnection } from '../../store/project-stage';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const PortalAPINavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { portal } = usePortal();

  if (!portal?.connectionItems?.api) {
    return null;
  }

  return (
    <NavigationItems>
      {portal.connectionItems.api.map((api, i) => {
        return (
          <NavItem
            lightTheme={true}
            isActive={false}
            key={`api-${api.name}-${i}`}
            onClick={() => {
              dispatch(updateActiveConnection(api));
              history.push(
                `${RouteName.PORTAL}/${portal?._id}/${Enums.ConnectionType.API}/${api._id}`,
              );
            }}
          >
            <NavTitle lightTheme={true} text={api.name} />
          </NavItem>
        );
      })}
    </NavigationItems>
  );
};

export default PortalAPINavigation;
