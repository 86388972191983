import styled from 'styled-components';

export const ConnectionSelectionWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > div {
    text-align: center;
    margin: ${({ theme }) => `0 ${theme.margin.standard}`};

    > p {
      margin-top: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const ConnectionTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => `0 ${theme.padding.xlarge}`}
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const TableWrapper = styled.div`
  width: 100%;
`;
