import { combineReducers } from '@reduxjs/toolkit';
import APIReducer from './api';
import AggregationReducer from './aggregation';
import ChartReducer from './chart';
import ChartsReducer from './charts';
import CommentReducer from './comment';
import CommentsReducer from './comments';
import DatasetReducer from './dataset';
import DatasetCollectionReducer from './dataset-collection';
import DatasetMetaReducer from './dataset-meta';
import DatasetMetasReducer from './dataset-metas';
import DatasetSnapshotsReducer from './dataset-snapshots';
import DatasetStageReducer from './dataset-stage';
import FiltersReducer from './filters';
import GraphReducer from './graph';
import IntegrationConfigReducer from './integration-config';
import IntegrationConfigsReducer from './integration-configs';
import IntegrationStageReducer from './integration-stage';
import IntegrationTemplateReducer from './integration-template';
import IntegrationTemplatesReducer from './integration-templates';
import ListReducer from './list';
import ListStageReducer from './list-stage';
import ListsReducer from './lists';
import LoadingReducer from './loading';
import LoggedInUserReducer from './logged-in-user';
import ModalReducer from './modal';
import NotificationReducer from './notification';
import NotificationAlertReducer from './notification-alert';
import NotificationAlertsReducer from './notification-alerts';
import NotificationsReducer from './notifications';
import OrganisationReducer from './organisation';
import PipelineStageReducer from './pipeline-stage';
import PipelineTemplateReducer from './pipeline-template';
import PipelineTemplatesReducer from './pipeline-templates';
import PortalStageReducer from './portal-stage';
import ProjectReducer from './project';
import CMSReducer from './project-cms';
import FormReducer from './project-form';
import PortalReducer from './project-portal';
import ProjectStageReducer from './project-stage';
import ProjectsReducer from './projects';
import QueriesReducer from './queries';
import QueryReducer from './query';
import QuotaReducer from './quota';
import SitewideBannerReducer from './sitewide-banner';
import TeamReducer from './team';
import TeamStageReducer from './team-stage';
import TeamsReducer from './teams';
import UsageReducer from './usage';
import UserReducer from './user';
import UserStageReducer from './user-stage';
import UsersReducer from './users';

const rootReducer = combineReducers({
  apis: APIReducer,
  aggregation: AggregationReducer,
  cms: CMSReducer,
  chart: ChartReducer,
  charts: ChartsReducer,
  comment: CommentReducer,
  comments: CommentsReducer,
  dataset: DatasetReducer,
  datasetCollection: DatasetCollectionReducer,
  datasetMeta: DatasetMetaReducer,
  datasetMetas: DatasetMetasReducer,
  datasetSnapshots: DatasetSnapshotsReducer,
  datasetStage: DatasetStageReducer,
  filters: FiltersReducer,
  form: FormReducer,
  graph: GraphReducer,
  integrationStage: IntegrationStageReducer,
  integrationConfig: IntegrationConfigReducer,
  integrationConfigs: IntegrationConfigsReducer,
  integrationTemplate: IntegrationTemplateReducer,
  integrationTemplates: IntegrationTemplatesReducer,
  loading: LoadingReducer,
  loggedInUser: LoggedInUserReducer,
  modal: ModalReducer,
  notificationAlert: NotificationAlertReducer,
  notificationAlerts: NotificationAlertsReducer,
  notificationEvent: NotificationReducer,
  notificationEvents: NotificationsReducer,
  organisation: OrganisationReducer,
  list: ListReducer,
  listStage: ListStageReducer,
  lists: ListsReducer,
  pipelineStage: PipelineStageReducer,
  pipelineTemplate: PipelineTemplateReducer,
  pipelineTemplates: PipelineTemplatesReducer,
  portal: PortalReducer,
  portalStage: PortalStageReducer,
  project: ProjectReducer,
  projects: ProjectsReducer,
  projectStage: ProjectStageReducer,
  sitewideBanner: SitewideBannerReducer,
  query: QueryReducer,
  queries: QueriesReducer,
  team: TeamReducer,
  teams: TeamsReducer,
  teamStage: TeamStageReducer,
  usage: UsageReducer,
  user: UserReducer,
  users: UsersReducer,
  userStage: UserStageReducer,
  quota: QuotaReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
