import { Interfaces } from '@configur-tech/upit-core-types';
import { FormulaOperandType } from '@configur-tech/upit-core-types/lib/enums';
import React from 'react';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { defaultTheme } from '../../main/theme';
import formulaOperationDisplayMap from './FormulaOperationDisplayMap';

const NEST_START = '(';
const NEST_END = ')';

const buildFormulaOutput = (
  formula: Interfaces.FormulaOperandValue[],
  action: (item: Interfaces.FormulaOperandValue) => void,
  getActiveNest: Interfaces.FormulaOperandValue,
  schema: Interfaces.Field[],
): (React.ReactElement | null)[] => {
  return formula.map((item, i) => {
    const nestedColor =
      getActiveNest === item
        ? defaultTheme.colors.general.sea
        : defaultTheme.colors.general.yellow;

    switch (item.type) {
      case FormulaOperandType.FIELD: {
        const schemaField = schema.find(
          (sf) =>
            sf.fieldId === item.value.field || sf.name === item.value.field,
        );
        return (
          <FeatureButton
            key={`${item.type}-${i}-${Math.random() * 1000}`}
            isActive={true}
            isDisplayOnly={true}
            size={FeatureButtonSize.WIDE}
            color={defaultTheme.colors.general.blue}
            text={schemaField?.name || item.value.field}
          />
        );
      }
      case FormulaOperandType.CONSTANT:
        return (
          <FeatureButton
            key={`${item.type}-${i}-${Math.random() * 1000}`}
            isActive={true}
            isDisplayOnly={true}
            size={FeatureButtonSize.WIDE}
            color={defaultTheme.colors.general.blue}
            text={item.value.value.toString()}
            textStyle={{ fontSize: defaultTheme.typography.sizes.h5 }}
          />
        );

      case FormulaOperandType.OPERATION:
        return (
          <FeatureButton
            key={`${item.type}-${i}-${Math.random() * 1000}`}
            isActive={true}
            isDisplayOnly={true}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={defaultTheme.colors.general.purple}
            text={formulaOperationDisplayMap(item.value.operation)}
            textStyle={{ fontSize: defaultTheme.typography.sizes.h1 }}
          />
        );

      case FormulaOperandType.NESTED:
        return (
          <>
            <FeatureButton
              key={`${item.type}-${i}-${Math.random() * 1000}`}
              isActive={true}
              isDisplayOnly={true}
              size={FeatureButtonSize.EXTRA_SMALL}
              color={nestedColor}
              text={NEST_START}
              textStyle={{ fontSize: defaultTheme.typography.sizes.h1 }}
            />

            {buildFormulaOutput(
              item.value.formula,
              action,
              getActiveNest,
              schema,
            )}

            <FeatureButton
              key={`${item.type}-${i}-${Math.random() * 1000}`}
              isActive={true}
              isDisplayOnly={true}
              size={FeatureButtonSize.EXTRA_SMALL}
              color={nestedColor}
              text={NEST_END}
              textStyle={{ fontSize: defaultTheme.typography.sizes.h1 }}
            />
          </>
        );
      default:
        return null;
    }
  });
};

export default buildFormulaOutput;
