import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IntegrationConfigService, {
  IntegrationConfigItem,
  IntegrationConfigOutput,
} from '../../services/integration/IntegrationConfigService';
import { AppThunk } from '../store';
import InitialIntegrationConfig from './initial-state';

export type IntegrationConfig = IntegrationConfigItem | IntegrationConfigOutput;

export interface IntegrationConfigState {
  data: IntegrationConfig;
  loading: boolean;
  error: string | null;
}

const initialState: IntegrationConfigState = {
  data: InitialIntegrationConfig,
  loading: false,
  error: null,
};

const IntegrationConfigSlice = createSlice({
  name: 'integration-Config',
  initialState,
  reducers: {
    // Reset
    resetIntegrationConfig(state) {
      state.data = InitialIntegrationConfig;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchIntegrationConfigStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchIntegrationConfigSuccess(
      state,
      action: PayloadAction<IntegrationConfig>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchIntegrationConfigFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createIntegrationConfigStart(state) {
      state.loading = true;
      state.error = null;
    },
    createIntegrationConfigSuccess(
      state,
      action: PayloadAction<IntegrationConfigOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createIntegrationConfigFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateIntegrationConfigStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateIntegrationConfigSuccess(
      state,
      action: PayloadAction<IntegrationConfigOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateIntegrationConfigFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteIntegrationConfigStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteIntegrationConfigSuccess(
      state,
      action: PayloadAction<IntegrationConfigOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteIntegrationConfigFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetIntegrationConfig,
  fetchIntegrationConfigStart,
  fetchIntegrationConfigSuccess,
  fetchIntegrationConfigFailure,
  createIntegrationConfigStart,
  createIntegrationConfigSuccess,
  createIntegrationConfigFailure,
  updateIntegrationConfigStart,
  updateIntegrationConfigSuccess,
  updateIntegrationConfigFailure,
  deleteIntegrationConfigStart,
  deleteIntegrationConfigSuccess,
  deleteIntegrationConfigFailure,
} = IntegrationConfigSlice.actions;

export default IntegrationConfigSlice.reducer;

export const fetchIntegrationConfig =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchIntegrationConfigStart());
      const fetched = await IntegrationConfigService.getIntegrationConfig(
        token,
        id,
      );
      dispatch(fetchIntegrationConfigSuccess(fetched));
    } catch (err) {
      dispatch(fetchIntegrationConfigFailure((err as string).toString()));
      throw Error((err as string).toString());
    }
  };

export const createIntegrationConfig =
  (
    token: string,
    integrationConfig: Interfaces.IntegrationConfig,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createIntegrationConfigStart());
      const created = await IntegrationConfigService.postIntegrationConfig(
        token,
        integrationConfig,
        userId,
      );
      dispatch(createIntegrationConfigSuccess(created));
    } catch (err) {
      dispatch(createIntegrationConfigFailure((err as string).toString()));
      throw err;
    }
  };

export const updateIntegrationConfig =
  (
    token: string,
    integrationConfig: Interfaces.IntegrationConfigOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateIntegrationConfigStart());
      const updated = await IntegrationConfigService.putIntegrationConfig(
        token,
        integrationConfig,
        userId,
      );
      dispatch(updateIntegrationConfigSuccess(updated));
    } catch (err) {
      dispatch(updateIntegrationConfigFailure((err as string).toString()));
      throw err;
    }
  };

export const deleteIntegrationConfig =
  (token: string, integrationConfigId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteIntegrationConfigStart());
      const deleted = await IntegrationConfigService.deleteIntegrationConfig(
        token,
        integrationConfigId,
        userId,
      );
      dispatch(deleteIntegrationConfigSuccess(deleted));
    } catch (err) {
      dispatch(deleteIntegrationConfigFailure((err as string).toString()));
      throw err;
    }
  };
