import { Enums, Interfaces } from '@configur-tech/upit-core-types';

/**
 * Remap legacy filter query fields to new dynamic filter fields
 *
 * @param filter: Interfaces.DynamicConditional - Filter to update
 * @param fields: Interfaces.QueryField[] - Array of fields within queryParams to find datasetMetaId
 *
 * @returns Interfaces.DynamicFilterField - Updated filter field
 */
const remapLegacyFilterToDynamicFilter = (
  filter: Interfaces.DynamicConditional,
  fields: Interfaces.QueryField[],
  datasetMetaId?: string,
): Interfaces.DynamicFilterField => {
  let dsmId = datasetMetaId;

  if (
    [
      Enums.DynamicConditionalType.CONSTANT,
      Enums.DynamicConditionalType.DATE,
      Enums.DynamicConditionalType.USER,
    ].includes(
      (
        filter as unknown as Record<
          string,
          { value: { type: Enums.DynamicConditionalType; value: unknown } }
        >
      )['value']['type'] as Enums.DynamicConditionalType,
    )
  ) {
    return filter;
  }

  if (filter.field) {
    dsmId =
      datasetMetaId ||
      fields.find((f) => f.field === filter.field)?.datasetMetaId;
  }

  if (
    !Array.isArray(filter.value) ||
    (Array.isArray(filter.value) &&
      !(filter.value?.[0] as unknown as Record<string, unknown>)?.['value'])
  ) {
    return {
      field: filter.field,
      alias: filter.alias,
      operator: filter.operator,
      datasetMetaId: dsmId || '',
      value: {
        type: Enums.DynamicConditionalType.CONSTANT,
        value: filter.value,
      },
    };
  }

  if (Array.isArray(filter.value)) {
    const filterVal = {
      ...filter,

      value: filter.value.map((cf) =>
        remapLegacyFilterToDynamicFilter(
          cf as unknown as Interfaces.DynamicConditional,
          fields,
        ),
      ),
    } as unknown as Interfaces.DynamicFilterField;

    return filterVal;
  }

  return filter;
};

export default remapLegacyFilterToDynamicFilter;
