import {
  DynamicFilterField,
  FieldOperand,
  GanttGraphParams,
  GraphAxisMetric,
  GraphMetric,
  MapGraphParams,
  QueryField,
  SupercolumnField,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { cloneDeep } from 'lodash';

const renameField = (
  originalValue: string,
  newValue: string,
  field:
    | QueryField
    | {
        alias: string;
        isMeasure: true;
      },
) => {
  if (originalValue === field.alias) {
    return { ...field, alias: newValue?.trim() };
  }
  return field;
};

// Update Graph Gantt Fields
export const renameGraphGanttFields = (
  originalValue: string,
  newValue: string,
  graphParams: GanttGraphParams,
) => {
  const clonedGraphParams = cloneDeep(graphParams) as GanttGraphParams;

  clonedGraphParams.projectMetrics.endDateField.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.projectMetrics.endDateField.field,
  );

  clonedGraphParams.projectMetrics.startDateField.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.projectMetrics.startDateField.field,
  );

  clonedGraphParams.projectMetrics.nameField.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.projectMetrics.nameField.field,
  );

  clonedGraphParams.projectMetrics.statusField.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.projectMetrics.statusField.field,
  );

  return clonedGraphParams;
};

// Update Graph Map Fields
export const renameGraphMapFields = (
  originalValue: string,
  newValue: string,
  graphParams: MapGraphParams,
) => {
  const clonedGraphParams = cloneDeep(graphParams) as MapGraphParams;

  clonedGraphParams.latitudeMetric.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.latitudeMetric.field,
  );

  clonedGraphParams.longitudeMetric.field = renameField(
    originalValue,
    newValue,
    clonedGraphParams.longitudeMetric.field,
  );

  if (clonedGraphParams.markerTitleMetric) {
    clonedGraphParams.markerTitleMetric.field = renameField(
      originalValue,
      newValue,
      clonedGraphParams.markerTitleMetric.field,
    );
  }

  if (clonedGraphParams.additionalMarkers) {
    clonedGraphParams.additionalMarkers = renameQueryFields(
      originalValue,
      newValue,
      clonedGraphParams.additionalMarkers,
    );
  }

  return clonedGraphParams;
};

// Update X Axis Metric Field
export const renameGraphXMetricField = (
  originalValue: string,
  newValue: string,
  xAxisMetric: GraphAxisMetric,
) => {
  if (originalValue === xAxisMetric?.field?.alias) {
    return {
      ...xAxisMetric,
      field: { ...xAxisMetric.field, alias: newValue?.trim() },
    };
  }
  return xAxisMetric;
};

// Update Query Fields
export const renameQueryFields = (
  originalValue: string,
  newValue: string,
  fields: QueryField[] | GraphMetric[],
) => {
  return fields.map((field) => {
    if (originalValue === field.alias) {
      return { ...field, alias: newValue?.trim() };
    }
    return field;
  });
};

export const mapQueryFilters = (
  originalValue: string,
  newValue: string,
  filters: DynamicFilterField[],
) => {
  const mapQueryAlias = (filter) => {
    const updatedAlias = filter.alias === originalValue;

    return {
      ...filter,
      alias: updatedAlias ? newValue?.trim() : filter.alias?.trim(),
      value:
        !Array.isArray(filter.value) || !filter.value[0]?.operator
          ? filter.value
          : filter.value.map((v) => mapQueryAlias(v)),
    };
  };

  return filters.map((f) => mapQueryAlias(f));
};

export const renameQuerySupercolumns = (
  originalValue: string,
  newValue: string,
  superColumns: SupercolumnField[],
) => {
  return superColumns?.map((f) => {
    // find all usages of original value in supercolumns array and replace with newValue
    superColumns?.map((s) => {
      if (s.formula) {
        const updatedFormulaField = s.formula.find(
          (singleFormula) =>
            (singleFormula.value as FieldOperand).field === originalValue,
        );
        if (updatedFormulaField) {
          (updatedFormulaField.value as FieldOperand).field = newValue?.trim();
        }
      }
      if (
        s.textTransformation &&
        (s.textTransformation.params as FieldOperand).field === originalValue
      ) {
        // change field value in params object to newValue
        (s.textTransformation.params as FieldOperand).field = newValue?.trim();
      }
      if (
        s.dateConversion &&
        (s.dateConversion.params as FieldOperand).field === originalValue
      ) {
        // change field value in params object to newValue
        (s.dateConversion.params as FieldOperand).field = newValue?.trim();
      }
      return s;
    });

    if (originalValue === f.alias) {
      return { ...f, alias: newValue?.trim(), field: newValue?.trim() };
    }
    return f;
  });
};
