import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getLookupFieldType } from '../../../../util/lookup/GetLookupFieldType';
import { getMissingLookupFieldDatasetIds } from '../../../../util/lookup/GetMissingLookupFieldDatasetIds';
import getTextTransformationDependencies from '../../../../util/validate-formula/getTextTransformationDependencies';
import TextCamelCase from './TextCamelCase';
import TextCompress from './TextCompress';
import TextConcat from './TextConcat';
import TextEmptyCheck from './TextEmptyCheck';
import TextLowerCase from './TextLowerCase';
import TextSnakeCase from './TextSnakeCase';
import TextSubstring from './TextSubstring';
import TextUpperCase from './TextUpperCase';

interface AddTextSuperColProps {
  textColumnType: Enums.TextTransformOperation;
  schemaField: Interfaces.Field;
  schema: Interfaces.Field[];
  setDataValidation: React.Dispatch<
    React.SetStateAction<Interfaces.DataValidation | undefined>
  >;
  setSuperColumnValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTextSuperCol: FC<AddTextSuperColProps> = ({
  textColumnType,
  schemaField,
  schema,
  setDataValidation,
  setSuperColumnValid,
}) => {
  const { getDatasetMetas } = useDatasetMeta();
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [loaded, setLoaded] = useState<boolean>(false);

  const [concatColumnNameOptions, setConcatColumnNameOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [columnNameOptions, setColumnNameOptions] = useState<
    DropdownItemProps[]
  >([]);

  const QUERY_ROUTE = 'query';
  const isQuery =
    location.pathname.includes(RouteName.PROJECT_ITEM) ||
    location.pathname.includes(QUERY_ROUTE);

  // Fetch any missing lookup datasetMetas
  useEffect(() => {
    (async () => {
      if (!loaded) {
        // Fetch any missing lookup datasets
        const lookupIds = getMissingLookupFieldDatasetIds(schema, datasetMetas);
        if (lookupIds.length) {
          await getDatasetMetas({ _id: { $in: lookupIds } });
        }
        setLoaded(true);
      }
    })();
  });

  // Filter schema fields
  useEffect(() => {
    if (loaded && schema) {
      setConcatColumnNameOptions(
        schema
          .filter(
            (f) =>
              f.fieldId !== schemaField.fieldId || f.name !== schemaField.name,
          )
          .map((f, i) => {
            let deps: string[] = [];
            if (f.dataValidation?.textTransformation) {
              deps = getTextTransformationDependencies(
                f.dataValidation?.textTransformation,
              );
            }
            const hasCircularDeps = deps.includes(schemaField.name);

            return {
              disabled: hasCircularDeps,
              key: `column-name-${f.name}-${i}`,
              value: isQuery ? f.name : f.fieldId || f.name,
              text: f.name,
              description: hasCircularDeps ? 'References current column' : '',
            };
          }),
      );

      setColumnNameOptions(
        schema
          .filter((s) => {
            return (
              s.dataValidation?.dataValidationType &&
              [
                Enums.ValueDataType.TEXT,
                Enums.ValueDataType.PHONE_NUM,
                Enums.ValueDataType.EMAIL,
                Enums.ValueDataType.POSTCODE,
                Enums.ValueDataType.TEXT_TRANSFORMATION,
              ].includes(
                getLookupFieldType(s, datasetMetas) ||
                  s.dataValidation?.dataValidationType,
              )
            );
          })
          .map((s, i) => {
            return {
              key: `column-name-${s.name}-${i}`,
              value: isQuery ? s.name : s.fieldId || s.name,
              text: s.name,
            };
          }),
      );
    }
  }, [
    datasetMetas,
    isQuery,
    loaded,
    schema,
    schemaField.fieldId,
    schemaField.name,
  ]);

  return (
    <>
      {textColumnType === Enums.TextTransformOperation.CONCAT && (
        <TextConcat
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={concatColumnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.EMPTY_CHECK && (
        <TextEmptyCheck
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.COMPRESS && (
        <TextCompress
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.UPPER_CASE && (
        <TextUpperCase
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.LOWER_CASE && (
        <TextLowerCase
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.SNAKE_CASE && (
        <TextSnakeCase
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.CAMEL_CASE && (
        <TextCamelCase
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}

      {textColumnType === Enums.TextTransformOperation.SUBSTRING && (
        <TextSubstring
          field={schemaField}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
          columnNameOptions={columnNameOptions}
        />
      )}
    </>
  );
};

export default AddTextSuperCol;
