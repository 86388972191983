import { Enums, Interfaces } from '@configur-tech/upit-core-types';

export default class CheckColumnUse {
  /**
   * Checks if a fieldName/fieldId is used in a lookup configuration
   *
   * @param {Interfaces.FieldLookup} lookup - Lookup configuration to check against
   * @param {string} fieldName - Name of the field to check
   * @param {string} [fieldId] - ID of the field to check
   *
   * @returns {boolean} - True/false
   */
  static isUsedInLookup(
    lookup: Interfaces.FieldLookup,
    fieldName: string,
    fieldId?: string,
  ): boolean {
    return (
      (fieldId && fieldId === lookup?.local.fieldId) ||
      fieldName === lookup?.local.fieldId
    );
  }

  /**
   * Checks if a fieldName/fieldId is used in a formula configuration
   *
   * @param {Interfaces.FormulaOperandValue[]} formula - Formula configuration to check against
   * @param {string} fieldName - Name of the field to check
   * @param {string} [fieldId] - ID of the field to check
   *
   * @returns {boolean} - True/false
   */
  static isUsedInFormula(
    formula: Interfaces.FormulaOperandValue[],
    fieldName: string,
    fieldId?: string,
  ): boolean {
    return formula.some((f) => {
      if (Array.isArray((f.value as Interfaces.NestedOperation).formula)) {
        return this.isUsedInFormula(
          (f.value as Interfaces.NestedOperation).formula,
          fieldName,
          fieldId,
        );
      }

      if (
        (fieldId &&
          fieldId === (f as Interfaces.FieldFormulaOperand).value?.field) ||
        fieldName === (f as Interfaces.FieldFormulaOperand).value?.field
      ) {
        return true;
      }
    });
  }

  /**
   * Checks if a fieldName/fieldId is used in a textTransformation configuration
   *
   * @param {Interfaces.TextTransformation} textTransformation - TextTransformation configuration to check against
   * @param {string} fieldName - Name of the field to check
   * @param {string} [fieldId] - ID of the field to check
   *
   * @returns {boolean} - True/false
   */
  static isUsedInTextTransformation(
    textTransformation: Interfaces.TextTransformation,
    fieldName: string,
    fieldId?: string,
  ): boolean {
    if ((textTransformation as Interfaces.ConcatOp).params.fields) {
      return !!(textTransformation as Interfaces.ConcatOp).params.fields?.find(
        (f) => (fieldId && fieldId === f) || fieldName === f,
      );
    }

    if ((textTransformation as Interfaces.SingleFieldOp).params.field) {
      return (
        (fieldId &&
          fieldId ===
            (textTransformation as Interfaces.SingleFieldOp).params.field) ||
        fieldName ===
          (textTransformation as Interfaces.SingleFieldOp).params.field
      );
    }

    return false;
  }

  /**
   * Checks if a fieldName/fieldId is used in a dateConversion configuration
   *
   * @param {Interfaces.DateConversion} dateConversion - DateConversion configuration to check against
   * @param {string} fieldName - Name of the field to check
   * @param {string} [fieldId] - ID of the field to check
   *
   * @returns {boolean} - True/false
   */
  static isUsedInDateConversion(
    dateConversion: Interfaces.DateConversion,
    fieldName: string,
    fieldId?: string,
  ): boolean {
    if (dateConversion.type === Enums.DateConversionOperation.DATE_DIFF) {
      return (
        (fieldId &&
          (fieldId === dateConversion.params.minuendField ||
            fieldId === dateConversion.params.subtrahendField)) ||
        fieldName === dateConversion.params.minuendField ||
        fieldName === dateConversion.params.subtrahendField
      );
    }

    const op = dateConversion as Interfaces.DateExtractionOp;

    return (
      (fieldId && fieldId === op.params.field) || fieldName === op.params.field
    );
  }

  /**
   * Checks if a fieldName/fieldId is used in any type of super column
   *
   * @param {Interfaces.Field[]} schema - Schema to check against
   * @param {string} fieldName - Name of the field to check
   * @param {string} [fieldId] - ID of the field to check
   *
   * @returns {boolean} - True/false
   */
  static isUsedBySuperColumn(
    schema: Interfaces.Field[],
    fieldName: string,
    fieldId?: string,
  ): boolean {
    return schema.some((field) => {
      if (
        field.dataValidation?.fieldLookup &&
        this.isUsedInLookup(
          field.dataValidation.fieldLookup,
          fieldName,
          fieldId,
        )
      ) {
        return true;
      }

      if (
        field.dataValidation?.formula &&
        this.isUsedInFormula(field.dataValidation.formula, fieldName, fieldId)
      ) {
        return true;
      }

      if (
        field.dataValidation?.textTransformation &&
        this.isUsedInTextTransformation(
          field.dataValidation.textTransformation,
          fieldName,
          fieldId,
        )
      ) {
        return true;
      }

      if (
        field.dataValidation?.dateConversion &&
        this.isUsedInDateConversion(
          field.dataValidation.dateConversion,
          fieldName,
          fieldId,
        )
      ) {
        return true;
      }
    });
  }
}
