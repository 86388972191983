import { Enums } from '@configur-tech/upit-core-types/';
import { cloneDeep, isEqual } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import OverviewIcon from '../../assets/icons/stages/overview-project/overview.svg';
import Banner from '../../components/BannerComponent/Banner';
import DataSample from '../../components/DataSample/DataSample';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import ProjectActions from '../../components/ProjectActions/ProjectActions';
import { ACTION_ROW_HEADER } from '../../const/DataSample';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePagination, {
  DEFAULT_META_PAGE_SIZE,
  SortByItem,
} from '../../hooks/pagination/UsePagination';
import useProject from '../../hooks/project/UseProject';
import useUsage from '../../hooks/usage/useUsage';
import { SampleDataRow } from '../../interfaces/SampleData';
import { FetchParams } from '../../interfaces/Search';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { ProjectsItemOutput } from '../../services/project/ProjectService';
import { fetchProjectSuccess } from '../../store/project';
import { resetStagesAndSubStages } from '../../store/project-stage';
import InitialProject from '../../store/project/initial-project';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const SEARCH_FIELD = 'name';

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Project Name';
const TAGS_FIELD = 'Tags';
const DATASETS_FIELD = 'Connected Datasets';
const CMS_FIELD = 'CMS Count';
const API_FIELD = 'API Count';
const FORM_FIELD = 'Form Count';
const PORTAL_FIELD = 'Portal Count';

const DEPRECATION_URL =
  'https://help.goconfigur.com/knowledge/removing-projects-from-configur';

const SORT_FIELDS_MAP = {
  [NAME_FIELD]: 'name',
};

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: TAGS_FIELD },
  { name: DATASETS_FIELD },
  { name: CMS_FIELD },
  { name: API_FIELD },
  { name: FORM_FIELD },
  { name: PORTAL_FIELD },
];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const ProjectsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { getProjects } = useProject();
  const { calculateRowNumber, updateSortBy } = usePagination();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const projectsState = useSelector((state: RootState) => state.projects);
  const projects: ProjectsItemOutput = projectsState.data;

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [projectUsage, setProjectUsage] = useState<number>(0);

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 1,
    sortBy: undefined,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 0,
    sortBy: undefined,
  });

  // Get Search Value
  useEffect(
    () => {
      const search = location.search;
      const searchParams = new URLSearchParams(search).get('search');
      setFetchParams((state) => ({
        ...state,
        searchQuery: {
          ...state.searchQuery,
          query:
            searchParams === null
              ? ''
              : decodeURI(searchParams as string).toString(),
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  // Get usage and show banner if approaching quota, reset project stage
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const projectUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.PROJECTS,
        );
        setProjectUsage(projectUsage.usagePercentage);
      })();

      // Reset projects stage on initial load
      dispatch(resetStagesAndSubStages());
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  useEffect(() => {
    (async () => {
      if (
        fetchParams.searchQuery.query !== lastFetchedParams.searchQuery.query &&
        fetchParams.pageNum !== 1
      ) {
        return;
      }

      if (!isEqual(fetchParams, lastFetchedParams)) {
        setLastFetchedParams(fetchParams);

        setLoadingProjects(true);
        await getProjects(
          undefined,
          fetchParams.searchQuery?.query?.length
            ? fetchParams.searchQuery
            : undefined,
          fetchParams.pageNum,
          undefined,
          fetchParams.sortBy,
        );
        setLoadingProjects(false);
      }
    })();
  }, [
    dispatch,
    fetchParams,
    getProjects,
    lastFetchedParams,
    fetchParams.searchQuery,
    projects,
  ]);

  useEffect(() => {
    const tableData: SampleDataRow[] = [];

    projects.data.map((p, i) => {
      if (p.entity._id) {
        const cmsCount =
          p.entity.connections?.[Enums.ConnectionType.CMS]?.length || 0;
        const apiCount =
          p.entity.connections?.[Enums.ConnectionType.API]?.length || 0;
        const formCount =
          p.entity.connections?.[Enums.ConnectionType.FORM]?.length || 0;
        const portalCount =
          p.entity.connections?.[Enums.ConnectionType.PORTAL]?.length || 0;

        tableData.push({
          [ROW_NUMBER]: {
            value: calculateRowNumber(
              fetchParams.pageNum,
              i,
              DEFAULT_META_PAGE_SIZE,
            ),
          },
          [ID_FIELD]: { value: p.entity._id },
          [NAME_FIELD]: { value: p.entity.name, avatar: p.entity.avatar },
          [TAGS_FIELD]: {
            value: p.entity.tags?.map((tag) => tag.value).toString(),
            tags: {
              activeTagValue: fetchParams.searchQuery.query,
            },
          },
          [DATASETS_FIELD]: {
            value: (p.entity.datasetMetaId.length || 0).toString(),
          },
          [CMS_FIELD]: {
            value: cmsCount.toString(),
          },
          [API_FIELD]: {
            value: apiCount.toString(),
          },
          [FORM_FIELD]: {
            value: formCount.toString(),
          },
          [PORTAL_FIELD]: {
            value: portalCount.toString(),
          },
        });
      }
    });

    setTableData(tableData);
  }, [
    calculateRowNumber,
    dispatch,
    fetchParams.pageNum,
    fetchParams.searchQuery.query,
    projects.data,
  ]);

  const onPageNumberChange = useCallback(
    (page: number) => {
      if (page !== fetchParams.pageNum) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.pageNum = page;

        setFetchParams(cloned);
      }
    },
    [fetchParams],
  );
  const onSortChange = useCallback(
    (sortBy: SortByItem) => {
      if (!isEqual(updateSortBy(SORT_FIELDS_MAP, sortBy), fetchParams.sortBy)) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.sortBy = updateSortBy(SORT_FIELDS_MAP, sortBy);
        setFetchParams(cloned);
      }
    },
    [fetchParams, updateSortBy],
  );

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.PROJECTS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'View Projects'}
          image={OverviewIcon}
          title={'Project Your Data'}
        />

        <Page>
          <PageInner>
            <Banner
              message={
                <p>
                  Projects have now been removed from Configur.{' '}
                  <a target={'_blank'} href={DEPRECATION_URL}>
                    Learn more
                  </a>
                </p>
              }
              alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
              alertStatus={AlertStatus.INFO}
            />

            <StageBodyText style={{ marginTop: 20 }}>
              The table below shows all of your existing projects, but there's
              always room for more.
            </StageBodyText>
            <StageBodyText>
              Tap the button below if you'd like to create a new project, or
              select one to carry on with an existing one.
            </StageBodyText>

            {projectUsage >= QuotaLimits.WARNING && (
              <div style={{ marginBottom: themeContext.margin.xlarge }}>
                {BuildBanner.generateBanner(
                  BannerType.QUOTA,
                  projectUsage >= QuotaLimits.FULL
                    ? AlertStatus.ERROR
                    : AlertStatus.WARNING,
                  ResourceType.PROJECTS,
                )}
              </div>
            )}

            {/*Deprecated*/}
            {/*<FeatureButton*/}
            {/*  action={() => {*/}
            {/*    // Reset project and stage data*/}
            {/*    dispatch(resetStagesAndSubStages());*/}
            {/*    dispatch(fetchProjectSuccess(InitialProject));*/}
            {/*    history.push(RouteName.PROJECT_ITEM);*/}
            {/*  }}*/}
            {/*  size={FeatureButtonSize.WIDE}*/}
            {/*  color={themeContext.colors.general.blue}*/}
            {/*  text={'Create new project'}*/}
            {/*  containerStyle={{ marginBottom: themeContext.margin.large }}*/}
            {/*  isDisabled={projectUsage >= QuotaLimits.FULL}*/}
            {/*/>*/}

            {projects?.data?.length > 0 && (
              <EntityFilter
                baseRoute={RouteName.PROJECTS}
                title={'Search Projects'}
                inputPlaceholder={'Enter a project name or tag'}
                totalResultCount={projectsState.data.pagination.totalCount}
                filteredResultCount={projects?.data?.length}
                loading={loadingProjects}
                search={fetchParams.searchQuery.query}
              />
            )}

            <TableWrapper>
              <DataSample
                loading={loadingProjects}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                iconMap={AvatarIconMap}
                resetPagination={
                  lastFetchedParams.searchQuery.query !==
                  fetchParams.searchQuery.query
                }
                actions={(entityId: string) => (
                  <ProjectActions entityId={entityId} />
                )}
                showPagination={true}
                paginationAction={onPageNumberChange}
                pageSize={DEFAULT_META_PAGE_SIZE}
                totalRows={projectsState?.data.pagination.totalCount}
                sortAction={onSortChange}
                hideSortOnCols={[
                  ROW_NUMBER,
                  DATASETS_FIELD,
                  TAGS_FIELD,
                  CMS_FIELD,
                  API_FIELD,
                  FORM_FIELD,
                  PORTAL_FIELD,
                  ACTION_ROW_HEADER,
                ]}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default ProjectsPage;
