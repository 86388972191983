import React, { FC } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader/PageHeader';
import useSiteWideBanner from '../hooks/sitewide-banner/useSitewideBanner';

interface ThemeProps {
  children: React.ReactNode;
}

const Wrapper = styled.div<{ showBanner?: boolean }>`
  display: grid;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 1fr;

  ${({ showBanner }) =>
    showBanner ? ` height: calc(100vh - 58px);` : `height: 100vh;`};
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
`;

const PageContainer: FC<ThemeProps> = ({ children }) => {
  const { isSitewideBanner } = useSiteWideBanner();
  return (
    <Wrapper showBanner={isSitewideBanner}>
      <PageHeader />
      <PageContent>{children}</PageContent>
    </Wrapper>
  );
};

export default PageContainer;
