import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import configurLogo from '../../assets/icons/notifications/configur-icon.svg';
import defaultLogo from '../../assets/icons/system/codex-icon.svg';
import emailLogo from '../../assets/icons/notifications/email-icon.svg';
import slackLogo from '../../assets/icons/notifications/slack-icon.svg';
import teamsLogo from '../../assets/icons/notifications/teams-icon.svg';
import * as SC from './styled';

export interface NotificationIconProps {
  channel: Enums.NotificationChannel;
}

const NotificationIcon: FC<NotificationIconProps> = ({ channel }) => {
  const icon =
    channel === Enums.NotificationChannel.IN_APP
      ? configurLogo
      : channel === Enums.NotificationChannel.EMAIL
      ? emailLogo
      : channel === Enums.NotificationChannel.SLACK
      ? slackLogo
      : channel === Enums.NotificationChannel.MS_TEAMS
      ? teamsLogo
      : defaultLogo;

  return <SC.CardLogo image={icon} />;
};

export default NotificationIcon;
