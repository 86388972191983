import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { EntityType, RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { updateActiveDatasetStage } from '../../../../store/dataset-stage';
import { AdditionalDatasetStages } from '../../../../store/dataset-stage/initial-state';
import { showModal } from '../../../../store/modal';
import { resetStagesAndSubStages } from '../../../../store/pipeline-stage';
import ActionBar from '../../../ActionBar/ActionBar';
import AdvancedSetting from '../../../AdvancedSetting/AdvancedSetting';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';

const PipelineConfigurationAdvancedStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { datasetMeta } = useDatasetMeta();
  const { pipelineTemplate } = usePipelineTemplate();

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        <AdvancedSetting
          action={() =>
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.DELETION,
                additionalProps: {
                  entityType: EntityType.PIPELINE,
                  entityId: pipelineTemplate?._id,
                  entityName: pipelineTemplate?.name,
                },
              }),
            )
          }
          headerText={'Delete Pipeline'}
          subHeaderText={`Delete your pipeline completely.`}
          bodyText={`This will destroy all pipeline data and may break any integrations or connections that this pipeline is linked to. Be absolutely sure you wish to delete this pipeline before proceeding.`}
          actionButtonText={'Delete Pipeline'}
          actionButtonColor={themeContext.colors.general.red}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(resetStagesAndSubStages);
              dispatch(
                updateActiveDatasetStage(AdditionalDatasetStages.PIPELINES),
              );
              history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Pipelines'}
          />
        }
      />
    </StageWrapper>
  );
};

export default PipelineConfigurationAdvancedStage;
