import { Enums } from '@configur-tech/upit-core-types';
import { faDownload, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import useDatasetExporter from '../../hooks/dataset-exporter/UseDatasetExporter';
import { SnapshotItemOutput } from '../../services/dataset-snapshot/DatasetSnapshotService';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';
import * as SC from './styled';

interface SnapshotsActionsProps {
  entityId: string;
}

const SnapshotsActions: FC<SnapshotsActionsProps> = ({ entityId }) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { downloadSnapshot } = useDatasetExporter();

  const datasetSnapshotsState = useSelector(
    (state: RootState) => state.datasetSnapshots,
  );
  const datasetSnapshots: SnapshotItemOutput[] = datasetSnapshotsState.data;

  return (
    <SC.SnapshotsIconWrapper>
      <FeatureButton
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.green}
        action={() => {
          const snapshot = datasetSnapshots?.find(
            (d) => d.entity._id === entityId,
          )?.entity;

          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.SNAPSHOT,
              additionalProps: {
                existingSnapshot: {
                  entityId,
                  snapshot,
                },
              },
            }),
          );
        }}
        icon={
          <FontAwesomeIcon
            icon={faEye}
            color={themeContext.colors.general.white}
            size={'1x'}
          />
        }
      />
      <FeatureButton
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.blue}
        action={async () => {
          dispatch(showLoading({ text: 'Exporting Data...' }));
          try {
            await downloadSnapshot(entityId, Enums.AcceptedFileType.CSV, false);

            toast.success(
              'Export requested successfully! Your file will be emailed to you once ready.',
            );
          } catch (err) {
            toast.error('Export requested failed. Please try again later.');
          }
          dispatch(hideLoading());
        }}
        icon={
          <FontAwesomeIcon
            icon={faDownload}
            color={themeContext.colors.general.white}
            size={'1x'}
          />
        }
      />
    </SC.SnapshotsIconWrapper>
  );
};

export default SnapshotsActions;
