import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const OptionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: ${({ theme }) => theme.padding.xlarge};

  margin-bottom: ${({ theme }) => theme.margin.large};

  background-color: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const OptionalFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const OptionalField = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 22%;
  max-width: 45%;

  text-align: center;

  margin-right: ${({ theme }) => theme.margin.xxlarge};

  &:last-of-type {
    margin-right: 0;
  }

  > .input {
    width: auto;
  }
`;
