import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PipelineTemplateService, {
  PipelineTemplateItem,
  PipelineTemplateOutput,
} from '../../services/pipeline/PipelineTemplateService';
import { AppThunk } from '../store';
import InitialPipelineTemplate from './inital-state';

type PipelineTemplate = PipelineTemplateItem | PipelineTemplateOutput;

export interface PipelineTemplateState {
  data: PipelineTemplate;
  loading: boolean;
  error: string | null;
}

const initialState: PipelineTemplateState = {
  data: InitialPipelineTemplate,
  loading: false,
  error: null,
};

const PipelineTemplateSlice = createSlice({
  name: 'pipeline-template',
  initialState,
  reducers: {
    // Reset
    resetPipelineTemplate(state) {
      state.data = InitialPipelineTemplate;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchPipelineTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPipelineTemplateSuccess(
      state,
      action: PayloadAction<PipelineTemplate>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchPipelineTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createPipelineTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    createPipelineTemplateSuccess(
      state,
      action: PayloadAction<PipelineTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createPipelineTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updatePipelineTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    updatePipelineTemplateSuccess(
      state,
      action: PayloadAction<PipelineTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updatePipelineTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deletePipelineTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    deletePipelineTemplateSuccess(
      state,
      action: PayloadAction<PipelineTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deletePipelineTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetPipelineTemplate,
  fetchPipelineTemplateStart,
  fetchPipelineTemplateSuccess,
  fetchPipelineTemplateFailure,
  createPipelineTemplateStart,
  createPipelineTemplateSuccess,
  createPipelineTemplateFailure,
  updatePipelineTemplateStart,
  updatePipelineTemplateSuccess,
  updatePipelineTemplateFailure,
  deletePipelineTemplateStart,
  deletePipelineTemplateSuccess,
  deletePipelineTemplateFailure,
} = PipelineTemplateSlice.actions;

export default PipelineTemplateSlice.reducer;

export const fetchPipelineTemplate =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchPipelineTemplateStart());
      const fetched = await PipelineTemplateService.getPipelineTemplate(
        token,
        id,
      );
      dispatch(fetchPipelineTemplateSuccess(fetched));
    } catch (err) {
      dispatch(fetchPipelineTemplateFailure((err as string).toString()));
      throw Error((err as string).toString());
    }
  };

export const createPipelineTemplate =
  (
    token: string,
    integrationTemplate: Interfaces.Pipeline,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPipelineTemplateStart());
      const created = await PipelineTemplateService.postPipelineTemplate(
        token,
        integrationTemplate,
        userId,
      );
      dispatch(createPipelineTemplateSuccess(created));
    } catch (err) {
      dispatch(createPipelineTemplateFailure((err as string).toString()));
      throw err;
    }
  };

export const updatePipelineTemplate =
  (
    token: string,
    integrationTemplate: Interfaces.PipelineOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePipelineTemplateStart());
      const updated = await PipelineTemplateService.putPipelineTemplate(
        token,
        integrationTemplate,
        userId,
      );
      dispatch(updatePipelineTemplateSuccess(updated));
    } catch (err) {
      dispatch(updatePipelineTemplateFailure((err as string).toString()));
      throw err;
    }
  };

export const deletePipelineTemplate =
  (token: string, integrationTemplateId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deletePipelineTemplateStart());
      const deleted = await PipelineTemplateService.deletePipelineTemplate(
        token,
        integrationTemplateId,
        userId,
      );
      dispatch(deletePipelineTemplateSuccess(deleted));
    } catch (err) {
      dispatch(deletePipelineTemplateFailure((err as string).toString()));
      throw err;
    }
  };
