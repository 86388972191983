import React, { FC, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums';
import { StyledBodySubHeader, StyledInput, StyledText } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

interface DeleteConfirmationProps {
  entityType: EntityType;
  entityName?: string;
  fullScreenView?: boolean;
  requireConfirmation?: boolean;
  cancelAction: () => void;
  deleteAction: () => void;
}

const CONFIRMATION_VALUE = 'confirm';

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  entityType,
  entityName,
  fullScreenView,
  requireConfirmation = true,
  cancelAction,
  deleteAction,
}) => {
  const themeContext = useContext(ThemeContext);

  const [confirmationValue, setConfirmationValue] = useState<string>();

  return (
    <SC.Container fullScreenView={fullScreenView}>
      <SC.Content fullScreenView={fullScreenView}>
        <StyledText>
          Are you sure you wish to delete{' '}
          {entityName ? (
            <strong>{entityName}</strong>
          ) : (
            <>
              this <strong>{entityType}</strong>
            </>
          )}
          ?
        </StyledText>
        {requireConfirmation && (
          <>
            <StyledText>
              To confirm this,{' '}
              {entityName ? (
                <>enter the {entityType}'s name</>
              ) : (
                <strong>{CONFIRMATION_VALUE}</strong>
              )}{' '}
              in the box below.
            </StyledText>

            <StyledBodySubHeader>Confirm Deletion</StyledBodySubHeader>

            <StyledInput
              placeholder={
                entityName
                  ? `Enter the ${entityType}'s name`
                  : `Enter '${CONFIRMATION_VALUE}'`
              }
              value={confirmationValue || ''}
              onChange={(e, data) => setConfirmationValue(data.value)}
            />
          </>
        )}
      </SC.Content>
      <SC.ActionButtonWrapper fullScreenView={fullScreenView}>
        <FeatureButton
          action={cancelAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={
            requireConfirmation
              ? entityName
                ? confirmationValue !== entityName
                : confirmationValue !== CONFIRMATION_VALUE
              : false
          }
          action={deleteAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.red}
          text={`Delete ${entityType}`}
        />
      </SC.ActionButtonWrapper>
    </SC.Container>
  );
};

export default DeleteConfirmation;
