import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { MapGraphParams } from '@configur-tech/upit-core-types/lib/interfaces';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, isEqual, startCase } from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, DropdownItemProps, Tab } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../../../enums/ProjectStage';
import useChart from '../../../../hooks/chart/UseChart';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import useDataset from '../../../../hooks/dataset/UseDataset';
import useFilter, {
  DynamicConditionalField,
} from '../../../../hooks/filter/UseFilter';
import useGraph, { FIELD } from '../../../../hooks/graph/UseGraph';
import useGraphBuilderAccordion from '../../../../hooks/graph/useGraphAccordion';
import useList from '../../../../hooks/list/UseList';
import useProject from '../../../../hooks/project/UseProject';
import { SampleData } from '../../../../interfaces/SampleData';
import {
  StageWrapper,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { DatasetState, GRAPH_RAW_DATA } from '../../../../store/dataset';
import {
  GraphOutputWithDetails,
  fetchGraphSuccess,
} from '../../../../store/graph';
import {
  InitialGanttParams,
  InitialGraphParams,
  InitialMapParams,
} from '../../../../store/graph/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectChartSubStage } from '../../../../store/project-stage/initial-state';
import { RootState } from '../../../../store/rootReducer';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import ActionBar from '../../../ActionBar/ActionBar';
import { GroupItem } from '../../../Aggregation/AggregationGroup';
import AggregationSort, {
  SortItem,
} from '../../../Aggregation/AggregationSort';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import GraphDisplay from '../../../GraphDisplay/GraphDisplay';
import { PopupColorPicker } from '../../../PopupColorPicker/PopupColorPicker';
import { AggregateStage } from '../../ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import { PageContent } from '../../ProjectItemChartStage/styled';
import * as SC from '../styled';
import GanttChartSetup, { GRAPH_PROJECT_METRICS } from './GanttChartSetup';

const DATASET_LIMIT = 300;
const GRAPH_TITLE_FIELD = 'title';
const GRAPH_TYPE_FIELD = 'graphType';
const GRAPH_SHOW_LEGEND_FIELD = 'showLegend';
const GRAPH_XAXIS_METRIC_FIELD = 'xAxisMetric';
const GRAPH_XAXIS_LABEL_FIELD = 'xAxisLabel';
const GRAPH_YAXIS_LABEL_FIELD = 'yAxisLabel';
const GRAPH_METRICS_FIELD = 'graphMetrics';
const GRAPH_PARAMS_FIELD = 'graphParams';
const GRAPH_LAT_FIELD = 'latitudeMetric';
const GRAPH_LONG_FIELD = 'longitudeMetric';
const GRAPH_MARKER_TITLE_FIELD = 'markerTitleMetric';
const GRAPH_RADIUS_FIELD = 'radiusMeters';
const GRAPH_MARKER_COLOUR_METRIC_FIELD = 'markerColourMetric';
const GRAPH_TEXT_OPTS_FIELD = 'graphTextOpts';

const DETAILS_ACCORDION_INDEX = 0;
const GRAPH_METRICS_ACCORDION_INDEX = 1;
export const PROJECT_METRICS_ACCORDION_INDEX = 2;
export const TASK_METRICS_ACCORDION_INDEX = 3;

const COLOUR_KEY_WHITE = 'white';

const TAB_INDEX_DATA = 0;
const TAB_INDEX_GRAPH = 1;

const TYPING_TIMEOUT = 1000;

const graphTypeOptions: DropdownItemProps[] = Object.entries(
  Enums.GraphType,
).map(([key, val]) => {
  return {
    key: `graph-type-${key}`,
    value: val,
    text: startCase(val.toLowerCase()),
  };
}, []);

const ProjectItemAggregationAggregateStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { chart, editChart } = useChart();
  const { graph, graphAccessLevel, buildGraphFilters } = useGraph();
  const { getDatasetMetas } = useDatasetMeta();
  const { project, projectAccessLevel } = useProject();
  const { buildAccordionPanel } = useGraphBuilderAccordion();
  const {
    replaceDatasetFilters,
    getDatasetFilters,
    conditionalFieldToFilterFieldMap,
    filterFieldToConditionalFieldMap,
  } = useFilter();
  const { getLists } = useList();
  const { getGraphDataset } = useDataset();
  const { createTableData } = useTableData();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [listValueIds, setListValueIds] = useState<string[]>([]);
  const [listValueMap, setListValueMap] = useState<
    { listId: string; datasetMetaId: string; field: string }[]
  >([]);

  const [filters, setFilters] = useState<DynamicConditionalField[]>();
  const [fieldItems, setFieldItems] = useState<Interfaces.FieldOutput[]>([]);
  const [groupItems, setGroupItems] = useState<GroupItem[]>([]);

  const [additionalMarkerItems, setAdditionalMarkerItems] = useState<
    SortItem[]
  >([]);
  const [sortItems, setSortItems] = useState<SortItem[]>([]);

  const [groupOptions, setGroupOptions] = useState<DropdownItemProps[]>([]);
  const [colourGroupOptions, setColourGroupOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [graphAccordionOpenIndexes, setGraphAccordionOpenIndexes] = useState<
    number[]
  >([0, 1]);

  const [graphXMetrics, setGraphXMetrics] = useState<
    Interfaces.GraphAxisMetric[]
  >([]);
  const [graphMetrics, setGraphMetrics] = useState<Interfaces.GraphMetric[]>(
    [],
  );

  const isMultiple =
    graph?.queryParams?.aggregationType === Enums.AggregationType.MULTIPLE;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleData, setSampleData] = useState<Record<string, unknown>[]>();
  const [loadedSampleData, setLoadedSampleData] = useState<boolean>();
  const [entrySchema, setEntrySchema] = useState<Interfaces.SchemaField[]>([]);
  const [tableData, setTableData] = useState<SampleData>();

  const [graphData, setGraphData] = useState<Record<string, unknown>[]>();
  const [graphDataReloaded, setGraphDataReloaded] = useState<boolean>();

  const [graphTitle, setGraphTitle] = useState<string>();
  const [graphXLabel, setGraphXLabel] = useState<string>();
  const [graphYLabel, setGraphYLabel] = useState<string>();
  const [graphMapRadius, setGraphMapRadius] = useState<number>(0);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleChangeGanttMetric = useCallback(
    (
      metricType: string,
      field: string,
      val: Interfaces.GraphMetric[] | Interfaces.GanttProjectTaskLink | string,
    ) => {
      const cloned = cloneDeep(graph);

      if (metricType === GRAPH_PROJECT_METRICS) {
        cloned[GRAPH_PARAMS_FIELD][metricType][field] = graphXMetrics.find(
          (m) => m.field.alias === val,
        );
      } else {
        cloned[GRAPH_PARAMS_FIELD][metricType][field] = val;
      }

      dispatch(
        fetchGraphSuccess({
          entity: cloned,
          accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );
    },
    [dispatch, graph, graphAccessLevel, graphXMetrics],
  );

  const updateGraph = (graph: GraphOutputWithDetails) => {
    dispatch(
      fetchGraphSuccess({
        entity: graph,
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  const handleChangeMetric = useCallback(
    (val: Interfaces.GraphMetric[]) => {
      const cloned = cloneDeep(graph);
      cloned[GRAPH_PARAMS_FIELD][GRAPH_METRICS_FIELD] = val;

      dispatch(
        fetchGraphSuccess({
          entity: cloned,
          accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );

      setGraphMetrics(val);
    },
    [dispatch, graph, graphAccessLevel],
  );

  const handleMetricChangeActive = useCallback(
    (active, index) => {
      const cloned = cloneDeep(graphMetrics);
      cloned[index].active = active;

      handleChangeMetric(cloned);
    },
    [graphMetrics, handleChangeMetric],
  );

  const handleMetricChangeAllActive = useCallback(
    (active) => {
      const cloned = cloneDeep(graphMetrics);
      cloned.map((metric) => (metric.active = active));

      handleChangeMetric(cloned);
    },
    [graphMetrics, handleChangeMetric],
  );

  const handleMetricChangeColour = useCallback(
    (colour, index) => {
      const cloned = cloneDeep(graphMetrics);
      cloned[index].colour = colour;

      handleChangeMetric(cloned);
    },
    [graphMetrics, handleChangeMetric],
  );

  // Build tableData
  useEffect(() => {
    if (!entrySchema || !sampleData) {
      setTableData(undefined);
      return;
    }

    setTableData(createTableData(entrySchema, sampleData));
  }, [entrySchema, sampleData, createTableData]);

  // Allow user to finish typing before setting text values
  useEffect(() => {
    if (!graph) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      const cloned = cloneDeep(graph);

      if (
        graphTitle !== graph.graphParams?.graphTextOpts?.title ||
        graphXLabel !== graph.graphParams?.graphTextOpts?.xAxisLabel ||
        graphYLabel !== graph.graphParams?.graphTextOpts?.yAxisLabel
      ) {
        cloned[GRAPH_PARAMS_FIELD][GRAPH_TEXT_OPTS_FIELD][GRAPH_TITLE_FIELD] =
          graphTitle;

        cloned[GRAPH_PARAMS_FIELD][GRAPH_TEXT_OPTS_FIELD][
          GRAPH_XAXIS_LABEL_FIELD
        ] = graphXLabel;

        cloned[GRAPH_PARAMS_FIELD][GRAPH_TEXT_OPTS_FIELD][
          GRAPH_YAXIS_LABEL_FIELD
        ] = graphYLabel;

        dispatch(
          fetchGraphSuccess({
            entity: cloned,
            accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
          }),
        );
      }
    }, TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [
    dispatch,
    graph,
    graphAccessLevel,
    graphMapRadius,
    graphTitle,
    graphXLabel,
    graphYLabel,
  ]);

  // Allow user to finish typing before resetting radius values
  useEffect(() => {
    if (!graph) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (
        graphMapRadius !== (graph.graphParams as MapGraphParams)?.radiusMeters
      ) {
        handleChange(GRAPH_RADIUS_FIELD, graphMapRadius || 0);
      }
    }, TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, graph, graphMapRadius]);

  // If chart changes, re-fetch graph from chart
  useEffect(() => {
    if (chart?._id && graph?._id) {
      const chartGraph = chart.graphs.find((g) => g._id === graph._id);

      if (chartGraph) {
        dispatch(
          fetchGraphSuccess({
            accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
            entity: chartGraph,
          }),
        );
      }
    }
  }, [chart?._id, chart?.graphs, dispatch, graph?._id, graphAccessLevel]);

  // Fetch initial sample data
  useEffect(() => {
    if (graph?._id && filters && !loadedSampleData) {
      (async () => {
        await getGraphDataset(
          graph._id,
          filters || [],
          undefined,
          undefined,
          true,
        );
      })();
      setLoadedSampleData(true);
    }
  }, [filters, getGraphDataset, graph?._id, loadedSampleData]);

  // Get graph and sample data from datasetState
  useEffect(() => {
    if (!graph?._id) {
      return;
    }

    const graphData = datasetState?.data?.[graph._id]?.entries || [];
    const graphDataRaw =
      datasetState?.data?.[`${graph._id}${GRAPH_RAW_DATA}`]?.entries || [];

    setGraphData(graphData);
    setGraphDataReloaded(true);

    if (!graphDataRaw.length) {
      return setSampleData(undefined);
    }

    setSampleData(graphDataRaw);

    let schema: Interfaces.SchemaField[];

    if (graph?.queryParams.displayOrder?.length) {
      // Has user defined display order
      schema = graph.queryParams.displayOrder
        ?.map((displayOrderItem) => {
          const entry = Object.entries(graphDataRaw[0]).find(
            ([key]) => key === displayOrderItem.alias,
          );

          return {
            field: displayOrderItem.alias || displayOrderItem.field,
            alias: displayOrderItem.alias || displayOrderItem.field,
            type: [
              Services.validators.DataValidator.isNumber(entry?.[1])
                ? Enums.ValueDataType.NUMBER
                : Enums.ValueDataType.TEXT,
            ],
          };
        })
        .concat(
          // Add additional measures that haven't been ordered
          (graph?.queryParams.measures || [])
            .filter(
              (measure) =>
                !graph.queryParams.displayOrder?.find(
                  (displayOrderItem) =>
                    displayOrderItem.alias === measure.alias,
                ),
            )
            .map((measure) => {
              return {
                field: measure.alias || measure.field,
                alias: measure.alias || measure.field,
                type: [Enums.ValueDataType.TEXT],
              };
            }),
        )
        .concat(
          // Add additional measures that haven't been ordered
          (graph?.queryParams.supercolumns || [])
            .filter(
              (supercol) =>
                !graph.queryParams.displayOrder?.find(
                  (displayOrderItem) =>
                    displayOrderItem.alias === supercol.alias,
                ),
            )
            .map((supercol) => {
              return {
                field: supercol.alias || supercol.field,
                alias: supercol.alias || supercol.field,
                type: [Enums.ValueDataType.TEXT],
              };
            }),
        );
    } else {
      schema = Object.keys(graphDataRaw[0]).map((k) => {
        return {
          field: k,
          alias: k,
          type: [
            Services.validators.DataValidator.isNumber(k)
              ? Enums.ValueDataType.NUMBER
              : Enums.ValueDataType.TEXT,
          ],
        };
      });
    }

    setEntrySchema(schema);
  }, [
    datasetState?.data,
    graph?._id,
    graph?.queryParams.displayOrder,
    graph?.queryParams.measures,
    graph?.queryParams.supercolumns,
  ]);

  const graphKeys = useMemo(() => {
    if (graphData) {
      const keys = new Set();
      const graphKeys = graphData.flatMap(Object.keys);
      graphKeys.map((r) => keys.add(r));

      return Array.from(keys);
    }
  }, [graphData]);

  const graphParams = graph?.graphParams || InitialGraphParams;
  const ganttParams = graph?.graphParams as Interfaces.GanttGraphParams;
  const mapParams = graph?.graphParams as Interfaces.MapGraphParams;

  const mapComplete =
    !!mapParams?.latitudeMetric?.field?.alias &&
    !!mapParams?.longitudeMetric?.field?.alias &&
    !!mapParams?.markerTitleMetric?.field?.alias;

  const ganttComplete =
    !!ganttParams?.projectMetrics?.nameField?.field[FIELD] &&
    !!ganttParams?.projectMetrics?.statusField?.field[FIELD] &&
    !!ganttParams?.projectMetrics?.startDateField?.field[FIELD] &&
    !!ganttParams?.projectMetrics?.endDateField?.field[FIELD];

  useEffect(() => {
    if (!graph?._id || !graphKeys) {
      return;
    }

    if (graphDataReloaded) {
      if (
        graph.graphParams.graphType !== Enums.GraphType.MAP &&
        graph.graphParams.graphType !== Enums.GraphType.GANTT
      ) {
        const graphParams = graph.graphParams as Interfaces.GraphParams;

        let metricColourIndex;
        const themeColourArray = Object.keys(themeContext.colors.general)
          .filter((c) => c !== COLOUR_KEY_WHITE)
          .map((k) => themeContext.colors.general[k]);

        const xMetricAlias = graphParams.xAxisMetric?.field?.alias;

        const graphMetricsItems = graphKeys
          .filter((k) => k != xMetricAlias)
          .map((e) => {
            metricColourIndex < themeColourArray.length - 1
              ? metricColourIndex++
              : (metricColourIndex = 0);

            return {
              alias: e,
              colour:
                graphParams.graphMetrics?.find((m) => m.alias === e)?.colour ||
                themeColourArray[metricColourIndex],

              active:
                (
                  graphParams.graphMetrics?.find(
                    (m) => m.alias === e,
                  ) as Interfaces.GraphMetric
                )?.active || false,
            };
          });

        // Set active if only one graph metric
        if (graphMetricsItems.length === 1) {
          graphMetricsItems.map((m) => (m.active = true));
        }

        if (!isEqual(graphMetricsItems, graphMetrics)) {
          setGraphMetrics(graphMetricsItems as Interfaces.GraphMetric[]);
        }

        setGraphXLabel(graph.graphParams?.graphTextOpts?.xAxisLabel);
        setGraphYLabel(graph.graphParams?.graphTextOpts?.yAxisLabel);
      }

      setGraphTitle(graph.graphParams?.graphTextOpts?.title);
      setGraphMapRadius(
        (graph.graphParams as MapGraphParams)?.radiusMeters || 0,
      );
      setGraphDataReloaded(false);
    }
  }, [
    graph?._id,
    graphDataReloaded,
    graph?.graphParams,
    graphMetrics,
    graphKeys,
    themeContext.colors.general,
    mapParams,
  ]);

  // Fetch active filters
  useEffect(() => {
    if (!graph?._id) {
      return;
    }

    const activeFilters = getDatasetFilters(graph._id).reduce((acc, filter) => {
      if ((filter.value as DynamicConditionalField[]).length) {
        const active = (filter.value as DynamicConditionalField[]).filter(
          (f) => f.active,
        );

        if (active.length) {
          return [...acc, { ...filter, value: active }];
        }
      }

      return acc;
    }, [] as DynamicConditionalField[]);

    setFilters(activeFilters);
  }, [getDatasetFilters, graph?._id, graph]);

  // If schema contains listValues constraint - go get it
  useEffect(() => {
    if (listValueIds.length) {
      (async () => {
        await getLists({ _id: { $in: listValueIds } });
      })();
    }
  }, [listValueIds, getLists]);

  const handleMapMarkerChange = (items: SortItem[] = []) => {
    const cloned = cloneDeep(graph);
    cloned.graphParams.additionalMarkers = items.filter((i) => i.active);

    const colourMetric = items.find(
      (i) => i.field === cloned.graphParams.markerColourMetric?.field.field,
    );
    if (!colourMetric?.active) {
      // Remove marker column
      cloned.graphParams.markerColourMetric = undefined;
    }

    dispatch(
      fetchGraphSuccess({
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
    setAdditionalMarkerItems(items);
  };

  const processAction = async (
    tabIndex?: number,
    reloadGraphData?: boolean,
    complete?: boolean,
    updatedGraph?: GraphOutputWithDetails,
  ) => {
    if (graph) {
      dispatch(showLoading({ text: 'Saving Graph...' }));
      // Save filters
      const clonedGraph = cloneDeep(updatedGraph ? updatedGraph : graph);

      // Map to filterField
      clonedGraph.queryParams.filters = filters?.map((f) =>
        conditionalFieldToFilterFieldMap(
          f,
          clonedGraph.queryParams.aggregationType ===
            Enums.AggregationType.MULTIPLE,
        ),
      );

      const cloned = cloneDeep(chart);
      const editingGraphIndex = cloned.graphs.findIndex(
        (g) => g._id === graph._id,
      );
      cloned.graphs[editingGraphIndex] = clonedGraph;
      await editChart(cloned);

      if (complete) {
        dispatch(updateActiveProjectStage(ProjectStage.CHARTS));
        dispatch(updateActiveProjectSubStage(ProjectChartSubStage.CHART));
      } else {
        // Reload data
        const filters = buildGraphFilters();
        if (tabIndex === TAB_INDEX_GRAPH) {
          if (reloadGraphData) {
            await getGraphDataset(graph._id, filters);
          }
        } else {
          await getGraphDataset(graph._id, filters, undefined, undefined, true);
        }
      }
      dispatch(hideLoading());
    }
  };

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          undefined,
          undefined,
          DATASET_LIMIT,
        );
        setIsLoading(false);
      }
    })();
  }, [dispatch, getDatasetMetas, project]);

  const isRechartsGraph = useMemo(() => {
    return (
      graph?.graphParams.graphType !== Enums.GraphType.MAP &&
      graph?.graphParams.graphType !== Enums.GraphType.TABLE &&
      graph?.graphParams.graphType !== Enums.GraphType.GANTT
    );
  }, [graph?.graphParams.graphType]);

  const createAggregationOptions = useCallback(() => {
    const fieldI: (Interfaces.FieldOutput & {
      datasetMetaName: string;
      datasetMetaId: string;
      alias?: string;
    })[] = [];
    const mapFields: SortItem[] = [];
    const sortI: SortItem[] = [];
    const groupI: GroupItem[] = [];
    let sortedGroupI: GroupItem[] = [];
    const listIds: string[] = [];
    const listMap: { listId: string; datasetMetaId: string; field: string }[] =
      [];

    if (graph && graph.queryParams.measures) {
      graph.queryParams.measures.map((field) => {
        groupI?.push({
          field: field.field,
          alias: field.alias || field.field,
          datasetMetaId: field.datasetMetaId,
          direction:
            graph?.queryParams?.groups?.find(
              (g) =>
                g.field === field.field &&
                g.datasetMetaId == field.datasetMetaId,
            )?.direction || Enums.ProjectionSortOrder.ASCENDING,
          active: true,
          isMeasure: true,
        });
      });
    }

    if (graph && graph.queryParams && graph.queryParams.fields) {
      graph.queryParams.fields.map((field) => {
        const dsm = datasetMetas.find(
          (d) => d.entity._id === field.datasetMetaId,
        )?.entity;

        if (dsm) {
          const collection = dsm.dataCollections?.find(
            (c) => c._id === dsm.activeDataCollection,
          );

          const schema = collection?.schemaData;

          const schemaItem = schema?.find(
            (f) => f.fieldId === field.field || f.name === field.field,
          );

          if (collection?.dataCollection && schemaItem) {
            fieldI.push({
              ...schemaItem,
              datasetMetaName: dsm.name,
              datasetMetaId: dsm._id,
              alias:
                isMultiple && field?.alias === schemaItem.name
                  ? `${schemaItem.name} - ${dsm.name}`
                  : field.alias,
            });

            const listValues =
              schemaItem.dataValidation?.constraints?.listValues;

            if (listValues && !Array.isArray(listValues)) {
              listIds.push(listValues);
              listMap.push({
                listId: listValues,
                datasetMetaId: dsm._id,
                field: field.field,
              });
            }
          }

          groupI?.push({
            field: field.field,
            alias: field.alias || field.field,
            datasetMetaId: dsm._id,
            direction:
              graph?.queryParams?.groups?.find(
                (g) =>
                  g.field === field.field &&
                  g.datasetMetaId == field.datasetMetaId,
              )?.direction || Enums.ProjectionSortOrder.ASCENDING,
            active: !!graph?.queryParams?.groups?.find(
              (gr) =>
                gr.field === field.field &&
                gr.datasetMetaId == field.datasetMetaId,
            ),
          });

          sortI?.push({
            field: field.field,
            alias: field.alias || field.field,
            datasetMetaId: dsm._id,
            direction:
              graph.queryParams.sort?.find(
                (s) =>
                  s.alias === field.alias &&
                  s.datasetMetaId == field.datasetMetaId,
              )?.direction || Enums.ProjectionSortOrder.ASCENDING,
            active: !!graph.queryParams.sort?.find(
              (s) =>
                s.alias === field.alias &&
                s.datasetMetaId == field.datasetMetaId,
            ),
          });

          mapFields?.push({
            field: field.field,
            alias: field.alias || field.field,
            datasetMetaId: dsm._id,
            direction: Enums.ProjectionSortOrder.ASCENDING,
            active: false,
          });
        }
      });

      graph?.queryParams.measures?.map((field) => {
        const dsm = datasetMetas.find(
          (d) => d.entity._id === field.datasetMetaId,
        )?.entity;

        if (!dsm) {
          return;
        }

        mapFields.push({
          field: field.field,
          alias: field.alias || field.field,
          datasetMetaId: dsm._id,
          direction: Enums.ProjectionSortOrder.ASCENDING,
          active: false,
        });
      });

      // Show any selected groups sorted at the top
      const queryParamsGroups = graph?.queryParams?.groups;

      if (queryParamsGroups) {
        groupI.sort(
          (a, b) =>
            queryParamsGroups.findIndex((go) => go.field === a.field) -
            queryParamsGroups.findIndex((go) => go.field === b.field),
        );
      }

      const queryParamGroupsAliases =
        queryParamsGroups?.flatMap((g) => g.alias) || [];

      const deselectedGroups = groupI.filter(
        (g) => !queryParamGroupsAliases?.includes(g.alias),
      );
      const selectedGroups = groupI.filter((g) =>
        queryParamGroupsAliases?.includes(g.alias),
      );

      sortedGroupI = selectedGroups.concat(deselectedGroups);

      if (graph.queryParams.filters?.length) {
        const queryFilters = cloneDeep(graph.queryParams).filters.map((f) =>
          filterFieldToConditionalFieldMap(f),
        );

        replaceDatasetFilters(graph._id, queryFilters);
      }

      if (!isEqual(fieldItems, fieldI)) {
        setFieldItems(fieldI);
      }
      if (!isEqual(groupItems, sortedGroupI)) {
        setGroupItems(sortedGroupI);
      }
      if (!isEqual(sortItems, sortI)) {
        setSortItems(sortI);
      }

      const graphAdditonalMarkerFields = (
        graph.graphParams as MapGraphParams
      ).additionalMarkers?.map((marker) => {
        return {
          alias: marker.alias,
          datasetMetaId: marker.datasetMetaId,
          field: marker.field,
          direction: Enums.ProjectionSortOrder.ASCENDING,
          active: true,
        } as SortItem;
      });

      const graphAdditionalMarkerFieldIds = (
        graph.graphParams as MapGraphParams
      ).additionalMarkers?.map((m) => m.alias);

      const filteredGraphAdditionalMarkerFields = mapFields.filter(
        (sort) => !graphAdditionalMarkerFieldIds?.includes(sort.alias),
      );

      const latestMarkerItems =
        graphAdditonalMarkerFields?.concat(
          filteredGraphAdditionalMarkerFields,
        ) || [];

      if (!isEqual(additionalMarkerItems, latestMarkerItems)) {
        setAdditionalMarkerItems(latestMarkerItems);
      }

      if (!isEqual(listIds, listValueIds)) {
        setListValueIds(listIds);
      }

      if (!isEqual(listMap, listValueMap)) {
        setListValueMap(listMap);
      }

      const groupOptions = groupI.map((e, i) => {
        return {
          key: `group-${e.field}-${i}`,
          value: e.alias,
          text: e.alias,
        };
      });
      setGroupOptions(groupOptions);

      const colourGroupOptions = groupI
        .filter((e) => listMap.find((list) => list.field === e.field))
        .map((e, i) => {
          return {
            key: `colour-group-${e.field}-${i}`,
            value: e.alias,
            text: e.alias,
          };
        });
      setColourGroupOptions(colourGroupOptions);

      let metricColourIndex;
      const themeColourArray = Object.keys(themeContext.colors.general)
        .filter((c) => c !== COLOUR_KEY_WHITE)
        .map((k) => themeContext.colors.general[k]);

      const graphMetricsItems = groupI.map((e) => {
        metricColourIndex < themeColourArray.length - 1
          ? metricColourIndex++
          : (metricColourIndex = 0);

        return {
          field: e,
          colour: themeColourArray[metricColourIndex],
          active: true,
        };
      }, []);

      setGraphXMetrics(graphMetricsItems);
    }
  }, [
    graph,
    fieldItems,
    groupItems,
    sortItems,
    additionalMarkerItems,
    listValueIds,
    listValueMap,
    themeContext.colors.general,
    datasetMetas,
    isMultiple,
    replaceDatasetFilters,
    filterFieldToConditionalFieldMap,
  ]);

  // Reset graph metrics on measure change
  useEffect(() => {
    if (graph?.queryParams.measures) {
      createAggregationOptions();
    }
  }, [createAggregationOptions, graph?.queryParams.measures]);

  // Create aggregation options
  useEffect(() => {
    if (fieldItems.length) {
      return;
    }

    if (graph?.queryParams?.fields && datasetMetas) {
      createAggregationOptions();
    }
  }, [graph, createAggregationOptions, datasetMetas, fieldItems.length]);

  const toggleAccordionIndex = (index: number) => {
    if (graphAccordionOpenIndexes.includes(index)) {
      setGraphAccordionOpenIndexes(
        graphAccordionOpenIndexes.filter((aoi) => aoi !== index),
      );
    } else {
      setGraphAccordionOpenIndexes([...graphAccordionOpenIndexes, index]);
    }
  };

  const handleChange = (
    field: string,
    val: string | boolean | string[] | number,
  ) => {
    const cloned = cloneDeep(graph);
    switch (field) {
      case GRAPH_XAXIS_METRIC_FIELD:
        cloned[GRAPH_PARAMS_FIELD][field] = graphXMetrics.find(
          (m) => m.field.alias === val,
        );
        break;
      case GRAPH_TYPE_FIELD:
        cloned[GRAPH_PARAMS_FIELD][field] = val;

        if (
          (graph?.graphParams.graphType === Enums.GraphType.MAP ||
            graph?.graphParams.graphType === Enums.GraphType.GANTT) &&
          val !== Enums.GraphType.MAP &&
          val !== Enums.GraphType.GANTT
        ) {
          cloned.graphParams = { ...InitialGraphParams, [field]: val };
          dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          );
          return;
        }
        if (
          graph?.graphParams.graphType !== Enums.GraphType.GANTT &&
          val === Enums.GraphType.GANTT
        ) {
          cloned.graphParams = InitialGanttParams;
          dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          );
          return;
        }

        if (
          graph?.graphParams.graphType !== Enums.GraphType.MAP &&
          val === Enums.GraphType.MAP
        ) {
          cloned.graphParams = InitialMapParams;
          dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          );
          return;
        }
        break;
      case GRAPH_LONG_FIELD:
      case GRAPH_LAT_FIELD:
      case GRAPH_MARKER_TITLE_FIELD:
      case GRAPH_MARKER_COLOUR_METRIC_FIELD: {
        cloned[GRAPH_PARAMS_FIELD][field] = graphXMetrics.find(
          (m) => m.field.alias === val,
        );

        // Make the selected column also an additionalMarker
        const addMarker = cloneDeep(additionalMarkerItems)
          .map((marker) => {
            if (marker.alias === val) {
              marker.active = true;
            }
            return marker;
          })
          .filter((marker) => marker.active);

        setAdditionalMarkerItems(addMarker);

        cloned[GRAPH_PARAMS_FIELD].additionalMarkers = addMarker;

        processAction(activeTabIndex, true, undefined, cloned);

        break;
      }
      case GRAPH_RADIUS_FIELD:
        cloned[GRAPH_PARAMS_FIELD][field] = val;
        processAction(activeTabIndex, true, undefined, cloned);
        break;

      default:
        cloned[GRAPH_PARAMS_FIELD][GRAPH_TEXT_OPTS_FIELD][field] = val;
        break;
    }

    const clonedMetrics = cloneDeep(graphMetrics);

    // Reset graph options if pie chart
    if (
      field === GRAPH_TYPE_FIELD &&
      (val === Enums.GraphType.PIE_CHART ||
        val === Enums.GraphType.DOUGHNUT_CHART)
    ) {
      cloned[GRAPH_XAXIS_LABEL_FIELD] = '';
      cloned[GRAPH_YAXIS_LABEL_FIELD] = '';
      cloned[GRAPH_METRICS_FIELD] = [];

      clonedMetrics.map((item) => (item.active = false));
    }

    // Set active if only one graph metric
    if (clonedMetrics.length === 1) {
      clonedMetrics.map((m) => (m.active = true));
    }

    cloned[GRAPH_PARAMS_FIELD][GRAPH_METRICS_FIELD] = clonedMetrics;
    setGraphMetrics(clonedMetrics);

    dispatch(
      fetchGraphSuccess({
        entity: cloned,
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );

    if (field === GRAPH_XAXIS_METRIC_FIELD) {
      processAction(activeTabIndex, true, undefined, cloned);
    }
  };

  const graphMetricDisabled = (item: Interfaces.GraphMetric) =>
    !graphMetrics.filter((m) => m.active).includes(item) &&
    (graph?.graphParams.graphType === Enums.GraphType.PIE_CHART ||
      graph?.graphParams.graphType === Enums.GraphType.DOUGHNUT_CHART) &&
    graphMetrics.filter((m) => m.active).length >= 1;

  const tabPanes = [
    {
      menuItem: 'Data',
      render: () => (
        <Tab.Pane loading={isLoading}>
          <SC.FilterAccordion
            defaultActiveIndex={[0, 1, 2, 3, 4, 5, 6]}
            exclusive={false}
            panels={Object.values(AggregateStage)
              .filter((stage) => stage !== AggregateStage.TOTALS)
              .filter((stage) => stage !== AggregateStage.DISPLAY_ORDER)
              .map((stage, index) => buildAccordionPanel(stage, index))}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Graph',
      render: () => (
        <Tab.Pane loading={isLoading}>
          {!isLoading && (
            <SC.FilterAccordion exclusive={false}>
              <StyledAccordionTitle
                index={DETAILS_ACCORDION_INDEX}
                onClick={() => toggleAccordionIndex(DETAILS_ACCORDION_INDEX)}
                active={graphAccordionOpenIndexes.includes(
                  DETAILS_ACCORDION_INDEX,
                )}
              >
                <StyledBodySubHeader>Graph Details</StyledBodySubHeader>
                <FontAwesomeIcon
                  icon={
                    graphAccordionOpenIndexes.includes(DETAILS_ACCORDION_INDEX)
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.offBlack}
                />
              </StyledAccordionTitle>
              <SC.FilterAccordionContent
                active={graphAccordionOpenIndexes.includes(
                  DETAILS_ACCORDION_INDEX,
                )}
              >
                <SC.GraphConfigWrapper>
                  <StyledBodySubHeader>Graph Type</StyledBodySubHeader>
                  <StyledDropdown
                    selectOnBlur={false}
                    placeholder={'Select a graph type'}
                    selection
                    options={graphTypeOptions}
                    style={{ marginBottom: themeContext.margin.large }}
                    value={graph?.graphParams.graphType || ''}
                    onChange={(e, data) =>
                      handleChange(GRAPH_TYPE_FIELD, data.value)
                    }
                  />

                  <StyledBodySubHeader>Graph Title</StyledBodySubHeader>
                  <StyledInput
                    placeholder={'Enter your graph title'}
                    style={{ marginBottom: themeContext.margin.large }}
                    value={graphTitle || ''}
                    onChange={(event, data) => setGraphTitle(data.value)}
                  />

                  {isRechartsGraph && (
                    <>
                      <StyledBodySubHeader>
                        {graph?.graphParams.graphType !==
                          Enums.GraphType.PIE_CHART &&
                          graph?.graphParams.graphType !==
                            Enums.GraphType.DOUGHNUT_CHART &&
                          'X Axis'}{' '}
                        Metric
                      </StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        placeholder={
                          graph?.graphParams?.graphType !==
                            Enums.GraphType.PIE_CHART &&
                          graph?.graphParams?.graphType !==
                            Enums.GraphType.DOUGHNUT_CHART
                            ? 'Select a Metric'
                            : 'Select an X Axis Metric'
                        }
                        selection
                        options={groupOptions}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={
                          (graph?.graphParams as Interfaces.GraphParams)
                            ?.xAxisMetric?.field?.alias || ''
                        }
                        onChange={(e, data) =>
                          handleChange(GRAPH_XAXIS_METRIC_FIELD, data.value)
                        }
                      />
                    </>
                  )}

                  {graph?.graphParams.graphType === Enums.GraphType.MAP && (
                    <>
                      <StyledBodySubHeader>Latitude</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        placeholder={'Select a Latitude value'}
                        selection
                        options={groupOptions}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={mapParams?.latitudeMetric?.field?.alias || ''}
                        onChange={(e, data) =>
                          handleChange(GRAPH_LAT_FIELD, data.value)
                        }
                      />

                      <StyledBodySubHeader>Longitude</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        placeholder={'Select a Longitude value'}
                        selection
                        options={groupOptions}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={mapParams?.longitudeMetric?.field?.alias || ''}
                        onChange={(e, data) =>
                          handleChange(GRAPH_LONG_FIELD, data.value)
                        }
                      />

                      <StyledBodySubHeader>Marker Title</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        placeholder={'Select a marker value'}
                        selection
                        options={groupOptions}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={mapParams?.markerTitleMetric?.field?.alias || ''}
                        onChange={(e, data) =>
                          handleChange(GRAPH_MARKER_TITLE_FIELD, data.value)
                        }
                      />

                      <StyledBodySubHeader>
                        Marker Radius (Meters)
                      </StyledBodySubHeader>
                      <StyledInput
                        type={'number'}
                        placeholder={'Optional - Enter a radius in meters'}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={graphMapRadius || 0}
                        onChange={(event, data) =>
                          setGraphMapRadius(+data.value)
                        }
                      />

                      <StyledBodySubHeader>Marker Colour</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        placeholder={'Select a list column'}
                        selection
                        clearable
                        options={colourGroupOptions}
                        style={{ marginBottom: themeContext.margin.large }}
                        value={
                          mapParams?.markerColourMetric?.field?.alias || ''
                        }
                        onChange={(e, data) =>
                          handleChange(
                            GRAPH_MARKER_COLOUR_METRIC_FIELD,
                            data.value,
                          )
                        }
                      />

                      <StyledBodySubHeader>Marker Content</StyledBodySubHeader>
                      <SC.SortWrapper style={{ width: '100%' }}>
                        <AggregationSort
                          sortItems={additionalMarkerItems}
                          onChange={handleMapMarkerChange}
                          singleList={true}
                          hideDirectionButton={true}
                        />
                      </SC.SortWrapper>
                    </>
                  )}

                  {graph?.graphParams.graphType !== Enums.GraphType.PIE_CHART &&
                    graph?.graphParams.graphType !==
                      Enums.GraphType.DOUGHNUT_CHART &&
                    isRechartsGraph && (
                      <>
                        <StyledBodySubHeader>X Axis Label</StyledBodySubHeader>
                        <StyledInput
                          placeholder={'Enter a label for the X Axis'}
                          style={{ marginBottom: themeContext.margin.large }}
                          value={graphXLabel || ''}
                          onChange={(event, data) => setGraphXLabel(data.value)}
                        />

                        <StyledBodySubHeader>Y Axis Label</StyledBodySubHeader>
                        <StyledInput
                          placeholder={'Enter a label for the Y Axis'}
                          style={{ marginBottom: themeContext.margin.large }}
                          value={graphYLabel || ''}
                          onChange={(event, data) => setGraphYLabel(data.value)}
                        />
                      </>
                    )}
                  {isRechartsGraph && (
                    <>
                      <StyledBodySubHeader>Show Legend</StyledBodySubHeader>
                      <Checkbox
                        label={'Show Graph Legend'}
                        style={{ marginBottom: themeContext.margin.large }}
                        checked={graph?.graphParams.graphTextOpts?.showLegend}
                        onChange={(e, data) =>
                          handleChange(
                            GRAPH_SHOW_LEGEND_FIELD,
                            data.checked || false,
                          )
                        }
                      />
                    </>
                  )}
                </SC.GraphConfigWrapper>
              </SC.FilterAccordionContent>

              {graph?.graphParams.graphType === Enums.GraphType.GANTT && (
                <GanttChartSetup
                  setActiveAccordion={toggleAccordionIndex}
                  isActiveAccordion={(index: number) =>
                    graphAccordionOpenIndexes.includes(index)
                  }
                  projectMetricOptions={groupOptions}
                  handleChangeGanttMetric={handleChangeGanttMetric}
                  updateGraph={updateGraph}
                />
              )}

              {isRechartsGraph && (
                <>
                  <StyledAccordionTitle
                    index={GRAPH_METRICS_ACCORDION_INDEX}
                    active={graphAccordionOpenIndexes.includes(
                      GRAPH_METRICS_ACCORDION_INDEX,
                    )}
                  >
                    <StyledBodySubHeader
                      onClick={() =>
                        toggleAccordionIndex(GRAPH_METRICS_ACCORDION_INDEX)
                      }
                    >
                      Graph Metrics
                    </StyledBodySubHeader>
                    <Checkbox
                      key={`checkbox-select-all`}
                      label={'Select All'}
                      checked={graphMetrics.every((f) => f.active)}
                      onChange={(e, data) =>
                        handleMetricChangeAllActive(data.checked)
                      }
                    />
                    <FontAwesomeIcon
                      icon={
                        graphAccordionOpenIndexes.includes(1)
                          ? faChevronUp
                          : faChevronDown
                      }
                      color={themeContext.colors.system.offBlack}
                      onClick={() => toggleAccordionIndex(1)}
                    />
                  </StyledAccordionTitle>
                  <SC.FilterAccordionContent
                    active={graphAccordionOpenIndexes.includes(1)}
                  >
                    <SC.AggregateSection style={{ overflow: 'visible' }}>
                      <SC.GroupWrapper style={{ overflow: 'visible' }}>
                        <div>
                          {!graphMetrics?.length && <p>No metrics available</p>}
                          {graphMetrics?.map((item, index) => {
                            return (
                              <SC.GroupButton
                                key={`group-button-${item.alias}-${index}`}
                                active={item?.active || false}
                                disabled={graphMetricDisabled(item)}
                              >
                                <Checkbox
                                  key={`group-checkbox-${item.alias}-${index}`}
                                  label={item.alias}
                                  disabled={graphMetricDisabled(item)}
                                  checked={item?.active || false}
                                  onChange={(e, data) => {
                                    handleMetricChangeActive(
                                      data.checked,
                                      index,
                                    );
                                  }}
                                />

                                {graph?.graphParams.graphType !==
                                  Enums.GraphType.PIE_CHART &&
                                  graph?.graphParams.graphType !==
                                    Enums.GraphType.DOUGHNUT_CHART && (
                                    <PopupColorPicker
                                      key={`popover-picker-${item.alias}-${index}`}
                                      onChange={(e) => {
                                        handleMetricChangeColour(e, index);
                                      }}
                                      color={item.colour}
                                      disabled={graphMetricDisabled(item)}
                                    />
                                  )}
                              </SC.GroupButton>
                            );
                          })}
                        </div>
                      </SC.GroupWrapper>
                    </SC.AggregateSection>
                  </SC.FilterAccordionContent>
                </>
              )}
            </SC.FilterAccordion>
          )}
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = async (e, data) => {
    if (data.activeIndex === activeTabIndex) {
      return;
    }
    // Save the chart before switching tabs
    await processAction(data.activeIndex);

    // Switch active tab
    setActiveTabIndex(data.activeIndex || 0);
  };

  return (
    <StageWrapper>
      <SC.GridWrapper showFilters={showFilters}>
        <SC.PageInner>
          <SC.PageHeader>
            <StyledSubHeader>{graph?.name}</StyledSubHeader>

            <FeatureButton
              action={() => setShowFilters(!showFilters)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.sea}
              text={`${showFilters ? 'Hide' : 'Show'} Sidebar`}
            />
          </SC.PageHeader>

          {activeTabIndex === TAB_INDEX_DATA && (
            <PageContent>
              <DataSample
                schema={graph?.additionalDetails
                  ?.map((detail) => detail.schemaData)
                  .flat()}
                fullWidth={true}
                sampleColumns={
                  tableData?.schema?.length ? tableData?.schema : []
                }
                sampleRows={tableData?.rows || []}
                iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                fixedHeightReduction={315}
                loading={isLoading}
                isEditingQueryColumn={true}
              />
            </PageContent>
          )}

          {graph && activeTabIndex === TAB_INDEX_GRAPH && (
            <GraphDisplay graph={graph} isEditing={true} />
          )}
        </SC.PageInner>

        <SC.Sidebar>
          <SC.StyledTab
            panes={tabPanes}
            menu={{ widths: tabPanes.length, attached: true, tabular: true }}
            onTabChange={handleTabChange}
          />
        </SC.Sidebar>
      </SC.GridWrapper>

      <ActionBar
        primaryButton={
          isProjectManager ? (
            <FeatureButton
              action={() => processAction(activeTabIndex, false, true)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              isDisabled={
                !graphParams.graphType ||
                (graphParams.graphType !== Enums.GraphType.MAP &&
                  graphParams.graphType !== Enums.GraphType.GANTT &&
                  !(graphParams as Interfaces.GraphParams)?.xAxisMetric?.field
                    ?.alias) ||
                (graphParams.graphType === Enums.GraphType.MAP &&
                  !mapComplete) ||
                (graphParams.graphType === Enums.GraphType.GANTT &&
                  !ganttComplete) ||
                isLoading
              }
              text={'Save graph'}
            />
          ) : undefined
        }
        secondaryButton={
          <FeatureButton
            action={() => processAction(activeTabIndex, true)}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Save & Update graph'}
            isDisabled={isLoading}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(ProjectStage.CHARTS));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to dashboard'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAggregationAggregateStage;
