import { FC } from 'react';
import { StageBodyText, StyledText } from '../../../../main/theme';

export interface MergeDatasetBodyTextProps {
  bodyText?: string;
  smallHeading?: boolean;
}

const MergeDatasetBodyText: FC<MergeDatasetBodyTextProps> = ({
  bodyText,
  smallHeading,
}) => {
  return (
    <>
      {smallHeading ? (
        <StyledText>{bodyText}</StyledText>
      ) : (
        <StageBodyText>{bodyText}</StageBodyText>
      )}
    </>
  );
};

export default MergeDatasetBodyText;
