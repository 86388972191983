import { Enums } from '@configur-tech/upit-core-types';
import { faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import { RouteName } from '../../enums/RouteName';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { defaultTheme } from '../../main/theme';
import { updateDatasetCollection } from '../../store/dataset-collection';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import { hideLoading, showLoading } from '../../store/loading';
import { RootState } from '../../store/rootReducer';
import * as SC from './styled';

const EDIT_ACTION = 'edit';

const CollectionSelection: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { datasetMeta, activeDataCollectionItem, addDatasetMetaCollection } =
    useDatasetMeta();
  const { collectionLockStatus, lockCollection, unlockCollection } =
    useDatasetCollection();

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = datasetStageState.activeStage;

  const [locked, setLocked] = useState<boolean>();
  const [collectionCreated, setCollectionCreated] = useState<boolean>(false);

  useEffect(() => {
    if (collectionLockStatus) {
      setLocked(true);
    } else {
      setLocked(false);
    }
  }, [collectionLockStatus]);

  // Dispatch collection if new dataCollection added
  useEffect(() => {
    if (collectionCreated && datasetMeta?.dataCollections.length) {
      dispatch(
        updateDatasetCollection({
          datasetMetaId: datasetMeta._id,
          collectionId:
            datasetMeta.dataCollections?.[
              datasetMeta.dataCollections.length - 1
            ]?._id,
        }),
      );
    }
  }, [
    collectionCreated,
    datasetMeta?._id,
    datasetMeta?.dataCollections,
    dispatch,
  ]);

  const handleToggleChange = async (val: boolean) => {
    if (datasetMeta && activeDataCollectionItem) {
      if (val) {
        // Set activeDataCollection as selected
        dispatch(
          updateDatasetCollection({
            datasetMetaId: datasetMeta._id,
            collectionId: activeDataCollectionItem._id,
          }),
        );
        // Lock dataset
        lockCollection();

        // Reset stages
        if (activeStage === Enums.DatasetStage.VALIDATION) {
          dispatch(resetStagesAndSubStages());
        }

        // Redirect
        history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
      } else {
        dispatch(showLoading({ text: 'Unlocking Dataset...' }));

        const inProgressCollection = cloneDeep(datasetMeta.dataCollections)
          .reverse()
          .find(
            (c) =>
              c.status === Enums.CollectionStatus.IN_PROGRESS &&
              c.dataSource?.dataSourceActionType ===
                Enums.DataSourceActionType.SCHEMA_CHANGE,
          );

        if (inProgressCollection) {
          dispatch(
            updateDatasetCollection({
              datasetMetaId: datasetMeta._id,
              collectionId: inProgressCollection._id,
            }),
          );
        } else {
          const createColl = {
            status: Enums.CollectionStatus.IN_PROGRESS,
            dataSource: {
              ...activeDataCollectionItem.dataSource,
              dataSourceActionType: Enums.DataSourceActionType.SCHEMA_CHANGE,
            },
            columnCount: 0,
            rowCount: 0,
            stages: {
              ...activeDataCollectionItem.stages,
              [Enums.DatasetStage.STRUCTURE]: {
                ...activeDataCollectionItem.stages[
                  Enums.DatasetStage.STRUCTURE
                ],
                status: Enums.StageStatus.IN_PROGRESS,
              },
              [Enums.DatasetStage.VALIDATION]: {
                status: Enums.StageStatus.NOT_STARTED,
                result: {
                  total: 0,
                  completed: 0,
                  failed: 0,
                },
                failed: [],
                corrected: [],
              },
            },
            schemaData: activeDataCollectionItem.schemaData,
          };

          await addDatasetMetaCollection(datasetMeta, createColl);
          setCollectionCreated(true);
        }

        // Unlock dataset
        history.push(
          `${RouteName.DATASET_ITEM}/${datasetMeta?._id}/${EDIT_ACTION}`,
        );
        unlockCollection();
        dispatch(hideLoading());
      }
    }
  };

  if (!datasetMeta?._id) {
    return null;
  }

  return (
    <SC.Wrapper>
      <FontAwesomeIcon
        icon={locked ? faLock : faLockOpen}
        color={defaultTheme.colors.system.offBlack}
        size={'sm'}
      />
      <SC.ToggleLabel>Dataset {locked ? 'locked' : 'unlocked'}</SC.ToggleLabel>

      <SC.ToggleWrapper>
        <Toggle
          checked={collectionLockStatus}
          icons={false}
          onChange={(event) => handleToggleChange(event.target.checked)}
        />
      </SC.ToggleWrapper>
    </SC.Wrapper>
  );
};

export default CollectionSelection;
