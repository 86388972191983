import { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  COMMENT_COUNT_HEADER,
  ROW_NUMBER_HEADER,
} from '../../../const/DataSample';
import { ClickableColumn } from '../../../hooks/data-sample/UseDataSample';
import { Cell } from '../../DataSample/DataSample';
import { TableCell } from '../../DataSample/styled';

interface ClickableColumnTableCellProps extends Cell {
  cell;
  clickableColumn: ClickableColumn;
  columnId: string;
  value: string;
}

const ClickableColumnTableCell: FC<ClickableColumnTableCellProps> = ({
  cell,
  clickableColumn,
  columnId,
  value,
}) => {
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const selectedRowId = useMemo(
    () => new URLSearchParams(location.search).get(ROW_NUMBER_HEADER),
    [location.search],
  );
  const cellValue = cell.row.original[clickableColumn?.valueField]?.toString();

  const isActive =
    columnId === COMMENT_COUNT_HEADER && selectedRowId === cellValue;

  return (
    <TableCell
      {...cell.getCellProps()}
      onClick={() =>
        clickableColumn?.action(cellValue, cell.row.original[ROW_NUMBER_HEADER])
      }
      isActive={isActive}
      isBold={true}
      textColor={
        value ? themeContext.colors.system.offBlack : `rgba(0, 0, 0, .20)`
      }
    >
      {value}
    </TableCell>
  );
};

export default ClickableColumnTableCell;
