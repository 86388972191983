import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserService, { UserItemOutput } from '../../services/user/UserService';
import { AppThunk } from '../store';

export interface UsersState {
  data: UserItemOutput[];
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  data: [],
  loading: false,
  error: null,
};

const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Fetch
    fetchUsersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUsersSuccess(state, action: PayloadAction<UserItemOutput[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUsersFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchUsersStart, fetchUsersSuccess, fetchUsersFailure } =
  UsersSlice.actions;

export default UsersSlice.reducer;

export const fetchUsers =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchUsersStart());
      const fetched = await UserService.getUsers(token, query);
      dispatch(
        fetchUsersSuccess(fetched.data?.data as unknown as UserItemOutput[]),
      );
    } catch (err) {
      dispatch(fetchUsersFailure((err as string).toString()));
    }
  };
