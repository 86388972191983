import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import OAuthService from '../../services/oauth/OAuthService';
import {
  fetchIntegrationConfigSuccess,
  IntegrationConfig,
} from '../../store/integration-config';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useOAuthResult {
  handleCallback: (
    integrationRef: string,
    integrationConfigId: string,
    callbackUri: string,
    authCode?: string,
  ) => Promise<IntegrationConfig | undefined>;
  buildAuthUrl: (
    integrationRef: string,
    integrationConfigId: string,
  ) => Promise<string>;
}

const useOAuth = (): useOAuthResult => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const handleCallback = useCallback(
    async (
      integrationRef: string,
      integrationConfigId: string,
      callbackUri: string,
      authCode?: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          const result = await OAuthService.runAction(
            token,
            integrationRef,
            integrationConfigId,
            Enums.oAuthActionType.FETCH_OAUTH,
            { callbackUri, authCode },
          );

          // Wait before proceeding
          await new Promise((resolve) => setTimeout(resolve, 5000));

          await dispatch(
            fetchIntegrationConfigSuccess(
              result.result.data as IntegrationConfig,
            ),
          );

          toast.success('Integration has been successfully authenticated.');

          return result.result.data as IntegrationConfig;
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Integration authentication was unsuccessful.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const buildAuthUrl = useCallback(
    async (integrationRef: string, integrationConfigId: string) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && loggedInUser) {
        try {
          result = await OAuthService.runAction(
            token,
            integrationRef,
            integrationConfigId,
            Enums.oAuthActionType.FETCH_AUTH_URL,
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to Build Authentication URL.`,
          );
        }
      }

      // Wait before proceeding
      await new Promise((resolve) => setTimeout(resolve, 5000));

      return result?.result?.data as string;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  return {
    handleCallback,
    buildAuthUrl,
  };
};

export default useOAuth;
