import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import QueryService from '../../services/query/QueryService';
import remapLegacyFilterToDynamicFilter from '../../util/migration/dynamic-filter/RemapLegacyFilterToDynamicFilter';
import { AppThunk } from '../store';

export interface QueryWithDetails extends Interfaces.Query {
  additionalDetails?: {
    name: string;
    datasetMetaId: string;
    schemaData: Interfaces.FieldOutput[];
  }[];
}

export interface QueryOutputWithDetails extends Interfaces.QueryOutput {
  additionalDetails?: {
    name: string;
    datasetMetaId: string;
    schemaData: Interfaces.FieldOutput[];
  }[];
}

export interface QueriesState {
  data: QueriesItemOutput;

  loading: boolean;
  error: string | null;
}

const initialState: QueriesState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      prevPageNum: null,
      nextPageNum: null,
      totalCount: 0,
      totalPages: 1,
    },
  },
  loading: false,
  error: null,
};

export interface QueryItem {
  accessLevel: Enums.AccessLevel;
  entity: QueryWithDetails;
}

export interface QueryItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: QueryOutputWithDetails;
}

export interface QueriesItemOutput {
  data: QueryItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export const initialQueriesState: QueriesItemOutput = {
  data: [],
  pagination: {
    currentPageNum: 1,
    prevPageNum: null,
    nextPageNum: null,
    totalCount: 0,
    totalPages: 0,
  },
};

const QueriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    // Fetch
    fetchQueriesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchQueriesSuccess(state, action: PayloadAction<QueriesItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchQueriesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchQueriesStart, fetchQueriesSuccess, fetchQueriesFailure } =
  QueriesSlice.actions;

export default QueriesSlice.reducer;

export const fetchQueries =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchQueriesStart());
      const fetched = await QueryService.getQueries(token, query);

      // Convert to new dynamic value format if required
      const formatted = !(fetched.data as unknown as QueriesItemOutput).data
        ?.length
        ? []
        : (fetched.data as unknown as QueriesItemOutput).data?.map((q) => {
            q.entity.queryParams.filters = q.entity.queryParams.filters.map(
              (f) =>
                remapLegacyFilterToDynamicFilter(
                  f as Interfaces.DynamicConditional,
                  q.entity.queryParams.fields,
                  q.entity.queryParams.datasetMetaId,
                ),
            );
            return q;
          });

      dispatch(
        fetchQueriesSuccess({
          ...fetched.data,
          data: formatted,
        } as unknown as QueriesItemOutput),
      );
    } catch (err) {
      dispatch(fetchQueriesFailure((err as string).toString()));
    }
  };
