import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactElement } from 'react';
import { AlertStatus } from '../../enums/AlertStatus';
import * as SC from './styled';

const Banner: FC<{
  message: string | ReactElement;
  alertStatus: AlertStatus;
  alertIcon: IconProp;
}> = ({ message, alertStatus, alertIcon }) => {
  return (
    <SC.BannerContainer alertStatus={alertStatus}>
      <FontAwesomeIcon size={'lg'} icon={alertIcon} />
      <SC.BannerText>{message}</SC.BannerText>
    </SC.BannerContainer>
  );
};

export default Banner;
