import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useForm from '../../../../hooks/form/UseForm';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchFormSuccess } from '../../../../store/project-form';
import {
  EndInputButtonWrapper,
  StageInner,
  StyledBodySubHeader,
  StyledFormHeader,
  StyledInput,
  StyledQuestionBuilderContainer,
  StyledTextArea,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const StyledFormBuilderInput = styled(StyledInput)`
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const StyledFormTextArea = styled(StyledTextArea)`
  width: 100%;
`;

const Outro: FC<{ hideEditor: () => void }> = ({ hideEditor }) => {
  const dispatch = useDispatch();
  const { form, editForm } = useForm();
  const themeContext = useContext(ThemeContext);

  const [outroState, setOutroState] = useState(
    form?.outro || { title: '', body: '' },
  );

  useEffect(() => {
    if (form && form.outro) {
      setOutroState(form.outro);
    }
  }, [form]);

  const submitOutro = async () => {
    dispatch(
      showLoading({
        text: `Updating Form...`,
      }),
    );

    const cloned = cloneDeep(form);
    cloned.outro = outroState;

    const updatedForm = await editForm(cloned);
    dispatch(fetchFormSuccess(updatedForm));
    dispatch(hideLoading());
  };

  return (
    <StyledQuestionBuilderContainer>
      <StageInner>
        <StyledFormHeader>Form Completion</StyledFormHeader>
        <StyledBodySubHeader>Title</StyledBodySubHeader>
        <StyledFormBuilderInput
          placeholder="Title"
          value={outroState.title}
          onChange={(_, data) =>
            setOutroState({ ...outroState, title: data.value })
          }
        />

        <StyledBodySubHeader>Description</StyledBodySubHeader>
        <StyledFormTextArea
          minRows={5}
          maxRows={10}
          placeholder="Body"
          value={outroState.body}
          onChange={(e) =>
            setOutroState({ ...outroState, body: e.target.value })
          }
        />

        <EndInputButtonWrapper>
          <FeatureButton
            action={() => {
              setOutroState({ title: '', body: '' });
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Cancel'}
            containerStyle={{ padding: `${themeContext.padding.standard} 0` }}
          />
          <FeatureButton
            action={async () => {
              await submitOutro();
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Submit'}
            containerStyle={{ padding: `${themeContext.padding.standard} 0` }}
            isDisabled={!outroState.title || !outroState.body}
          />
        </EndInputButtonWrapper>
      </StageInner>
    </StyledQuestionBuilderContainer>
  );
};

export default Outro;
