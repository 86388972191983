import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import { RouteName } from '../../../../enums/RouteName';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import useTeam from '../../../../hooks/team/UseTeam';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchTeamSuccess } from '../../../../store/team';
import { updateActiveTeamSubStage } from '../../../../store/team-stage';
import { TeamCreationSubStage } from '../../../../store/team-stage/initial-state';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../styled';

const PREV_STAGE = TeamCreationSubStage.AVATAR;

const TeamItemMembersStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { team, teamAccessLevel, editTeam } = useTeam();
  const isTeamManager = teamAccessLevel === Enums.AccessLevel.MANAGE;

  const [processComplete, setProcessComplete] = useState<boolean>(false);

  useEffect(() => {
    if (
      team &&
      loggedInUser &&
      !team.access.manage.user.includes(loggedInUser._id)
    ) {
      // Add user to access
      const cloned: Interfaces.TeamOutput = cloneDeep(team);
      cloned.access.manage.user.push(loggedInUser._id);
      dispatch(
        fetchTeamSuccess({
          entity: cloned,
          accessLevel: teamAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );
    }
  }, [dispatch, loggedInUser, team, teamAccessLevel]);

  const handleAccessChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update team
      const cloned: Interfaces.TeamOutput = cloneDeep(team);
      cloned.access[type][group] = updated;

      // Remove if present in another access type
      Object.entries(cloned.access).forEach(([cType, cAccess]) => {
        if (cType !== type) {
          cAccess[group] = cAccess[group].filter((e) => !updated.includes(e));
        }
      });

      await editTeam(cloned);
    }
  };

  useEffect(() => {
    if (processComplete && team?._id) {
      // ProcessAction has completed successfully
      history.push(`${RouteName.TEAMS}`);
    }
  }, [processComplete, team?._id, dispatch, history]);

  const processAction = async () => {
    if (loggedInUser) {
      dispatch(
        showLoading({
          text: `Updating Team...`,
        }),
      );

      // Create team
      const cloned = cloneDeep(team);

      // Add organisation
      cloned.organisation = loggedInUser.organisationId;

      // Save team
      await editTeam(cloned);

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Which users would you like to add to this team?
          </StageBodyText>

          <StageBodyText>
            Users can be added as Managers or Members. Managers are able to
            modify the team and its members.
          </StageBodyText>

          <AccessSelection
            disabled={!isTeamManager}
            access={team?.access}
            onChange={handleAccessChange}
            hideOrganisation={true}
            hideTeams={true}
            hideTypes={[AccessType.VIEW]}
            modifyText={[
              {
                type: AccessType.MANAGE,
                title: 'Team Managers',
                body: 'Team Managers can add and remove Team Members as well as modify the Team itself.',
              },
              {
                type: AccessType.EDIT,
                title: 'Team Members',
                body: 'Team Members have no additional permissions.',
              },
            ]}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          isTeamManager ? (
            <FeatureButton
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={team?._id ? 'Update Team' : 'Create Team'}
            />
          ) : (
            <FeatureButton
              action={() => history.push(RouteName.TEAMS)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Back to Teams'}
            />
          )
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveTeamSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default TeamItemMembersStage;
