import { isEmpty } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useForm from '../../../hooks/form/UseForm';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import usePermission from '../../../hooks/permission/UsePermission';
import { SampleDataRow } from '../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../main/theme';
import {
  fetchFormSuccess,
  FormsItemOutput,
  initialFormsState,
  resetForm,
} from '../../../store/project-form';
import { updateActiveProjectSubStage } from '../../../store/project-stage';
import { ProjectConnectionFormSubStage } from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

const PAGE_SIZE = 25;

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const DATASET_FIELD = 'Dataset';
const ORDER_BY_PARAM = 'orderBy';
const DESCRIPTION_FIELD = 'Description';

const FORM_TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: DATASET_FIELD },
];

enum SortTypes {
  'Name' = 'name',
  'Description' = 'description',
  'Dataset' = 'datasetMetaId',
}

const ProjectItemFormStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getDatasetMetas } = useDatasetMeta();
  const { getForms } = useForm();
  const { isOrgManager } = usePermission();
  const { organisation } = useOrganisation();

  const [fetchedForms, setFetchedForms] = useState<FormsItemOutput>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  const [pagination, setPagination] = useState(0);
  const [sortBy, setSortBy] = useState<Record<string, number>>();

  const datasetMetas = useSelector((state: RootState) => state.datasetMetas)
    ?.data.data;
  const charts = useSelector((state: RootState) => state.charts)?.data.data;
  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (fetchedForms?.data) {
        try {
          await getDatasetMetas({
            _id: { $in: fetchedForms.data.map((form) => form.datasetMetaId) },
            activeDataCollection: { $ne: null },
          });
          setIsLoading(false);
        } catch {
          setIsLoading(false);
        }
      }
    })();
  }, [fetchedForms?.data, getDatasetMetas]);

  // Get Forms
  useEffect(() => {
    (async () => {
      if (fetchedForms === undefined && organisation?._id) {
        let forms;

        const formParams = {
          pageNum: pagination,
          limit: PAGE_SIZE,
          organisationId: organisation?._id,
        };

        if (!isEmpty(sortBy)) {
          formParams[ORDER_BY_PARAM] = sortBy;
        }

        try {
          forms = await getForms(formParams);
        } catch (err) {
          forms = initialFormsState;
        }
        setFetchedForms(forms);
      }
    })();
  }, [fetchedForms, getForms, organisation?._id, pagination, sortBy]);

  useEffect(() => {
    if (fetchedForms) {
      const tableData: SampleDataRow[] = [];

      fetchedForms?.data.map((form) => {
        const formDataset = fetchedForms?.data.find(
          (f) => f._id === form._id,
        )?.datasetMetaId;
        const dataset = datasetMetas.find(
          (dsm) => dsm.entity._id === formDataset,
        )?.entity.name;

        if (dataset) {
          tableData.push({
            [ID_FIELD]: { value: form._id },
            [NAME_FIELD]: { value: form.name },
            [DESCRIPTION_FIELD]: { value: form.description || '' },
            [DATASET_FIELD]: { value: dataset },
          });
        }
      });

      setTableData(tableData);
    }
  }, [charts, datasetMetas, fetchedForms, queries]);

  const updateSortBy = useCallback(
    (sortItem: { id: string; desc: boolean }) => {
      if (isEmpty(sortItem)) {
        setSortBy(undefined);
        return;
      }

      const sortProperty = SortTypes[sortItem.id];

      switch (sortItem.desc) {
        case true:
          setSortBy({ [sortProperty]: -1 });
          break;
        case false:
          setSortBy({ [sortProperty]: 1 });
          break;
      }
    },
    [],
  );

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  return (
    <StageWrapper>
      <StageInner>
        <SC.ConnectionTypeWrapper>
          <StyledSubHeader>Forms</StyledSubHeader>

          <StageBodyText>
            Capture responses directly into your datasets through interactive
            forms.
          </StageBodyText>

          {isOrgManager && (
            <FeatureButton
              action={() => {
                dispatch(resetForm());
                dispatch(
                  updateActiveProjectSubStage(
                    ProjectConnectionFormSubStage.NAME,
                  ),
                );
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Add Form'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
            />
          )}

          {isOrgManager && !tableData.length && !isLoading && (
            <StageBodyText>
              <strong>No Forms found</strong>
            </StageBodyText>
          )}

          {(tableData.length > 0 || isLoading) && (
            <SC.TableWrapper>
              <DataSample
                showPagination={true}
                paginationAction={updatePagination}
                sortAction={updateSortBy}
                pageSize={PAGE_SIZE}
                totalRows={fetchedForms?.pagination?.totalCount}
                sampleColumns={FORM_TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (connectionId: string) => {
                    const existingForm = fetchedForms?.data.find(
                      (a) => a._id === connectionId,
                    );

                    if (existingForm) {
                      dispatch(fetchFormSuccess(existingForm));
                      dispatch(
                        updateActiveProjectSubStage(
                          ProjectConnectionFormSubStage.FORM_BUILDER,
                        ),
                      );
                    }
                  },
                }}
                loading={isLoading}
              />
            </SC.TableWrapper>
          )}
        </SC.ConnectionTypeWrapper>
      </StageInner>
    </StageWrapper>
  );
};

export default ProjectItemFormStage;
