import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { initialSchemaRowState } from '../../store/integration-template/inital-state';

const FIELD_KEY = 'field';
const VALUE_KEY = 'value';

export enum PayloadResponseSchemaField {
  PAYLOAD = 'payloadSchema',
  RESPONSE = 'responseSchema',
}

type PayloadResponse = Interfaces.ResponseInfo | Interfaces.RequestPayload;

interface useEndpointPayloadResponseResult {
  updateInput: (
    data: PayloadResponse,
    field: string,
    value: string,
  ) => PayloadResponse;

  addSchemaRow: (
    data: PayloadResponse,
    schemaField: PayloadResponseSchemaField,
  ) => PayloadResponse;

  removeSchemaRow: (
    data: PayloadResponse,
    schemaField: PayloadResponseSchemaField,
    removeIndex: number,
  ) => PayloadResponse;

  updateSchemaInput: (
    data: PayloadResponse,
    schemaField: PayloadResponseSchemaField,
    index: number,
    field: string,
    value: string | string[],
    dynamicField?: boolean,
  ) => PayloadResponse;
}

const useEndpointPayloadResponse = (): useEndpointPayloadResponseResult => {
  const updateInput = useCallback(
    (data: PayloadResponse, field: string, value: string): PayloadResponse => {
      const cloned = cloneDeep(data);
      cloned[field] = value;

      return cloned;
    },
    [],
  );

  const addSchemaRow = useCallback(
    (
      data: PayloadResponse,
      schemaField: PayloadResponseSchemaField,
    ): PayloadResponse => {
      const cloned = cloneDeep(data[schemaField]) || [];

      return {
        ...data,
        [schemaField]: [...cloned, initialSchemaRowState],
      };
    },
    [],
  );

  const removeSchemaRow = useCallback(
    (
      data: PayloadResponse,
      schemaField: PayloadResponseSchemaField,
      removeIndex: number,
    ): PayloadResponse => {
      const cloned = cloneDeep(data[schemaField]);

      return {
        ...data,
        [schemaField]: cloned.filter((row, i) => i !== removeIndex),
      };
    },
    [],
  );

  const updateSchemaInput = useCallback(
    (
      data: PayloadResponse,
      schemaField: PayloadResponseSchemaField,
      index: number,
      field: string,
      value: string | string[],
      dynamicField?: boolean,
    ) => {
      const cloned = cloneDeep(data[schemaField]);

      if (field === FIELD_KEY) {
        cloned.map((row, i) => {
          if (i === index) {
            row[field] = value;
            if (dynamicField) {
              row[VALUE_KEY] = {
                field: value,
                type: Enums.DynamicConditionalType.FIELD,
              };
            }

            return row;
          }
        });
      } else {
        cloned.map((row, i) => {
          if (i === index) {
            row.type = value;
            return row;
          }
        });
      }

      return {
        ...data,
        [schemaField]: cloned,
      };
    },
    [],
  );

  return {
    updateInput,
    addSchemaRow,
    removeSchemaRow,
    updateSchemaInput,
  };
};

export default useEndpointPayloadResponse;
