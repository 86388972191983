import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import useQuery from '../../../hooks/query/UseQuery';
import {
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import {
  initialMappingJobParams,
  initialQueryJobParams,
} from '../../../store/pipeline-template/inital-state';
import { QueryItemOutput } from '../../../store/queries';
import { RootState } from '../../../store/rootReducer';
import FilterBuilder from '../filter/FilterBuilder';
import { DropdownItemPropsWithValidation } from '../filter/FilterModal';

const QUERY_ID = 'queryId';
const PAGE_SIZE = 50;
const PAGE_NUM = 1;
const FILTER_FIELD = 'filters';

export interface JobQueryModalComponentProps {
  jobIndex: number;
  onChange: (data) => void;
}

const JobQueryModalComponent: FC<JobQueryModalComponentProps> = ({
  jobIndex,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { getQueries } = useQuery();

  const queries: QueryItemOutput[] = useSelector(
    (state: RootState) => state.queries,
  )?.data.data;

  const { activeDataCollectionItem } = useDatasetMeta();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const integrationTemplates: IntegrationTemplateItem[] = useSelector(
    (state: RootState) => state.integrationTemplates,
  )?.data.data;

  const { pipelineTemplate } = usePipelineTemplate();

  const [queriesLoaded, setQueriesLoaded] = useState<boolean>(false);

  const [pipelineJob, setPipelineJob] = useState<Interfaces.QueryJobParams>(
    initialQueryJobParams,
  );

  const [prevMappingJob, setPrevMappingJob] =
    useState<Interfaces.MappingJobParams>(initialMappingJobParams);

  const [existingQueryOptions, setExistingQueryOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();

  const [filter, setFilter] = useState<DynamicConditionalField>({
    operator: Enums.AggregationFilterOperator.AND,
    value: [],
    active: true,
  });
  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();

  const [pipelineMappings, setPipelineMappings] = useState<
    Interfaces.FieldOutput[]
  >([]);

  const isEditing = jobIndex > -1;

  // If editing existing, set data
  useEffect(() => {
    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      const params = pipelineTemplate.jobs[jobIndex]
        .jobParams as Interfaces.QueryJobParams;
      setPipelineJob(params);

      setFilter(
        params?.filters?.[0] || {
          operator: Enums.AggregationFilterOperator.AND,
          value: [],
          active: true,
        },
      );
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  // Get queries
  useEffect(() => {
    setQueriesLoaded(false);
    (async () => {
      const queryParams = {
        pageNum: PAGE_NUM,
        limit: PAGE_SIZE,
      };

      await getQueries(queryParams);

      setQueriesLoaded(true);
    })();
  }, [dispatch, getQueries]);

  // Map existing query options
  useEffect(() => {
    if (queries?.length) {
      setExistingQueryOptions(
        queries.map((q) => {
          const ent = q.entity;
          return {
            key: ent._id,
            value: ent._id,
            text: ent.name,
          };
        }),
      );
    }
  }, [queries]);

  // Set entity schema of selected query
  useEffect(() => {
    if (pipelineJob[QUERY_ID]) {
      const activeQuery = queries.find(
        (query) => query.entity._id === pipelineJob[QUERY_ID],
      );

      if (activeQuery) {
        const coll = activeQuery.entity.additionalDetails?.find(
          (schema) =>
            schema.datasetMetaId ===
            activeQuery.entity.queryParams.datasetMetaId,
        );

        const schema = coll?.schemaData.map((s) => ({
          ...s,
          datasetMetaId: activeQuery.entity.queryParams.datasetMetaId,
        }));

        setEntitySchema(schema);
      }
    }
  }, [pipelineJob, queries]);

  // Set field options for filtering
  useEffect(() => {
    if (entitySchema) {
      const options: DropdownItemProps[] = entitySchema.map((f, i) => {
        const field = f as Interfaces.FieldOutput & { datasetMetaId: string };
        return {
          key: `field-${f.fieldId}-${i}`,
          value: `${f.fieldId}***${field.datasetMetaId}***${f.name}`,
          text: f.name,
        };
      });

      setFieldOptions(options);
    }
  }, [entitySchema, pipelineJob]);

  // Get datasetMetaId from mapping pipeline job
  useEffect(() => {
    if (pipelineTemplate?._id && !pipelineMappings) {
      const clonedJobs = cloneDeep(pipelineTemplate.jobs);
      const previousMappingJob = clonedJobs
        .slice(0, jobIndex)
        .reverse()
        .find(
          (job) => job.jobType === Enums.PipelineJobType.MAPPING,
        ) as Interfaces.MappingJob;

      const previousMapParams =
        previousMappingJob?.jobParams as Interfaces.MappingJobParams;

      if (previousMappingJob) {
        setPrevMappingJob(previousMapParams);
      }
    }
  }, [
    isEditing,
    jobIndex,
    pipelineMappings,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // Build pipeline mappings
  useEffect(() => {
    if (!prevMappingJob) {
      return;
    }

    if (activeDataCollectionItem?.schemaData) {
      return setPipelineMappings(activeDataCollectionItem.schemaData);
    }

    let pipelineSchemaMappings;

    switch (prevMappingJob.output.type) {
      case Enums.MappingSourceType.INTEGRATION: {
        const integration = integrationTemplates?.find(
          (integration) =>
            integration.entity._id === prevMappingJob.output.entityId,
        )?.entity;

        if (prevMappingJob.output.baseEndpointId) {
          const baseEndpoint = integration?.endpoints.find(
            (base) => base._id === prevMappingJob.output.baseEndpointId,
          );

          const reqEndpoint = baseEndpoint?.endpoints.find(
            (req) => req._id === prevMappingJob.output.requestEndpointId,
          );

          pipelineSchemaMappings =
            reqEndpoint?.responseInfo?.responseSchema?.map((field) =>
              Mappers.SchemaFieldMapper.domainSchemaToDbSchema(field),
            );
        } else if (prevMappingJob.output.customActionId) {
          const action = integration?.customActions.find(
            (act) => act._id === prevMappingJob.output.customActionId,
          );

          pipelineSchemaMappings = action?.responseSchema?.map((field) =>
            Mappers.SchemaFieldMapper.domainSchemaToDbSchema(field),
          );
        }

        break;
      }
      case Enums.MappingSourceType.DATASET: {
        const outputDsm = datasetMetas.find(
          (dsm) => dsm.entity._id === prevMappingJob.output.entityId,
        );
        const activeCollection = outputDsm?.entity.dataCollections.find(
          (coll) => coll._id === outputDsm?.entity.activeDataCollection,
        );

        pipelineSchemaMappings = activeCollection?.schemaData;

        break;
      }

      default:
        pipelineSchemaMappings = activeDataCollectionItem?.schemaData;
    }

    if (pipelineSchemaMappings) {
      // Build mapping field schema
      const pipelineSchema: Interfaces.FieldOutput[] =
        prevMappingJob?.mappings.map((mapping) => {
          return pipelineSchemaMappings.find(
            (field) =>
              mapping.outputField === field.fieldId ||
              mapping.outputField === field.name,
          );
        });

      setPipelineMappings(pipelineSchema);
    }
  }, [
    activeDataCollectionItem?.schemaData,
    datasetMetas,
    integrationTemplates,
    prevMappingJob,
  ]);

  // Handle field change
  const handleFieldChange = (field: string, value: unknown) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[field] = value;

    setPipelineJob(cloned);
  };

  const handleFilterChange = (filter) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[FILTER_FIELD] = [filter];

    setPipelineJob(cloned);
    setFilter(filter);
  };

  return (
    <>
      <StyledBodySubHeader>Select Query</StyledBodySubHeader>
      <StyledText style={{ marginBottom: themeContext.margin.large }}>
        Select which query you would like to fetch data from.
      </StyledText>

      <StyledDropdownWide
        search
        loading={!queriesLoaded}
        selectOnBlur={false}
        upward={true}
        selection
        value={pipelineJob[QUERY_ID] || ''}
        placeholder={'Please select a query'}
        options={existingQueryOptions}
        style={{ marginTop: 0 }}
        onChange={(e, { value }) => handleFieldChange(QUERY_ID, value)}
      />

      <FilterBuilder
        entitySchema={entitySchema}
        fieldOptions={fieldOptions}
        setFilter={handleFilterChange}
        filter={filter}
        smallHeadings={true}
        isAddRowDisabled={!pipelineJob[QUERY_ID]}
        pipelineMappings={pipelineMappings}
      />
    </>
  );
};

export default JobQueryModalComponent;
