import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import usePermission from '../hooks/permission/UsePermission';
import NotAuthorisedPage from '../pages/NotAuthorised/NotAuthorisedPage';

interface OrgManagerRouteProps {
  path: string;
  exact: boolean;
  component: FC;
}

const OrgManagerRoute: FC<OrgManagerRouteProps> = ({
  path,
  exact,
  component,
}) => {
  const { isOrgManager } = usePermission();

  return (
    <Route
      path={path}
      exact={exact}
      component={isOrgManager ? component : NotAuthorisedPage}
    />
  );
};

export default OrgManagerRoute;
