import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faCog,
  faDatabase,
  faSearch,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import NavItem from '../../components/Navigation/NavItem';
import NavTitle from '../../components/Navigation/NavTitle';
import useFilter from '../../hooks/filter/UseFilter';
import useProject from '../../hooks/project/UseProject';
import useQuery from '../../hooks/query/UseQuery';
import { BackNavigationItem } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import {
  resetAggregationStage,
  updateActiveProjectSubStage,
} from '../../store/project-stage';
import {
  ConfigurationSubStage,
  ProjectAggregationSubStage,
} from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../ProjectItem/ProjectItemNavigation/styled';

const NavIcons = {
  [ProjectAggregationSubStage.NAME]: faWrench,
  [ProjectAggregationSubStage.DATASETS]: faDatabase,
  [ProjectAggregationSubStage.AGGREGATE]: faSearch,
};

const AggregationItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { projectAccessLevel } = useProject();
  const { query, editQuery } = useQuery();
  const { getDatasetFilters, updateFilterGroup } = useFilter();

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [ProjectAggregationSubStage.CONFIGURATION]: true });

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const aggregationStage =
    projectStageState.stages[Enums.ProjectStage.AGGREGATION];
  const activeSubStage = aggregationStage.activeSubStage;

  const saveQuery = async (nextStage: ProjectAggregationSubStage) => {
    const cloned = cloneDeep(query);

    // Remove any reference to removed fields
    const clearKeys = ['groups', 'sort', 'measures'];

    clearKeys.forEach((k) => {
      cloned.queryParams[k] = cloned.queryParams[k].filter((f) => {
        if (k === 'sort' && f.isMeasure) {
          return true;
        }

        return !!cloned.queryParams.fields.find(
          (af) => af.datasetMetaId === f.datasetMetaId && af.field === f.field,
        );
      });
    });

    // Remove any removed fields from filter state
    const savedFilters = getDatasetFilters(cloned._id);
    const clearRemovedFieldsFromFilterState = (filter) => {
      return {
        ...filter,
        value:
          Array.isArray(filter.value) && filter.value[0]?.operator
            ? filter.value
                .filter((v) => {
                  // If a filter wrapper, check if no values or if single value has it been deleted
                  if (!v.field) {
                    if (Array.isArray(v.value)) {
                      if (!v.value.length) {
                        return false;
                      }

                      if (v.value[0]?.operator && v.value.length === 1) {
                        if (
                          !cloned.queryParams.fields.find(
                            (af) => af.field === v.value[0].field,
                          )
                        ) {
                          return false;
                        }
                      }

                      return true;
                    }
                  }

                  // Otherwise check if field still exists
                  return !!cloned.queryParams.fields.find(
                    (af) => af.field === v.field,
                  );
                })
                .map((v) => clearRemovedFieldsFromFilterState(v))
            : filter.value,
      };
    };

    const updatedFilters = (savedFilters || []).map((f) =>
      clearRemovedFieldsFromFilterState(f),
    );

    updateFilterGroup(cloned._id, updatedFilters);

    dispatch(showLoading({ text: 'Saving Query...' }));

    await editQuery(cloned);

    dispatch(updateActiveProjectSubStage(nextStage));
    dispatch(hideLoading());
  };

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`aggregation-stage-back`}
        onClick={() => dispatch(resetAggregationStage())}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={themeContext.colors.system.white}
        />

        <NavTitle text={'Back To Queries'} />
      </BackNavigationItem>

      {projectAccessLevel !== Enums.AccessLevel.MANAGE && (
        <NavItem
          key={`aggregation-stage-${ProjectAggregationSubStage.AGGREGATE}`}
          onClick={() =>
            dispatch(
              updateActiveProjectSubStage(ProjectAggregationSubStage.AGGREGATE),
            )
          }
          isActive={activeSubStage === ProjectAggregationSubStage.AGGREGATE}
        >
          <FontAwesomeIcon
            icon={NavIcons[ProjectAggregationSubStage.AGGREGATE]}
            color={themeContext.colors.system.white}
          />

          <NavTitle
            text={
              aggregationStage.subStages.find(
                (s) => s.subStage === ProjectAggregationSubStage.AGGREGATE,
              )?.title || 'Aggregate'
            }
          />
        </NavItem>
      )}

      {projectAccessLevel === Enums.AccessLevel.MANAGE &&
        Object.values(aggregationStage.subStages)
          .filter(
            (subStage) =>
              subStage.subStage !== ProjectAggregationSubStage.CONFIGURATION,
          )
          .map((subStage) => {
            const subStageName =
              subStage.subStage as ProjectAggregationSubStage;

            return (
              <NavItem
                disabled={
                  (subStageName === ProjectAggregationSubStage.DATASETS &&
                    !query?.name) ||
                  (subStageName === ProjectAggregationSubStage.AGGREGATE &&
                    !query?._id)
                }
                key={`aggregation-stage-${subStageName}`}
                onClick={() => {
                  if (subStageName === ProjectAggregationSubStage.AGGREGATE) {
                    saveQuery(subStageName);
                  } else {
                    dispatch(updateActiveProjectSubStage(subStageName));
                  }
                }}
                isActive={activeSubStage === subStageName}
              >
                <FontAwesomeIcon
                  icon={NavIcons[subStageName]}
                  color={themeContext.colors.system.white}
                />

                <NavTitle text={subStage.title} />
              </NavItem>
            );
          })}

      {/* Configuration accordion nav */}
      {query?._id && (
        <NavigationAccordion>
          <div key={`${ProjectAggregationSubStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={
                !accordionGroups[ProjectAggregationSubStage.CONFIGURATION]
              }
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [ProjectAggregationSubStage.CONFIGURATION]:
                    !accordionGroups[ProjectAggregationSubStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle
                  text={
                    aggregationStage.subStages.find(
                      (s) =>
                        s.subStage === ProjectAggregationSubStage.CONFIGURATION,
                    )?.title || 'Config'
                  }
                />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[Enums.DatasetStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={
                !accordionGroups[ProjectAggregationSubStage.CONFIGURATION]
              }
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActiveProjectSubStage(
                        ProjectAggregationSubStage.CONFIGURATION,
                      ),
                    );
                  }}
                  isActive={
                    activeSubStage === ProjectAggregationSubStage.CONFIGURATION
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={ConfigurationSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default AggregationItemNavigation;
