import { Interfaces } from '@configur-tech/upit-core-types';

const InitialDataAPI: Interfaces.DatasetApi = {
  access: {
    manage: {
      organisation: [],
      team: [],
      user: [],
    },
    edit: { organisation: [], team: [], user: [] },
    view: { organisation: [], team: [], user: [] },
  },

  alias: '',

  allowedMethods: {
    httpAll: {
      isPublic: false,
      isEnabled: false,
    },
    httpPost: {
      isPublic: false,
      isEnabled: false,
    },
    httpPut: {
      isPublic: false,
      isEnabled: false,
    },
    httpGet: {
      isPublic: false,
      isEnabled: false,
    },
    httpDelete: {
      isPublic: false,
      isEnabled: false,
    },
  },
  datasetMetaId: '',
  organisationId: '',
};

export default InitialDataAPI;
