import { Interfaces } from '@configur-tech/upit-core-types';
import { FormsItemOutput } from '../../store/project-form';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_FORM'] || '';

export default class FormService {
  public static async getForms(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<FormsItemOutput | Interfaces.ErrorResponseBody> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<FormsItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);

    return result?.data as FormsItemOutput;
  }

  public static async getForm(
    token: string,
    id: string,
  ): Promise<Interfaces.FormOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<Interfaces.FormOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as Interfaces.FormOutput;
  }

  public static async postForm(
    token: string,
    form: Interfaces.Form,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.FormOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<Interfaces.FormOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: form, projectId, userId });

    return result?.data as Interfaces.FormOutput;
  }

  public static async putForm(
    token: string,
    form: Interfaces.FormOutput,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.FormOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<Interfaces.FormOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${form._id}`, { data: form, projectId, userId });

    return result?.data as Interfaces.FormOutput;
  }

  public static async deleteForm(
    token: string,
    formId: string,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.FormOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<Interfaces.FormOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${formId}`, userId, projectId);

    return result?.data as Interfaces.FormOutput;
  }
}
