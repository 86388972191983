import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../store/rootReducer';

interface SchemaColumnDropdownProps {
  onChange: (val: string) => void;
  datasetMetaId: string;
  initialColumnId?: string;
}

const SchemaColumnDropdown: FC<SchemaColumnDropdownProps> = ({
  onChange,
  datasetMetaId,
  initialColumnId,
}) => {
  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [columnId, setColumnId] = useState<string>();
  const [columnOptions, setColumnOptions] = useState<DropdownItemProps[]>([]);

  // Map allowed columns to options
  useEffect(() => {
    setColumnId(undefined);
    setColumnOptions([]);

    if (!datasetMetaId) {
      return;
    }

    const selectedDataset = datasetMetas.find(
      (dsm) => dsm.entity._id === datasetMetaId,
    )?.entity;

    const activeDataCollection = selectedDataset?.dataCollections.find(
      (c) => c._id === selectedDataset.activeDataCollection,
    );

    const options =
      activeDataCollection?.schemaData
        .filter(
          (c) =>
            c.dataValidation?.constraints?.isUnique ||
            c.dataValidation?.constraints?.isAutoInc,
        )
        .map((column) => {
          return {
            key: column.fieldId,
            value: column.fieldId,
            text: column.name,
          };
        }) || [];

    setColumnOptions(options);
  }, [datasetMetas, datasetMetaId]);

  const handleDatasetSelection = (val: string) => {
    setColumnId(val);
    onChange(val);
  };

  return (
    <>
      <StyledBodySubHeader>Select a Column</StyledBodySubHeader>
      {datasetMetaId && !columnOptions.length && (
        <StyledText>
          This dataset has no unique columns. You can set this constraint when
          amending the Data Structure of this dataset.
        </StyledText>
      )}
      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a Column'}
        disabled={!columnOptions.length}
        selection
        options={columnOptions}
        value={initialColumnId || columnId}
        onChange={(e, data) => handleDatasetSelection(data.value)}
      />
    </>
  );
};

export default SchemaColumnDropdown;
