import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { EntityType, RouteName } from '../../../../enums';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { resetStagesAndSubStages } from '../../../../store/integration-stage';
import { showModal } from '../../../../store/modal';
import ActionBar from '../../../ActionBar/ActionBar';
import AdvancedSetting from '../../../AdvancedSetting/AdvancedSetting';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';

const IntegrationConfigurationAdvancedStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { integrationTemplate } = useIntegrationTemplate();

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        <AdvancedSetting
          action={() =>
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.DELETION,
                additionalProps: {
                  entityType: EntityType.INTEGRATION,
                  entityId: integrationTemplate?._id,
                  entityName: integrationTemplate?.name,
                },
              }),
            )
          }
          headerText={'Delete Integration'}
          subHeaderText={`Delete your integration completely.`}
          bodyText={`This will destroy all integration data and may break any pipelines or connections that this integration is linked to. Be absolutely sure you wish to delete this integration before proceeding.`}
          actionButtonText={'Delete Integration'}
          actionButtonColor={themeContext.colors.general.red}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(resetStagesAndSubStages);
              history.push(RouteName.INTEGRATION_CENTRE);
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Integrations'}
          />
        }
      />
    </StageWrapper>
  );
};

export default IntegrationConfigurationAdvancedStage;
