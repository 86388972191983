import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled, { ThemeContext } from 'styled-components';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledErrorText,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectConnectionCMSSubStage } from '../../../../store/project-stage/initial-state';
import { RootState } from '../../../../store/rootReducer';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectConnectionCMSSubStage.DATA;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemCMSNameStage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { connections } = useProject();

  const cmsState = useSelector((state: RootState) => state.cms);
  const cmsData = cmsState.data;

  const [isDuplicateName, setIsDuplicateName] = useState<boolean>(false);

  useEffect(() => {
    if (params.cmsId) {
      (async () => {
        const connection = connections?.cms?.find(
          (c) => c._id === params.cmsId,
        );

        if (connection) {
          dispatch(fetchCMSSuccess(connection));
        }
      })();
    }
  }, [connections, dispatch, params.cmsId, params.portalId]);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(cmsData);

    if (field === DESCRIPTION_FIELD) {
      cloned.configuration.description = val;
    } else {
      if (field === NAME_FIELD) {
        const nameExists = connections?.cms.find(
          (c) =>
            c.name.toLowerCase() === (val as string).toString().toLowerCase(),
        );
        if (nameExists) {
          setIsDuplicateName(true);
        } else {
          setIsDuplicateName(false);
        }
      }

      cloned[field] = val;
    }
    dispatch(fetchCMSSuccess(cloned));
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Workspace Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new workspace.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your workspace name'}
            value={cmsData?.name || ''}
            onChange={(event, data) => handleChange(NAME_FIELD, data.value)}
            style={{ marginBottom: themeContext.margin.standard }}
          />
          {isDuplicateName && (
            <StyledErrorText>
              A workspace with this name already exists.
            </StyledErrorText>
          )}

          <StyledBodySubHeader
            style={{ marginTop: themeContext.margin.standard }}
          >
            Description
          </StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this workspace?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={
              (cmsData?.configuration as Interfaces.CMSConnectionConfiguration)
                ?.description || ''
            }
            onChange={(event) =>
              handleChange(DESCRIPTION_FIELD, event.target.value)
            }
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!cmsData?.name.length || isDuplicateName}
            action={() => dispatch(updateActiveProjectSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Data'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemCMSNameStage;
