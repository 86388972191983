import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialListStage, {
  ListCreateSubStage,
  ListStage,
  ListSubStages,
} from './initial-state';

const LitStageSlice = createSlice({
  name: 'listStage',
  initialState: InitialListStage,
  reducers: {
    updateActiveListStage(state, action: PayloadAction<ListStage>) {
      state.activeStage = action.payload;
    },
    updateActiveListSubStage(state, action: PayloadAction<ListSubStages>) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },
    resetStagesAndSubStages(state) {
      state.activeStage = ListStage.CREATION;
      state.stages[ListStage.CREATION].activeSubStage = ListCreateSubStage.NAME;
    },
  },
});

export const {
  updateActiveListStage,
  updateActiveListSubStage,
  resetStagesAndSubStages,
} = LitStageSlice.actions;

export default LitStageSlice.reducer;
