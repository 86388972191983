import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { CollectionStatus } from '@configur-tech/upit-core-types/lib/enums';
import { cloneDeep, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components';
import DataExport from '../../components/DataExport/DataExport';
import DataSample from '../../components/DataSample/DataSample';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { SELECTION_HEADER } from '../../const/DataSample';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import useDatasetExporter from '../../hooks/dataset-exporter/UseDatasetExporter';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import usePagination, {
  DEFAULT_META_PAGE_SIZE,
  SortByItem,
} from '../../hooks/pagination/UsePagination';
import { SampleDataRow } from '../../interfaces/SampleData';
import { FetchParams } from '../../interfaces/Search';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { hideLoading, showLoading } from '../../store/loading';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Dataset Name';
const TAGS_FIELD = 'Tags';
const LAST_UPDATED_FIELD = 'Last Updated';
const COLUMN_COUNT_FIELD = 'Column Count';
const ROW_COUNT_FIELD = 'Row Count';
const SEARCH_FIELD = 'name';

const SORT_FIELDS_MAP = {
  [NAME_FIELD]: 'name',
  [TAGS_FIELD]: 'tag',
  [LAST_UPDATED_FIELD]: 'meta.lastUpdated',
  [COLUMN_COUNT_FIELD]: 'dataCollections.columnCount',
  [ROW_COUNT_FIELD]: 'dataCollections.rowCount',
};

const TABLE_SCHEMA: Interfaces.Field[] = [
  { name: NAME_FIELD },
  { name: TAGS_FIELD },
  {
    name: LAST_UPDATED_FIELD,
    dataValidation: {
      dataValidationType: Enums.ValueDataType.DATE,
    },
  },
  { name: COLUMN_COUNT_FIELD },
  { name: ROW_COUNT_FIELD },
];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const DatasetsExportPage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const { getDatasetMetas } = useDatasetMeta();
  const { downloadDatasets } = useDatasetExporter();
  const { calculateRowNumber, updateSortBy } = usePagination();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [loadingDatasets, setLoadingDatasets] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<Enums.AcceptedFileType>(
    Enums.AcceptedFileType.CSV,
  );
  const [applyFormatting, setApplyFormatting] = useState<boolean>(true);

  const [activeDatasets, setActiveDatasets] = useState<DatasetMetaItemOutput[]>(
    [],
  );

  const [datasetsToExport, setDatasetsToExport] = useState<{ _id: string }[]>(
    [],
  );

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 1,
    sortBy: undefined,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 0,
    sortBy: undefined,
  });

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get('search');
    setFetchParams((state) => ({
      ...state,
      searchQuery: {
        ...state.searchQuery,
        query:
          searchParams === null
            ? ''
            : decodeURI(searchParams as string).toString(),
      },
    }));
  }, [location.search]);

  // Fetch Dataset Metas
  useEffect(() => {
    (async () => {
      if (
        fetchParams.searchQuery.query !== lastFetchedParams.searchQuery.query &&
        fetchParams.pageNum !== 1
      ) {
        return;
      }

      if (!isEqual(fetchParams, lastFetchedParams)) {
        setLastFetchedParams(fetchParams);

        setLoadingDatasets(true);
        await getDatasetMetas(
          { activeDataCollection: { $exists: true } },
          fetchParams.searchQuery?.query?.length
            ? fetchParams.searchQuery
            : undefined,
          fetchParams.pageNum,
          undefined,
          fetchParams.sortBy,
        );
        setLoadingDatasets(false);
      }
    })();
  }, [
    fetchParams,
    fetchParams.pageNum,
    fetchParams.searchQuery,
    fetchParams.sortBy,
    getDatasetMetas,
    lastFetchedParams,
  ]);

  // Filter active datasets
  useEffect(() => {
    if (datasetMetas?.length) {
      const active = datasetMetas.reduce(
        (acc: DatasetMetaItemOutput[], dsm, i) => {
          if (!dsm.entity._id || !dsm.entity.dataCollections) {
            return acc;
          }

          const ent = dsm.entity;
          const collection = ent.activeDataCollection
            ? ent.dataCollections.find(
                (c) => c._id === ent.activeDataCollection,
              )
            : ent.dataCollections[0];

          if (!collection || collection.status !== CollectionStatus.ACTIVE) {
            return acc;
          }

          return acc.concat(dsm);
        },
        [],
      );

      setActiveDatasets(active);
    }
  }, [datasetMetas]);

  const tableData: SampleDataRow[] = useMemo(() => {
    return activeDatasets.reduce((acc: SampleDataRow[], dsm, i) => {
      const ent = dsm.entity;
      const collection = ent.activeDataCollection
        ? ent.dataCollections.find((c) => c._id === ent.activeDataCollection)
        : ent.dataCollections[0];

      if (!collection) {
        return acc;
      }

      return acc.concat({
        [ROW_NUMBER]: {
          value: calculateRowNumber(
            fetchParams.pageNum,
            i,
            DEFAULT_META_PAGE_SIZE,
          ),
        },
        [ID_FIELD]: { value: ent._id },
        [TAGS_FIELD]: {
          value: ent.tags?.map((tag) => tag.value).toString(),
          tags: {
            activeTagValue: fetchParams.searchQuery.query,
          },
        },
        [NAME_FIELD]: { value: ent.name, avatar: ent.avatar },
        [LAST_UPDATED_FIELD]: {
          value: DateTime.fromISO(ent.meta.lastUpdated).toFormat(
            'dd/MM/yyyy HH:mm:ss',
          ),
        },
        [COLUMN_COUNT_FIELD]: {
          value: collection.columnCount?.toString() || '0',
        },
        [ROW_COUNT_FIELD]: {
          value: collection.rowCount?.toString() || '0',
        },
      });
    }, []);
  }, [
    activeDatasets,
    calculateRowNumber,
    fetchParams.pageNum,
    fetchParams.searchQuery.query,
  ]);

  const onPageNumberChange = useCallback(
    (page: number) => {
      if (page !== fetchParams.pageNum) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.pageNum = page;

        setFetchParams(cloned);
      }
    },
    [fetchParams],
  );

  const onSortChange = useCallback(
    (sortBy: SortByItem) => {
      if (!isEqual(updateSortBy(SORT_FIELDS_MAP, sortBy), fetchParams.sortBy)) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.sortBy = updateSortBy(SORT_FIELDS_MAP, sortBy);
        setFetchParams(cloned);
      }
    },
    [fetchParams, updateSortBy],
  );

  const onDatasetChange = (dsmIds: string[] = []) => {
    if (dsmIds.length !== datasetsToExport.length) {
      setDatasetsToExport(
        dsmIds.map((d) => {
          return { _id: activeDatasets[d].entity._id };
        }),
      );
    }
  };

  const processAction = async () => {
    dispatch(showLoading({ text: 'Exporting Data...' }));
    try {
      await downloadDatasets(datasetsToExport, exportFormat, applyFormatting);

      toast.success(
        'Export requested successfully! Your file(s) will be emailed to you once ready.',
      );
    } catch (err) {
      toast.error('Export requested failed. Please try again later.');
    }

    dispatch(hideLoading());

    setDatasetsToExport([]);
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.DATASETS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Export Data'}
          image={getDefaultAvatar(EntityType.DATASET)}
          title={'Download Your Data'}
        />

        <Page>
          <PageInner>
            {datasetMetas.length === 0 && (
              <>
                <StageBodyText>
                  You'll need some datasets in order to export them! Come back
                  when you've created some.
                </StageBodyText>
              </>
            )}

            {datasetMetas.length > 0 && (
              <>
                <DataExport
                  exportFormat={exportFormat}
                  setExportFormat={setExportFormat}
                  applyFormatting={applyFormatting}
                  setApplyFormatting={setApplyFormatting}
                  bodyText="To export your data, simply tick the boxes of the datasets
                  you'd like to export and select what format you'd like to
                  export it to."
                />

                <FeatureButton
                  isDisabled={!exportFormat || !datasetsToExport.length}
                  action={processAction}
                  size={FeatureButtonSize.WIDE}
                  color={themeContext.colors.general.blue}
                  text={'Export Data'}
                  containerStyle={{ marginBottom: themeContext.margin.large }}
                />

                <EntityFilter
                  baseRoute={`${RouteName.DATASETS}${RouteName.EXPORT}`}
                  title={'Search Datasets'}
                  search={fetchParams.searchQuery.query}
                  inputPlaceholder={'Enter a dataset name or tag'}
                  totalResultCount={
                    datasetMetasState.data.pagination.totalCount
                  }
                  filteredResultCount={datasetMetas?.length}
                  loading={loadingDatasets}
                />
                <TableWrapper>
                  <DataSample
                    loading={loadingDatasets}
                    sampleColumns={TABLE_SCHEMA}
                    sampleRows={tableData}
                    iconMap={AvatarIconMap}
                    toggleAction={onDatasetChange}
                    hideSortOnCols={[ROW_NUMBER, TAGS_FIELD, SELECTION_HEADER]}
                    showPagination={true}
                    sortAction={onSortChange}
                    resetPagination={
                      lastFetchedParams.searchQuery.query !==
                      fetchParams.searchQuery.query
                    }
                    pageSize={DEFAULT_META_PAGE_SIZE}
                    paginationAction={onPageNumberChange}
                    totalRows={datasetMetasState?.data.pagination.totalCount}
                  />
                </TableWrapper>
              </>
            )}
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default DatasetsExportPage;
