import { Interfaces } from '@configur-tech/upit-core-types';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';

export const getMissingLookupFieldDatasetIds = (
  schema: Interfaces.Field[],
  datasetMetas: DatasetMetaItemOutput[],
): string[] => {
  return schema.reduce((acc: string[], f) => {
    const lookupDsmId = f.dataValidation?.fieldLookup?.target.datasetMetaId;

    if (
      lookupDsmId &&
      !datasetMetas.find((d) => d.entity._id === lookupDsmId)
    ) {
      return [...acc, lookupDsmId];
    }

    return acc;
  }, []);
};
