/**
 * Gets fields Text Transformation depends on
 *
 * @param {Interfaces.TextTransformation} textTransformation - Text transformation to fetch dependencies for
 *
 * @returns {string[]} - List of fields text transformation depends on
 */
import { Enums, Interfaces } from '@configur-tech/upit-core-types';

const getTextTransformationDependencies = (
  textTransformation: Interfaces.TextTransformation,
): string[] => {
  switch (textTransformation.type) {
    case Enums.TextTransformOperation.CONCAT:
      return textTransformation.params.fields;
    case Enums.TextTransformOperation.SUBSTRING:
    default:
      return [textTransformation.params.field];
  }
};

export default getTextTransformationDependencies;
