import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { uniq } from 'lodash';
import { SampleDataRow } from '../../interfaces/SampleData';
import { cloneDeep } from 'lodash';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import { QueryWithDetails } from '../../store/queries';

export interface QueryFiltersForDataset {
  datasetMetaId: string;
  filters: DynamicConditionalField[];
}

const SUBTOTAL_FIELD_VALUE = 'Total';

/**
 * Removes numerical formqtting from a number value
 *
 * @param {string} value - Value to cleanse
 * @param {DataValidation} dataValidation - Data validation obj containing formatting detail
 *
 * @returns {string} - Cleansed string
 */
const cleanseNumericalFiltering = (
  value: string,
  dataValidation: Interfaces.DataValidation,
): string => {
  if (
    !dataValidation?.dataValidationType ||
    ![Enums.ValueDataType.NUMBER, Enums.ValueDataType.FORMULA].includes(
      dataValidation.dataValidationType,
    )
  ) {
    return value;
  }

  const cleaned = parseFloat(value.toString().replace(/[^\d.-]/g, ''));
  if (
    (dataValidation.formatting as Interfaces.NumericalFormatting)?.format ===
    Enums.DataDisplayFormat.PERCENTAGE
  ) {
    // Remove percentage formatting
    return (cleaned / 100).toString();
  }

  return cleaned.toString();
};

/**
 * Converts Query Parameters to QueryFiltersForDataset[]
 *
 * @param {Interfaces.QueryParams} queryParams - Query parameters
 * @param {SampleDataRow} tableRow - Selected table row
 *
 * returns {QueryFiltersForDataset[]} - Array of QueryFiltersForDataset
 */
//
const queryParamsToFilter = (
  query: QueryWithDetails,
  tableRow: SampleDataRow,
  customFilters?: Interfaces.DynamicConditionalField[],
): QueryFiltersForDataset[] => {
  const queryParams = query.queryParams;
  const queryFields = query.queryParams.fields;
  const additionalDetails = query.additionalDetails;

  const queryFiltersForDataset: QueryFiltersForDataset[] = [];

  // Get all DatasetMetaIds used in query
  const uniqueQueryDatasetMetaIds = uniq(
    query.queryParams?.fields?.map((f) => f.datasetMetaId),
  );

  const filters = cloneDeep(query.queryParams.filters);

  // Build combined filters and converted group filters per datasetMetaId
  uniqueQueryDatasetMetaIds.map((datasetMetaId: string) => {
    // Get filters for DatasetMetaId
    const datasetFilters =
      ((filters[0]?.value[0]?.value as DynamicConditionalField[])?.filter(
        (v) => v.datasetMetaId === datasetMetaId,
      ) as DynamicConditionalField[]) || [];
    const combinedFilters: DynamicConditionalField[] = [];

    // Add base filter
    combinedFilters.push({
      operator: Enums.AggregationFilterOperator.AND,
      value: [],
      active: true,
    });

    datasetFilters.map((f) =>
      // Push standard filters to combined filters
      (combinedFilters[0].value as DynamicConditionalField[]).push({
        ...f,
        // Get field name from schema as multi dataset query will contain a join character
        alias: queryFields?.find((s) => s.field === f.field)?.alias,
      }),
    );

    if (customFilters) {
      const customFilterValues =
        (customFilters[0]?.value[0]
          ?.value as DynamicConditionalField[] as DynamicConditionalField[]) ||
        [];

      customFilterValues
        ?.filter((f) => !f.isMeasure)
        ?.map((f) =>
          // Push custom filters to combined filters
          (combinedFilters[0].value as DynamicConditionalField[]).push(f),
        );
    }

    const queryGroups = queryParams?.groups?.filter(
      (g) => g.datasetMetaId === datasetMetaId,
    );

    const schemaData = additionalDetails?.[0]?.schemaData;

    queryGroups?.map((group) => {
      const dataValidation = schemaData?.find(
        (s) => s.fieldId === group.field && s.name === group.alias,
      )?.dataValidation;

      if (
        dataValidation?.dataValidationType === Enums.ValueDataType.FIELD_LOOKUP
      ) {
        return;
      }

      // Ignore any subtotals
      if (tableRow[`${group?.alias}`]?.value === SUBTOTAL_FIELD_VALUE) {
        return;
      }

      // Remove numerical formatting
      const cleansedValue = cleanseNumericalFiltering(
        (tableRow[`${group?.alias}`]?.value || '') as string,
        dataValidation as Interfaces.DataValidation,
      );

      // Push converted group filters to combined filters
      (combinedFilters[0].value as DynamicConditionalField[]).push({
        field: group.field,
        datasetMetaId: group.datasetMetaId,
        // Get field name from schema as multi dataset query will contain a join character
        alias: queryFields?.find((s) => s.field === group.field)?.alias,
        operator: dataValidation?.constraints?.listValues
          ? Enums.AggregationFilterOperator.IN
          : Enums.AggregationFilterOperator.EQUAL,
        isMeasure: false,
        value: {
          type: Enums.DynamicConditionalType.CONSTANT,
          value: dataValidation?.constraints?.listValues
            ? [tableRow[`${group?.alias}`]?.value]
            : cleansedValue,
        },
      });
    });

    // Add converted group filters to filters
    queryFiltersForDataset.push({
      datasetMetaId: datasetMetaId,
      filters: [
        {
          operator: Enums.AggregationFilterOperator.AND,
          value: combinedFilters,
        },
      ],
    });
  });

  return queryFiltersForDataset;
};

export default queryParamsToFilter;
