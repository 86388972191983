import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import useList from '../../hooks/list/UseList';
import PageContainer from '../../main/PageContainer';
import { ListStage } from '../../store/list-stage/initial-state';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import ListItemConfiguration from './ListConfiguration';
import ListItemCreation from './ListCreation';

const ListItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { list, getList } = useList();

  const [fetchingList, setFetchingList] = useState<boolean>(false);

  const listStageState = useSelector((state: RootState) => state.listStage);
  const listState = useSelector((state: RootState) => state.list);

  const activeStage = listStageState.activeStage;

  // Get an existing list if requested
  useEffect(() => {
    if (!fetchingList && params?.listId && params?.listId !== list?._id) {
      (async () => {
        setFetchingList(true);
        dispatch(
          showLoading({
            text: `Loading List...`,
          }),
        );

        await getList(params.listId);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, fetchingList, getList, list?._id, params.listId]);

  useEffect(() => {
    if (listState?.error) {
      // Handle error
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'List Not Found',
            errorText:
              'This list could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Lists',
            errorActionRedirectRoute: `${RouteName.DATASETS}${RouteName.LISTS}`,
          },
        }),
      );
    }
  }, [dispatch, listState]);

  const getStageComponent = () => {
    switch (activeStage) {
      case ListStage.CREATION:
        return <ListItemCreation />;
      case ListStage.CONFIGURATION:
        return <ListItemConfiguration />;
      default:
        return <ListItemCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.LIST_ITEM} />
      <PageContainer>{getStageComponent()}</PageContainer>
    </>
  );
};

export default ListItemPage;
