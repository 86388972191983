import styled from 'styled-components';

export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const GridWrapper = styled.div<{ showFilters?: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr) ${({ showFilters }) =>
      showFilters ? '260px' : 0};

  width: 100%;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: ${({ theme }) => theme.margin.large}
    ${({ theme }) => theme.margin.standard};

  height: calc(100vh - 150px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const PageInner = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `${theme.padding.large} ${theme.padding.xlarge}`};
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xlarge}`};
  
  background-color ${({ theme }) => theme.colors.system.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  
  > p {
   margin: 0
  }
  
  > div {
  display: flex;
    > div {
      margin-left: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const GraphList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: ${({ theme }) => theme.margin.xlarge} 0 0 0;
  width: 100%;
`;

export const GraphListItem = styled.li`
  display: flex;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const DraggableGraphItemWrapper = styled.div`
  width: 100%;

  svg {
    cursor: grab;
  }
`;
