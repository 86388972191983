import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, isEmpty, startCase } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps, Icon, Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import useList from '../../../hooks/list/UseList';
import UseNotificationAlert from '../../../hooks/notification-alert/UseNotificationAlert';
import useTeam from '../../../hooks/team/UseTeam';
import useUser from '../../../hooks/user/UseUser';
import { SearchQuery } from '../../../interfaces/Search';
import {
  DefaultPopupStyles,
  defaultTheme,
  StyledBodySubHeader,
  StyledDropdown,
  StyledDropdownUltraWide,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { TeamsItemOutput } from '../../../services/team/TeamService';
import { UserItemOutput } from '../../../services/user/UserService';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { fetchNotificationAlertSuccess } from '../../../store/notification-alert';
import { RootState } from '../../../store/rootReducer';
import { formatTeamAndUserOptions } from '../../AccessSelection/AccessSelection';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import FilterBuilder from '../filter/FilterBuilder';
import { DropdownItemPropsWithValidation } from '../filter/FilterModal';
import { SLACK, TEAMS } from '../integration-centre/IntegrationManageModal';
import * as SC from './styled';

export interface NotificationAlertModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing?: boolean;
  notification: Interfaces.NotificationAlert;
}

const SEARCH_TYPING_TIMEOUT = 1000;
const SEARCH_FIELD_DATASET = 'name';
const FREQUENCY_KEY = 'frequency';
const CHANNEL_KEY = 'channel';

const formatChannelOptions = (arr: Interfaces.CustomConfigParam[] | []) => {
  return arr.map((e, i) => {
    return {
      key: `channel-${e.field}-${i}`,
      value: e.field,
      text: e.field,
    };
  });
};

const INITIAL_FILTER = {
  operator: Enums.AggregationFilterOperator.AND,
  value: [{ operator: Enums.AggregationFilterOperator.IN }],
};

const FREQUENCY_OPTIONS = Object.values(Enums.NotificationFrequency).map(
  (type, index) => ({
    key: `frequency-${type}-${index}`,
    value: type,
    text:
      type === Enums.NotificationFrequency.ONCE
        ? 'On Change'
        : startCase(type.toLowerCase()),
  }),
);

const NotificationAlertModal: FC<NotificationAlertModalProps> = ({
  setShowModal,
  isEditing,
  notification,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getUsers } = useUser();
  const { getTeams } = useTeam();
  const { getDatasetMetasDirect } = useDatasetMeta();
  const {
    notificationAlert,
    notificationAlertAccessLevel,
    addNotificationAlert,
    editNotificationAlert,
  } = UseNotificationAlert();
  const { getListsForResource } = useList();

  const usersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  const teamsState = useSelector((state: RootState) => state.teams);
  const teams: TeamsItemOutput = teamsState.data;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data;

  const [datasetMetasFetched, setDatasetMetasFetched] = useState<
    DatasetMetaItemOutput[]
  >(datasetMetasState?.data || []);

  const integrationConfigState = useSelector(
    (state: RootState) => state.integrationConfigs,
  );
  const integrationConfigEnts = integrationConfigState.data.data;

  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();

  const [canProgress, setCanProgress] = useState<boolean>();

  const [datasetOptions, setDatasetOptions] = useState<
    Map<string, DropdownItemProps>
  >(new Map());

  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();

  const [recipientUserOptions, setRecipientUserOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [recipientSlackOptions, setRecipientSlackOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [recipientTeamsOptions, setRecipientTeamsOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [channelOptions, setChannelOptions] = useState<DropdownItemProps[]>([]);

  const [conditions, setConditions] =
    useState<DynamicConditionalField>(INITIAL_FILTER);

  const [loadingDatasetMetas, setLoadingDatasetMetas] = useState<boolean>();
  const [loadedUsers, setLoadedUsers] = useState<boolean>();
  const [loadedTeams, setLoadedTeams] = useState<boolean>();

  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD_DATASET,
    query: undefined,
  });

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD_DATASET,
    query: undefined,
  });

  const selectedDatasetMeta = useMemo(
    () =>
      datasetMetasFetched.find(
        (dsm) => dsm.entity._id === notificationAlert?.resource.resourceId,
      )?.entity,
    [datasetMetasFetched, notificationAlert?.resource.resourceId],
  );

  const selectedDatasetName = useMemo(
    () =>
      datasetMetasFetched.find(
        (dsm) => dsm.entity._id === notificationAlert?.resource.resourceId,
      )?.entity?._id,
    [datasetMetasFetched, notificationAlert?.resource.resourceId],
  );

  // Set Slack and Teams integration configs if available
  useEffect(() => {
    const slackCustomConfig = integrationConfigEnts.find(
      (c) => c.entity.name === SLACK,
    )?.entity?.customConfig;
    const teamsCustomConfig = integrationConfigEnts.find(
      (c) => c.entity.name === TEAMS,
    )?.entity?.customConfig;

    // Update channel options
    setChannelOptions(
      Object.values(Enums.NotificationChannel).map((type, index) => ({
        key: `channel-${type}-${index}`,
        value: type,
        text: (
          <>
            {startCase(type.toLowerCase())}
            {((type === Enums.NotificationChannel.MS_TEAMS &&
              !teamsCustomConfig) ||
              (type === Enums.NotificationChannel.SLACK &&
                !slackCustomConfig)) && (
              <div className="description">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  color={defaultTheme.colors.system.white}
                />
                <span>Not integrated</span>
              </div>
            )}
          </>
        ),
        className: 'integration',
        disabled:
          (type === Enums.NotificationChannel.MS_TEAMS && !teamsCustomConfig) ||
          (type === Enums.NotificationChannel.SLACK && !slackCustomConfig),
      })),
    );

    // Set slack and teams options
    setRecipientSlackOptions(formatChannelOptions(slackCustomConfig || []));
    setRecipientTeamsOptions(formatChannelOptions(teamsCustomConfig || []));
  }, [integrationConfigEnts]);

  // Load users
  useEffect(() => {
    if (isEmpty(users)) {
      getUsers();
    }
    setLoadedUsers(true);
  }, [getUsers, users]);

  // Load teams
  useEffect(() => {
    if (isEmpty(teams)) {
      getTeams();
    }
    setLoadedTeams(true);
  }, [getTeams, teams]);

  // Map users and teams to dropdown options
  useEffect(() => {
    if (loadedUsers && loadedTeams) {
      const formattedUsers = formatTeamAndUserOptions(
        EntityType.USER,
        users.map((u) => u.entity),
      );
      const formattedTeams = formatTeamAndUserOptions(
        EntityType.TEAM,
        teams.data.map((t) => t.entity),
      );

      setRecipientUserOptions(formattedUsers.concat(formattedTeams));
    }
  }, [users, teams, loadedUsers, loadedTeams]);

  // Set Entity Schema
  useEffect(() => {
    const activeDataCollectionItem = selectedDatasetMeta?.dataCollections.find(
      (dc) => dc._id === selectedDatasetMeta.activeDataCollection,
    );

    const filteredSchemaData: Interfaces.FieldOutput[] =
      activeDataCollectionItem?.schemaData || [];

    if (filteredSchemaData.length) {
      setEntitySchema(filteredSchemaData);
    }
  }, [
    selectedDatasetMeta,
    entitySchema,
    notificationAlert?.resource.resourceId,
    notificationAlert?.resource.resourceType,
  ]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // Set notification conditions
  useEffect(() => {
    if (notification?.conditions?.queryConditions) {
      const formattedFilter = Array.isArray(
        (
          notification?.conditions
            ?.queryConditions as Interfaces.DynamicConditional
        ).value,
      )
        ? notification?.conditions?.queryConditions
        : {
            operator: Enums.AggregationFilterOperator.AND,
            value: [notification?.conditions?.queryConditions],
          };

      setConditions(formattedFilter);
    } else {
      setConditions(INITIAL_FILTER);
    }
  }, [notification]);

  //get initial dsm
  useEffect(() => {
    (async () => {
      const fetchedDatasetMetas = await getDatasetMetasDirect({
        _id: { $in: [notification?.resource?.resourceId] },
      });

      setDatasetMetasFetched((dsms) => {
        return [...dsms, ...fetchedDatasetMetas];
      });
    })();
  }, [getDatasetMetasDirect, notification]);

  // Fetch datasetMetas
  useEffect(() => {
    if (searchQuery.query) {
      (async () => {
        setLoadingDatasetMetas(true);

        const fetchedDatasetMetas = await getDatasetMetasDirect(
          { activeDataCollection: { $ne: null } },
          searchQuery?.query?.length ? searchQuery : undefined,
        );

        setDatasetMetasFetched((dsms) => {
          return [...dsms, ...fetchedDatasetMetas];
        });

        setLoadingDatasetMetas(false);
      })();
    }
  }, [
    dispatch,
    getDatasetMetasDirect,
    notification?.resource?.resourceId,
    searchQuery,
  ]);

  // Load lists for notification dataset
  useEffect(() => {
    if (notificationAlert?.resource?.resourceId) {
      getListsForResource(
        Enums.SchemaName.DATASET_META,
        notificationAlert.resource.resourceId,
      );
    }
  }, [getListsForResource, notificationAlert?.resource?.resourceId]);

  // Map datasetMetas to options - add to array as users may search for more options
  useEffect(() => {
    datasetMetasFetched.map((d) => {
      const ent:
        | Interfaces.DatasetMetaOutput
        | Interfaces.QueryOutput
        | Interfaces.ChartOutput = d.entity;

      setDatasetOptions((entOpts) => {
        const cloned = cloneDeep(entOpts);

        cloned.set(ent._id, {
          key: ent._id,
          value: ent._id,
          text:
            d.accessLevel === Enums.AccessLevel.NONE ? (
              <Popup
                content={'You do not have direct access to this Dataset.'}
                position="top center"
                style={DefaultPopupStyles}
                trigger={
                  <div style={{ display: 'inline-block' }}>
                    <Icon name={'attention'} />
                    {ent.name}
                  </div>
                }
              />
            ) : (
              ent.name
            ),
        });

        return cloned;
      });
    });
  }, [datasetMetasFetched]);

  // Get fields from schema
  useEffect(() => {
    if (entitySchema) {
      const options: DropdownItemProps[] = [];
      if (selectedDatasetMeta?._id && entitySchema) {
        options.push(
          ...entitySchema.map((f, i) => {
            return {
              key: `field-${f.name}-${i}`,
              value: `${f.fieldId || f.name}***${selectedDatasetMeta._id}***${
                f.name
              }`,
              text: f.name,
            };
          }),
        );
      }

      return setFieldOptions(options);
    }
  }, [
    entitySchema,
    notificationAlert?.resource.resourceType,
    selectedDatasetMeta?._id,
  ]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (
        value !== searchQuery.query &&
        (!value.length || (value.length && value.trim().length))
      ) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        return setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  const handleDatasetSelection = (val: string) => {
    const cloned = cloneDeep(
      notificationAlert,
    ) as Interfaces.NotificationAlertOutput;
    cloned.resource.resourceId = val;

    dispatch(
      fetchNotificationAlertSuccess({
        accessLevel: notificationAlertAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleRecipientSelection = (val: string[]) => {
    let formattedRecipients: Interfaces.Recipient[];

    if (
      notificationAlert?.channel === Enums.NotificationChannel.EMAIL ||
      notificationAlert?.channel === Enums.NotificationChannel.IN_APP
    ) {
      formattedRecipients = val.map((id) => {
        const recipientEntity =
          users.find((u) => u.entity._id === id) ||
          teams.data.find((t) => t.entity._id === id);

        return {
          type: (recipientEntity as UserItemOutput)?.entity?.firstName
            ? Enums.RecipientType.USER
            : Enums.RecipientType.TEAM,
          recipientId: id,
        };
      });
    } else {
      formattedRecipients = val.map((id) => {
        return {
          type:
            notificationAlert?.channel === Enums.NotificationChannel.SLACK
              ? Enums.RecipientType.SLACK
              : Enums.RecipientType.MS_TEAMS,
          channelId: id,
        };
      });
    }

    const cloned = cloneDeep(
      notificationAlert,
    ) as Interfaces.NotificationAlertOutput;
    cloned.recipients = formattedRecipients;

    dispatch(
      fetchNotificationAlertSuccess({
        accessLevel: notificationAlertAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  // Handle field change
  const handleFieldChange = (field: string, value: unknown) => {
    const cloned = cloneDeep(notificationAlert);
    cloned[field] = value;

    if (field === CHANNEL_KEY) {
      cloned.recipients = [];
    }

    dispatch(
      fetchNotificationAlertSuccess({
        accessLevel: notificationAlertAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const processAction = async () => {
    const cloned = cloneDeep(notificationAlert);
    cloned.conditions = {};
    cloned.conditions.queryConditions = conditions;

    if (!cloned._id) {
      // Create notificationAlert
      dispatch(showLoading({ text: 'Creating Notification Alert...' }));
      await addNotificationAlert(cloned);
    } else {
      // Update notificationAlert
      dispatch(showLoading({ text: 'Updating Notification Alert...' }));

      await editNotificationAlert(cloned);
    }

    dispatch(hideLoading());
    dispatch(hideModal());
  };

  // Confirm filters are complete
  useEffect(() => {
    if (
      conditions?.operator &&
      (conditions?.value as DynamicConditionalField[]).length
    ) {
      if (
        (conditions.value as DynamicConditionalField[]).every(
          (c) =>
            (c.operator || c.fields) &&
            (Array.isArray(c.value)
              ? c.value.length
              : (
                  (c.value as Interfaces.ConstantConditionalValue)
                    ?.value as string
                )?.toString()?.length),
        )
      ) {
        if (
          notificationAlert?.resource?.resourceId &&
          notificationAlert?.resource?.resourceType &&
          notificationAlert?.frequency &&
          notificationAlert?.channel &&
          notificationAlert?.recipients?.length
        ) {
          return setCanProgress(true);
        }
      }
    }

    setCanProgress(false);
  }, [
    conditions?.operator,
    conditions.value,
    notificationAlert?.channel,
    notificationAlert?.frequency,
    notificationAlert?.recipients?.length,
    notificationAlert?.resource?.resourceId,
    notificationAlert?.resource?.resourceType,
  ]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <div>
          <SC.Header>{isEditing ? 'Update' : 'Create'} Notification</SC.Header>
          <StyledText>
            Simply complete the fields below to create your notification
          </StyledText>
        </div>

        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>

      <SC.ContentContainer>
        <SC.Content>
          <>
            <StyledSubHeader>Choose a dataset</StyledSubHeader>

            <StyledText>
              Which dataset would you like to create a notification for?
            </StyledText>

            <StyledDropdown
              selectOnBlur={false}
              loading={loadingDatasetMetas}
              placeholder={'Select a Dataset'}
              noResultsMessage={'Type to search Datasets'}
              selection
              search
              onSearchChange={(e, data) =>
                onSearchValueChange(data.searchQuery)
              }
              clearable
              options={Array.from(datasetOptions, ([, opt]) => opt)}
              value={selectedDatasetName}
              onChange={(e, data) => handleDatasetSelection(data.value)}
            />
          </>

          <SC.TriggerContainer>
            <StyledSubHeader>Create your conditions</StyledSubHeader>
            <StyledText style={{ margin: 0 }}>
              What do you want to be notified about?
            </StyledText>
            <FilterBuilder
              entitySchema={entitySchema}
              filter={conditions}
              fieldOptions={fieldOptions}
              setFilter={setConditions}
              type={EntityType.DATASET}
              hideIntroText={true}
              filterText="Trigger"
            />
          </SC.TriggerContainer>

          <StyledSubHeader>Set your notification options</StyledSubHeader>
          <StyledText>
            How often will notifications be sent and how do you want them to be
            sent?
          </StyledText>

          <SC.InputRowContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Frequency</StyledBodySubHeader>
              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a frequency'}
                options={FREQUENCY_OPTIONS}
                selection
                value={notificationAlert?.frequency}
                onChange={(e, data) => {
                  handleFieldChange(FREQUENCY_KEY, data.value);
                }}
                style={{ margin: 0 }}
              />
            </SC.InputContainer>

            <SC.InputContainer>
              <StyledBodySubHeader>Channel</StyledBodySubHeader>
              <SC.StyledDropdownWithIcon
                selectOnBlur={false}
                placeholder={'Select a channel'}
                options={channelOptions}
                selection
                value={notificationAlert?.channel}
                onChange={(e, data) => {
                  handleFieldChange(CHANNEL_KEY, data.value);
                }}
                style={{ margin: 0 }}
              />
            </SC.InputContainer>
          </SC.InputRowContainer>

          <StyledSubHeader>Choose your recipients</StyledSubHeader>
          <StyledText>Who will receive these notifications?</StyledText>
          <StyledDropdownUltraWide
            selectOnBlur={false}
            loading={!loadedUsers || !loadedTeams}
            placeholder={
              'Start typing a name or group, or choose from the list...'
            }
            noResultsMessage={'Type to search names or groups'}
            selection
            search
            clearable
            options={
              notificationAlert?.channel === Enums.NotificationChannel.SLACK
                ? recipientSlackOptions
                : notificationAlert?.channel ===
                  Enums.NotificationChannel.MS_TEAMS
                ? recipientTeamsOptions
                : recipientUserOptions
            }
            value={notificationAlert?.recipients?.map(
              (r) =>
                (r as Interfaces.UserRecipient)?.recipientId ||
                (r as Interfaces.ChannelRecipient)?.channelId,
            )}
            onChange={(e, data) => handleRecipientSelection(data.value)}
            multiple
            style={{ margin: 0 }}
          />
        </SC.Content>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper>
        <FeatureButton
          isDisabled={!canProgress}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={`${isEditing ? 'Update' : 'Create'} Notification`}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default NotificationAlertModal;
