import { faListUl, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums/RouteName';
import { fetchTeamSuccess } from '../../store/team';
import { resetStagesAndSubStages } from '../../store/team-stage';
import InitialTeam from '../../store/team/initial-team';
import { defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavIcons = {
  [RouteName.TEAMS]: faListUl,
  [RouteName.TEAM_ITEM]: faPlusSquare,
};

const TeamsNavigation: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <NavItem
        key={`teams-view`}
        onClick={() => history.push(RouteName.TEAMS)}
        isActive={location.pathname === RouteName.TEAMS}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.TEAMS]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'View Teams'} />
      </NavItem>
      <NavItem
        key={`teams-create`}
        onClick={() => {
          // Reset team data
          dispatch(resetStagesAndSubStages());
          dispatch(fetchTeamSuccess(InitialTeam));
          history.push(RouteName.TEAM_ITEM);
        }}
        isActive={location.pathname === RouteName.TEAM_ITEM}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.TEAM_ITEM]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'Create New Team'} />
      </NavItem>
    </>
  );
};

export default TeamsNavigation;
