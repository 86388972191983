import { Enums } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import PipelineTemplateService, {
  PipelineTemplateOutput,
  PipelineTemplatesOutput,
} from '../../services/pipeline/PipelineTemplateService';
import { AppThunk } from '../store';

export interface PipelineTemplatesState {
  data: {
    data: PipelineTemplateOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };

  loading: boolean;
  error: string | null;
}

const initialState: PipelineTemplatesState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const PipelineTemplatesSlice = createSlice({
  name: 'integration-templates',
  initialState,
  reducers: {
    // Fetch
    fetchPipelineTemplatesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPipelineTemplatesSuccess(
      state,
      action: PayloadAction<PipelineTemplatesOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchPipelineTemplatesFailure(state, action: PayloadAction<string>) {
      state.data = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPipelineTemplatesStart,
  fetchPipelineTemplatesSuccess,
  fetchPipelineTemplatesFailure,
} = PipelineTemplatesSlice.actions;

export default PipelineTemplatesSlice.reducer;

export const fetchPipelineTemplates =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchPipelineTemplatesStart());
      const fetched = await PipelineTemplateService.getPipelineTemplates(
        token,
        query,
      );
      dispatch(
        fetchPipelineTemplatesSuccess(
          fetched.data as unknown as PipelineTemplatesOutput,
        ),
      );
    } catch (err) {
      dispatch(fetchPipelineTemplatesFailure((err as string).toString()));
      if (
        (err as AxiosError)?.response?.data.statusCode !==
        Enums.StatusCode.NOT_FOUND
      ) {
        throw err;
      }
    }
  };
