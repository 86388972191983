import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faPencilAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums';
import { RouteName } from '../../../../enums/RouteName';
import useChart from '../../../../hooks/chart/UseChart';
import useCMS from '../../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import useProject from '../../../../hooks/project/UseProject';
import useQuery from '../../../../hooks/query/UseQuery';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectConnectionCMSSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FAButton from '../../../FaButton/FAButton';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import CMSEntitySelector from './CMSEntitySelector';
import * as SC from './styled';

const NEXT_STAGE = ProjectConnectionCMSSubStage.ACCESS;
const PREV_STAGE = ProjectConnectionCMSSubStage.NAME;

const ProjectItemCMSDataStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject, addCmsConnection } = useProject();
  const { organisation } = useOrganisation();
  const { getDatasetMetas } = useDatasetMeta();
  const { getQueries } = useQuery();
  const { getCharts } = useChart();
  const { cms, cmsConfig, listCmsEntityIds } = useCMS();

  const [groupAccordionStatus, setGroupAccordionStatus] = useState<
    Record<string, boolean>
  >({});

  const [canProceed, setCanProceed] = useState<boolean>(false);
  const [shouldPreview, setShouldPreview] = useState<boolean>(false);
  const [hasfinished, setHasFinished] = useState<boolean>(false);

  const [loadedContent, setLoadedContent] = useState<boolean>(false);

  // Get datasetMetas & queries
  useEffect(() => {
    if (project?._id && !loadedContent) {
      (async () => {
        setLoadedContent(true);
        const { datasetMetaIds, queryIds, chartIds } = listCmsEntityIds();

        // Fetch datasetMetas
        const dsmIds = [...datasetMetaIds, ...project.datasetMetaId];

        if (dsmIds.length) {
          await getDatasetMetas(
            { _id: { $in: dsmIds } },
            undefined,
            undefined,
            200,
          );
        }

        // Fetch queries
        const aggIds = [...queryIds, ...project.queries];

        if (aggIds.length) {
          await getQueries({ projectOriginId: project._id, limit: 200 });
        }

        // Fetch charts
        const dashIds = [...chartIds, ...project.charts];

        if (dashIds.length) {
          await getCharts({ _id: { $in: dashIds } });
        }
      })();
    }
  }, [
    getCharts,
    getDatasetMetas,
    getQueries,
    listCmsEntityIds,
    loadedContent,
    project?._id,
    project?.charts,
    project?.datasetMetaId,
    project?.queries,
  ]);

  // Set canProceed
  useEffect(() => {
    const groups = cmsConfig?.groups;

    if (groups) {
      setCanProceed(
        groups.every((group) =>
          group.items.every(
            (item) => item.aggregationId || item.datasetMetaId || item.chartId,
          ),
        ),
      );
    }
  }, [cmsConfig]);

  // Add _id from project when created
  useEffect(() => {
    if (project?.connections[Enums.ConnectionType.CMS].length) {
      const connection = project?.connections[Enums.ConnectionType.CMS].find(
        (c) => c.name === cms?.name && !cms._id,
      );

      if (connection) {
        dispatch(fetchCMSSuccess(connection));

        if (hasfinished && !shouldPreview) {
          dispatch(updateActiveProjectSubStage(NEXT_STAGE));
        }
      }
    }
  }, [
    cms?._id,
    cms?.name,
    dispatch,
    hasfinished,
    project?._id,
    project?.connections,
    shouldPreview,
  ]);

  // Load preview
  useEffect(() => {
    if (cms?._id && shouldPreview) {
      const firstGroup = (
        cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
      ).groups[0];

      const dataType = firstGroup.items[0].datasetMetaId
        ? EntityType.DATASET
        : firstGroup.items[0].aggregationId
        ? EntityType.AGGREGATION
        : EntityType.CHART;

      switch (dataType) {
        case EntityType.DATASET:
          history.push(
            `${RouteName.PROJECT_ITEM}/${project?._id}${RouteName.CMS}/${cms?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].datasetMetaId}`,
          );
          break;
        case EntityType.AGGREGATION:
          history.push(
            `${RouteName.PROJECT_ITEM}/${project?._id}${RouteName.CMS}/${cms?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].aggregationId}`,
          );
          break;
        case EntityType.CHART:
          history.push(
            `${RouteName.PROJECT_ITEM}/${project?._id}${RouteName.CMS}/${cms?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].chartId}`,
          );
          break;
        default:
          history.push(
            `${RouteName.PROJECT_ITEM}/${project?._id}${RouteName.CMS}/${cms?._id}/`,
          );
      }
    }

    if (cms?._id && hasfinished) {
      dispatch(updateActiveProjectSubStage(NEXT_STAGE));
    }
  }, [
    cms?._id,
    cms?.configuration,
    dispatch,
    hasfinished,
    history,
    project?._id,
    shouldPreview,
  ]);

  const processAction = async (finished = true, preview = false) => {
    if (organisation && project && cms) {
      if (!(cms as Interfaces.ConnectionOutput)._id) {
        dispatch(showLoading({ text: 'Creating Workspace...' }));
        await addCmsConnection(project, cms);
      } else {
        dispatch(showLoading({ text: 'Updating Workspace...' }));
        const clonedProject = cloneDeep(project);
        clonedProject.connections[Enums.ConnectionType.CMS] =
          clonedProject.connections[Enums.ConnectionType.CMS].map((c) => {
            if (c._id === cms._id) {
              return cms;
            }

            return c;
          });

        await editProject(clonedProject);
        dispatch(fetchCMSSuccess(cms));

        if (finished) {
          dispatch(updateActiveProjectSubStage(NEXT_STAGE));
        }
      }

      if (preview) {
        setShouldPreview(true);
      }

      dispatch(hideLoading());

      if (finished) {
        setHasFinished(true);
      }
    }
  };

  const reorderItems = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const cloned = cloneDeep(cms);
    cloned.configuration.groups = result;

    dispatch(fetchCMSSuccess(cloned));
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Create groups and add your datasets and queries to be made available
            within this workspace.
          </StageBodyText>

          <FeatureButton
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.CONNECTION_CMS_CREATE_GROUP,
                }),
              )
            }
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Create Group'}
            containerStyle={{ marginBottom: themeContext.margin.xxlarge }}
          />

          {(cmsConfig?.groups?.length || 0) > 0 &&
            cmsConfig?.groups.map((group, index) => (
              <SC.GroupAccordion key={`${group.groupName}-${index}`}>
                <SC.AccordionTitle
                  active={!groupAccordionStatus[group.groupName]}
                  index={0}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <SC.AccordionTitleWrapper>
                    <StyledSubHeader>{group.groupName}</StyledSubHeader>

                    <SC.AccordionTitleButtons>
                      <FeatureButton
                        isDisabled={index === 0}
                        action={() => {
                          reorderItems(cmsConfig.groups, index, index - 1);
                        }}
                        size={FeatureButtonSize.EXTRA_SMALL}
                        color={themeContext.colors.general.purple}
                        icon={
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            color={themeContext.colors.system.white}
                          />
                        }
                      />
                      <FeatureButton
                        isDisabled={index === cmsConfig.groups.length - 1}
                        action={() => {
                          reorderItems(cmsConfig.groups, index, index + 1);
                        }}
                        size={FeatureButtonSize.EXTRA_SMALL}
                        color={themeContext.colors.general.purple}
                        icon={
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            color={themeContext.colors.system.white}
                          />
                        }
                      />
                      <FeatureButton
                        action={() => {
                          dispatch(
                            showModal({
                              visible: true,
                              modal: ModalTypes.CONNECTION_CMS_CREATE_GROUP,
                              additionalProps: {
                                existingGroupName: group.groupName,
                              },
                            }),
                          );
                        }}
                        size={FeatureButtonSize.EXTRA_SMALL}
                        color={themeContext.colors.general.blue}
                        icon={
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            color={themeContext.colors.system.white}
                          />
                        }
                      />

                      <FAButton
                        action={() =>
                          setGroupAccordionStatus({
                            ...groupAccordionStatus,
                            [group.groupName]:
                              !groupAccordionStatus[group.groupName],
                          })
                        }
                        icon={
                          !groupAccordionStatus[group.groupName]
                            ? faChevronUp
                            : faChevronDown
                        }
                        size={'1x'}
                        color={themeContext.colors.system.offBlack}
                      />
                    </SC.AccordionTitleButtons>
                  </SC.AccordionTitleWrapper>
                </SC.AccordionTitle>
                <SC.AccordionContentWrapper
                  active={!groupAccordionStatus[group.groupName]}
                >
                  <div>
                    <CMSEntitySelector group={group} />
                  </div>
                </SC.AccordionContentWrapper>
              </SC.GroupAccordion>
            ))}
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Keep it moving`}
        primaryButton={
          <FeatureButton
            isDisabled={!canProceed}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to access'}
          />
        }
        secondaryButton={
          <FeatureButton
            isDisabled={!canProceed}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Save & Preview Workspace'}
            action={async () => await processAction(false, true)}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemCMSDataStage;
