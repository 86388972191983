import { Enums, Interfaces } from '@configur-tech/upit-core-types';

const InitialPortalConnection: Interfaces.Connection = {
  name: '',
  status: Enums.ConnectionStatus.ACTIVE,

  access: {
    manage: {
      organisation: [],
      team: [],
      user: [],
    },
    edit: { organisation: [], team: [], user: [] },
    view: { organisation: [], team: [], user: [] },
  },

  configuration: {
    portalId: '',
  },
};

export default InitialPortalConnection;
