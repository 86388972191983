import { Enums } from '@configur-tech/upit-core-types';
import { UserType } from '@configur-tech/upit-core-types/lib/enums';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PortalConnections, ProjectStage, RouteName } from '../../enums';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import { RootState } from '../../store/rootReducer';
import AggregationItemNavigation from '../AggregationItemNavigation/AggregationItemNavigation';
import ChartsItemNavigation from '../ChartsItemNavigation/ChartsItemNavigation';
import CMSNavigation from '../CMS/CMSNavigation/CMSNavigation';
import DatasetItemNavigation from '../DatasetItemNavigation/DatasetItemNavigation';
import DatasetsNavigation from '../DatasetsNavigation/DatasetsNavigation';
import DefaultNavigation from '../DefaultNavigation/DefaultNavigation';
import GraphItemNavigation from '../GraphItemNavigation/GraphItemNavigation';
import IntegrationCentreNavigation from '../IntegrationCentreNavigation/IntegrationCentreNavigation';
import IntegrationItemNavigation from '../IntegrationItemNavigation/IntegrationItemNavigation';
import ListItemNavigation from '../ListItemNavigation/ListItemNavigation';
import OrganisationNavigation from '../OrganisationNavigation/OrganisationNavigation';
import PipelineItemNavigation from '../PipelineItemNavigation/PipelineItemNavigation';
import PortalAPINavigation from '../PortalNavigation/PortalAPINavigation';
import PortalCMSNavigation from '../PortalNavigation/PortalCMSNavigation';
import PortalFormNavigation from '../PortalNavigation/PortalFormNavigation';
import PortalQueryNavigation from '../PortalNavigation/PortalQueryNavigation';
import ProjectItemCMSNavigation from '../ProjectItem/ProjectItemCmsNavigation/ProjectItemCMSNavigation';
import ProjectItemConnectionFormNavigation from '../ProjectItem/ProjectItemFormNavigation/ProjectItemFormNavigation';
import ProjectItemNavigation from '../ProjectItem/ProjectItemNavigation/ProjectItemNavigation';
import ProjectItemPortalNavigation from '../ProjectItem/ProjectItemPortalNavigation/ProjectItemPortalNavigation';
import ProjectsNavigation from '../ProjectsNavigation/ProjectsNavigation';
import TeamItemNavigation from '../TeamItemNavigation/TeamItemNavigation';
import TeamsNavigation from '../TeamsNavigation/TeamsNavigation';
import UsersNavigation from '../UsersNavigation/UsersNavigation';
import UserItemNavigation from '../UserItemNavigation/UserItemNavigation';
import NotificationCentreNavigation from '../NotificationCentreNavigation/NotificationCentreNavigation';

interface PrimaryNavigationProps {
  route: RouteName;
  connectionType?: Enums.ConnectionType | 'query';
}

const Wrapper = styled.div<{ lightTheme?: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  background-color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.white : theme.colors.system.darkBlue};
  overflow-y: auto;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrimaryNavigation: FC<PrimaryNavigationProps> = ({
  route,
  connectionType,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const location = useLocation().pathname;
  const isPortal = location.includes(RouteName.PORTAL);

  let navigation;
  switch (route) {
    case RouteName.CMS:
      navigation = <CMSNavigation />;
      break;
    case RouteName.DATASETS:
    case RouteName.LISTS:
      navigation = <DatasetsNavigation />;
      break;
    case RouteName.DATASET_ITEM:
      navigation = <DatasetItemNavigation />;
      break;
    case RouteName.INTEGRATION_CENTRE:
      navigation = <IntegrationCentreNavigation />;
      break;
    case RouteName.INTEGRATION_ITEM:
      navigation = <IntegrationItemNavigation />;
      break;
    case RouteName.LIST_ITEM:
      navigation = <ListItemNavigation />;
      break;
    case RouteName.PIPELINE_ITEM:
      navigation = <PipelineItemNavigation />;
      break;
    case RouteName.ORGANISATION_ITEM:
      navigation = <OrganisationNavigation />;
      break;

    case RouteName.PORTAL:
      switch (connectionType) {
        case Enums.ConnectionType.CMS:
          return <PortalCMSNavigation />;
        case Enums.ConnectionType.API:
          return <PortalAPINavigation />;
        case Enums.ConnectionType.FORM:
          return <PortalFormNavigation />;
        case 'query':
          return <PortalQueryNavigation />;
        default:
          return <PortalCMSNavigation />;
      }
    case RouteName.PROJECTS:
      navigation = <ProjectsNavigation />;
      break;
    case RouteName.PROJECT_ITEM:
      if (
        projectStageState.activeStage === ProjectStage.AGGREGATION &&
        projectStageState.stages[ProjectStage.AGGREGATION].activeSubStage
      ) {
        navigation = <AggregationItemNavigation />;
        break;
      }

      if (
        projectStageState.activeStage === ProjectStage.CHARTS &&
        projectStageState.stages[ProjectStage.CHARTS].activeSubStage
      ) {
        navigation = <ChartsItemNavigation />;
        break;
      }

      if (
        projectStageState.activeStage === ProjectStage.GRAPH &&
        projectStageState.stages[ProjectStage.GRAPH].activeSubStage
      ) {
        navigation = <GraphItemNavigation />;
        break;
      }

      if (
        projectStageState.activeStage === Enums.ConnectionType.CMS &&
        projectStageState.stages[Enums.ConnectionType.CMS].activeSubStage
      ) {
        navigation = <ProjectItemCMSNavigation />;
        break;
      }

      if (
        projectStageState.activeStage === Enums.ConnectionType.FORM &&
        projectStageState.stages[Enums.ConnectionType.FORM].activeSubStage
      ) {
        navigation = <ProjectItemConnectionFormNavigation />;
        break;
      }

      if (
        projectStageState.activeStage === Enums.ConnectionType.PORTAL &&
        projectStageState.stages[Enums.ConnectionType.PORTAL].activeSubStage
      ) {
        navigation = <ProjectItemPortalNavigation />;
        break;
      }

      navigation = <ProjectItemNavigation />;
      break;
    case RouteName.TEAMS:
      navigation = <TeamsNavigation />;
      break;
    case RouteName.TEAM_ITEM:
      navigation = <TeamItemNavigation />;
      break;
    case RouteName.USERS:
      navigation = <UsersNavigation />;
      break;
    case RouteName.USER_ITEM:
      navigation = <UserItemNavigation />;
      break;
    default:
      navigation = <DefaultNavigation />;
  }

  const isPortalHomepage =
    location.includes(RouteName.PORTAL) &&
    !Object.values(PortalConnections).some((c) => location.includes(c));

  if (
    !navigation ||
    location === RouteName.WELCOME ||
    isPortalHomepage ||
    (loggedInUser?.userType === UserType.PORTAL &&
      !location.includes(RouteName.PORTAL)) ||
    location === RouteName.APIS ||
    location === RouteName.NOTIFICATIONS ||
    location === RouteName.NOTIFICATION_CENTRE
  ) {
    return null;
  }

  return (
    <Wrapper lightTheme={isPortal}>
      <Navigation>{navigation}</Navigation>
    </Wrapper>
  );
};

export default PrimaryNavigation;
