import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { CommentItemOutput, CommentsItemOutput } from '../../store/comments';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_COMMENT'] || '';
const COMMENTS_LIMIT = 12;
const ORIGIN_PROJECTS = 'projects';
const ORIGIN_PORTALS = 'portals';
const ORIGIN_RESOURCES = 'resources';

const formatQueryParam = (param?: string) => {
  return param ? `/${param}` : '';
};

export default class CommentsService {
  public static async postComment(
    token: string,
    comment: Interfaces.Comment,
    userId: string,
    portalId?: string,
  ): Promise<CommentItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<CommentItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: comment, userId, portalId });

    return result?.data as CommentItemOutput;
  }

  public static async getComment(
    token: string,
    id: string,
  ): Promise<CommentItemOutput> {
    const ah = new HttpHelper(token);
    const url = `${endpoint}/${id}`;

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<CommentItemOutput>
      | Interfaces.ErrorResponseBody
    >(url, { commentId: id, singleDocument: true });

    return result?.data as CommentItemOutput;
  }

  public static async getComments(
    token: string,
    resourceType: Enums.SchemaName,
    resourceId: string,
    pageNum: number,
    childId?: string,
    portalId?: string,
    cmsId?: string,
    groupId?: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<CommentsItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    const originParam =
      !portalId && cmsId
        ? ORIGIN_PROJECTS
        : portalId
        ? ORIGIN_PORTALS
        : ORIGIN_RESOURCES;

    const portalParam = formatQueryParam(portalId);
    const cmsParam = formatQueryParam(cmsId);
    const groupIdParam = formatQueryParam(groupId);
    const childIdParam = formatQueryParam(childId);

    const url = `${endpoint}${originParam}${portalParam}${cmsParam}${groupIdParam}/${resourceType}/${resourceId}${childIdParam}`;

    const orderBy: Record<string, number> = { 'meta.created': -1 };
    return ah.get<
      | Interfaces.SuccessResponseBody<CommentsItemOutput>
      | Interfaces.ErrorResponseBody
    >(url, { pageNum, limit: COMMENTS_LIMIT, orderBy });
  }

  public static async putComment(
    token: string,
    comment: Interfaces.CommentOutput,
    userId: string,
    portalId?: string,
  ): Promise<CommentItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<CommentItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${comment._id}`, { data: comment, userId, portalId });

    return result?.data as CommentItemOutput;
  }

  public static async deleteComment(
    token: string,
    commentId: string,
    userId: string,
    portalId?: string,
  ): Promise<CommentItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<CommentItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${commentId}`, userId, portalId);

    return result?.data as CommentItemOutput;
  }
}
