import styled from 'styled-components';
import { StyledDropdown, StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const InputContainer = styled.div`
  margin-right: ${({ theme }) => theme.margin.standard};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      > div {
        margin: 0;
      }
    }
  }
`;

export const StyledDropdownWithIcon = styled(StyledDropdown)`
  .text {
    width: 100%;
  }
  div.description {
    color: ${({ theme }) => theme.colors.general.red} !important;
    letter-spacing: -1px;

    svg {
      color: ${({ theme }) => theme.colors.general.red};
      margin-right: ${({ theme }) => theme.margin.small};
    }
  }
`;

export const TriggerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => `${theme.margin.small} 0 ${theme.margin.large}`};
`;
