import { DatasetApiOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import APIService from '../../services/api/APIService';
import { AppThunk } from '../store';

export interface APIsState {
  data: DatasetApiOutput[];
  loading: boolean;
  error: string | null;
}

const initialState: APIsState = {
  data: [],
  loading: false,
  error: null,
};

const APIsSlice = createSlice({
  name: 'APIs',
  initialState,
  reducers: {
    // Fetch
    fetchAPIsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAPIsSuccess(state, action: PayloadAction<DatasetApiOutput[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchAPIsFailure(state, action: PayloadAction<string>) {
      state.data = [];
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAPIsStart, fetchAPIsSuccess, fetchAPIsFailure } =
  APIsSlice.actions;

export default APIsSlice.reducer;

export const fetchAPIs =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchAPIsStart());
      const fetched = await APIService.getAPIs(token, undefined, query);

      dispatch(fetchAPIsSuccess(fetched as unknown as DatasetApiOutput[]));
    } catch (err) {
      dispatch(fetchAPIsFailure((err as string).toString()));
      throw err;
    }
  };
