import { Enums } from '@configur-tech/upit-core-types';
import { ListItem } from '../../services/list/ListService';

const InitialList: ListItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    description: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    listType: Enums.ListType.STANDARD,
    values: [],
  },
};

export default InitialList;
