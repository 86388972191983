import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import OrganisationService, {
  OrganisationItem,
  OrganisationItemOutput,
} from '../../services/organisation/OrganisationService';
import { AppThunk } from '../store';
import InitialOrganisation from './initial-organisation';

type Organisation = OrganisationItem | OrganisationItemOutput;

export interface OrganisationState {
  data: Organisation;
  loading: boolean;
  error: string | null;
}

const initialState: OrganisationState = {
  data: InitialOrganisation,
  loading: false,
  error: null,
};

const OrganisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    // Fetch
    fetchOrganisationStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchOrganisationSuccess(state, action: PayloadAction<Organisation>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchOrganisationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateOrganisationStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateOrganisationSuccess(state, action: PayloadAction<Organisation>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateOrganisationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchOrganisationStart,
  fetchOrganisationSuccess,
  fetchOrganisationFailure,
  updateOrganisationStart,
  updateOrganisationSuccess,
  updateOrganisationFailure,
} = OrganisationSlice.actions;

export default OrganisationSlice.reducer;

export const fetchOrganisation =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchOrganisationStart());
      const fetched = await OrganisationService.getOrganisation(token, id);
      dispatch(fetchOrganisationSuccess(fetched));
    } catch (err) {
      dispatch(fetchOrganisationFailure((err as string).toString()));
    }
  };

export const updateOrganisation =
  (
    token: string,
    organisation: Partial<Interfaces.OrganisationOutput>,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrganisationStart());
      const updated = await OrganisationService.putOrganisation(
        token,
        organisation,
        userId,
      );
      dispatch(updateOrganisationSuccess(updated));
    } catch (err) {
      dispatch(updateOrganisationFailure((err as string).toString()));
    }
  };
