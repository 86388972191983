import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FileHeaderType } from '@configur-tech/upit-core-types/lib/enums';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ValidationService from '../../services/validation/ValidationService';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import { hideLoading, showLoading } from '../../store/loading';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useDatasetMeta from './UseDatasetMeta';

const PAGE_SIZE = 250;

interface useValidationResult {
  validateDataset: (
    pageNum?: number,
    totalCount?: number,
  ) => Promise<Interfaces.ValidationResult>;
}

const useValidation = (): useValidationResult => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { datasetMeta, datasetMetaAccessLevel } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const sourceType = coll?.dataSource?.dataSourceType;
  const source = sourceType && coll?.dataSource?.[sourceType];
  const mergeSource =
    isMerging &&
    coll?.dataSource?.dataSourceActionType ===
      Enums.DataSourceActionType.MERGE &&
    coll?.dataSource.dataMergeFileId;

  const validateDataset = async (
    pageNum = 1,
    totalCount?: number,
  ): Promise<Interfaces.ValidationResult> => {
    const token = await getAccessTokenSilently();
    if (
      !token ||
      !collection?.collectionId ||
      !datasetMeta ||
      !loggedInUser ||
      ![
        Enums.DataSourceType.CSV,
        Enums.DataSourceType.TXT,
        Enums.DataSourceType.XLS,
        Enums.DataSourceType.INTERNAL,
        Enums.DataSourceType.DATASET,
      ].includes(sourceType as Enums.DataSourceType)
    ) {
      dispatch(hideLoading());
      toast.error('Error - Failed to validate dataset.');
      throw Error('Missing required param or incorrect source type');
    }

    dispatch(
      showLoading({
        text: totalCount
          ? `Validating rows ${Math.min(
              PAGE_SIZE * pageNum,
              totalCount,
            )} of ${totalCount}`
          : 'Validating Dataset...',
      }),
    );

    try {
      const sourceFile = source as Interfaces.DataSourceFile;
      const validatingPublishedDataset =
        isReplacing || isMerging ? false : !!datasetMeta?.activeDataCollection;
      const validationCompleteResult = await ValidationService.validateDataset(
        token,
        loggedInUser.organisationId,
        loggedInUser._id,
        datasetMeta._id,
        collection.collectionId,
        pageNum,
        !validatingPublishedDataset
          ? isMerging
            ? mergeSource
            : sourceFile.fileId
          : undefined,
        sourceFile.headers !== FileHeaderType.NONE
          ? sourceFile.headers
          : undefined,
        sourceFile.headers === FileHeaderType.NONE,
        sourceFile.delimiter,
        sourceFile.sheetName,
        validatingPublishedDataset ? datasetMeta._id : undefined,
      );

      // Update local state
      await dispatch(
        fetchDatasetMetaSuccess({
          entity: validationCompleteResult.datasetMeta,
          accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );

      if (validationCompleteResult.pagination.nextPageNum) {
        return validateDataset(
          validationCompleteResult.pagination.nextPageNum,
          validationCompleteResult.pagination.totalCount,
        );
      }

      dispatch(hideLoading());
      return validationCompleteResult.validationResult;
    } catch (err) {
      dispatch(hideLoading());
      toast.error(
        `${
          (err as AxiosError)?.response?.data?.statusCode || 'Error'
        } - Failed to validate dataset.`,
      );
      throw Error((err as AxiosError)?.response?.data?.error);
    }
  };

  return {
    validateDataset,
  };
};
export default useValidation;
