import { Enums } from '@configur-tech/upit-core-types';
import { TeamItem } from '../../services/team/TeamService';

const InitialTeam: TeamItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',
    avatar: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },
  },
};

export default InitialTeam;
