import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';

import { updateActivePipelineSubStage } from '../../../../store/pipeline-stage';
import { PipelineCreationSubStage } from '../../../../store/pipeline-stage/initial-state';
import { fetchPipelineTemplateSuccess } from '../../../../store/pipeline-template';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NEXT_STAGE = PipelineCreationSubStage.TRIGGER;

const MetaPipelineStage: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const themeContext = useContext(ThemeContext);

  const { loggedInUser } = useLoggedInUser();
  const { pipelineTemplate, getPipelineTemplate, pipelineTemplateAccessLevel } =
    usePipelineTemplate();

  const [updatedName, setUpdatedName] = useState<string>();
  const [updatedDesc, setUpdatedDesc] = useState<string>();
  const [processComplete, setProcessComplete] = useState<boolean>(false);

  // Set meta ready for editing
  useEffect(() => {
    if (pipelineTemplate?._id) {
      setUpdatedName(pipelineTemplate.name);
      setUpdatedDesc(pipelineTemplate.description);
    }
  }, [pipelineTemplate]);

  useEffect(() => {
    if (processComplete) {
      // Move on to next stage
      dispatch(updateActivePipelineSubStage(NEXT_STAGE));
    }
  }, [processComplete, pipelineTemplate?._id, dispatch]);

  useEffect(() => {
    if (params?.pipelineId && params?.pipelineId !== pipelineTemplate?._id) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Pipeline...' }));
        await getPipelineTemplate(params.pipelineId);
        dispatch(hideLoading());
      })();
    }
  }, [
    dispatch,
    getPipelineTemplate,
    params?.pipelineId,
    pipelineTemplate?._id,
  ]);

  const processAction = async () => {
    if (loggedInUser && pipelineTemplate) {
      const cloned = cloneDeep(pipelineTemplate);

      // Add organisation
      cloned.organisationId = loggedInUser.organisationId;

      // Add datasetMetaId
      cloned.datasetMetaId = params?.datasetMetaId;

      // Add name/desc
      cloned.name = updatedName;
      cloned.description = updatedDesc;

      dispatch(
        fetchPipelineTemplateSuccess({
          accessLevel: pipelineTemplateAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          Enter an easily identifiable name for your new pipeline.
        </StageBodyText>

        <StyledBodySubHeader>Name</StyledBodySubHeader>
        <StyledInput
          placeholder={'Enter your pipeline name'}
          value={updatedName || ''}
          onChange={(e, data) => setUpdatedName(data.value)}
          style={{ marginBottom: themeContext.margin.xxlarge }}
        />

        <StyledBodySubHeader>Description</StyledBodySubHeader>
        <StageBodyText>
          Briefly, what are you trying to achieve with this pipeline?
        </StageBodyText>
        <StyledTextArea
          minRows={5}
          maxRows={10}
          placeholder={'Enter your pipeline description'}
          value={updatedDesc || ''}
          onChange={(e) => setUpdatedDesc(e.target.value)}
        />
      </StageInner>
      <ActionBar
        text={`Ready to carry on?`}
        primaryButton={
          <FeatureButton
            isDisabled={!updatedName?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Trigger'}
          />
        }
      />
    </StageWrapper>
  );
};

export default MetaPipelineStage;
