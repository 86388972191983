import { faCaretSquareDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { FC, useContext } from 'react';
import { Column } from 'react-table';
import { Dropdown, Icon } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import {
  COMMENT_COUNT_HEADER,
  ROW_NUMBER_HEADER,
  SELECTION_HEADER,
} from '../../const/DataSample';
import usePinned from '../../hooks/pinned/UsePinned';
import * as SC from './styled';

interface DataSampleContextMenuProps {
  allowColumnLock?: boolean;
  column: Column;
  columnIndex: number;
  entityId?: string;
  hideSortOptions?: boolean;
  isLastColumn: boolean;
  setPinnedColumn: any;
  setActiveDropdownColumn: any;
}

const DataSampleContextMenu: FC<DataSampleContextMenuProps> = ({
  allowColumnLock,
  column,
  columnIndex,
  entityId,
  hideSortOptions,
  isLastColumn,
  setPinnedColumn,
  setActiveDropdownColumn,
}) => {
  const themeContext = useContext(ThemeContext);
  const { getPinnedColumn, removePinnedColumn, updatePinnedColumn } =
    usePinned();

  const pinnedColumn = entityId ? getPinnedColumn(entityId) : undefined;
  const isStoredPinnedCol = pinnedColumn?.id == columnIndex;

  const triggerElement = (isActive: boolean) => (
    <SC.TableHeaderDropdown>
      <FontAwesomeIcon
        icon={faCaretSquareDown}
        color={
          isActive
            ? themeContext.colors.general.blue
            : themeContext.colors.system.grey
        }
        size={'sm'}
      />
    </SC.TableHeaderDropdown>
  );

  return (
    <Dropdown
      onClick={() => setActiveDropdownColumn(column.id)}
      onClose={() => setActiveDropdownColumn(undefined)}
      direction={isLastColumn ? 'left' : 'right'}
      inline
      style={{ fontFamily: 'Lato' }}
      trigger={triggerElement(isStoredPinnedCol || column.isSorted)}
      icon={null}
    >
      <Dropdown.Menu>
        {column.id !== ROW_NUMBER_HEADER &&
          column.id !== SELECTION_HEADER &&
          allowColumnLock && (
            <>
              <Dropdown.Item
                text={isStoredPinnedCol ? 'Unpin column' : 'Pin column'}
                onClick={() => {
                  if (!entityId) {
                    return;
                  }
                  const pinned = {
                    id: columnIndex,
                    desc: column.id,
                  };

                  if (!isEqual(pinned, pinnedColumn)) {
                    setPinnedColumn(
                      !isEqual(pinned, pinnedColumn) ? pinned : undefined,
                    );
                    updatePinnedColumn(entityId, pinned);
                  } else {
                    removePinnedColumn(entityId);
                    setPinnedColumn(undefined);
                  }
                }}
                icon={
                  <Icon
                    color={isStoredPinnedCol ? 'blue' : 'grey'}
                    name={isStoredPinnedCol ? 'unlock' : 'lock'}
                  />
                }
              />
              <Dropdown.Divider style={{ margin: 0 }} />
            </>
          )}
        {!hideSortOptions && column.id !== COMMENT_COUNT_HEADER && (
          <>
            <Dropdown.Item
              text={'Sort ascending'}
              icon={
                <Icon
                  color={
                    !column.isSorted || column.isSortedDesc ? 'grey' : 'blue'
                  }
                  name={'sort amount up'}
                />
              }
              onClick={() =>
                !column.isSorted || column.isSortedDesc
                  ? column.toggleSortBy(false)
                  : column.clearSortBy()
              }
            />
            <Dropdown.Item
              text={`Sort descending`}
              icon={
                <Icon
                  color={
                    !column.isSorted || !column.isSortedDesc ? 'grey' : 'blue'
                  }
                  name={'sort amount down'}
                />
              }
              onClick={() =>
                !column.isSorted || !column.isSortedDesc
                  ? column.toggleSortBy(true)
                  : column.clearSortBy()
              }
            />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DataSampleContextMenu;
