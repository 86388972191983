import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const AddQuestionButton: React.FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <FeatureButton
      action={handleClick}
      size={FeatureButtonSize.WIDE_SMALL}
      color={themeContext.colors.general.blue}
      text={'Add Question'}
      containerStyle={{
        paddingTop: themeContext.padding.standard,
        marginLeft: themeContext.margin.standard,
        marginRight: themeContext.margin.standard,
      }}
      style={{ width: '100%' }}
      faceStyle={{ width: '100%' }}
    />
  );
};

export default AddQuestionButton;
