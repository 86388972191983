import { Enums } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Enums
import { EntityType, ProjectStage, RouteName } from '../../../enums';

// Hooks
import useChart from '../../../hooks/chart/UseChart';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useForm from '../../../hooks/form/UseForm';
import useIntegrationTemplate from '../../../hooks/integration-template/UseIntegrationTemplate';
import useList from '../../../hooks/list/UseList';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import UseNotificationAlert from '../../../hooks/notification-alert/UseNotificationAlert';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import useQuery from '../../../hooks/query/UseQuery';
import useTeam from '../../../hooks/team/UseTeam';
import useUser from '../../../hooks/user/UseUser';

// Styled Components
import { StyledText } from '../../../main/theme';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';

// State
import { updateActiveDatasetStage } from '../../../store/dataset-stage';
import { AdditionalDatasetStages } from '../../../store/dataset-stage/initial-state';
import { resetStagesAndSubStages as resetIntegrationStagesAndSubStages } from '../../../store/integration-stage';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { resetStagesAndSubStages as resetPipelineStagesAndSubStages } from '../../../store/pipeline-stage';
import { updateActivePortalSubStage } from '../../../store/portal-stage';
import {
  resetDataviewStage,
  resetFormStage,
  resetPortalStage,
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectChartSubStage } from '../../../store/project-stage/initial-state';

export interface DeletionModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  entityType: EntityType;
  entityId: string;
  entityName: string;
  requireConfirmation?: boolean;
  connectionId?: string;
  isPortal?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const DeletionModal: FC<DeletionModalProps> = ({
  setShowModal,
  entityType,
  entityId,
  entityName,
  requireConfirmation,
  connectionId,
  isPortal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedInUser } = useLoggedInUser();

  const { removeChart, removeGraph } = useChart();
  const { removeUser } = useUser();
  const { removeTeam } = useTeam();
  const { removeDatasetMeta, datasetMeta } = useDatasetMeta();
  const {
    removeProject,
    removeConnection,
    removeProjectChart,
    removeProjectQuery,
  } = useProject();
  const { removeForm } = useForm();
  const { removePortal } = usePortal();
  const { removeList } = useList();
  const { removeQuery } = useQuery();
  const { removeIntegrationTemplate } = useIntegrationTemplate();
  const { removePipelineTemplate } = usePipelineTemplate();
  const { removeNotificationAlert } = UseNotificationAlert();

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const processDeletion = async () => {
    if (loggedInUser?._id && entityId) {
      switch (entityType) {
        case EntityType.DATASET:
          dispatch(showLoading({ text: 'Deleting Dataset...' }));
          await removeDatasetMeta(entityId);
          history.push(RouteName.DATASETS);
          break;
        case EntityType.PROJECT:
          dispatch(showLoading({ text: 'Deleting Project...' }));
          await removeProject(entityId);
          history.push(RouteName.PROJECTS);
          break;
        case EntityType.USER:
          dispatch(showLoading({ text: 'Deleting User...' }));
          await removeUser(entityId);
          history.push(RouteName.USERS);
          break;
        case EntityType.TEAM:
          dispatch(showLoading({ text: 'Deleting Team...' }));
          await removeTeam(entityId);
          history.push(RouteName.TEAMS);
          break;
        case EntityType.AGGREGATION:
          dispatch(showLoading({ text: 'Deleting Query...' }));
          await removeQuery(entityId);

          if (isPortal) {
            dispatch(updateActivePortalSubStage(undefined));
            break;
          }

          await removeProjectQuery(entityId);
          dispatch(updateActiveProjectSubStage(undefined));

          break;
        case EntityType.FORM:
          dispatch(showLoading({ text: 'Deleting Form...' }));
          await removeForm(entityId);
          if (connectionId) {
            await removeConnection(Enums.ConnectionType.FORM, connectionId);
          }
          dispatch(resetFormStage());
          break;
        case EntityType.PORTAL:
          dispatch(showLoading({ text: 'Deleting Portal...' }));
          await removePortal(entityId);
          if (connectionId) {
            await removeConnection(Enums.ConnectionType.PORTAL, connectionId);
          }
          dispatch(resetPortalStage());
          break;
        case EntityType.DATA_VIEW:
          dispatch(showLoading({ text: 'Deleting Workspace...' }));
          await removeConnection(Enums.ConnectionType.CMS, entityId);
          dispatch(resetDataviewStage());
          break;
        case EntityType.LIST:
          dispatch(showLoading({ text: 'Deleting List...' }));
          await removeList(entityId);
          history.push(`${RouteName.DATASETS}${RouteName.LISTS}`);
          break;
        case EntityType.CHART:
          dispatch(showLoading({ text: 'Deleting Dashboard...' }));
          await removeChart(entityId);
          await removeProjectChart(entityId);
          dispatch(updateActiveProjectSubStage(undefined));
          break;
        case EntityType.GRAPH:
          dispatch(showLoading({ text: 'Deleting Graph...' }));
          await removeGraph(entityId);
          dispatch(updateActiveProjectStage(ProjectStage.CHARTS));
          dispatch(updateActiveProjectSubStage(ProjectChartSubStage.CHART));
          break;
        case EntityType.INTEGRATION:
          dispatch(showLoading({ text: 'Deleting Integration...' }));
          await removeIntegrationTemplate(entityId);
          dispatch(resetIntegrationStagesAndSubStages());
          history.push(RouteName.INTEGRATION_CENTRE);
          break;
        case EntityType.PIPELINE:
          dispatch(showLoading({ text: 'Deleting Pipeline...' }));
          await removePipelineTemplate(entityId);
          dispatch(resetPipelineStagesAndSubStages());
          dispatch(updateActiveDatasetStage(AdditionalDatasetStages.PIPELINES));
          history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
          break;
        case EntityType.NOTIFICATION:
          dispatch(showLoading({ text: 'Deleting Notification...' }));
          await removeNotificationAlert(entityId);
          break;
      }
      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  return (
    <Wrapper>
      <Header>Delete {startCase(entityType)}</Header>

      <DeleteConfirmation
        entityType={entityType}
        entityName={entityName}
        requireConfirmation={requireConfirmation}
        cancelAction={() => {
          dispatch(hideModal());
        }}
        deleteAction={processDeletion}
      />
    </Wrapper>
  );
};

export default DeletionModal;
