import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import MetaPipelineStage from '../../components/PipelineStages/PipelineCreationStages/1-meta/MetaPipelineStage';
import PipelineTriggerStage from '../../components/PipelineStages/PipelineCreationStages/2-trigger/TriggerPipelineStage';
import JobsPipelineStage from '../../components/PipelineStages/PipelineCreationStages/3-jobs/JobsPipelineStage';
import EventsPipelineStage from '../../components/PipelineStages/PipelineCreationStages/4-events/EventsPipelineStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import Page from '../../main/Page';
import {
  PipelineCreationSubStage,
  PipelineStage,
} from '../../store/pipeline-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getPipelineStageTitle,
  getPipelineSubStageContent,
} from '../../util/pipeline-stage-content/PipelineStageContent';

const PipelineItemCreation: FC = () => {
  const params = useParams();

  const { datasetMeta, getDatasetMeta } = useDatasetMeta();

  const pipelineStageState = useSelector(
    (state: RootState) => state.pipelineStage,
  );

  const activeStage = PipelineStage.CREATION;
  const activeSubStage = pipelineStageState.stages[activeStage].activeSubStage;

  const stageTitle = getPipelineStageTitle(activeStage);
  const subStageContent = getPipelineSubStageContent(
    activeStage,
    activeSubStage,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case PipelineCreationSubStage.META:
        return <MetaPipelineStage />;
      case PipelineCreationSubStage.TRIGGER:
        return <PipelineTriggerStage />;
      case PipelineCreationSubStage.JOBS:
        return <JobsPipelineStage />;
      case PipelineCreationSubStage.EVENTS:
        return <EventsPipelineStage />;
      default:
        return <MetaPipelineStage />;
    }
  };

  // If dataset not in state, fetch it
  useEffect(() => {
    if (params.datasetMetaId && params.datasetMetaId !== datasetMeta?._id) {
      (async () => {
        await getDatasetMeta(params.datasetMetaId);
      })();
    }
  }, [datasetMeta?._id, getDatasetMeta, params.datasetMetaId]);

  return (
    <>
      <PageFeatureHeader
        pageHeader={
          activeSubStage === PipelineCreationSubStage.EVENTS
            ? 'Events'
            : stageTitle
        }
        image={subStageContent.image}
        title={subStageContent.title}
        subStages={
          activeSubStage !== PipelineCreationSubStage.EVENTS
            ? {
                activeSubStage: activeSubStage,
                subStageItems: pipelineStageState.stages[
                  activeStage
                ].subStages.filter(
                  (stage) => stage.subStage !== PipelineCreationSubStage.EVENTS,
                ) as SubStageItem[],
              }
            : undefined
        }
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default PipelineItemCreation;
