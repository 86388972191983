import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DataLinkRelationship } from '@configur-tech/upit-core-types/lib/interfaces/models/input/data-link/DataLinkRelationship';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useGraph from '../../../../hooks/graph/UseGraph';
import useList from '../../../../hooks/list/UseList';
import useProject from '../../../../hooks/project/UseProject';
import {
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { GraphOutputWithDetails } from '../../../../store/graph';
import { InitialTaskMetrics } from '../../../../store/graph/initial-state';
import { RootState } from '../../../../store/rootReducer';
import * as SC from '../styled';
import {
  PROJECT_METRICS_ACCORDION_INDEX,
  TASK_METRICS_ACCORDION_INDEX,
} from './ProjectItemGraphAggregateStage';

export const GRAPH_PROJECT_METRICS = 'projectMetrics';
export const GRAPH_TASK_METRICS = 'taskMetrics';
const GRAPH_NAME_FIELD = 'nameField';
const GRAPH_START_DATE_FIELD = 'startDateField';
const GRAPH_END_DATE_FIELD = 'endDateField';
const GRAPH_STATUS_FIELD = 'statusField';
const GRAPH_PARAMS_FIELD = 'graphParams';

const TASK_DATA_SOURCE = 'taskDatasetMetaId';
const TASK_COLUMN_LINK = 'projectLink';

interface GanttChartSetup {
  setActiveAccordion: (index: number) => void;
  isActiveAccordion: (index: number) => boolean;
  projectMetricOptions: DropdownItemProps[];
  handleChangeGanttMetric: (
    metricType: string,
    field: string,
    val: Interfaces.GraphMetric[] | Interfaces.GanttProjectTaskLink | string,
  ) => void;
  updateGraph: (graph: GraphOutputWithDetails) => void;
}

const GanttChartSetup: FC<GanttChartSetup> = ({
  setActiveAccordion,
  isActiveAccordion,
  projectMetricOptions,
  handleChangeGanttMetric,
  updateGraph,
}) => {
  const themeContext = useContext(ThemeContext);
  const { graph } = useGraph();
  const { getDatasetMetas } = useDatasetMeta();
  const { project } = useProject();

  const { getListsForMultipleResources } = useList();

  const [loadingDatasets, setLoadingDatasets] = useState<boolean>(true);
  const [loadedLinkedLists, setLoadedLinkedLists] = useState<boolean>(false);

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [taskDataSourceOptions, setTaskDataSourceOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [taskColumnLinkOptions, setTaskColumnLinkOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [taskFieldOptions, setTaskFieldOptions] = useState<DropdownItemProps[]>(
    [],
  );

  const [modelLinks, setModelLinks] = useState<
    DataLinkRelationship[] | undefined
  >();

  const [linkedDatasetIds, setLinkedDatasetIds] = useState<string[]>();

  const formatProjectLink = useCallback(
    (fieldId: string) => {
      const modelLink = modelLinks?.find(
        (link) => link.secondary.field === fieldId,
      );

      const dsm = datasetMetas.find(
        (d) => d.entity._id === modelLink?.secondary.datasetMetaId,
      )?.entity;
      const activeSchema = dsm?.dataCollections.find(
        (c) => c._id === dsm.activeDataCollection,
      )?.schemaData;

      return {
        projectField: {
          field: modelLink?.primary,
        },
        taskField: {
          field: {
            ...modelLink?.secondary,
            alias: activeSchema?.find(
              (s) => s.fieldId === modelLink?.secondary.field,
            )?.name,
          },
        },
      } as Interfaces.GanttProjectTaskLink;
    },
    [datasetMetas, modelLinks],
  );

  // Load any linked DatasetMetas and Lists
  useEffect(() => {
    (async () => {
      const modelItems = project?.dataModel;

      const linkedDSIdsMap = new Set<string>();
      modelItems
        ?.filter(
          (item) =>
            item.primary.datasetMetaId === graph?.queryParams?.datasetMetaId ||
            item.secondary.datasetMetaId == graph?.queryParams?.datasetMetaId,
        )
        ?.map((item) => {
          linkedDSIdsMap.add(item.primary.datasetMetaId);
          linkedDSIdsMap.add(item.secondary.datasetMetaId);
        });

      setModelLinks(modelItems);

      const linkedDatasetIds = Array.from(linkedDSIdsMap).filter(
        (id) => id !== graph?.queryParams?.datasetMetaId,
      );
      setLinkedDatasetIds(linkedDatasetIds);

      if (linkedDatasetIds) {
        if (!loadedLinkedLists) {
          await getListsForMultipleResources(
            Enums.SchemaName.DATASET_META,
            linkedDatasetIds,
          );

          setLoadedLinkedLists(true);
        }
      }

      setLoadingDatasets(false);
    })();
  }, [
    graph?.queryParams?.datasetMetaId,
    datasetMetas,
    getDatasetMetas,
    getListsForMultipleResources,
    loadedLinkedLists,
    project?.dataModel,
  ]);

  const createColumnLinkOptions = useCallback(
    (datasetMetaId: string) => {
      // If no datasetMetaId, clear task fields
      if (!datasetMetaId) {
        setTaskFieldOptions([]);

        const cloned = cloneDeep(graph);
        delete cloned[GRAPH_PARAMS_FIELD][GRAPH_TASK_METRICS];
        updateGraph(cloned);

        return;
      }

      const datasetModelLinks = modelLinks?.filter(
        (item) => item.secondary.datasetMetaId == datasetMetaId,
      );

      const taskColumnLinkOptions =
        datasetModelLinks?.map((link, i) => {
          const linkFieldId = link.secondary.field;
          let linkFieldName;

          const dsm = datasetMetas.find(
            (d) => d.entity._id === datasetMetaId,
          )?.entity;

          if (dsm) {
            const collection = dsm.dataCollections?.find(
              (c) => c._id === dsm.activeDataCollection,
            );

            const linkSchema = collection?.schemaData;
            linkFieldName =
              linkSchema?.find((field) => field.fieldId === linkFieldId)
                ?.name || '';
          }
          return {
            key: `link-${
              (link as DataLinkRelationship & { _id: string })._id
            }-${i}`,
            value: link.secondary.field,
            text: linkFieldName,
          };
        }) || [];

      setTaskColumnLinkOptions(taskColumnLinkOptions);

      if (graph && !graph[GRAPH_PARAMS_FIELD][GRAPH_TASK_METRICS]) {
        const taskMetrics = {
          ...InitialTaskMetrics,
          [TASK_DATA_SOURCE]: datasetMetaId,
        };

        const cloned = cloneDeep(graph);
        cloned[GRAPH_PARAMS_FIELD] = {
          ...cloned[GRAPH_PARAMS_FIELD],
          taskMetrics: taskMetrics,
        };

        if (taskColumnLinkOptions.length === 1) {
          cloned[GRAPH_PARAMS_FIELD][GRAPH_TASK_METRICS][TASK_COLUMN_LINK] =
            formatProjectLink(taskColumnLinkOptions[0].value as string);
        }

        updateGraph(cloned);
        return;
      }

      if (taskColumnLinkOptions.length === 1) {
        const cloned = cloneDeep(graph);

        cloned[GRAPH_PARAMS_FIELD][GRAPH_TASK_METRICS][TASK_DATA_SOURCE] =
          datasetMetaId;
        cloned[GRAPH_PARAMS_FIELD][GRAPH_TASK_METRICS][TASK_COLUMN_LINK] =
          formatProjectLink(taskColumnLinkOptions[0].value as string);

        updateGraph(cloned);
      } else {
        handleChangeGanttMetric(
          GRAPH_TASK_METRICS,
          TASK_DATA_SOURCE,
          datasetMetaId,
        );
      }
    },
    [
      datasetMetas,
      formatProjectLink,
      graph,
      handleChangeGanttMetric,
      modelLinks,
      updateGraph,
    ],
  );

  const createTaskDataSourceOptions = useCallback(() => {
    if (!loadingDatasets && linkedDatasetIds?.length) {
      setTaskDataSourceOptions(
        datasetMetas
          .filter((dsm) => linkedDatasetIds.includes(dsm.entity._id))
          .map((dsm, i) => {
            return {
              key: `task-${dsm.entity._id}-${i}`,
              value: dsm.entity._id,
              text: dsm.entity.name,
            };
          }),
      );
    }
  }, [loadingDatasets, linkedDatasetIds, datasetMetas]);

  const createTaskFieldOptions = useCallback(() => {
    const taskDatasetMetaId = (
      graph?.graphParams as Interfaces.GanttGraphParams
    )?.taskMetrics?.taskDatasetMetaId;

    if (taskDatasetMetaId) {
      const dsm = datasetMetas.find(
        (d) => d.entity._id === taskDatasetMetaId,
      )?.entity;

      if (!dsm) {
        return;
      }

      const collection = dsm.dataCollections?.find(
        (c) => c._id === dsm.activeDataCollection,
      );

      const taskSchema = collection?.schemaData;

      const groupI: DropdownItemProps[] = [];

      taskSchema?.map((e, i) => {
        groupI?.push({
          key: `group-${e.fieldId}-${i}`,
          value: e.name,
          text: e.name,
          field: {
            field: {
              field: e.fieldId,
              alias: e.name,
              datasetMetaId: dsm._id,
            },
          },
        });
      });

      setTaskFieldOptions(groupI);
    }
  }, [datasetMetas, graph?.graphParams]);

  // Create task data source options
  useEffect(() => {
    if (!loadingDatasets) {
      createTaskDataSourceOptions();
    }
  }, [createTaskDataSourceOptions, loadingDatasets]);

  // Create task field options
  useEffect(() => {
    if (!loadingDatasets) {
      createTaskFieldOptions();
    }
  }, [createTaskFieldOptions, loadingDatasets]);

  // Create task column link options
  useEffect(() => {
    const selectedDS = (graph?.graphParams as Interfaces.GanttGraphParams)
      ?.taskMetrics?.taskDatasetMetaId;

    if (!loadingDatasets && selectedDS && !taskColumnLinkOptions?.length) {
      createColumnLinkOptions(selectedDS);
    }
  }, [
    createColumnLinkOptions,
    graph?.graphParams,
    loadingDatasets,
    taskColumnLinkOptions?.length,
  ]);

  return (
    <>
      <StyledAccordionTitle
        index={PROJECT_METRICS_ACCORDION_INDEX}
        active={isActiveAccordion(PROJECT_METRICS_ACCORDION_INDEX)}
      >
        <StyledBodySubHeader
          onClick={() => setActiveAccordion(PROJECT_METRICS_ACCORDION_INDEX)}
        >
          Project Metrics
        </StyledBodySubHeader>
        <FontAwesomeIcon
          icon={
            isActiveAccordion(PROJECT_METRICS_ACCORDION_INDEX)
              ? faChevronUp
              : faChevronDown
          }
          color={themeContext.colors.system.offBlack}
          onClick={() => setActiveAccordion(PROJECT_METRICS_ACCORDION_INDEX)}
        />
      </StyledAccordionTitle>
      <SC.FilterAccordionContent
        active={isActiveAccordion(PROJECT_METRICS_ACCORDION_INDEX)}
      >
        <SC.GraphConfigWrapper>
          <StyledText>
            Select the data that will populate your project details in the Gantt
            chart.
          </StyledText>

          <StyledBodySubHeader>Project Name Source</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select a project name source'}
            selection
            options={projectMetricOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)
                ?.projectMetrics?.nameField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_PROJECT_METRICS,
                GRAPH_NAME_FIELD,
                data.value,
              )
            }
          />
          <StyledBodySubHeader>Start Date Source</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select a start date source'}
            selection
            options={projectMetricOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)
                ?.projectMetrics?.startDateField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_PROJECT_METRICS,
                GRAPH_START_DATE_FIELD,
                data.value,
              )
            }
          />
          <StyledBodySubHeader>End Date Source</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select an end date source'}
            selection
            options={projectMetricOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)
                ?.projectMetrics?.endDateField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_PROJECT_METRICS,
                GRAPH_END_DATE_FIELD,
                data.value,
              )
            }
          />
          <StyledBodySubHeader>Status Source</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select a status source'}
            selection
            options={projectMetricOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)
                ?.projectMetrics?.statusField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_PROJECT_METRICS,
                GRAPH_STATUS_FIELD,
                data.value,
              )
            }
          />
        </SC.GraphConfigWrapper>
      </SC.FilterAccordionContent>
      <StyledAccordionTitle
        index={TASK_METRICS_ACCORDION_INDEX}
        active={isActiveAccordion(TASK_METRICS_ACCORDION_INDEX)}
      >
        <StyledBodySubHeader
          onClick={() => setActiveAccordion(TASK_METRICS_ACCORDION_INDEX)}
        >
          Task Metrics (optional)
        </StyledBodySubHeader>

        <FontAwesomeIcon
          icon={
            isActiveAccordion(TASK_METRICS_ACCORDION_INDEX)
              ? faChevronUp
              : faChevronDown
          }
          color={themeContext.colors.system.offBlack}
          onClick={() => setActiveAccordion(TASK_METRICS_ACCORDION_INDEX)}
        />
      </StyledAccordionTitle>
      <SC.FilterAccordionContent
        active={isActiveAccordion(TASK_METRICS_ACCORDION_INDEX)}
      >
        <SC.GraphConfigWrapper>
          <StyledText>
            Select the data that will populate your project tasks in the Gantt
            chart.
          </StyledText>

          <StyledBodySubHeader>Data Source</StyledBodySubHeader>
          <StyledDropdown
            disabled={loadingDatasets}
            selectOnBlur={false}
            placeholder={'Select a data source'}
            selection
            options={taskDataSourceOptions}
            style={{ marginBottom: themeContext.margin.large }}
            clearable
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.taskDatasetMetaId || ''
            }
            onChange={(e, data) => createColumnLinkOptions(data.value)}
          />
          <StyledBodySubHeader>Column link</StyledBodySubHeader>
          <StyledDropdown
            disabled={
              loadingDatasets ||
              taskColumnLinkOptions.length === 1 ||
              !(graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.taskDatasetMetaId
            }
            selectOnBlur={false}
            placeholder={'Select column link'}
            selection
            options={taskColumnLinkOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (
                (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                  ?.projectLink?.taskField?.field as Interfaces.QueryField
              )?.field || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_TASK_METRICS,
                TASK_COLUMN_LINK,
                formatProjectLink(data.value as string),
              )
            }
          />

          <StyledBodySubHeader>Task name source</StyledBodySubHeader>
          <StyledDropdown
            disabled={loadingDatasets || !taskFieldOptions?.length}
            selectOnBlur={false}
            placeholder={'Select a task name source'}
            selection
            options={taskFieldOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.nameField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_TASK_METRICS,
                GRAPH_NAME_FIELD,
                data.options.find((o) => o.value === data.value).field,
              )
            }
          />

          <StyledBodySubHeader>Start Date Source</StyledBodySubHeader>
          <StyledDropdown
            disabled={loadingDatasets || !taskFieldOptions?.length}
            selectOnBlur={false}
            placeholder={'Select a start date source'}
            selection
            options={taskFieldOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.startDateField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_TASK_METRICS,
                GRAPH_START_DATE_FIELD,
                data.options.find((o) => o.value === data.value).field,
              )
            }
          />

          <StyledBodySubHeader>End Date Source</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select an end date source'}
            selection
            options={taskFieldOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.endDateField?.field?.alias || ''
            }
            disabled={loadingDatasets || !taskFieldOptions?.length}
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_TASK_METRICS,
                GRAPH_END_DATE_FIELD,
                data.options.find((o) => o.value === data.value).field,
              )
            }
          />

          <StyledBodySubHeader>Status Source</StyledBodySubHeader>
          <StyledDropdown
            disabled={loadingDatasets || !taskFieldOptions?.length}
            selectOnBlur={false}
            placeholder={'Select a status source'}
            selection
            options={taskFieldOptions}
            style={{ marginBottom: themeContext.margin.large }}
            value={
              (graph?.graphParams as Interfaces.GanttGraphParams)?.taskMetrics
                ?.statusField?.field?.alias || ''
            }
            onChange={(e, data) =>
              handleChangeGanttMetric(
                GRAPH_TASK_METRICS,
                GRAPH_STATUS_FIELD,
                data.options.find((o) => o.value === data.value).field,
              )
            }
          />
        </SC.GraphConfigWrapper>
      </SC.FilterAccordionContent>
    </>
  );
};

export default GanttChartSetup;
