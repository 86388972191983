import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import usePipelineEntityHelper from '../../../hooks/pipeline-mapping/UsePipelineEntityHelper';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import { StyledSubHeader, StyledText } from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { initialIntegrationJobParams } from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import CustomActionsAccordion from '../../IntegrationItemStages/IntegrationCreationStages/4-endpoints/CustomActionsAccordion';
import EndpointParamsAccordion, {
  ParamType,
} from '../../IntegrationItemStages/IntegrationCreationStages/4-endpoints/EndpointParamsAccordion';
import JobIntegrationSelector, {
  INTEGRATION_BASE_ENDPOINT_FIELD,
  INTEGRATION_CONFIG_FIELD,
  INTEGRATION_CUSTOM_ACTION_FIELD,
  INTEGRATION_REQUEST_ENDPOINT_FIELD,
  INTEGRATION_TEMPLATE_FIELD,
} from './JobIntegrationSelector';
import { SUPER_COL_TYPES } from './JobMappingModalComponent';

const CUSTOM_CONFIG_FIELD = 'customConfig';
const REQUEST_PARAMS_FIELD = 'requestParams';
const PAGINATION_PARAMS_FIELD = 'paginationParams';

export interface PipelineJobsModalProps {
  jobIndex: number;
  onChange: (data) => void;
}

const JobIntegrationModalComponent: FC<PipelineJobsModalProps> = ({
  jobIndex,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const { pipelineTemplate } = usePipelineTemplate();
  const { activeDataCollectionItem } = useDatasetMeta();
  const { getLastDataJob } = usePipelineEntityHelper();

  const integrationConfigState = useSelector(
    (state: RootState) => state.integrationConfigs,
  );
  const integrationConfigEnts = integrationConfigState.data.data;

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplates: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const isEditing = jobIndex || jobIndex == 0;

  const [pipelineJob, setPipelineJob] =
    useState<Interfaces.IntegrationJobParams>(initialIntegrationJobParams);

  const [dynamicFields, setDynamicFields] = useState<Interfaces.SchemaField[]>(
    [],
  );

  const [prevJob, setPrevJob] = useState<Interfaces.PipelineJob>();
  const [prevDataJob, setPrevDataJob] = useState<Interfaces.PipelineJob>();

  const [integrationType, setIntegrationType] =
    useState<Enums.IntegrationType>();

  const [activeCollectionSchemaFields, setActiveCollectionSchemaFields] =
    useState<Interfaces.SchemaField[]>([]);

  const [customActionParams, setCustomActionParams] =
    useState<Interfaces.EndpointParam[]>();

  // If editing existing, set data
  useEffect(() => {
    if (pipelineTemplate?._id) {
      const lastJob =
        pipelineTemplate.jobs[
          isEditing ? jobIndex - 1 : pipelineTemplate.jobs.length - 1
        ];

      if (lastJob) {
        setPrevJob(lastJob);
        setPrevDataJob(getLastDataJob(pipelineTemplate.jobs, lastJob));
      }
    }

    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      setPipelineJob(
        pipelineTemplate.jobs[jobIndex]
          .jobParams as Interfaces.IntegrationJobParams,
      );
      setIntegrationType(
        pipelineTemplate.jobs[jobIndex].jobParams[
          INTEGRATION_CUSTOM_ACTION_FIELD
        ]
          ? Enums.IntegrationType.CUSTOM
          : Enums.IntegrationType.HTTP,
      );
    }
  }, [
    getLastDataJob,
    isEditing,
    jobIndex,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // Re-map activeDataCollection schema
  useEffect(() => {
    if (activeDataCollectionItem?._id) {
      setActiveCollectionSchemaFields(
        activeDataCollectionItem.schemaData.map((field) =>
          Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
        ),
      );
    }
  }, [activeDataCollectionItem?._id, activeDataCollectionItem?.schemaData]);

  // If no previous job, set input schema from origin DatasetMeta
  useEffect(() => {
    if (!prevDataJob && activeCollectionSchemaFields) {
      setDynamicFields(activeCollectionSchemaFields);
    }
  }, [activeCollectionSchemaFields, prevDataJob]);

  // If mapping is previous job, and mapping to a DATASET set output schema from targeted DatasetMeta
  useEffect(() => {
    const prevMappingJob =
      prevDataJob?.jobParams as Interfaces.MappingJobParams;

    if (
      prevMappingJob?.output?.type === Enums.MappingSourceType.DATASET &&
      prevMappingJob?.output?.entityId
    ) {
      const targetDSM = datasetMetas.find(
        (dsm) => dsm.entity._id === prevMappingJob.output?.entityId,
      );

      const activeCollection = targetDSM?.entity.dataCollections.find(
        (collection) =>
          collection._id === targetDSM.entity.activeDataCollection,
      );

      if (activeCollection?.schemaData) {
        setDynamicFields(
          activeCollection.schemaData
            .filter(
              (field) =>
                field.dataValidation?.dataValidationType &&
                !SUPER_COL_TYPES.includes(
                  field.dataValidation?.dataValidationType,
                ),
            )
            .map((field) =>
              Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
            ),
        );
      }
    }
  }, [datasetMetas, prevDataJob?.jobParams]);

  // If mapping is previous job, and mapping to an INTEGRATION set output schema from targeted endpoint
  useEffect(() => {
    const prevMappingJob =
      prevDataJob?.jobParams as Interfaces.MappingJobParams;

    if (
      (prevMappingJob?.output?.type === Enums.MappingSourceType.INTEGRATION &&
        prevMappingJob?.output?.entityId &&
        prevMappingJob?.output?.baseEndpointId &&
        prevMappingJob?.output?.requestEndpointId) ||
      (prevMappingJob?.output?.type === Enums.MappingSourceType.INTEGRATION &&
        prevMappingJob?.output?.entityId &&
        prevMappingJob?.output?.customActionId)
    ) {
      const targetIntegration = integrationTemplates.find(
        (int) => int.entity._id === prevMappingJob?.output?.entityId,
      );
      const baseEndpoint = targetIntegration?.entity.endpoints.find(
        (base) => base._id === prevMappingJob?.output?.baseEndpointId,
      );
      const requestEndpoint = baseEndpoint?.endpoints.find(
        (end) => end._id === prevMappingJob?.output?.requestEndpointId,
      );
      const customAction = targetIntegration?.entity.customActions?.find(
        (action) => action._id === prevMappingJob?.output?.customActionId,
      );

      const responseSchema = requestEndpoint
        ? [
            Enums.ApiRequestType.PATCH,
            Enums.ApiRequestType.PUT,
            Enums.ApiRequestType.POST,
          ].includes(requestEndpoint.httpMethod)
          ? requestEndpoint.requestParams?.payload?.payloadSchema
          : requestEndpoint.responseInfo?.responseSchema
        : customAction?.responseSchema;

      if (responseSchema) {
        setDynamicFields(
          responseSchema.filter(
            (field) =>
              field.type &&
              field.type[0] &&
              !SUPER_COL_TYPES.includes(field.type[0] as Enums.ValueDataType),
          ),
        );
      }
    }
  }, [integrationTemplates, prevDataJob?.jobParams]);

  // If previous job was dataset, set dynamic fields from that dataset
  useEffect(() => {
    if (prevDataJob?.jobType === Enums.PipelineJobType.DATASET) {
      const targetDSM = datasetMetas.find(
        (dsm) =>
          dsm.entity._id ===
          (prevDataJob.jobParams as Interfaces.DatasetJobParams).datasetMetaId,
      );

      const activeCollection = targetDSM?.entity.dataCollections.find(
        (collection) =>
          collection._id === targetDSM.entity.activeDataCollection,
      );

      if (activeCollection?.schemaData) {
        setDynamicFields(
          activeCollection.schemaData
            .filter(
              (field) =>
                field.dataValidation?.dataValidationType &&
                !SUPER_COL_TYPES.includes(
                  field.dataValidation?.dataValidationType,
                ),
            )
            .map((field) =>
              Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
            ),
        );
      }
    }
  }, [datasetMetas, prevDataJob?.jobParams, prevDataJob?.jobType]);

  // If previous job was integration, set dynamic fields from that integration
  useEffect(() => {
    if (prevJob?.jobType === Enums.PipelineJobType.INTEGRATION) {
      const targetIntegration = integrationTemplates.find(
        (int) =>
          int.entity._id ===
          (prevJob.jobParams as Interfaces.IntegrationJobParams).integrationId,
      );
      const baseEndpoint = targetIntegration?.entity.endpoints.find(
        (base) =>
          base._id ===
          (prevJob.jobParams as Interfaces.IntegrationJobParams)
            .integrationEndpointId,
      );
      const requestEndpoint = baseEndpoint?.endpoints.find(
        (end) =>
          end._id ===
          (prevJob.jobParams as Interfaces.IntegrationJobParams)
            .integrationRequestEndpointId,
      );
      const customAction = targetIntegration?.entity.customActions?.find(
        (action) =>
          action._id ===
          (prevJob.jobParams as Interfaces.IntegrationJobParams)
            .integrationCustomActionId,
      );

      const responseSchema = requestEndpoint
        ? requestEndpoint.responseInfo?.responseSchema
        : customAction?.responseSchema;

      if (responseSchema) {
        setDynamicFields(
          responseSchema.filter(
            (field) =>
              field.type &&
              field.type[0] &&
              !SUPER_COL_TYPES.includes(field.type[0] as Enums.ValueDataType),
          ),
        );
      }
    }
  }, [integrationTemplates, prevJob?.jobParams, prevJob?.jobType]);

  // Set any custom action params
  useEffect(() => {
    if (pipelineJob) {
      const targetIntegration = integrationTemplates.find(
        (int) => int.entity._id === pipelineJob.integrationId,
      );

      const customAction = targetIntegration?.entity.customActions.find(
        (action) => action._id === pipelineJob.integrationCustomActionId,
      );

      if (customAction?.actionParams) {
        setCustomActionParams(customAction.actionParams);
      }
    }
  }, [integrationTemplates, pipelineJob]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  // Handle field change
  const handleFieldChange = (
    field: string,
    value: unknown,
    type?: Enums.IntegrationType,
  ) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[field] = value;
    if (field === INTEGRATION_TEMPLATE_FIELD) {
      // Find corresponding integrationConfig
      const integrationConfig = integrationConfigEnts.find(
        (config) => config.entity.integrationTemplateId === value,
      );

      if (integrationConfig) {
        cloned[INTEGRATION_CONFIG_FIELD] = integrationConfig.entity._id;
      }
    }

    if (type) {
      setIntegrationType(type);
    }
    setPipelineJob(cloned);
  };

  const handlePaginationChange = (
    paginationParams?: Interfaces.PaginationParams,
  ) => {
    const cloned = cloneDeep(pipelineJob);
    if (!paginationParams) {
      delete cloned[ParamType.PAGINATION];
    } else {
      cloned[ParamType.PAGINATION] = paginationParams;
    }
    setPipelineJob(cloned);
  };

  return (
    <>
      <StyledSubHeader
        style={{
          marginBottom: themeContext.margin.standard,
          marginTop: themeContext.margin.standard,
        }}
      >
        Integration Job
      </StyledSubHeader>
      <StyledText>
        Choose which integration you want this pipeline to interact with.
      </StyledText>

      <JobIntegrationSelector
        integrationType={integrationType}
        integrationValue={pipelineJob[INTEGRATION_TEMPLATE_FIELD]}
        baseEndpointValue={pipelineJob[INTEGRATION_BASE_ENDPOINT_FIELD]}
        requestEndpointValue={pipelineJob[INTEGRATION_REQUEST_ENDPOINT_FIELD]}
        customActionValue={pipelineJob[INTEGRATION_CUSTOM_ACTION_FIELD]}
        onChange={handleFieldChange}
      />

      {integrationType !== Enums.IntegrationType.CUSTOM && (
        <EndpointParamsAccordion
          onChange={(data) => handleFieldChange(REQUEST_PARAMS_FIELD, data)}
          onChangePagination={(data) => handlePaginationChange(data)}
          paramsData={pipelineJob[REQUEST_PARAMS_FIELD]}
          paginationParamsData={pipelineJob[PAGINATION_PARAMS_FIELD]}
          dynamicFields={dynamicFields}
        />
      )}
      {integrationType === Enums.IntegrationType.CUSTOM &&
        customActionParams && (
          <CustomActionsAccordion
            onChange={(data) => handleFieldChange(CUSTOM_CONFIG_FIELD, data)}
            paramsData={pipelineJob[CUSTOM_CONFIG_FIELD] || []}
            dynamicFields={dynamicFields}
            customActionParams={customActionParams}
          />
        )}
    </>
  );
};

export default JobIntegrationModalComponent;
