import {
  GanttChart,
  GanttFormattedTask,
} from '@configur-tech/upit-design-library';
import { ViewMode } from 'gantt-task-react';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectStage } from '../../enums';
import { GanttDataOutput, TaskType } from '../../interfaces/Gantt';
import { StyledDropdown } from '../../main/theme';
import { RootState } from '../../store/rootReducer';
import TooltipContent from './GanttTooltip';
import * as SC from './styled';

interface GanttChartProps {
  graphData: GanttDataOutput[];
  dataDrilldown?: (task) => void;
}

const VIEW_MODE_OPTIONS = Object.values(ViewMode).map((mode, index) => ({
  key: `view-mode-${index}`,
  value: mode,
  text: mode,
}));

const CONTAINER_HEIGHT = '100%';
const CONTAINER_WIDTH = '100%';
const GANTT_COLUMN_WIDTH = 175;

const Gantt: FC<GanttChartProps> = ({ graphData, dataDrilldown }) => {
  const [formattedData, setFormattedData] = useState<GanttFormattedTask[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const isCreating = projectStageState.activeStage === ProjectStage.GRAPH;

  useEffect(() => {
    if (!graphData || !graphData.length) {
      return;
    }

    try {
      setFormattedData(
        graphData
          .filter((d) => {
            // Validate task dates before display
            const startDate = DateTime.fromISO(d.start.unformatted);
            const endDate = DateTime.fromISO(d.end.unformatted);

            return endDate > startDate;
          })
          .map((d) => {
            return {
              ...d,
              start: new Date(d.start.unformatted),
              end: new Date(d.end.unformatted),
              startFormatted: d.start.formatted as string,
              endFormatted: d.end.formatted as string,
              hideChildren: isCreating ? false : true,
              id: d.id as string,
              type: d.type as TaskType,
              name: d.name as string,
              progress: d.progress as number,
            };
          }),
      );
    } catch {
      setFormattedData([]);
    }
  }, [graphData, isCreating]);

  const handleExpanderClick = (task: GanttFormattedTask) => {
    setFormattedData(formattedData.map((t) => (t.id === task.id ? task : t)));
  };

  const handleClick = (task: GanttFormattedTask) => {
    if (dataDrilldown) {
      dataDrilldown(task);
    }
  };

  return (
    <>
      {!formattedData?.length && (
        <SC.GanttError>This gantt chart has no data to display</SC.GanttError>
      )}

      {!!formattedData?.length && (
        <>
          <SC.GanttDropdownContainer>
            <StyledDropdown
              placeholder={'Select a view mode'}
              options={VIEW_MODE_OPTIONS}
              selection
              value={viewMode}
              onChange={(e, data) => setViewMode(data.value)}
            />
          </SC.GanttDropdownContainer>

          <SC.GanttContainer width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT}>
            <GanttChart
              containerWidth={CONTAINER_WIDTH}
              containerHeight={CONTAINER_HEIGHT}
              tasks={formattedData}
              handleExpanderClick={handleExpanderClick}
              handleClick={(task) => handleClick(task)}
              viewMode={viewMode}
              columnWidth={GANTT_COLUMN_WIDTH}
              tooltipContent={(task) => TooltipContent(task)}
              viewDate={new Date()}
            />
          </SC.GanttContainer>
        </>
      )}
    </>
  );
};

export default Gantt;
