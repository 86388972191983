import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CmsConnectionIcon from '../../assets/icons/connections/cms-connection-icon.svg';
import DataSample from '../../components/DataSample/DataSample';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import { updateActiveConnection } from '../../store/project-stage';

const ID_FIELD = '_id';
const NAME_FIELD = 'Workspace Name';
const DATASETS_FIELD = 'Available Datasets';
const QUERIES_FIELD = 'Available Queries';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DATASETS_FIELD },
  { name: QUERIES_FIELD },
];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const PortalDataViewsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { portalId } = useParams();

  const { portal, getPortal } = usePortal();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  // Fetch portal if on portal page and not in state
  useEffect(() => {
    if (
      location.pathname.includes(RouteName.PORTAL) &&
      portalId &&
      portal &&
      portal._id !== portalId
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Portal...' }));
        await getPortal(portalId, true);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getPortal, portal, portal?._id, portalId]);

  useEffect(() => {
    if (portal?.connectionItems?.cms) {
      const tableData: SampleDataRow[] = [];

      portal?.connectionItems?.cms.map((connection) => {
        const { datasetCount, queryCount } = (
          connection.configuration as Interfaces.CMSConnectionConfiguration
        ).groups.reduce(
          (acc, group) => {
            let dsm = 0;
            let agg = 0;

            group.items.map((item) => {
              if (item.datasetMetaId) {
                dsm++;
              } else if (item.aggregationId) {
                agg++;
              }
            });

            return {
              datasetCount: acc.datasetCount + dsm,
              queryCount: acc.queryCount + agg,
            };
          },
          { datasetCount: 0, queryCount: 0 },
        );

        tableData.push({
          [ID_FIELD]: { value: connection._id },
          [NAME_FIELD]: { value: connection.name },
          [DATASETS_FIELD]: {
            value: datasetCount.toString(),
          },
          [QUERIES_FIELD]: {
            value: queryCount.toString(),
          },
        });
      });

      setTableData(tableData);
    }
  }, [portal]);

  return (
    <>
      <PrimaryNavigationContainer
        route={RouteName.PORTAL}
        connectionType={Enums.ConnectionType.CMS}
      />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={CmsConnectionIcon}
          title={'Workspaces'}
        />

        <Page>
          <PageInner>
            <StageBodyText>
              Select a workspace below to access datasets and queries.
            </StageBodyText>

            <TableWrapper>
              <DataSample
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (cmsId: string) => {
                    dispatch(
                      updateActiveConnection(
                        portal?.connectionItems?.cms.find(
                          (c) => c._id === cmsId,
                        ),
                      ),
                    );
                    history.push(
                      `${RouteName.PORTAL}/${portal?._id}/${Enums.ConnectionType.CMS}/${cmsId}`,
                    );
                  },
                }}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalDataViewsPage;
