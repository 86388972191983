import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchQuery } from '../../interfaces/Search';
import ProjectService, {
  ProjectItemOutput,
  ProjectsItemOutput,
} from '../../services/project/ProjectService';
import { AppThunk } from '../store';

export interface ProjectsState {
  data: {
    data: ProjectItemOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  loading: boolean;
  error: string | null;
}

const initialState: ProjectsState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const ProjectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // Fetch
    fetchProjectsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProjectsSuccess(state, action: PayloadAction<ProjectsItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchProjectsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchProjectsStart,
  fetchProjectsSuccess,
  fetchProjectsFailure,
} = ProjectsSlice.actions;

export default ProjectsSlice.reducer;

export const fetchProjects =
  (
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchProjectsStart());
      const fetched = await ProjectService.getProjects(
        token,
        query,
        searchQuery,
        pageNum,
        limit,
        orderBy,
      );
      dispatch(
        fetchProjectsSuccess(fetched.data as unknown as ProjectsItemOutput),
      );
    } catch (err) {
      dispatch(fetchProjectsFailure((err as string).toString()));
    }
  };
