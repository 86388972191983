import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useQuery from '../../../../hooks/query/UseQuery';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectAggregationSubStage } from '../../../../store/project-stage/initial-state';
import { fetchQuerySuccess } from '../../../../store/query';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectAggregationSubStage.DATASETS;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemAggregationNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { query, queryAccessLevel } = useQuery();

  const [queryName, setQueryName] = useState<string>();
  const [queryDesc, setQueryDesc] = useState<string>();

  useEffect(() => {
    if (query) {
      setQueryName(query?.name);
      setQueryDesc(query?.description);
    }
  }, [query]);

  const processAction = () => {
    const cloned = cloneDeep(query);
    cloned[NAME_FIELD] = queryName;
    cloned[DESCRIPTION_FIELD] = queryDesc;

    dispatch(
      fetchQuerySuccess({
        accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
    dispatch(updateActiveProjectSubStage(NEXT_STAGE));
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Query Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new query.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your query name'}
            value={queryName || ''}
            onChange={(event, data) => setQueryName(data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this query?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={queryDesc || ''}
            onChange={(event) => setQueryDesc(event.target.value)}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!queryName?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Select'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAggregationNameStage;
