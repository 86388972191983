import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const ItemHeader = styled(StyledText)<{ color?: string }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  ${({ color }) => color && `color: ${color}`};
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  margin-top: ${({ theme }) => theme.margin.xxlarge};
  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ theme }) => `0 ${theme.margin.large}`};

    > p {
      margin-top: ${({ theme }) => theme.margin.standard};
    }
  }
`;
