import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import FormConnectionIcon from '../../assets/icons/connections/form-connection-icon.svg';
import logo from '../../assets/icons/system/codex-icon.svg';
import Card from '../../components/Card/Card';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePortal from '../../hooks/portal/UsePortal';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { CardEntityWrapper, StageBodyText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';

export interface FormConfigurationAdditional
  extends Interfaces.FormConnectionConfiguration {
  name: string;
  description: string;
  avatar: string;
}

const FORM_LINK = 'https://form.configurcodex.com';

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const PortalFormsPage: FC = () => {
  const dispatch = useDispatch();
  const { portalId } = useParams();

  const { portal, getPortal } = usePortal();
  const { organisation } = useOrganisation();

  // Fetch portal if on portal page and not in state
  useEffect(() => {
    if (
      location.pathname.includes(RouteName.PORTAL) &&
      portalId &&
      portal &&
      portal._id !== portalId
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Portal...' }));
        await getPortal(portalId, true);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getPortal, portal, portal?._id, portalId]);

  return (
    <>
      <PrimaryNavigationContainer
        route={RouteName.PORTAL}
        connectionType={Enums.ConnectionType.FORM}
      />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={FormConnectionIcon}
          title={'Forms'}
        />

        <Page>
          <PageInner>
            <StageBodyText>
              Selecting an item below will open a form in a new window.
            </StageBodyText>

            <CardEntityWrapper>
              {portal?.connectionItems?.form?.map((f, i) => {
                const config = f.configuration as FormConfigurationAdditional;
                return (
                  <Card
                    key={`${f.name}-${i}`}
                    title={config.name}
                    body={config.description}
                    image={organisation?.theme?.logo || logo}
                    action={() => {
                      window.open(`${FORM_LINK}/${f._id}/${config.formId}`);
                    }}
                  />
                );
              })}
            </CardEntityWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalFormsPage;
