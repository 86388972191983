import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { GraphItem } from '.';

const InitialField = {
  field: { alias: '', field: '', datasetMetaId: '' },
};

export const InitialTaskMetrics = {
  taskDatasetMetaId: '',
  projectField: InitialField,
  taskField: InitialField,
  nameField: InitialField,
  startDateField: InitialField,
  endDateField: InitialField,
  statusField: InitialField,
};

export const InitialMapParams = {
  graphType: Enums.GraphType.MAP,
  graphTextOpts: {
    title: '',
  },
  latitudeMetric: InitialField,
  longitudeMetric: InitialField,
  markerTitleMetric: InitialField,
  additionalMarkers: [],
};

export const InitialGanttParams = {
  graphType: Enums.GraphType.GANTT,
  graphTextOpts: {
    title: '',
  },
  projectMetrics: {
    nameField: InitialField,
    startDateField: InitialField,
    endDateField: InitialField,
    statusField: InitialField,
  },
};

export const InitialGraphParams = {
  graphType: Enums.GraphType.SIMPLE_LINE,
  graphMetrics: [],
  graphTextOpts: {
    title: '',
    showLegend: false,
    xAxisLabel: '',
    yAxisLabel: '',
  },
  xAxisMetric: InitialField,
};

const InitialGraph: GraphItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    name: '',
    description: '',
    queryParams: {
      aggregationType: Enums.AggregationType.SINGLE,
      fields: [],
      filters: [],
      groups: [],
      measures: [],
      sort: [],
    },
    graphParams: InitialGraphParams as Interfaces.GraphParams,
  },
};

export default InitialGraph;
