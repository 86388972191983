import { Enums } from '@configur-tech/upit-core-types';
import { isEmpty, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import logo from '../../assets/icons/system/codex-icon.svg';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import { StageBodyText, StageInner, StageWrapper } from '../../main/theme';
import { DatasetEventsItemOutput } from '../../services/events/EventsService';
import { updateActiveDatasetStage } from '../../store/dataset-stage';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import ActionBar from '../ActionBar/ActionBar';
import DataSample from '../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const DEFAULT_USER_NAME = 'Form User';
const DEFAULT_USER_AVATAR = 'configur-logo';

const PAGE_SIZE = 25;
const ROW_NUMBER = 'row_id';
const CREATED_BY_FIELD = 'User';
const DATE_FIELD = 'Date';
const EVENT_TYPE_FIELD = 'Event Type';

const TABLE_SCHEMA_EVENTS = [
  { name: CREATED_BY_FIELD },
  { name: EVENT_TYPE_FIELD },
  { name: DATE_FIELD },
];

enum SortTypes {
  'Date' = 'meta.created',
  'Event Type' = 'eventType',
  'User' = 'meta.createdBy',
}

const DatasetItemEventsView: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, getDatasetEvents } = useDatasetMeta();
  const [eventTableData, setEventTableData] = useState<SampleDataRow[]>([]);
  const [pagination, setPagination] = useState(0);
  const [datasetEvents, setDatasetEvents] = useState<DatasetEventsItemOutput>();
  const [sortBy, setSortBy] = useState<Record<string, number>>({});
  const [loadingEvents, setLoadingEvents] = useState<boolean>();

  useEffect(() => {
    if (datasetMeta?._id) {
      (async () => {
        setLoadingEvents(true);
        setDatasetEvents(
          await getDatasetEvents(
            datasetMeta._id,
            pagination,
            PAGE_SIZE,
            !isEmpty(sortBy) ? sortBy : undefined,
          ),
        );
        setLoadingEvents(false);
      })();
    }
  }, [datasetMeta, pagination, getDatasetEvents, sortBy]);

  const calculateRowNumber = useCallback(
    (rowIndex) => {
      const displayedRowIndex = rowIndex + 1;
      const initialRowIndex = (pagination - 1) * PAGE_SIZE;

      return (displayedRowIndex + initialRowIndex).toString();
    },
    [pagination],
  );

  useEffect(() => {
    if (datasetEvents) {
      const tableData: SampleDataRow[] = [];

      datasetEvents.data.map((i, index) => {
        const ent = i.entity;

        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(index) },
          [EVENT_TYPE_FIELD]: { value: startCase(ent.eventType) || '' },
          [CREATED_BY_FIELD]: {
            value: `${ent.createdByUser?.[0]?.firstName || ''} ${
              ent.createdByUser?.[0]?.lastName || DEFAULT_USER_NAME
            }`,
            avatar: ent.createdByUser?.[0]?.avatar || DEFAULT_USER_AVATAR,
          },
          [DATE_FIELD]: {
            value: DateTime.fromISO(ent.meta.created).toFormat(
              'dd/MM/yyyy HH:mm:ss',
            ),
          },
        });
      });

      setEventTableData(tableData);
    }
  }, [calculateRowNumber, datasetEvents]);

  const updateSortBy = useCallback(
    (sortItem: { id: string; desc: boolean }) => {
      if (isEmpty(sortItem)) {
        setSortBy({});
        return;
      }

      const sortProperty = SortTypes[sortItem.id];

      switch (sortItem.desc) {
        case true:
          setSortBy({ [sortProperty]: -1 });
          break;
        case false:
          setSortBy({ [sortProperty]: 1 });
          break;
      }
    },
    [],
  );

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          Track every interaction with your dataset here for full auditability.
        </StageBodyText>

        <DataSample
          sampleColumns={TABLE_SCHEMA_EVENTS}
          sampleRows={eventTableData}
          showPagination={true}
          paginationAction={updatePagination}
          pageSize={PAGE_SIZE}
          totalRows={datasetEvents?.pagination.totalCount}
          sortAction={updateSortBy}
          hideSortOnCols={[ROW_NUMBER]}
          iconMap={{ ...UserIconMap, [DEFAULT_USER_AVATAR]: logo }}
          loading={loadingEvents}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() =>
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION))
            }
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={`Back to Overview`}
          />
        }
      />
    </StageWrapper>
  );
};

export default DatasetItemEventsView;
