import { Interfaces } from '@configur-tech/upit-core-types';
import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../../assets/icons/loading/default-loading-icon.gif';
import { StyledBodySubHeader, StyledText } from '../../../../main/theme';
import { IntegrationTemplateItem } from '../../../../services/integration/IntegrationTemplateService';
import { RootState } from '../../../../store/rootReducer';
import * as SC from '../../styled';

interface IntegrationJobProps {
  job: Interfaces.PipelineJob;
  loading?: boolean;
}

const IntegrationJob: FC<IntegrationJobProps> = ({ job, loading }) => {
  const themeContext = useContext(ThemeContext);

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplateEnts: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const selectedIntegrationTemplate = integrationTemplateEnts?.find(
    (int) =>
      int.entity._id ===
      (job.jobParams as Interfaces.IntegrationJobParams).integrationId,
  )?.entity;

  const selectedBaseEndpoint = selectedIntegrationTemplate?.endpoints.find(
    (endpoint) =>
      endpoint._id ===
      (job.jobParams as Interfaces.IntegrationJobParams).integrationEndpointId,
  );

  const selectedRequestEndpoint = selectedBaseEndpoint?.endpoints.find(
    (endpoint) =>
      endpoint._id ===
      (job.jobParams as Interfaces.IntegrationJobParams)
        .integrationRequestEndpointId,
  );

  const selectedCustomAction = selectedIntegrationTemplate?.customActions?.find(
    (action) =>
      action._id ===
      (job.jobParams as Interfaces.IntegrationJobParams)
        .integrationCustomActionId,
  );

  return (
    <>
      {loading && (
        <>
          <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
        </>
      )}
      {!loading && (
        <>
          <div>
            <StyledBodySubHeader>Integration</StyledBodySubHeader>
            <StyledText
              style={{
                marginTop: themeContext.margin.standard,
                textAlign: 'center',
              }}
            >
              {selectedIntegrationTemplate?.name}
            </StyledText>
          </div>

          {selectedBaseEndpoint && (
            <>
              <div>
                <StyledBodySubHeader>Base Endpoint</StyledBodySubHeader>
                <StyledText
                  style={{
                    marginTop: themeContext.margin.standard,
                    textAlign: 'center',
                  }}
                >
                  {selectedBaseEndpoint?.name}
                </StyledText>
                <StyledText
                  style={{ color: themeContext.colors.system.darkGrey }}
                >
                  {selectedBaseEndpoint?.baseUrl}
                </StyledText>
              </div>

              <div>
                <StyledBodySubHeader>Request Endpoint</StyledBodySubHeader>
                <StyledText
                  style={{
                    marginTop: themeContext.margin.standard,
                    textAlign: 'center',
                  }}
                >
                  {selectedRequestEndpoint?.name}
                </StyledText>
                <StyledText
                  style={{ color: themeContext.colors.system.darkGrey }}
                >
                  {selectedRequestEndpoint?.path}
                </StyledText>
              </div>
            </>
          )}

          {selectedCustomAction && (
            <div>
              <StyledBodySubHeader>Action</StyledBodySubHeader>
              <StyledText
                style={{
                  marginTop: themeContext.margin.standard,
                  textAlign: 'center',
                }}
              >
                {selectedCustomAction?.name}
              </StyledText>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IntegrationJob;
