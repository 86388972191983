import { AccordionContent } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledSubHeader, StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.padding.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > svg {
    margin: ${({ theme }) =>
      `0 ${theme.margin.xxlarge} 0 ${theme.margin.standard}`};
  }
`;

export const InputWrapperCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    margin: ${({ theme }) =>
      `0 ${theme.margin.xxlarge} 0 ${theme.margin.standard}`};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 400px;
  text-align: center;

  margin-right: ${({ theme }) => theme.margin.standard};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const InputRowContainer = styled.div`
  background: ${({ theme }) => theme.colors.system.offWhite};
  padding: ${({ theme }) => theme.padding.xxlarge};

  border-radius: ${({ theme }) => theme.borders.radius};
  border: ${({ theme }) => `1px solid ${theme.colors.system.grey}`};

  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    margin: ${({ theme }) => theme.margin.standard};

    > div {
      > div {
        margin: 0;
      }
    }
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: ${({ theme }) => theme.margin.standard};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ToggleLabel = styled(StyledSubHeader)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  margin: 0 ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.small};

  &:last-of-type {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const StyledInputColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAccordionContent = styled(AccordionContent)`
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  padding: ${({ theme }) => theme.padding.large} !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  display: flex;

  &:last-of-type {
    border: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dropdown {
      margin: 0;
    }
  }
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  padding: ${({ theme }) => theme.padding.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const MappingHeaders = styled.div`
  display: flex;
  gap: 14em;
`;

export const SchemaContainer = styled.div`
  width: 300px;
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;
