import { Interfaces } from '@configur-tech/upit-core-types';
import {
  faComment,
  faDownload,
  faFilter,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { ModalTypes } from '../../components/Modal/Modal';
import { RouteName } from '../../enums';
import { EntityType } from '../../enums/EntityType';
import useChart from '../../hooks/chart/UseChart';
import useCMS from '../../hooks/cms/useCMS';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useFilter from '../../hooks/filter/UseFilter';
import useQuery from '../../hooks/query/UseQuery';
import { DefaultPopupStyles, StyledSubHeader } from '../../main/theme';
import { showModal } from '../../store/modal';
import { Sidebar } from './CMSPage';
import * as SC from './styled';

interface CMSHeaderProps {
  sidebar?: Sidebar;
  setSidebar: (sidebar: Sidebar | undefined) => void;
}

const VIEW = 'view';

const CMSHeader: FC<CMSHeaderProps> = ({ sidebar, setSidebar }) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { datasetMetaId, aggregationId, chartId, portalId, groupId } =
    useParams();
  const { getDatasetFilters } = useFilter();
  const { query } = useQuery();
  const { chart } = useChart();
  const { datasetMeta } = useDatasetMeta();
  const { checkCommentsEnabledForItem } = useCMS();
  const { cms } = useCMS();

  const history = useHistory();
  const [hasFilterParams, setHasFilterParams] = useState<boolean>();
  const [activeItem, setActiveItem] = useState<
    Interfaces.CMSConfigurationDataGroupItem | undefined
  >();
  const isViewingDataset = location.pathname.includes(VIEW);
  const showDescription =
    location.pathname.includes(RouteName.PROJECT_ITEM) ||
    location.pathname.includes(RouteName.PORTAL);

  const entityId = aggregationId || datasetMetaId || chartId;

  // Check for filter params
  useEffect(() => {
    setHasFilterParams(!!history.location.state?.filters);
  }, [history.location.state?.filters]);

  useEffect(() => {
    if (sidebar === Sidebar.DESCRIPTION && !activeItem?.description?.length) {
      setSidebar(undefined);
    }
  }, [activeItem?.description?.length, setSidebar, sidebar]);

  useEffect(() => {
    if (cms && entityId) {
      const group = (
        cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
      ).groups.find((group) => group._id === groupId);

      const item = group?.items.find(
        (item) =>
          item.datasetMetaId === entityId ||
          item.aggregationId === entityId ||
          item.chartId === entityId,
      );

      setActiveItem(item);
    }
  }, [activeItem, cms, entityId, groupId]);

  return (
    <SC.PageHeader>
      <StyledSubHeader>
        {hasFilterParams && `Filtering - `}
        {aggregationId
          ? query?.name
          : datasetMetaId
          ? datasetMeta?.name
          : chart?.name}
      </StyledSubHeader>

      <div>
        {!chartId && (
          <Popup
            content={`Export Data`}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  action={() => {
                    const addProps: Record<string, unknown> = {
                      portalId,
                      groupId,
                    };

                    if (datasetMetaId) {
                      addProps.entityType = EntityType.DATASET;
                      addProps.datasetMetas = [
                        {
                          _id: datasetMeta?._id,
                          conditions: getDatasetFilters(
                            aggregationId || datasetMetaId,
                            true,
                          ),
                        },
                      ];
                    } else if (aggregationId) {
                      addProps.entityType = EntityType.AGGREGATION;
                      addProps.query = {
                        _id: aggregationId,
                        conditions: getDatasetFilters(
                          aggregationId || datasetMetaId,
                          true,
                        ),
                      };
                    }

                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.EXPORT_DATA,
                        additionalProps: addProps,
                      }),
                    );
                  }}
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.blue}
                  icon={
                    <FontAwesomeIcon
                      icon={faDownload}
                      color={themeContext.colors.general.white}
                      size={'1x'}
                    />
                  }
                />
              </div>
            }
          />
        )}

        <Popup
          content={'Filters'}
          position="top center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FeatureButton
                isActive={sidebar === Sidebar.FILTERS}
                action={() =>
                  setSidebar(
                    sidebar === Sidebar.FILTERS ? undefined : Sidebar.FILTERS,
                  )
                }
                size={FeatureButtonSize.EXTRA_SMALL}
                height={30}
                color={themeContext.colors.general.sea}
                icon={
                  <FontAwesomeIcon
                    icon={faFilter}
                    color={themeContext.colors.system.white}
                    size={'1x'}
                  />
                }
              />
            </div>
          }
        />

        {showDescription && (
          <Popup
            content={'Description'}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  isDisabled={!activeItem?.description?.length}
                  isActive={sidebar === Sidebar.DESCRIPTION}
                  action={() =>
                    setSidebar(
                      sidebar === Sidebar.DESCRIPTION
                        ? undefined
                        : Sidebar.DESCRIPTION,
                    )
                  }
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.sea}
                  icon={
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={themeContext.colors.system.white}
                      size={'1x'}
                    />
                  }
                />
              </div>
            }
          />
        )}

        {(isViewingDataset ||
          checkCommentsEnabledForItem(
            datasetMetaId || aggregationId || chartId,
            groupId,
          )) && (
          <Popup
            content={'Comments'}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  isActive={sidebar === Sidebar.COMMENTS}
                  action={() =>
                    setSidebar(
                      sidebar === Sidebar.COMMENTS
                        ? undefined
                        : Sidebar.COMMENTS,
                    )
                  }
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.sea}
                  icon={
                    <FontAwesomeIcon
                      icon={faComment}
                      color={themeContext.colors.system.white}
                      size={'1x'}
                    />
                  }
                />
              </div>
            }
          />
        )}
      </div>
    </SC.PageHeader>
  );
};

export default CMSHeader;
