import { Interfaces } from '@configur-tech/upit-core-types';
import { SchemaField } from '@configur-tech/upit-core-types/lib/interfaces';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_GENERATE_SAMPLE'] || '';

interface SampleResult {
  sample: Record<string, unknown>[];
}

export default class SampleService {
  public static async fetchSample(
    token: string,
    dataMetaId: string,
    organisationId: string,
    userId: string,
    schema: SchemaField[],
    collectionId: string,
    sampleSize = 25,
    evaluateSuperColumns?: boolean,
  ): Promise<SampleResult> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<SampleResult>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      dataMetaId,
      organisationId,
      userId,
      sampleSize,
      collectionId,
      evaluateSuperColumns,
    });

    return result.data as SampleResult;
  }
}
