import { Enums } from '@configur-tech/upit-core-types';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import DataSamplePagination from '../../components/DataSample/DataSamplePagination';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import EmptyNotifications from '../../components/Notification/EmptyNotifications';
import NotificationEventCard from '../../components/NotificationEvent/NotificationEventCard';
import NotificationEventReadToggle from '../../components/NotificationEvent/NotificationEventReadToggle';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType, RouteName } from '../../enums';
import useNotification from '../../hooks/notification/UseNotification';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { NotificationItemsOutput } from '../../services/notification/NotificationService';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import { DEFAULT_PAGE_SIZE } from '../CMS/CMSPage';
import { generatePageArray } from '../NotificationAlerts/NotificationAlertsPage';
import { PageInner } from '../styled';
import * as SC from './styled';

export interface NotificationFetchParams {
  pageNum: number;
  readStatus: Enums.NotificationReadStatus;
}

const CLOSE_BUTTON_HEIGHT = 30;

const NotificationCentrePage: FC = () => {
  const { getNotifications, markAllNotificationsAsRead } = useNotification();
  const themeContext = useContext(ThemeContext);

  const notificationEventsState = useSelector(
    (state: RootState) => state.notificationEvents,
  );

  const [fetchParams, setFetchParams] = useState<NotificationFetchParams>({
    pageNum: 0,
    readStatus: Enums.NotificationReadStatus.UNREAD,
  });

  const notificationEvents: NotificationItemsOutput =
    fetchParams.readStatus === Enums.NotificationReadStatus.READ
      ? notificationEventsState.read
      : notificationEventsState.unread;

  // Fetch Notification Events
  useEffect(() => {
    (async () => {
      const formattedSearchParam = cloneDeep(fetchParams);
      formattedSearchParam.pageNum = fetchParams.pageNum + 1;
      await getNotifications(formattedSearchParam);
    })();
  }, [fetchParams, getNotifications]);

  const handleMarkAllAsRead = async () => {
    await markAllNotificationsAsRead();
  };

  const toggleReadStatus = (isRead: boolean) => {
    setFetchParams({
      ...fetchParams,
      readStatus: isRead
        ? Enums.NotificationReadStatus.READ
        : Enums.NotificationReadStatus.UNREAD,
    });
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.NOTIFICATION_CENTRE} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Notification Centre'}
          image={getDefaultAvatar(EntityType.NOTIFICATION)}
          title={'All Your Notifications In One Place'}
        />
        <Page>
          <PageInner>
            <SC.NotificationEventActions>
              <SC.NotificationEventButtons>
                <NotificationEventReadToggle
                  unreadCount={notificationEvents.pagination.totalCount}
                  showReadEvents={
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                  }
                  setShowReadEvents={toggleReadStatus}
                />
                <FeatureButton
                  action={() =>
                    getNotifications({
                      pageNum: 1,
                      readStatus: fetchParams.readStatus,
                    })
                  }
                  size={FeatureButtonSize.EXTRA_SMALL}
                  color={themeContext.colors.general.blue}
                  height={CLOSE_BUTTON_HEIGHT}
                  icon={
                    <FontAwesomeIcon
                      icon={faRefresh}
                      color={themeContext.colors.system.white}
                      size={'lg'}
                    />
                  }
                />
              </SC.NotificationEventButtons>
              {notificationEvents.pagination.totalCount > 0 &&
                fetchParams.readStatus ===
                  Enums.NotificationReadStatus.UNREAD && (
                  <SC.TextButton onClick={() => handleMarkAllAsRead()}>
                    Mark all as read
                  </SC.TextButton>
                )}
            </SC.NotificationEventActions>

            {notificationEventsState.loading && (
              <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />
            )}

            {!notificationEventsState.loading &&
              notificationEvents?.data?.map((notificationEvent) => {
                return (
                  <NotificationEventCard
                    notificationEvent={notificationEvent.entity}
                    key={`event-card-${notificationEvent.entity._id}`}
                  />
                );
              })}

            {!notificationEventsState.loading &&
              !!notificationEvents?.data?.length && (
                <DataSamplePagination
                  canNextPage={
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                      ? !!notificationEventsState.read.pagination.nextPageNum
                      : !!notificationEventsState.unread.pagination.nextPageNum
                  }
                  canPreviousPage={
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                      ? !!notificationEventsState.read.pagination.prevPageNum
                      : !!notificationEventsState.unread.pagination.prevPageNum
                  }
                  gotoPage={(pageIndex: number) =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: pageIndex,
                    })
                  }
                  nextPage={() =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: fetchParams.pageNum + 1,
                    })
                  }
                  pageCount={
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                      ? notificationEventsState.read.pagination.totalPages
                      : notificationEventsState.unread.pagination.totalPages
                  }
                  pageIndex={fetchParams.pageNum}
                  pageOptions={generatePageArray(
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                      ? notificationEventsState.read.pagination.totalPages
                      : notificationEventsState.unread.pagination.totalPages,
                  )}
                  pageSize={DEFAULT_PAGE_SIZE}
                  previousPage={() =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: fetchParams.pageNum - 1,
                    })
                  }
                  totalRows={
                    fetchParams.readStatus === Enums.NotificationReadStatus.READ
                      ? notificationEventsState.read.pagination.totalCount
                      : notificationEventsState.unread.pagination.totalCount
                  }
                />
              )}

            {!notificationEventsState.loading &&
              notificationEvents?.data?.length === 0 && <EmptyNotifications />}
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default NotificationCentrePage;
