import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faCog,
  faList,
  faPlus,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ThemeContext } from 'styled-components';
import { DateDurationUnits, EntityType } from '../../../enums';
import useChart from '../../../hooks/chart/UseChart';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import useFilterInput from '../../../hooks/filter/useFilterInput';
import useGraph from '../../../hooks/graph/UseGraph';
import useQuery from '../../../hooks/query/UseQuery';
import {
  defaultTheme,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
} from '../../../main/theme';
import { RootState } from '../../../store/rootReducer';
import FeatureButton from '../../FeatureButton/FeatureButton';
import { HELPER_EXTRACT_TYPE } from '../enhancement/date/DateExtract';
import FilterMatchConditions from './FilterMatchConditions';

import { DropdownItemPropsWithValidation } from './FilterModal';
import * as SC from './styled';

const datePlusUnitDropdownOptions = Object.values(DateDurationUnits).map(
  (i) => {
    return {
      key: i,
      text: startCase(i.toLowerCase()),
      value: i,
    };
  },
);

const textOperatorDropdownOptions = [
  {
    key: `condition-equal-to`,
    value: Enums.AggregationFilterOperator.EQUAL,
    text: 'Equal To',
  },
  {
    key: `condition-not-equal-to`,
    value: Enums.AggregationFilterOperator.NOT_EQUAL,
    text: `Not Equal To`,
  },
  {
    key: `condition-contains`,
    value: Enums.AggregationFilterOperator.LIKE,
    text: `Contains`,
  },
  {
    key: `condition-does-not-contain`,
    value: Enums.AggregationFilterOperator.NOT_LIKE,
    text: `Does Not Contain`,
  },
];

const listOperatorDropdownOptions = [
  {
    key: `condition-in`,
    value: Enums.AggregationFilterOperator.IN,
    text: 'Includes',
  },
  {
    key: `condition-not-in`,
    value: Enums.AggregationFilterOperator.NOT_IN,
    text: `Excludes`,
  },
];

const numberOperatorDropdownOptions = [
  {
    key: `condition-equal-to`,
    value: Enums.AggregationFilterOperator.EQUAL,
    text: 'Equal To',
  },
  {
    key: `condition-not-equal-to`,
    value: Enums.AggregationFilterOperator.NOT_EQUAL,
    text: `Not Equal To`,
  },
  {
    key: `condition-greater-than`,
    value: Enums.AggregationFilterOperator.GREATER_THAN,
    text: `Greater Than`,
  },
  {
    key: `condition-greater-than-equal`,
    value: Enums.AggregationFilterOperator.GREATER_THAN_EQUAL,
    text: `Greater Than Or Equal To`,
  },
  {
    key: `condition-less-than`,
    value: Enums.AggregationFilterOperator.LESS_THAN,
    text: `Less Than`,
  },
  {
    key: `condition-less-than-equal`,
    value: Enums.AggregationFilterOperator.LESS_THAN_EQUAL,
    text: `Less Than Or Equal To`,
  },
  {
    key: `condition-between`,
    value: Enums.AggregationFilterOperator.BETWEEN,
    text: `Between`,
  },
  {
    key: `condition-not-between`,
    value: Enums.AggregationFilterOperator.NOT_BETWEEN,
    text: `Not Between`,
  },
];

interface FilterBuilderProps {
  entitySchema?: Interfaces.FieldOutput[];
  fields?: (Interfaces.FieldOutput & {
    datasetMetaName: string;
    datasetMetaId: string;
    alias?: string;
  })[];
  fieldOptions?: DropdownItemPropsWithValidation[];
  filter: DynamicConditionalField;
  setFilter: React.Dispatch<React.SetStateAction<DynamicConditionalField>>;
  type?:
    | EntityType.DATASET
    | EntityType.AGGREGATION
    | EntityType.CHART
    | EntityType.GRAPH;
  pipelineMappings?: Interfaces.FieldOutput[];
  hiddenFieldIds?: string[];
  isAddRowDisabled?: boolean;
  smallHeadings?: boolean;
  schemaFieldOutput?: boolean;
  datasetMetaId?: string;
  hideIntroText?: boolean;
  filterText?: string;
}

const FilterBuilder: FC<FilterBuilderProps> = ({
  entitySchema,
  fields,
  fieldOptions,
  filter,
  setFilter,
  type,
  pipelineMappings,
  hiddenFieldIds,
  isAddRowDisabled,
  smallHeadings,
  schemaFieldOutput,
  datasetMetaId,
  hideIntroText,
  filterText,
}) => {
  const themeContext = useContext(ThemeContext);
  const { cmsId } = useParams();

  const { buildFilterInput } = useFilterInput();
  const { getDynamicPreFilterValue, listPreFilters } = useCMS();

  const { datasetMeta } = useDatasetMeta();
  const { query } = useQuery();
  const { graph } = useGraph();
  const { chart } = useChart();

  const listsState = useSelector((state: RootState) => state.lists);
  const lists = listsState.data;

  const dynamicFieldOptions = pipelineMappings?.map((m) => {
    const validation = { ...m.dataValidation };

    // Resolve lookup dataValidationType
    if (validation?.fieldLookup) {
      validation.dataValidationType =
        (validation.fieldLookup as unknown as Interfaces.FieldLookupWithSchema)
          .target.targetSchema.dataValidation?.dataValidationType ||
        Enums.ValueDataType.TEXT;
    }

    return {
      key: m.fieldId || m.name,
      text: `Value of "${m.name}"`,
      value: m.fieldId || m.name,
      validation,
    };
  });

  const handleDatePlusChange = (
    index: number,
    unit?: DateDurationUnits,
    amount?: number,
  ) => {
    const cloned = cloneDeep(filter);

    // Reset date plus object if none exists
    if (!cloned.value[index].value.plus) {
      cloned.value[index].value.plus = {};
    }

    cloned.value[index].value.plus.unit =
      unit || cloned.value[index].value.plus.unit;
    cloned.value[index].value.plus.amount =
      amount || cloned.value[index].value.plus.amount;

    setFilter(cloned);
  };

  const formatSchemaFieldOutput = (fieldId: string, fieldName: string) => {
    const schema = cloneDeep(
      entitySchema?.find((f) => f.fieldId === fieldId || f.name === fieldName),
    );

    return {
      ...schema.dataValidation,
      fieldId,
      field: schema.name,
      type: [schema.dataValidation.dataValidationType],
    };
  };

  const handleFieldChange = (field: string, alias: string, index: number) => {
    const cloned = cloneDeep(filter);

    const split = field.split('***');

    const isMeasure =
      type === EntityType.AGGREGATION
        ? !!query?.queryParams?.measures?.find((m) => m.alias === alias)
        : !!graph?.queryParams?.measures?.find((m) => m.alias === alias);

    cloned.value[index] = {
      ...cloned.value[index],
      datasetMetaId: split[1] || datasetMetaId || datasetMeta?._id,
      field: schemaFieldOutput
        ? formatSchemaFieldOutput(split[0], split[2])
        : split[0],
      alias,
      isMeasure,
    };
    // Reset operator
    cloned.value[index].operator = undefined;
    // Reset value
    cloned.value[index].value = undefined;

    setFilter(cloned);
  };

  const handleFieldChangeMultiple = (fields: string[], index: number) => {
    if (!fieldOptions) {
      return;
    }
    const cloned = cloneDeep(filter);

    if (!fields.length) {
      cloned.value[index].fields = [];
      setFilter(cloned);
      return;
    }

    cloned.value[index].fields = [];

    fields.map((field) => {
      const fieldItem = fieldOptions.find((o) => o.value === field);
      const alias = fieldItem?.text;
      const split = field.split('***');

      const isMeasure = !!chart?.graphs
        .find((g) => g.name === fieldItem?.description)
        ?.queryParams?.measures?.find((m) => m.alias === alias);

      cloned.value[index].fields = [
        ...cloned.value[index].fields,
        {
          datasetMetaId: split[1],
          field: split[0],
          alias,
          isMeasure,
        },
      ];
      // Reset operator
      cloned.value[index].operator = undefined;
      // Reset value
      cloned.value[index].value = undefined;
    });

    setFilter(cloned);
  };

  const handleOperatorChange = (
    operator: Enums.AggregationFilterOperator,
    index: number,
  ) => {
    const cloned = cloneDeep(filter);
    cloned.value[index].operator = operator;

    // Clear value only if it is not a dynamic field
    if (!cloned.value[index].value?.field) {
      cloned.value[index].value = undefined;
    }

    setFilter(cloned);
  };

  const handleToggleAdvancedOptions = (
    active: boolean,
    filterIndex: number,
  ) => {
    const cloned = cloneDeep(filter);
    if (!active) {
      delete cloned.value[filterIndex].value.plus;
    } else {
      cloned.value[filterIndex].value.plus = {};
    }
    setFilter(cloned);
  };

  const handleValueChange = (
    value: unknown,
    index: number,
    betweenValueIndex?: number,
  ) => {
    const cloned = cloneDeep(filter);

    let val;
    if (typeof betweenValueIndex !== 'undefined') {
      const path =
        cloned.value[index].value?.value || cloned.value[index].value;

      const between = Array.isArray(path) ? path : [0, 0];

      between[betweenValueIndex] = value as number;

      val = between;
    } else {
      val =
        value !== null
          ? [
              Enums.AggregationFilterOperator.LIKE,
              Enums.AggregationFilterOperator.NOT_LIKE,
            ].includes(cloned.value[index].operator)
            ? `%${value}%`
            : value
          : '';
    }

    dynamicFieldOptions?.map((d) => d.key).includes(val)
      ? (cloned.value[index].value = {
          type: Enums.DynamicConditionalType.FIELD,
          field: val,
        })
      : (cloned.value[index].value = {
          type: Object.values(Enums.DynamicDateValue).includes(
            value as Enums.DynamicDateValue,
          )
            ? Enums.DynamicConditionalType.DATE
            : Enums.DynamicConditionalType.CONSTANT,
          value: val,
        });

    setFilter(cloned);
  };

  const addFilterRow = () => {
    const cloned = cloneDeep(filter);
    cloned.value.push({
      operator: Enums.AggregationFilterOperator.IN,
    });

    setFilter(cloned);
  };

  const removeFilterRow = (index: number) => {
    const cloned = cloneDeep(filter);
    cloned.value.splice(index, 1);

    setFilter(cloned);
  };

  return (
    <>
      <FilterMatchConditions
        filter={filter}
        setFilter={setFilter}
        isDisabled={isAddRowDisabled}
        smallHeadings={smallHeadings}
        hideIntroText={hideIntroText}
      />

      {(filter?.value as DynamicConditionalField[])?.map((f, index) => {
        const fieldName = f.fields?.length
          ? f.fields[0]?.alias || f.fields[0]?.field
          : f.alias || f.field;

        const fieldId = f.fields?.length
          ? f.fields[0]?.field || ''
          : f.field || '';

        const fieldIsMeasure = f.fields?.length
          ? f.fields[0]?.isMeasure || false
          : false;

        // Hide display of any hidden field ids
        if (hiddenFieldIds?.includes(fieldId)) {
          return;
        }

        const schemaField =
          fields?.find((s) => s.name === fieldName) ||
          fields?.find((s) => s.fieldId === fieldId) ||
          entitySchema?.find((s) => s.name === fieldName) ||
          entitySchema?.find((s) => s.fieldId === fieldId);

        let fieldType = fieldIsMeasure
          ? Enums.ValueDataType.NUMBER
          : schemaField?.dataValidation?.dataValidationType ||
            Enums.ValueDataType.TEXT;

        // Set field type if lookup
        if (
          fieldType === Enums.ValueDataType.FIELD_LOOKUP &&
          schemaField?.dataValidation?.fieldLookup?.target
        ) {
          const lookupTarget = schemaField.dataValidation
            .fieldLookup as unknown as Interfaces.FieldLookupWithSchema;

          if (lookupTarget) {
            fieldType =
              (lookupTarget.target.targetSchema?.dataValidation
                ?.dataValidationType as Enums.ValueDataType) ||
              Enums.ValueDataType.TEXT;
          }
        }

        const isList =
          !fieldIsMeasure &&
          (schemaField?.dataValidation?.constraints?.listValues ||
            lists.data.find(
              (list) =>
                list.entity.datasetListOptions?.fieldId ===
                schemaField?.fieldId,
            )?.entity._id);

        let filteredOptions = fieldOptions;

        if (type === EntityType.CHART) {
          if (filter?.value) {
            const firstFilter = (filter?.value as DynamicConditionalField[])[
              index
            ]?.fields;

            if (firstFilter?.length) {
              const firstFilterField = firstFilter[0];

              const schemaField = (fields || entitySchema)?.find(
                (s) => s.name === firstFilterField?.alias,
              );

              fieldType =
                schemaField?.dataValidation?.dataValidationType ||
                Enums.ValueDataType.TEXT;

              if (!fieldIsMeasure && isList) {
                filteredOptions = fieldOptions?.filter(
                  (o) =>
                    o['data-validation']?.constraints?.listValues === isList &&
                    o['data-validation']?.dataValidationType === fieldType,
                );
              } else {
                filteredOptions = fieldOptions?.filter(
                  (o) => o['data-validation']?.dataValidationType === fieldType,
                );
              }
            }
          }
        }

        let matchOptions;
        const activeDynamicField = !!(
          f.value as Interfaces.FieldConditionalValue
        )?.field;

        if (fieldIsMeasure) {
          matchOptions = numberOperatorDropdownOptions;
        } else if (isList && !activeDynamicField) {
          matchOptions = listOperatorDropdownOptions;
        } else {
          switch (fieldType) {
            case Enums.ValueDataType.NUMBER:
            case Enums.ValueDataType.DATE:
            case Enums.ValueDataType.FORMULA:
              matchOptions = numberOperatorDropdownOptions;
              break;
            case Enums.ValueDataType.DATE_CONVERSION: {
              const conversionOpts =
                schemaField?.dataValidation?.dateConversion;
              if (
                (conversionOpts?.type &&
                  (conversionOpts?.type ===
                    Enums.DateConversionOperation.REFORMAT ||
                    (HELPER_EXTRACT_TYPE.includes(conversionOpts?.type) &&
                      [
                        Enums.DateFormat.DAY_UNPADDED,
                        Enums.DateFormat.DAY_PADDED,
                        Enums.DateFormat.WEEK_NUM_UNPADDED,
                        Enums.DateFormat.WEEK_NUM_PADDED,
                        Enums.DateFormat.MONTH_NUM_UNPADDED,
                        Enums.DateFormat.MONTH_NUM_PADDED,
                        Enums.DateFormat.YEAR_SHORT,
                        Enums.DateFormat.YEAR_FULL,
                        Enums.DateFormat.HOUR_12_UNPADDED,
                        Enums.DateFormat.HOUR_12_PADDED,
                        Enums.DateFormat.HOUR_24_UNPADDED,
                        Enums.DateFormat.HOUR_24_PADDED,
                        Enums.DateFormat.MINUTE_UNPADDED,
                        Enums.DateFormat.MINUTE_PADDED,
                        Enums.DateFormat.SECOND_UNPADDED,
                        Enums.DateFormat.SECOND_PADDED,
                      ].includes(
                        (conversionOpts as Interfaces.DateExtractionOp).params
                          .format,
                      )))) ||
                conversionOpts?.type === Enums.DateConversionOperation.DATE_DIFF
              ) {
                matchOptions = numberOperatorDropdownOptions;
                break;
              }
              matchOptions = listOperatorDropdownOptions;
              break;
            }
            default:
              matchOptions = textOperatorDropdownOptions;
          }
        }

        const filteredDynamicFieldOptions = dynamicFieldOptions?.filter(
          (option) => {
            switch (fieldType) {
              case Enums.ValueDataType.FORMULA:
                return (
                  option.validation?.dataValidationType ===
                  Enums.ValueDataType.NUMBER
                );
              case Enums.ValueDataType.DATE_CONVERSION:
                return (
                  option.validation?.dataValidationType ===
                  Enums.ValueDataType.DATE
                );
              case Enums.ValueDataType.TEXT_TRANSFORMATION:
                return true;
              case Enums.ValueDataType.TEXT:
                return true;
              default:
                return option.validation?.dataValidationType === fieldType;
            }
          },
        );

        const handleToggleFieldConditionalValue = (
          active: boolean,
          filterIndex: number,
        ) => {
          const cloned = cloneDeep(filter);
          if (!active) {
            delete cloned.value[filterIndex].value.field;
          } else {
            if (cloned.value?.[filterIndex]?.value?.value) {
              delete cloned.value[filterIndex].value.value;
            }

            cloned.value[filterIndex].value
              ? cloned.value[filterIndex].value
              : (cloned.value[filterIndex].value = {});
            cloned.value[filterIndex].value.type =
              Enums.DynamicConditionalType.FIELD;
            cloned.value[filterIndex].value.field =
              filteredDynamicFieldOptions?.length
                ? filteredDynamicFieldOptions[0].key
                : '';
            cloned.value[filterIndex].operator =
              Enums.AggregationFilterOperator.EQUAL;
          }

          setFilter(cloned);
        };

        const isDynamicDate =
          fieldType === Enums.ValueDataType.DATE &&
          Object.values(Enums.DynamicDateValue).includes(
            (f.value as Interfaces.DateConditionalValue)
              ?.value as Enums.DynamicDateValue,
          );

        const showAdvancedDateOptions = !!(
          f.value as Interfaces.DateConditionalValue
        )?.plus;

        const showDynamicField = !!(f.value as Interfaces.FieldConditionalValue)
          ?.field;

        const preFilters = listPreFilters(
          (datasetMetaId || datasetMeta?._id) as string,
        );

        const preFilterValue = getDynamicPreFilterValue(
          preFilters,
          schemaField?.fieldId as string,
        );

        // Set correct columnValue
        let columnValue: string;
        if (schemaFieldOutput && f.field) {
          const sField = entitySchema?.find(
            (s) =>
              s.fieldId ===
                (f.field as unknown as Interfaces.SchemaField).fieldId ||
              s.name === (f.field as unknown as Interfaces.SchemaField).field,
          );

          columnValue = `${sField?.fieldId || sField?.name}***${
            datasetMetaId || datasetMeta?._id
          }***${f.alias}`;
        } else {
          // Instance of a queried field using its default name
          const nonAliasedSchemaField = entitySchema?.find((entF) => {
            return entF.name === f.alias && entF.fieldId === f.field;
          });

          if (nonAliasedSchemaField) {
            columnValue = `${f.field}***${f.datasetMetaId}***${nonAliasedSchemaField.name}`;
          } else {
            // Instance of a queried field using a different alias to its default name
            const aliasedSchemaField = entitySchema?.find((entF) => {
              return entF.fieldId === f.field || entF.name === f.alias;
            });
            columnValue = `${f.field || aliasedSchemaField?.fieldId}***${
              f.datasetMetaId
            }***${f.alias || aliasedSchemaField?.name}`;
          }
        }

        return (
          <SC.FilterWrapper
            key={`filterWrap-${index}`}
            applyLeftMargin={isDynamicDate}
          >
            {type !== EntityType.CHART && (
              <SC.FilterStage>
                {index === 0 && (
                  <StyledBodySubHeader>Column</StyledBodySubHeader>
                )}
                <StyledDropdown
                  selectOnBlur={false}
                  placeholder={'Select a column'}
                  options={fieldOptions}
                  selection
                  search
                  value={columnValue}
                  onChange={(e, data) => {
                    handleFieldChange(
                      data.value,
                      data.options.find((o) => o.value === data.value)?.text,
                      index,
                    );
                  }}
                  style={{ margin: 0 }}
                  upward={true}
                  disabled={!fieldOptions || !fieldOptions.length}
                />
              </SC.FilterStage>
            )}
            {type === EntityType.CHART && (
              <SC.FilterStage>
                {index === 0 && (
                  <StyledBodySubHeader>Column</StyledBodySubHeader>
                )}
                <StyledDropdown
                  selectOnBlur={false}
                  placeholder={'Select a column'}
                  options={filteredOptions}
                  search
                  selection
                  value={
                    f.fields?.map(
                      (c) => `${c.field}***${c.datasetMetaId}***${c.alias}`,
                    ) || []
                  }
                  onChange={(e, data) => {
                    handleFieldChangeMultiple(data.value, index);
                  }}
                  style={{ margin: 0 }}
                  upward={true}
                  multiple
                  disabled={!fieldOptions || !fieldOptions.length}
                />
              </SC.FilterStage>
            )}

            <SC.FilterStage>
              {index === 0 && (
                <StyledBodySubHeader>
                  {filterText || 'Filter'}
                </StyledBodySubHeader>
              )}
              <StyledDropdown
                selectOnBlur={false}
                disabled={!f.field && !f.fields && !schemaField}
                placeholder={`Select ${
                  filterText ? filterText.toLowerCase() : 'filter'
                }`}
                options={matchOptions}
                selection
                value={f.operator || ''}
                onChange={(e, data) => handleOperatorChange(data.value, index)}
                style={{ margin: 0 }}
                upward={true}
              />
            </SC.FilterStage>

            <SC.FilterStage>
              {index === 0 && <StyledBodySubHeader>Value</StyledBodySubHeader>}

              {!showDynamicField &&
                buildFilterInput(
                  schemaField as Interfaces.FieldOutput,
                  fieldType,
                  (f.value as Interfaces.DateConditionalValue)?.value,
                  (value, betweenValueIndex) =>
                    handleValueChange(value, index, betweenValueIndex),
                  false,
                  !f.operator || (!f.field && !f.fields && !schemaField),
                  [
                    Enums.AggregationFilterOperator.BETWEEN,
                    Enums.AggregationFilterOperator.NOT_BETWEEN,
                  ].includes(f.operator),
                  cmsId ? preFilterValue : undefined,
                  fieldIsMeasure,
                )}

              {showDynamicField && !!filteredDynamicFieldOptions?.length && (
                <StyledDropdown
                  selectOnBlur={false}
                  disabled={!f.field && !f.fields && !f.operator}
                  placeholder={'Select value'}
                  options={filteredDynamicFieldOptions}
                  selection
                  value={
                    (f.value as Interfaces.FieldConditionalValue)?.field || ''
                  }
                  onChange={(e, data) => {
                    handleValueChange(data.value, index);
                  }}
                  style={{ margin: 0 }}
                  upward={true}
                />
              )}

              {showAdvancedDateOptions && isDynamicDate && (
                <SC.DateAdvancedWrapper>
                  <div>
                    <StyledBodySubHeader>+/-</StyledBodySubHeader>
                    <StyledInput
                      disabled={
                        !showAdvancedDateOptions || !isDynamicDate || !f.value
                      }
                      placeholder={'Amount'}
                      value={
                        (f.value as Interfaces.DateConditionalValue)?.plus
                          ?.amount || ''
                      }
                      type={'number'}
                      onChange={(e, data) =>
                        handleDatePlusChange(index, undefined, data.value)
                      }
                    />
                  </div>
                  <div>
                    <StyledBodySubHeader>Unit</StyledBodySubHeader>
                    <StyledDropdown
                      disabled={
                        !showAdvancedDateOptions || !isDynamicDate || !f.value
                      }
                      selectOnBlur={false}
                      placeholder={'Select unit'}
                      options={datePlusUnitDropdownOptions}
                      selection
                      value={
                        (f.value as Interfaces.DateConditionalValue)?.plus
                          ?.unit || ''
                      }
                      onChange={(e, data) =>
                        handleDatePlusChange(index, data.value)
                      }
                      style={{
                        marginBottom: 0,
                        marginTop: 0,
                        minWidth: SC.DATE_ADVANCED_OPTIONS_WIDTH,
                      }}
                    />
                  </div>
                </SC.DateAdvancedWrapper>
              )}
            </SC.FilterStage>

            {isDynamicDate &&
              ![
                Enums.AggregationFilterOperator.BETWEEN,
                Enums.AggregationFilterOperator.NOT_BETWEEN,
              ].includes(f.operator) && (
                <SC.FilterStage
                  applyMargin={showAdvancedDateOptions && isDynamicDate}
                  buttonContainer={true}
                >
                  <FeatureButton
                    isDisabled={!f.value}
                    isActive={showAdvancedDateOptions}
                    action={() =>
                      handleToggleAdvancedOptions(
                        !showAdvancedDateOptions,
                        index,
                      )
                    }
                    size={35}
                    height={34}
                    color={themeContext.colors.general.blue}
                    icon={
                      <FontAwesomeIcon
                        icon={faCog}
                        color={defaultTheme.colors.system.white}
                      />
                    }
                  />
                </SC.FilterStage>
              )}

            {pipelineMappings && pipelineMappings.length > 0 && (
              <SC.FilterStage
                applyMargin={showAdvancedDateOptions && isDynamicDate}
                buttonContainer={true}
              >
                <FeatureButton
                  isDisabled={
                    !f.field ||
                    !f.operator ||
                    !filteredDynamicFieldOptions?.length
                  }
                  isActive={showDynamicField}
                  action={() =>
                    handleToggleFieldConditionalValue(!showDynamicField, index)
                  }
                  size={35}
                  height={34}
                  color={themeContext.colors.general.purple}
                  icon={
                    <FontAwesomeIcon
                      icon={faList}
                      color={defaultTheme.colors.system.white}
                    />
                  }
                />
              </SC.FilterStage>
            )}

            <SC.FilterStageActions
              applyMargin={showAdvancedDateOptions && isDynamicDate}
            >
              {index + 1 ===
                (filter?.value as DynamicConditionalField[]).length && (
                <FeatureButton
                  action={addFilterRow}
                  size={34}
                  height={34}
                  color={themeContext.colors.general.blue}
                  icon={
                    <FontAwesomeIcon
                      icon={faPlus}
                      color={defaultTheme.colors.system.white}
                    />
                  }
                  style={{ marginRight: themeContext.margin.standard }}
                />
              )}

              <FeatureButton
                action={() => removeFilterRow(index)}
                size={34}
                height={34}
                color={themeContext.colors.general.red}
                icon={
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color={defaultTheme.colors.system.white}
                  />
                }
                isDisabled={
                  index === 0 &&
                  (filter?.value as DynamicConditionalField[]).length === 1
                }
              />
            </SC.FilterStageActions>
          </SC.FilterWrapper>
        );
      })}
    </>
  );
};

export default FilterBuilder;
