import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SampleData, SampleDataRow } from '../../interfaces/SampleData';

const buildSchema = (
  sampleSchema: Interfaces.SchemaField[] | Interfaces.Field[],
): Interfaces.FieldOutput[] => {
  // Check if already in the correct format
  if ((sampleSchema[0] as unknown as Interfaces.Field)?.name) {
    return sampleSchema as Interfaces.FieldOutput[];
  }

  return (sampleSchema as Interfaces.SchemaField[]).map((i) => {
    return {
      fieldId: i.fieldId,
      name: i.field,
      dataValidation: {
        dataValidationType: i.type[0] as Enums.ValueDataType,
      },
    } as Interfaces.FieldOutput;
  });
};

const buildRows = (sampleRows: Record<string, unknown>[]): SampleDataRow[] => {
  return sampleRows.map((row) => {
    return Object.entries(row).reduce((formattedRow, [col, colData]) => {
      return {
        ...formattedRow,
        [col]: {
          value: colData,
        },
      };
    }, {});
  });
};

const buildTableData = (
  schema: Interfaces.SchemaField[] | Interfaces.Field[],
  sample: Record<string, unknown>[],
): SampleData => {
  // Map for sample output
  const sampleSchema = schema || [];
  const tableSchema = buildSchema(sampleSchema);

  const sampleRows = sample || [];
  const tableRows = buildRows(sampleRows);

  return { schema: tableSchema, rows: tableRows };
};

export default buildTableData;
