import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ValueDataType } from '@configur-tech/upit-core-types/lib/enums';
import {
  Field,
  SupercolumnField,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItemProps, Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../enums';
import { EntityType } from '../../../enums/EntityType';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useGraph from '../../../hooks/graph/UseGraph';
import useQuery from '../../../hooks/query/UseQuery';
import {
  DefaultPopupStyles,
  StyledAccordion,
  StyledDropdownUltraWide,
  StyledH5,
  StyledInput,
  defaultTheme,
} from '../../../main/theme';
import {
  GraphOutputWithDetails,
  fetchGraphSuccess,
} from '../../../store/graph';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { QueryOutputWithDetails } from '../../../store/queries';
import { fetchQuerySuccess } from '../../../store/query';
import CheckColumnUse from '../../../util/check-column-use/CheckColumnUse';
import namesRequiresCleansing from '../../../util/column-name/CleanseColumnName';
import { GroupItem } from '../../Aggregation/AggregationGroup';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import FormattingOptions from '../validation/OptionPanels/FormattingOptions';
import ValidationOptions from './ValidationOptions';
import AddDateSuperCol from './date/AddDateSuperCol';
import Lookup from './lookup/Lookup';
import FormulaCalc from './numeric/FormulaCalc';
import * as SC from './styled';
import AddTextSuperCol from './text/AddTextSuperCol';

const STAGE_ONE = 1;
const STAGE_TWO = 2;
const STAGE_DELETE = 3;

export enum SuperColumnType {
  NUMERIC = 'numeric',
  TEXT = 'text',
  DATE = 'date',
  LOOKUP = 'lookup',
}

const EXTRACT_TEXT = 'Extract';

const EXTRACT_DATE_TYPES = [
  Enums.DateConversionOperation.EXTRACT_DATE,
  Enums.DateConversionOperation.EXTRACT_DAY,
  Enums.DateConversionOperation.EXTRACT_WEEK_NUM,
  Enums.DateConversionOperation.EXTRACT_HOUR,
  Enums.DateConversionOperation.EXTRACT_MINUTE,
  Enums.DateConversionOperation.EXTRACT_MONTH,
  Enums.DateConversionOperation.EXTRACT_SECOND,
  Enums.DateConversionOperation.EXTRACT_TIME,
  Enums.DateConversionOperation.EXTRACT_YEAR,
];

export interface EnhancementAddColumnModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  field?: string;
  stage?: number;
  dataCollectionId: string;
  existingSupercol: {
    index: number;
    supercolumn: SupercolumnField;
  };
  entityType: EntityType;
}

const textColumnTypeOptions = (typeEnums: Record<number, unknown>) =>
  Object.entries(typeEnums).reduce((acc, [k, v]) => {
    if (isNaN(Number(k)) && v !== Enums.TextTransformOperation.UNKNOWN) {
      const type = startCase(k.toString().toLowerCase());

      return [
        ...acc,
        {
          key: `column-type-${type}-${v}`,
          value: v as Enums.TextTransformOperation,
          text: type,
        },
      ];
    }
    return acc;
  }, [] as DropdownItemProps[]);

const dateColumnTypeOptions = (typeEnums: Record<number, unknown>) =>
  Object.entries(typeEnums).reduce((acc, [k, v], i) => {
    if (
      isNaN(Number(k)) &&
      v !== Enums.DateConversionOperation.UNKNOWN &&
      v !== Enums.DateConversionOperation.TODAY
    ) {
      if (
        v !== Enums.DateConversionOperation.EXTRACT_DATE &&
        EXTRACT_DATE_TYPES.includes(v as Enums.DateConversionOperation)
      ) {
        return acc;
      }

      const type =
        v === Enums.DateConversionOperation.EXTRACT_DATE
          ? EXTRACT_TEXT
          : startCase(k.toString().toLowerCase());

      return [
        ...acc,
        {
          key: `column-type-${type}-${i}`,
          value: v as Enums.DateConversionOperation,
          text: type,
        },
      ];
    }
    return acc;
  }, [] as DropdownItemProps[]);

const EnhancementAddColumnModal: FC<EnhancementAddColumnModalProps> = ({
  setShowModal,
  field,
  stage,
  dataCollectionId,
  existingSupercol,
  entityType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();
  const { editQuery, query, queryAccessLevel } = useQuery();
  const { graph, graphAccessLevel } = useGraph();

  const [modalStage, setModalStage] = useState<number>(STAGE_ONE);
  const [schemaField, setSchemaField] = useState<Field>({ name: '' });
  const [columnName, setColumnName] = useState<string>('');
  const [isUniqueColumnName, setIsUniqueColumnName] = useState<boolean>(false);
  const [invalidName, setInvalidName] = useState<boolean>(false);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState<number[]>(
    [],
  );
  const [formatting, setFormatting] = useState<Interfaces.SchemaFieldFormats>();

  const [superColumnType, setSuperColumnType] = useState<
    | SuperColumnType
    | ValueDataType.DATE_CONVERSION
    | ValueDataType.FORMULA
    | ValueDataType.TEXT_TRANSFORMATION
  >();
  const [textColumnType, setTextColumnType] =
    useState<Enums.TextTransformOperation>();

  const [dateColumnType, setDateColumnType] =
    useState<Enums.DateConversionOperation>();

  const [dataValidation, setDataValidation] =
    useState<Interfaces.DataValidation>();

  const [superColumnValid, setSuperColumnValid] = useState<boolean>(false);
  const [usedInSuperCol, setUsedInSuperCol] = useState<boolean>(false);

  const dataCollection = datasetMeta?.dataCollections?.find(
    (c) => c._id === dataCollectionId,
  );

  const QUERY_ROUTE = 'query';
  const isQuery =
    location.pathname.includes(RouteName.PROJECT_ITEM) ||
    location.pathname.includes(QUERY_ROUTE);

  let querySchema;

  if (
    entityType === EntityType.AGGREGATION &&
    query?.additionalDetails &&
    query.queryParams.displayOrder
  ) {
    let allSchemaData: Interfaces.FieldOutput[] = [];

    allSchemaData = query.additionalDetails.reduce(
      (acc, detail) => [
        ...acc,
        ...detail.schemaData.filter(
          (field) =>
            !acc.find((f) => f.fieldId === field.fieldId) &&
            field.dataValidation?.dataValidationType !==
              Enums.ValueDataType.FIELD_LOOKUP,
        ),
      ],
      [] as Interfaces.FieldOutput[],
    );

    querySchema = allSchemaData.filter((field) => {
      return query?.queryParams?.displayOrder?.find((group) => {
        return (
          group.alias === field.name ||
          (field as unknown as GroupItem).isMeasure === true ||
          query?.queryParams?.supercolumns?.find((superCol) => {
            return superCol.field === field.name;
          })
        );
      });
    });
  } else if (entityType === EntityType.GRAPH && graph?.additionalDetails) {
    let allSchemaData: Interfaces.FieldOutput[] = [];

    allSchemaData = graph.additionalDetails.reduce(
      (acc, detail) => [
        ...acc,
        ...detail.schemaData.filter(
          (field) =>
            !acc.find((f) => f.fieldId === field.fieldId) &&
            field.dataValidation?.dataValidationType !==
              Enums.ValueDataType.FIELD_LOOKUP,
        ),
      ],
      [] as Interfaces.FieldOutput[],
    );

    querySchema = allSchemaData.filter((field) => {
      const fields = graph?.queryParams?.displayOrder?.length
        ? graph?.queryParams?.displayOrder
        : graph?.queryParams?.fields;

      return fields?.find((group) => {
        return (
          group.alias === field.name ||
          (field as unknown as GroupItem).isMeasure === true ||
          graph?.queryParams?.measures?.find((measure) => {
            return measure.field === field.name;
          }) ||
          graph?.queryParams?.supercolumns?.find((superCol) => {
            return superCol.field === field.name;
          })
        );
      });
    });
  } else if (!entityType) {
    querySchema = dataCollection?.schemaData;
  }

  useEffect(() => {
    if (field) {
      setColumnName(field);
    }
    if (existingSupercol && existingSupercol.supercolumn.field) {
      setColumnName(existingSupercol.supercolumn.field);
    }
  }, [field, existingSupercol]);

  // Check column name doesn't contain special characters
  useEffect(() => {
    if (columnName) {
      setInvalidName(namesRequiresCleansing(columnName));
    }
  }, [columnName]);

  // Set schemaField
  useEffect(() => {
    if (schemaField?.name) {
      return;
    }

    if (isQuery && existingSupercol && querySchema) {
      const sField = querySchema.find((f) => {
        return f.name === existingSupercol.supercolumn.field;
      });

      if (sField) {
        setSchemaField(sField);

        // Check if in use by a super column
        if (
          CheckColumnUse.isUsedBySuperColumn(
            querySchema,
            sField.name,
            sField.fieldId,
          )
        ) {
          setUsedInSuperCol(true);
        }
      }
    } else {
      if (datasetMeta) {
        const dataCollection = datasetMeta.dataCollections?.find(
          (c) => c._id === dataCollectionId,
        );

        if (!dataCollection) {
          return;
        }

        const sField = dataCollection.schemaData?.find((f) => {
          return f.name === field;
        });

        if (sField) {
          setSchemaField(sField);

          // Check if in use by a super column
          if (
            CheckColumnUse.isUsedBySuperColumn(
              dataCollection.schemaData,
              sField.name,
              sField.fieldId,
            )
          ) {
            setUsedInSuperCol(true);
          }
        }
      }
    }
  }, [
    dataCollectionId,
    datasetMeta,
    existingSupercol,
    field,
    graph,
    isQuery,
    query,
    querySchema,
    schemaField,
  ]);

  // Check column name is unique
  useEffect(() => {
    let isUnique = true;

    if (!isQuery) {
      if (schemaField?.name?.toLowerCase() !== columnName?.toLowerCase()) {
        isUnique =
          dataCollection?.schemaData?.every(
            (col) => col.name.toLowerCase() !== columnName.toLowerCase(),
          ) || false;
      }

      setIsUniqueColumnName(isUnique);
    } else {
      if (
        graph &&
        querySchema?.field?.toLowerCase() !== columnName?.toLowerCase() &&
        graph?.queryParams?.fields &&
        !existingSupercol
      ) {
        const fields = graph?.queryParams?.supercolumns
          ? graph.queryParams.fields.concat(graph?.queryParams?.supercolumns)
          : graph.queryParams.fields;

        const allSchemaAliases = fields
          .map((detail) => detail.alias?.toLowerCase())
          .flat();

        isUnique =
          !allSchemaAliases.includes(columnName.toLowerCase()) || false;
      } else if (
        query &&
        query.additionalDetails &&
        querySchema?.field?.toLowerCase() !== columnName?.toLowerCase() &&
        !existingSupercol
      ) {
        isUnique = false;
      }

      setIsUniqueColumnName(isUnique);
    }
  }, [
    columnName,
    dataCollection?.schemaData,
    existingSupercol,
    graph,
    isQuery,
    query,
    querySchema?.field,
    schemaField?.name,
  ]);

  // Set modal to display
  useEffect(() => {
    if (stage) {
      setModalStage(stage);
    }
    setShowModal(true);
  }, [setShowModal, stage]);

  useMemo(() => {
    // Set type if existing for query supercol
    if (existingSupercol?.supercolumn?.supercolumnType) {
      switch (existingSupercol?.supercolumn?.supercolumnType) {
        case Enums.ValueDataType.TEXT_TRANSFORMATION: {
          setSuperColumnType(
            isQuery ? ValueDataType.TEXT_TRANSFORMATION : SuperColumnType.TEXT,
          );
          setTextColumnType(
            existingSupercol.supercolumn.textTransformation?.type,
          );
          break;
        }
        case Enums.ValueDataType.DATE_CONVERSION: {
          setSuperColumnType(
            isQuery ? ValueDataType.DATE_CONVERSION : SuperColumnType.DATE,
          );

          let dateType = schemaField.dataValidation?.dateConversion?.type;

          if (dateType) {
            if (EXTRACT_DATE_TYPES.includes(dateType)) {
              dateType = Enums.DateConversionOperation.EXTRACT_DATE;
            }
            setDateColumnType(dateType);
          }
          setFormatting(schemaField.dataValidation?.formatting);
          break;
        }
        case Enums.ValueDataType.FORMULA:
          setSuperColumnType(
            isQuery ? ValueDataType.FORMULA : SuperColumnType.NUMERIC,
          );
          break;
      }
    }

    // Set type for existing creating structure
    if (schemaField?.dataValidation?.dataValidationType) {
      switch (schemaField.dataValidation.dataValidationType) {
        case Enums.ValueDataType.TEXT_TRANSFORMATION: {
          setSuperColumnType(
            isQuery ? ValueDataType.TEXT_TRANSFORMATION : SuperColumnType.TEXT,
          );
          setTextColumnType(
            schemaField.dataValidation.textTransformation?.type,
          );
          break;
        }
        case Enums.ValueDataType.DATE_CONVERSION: {
          setSuperColumnType(
            isQuery ? ValueDataType.DATE_CONVERSION : SuperColumnType.DATE,
          );

          let dateType = schemaField.dataValidation?.dateConversion?.type;

          if (dateType) {
            if (EXTRACT_DATE_TYPES.includes(dateType)) {
              dateType = Enums.DateConversionOperation.EXTRACT_DATE;
            }
            setDateColumnType(dateType);
          }
          setFormatting(schemaField.dataValidation?.formatting);
          break;
        }
        case Enums.ValueDataType.FORMULA:
          setSuperColumnType(
            isQuery ? ValueDataType.FORMULA : SuperColumnType.NUMERIC,
          );
          break;
        case Enums.ValueDataType.FIELD_LOOKUP:
          setSuperColumnType(SuperColumnType.LOOKUP);
          break;
      }
    }
  }, [existingSupercol, isQuery, schemaField]);

  const headerText = useMemo(() => {
    switch (modalStage) {
      case STAGE_ONE:
        return `${
          field ? 'Update' : existingSupercol ? 'Update' : 'Add'
        } A Super Column`;
      case STAGE_TWO:
        return 'Select Your Super Powers';
      case STAGE_DELETE:
        return 'Delete Super Column';
    }
  }, [existingSupercol, field, modalStage]);

  const processDelete = async () => {
    dispatch(
      showLoading({
        text: `Deleting Super Column...`,
      }),
    );
    if (isQuery) {
      const cloned =
        entityType === EntityType.AGGREGATION
          ? (cloneDeep(query) as QueryOutputWithDetails)
          : (cloneDeep(graph) as GraphOutputWithDetails);

      if (querySchema) {
        cloned.queryParams.supercolumns =
          cloned?.queryParams.supercolumns?.filter(
            (f) => f.field !== columnName,
          ) || [];

        // Remove from sort
        cloned.queryParams.sort = cloned?.queryParams?.sort?.filter(
          (s) => s.field !== columnName,
        );

        // Remove from displayOrder
        if (cloned.queryParams.displayOrder) {
          cloned.queryParams.displayOrder =
            cloned.queryParams?.displayOrder.filter(
              (s) => s.field !== columnName,
            );
        }

        entityType === EntityType.AGGREGATION
          ? dispatch(
              fetchQuerySuccess({
                accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
                entity: cloned as QueryOutputWithDetails,
              }),
            )
          : dispatch(
              fetchGraphSuccess({
                accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
                entity: cloned as GraphOutputWithDetails,
              }),
            );
      }
    } else {
      const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

      const dataCollection = cloned.dataCollections?.find(
        (c) => c._id === dataCollectionId,
      );

      if (dataCollection?.schemaData) {
        dataCollection.schemaData =
          dataCollection?.schemaData.filter((f) => f.name !== columnName) || [];

        await editDatasetMeta(cloned);
      }
    }

    dispatch(hideLoading());
    dispatch(hideModal());
  };

  const toggleAccordionIndex = (index: number) => {
    if (accordionOpenIndexes.includes(index)) {
      setAccordionOpenIndexes(
        accordionOpenIndexes.filter((aoi) => aoi !== index),
      );
    } else {
      setAccordionOpenIndexes([...accordionOpenIndexes, index]);
    }
  };

  const processAction = async () => {
    const combinedDataValidation = {
      ...dataValidation,
      formatting: formatting,
      constraints: schemaField?.dataValidation?.constraints,
    };
    // Update Query
    if (isQuery) {
      const cloned: QueryOutputWithDetails =
        entityType === EntityType.AGGREGATION
          ? cloneDeep(query)
          : cloneDeep(graph);

      if (querySchema && dataValidation) {
        dispatch(
          showLoading({
            text: `Saving Super Column...`,
          }),
        );

        const existingField = cloned?.queryParams?.supercolumns?.find(
          (f) => f.alias === columnName,
        );

        const supercolSubmission = {
          supercolumnType: superColumnType as
            | ValueDataType.DATE_CONVERSION
            | ValueDataType.FORMULA
            | ValueDataType.TEXT_TRANSFORMATION,
          alias: columnName.trim(),
          field: columnName.trim() || (schemaField?.name?.trim() as string),
          datasetMetaId:
            (cloned?.queryParams?.datasetMetaId as string) ||
            (cloned?.queryParams?.dataModelLinkIds?.[0] as string),
          ...combinedDataValidation,
        };

        // Update Existing
        if (existingField && cloned.queryParams.supercolumns) {
          const existingFieldIndex =
            cloned?.queryParams?.supercolumns?.findIndex(
              (f) => f.alias === columnName,
            );

          cloned.queryParams.supercolumns[existingFieldIndex] =
            supercolSubmission;
        } else {
          cloned?.queryParams?.supercolumns?.push(
            supercolSubmission as SupercolumnField,
          );
          cloned?.queryParams?.displayOrder?.push(
            supercolSubmission as SupercolumnField,
          );
        }

        try {
          if (isQuery) {
            if (entityType === EntityType.GRAPH) {
              dispatch(
                fetchGraphSuccess({
                  accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
                  entity: cloned as unknown as GraphOutputWithDetails,
                }),
              );
            } else {
              dispatch(
                fetchQuerySuccess({
                  accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
                  entity: cloned,
                }),
              );
            }
          } else {
            await editQuery(cloned);
          }
        } finally {
          dispatch(hideModal());
          dispatch(hideLoading());
        }
      }
    } else {
      const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

      const dataCollection = cloned.dataCollections?.find(
        (c) => c._id === dataCollectionId,
      );

      if (dataCollection?.schemaData && dataValidation) {
        dispatch(
          showLoading({
            text: `Saving Super Column...`,
          }),
        );

        const existingField = dataCollection.schemaData.find((f) =>
          schemaField?.fieldId
            ? f.fieldId === schemaField.fieldId
            : f.name === columnName,
        );

        if (existingField) {
          // Updating
          existingField.name = columnName.trim();
          existingField.dataValidation =
            combinedDataValidation as Interfaces.DataValidation;
        } else {
          // Creating
          const createdField: Interfaces.Field = {
            fieldId: schemaField?.fieldId,
            name: columnName,
            dataValidation: combinedDataValidation as Interfaces.DataValidation,
          };

          dataCollection.schemaData.push(
            createdField as Interfaces.FieldOutput,
          );
        }

        try {
          await editDatasetMeta(cloned);
        } finally {
          dispatch(hideModal());
          dispatch(hideLoading());
        }
      }
    }
  };

  if (modalStage === STAGE_DELETE) {
    return (
      <SC.Wrapper>
        <SC.HeaderContainer>
          <SC.Header>{headerText}</SC.Header>
        </SC.HeaderContainer>
        <DeleteConfirmation
          entityType={EntityType.SUPER_COLUMN}
          entityName={columnName}
          fullScreenView={true}
          cancelAction={() => setModalStage(STAGE_ONE)}
          deleteAction={processDelete}
        />
      </SC.Wrapper>
    );
  }

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>{headerText}</SC.Header>
        {!field && !existingSupercol && (
          <FeatureButton
            action={() => dispatch(hideModal())}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.sea}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                color={defaultTheme.colors.system.white}
                size={'lg'}
              />
            }
          />
        )}

        {modalStage !== STAGE_DELETE && (field || existingSupercol) && (
          <Popup
            disabled={!usedInSuperCol}
            content={
              'This column cannot be deleted as it is currently being used in another super column in this dataset.'
            }
            position="left center"
            style={DefaultPopupStyles}
            hideOnScroll={true}
            trigger={
              <div>
                <FeatureButton
                  isDisabled={usedInSuperCol}
                  action={() => setModalStage(STAGE_DELETE)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </div>
            }
          />
        )}
      </SC.HeaderContainer>

      <SC.ContentContainer>
        {modalStage === STAGE_ONE && (
          <SC.Content>
            <SC.BodyText>
              Super Columns allow you to enhance your dataset with dynamic
              formulas, transformations and even values from other datasets.
            </SC.BodyText>
            <SC.BodyText>
              First things first, what you would you like to name your column.
              This must be unique for the dataset.
            </SC.BodyText>

            <StyledH5>Super Column Name</StyledH5>

            <StyledInput
              placeholder={'Enter a column name'}
              value={columnName}
              onChange={(e, data) => setColumnName(data.value)}
            />

            {!isUniqueColumnName && columnName && (
              <SC.ErrorText>Column names must be unique</SC.ErrorText>
            )}
            {invalidName && (
              <SC.ErrorText>
                Column name contains invalid characters
              </SC.ErrorText>
            )}
          </SC.Content>
        )}

        {modalStage === STAGE_TWO && (
          <SC.Content>
            <SC.BodyText>
              What kind of super column would you like to use?
            </SC.BodyText>
            <SC.ColumnTypeWrapper>
              <FeatureButton
                isActive={
                  superColumnType === SuperColumnType.NUMERIC ||
                  superColumnType === ValueDataType.FORMULA
                }
                action={() => {
                  setSuperColumnValid(false);
                  setSuperColumnType(
                    isQuery ? ValueDataType.FORMULA : SuperColumnType.NUMERIC,
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Numeric'}
              />

              <FeatureButton
                isActive={
                  superColumnType === SuperColumnType.TEXT ||
                  superColumnType === ValueDataType.TEXT_TRANSFORMATION
                }
                action={() => {
                  setSuperColumnValid(false);
                  setSuperColumnType(
                    isQuery
                      ? ValueDataType.TEXT_TRANSFORMATION
                      : SuperColumnType.TEXT,
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Text'}
              />

              <FeatureButton
                isActive={
                  superColumnType === SuperColumnType.DATE ||
                  superColumnType === ValueDataType.DATE_CONVERSION
                }
                action={() => {
                  setSuperColumnValid(false);
                  setSuperColumnType(
                    isQuery
                      ? ValueDataType.DATE_CONVERSION
                      : SuperColumnType.DATE,
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Date'}
              />

              {!isQuery && (
                <FeatureButton
                  isActive={superColumnType === SuperColumnType.LOOKUP}
                  action={() => {
                    setSuperColumnValid(false);
                    setSuperColumnType(SuperColumnType.LOOKUP);
                  }}
                  size={FeatureButtonSize.WIDE}
                  color={themeContext.colors.general.blue}
                  text={'Lookup'}
                />
              )}
            </SC.ColumnTypeWrapper>
            {(superColumnType === SuperColumnType.TEXT ||
              superColumnType === ValueDataType.TEXT_TRANSFORMATION) && (
              <SC.SubColumnTypeWrapper>
                <SC.BodyText>
                  Text super columns let you modify text values allowing you to
                  transform, evaluate and even join values together to create
                  new data points.
                </SC.BodyText>
                <SC.BodyText>
                  What kind of text super column would you like to create?
                </SC.BodyText>

                <StyledDropdownUltraWide
                  selectOnBlur={false}
                  placeholder={'Select a text super column type'}
                  options={textColumnTypeOptions(Enums.TextTransformOperation)}
                  selection
                  value={textColumnType}
                  onChange={(e, data) => setTextColumnType(data.value)}
                  style={{ marginTop: 0 }}
                />

                {textColumnType && (
                  <AddTextSuperCol
                    textColumnType={textColumnType}
                    schemaField={schemaField}
                    schema={querySchema || []}
                    setDataValidation={setDataValidation}
                    setSuperColumnValid={setSuperColumnValid}
                  />
                )}

                {!isQuery && (
                  <StyledAccordion
                    fluid
                    style={{ marginTop: themeContext.margin.xxlarge }}
                  >
                    <ValidationOptions
                      active={accordionOpenIndexes.includes(1)}
                      activeToggle={() => toggleAccordionIndex(1)}
                      schemaField={schemaField}
                      superColumnValueType={Enums.ValueDataType.TEXT}
                      onChange={(updatedSchemaField) => {
                        setSchemaField(updatedSchemaField);
                      }}
                    />
                  </StyledAccordion>
                )}
              </SC.SubColumnTypeWrapper>
            )}
            {(superColumnType === SuperColumnType.DATE ||
              superColumnType === ValueDataType.DATE_CONVERSION) && (
              <SC.SubColumnTypeWrapper>
                <SC.BodyText>
                  Date super columns allow you to transform and evaluate date
                  values easily.
                </SC.BodyText>
                <SC.BodyText>
                  What kind of date super column would you like to create?
                </SC.BodyText>

                <StyledDropdownUltraWide
                  selectOnBlur={false}
                  placeholder={'Select a date super column type'}
                  options={dateColumnTypeOptions(Enums.DateConversionOperation)}
                  selection
                  value={dateColumnType}
                  onChange={(e, data) => setDateColumnType(data.value)}
                  style={{ marginTop: 0 }}
                />

                {dateColumnType && (
                  <AddDateSuperCol
                    dateColumnType={dateColumnType}
                    schemaField={schemaField}
                    schema={dataCollection?.schemaData || querySchema || []}
                    setSchemaField={setSchemaField}
                    setDataValidation={setDataValidation}
                    setSuperColumnValid={setSuperColumnValid}
                  />
                )}
              </SC.SubColumnTypeWrapper>
            )}
            {superColumnType === SuperColumnType.LOOKUP && (
              <SC.SubColumnTypeWrapper>
                <Lookup
                  field={schemaField}
                  schema={dataCollection?.schemaData || querySchema || []}
                  setDataValidation={setDataValidation}
                  setSuperColumnValid={setSuperColumnValid}
                />
                {!isQuery && (
                  <StyledAccordion
                    fluid
                    style={{ marginTop: themeContext.margin.standard }}
                  >
                    <ValidationOptions
                      active={accordionOpenIndexes.includes(1)}
                      activeToggle={() => toggleAccordionIndex(1)}
                      schemaField={schemaField}
                      superColumnValueType={Enums.ValueDataType.TEXT}
                      onChange={(updatedSchemaField) => {
                        setSchemaField(updatedSchemaField);
                      }}
                    />
                  </StyledAccordion>
                )}
              </SC.SubColumnTypeWrapper>
            )}

            {(superColumnType === SuperColumnType.NUMERIC ||
              superColumnType === ValueDataType.FORMULA) && (
              <SC.SubColumnTypeWrapper>
                <SC.BodyText>
                  Numeric super columns allow you to create dynamic calculations
                  easily.
                </SC.BodyText>
                <SC.BodyText>
                  Use the buttons below to quickly build custom logic with no
                  formulas required.
                </SC.BodyText>

                <FormulaCalc
                  field={schemaField}
                  schema={dataCollection?.schemaData || querySchema || []}
                  columnName={columnName}
                  setDataValidation={setDataValidation}
                  setSuperColumnValid={setSuperColumnValid}
                  existingFormula={
                    existingSupercol &&
                    existingSupercol.supercolumn &&
                    existingSupercol.supercolumn.formula
                      ? existingSupercol.supercolumn.formula
                      : undefined
                  }
                />

                <StyledAccordion
                  fluid
                  style={{ marginTop: themeContext.margin.standard }}
                >
                  <FormattingOptions
                    active={accordionOpenIndexes.includes(0)}
                    activeToggle={() => toggleAccordionIndex(0)}
                    schemaField={schemaField}
                    superColumnType={superColumnType as SuperColumnType}
                    onChange={(updatedSchemaField) => {
                      setSchemaField(updatedSchemaField);
                      setFormatting(
                        updatedSchemaField.dataValidation?.formatting,
                      );
                    }}
                    existingFormatting={
                      existingSupercol?.supercolumn?.formatting
                    }
                  />
                  {!isQuery && (
                    <ValidationOptions
                      active={accordionOpenIndexes.includes(1)}
                      activeToggle={() => toggleAccordionIndex(1)}
                      schemaField={schemaField}
                      superColumnValueType={Enums.ValueDataType.NUMBER}
                      onChange={(updatedSchemaField) => {
                        setSchemaField(updatedSchemaField);
                      }}
                    />
                  )}
                </StyledAccordion>
              </SC.SubColumnTypeWrapper>
            )}
          </SC.Content>
        )}
      </SC.ContentContainer>

      <SC.ActionButtonWrapper multipleButtons={true}>
        {modalStage === STAGE_ONE && (
          <>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={
                columnName.length === 0 || !isUniqueColumnName || invalidName
              }
              action={() => setModalStage(STAGE_TWO)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue'}
            />
          </>
        )}

        {modalStage === STAGE_TWO && (
          <>
            <FeatureButton
              action={() => setModalStage(STAGE_ONE)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to name'}
            />
            <FeatureButton
              isDisabled={!superColumnValid}
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={`Save Super Column`}
            />
          </>
        )}
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default EnhancementAddColumnModal;
