import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { MIN_COL_WIDTH } from '../../const/DataSample';

const CELL_HORI_PADDING = 25;
const DURATION_FORMAT = 'hh:mm:ss';
const DURATION_UNITS = ['hours', 'minutes', 'seconds'];

export const getColumnWidth = (
  sampleRows: Record<string, unknown>[],
  accessor: string,
  headerText: string,
  minWidth?: number,
): number => {
  let longestWidth = 0;

  sampleRows.forEach((row) => {
    const cellLength = Math.max(
      (row[accessor] as { value: string })?.value?.length || 0,
      Math.min(headerText.length, 50),
    );

    const magicSpacing = 13;
    const generated = cellLength * magicSpacing + CELL_HORI_PADDING * 2;

    if (generated > longestWidth) {
      longestWidth = generated;
    }
  });

  return longestWidth > (minWidth || MIN_COL_WIDTH)
    ? longestWidth
    : minWidth || MIN_COL_WIDTH;
};

export const reorder = (
  currentIndex: number,
  newIndex: number,
  columnOrder: number[],
  setColumnOrder: (newOrder: string[]) => void,
  reorderAction?: (newOrder: string[]) => void,
) => {
  const dragRecord = columnOrder[currentIndex];

  const newOrder = cloneDeep(columnOrder);
  newOrder.splice(currentIndex, 1);
  newOrder.splice(newIndex, 0, dragRecord);

  setColumnOrder(newOrder);

  if (reorderAction) {
    reorderAction(newOrder);
  }
};

export const getDuration = (start: Date, end: Date) => {
  const startDateTime = DateTime.fromISO(start);
  const endDateTime = DateTime.fromISO(end);
  const diff = endDateTime.diff(startDateTime, DURATION_UNITS);

  return diff.toFormat(DURATION_FORMAT);
};
