import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

const FILTER_STAGE_BUTTON_MARGIN = '78px';
const FILTER_STAGE_BUTTON_OFFSET = '-4px';
const FILTER_ACTION_BUTTONS_WIDTH = '80px';
export const DATE_ADVANCED_OPTIONS_WIDTH = '120px';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const FilterWrapper = styled.div<{
  applyLeftMargin?: boolean;
}>`
  display: flex;
  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.standard}`};
  margin-left: ${({ applyLeftMargin }) => (applyLeftMargin ? '45px' : 0)};
`;

export const FilterStage = styled.div<{
  applyMargin?: boolean;
  buttonContainer?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: ${({ theme }) => theme.margin.standard};
  margin-bottom: ${({ applyMargin }) =>
    applyMargin ? FILTER_STAGE_BUTTON_MARGIN : 0};
  margin-top: ${({ buttonContainer }) =>
    buttonContainer ? FILTER_STAGE_BUTTON_OFFSET : 0};
  justify-content: ${({ buttonContainer }) =>
    buttonContainer ? 'flex-end' : 'flex-start'};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const FilterStageActions = styled.div<{
  applyMargin?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: ${FILTER_STAGE_BUTTON_OFFSET};
  margin-bottom: ${({ applyMargin }) =>
    applyMargin ? FILTER_STAGE_BUTTON_MARGIN : 0};
  justify-content: 'flex-end';
  width: ${FILTER_ACTION_BUTTONS_WIDTH};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const BetweenWrapper = styled.div`
  display: flex;

  > div {
    width: 120px;

    &:first-of-type {
      margin-right: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const DateAdvancedWrapper = styled.div`
  display: flex;
  text-align: center;
  margin-top: ${({ theme }) => theme.margin.standard};

  > div {
    width: ${DATE_ADVANCED_OPTIONS_WIDTH};

    &:first-of-type {
      margin-right: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const inputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
