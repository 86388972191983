import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useList from '../../../hooks/list/UseList';
import usePermission from '../../../hooks/permission/UsePermission';
import { ListValueWithUser } from '../../../interfaces/ListValue';
import { StyledBodySubHeader, StyledText } from '../../../main/theme';
import { fetchListSuccess } from '../../../store/list';
import { hideModal } from '../../../store/modal';
import ColorPicker from '../../ColorPicker/ColorPicker';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';
import UserListItemSection from './UserListItemSection';

export interface ListAddItemModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingListItem?: {
    index: number;
    listItem: Interfaces.ListValue;
  };
  listType: Enums.ListType;
}

const VALUES_FIELD = 'values';

const ListModal: FC<ListAddItemModalProps> = ({
  setShowModal,
  existingListItem,
  listType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { list, listAccessLevel } = useList();
  const { isProTier } = usePermission();
  const [editingItemIndex, setEditingItemIndex] = useState<number>();

  const [newListItem, setNewListItem] = useState<
    Interfaces.ListValue | ListValueWithUser
  >({
    value: '',
  });

  const handleColorChange = (color?: string) => {
    setNewListItem({
      ...newListItem,
      color,
    } as Interfaces.ListValue);
  };

  const handleValueChange = (value: string) => {
    setNewListItem({ ...newListItem, value: value } as Interfaces.ListValue);
  };

  const processAction = async () => {
    if (newListItem) {
      const cloned = cloneDeep(list);

      if (existingListItem) {
        cloned.values[editingItemIndex] = newListItem;

        dispatch(
          fetchListSuccess({
            entity: cloned,
            accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
          }),
        );

        dispatch(hideModal());
      } else {
        const isDuplicate: boolean = cloned.values.some(
          (e) => e.value === newListItem.value,
        );

        if (!isDuplicate) {
          cloned[VALUES_FIELD] = list?.values
            ? [...list.values, newListItem]
            : [newListItem];

          dispatch(
            fetchListSuccess({
              entity: cloned,
              accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
            }),
          );
        }

        dispatch(hideModal());
      }
    }
  };

  useEffect(() => {
    if (existingListItem) {
      setNewListItem(existingListItem.listItem);
      setEditingItemIndex(existingListItem.index);
    }
  }, [existingListItem]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderWrapper>
        <SC.Header>
          {existingListItem ? 'Update ' : 'Add '}
          {listType === Enums.ListType.STANDARD
            ? 'List Item'
            : isProTier
            ? 'User Or Team'
            : 'User'}
        </SC.Header>
      </SC.HeaderWrapper>

      {listType === Enums.ListType.STANDARD && (
        <>
          <StyledText>Add an option to your list.</StyledText>
          <StyledText>
            Optionally, select a colour to be used to highlight the value when
            it matches this list item.
          </StyledText>

          <StyledBodySubHeader>Value</StyledBodySubHeader>
          <SC.ValueInput
            onChange={(e, data) => handleValueChange(data.value)}
            value={newListItem ? newListItem.value : ''}
            placeholder={'Enter list option value'}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Colour</StyledBodySubHeader>
          <ColorPicker
            onChange={(color) => handleColorChange(color)}
            selectedColor={newListItem ? newListItem.color : undefined}
          />
        </>
      )}

      {listType === Enums.ListType.USER && (
        <UserListItemSection
          setListItem={setNewListItem}
          existingListItem={existingListItem}
        />
      )}

      <SC.ActionButtonWrapper>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={!newListItem?.value}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={
            listType === Enums.ListType.STANDARD ? 'Save List Item' : 'Save'
          }
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default ListModal;
