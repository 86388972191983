export enum RouteName {
  CHART_ITEM = '/dashboard',
  CHARTS = '/dashboards',
  CMS = '/cms',
  DATASET_ITEM = '/dataset',
  DATASETS = '/datasets',
  EVENTS = '/events',
  EVENT_ITEM = '/event',
  EXPORT = '/export',
  GRAPH_ITEM = '/graph',
  INTEGRATION_CENTRE = '/integration-centre',
  INTEGRATION_ITEM = '/integration',
  LISTS = '/lists',
  LIST_ITEM = '/list',
  NOT_AUTHORISED = '/not-authorised',
  NOTIFICATION_REDIRECT = '/notification',
  NOTIFICATIONS = '/notifications',
  NOTIFICATION_CENTRE = '/notification-centre',
  OAUTH_CALLBACK = '/oauth-callback',
  ORGANISATION_ITEM = '/organisation',
  PIPELINE_ITEM = '/pipeline',
  PORTAL = '/portal',
  PROJECT_ITEM = '/project',
  PROJECTS = '/projects',
  QUICK_ACCESS = '/quick-access',
  SIGNUP = '/signup',
  TEAM_ITEM = '/team',
  TEAMS = '/teams',
  USAGE = '/usage',
  USER_ITEM = '/user',
  USERS = '/users',
  WELCOME = '/',
  APIS = '/apis',
  FORMS = '/forms',
}
