import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums';
import useChart from '../../../../hooks/chart/UseChart';
import useCMS from '../../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../../hooks/filter/UseFilter';
import useList from '../../../../hooks/list/UseList';
import useProject from '../../../../hooks/project/UseProject';
import useQuery from '../../../../hooks/query/UseQuery';
import { SearchQuery } from '../../../../interfaces/Search';
import {
  defaultTheme,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledDropdownUltraWide,
  StyledDropdownWide,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { ChartItemOutput } from '../../../../store/charts';
import { hideModal } from '../../../../store/modal';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import { QueryItemOutput } from '../../../../store/queries';
import { RootState } from '../../../../store/rootReducer';
import WysiwygEditor from '../../../ChartBuilder/WysiwygEditor';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import FilterBuilder from '../../filter/FilterBuilder';
import * as SC from './styled';

const SEARCH_FIELD = 'name';
const SEARCH_TYPING_TIMEOUT = 1000;
const DROPDOWN_OPTION_VALUE_DIVIDER = '***';
const EMAIL_FIELD = 'email';
const ID_FIELD = '_id';

const COMMENTS_ENABLED_OPTIONS = [
  {
    key: 'yes',
    value: true,
    text: 'Yes',
  },
  {
    key: 'no',
    value: false,
    text: 'No',
  },
];

export interface CMSAddEntityModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  groupName: string;
  id?: string;
}

interface FieldItemOutput extends Interfaces.FieldOutput {
  datasetMetaName: string;
  datasetMetaId: string;
  alias?: string;
}

interface WysiwygContent {
  id?: string;
  type: string;
  data: unknown;
  tunes?: unknown;
}

const BASE_FILTER = {
  operator: Enums.AggregationFilterOperator.AND,
  value: [],
};

const CMSAddEntityModal: FC<CMSAddEntityModalProps> = ({
  setShowModal,
  groupName,
  id,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { project } = useProject();
  const { getDatasetMetasDirect } = useDatasetMeta();
  const { getQueriesDirect, listQueryDatasetMetas } = useQuery();
  const { getChartsDirect } = useChart();
  const { getLists } = useList();
  const { cms, cmsConfig } = useCMS();

  const activeGroup = cmsConfig?.groups.find((g) => g.groupName === groupName);

  const datasetMetasStateEnts = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data;
  const queriesStateEnts = useSelector(
    (state: RootState) => state.queries,
  )?.data;
  const chartsStateEnts = useSelector((state: RootState) => state.charts)?.data;
  const lists = useSelector((state: RootState) => state.lists)?.data;

  const [groupItem, setGroupItem] =
    useState<Interfaces.CMSConfigurationDataGroupItem>({});

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [description, setDescription] = useState<boolean>(false);

  const [datasetMetas, setDatasetMetas] = useState<DatasetMetaItemOutput[]>(
    datasetMetasStateEnts?.data || [],
  );
  const [queries, setQueries] = useState<QueryItemOutput[]>(
    queriesStateEnts?.data || [],
  );
  const [charts, setCharts] = useState<ChartItemOutput[]>(
    chartsStateEnts?.data || [],
  );

  const [filteredOptions, setFilteredOptions] = useState<DropdownItemProps[]>(
    [],
  );
  const [entityOptions, setEntityOptions] = useState<
    Map<string, DropdownItemProps>
  >(new Map());

  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const [advancedOpen, setAdvancedOpen] = useState<boolean>(false);
  const [dataFiltersOpen, setDataFiltersOpen] = useState<boolean>(false);
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [wysiwygContent, setWysiwygContent] = useState<WysiwygContent[]>();

  const [loadingUserLockColumns, setLoadingUserLockColumns] =
    useState<boolean>(false);
  const [userLockColumns, setUserLockColumns] = useState<DropdownItemProps[]>(
    [],
  );
  const [selectedUserLockColumn, setSelectedUserLockColumn] =
    useState<string>();

  const [dataFilterColumns, setDataFilterColumns] = useState<
    DropdownItemProps[]
  >([]);

  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true);

  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: undefined,
  });

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: undefined,
  });

  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();

  const [filter, setFilter] = useState<DynamicConditionalField>(BASE_FILTER);
  const [fieldItems, setFieldItems] = useState<FieldItemOutput[]>([]);

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (
        value !== searchQuery.query &&
        (!value.length || (value.length && value.trim().length))
      ) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        return setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  // Confirm filters are complete
  useEffect(() => {
    if (!(filter?.value as DynamicConditionalField[]).length) {
      return setCanProgress(true);
    }
    if (
      filter?.operator &&
      (filter?.value as DynamicConditionalField[]).length
    ) {
      if (
        (filter.value as DynamicConditionalField[]).every(
          (c) =>
            (c.operator || c.fields) &&
            (Array.isArray(c.value) ? c.value.length : c.value),
        )
      ) {
        return setCanProgress(true);
      }
    }

    setCanProgress(false);
  }, [filter.operator, filter.value]);

  // Filter entity dropdown options
  useEffect(() => {
    const options = Array.from(entityOptions, ([, opt]) => opt);
    if (options.length && activeGroup) {
      const filteredOpts = options.filter((d) => {
        return (
          d.value === id ||
          !activeGroup.items.find(
            (gi) =>
              (gi.datasetMetaId || gi.aggregationId || gi.chartId) === d.value,
          )
        );
      });

      if (filteredOpts) {
        setFilteredOptions(filteredOpts);
      }
    }
  }, [activeGroup, entityOptions, id]);

  // Map entities to options - add to array as users may search for more options
  useEffect(() => {
    [...datasetMetas, ...queries, ...charts].map((d) => {
      const ent:
        | Interfaces.DatasetMetaOutput
        | Interfaces.QueryOutput
        | Interfaces.ChartOutput = d.entity;

      setEntityOptions((entOpts) => {
        const cloned = cloneDeep(entOpts);

        cloned.set(ent._id, {
          key: ent._id,
          value: ent._id,
          text: ent.name,
          description: Object.prototype.hasOwnProperty.call(ent, 'graphs')
            ? 'Dashboard'
            : Object.prototype.hasOwnProperty.call(ent, 'queryParams')
            ? 'Query'
            : 'Dataset',
        });

        return cloned;
      });
    });
  }, [datasetMetas, queries, charts]);

  // Create user lock column options
  useEffect(() => {
    if (groupItem.datasetMetaId) {
      const dsm = datasetMetas.find(
        (d) => d.entity._id === groupItem.datasetMetaId,
      )?.entity;
      const activeSchema = dsm?.dataCollections.find(
        (c) => c._id === dsm.activeDataCollection,
      )?.schemaData;

      setEntitySchema(activeSchema);

      if (activeSchema) {
        // Set User Lock columns
        const userLockCols = activeSchema
          .filter((field) => {
            const hasUserList =
              field.dataValidation?.constraints?.listValues &&
              lists.data.find(
                (l) =>
                  l.entity._id ===
                  field.dataValidation?.constraints?.listValues,
              )?.entity.listType === Enums.ListType.USER;

            return (
              field.dataValidation?.dataValidationType ===
                Enums.ValueDataType.EMAIL || hasUserList
            );
          })
          .map((field, i) => ({
            key: `${field.name}-${i}`,
            value: `${field.fieldId}${DROPDOWN_OPTION_VALUE_DIVIDER}${dsm?._id}${DROPDOWN_OPTION_VALUE_DIVIDER}${field.dataValidation?.dataValidationType}`,
            text: field.name,
            description: startCase(
              field.dataValidation?.dataValidationType.toLowerCase(),
            ),
          }));
        setUserLockColumns(userLockCols);

        const fieldItems: FieldItemOutput[] = [];

        activeSchema
          .filter(
            (o) =>
              !userLockCols
                .map((c) => c.value.split(DROPDOWN_OPTION_VALUE_DIVIDER)[0])
                .includes(o.fieldId),
          )
          .map((t) => {
            fieldItems.push({
              ...activeSchema?.find(
                (f) => f.fieldId === t.fieldId || f.name === t.name,
              ),
              datasetMetaName: dsm.name,
              datasetMetaId: dsm._id,
            } as FieldItemOutput);
            setFieldItems(fieldItems);
          });

        const dataFilterCols = activeSchema
          .filter(
            (o) =>
              !userLockCols
                .map((c) => c.value.split(DROPDOWN_OPTION_VALUE_DIVIDER)[0])
                .includes(o.fieldId),
          )
          .map((f, i) => ({
            key: `field-${f.name}-${i}`,
            value: `${f.fieldId || f.name}***${dsm._id}***${f.name}`,
            text: f.name,
            description: startCase(
              f.dataValidation?.dataValidationType.toLowerCase(),
            ),
          }));
        setDataFilterColumns(dataFilterCols);
      }
    }

    if (groupItem.aggregationId) {
      const query = queries.find(
        (q) => q.entity._id === groupItem.aggregationId,
      )?.entity;

      if (query) {
        const dsmIds = listQueryDatasetMetas(query);

        const listCols: DropdownItemProps[] = [];

        dsmIds.map((id) => {
          const dsm = datasetMetas.find((d) => d.entity._id === id)?.entity;
          const activeSchema = dsm?.dataCollections.find(
            (c) => c._id === dsm.activeDataCollection,
          )?.schemaData;

          if (activeSchema) {
            activeSchema
              .filter((field) => {
                const hasUserList =
                  field.dataValidation?.constraints?.listValues &&
                  lists.data.find(
                    (l) =>
                      l.entity._id ===
                      field.dataValidation?.constraints?.listValues,
                  )?.entity.listType === Enums.ListType.USER;

                return (
                  field.dataValidation?.dataValidationType ===
                    Enums.ValueDataType.EMAIL || hasUserList
                );
              })
              .map((field, i) => {
                if (!listCols.find((l) => l.value === field.fieldId)) {
                  listCols.push({
                    key: `${field.name}-${i}`,
                    value: `${field.fieldId}${DROPDOWN_OPTION_VALUE_DIVIDER}${dsm?._id}${DROPDOWN_OPTION_VALUE_DIVIDER}${field.dataValidation?.dataValidationType}`,
                    text: field.name,
                    description: startCase(
                      field.dataValidation?.dataValidationType.toLowerCase(),
                    ),
                  });
                }
              });

            setUserLockColumns(listCols);

            const fieldItems: FieldItemOutput[] = [];

            activeSchema
              .filter((o) => !listCols.map((c) => c.text).includes(o.name))
              .map((t) => {
                fieldItems.push({
                  ...activeSchema?.find(
                    (f) => f.fieldId === t.fieldId || f.name === t.name,
                  ),
                  datasetMetaName: dsm.name,
                  datasetMetaId: dsm._id,
                } as FieldItemOutput);
                setFieldItems(fieldItems);
              });

            const dataFilterCols = activeSchema
              .filter((o) => !listCols.map((c) => c.text).includes(o.name))
              .map((f, i) => ({
                key: `field-${f.name}-${i}`,
                value: `${f.fieldId || f.name}***${dsm._id}***${f.name}`,
                text: f.name,
                description: startCase(
                  f.dataValidation?.dataValidationType.toLowerCase(),
                ),
              }));
            setDataFilterColumns(dataFilterCols);
          }
        });
      }
    }
  }, [
    datasetMetas,
    groupItem.aggregationId,
    groupItem.datasetMetaId,
    listQueryDatasetMetas,
    lists?.data,
    queries,
  ]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // If editing existing, set groupItem, filter and comments
  useEffect(() => {
    if (id && cms?._id) {
      const group = cmsConfig?.groups.find((g) => g.groupName === groupName);

      if (group) {
        const item: Interfaces.CMSConfigurationDataGroupItem | undefined =
          group.items.find(
            (item) =>
              id === item.datasetMetaId ||
              id === item.aggregationId ||
              id === item.chartId,
          );

        if (item) {
          setGroupItem(item);
          setCommentsEnabled(!!item.options?.commentsEnabled);

          // Set user lock column if editing existing
          if (
            item.options?.preFilters &&
            item.options?.preFilters?.value?.length
          ) {
            const cond = item.options?.preFilters.value.find(
              (o) =>
                (o.value as Interfaces.DynamicConditionalValue).type ===
                Enums.DynamicConditionalType.USER,
            ) as Interfaces.DynamicConditional;

            if (cond) {
              const val = `${cond.field}${DROPDOWN_OPTION_VALUE_DIVIDER}${
                cond.datasetMetaId
              }${DROPDOWN_OPTION_VALUE_DIVIDER}${
                (cond?.value as Interfaces.DynamicConditionalValue).type ===
                Enums.DynamicConditionalType.USER
                  ? Enums.ValueDataType.TEXT
                  : Enums.ValueDataType.EMAIL
              }`;

              setSelectedUserLockColumn(val);

              setFilter(item.options?.preFilters?.value[1] || BASE_FILTER);
            } else {
              setFilter(item.options?.preFilters?.value[0] || BASE_FILTER);
            }
          }
        }
      }
    }
  }, [cms?._id, cmsConfig?.groups, groupName, id]);

  // Fetch entities
  useEffect(() => {
    if (searchQuery.query && project?._id) {
      (async () => {
        setLoadingEntities(true);

        const [fetchedDsms, fetchedQueries, fetchedCharts] = await Promise.all([
          getDatasetMetasDirect(
            { _id: { $in: project.datasetMetaId } },
            searchQuery?.query?.length ? searchQuery : undefined,
          ),
          getQueriesDirect(
            {
              projectOriginId: project._id,
              limit: 200,
            },

            // TODO in BE
            // searchQuery?.query?.length ? searchQuery : undefined,
          ),
          getChartsDirect({
            _id: {
              $in: [...project.queries],
            },
          }),
        ]);

        setDatasetMetas((dsms) => {
          return [
            ...dsms,
            ...fetchedDsms.filter(
              (d) => !dsms.find((dsm) => dsm.entity._id === d.entity._id),
            ),
          ];
        });

        setQueries((qs) => {
          return [
            ...qs,
            ...fetchedQueries.filter(
              (q) => !qs.find((query) => query.entity._id === q.entity._id),
            ),
          ];
        });

        setCharts((chs) => {
          return [
            ...chs,
            ...fetchedCharts.filter(
              (c) => !chs.find((chart) => chart.entity._id === c.entity._id),
            ),
          ];
        });

        setLoadingEntities(false);
      })();
    }
  }, [
    getChartsDirect,
    getDatasetMetasDirect,
    getQueriesDirect,
    project?._id,
    project?.datasetMetaId,
    project?.queries,
    searchQuery,
  ]);

  // Fetch lists
  useEffect(() => {
    if (groupItem.datasetMetaId) {
      (async () => {
        setLoadingUserLockColumns(true);
        const dsm = datasetMetas.find(
          (d) => d.entity._id === groupItem.datasetMetaId,
        )?.entity;
        const activeSchema = dsm?.dataCollections.find(
          (c) => c._id === dsm.activeDataCollection,
        )?.schemaData;

        const listIds = activeSchema?.reduce((acc: string[], field) => {
          if (
            field.dataValidation?.constraints?.listValues &&
            typeof field.dataValidation?.constraints?.listValues === 'string' &&
            !acc.includes(field.dataValidation?.constraints?.listValues)
          ) {
            return acc.concat([field.dataValidation.constraints.listValues]);
          }
          return acc;
        }, []);

        if (listIds?.length) {
          await getLists({ _id: { $in: listIds } });
        }
        setLoadingUserLockColumns(false);
      })();
    }

    if (groupItem.aggregationId) {
      (async () => {
        setLoadingUserLockColumns(true);
        const query = queries.find(
          (q) => q.entity._id === groupItem.aggregationId,
        )?.entity;

        if (query) {
          const dsmIds = listQueryDatasetMetas(query);

          let listIds: string[] = [];

          dsmIds.map((id) => {
            const dsm = datasetMetas.find((d) => d.entity._id === id)?.entity;
            const activeSchema = dsm?.dataCollections.find(
              (c) => c._id === dsm.activeDataCollection,
            )?.schemaData;

            if (activeSchema) {
              listIds = listIds.concat(
                activeSchema?.reduce((acc: string[], field) => {
                  if (
                    field.dataValidation?.constraints?.listValues &&
                    typeof field.dataValidation?.constraints?.listValues ===
                      'string' &&
                    !acc.includes(field.dataValidation?.constraints?.listValues)
                  ) {
                    return acc.concat([
                      field.dataValidation.constraints.listValues,
                    ]);
                  }
                  return acc;
                }, []),
              );
            }
          });

          if (listIds?.length) {
            await getLists({ _id: { $in: listIds } });
          }
        }

        setLoadingUserLockColumns(false);
      })();
    }
  }, [
    datasetMetas,
    getLists,
    groupItem.aggregationId,
    groupItem.datasetMetaId,
    listQueryDatasetMetas,
    queries,
  ]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    if (groupItem.description) {
      setDescription(true);
    }
  }, [groupItem.description]);

  const processAction = async () => {
    if (
      groupItem.datasetMetaId ||
      groupItem.aggregationId ||
      groupItem.chartId
    ) {
      const cloned = cloneDeep(cms);

      const group = (
        cloned.configuration as Interfaces.CMSConnectionConfiguration
      ).groups.find((g) => g.groupName === groupName);

      // Combine selected user lock and data filters
      const newFilter = cloneDeep(BASE_FILTER);

      if (selectedUserLockColumn) {
        newFilter.value.push({
          field: selectedUserLockColumn.split(DROPDOWN_OPTION_VALUE_DIVIDER)[0],
          operator: Enums.AggregationFilterOperator.EQUAL,
          datasetMetaId: selectedUserLockColumn.split(
            DROPDOWN_OPTION_VALUE_DIVIDER,
          )[1],
          value: {
            type: Enums.DynamicConditionalType.USER,
            field:
              selectedUserLockColumn.split(DROPDOWN_OPTION_VALUE_DIVIDER)[2] ===
              Enums.ValueDataType.EMAIL
                ? EMAIL_FIELD
                : ID_FIELD,
          },
        });
      }

      if ((filter?.value as DynamicConditionalField[]).length) {
        newFilter.value.push(filter);
      }

      if (group) {
        const formattedGroupItem: Interfaces.CMSConfigurationDataGroupItem = {
          ...groupItem,
          description: wysiwygContent?.length ? wysiwygContent : undefined,
          options: {
            ...groupItem.options,
            preFilters: newFilter ?? undefined,
            commentsEnabled: commentsEnabled,
          },
        };

        if (id) {
          // Update existing
          group.items = group.items.map((item) => {
            return (item.datasetMetaId || item.aggregationId) ===
              (groupItem.datasetMetaId || groupItem.aggregationId)
              ? formattedGroupItem
              : item;
          });
        } else {
          // Add new item
          group.items = [...group.items, formattedGroupItem];
        }

        dispatch(fetchCMSSuccess(cloned));
      }

      dispatch(hideModal());
    }
  };

  const resetFilter = () => {
    const cloned = cloneDeep(filter);
    cloned.value = [];

    setFilter(cloned);
  };

  const handleUpdateSelectedUserLock = (value) => {
    // Remove user lock and filter row
    if (!value && selectedUserLockColumn) {
      setSelectedUserLockColumn('');
      return;
    }

    // Set selected user lock
    setSelectedUserLockColumn(value);
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>Add Content</SC.Header>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>
      <SC.ContentContainer>
        <SC.Content>
          <StyledText>Which content would you like to add?</StyledText>

          <StyledBodySubHeader>Select Content</StyledBodySubHeader>

          <StyledDropdownUltraWide
            selectOnBlur={false}
            loading={loadingEntities}
            placeholder={`Select content`}
            selection
            search
            options={filteredOptions}
            upward={false}
            value={
              groupItem.datasetMetaId ||
              groupItem.aggregationId ||
              groupItem.chartId
            }
            onChange={(e, data) => {
              const option = data.options.find((o) => o.value === data.value);

              const isDsm = option?.description === 'Dataset';
              const isQuery = option?.description === 'Query';

              const cloned: Interfaces.CMSConfigurationDataGroupItem =
                cloneDeep(groupItem);

              if (isDsm) {
                cloned.datasetMetaId = data.value;
                cloned.aggregationId = undefined;
                cloned.chartId = undefined;
              } else if (isQuery) {
                cloned.aggregationId = data.value;
                cloned.datasetMetaId = undefined;
                cloned.chartId = undefined;
              } else {
                cloned.aggregationId = undefined;
                cloned.datasetMetaId = undefined;
                cloned.chartId = data.value;
              }
              setSelectedUserLockColumn('');

              setFieldItems([]);
              setEntitySchema([]);
              setDataFilterColumns([]);
              resetFilter();

              setGroupItem(cloned);
            }}
            onSearchChange={(e, data) => onSearchValueChange(data.searchQuery)}
          />

          <SC.AdvancedAccordion>
            <StyledAccordionTitle
              active={descriptionOpen}
              index={0}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              style={{ backgroundColor: themeContext.colors.system.offWhite }}
            >
              <StyledSubHeader>Description</StyledSubHeader>
              <FontAwesomeIcon
                icon={descriptionOpen ? faChevronUp : faChevronDown}
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={descriptionOpen}>
              {!description && (
                <>
                  <SC.Panel key={`panel-description}`}>
                    <WysiwygEditor
                      page={EntityType.PORTAL}
                      readOnly={false}
                      areaId={'description'}
                      panelId={'description-panel'}
                      content={groupItem.description || undefined}
                      onChange={setWysiwygContent}
                    />
                  </SC.Panel>
                </>
              )}
              {description && (
                <SC.Panel key={`panel-description}`}>
                  <WysiwygEditor
                    page={EntityType.PORTAL}
                    readOnly={false}
                    areaId={'description'}
                    panelId={'description-panel'}
                    content={groupItem.description || undefined}
                    onChange={setWysiwygContent}
                  />
                </SC.Panel>
              )}
            </StyledAccordionContent>
            {(groupItem.chartId || groupItem.aggregationId) && (
              <>
                <StyledAccordionTitle
                  active={dataFiltersOpen}
                  index={0}
                  onClick={() => setCommentsOpen(!commentsOpen)}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledSubHeader>Comments</StyledSubHeader>
                  <FontAwesomeIcon
                    icon={commentsOpen ? faChevronUp : faChevronDown}
                    color={defaultTheme.colors.system.offBlack}
                  />
                </StyledAccordionTitle>
                <StyledAccordionContent active={commentsOpen}>
                  <StyledText>
                    Would you like to allow users to comment on your
                    {groupItem.chartId
                      ? ` ${EntityType.CHART}`
                      : ` ${EntityType.AGGREGATION}`}
                    ?
                  </StyledText>
                  <StyledDropdown
                    selectOnBlur={false}
                    selection
                    options={COMMENTS_ENABLED_OPTIONS}
                    value={commentsEnabled}
                    onChange={(e, data) => setCommentsEnabled(data.value)}
                  />
                </StyledAccordionContent>
              </>
            )}
            {groupItem.datasetMetaId && (
              <>
                <StyledAccordionTitle
                  active={advancedOpen}
                  index={0}
                  onClick={() => setAdvancedOpen(!advancedOpen)}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledSubHeader>User Lock</StyledSubHeader>
                  <FontAwesomeIcon
                    icon={advancedOpen ? faChevronUp : faChevronDown}
                    color={defaultTheme.colors.system.offBlack}
                  />
                </StyledAccordionTitle>
                <StyledAccordionContent active={advancedOpen}>
                  <StyledText>
                    Filter data to only show rows linked to user viewing this
                    dataset.
                  </StyledText>
                  <StyledText>
                    Select a column that contains a User List or email
                    addresses.
                  </StyledText>
                  <StyledDropdownWide
                    selectOnBlur={false}
                    disabled={
                      (!groupItem.datasetMetaId && !groupItem.aggregationId) ||
                      !userLockColumns.length
                    }
                    loading={loadingUserLockColumns}
                    placeholder={'Select a column'}
                    selection
                    clearable
                    options={userLockColumns}
                    value={selectedUserLockColumn}
                    onChange={(e, data) =>
                      handleUpdateSelectedUserLock(data.value)
                    }
                  />
                </StyledAccordionContent>
                <StyledAccordionTitle
                  active={dataFiltersOpen}
                  index={0}
                  onClick={() => setDataFiltersOpen(!dataFiltersOpen)}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledSubHeader>Data Filters</StyledSubHeader>
                  <FontAwesomeIcon
                    icon={dataFiltersOpen ? faChevronUp : faChevronDown}
                    color={defaultTheme.colors.system.offBlack}
                  />
                </StyledAccordionTitle>
                <StyledAccordionContent active={dataFiltersOpen}>
                  <StyledText>
                    Apply a filter to your data to limit what rows are available
                    in your workspace.
                  </StyledText>

                  <div>
                    <FilterBuilder
                      entitySchema={entitySchema}
                      fields={fieldItems}
                      filter={filter}
                      setFilter={setFilter}
                      isAddRowDisabled={
                        !groupItem.datasetMetaId &&
                        !groupItem.aggregationId &&
                        !groupItem.chartId
                      }
                      fieldOptions={dataFilterColumns}
                      hiddenFieldIds={
                        selectedUserLockColumn
                          ? [
                              selectedUserLockColumn.split(
                                DROPDOWN_OPTION_VALUE_DIVIDER,
                              )[0],
                            ]
                          : []
                      }
                    />
                  </div>
                </StyledAccordionContent>
                <StyledAccordionTitle
                  active={commentsOpen}
                  index={0}
                  onClick={() => setCommentsOpen(!commentsOpen)}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledSubHeader>Comments</StyledSubHeader>
                  <FontAwesomeIcon
                    icon={commentsOpen ? faChevronUp : faChevronDown}
                    color={defaultTheme.colors.system.offBlack}
                  />
                </StyledAccordionTitle>
                <StyledAccordionContent active={commentsOpen}>
                  <StyledText>
                    Would you like to allow users to comment on your dataset?
                  </StyledText>
                  <StyledDropdown
                    selectOnBlur={false}
                    selection
                    options={COMMENTS_ENABLED_OPTIONS}
                    value={commentsEnabled}
                    onChange={(e, data) => setCommentsEnabled(data.value)}
                  />
                </StyledAccordionContent>
              </>
            )}
          </SC.AdvancedAccordion>
        </SC.Content>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper multipleButtons>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={!canProgress}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={`Add Dataset`}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default CMSAddEntityModal;
