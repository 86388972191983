import { Enums } from '@configur-tech/upit-core-types';
import { ListValue } from '@configur-tech/upit-core-types/lib/interfaces/models/input/list/ListValue';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import useDatasetCollection from '../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DefaultPopupStyles } from '../../../main/theme';
import { UserListOutput } from '../../../services/list/ListService';
import { RootState } from '../../../store/rootReducer';
import validateValue from '../../../util/data-validator/DataValidator';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';
import { Cell } from '../../DataSample/DataSample';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import * as SC from './styled';

const ROW_NUMBER = 'row_id';

export interface EditableCellProps extends Cell {
  editAction: (rowIndex: number, columnKey: number, value: string) => void;
}

const EditableTableCell: FC<EditableCellProps> = ({
  value,
  row,
  column,
  editAction,
}) => {
  const { datasetMeta } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const [currentVal, setCurrentVal] = useState(value);
  const [isValid, setIsValid] = useState(false);

  const dataCollection =
    (collection?.collectionId &&
      datasetMeta?.dataCollections.find(
        (c) => c._id === collection.collectionId,
      )) ||
    datasetMeta?.dataCollections[0];
  const schemaData = dataCollection?.schemaData;
  const cellType = schemaData?.find((f) => f.name === column.id.toString());

  const listsState = useSelector((state: RootState) => state.lists);
  const lists = listsState?.data;

  const onChange = (e) => {
    setCurrentVal(e.target.value);
  };

  const processChange = (val?: string) => {
    if (value !== (val || currentVal)) {
      editAction(
        +(row.values[ROW_NUMBER] as string),
        column.id,
        val || currentVal,
      );
    }
  };

  // If value is changed externally, sync it back up
  useEffect(() => {
    setCurrentVal(value);
  }, [value]);

  useEffect(() => {
    if (cellType?.dataValidation?.dataValidationType) {
      let valuesToCheck: ListValue[] = [];

      const listValues = cellType?.dataValidation?.constraints?.listValues;
      if (listValues) {
        if (Array.isArray(listValues)) {
          valuesToCheck = listValues;
        } else {
          valuesToCheck =
            lists.data.find((l) => l.entity._id === listValues)?.entity
              .values || [];
        }
      }

      setIsValid(
        validateValue(
          cellType.dataValidation.dataValidationType,
          currentVal,
          cellType.dataValidation.constraints,
          valuesToCheck,
        ),
      );
    } else {
      setIsValid(true);
    }
  }, [
    cellType?.dataValidation?.constraints,
    cellType?.dataValidation?.dataValidationType,
    currentVal,
    lists,
  ]);

  switch (cellType?.dataValidation?.dataValidationType) {
    case Enums.ValueDataType.DATE: {
      const format =
        cellType?.dataValidation?.constraints?.format ||
        Enums.DateFormat.DATE_SHORT;

      return (
        <Popup
          disabled={isValid}
          content={
            <>
              <p>Invalid value: {currentVal}</p>
              <p>Expected format: {format}</p>
            </>
          }
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <SC.EditableDatePicker>
              <DateTimePicker
                popperPlacement={'top'}
                hideIcon={true}
                value={
                  validateValue(Enums.ValueDataType.DATE, currentVal)
                    ? currentVal
                    : ''
                }
                onChange={(date) => {
                  setCurrentVal(date);
                  processChange(date);
                }}
                dateFormat={format}
              />
            </SC.EditableDatePicker>
          }
        />
      );
    }
    case Enums.ValueDataType.TEXT: {
      let listValues = cellType?.dataValidation?.constraints?.listValues;
      if (!listValues) {
        return (
          <SC.EditableInput
            value={currentVal}
            onChange={onChange}
            onBlur={() => processChange()}
            disabled={!editAction}
          />
        );
      }

      if (!Array.isArray(listValues)) {
        listValues =
          lists?.data.find((l) => l.entity._id === listValues)?.entity.values ||
          [];
      }

      const listValuesUsers =
        (lists?.data.find(
          (l) =>
            l.entity._id === cellType?.dataValidation?.constraints?.listValues,
        )?.entity as UserListOutput) || [];
      const isUserList = listValuesUsers?.listType === Enums.ListType.USER;

      const displayColorIndicator = listValues.some((lv) => lv.color);

      const listVals = isUserList ? listValuesUsers.users : listValues;

      const options = listVals.map((lv, i) => {
        const image = isUserList
          ? {
              avatar: true,
              src: UserIconMap[lv.avatar],
            }
          : undefined;

        return {
          key: `list-${isUserList ? lv._id : lv.value}-${i}`,
          value: isUserList ? lv._id : lv.value,
          text: isUserList ? `${lv.firstName} ${lv.lastName}` : lv.value,
          label: displayColorIndicator && (
            <SC.DropdownLabel
              indicatorcolor={lv.color}
              circular={true}
              size={'mini'}
            />
          ),
          image,
        };
      });

      return (
        <Popup
          disabled={isValid}
          content={
            <>
              <p>Invalid value: {currentVal}</p>
            </>
          }
          position="left center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <SC.EditableDropdown
                selectOnBlur={false}
                placeholder={'Select an option'}
                options={options}
                selection
                search
                value={currentVal || ''}
                onChange={(e, data) => processChange(data.value)}
                style={{ margin: 0 }}
                disabled={!editAction}
              />
            </div>
          }
        />
      );
    }
    default:
      return (
        <SC.EditableInput
          value={currentVal}
          onChange={onChange}
          onBlur={() => processChange()}
          disabled={!editAction}
        />
      );
  }
};

export default EditableTableCell;
