import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FC, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import { DefaultPopupStyles } from '../../main/theme';
import FAButton from '../FaButton/FAButton';
import * as SC from './styled';

const PortalUserSystemShortcuts: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);

  return (
    <SC.Wrapper>
      <Popup
        key={`shortcut-dashboard`}
        content={'Dashboard'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FAButton
              action={() => history.push(RouteName.WELCOME)}
              icon={faBolt}
              color={
                location.pathname === RouteName.WELCOME
                  ? themeContext.colors.general.blue
                  : themeContext.colors.system.white
              }
              size={'2x'}
            />
          </div>
        }
      />
    </SC.Wrapper>
  );
};

export default PortalUserSystemShortcuts;
