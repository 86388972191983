import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import useUser from '../../../hooks/user/UseUser';
import { fetchUserSuccess } from '../../../store/user';
import { updateActiveUserSubStage } from '../../../store/user-stage';
import { UserCreationSubStage } from '../../../store/user-stage/initial-state';

import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
} from '../../../main/theme';
import validateValue from '../../../util/data-validator/DataValidator';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import ActionBar from '../../ActionBar/ActionBar';

const FIRST_NAME_FIELD = 'firstName';
const LAST_NAME_FIELD = 'lastName';
const EMAIL_FIELD = 'email';
const JOB_ROLE_FIELD = 'jobRole';
const NEXT_STAGE = UserCreationSubStage.AVATAR;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const NameWrapper = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: ${({ theme }) => `0 ${theme.margin.standard}`};
  }
`;

const PortalUserItemNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { user, userAccessLevel } = useUser();
  const { loggedInUser, loggedInUserAccessLevel } = useLoggedInUser();

  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(user);
    cloned[field] = val;
    dispatch(
      fetchUserSuccess({
        accessLevel: userAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  useEffect(() => {
    if (!user?._id && loggedInUser?._id) {
      dispatch(
        fetchUserSuccess({
          accessLevel: loggedInUserAccessLevel || Enums.AccessLevel.EDIT,
          entity: loggedInUser,
        }),
      );
    }
  }, [dispatch, loggedInUser, loggedInUserAccessLevel, user?._id]);

  useEffect(() => {
    setEmailIsInvalid(!validateValue(Enums.ValueDataType.EMAIL, user?.email));
  }, [user?.email]);

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Name</StyledBodySubHeader>
          <StageBodyText>What's your name?</StageBodyText>

          <NameWrapper>
            <div>
              <StyledBodySubHeader>First Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter your first name`}
                value={user?.firstName || ''}
                onChange={(event, data) =>
                  handleChange(FIRST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>

            <div>
              <StyledBodySubHeader>Last Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter your last name`}
                value={user?.lastName || ''}
                onChange={(event, data) =>
                  handleChange(LAST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>
          </NameWrapper>

          <StyledBodySubHeader>Email Address</StyledBodySubHeader>
          <StageBodyText>What's your email address?</StageBodyText>
          <StyledInput
            error={emailIsInvalid}
            placeholder={`Enter your email address`}
            value={user?.email || ''}
            onChange={(event, data) => handleChange(EMAIL_FIELD, data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Job Role</StyledBodySubHeader>
          <StageBodyText>What's your job role?</StageBodyText>
          <StyledInput
            placeholder={`Enter your job role`}
            value={user?.jobRole || ''}
            onChange={(event, data) => handleChange(JOB_ROLE_FIELD, data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={
              !user?.firstName.length ||
              !user?.lastName.length ||
              !user?.email.length ||
              emailIsInvalid
            }
            action={() => dispatch(updateActiveUserSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default PortalUserItemNameStage;
