import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ValueDataType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useEffect, useState } from 'react';

import { DropdownItemProps } from 'semantic-ui-react';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledInput,
  StyledText,
} from '../../../../main/theme';

interface TextConcatProps {
  field: Interfaces.Field;
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
  columnNameOptions: DropdownItemProps[];
}

const TextConcat: FC<TextConcatProps> = ({
  field,
  setDataValidation,
  setSuperColumnValid,
  loaded,
  columnNameOptions,
}) => {
  const [selectedColumnNames, setSelectedColumnNames] = useState<string[]>([]);
  const [joinCharacter, setJoinCharacter] = useState<string>();

  // Set values if updating existing
  useEffect(() => {
    if (field) {
      const opts = field.dataValidation
        ?.textTransformation as Interfaces.ConcatOp;

      if (opts) {
        setSelectedColumnNames(opts.params.fields);
        setJoinCharacter(opts.params.delimiter);
      }
    }
  }, [field]);

  // Set selected
  useEffect(() => {
    if (selectedColumnNames?.length && joinCharacter) {
      setSuperColumnValid(true);
      setDataValidation({
        dataValidationType: ValueDataType.TEXT_TRANSFORMATION,
        textTransformation: {
          type: Enums.TextTransformOperation.CONCAT,
          params: {
            fields: selectedColumnNames,
            delimiter: joinCharacter,
          },
        },
      });
    } else {
      setSuperColumnValid(false);
    }
  }, [
    selectedColumnNames,
    joinCharacter,
    setDataValidation,
    setSuperColumnValid,
  ]);

  return (
    <>
      <StyledBodySubHeader>Concatenate Columns</StyledBodySubHeader>

      <StyledText>
        Concatenate will join multiple text fields together into one value.
      </StyledText>

      <StyledBodySubHeader>Columns</StyledBodySubHeader>

      <StyledText>
        Select which columns you would like to concatenate.
      </StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select columns to concatenate'}
        options={columnNameOptions}
        selection
        multiple
        search
        value={selectedColumnNames}
        onChange={(e, data) => setSelectedColumnNames(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />

      <StyledBodySubHeader>Join Character</StyledBodySubHeader>

      <StyledText>
        What value should we use to join the column values? E.g. "value1-value2"
      </StyledText>

      <StyledInput
        placeholder={'Enter a join character'}
        value={joinCharacter || ''}
        onChange={(e, data) => setJoinCharacter(data.value)}
        style={{ marginTop: 0 }}
      />
    </>
  );
};

export default TextConcat;
