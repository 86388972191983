import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums/EntityType';
import { hideModal } from '../../../../store/modal';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import { RootState } from '../../../../store/rootReducer';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import DeleteConfirmation from '../../../DeleteConfirmation/DeleteConfirmation';

export interface CMSCreateGroupModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingGroupName?: string;
}

const STAGE_ONE = 1;
const STAGE_DELETE = 2;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 1050px;
  max-width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const CMSCreateGroupModal: FC<CMSCreateGroupModalProps> = ({
  setShowModal,
  existingGroupName,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const cmsState = useSelector((state: RootState) => state.cms);
  const cmsData = cmsState.data;

  const [modalStage, setModalStage] = useState<number>(STAGE_ONE);
  const [groupName, setGroupName] = useState<string>(existingGroupName || '');

  // Set modal to display
  useEffect(() => {
    setShowModal(true);
    return () => setShowModal(false);
  }, [setShowModal]);

  const processAction = async () => {
    if (groupName && cmsData) {
      const cloned = cloneDeep(cmsData);

      if (existingGroupName) {
        const group = cloned.configuration.groups.find(
          (g) => g.groupName === existingGroupName,
        );

        if (group) {
          group.groupName = groupName;
        }
      } else {
        cloned.configuration.groups.push({
          groupName,
          items: [],
        });
      }

      dispatch(fetchCMSSuccess(cloned));
      dispatch(hideModal());
    }
  };

  const processDelete = async () => {
    const cloned = cloneDeep(cmsData);

    cloned.configuration.groups =
      cloned?.configuration?.groups.filter(
        (g) => g.groupName !== existingGroupName,
      ) || [];

    dispatch(fetchCMSSuccess(cloned));
    dispatch(hideModal());
  };

  return (
    <Wrapper>
      {modalStage === STAGE_ONE && (
        <>
          <HeaderWrapper>
            <Header>
              {existingGroupName ? 'Update Group' : 'Create Group'}
            </Header>

            {existingGroupName && (
              <DeleteButton>
                <FeatureButton
                  action={() => setModalStage(STAGE_DELETE)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </DeleteButton>
            )}
          </HeaderWrapper>

          <StyledText>What would you like to call this group?</StyledText>

          <StyledBodySubHeader>Group Name</StyledBodySubHeader>

          <StyledInput
            placeholder={'Enter a name for your group'}
            value={groupName || ''}
            onChange={(event, data) => setGroupName(data.value)}
          />

          <ActionButtonWrapper>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={!groupName}
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={`${existingGroupName ? 'Update' : 'Create'} Group`}
            />
          </ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <>
          <Header>{'Delete Group'}</Header>

          <DeleteConfirmation
            entityType={EntityType.DATA_GROUP}
            entityName={groupName}
            cancelAction={() => setModalStage(STAGE_ONE)}
            deleteAction={processDelete}
          />
        </>
      )}
    </Wrapper>
  );
};

export default CMSCreateGroupModal;
