import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import { RouteName } from '../../../../enums/RouteName';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import usePortal from '../../../../hooks/portal/UsePortal';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchPortalSuccess } from '../../../../store/project-portal';
import {
  resetPortalStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectConnectionPortalSubStage } from '../../../../store/project-stage/initial-state';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const PREV_STAGE = ProjectConnectionPortalSubStage.CONNECTIONS;

const ProjectItemPortalAccessStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { portal, portalAccessLevel, editPortal } = usePortal();
  const { organisation } = useOrganisation();
  const { project, editProject, activeConnection } = useProject();

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update datasetMeta
      const cloned: Interfaces.PortalOutput = cloneDeep(portal);

      cloned.access[type][group] = updated;

      // Remove if present in another access type
      Object.entries(cloned.access).forEach(([cType, cAccess]) => {
        if (cType !== type) {
          cAccess[group] = cAccess[group].filter((e) => !updated.includes(e));
        }
      });

      // Save portal
      const updatedPortal = await editPortal(cloned);
      dispatch(
        fetchPortalSuccess({
          accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
          entity: updatedPortal,
        }),
      );
    }
  };

  const processAction = async () => {
    if (organisation && project && portal) {
      dispatch(showLoading({ text: 'Updating Portal...' }));
      const clonePortal = cloneDeep(portal);
      const clonedConnection = cloneDeep(activeConnection);

      const updatedPortal = await editPortal(clonePortal);

      if (updatedPortal) {
        clonedConnection.name = updatedPortal.name;

        const clonedProject = cloneDeep(project);
        clonedProject.connections[Enums.ConnectionType.PORTAL] =
          clonedProject.connections[Enums.ConnectionType.PORTAL].map((c) => {
            if (
              c._id === (clonedConnection as Interfaces.ConnectionOutput)._id
            ) {
              return clonedConnection;
            }

            return c;
          });

        await editProject(clonedProject);
        dispatch(
          fetchPortalSuccess({
            accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
            entity: updatedPortal,
          }),
        );
      }

      dispatch(hideLoading());
      dispatch(resetPortalStage());
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Set Permissions</StyledH2>
        <StageBodyText>
          Add users, teams or the whole organisation to the fields below to
          control who can access your portal and what permissions they have.
        </StageBodyText>

        <AccessSelection
          access={portal?.access}
          onChange={handleChange}
          hideTypes={[AccessType.MANAGE]}
        />
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save & Finish'}
          />
        }
        secondaryButton={
          <FeatureButton
            isDisabled={!portal?._id}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Preview Portal'}
            action={() => {
              window.open(
                `${process.env['REACT_APP_PORTAL_DOMAIN']}${RouteName.PORTAL}/${portal?._id}`,
              );
            }}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to connections'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemPortalAccessStage;
