import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { StyledH4 } from '../../main/theme';

interface ActionBarProps {
  primaryButton?: ReactElement;
  secondaryButton?: ReactElement;
  backButton?: ReactElement;
  text?: string;
}

const Wrapper = styled.div<Pick<ActionBarProps, 'backButton'>>`
  position: sticky;
  bottom: 0;
  z-index: 20;

  background: ${({ theme }) => theme.colors.system.offWhite};

  display: flex;
  justify-content: ${({ backButton }) =>
    backButton ? 'space-between' : 'flex-end'};
  align-items: center;

  width: 100%;
  height: 80px;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};

  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

const AdvanceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SecondaryButtonWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.margin.large} 0 0;
`;

const Text = styled(StyledH4)`
  margin: 0 ${({ theme }) => theme.margin.large} 0 0;
`;

const ActionBar: FC<ActionBarProps> = ({
  primaryButton,
  secondaryButton,
  backButton,
  text,
}) => {
  return (
    <Wrapper backButton={backButton}>
      {backButton}

      <AdvanceWrapper>
        {text && <Text>{text}</Text>}
        {secondaryButton && (
          <SecondaryButtonWrapper>{secondaryButton}</SecondaryButtonWrapper>
        )}
        {primaryButton}
      </AdvanceWrapper>
    </Wrapper>
  );
};

export default ActionBar;
