import { Enums } from '@configur-tech/upit-core-types';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import defaultLogo from '../../assets/icons/system/codex-icon.svg';
import { QuotaLimits, RouteName } from '../../enums';
import { defaultTheme } from '../../main/theme';
import { IntegrationConfigItem } from '../../services/integration/IntegrationConfigService';
import { IntegrationTemplateItem } from '../../services/integration/IntegrationTemplateService';
import { resetIntegrationConfig } from '../../store/integration-config';
import { resetStagesAndSubStages } from '../../store/integration-stage';
import { showModal } from '../../store/modal';
import resolveAvatar from '../../util/avatar/ResolveAvatar';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';
import IntegrationTagList from './IntegrationTagList';
import * as SC from './styled';

enum IntegrationActionType {
  REQUEST = 'Request',
  MANAGE = 'Manage',
  ACTIVATE = 'Activate',
}

const TITLE_TRUNCATE_LIMIT = 50;
const DESCRIPTION_TRUNCATE_LIMIT = 100;

export const INTEGRATION_REQUEST_FORM_ID =
  '612e6d6ae1ed100009112f3b/612f757f7879e50009dfd86b';

const CONTAINER_STYLE = {
  padding: `${defaultTheme.padding.standard} ${defaultTheme.padding.small}`,
};
const FACE_STYLE = { height: 'calc(100% - 5px)', width: '100%' };
const ADDITIONAL_STYLE = {
  overflow: 'hidden',
  height: 'calc(100% + 5px)',
  width: '100%',
};

interface IntegrationCardProps {
  integrationTemplate: IntegrationTemplateItem;
  usage: number;
  integrationConfig?: IntegrationConfigItem;
  action?: () => void;
}

const IntegrationCard: FC<IntegrationCardProps> = ({
  action,
  integrationTemplate,
  usage,
  integrationConfig,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { name, description, tags, avatar, status, isPublic } =
    integrationTemplate.entity;

  const integrationStatus =
    status === Enums.IntegrationStatus.ACTIVE
      ? integrationConfig?.entity
        ? IntegrationActionType.MANAGE
        : IntegrationActionType.ACTIVATE
      : IntegrationActionType.REQUEST;

  const handleIntegrationAction = (status: Enums.IntegrationStatus) => {
    switch (status) {
      case Enums.IntegrationStatus.ACTIVE:
        dispatch(resetIntegrationConfig());
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.INTEGRATION_CENTRE_MANAGE,
            fullScreen: true,
            additionalProps: {
              integrationTemplate: integrationTemplate,
              integrationConfigDoc: integrationConfig,
            },
          }),
        );
        break;
      case Enums.IntegrationStatus.INACTIVE:
        return window.open(
          `${process.env['REACT_APP_FORM_DOMAIN']}/${INTEGRATION_REQUEST_FORM_ID}`,
        );
    }
  };

  const handleIntegrationEdit = () => {
    if (integrationTemplate.entity._id) {
      dispatch(resetStagesAndSubStages());
      history.push(
        `${RouteName.INTEGRATION_ITEM}/${integrationTemplate.entity._id}`,
      );
    }
  };

  return (
    <FeatureButton
      action={action}
      color={themeContext.colors.system.offWhite}
      icon={
        <SC.CardWrapper>
          <SC.CardContent>
            <SC.CardLogo image={avatar ? resolveAvatar(avatar) : defaultLogo} />
            <div>
              <SC.CardTitle hasBody={!!description?.length}>
                {truncate(name, { length: TITLE_TRUNCATE_LIMIT })}
              </SC.CardTitle>
              <SC.CardDescription>
                {truncate(description, { length: DESCRIPTION_TRUNCATE_LIMIT })}
              </SC.CardDescription>
              <IntegrationTagList tags={tags} />
            </div>
          </SC.CardContent>
          <SC.IntegrationButton>
            <FeatureButton
              action={() => handleIntegrationAction(status)}
              size={FeatureButtonSize.WIDE}
              color={
                integrationStatus === IntegrationActionType.MANAGE
                  ? defaultTheme.colors.general.green
                  : defaultTheme.colors.general.blue
              }
              text={`${integrationStatus} Integration`}
              isDisabled={
                usage >= QuotaLimits.FULL &&
                integrationStatus === IntegrationActionType.ACTIVATE
              }
            />

            {integrationTemplate.accessLevel === Enums.AccessLevel.MANAGE &&
              !isPublic && (
                <FeatureButton
                  action={handleIntegrationEdit}
                  size={FeatureButtonSize.EXTRA_SMALL}
                  color={themeContext.colors.general.blue}
                  containerStyle={{ marginLeft: themeContext.margin.standard }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCog}
                      color={themeContext.colors.system.white}
                      size={'lg'}
                    />
                  }
                />
              )}
          </SC.IntegrationButton>
        </SC.CardWrapper>
      }
      containerStyle={CONTAINER_STYLE}
      faceStyle={FACE_STYLE}
      style={ADDITIONAL_STYLE}
    />
  );
};

export default IntegrationCard;
