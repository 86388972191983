import { Enums } from '@configur-tech/upit-core-types';
import { OrganisationItem } from '../../services/organisation/OrganisationService';

const InitialOrganisation: OrganisationItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    name: '',
    signupComplete: false,

    theme: {
      logo: '',
      colours: {
        primary: '',
        secondary: '',
        tertiary: '',
      },
    },

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    subscriptionDetails: {
      status: Enums.SubscriptionStatus.ACTIVE,
      subscriptionTier: Enums.SubscriptionTier.BASIC,
      subscriptionId: '',
      subscriptionStarted: '' as unknown as Date,
      quotas: {
        staticResources: {
          datasetMetas: 0,
          dataPoints: 0,
          projects: 0,
          portals: 0,
          apis: 0,
          users: 0,
          teams: 0,
          snapshots: 0,
          pipelines: 0,
          customPipelineIntegrations: 0,
        },
        dynamicResources: {
          apiCalls: { quota: 0, period: Enums.QuotaPeriod.MONTHLY },
          pipelineRuns: { quota: 0, period: Enums.QuotaPeriod.MONTHLY },
        },
      },
      usage: {},
    },
  },
};

export default InitialOrganisation;
