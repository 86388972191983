import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { StyledBodySubHeader, StyledText } from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getActiveDataCollection } from '../../../../util/dataset-meta-collection/getActiveDataCollection';
import * as SC from '../../styled';

interface MappingJobProps {
  job: Interfaces.PipelineJob;
}

const MappingJob: FC<MappingJobProps> = ({ job }) => {
  const themeContext = useContext(ThemeContext);
  const { activeDataCollectionItem } = useDatasetMeta();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [selectedActiveCollection, setSelectedActiveCollection] =
    useState<Interfaces.CollectionOutput>();

  // Set active collection if not the primary datasetMeta
  useEffect(() => {
    if (
      (job.jobParams as Interfaces.MappingJobParams).input.type ===
      Enums.MappingSourceType.DATASET
    ) {
      const dsmId = (job.jobParams as Interfaces.MappingJobParams).input
        .entityId;

      const dsm = datasetMetas?.find(
        (datasetMeta) => datasetMeta.entity._id === dsmId,
      );
      if (dsm) {
        const activeCollection = getActiveDataCollection(dsm.entity);

        if (activeCollection) {
          setSelectedActiveCollection(activeCollection);
        }
      }
    }
  }, [datasetMetas, job.jobParams]);

  return (
    <>
      <div>
        <StyledBodySubHeader
          style={{ marginBottom: themeContext.margin.standard }}
        >
          Mapped Fields
        </StyledBodySubHeader>
        <SC.MappingFieldWrapper>
          {(job.jobParams as Interfaces.MappingJobParams).mappings.map(
            (mapping, index) => {
              const schemaField = (
                selectedActiveCollection || activeDataCollectionItem
              )?.schemaData.find(
                (field) =>
                  mapping.inputField === field.fieldId ||
                  mapping.inputField === field.name,
              );

              return (
                <SC.MappingField
                  key={`map-field-${mapping.inputField}-${mapping.outputField}-${index}`}
                >
                  <StyledText>
                    {schemaField?.name || mapping.inputField}
                  </StyledText>
                </SC.MappingField>
              );
            },
          )}
        </SC.MappingFieldWrapper>
      </div>
    </>
  );
};

export default MappingJob;
