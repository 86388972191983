import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums';
import useGraph from '../../../hooks/graph/UseGraph';
import useQuery from '../../../hooks/query/UseQuery';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { fetchGraphSuccess } from '../../../store/graph';
import { hideModal } from '../../../store/modal';
import { fetchQuerySuccess } from '../../../store/query';
import { RootState } from '../../../store/rootReducer';
import { renameQuerySupercolumns } from '../../../util/data-structure/DataStructureUtils';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

const numberMeasureOptions = [
  Enums.ProjectionType.COUNT,
  Enums.ProjectionType.SUM,
  Enums.ProjectionType.AVERAGE,
  Enums.ProjectionType.MIN,
  Enums.ProjectionType.MAX,
].map((t, i) => {
  return {
    key: `${t}-${i}`,
    value: t,
    text: startCase(t),
  };
});

const textMeasureOptions = [Enums.ProjectionType.COUNT].map((t, i) => {
  return {
    key: `${t}-${i}`,
    value: t,
    text: startCase(t),
  };
});

export interface MeasureItem {
  field: string;
  datasetMetaId: string;
  measure: Enums.ProjectionType;
  alias?: string;
}

export interface AggregationAddMeasureModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingMeasure?: {
    index: number;
    measure: MeasureItem;
  };
  entityType: EntityType;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const AggregationAddMeasureModal: FC<AggregationAddMeasureModalProps> = ({
  setShowModal,
  existingMeasure,
  entityType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { query, queryAccessLevel } = useQuery();
  const { graph, graphAccessLevel } = useGraph();

  const [measure, setMeasure] = useState<MeasureItem>();
  const [fieldOptions, setFieldOptions] = useState<DropdownItemProps[]>([]);
  const [fieldType, setFieldType] = useState<Enums.ValueDataType>(
    Enums.ValueDataType.TEXT,
  );
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);

  const [dateConversionType, setDateConversionType] =
    useState<Enums.DateConversionOperation>();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const isMultiple =
    entityType === EntityType.AGGREGATION
      ? query?.queryParams?.aggregationType === Enums.AggregationType.MULTIPLE
      : graph?.queryParams?.aggregationType === Enums.AggregationType.MULTIPLE;

  useEffect(() => {
    if (measure) {
      const dsmId = measure.datasetMetaId;
      const field = measure.field;

      const ds = datasetMetas?.find((d) => d.entity._id === dsmId)?.entity;
      const schema = ds?.dataCollections.find(
        (c) => c._id === ds.activeDataCollection,
      )?.schemaData;
      const schemaField = schema?.find(
        (f) => f.fieldId === field || f.name === field,
      );

      if (schemaField?.dataValidation?.dateConversion) {
        setDateConversionType(schemaField?.dataValidation?.dateConversion.type);
      }

      const isLookupField =
        schemaField?.dataValidation?.dataValidationType ===
        Enums.ValueDataType.FIELD_LOOKUP;

      const lookupFieldSchema = schemaField?.dataValidation
        ?.fieldLookup as Interfaces.FieldLookupWithSchema;

      isLookupField
        ? setFieldType(
            lookupFieldSchema.target.targetSchema.dataValidation
              ?.dataValidationType || Enums.ValueDataType.TEXT,
          )
        : setFieldType(
            schemaField?.dataValidation?.dataValidationType ||
              Enums.ValueDataType.TEXT,
          );
    }
  }, [datasetMetas, measure]);

  const handleColumnChange = (value: string) => {
    if (value) {
      const cloned = cloneDeep(measure || {});

      const split = value.split('*');

      const alias = isMultiple
        ? `${fieldOptions.find((o) => o.value === value)?.text} - ${
            datasetMetas?.find((d) => d.entity._id === split[0])?.entity.name
          }_${measure?.measure}`
        : `${fieldOptions.find((o) => o.value === value)?.text}_${
            measure?.measure
          }`;

      cloned.datasetMetaId = split[0];
      cloned.field = split[1];
      cloned.alias = alias;

      setMeasure(cloned);
    }
  };

  const handleMeasureChange = (value: string) => {
    if (value) {
      const cloned = cloneDeep(measure || {});

      const field = fieldOptions.find(
        (o) => o.value && (o.value as string).split('*')[1] === cloned.field,
      );

      const alias = isMultiple
        ? `${field?.text} - ${field?.description}_${value}`
        : `${field?.text}_${value}`;

      cloned.measure = value;
      cloned.alias = alias;

      setMeasure(cloned);
    }
  };

  const processAction = () => {
    if (measure) {
      const cloned =
        entityType === EntityType.AGGREGATION
          ? cloneDeep(query)
          : cloneDeep(graph);

      const clonedMeasure = cloneDeep(measure);

      if (existingMeasure) {
        cloned.queryParams.measures[existingMeasure.index] = clonedMeasure;

        // If alias changed remove from sort and displayOrder
        if (existingMeasure.measure.alias !== clonedMeasure.alias) {
          // Replace in sort
          cloned.queryParams.sort = cloned.queryParams.sort.map((sortItem) => {
            if (sortItem.alias === existingMeasure.measure.alias) {
              return {
                ...clonedMeasure,
                isMeasure: true,
                active: true,
                direction: sortItem.direction,
              };
            }
            return sortItem;
          });

          // Replace in displayOrder
          cloned.queryParams.displayOrder = cloned.queryParams.displayOrder.map(
            (displayOrderItem) => {
              if (displayOrderItem.alias === existingMeasure.measure.alias) {
                return {
                  field: clonedMeasure.field,
                  alias: clonedMeasure.alias,
                  datasetMetaId: clonedMeasure.datasetMetaId,
                };
              }

              return displayOrderItem;
            },
          );

          // Update Supercolumns
          cloned.queryParams.supercolumns = renameQuerySupercolumns(
            existingMeasure.measure.alias as string,
            clonedMeasure.alias,
            cloned.queryParams.supercolumns,
          );
        }
      } else {
        cloned.queryParams.measures.push(clonedMeasure);
        cloned.queryParams.displayOrder.push(clonedMeasure);
      }

      entityType === EntityType.AGGREGATION
        ? dispatch(
            fetchQuerySuccess({
              accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          )
        : dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          );
    }

    dispatch(hideModal());
  };

  const processDelete = () => {
    if (existingMeasure) {
      const cloned =
        entityType === EntityType.AGGREGATION
          ? cloneDeep(query)
          : cloneDeep(graph);

      cloned.queryParams.measures.splice(existingMeasure.index, 1);

      // Find measures in sort by field or alias and remove
      cloned.queryParams.sort = cloned.queryParams.sort.filter(
        (sortItem) =>
          sortItem.field !== existingMeasure.measure.field ||
          sortItem.alias !== existingMeasure.measure.alias,
      );

      // find any supercolumns containing removed measure
      const removedSuperColIndexes = cloned.queryParams.supercolumns.map(
        (s, i) => {
          if (s.formula.includes(existingMeasure.measure.alias)) {
            return i;
          }
          if (s.textTransformation?.includes(existingMeasure.measure.alias)) {
            return i;
          }
          if (s.dateConversion?.includes(existingMeasure.measure.alias)) {
            return i;
          }
        },
      );

      // remove index of removedMeasureSuperIndex from supercolumns
      if (removedSuperColIndexes.length) {
        // remove all indexes from supercolumns
        removedSuperColIndexes.forEach((i) => {
          cloned.queryParams.supercolumns.splice(i, 1);
        });
      }

      // Remove from displayOrder
      if (cloned.queryParams.displayOrder) {
        cloned.queryParams.displayOrder =
          cloned.queryParams.displayOrder.filter(
            (s) =>
              s.field !== existingMeasure.measure.alias &&
              s.alias !== existingMeasure.measure.alias,
          );
      }

      // If no measures remaining, remove includeSubtotals
      if (!cloned.queryParams.measures.length) {
        cloned.queryParams.includeSubtotals = false;
        cloned.queryParams.includeGrandTotals = false;
      }

      entityType === EntityType.AGGREGATION
        ? dispatch(
            fetchQuerySuccess({
              accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          )
        : dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: cloned,
            }),
          );

      dispatch(hideModal());
    }
  };

  useEffect(() => {
    if (existingMeasure) {
      setMeasure(existingMeasure.measure);
    }
  }, [existingMeasure]);

  useEffect(() => {
    if (!entityType) {
      return;
    }

    if (entityType === EntityType.AGGREGATION && query?.queryParams.fields) {
      const options = query?.queryParams.fields?.map((f, i) => {
        return {
          key: `${f.datasetMetaId}-${f.field}-${i}`,
          value: `${f.datasetMetaId}*${f.field}`,
          text: (f.alias || f.field).replace(
            ` - ${
              datasetMetas?.find((d) => d.entity._id === f.datasetMetaId)
                ?.entity.name
            }`,
            '',
          ),
          description: datasetMetas?.find(
            (d) => d.entity._id === f.datasetMetaId,
          )?.entity.name,
        };
      });

      setFieldOptions(options);
    } else if (entityType === EntityType.GRAPH && graph?.queryParams.fields) {
      const options = graph?.queryParams.fields?.map((f, i) => {
        return {
          key: `${f.datasetMetaId}-${f.field}-${i}`,
          value: `${f.datasetMetaId}*${f.field}`,
          text: (f.alias || f.field).replace(
            ` - ${
              datasetMetas?.find((d) => d.entity._id === f.datasetMetaId)
                ?.entity.name
            }`,
            '',
          ),
          description: datasetMetas?.find(
            (d) => d.entity._id === f.datasetMetaId,
          )?.entity.name,
        };
      });

      setFieldOptions(options);
    }
  }, [
    query?.queryParams.fields,
    graph?.queryParams.fields,
    datasetMetas,
    entityType,
  ]);

  useEffect(() => {
    if (!entityType) {
      return;
    }

    if (
      measure?.measure &&
      measure.field &&
      !(
        measure.measure === existingMeasure?.measure.measure &&
        measure.field === existingMeasure?.measure.field
      )
    ) {
      entityType === EntityType.AGGREGATION
        ? setAlreadyExists(
            !!query?.queryParams.measures.find(
              (m) =>
                m.datasetMetaId === measure.datasetMetaId &&
                m.measure === measure.measure &&
                m.field === measure.field,
            ),
          )
        : setAlreadyExists(
            !!graph?.queryParams?.measures?.find(
              (m) =>
                m.datasetMetaId === measure.datasetMetaId &&
                m.measure === measure.measure &&
                m.field === measure.field,
            ),
          );
    }
  }, [
    query?.queryParams.measures,
    graph?.queryParams?.measures,
    measure?.datasetMetaId,
    measure?.field,
    measure?.measure,
    existingMeasure?.measure,
    entityType,
  ]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>{existingMeasure ? 'Update' : 'Add'} Measure</Header>

        {existingMeasure && (
          <DeleteButton>
            <FeatureButton
              action={() => processDelete()}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.red}
              text={'Delete'}
            />
          </DeleteButton>
        )}
      </HeaderWrapper>

      <StyledText>
        A measure is a computed value based on your columns.
      </StyledText>
      <StyledText>
        Simply select a column and what you'd like to do with the data and we'll
        do the rest.
      </StyledText>

      <StyledBodySubHeader>Select A Column</StyledBodySubHeader>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a Column'}
        selection
        options={fieldOptions}
        value={measure ? `${measure?.datasetMetaId}*${measure?.field}` : ''}
        onChange={(e, data) => handleColumnChange(data.value)}
      />

      <StyledBodySubHeader>Select A Measure</StyledBodySubHeader>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a Measure'}
        selection
        options={
          [Enums.ValueDataType.FORMULA, Enums.ValueDataType.NUMBER].includes(
            fieldType,
          ) || dateConversionType === Enums.DateConversionOperation.DATE_DIFF
            ? numberMeasureOptions
            : textMeasureOptions
        }
        value={measure ? measure.measure : ''}
        onChange={(e, data) => handleMeasureChange(data.value)}
      />

      {alreadyExists && (
        <StyledText>
          A measure of this type and field already exists.
        </StyledText>
      )}

      <ActionButtonWrapper>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={!measure?.field || !measure?.measure || alreadyExists}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Save Measure'}
        />
      </ActionButtonWrapper>
    </Wrapper>
  );
};

export default AggregationAddMeasureModal;
