import { Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_SCHEMA_DISCOVERY'] || '';

export interface DiscoveryResponse {
  schema: Interfaces.SchemaField[];
  sample: Record<string, string>[];
}

export default class SchemaService {
  public static async discoverSchema(
    token: string,
    fileId: string,
    organisationId: string,
    userId: string,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    sheetName?: string,
  ): Promise<DiscoveryResponse> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<DiscoveryResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      fileId,
      organisationId,
      userId,
      entryType,
      hasNoHeadings,
      delimiter,
      sheetName,
    });

    return result.data as DiscoveryResponse;
  }
}
