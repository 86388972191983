export enum AccessType {
  MANAGE = 'manage',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum AccessEntityType {
  ORGANISATION = 'organisation',
  TEAM = 'team',
  USER = 'user',
}
