import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface PipelineTemplateItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Pipeline;
}

export interface PipelineTemplateOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.PipelineOutput;
}

export interface PipelineTemplatesOutput {
  data: PipelineTemplateOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const endpoint = process.env['REACT_APP_CRUD_PIPELINE_TEMPLATE'] || '';

export default class PipelineTemplateService {
  /**
   * Gets all Pipeline Templates
   * @param {string} token - Token from auth0 to authorise users
   * @param {Record<string, unknown>} [query] - Query to decide which templates to fetch
   * @returns {Promise<Interfaces.SuccessResponseBody<PipelineTemplatesOutput> | Interfaces.ErrorResponseBody>}
   */
  public static async getPipelineTemplates(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<PipelineTemplatesOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<PipelineTemplatesOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }
  /**
   * Gets specific pipeline template
   * @param {string} token - Token from auth0 to authorise users
   * @param {string} id - Pipeline Id for specific template
   * @returns {Promise<Interfaces.SuccessResponseBody<PipelineTemplateOutput> | Interfaces.ErrorResponseBody>}
   */
  public static async getPipelineTemplate(
    token: string,
    id: string,
  ): Promise<PipelineTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<PipelineTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${id}`, { singleDocument: true });

    return result?.data as PipelineTemplateOutput;
  }

  /**
   * Posts new pipeline template created by user.
   * @param {string} token
   * @param {Interfaces.Pipeline} pipelineTemplate - Passes in PipelineTemplate to post
   * @param {string} userId - Gets specific UserId to assign Template to account
   * @returns {Promise<Interfaces.SuccessResponseBody<PipelineTemplateOutput> | Interfaces.ErrorResponseBody>}
   */
  public static async postPipelineTemplate(
    token: string,
    pipelineTemplate: Interfaces.Pipeline,
    userId: string,
  ): Promise<PipelineTemplateOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<PipelineTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: pipelineTemplate, userId });

    return result?.data as PipelineTemplateOutput;
  }
  /**
   * Edits an existing PipelineTemplate
   * @param {string} token - Token from auth0 to authorise users
   * @param {Interfaces.PipelineOutput} pipelineTemplate - PipelineTemplate that is being edited
   * @param {string} userId - User Id the PipelineTemplate is associated with
   * @returns {Promise<Interfaces.SuccessResponseBody<PipelineTemplateOutput> | Interfaces.ErrorResponseBody>}
   */
  public static async putPipelineTemplate(
    token: string,
    pipelineTemplate: Interfaces.PipelineOutput,
    userId: string,
  ): Promise<PipelineTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<PipelineTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${pipelineTemplate._id}`, {
      data: pipelineTemplate,
      userId,
    });

    return result?.data as PipelineTemplateOutput;
  }

  /**
   * Deletes PipelineTemplate
   * @param {string} token - Token from auth0 to authorise users
   * @param {string} pipelineTemplateId - Id of the Pipeline being deleted
   * @param {string} userId - UserId the pipeline is associated with
   * @returns {Promise<Interfaces.SuccessResponseBody<PipelineTemplateOutput> | Interfaces.ErrorResponseBody>}
   */
  public static async deletePipelineTemplate(
    token: string,
    pipelineTemplateId: string,
    userId: string,
  ): Promise<PipelineTemplateOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<PipelineTemplateOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${pipelineTemplateId}`, userId);

    return result?.data as PipelineTemplateOutput;
  }
}
