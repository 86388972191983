import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { StyledText } from '../../main/theme';

const LOGO_CONTAINER_IMAGE_SIZE = 48;
const LOGO_IMAGE_SIZE = 20;
const LOGO_SVG_SIZE = 28;
const READ_BUTTON_HEIGHT = 25;
const TOGGLE_EVENTS_BUTTON_WIDTH = 107;
const TOGGLE_EVENTS_BUTTON_PADDING = 7;

export const NotificationEventLogoContainer = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.system.grey};
  margin-right: ${({ theme }) => theme.margin.large};
  width: ${LOGO_CONTAINER_IMAGE_SIZE}px;
  height: ${LOGO_CONTAINER_IMAGE_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const NotificationEventLogo = styled(FontAwesomeIcon)`
  width: ${LOGO_IMAGE_SIZE}px;
  height: ${LOGO_IMAGE_SIZE}px;
`;

export const NotificationEventLogoSVG = styled.div<{ image: string }>`
  width: ${LOGO_SVG_SIZE}px;
  height: ${LOGO_SVG_SIZE}px;
  background: url(${({ image }) => image}) no-repeat center / 100%;
`;

export const NotificationCardWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.system.grey}`};
  background-color: ${({ theme }) => theme.colors.general.white};
  margin: ${({ theme }) => `${theme.margin.small} 0`};
  padding: ${({ theme }) => theme.padding.large};

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.system.white};
  }
`;

export const NotificationCardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const NotificationCardDetails = styled.div<{ read: boolean }>`
  display: flex;
  flex-direction: column;
  font-weight: ${({ read }) => !read && 'bold'};
  width: 100%;
`;

export const NotificationCardTitle = styled(StyledText)`
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.system.darkGrey};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const NotificationCardMessage = styled(StyledText)`
  color: ${({ theme }) => theme.colors.system.darkOffBlack};
  margin-right: ${({ theme }) => theme.margin.xxxlarge};
`;

export const UndoLink = styled.a`
  text-decoration: underline;
  margin-left: ${({ theme }) => theme.margin.small};
`;

export const NotificationReceived = styled.p<{ read: boolean }>`
  color: ${({ theme }) => theme.colors.general.blue};
  margin: ${({ theme }) => `${theme.margin.small} 0 0 !important`};

  &::before {
    content: ${({ read }) => !read && `''`};
    width: 12px;
    height: 12px;
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.general.blue};
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;

export const ToggleReadButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  border-radius: 2px;
  height: ${READ_BUTTON_HEIGHT}px;
  color: ${({ theme }) => theme.colors.system.darkGrey};

  svg {
    color: ${({ theme }) => theme.colors.system.darkGrey};
    margin-right: ${({ theme }) => theme.margin.small};
  }

  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.general.blue};
    color: ${({ theme }) => theme.colors.system.white};
    border-color: grey;

    svg {
      color: ${({ theme }) => theme.colors.system.white};
    }
  }
`;

export const NotificationCardMeta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

export const NotificationCardArrow = styled(FontAwesomeIcon)`
  align-self: center;
`;

export const ReadToggleContainer = styled.div`
  display: flex;
`;

export const ReadToggleButton = styled.button`
  cursor: pointer;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.general.blue};
  border: 1px solid ${({ theme }) => theme.colors.general.blue};
  min-width: ${TOGGLE_EVENTS_BUTTON_WIDTH}px;
  background: transparent;
  padding: ${TOGGLE_EVENTS_BUTTON_PADDING}px 0;

  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }

  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }

  &.active {
    color: ${({ theme }) => theme.colors.general.white};
    background: ${({ theme }) => theme.colors.general.blue};
  }
`;
