import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../hooks/project/UseProject';
import { SearchQuery } from '../../../interfaces/Search';
import {
  defaultTheme,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledDropdown,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

export interface ModellingAddLinkModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  dataLinkId: string;
}

const STAGE_ONE = 1;
const STAGE_DELETE = 2;
const SEARCH_TYPING_TIMEOUT = 1000;
const SEARCH_FIELD = 'name';

const joinTypeOptions = Object.values(Enums.JoinType).map((j, i) => {
  return {
    key: `join-type-${j}-${i}`,
    value: j,
    text: startCase(j),
  };
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 1050px;
  max-width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const SubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  margin-top: ${({ theme }) => theme.margin.standard};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const DropdownGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 55%;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DropdownHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

const AdvancedAccordion = styled(StyledAccordion)`
  width: 55%;
`;

const buildColumnDropdownOptions = (schemaFields: Interfaces.Field[]) => {
  const excludeText = [
    Enums.TextTransformOperation.EMPTY_CHECK,
    Enums.TextTransformOperation.SUBSTRING,
  ];

  return schemaFields
    .filter(
      (field) =>
        !(
          field.dataValidation?.textTransformation?.type &&
          excludeText.includes(field.dataValidation?.textTransformation?.type)
        ),
    )
    ?.map((field) => {
      let type: Enums.ValueDataType | 'Super' =
        field.dataValidation?.dataValidationType || Enums.ValueDataType.TEXT;

      if (
        [
          Enums.ValueDataType.TEXT_TRANSFORMATION,
          Enums.ValueDataType.DATE_CONVERSION,
          Enums.ValueDataType.FORMULA,
        ].includes(type)
      ) {
        type = 'Super';
      }

      return {
        key: field.name,
        text: field.name,
        value: field.fieldId || field.name,
        description: startCase(type.toLowerCase()),
      };
    });
};

const datasetOptions: Map<string, DropdownItemProps> = new Map();

const ModellingAddLinkModal: FC<ModellingAddLinkModalProps> = ({
  setShowModal,
  dataLinkId,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject } = useProject();
  const { getDatasetMetas } = useDatasetMeta();

  const datasetMetas = useSelector((state: RootState) => state.datasetMetas)
    ?.data.data;

  const [modalStage, setModalStage] = useState<number>(STAGE_ONE);
  const [primaryColumnOptions, setPrimaryColumnOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [secondaryColumnOptions, setSecondaryColumnOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [primaryDataset, setPrimaryDataset] = useState<string>();
  const [primaryColumn, setPrimaryColumn] = useState<string>();
  const [secondaryDataset, setSecondaryDataset] = useState<string>();
  const [secondaryColumn, setSecondaryColumn] = useState<string>();
  const [joinType, setJoinType] = useState<Enums.JoinType>(
    Enums.JoinType.LINK_MATCHES,
  );
  const [advancedOpen, setAdvancedOpen] = useState<boolean>(false);
  const [loadingDatasetMetas, setLoadingDatasetMetas] =
    useState<boolean>(false);
  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (value !== searchQuery.query) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  const getColumnDropdownOptions = useCallback(
    (dataset: string) => {
      const dsm = datasetMetas.find((d) => d.entity._id === dataset)?.entity;

      if (dsm) {
        const collection = dsm.activeDataCollection
          ? dsm.dataCollections.find((c) => c._id === dsm.activeDataCollection)
          : dsm.dataCollections[0];

        return buildColumnDropdownOptions(collection?.schemaData || []);
      }
    },
    [datasetMetas],
  );

  useEffect(() => {
    if (dataLinkId && project) {
      const link = project.dataModel.find((l) => l._id === dataLinkId);

      if (link) {
        setPrimaryDataset(link.primary.datasetMetaId);
        setPrimaryColumn(link.primary.field);
        setSecondaryDataset(link.secondary.datasetMetaId);
        setSecondaryColumn(link.secondary.field);
        setJoinType(link.joinType);
      }
    }
  }, [dataLinkId, project]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        setLoadingDatasetMetas(true);
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          searchQuery?.query?.length ? searchQuery : undefined,
        );

        setLoadingDatasetMetas(false);
      }
    })();
  }, [dispatch, getDatasetMetas, project, searchQuery]);

  useEffect(() => {
    if (datasetMetas.length) {
      datasetMetas.map((d) => {
        const ent = d.entity;

        datasetOptions.set(ent._id, {
          key: ent._id,
          value: ent._id,
          text: ent.name,
        });
      });
    }
  }, [datasetMetas]);

  useEffect(() => {
    if (primaryDataset) {
      const options = getColumnDropdownOptions(primaryDataset);

      if (options) {
        setPrimaryColumnOptions(options);
      }
    }
  }, [getColumnDropdownOptions, primaryDataset]);

  useEffect(() => {
    if (secondaryDataset) {
      const options = getColumnDropdownOptions(secondaryDataset);

      if (options) {
        setSecondaryColumnOptions(options);
      }
    }
  }, [getColumnDropdownOptions, secondaryDataset]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [
    primaryColumnOptions.length,
    secondaryColumnOptions.length,
    setShowModal,
  ]);

  const processAction = async () => {
    if (
      primaryDataset &&
      primaryColumn &&
      secondaryDataset &&
      secondaryColumn
    ) {
      dispatch(
        showLoading({
          text: `${dataLinkId ? 'Updating' : 'Creating'} Data Link...`,
        }),
      );

      const cloned = cloneDeep(project);

      // Create/Update link
      if (!dataLinkId) {
        cloned.dataModel.push({
          primary: {
            datasetMetaId: primaryDataset,
            field: primaryColumn,
          },
          secondary: {
            datasetMetaId: secondaryDataset,
            field: secondaryColumn,
          },
          joinType: joinType,
        });
      } else {
        const dataLink = cloned.dataModel.find((l) => l._id === dataLinkId);

        if (dataLink) {
          dataLink.primary = {
            datasetMetaId: primaryDataset,
            field: primaryColumn,
          };
          dataLink.secondary = {
            datasetMetaId: secondaryDataset,
            field: secondaryColumn,
          };
          dataLink.joinType = joinType;
        }
      }

      /// Update current status
      cloned.stages[Enums.ProjectStage.MODELLING].status =
        Enums.StageStatus.COMPLETED;

      // Update Project
      await editProject(cloned);

      dispatch(hideModal());
      dispatch(hideLoading());
    }
  };

  const processDelete = async () => {
    dispatch(
      showLoading({
        text: `Deleting Data Link...`,
      }),
    );
    const cloned: Interfaces.ProjectOutput = cloneDeep(project);

    cloned.dataModel =
      cloned?.dataModel.filter((f) => f._id !== dataLinkId) || [];

    await editProject(cloned);

    dispatch(hideModal());
    dispatch(hideLoading());
  };

  return (
    <Wrapper>
      {modalStage === STAGE_ONE && (
        <>
          <HeaderWrapper>
            <Header>{dataLinkId ? 'Update A Link' : 'Make A Match'}</Header>

            {dataLinkId && (
              <DeleteButton>
                <FeatureButton
                  action={() => setModalStage(STAGE_DELETE)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </DeleteButton>
            )}
          </HeaderWrapper>

          <StyledText>
            Simply select the datasets and columns you’d like to link using the
            dropdowns below.
          </StyledText>

          <SubHeader>I'd Like To Link</SubHeader>

          <DropdownGroupWrapper>
            <DropdownWrapper>
              <DropdownHeader>Primary Dataset</DropdownHeader>
              {/*TODO - Currently throwing a strict warning due to Semantic UI library, awaiting fix */}
              {/*https://github.com/Semantic-Org/Semantic-UI-React/issues/3819*/}
              <StyledDropdown
                selectOnBlur={false}
                loading={loadingDatasetMetas}
                placeholder={'Select a Primary Dataset'}
                selection
                search
                options={Array.from(datasetOptions, ([, opt]) => opt).filter(
                  (d) => d.value !== secondaryDataset,
                )}
                value={primaryDataset}
                onChange={(e, data) => {
                  setPrimaryDataset(data.value);
                  setPrimaryColumn('');
                }}
                onSearchChange={(e, data) =>
                  onSearchValueChange(data.searchQuery)
                }
              />
            </DropdownWrapper>

            <DropdownWrapper>
              <DropdownHeader>Column</DropdownHeader>
              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a Column'}
                selection
                options={primaryColumnOptions}
                value={primaryColumn}
                onChange={(e, data) => setPrimaryColumn(data.value)}
              />
            </DropdownWrapper>
          </DropdownGroupWrapper>

          <SubHeader>With</SubHeader>

          <DropdownGroupWrapper>
            <DropdownWrapper>
              <DropdownHeader>Secondary Dataset</DropdownHeader>
              <StyledDropdown
                selectOnBlur={false}
                loading={loadingDatasetMetas}
                placeholder={'Select a Secondary Dataset'}
                selection
                search
                options={Array.from(datasetOptions, ([, opt]) => opt).filter(
                  (d) => d.value !== primaryDataset,
                )}
                value={secondaryDataset}
                onChange={(e, data) => {
                  setSecondaryDataset(data.value);
                  setSecondaryColumn('');
                }}
                onSearchChange={(e, data) =>
                  onSearchValueChange(data.searchQuery)
                }
              />
            </DropdownWrapper>

            <DropdownWrapper>
              <DropdownHeader>Column</DropdownHeader>
              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a Column'}
                selection
                options={secondaryColumnOptions}
                value={secondaryColumn}
                onChange={(e, data) => setSecondaryColumn(data.value)}
              />
            </DropdownWrapper>
          </DropdownGroupWrapper>

          <AdvancedAccordion>
            <StyledAccordionTitle
              active={advancedOpen}
              index={0}
              onClick={() => setAdvancedOpen(!advancedOpen)}
              style={{ backgroundColor: themeContext.colors.system.offWhite }}
            >
              <StyledSubHeader>Advanced Options</StyledSubHeader>
              <FontAwesomeIcon
                icon={advancedOpen ? faChevronUp : faChevronDown}
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={advancedOpen}>
              <DropdownHeader>Link Type</DropdownHeader>

              <StyledText>
                This setting decides how these datasets are joined when queried.
              </StyledText>
              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a Link Type'}
                selection
                options={joinTypeOptions}
                value={joinType}
                onChange={(e, data) => setJoinType(data.value)}
              />
            </StyledAccordionContent>
          </AdvancedAccordion>

          <ActionButtonWrapper>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={
                !primaryDataset ||
                !primaryColumn ||
                !secondaryDataset ||
                !secondaryColumn
              }
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={`${dataLinkId ? 'Update' : 'Add'} Data Link`}
            />
          </ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <>
          <Header>{'Delete Data Link'}</Header>

          <DeleteConfirmation
            entityType={EntityType.DATA_LINK}
            entityName={EntityType.DATA_LINK}
            cancelAction={() => setModalStage(STAGE_ONE)}
            deleteAction={processDelete}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ModellingAddLinkModal;
