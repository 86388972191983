import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DataSample from '../../components/DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import {
  AlertStatus,
  BannerType,
  EntityType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePipelineTemplate from '../../hooks/pipeline-template/UsePipelineTemplate';
import useUsage from '../../hooks/usage/useUsage';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import { StageBodyText } from '../../main/theme';
import { resetStagesAndSubStages } from '../../store/pipeline-stage';
import { fetchPipelineTemplateSuccess } from '../../store/pipeline-template';
import InitialPipelineTemplate from '../../store/pipeline-template/inital-state';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import * as SC from '../styled';

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Pipeline Name';
const DESCRIPTION_FIELD = 'Description';
const TRIGGER_FIELD = 'Trigger';
const STATUS_FIELD = 'Status';
const ACTIVE_TEXT = 'Active';
const INACTIVE_TEXT = 'Inactive';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: TRIGGER_FIELD },
  { name: STATUS_FIELD },
];

const PipelinesPage: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta } = useDatasetMeta();
  const { getPipelineTemplates } = usePipelineTemplate();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const pipelinesState = useSelector(
    (state: RootState) => state.pipelineTemplates,
  );
  const pipelines = pipelinesState.data.data;

  const [fetchingPipelines, setFetchingPipelines] = useState<boolean>(false);

  const [pipelineTableData, setPipelineTableData] = useState<SampleDataRow[]>(
    [],
  );

  const [pipelineUsage, setPipelineUsage] = useState<number>(0);

  // Fetch pipelines
  useEffect(() => {
    if (params?.datasetMetaId && params?.datasetMetaId === datasetMeta?._id) {
      (async () => {
        setFetchingPipelines(true);
        await getPipelineTemplates({
          datasetMetaId: datasetMeta?._id,
          limit: 100,
        });
        setFetchingPipelines(false);
      })();
    }
  }, [datasetMeta?._id, getPipelineTemplates, params?.datasetMetaId]);

  // Map pipelines to table data
  useEffect(() => {
    const tableData: SampleDataRow[] = [];

    pipelines.map((i) => {
      const ent = i.entity;

      tableData.push({
        [ID_FIELD]: { value: ent._id },
        [NAME_FIELD]: { value: ent.name },
        [DESCRIPTION_FIELD]: { value: ent.description || '' },
        [TRIGGER_FIELD]: {
          value: startCase(ent.trigger?.toLowerCase()) || '',
        },
        [STATUS_FIELD]: {
          value: ent.isEnabled ? ACTIVE_TEXT : INACTIVE_TEXT,
        },
      });
    });

    setPipelineTableData(tableData);
  }, [pipelines]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const pipelineUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.PIPELINES,
        );
        setPipelineUsage(pipelineUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  return (
    <>
      <PageFeatureHeader
        pageHeader={'Pipelines'}
        image={getDefaultAvatar(EntityType.PIPELINE)}
        title={'Ground Control For Your Pipelines'}
      />

      <Page>
        <SC.PageInner>
          <StageBodyText>
            Pipelines automate your data processes both internally and through
            connected integrations.
          </StageBodyText>

          <StageBodyText>
            Tap the button below to create a pipeline in minutes.
          </StageBodyText>

          {pipelineUsage >= QuotaLimits.WARNING && (
            <div style={{ marginBottom: themeContext.margin.xlarge }}>
              {BuildBanner.generateBanner(
                BannerType.QUOTA,
                pipelineUsage >= QuotaLimits.FULL
                  ? AlertStatus.ERROR
                  : AlertStatus.WARNING,
                ResourceType.PIPELINES,
              )}
            </div>
          )}

          <FeatureButton
            action={() => {
              // Reset pipeline template and stage data
              dispatch(resetStagesAndSubStages());
              dispatch(fetchPipelineTemplateSuccess(InitialPipelineTemplate));
              history.push(
                `${RouteName.DATASET_ITEM}/${datasetMeta?._id}${RouteName.PIPELINE_ITEM}`,
              );
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Create pipeline'}
            containerStyle={{ marginBottom: themeContext.margin.large }}
            isDisabled={pipelineUsage >= QuotaLimits.FULL}
          />

          {(fetchingPipelines ||
            (!!pipelineTableData?.length && !fetchingPipelines)) && (
            <DataSample
              loading={fetchingPipelines}
              sampleColumns={TABLE_SCHEMA}
              sampleRows={pipelineTableData}
              hideSortOnCols={[ROW_NUMBER]}
              clickableRows={{
                valueField: ID_FIELD,
                action: (pipelineId: string) => {
                  dispatch(resetStagesAndSubStages());
                  history.push(
                    `${RouteName.DATASET_ITEM}/${datasetMeta?._id}${RouteName.PIPELINE_ITEM}/${pipelineId}`,
                  );
                },
              }}
            />
          )}
        </SC.PageInner>
      </Page>
    </>
  );
};

export default PipelinesPage;
