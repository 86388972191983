import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { EventType } from '@configur-tech/upit-core-types/lib/enums';
import {
  faCalendar,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faTimeline,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageInner,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledH3,
  StyledText,
} from '../../../../main/theme';
import { PipelineEventItemOutput } from '../../../../services/events/EventsService';
import { getDuration } from '../../../../util/data-sample/DataSampleUtils';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { CodeBox } from '../../../Modal/connection/api/styled';
import * as SC from '../../styled';

interface JobEventMeta {
  jobId: string;
  jobName: string;
  errorMessage?: string;
}

interface EventPipelineStageProps {
  selectedEvent: PipelineEventItemOutput;
  resetSelectedEvent: () => void;
}

const EventPipelineStage: FC<EventPipelineStageProps> = ({
  selectedEvent,
  resetSelectedEvent,
}) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { datasetMetaId, eventId, pipelineId } = useParams();
  const { pipelineTemplate } = usePipelineTemplate();

  const [accordionStage, setAccordionStage] = useState<number[]>([0]);
  const [pipelineJobs, setPipelineJobs] = useState<Interfaces.EventOutput[]>();

  useEffect(() => {
    if (selectedEvent) {
      const pipelineJobs = selectedEvent.entity.pipelineEvents.filter(
        (event) => (event.eventMeta as unknown as JobEventMeta).jobId,
      );

      setPipelineJobs(pipelineJobs);
    }
  }, [eventId, selectedEvent]);

  const pipelineCompletedWidget = (
    pipelinePassed: boolean,
    isJob?: boolean,
  ) => (
    <>
      <SC.PipelineIcon
        icon={pipelinePassed ? faCircleCheck : faCircleXmark}
        color={
          pipelinePassed
            ? themeContext.colors.general.green
            : themeContext.colors.general.red
        }
      />
      <StyledText>
        {pipelinePassed
          ? isJob
            ? 'Pipeline job completed successfully'
            : 'Pipeline completed successfully'
          : isJob
          ? 'Pipeline job failed'
          : 'Pipeline failed'}
      </StyledText>
    </>
  );

  const pipelinePanels = pipelineJobs
    ?.filter((j) => j.eventType === Enums.PipelineEventType.JOB_STARTED)
    ?.map((job, i) => {
      const jobEventMeta = job.eventMeta as unknown as JobEventMeta;
      const jobId = jobEventMeta.jobId;

      const jobPassed = pipelineJobs.some(
        (job) =>
          (job.eventMeta as unknown as JobEventMeta).jobId === jobId &&
          job.eventType === EventType.JOB_SUCCESS,
      );
      const jobEndTime =
        pipelineJobs[
          pipelineJobs.filter(
            (job) => (job.eventMeta as unknown as JobEventMeta).jobId === jobId,
          ).length - 1
        ].meta.created;

      const jobError = (
        pipelineJobs.find(
          (job) =>
            (job.eventMeta as unknown as JobEventMeta).jobId === jobId &&
            job.eventType === EventType.JOB_FAIL,
        )?.eventMeta as unknown as JobEventMeta
      )?.errorMessage;

      return {
        key: `pipeline-panel-${jobId}-${i}`,
        title: (
          <StyledAccordionTitle
            onClick={() => {
              let cloned = cloneDeep(accordionStage);
              if (accordionStage.includes(i)) {
                cloned = cloned.filter((e) => e !== i);
              } else {
                cloned.push(i);
              }
              setAccordionStage(cloned);
            }}
          >
            <StyledBodySubHeader>
              <SC.PipelineIcon
                removeOutline={true}
                icon={jobPassed ? faCircleCheck : faCircleXmark}
                color={
                  jobPassed
                    ? themeContext.colors.general.green
                    : themeContext.colors.general.red
                }
              />
              {jobEventMeta.jobName}
            </StyledBodySubHeader>
            <FontAwesomeIcon
              icon={accordionStage.includes(i) ? faChevronUp : faChevronDown}
              color={themeContext.colors.system.offBlack}
            />
          </StyledAccordionTitle>
        ),
        content: (
          <SC.PipelineAccordionContentWrapper>
            <SC.AccordionContentWrapper
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  {pipelineCompletedWidget(jobPassed, true)}
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>

            <SC.AccordionContentWrapper
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  <SC.PipelineIcon
                    icon={faCalendar}
                    color={themeContext.colors.system.offBlack}
                  />
                  <StyledText>
                    {DateTime.fromISO(job.meta.created).toFormat(
                      'dd/MM/yyyy HH:mm:ss',
                    )}
                  </StyledText>
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>

            <SC.AccordionContentWrapper
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  <SC.PipelineIcon
                    icon={faClock}
                    color={themeContext.colors.system.offBlack}
                  />
                  <StyledText>
                    {getDuration(job.meta.created, jobEndTime)}
                  </StyledText>
                </SC.PipeLineInfoRow>
                {jobError && (
                  <SC.PipeLineInfoRow>
                    <CodeBox>{jobError}</CodeBox>
                  </SC.PipeLineInfoRow>
                )}
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>
          </SC.PipelineAccordionContentWrapper>
        ),
      };
    });

  return (
    <>
      <StageInner>
        <SC.Section>
          <SC.GroupAccordion
            style={{
              marginTop: themeContext.margin.xxlarge,
              backgroundColor: themeContext.colors.system.offWhite,
              width: '100%',
            }}
          >
            <SC.AccordionTitle
              style={{
                backgroundColor: themeContext.colors.system.offWhite,
              }}
            >
              <SC.AccordionTitleWrapper>
                <StyledH3>Pipeline {pipelineTemplate?.name}</StyledH3>
              </SC.AccordionTitleWrapper>
            </SC.AccordionTitle>
            <SC.AccordionContentWrapper
              active={true}
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  <SC.PipelineIcon
                    icon={faTimeline}
                    color={themeContext.colors.system.offBlack}
                  />
                  <StyledText>{selectedEvent.entity._id}</StyledText>
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>
            <SC.AccordionContentWrapper
              active={true}
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  {pipelineCompletedWidget(
                    selectedEvent?.entity?.pipelineEvents?.some(
                      (e) => e.eventType === EventType.PIPELINE_SUCCESS,
                    ),
                  )}
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>
            <SC.AccordionContentWrapper
              active={true}
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  <SC.PipelineIcon
                    icon={faCalendar}
                    color={themeContext.colors.system.offBlack}
                  />
                  <StyledText>
                    {DateTime.fromISO(
                      selectedEvent.entity.pipelineEvents[0].meta.created,
                    ).toFormat('dd/MM/yyyy HH:mm:ss')}
                  </StyledText>
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>
            <SC.AccordionContentWrapper
              active={true}
              backgroundColor={themeContext.colors.system.offWhite}
            >
              <SC.AccordionContentInfoWrapper removePadding={true}>
                <SC.PipeLineInfoRow>
                  <SC.PipelineIcon
                    icon={faClock}
                    color={themeContext.colors.system.offBlack}
                  />
                  <StyledText>
                    {getDuration(
                      selectedEvent.entity.pipelineEvents[0].meta.created,
                      selectedEvent?.entity.pipelineEvents[
                        selectedEvent.entity.pipelineEvents.length - 1
                      ].meta.created,
                    )}
                  </StyledText>
                </SC.PipeLineInfoRow>
              </SC.AccordionContentInfoWrapper>
            </SC.AccordionContentWrapper>
          </SC.GroupAccordion>
        </SC.Section>
        <SC.Section>
          <SC.EventAccordion
            defaultActiveIndex={[0]}
            exclusive={false}
            panels={pipelinePanels}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              resetSelectedEvent();
              history.push(
                `${RouteName.DATASET_ITEM}/${datasetMetaId}${RouteName.PIPELINE_ITEM}/${pipelineId}`,
              );
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={`Back to Events`}
          />
        }
      />
    </>
  );
};

export default EventPipelineStage;
