import { Enums } from '@configur-tech/upit-core-types';
import { DateTime } from 'luxon';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ACTION_ROW_HEADER } from '../../../../const/DataSample';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
} from '../../../../enums/';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import useUsage from '../../../../hooks/usage/useUsage';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { SnapshotItemOutput } from '../../../../services/dataset-snapshot/DatasetSnapshotService';
import { updateActiveDatasetStage } from '../../../../store/dataset-stage';
import { showModal } from '../../../../store/modal';
import { RootState } from '../../../../store/rootReducer';
import BuildBanner from '../../../../util/buildBanner/BuildBanner';
import ActionBar from '../../../ActionBar/ActionBar';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import SnapshotsActions from '../../../SnapshotsActions/SnapshotsActions';

const SNAPSHOT_LIMIT = 10;

const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const DESCRIPTION_FIELD = 'Description';
const DATE_FIELD = 'Date';
const NAME_FIELD = 'Name';
const TOTAL_ROWS_FIELD = 'Total Rows';
const TOTAL_COLUMNS_FIELD = 'Total Columns';

const TABLE_SCHEMA_SNAPSHOTS = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: TOTAL_ROWS_FIELD },
  { name: TOTAL_COLUMNS_FIELD },
  { name: DATE_FIELD },
];

const ConfigureSnapshotStage: FC = () => {
  const { getDatasetSnapshot } = useDatasetMeta();

  const dispatch = useDispatch();

  const themeContext = useContext(ThemeContext);
  const [snapshotTableData, setSnapshotTableData] = useState<SampleDataRow[]>(
    [],
  );
  const datasetSnapshotsState = useSelector(
    (state: RootState) => state.datasetSnapshots,
  );
  const datasetSnapshots: SnapshotItemOutput[] = datasetSnapshotsState.data;

  const { datasetMeta } = useDatasetMeta();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const [snapshotUsage, setSnapshotUsage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (datasetMeta?._id?.length) {
      (async () => {
        await getDatasetSnapshot({
          'snapshot.datasetMeta._id': datasetMeta?._id,
        });
        setIsLoading(false);
      })();
    }
  }, [datasetMeta?._id, getDatasetSnapshot]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const snapshotUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.SNAPSHOTS,
        );
        setSnapshotUsage(snapshotUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  useEffect(() => {
    if (datasetSnapshots) {
      const tableData: SampleDataRow[] = [];

      datasetSnapshots.map((i) => {
        const ent = i.entity;

        const activeCollectionIndex =
          ent.snapshot.datasetMeta.dataCollections.findIndex(
            (s) => s._id === ent.snapshot.datasetMeta.activeDataCollection,
          );
        const activeCollection =
          ent.snapshot.datasetMeta.dataCollections[activeCollectionIndex];

        const snapshotRowCount = activeCollection.rowCount
          ? activeCollection.rowCount.toString()
          : '0';
        const snapshotColumnCount = activeCollection.columnCount
          ? activeCollection.columnCount.toString()
          : '0';

        tableData.push({
          [ID_FIELD]: { value: ent._id },
          [NAME_FIELD]: { value: ent.name },
          [DESCRIPTION_FIELD]: {
            value: ent.description ? ent.description : '',
          },
          [TOTAL_ROWS_FIELD]: { value: snapshotRowCount },
          [TOTAL_COLUMNS_FIELD]: { value: snapshotColumnCount },
          [DATE_FIELD]: {
            value: DateTime.fromISO(ent.meta.created).toFormat(
              'dd/MM/yyyy HH:mm:ss',
            ),
          },
        });
      });

      setSnapshotTableData(tableData);
    }
  }, [datasetSnapshots]);

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          Snapshots are complete backups of your dataset and its configuration.
        </StageBodyText>
        <StageBodyText>
          Once created, you can always revert your data back to that precise
          moment should you ever need to.
        </StageBodyText>
        {datasetSnapshots.length >= SNAPSHOT_LIMIT && (
          <>
            <StageBodyText>
              You have created the maximum amount of snapshots allowed (
              {SNAPSHOT_LIMIT}) for this dataset.
            </StageBodyText>
            <StageBodyText>
              To create additional snapshots you'll need to delete older ones
              via the table below.
            </StageBodyText>
          </>
        )}

        {datasetMeta && datasetMeta._id && (
          <>
            {snapshotUsage >= QuotaLimits.WARNING && (
              <div style={{ marginBottom: themeContext.margin.xlarge }}>
                {BuildBanner.generateBanner(
                  BannerType.QUOTA,
                  snapshotUsage >= QuotaLimits.FULL
                    ? AlertStatus.ERROR
                    : AlertStatus.WARNING,
                  ResourceType.SNAPSHOTS,
                )}
              </div>
            )}
            <FeatureButton
              isDisabled={
                datasetSnapshots.length >= SNAPSHOT_LIMIT ||
                snapshotUsage >= QuotaLimits.FULL
              }
              action={() =>
                dispatch(
                  showModal({
                    visible: true,
                    modal: ModalTypes.SNAPSHOT,
                  }),
                )
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create snapshot'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
            />
          </>
        )}

        <DataSample
          sampleColumns={TABLE_SCHEMA_SNAPSHOTS}
          sampleRows={snapshotTableData}
          fullWidth={true}
          loading={isLoading}
          actions={(entityId: string) => (
            <SnapshotsActions entityId={entityId} />
          )}
          hideSortOnCols={[ROW_NUMBER, ACTION_ROW_HEADER]}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ConfigureSnapshotStage;
