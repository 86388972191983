import { Label } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { ListOptionColor } from '../../../enums/ListOptionColor';
import { StyledDropdown } from '../../../main/theme';

export const EditableInput = styled.input`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.system.white};
  text-align: center;

  transition: background 0.3s;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => `${theme.colors.system.white}33`};
      }
    `}

  &:focus {
    background: ${({ theme }) => theme.colors.general.blue};
    cursor: text;
  }
`;

export const EditableDatePicker = styled.div`
  padding: 0;
  height: 100%;

  > div {
    height: 100%;
    background: none;
    border: none;
    width: 100% !important;

    border-radius: 0;
    margin: 0;
    padding: 0;

    &:hover {
      background: ${({ theme }) => `${theme.colors.system.white}33`};
    }

    input {
      background: none;
      width: 100% !important;
      height: 39px !important;
      color: ${({ theme }) => theme.colors.system.white};
      margin: 0;
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        background: ${({ theme }) => theme.colors.general.blue};
        cursor: text;
      }
    }

    .react-datepicker__tab-loop {
      width: 0;
      z-index: 5;
    }
  }
`;

export const EditableDropdown = styled(StyledDropdown)`
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: ${({ theme }) => theme.colors.system.white} !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: auto !important;

  > div:first-of-type {
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: ${({ theme }) => theme.colors.system.white} !important;
  }

  > div.visible {
    position: relative !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-left: 1px solid ${({ theme }) => theme.colors.system.grey} !important;
    border-right: 1px solid ${({ theme }) => theme.colors.system.grey} !important;
    max-height: 200px !important;

    > div.option {
      width: 100%;
    }
  }
`;

export const DropdownLabel = styled(Label)`
  background-color: ${({ indicatorcolor }) =>
    ListOptionColor[indicatorcolor as string]} !important;
  border-color: ${({ indicatorcolor }) =>
    ListOptionColor[indicatorcolor as string]} !important;
  min-width: 1.2em !important;
  min-height: 1.2em !important;
  width: 1.2em;
  height: 1.2em;
`;
