import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { PipelineTemplateItem } from '../../services/pipeline/PipelineTemplateService';
import { initialRequestParamsState } from '../integration-template/inital-state';

export const initialBaseJob: Interfaces.PipelineJob = {
  name: '',
  description: '',
  jobType: '' as Enums.PipelineJobType,
  jobParams: {},
};

export const initialIntegrationJobParams: Interfaces.IntegrationJobParams = {
  integrationId: '',
  integrationEndpointId: '',
  integrationRequestEndpointId: '',
  requestParams: initialRequestParamsState,
};

export const initialMappingJobParams: Interfaces.MappingJobParams = {
  input: {} as unknown as Interfaces.MappingSource,
  output: {} as unknown as Interfaces.MappingSource,
  mappings: [],
};

export const initialDatasetJobParams: Interfaces.DatasetJobParams = {
  datasetMetaId: '',
  action: '' as Enums.DatasetJobAction,
};

export const initialConditionalJobParams: Interfaces.ConditionalJobParams = {
  condition: [],
  matchType: Enums.ConditionalMatchType.ALL,
};

export const initialFilterJobParams: Interfaces.FilterJobParams = {
  condition: [],
};

export const initialQueryJobParams: Interfaces.QueryJobParams = {
  queryId: '',
  filters: [],
};

const InitialPipelineTemplate: PipelineTemplateItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    description: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    trigger: '' as Enums.PipelineTrigger,
    jobs: [],
    schedule: '',
    datasetMetaId: '',
    webhookUrl: '',
  },
};

export default InitialPipelineTemplate;
