import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faChevronDown,
  faChevronUp,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { ThemeContext } from 'styled-components';
import useEndpointPayloadResponse, {
  PayloadResponseSchemaField,
} from '../../../../hooks/integration/UseEndpointPayloadResponse';
import {
  StyledAccordion,
  StyledAccordionRequirement,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
  defaultTheme,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import StateManagedInput from '../../../StateManagedInput/StateManagedInput';
import * as SC from './styled';

const FIELD_KEY = 'field';
const TYPE_KEY = 'type';

const schemaDropdownFieldOptions = Object.values(Enums.ValueDataType)
  .filter(
    (i) =>
      ![
        Enums.ValueDataType.UNKNOWN,
        Enums.ValueDataType.EMPTY,
        Enums.ValueDataType.FORMULA,
        Enums.ValueDataType.TEXT_TRANSFORMATION,
        Enums.ValueDataType.DATE_CONVERSION,
        Enums.ValueDataType.FIELD_LOOKUP,
      ].includes(i),
  )
  .map((i) => {
    return {
      key: i,
      text: startCase(i.toLowerCase()),
      value: i,
    };
  });

interface PayloadResponseAccordionProps {
  payload?: Interfaces.RequestPayload;
  response?: Interfaces.ResponseInfo;
  onChange: (
    response?: Interfaces.ResponseInfo,
    payload?: Interfaces.RequestPayload,
  ) => void;
  payloadRequired?: boolean;
  responseRequired?: boolean;
}

const PayloadResponseAccordion: FC<PayloadResponseAccordionProps> = ({
  payload,
  response,
  onChange,
  payloadRequired,
  responseRequired,
}) => {
  const themeContext = useContext(ThemeContext);

  const { addSchemaRow, updateInput, updateSchemaInput, removeSchemaRow } =
    useEndpointPayloadResponse();

  const [payloadAccordionStage, setPayloadAccordionStage] = useState<number>(0);
  const [requestPayload, setRequestPayload] =
    useState<Interfaces.RequestPayload>();
  const [responseInfo, setResponseInfo] = useState<Interfaces.ResponseInfo>();

  // Use existing payload and response
  useEffect(() => {
    if (payload) {
      setRequestPayload(payload);
    }

    if (response) {
      setResponseInfo(response);
    }
  }, [payload, response]);

  return (
    <StyledAccordion
      style={{
        marginTop: themeContext.margin.xxlarge,
        backgroundColor: themeContext.colors.system.offWhite,
      }}
      fluid
    >
      <StyledAccordionTitle
        active={payloadAccordionStage === 1}
        index={1}
        onClick={() =>
          setPayloadAccordionStage(payloadAccordionStage === 1 ? 0 : 1)
        }
      >
        <StyledSubHeader>Payload Options</StyledSubHeader>
        <div>
          {payloadRequired && (
            <StyledAccordionRequirement>Required</StyledAccordionRequirement>
          )}
          <FontAwesomeIcon
            icon={payloadAccordionStage === 1 ? faChevronUp : faChevronDown}
            color={themeContext.colors.system.offBlack}
          />
        </div>
      </StyledAccordionTitle>
      <SC.StyledAccordionContent
        active={payloadAccordionStage === 1}
        style={{
          backgroundColor: themeContext.colors.system.white,
        }}
      >
        <SC.StyledInputColumn>
          <SC.InputContainer>
            <StyledText
              style={{
                marginBottom: themeContext.margin.xxlarge,
              }}
            >
              Tell us how the data you're sending with your request is
              structured.
            </StyledText>
          </SC.InputContainer>
          <SC.ToggleWrapper
            style={{ marginBottom: themeContext.margin.xxlarge }}
          >
            <SC.ToggleLabel>Is entire payload an array?</SC.ToggleLabel>
            <Toggle
              checked={requestPayload?.isEntirePayloadArray}
              onChange={(event) => {
                const updated = updateInput(
                  requestPayload || ({} as Interfaces.RequestPayload),
                  'isEntirePayloadArray',
                  event.target.checked,
                );
                onChange(responseInfo, updated as Interfaces.RequestPayload);
              }}
            />

            <SC.ToggleLabel>Data in top level?</SC.ToggleLabel>
            <Toggle
              checked={requestPayload?.dataInTopLevel}
              onChange={(event) => {
                const updated = updateInput(
                  requestPayload || ({} as Interfaces.RequestPayload),
                  'dataInTopLevel',
                  event.target.checked,
                );
                onChange(responseInfo, updated as Interfaces.RequestPayload);
              }}
            />
          </SC.ToggleWrapper>
          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader>Data Field Key</StyledBodySubHeader>
              <StyledText
                style={{
                  marginBottom: themeContext.margin.xlarge,
                }}
              >
                If the data won't be sent at the top level of the payload, tell
                us what field to put it in. Dot notation is supported.
              </StyledText>
              {requestPayload && (
                <StateManagedInput
                  disabled={requestPayload?.dataInTopLevel}
                  placeholder={'Enter a data field'}
                  initialValue={requestPayload?.dataFieldKey || ''}
                  onChange={(value) => {
                    const updated = updateInput(
                      requestPayload || ({} as Interfaces.RequestPayload),
                      'dataFieldKey',
                      value,
                    );
                    onChange(
                      responseInfo,
                      updated as Interfaces.RequestPayload,
                    );
                  }}
                  style={{
                    marginBottom: themeContext.margin.large,
                    marginRight: themeContext.margin.standard,
                  }}
                />
              )}
            </SC.InputContainer>
          </SC.InputWrapper>
        </SC.StyledInputColumn>
        <SC.StyledInputColumn>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xxxlarge,
            }}
          >
            Payload Data Structure
          </StyledSubHeader>
          <StyledText
            style={{
              marginBottom: themeContext.margin.xlarge,
            }}
          >
            Add fields to tell us how your payload data is structured and what
            data types it consists of. Dot notation is supported.
          </StyledText>

          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Add Schema Field'}
            action={() => {
              const updated = addSchemaRow(
                requestPayload || ({} as Interfaces.RequestPayload),
                PayloadResponseSchemaField.PAYLOAD,
              );
              onChange(responseInfo, updated as Interfaces.RequestPayload);
            }}
            style={{
              marginBottom: themeContext.margin.xxlarge,
            }}
          />

          {requestPayload?.payloadSchema &&
            requestPayload?.payloadSchema?.length > 0 && (
              <SC.InputRowContainer>
                {requestPayload?.payloadSchema?.map((row, index) => (
                  <div key={`inputs-${index}`}>
                    <SC.InputContainer>
                      <StyledBodySubHeader>Field Name</StyledBodySubHeader>
                      <StateManagedInput
                        disabled={requestPayload?.dataInTopLevel}
                        placeholder={'Enter a Field name'}
                        initialValue={row.field || ''}
                        onChange={(value) => {
                          const updated = updateSchemaInput(
                            requestPayload,
                            PayloadResponseSchemaField.PAYLOAD,
                            index,
                            FIELD_KEY,
                            value,
                            true,
                          );
                          onChange(
                            responseInfo,
                            updated as Interfaces.RequestPayload,
                          );
                        }}
                      />
                    </SC.InputContainer>

                    <SC.InputContainer>
                      <StyledBodySubHeader>Data Type</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        selection
                        value={row.type.toString()}
                        placeholder={'Select a data type'}
                        options={schemaDropdownFieldOptions}
                        onChange={(e, { value }) => {
                          const updated = updateSchemaInput(
                            requestPayload,
                            PayloadResponseSchemaField.PAYLOAD,
                            index,
                            TYPE_KEY,
                            [value],
                            true,
                          );

                          onChange(
                            responseInfo,
                            updated as Interfaces.RequestPayload,
                          );
                        }}
                        upward={true}
                      />
                    </SC.InputContainer>

                    <FeatureButton
                      action={() => {
                        const updated = removeSchemaRow(
                          requestPayload,
                          PayloadResponseSchemaField.PAYLOAD,
                          index,
                        );
                        onChange(
                          responseInfo,
                          updated as Interfaces.RequestPayload,
                        );
                      }}
                      size={34}
                      height={34}
                      color={themeContext.colors.general.red}
                      icon={
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          color={defaultTheme.colors.system.white}
                        />
                      }
                    />
                  </div>
                ))}
              </SC.InputRowContainer>
            )}
        </SC.StyledInputColumn>
      </SC.StyledAccordionContent>

      <StyledAccordionTitle
        active={payloadAccordionStage === 2}
        index={2}
        onClick={() =>
          setPayloadAccordionStage(payloadAccordionStage === 2 ? 0 : 2)
        }
      >
        <StyledSubHeader>Response Options</StyledSubHeader>
        <div>
          {responseRequired && (
            <StyledAccordionRequirement>Required</StyledAccordionRequirement>
          )}
          <FontAwesomeIcon
            icon={payloadAccordionStage === 2 ? faChevronUp : faChevronDown}
            color={themeContext.colors.system.offBlack}
          />
        </div>
      </StyledAccordionTitle>
      <SC.StyledAccordionContent
        active={payloadAccordionStage === 2}
        style={{
          backgroundColor: themeContext.colors.system.white,
        }}
      >
        <SC.StyledInputColumn>
          <SC.InputContainer>
            <StyledText
              style={{
                marginBottom: themeContext.margin.xxlarge,
              }}
            >
              Tell us how to access your data from the response.
            </StyledText>
          </SC.InputContainer>
          <SC.ToggleWrapper
            style={{ marginBottom: themeContext.margin.xxlarge }}
          >
            <SC.ToggleLabel>Data returned at top level?</SC.ToggleLabel>
            <Toggle
              checked={responseInfo?.dataInTopLevel}
              onChange={(event) => {
                const updated = updateInput(
                  responseInfo || ({} as Interfaces.ResponseInfo),
                  'dataInTopLevel',
                  event.target.checked,
                );
                onChange(updated as Interfaces.ResponseInfo, requestPayload);
              }}
            />

            <SC.ToggleLabel>Is the data in an array?</SC.ToggleLabel>
            <Toggle
              checked={responseInfo?.isArray}
              onChange={(event) => {
                const updated = updateInput(
                  responseInfo || ({} as Interfaces.ResponseInfo),
                  'isArray',
                  event.target.checked,
                );
                onChange(updated as Interfaces.ResponseInfo, requestPayload);
              }}
            />
          </SC.ToggleWrapper>
          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader>Data Field</StyledBodySubHeader>
              <StyledText
                style={{
                  marginBottom: themeContext.margin.xlarge,
                }}
              >
                If the data isn't returned at the top level of the response,
                tell us what field to find it in. Dot notation is supported.
              </StyledText>
              {responseInfo && (
                <StateManagedInput
                  disabled={responseInfo?.dataInTopLevel}
                  placeholder={'Enter a data field'}
                  initialValue={responseInfo?.dataFieldKey || ''}
                  onChange={(value) => {
                    const updated = updateInput(
                      responseInfo || ({} as Interfaces.ResponseInfo),
                      'dataFieldKey',
                      value,
                    );
                    onChange(
                      updated as Interfaces.ResponseInfo,
                      requestPayload,
                    );
                  }}
                  style={{
                    marginBottom: themeContext.margin.large,
                    marginRight: themeContext.margin.standard,
                  }}
                />
              )}
            </SC.InputContainer>
          </SC.InputWrapper>
        </SC.StyledInputColumn>

        <SC.StyledInputColumn>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xxxlarge,
            }}
          >
            Response Data Structure
          </StyledSubHeader>
          <StyledText
            style={{
              marginBottom: themeContext.margin.xlarge,
            }}
          >
            Add fields to tell us how your data is structured and what data
            types it consists of. Dot notation is supported.
          </StyledText>

          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Add Schema Field'}
            action={() => {
              const updated = addSchemaRow(
                responseInfo || ({} as Interfaces.ResponseInfo),
                PayloadResponseSchemaField.RESPONSE,
              );
              onChange(updated as Interfaces.ResponseInfo, requestPayload);
            }}
            style={{
              marginBottom: themeContext.margin.xxlarge,
            }}
          />

          {responseInfo?.responseSchema &&
            responseInfo.responseSchema?.length > 0 && (
              <SC.InputRowContainer>
                {responseInfo.responseSchema.map((row, index) => (
                  <div key={`inputs-${index}`}>
                    <SC.InputContainer>
                      <StyledBodySubHeader>Field Name</StyledBodySubHeader>
                      <StateManagedInput
                        disabled={responseInfo?.dataInTopLevel}
                        placeholder={'Enter a Field name'}
                        initialValue={row.field || ''}
                        onChange={(value) => {
                          const updated = updateSchemaInput(
                            responseInfo,
                            PayloadResponseSchemaField.RESPONSE,
                            index,
                            FIELD_KEY,
                            value,
                            true,
                          );
                          onChange(
                            updated as Interfaces.ResponseInfo,
                            requestPayload,
                          );
                        }}
                      />
                    </SC.InputContainer>

                    <SC.InputContainer>
                      <StyledBodySubHeader>Data Type</StyledBodySubHeader>
                      <StyledDropdown
                        selectOnBlur={false}
                        selection
                        value={row.type.toString()}
                        placeholder={'Select a data type'}
                        options={schemaDropdownFieldOptions}
                        onChange={(e, { value }) => {
                          const updated = updateSchemaInput(
                            responseInfo,
                            PayloadResponseSchemaField.RESPONSE,
                            index,
                            TYPE_KEY,
                            [value],
                            true,
                          );

                          onChange(
                            updated as Interfaces.ResponseInfo,
                            requestPayload,
                          );
                        }}
                        upward={true}
                      />
                    </SC.InputContainer>

                    <FeatureButton
                      action={() => {
                        const updated = removeSchemaRow(
                          responseInfo,
                          PayloadResponseSchemaField.RESPONSE,
                          index,
                        );
                        onChange(
                          updated as Interfaces.ResponseInfo,
                          requestPayload,
                        );
                      }}
                      size={34}
                      height={34}
                      color={themeContext.colors.general.red}
                      icon={
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          color={themeContext.colors.system.white}
                        />
                      }
                    />
                  </div>
                ))}
              </SC.InputRowContainer>
            )}
        </SC.StyledInputColumn>
      </SC.StyledAccordionContent>
    </StyledAccordion>
  );
};

export default PayloadResponseAccordion;
