import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useContext, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../../enums/RouteName';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { StyledText } from '../../../main/theme';
import {
  resetStagesAndSubStages,
  updateActiveDatasetStage,
} from '../../../store/dataset-stage';
import { hideModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

export interface DatasetPublishedModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;

  padding: ${({ theme }) => theme.padding.xxxlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const DatasetPublishedModal: FC<DatasetPublishedModalProps> = ({
  setShowModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta } = useDatasetMeta();

  const [confettiCount, setConfettiCount] = useState<number>(200);

  const isIntegration = location.pathname.includes('integration');

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Confetti timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiCount(0);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      <Confetti numberOfPieces={confettiCount} />

      <Header>{datasetMeta?.name} Has Been Published!</Header>
      <StyledText>
        Great work! Your data has been imported, validated and published.
      </StyledText>
      <StyledText>
        You can now either view it or continue to your dataset overview.
      </StyledText>

      <ActionButtonWrapper>
        <FeatureButton
          action={() => {
            dispatch(hideModal());
            dispatch(resetStagesAndSubStages());
            history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}/view`);
          }}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.blue}
          text={'View Dataset'}
        />
        <FeatureButton
          action={() => {
            dispatch(hideModal());
            dispatch(resetStagesAndSubStages());
            dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
            history.push(
              isIntegration
                ? `${RouteName.INTEGRATION_CENTRE}`
                : `${RouteName.DATASET_ITEM}/${datasetMeta?._id}`,
            );
          }}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={
            isIntegration ? 'Continue to Integrations' : 'Continue to Overview'
          }
        />
      </ActionButtonWrapper>
    </Wrapper>
  );
};

export default DatasetPublishedModal;
