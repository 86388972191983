import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { QueryField } from '@configur-tech/upit-core-types/lib/interfaces';
import { GanttFormattedTask } from '@configur-tech/upit-design-library';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  LOCAL_STORAGE_DASHBOARD_URL,
  LOCAL_STORAGE_TEMP_FILTERS_KEY,
  Params,
} from '../../components/Modal/graph/GraphModal';
import { EntityType, RouteName } from '../../enums';
import { TaskType } from '../../interfaces/Gantt';
import { GraphItemOutput, GraphOutputWithDetails } from '../../store/graph';
import { hideModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import useChart from '../chart/UseChart';
import useCMS from '../cms/useCMS';
import useFilter, {
  DynamicConditionalField,
  fields,
} from '../filter/UseFilter';

export const FIELD = 'field';

interface useGraphResult {
  // Model
  graph?: GraphOutputWithDetails;
  graphAccessLevel?: Enums.AccessLevel;

  // Utils
  buildGraphFilters: (
    graphId?: string,
    isDataView?: boolean,
  ) => DynamicConditionalField[];

  handleClickedGanttTask: (
    graph: Interfaces.GraphOutput,
    task: GanttFormattedTask,
    params?: Params,
  ) => void;
}

const useGraph = (): useGraphResult => {
  const dispatch = useDispatch();
  const { getDatasetFilters } = useFilter();
  const { chart } = useChart();
  const { cms } = useCMS();
  const history = useHistory();
  const { projectId, cmsId, portalId, groupId } = useParams();

  const [graph, setGraph] = useState<Interfaces.GraphOutput>();
  const [graphAccessLevel, setGraphAccessLevel] = useState<Enums.AccessLevel>();

  const graphState = useSelector((state: RootState) => state.graph);
  const graphObj = graphState.data as GraphItemOutput;

  // Builds filters per graph
  const buildGraphFilters = useCallback(
    (graphId?: string, isDataView?: boolean) => {
      if (!isDataView) {
        return [];
      }

      const activeGraph = !graphId
        ? graph
        : chart?.graphs.find((g) => g._id === graphId);

      if (activeGraph && chart?._id) {
        const graphFieldAliases = activeGraph.queryParams.fields?.flatMap(
          (f) => f.alias,
        );
        const graphMeasureAliases = activeGraph.queryParams.measures?.flatMap(
          (f) => f.alias,
        );
        const allChartFilters = getDatasetFilters(chart?._id, true);
        const cloned = cloneDeep(allChartFilters);

        return (
          cloned
            // Ignore if this filter has no fields for this graph
            .filter((f) =>
              (f.value as DynamicConditionalField[]).some((v) =>
                (v.value as DynamicConditionalField[]).some((i) =>
                  i.fields?.some(
                    (i) =>
                      graphFieldAliases?.includes(i.alias) ||
                      graphMeasureAliases?.includes(i.alias),
                  ),
                ),
              ),
            )
            .map((filter, index) => {
              const clonedFilter = cloneDeep(filter);

              clonedFilter.value =
                clonedFilter.value
                  .filter((v) =>
                    (v.value as DynamicConditionalField[]).some((c) =>
                      c.fields?.some(
                        (c) =>
                          graphFieldAliases?.includes(c.alias) ||
                          graphMeasureAliases?.includes(c.alias),
                      ),
                    ),
                  )
                  .map((v) => {
                    const cloned = cloneDeep(v);
                    cloned.value = (cloned.value as DynamicConditionalField[])
                      // Filter any fields for other graphs
                      .filter((t) =>
                        (t.fields as [fields])?.some(
                          (p) =>
                            graphFieldAliases?.includes(p.alias) ||
                            graphMeasureAliases?.includes(p.alias),
                        ),
                      )
                      .map((t) => {
                        const cloned = cloneDeep(t);

                        // Filter the columns again
                        cloned.fields = cloned.fields.filter(
                          (p) =>
                            graphFieldAliases?.includes(p.alias) ||
                            graphMeasureAliases?.includes(p.alias),
                        );

                        // Move any available filter fields to root filter
                        if (cloned.fields) {
                          cloned.datasetMetaId =
                            cloned.fields[index].datasetMetaId;
                          cloned.field = cloned.fields[index].field;
                          cloned.alias = cloned.fields[index].alias;
                          cloned.isMeasure = cloned.fields[index].isMeasure;
                        }

                        delete cloned.fields;
                        return cloned;
                      });

                    return cloned;
                  }) || [];

              return clonedFilter;
            })
        );
      }
    },
    [chart?._id, chart?.graphs, getDatasetFilters, graph],
  );

  const handleClickedGanttTask = useCallback(
    (
      graph: Interfaces.GraphOutput,
      task: GanttFormattedTask,
      params?: Params,
    ) => {
      const graphParams = graph.graphParams as Interfaces.GanttGraphParams;

      const projectDatasetMetaId = (
        graphParams.projectMetrics?.nameField?.field as QueryField
      ).datasetMetaId;
      const taskDatasetMetaId = graphParams?.taskMetrics?.taskDatasetMetaId;

      // Check if all datasetMetas exist in this workspace
      const cmsConfGroupsDatasetIds = (
        cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
      ).groups
        .flatMap((conf) => conf.items)
        .map((item) => item.datasetMetaId);

      if (
        [projectDatasetMetaId, taskDatasetMetaId].some(
          (dsmId) => !cmsConfGroupsDatasetIds.includes(dsmId),
        )
      ) {
        return;
      }

      const redirectUrl = localStorage.getItem(
        `${LOCAL_STORAGE_DASHBOARD_URL}`,
      );
      if (!redirectUrl) {
        localStorage.setItem(
          `${LOCAL_STORAGE_DASHBOARD_URL}`,
          JSON.stringify(window.location.pathname),
        );
      }

      const projectLinkField = (
        graphParams?.taskMetrics?.projectLink.taskField.field as QueryField
      ).field;

      const projectLinkAlias = (
        graphParams?.taskMetrics?.projectLink.taskField.field as QueryField
      ).alias;

      let histObj;

      const activePortalId = params?.portalId || portalId;
      const activeProjectId = params?.projectId || projectId;
      const activeCmsId = params?.cmsId || cmsId;
      const activeGroupId = params?.groupId || groupId;

      switch (task.type) {
        case TaskType.PROJECT:
          histObj = {
            pathname: activePortalId
              ? `${RouteName.PORTAL}/${activePortalId}/${EntityType.CMS}/${activeCmsId}/${activeGroupId}/${EntityType.DATASET}/${projectDatasetMetaId}`
              : `${RouteName.PROJECT_ITEM}/${activeProjectId}/${EntityType.CMS}/${activeCmsId}/${activeGroupId}/${EntityType.DATASET}/${projectDatasetMetaId}`,
            state: {
              filters: JSON.stringify([
                {
                  operator: Enums.AggregationFilterOperator.AND,
                  value: [
                    {
                      operator: Enums.AggregationFilterOperator.AND,
                      value: [
                        {
                          operator: Enums.AggregationFilterOperator.EQUAL,
                          datasetMetaId: projectDatasetMetaId,
                          field: (
                            graphParams?.projectMetrics?.nameField
                              ?.field as QueryField
                          ).field,
                          alias:
                            graphParams?.projectMetrics?.nameField?.field
                              ?.alias,
                          isMeasure: false,
                          value: {
                            type: Enums.DynamicConditionalType.CONSTANT,
                            value: task.name,
                          },
                        },
                      ],
                      active: true,
                    },
                  ],
                },
              ]),
              filterType: EntityType.CHART,
            },
          };
          break;
        case TaskType.TASK:
          histObj = {
            pathname: activePortalId
              ? `${RouteName.PORTAL}/${activePortalId}/${EntityType.CMS}/${activeCmsId}/${activeGroupId}/${EntityType.DATASET}/${taskDatasetMetaId}`
              : `${RouteName.PROJECT_ITEM}/${activeProjectId}/${EntityType.CMS}/${activeCmsId}/${activeGroupId}/${EntityType.DATASET}/${taskDatasetMetaId}`,
            state: {
              filters: JSON.stringify([
                {
                  operator: Enums.AggregationFilterOperator.AND,
                  value: [
                    {
                      operator: Enums.AggregationFilterOperator.AND,
                      value: [
                        {
                          operator: Enums.AggregationFilterOperator.EQUAL,
                          datasetMetaId: taskDatasetMetaId,
                          field: projectLinkField,
                          alias: projectLinkAlias,
                          isMeasure: false,
                          value: {
                            type: Enums.DynamicConditionalType.CONSTANT,
                            value: task.project,
                          },
                        },
                      ],
                      active: true,
                    },
                  ],
                },
              ]),
              filterType: EntityType.CHART,
            },
          };

          break;
      }

      localStorage.setItem(
        `${LOCAL_STORAGE_DASHBOARD_URL}`,
        JSON.stringify(window.location.pathname),
      );

      localStorage.setItem(
        `${LOCAL_STORAGE_TEMP_FILTERS_KEY}`,
        JSON.stringify(histObj?.state),
      );

      // Navigate to dataset with generated filters
      history.push(histObj);

      dispatch(hideModal());
    },
    [
      cms?.configuration,
      cmsId,
      dispatch,
      groupId,
      history,
      portalId,
      projectId,
    ],
  );

  useEffect(() => {
    if (graphObj) {
      // Get latest additional details from chart
      let graphDetails = graphObj.entity;
      if (chart) {
        const chartGraph = chart.graphs.find((g) => g._id === graphDetails._id);
        if (chartGraph) {
          graphDetails = {
            ...graphDetails,
            additionalDetails: (chartGraph as GraphOutputWithDetails)
              .additionalDetails,
          };
        }
      }

      // Complete model
      setGraph(graphDetails);
      setGraphAccessLevel(graphObj.accessLevel);
    }
  }, [chart, graphObj]);

  return {
    // Model
    graph,
    graphAccessLevel,

    // Utils
    buildGraphFilters,
    handleClickedGanttTask,
  };
};

export default useGraph;
