import styled from 'styled-components';
import { StyledSubHeader, StyledText } from '../../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;

  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

// noinspection CssInvalidPropertyValue
export const CodeBox = styled.pre`
  background-color: ${({ theme }) => theme.colors.system.offBlack};
  color: ${({ theme }) => theme.colors.system.white};

  padding: ${({ theme }) => theme.padding.standard};
  border-radius: ${({ theme }) => theme.borders.radius};

  text-align: left;
  white-space: break-spaces;
  font-family: monospace;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const ToggleLabel = styled(StyledSubHeader)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  margin: 0 ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.small};

  &:last-of-type {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const OriginWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ApiKeyCopyWrapper = styled.div`
  width: 100%;
  max-width: 400px;

  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;
