import { Interfaces } from '@configur-tech/upit-core-types';
import { faCheckCircle, faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useForm from '../../../../hooks/form/UseForm';
import useList from '../../../../hooks/list/UseList';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import {
  StageInner,
  StageWrapper,
  StyledH2,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import {
  resetFormStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectConnectionFormSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import CopyCodeBox from '../../../CopyCodeBox/CopyCodeBox';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import FormDragAndDrop from '../../../Form/FormDragAndDrop';
import AddGroupButton from './AddGroupButton';
import Intro from './Intro';
import Outro from './Outro';
import QuestionEditor from './QuestionEditor';

const PREV_STAGE = ProjectConnectionFormSubStage.DATASET;

const GridOuter = styled(StageInner)`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const SummaryWrapper = styled.div`
  width: 600px;
  max-width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr) 250px;

  width: 100%;
  height: 100%;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
  > div:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.padding.large} ${theme.padding.standard}`};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const IntroOutroWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.padding.standard};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  background-color: ${({ theme }) => theme.colors.system.white};
  font-weight: bold;
  cursor: pointer;
  :hover {
    > .pencil {
      transition: 0.5s;
      opacity: 1;
    }
    > .check {
      display: none;
    }
  }
`;

const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.standard};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  color: ${({ theme }) => theme.colors.system.offBlack};
`;

export enum PageType {
  'NONE',
  'CREATE_QUESTION',
  'UPDATE_QUESTION',
  'INTRO',
  'OUTRO',
}
const ProjectItemFormBuildStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { form, editForm } = useForm();
  const { datasetMeta, getDatasetMeta, activeDataCollectionItem } =
    useDatasetMeta();
  const { getLists } = useList();
  const { organisation } = useOrganisation();

  const [fetchedDatasetMeta, setFetchedDatasetMeta] = useState<boolean>(false);
  const [pageType, setPageType] = useState<PageType>(PageType.NONE);
  const [hasQuestions, setHasQuestions] = useState<boolean>(false);

  const [activeQuestionGroup, setActiveQuestionGroup] = useState('');
  const [activeQuestionId, setActiveQuestionId] = useState('');

  useEffect(() => {
    if (form?.questionGroups) {
      const hasQs = Object.values(form.questionGroups).some(
        (qg) => qg.questions.length,
      );

      setHasQuestions(hasQs);
    }
  }, [form?.questionGroups]);

  // Get an existing datasetMeta if requested
  useEffect(() => {
    if (!fetchedDatasetMeta && form?.datasetMetaId) {
      (async () => {
        dispatch(
          showLoading({
            text: `Loading Dataset...`,
          }),
        );

        await getDatasetMeta(form.datasetMetaId);

        dispatch(hideLoading());
        setFetchedDatasetMeta(true);
      })();
    }
  }, [form, dispatch, fetchedDatasetMeta, getDatasetMeta]);

  // Get any lists from the schema
  useEffect(() => {
    if (
      fetchedDatasetMeta &&
      datasetMeta &&
      datasetMeta._id === form?.datasetMetaId &&
      activeDataCollectionItem
    ) {
      (async () => {
        const listIds = activeDataCollectionItem.schemaData.reduce((acc, f) => {
          if (
            f.dataValidation?.constraints?.listValues &&
            !Array.isArray(f.dataValidation?.constraints?.listValues)
          ) {
            return [...acc, f.dataValidation?.constraints?.listValues];
          }

          return acc;
        }, [] as string[]);

        if (listIds.length) {
          await getLists({ _id: { $in: listIds } });
        }
      })();
    }
  }, [
    activeDataCollectionItem,
    datasetMeta,
    fetchedDatasetMeta,
    form?.datasetMetaId,
    getLists,
  ]);

  const selectedSchemaData = activeDataCollectionItem?.schemaData;

  const renderMainPage = (pageType: PageType) => {
    switch (pageType) {
      case PageType.INTRO:
        return <Intro hideEditor={() => setPageType(PageType.NONE)} />;
      case PageType.OUTRO:
        return <Outro hideEditor={() => setPageType(PageType.NONE)} />;
      case PageType.CREATE_QUESTION:
      case PageType.UPDATE_QUESTION:
        return (
          <QuestionEditor
            activeQuestionGroup={activeQuestionGroup}
            setActiveQuestionGroup={setActiveQuestionGroup}
            hideEditor={() => setPageType(PageType.NONE)}
            schemaData={selectedSchemaData}
            activeQuestionId={activeQuestionId}
            mode={pageType}
          />
        );
      case PageType.NONE:
      default:
        return (
          <SummaryWrapper>
            <StyledH2>Form Summary</StyledH2>
            <StyledSubHeader>Form Name </StyledSubHeader>
            <StyledText>{form?.name}</StyledText>
            <StyledSubHeader>Dataset Name</StyledSubHeader>
            <StyledText>{datasetMeta?.name}</StyledText>

            {form?.intro?.body.length &&
              form?.outro?.body.length &&
              hasQuestions && (
                <>
                  <StyledSubHeader>Form URL</StyledSubHeader>

                  <CopyCodeBox
                    text={`${process.env['REACT_APP_FORM_DOMAIN']}/form/${form?._id}`}
                  />
                </>
              )}
          </SummaryWrapper>
        );
    }
  };

  const processAction = async (closeEditor = true) => {
    if (form) {
      dispatch(showLoading({ text: 'Updating Form...' }));
      const clonedForm: Interfaces.FormOutput = cloneDeep(form);

      // Add org logo if present
      if (organisation?.theme?.logo) {
        clonedForm.avatar = organisation.theme.logo;
      }

      await editForm(clonedForm);

      dispatch(hideLoading());

      if (closeEditor) {
        dispatch(resetFormStage());
      }
    }
  };

  return (
    <StageWrapper>
      <GridOuter>
        <GridWrapper>
          <Section>{renderMainPage(pageType)}</Section>

          <Sidebar>
            <HeaderWrapper>
              <Header>Form Template</Header>
            </HeaderWrapper>
            <IntroOutroWrapper onClick={() => setPageType(PageType.INTRO)}>
              Introduction
              <FontAwesomeIcon
                className={'pencil'}
                icon={faPencilAlt}
                color={themeContext.colors.system.offBlack}
                opacity={'0'}
              />
              <FontAwesomeIcon
                className={'check'}
                icon={faCheckCircle}
                color={
                  form?.intro?.body.length
                    ? themeContext.colors.general.green
                    : themeContext.colors.system.grey
                }
                opacity={'1'}
              />
            </IntroOutroWrapper>
            <IntroOutroWrapper onClick={() => setPageType(PageType.OUTRO)}>
              Form Completion
              <FontAwesomeIcon
                className={'pencil'}
                icon={faPencilAlt}
                color={themeContext.colors.system.offBlack}
                opacity={'0'}
              />
              <FontAwesomeIcon
                className={'check'}
                icon={faCheckCircle}
                color={
                  form?.outro?.body.length
                    ? themeContext.colors.general.green
                    : themeContext.colors.system.grey
                }
                opacity={'1'}
              />
            </IntroOutroWrapper>
            <HeaderWrapper>
              <Header>Question Groups</Header>
            </HeaderWrapper>
            <AddGroupButton />

            {form && (
              <FormDragAndDrop
                items={form?.questionGroups}
                setPageType={setPageType}
                activeQuestionId={activeQuestionId}
                setActiveQuestionId={setActiveQuestionId}
                setActiveQuestionGroup={setActiveQuestionGroup}
              />
            )}
          </Sidebar>
        </GridWrapper>
      </GridOuter>

      <ActionBar
        primaryButton={
          <FeatureButton
            isDisabled={
              !form?.intro?.body.length ||
              !form?.outro?.body.length ||
              !hasQuestions
            }
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save & Finish'}
            action={() => processAction()}
          />
        }
        secondaryButton={
          <FeatureButton
            isDisabled={
              !form?.intro?.body.length ||
              !form?.outro?.body.length ||
              !hasQuestions
            }
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Save & Preview Form'}
            action={async () => {
              await processAction(false);
              window.open(
                `${process.env['REACT_APP_FORM_DOMAIN']}/form/${form?._id}`,
              );
            }}
          />
        }
        backButton={
          <FeatureButton
            action={() => dispatch(updateActiveProjectSubStage(PREV_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to select'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemFormBuildStage;
