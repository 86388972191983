import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DataLinkRelationship } from '@configur-tech/upit-core-types/lib/interfaces/models/input/data-link/DataLinkRelationship';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface PortalItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Portal;
}

export interface PortalItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: PortalOutputWithAdditionalData;
}

export interface PortalItemsOutput {
  data: PortalItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export interface PortalOutputWithAdditionalData
  extends Interfaces.PortalOutput {
  modellingItems?: DataLinkRelationship[];
  connectionItems?: {
    cms: Interfaces.ConnectionOutput[];
    form: Interfaces.ConnectionOutput[];
    api: Interfaces.ConnectionOutput[];
  };
}

const endpoint = process.env['REACT_APP_CRUD_PORTAL'] || '';

export default class PortalService {
  public static async getPortals(
    token: string,
    query?: Record<string, unknown>,
    internal?: boolean,
  ): Promise<Promise<PortalItemsOutput> | Interfaces.ErrorResponseBody> {
    const ah = new HttpHelper(token);
    const url = internal ? `${endpoint}internal` : endpoint;

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<PortalItemsOutput>
      | Interfaces.ErrorResponseBody
    >(url, query);

    return result?.data as PortalItemsOutput;
  }

  public static async getPortal(
    token: string,
    id: string,
    internal?: boolean,
  ): Promise<PortalItemOutput> {
    const ah = new HttpHelper(token);

    const url = internal ? `${endpoint}internal` : endpoint;
    const query = internal
      ? { projectId: id }
      : { projectId: id, singleDocument: true };

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<PortalItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${url}/${id}`, query);

    return result?.data as PortalItemOutput;
  }

  public static async postPortal(
    token: string,
    portal: Interfaces.Portal,
    projectId: string,
    userId: string,
  ): Promise<PortalOutputWithAdditionalData> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<PortalOutputWithAdditionalData>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: portal, projectId, userId });

    return result?.data as PortalOutputWithAdditionalData;
  }

  public static async putPortal(
    token: string,
    portal: PortalOutputWithAdditionalData,
    projectId: string,
    userId: string,
  ): Promise<PortalOutputWithAdditionalData> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<PortalOutputWithAdditionalData>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${portal._id}`, { data: portal, projectId, userId });

    return result?.data as PortalOutputWithAdditionalData;
  }

  public static async deletePortal(
    token: string,
    portalId: string,
    userId: string,
    projectId?: string,
  ): Promise<PortalOutputWithAdditionalData> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<PortalOutputWithAdditionalData>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${portalId}`, userId, projectId);

    return result?.data as PortalOutputWithAdditionalData;
  }
}
