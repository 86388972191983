import React, { FC } from 'react';
import styled from 'styled-components';
import { DatasetSubStages } from '../../store/dataset-stage/initial-state';
import { StyledText } from '../../main/theme';

export interface SubStageItem {
  subStage: DatasetSubStages;
  stage: number;
  title: string;
}

interface SubStageMarkerProps {
  subStageItem: SubStageItem;
  index: number;
  activeSubStageNumber?: number;
  totalSubStages: number;
}

interface MarkerProps {
  isActive: boolean;
}

const MarkerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  width: 90px;
`;

const MarkerContent = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
`;

const Marker = styled.div<MarkerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: ${({ theme }) => theme.borders.radius};

  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.general.green : theme.colors.system.grey};
  color: ${({ theme }) => theme.colors.system.white};

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h3};

  transition: 0.5s background-color;
`;

const MarkerTitle = styled(StyledText)<MarkerProps>`
  text-align: center;
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  text-transform: uppercase;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.system.offBlack : theme.colors.system.grey};

  margin-top: ${({ theme }) => theme.margin.standard};
`;

const MarkerConnector = styled.div<MarkerProps>`
  position: absolute;
  top: 17px;
  left: 60px;

  width: 80px;
  height: 8px;

  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.general.green : theme.colors.system.grey};

  transition: 0.5s background-color;
`;

const SubStageMarker: FC<SubStageMarkerProps> = ({
  subStageItem,
  index,
  activeSubStageNumber = 1,
  totalSubStages,
}) => {
  return (
    <MarkerWrapper>
      <MarkerContent>
        <Marker isActive={activeSubStageNumber >= subStageItem.stage}>
          {subStageItem.stage}
        </Marker>
        <MarkerTitle isActive={activeSubStageNumber >= subStageItem.stage}>
          {subStageItem.title}
        </MarkerTitle>
      </MarkerContent>
      {index + 1 < totalSubStages && (
        <MarkerConnector isActive={activeSubStageNumber > subStageItem.stage} />
      )}
    </MarkerWrapper>
  );
};

export default SubStageMarker;
