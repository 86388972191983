import { Interfaces } from '@configur-tech/upit-core-types';
import {
  DuplicationMode,
  DuplicationTarget,
} from '@configur-tech/upit-core-types/lib/enums/ResourceDuplication';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface DatasetCopyConfig {
  datasetIdToCopy: string;
  name: string;
}

export interface DatasetDuplicationResponse {
  activeDataCollection: string;
  datasetMeta: Interfaces.DatasetMetaOutput;
}

export default class DuplicationService {
  public static async duplicateDataset(
    token: string,
    duplicationMode: DuplicationMode,
    duplicationTarget: DuplicationTarget,
    datasetCopyConfig?: DatasetCopyConfig,
  ): Promise<DatasetDuplicationResponse> {
    const endpoint = process.env['REACT_APP_DUPLICATE_DATASET'] || '';

    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetDuplicationResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      duplicationMode,
      duplicationTarget,
      datasetCopyConfig,
    });

    return result?.data as DatasetDuplicationResponse;
  }
}
