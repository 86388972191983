import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import fourColIcon from '../../../assets/icons/stages/chart-project/four-col.svg';
import oneColIcon from '../../../assets/icons/stages/chart-project/one-col.svg';
import threeColIcon from '../../../assets/icons/stages/chart-project/three-col.svg';
import twoColIcon from '../../../assets/icons/stages/chart-project/two-col.svg';
import { AreaSizeDescription } from '../../../enums/Chart';
import useChart from '../../../hooks/chart/UseChart';
import useProject from '../../../hooks/project/UseProject';
import { StyledSubHeader } from '../../../main/theme';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

export interface ChartAddAreaModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EMPTY_PANEL = {
  panelType: Enums.PanelType.EMPTY,
};

const ChartAddAreaModal: FC<ChartAddAreaModalProps> = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { chart, addChart, editChart } = useChart();
  const [newChartAdded, setNewChartAdded] = useState<boolean>();
  const [areaSize, setAreaSize] = useState<Enums.AreaSize>();
  const { project, editProject } = useProject();

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Update project state
  useEffect(() => {
    (async () => {
      if (newChartAdded) {
        if (chart?._id) {
          const cloned = cloneDeep(project);
          if (!cloned.charts.includes(chart?._id)) {
            cloned.charts = cloned.charts
              ? [...cloned.charts, chart._id]
              : [chart._id];
            await editProject(cloned);
          }
          dispatch(hideLoading());
          dispatch(hideModal());
        }
      }
    })();
  }, [dispatch, editProject, project, chart?._id, newChartAdded]);

  const handleAddArea = async () => {
    let tempPanels: Interfaces.Panel[] = [];

    switch (areaSize) {
      case Enums.AreaSize.ONE_COLUMN:
        tempPanels = [EMPTY_PANEL];
        break;
      case Enums.AreaSize.TWO_COLUMN:
        tempPanels = [EMPTY_PANEL, EMPTY_PANEL];
        break;
      case Enums.AreaSize.THREE_COLUMN:
        tempPanels = [EMPTY_PANEL, EMPTY_PANEL, EMPTY_PANEL];
        break;
      case Enums.AreaSize.FOUR_COLUMN:
        tempPanels = [EMPTY_PANEL, EMPTY_PANEL, EMPTY_PANEL, EMPTY_PANEL];
        break;
    }

    const cloned = cloneDeep(chart);
    cloned.areas.push({
      areaSize: areaSize,
      panels: tempPanels,
    });

    dispatch(showLoading({ text: 'Adding Area...' }));

    if (chart?._id) {
      await editChart(cloned);
      dispatch(hideLoading());
      dispatch(hideModal());
    } else {
      await addChart(cloned);
      setNewChartAdded(true);
    }
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>Add Area</SC.Header>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={themeContext.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>

      <SC.ContentContainer>
        <SC.Content>
          <StyledSubHeader>Select an Area Type</StyledSubHeader>

          <SC.ActionWrapper>
            <div>
              <FeatureButton
                action={() => setAreaSize(Enums.AreaSize.ONE_COLUMN)}
                size={FeatureButtonSize.MEDIUM}
                color={themeContext.colors.general.blue}
                image={oneColIcon}
                isActive={areaSize === Enums.AreaSize.ONE_COLUMN}
              />
              <SC.ItemHeader>{AreaSizeDescription.ONE_COLUMN}</SC.ItemHeader>
            </div>
            <div>
              <FeatureButton
                action={() => setAreaSize(Enums.AreaSize.TWO_COLUMN)}
                size={FeatureButtonSize.MEDIUM}
                color={themeContext.colors.general.blue}
                image={twoColIcon}
                isActive={areaSize === Enums.AreaSize.TWO_COLUMN}
              />
              <SC.ItemHeader>{AreaSizeDescription.TWO_COLUMN}</SC.ItemHeader>
            </div>

            <div>
              <FeatureButton
                action={() => setAreaSize(Enums.AreaSize.THREE_COLUMN)}
                size={FeatureButtonSize.MEDIUM}
                color={themeContext.colors.general.blue}
                image={threeColIcon}
                isActive={areaSize === Enums.AreaSize.THREE_COLUMN}
              />
              <SC.ItemHeader>{AreaSizeDescription.THREE_COLUMN}</SC.ItemHeader>
            </div>
            <div>
              <FeatureButton
                action={() => setAreaSize(Enums.AreaSize.FOUR_COLUMN)}
                size={FeatureButtonSize.MEDIUM}
                color={themeContext.colors.general.blue}
                image={fourColIcon}
                isActive={areaSize === Enums.AreaSize.FOUR_COLUMN}
              />
              <SC.ItemHeader>{AreaSizeDescription.FOUR_COLUMN}</SC.ItemHeader>
            </div>
          </SC.ActionWrapper>
        </SC.Content>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper>
        <FeatureButton
          isDisabled={!areaSize}
          action={() => handleAddArea()}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={`Save Area`}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default ChartAddAreaModal;
