import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IntegrationTemplateService, {
  IntegrationTemplateItem,
  IntegrationTemplateOutput,
} from '../../services/integration/IntegrationTemplateService';
import { AppThunk } from '../store';
import InitialIntegrationTemplate from './inital-state';

type IntegrationTemplate = IntegrationTemplateItem | IntegrationTemplateOutput;

export interface IntegrationTemplateState {
  data: IntegrationTemplate;
  loading: boolean;
  error: string | null;
}

const initialState: IntegrationTemplateState = {
  data: InitialIntegrationTemplate,
  loading: false,
  error: null,
};

const IntegrationTemplateSlice = createSlice({
  name: 'integration-template',
  initialState,
  reducers: {
    // Reset
    resetIntegrationTemplate(state) {
      state.data = InitialIntegrationTemplate;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchIntegrationTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchIntegrationTemplateSuccess(
      state,
      action: PayloadAction<IntegrationTemplate>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchIntegrationTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createIntegrationTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    createIntegrationTemplateSuccess(
      state,
      action: PayloadAction<IntegrationTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createIntegrationTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateIntegrationTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateIntegrationTemplateSuccess(
      state,
      action: PayloadAction<IntegrationTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateIntegrationTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteIntegrationTemplateStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteIntegrationTemplateSuccess(
      state,
      action: PayloadAction<IntegrationTemplateOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteIntegrationTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetIntegrationTemplate,
  fetchIntegrationTemplateStart,
  fetchIntegrationTemplateSuccess,
  fetchIntegrationTemplateFailure,
  createIntegrationTemplateStart,
  createIntegrationTemplateSuccess,
  createIntegrationTemplateFailure,
  updateIntegrationTemplateStart,
  updateIntegrationTemplateSuccess,
  updateIntegrationTemplateFailure,
  deleteIntegrationTemplateStart,
  deleteIntegrationTemplateSuccess,
  deleteIntegrationTemplateFailure,
} = IntegrationTemplateSlice.actions;

export default IntegrationTemplateSlice.reducer;

export const fetchIntegrationTemplate =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchIntegrationTemplateStart());
      const fetched = await IntegrationTemplateService.getIntegrationTemplate(
        token,
        id,
      );
      dispatch(fetchIntegrationTemplateSuccess(fetched));
    } catch (err) {
      dispatch(fetchIntegrationTemplateFailure((err as string).toString()));
      throw Error((err as string).toString());
    }
  };

export const createIntegrationTemplate =
  (
    token: string,
    integrationTemplate: Interfaces.IntegrationTemplate,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createIntegrationTemplateStart());
      const created = await IntegrationTemplateService.postIntegrationTemplate(
        token,
        integrationTemplate,
        userId,
      );
      dispatch(createIntegrationTemplateSuccess(created));
    } catch (err) {
      dispatch(createIntegrationTemplateFailure((err as string).toString()));
      throw err;
    }
  };

export const updateIntegrationTemplate =
  (
    token: string,
    integrationTemplate: Interfaces.IntegrationTemplateOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateIntegrationTemplateStart());
      const updated = await IntegrationTemplateService.putIntegrationTemplate(
        token,
        integrationTemplate,
        userId,
      );
      dispatch(updateIntegrationTemplateSuccess(updated));
    } catch (err) {
      dispatch(updateIntegrationTemplateFailure((err as string).toString()));
      throw err;
    }
  };

export const deleteIntegrationTemplate =
  (token: string, integrationTemplateId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteIntegrationTemplateStart());
      const deleted =
        await IntegrationTemplateService.deleteIntegrationTemplate(
          token,
          integrationTemplateId,
          userId,
        );
      dispatch(deleteIntegrationTemplateSuccess(deleted));
    } catch (err) {
      dispatch(deleteIntegrationTemplateFailure((err as string).toString()));
      throw err;
    }
  };
