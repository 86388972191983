import { Enums } from '@configur-tech/upit-core-types';
import {
  faClipboardList,
  faSearch,
  faShareAlt,
  faTable,
} from '@fortawesome/pro-solid-svg-icons';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import { DefaultPopupStyles } from '../../main/theme';
import { resetStagesAndSubStages } from '../../store/portal-stage';
import FAButton from '../FaButton/FAButton';
import * as SC from './styled';

const routeData = {
  [Enums.ConnectionType.CMS]: {
    title: 'Workspaces',
    icon: faTable,
  },
  [Enums.ConnectionType.FORM]: {
    title: 'Forms',
    icon: faClipboardList,
  },
  [Enums.ConnectionType.API]: {
    title: 'APIs',
    icon: faShareAlt,
  },
};

const PORTAL_CMS = 'portal-cms';
const CMS = 'cms';

const PortalSystemShortcuts: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);

  const { portal } = usePortal();

  const [availableConnectionTypes, setAvailableConnectionTypes] = useState<
    Enums.ConnectionType[]
  >([]);
  const [lastVisitedDataset, setLastVisitedDataset] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (localStorage.getItem(PORTAL_CMS)?.includes(portal?._id as string)) {
      setLastVisitedDataset(localStorage.getItem(PORTAL_CMS));
    }
  }, [portal?._id, location.pathname, portal?.connectionItems?.cms]);

  useEffect(() => {
    if (portal) {
      const types: Enums.ConnectionType[] = [];
      if (portal.connections.cmsIds.length) {
        types.push(Enums.ConnectionType.CMS);
      }
      if (portal.connections.formIds.length) {
        types.push(Enums.ConnectionType.FORM);
      }
      if (portal.connections.apiIds.length) {
        types.push(Enums.ConnectionType.API);
      }

      if (types.length) {
        setAvailableConnectionTypes(types);
      }
    }
  }, [portal]);

  if (!portal) {
    return null;
  }

  return (
    <SC.Wrapper>
      {availableConnectionTypes.map((c, i) => (
        <Popup
          key={`shortcut-${c}-${i}`}
          content={routeData[c].title}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FAButton
                action={() =>
                  c === CMS
                    ? lastVisitedDataset
                      ? location.pathname.includes(CMS)
                        ? undefined
                        : history.push(lastVisitedDataset)
                      : history.push(`${RouteName.PORTAL}/${portal?._id}/${c}`)
                    : history.push(`${RouteName.PORTAL}/${portal?._id}/${c}`)
                }
                icon={routeData[c].icon}
                color={
                  location.pathname.includes(c)
                    ? themeContext.colors.general.blue
                    : themeContext.colors.system.white
                }
                size={'2x'}
              />
            </div>
          }
        />
      ))}

      {portal?.allowAggregations && (
        <Popup
          key={`shortcut-query`}
          content={'Queries'}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FAButton
                action={() => {
                  dispatch(resetStagesAndSubStages());
                  history.push(`${RouteName.PORTAL}/${portal?._id}/query`);
                }}
                icon={faSearch}
                color={
                  location.pathname.includes('query') &&
                  !location.pathname.includes(RouteName.CMS)
                    ? themeContext.colors.general.blue
                    : themeContext.colors.system.white
                }
                size={'2x'}
              />
            </div>
          }
        />
      )}
    </SC.Wrapper>
  );
};

export default PortalSystemShortcuts;
