import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UsageService, { UsageQuotas } from '../../services/usage/UsageService';
import { AppThunk } from '../store';
import InitialUsage from './initial-usage';

type OrgUsage = UsageQuotas;

export interface UsageState {
  data: OrgUsage;
  loading: boolean;
  error: string | null;
}

const initialState: UsageState = {
  data: InitialUsage,
  loading: false,
  error: null,
};

const UsageSlice = createSlice({
  name: 'org usage',
  initialState,
  reducers: {
    // Fetch
    fetchOrgUsageStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchOrgUsageSuccess(state, action: PayloadAction<OrgUsage>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchOrgUsageFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchOrgUsageFailure,
  fetchOrgUsageStart,
  fetchOrgUsageSuccess,
} = UsageSlice.actions;

export default UsageSlice.reducer;

export const fetchOrgUsage =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchOrgUsageStart());
      const fetched = await UsageService.getUsageMetrics(token, id);
      dispatch(fetchOrgUsageSuccess(fetched));
    } catch (err) {
      dispatch(fetchOrgUsageFailure((err as string).toString()));
    }
  };
