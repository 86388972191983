import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { Interfaces } from '@configur-tech/upit-core-types';
import { GraphAxisMetric } from '@configur-tech/upit-core-types/lib/interfaces';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { startCase } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import * as SC from './styled';

export interface GoogleMapData {
  latitude: string;
  longitude: string;
  markerTitle: string;
  additionalMarkers: Interfaces.QueryField[];
}

interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  data: [GoogleMapData];
  dataDrilldown?: (rowId: string) => void;
  radius?: number;
  markerColour?: GraphAxisMetric;
  markerColourValues?: Interfaces.ListValue[];
}

const HTML_BUTTON_ID = 'title';
const HTML_BUTTON_INDEX_ATTRIBUTE = 'data-index';

const DEFAULT_RADIUS_STROKE_COLOUR = '#3498db';
const DEFAULT_RADIUS_STROKE_OPACITY = 0.6;
const DEFAULT_RADIUS_STROKE_WEIGHT = 2;
const DEFAULT_RADIUS_FILL_COLOUR = '#3498db';
const DEFAULT_RADIUS_FILL_OPACITY = 0.3;
const DEFAULT_RADIUS_MARKER_COLOUR = '#ea4135';
const DEFAULT_RADIUS_MARKER_STROKE_COLOUR = '#2e2f30';

const GoogleMap: FC<MapProps> = ({
  data,
  dataDrilldown,
  radius,
  markerColour,
  markerColourValues,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [prevData, setPrevData] = useState<[GoogleMapData]>();
  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [radiusCircles, setRadiusCircles] = useState<google.maps.Circle[]>([]);
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState<string>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new google.maps.Map(ref.current, {
          maxZoom: 19,
        }),
      );
    }
  }, [ref, map]);

  // Navigate to filtered dataset view when marker clicked
  useEffect(() => {
    if (!clickedMarkerIndex || !dataDrilldown) {
      return;
    }
    dataDrilldown(clickedMarkerIndex);
  }, [dataDrilldown, clickedMarkerIndex]);

  useEffect(() => {
    if (map && data.length) {
      // If data is the same don't update the map
      if (data === prevData || !dataDrilldown) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      const infoWindow = new google.maps.InfoWindow();
      const tempMarkers: google.maps.Marker[] = [];
      const tempRadiusCircles: google.maps.Circle[] = [];

      infoWindow.addListener('domready', () => {
        const button = document.getElementById(HTML_BUTTON_ID);
        if (button) {
          button.addEventListener('click', () => {
            const index = button.getAttribute(HTML_BUTTON_INDEX_ATTRIBUTE);
            index && setClickedMarkerIndex(index);
          });
        }
      });

      // Remove any markers from map
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }

      // Remove any radiusCircles from map
      for (let i = 0; i < radiusCircles.length; i++) {
        radiusCircles[i].setMap(null);
      }

      data?.forEach((mapItem, index) => {
        if (!mapItem.latitude || !mapItem.longitude || !mapItem.markerTitle) {
          return;
        }

        const markerPosition = {
          lat: +mapItem.latitude,
          lng: +mapItem.longitude,
        };

        const additionalMarkerText = mapItem.additionalMarkers
          .map((markerItem) =>
            Object.entries(markerItem)?.map(
              ([key, value]) =>
                `<li><p><strong>${startCase(key)}:</strong> ${value}</p></li>`,
            ),
          )
          .join('');

        const additionalMarkerList = mapItem.additionalMarkers.length
          ? `<div id="bodyContent"><ul id="list">` +
            additionalMarkerText +
            '</ul></div>'
          : '';

        const button =
          `<button id=${HTML_BUTTON_ID} data-index='${index}'>` +
          mapItem.markerTitle?.toString() +
          '</button>';

        const contentString =
          `<div id="content">` + button + additionalMarkerList + '</div>';

        // Get any list applicable list colour
        let colour: string | undefined;
        if (markerColour?.field.alias) {
          const alias = markerColour.field.alias;
          const colourVal = mapItem.additionalMarkers.find((item) =>
            Object.keys(item).find((k) => k === alias),
          );

          if (colourVal && markerColour.field.alias) {
            colour = markerColourValues?.find(
              (col) => col.value === colourVal[alias],
            )?.color;
          }
        }

        // Create markers
        const svgMarker = {
          path: faLocationDot.icon[4] as string,
          fillColor: colour || DEFAULT_RADIUS_MARKER_COLOUR,
          fillOpacity: 0.85,
          anchor: new google.maps.Point(
            faLocationDot.icon[0] / 2,
            faLocationDot.icon[1] - 200,
          ),
          strokeWeight: 1,
          strokeColor: DEFAULT_RADIUS_MARKER_STROKE_COLOUR,
          strokeOpacity: 0.5,
          scale: 0.065,
        };

        const marker = new google.maps.Marker({
          position: markerPosition,
          map,
          title: mapItem.markerTitle?.toString(),
          optimized: false,
          icon: svgMarker,
        });

        tempMarkers.push(marker);

        // Extend the bounds to include position
        bounds.extend(markerPosition);

        // Add a click listener and info window for marker
        marker.addListener('click', () => {
          infoWindow.close();
          infoWindow.setContent(contentString);
          infoWindow.open(marker.getMap(), marker);
        });

        // Create Radius
        const radiusCircle = new google.maps.Circle({
          center: markerPosition,
          radius: radius,
          map,
          strokeColor: colour || DEFAULT_RADIUS_STROKE_COLOUR,
          strokeOpacity: DEFAULT_RADIUS_STROKE_OPACITY,
          strokeWeight: DEFAULT_RADIUS_STROKE_WEIGHT,
          fillColor: colour || DEFAULT_RADIUS_FILL_COLOUR,
          fillOpacity: DEFAULT_RADIUS_FILL_OPACITY,
        });

        tempRadiusCircles.push(radiusCircle);
      });

      // Update stored markers and radiusCircles
      setMarkers(tempMarkers);
      setRadiusCircles(tempRadiusCircles);

      // Create clusters
      const clusters = new MarkerClusterer({ map, markers: tempMarkers });

      // Limit clusters/markers to only what is in view
      map.addListener('tilesloaded', () => {
        clusters.clearMarkers();
        clusters.addMarkers(
          tempMarkers.filter((marker) => {
            const bounds = map.getBounds();
            const pos = marker.getPosition();
            return marker.getVisible() && bounds && pos && bounds.contains(pos);
          }),
        );
      });

      // Fit the map to the bounds
      map?.fitBounds(bounds);

      setPrevData(data);
    }
  }, [
    data,
    dataDrilldown,
    map,
    markers,
    prevData,
    clickedMarkerIndex,
    radiusCircles,
    markerColour?.field.alias,
    radius,
    markerColourValues,
  ]);

  return (
    <>
      {!data.length && (
        <SC.GoogleMapError>
          This map has no markers to display
        </SC.GoogleMapError>
      )}

      <SC.GoogleMapContainer
        ref={ref}
        style={data.length ? { display: 'block' } : { display: 'none' }}
      />
    </>
  );
};

export default GoogleMap;
