import { CMSConnectionConfigurationOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { EntityType } from '../../enums';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import useProject from '../../hooks/project/UseProject';
import { updateActiveConnection } from '../../store/project-stage';
import * as SC from './styled';

const CMS = 'cms';
const GROUP_KEY = 'groups';
interface DropdownProps {
  key: string;
  value: string;
  text: string;
  groups: CMSConnectionConfigurationOutput[typeof GROUP_KEY];
}

const PortalCMSHeader: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { activeConnection } = useProject();
  const { portal } = usePortal();
  const [dropdownOptions, setDropdownOptions] = useState<DropdownProps[]>();
  const [previousConnection, setPreviousConnection] = useState<string>();

  useEffect(() => {
    // Generate dropdown options including groups for URL generation
    const workspaceDropdown = portal?.connectionItems?.cms.map((item) => {
      return {
        key: item.name,
        value: item._id,
        text: item.name,
        groups: (item.configuration as CMSConnectionConfigurationOutput).groups,
      };
    });

    setDropdownOptions(workspaceDropdown);
  }, [portal?._id, portal?.connectionItems?.cms]);

  if (!activeConnection?._id || !params?.cmsId) {
    return <SC.Wrapper />;
  }

  // Handle changing workspace via dropdown
  const handleWorkspaceChange = (value) => {
    // Find workspace selected by dropdown
    const selectedWorkspace = dropdownOptions?.find(
      (item) => item.value === value,
    );

    // Figure out DataType for URL generation
    const dataType = selectedWorkspace?.groups[0].items[0].datasetMetaId
      ? EntityType.DATASET
      : selectedWorkspace?.groups[0].items[0].aggregationId
      ? EntityType.AGGREGATION
      : EntityType.CHART;

    // Find active connection by ID and set it as active connection
    const newActiveConnection = portal?.connectionItems?.cms.find(
      (workspace) => workspace._id === value,
    );

    const itemId = previousConnection?.split('/').pop();

    setPreviousConnection(window.location.pathname);

    dispatch(updateActiveConnection(newActiveConnection));

    if (activeConnection)
      // Generate URL based on DataType
      switch (dataType) {
        case EntityType.DATASET:
          history.push({
            pathname:
              previousConnection &&
              previousConnection.includes(itemId as string)
                ? previousConnection
                : `${RouteName.PORTAL}/${portal?._id}/${CMS}/${value}/${selectedWorkspace?.groups[0]._id}/${dataType}/${selectedWorkspace?.groups[0].items[0].datasetMetaId}`,
          });
          break;
        case EntityType.AGGREGATION:
          history.push({
            pathname: previousConnection?.includes(value)
              ? previousConnection
              : `${RouteName.PORTAL}/${portal?._id}/${CMS}/${value}/${selectedWorkspace?.groups[0]._id}/${dataType}/${selectedWorkspace?.groups[0].items[0].aggregationId}`,
          });
          break;
        case EntityType.CHART:
          history.push({
            pathname: previousConnection?.includes(value)
              ? previousConnection
              : `${RouteName.PORTAL}/${portal?._id}/${CMS}/${value}/${selectedWorkspace?.groups[0]._id}/${dataType}/${selectedWorkspace?.groups[0].items[0].chartId}`,
          });
          break;
      }
  };

  return (
    <SC.Wrapper>
      <SC.PortalDropdown
        placeholder={activeConnection.name}
        selection
        options={dropdownOptions}
        selectOnBlur={false}
        defaultValue={activeConnection.name}
        onChange={(e, { value }) => handleWorkspaceChange(value)}
      />
    </SC.Wrapper>
  );
};

export default PortalCMSHeader;
