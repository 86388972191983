import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface DatasetEventItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.EventOutput & {
    createdByUser: Interfaces.UserOutput[];
  };
}

export interface PipelineEventItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.EventOutput & {
    pipelineEvents: Interfaces.EventOutput[];
  };
}

export interface DatasetEventsItemOutput {
  data: DatasetEventItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export interface PipelineEventsItemOutput {
  data: PipelineEventItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class EventsService {
  public static async getDatasetEvents(
    token: string,
    resourceId: string,
    pageNum = 0,
    limit?: number,
    orderBy?: Record<string, number>,
  ): Promise<DatasetEventsItemOutput> {
    const endpoint = process.env['REACT_APP_CRUD_EVENTS'] || '';
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<DatasetEventsItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      'eventResource.resourceId': resourceId,
      limit,
      pageNum,
      orderBy,
    });

    return result?.data as DatasetEventsItemOutput;
  }

  public static async getPipelineEvents(
    token: string,
    pipelineId: string,
    pageNum = 0,
    limit?: number,
    orderBy?: Record<string, number>,
  ): Promise<PipelineEventsItemOutput> {
    const endpoint = process.env['REACT_APP_CRUD_EVENTS'] || '';
    const pipelineEventParams = `?groupKey=eventOriginInvocationId&outputKey=pipelineEvents`;
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<PipelineEventsItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/aggregate${pipelineEventParams}`, {
      'eventResource.resourceId': pipelineId,
      limit,
      pageNum,
      orderBy,
    });

    return result?.data as PipelineEventsItemOutput;
  }
}
