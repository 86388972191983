import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import QueryService from '../../services/query/QueryService';
import { QueryItem, QueryItemOutput } from '../queries';
import { AppThunk } from '../store';
import InitialQuery from './initial-state';

type Query = QueryItem | QueryItemOutput;

export interface QueryState {
  data: Query;
  loading: boolean;
  error: string | null;
}

const initialState: QueryState = {
  data: InitialQuery,
  loading: false,
  error: null,
};

const QuerySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    // Reset
    resetQuery(state) {
      state.data = InitialQuery;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchQueryStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchQuerySuccess(state, action: PayloadAction<Query>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchQueryFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createQueryStart(state) {
      state.loading = true;
      state.error = null;
    },
    createQuerySuccess(state, action: PayloadAction<QueryItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createQueryFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateQueryStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateQuerySuccess(state, action: PayloadAction<QueryItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateQueryFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteQueryStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteQuerySuccess(state, action: PayloadAction<QueryItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteQueryFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetQuery,
  fetchQueryStart,
  fetchQuerySuccess,
  fetchQueryFailure,
  createQueryStart,
  createQuerySuccess,
  createQueryFailure,
  updateQueryStart,
  updateQuerySuccess,
  updateQueryFailure,
  deleteQueryStart,
  deleteQuerySuccess,
  deleteQueryFailure,
} = QuerySlice.actions;

export default QuerySlice.reducer;

export const fetchQuery =
  (token: string, id: string, portalId?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchQueryStart());
      const fetched = await QueryService.getQuery(token, id, portalId);
      dispatch(fetchQuerySuccess(fetched));
    } catch (err) {
      dispatch(fetchQueryFailure((err as string).toString()));
    }
  };

export const createQuery =
  (
    token: string,
    query: Interfaces.Query,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createQueryStart());
      const created = await QueryService.postQuery(
        token,
        query,
        userId,
        portalId,
      );
      dispatch(createQuerySuccess(created));
    } catch (err) {
      dispatch(createQueryFailure((err as string).toString()));
    }
  };

export const updateQuery =
  (
    token: string,
    project: Interfaces.QueryOutput,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateQueryStart());
      const updated = await QueryService.putQuery(
        token,
        project,
        userId,
        portalId,
      );
      dispatch(updateQuerySuccess(updated));
    } catch (err) {
      dispatch(updateQueryFailure((err as string).toString()));
    }
  };

export const deleteQuery =
  (
    token: string,
    projectId: string,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteQueryStart());
      const deleted = await QueryService.deleteQuery(
        token,
        projectId,
        userId,
        portalId,
      );
      dispatch(deleteQuerySuccess(deleted));
    } catch (err) {
      dispatch(deleteQueryFailure((err as string).toString()));
    }
  };
