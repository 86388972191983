import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps, Icon, Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../enums';
import { RouteName } from '../../../enums/RouteName';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../hooks/project/UseProject';
import { SearchQuery } from '../../../interfaces/Search';
import {
  DefaultPopupStyles,
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledDropdownUltraWide,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { hideLoading, showLoading } from '../../../store/loading';
import { updateActiveProjectStage } from '../../../store/project-stage';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

const SEARCH_TYPING_TIMEOUT = 1000;
const SEARCH_FIELD = 'name';
const PREV_STAGE = Enums.ProjectStage.CREATION;
const DATASET_LIMIT = 300;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const datasetOptions: Map<string, DropdownItemProps> = new Map();

const ProjectDatasetsStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject } = useProject();
  const { getDatasetMetas } = useDatasetMeta();

  const [loadingDatasetMetas, setLoadingDatasetMetas] =
    useState<boolean>(false);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [processComplete, setProcessComplete] = useState<boolean>(false);

  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });

  const [projectDSMIds, setProjectDSMIds] = useState<string[]>([]);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const handleDatasetSelection = (val: string[]) => {
    setProjectDSMIds(val);
    setDataHasChanged(true);
  };

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (value !== searchQuery.query) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  // Set initial Ids
  useEffect(() => {
    if (project?.datasetMetaId) {
      setProjectDSMIds(project.datasetMetaId);
    }
  }, [project?.datasetMetaId]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        setLoadingDatasetMetas(true);
        if (!searchQuery?.query?.length && project?.datasetMetaId.length) {
          await getDatasetMetas(
            {
              projectId: project._id,
              activeDataCollection: { $ne: null },
            },
            undefined,
            undefined,
            DATASET_LIMIT,
          );
        } else {
          await getDatasetMetas(
            { activeDataCollection: { $ne: null } },
            searchQuery?.query?.length ? searchQuery : undefined,
          );
        }

        setLoadingDatasetMetas(false);
      }
    })();
  }, [dispatch, getDatasetMetas, project, searchQuery]);

  // Map datasetMetas to options - add to array as users may search for more options
  useEffect(() => {
    if (datasetMetas?.length) {
      datasetMetas.map((d) => {
        const ent = d.entity;

        datasetOptions.set(ent._id, {
          key: ent._id,
          value: ent._id,
          text:
            d.accessLevel === Enums.AccessLevel.NONE ? (
              <Popup
                content={'You do not have direct access to this Dataset.'}
                position="top center"
                style={DefaultPopupStyles}
                trigger={
                  <div style={{ display: 'inline-block' }}>
                    <Icon name={'attention'} />
                    {ent.name}
                  </div>
                }
              />
            ) : (
              ent.name
            ),
        });
      });
    }
  }, [datasetMetas]);

  useEffect(() => {
    if (processComplete && project?._id) {
      // ProcessAction has completed successfully
      // Move on to next stage
      history.push(`${RouteName.PROJECT_ITEM}/${project._id}`);
      dispatch(updateActiveProjectStage(PREV_STAGE));
    }
  }, [processComplete, project?._id, dispatch, history]);

  const processAction = async () => {
    if (dataHasChanged) {
      dispatch(
        showLoading({
          text: `Adding Datasets...`,
        }),
      );

      // Update project
      const cloned = cloneDeep(project);
      cloned.datasetMetaId = projectDSMIds;

      // Update current status
      cloned.stages[Enums.ProjectStage.DATASETS].status =
        Enums.StageStatus.COMPLETED;

      // Save project
      await editProject(cloned as Interfaces.ProjectOutput);

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <Banner
            message={
              <p>
                Projects have been removed from Configur and these options are
                now read-only.
              </p>
            }
            alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
            alertStatus={AlertStatus.INFO}
          />

          <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
            Which datasets would you like to have access to within this project?
          </StageBodyText>

          <StyledDropdownUltraWide
            disabled
            selectOnBlur={false}
            loading={loadingDatasetMetas}
            placeholder={'Select a Dataset'}
            noResultsMessage={'Type to search Datasets'}
            selection
            multiple
            search
            onSearchChange={(e, data) => onSearchValueChange(data.searchQuery)}
            clearable
            options={Array.from(datasetOptions, ([, opt]) => opt)}
            value={projectDSMIds}
            onChange={(e, data) => handleDatasetSelection(data.value)}
          />
        </Section>
      </StageInner>

      <ActionBar
        primaryButton={
          <FeatureButton
            isDisabled={!projectDSMIds.length || !dataHasChanged}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectDatasetsStage;
