import { Enums } from '@configur-tech/upit-core-types';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Toggle from 'react-toggle';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../../../enums/ProjectStage';
import useChart from '../../../../hooks/chart/UseChart';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../../hooks/project/UseProject';
import {
  defaultTheme,
  StageWrapper,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { resetGraph } from '../../../../store/graph';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import {
  ProjectChartSubStage,
  ProjectGraphSubStage,
} from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import ChartBuilder from '../../../ChartBuilder/ChartBuilder';
import {
  ToggleLabel,
  ToggleWrapper,
  Wrapper,
} from '../../../CollectionSelection/styled';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from '../styled';
import DraggableGraphItem from './DraggableGraphItem';
import GraphItem from './GraphItem';

const PREV_STAGE = ProjectChartSubStage.NAME;

const ProjectItemChartBuilderStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject, projectAccessLevel } = useProject();
  const { chart, addChart, editChart, updateChartContent } = useChart();
  const { getDatasetMetas } = useDatasetMeta();

  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [chartUpdated, setChartUpdated] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>();
  const [activeGraphItemName, setActiveGraphItemName] = useState<string>();

  const usedGraphIds =
    chart && chart.areas
      ? chart.areas
          .flatMap((area) => area.panels)
          .filter((panel) => panel.graphId)
          .map((filteredPanel) => filteredPanel.graphId)
      : [];
  const availableGraphs =
    chart && usedGraphIds && chart?.graphs
      ? chart.graphs.filter((graph) => !usedGraphIds.includes(graph._id))
      : [];

  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const handleTogglePreview = async () => {
    dispatch(
      showLoading({
        text: `${!readOnly ? 'Enabling' : 'Disabling'} Chart Preview...`,
      }),
    );

    // Save chart
    const cloned = cloneDeep(chart);
    await editChart(cloned);

    setShowSidebar(readOnly);
    setReadOnly(!readOnly);

    dispatch(hideLoading());
  };

  const handleDragStart = (event) => {
    setActiveGraphItemName(
      chart?.graphs?.find((g) => g._id === event.active.id)?.name,
    );
  };

  const handleDragEnd = async ({ active, over }) => {
    setActiveGraphItemName(undefined);

    if (!over?.id || !active.id) {
      return;
    }

    // Add graphId to chart
    const cloned = cloneDeep(chart);

    const droppedAreaIndex = cloned.areas.findIndex(
      (a) => a._id === over.data.current.areaId,
    );
    const droppedPanelIndex = cloned.areas[droppedAreaIndex].panels.findIndex(
      (p) => p._id === over.data.current.panelId,
    );

    cloned.areas[droppedAreaIndex].panels[droppedPanelIndex] = {
      ...cloned.areas[droppedAreaIndex].panels[droppedPanelIndex],
      graphId: active.id,
      panelType: Enums.PanelType.GRAPH,
    };

    await updateChartContent(cloned);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const processAction = async (isComplete: boolean) => {
    if (chart) {
      setIsComplete(isComplete);
      dispatch(showLoading({ text: 'Saving Dashboard...' }));

      if (chart._id) {
        await editChart(chart);
        setChartUpdated(true);
        dispatch(hideLoading());
      } else {
        await addChart(chart);
        setChartUpdated(true);
        dispatch(hideLoading());
      }
    }
  };

  // Update project state
  useEffect(() => {
    (async () => {
      if (chartUpdated) {
        if (chart?._id) {
          const cloned = cloneDeep(project);
          if (!cloned.charts.includes(chart?._id)) {
            cloned.charts = cloned.charts
              ? [...cloned.charts, chart._id]
              : [chart._id];

            await editProject(cloned);
          }
          if (isComplete) {
            await dispatch(updateActiveProjectSubStage(undefined));
          } else {
            setChartUpdated(false);
            dispatch(hideLoading());
          }
        }
      }
    })();
  }, [
    dispatch,
    editProject,
    project,
    chart?._id,
    chartUpdated,
    projectAccessLevel,
    isComplete,
  ]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        await getDatasetMetas({
          projectId: project._id,
          activeDataCollection: { $ne: null },
        });
      }
    })();
  }, [getDatasetMetas, project]);

  return (
    <StageWrapper>
      <SC.GridWrapper showFilters={showSidebar}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SC.PageInner>
            <SC.PageHeader>
              <StyledSubHeader>{chart?.name}</StyledSubHeader>

              <div>
                <Wrapper>
                  <ToggleLabel>
                    {readOnly ? 'Disable' : 'Enable'} Preview
                  </ToggleLabel>

                  <ToggleWrapper>
                    <Toggle
                      checked={readOnly}
                      icons={false}
                      onChange={() => handleTogglePreview()}
                    />
                  </ToggleWrapper>
                </Wrapper>

                <FeatureButton
                  action={() => setShowSidebar(!showSidebar)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.sea}
                  text={`${showSidebar ? 'Hide' : 'Show'} sidebar`}
                  style={{ marginLeft: defaultTheme.margin.standard }}
                />
              </div>
            </SC.PageHeader>

            <SC.PageContent>
              <ChartBuilder readOnly={readOnly} />

              {!readOnly && (
                <>
                  <StyledText>
                    To add graphs and content, tap the below button to add a new
                    content area.
                  </StyledText>
                  <FeatureButton
                    action={() => {
                      dispatch(
                        showModal({
                          visible: true,
                          modal: ModalTypes.CHART_ADD_AREA,
                          fullScreen: true,
                        }),
                      );
                    }}
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.blue}
                    text={'Add Area'}
                  />
                </>
              )}
            </SC.PageContent>
          </SC.PageInner>
          <SC.Sidebar>
            <FeatureButton
              action={() => {
                if (chart) {
                  editChart(chart);
                }
                dispatch(resetGraph());
                dispatch(updateActiveProjectStage(ProjectStage.GRAPH));
                dispatch(
                  updateActiveProjectSubStage(ProjectGraphSubStage.NAME),
                );
              }}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'Add Graph'}
              isDisabled={!chart?.areas.length}
              containerStyle={{ width: '100%' }}
              style={{ width: '100%' }}
              faceStyle={{
                width: '100%',
              }}
            />

            <SC.GraphList>
              {Object.values(chart?.graphs || []).map((graph, i) => {
                return (
                  <SC.GraphListItem key={`graph-${graph}-${i}`}>
                    {graph._id && (
                      <DraggableGraphItem
                        id={graph._id}
                        text={graph.name}
                        active={!availableGraphs.includes(graph)}
                      />
                    )}
                  </SC.GraphListItem>
                );
              })}
              <DragOverlay>
                {activeGraphItemName ? (
                  <GraphItem text={activeGraphItemName} />
                ) : null}
              </DragOverlay>
            </SC.GraphList>
          </SC.Sidebar>
        </DndContext>
      </SC.GridWrapper>

      <ActionBar
        primaryButton={
          isProjectManager ? (
            <FeatureButton
              action={() => processAction(true)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Save Dashboard & Finish'}
            />
          ) : undefined
        }
        secondaryButton={
          isProjectManager ? (
            <FeatureButton
              action={() => processAction(false)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Save Dashboard'}
            />
          ) : undefined
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemChartBuilderStage;
