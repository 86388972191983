export enum ProjectStage {
  CREATION = 'creation',
  DATASETS = 'datasets',
  MODELLING = 'modelling',
  CONNECTION = 'connection',
  AGGREGATION = 'aggregation',
  CONFIGURATION = 'configuration',
  CHARTS = 'dashboards',
  GRAPH = 'graph',
}
