import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  NotificationEventOutputWithDetails,
  NotificationEventWithDetails,
} from '../../store/notification';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_NOTIFICATION_EVENTS'] || '';

export interface NotificationItem {
  accessLevel: Enums.AccessLevel;
  entity: NotificationEventWithDetails;
}

export interface NotificationItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: NotificationEventOutputWithDetails;
}

export interface NotificationItemsOutput {
  data: NotificationItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class NotificationService {
  public static async getNotifications(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<NotificationItemsOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<NotificationItemsOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getNotification(
    token: string,
    id: string,
  ): Promise<NotificationItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<NotificationItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id });

    return result?.data as NotificationItemOutput;
  }

  public static async putNotification(
    token: string,
    userId: string,
    notification?: Interfaces.NotificationEventOutput,
  ): Promise<NotificationItemOutput> {
    const ah = new HttpHelper(token);
    let result;

    if (notification) {
      result = await ah.put<
        | Interfaces.SuccessResponseBody<NotificationItemOutput>
        | Interfaces.ErrorResponseBody
      >(`${endpoint}/${notification._id}`, {
        data: notification,
        userId,
      });
    }

    return result?.data as NotificationItemOutput;
  }

  public static async markAllAsRead(
    token: string,
    userId: string,
  ): Promise<NotificationItemsOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<NotificationItemsOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/markAllAsRead`, {
      userId,
    });

    return result?.data as NotificationItemsOutput;
  }
}
