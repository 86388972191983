import {
  faAlignLeft,
  faAt,
  faBasketShopping,
  faBullhorn,
  faCalendarDays,
  faChartLine,
  faCloud,
  faCog,
  faCreditCard,
  faDatabase,
  faHeadset,
  faHeart,
  faListCheck,
  faMoneyBill1Wave,
  faPaintbrush,
  faPhone,
  faRectangleList,
  faSquarePollHorizontal,
  faUser,
  faUserCheck,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { IntegrationCategoryTags } from '../../enums';

const getIntegrationCategoryIcon = (
  category: IntegrationCategoryTags,
): IconDefinition => {
  switch (category) {
    case IntegrationCategoryTags.ACCOUNTING:
      return faMoneyBill1Wave;
    case IntegrationCategoryTags.CALENDAR:
      return faCalendarDays;
    case IntegrationCategoryTags.CLOUD_STORAGE:
      return faCloud;
    case IntegrationCategoryTags.CMS:
      return faRectangleList;
    case IntegrationCategoryTags.COMMUNICATION:
      return faPhone;
    case IntegrationCategoryTags.CRM:
      return faUser;
    case IntegrationCategoryTags.CUSTOMER_SUPPORT:
      return faHeadset;
    case IntegrationCategoryTags.DESIGN:
      return faPaintbrush;
    case IntegrationCategoryTags.DATA:
      return faDatabase;
    case IntegrationCategoryTags.ECOMMERCE:
      return faBasketShopping;
    case IntegrationCategoryTags.EMAIL:
      return faAt;
    case IntegrationCategoryTags.FORM:
      return faAlignLeft;
    case IntegrationCategoryTags.FUNDRAISING:
      return faHeart;
    case IntegrationCategoryTags.HR:
      return faUserCheck;
    case IntegrationCategoryTags.LEAD_GENERATION:
      return faChartLine;
    case IntegrationCategoryTags.MARKETING:
      return faBullhorn;
    case IntegrationCategoryTags.PAYMENT:
      return faCreditCard;
    case IntegrationCategoryTags.PROJECT_MANAGEMENT:
      return faListCheck;
    case IntegrationCategoryTags.SURVEY:
      return faSquarePollHorizontal;

    default:
      return faCog;
  }
};
export default getIntegrationCategoryIcon;
