import { FormQuestionOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { ThemeContext } from 'styled-components';
import { faPencilAlt, faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDraggableItem = styled.div<{ isDragging: boolean; draggableStyle }>`
  padding: ${({ theme }) => `${theme.padding.standard}`};
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > svg.pencil {
    opacity: 0;
    transition: 0.5s;
  }

  :hover {
    cursor: pointer;

    > svg {
      transition: 0.5s;
      opacity: 1;
    }
  }
`;

const StyledDraggableInner = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${({ theme }) => theme.margin.standard};

    :hover {
      cursor: grab;
    }
  }
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
    width: 180px;
    display: inline-block;
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const DraggableItem: React.FC<{
  item: FormQuestionOutput;
  index: number;
}> = ({ item, index }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Draggable
      key={`${item._id}-${index}`}
      draggableId={`${item._id}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <StyledDraggableItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <StyledDraggableInner>
            <FontAwesomeIcon
              icon={faBars}
              color={themeContext.colors.system.offBlack}
            />
            <p>{item.question}</p>
          </StyledDraggableInner>
          <FontAwesomeIcon
            className={'pencil'}
            icon={faPencilAlt}
            color={themeContext.colors.system.offBlack}
          />
        </StyledDraggableItem>
      )}
    </Draggable>
  );
};

export default DraggableItem;
