import { FC } from 'react';
import { useSelector } from 'react-redux';
import ListItemAccessStage from '../../components/ListItemStages/ListItemConfigurationStages/1-access/ListConfigureAccessStage';
import ListItemAdvancedStage from '../../components/ListItemStages/ListItemConfigurationStages/2-advanced/ListConfigureAdvancedStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import Page from '../../main/Page';
import {
  ListConfigureSubStage,
  ListStage,
} from '../../store/list-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getListStageTitle,
  getListSubStageContent,
} from '../../util/list-stage-content/ListStageContent';

const ListConfiguration: FC = () => {
  const listStageState = useSelector((state: RootState) => state.listStage);

  const activeStage = ListStage.CONFIGURATION;
  const activeSubStage = listStageState.stages[activeStage].activeSubStage;

  const stageTitle = getListStageTitle(activeStage);
  const subStageContent = getListSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ListConfigureSubStage.ACCESS:
        return <ListItemAccessStage />;
      case ListConfigureSubStage.ADVANCED:
        return <ListItemAdvancedStage />;
      default:
        return <ListItemAccessStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={subStageContent.image}
        title={subStageContent.title}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default ListConfiguration;
