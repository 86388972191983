import { Enums } from '@configur-tech/upit-core-types';
import FileService, {
  CreateFileDocumentResponse,
} from '../../services/file/FileService';

/**
 * Creates a File document in the database
 *
 * @param {string} token - Auth0 token
 * @param {string} fileName - Name of the file
 * @param {string} mimeType - Mime type of the file
 * @param {string} uploadId - UploadId provided by getFileUploadUrl
 * @param {string} orgId - Organisation ID of the uploader
 * @param {string} userId - User ID of the uploader
 * @param {string} [sheetName] - Name of sheet in file
 * @param {Enums['FilePurpose']} [filePurpose] - Purpose of file
 *
 * returns {CreateFileDocumentResponse} - File ID and File of the created File document
 */
const createFileDocument = async (
  token: string,
  fileName: string,
  mimeType: string,
  uploadId: string,
  orgId: string,
  userId: string,
  sheetName?: string,
  filePurpose?: Enums.FilePurpose,
): Promise<CreateFileDocumentResponse> => {
  const fileHandlerResult = await FileService.createFileDocument(
    token,
    fileName,
    mimeType,
    uploadId,
    orgId,
    userId,
    sheetName,
    filePurpose,
  );

  return fileHandlerResult?.data as CreateFileDocumentResponse;
};

export default createFileDocument;
