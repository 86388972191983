import styled from 'styled-components';
import { StyledText } from '../../main/theme';
import { BodyText } from '../Modal/enhancement/styled';

const CARD_IMAGE_SIZE = 48;
const CARD_ACTIONS_MARGIN_LEFT = 52;

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const Wrapper = styled.div<{
  noBorder?: boolean;
  fixedHeight?: number;
  fixedHeightReduction?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;

  border: ${({ theme, noBorder }) =>
    noBorder ? '' : `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const NoResults = styled.div`
  flex: 0 0 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge}`};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;

  border-radius: ${({ theme }) => theme.borders.radius};
  border: ${({ theme }) => `1px solid ${theme.colors.system.grey}`};
  background-color: ${({ theme }) => theme.colors.general.white};
  margin: ${({ theme }) => `${theme.margin.small} 0`};
  padding: ${({ theme }) => `${theme.padding.large} ${theme.padding.xlarge} `};
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardLogo = styled.div<{ image }>`
  width: ${CARD_IMAGE_SIZE}px;
  height: ${CARD_IMAGE_SIZE}px;

  margin-right: ${({ theme }) => theme.margin.xlarge};
  background: url(${({ image }) => image}) no-repeat center / 100%;
  flex-shrink: 0;
`;

export const CardDescription = styled(BodyText)`
  margin: 0;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${CARD_ACTIONS_MARGIN_LEFT}px;
`;

export const CardConditionText = styled(StyledText)`
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  color: ${({ theme }) => theme.colors.system.darkOffBlack};
`;

export const CardTitle = styled(StyledText)`
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.system.darkGrey};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const UndoLink = styled.a`
  text-decoration: underline;
  margin-left: ${({ theme }) => theme.margin.small};
`;
