import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ReactElement } from 'react';
import configurationAccessIcon from '../../assets/icons/stages/configuration/access.svg';
import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import configurationSnapshotIcon from '../../assets/icons/stages/configuration/snapshot.svg';
import createConfirmIcon from '../../assets/icons/stages/create-dataset/confirm.svg';
import createNameIcon from '../../assets/icons/stages/create-dataset/name.svg';
import createOptionsIcon from '../../assets/icons/stages/create-dataset/options.svg';
import createResultIcon from '../../assets/icons/stages/create-dataset/result.svg';
import createSourceSelectionIcon from '../../assets/icons/stages/create-dataset/source-selection.svg';
import createAvatarIcon from '../../assets/icons/stages/create-team/avatar.svg';
import eventsIcon from '../../assets/icons/stages/dataset-project/events.svg';
import structureIcon from '../../assets/icons/stages/structure-dataset/structure.svg';
import validateAnalyseIcon from '../../assets/icons/stages/validate-dataset/analyse.svg';
import validateResultFailureIcon from '../../assets/icons/stages/validate-dataset/result-failure.svg';
import validateResultSuccessIcon from '../../assets/icons/stages/validate-dataset/result-success.svg';
import {
  DatasetConfigureSubStage,
  DatasetCreateSubStage,
  DatasetEventsSubStage,
  DatasetStructureSubStage,
  DatasetSubStages,
  DatasetValidateSubStage,
} from '../../store/dataset-stage/initial-state';

export interface SubStageContent {
  stage: number;
  image: string;
  title: string;
  headerContent?: ReactElement;
}

export interface StageMeta {
  [key: string]: {
    title: string;
  };
}

export interface SubStageMeta {
  [key: string]: {
    subStages: Record<string, SubStageContent>;
  };
}

const stageMeta: StageMeta = {
  [Enums.DatasetStage.CREATION]: {
    title: 'Create Dataset',
  },
  [Enums.DatasetStage.STRUCTURE]: {
    title: 'Data Structure',
  },
  [Enums.DatasetStage.VALIDATION]: {
    title: 'Data Validation',
  },
  [Enums.DatasetStage.CONFIGURATION]: {
    title: 'Configuration',
  },
  ['events']: {
    title: 'Events',
  },
};

export const getStageTitle = (
  stage: Enums.DatasetStage | 'events',
  isReplacing?: boolean,
  isMerging?: boolean,
): string => {
  if (stage === Enums.DatasetStage.CREATION && isReplacing) {
    return 'Replace Dataset';
  }
  if (stage === Enums.DatasetStage.CREATION && isMerging) {
    return 'Merge Dataset';
  }
  return stageMeta[stage].title;
};

export const getSubStageContent = (
  stage: Enums.DatasetStage | 'events',
  subStage: DatasetSubStages,
  datasetMeta?: Interfaces.DatasetMeta,
  activeCollection?: string,
): SubStageContent => {
  let collection;
  if (datasetMeta) {
    const colLookUp = activeCollection || datasetMeta.activeDataCollection;
    collection = colLookUp
      ? datasetMeta.dataCollections?.find((c) => c._id === colLookUp)
      : datasetMeta.dataCollections?.[0];
  }

  const subStageContent: SubStageMeta = {
    ['events']: {
      subStages: {
        [DatasetEventsSubStage.VIEW]: {
          stage: 1,
          image: eventsIcon,
          title: `Recent Events`,
        },
      },
    },

    [Enums.DatasetStage.CREATION]: {
      subStages: {
        [DatasetCreateSubStage.NAME]: {
          stage: 1,
          image: createNameIcon,
          title: `Hi! My Name Is...`,
        },
        [DatasetCreateSubStage.AVATAR]: {
          stage: 1,
          image: createAvatarIcon,
          title: `Pick The Perfect Picture`,
        },
        [DatasetCreateSubStage.TYPE]: {
          stage: 2,
          image: createSourceSelectionIcon,
          title: 'Pick A Source, Any Source',
        },
        [DatasetCreateSubStage.CONFIGURE]: {
          stage: 3,
          image: createOptionsIcon,
          title: 'Define Your Options',
        },
        [DatasetCreateSubStage.CONFIRM]: {
          stage: 4,
          image: createConfirmIcon,
          title: 'Ready? Ignition',
        },
        [DatasetCreateSubStage.RESULT]: {
          stage: 5,
          image: createResultIcon,
          title: `That's A Result`,
        },
      },
    },

    [Enums.DatasetStage.STRUCTURE]: {
      subStages: {
        [DatasetStructureSubStage.DISCOVERY]: {
          stage: 1,
          image: validateAnalyseIcon,
          title: `Discover Your Dataset`,
        },
        [DatasetStructureSubStage.CONFIRMATION]: {
          stage: 2,
          image: structureIcon,
          title: 'Set Your Data Structure',
        },
      },
    },

    [Enums.DatasetStage.VALIDATION]: {
      subStages: {
        [DatasetValidateSubStage.RESULT]: {
          stage: 3,
          image: collection?.stages?.[Enums.DatasetStage.VALIDATION]?.result
            ?.failed
            ? validateResultFailureIcon
            : validateResultSuccessIcon,
          title: collection?.stages?.[Enums.DatasetStage.VALIDATION]?.result
            ?.failed
            ? 'Close, But We Want Perfection'
            : 'As Clean As A Whistle',
        },
      },
    },

    [Enums.DatasetStage.CONFIGURATION]: {
      subStages: {
        [DatasetConfigureSubStage.ACCESS]: {
          stage: 1,
          image: configurationAccessIcon,
          title: `Safe & Secure`,
        },
        [DatasetConfigureSubStage.SNAPSHOTS]: {
          stage: 2,
          image: configurationSnapshotIcon,
          title: 'Back It Up',
        },
        [DatasetConfigureSubStage.ADVANCED]: {
          stage: 2,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
