import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';

import { SearchQuery } from '../../../../interfaces/Search';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import * as SC from '../styled';

interface LookupProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
}

const SEARCH_FIELD = 'name';
const SEARCH_TYPING_TIMEOUT = 1000;

const DATASET_META_QUERY = {
  activeDataCollection: { $exists: true },
};
const DATASET_META_PAGE_NUM = 1;

const buildDropdownOptions = (items: Interfaces.DatasetMetaOutput[]) =>
  items.map((item) => {
    return {
      key: item._id,
      text: item.name,
      value: item._id,
    };
  });

const Lookup: FC<LookupProps> = ({
  field,
  schema,
  setDataValidation,
  setSuperColumnValid,
}) => {
  const { datasetMeta, getDatasetMetas } = useDatasetMeta();

  const [targetFieldOptions, setTargetFieldOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: '',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedDatasetMetaId, setSelectedDatasetMetaId] = useState<string>();
  const [selectedLocalFieldId, setSelectedLocalFieldId] = useState<string>();
  const [selectedTargetMatchFieldId, setSelectedTargetMatchFieldId] =
    useState<string>();
  const [selectedTargetDisplayFieldId, setSelectedTargetDisplayFieldId] =
    useState<string>();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (value !== searchQuery.query) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  // Set local field dropdown options
  const localFieldOptions = useMemo(() => {
    if (!schema) {
      return [];
    }

    return schema.map((schemaField, i) => ({
      key: `column-name-${schemaField.name}-${i}`,
      value: schemaField.fieldId || schemaField.name,
      text: schemaField.name,
    }));
  }, [schema]);

  // Set dataset dropdown options
  const datasetMetaOptions = useMemo(() => {
    if (!datasetMeta?._id || !datasetMetas) {
      return [];
    }

    return buildDropdownOptions(
      datasetMetas
        ?.filter(
          (d) =>
            d.entity._id !== datasetMeta?._id &&
            d.entity.activeDataCollection !== undefined,
        )
        .map((d) => d.entity),
    );
  }, [datasetMeta?._id, datasetMetas]);

  // Find any existing lookups for same datasetMetaId and prefill values
  const hasExistingDatasetLookup = useMemo(() => {
    if (!selectedDatasetMetaId || !schema) {
      return false;
    }

    const existingDatasetLookups = schema.filter(
      (s) =>
        s.dataValidation?.fieldLookup?.target?.datasetMetaId ===
        selectedDatasetMetaId,
    );

    if (
      existingDatasetLookups.length === 1 &&
      field?.fieldId === existingDatasetLookups[0].fieldId
    ) {
      return false;
    }

    const existingDatasetLookup = existingDatasetLookups.find(
      (s) =>
        s.dataValidation?.fieldLookup?.target?.datasetMetaId ===
        selectedDatasetMetaId,
    )?.dataValidation?.fieldLookup;
    if (existingDatasetLookup) {
      // Try and turn local field name into fieldId
      const localSF = schema.find(
        (s) =>
          s.name === existingDatasetLookup.local.fieldId ||
          s.fieldId === existingDatasetLookup.local.fieldId,
      );

      const localFieldId =
        localSF?.fieldId || existingDatasetLookup.local.fieldId;

      setSelectedLocalFieldId(localFieldId);
      setSelectedTargetMatchFieldId(
        existingDatasetLookup.target.matchingFieldId,
      );

      // Check target field still exists before locking
      const selectedDatasetMeta = datasetMetas.find(
        (datasetMeta) => datasetMeta.entity._id === selectedDatasetMetaId,
      );

      const selectedActiveDataCollection =
        selectedDatasetMeta?.entity.dataCollections.find(
          (dataCollection) =>
            dataCollection._id ===
            selectedDatasetMeta.entity.activeDataCollection,
        );

      if (
        selectedActiveDataCollection?.schemaData.find(
          (schemaField) =>
            schemaField.fieldId ===
            existingDatasetLookup.target.matchingFieldId,
        )
      ) {
        return true;
      }
    }
  }, [selectedDatasetMetaId, schema, field?.fieldId, datasetMetas]);

  // Get selected DatasetMeta if not in first page fetched
  useEffect(() => {
    (async () => {
      if (
        datasetMetas.length &&
        selectedDatasetMetaId &&
        !datasetMetas.find((d) => d.entity._id === selectedDatasetMetaId)
      ) {
        setLoading(true);
        await getDatasetMetas({ _id: selectedDatasetMetaId });
        setLoading(false);
      }
    })();
  }, [getDatasetMetas, datasetMetas, selectedDatasetMetaId]);

  // Get DatasetMetas
  useEffect(() => {
    (async () => {
      if (!searchQuery?.query?.length) {
        setLoading(true);
        await getDatasetMetas(
          DATASET_META_QUERY,
          undefined,
          DATASET_META_PAGE_NUM,
        );
      } else {
        setLoading(true);
        await getDatasetMetas(
          { activeDataCollection: { $ne: null } },
          searchQuery?.query?.length ? searchQuery : undefined,
        );
      }
      setLoading(false);
    })();
  }, [getDatasetMetas, searchQuery]);

  // Set target field options
  useEffect(() => {
    if (!datasetMetas.length || !selectedDatasetMetaId) {
      return;
    }

    const selectedDatasetMeta = datasetMetas.find(
      (d) => d.entity._id === selectedDatasetMetaId,
    );

    const selectedActiveDataCollection =
      selectedDatasetMeta?.entity.dataCollections.find(
        (d) => d._id === selectedDatasetMeta.entity.activeDataCollection,
      );

    if (selectedActiveDataCollection) {
      setTargetFieldOptions(
        selectedActiveDataCollection?.schemaData
          .filter((s) => !s.dataValidation?.fieldLookup)
          .map((s, i) => ({
            key: `column-name-${s.name}-${i}`,
            value: s.fieldId,
            text: s.name,
          })),
      );
    } else {
      setTargetFieldOptions([]);
    }
  }, [datasetMetas, selectedDatasetMetaId, setTargetFieldOptions]);

  // Set initial values if updating existing
  useEffect(() => {
    if (!field) {
      return;
    }

    const opts = field.dataValidation?.fieldLookup as Interfaces.FieldLookup;

    if (opts) {
      setSelectedLocalFieldId(opts.local.fieldId);
      setSelectedDatasetMetaId(opts.target.datasetMetaId);
      setSelectedTargetMatchFieldId(opts.target.matchingFieldId);
      setSelectedTargetDisplayFieldId(opts.target.outputFieldId);
    }
  }, [field]);

  useEffect(() => {
    if (
      !selectedLocalFieldId ||
      !selectedDatasetMetaId ||
      !selectedTargetMatchFieldId ||
      !selectedTargetDisplayFieldId
    ) {
      setSuperColumnValid(false);
      return;
    }

    setSuperColumnValid(true);
    setDataValidation({
      dataValidationType: Enums.ValueDataType.FIELD_LOOKUP,
      fieldLookup: {
        local: {
          fieldId: selectedLocalFieldId,
        },
        target: {
          datasetMetaId: selectedDatasetMetaId,
          matchingFieldId: selectedTargetMatchFieldId,
          outputFieldId: selectedTargetDisplayFieldId,
        },
      },
    });
  }, [
    selectedLocalFieldId,
    selectedDatasetMetaId,
    selectedTargetMatchFieldId,
    selectedTargetDisplayFieldId,
    setSuperColumnValid,
    setDataValidation,
  ]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  return (
    <>
      <SC.BodyText>
        Lookup super columns enable you to add new columns to your dataset by
        looking up values in other tables.
      </SC.BodyText>

      <StyledBodySubHeader>Lookup Dataset</StyledBodySubHeader>

      <StyledText>
        Select the dataset that you would like to lookup values from.
      </StyledText>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={loading}
        placeholder={'Select target Dataset'}
        options={datasetMetaOptions}
        selection
        search
        value={selectedDatasetMetaId}
        onChange={(e, data) => setSelectedDatasetMetaId(data.value)}
        onSearchChange={(e, data) => onSearchValueChange(data.searchQuery)}
        style={{ marginTop: 0 }}
        upward={false}
      />

      <StyledText>
        Select the column in the lookup dataset that you would like to use to
        connect your datasets.
      </StyledText>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        disabled={!selectedDatasetMetaId || hasExistingDatasetLookup}
        placeholder={'Select target match field'}
        options={targetFieldOptions.filter(
          (o) => o.value !== selectedTargetDisplayFieldId,
        )}
        selection
        search
        value={selectedTargetMatchFieldId}
        onChange={(e, data) => setSelectedTargetMatchFieldId(data.value)}
        style={{ marginTop: 0 }}
        upward={false}
      />

      <SC.Divider />

      <StyledBodySubHeader>Current Dataset</StyledBodySubHeader>

      <StyledText>
        Select the column in your current dataset that matches the lookup column
        you have selected above.
      </StyledText>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select local field'}
        options={localFieldOptions}
        selection
        value={selectedLocalFieldId}
        onChange={(e, data) => {
          setSelectedLocalFieldId(data.value);
        }}
        style={{ marginTop: 0 }}
        disabled={hasExistingDatasetLookup}
        upward={true}
      />

      <SC.Divider />

      <StyledBodySubHeader>Lookup Column To Display</StyledBodySubHeader>

      <StyledText>
        Select a column from the lookup dataset that you would like to output in
        this super column.
      </StyledText>
      <StyledDropdownUltraWide
        selectOnBlur={false}
        disabled={!selectedDatasetMetaId}
        placeholder={'Select target display field'}
        options={targetFieldOptions.filter(
          (o) => o.value !== selectedTargetMatchFieldId,
        )}
        selection
        search
        value={selectedTargetDisplayFieldId}
        onChange={(e, data) => setSelectedTargetDisplayFieldId(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />
    </>
  );
};

export default Lookup;
