import { Auth0Provider } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

const REDIRECT_SPLIT = '.com/';

interface Auth0ProviderWithHistoryProps {
  children?: React.ReactNode;
}

const Auth0ProviderWithHistory: FC<Auth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    let url = appState?.redirectUrl || window.location.pathname;

    if (url.includes(REDIRECT_SPLIT)) {
      url = url.split(REDIRECT_SPLIT)[1];
    }

    history.replace(url);
  };

  if (!domain || !clientId) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
