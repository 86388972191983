import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase, uniq } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../enums';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../hooks/project/UseProject';
import { SampleDataRow } from '../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../main/theme';
import { resetFilters } from '../../../store/filters';
import { fetchCMSSuccess, resetCMS } from '../../../store/project-cms';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectConnectionCMSSubStage } from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

const CMS_ENTITY_LIMIT = 5;

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const STATUS_FIELD = 'Status';
const DATASETS_FIELD = 'Available Datasets';
const QUERIES_FIELD = 'Available Queries';
const DASHBOARDS_FIELD = 'Available Dashboards';

const CMS_TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: STATUS_FIELD },
  { name: DATASETS_FIELD },
  { name: QUERIES_FIELD },
  { name: DASHBOARDS_FIELD },
];

const PREV_STAGE = Enums.ProjectStage.CREATION;

const buildEntityString = (entities: string[]): string => {
  return `${entities.slice(0, CMS_ENTITY_LIMIT).join(', ')}${
    entities.length > CMS_ENTITY_LIMIT
      ? ` & ${entities.length - CMS_ENTITY_LIMIT} more`
      : ''
  }`;
};

const ProjectItemCMSStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { connections, project, projectAccessLevel } = useProject();
  const { getDatasetMetas } = useDatasetMeta();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [cmsConnections, setCmsConnections] =
    useState<Interfaces.ConnectionOutput[]>();

  const datasetMetas = useSelector((state: RootState) => state.datasetMetas)
    ?.data.data;
  const charts = useSelector((state: RootState) => state.charts)?.data.data;
  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  // Get datasetMetas
  useEffect(() => {
    if (project?.datasetMetaId?.length) {
      (async () => {
        await getDatasetMetas({
          projectId: project._id,
          activeDataCollection: { $ne: null },
        });
        setIsLoading(false);
      })();
    }
  }, [getDatasetMetas, project?._id, project?.datasetMetaId]);

  // Reset Redux Filters
  useEffect(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  // Get connections
  useEffect(() => {
    if (connections) {
      setCmsConnections(connections.cms || []);
    }
  }, [connections]);

  useEffect(() => {
    if (cmsConnections) {
      const tableData: SampleDataRow[] = [];

      cmsConnections?.map((cms) => {
        const entities = (
          cms.configuration as Interfaces.CMSConnectionConfiguration
        ).groups.reduce(
          (
            acc: {
              datasets: string[];
              queries: string[];
              dashboards: string[];
            },
            group,
          ) => {
            const groupItems = group.items.reduce(
              (
                acc: {
                  datasets: string[];
                  queries: string[];
                  dashboards: string[];
                },
                item,
              ) => {
                if (item.datasetMetaId) {
                  const name = datasetMetas.find(
                    (dsm) => dsm.entity._id === item.datasetMetaId,
                  )?.entity.name;

                  if (name) {
                    return { ...acc, datasets: [...acc.datasets, name] };
                  }
                }

                if (item.aggregationId) {
                  const name = queries.find(
                    (dsm) => dsm.entity._id === item.aggregationId,
                  )?.entity.name;

                  if (name) {
                    return { ...acc, queries: [...acc.queries, name] };
                  }
                }

                if (item.chartId) {
                  const name = charts.find(
                    (dsm) => dsm.entity._id === item.chartId,
                  )?.entity.name;

                  if (name) {
                    return { ...acc, dashboards: [...acc.dashboards, name] };
                  }
                }

                return acc as {
                  datasets: string[];
                  queries: string[];
                  dashboards: string[];
                };
              },
              { datasets: [], queries: [], dashboards: [] } as {
                datasets: string[];
                queries: string[];
                dashboards: string[];
              },
            );

            return {
              ...acc,
              datasets: uniq(acc.datasets.concat(groupItems.datasets)),
              queries: uniq(acc.queries.concat(groupItems.queries)),
              dashboards: uniq(acc.dashboards.concat(groupItems.dashboards)),
            };
          },
          { datasets: [], queries: [], dashboards: [] },
        );

        tableData.push({
          [ID_FIELD]: { value: cms._id },
          [NAME_FIELD]: { value: cms.name },
          [STATUS_FIELD]: { value: startCase(cms.status) },
          [DATASETS_FIELD]: { value: buildEntityString(entities.datasets) },
          [QUERIES_FIELD]: { value: buildEntityString(entities.queries) },
          [DASHBOARDS_FIELD]: {
            value: buildEntityString(entities.dashboards),
          },
        });
      });

      setTableData(tableData);
    }
  }, [charts, cmsConnections, datasetMetas, queries]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.ConnectionTypeWrapper>
          <Banner
            message={
              <p>
                Workspaces have been unified into Portals can now be found{' '}
                <a href={'https://portal.goconfigur.com'}>here</a>.
              </p>
            }
            alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
            alertStatus={AlertStatus.INFO}
          />

          <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
            Build Workspaces to group your data together for easy access and
            sharing.
          </StageBodyText>

          {/*Deprecated*/}
          {/*{projectAccessLevel === Enums.AccessLevel.MANAGE && (*/}
          {/*  <FeatureButton*/}
          {/*    action={() => {*/}
          {/*      dispatch(resetCMS());*/}
          {/*      dispatch(*/}
          {/*        updateActiveProjectSubStage(*/}
          {/*          ProjectConnectionCMSSubStage.NAME,*/}
          {/*        ),*/}
          {/*      );*/}
          {/*    }}*/}
          {/*    size={FeatureButtonSize.WIDE}*/}
          {/*    color={themeContext.colors.general.blue}*/}
          {/*    text={'Add Workspace'}*/}
          {/*    containerStyle={{ marginBottom: themeContext.margin.large }}*/}
          {/*  />*/}
          {/*)}*/}

          {projectAccessLevel !== Enums.AccessLevel.MANAGE &&
            !tableData.length &&
            !isLoading && (
              <StageBodyText>
                <strong>No Workspaces found</strong>
              </StageBodyText>
            )}

          {(tableData.length > 0 || isLoading) && (
            <SC.TableWrapper>
              <DataSample
                fullWidth={true}
                sampleColumns={CMS_TABLE_SCHEMA}
                sampleRows={tableData}
                // Deprecated
                // clickableRows={{
                //   valueField: ID_FIELD,
                //   action: (connectionId: string) => {
                //     const existingCMS = project?.connections[
                //       Enums.ConnectionType.CMS
                //     ].find((c) => c._id === connectionId);
                //
                //     if (existingCMS) {
                //       dispatch(fetchCMSSuccess(existingCMS));
                //       dispatch(
                //         updateActiveProjectSubStage(
                //           ProjectConnectionCMSSubStage.DATA,
                //         ),
                //       );
                //     }
                //   },
                // }}
                loading={isLoading}
              />
            </SC.TableWrapper>
          )}
        </SC.ConnectionTypeWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemCMSStage;
