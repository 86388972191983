import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { EntityType, RouteName } from '../../../../enums';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { updateActiveIntegrationSubStage } from '../../../../store/integration-stage';
import { IntegrationCreationSubStage } from '../../../../store/integration-stage/initial-state';
import { fetchIntegrationTemplateSuccess } from '../../../../store/integration-template';
import { hideLoading, showLoading } from '../../../../store/loading';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import IconSelector from '../../../IconSelector/IconSelector';
import * as SC from '../styled';

const AVATAR_FIELD = 'avatar';
const PREV_STAGE = IntegrationCreationSubStage.META;
const NEXT_STAGE = IntegrationCreationSubStage.ENDPOINTS;

const AvatarIntegrationStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { loggedInUser } = useLoggedInUser();

  const {
    integrationTemplate,
    integrationTemplateAccessLevel,
    addIntegrationTemplate,
    editIntegrationTemplate,
  } = useIntegrationTemplate();

  const [processComplete, setProcessComplete] = useState<boolean>(false);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(integrationTemplate);
    cloned[field] = val;
    dispatch(
      fetchIntegrationTemplateSuccess({
        entity: cloned,
        accessLevel: integrationTemplateAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  // Process complete
  useEffect(() => {
    if (processComplete && integrationTemplate?._id) {
      // Creation/Update has completed successfully
      // Move on to next stage
      history.push(`${RouteName.INTEGRATION_ITEM}/${integrationTemplate._id}`);
      dispatch(updateActiveIntegrationSubStage(NEXT_STAGE));
    }
  }, [processComplete, integrationTemplate?._id, dispatch, history]);

  const processAction = async () => {
    if (loggedInUser && integrationTemplate) {
      if (!integrationTemplate._id) {
        // Create integration
        dispatch(showLoading({ text: 'Creating Integration...' }));

        // Create team
        const cloned = cloneDeep(integrationTemplate);

        // Add organisation
        cloned.organisationId = loggedInUser.organisationId;

        await addIntegrationTemplate(cloned);

        dispatch(hideLoading());
      } else {
        // Update integration
        dispatch(showLoading({ text: 'Updating Integration...' }));

        await editIntegrationTemplate(integrationTemplate);

        dispatch(hideLoading());
      }
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Pick the perfect avatar to represent your integration.
          </StageBodyText>

          <StageBodyText>
            This will make it easier to identify your integration across the
            organisation.
          </StageBodyText>

          <IconSelector
            type={EntityType.PROJECT}
            onChange={(icon) => handleChange(AVATAR_FIELD, icon)}
            selectedIcon={integrationTemplate?.avatar}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Keep it going!`}
        primaryButton={
          <FeatureButton
            isDisabled={!integrationTemplate?.avatar?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to endpoints'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveIntegrationSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default AvatarIntegrationStage;
