import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
} from '../../../main/theme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 800px;
  width: 90%;

  padding: ${({ theme }) => theme.padding.xxxlarge};
  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.xxxlarge}`};

  background: ${({ theme }) => theme.colors.general.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const FilterWrapper = styled.div<{
  applyLeftMargin?: boolean;
}>`
  display: flex;
  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
  margin-left: ${({ applyLeftMargin }) => (applyLeftMargin ? '45px' : 0)};
  justify-content: center;
  &:first-of-type {
    padding-top: ${({ theme }) => theme.padding.xlarge};
  }
  &:last-of-type {
    padding-bottom: ${({ theme }) => theme.padding.xlarge};
  }
`;

export const FilterStage = styled.div<{
  applyMargin?: boolean;
  buttonContainer?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  margin-right: ${({ theme }) => theme.margin.standard};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const GroupAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 100% !important;
`;

export const AccordionTitle = styled(StyledAccordionTitle)`
  & :hover {
    cursor: default;
  }
`;

export const AccordionTitleWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin-right: ${({ theme }) => theme.margin.standard};
    margin-bottom: 0;
  }
`;

export const AccordionTitleButtons = styled.div`
  display: flex;

  > div,
  > button {
    margin-left: ${({ theme }) => theme.margin.standard};

    & :hover {
      cursor: pointer;
    }
  }
`;

export const AccordionContentWrapper = styled(StyledAccordionContent)`
  background: ${({ theme }) => theme.colors.system.white};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;
