import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
} from '../../../main/theme';
import NavItem from '../../Navigation/NavItem';

export const NavigationAccordion = styled(StyledAccordion)<{
  $lightTheme?: boolean;
}>`
  background: ${({ theme, $lightTheme }) =>
    $lightTheme
      ? theme.colors.system.offWhite
      : theme.colors.system.darkBlueShadow};
  border: unset;
  border-radius: unset;
  min-width: 100%;
`;

export const NavigationAccordionTitle = styled(StyledAccordionTitle)<{
  $lightTheme?: boolean;
}>`
  height: 46px;
  border-bottom: 1px solid
    ${({ theme, $lightTheme }) =>
      $lightTheme
        ? theme.colors.system.grey
        : theme.colors.system.darkOffBlack};
`;

export const NavigationAccordionContent = styled(StyledAccordionContent)`
  padding: 0 !important;
  border: unset;
  background: ${({ theme }) => theme.colors.system.darkBlue};

  > div {
    > div {
      width: 100%;

      > button {
        width: 100%;

        svg {
          margin-right: 0;
        }
      }
    }

    > button {
      width: 100%;

      svg {
        margin-right: 0;
      }
    }
  }
`;

export const NavigationNavItem = styled(NavItem)`
  > div {
    width: 100%;
    display: flex;
    align-items: center;

    > svg {
      margin-left: ${({ theme }) => theme.margin.standard};
      margin-right: 0;
    }
  }
`;
