import { Interfaces } from '@configur-tech/upit-core-types';
import { DatasetApiOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, set } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums/EntityType';
import useApiKey from '../../../../hooks/api-key/UseApiKey';
import useApi from '../../../../hooks/api/UseApi';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import { SearchQuery } from '../../../../interfaces/Search';
import {
  defaultTheme,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledDropdownWide,
  StyledH5,
  StyledInput,
  StyledSubHeader,
  StyledText,
  StyledTextArea,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { hideLoading, showLoading } from '../../../../store/loading';
import { hideModal } from '../../../../store/modal';
import InitialAPIKey from '../../../../store/project/initial-api-key';
import InitialDataAPI from '../../../../store/project/initial-data-api';
import { RootState } from '../../../../store/rootReducer';
import CopyCodeBox from '../../../CopyCodeBox/CopyCodeBox';
import DataSample from '../../../DataSample/DataSample';
import DeleteConfirmation from '../../../DeleteConfirmation/DeleteConfirmation';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from './styled';

enum Endpoint {
  GET = 'httpGet',
  POST = 'httpPost',
  PUT = 'httpPut',
  DELETE = 'httpDelete',
}

enum EndpointField {
  IS_ENABLED = 'isEnabled',
  IS_PUBLIC = 'isPublic',
}

const SEARCH_FIELD = 'name';
const SEARCH_TYPING_TIMEOUT = 1000;
const STAGE_ZERO = 0;
const STAGE_ONE = 1;
const STAGE_TWO = 2;
const STAGE_THREE = 3;
const STAGE_FOUR = 4;
const STAGE_DELETE = 5;
const STAGE_DELETE_API_KEY = 6;

const ID_FIELD = 'API Key';
const NAME_FIELD = 'Name';
const DESCRIPTION_FIELD = 'Description';
const ENDPOINTS_FIELD = 'Allowed Endpoints';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: ENDPOINTS_FIELD },
  { name: ID_FIELD },
];

const ENDPOINT_TEXT_MAP = {
  [Endpoint.GET]: 'Read',
  [Endpoint.POST]: 'Create',
  [Endpoint.PUT]: 'Update',
  [Endpoint.DELETE]: 'Delete',
};

export interface ConnectionAPIModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingConnection?: Interfaces.ConnectionOutput;
  existingAPI?: Interfaces.DatasetApiOutput;
}

const buildDatasetDropdownOptions = (
  datasetMetas: Interfaces.DatasetMetaOutput[],
) =>
  datasetMetas.map((d, i) => {
    return {
      key: `${d._id}-${i}`,
      text: d.name,
      value: d._id,
    };
  });

const buildOriginDropdownOptions = (origins: string[]) =>
  origins.map((o, i) => {
    return {
      key: `${o}-${i}`,
      text: o,
      value: o,
    };
  });

const ConnectionAPIModal: FC<ConnectionAPIModalProps> = ({
  setShowModal,
  existingAPI,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { getDatasetMetas, getDatasetMeta, datasetMeta } = useDatasetMeta();
  const { getApis, addApi, editApi, removeApi } = useApi();
  const { getApiKeys, addApiKey, editApiKey, removeApiKey } = useApiKey();

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [debounceQuery, setDebounceQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: undefined,
  });

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    index: SEARCH_FIELD,
    query: undefined,
  });

  const [editingApi, setEditingApi] = useState<boolean>(!!existingAPI);
  const [datasetApi, setDatasetApi] = useState<
    Interfaces.DatasetApi | Interfaces.DatasetApiOutput
  >(existingAPI || InitialDataAPI);
  const [modalStage, setModalStage] = useState<number>(STAGE_ZERO);
  const [datasetMetaOptions, setDatasetMetaOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [originOptions, setOriginOptions] = useState<DropdownItemProps[]>([]);
  const [hasActiveEndpoint, setHasActiveEndpoint] = useState<boolean>(false);
  const [requiresApiKeys, setRequiresApiKeys] = useState<boolean>(false);
  const [fetchedApiKeys, setFetchedApiKeys] = useState<
    Interfaces.ApiKeyOutput[]
  >([]);
  const [apiKey, setApiKey] = useState<
    Interfaces.ApiKey | Interfaces.ApiKeyOutput
  >(InitialAPIKey);
  const [endpointAccordionStage, setEndpointAccordionStage] =
    useState<number>(0);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  useEffect(() => {
    if (existingAPI || (datasetApi as Interfaces.DatasetApiOutput)._id) {
      (async () => {
        setEditingApi(true);
        await getDatasetMeta(datasetApi.datasetMetaId);
      })();
    }
  }, [datasetApi, existingAPI]);

  useEffect(() => {
    if (datasetApi?.allowedMethods) {
      const hasActive = !Object.values(datasetApi.allowedMethods).every(
        (m) => !m?.isEnabled,
      );

      setHasActiveEndpoint(hasActive);

      const requiresKeys = !Object.values(datasetApi.allowedMethods).every(
        (m) => (m?.isEnabled ? m?.isPublic : true),
      );

      setRequiresApiKeys(requiresKeys);
    }
  }, [datasetApi]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (searchQuery.query) {
        setLoadingEntities(true);
        await getDatasetMetas(
          undefined,
          searchQuery?.query?.length ? searchQuery : undefined,
          undefined,
          50,
        );
        setLoadingEntities(false);
      }
    })();
  }, [getDatasetMetas, searchQuery]);

  // Get apiKeys
  useEffect(() => {
    if (existingAPI) {
      (async () => {
        const apiKeys = await getApiKeys(undefined, {
          apiId: existingAPI._id,
        });

        if (apiKeys) {
          setFetchedApiKeys(apiKeys);
        }
      })();
    }
  }, [existingAPI, getApiKeys]);

  // Build dataset dropdown options
  useEffect(() => {
    if (datasetMetas.length) {
      const dsms = datasetMetas.map((d) => d.entity);

      if (datasetMeta?._id) {
        dsms.push(datasetMeta);
      }
      setDatasetMetaOptions(buildDatasetDropdownOptions(dsms));
    }
  }, [datasetMeta, datasetMetas]);

  // Build origin dropdown options
  useEffect(() => {
    if (
      get(datasetApi, `allowedMethods.${Endpoint.GET}.originWhitelist`)?.length
    ) {
      setOriginOptions(
        buildOriginDropdownOptions(
          get(datasetApi, `allowedMethods.${Endpoint.GET}.originWhitelist`),
        ),
      );
    }
  }, [datasetApi]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery.query !== searchQuery.query) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery.query]);

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      if (
        value !== searchQuery.query &&
        (!value.length || (value.length && value.trim().length))
      ) {
        const cloned: SearchQuery = cloneDeep(searchQuery);
        cloned.query = value;

        return setDebounceQuery(cloned);
      }
    },
    [searchQuery],
  );

  const handleNameChange = (name: string) => {
    // Update dataset api
    const clonedApi: Interfaces.DatasetApi = cloneDeep(datasetApi);
    clonedApi.alias = name;
    setDatasetApi(clonedApi);
  };

  const handleDescChange = (desc: string) => {
    // Update dataset api
    const clonedApi: Interfaces.DatasetApi = cloneDeep(datasetApi);
    clonedApi.description = desc;
    setDatasetApi(clonedApi);
  };

  const handleDatasetMetaChange = (datasetMetaId: string) => {
    // Update dataset api
    const clonedApi: Interfaces.DatasetApi = cloneDeep(datasetApi);
    clonedApi.datasetMetaId = datasetMetaId;
    setDatasetApi(clonedApi);
  };

  const handleEndpointToggle = (
    endpoint: Endpoint,
    field: EndpointField,
    val: boolean,
  ) => {
    // Update dataset api
    const clonedApi: Interfaces.DatasetApi = cloneDeep(datasetApi);

    if (clonedApi.allowedMethods?.[endpoint]) {
      set(clonedApi, `allowedMethods.${endpoint}.${field}`, val);
      setDatasetApi(clonedApi);
    }
  };

  const handleOriginAdd = (origin: string) => {
    const cloned = cloneDeep(originOptions);
    cloned.push({ value: origin, text: origin });
    setOriginOptions(cloned);
  };

  const handleOriginChange = (endpoint: Endpoint, origins: string[]) => {
    // Update dataset api
    const clonedApi: Interfaces.DatasetApi = cloneDeep(datasetApi);

    if (clonedApi.allowedMethods?.[endpoint]) {
      set(clonedApi, `allowedMethods.${endpoint}.originWhitelist`, origins);
      setDatasetApi(clonedApi);
    }
  };

  const handleKeyAliasChange = (alias: string) => {
    // Update api key
    const cloned: Interfaces.ApiKey = cloneDeep(apiKey);
    cloned.alias = alias;
    setApiKey(cloned);
  };

  const handleKeyDescChange = (description: string) => {
    // Update api key
    const cloned: Interfaces.ApiKey = cloneDeep(apiKey);
    cloned.description = description;
    setApiKey(cloned);
  };

  const handleKeyEndpointToggle = (endpoint: Endpoint, val: boolean) => {
    // Update api key
    const cloned: Interfaces.ApiKey = cloneDeep(apiKey);
    set(cloned, `allowedMethods.${endpoint}`, val);
    setApiKey(cloned);
  };

  const createAPI = async (stage?: number) => {
    if (datasetApi && loggedInUser) {
      dispatch(
        showLoading({
          text: editingApi ? 'Updating API...' : 'Creating API...',
        }),
      );

      const clonedApi = cloneDeep(datasetApi);
      // Add organisation to API
      clonedApi.organisationId = loggedInUser.organisationId;

      if (editingApi) {
        // Update API
        await editApi(clonedApi as Interfaces.DatasetApiOutput);
      } else {
        // Create API
        const createdAPI = await addApi(clonedApi);
        setDatasetApi(createdAPI);
      }
      await getApis();
      dispatch(hideLoading());
    }

    if (stage) {
      return setModalStage(stage);
    }

    dispatch(hideModal());
  };

  const deleteApi = async () => {
    if (datasetApi) {
      dispatch(
        showLoading({
          text: 'Deleting API...',
        }),
      );

      await removeApi(datasetApi as DatasetApiOutput);
    }

    await getApis();
    dispatch(hideLoading());
    dispatch(hideModal());
  };

  const createAPIKey = async () => {
    if (
      (datasetApi as Interfaces.DatasetApiOutput)?._id &&
      apiKey &&
      loggedInUser
    ) {
      dispatch(
        showLoading({
          text: (apiKey as Interfaces.ApiKeyOutput)._id
            ? 'Updating API Key...'
            : 'Creating API Key...',
        }),
      );

      if ((apiKey as Interfaces.ApiKeyOutput)._id) {
        // Update API Key
        const updatedApiKey = await editApiKey(
          apiKey as Interfaces.ApiKeyOutput,
        );

        // Update local state
        let cloned = cloneDeep(fetchedApiKeys);
        cloned = cloned.map((k) => {
          if (k._id === updatedApiKey._id) {
            return updatedApiKey;
          }
          return k;
        });

        setFetchedApiKeys(cloned);
      } else {
        // Add required fields
        apiKey.organisationId = loggedInUser.organisationId;
        apiKey.apiId = (datasetApi as Interfaces.DatasetApiOutput)._id;
        // Create API Key
        const createdAPI = await addApiKey(apiKey);

        // Update local state
        const cloned = cloneDeep(fetchedApiKeys);
        cloned.push(createdAPI);
        setFetchedApiKeys(cloned);
      }
    }

    setModalStage(STAGE_THREE);
    dispatch(hideLoading());
  };

  const deleteAPIKey = async () => {
    if (datasetApi && apiKey) {
      dispatch(
        showLoading({
          text: 'Deleting API Key...',
        }),
      );

      // Delete API Key
      await removeApiKey((apiKey as Interfaces.ApiKeyOutput)._id);

      // Update local state
      let cloned = cloneDeep(fetchedApiKeys);
      cloned = cloned.filter(
        (k) => k._id !== (apiKey as Interfaces.ApiKeyOutput)._id,
      );

      setFetchedApiKeys(cloned);
    }

    dispatch(hideLoading());
    setModalStage(STAGE_THREE);
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderWrapper>
        {modalStage !== STAGE_FOUR && (
          <>
            {modalStage !== STAGE_DELETE &&
              modalStage !== STAGE_DELETE_API_KEY && (
                <SC.Header>{editingApi ? 'Update' : 'Create'} API</SC.Header>
              )}

            {modalStage === STAGE_DELETE && editingApi && (
              <SC.Header>Delete API</SC.Header>
            )}

            {modalStage === STAGE_DELETE_API_KEY && editingApi && (
              <SC.Header>Delete API Key</SC.Header>
            )}

            {(modalStage === STAGE_ONE ||
              (modalStage === STAGE_ZERO &&
                (datasetApi as Interfaces.DatasetApiOutput)._id)) && (
              <SC.DeleteButton>
                <FeatureButton
                  action={() => setModalStage(STAGE_DELETE)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </SC.DeleteButton>
            )}
          </>
        )}
        {modalStage === STAGE_FOUR && (
          <>
            <SC.Header>
              {(apiKey as Interfaces.ApiKeyOutput)?._id ? 'Update' : 'Create'}{' '}
              API Key
            </SC.Header>
            {(apiKey as Interfaces.ApiKeyOutput)._id && (
              <SC.DeleteButton>
                <FeatureButton
                  action={() => setModalStage(STAGE_DELETE_API_KEY)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </SC.DeleteButton>
            )}
          </>
        )}
      </SC.HeaderWrapper>

      {modalStage === STAGE_ZERO &&
        (datasetApi as Interfaces.DatasetApiOutput)._id &&
        datasetApi.allowedMethods && (
          <>
            <StyledSubHeader>API Documentation</StyledSubHeader>
            <StyledText>
              Below are details on the endpoints that have been made available
              via this API.
            </StyledText>
            <StyledText>
              All endpoints require the <strong>Origin</strong> header to be
              supplied.
            </StyledText>

            <StyledText>
              Non-public endpoints also require the <strong>X-API-Key</strong>{' '}
              header to be supplied.
            </StyledText>

            {Object.entries(datasetApi.allowedMethods).map(
              ([endpoint, val], i) => {
                if (val?.isEnabled) {
                  switch (endpoint) {
                    case Endpoint.GET:
                      return (
                        <div key={`connection-${endpoint}-${i}`}>
                          <SC.ToggleLabel
                            style={{ alignSelf: 'flex-start', marginLeft: 0 }}
                          >
                            {ENDPOINT_TEXT_MAP[endpoint]} Endpoint
                          </SC.ToggleLabel>
                          <SC.CodeBox>
                            curl --request GET --url{' '}
                            {process.env['REACT_APP_API_DOMAIN']}/
                            {(datasetApi as Interfaces.DatasetApiOutput)?._id}
                          </SC.CodeBox>
                        </div>
                      );
                    case Endpoint.POST:
                      return (
                        <div key={`connection-${endpoint}-${i}`}>
                          <SC.ToggleLabel
                            style={{ alignSelf: 'flex-start', marginLeft: 0 }}
                          >
                            {ENDPOINT_TEXT_MAP[endpoint]} Endpoint
                          </SC.ToggleLabel>
                          <SC.CodeBox>
                            curl --request POST --url{' '}
                            {process.env['REACT_APP_API_DOMAIN']}/
                            {(datasetApi as Interfaces.DatasetApiOutput)?._id}
                          </SC.CodeBox>
                        </div>
                      );
                    case Endpoint.PUT:
                      return (
                        <div key={`connection-${endpoint}-${i}`}>
                          <SC.ToggleLabel
                            style={{ alignSelf: 'flex-start', marginLeft: 0 }}
                          >
                            {ENDPOINT_TEXT_MAP[endpoint]} Endpoint
                          </SC.ToggleLabel>
                          <SC.CodeBox>
                            curl --request PUT --url{' '}
                            {process.env['REACT_APP_API_DOMAIN']}/
                            {(datasetApi as Interfaces.DatasetApiOutput)?._id}/
                            {`{rowId}`}
                          </SC.CodeBox>
                        </div>
                      );
                    case Endpoint.DELETE:
                      return (
                        <div key={`connection-${endpoint}-${i}`}>
                          <SC.ToggleLabel
                            style={{ alignSelf: 'flex-start', marginLeft: 0 }}
                          >
                            {ENDPOINT_TEXT_MAP[endpoint]} Endpoint
                          </SC.ToggleLabel>
                          <SC.CodeBox>
                            curl --request DELETE --url{' '}
                            {process.env['REACT_APP_API_DOMAIN']}/
                            {(datasetApi as Interfaces.DatasetApiOutput)?._id}/
                            {`{rowId}`}
                          </SC.CodeBox>
                        </div>
                      );
                  }
                }

                return null;
              },
            )}

            {requiresApiKeys && (
              <FeatureButton
                action={() => {
                  setModalStage(STAGE_THREE);
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Manage API Keys'}
              />
            )}

            <SC.ActionButtonWrapper>
              <FeatureButton
                action={() => dispatch(hideModal())}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Cancel'}
              />
              <FeatureButton
                isDisabled={!datasetApi.alias?.length}
                action={() => setModalStage(STAGE_ONE)}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.green}
                text={'Continue to name'}
              />
            </SC.ActionButtonWrapper>
          </>
        )}

      {(modalStage === STAGE_ONE ||
        (modalStage === STAGE_ZERO &&
          !(datasetApi as Interfaces.DatasetApiOutput)._id)) && (
        <>
          <StyledText>
            To create an API, give it a name, select your dataset and endpoints,
            and set some access rules.
          </StyledText>

          <StyledH5>API Name</StyledH5>

          <StyledInput
            style={{ marginBottom: themeContext.margin.large }}
            placeholder={'Enter your API name'}
            value={datasetApi.alias}
            onChange={(_, data) => handleNameChange(data.value)}
          />

          <StyledH5>API Description</StyledH5>

          <StyledTextArea
            minRows={5}
            maxRows={10}
            onChange={(e) => handleDescChange(e.target.value)}
            value={datasetApi.description}
            placeholder={'Enter a description for your API'}
          />

          <SC.ActionButtonWrapper>
            {(datasetApi as Interfaces.DatasetApiOutput)?._id && (
              <FeatureButton
                action={() => setModalStage(STAGE_ZERO)}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Back to Documentation'}
              />
            )}

            {!(datasetApi as Interfaces.DatasetApiOutput)?._id && (
              <FeatureButton
                action={() => dispatch(hideModal())}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Cancel'}
              />
            )}

            <FeatureButton
              isDisabled={!datasetApi.alias?.length}
              action={() => setModalStage(STAGE_TWO)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue to dataset'}
            />
          </SC.ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_TWO && (
        <>
          <StyledSubHeader>Available Datasets</StyledSubHeader>

          <StyledText>
            Which dataset would you like to be made available via this API?
          </StyledText>

          <StyledDropdownWide
            loading={loadingEntities}
            selectOnBlur={false}
            placeholder={'Type to search datasets'}
            options={datasetMetaOptions}
            selection
            value={datasetApi.datasetMetaId}
            onChange={(e, data) => handleDatasetMetaChange(data.value)}
            style={{ marginTop: 0 }}
            search
            onSearchChange={(e, data) => onSearchValueChange(data.searchQuery)}
          />

          <StyledSubHeader>Available Endpoints</StyledSubHeader>

          <StyledText>
            Which endpoints would you like to be made available via this API?
          </StyledText>

          <StyledAccordion fluid>
            {/*GET*/}
            <StyledAccordionTitle
              active={endpointAccordionStage === 0}
              index={0}
              onClick={() => setEndpointAccordionStage(0)}
            >
              <StyledBodySubHeader>Read (GET)</StyledBodySubHeader>
              <FontAwesomeIcon
                icon={
                  endpointAccordionStage === 0 ? faChevronUp : faChevronDown
                }
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={endpointAccordionStage === 0}>
              <div>
                <StyledText>
                  This endpoint will allow users to read your data.
                </StyledText>

                <SC.ToggleWrapper>
                  <SC.ToggleLabel>Endpoint Active</SC.ToggleLabel>
                  <Toggle
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.GET}.${EndpointField.IS_ENABLED}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.GET,
                        EndpointField.IS_ENABLED,
                        event.target.checked,
                      )
                    }
                  />

                  <SC.ToggleLabel>Allow Public Access</SC.ToggleLabel>
                  <Toggle
                    disabled={
                      !get(
                        datasetApi,
                        `allowedMethods.${Endpoint.GET}.${EndpointField.IS_ENABLED}`,
                      )
                    }
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.GET}.${EndpointField.IS_PUBLIC}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.GET,
                        EndpointField.IS_PUBLIC,
                        event.target.checked,
                      )
                    }
                  />
                </SC.ToggleWrapper>

                {!get(
                  datasetApi,
                  `allowedMethods.${Endpoint.GET}.${EndpointField.IS_PUBLIC}`,
                ) && (
                  <SC.OriginWrapper>
                    <SC.ToggleLabel>Allowed Origins</SC.ToggleLabel>
                    <StyledText>
                      Enter a list of origins allowed to access this endpoint.
                    </StyledText>
                    <StyledDropdownUltraWide
                      selectOnBlur={false}
                      style={{ marginTop: 0 }}
                      placeholder={`Enter an origin e.g. 'configur.tech' or '*.configurcodex.com'`}
                      noResultsMessage={'Type to add a new origin'}
                      options={originOptions}
                      search
                      allowAdditions
                      selection
                      multiple
                      value={
                        get(
                          datasetApi,
                          `allowedMethods.${Endpoint.GET}.originWhitelist`,
                        ) || []
                      }
                      onAddItem={(e, data) => handleOriginAdd(data.value)}
                      onChange={(e, data) =>
                        handleOriginChange(Endpoint.GET, data.value)
                      }
                    />
                  </SC.OriginWrapper>
                )}
              </div>
            </StyledAccordionContent>

            {/*POST*/}
            <StyledAccordionTitle
              active={endpointAccordionStage === 1}
              index={1}
              onClick={() => setEndpointAccordionStage(1)}
            >
              <StyledBodySubHeader>Create (POST)</StyledBodySubHeader>
              <FontAwesomeIcon
                icon={
                  endpointAccordionStage === 1 ? faChevronUp : faChevronDown
                }
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={endpointAccordionStage === 1}>
              <div>
                <StyledText>
                  This endpoint will allow users to create new rows of data.
                </StyledText>

                <SC.ToggleWrapper>
                  <SC.ToggleLabel>Endpoint Active</SC.ToggleLabel>
                  <Toggle
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.POST}.${EndpointField.IS_ENABLED}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.POST,
                        EndpointField.IS_ENABLED,
                        event.target.checked,
                      )
                    }
                  />

                  <SC.ToggleLabel>Public Access</SC.ToggleLabel>
                  <Toggle
                    disabled={
                      !get(
                        datasetApi,
                        `allowedMethods.${Endpoint.POST}.${EndpointField.IS_ENABLED}`,
                      )
                    }
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.POST}.${EndpointField.IS_PUBLIC}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.POST,
                        EndpointField.IS_PUBLIC,
                        event.target.checked,
                      )
                    }
                  />
                </SC.ToggleWrapper>

                {!get(
                  datasetApi,
                  `allowedMethods.${Endpoint.POST}.${EndpointField.IS_PUBLIC}`,
                ) && (
                  <SC.OriginWrapper>
                    <SC.ToggleLabel>Allowed Origins</SC.ToggleLabel>
                    <StyledText>
                      Enter a list of origins allowed to access this endpoint.
                    </StyledText>
                    <StyledDropdownUltraWide
                      selectOnBlur={false}
                      placeholder={`Enter an origin e.g. 'configur.tech' or '*.configurcodex.com'`}
                      noResultsMessage={'Type to add a new origin'}
                      options={originOptions}
                      search
                      allowAdditions
                      selection
                      multiple
                      value={
                        get(
                          datasetApi,
                          `allowedMethods.${Endpoint.POST}.originWhitelist`,
                        ) || []
                      }
                      onAddItem={(e, data) => handleOriginAdd(data.value)}
                      onChange={(e, data) =>
                        handleOriginChange(Endpoint.POST, data.value)
                      }
                    />
                  </SC.OriginWrapper>
                )}
              </div>
            </StyledAccordionContent>

            {/*PUT*/}
            <StyledAccordionTitle
              active={endpointAccordionStage === 2}
              index={2}
              onClick={() => setEndpointAccordionStage(2)}
            >
              <StyledBodySubHeader>Update (PUT)</StyledBodySubHeader>
              <FontAwesomeIcon
                icon={
                  endpointAccordionStage === 2 ? faChevronUp : faChevronDown
                }
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={endpointAccordionStage === 2}>
              <div>
                <StyledText>
                  This endpoint will allow users to update your data.
                </StyledText>

                <SC.ToggleWrapper>
                  <SC.ToggleLabel>Endpoint Active</SC.ToggleLabel>
                  <Toggle
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.PUT}.${EndpointField.IS_ENABLED}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.PUT,
                        EndpointField.IS_ENABLED,
                        event.target.checked,
                      )
                    }
                  />

                  <SC.ToggleLabel>Allow Public Access</SC.ToggleLabel>
                  <Toggle
                    disabled={
                      !get(
                        datasetApi,
                        `allowedMethods.${Endpoint.PUT}.${EndpointField.IS_ENABLED}`,
                      )
                    }
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.PUT}.${EndpointField.IS_PUBLIC}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.PUT,
                        EndpointField.IS_PUBLIC,
                        event.target.checked,
                      )
                    }
                  />
                </SC.ToggleWrapper>

                {!get(
                  datasetApi,
                  `allowedMethods.${Endpoint.PUT}.${EndpointField.IS_PUBLIC}`,
                ) && (
                  <SC.OriginWrapper>
                    <SC.ToggleLabel>Allowed Origins</SC.ToggleLabel>
                    <StyledText>
                      Enter a list of origins allowed to access this endpoint.
                    </StyledText>
                    <StyledDropdownUltraWide
                      selectOnBlur={false}
                      style={{ marginTop: 0 }}
                      placeholder={`Enter an origin e.g. 'configur.tech' or '*.configurcodex.com'`}
                      noResultsMessage={'Type to add a new origin'}
                      options={originOptions}
                      search
                      allowAdditions
                      selection
                      multiple
                      value={
                        get(
                          datasetApi,
                          `allowedMethods.${Endpoint.PUT}.originWhitelist`,
                        ) || []
                      }
                      onAddItem={(e, data) => handleOriginAdd(data.value)}
                      onChange={(e, data) =>
                        handleOriginChange(Endpoint.PUT, data.value)
                      }
                    />
                  </SC.OriginWrapper>
                )}
              </div>
            </StyledAccordionContent>

            {/*DELETE*/}
            <StyledAccordionTitle
              active={endpointAccordionStage === 3}
              index={3}
              onClick={() => setEndpointAccordionStage(3)}
            >
              <StyledBodySubHeader>Delete (DELETE)</StyledBodySubHeader>
              <FontAwesomeIcon
                icon={
                  endpointAccordionStage === 3 ? faChevronUp : faChevronDown
                }
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <StyledAccordionContent active={endpointAccordionStage === 3}>
              <div>
                <StyledText>
                  This endpoint will allow users to delete your data.
                </StyledText>

                <SC.ToggleWrapper>
                  <SC.ToggleLabel>Endpoint Active</SC.ToggleLabel>
                  <Toggle
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.DELETE}.${EndpointField.IS_ENABLED}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.DELETE,
                        EndpointField.IS_ENABLED,
                        event.target.checked,
                      )
                    }
                  />

                  <SC.ToggleLabel>Allow Public Access</SC.ToggleLabel>
                  <Toggle
                    disabled={
                      !get(
                        datasetApi,
                        `allowedMethods.${Endpoint.DELETE}.${EndpointField.IS_ENABLED}`,
                      )
                    }
                    defaultChecked={get(
                      datasetApi,
                      `allowedMethods.${Endpoint.DELETE}.${EndpointField.IS_PUBLIC}`,
                    )}
                    icons={false}
                    onChange={(event) =>
                      handleEndpointToggle(
                        Endpoint.DELETE,
                        EndpointField.IS_PUBLIC,
                        event.target.checked,
                      )
                    }
                  />
                </SC.ToggleWrapper>

                {!get(
                  datasetApi,
                  `allowedMethods.${Endpoint.DELETE}.${EndpointField.IS_PUBLIC}`,
                ) && (
                  <SC.OriginWrapper>
                    <SC.ToggleLabel>Allowed Origins</SC.ToggleLabel>
                    <StyledText>
                      Enter a list of origins allowed to access this endpoint.
                    </StyledText>
                    <StyledDropdownUltraWide
                      selectOnBlur={false}
                      style={{ marginTop: 0 }}
                      placeholder={`Enter an origin e.g. 'configur.tech' or '*.configurcodex.com'`}
                      noResultsMessage={'Type to add a new origin'}
                      options={originOptions}
                      search
                      allowAdditions
                      selection
                      multiple
                      value={
                        get(
                          datasetApi,
                          `allowedMethods.${Endpoint.DELETE}.originWhitelist`,
                        ) || []
                      }
                      onAddItem={(e, data) => handleOriginAdd(data.value)}
                      onChange={(e, data) =>
                        handleOriginChange(Endpoint.DELETE, data.value)
                      }
                    />
                  </SC.OriginWrapper>
                )}
              </div>
            </StyledAccordionContent>
          </StyledAccordion>

          <SC.ActionButtonWrapper>
            <FeatureButton
              action={() => setModalStage(STAGE_ONE)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to name'}
            />
            <FeatureButton
              isDisabled={!datasetApi.datasetMetaId || !hasActiveEndpoint}
              action={() =>
                requiresApiKeys ? createAPI(STAGE_THREE) : createAPI()
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={
                requiresApiKeys
                  ? 'Continue to Access'
                  : editingApi
                  ? 'Update API'
                  : 'Create API'
              }
            />
          </SC.ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_THREE && (
        <>
          <StyledSubHeader>API Keys</StyledSubHeader>

          <StyledText>
            To secure your API, create separate API Keys for each user you wish
            to have access.
          </StyledText>

          <FeatureButton
            action={() => {
              setApiKey(InitialAPIKey);
              setModalStage(STAGE_FOUR);
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={`Create API Key`}
            containerStyle={{ margin: `${themeContext.margin.standard} 0` }}
          />

          <DataSample
            sampleColumns={TABLE_SCHEMA}
            sampleRows={fetchedApiKeys.map((k) => {
              return {
                [ID_FIELD]: { value: k._id },
                [NAME_FIELD]: { value: k.alias },
                [DESCRIPTION_FIELD]: { value: k.description || '' },
                [ENDPOINTS_FIELD]: {
                  value: Object.keys(k.allowedMethods || [])
                    .filter((e) => k.allowedMethods?.[e])
                    .map((e) => ENDPOINT_TEXT_MAP[e])
                    .join(),
                },
              };
            })}
            clickableRows={{
              valueField: ID_FIELD,
              action: (keyId: string) => {
                const key = fetchedApiKeys.find((k) => k._id === keyId);

                if (key) {
                  setApiKey(key);
                  setModalStage(STAGE_FOUR);
                }
              },
            }}
          />

          <SC.ActionButtonWrapper>
            <FeatureButton
              action={() => setModalStage(STAGE_TWO)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to dataset'}
            />
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={`Finish`}
            />
          </SC.ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_FOUR && (
        <>
          <StyledSubHeader>API Key Name</StyledSubHeader>

          <StyledText>
            The user or service that will utilise this API.
          </StyledText>

          <StyledInput
            placeholder={'Enter a name for this API Key'}
            value={apiKey.alias}
            onChange={(event, data) => handleKeyAliasChange(data.value)}
          />

          <StyledSubHeader
            style={{
              marginTop: themeContext.margin.large,
            }}
          >
            API Key Description
          </StyledSubHeader>

          <StyledText>
            A brief description of why this key is required.
          </StyledText>

          <StyledInput
            placeholder={'Enter a description for this API Key'}
            value={apiKey.description}
            onChange={(event, data) => handleKeyDescChange(data.value)}
          />

          <StyledSubHeader
            style={{
              marginTop: themeContext.margin.large,
            }}
          >
            Allowed Endpoints
          </StyledSubHeader>

          <StyledAccordion>
            {Object.entries(datasetApi.allowedMethods || []).map(
              ([endpoint, val]) => {
                if (val?.isEnabled && !val?.isPublic) {
                  return (
                    <StyledAccordionTitle>
                      <StyledBodySubHeader>
                        {ENDPOINT_TEXT_MAP[endpoint]}
                      </StyledBodySubHeader>
                      <Toggle
                        defaultChecked={get(
                          apiKey,
                          `allowedMethods.${endpoint}`,
                        )}
                        icons={false}
                        onChange={(event) =>
                          handleKeyEndpointToggle(
                            endpoint as Endpoint,
                            event.target.checked,
                          )
                        }
                      />
                    </StyledAccordionTitle>
                  );
                }

                return null;
              },
            )}
          </StyledAccordion>

          {(apiKey as Interfaces.ApiKeyOutput)?._id && (
            <>
              <StyledSubHeader
                style={{
                  marginTop: themeContext.margin.large,
                }}
              >
                API Key
              </StyledSubHeader>

              <SC.ApiKeyCopyWrapper>
                <CopyCodeBox text={(apiKey as Interfaces.ApiKeyOutput)._id} />
              </SC.ApiKeyCopyWrapper>
            </>
          )}

          <SC.ActionButtonWrapper>
            <FeatureButton
              action={() => setModalStage(STAGE_THREE)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to api keys'}
            />
            <FeatureButton
              action={createAPIKey}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={
                (apiKey as Interfaces.ApiKeyOutput)?._id
                  ? 'Updating API Key'
                  : 'Creating API Key'
              }
            />
          </SC.ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <DeleteConfirmation
          entityType={EntityType.API}
          entityName={datasetApi?.alias}
          cancelAction={() => setModalStage(STAGE_ZERO)}
          deleteAction={deleteApi}
        />
      )}

      {modalStage === STAGE_DELETE_API_KEY && (
        <DeleteConfirmation
          entityType={EntityType.API_KEY}
          entityName={apiKey.alias}
          cancelAction={() => setModalStage(STAGE_THREE)}
          deleteAction={deleteAPIKey}
        />
      )}
    </SC.Wrapper>
  );
};

export default ConnectionAPIModal;
