import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProjectService, {
  ProjectItem,
  ProjectItemOutput,
} from '../../services/project/ProjectService';
import { AppThunk } from '../store';
import InitialProject from './initial-project';

type Project = ProjectItem | ProjectItemOutput;

export interface ProjectState {
  data: Project;
  loading: boolean;
  error: string | null;
}

const initialState: ProjectState = {
  data: InitialProject,
  loading: false,
  error: null,
};

const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // Fetch
    fetchProjectStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProjectSuccess(state, action: PayloadAction<Project>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchProjectFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createProjectStart(state) {
      state.loading = true;
      state.error = null;
    },
    createProjectSuccess(state, action: PayloadAction<ProjectItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createProjectFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateProjectStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateProjectSuccess(state, action: PayloadAction<ProjectItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateProjectFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteProjectStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteProjectSuccess(state, action: PayloadAction<ProjectItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteProjectFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchProjectStart,
  fetchProjectSuccess,
  fetchProjectFailure,
  createProjectStart,
  createProjectSuccess,
  createProjectFailure,
  updateProjectStart,
  updateProjectSuccess,
  updateProjectFailure,
  deleteProjectStart,
  deleteProjectSuccess,
  deleteProjectFailure,
} = ProjectSlice.actions;

export default ProjectSlice.reducer;

export const fetchProject =
  (token: string, id: string, addCMSData?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchProjectStart());
      const fetched = await ProjectService.getProject(token, id, addCMSData);
      dispatch(fetchProjectSuccess(fetched));
    } catch (err) {
      dispatch(fetchProjectFailure((err as string).toString()));
      throw Error((err as string).toString());
    }
  };

export const createProject =
  (token: string, project: Interfaces.Project, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectStart());
      const created = await ProjectService.postProject(token, project, userId);
      dispatch(createProjectSuccess(created));
    } catch (err) {
      dispatch(createProjectFailure((err as string).toString()));
    }
  };

export const updateProject =
  (
    token: string,
    project: Interfaces.ProjectOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectStart());
      const updated = await ProjectService.putProject(token, project, userId);
      dispatch(updateProjectSuccess(updated));
    } catch (err) {
      dispatch(updateProjectFailure((err as string).toString()));
    }
  };

export const deleteProject =
  (token: string, projectId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteProjectStart());
      const deleted = await ProjectService.deleteProject(
        token,
        projectId,
        userId,
      );
      dispatch(deleteProjectSuccess(deleted));
    } catch (err) {
      dispatch(deleteProjectFailure((err as string).toString()));
    }
  };

export const createCMSConnection =
  (
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectStart());
      const updated = await ProjectService.createCMSConnection(
        token,
        project,
        connection,
        userId,
      );
      dispatch(updateProjectSuccess(updated));
    } catch (err) {
      dispatch(updateProjectFailure((err as string).toString()));
    }
  };

export const createAPIConnection =
  (
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectStart());
      const updated = await ProjectService.createAPIConnection(
        token,
        project,
        connection,
        userId,
      );
      dispatch(updateProjectSuccess(updated));
    } catch (err) {
      dispatch(updateProjectFailure((err as string).toString()));
    }
  };

export const createFormConnection =
  (
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectStart());
      const updated = await ProjectService.createFormConnection(
        token,
        project,
        connection,
        userId,
      );
      dispatch(updateProjectSuccess(updated));
    } catch (err) {
      dispatch(updateProjectFailure((err as string).toString()));
    }
  };

export const createPortalConnection =
  (
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectStart());
      const updated = await ProjectService.createPortalConnection(
        token,
        project,
        connection,
        userId,
      );
      dispatch(updateProjectSuccess(updated));
    } catch (err) {
      dispatch(updateProjectFailure((err as string).toString()));
    }
  };
