import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, truncate } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../components/FeatureButton/FeatureButton';
import { EntityType } from '../../../enums';
import useFilter, {
  DynamicConditionalField,
} from '../../../hooks/filter/UseFilter';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledText,
} from '../../../main/theme';
import { showModal } from '../../../store/modal';
import { ModalTypes } from '../../Modal/Modal';

interface AggregationFilterProps {
  fields: Interfaces.FieldOutput[] & { alias?: string };
  queryId?: string;
  graphId?: string;
}

const TRUNCATE_LIMIT = 60;

const matchDropdownOptions = [
  {
    key: 'match-all',
    value: Enums.AggregationFilterOperator.AND,
    text: 'Match All Filter Groups',
  },
  {
    key: 'match-any',
    value: Enums.AggregationFilterOperator.OR,
    text: 'Match Any Filter Groups',
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  position: relative;

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  padding-top: ${({ theme }) => theme.padding.standard};
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${({ theme }) => `0 0 ${theme.margin.large}`};
`;

const Filter = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: ${({ theme }) => theme.borders.radius};

  color: ${({ theme, active }) =>
    active ? theme.colors.system.white : theme.colors.system.offBlack};

  > p {
    color: ${({ theme, active }) =>
      active ? theme.colors.system.white : theme.colors.system.offBlack};

    &:first-of-type {
      margin: 0;
    }
  }
`;

const FilterHeader = styled.div<{ active?: boolean; showingMore?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${({ showingMore, theme }) =>
    !showingMore ? theme.margin.standard : 0};

  > p {
    color: ${({ theme, active }) =>
      active ? theme.colors.system.white : theme.colors.system.offBlack};
    margin: 0;
  }
`;

const AggregationFilter: FC<AggregationFilterProps> = ({
  fields,
  queryId,
  graphId,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { updateFilterGroup, getDatasetFilters } = useFilter();

  const [filters, setFilters] = useState<DynamicConditionalField[]>();

  const entityId = queryId || graphId;

  // Fetch filters
  useEffect(() => {
    if (entityId && getDatasetFilters(entityId)) {
      const filter = getDatasetFilters(entityId).map((f) => {
        return {
          ...f,
          active: true,
          value: (f.value as DynamicConditionalField[])?.map((v) => {
            return { ...v, active: v.active };
          }),
        };
      });
      setFilters(filter);
    }
  }, [entityId, getDatasetFilters]);

  const handleFilterMatchOperatorChange = (
    matchOperator:
      | Enums.AggregationFilterOperator.AND
      | Enums.AggregationFilterOperator.OR,
  ) => {
    if (entityId && filters?.length) {
      const cloned = cloneDeep(filters);

      cloned[0].operator = matchOperator;

      updateFilterGroup(entityId, cloned);
    }
  };

  return (
    <Wrapper>
      {(filters || []).length > 0 && (
        <Filters>
          <StyledBodySubHeader>Match Group Condition</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select a match condition'}
            options={matchDropdownOptions}
            selection
            value={filters?.[0]?.operator}
            onChange={(e, data) => handleFilterMatchOperatorChange(data.value)}
            style={{ marginBottom: themeContext.margin.small }}
          />

          {(filters?.[0]?.value as DynamicConditionalField[]).map((f, i) => {
            const filterColumns = (f.value as DynamicConditionalField[]).reduce(
              (acc, val) => {
                const exists = acc.find(
                  (f) => f === val?.alias || f === val?.field,
                );

                if (!exists && (val.alias || val.field)) {
                  acc.push(val.alias || val.field || '');
                }

                return acc;
              },
              [] as string[],
            );

            return (
              <FeatureButton
                key={`filter-${i}`}
                action={() =>
                  dispatch(
                    showModal({
                      visible: true,
                      modal: ModalTypes.ADD_FILTER,
                      fullScreen: true,
                      additionalProps: {
                        fields,
                        existingFilterIndex: i,
                        type: queryId
                          ? EntityType.AGGREGATION
                          : EntityType.GRAPH,
                        ignoreLocalStorage: true,
                      },
                    }),
                  )
                }
                size={'auto'}
                height={100}
                color={
                  f.active
                    ? themeContext.colors.general.purple
                    : themeContext.colors.system.grey
                }
                icon={
                  <Filter active={f.active}>
                    <FilterHeader
                      active={f.active}
                      showingMore={filterColumns.length > 3}
                    >
                      <StyledBodySubHeader>
                        {f.operator === Enums.AggregationFilterOperator.AND
                          ? 'Match All'
                          : 'Match Any'}
                      </StyledBodySubHeader>
                    </FilterHeader>
                    <StyledBodySubHeader>Filtering</StyledBodySubHeader>
                    <StyledText>
                      {truncate(
                        `${filterColumns.slice(0, 3).join(', ')} ${
                          filterColumns.length > 3 ? (
                            <strong>+ {filterColumns.length - 3} more</strong>
                          ) : (
                            ''
                          )
                        }`,
                        { length: TRUNCATE_LIMIT },
                      )}
                    </StyledText>
                  </Filter>
                }
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                faceStyle={{
                  width: '100%',
                  alignItems: 'flex-start',
                }}
                containerStyle={{
                  marginTop: themeContext.margin.standard,
                  justifyContent: 'space-between',
                }}
              />
            );
          })}
        </Filters>
      )}

      <FeatureButton
        action={() =>
          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.ADD_FILTER,
              fullScreen: true,
              additionalProps: {
                fields,
                type: queryId ? EntityType.AGGREGATION : EntityType.GRAPH,
                ignoreLocalStorage: true,
              },
            }),
          )
        }
        size={FeatureButtonSize.WIDE_SMALL}
        color={themeContext.colors.general.blue}
        text={'Add Filter Group'}
        containerStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        faceStyle={{ width: '100%' }}
      />
    </Wrapper>
  );
};

export default AggregationFilter;
