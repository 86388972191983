export enum ListOptionColor {
  TURQUOISE = '#1abc9c',
  GREEN_SEA = '#16a085',
  EMERALD = '#2ecc71',
  NEPHRITIS = '#27ae60',
  PETER_RIVER = '#3498db',
  BELIZE_HOLE = '#2980b9',
  AMETHYST = '#9b59b6',
  WISTERIA = '#8e44ad',
  SUN_FLOWER = '#f1c40f',
  ORANGE = '#f39c12',
  CARROT = '#e67e22',
  PUMPKIN = '#d35400',
  ALIZARIN = '#e74c3c',
  POMEGRANATE = '#c0392b',
  WET_ASPHALT = '#34495e',
  MIDNIGHT_BLUE = '#2c3e50',
}
