import styled from 'styled-components';

export const NotificationEventActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const TextButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.general.sea};
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
  align-self: center;
`;

export const NotificationEventButtons = styled.div`
  display: flex;
  flex-direction: 'row';

  > div {
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;
