import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { updateActiveDatasetStage } from '../../../../store/dataset-stage';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';

const ConfigureAccessStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update datasetMeta
      const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

      cloned.access[type][group] = updated;

      // Remove if present in another access type
      Object.entries(cloned.access).forEach(([cType, cAccess]) => {
        if (cType !== type) {
          cAccess[group] = cAccess[group].filter((e) => !updated.includes(e));
        }
      });

      // Save datasetMeta
      await editDatasetMeta(cloned);
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Set Permissions</StyledH2>
        <StageBodyText>
          Add users, teams or the whole organisation to the fields below to
          control who can access your data and what permissions they have.
        </StageBodyText>

        <AccessSelection access={datasetMeta?.access} onChange={handleChange} />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ConfigureAccessStage;
