import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const Wrapper = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.darkBlueShadow};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 ${({ theme }) => theme.margin.standard};
`;

const DatasetActionHeader: FC = () => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();
  const isEditing = location.pathname.includes('edit');
  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');

  if (!datasetMeta?._id || !activeDataCollectionItem) {
    return null;
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <SectionWrapper>
          <FeatureButton
            isDisabled={isEditing || isReplacing || isMerging}
            action={() =>
              history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}/view`)
            }
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.green}
            text={`View Dataset`}
          />
        </SectionWrapper>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default DatasetActionHeader;
