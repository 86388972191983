import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FormService from '../../services/form/FormService';
import { AppThunk } from '../store';
import InitialForm from './initial-form';

type Form = Interfaces.Form | Interfaces.FormOutput;

export interface FormState {
  data: Form;
  loading: boolean;
  error: string | null;
}

const initialState: FormState = {
  data: InitialForm,
  loading: false,
  error: null,
};

export interface FormsItemOutput {
  data: Interfaces.FormOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export const initialFormsState: FormsItemOutput = {
  data: [],
  pagination: {
    currentPageNum: 1,
    prevPageNum: null,
    nextPageNum: null,
    totalCount: 0,
    totalPages: 0,
  },
};

const FormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // Reset
    resetForm(state) {
      state.data = InitialForm;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchFormStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFormSuccess(state, action: PayloadAction<Form>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchFormFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createFormStart(state) {
      state.loading = true;
      state.error = null;
    },
    createFormSuccess(state, action: PayloadAction<Form>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createFormFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateFormStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateFormSuccess(state, action: PayloadAction<Form>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateFormFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteFormStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteFormSuccess(state, action: PayloadAction<Form>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteFormFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetForm,
  fetchFormStart,
  fetchFormSuccess,
  fetchFormFailure,
  createFormStart,
  createFormSuccess,
  createFormFailure,
  updateFormStart,
  updateFormSuccess,
  updateFormFailure,
  deleteFormStart,
  deleteFormSuccess,
  deleteFormFailure,
} = FormSlice.actions;

export default FormSlice.reducer;

export const fetchForm =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchFormStart());
      const fetched = await FormService.getForm(token, id);
      dispatch(fetchFormSuccess(fetched));
    } catch (err) {
      dispatch(fetchFormFailure((err as string).toString()));
    }
  };

export const createForm =
  (
    token: string,
    form: Interfaces.Form,
    projectId: string,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createFormStart());
      const created = await FormService.postForm(
        token,
        form,
        projectId,
        userId,
      );
      dispatch(createFormSuccess(created));
    } catch (err) {
      dispatch(createFormFailure((err as string).toString()));
    }
  };

export const updateForm =
  (
    token: string,
    form: Interfaces.FormOutput,
    projectId: string,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateFormStart());
      const updated = await FormService.putForm(token, form, projectId, userId);
      dispatch(updateFormSuccess(updated));
    } catch (err) {
      dispatch(updateFormFailure((err as string).toString()));
    }
  };

export const deleteForm =
  (token: string, formId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFormStart());
      const deleted = await FormService.deleteForm(token, formId, userId);
      dispatch(deleteFormSuccess(deleted));
    } catch (err) {
      dispatch(deleteFormFailure((err as string).toString()));
    }
  };
