import { Enums } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SearchQuery } from '../../interfaces/Search';
import IntegrationTemplateService, {
  IntegrationTemplateOutput,
  IntegrationTemplatesOutput,
} from '../../services/integration/IntegrationTemplateService';
import { AppThunk } from '../store';

export interface IntegrationTemplatesState {
  data: {
    data: IntegrationTemplateOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };

  loading: boolean;
  error: string | null;
}

const initialState: IntegrationTemplatesState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const IntegrationTemplatesSlice = createSlice({
  name: 'integration-templates',
  initialState,
  reducers: {
    // Fetch
    fetchIntegrationTemplatesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchIntegrationTemplatesSuccess(
      state,
      action: PayloadAction<IntegrationTemplatesOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchIntegrationTemplatesFailure(state, action: PayloadAction<string>) {
      state.data = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchIntegrationTemplatesStart,
  fetchIntegrationTemplatesSuccess,
  fetchIntegrationTemplatesFailure,
} = IntegrationTemplatesSlice.actions;

export default IntegrationTemplatesSlice.reducer;

export const fetchIntegrationTemplates =
  (
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchIntegrationTemplatesStart());
      const fetched = await IntegrationTemplateService.getIntegrationTemplates(
        token,
        query,
        searchQuery,
        pageNum,
        limit,
        orderBy,
      );
      dispatch(
        fetchIntegrationTemplatesSuccess(
          fetched.data as unknown as IntegrationTemplatesOutput,
        ),
      );
    } catch (err) {
      dispatch(fetchIntegrationTemplatesFailure((err as string).toString()));
      if (
        (err as AxiosError)?.response?.data.statusCode !==
        Enums.StatusCode.NOT_FOUND
      ) {
        throw err;
      }
    }
  };
