import AvatarIcon1stPlace from '../../assets/icons/avatars/icon-1st-place.svg';
import AvatarIcon5Star from '../../assets/icons/avatars/icon-5-star.svg';
import AvatarIconAdvertising from '../../assets/icons/avatars/icon-advertising.svg';
import AvatarIconAgency from '../../assets/icons/avatars/icon-agency.svg';
import AvatarIconAirplaneTicket from '../../assets/icons/avatars/icon-airplane-ticket.svg';
import AvatarIconAlarm from '../../assets/icons/avatars/icon-alarm.svg';
import AvatarIconAmbulance from '../../assets/icons/avatars/icon-ambulance.svg';
import AvatarIconAnalyticsGraph from '../../assets/icons/avatars/icon-analytics-graph.svg';
import AvatarIconAnchor from '../../assets/icons/avatars/icon-anchor.svg';
import AvatarIconAnonymous from '../../assets/icons/avatars/icon-anonymous.svg';
import AvatarIconApple from '../../assets/icons/avatars/icon-apple.svg';
import AvatarIconApron from '../../assets/icons/avatars/icon-apron.svg';
import AvatarIconAstrophysics from '../../assets/icons/avatars/icon-astrophysics.svg';
import AvatarIconAtom from '../../assets/icons/avatars/icon-atom.svg';
import AvatarIconAward from '../../assets/icons/avatars/icon-award.svg';
import AvatarIconAztec from '../../assets/icons/avatars/icon-aztec.svg';
import AvatarIconBalanceScales from '../../assets/icons/avatars/icon-balance-scales.svg';
import AvatarIconBalloons from '../../assets/icons/avatars/icon-balloons.svg';
import AvatarIconBarGraph from '../../assets/icons/avatars/icon-bar-graph.svg';
import AvatarIconBarrel from '../../assets/icons/avatars/icon-barrel.svg';
import AvatarIconBench from '../../assets/icons/avatars/icon-bench.svg';
import AvatarIconBicycle from '../../assets/icons/avatars/icon-bicycle.svg';
import AvatarIconBlankMap from '../../assets/icons/avatars/icon-blank-map.svg';
import AvatarIconBlueprint from '../../assets/icons/avatars/icon-blueprint.svg';
import AvatarIconBonus from '../../assets/icons/avatars/icon-bonus.svg';
import AvatarIconBooks from '../../assets/icons/avatars/icon-books.svg';
import AvatarIconBookshelf from '../../assets/icons/avatars/icon-bookshelf.svg';
import AvatarIconBox from '../../assets/icons/avatars/icon-box.svg';
import AvatarIconBrush from '../../assets/icons/avatars/icon-brush.svg';
import AvatarIconBurger from '../../assets/icons/avatars/icon-burger.svg';
import AvatarIconCalendar from '../../assets/icons/avatars/icon-calendar.svg';
import AvatarIconCamera from '../../assets/icons/avatars/icon-camera.svg';
import AvatarIconCanyon from '../../assets/icons/avatars/icon-canyon.svg';
import AvatarIconCaptcha from '../../assets/icons/avatars/icon-captcha.svg';
import AvatarIconCar from '../../assets/icons/avatars/icon-car.svg';
import AvatarIconCargoShip from '../../assets/icons/avatars/icon-cargo-ship.svg';
import AvatarIconCarousel from '../../assets/icons/avatars/icon-carousel.svg';
import AvatarIconCCTV from '../../assets/icons/avatars/icon-cctv.svg';
import AvatarIconCertificate from '../../assets/icons/avatars/icon-certificate.svg';
import AvatarIconChalk from '../../assets/icons/avatars/icon-chalk.svg';
import AvatarIconChalkboard from '../../assets/icons/avatars/icon-chalkboard.svg';
import AvatarIconChurch from '../../assets/icons/avatars/icon-church.svg';
import AvatarIconCoin from '../../assets/icons/avatars/icon-coin.svg';
import AvatarIconColourCloud from '../../assets/icons/avatars/icon-colour-cloud.svg';
import AvatarIconColourFile from '../../assets/icons/avatars/icon-colour-file.svg';
import AvatarIconColourPencils from '../../assets/icons/avatars/icon-colour-pencils.svg';
import AvatarIconCompass from '../../assets/icons/avatars/icon-compass.svg';
import AvatarIconCone from '../../assets/icons/avatars/icon-cone.svg';
import AvatarIconConfetti from '../../assets/icons/avatars/icon-confetti.svg';
import AvatarIconCoralReef from '../../assets/icons/avatars/icon-coral-reef.svg';
import AvatarIconCough from '../../assets/icons/avatars/icon-cough.svg';
import AvatarIconCowboy from '../../assets/icons/avatars/icon-cowboy.svg';
import AvatarIconCoworking from '../../assets/icons/avatars/icon-coworking.svg';
import AvatarIconCrab from '../../assets/icons/avatars/icon-crab.svg';
import AvatarIconCradle from '../../assets/icons/avatars/icon-cradle.svg';
import AvatarIconCrate from '../../assets/icons/avatars/icon-crate.svg';
import AvatarIconCreditCard from '../../assets/icons/avatars/icon-credit-card.svg';
import AvatarIconCruiseShip from '../../assets/icons/avatars/icon-cruise-ship.svg';
import AvatarIconCups from '../../assets/icons/avatars/icon-cups.svg';
import AvatarIconDatabase from '../../assets/icons/avatars/icon-database.svg';
import AvatarIconDiamond from '../../assets/icons/avatars/icon-diamond.svg';
import AvatarIconDirection from '../../assets/icons/avatars/icon-direction.svg';
import AvatarIconDivingHelmet from '../../assets/icons/avatars/icon-diving-helmet.svg';
import AvatarIconDna from '../../assets/icons/avatars/icon-dna.svg';
import AvatarIconDollar from '../../assets/icons/avatars/icon-dollar.svg';
import AvatarIconDoorDelivery from '../../assets/icons/avatars/icon-door-delivery.svg';
import AvatarIconDrama from '../../assets/icons/avatars/icon-drama.svg';
import AvatarIconDress from '../../assets/icons/avatars/icon-dress.svg';
import AvatarIconDriverLicense from '../../assets/icons/avatars/icon-driver-license.svg';
import AvatarIconDrone from '../../assets/icons/avatars/icon-drone.svg';
import AvatarIconEmailReceived from '../../assets/icons/avatars/icon-email-received.svg';
import AvatarIconEmail from '../../assets/icons/avatars/icon-email.svg';
import AvatarIconExperiment from '../../assets/icons/avatars/icon-experiment.svg';
import AvatarIconFaceMask from '../../assets/icons/avatars/icon-face-mask.svg';
import AvatarIconFaceShield from '../../assets/icons/avatars/icon-face-shield.svg';
import AvatarIconFeedback from '../../assets/icons/avatars/icon-feedback.svg';
import AvatarIconFilmReel from '../../assets/icons/avatars/icon-film-reel.svg';
import AvatarIconFireSketch from '../../assets/icons/avatars/icon-fire-sketch.svg';
import AvatarIconFish from '../../assets/icons/avatars/icon-fish.svg';
import AvatarIconFishingVest from '../../assets/icons/avatars/icon-fishing-vest.svg';
import AvatarIconFolders from '../../assets/icons/avatars/icon-folders.svg';
import AvatarIconFootball from '../../assets/icons/avatars/icon-football.svg';
import AvatarIconFrenchFries from '../../assets/icons/avatars/icon-french-fries.svg';
import AvatarIconFunnyDisguise from '../../assets/icons/avatars/icon-funny-disguise.svg';
import AvatarIconGift from '../../assets/icons/avatars/icon-gift.svg';
import AvatarIconGlobalisation from '../../assets/icons/avatars/icon-globalisation.svg';
import AvatarIconGlobe from '../../assets/icons/avatars/icon-globe.svg';
import AvatarIconGoal from '../../assets/icons/avatars/icon-goal.svg';
import AvatarIconGoldCart from '../../assets/icons/avatars/icon-gold-cart.svg';
import AvatarIconGoldMine from '../../assets/icons/avatars/icon-gold-mine.svg';
import AvatarIconGolfBall from '../../assets/icons/avatars/icon-golf-ball.svg';
import AvatarIconGoogleWindmill from '../../assets/icons/avatars/icon-google-windmill.svg';
import AvatarIconGorilla from '../../assets/icons/avatars/icon-gorilla.svg';
import AvatarIconGreenMap from '../../assets/icons/avatars/icon-green-map.svg';
import AvatarIconGroupChat from '../../assets/icons/avatars/icon-group-chat.svg';
import AvatarIconGroupVideoCall from '../../assets/icons/avatars/icon-group-video-call.svg';
import AvatarIconHacker from '../../assets/icons/avatars/icon-hacker.svg';
import AvatarIconHat from '../../assets/icons/avatars/icon-hat.svg';
import AvatarIconHelicopter from '../../assets/icons/avatars/icon-helicopter.svg';
import AvatarIconHelpingHand from '../../assets/icons/avatars/icon-helping-hand.svg';
import AvatarIconHighSpeedTrain from '../../assets/icons/avatars/icon-high-speed-train.svg';
import AvatarIconHorseShow from '../../assets/icons/avatars/icon-horse-show.svg';
import AvatarIconHospital from '../../assets/icons/avatars/icon-hospital.svg';
import AvatarIconHotAirBalloon from '../../assets/icons/avatars/icon-hot-air-balloon.svg';
import AvatarIconHotChocolate from '../../assets/icons/avatars/icon-hot-chocolate.svg';
import AvatarIconHouse from '../../assets/icons/avatars/icon-house.svg';
import AvatarIconKangaroo from '../../assets/icons/avatars/icon-kangaroo.svg';
import AvatarIconKey from '../../assets/icons/avatars/icon-key.svg';
import AvatarIconLibrary from '../../assets/icons/avatars/icon-library.svg';
import AvatarIconLightningBolt from '../../assets/icons/avatars/icon-lightning-bolt.svg';
import AvatarIconLightning from '../../assets/icons/avatars/icon-lightning.svg';
import AvatarIconLike from '../../assets/icons/avatars/icon-like.svg';
import AvatarIconLion from '../../assets/icons/avatars/icon-lion.svg';
import AvatarIconLobster from '../../assets/icons/avatars/icon-lobster.svg';
import AvatarIconLocation from '../../assets/icons/avatars/icon-location.svg';
import AvatarIconMaleFaceMask from '../../assets/icons/avatars/icon-male-face-mask.svg';
import AvatarIconMaracas from '../../assets/icons/avatars/icon-maracas.svg';
import AvatarIconMerryGoRound from '../../assets/icons/avatars/icon-merry-go-round.svg';
import AvatarIconMexicanHat from '../../assets/icons/avatars/icon-mexican-hat.svg';
import AvatarIconMicrophone from '../../assets/icons/avatars/icon-microphone.svg';
import AvatarIconMining from '../../assets/icons/avatars/icon-mining.svg';
import AvatarIconMolecule from '../../assets/icons/avatars/icon-molecule.svg';
import AvatarIconMoneyBag from '../../assets/icons/avatars/icon-money-bag.svg';
import AvatarIconMotorbike from '../../assets/icons/avatars/icon-motorbike.svg';
import AvatarIconMovie from '../../assets/icons/avatars/icon-movie.svg';
import AvatarIconMusicHeadphones from '../../assets/icons/avatars/icon-music-headphones.svg';
import AvatarIconMusic from '../../assets/icons/avatars/icon-music.svg';
import AvatarIconNachos from '../../assets/icons/avatars/icon-nachos.svg';
import AvatarIconNewspaper from '../../assets/icons/avatars/icon-newspaper.svg';
import AvatarIconNightTimeClouds from '../../assets/icons/avatars/icon-night-time-clouds.svg';
import AvatarIconNightTime from '../../assets/icons/avatars/icon-night-time.svg';
import AvatarIconNotes from '../../assets/icons/avatars/icon-notes.svg';
import AvatarIconNuclearButton from '../../assets/icons/avatars/icon-nuclear-button.svg';
import AvatarIconOctopus from '../../assets/icons/avatars/icon-octopus.svg';
import AvatarIconOnlineEducation from '../../assets/icons/avatars/icon-online-education.svg';
import AvatarIconOnlinePresentation from '../../assets/icons/avatars/icon-online-presentation.svg';
import AvatarIconPandaBear from '../../assets/icons/avatars/icon-panda-bear.svg';
import AvatarIconPantone from '../../assets/icons/avatars/icon-pantone.svg';
import AvatarIconParking from '../../assets/icons/avatars/icon-parking.svg';
import AvatarIconParrot from '../../assets/icons/avatars/icon-parrot.svg';
import AvatarIconPassword from '../../assets/icons/avatars/icon-password.svg';
import AvatarIconPayphone from '../../assets/icons/avatars/icon-payphone.svg';
import AvatarIconPencil from '../../assets/icons/avatars/icon-pencil.svg';
import AvatarIconPercentage from '../../assets/icons/avatars/icon-percentage.svg';
import AvatarIconPicture from '../../assets/icons/avatars/icon-picture.svg';
import AvatarIconPigeon from '../../assets/icons/avatars/icon-pigeon.svg';
import AvatarIconPinata from '../../assets/icons/avatars/icon-pinata.svg';
import AvatarIconPizza from '../../assets/icons/avatars/icon-pizza.svg';
import AvatarIconPlan from '../../assets/icons/avatars/icon-plan.svg';
import AvatarIconPolarBear from '../../assets/icons/avatars/icon-polar-bear.svg';
import AvatarIconPolaroid from '../../assets/icons/avatars/icon-polaroid.svg';
import AvatarIconPolice from '../../assets/icons/avatars/icon-police.svg';
import AvatarIconPopcorn from '../../assets/icons/avatars/icon-popcorn.svg';
import AvatarIconPresent from '../../assets/icons/avatars/icon-present.svg';
import AvatarIconPresentation from '../../assets/icons/avatars/icon-presentation.svg';
import AvatarIconPriority from '../../assets/icons/avatars/icon-priority.svg';
import AvatarIconProblemSolve from '../../assets/icons/avatars/icon-problem-solve.svg';
import AvatarIconProtection from '../../assets/icons/avatars/icon-protection.svg';
import AvatarIconPullover from '../../assets/icons/avatars/icon-pullover.svg';
import AvatarIconQa from '../../assets/icons/avatars/icon-qa.svg';
import AvatarIconRabbit from '../../assets/icons/avatars/icon-rabbit.svg';
import AvatarIconRadar from '../../assets/icons/avatars/icon-radar.svg';
import AvatarIconRainbow from '../../assets/icons/avatars/icon-rainbow.svg';
import AvatarIconRanking from '../../assets/icons/avatars/icon-ranking.svg';
import AvatarIconReport from '../../assets/icons/avatars/icon-report.svg';
import AvatarIconRgb from '../../assets/icons/avatars/icon-rgb.svg';
import AvatarIconRoadSign from '../../assets/icons/avatars/icon-road-sign.svg';
import AvatarIconRoad from '../../assets/icons/avatars/icon-road.svg';
import AvatarIconRobot from '../../assets/icons/avatars/icon-robot.svg';
import AvatarIconRocket from '../../assets/icons/avatars/icon-rocket.svg';
import AvatarIconRollerCoaster from '../../assets/icons/avatars/icon-roller-coaster.svg';
import AvatarIconRubberBands from '../../assets/icons/avatars/icon-rubber-bands.svg';
import AvatarIconSafety from '../../assets/icons/avatars/icon-safety.svg';
import AvatarIconSchool from '../../assets/icons/avatars/icon-school.svg';
import AvatarIconScript from '../../assets/icons/avatars/icon-script.svg';
import AvatarIconSearch from '../../assets/icons/avatars/icon-search.svg';
import AvatarIconSecurity1 from '../../assets/icons/avatars/icon-security-1.svg';
import AvatarIconSecurity from '../../assets/icons/avatars/icon-security.svg';
import AvatarIconSeesaw from '../../assets/icons/avatars/icon-seesaw.svg';
import AvatarIconShell from '../../assets/icons/avatars/icon-shell.svg';
import AvatarIconShipWheel from '../../assets/icons/avatars/icon-ship-wheel.svg';
import AvatarIconShoppingBagColours from '../../assets/icons/avatars/icon-shopping-bag-colours.svg';
import AvatarIconShoppingBag from '../../assets/icons/avatars/icon-shopping-bag.svg';
import AvatarIconSmartphone from '../../assets/icons/avatars/icon-smartphone.svg';
import AvatarIconSnorkel from '../../assets/icons/avatars/icon-snorkel.svg';
import AvatarIconSnorkeling from '../../assets/icons/avatars/icon-snorkeling.svg';
import AvatarIconSoccerBall from '../../assets/icons/avatars/icon-soccer-ball.svg';
import AvatarIconSportsShirt from '../../assets/icons/avatars/icon-sports-shirt.svg';
import AvatarIconSprout from '../../assets/icons/avatars/icon-sprout.svg';
import AvatarIconStadium from '../../assets/icons/avatars/icon-stadium.svg';
import AvatarIconStar from '../../assets/icons/avatars/icon-star.svg';
import AvatarIconStartup from '../../assets/icons/avatars/icon-startup.svg';
import AvatarIconStayHome from '../../assets/icons/avatars/icon-stay-home.svg';
import AvatarIconStopSign from '../../assets/icons/avatars/icon-stop-sign.svg';
import AvatarIconStrategy from '../../assets/icons/avatars/icon-strategy.svg';
import AvatarIconStreetLights from '../../assets/icons/avatars/icon-street-lights.svg';
import AvatarIconSubmarine from '../../assets/icons/avatars/icon-submarine.svg';
import AvatarIconSuit from '../../assets/icons/avatars/icon-suit.svg';
import AvatarIconSuitcase from '../../assets/icons/avatars/icon-suitcase.svg';
import AvatarIconSunflower from '../../assets/icons/avatars/icon-sunflower.svg';
import AvatarIconTarget from '../../assets/icons/avatars/icon-target.svg';
import AvatarIconTaxi from '../../assets/icons/avatars/icon-taxi.svg';
import AvatarIconThermometer from '../../assets/icons/avatars/icon-thermometer.svg';
import AvatarIconThinking from '../../assets/icons/avatars/icon-thinking.svg';
import AvatarIconThumbsUp from '../../assets/icons/avatars/icon-thumbs-up.svg';
import AvatarIconToilet from '../../assets/icons/avatars/icon-toilet.svg';
import AvatarIconToxicBin from '../../assets/icons/avatars/icon-toxic-bin.svg';
import AvatarIconTrafficBarrier from '../../assets/icons/avatars/icon-traffic-barrier.svg';
import AvatarIconTrafficLights from '../../assets/icons/avatars/icon-traffic-lights.svg';
import AvatarIconTrain from '../../assets/icons/avatars/icon-train.svg';
import AvatarIconTrashCan from '../../assets/icons/avatars/icon-trash-can.svg';
import AvatarIconTreasureChest from '../../assets/icons/avatars/icon-treasure-chest.svg';
import AvatarIconTreasureMap from '../../assets/icons/avatars/icon-treasure-map.svg';
import AvatarIconTrophy from '../../assets/icons/avatars/icon-trophy.svg';
import AvatarIconUpload from '../../assets/icons/avatars/icon-upload.svg';
import AvatarIconUrbanHouse from '../../assets/icons/avatars/icon-urban-house.svg';
import AvatarIconUsability from '../../assets/icons/avatars/icon-usability.svg';
import AvatarIconVideoGameController from '../../assets/icons/avatars/icon-video-game-controller.svg';
import AvatarIconWebsiteBug from '../../assets/icons/avatars/icon-website-bug.svg';
import AvatarIconWeddingSuit from '../../assets/icons/avatars/icon-wedding-suit.svg';
import AvatarIconWhaleTail1 from '../../assets/icons/avatars/icon-whale-tail-1.svg';
import AvatarIconWhale from '../../assets/icons/avatars/icon-whale.svg';
import AvatarIconWindmill from '../../assets/icons/avatars/icon-windmill.svg';
import AvatarIconWindy from '../../assets/icons/avatars/icon-windy.svg';
import AvatarIconWorkingAtHomeStorm from '../../assets/icons/avatars/icon-working-at-home-storm.svg';
import AvatarIconWorkingAtHome from '../../assets/icons/avatars/icon-working-at-home.svg';
import AvatarIconWorkspace from '../../assets/icons/avatars/icon-workspace.svg';
import AvatarIconWorldwide from '../../assets/icons/avatars/icon-worldwide.svg';
import AvatarIconZebra from '../../assets/icons/avatars/icon-zebra.svg';
// MS
import AvatarIconMSAndroid from '../../assets/icons/avatars/ms/icon-android.svg';
import AvatarIconMSApple from '../../assets/icons/avatars/ms/icon-apple.svg';
import AvatarIconMSEmail from '../../assets/icons/avatars/ms/icon-email.svg';
import AvatarIconMSFacebook from '../../assets/icons/avatars/ms/icon-facebook.svg';
import AvatarIconMSGoogle from '../../assets/icons/avatars/ms/icon-google.svg';
import AvatarIconMSLine from '../../assets/icons/avatars/ms/icon-line.svg';
import AvatarIconMSMessaging from '../../assets/icons/avatars/ms/icon-messaging.svg';
import AvatarIconMSMessenger from '../../assets/icons/avatars/ms/icon-messenger.svg';
import AvatarIconMSMMIQ from '../../assets/icons/avatars/ms/icon-mmiq.svg';
import AvatarIconMSMobileOrange from '../../assets/icons/avatars/ms/icon-mobile-orange.svg';
import AvatarIconMSMobile from '../../assets/icons/avatars/ms/icon-mobile.svg';
import AvatarIconMSMoney from '../../assets/icons/avatars/ms/icon-money.svg';
import AvatarIconMSRCS from '../../assets/icons/avatars/ms/icon-rcs.svg';
import AvatarIconMSRefreshUsers from '../../assets/icons/avatars/ms/icon-refresh-users.svg';
import AvatarIconMSSignal from '../../assets/icons/avatars/ms/icon-signal.svg';
import AvatarIconMSSkype from '../../assets/icons/avatars/ms/icon-skype.svg';
import AvatarIconMSSMS from '../../assets/icons/avatars/ms/icon-sms.svg';
import AvatarIconMSTalk from '../../assets/icons/avatars/ms/icon-talk.svg';
import AvatarIconMSTelegram from '../../assets/icons/avatars/ms/icon-telegram.svg';
import AvatarIconMSTikTok from '../../assets/icons/avatars/ms/icon-tiktok.svg';
import AvatarIconMSViber from '../../assets/icons/avatars/ms/icon-viber.svg';
import AvatarIconMSWeb from '../../assets/icons/avatars/ms/icon-web.svg';
import AvatarIconMSWeChat from '../../assets/icons/avatars/ms/icon-wechat.svg';
import AvatarIconMSWhatsApp from '../../assets/icons/avatars/ms/icon-whatsapp.svg';

export enum AvatarIcon {
  ATOM = 'atom',
  FIRST_PLACE = '1st-place',
  FIVE_STAR = '5-star',
  ADVERTISING = 'advertising',
  ANALYTICS_GRAPH = 'analytics-graph',
  MUSIC = 'music',
  MUSIC_HEADPHONES = 'music-headphones',
  NEWSPAPER = 'newspaper',
  AGENCY = 'agency',
  AIRPLANE_TICKET = 'airplane-ticket',
  POPCORN = 'popcorn',
  PRESENT = 'present',
  ROBOT = 'robot',
  SHOPPING_BAG = 'shopping-bag',
  SNORKEL = 'snorkel',
  STAR = 'star',
  VIDEO_GAME_CONTROLLER = 'video-game-controller',
  ALARM = 'alarm',
  AMBULANCE = 'ambulance',
  ANCHOR = 'anchor',
  ANONYMOUS = 'anonymous',
  APPLE = 'apple',
  APRON = 'apron',
  ASTROPHYSICS = 'astrophysics',
  AWARD = 'award',
  AZTEC = 'aztec',
  BALANCE_SCALES = 'balance-scales',
  BALLOONS = 'balloons',
  BAR_GRAPH = 'bar-graph',
  BARREL = 'barrel',
  BENCH = 'bench',
  BICYCLE = 'bicycle',
  BLANK_MAP = 'blank-map',
  BLUEPRINT = 'blueprint',
  BONUS = 'bonus',
  BOOKS = 'books',
  BOOKSHELF = 'bookshelf',
  BOX = 'box',
  BRUSH = 'brush',
  BURGER = 'burger',
  CALENDAR = 'calendar',
  CAMERA = 'camera',
  CANYON = 'canyon',
  CAPTCHA = 'captcha',
  CAR = 'car',
  CARGO_SHIP = 'cargo-ship',
  CAROUSEL = 'carousel',
  CCTV = 'cctv',
  CERTIFICATE = 'certificate',
  CHALK = 'chalk',
  CHALKBOARD = 'chalkboard',
  CHURCH = 'church',
  COLOUR_PENCILS = 'colour-pencils',
  COLOUR_CLOUD = 'colour-cloud',
  COLOUR_FILE = 'colour-file',
  COIN = 'coin',
  COMPASS = 'compass',
  CONE = 'cone',
  CONFETTI = 'confetti',
  CORAL_REEF = 'coral-reef',
  COUGH = 'cough',
  COWBOY = 'cowboy',
  COWORKING = 'coworking',
  CRAB = 'crab',
  CRADLE = 'cradle',
  CRATE = 'crate',
  CREDIT_CARD = 'credit-card',
  CRUISE_SHIP = 'cruise-ship ',
  CUPS = 'cups',
  DATABASE = 'database',
  DIAMOND = 'diamond',
  DIRECTION_SIGN = 'direction-sign',
  DIRECTION = 'direction',
  DIVING_HELMET = 'diving-helmet',
  DNA = 'dna',
  DOLLAR = 'dollar',
  DOOR_DELIVERY = 'door-delivery',
  DRAMA = 'drama',
  DRESS = 'dress',
  DRIVER_LICENSE = 'driver-license',
  DRONE = 'drone',
  EMAIL_RECEIVED = 'email-received',
  EMAIL = 'email',
  EXPERIMENT = 'experiment',
  FACE_MASK = 'face-mask',
  FACE_SHIELD = 'face-shield',
  FEEDBACK = 'feedback',
  FILM_REEL = 'film-reel',
  FIRE_SKETCH = 'fire-sketch',
  FISH = 'fish',
  FISHING_VEST = 'fishing-vest',
  FOLDERS = 'folders',
  FOOTBALL = 'football',
  FRENCH_FRIES = 'french-fries',
  FUNNY_DISGUISE = 'funny-disguise',
  GIFT = 'gift',
  GLOBALISATION = 'globalisation',
  GLOBE = 'globe',
  GOAL = 'goal',
  GOLD_CART = 'gold-cart',
  GOLD_MINE = 'gold-mine',
  GOLF_BALL = 'golf-ball',
  GOOGLE_WINDMILL = 'google-windmill',
  GORILLA = 'gorilla',
  GREEN_MAP = 'green-map',
  GROUP_CHAT = 'group-chat',
  GROUP_VIDEO_CALL = 'group-video-call',
  HACKER = 'hacker',
  HAT = 'hat',
  HELICOPTER = 'helicopter',
  HELPING_HAND = 'helping-hand',
  HIGH_SPEED_TRAIN = 'high-speed-train',
  HORSE_SHOW = 'horse-show',
  HOSPITAL = 'hospital',
  HOT_AIR_BALLOON = 'hot-air-balloon',
  HOT_CHOCOLATE = 'hot-chocolate',
  HOUSE = 'house',
  KANGAROO = 'kangaroo',
  KEY = 'key',
  LIBRARY = 'library',
  LIGHTNING_BOLT = 'lightning-bolt',
  LIGHTNING = 'lightning',
  LIKE = 'like',
  LION = 'lion',
  LOBSTER = 'lobster',
  LOCATION = 'location',
  MALE_FACE_MASK = 'male-face-mask',
  MARACAS = 'maracas',
  MERRY_GO_ROUND = 'merry-go-round',
  MEXICAN_HAT = 'mexican-hat',
  MICROPHONE = 'microphone',
  MINING = 'mining',
  MOLECULE = 'molecule',
  MONEY_BAG = 'money-bag',
  MOTORBIKE = 'motorbike',
  MOVIE = 'movie',
  NACHOS = 'nachos',
  NOTES = 'notes',
  NUCLEAR_BUTTON = 'nuclear-button',
  OCTOPUS = 'octopus',
  ONLINE_EDUCATION = 'online-education',
  ONLINE_PRESENTATION = 'online-presentation',
  PANDA_BEAR = 'panda-bear',
  PANTONE = 'pantone',
  PARKING = 'parking',
  PARROT = 'parrot',
  PASSWORD = 'password',
  PAYPHONE = 'payphone',
  PENCIL = 'pencil',
  PERCENTAGE = 'percentage',
  PICTURE = 'picture',
  PIGEON = 'pigeon',
  PINATA = 'pinata',
  PIZZA = 'pizza',
  PLAN = 'plan',
  POLAR_BEAR = 'polar-bear',
  POLAROID = 'polaroid',
  POLICE = 'police',
  NIGHT_TIME_CLOUDS = 'night-time-clouds',
  NIGHT_TIME = 'night-time',
  PRESENTATION = 'presentation',
  PRIORITY = 'priority',
  PROBLEM_SOLVE = 'problem-solve',
  PROTECTION = 'protection',
  PULLOVER = 'pullover',
  QA = 'qa',
  RABBIT = 'rabbit',
  RADAR = 'radar',
  RAINBOW = 'rainbow',
  RANKING = 'ranking',
  REPORT = 'report',
  RGB = 'rgb',
  ROAD_SIGN = 'road-sign',
  ROAD = 'road',
  ROCKET = 'rocket',
  ROLLER_COASTER = 'roller-coaster',
  RUBBER_BANDS = 'rubber-bands',
  SAFETY = 'safety',
  SCHOOL = 'school',
  SCRIPT = 'script',
  SEARCH = 'search',
  SECURITY_1 = 'security-1',
  SECURITY = 'security',
  SEESAW = 'seesaw',
  SHELL = 'shell',
  SHIP_WHEEL = 'ship-wheel',
  SHOPPING_BAG_COLOURS = 'shopping-bag-colours',
  SMARTPHONE = 'smartphone',
  SNORKELING = 'snorkeling',
  SOCCER_BALL = 'soccer-ball',
  SPORTS_SHIRT = 'sports-shirt',
  SPROUT = 'sprout',
  STADIUM = 'stadium',
  STARTUP = 'startup',
  STAY_HOME = 'stay-home',
  STOP_SIGN = 'stop-sign',
  STRATEGY = 'strategy',
  STREET_LIGHTS = 'street-lights',
  SUBMARINE = 'submarine',
  SUIT = 'suit',
  SUITCASE = 'suitcase',
  SUNFLOWER = 'sunflower',
  TARGET = 'target',
  TAXI = 'taxi',
  THERMOMETER = 'thermometer',
  THINKING = 'thinking',
  THUMBS_UP = 'thumbs-up',
  TOILET = 'toilet',
  TOXIC_BIN = 'toxic-bin',
  TRAFFIC_BARRIER = 'traffic-barrier',
  TRAFFIC_LIGHTS = 'traffic-lights',
  TRAIN = 'train',
  TRASH_CAN = 'trash-can',
  TREASURE_CHEST = 'treasure-chest',
  TREASURE_MAP = 'treasure-map',
  TROPHY = 'trophy',
  UPLOAD = 'upload',
  URBAN_HOUSE = 'urban-house',
  USABILITY = 'usability',
  WEBSITE_BUG = 'website-bug',
  WEDDING_SUIT = 'wedding-suit',
  WHAT_TAIL_1 = 'whale-tail-1',
  WHALE = 'whale',
  WINDMILL = 'windmill',
  WINDY = 'windy',
  WORKING_AT_HOME_STORM = 'working-at-home-storm',
  WORKING_AT_HOME = 'working-at-home',
  WORKSPACE = 'workspace',
  WORLDWIDE = 'worldwide',
  ZEBRA = 'zebra',
  // MS
  MSAndroid = 'ms-android',
  MSApple = 'ms-apple',
  MSEmail = 'ms-email',
  MSFacebook = 'ms-facebook',
  MSGoogle = 'ms-google',
  MSLine = 'ms-line',
  MSMessaging = 'ms-messaging',
  MSMessenger = 'ms-messenger',
  MSMMIQ = 'ms-mmiq',
  MSMobile = 'ms-mobile',
  MSMobileOrange = 'ms-mobile-orange',
  MSMoney = 'ms-money',
  MSRCS = 'ms-rcs',
  MSRefreshUsers = 'ms-refresh-users',
  MSSignal = 'ms-signal',
  MSSkype = 'ms-skype',
  MSSMS = 'ms-sms',
  MSTalk = 'ms-talk',
  MSTelegram = 'ms-telegram',
  MSTikTok = 'ms-tiktok',
  MSViber = 'ms-viber',
  MSWeb = 'ms-web',
  MSWeChat = 'ms-wechat',
  MSWhatsApp = 'ms-whatsapp',
}

const AvatarIconMap: Record<AvatarIcon, string> = {
  [AvatarIcon.ATOM]: AvatarIconAtom,
  [AvatarIcon.FIRST_PLACE]: AvatarIcon1stPlace,
  [AvatarIcon.FIVE_STAR]: AvatarIcon5Star,
  [AvatarIcon.ADVERTISING]: AvatarIconAdvertising,
  [AvatarIcon.ANALYTICS_GRAPH]: AvatarIconAnalyticsGraph,
  [AvatarIcon.HACKER]: AvatarIconHacker,
  [AvatarIcon.KEY]: AvatarIconKey,
  [AvatarIcon.MUSIC]: AvatarIconMusic,
  [AvatarIcon.MUSIC_HEADPHONES]: AvatarIconMusicHeadphones,
  [AvatarIcon.NEWSPAPER]: AvatarIconNewspaper,
  [AvatarIcon.AGENCY]: AvatarIconAgency,
  [AvatarIcon.AIRPLANE_TICKET]: AvatarIconAirplaneTicket,
  [AvatarIcon.POPCORN]: AvatarIconPopcorn,
  [AvatarIcon.PRESENT]: AvatarIconPresent,
  [AvatarIcon.RAINBOW]: AvatarIconRainbow,
  [AvatarIcon.ROBOT]: AvatarIconRobot,
  [AvatarIcon.SHOPPING_BAG]: AvatarIconShoppingBag,
  [AvatarIcon.SNORKEL]: AvatarIconSnorkel,
  [AvatarIcon.STAR]: AvatarIconStar,
  [AvatarIcon.VIDEO_GAME_CONTROLLER]: AvatarIconVideoGameController,
  [AvatarIcon.ALARM]: AvatarIconAlarm,
  [AvatarIcon.AMBULANCE]: AvatarIconAmbulance,
  [AvatarIcon.ANCHOR]: AvatarIconAnchor,
  [AvatarIcon.ANONYMOUS]: AvatarIconAnonymous,
  [AvatarIcon.APPLE]: AvatarIconApple,
  [AvatarIcon.APRON]: AvatarIconApron,
  [AvatarIcon.ASTROPHYSICS]: AvatarIconAstrophysics,
  [AvatarIcon.AWARD]: AvatarIconAward,
  [AvatarIcon.AZTEC]: AvatarIconAztec,
  [AvatarIcon.BALANCE_SCALES]: AvatarIconBalanceScales,
  [AvatarIcon.BALLOONS]: AvatarIconBalloons,
  [AvatarIcon.BAR_GRAPH]: AvatarIconBarGraph,
  [AvatarIcon.BARREL]: AvatarIconBarrel,
  [AvatarIcon.BENCH]: AvatarIconBench,
  [AvatarIcon.BICYCLE]: AvatarIconBicycle,
  [AvatarIcon.BLANK_MAP]: AvatarIconBlankMap,
  [AvatarIcon.BLUEPRINT]: AvatarIconBlueprint,
  [AvatarIcon.BONUS]: AvatarIconBonus,
  [AvatarIcon.BOOKS]: AvatarIconBooks,
  [AvatarIcon.BOOKSHELF]: AvatarIconBookshelf,
  [AvatarIcon.BOX]: AvatarIconBox,
  [AvatarIcon.BRUSH]: AvatarIconBrush,
  [AvatarIcon.BURGER]: AvatarIconBurger,
  [AvatarIcon.CALENDAR]: AvatarIconCalendar,
  [AvatarIcon.CAMERA]: AvatarIconCamera,
  [AvatarIcon.CANYON]: AvatarIconCanyon,
  [AvatarIcon.CAPTCHA]: AvatarIconCaptcha,
  [AvatarIcon.CAR]: AvatarIconCar,
  [AvatarIcon.CARGO_SHIP]: AvatarIconCargoShip,
  [AvatarIcon.CAROUSEL]: AvatarIconCarousel,
  [AvatarIcon.CCTV]: AvatarIconCCTV,
  [AvatarIcon.CERTIFICATE]: AvatarIconCertificate,
  [AvatarIcon.CHALK]: AvatarIconChalk,
  [AvatarIcon.CHALKBOARD]: AvatarIconChalkboard,
  [AvatarIcon.CHURCH]: AvatarIconChurch,
  [AvatarIcon.COIN]: AvatarIconCoin,
  [AvatarIcon.COLOUR_PENCILS]: AvatarIconColourPencils,
  [AvatarIcon.COLOUR_CLOUD]: AvatarIconColourCloud,
  [AvatarIcon.COLOUR_FILE]: AvatarIconColourFile,
  [AvatarIcon.COMPASS]: AvatarIconCompass,
  [AvatarIcon.CONE]: AvatarIconCone,
  [AvatarIcon.CONFETTI]: AvatarIconConfetti,
  [AvatarIcon.CORAL_REEF]: AvatarIconCoralReef,
  [AvatarIcon.COUGH]: AvatarIconCough,
  [AvatarIcon.COWBOY]: AvatarIconCowboy,
  [AvatarIcon.COWORKING]: AvatarIconCoworking,
  [AvatarIcon.CRAB]: AvatarIconCrab,
  [AvatarIcon.CRADLE]: AvatarIconCradle,
  [AvatarIcon.CRATE]: AvatarIconCrate,
  [AvatarIcon.CREDIT_CARD]: AvatarIconCreditCard,
  [AvatarIcon.CRUISE_SHIP]: AvatarIconCruiseShip,
  [AvatarIcon.CUPS]: AvatarIconCups,
  [AvatarIcon.DATABASE]: AvatarIconDatabase,
  [AvatarIcon.DIAMOND]: AvatarIconDiamond,
  [AvatarIcon.DIRECTION_SIGN]: AvatarIconDirection,
  [AvatarIcon.DIRECTION]: AvatarIconDirection,
  [AvatarIcon.DIVING_HELMET]: AvatarIconDivingHelmet,
  [AvatarIcon.DNA]: AvatarIconDna,
  [AvatarIcon.DOLLAR]: AvatarIconDollar,
  [AvatarIcon.DOOR_DELIVERY]: AvatarIconDoorDelivery,
  [AvatarIcon.DRAMA]: AvatarIconDrama,
  [AvatarIcon.DRESS]: AvatarIconDress,
  [AvatarIcon.DRIVER_LICENSE]: AvatarIconDriverLicense,
  [AvatarIcon.DRONE]: AvatarIconDrone,
  [AvatarIcon.EMAIL_RECEIVED]: AvatarIconEmailReceived,
  [AvatarIcon.EMAIL]: AvatarIconEmail,
  [AvatarIcon.EXPERIMENT]: AvatarIconExperiment,
  [AvatarIcon.FACE_MASK]: AvatarIconFaceMask,
  [AvatarIcon.FACE_SHIELD]: AvatarIconFaceShield,
  [AvatarIcon.FEEDBACK]: AvatarIconFeedback,
  [AvatarIcon.FILM_REEL]: AvatarIconFilmReel,
  [AvatarIcon.FIRE_SKETCH]: AvatarIconFireSketch,
  [AvatarIcon.FISH]: AvatarIconFish,
  [AvatarIcon.FISHING_VEST]: AvatarIconFishingVest,
  [AvatarIcon.FOOTBALL]: AvatarIconFootball,
  [AvatarIcon.FOLDERS]: AvatarIconFolders,
  [AvatarIcon.FRENCH_FRIES]: AvatarIconFrenchFries,
  [AvatarIcon.FUNNY_DISGUISE]: AvatarIconFunnyDisguise,
  [AvatarIcon.GIFT]: AvatarIconGift,
  [AvatarIcon.GLOBALISATION]: AvatarIconGlobalisation,
  [AvatarIcon.GLOBE]: AvatarIconGlobe,
  [AvatarIcon.GOAL]: AvatarIconGoal,
  [AvatarIcon.GOLD_CART]: AvatarIconGoldCart,
  [AvatarIcon.GOLD_MINE]: AvatarIconGoldMine,
  [AvatarIcon.GOLF_BALL]: AvatarIconGolfBall,
  [AvatarIcon.GOOGLE_WINDMILL]: AvatarIconGoogleWindmill,
  [AvatarIcon.GORILLA]: AvatarIconGorilla,
  [AvatarIcon.GREEN_MAP]: AvatarIconGreenMap,
  [AvatarIcon.GROUP_CHAT]: AvatarIconGroupChat,
  [AvatarIcon.GROUP_VIDEO_CALL]: AvatarIconGroupVideoCall,
  [AvatarIcon.HACKER]: AvatarIconHacker,
  [AvatarIcon.HAT]: AvatarIconHat,
  [AvatarIcon.HELICOPTER]: AvatarIconHelicopter,
  [AvatarIcon.HELPING_HAND]: AvatarIconHelpingHand,
  [AvatarIcon.HIGH_SPEED_TRAIN]: AvatarIconHighSpeedTrain,
  [AvatarIcon.HORSE_SHOW]: AvatarIconHorseShow,
  [AvatarIcon.HOSPITAL]: AvatarIconHospital,
  [AvatarIcon.HOT_AIR_BALLOON]: AvatarIconHotAirBalloon,
  [AvatarIcon.HOT_CHOCOLATE]: AvatarIconHotChocolate,
  [AvatarIcon.HOUSE]: AvatarIconHouse,
  [AvatarIcon.KANGAROO]: AvatarIconKangaroo,
  [AvatarIcon.KEY]: AvatarIconKey,
  [AvatarIcon.LIBRARY]: AvatarIconLibrary,
  [AvatarIcon.LIGHTNING_BOLT]: AvatarIconLightningBolt,
  [AvatarIcon.LIGHTNING]: AvatarIconLightning,
  [AvatarIcon.LIKE]: AvatarIconLike,
  [AvatarIcon.LION]: AvatarIconLion,
  [AvatarIcon.LOBSTER]: AvatarIconLobster,
  [AvatarIcon.LOCATION]: AvatarIconLocation,
  [AvatarIcon.MALE_FACE_MASK]: AvatarIconMaleFaceMask,
  [AvatarIcon.MARACAS]: AvatarIconMaracas,
  [AvatarIcon.MERRY_GO_ROUND]: AvatarIconMerryGoRound,
  [AvatarIcon.MEXICAN_HAT]: AvatarIconMexicanHat,
  [AvatarIcon.MICROPHONE]: AvatarIconMicrophone,
  [AvatarIcon.MINING]: AvatarIconMining,
  [AvatarIcon.MOLECULE]: AvatarIconMolecule,
  [AvatarIcon.MONEY_BAG]: AvatarIconMoneyBag,
  [AvatarIcon.MOTORBIKE]: AvatarIconMotorbike,
  [AvatarIcon.MOVIE]: AvatarIconMovie,
  [AvatarIcon.NACHOS]: AvatarIconNachos,
  [AvatarIcon.NIGHT_TIME_CLOUDS]: AvatarIconNightTimeClouds,
  [AvatarIcon.NIGHT_TIME]: AvatarIconNightTime,
  [AvatarIcon.NOTES]: AvatarIconNotes,
  [AvatarIcon.NUCLEAR_BUTTON]: AvatarIconNuclearButton,
  [AvatarIcon.OCTOPUS]: AvatarIconOctopus,
  [AvatarIcon.ONLINE_EDUCATION]: AvatarIconOnlineEducation,
  [AvatarIcon.ONLINE_PRESENTATION]: AvatarIconOnlinePresentation,
  [AvatarIcon.PANDA_BEAR]: AvatarIconPandaBear,
  [AvatarIcon.PANTONE]: AvatarIconPantone,
  [AvatarIcon.PARKING]: AvatarIconParking,
  [AvatarIcon.PARROT]: AvatarIconParrot,
  [AvatarIcon.PASSWORD]: AvatarIconPassword,
  [AvatarIcon.PAYPHONE]: AvatarIconPayphone,
  [AvatarIcon.PENCIL]: AvatarIconPencil,
  [AvatarIcon.PERCENTAGE]: AvatarIconPercentage,
  [AvatarIcon.PICTURE]: AvatarIconPicture,
  [AvatarIcon.PIGEON]: AvatarIconPigeon,
  [AvatarIcon.PINATA]: AvatarIconPinata,
  [AvatarIcon.PIZZA]: AvatarIconPizza,
  [AvatarIcon.PLAN]: AvatarIconPlan,
  [AvatarIcon.POLAR_BEAR]: AvatarIconPolarBear,
  [AvatarIcon.POLAROID]: AvatarIconPolaroid,
  [AvatarIcon.POLICE]: AvatarIconPolice,
  [AvatarIcon.PRESENTATION]: AvatarIconPresentation,
  [AvatarIcon.PRIORITY]: AvatarIconPriority,
  [AvatarIcon.PROBLEM_SOLVE]: AvatarIconProblemSolve,
  [AvatarIcon.PROTECTION]: AvatarIconProtection,
  [AvatarIcon.PULLOVER]: AvatarIconPullover,
  [AvatarIcon.QA]: AvatarIconQa,
  [AvatarIcon.RABBIT]: AvatarIconRabbit,
  [AvatarIcon.RADAR]: AvatarIconRadar,
  [AvatarIcon.RANKING]: AvatarIconRanking,
  [AvatarIcon.REPORT]: AvatarIconReport,
  [AvatarIcon.RGB]: AvatarIconRgb,
  [AvatarIcon.ROAD_SIGN]: AvatarIconRoadSign,
  [AvatarIcon.ROAD]: AvatarIconRoad,
  [AvatarIcon.ROCKET]: AvatarIconRocket,
  [AvatarIcon.ROLLER_COASTER]: AvatarIconRollerCoaster,
  [AvatarIcon.RUBBER_BANDS]: AvatarIconRubberBands,
  [AvatarIcon.SAFETY]: AvatarIconSafety,
  [AvatarIcon.SCHOOL]: AvatarIconSchool,
  [AvatarIcon.SCRIPT]: AvatarIconScript,
  [AvatarIcon.SEARCH]: AvatarIconSearch,
  [AvatarIcon.SECURITY_1]: AvatarIconSecurity1,
  [AvatarIcon.SECURITY]: AvatarIconSecurity,
  [AvatarIcon.SEESAW]: AvatarIconSeesaw,
  [AvatarIcon.SHELL]: AvatarIconShell,
  [AvatarIcon.SHIP_WHEEL]: AvatarIconShipWheel,
  [AvatarIcon.SHOPPING_BAG_COLOURS]: AvatarIconShoppingBagColours,
  [AvatarIcon.SMARTPHONE]: AvatarIconSmartphone,
  [AvatarIcon.SNORKELING]: AvatarIconSnorkeling,
  [AvatarIcon.SOCCER_BALL]: AvatarIconSoccerBall,
  [AvatarIcon.SPORTS_SHIRT]: AvatarIconSportsShirt,
  [AvatarIcon.SPROUT]: AvatarIconSprout,
  [AvatarIcon.STADIUM]: AvatarIconStadium,
  [AvatarIcon.STARTUP]: AvatarIconStartup,
  [AvatarIcon.STAY_HOME]: AvatarIconStayHome,
  [AvatarIcon.STOP_SIGN]: AvatarIconStopSign,
  [AvatarIcon.STRATEGY]: AvatarIconStrategy,
  [AvatarIcon.STREET_LIGHTS]: AvatarIconStreetLights,
  [AvatarIcon.SUBMARINE]: AvatarIconSubmarine,
  [AvatarIcon.SUIT]: AvatarIconSuit,
  [AvatarIcon.SUITCASE]: AvatarIconSuitcase,
  [AvatarIcon.SUNFLOWER]: AvatarIconSunflower,
  [AvatarIcon.TARGET]: AvatarIconTarget,
  [AvatarIcon.TAXI]: AvatarIconTaxi,
  [AvatarIcon.THERMOMETER]: AvatarIconThermometer,
  [AvatarIcon.THINKING]: AvatarIconThinking,
  [AvatarIcon.THUMBS_UP]: AvatarIconThumbsUp,
  [AvatarIcon.TOILET]: AvatarIconToilet,
  [AvatarIcon.TOXIC_BIN]: AvatarIconToxicBin,
  [AvatarIcon.TRAFFIC_BARRIER]: AvatarIconTrafficBarrier,
  [AvatarIcon.TRAFFIC_LIGHTS]: AvatarIconTrafficLights,
  [AvatarIcon.TRAIN]: AvatarIconTrain,
  [AvatarIcon.TRASH_CAN]: AvatarIconTrashCan,
  [AvatarIcon.TREASURE_CHEST]: AvatarIconTreasureChest,
  [AvatarIcon.TREASURE_MAP]: AvatarIconTreasureMap,
  [AvatarIcon.TROPHY]: AvatarIconTrophy,
  [AvatarIcon.UPLOAD]: AvatarIconUpload,
  [AvatarIcon.URBAN_HOUSE]: AvatarIconUrbanHouse,
  [AvatarIcon.USABILITY]: AvatarIconUsability,
  [AvatarIcon.WEBSITE_BUG]: AvatarIconWebsiteBug,
  [AvatarIcon.WEDDING_SUIT]: AvatarIconWeddingSuit,
  [AvatarIcon.WHAT_TAIL_1]: AvatarIconWhaleTail1,
  [AvatarIcon.WHALE]: AvatarIconWhale,
  [AvatarIcon.WINDMILL]: AvatarIconWindmill,
  [AvatarIcon.WINDY]: AvatarIconWindy,
  [AvatarIcon.WORKING_AT_HOME_STORM]: AvatarIconWorkingAtHomeStorm,
  [AvatarIcon.WORKING_AT_HOME]: AvatarIconWorkingAtHome,
  [AvatarIcon.WORKSPACE]: AvatarIconWorkspace,
  [AvatarIcon.WORLDWIDE]: AvatarIconWorldwide,
  [AvatarIcon.ZEBRA]: AvatarIconZebra,
  // MS
  [AvatarIcon.MSAndroid]: AvatarIconMSAndroid,
  [AvatarIcon.MSApple]: AvatarIconMSApple,
  [AvatarIcon.MSEmail]: AvatarIconMSEmail,
  [AvatarIcon.MSFacebook]: AvatarIconMSFacebook,
  [AvatarIcon.MSGoogle]: AvatarIconMSGoogle,
  [AvatarIcon.MSLine]: AvatarIconMSLine,
  [AvatarIcon.MSMessaging]: AvatarIconMSMessaging,
  [AvatarIcon.MSMessenger]: AvatarIconMSMessenger,
  [AvatarIcon.MSMMIQ]: AvatarIconMSMMIQ,
  [AvatarIcon.MSMobile]: AvatarIconMSMobile,
  [AvatarIcon.MSMobileOrange]: AvatarIconMSMobileOrange,
  [AvatarIcon.MSMoney]: AvatarIconMSMoney,
  [AvatarIcon.MSRCS]: AvatarIconMSRCS,
  [AvatarIcon.MSRefreshUsers]: AvatarIconMSRefreshUsers,
  [AvatarIcon.MSSignal]: AvatarIconMSSignal,
  [AvatarIcon.MSSkype]: AvatarIconMSSkype,
  [AvatarIcon.MSSMS]: AvatarIconMSSMS,
  [AvatarIcon.MSTalk]: AvatarIconMSTalk,
  [AvatarIcon.MSTelegram]: AvatarIconMSTelegram,
  [AvatarIcon.MSTikTok]: AvatarIconMSTikTok,
  [AvatarIcon.MSViber]: AvatarIconMSViber,
  [AvatarIcon.MSWeb]: AvatarIconMSWeb,
  [AvatarIcon.MSWeChat]: AvatarIconMSWeChat,
  [AvatarIcon.MSWhatsApp]: AvatarIconMSWhatsApp,
};

export default AvatarIconMap;
