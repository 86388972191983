import { Enums } from '@configur-tech/upit-core-types';
import { StageStatus } from '@configur-tech/upit-core-types/lib/enums';
import { ProjectItem } from '../../services/project/ProjectService';

const InitialProject: ProjectItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    avatar: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    datasetMetaId: [],
    dataModel: [],

    stages: {
      [Enums.ProjectStage.CREATION]: {
        status: StageStatus.NOT_STARTED,
      },
      [Enums.ProjectStage.DATASETS]: {
        status: StageStatus.NOT_STARTED,
      },
      [Enums.ProjectStage.MODELLING]: {
        status: StageStatus.NOT_STARTED,
      },
      [Enums.ProjectStage.CONNECTION]: {
        status: StageStatus.NOT_STARTED,
      },
      [Enums.ProjectStage.AGGREGATION]: {
        status: StageStatus.NOT_STARTED,
      },
      [Enums.ProjectStage.CONFIGURATION]: {
        status: StageStatus.NOT_STARTED,
      },
    },
  },
};

export default InitialProject;
