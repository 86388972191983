import {
  faArrowLeft,
  faCamera,
  faChevronDown,
  faChevronUp,
  faCog,
  faLock,
  faPlusSquare,
  faRoute,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useIntegrationTemplate from '../../hooks/integration-template/UseIntegrationTemplate';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import {
  updateActiveIntegrationStage,
  updateActiveIntegrationSubStage,
} from '../../store/integration-stage';
import {
  IntegrationConfigurationSubStage,
  IntegrationCreationSubStage,
  IntegrationStage,
} from '../../store/integration-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [IntegrationCreationSubStage.META]: faPlusSquare,
  [IntegrationCreationSubStage.AVATAR]: faCamera,
  [IntegrationCreationSubStage.ENDPOINTS]: faRoute,
  [IntegrationCreationSubStage.ACCESS]: faLock,
};

const IntegrationItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { integrationTemplate } = useIntegrationTemplate();

  const integrationStage = useSelector(
    (state: RootState) => state.integrationStage,
  );

  const [configurationStatus, setConfigurationStatus] =
    useState<boolean>(false);

  const activeStage = integrationStage.activeStage;
  const activeSubStage = integrationStage.stages[activeStage].activeSubStage;

  const isDisabled = (stage: IntegrationCreationSubStage) => {
    switch (stage) {
      case IntegrationCreationSubStage.AVATAR:
        return !integrationTemplate?.name.length;
      case IntegrationCreationSubStage.ENDPOINTS:
        return !integrationTemplate?._id;
      case IntegrationCreationSubStage.ACCESS:
        return !integrationTemplate?._id;
      default:
        return false;
    }
  };

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`form-stage-back`}
        onClick={() => history.push(RouteName.INTEGRATION_CENTRE)}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Back To Integrations'} />
      </BackNavigationItem>

      {Object.values(IntegrationCreationSubStage).map((stage) => {
        return (
          <NavItem
            key={`integration-create-${stage}`}
            onClick={() => {
              dispatch(updateActiveIntegrationStage(IntegrationStage.CREATION));
              dispatch(updateActiveIntegrationSubStage(stage));
            }}
            isActive={stage === activeSubStage}
            disabled={isDisabled(stage)}
          >
            <FontAwesomeIcon
              icon={NavIcons[stage]}
              color={defaultTheme.colors.system.white}
            />
            <NavTitle text={stage} />
          </NavItem>
        );
      })}

      {/* Configuration accordion nav */}
      {integrationTemplate?._id && (
        <NavigationAccordion>
          <div key={`configuration`}>
            <NavigationAccordionTitle
              active={configurationStatus}
              index={0}
              onClick={() => setConfigurationStatus(!configurationStatus)}
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle text={'Configuration'} />

                <FontAwesomeIcon
                  icon={configurationStatus ? faChevronUp : faChevronDown}
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent active={configurationStatus}>
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActiveIntegrationStage(
                        IntegrationStage.CONFIGURATION,
                      ),
                    );
                    dispatch(
                      updateActiveIntegrationSubStage(
                        IntegrationConfigurationSubStage.ADVANCED,
                      ),
                    );
                  }}
                  isActive={
                    integrationStage.activeStage ===
                      IntegrationStage.CONFIGURATION &&
                    activeSubStage == IntegrationConfigurationSubStage.ADVANCED
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={IntegrationConfigurationSubStage.ADVANCED}
                    isActive={
                      integrationStage.activeStage ==
                        IntegrationStage.CONFIGURATION &&
                      activeSubStage ==
                        IntegrationConfigurationSubStage.ADVANCED
                    }
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default IntegrationItemNavigation;
