import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SearchQuery } from '../../interfaces/Search';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface IntegrationConfigItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.IntegrationConfig;
}

export interface IntegrationConfigOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.IntegrationConfigOutput;
}

export interface IntegrationConfigsOutput {
  data: IntegrationConfigOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const endpoint = process.env['REACT_APP_CRUD_INTEGRATION_CONFIG'] || '';

export default class IntegrationConfigService {
  public static async getIntegrationConfigs(
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum = 1,
    limit = 100,
    orderBy?: Record<string, number>,
  ): Promise<
    | Interfaces.SuccessResponseBody<IntegrationConfigsOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<IntegrationConfigsOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { ...query, searchQuery, pageNum, limit, orderBy });
  }

  public static async getIntegrationConfig(
    token: string,
    id: string,
  ): Promise<IntegrationConfigOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<IntegrationConfigOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${id}`, { singleDocument: true });

    return result?.data as IntegrationConfigOutput;
  }

  public static async postIntegrationConfig(
    token: string,
    integrationConfig: Interfaces.IntegrationConfig,
    userId: string,
  ): Promise<IntegrationConfigOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<IntegrationConfigOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: integrationConfig, userId });

    return result?.data as IntegrationConfigOutput;
  }

  public static async putIntegrationConfig(
    token: string,
    integrationConfig: Interfaces.IntegrationConfigOutput,
    userId: string,
  ): Promise<IntegrationConfigOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<IntegrationConfigOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${integrationConfig._id}`, {
      data: integrationConfig,
      userId,
    });

    return result?.data as IntegrationConfigOutput;
  }

  public static async deleteIntegrationConfig(
    token: string,
    integrationConfigId: string,
    userId: string,
  ): Promise<IntegrationConfigOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<IntegrationConfigOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${integrationConfigId}`, userId);

    return result?.data as IntegrationConfigOutput;
  }
}
