import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, get, set } from 'lodash';
import React, { FC } from 'react';
import Toggle from 'react-toggle';
import { DropdownItemProps } from 'semantic-ui-react';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import DateTimePicker from '../../../DateTimePicker/DateTimePicker';
import * as SC from '../styled';

interface AdditionalValidationOptionsProps {
  schemaField: Interfaces.Field;
  defaultValueOptions?: DropdownItemProps[];
  onChange: (updatedSchemaField: Interfaces.Field) => void;
  superColumnValueType?: Enums.ValueDataType;
}

const DATA_VALIDATION_CONSTRAINTS = 'dataValidation.constraints';
const DEFAULT_VALUE_FIELD = 'defaultValue';
const REQUIRED_FIELD = 'isRequired';
const UNIQUE_FIELD = 'isUnique';
const INCREMENT_FIELD = 'isAutoInc';
const LIST_VALUES_FIELD = 'listValues';

const AdditionalValidationOptions: FC<AdditionalValidationOptionsProps> = ({
  schemaField,
  defaultValueOptions,
  onChange,
  superColumnValueType,
}) => {
  const fieldType =
    superColumnValueType || schemaField.dataValidation?.dataValidationType;

  const dateFormat =
    (schemaField.dataValidation?.dateConversion as Interfaces.DateExtractionOp)
      ?.params.format ||
    schemaField.dataValidation?.constraints?.format ||
    schemaField.dataValidation?.dateFormat;

  const handleConstraintChange = (field: string, value: unknown) => {
    if (field && schemaField) {
      const cloned = cloneDeep(schemaField);

      if (!cloned.dataValidation) {
        cloned.dataValidation = {};
      }

      if (Array.isArray(value)) {
        const formattedValues = value.map((item) => {
          return { value: item };
        });

        if (!formattedValues.length) {
          delete cloned.dataValidation.constraints[field];
        } else {
          set(
            cloned,
            `${DATA_VALIDATION_CONSTRAINTS}.${field}`,
            formattedValues,
          );
        }
      } else {
        if (!value) {
          delete cloned.dataValidation.constraints[field];
        } else {
          const parsed =
            fieldType === Enums.ValueDataType.NUMBER
              ? +(value as string)
              : value;
          set(cloned, `${DATA_VALIDATION_CONSTRAINTS}.${field}`, parsed);
        }
      }

      onChange(cloned);
    }
  };

  return (
    <>
      <SC.OptionalWrapper>
        <StyledSubHeader>Additional Requirements</StyledSubHeader>

        <SC.OptionalFieldWrapper>
          <SC.OptionalField>
            <StyledBodySubHeader>Required Field</StyledBodySubHeader>
            <StyledText>
              Required fields will cause validation to fail if entries are
              missing data.
            </StyledText>

            <Toggle
              checked={get(
                schemaField,
                `${DATA_VALIDATION_CONSTRAINTS}.${REQUIRED_FIELD}`,
              )}
              icons={false}
              onChange={(event) =>
                handleConstraintChange(REQUIRED_FIELD, event.target.checked)
              }
            />
          </SC.OptionalField>

          <SC.OptionalField>
            <StyledBodySubHeader>Default Value</StyledBodySubHeader>
            <StyledText>
              A default value can be used for any missing entries.
            </StyledText>

            {!get(
              schemaField,
              `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
            ) &&
              fieldType === Enums.ValueDataType.DATE && (
                <DateTimePicker
                  value={
                    get(
                      schemaField,
                      `${DATA_VALIDATION_CONSTRAINTS}.${DEFAULT_VALUE_FIELD}`,
                    ) || ''
                  }
                  onChange={(date) =>
                    handleConstraintChange(
                      DEFAULT_VALUE_FIELD,
                      date ? date : undefined,
                    )
                  }
                  dateFormat={dateFormat}
                />
              )}

            {!get(
              schemaField,
              `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
            ) &&
              fieldType !== Enums.ValueDataType.DATE && (
                <StyledInput
                  type={
                    fieldType === Enums.ValueDataType.NUMBER
                      ? 'number'
                      : 'string'
                  }
                  placeholder={'Default value'}
                  value={
                    get(
                      schemaField,
                      `${DATA_VALIDATION_CONSTRAINTS}.${DEFAULT_VALUE_FIELD}`,
                    ) ?? ''
                  }
                  onChange={(event, data) =>
                    handleConstraintChange(
                      DEFAULT_VALUE_FIELD,
                      data.value.length ? data.value : undefined,
                    )
                  }
                />
              )}

            {get(
              schemaField,
              `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
            ) && (
              <StyledDropdown
                selectOnBlur={false}
                style={{ minWidth: 'auto' }}
                placeholder={'Select a default value'}
                search
                selection
                clearable
                options={defaultValueOptions || []}
                value={
                  get(
                    schemaField,
                    `${DATA_VALIDATION_CONSTRAINTS}.${DEFAULT_VALUE_FIELD}`,
                  ) || ''
                }
                onChange={(event, data) => {
                  const val = data.value?.toString();
                  handleConstraintChange(
                    DEFAULT_VALUE_FIELD,
                    val?.length ? val : undefined,
                  );
                }}
              />
            )}
          </SC.OptionalField>

          <SC.OptionalField>
            <StyledBodySubHeader>Unique Field</StyledBodySubHeader>
            <StyledText>
              Unique fields will cause validation to fail if any entries are
              duplicated.
            </StyledText>

            <Toggle
              checked={get(
                schemaField,
                `${DATA_VALIDATION_CONSTRAINTS}.${UNIQUE_FIELD}`,
              )}
              icons={false}
              onChange={(event) =>
                handleConstraintChange(UNIQUE_FIELD, event.target.checked)
              }
              disabled={get(
                schemaField,
                `${DATA_VALIDATION_CONSTRAINTS}.${INCREMENT_FIELD}`,
              )}
            />
          </SC.OptionalField>
        </SC.OptionalFieldWrapper>
      </SC.OptionalWrapper>
    </>
  );
};

export default AdditionalValidationOptions;
