import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SUPER_COLUMN_TYPES } from '../../components/DataSample/DataSample';

const clearSuperColumnValues = (
  entries: Record<string, unknown>[],
  schema: Interfaces.FieldOutput[],
) => {
  const superColumnNames = schema
    .filter((s) =>
      SUPER_COLUMN_TYPES.includes(
        s.dataValidation?.dataValidationType || Enums.ValueDataType.TEXT,
      ),
    )
    .map((s) => s.name);

  return entries.map((_row, i) =>
    Object.fromEntries(
      Object.entries(entries[i]).filter(
        ([key]) => !superColumnNames.includes(key),
      ),
    ),
  );
};

export default clearSuperColumnValues;
