import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DiscoveryDatasetStage from '../../components/DatasetStages/DatasetValidationStages/1-discovery/DiscoveryDatasetStage';
import DiscoveryConfirmationDatasetStage from '../../components/DatasetStages/DatasetValidationStages/2-confirmation/DiscoveryConfirmationDatasetStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import { updateActiveDatasetSubStage } from '../../store/dataset-stage';
import { DatasetStructureSubStage } from '../../store/dataset-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getStageTitle,
  getSubStageContent,
} from '../../util/stage-content/StageHeaderContent';

const REPLACE_ACTION = 'replace';
const MERGE_ACTION = 'merge';

interface DatasetItemStructureProps {
  schema: Interfaces.Field[];
  setSchema: React.Dispatch<React.SetStateAction<Interfaces.Field[]>>;
  sampleData: SampleDataRow[];
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const DatasetItemStructure: FC<DatasetItemStructureProps> = ({
  schema,
  setSchema,
  sampleData,
  setSampleData,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );
  const isUsingTableBuilder =
    coll?.dataSource?.dataSourceType === Enums.DataSourceType.INTERNAL;
  const previouslyDiscovered = coll?.schemaData?.length;
  const isReplacing = location.pathname.includes(REPLACE_ACTION);
  const isMerging = location.pathname.includes(MERGE_ACTION);

  const activeStage = Enums.DatasetStage.STRUCTURE;
  const activeSubStage = datasetStageState.stages[activeStage].activeSubStage;

  const stageTitle = getStageTitle(activeStage);
  const subStageContent = getSubStageContent(
    activeStage,
    activeSubStage,
    datasetMeta,
    collection?.collectionId,
  );

  // If using TableBuilder or has already discovered schema, skip on
  useEffect(() => {
    if (
      !isReplacing &&
      !isMerging &&
      (isUsingTableBuilder || (!isUsingTableBuilder && previouslyDiscovered))
    ) {
      dispatch(
        updateActiveDatasetSubStage(DatasetStructureSubStage.CONFIRMATION),
      );
    }
  });

  const getSubStageComponent = () => {
    const discoveryStage = (
      <DiscoveryDatasetStage setSampleData={setSampleData} />
    );

    const confirmationStage = (
      <DiscoveryConfirmationDatasetStage
        setSchema={setSchema}
        schema={schema}
        sampleData={sampleData}
        setSampleData={setSampleData}
      />
    );

    switch (activeSubStage) {
      case DatasetStructureSubStage.DISCOVERY:
        return (!activeDataCollectionItem && !isUsingTableBuilder) ||
          isReplacing ||
          isMerging
          ? discoveryStage
          : confirmationStage;
      case DatasetStructureSubStage.CONFIRMATION:
        return confirmationStage;
      default:
        return discoveryStage;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: datasetStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default DatasetItemStructure;
