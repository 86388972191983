import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { QueryOutputWithDetails } from '../../store/queries';

const formatEntries = (
  entries: Record<string, unknown>[],
  query?: QueryOutputWithDetails,
): Interfaces.SchemaField[] => {
  if (!entries?.length) {
    return [];
  }

  if (query?.queryParams.displayOrder?.length) {
    // Has user defined display order
    return query.queryParams.displayOrder
      ?.map((displayOrderItem) => {
        const entry = Object.entries(entries[0]).find(
          ([key]) => key === displayOrderItem.alias,
        );

        return {
          field: displayOrderItem.alias || displayOrderItem.field,
          alias: displayOrderItem.alias || displayOrderItem.field,
          type: [
            Services.validators.DataValidator.isNumber(entry?.[1])
              ? Enums.ValueDataType.NUMBER
              : Enums.ValueDataType.TEXT,
          ],
        };
      })
      .concat(
        // Add additional measures that haven't been ordered
        (query?.queryParams.measures || [])
          .filter(
            (measure) =>
              !query.queryParams.displayOrder?.find(
                (displayOrderItem) => displayOrderItem.alias === measure.alias,
              ),
          )
          .map((measure) => {
            return {
              field: measure.alias || measure.field,
              alias: measure.alias || measure.field,
              type: [Enums.ValueDataType.TEXT],
            };
          }),
      )
      .concat(
        // Add additional supercolumns that haven't been ordered
        (query?.queryParams.supercolumns || [])
          .filter(
            (supercolumn) =>
              !query.queryParams.displayOrder?.find(
                (displayOrderItem) =>
                  displayOrderItem.alias === supercolumn.alias,
              ),
          )
          .map((supercolumn) => {
            return {
              field: supercolumn.alias || supercolumn.field,
              alias: supercolumn.alias || supercolumn.field,
              type: [Enums.ValueDataType.TEXT],
            };
          }),
      );
  } else {
    // No order specified, output in order data has arrived
    return Object.keys(entries[0]).map((k) => {
      return {
        field: k,
        alias: k,
        type: [
          Services.validators.DataValidator.isNumber(k)
            ? Enums.ValueDataType.NUMBER
            : Enums.ValueDataType.TEXT,
        ],
      };
    });
  }
};

export default formatEntries;
