import { Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_API_KEY'] || '';

export default class APIKeyService {
  public static async getAPIKeys(
    token: string,
    projectId?: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput[]>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput[]>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, ...query });
  }

  public static async getAPIKey(
    token: string,
    projectId: string,
    id: string,
  ): Promise<Interfaces.ApiKeyOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, _id: id, singleDocument: true });

    return result?.data as Interfaces.ApiKeyOutput;
  }

  public static async postAPIKey(
    token: string,
    apiKey: Interfaces.ApiKey,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.ApiKeyOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, data: apiKey, userId });

    return result?.data as Interfaces.ApiKeyOutput;
  }

  public static async putAPIKey(
    token: string,
    apiKey: Interfaces.ApiKeyOutput,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.ApiKeyOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}${apiKey._id}`, { projectId, data: apiKey, userId });

    return result?.data as Interfaces.ApiKeyOutput;
  }

  public static async deleteAPIKey(
    token: string,
    apiKeyId: string,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.ApiKeyOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<Interfaces.ApiKeyOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${apiKeyId}`, userId, projectId);

    return result?.data as Interfaces.ApiKeyOutput;
  }
}
