import styled, { css } from 'styled-components';
import {
  StyledDropdown,
  StyledInput,
  StyledText,
  StyledTextArea,
} from '../../../main/theme';

interface InputProps {
  $isLiveEditing?: boolean;
}

const FOCUS_BORDER_WIDTH = '1px';
const INPUT_LINE_HEIGHT = '19.99px';

export const Wrapper = styled.div`
  display: flex;
  max-width: 100%;

  overflow-y: hidden;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 600px;
  height: 100%;

  overflow-y: auto;

  padding: ${({ theme }) => theme.padding.xxxlarge};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};

  > div {
    position: absolute;
    right: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const InputsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme }) => theme.margin.large};
`;

export const InputTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const InputHeader = styled(StyledText)`
  text-align: left;
  text-transform: uppercase;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.offBlack};
`;

export const InputHeaderRequired = styled(StyledText)`
  text-align: right;
  text-transform: uppercase;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.darkGrey};
`;

export const InputField = styled(StyledInput)<InputProps>`
  outline: none;
  width: ${({ $isLiveEditing }) => ($isLiveEditing ? '100%' : '400px')};
  ${({ $isLiveEditing }) => $isLiveEditing && `height: 100%;`};

  &.input-number[class*='right labeled'] {
    > input {
      border-right-color: ${({ theme }) => theme.colors.system.grey} !important;
      border-radius: ${({ theme, $isLiveEditing }) =>
        !$isLiveEditing && `${theme.borders.radius} !important`};
    }
  }

  &.input > input:not(:focus) {
    ${({ $isLiveEditing }) =>
      $isLiveEditing && `border: 0; padding-top:1px; padding-bottom: 1px;`};
  }

  &.input > input:focus {
    border-color: ${({ theme }) => theme.colors.general.blue};
    ${({ $isLiveEditing }) =>
      $isLiveEditing && `padding-top:0; padding-bottom: 0;`};
  }

  &.input.error > input:focus {
    border-color: ${({ theme }) => theme.colors.general.red};
  }

  &.input > input {
    ${({ $isLiveEditing }) =>
      $isLiveEditing &&
      `background: none; line-height: inherit; border-radius:0`};
  }
`;

export const EditableInput = styled.input`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;

  line-height: ${INPUT_LINE_HEIGHT};

  transition: background 0.3s;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => `${theme.colors.system.white}33`};
      }
    `}

  &:focus {
    cursor: text;
    background: ${({ theme }) => theme.colors.general.white};
    border: ${FOCUS_BORDER_WIDTH} solid
      ${({ theme }) => theme.colors.general.blue};
  }
`;

export const InputFieldArea = styled(StyledTextArea)`
  width: 400px;
  resize: vertical;
  min-height: 38px;
`;

export const InputDropdown = styled(StyledDropdown)`
  width: 400px;
`;

export const EditableDropdown = styled(StyledDropdown)<InputProps>`
  background: ${({ theme }) => theme.colors.general.white} !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  min-width: 100% !important;
  height: 100%;
  width: 100%;

  &.active.search.dropdown {
    border: 1px solid ${({ theme }) => theme.colors.general.blue} !important;
    margin: -1px 1px !important;
    left: -1px !important;
  }

  > div.visible {
    border-radius: 0 !important;
    border: 1px solid ${({ theme }) => theme.colors.general.blue} !important;
    margin: 0 -1px !important;
  }

  input.search,
  input.search::placeholder,
  .text {
    text-align: center;
    padding: ${({ theme }) => theme.padding.standard};
    line-height: ${INPUT_LINE_HEIGHT};
    width: 100%;
  }
`;

export const ActionButtonWrapper = styled.div<{ buttonMargin?: boolean }>`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme, buttonMargin }) =>
      buttonMargin ? theme.margin.large : 0};
  }
`;

export const LinkedWrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  overflow-y: auto;
`;

export const LinkedDataset = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};
`;

export const LinkedDatasetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    margin: 0;
  }
`;

export const NoResultsText = styled(StyledText)`
  text-align: center;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.margin.xlarge};
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${({ theme }) => theme.margin.large};
`;
