import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import APIService from '../../services/api/APIService';
import { fetchAPIs } from '../../store/api';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useApiResult {
  getApis: (
    projectId?: string,
    query?: Record<string, unknown>,
  ) => Promise<Interfaces.DatasetApiOutput[]>;
  addApi: (
    apiObj: Interfaces.DatasetApi,
    projectId?: string,
  ) => Promise<Interfaces.DatasetApiOutput>;
  editApi: (
    apiObj: Interfaces.DatasetApiOutput,
    projectId?: string,
  ) => Promise<Interfaces.DatasetApiOutput>;
  removeApi: (
    apiObj: Interfaces.DatasetApiOutput,
  ) => Promise<Interfaces.DatasetApiOutput>;
}

const useApi = (): useApiResult => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const getApis = useCallback(
    async (
      projectId?: string,
      query?: Record<string, unknown>,
    ): Promise<Interfaces.DatasetApiOutput[]> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token) {
        dispatch(fetchAPIs(token, query));
        result = await APIService.getAPIs(token, projectId, query);
      }

      return result;
    },
    [dispatch, getAccessTokenSilently],
  );

  const addApi = useCallback(
    async (
      apiObj: Interfaces.DatasetApi,
      projectId?: string,
    ): Promise<Interfaces.DatasetApiOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiObj && loggedInUser) {
        result = await APIService.postAPI(
          token,
          apiObj,
          loggedInUser._id,
          projectId,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const editApi = useCallback(
    async (
      apiObj: Interfaces.DatasetApiOutput,
      projectId?: string,
    ): Promise<Interfaces.DatasetApiOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiObj && loggedInUser) {
        result = await APIService.putAPI(
          token,
          apiObj,
          loggedInUser._id,
          projectId,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const removeApi = useCallback(
    async (
      apiObj: Interfaces.DatasetApiOutput,
    ): Promise<Interfaces.DatasetApiOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && apiObj && loggedInUser) {
        result = await APIService.deleteAPI(token, apiObj, loggedInUser._id);
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  return {
    getApis,
    addApi,
    editApi,
    removeApi,
  };
};

export default useApi;
