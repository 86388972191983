import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, set, startCase } from 'lodash';
import { FC, useContext, useState } from 'react';
import Toggle from 'react-toggle';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { dateTimeFormats } from '../../../DateTimePicker/DateTimePicker';
import { SuperColumnType } from '../../enhancement/EnhancementAddColumnModal';
import {
  OptionalField,
  OptionalFieldWrapper,
  OptionalWrapper,
} from '../styled';

interface FormattingOptionsProps {
  active: boolean;
  hideDisplayAsFormats?: boolean;
  activeToggle: () => void;
  schemaField: Interfaces.Field;
  onChange: (updatedSchemaField: Interfaces.Field) => void;
  existingFormatting?: Interfaces.SchemaFieldFormats;
  superColumnType?: SuperColumnType;
}

const DECIMAL_FIELD = 'decimalPlaces';
const NUMBER_FORMAT_FIELD = 'format';
const CURRENCY_FIELD = 'currency';
const PRETTY_NUMBER_FIELD = 'prettyNumber';
const DATE_LOCALISATION_FIELD = 'isLocalised';

const currencyFormatDropdownOptions = Object.entries(Enums.Currency).map(
  ([key, value], i) => {
    return {
      key: `currency-format-${key}-${i}`,
      text: startCase(key.toLowerCase()).replace('Us', 'US'),
      value: value,
      description: value,
    };
  },
);

const FormattingOptions: FC<FormattingOptionsProps> = ({
  active,
  activeToggle,
  hideDisplayAsFormats,
  schemaField,
  onChange,
  existingFormatting,
  superColumnType,
}) => {
  const themeContext = useContext(ThemeContext);

  const FORMAT_NUMBER_TYPES = [
    Enums.ValueDataType.NUMBER,
    Enums.ValueDataType.FORMULA,
    SuperColumnType.NUMERIC,
  ];

  const [formatting, setFormatting] = useState<Interfaces.SchemaFieldFormats>(
    existingFormatting || {},
  );

  const handleFormattingChange = (field: string, value: unknown) => {
    if (field && schemaField) {
      const cloned = cloneDeep(schemaField);

      set(cloned, `dataValidation.formatting.${field}`, value);
      setFormatting(cloned.dataValidation.formatting);

      if (field === CURRENCY_FIELD) {
        if (!(value as string)?.length) {
          delete cloned.dataValidation.formatting[NUMBER_FORMAT_FIELD];
          delete cloned.dataValidation.formatting[CURRENCY_FIELD];
        } else {
          set(
            cloned,
            `dataValidation.formatting.${NUMBER_FORMAT_FIELD}`,
            Enums.DataDisplayFormat.CURRENCY,
          );
        }
      } else if (
        (field === NUMBER_FORMAT_FIELD || field === PRETTY_NUMBER_FIELD) &&
        value === Enums.DataDisplayFormat.PERCENTAGE
      ) {
        delete cloned.dataValidation.formatting[CURRENCY_FIELD];
      }

      onChange(cloned);
    }
  };

  const FieldFixedDecimal = (
    <OptionalField>
      <StyledBodySubHeader>Fixed Decimal Places</StyledBodySubHeader>
      <StyledText>Display number to fixed length of decimal places.</StyledText>

      <StyledInput
        type={'number'}
        min={0}
        placeholder={'Decimal places'}
        value={
          typeof get(
            schemaField,
            `dataValidation.formatting.${DECIMAL_FIELD}`,
          ) !== 'undefined'
            ? get(schemaField, `dataValidation.formatting.${DECIMAL_FIELD}`)
            : '' ||
              (formatting as Interfaces.NumericalFormatting)?.decimalPlaces
        }
        onChange={(event, data) =>
          handleFormattingChange(
            DECIMAL_FIELD,
            data.value.length ? +data.value : undefined,
          )
        }
      />
    </OptionalField>
  );

  const FieldCurrency = (
    <OptionalField>
      <StyledBodySubHeader>Display As Currency</StyledBodySubHeader>
      <StyledText>Can be combined with 'Fixed Decimal Places'.</StyledText>

      <StyledDropdown
        selectOnBlur={false}
        disabled={
          get(
            schemaField,
            `dataValidation.formatting.${NUMBER_FORMAT_FIELD}`,
          ) === Enums.DataDisplayFormat.PERCENTAGE ||
          get(
            schemaField,
            `dataValidation.formatting.${PRETTY_NUMBER_FIELD}`,
          ) === true ||
          (formatting as Interfaces.CurrencyFormatting).prettyNumber === true ||
          (formatting as Interfaces.NumericalFormatting).format ===
            Enums.DataDisplayFormat.PERCENTAGE
        }
        placeholder={'Select a currency'}
        search
        clearable
        selection
        options={currencyFormatDropdownOptions}
        value={
          (formatting as Interfaces.CurrencyFormatting).currency ||
          get(schemaField, `dataValidation.formatting.${CURRENCY_FIELD}`)
        }
        onChange={(event, data) =>
          handleFormattingChange(CURRENCY_FIELD, data.value)
        }
      />
    </OptionalField>
  );

  const FieldPercentage = (
    <OptionalField>
      <StyledBodySubHeader>Display As Percentage</StyledBodySubHeader>
      <StyledText>Can be combined with 'Fixed Decimal Places'.</StyledText>

      <Toggle
        disabled={
          !!get(schemaField, `dataValidation.formatting.${CURRENCY_FIELD}`) ||
          (formatting as Interfaces.CurrencyFormatting).currency
        }
        checked={
          get(
            schemaField,
            `dataValidation.formatting.${NUMBER_FORMAT_FIELD}`,
          ) === Enums.DataDisplayFormat.PERCENTAGE ||
          (formatting as Interfaces.NumericalFormatting)?.format ===
            Enums.DataDisplayFormat.PERCENTAGE
        }
        icons={false}
        onChange={(event) =>
          handleFormattingChange(
            NUMBER_FORMAT_FIELD,
            event.target.checked
              ? Enums.DataDisplayFormat.PERCENTAGE
              : undefined,
          )
        }
      />
    </OptionalField>
  );

  const FieldPrettyNumbers = (
    <OptionalField>
      <StyledBodySubHeader>Pretty Numbers</StyledBodySubHeader>
      <StyledText>Display number formatted with commas</StyledText>

      <Toggle
        disabled={
          !!get(schemaField, `dataValidation.formatting.${CURRENCY_FIELD}`)
        }
        checked={
          get(
            schemaField,
            `dataValidation.formatting.${PRETTY_NUMBER_FIELD}`,
          ) ||
          (formatting as Interfaces.CurrencyFormatting).prettyNumber ||
          false
        }
        icons={false}
        onChange={(event) =>
          handleFormattingChange(PRETTY_NUMBER_FIELD, event.target.checked)
        }
      />
    </OptionalField>
  );

  const FieldDateLocalisation = (
    <OptionalField>
      <StyledBodySubHeader>Localise Timezone</StyledBodySubHeader>
      <StyledText>Localise datetime based on your browser location.</StyledText>

      <Toggle
        disabled={
          !dateTimeFormats.includes(
            get(schemaField, `dataValidation.constraints.format`),
          )
        }
        checked={get(
          schemaField,
          `dataValidation.formatting.${DATE_LOCALISATION_FIELD}`,
        )}
        icons={false}
        onChange={(event) =>
          handleFormattingChange(DATE_LOCALISATION_FIELD, event.target.checked)
        }
      />
    </OptionalField>
  );

  return (
    <>
      <StyledAccordionTitle
        active={active}
        index={0}
        onClick={activeToggle}
        style={{
          backgroundColor: themeContext.colors.system.offWhite,
        }}
      >
        <StyledSubHeader>Formatting Options</StyledSubHeader>
        <FontAwesomeIcon
          icon={active ? faChevronUp : faChevronDown}
          color={defaultTheme.colors.system.offBlack}
        />
      </StyledAccordionTitle>

      <StyledAccordionContent active={active}>
        {schemaField.dataValidation?.dataValidationType ===
          Enums.ValueDataType.DATE && (
          <OptionalWrapper>
            <StyledSubHeader>Date Formats</StyledSubHeader>

            <OptionalFieldWrapper>{FieldDateLocalisation}</OptionalFieldWrapper>
          </OptionalWrapper>
        )}

        {((schemaField.dataValidation?.dataValidationType &&
          FORMAT_NUMBER_TYPES.includes(
            schemaField.dataValidation?.dataValidationType,
          )) ||
          FORMAT_NUMBER_TYPES.includes(superColumnType as SuperColumnType)) && (
          <OptionalWrapper>
            <StyledSubHeader>Number Formats</StyledSubHeader>

            <OptionalFieldWrapper>
              {FieldFixedDecimal}

              {hideDisplayAsFormats ? FieldPrettyNumbers : FieldCurrency}
            </OptionalFieldWrapper>

            {!hideDisplayAsFormats && (
              <OptionalFieldWrapper
                style={{
                  marginTop: themeContext.margin.large,
                }}
              >
                {FieldPercentage}
                {FieldPrettyNumbers}
              </OptionalFieldWrapper>
            )}
          </OptionalWrapper>
        )}
      </StyledAccordionContent>
    </>
  );
};

export default FormattingOptions;
