import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrganisationItemOutput } from '../../services/organisation/OrganisationService';
import {
  fetchOrganisation,
  updateOrganisation,
} from '../../store/organisation';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useOrganisationResult {
  organisation?: Interfaces.OrganisationOutput;
  organisationAccessLevel?: Enums.AccessLevel;
  hasAccessToDashboards?: boolean;
  hasAccessToHouston?: boolean;

  getOrganisation: (organisationId: string) => void;
  editOrganisation: (
    organisationObj: Partial<Interfaces.OrganisationOutput>,
  ) => void;
}

const useOrganisation = (): useOrganisationResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [organisation, setOrganisation] =
    useState<Interfaces.OrganisationOutput>();
  const [organisationAccessLevel, setOrganisationAccessLevel] =
    useState<Enums.AccessLevel>();
  const [hasAccessToDashboards, setHasAccessToDashboards] =
    useState<boolean>(false);
  const [hasAccessToHouston, setHasAccessToHouston] = useState<boolean>(false);

  const organisationState = useSelector(
    (state: RootState) => state.organisation,
  );
  const organisationObj = organisationState.data as OrganisationItemOutput;

  const getOrganisation = useCallback(
    async (organisationId: string) => {
      const token = await getAccessTokenSilently();

      if (token && organisationId && loggedInUser) {
        await dispatch(fetchOrganisation(token, organisationId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editOrganisation = useCallback(
    async (organisationObj: Partial<Interfaces.OrganisationOutput>) => {
      const token = await getAccessTokenSilently();

      if (token && organisationObj && loggedInUser) {
        await dispatch(
          updateOrganisation(token, organisationObj, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (organisationObj) {
      // Complete model
      setOrganisation(organisationObj.entity);
      setOrganisationAccessLevel(organisationObj.accessLevel);
      setHasAccessToDashboards(
        !!organisationObj.entity.subscriptionDetails.features?.dashboards,
      );
      setHasAccessToHouston(
        !!organisationObj.entity.subscriptionDetails.features?.houston,
      );
    }
  }, [organisationObj]);

  return {
    organisation,
    organisationAccessLevel,
    hasAccessToDashboards: hasAccessToDashboards,
    hasAccessToHouston: hasAccessToHouston,
    getOrganisation,
    editOrganisation,
  };
};

export default useOrganisation;
