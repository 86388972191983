import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormService from '../../services/form/FormService';
import { fetchForm } from '../../store/project-form';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useProject from '../project/UseProject';

interface useFormResult {
  form?: Interfaces.FormOutput;
  getForms: (
    query?: Record<string, unknown>,
  ) => Promise<Interfaces.FormOutput[]>;
  getForm: (formId: string) => void;
  addForm: (formObj: Interfaces.Form) => Promise<Interfaces.FormOutput>;
  editForm: (formObj: Interfaces.FormOutput) => Promise<Interfaces.FormOutput>;
  removeForm: (formId: string) => Promise<Interfaces.FormOutput>;
}

const useForm = (): useFormResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { project } = useProject();
  const [form, setForm] = useState<Interfaces.FormOutput>();

  const formState = useSelector((state: RootState) => state.form);
  const formObj = formState.data as Interfaces.FormOutput;

  const getForms = useCallback(
    async (
      query?: Record<string, unknown>,
    ): Promise<Interfaces.FormOutput[]> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && query) {
        result = await FormService.getForms(token, query);
      }

      return result;
    },
    [getAccessTokenSilently],
  );

  const getForm = useCallback(
    async (formId: string) => {
      const token = await getAccessTokenSilently();

      if (token && formId) {
        await dispatch(fetchForm(token, formId));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addForm = useCallback(
    async (formObj: Interfaces.Form): Promise<Interfaces.FormOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && formObj && loggedInUser) {
        result = await FormService.postForm(
          token,
          formObj,
          loggedInUser._id,
          project?._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  const editForm = useCallback(
    async (formObj: Interfaces.FormOutput): Promise<Interfaces.FormOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && formObj && loggedInUser) {
        result = await FormService.putForm(
          token,
          formObj,
          loggedInUser._id,
          project?._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  const removeForm = useCallback(
    async (formId: string) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && formId && loggedInUser) {
        result = await FormService.deleteForm(
          token,
          formId,
          loggedInUser._id,
          project?._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  useEffect(() => {
    if (formObj) {
      // Complete model
      setForm(formObj);
    }
  }, [formObj]);

  return { form, getForms, getForm, addForm, editForm, removeForm };
};

export default useForm;
