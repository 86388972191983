import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import FileService, { FileItemsOutput } from '../../services/file/FileService';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useFileResult {
  getFiles: (filePurpose: Enums.FilePurpose) => Promise<FileItemsOutput>;
}

const useFile = (): useFileResult => {
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const getFiles = useCallback(
    async (filePurpose: Enums.FilePurpose): Promise<FileItemsOutput> => {
      const token = await getAccessTokenSilently();

      let result;

      if (token && loggedInUser) {
        result = await FileService.getFiles(token, filePurpose);
      }
      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  return {
    getFiles,
  };
};

export default useFile;
