import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageBodyText,
  StyledBodySubHeader,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { mergeModeOptions } from '../../../DatasetStages/DatasetCreationStages/2-source/MergeDatasetOptions';
import * as SC from '../../styled';

interface DatasetJobProps {
  job: Interfaces.PipelineJob;
  jobIndex: number;
}

const DatasetJob: FC<DatasetJobProps> = ({ job, jobIndex }) => {
  const themeContext = useContext(ThemeContext);
  const { pipelineTemplate } = usePipelineTemplate();
  const { getDatasetMetas } = useDatasetMeta();

  const [outputDatasetId, setOutputDatasetId] = useState<string>();
  const [outputDatasetName, setOutputDatasetName] = useState<string>();
  const [upsertFieldName, setUpsertFieldName] = useState<string>();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const jobParams = job.jobParams as Interfaces.DatasetJobParams;

  // Set mapping job output datasetId
  useEffect(() => {
    if (pipelineTemplate) {
      const clonedJobs = cloneDeep(pipelineTemplate.jobs);
      const previousMappingJob = clonedJobs
        .slice(0, jobIndex)
        .reverse()
        .find(
          (job) => job.jobType === Enums.PipelineJobType.MAPPING,
        ) as Interfaces.MappingJob;

      setOutputDatasetId(
        previousMappingJob?.jobParams.output.entityId ||
          clonedJobs?.reverse()?.[0]?.jobParams.datasetMetaId,
      );
    }
  }, [jobIndex, pipelineTemplate]);

  // Set mapping job ouput dataset name
  useEffect(() => {
    if (!outputDatasetName && outputDatasetId) {
      (async () => {
        let outputDsm = datasetMetas.find(
          (ds) => ds.entity._id === outputDatasetId,
        );
        if (outputDsm) {
          setOutputDatasetName(outputDsm?.entity.name);
        } else {
          await getDatasetMetas({ _id: { $in: [outputDatasetId] } });

          outputDsm = datasetMetas.find(
            (ds) => ds.entity._id === outputDatasetId,
          );
          setOutputDatasetName(outputDsm?.entity.name);
        }

        // Set upsert merge key field name
        if (jobParams.mergeKey) {
          const upsertDsmDataCollection =
            outputDsm?.entity.dataCollections.find(
              (c) => outputDsm?.entity.activeDataCollection === c._id,
            );
          if (upsertDsmDataCollection) {
            const upsertFieldName = upsertDsmDataCollection?.schemaData?.find(
              (f) => f.fieldId === jobParams?.mergeKey,
            )?.name;
            setUpsertFieldName(upsertFieldName);
          }
        }
      })();
    }
  }, [
    outputDatasetId,
    outputDatasetName,
    datasetMetas,
    getDatasetMetas,
    jobParams?.mergeKey,
  ]);

  const filter = jobParams.query
    ?.conditions as unknown as Interfaces.DynamicFilterField[];

  let heading;

  switch (jobParams.action) {
    case Enums.DatasetJobAction.UPDATE:
      heading = 'Updating Data In';
      break;
    case Enums.DatasetJobAction.UPSERT:
      heading = 'Upserting Data In';
      break;
    case Enums.DatasetJobAction.INSERT:
      heading = 'Inserting Data In';
      break;
    case Enums.DatasetJobAction.DELETE:
      heading = 'Deleting Data In';
      break;
  }

  return (
    <>
      <div>
        <StyledBodySubHeader
          style={{ marginBottom: themeContext.margin.standard }}
        >
          {heading}
        </StyledBodySubHeader>
        <StageBodyText>{outputDatasetName}</StageBodyText>

        {!!(filter?.[0]?.value as Interfaces.DynamicConditionalField[])
          ?.length && (
          <>
            <StyledBodySubHeader
              style={{ marginBottom: themeContext.margin.standard }}
            >
              Filtering By
            </StyledBodySubHeader>
            <SC.MappingFieldWrapper>
              {(filter[0]?.value as Interfaces.DynamicConditionalField[])?.map(
                (field, index) => {
                  const fieldWithAlias =
                    field as unknown as Interfaces.FieldOutput & {
                      alias?: string;
                    };
                  return (
                    <SC.MappingField
                      key={`map-field-${fieldWithAlias.alias}-${index}`}
                    >
                      <StyledText>{fieldWithAlias.alias}</StyledText>
                    </SC.MappingField>
                  );
                },
              )}
            </SC.MappingFieldWrapper>
          </>
        )}

        {!!upsertFieldName && (
          <>
            <StyledBodySubHeader
              style={{
                marginTop: themeContext.margin.xlarge,
                marginBottom: themeContext.margin.standard,
              }}
            >
              Upsert Type
            </StyledBodySubHeader>
            <StageBodyText>
              {
                mergeModeOptions.find(
                  (option) => option.key === jobParams.mergeMode,
                )?.text
              }
            </StageBodyText>

            <StyledBodySubHeader
              style={{ marginBottom: themeContext.margin.standard }}
            >
              Matching Column
            </StyledBodySubHeader>
            <SC.MappingFieldWrapper>
              <SC.MappingField key={`upsert-field-${upsertFieldName}`}>
                <StyledText>{upsertFieldName}</StyledText>
              </SC.MappingField>
            </SC.MappingFieldWrapper>
          </>
        )}
      </div>
    </>
  );
};

export default DatasetJob;
