import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { MergeMode, RouteName } from '../../../../enums';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useCreateDatasetMetaSource from '../../../../hooks/dataset-meta/UseCreateDatasetMetaSource';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useValidation from '../../../../hooks/dataset-meta/UseValidation';
import useDataset from '../../../../hooks/dataset/UseDataset';
import useFileUpload from '../../../../hooks/file-upload/UseFileUpload';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledAccordion,
  StyledAccordionTitle,
  StyledH2,
  StyledH4,
  StyledH5,
  StyledInput,
  StyledSubHeader,
  StyledText,
  defaultTheme,
} from '../../../../main/theme';
import { CreateFileDocumentResponse } from '../../../../services/file/FileService';
import { fetchDatasetMetaSuccess } from '../../../../store/dataset-meta';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../../store/dataset-stage';
import { DatasetCreateSubStage } from '../../../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import convertFileTypeToEnum from '../../../../util/file-upload-to-enum/FileTypeToEnum';
import ActionBar from '../../../ActionBar/ActionBar';
import { ActionWrapper } from '../../../DatasetOverview/styled';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import FeatureButtonSelect from '../../../FeatureButtonSelect/FeatureButtonSelect';
import FileUploader from '../../../FileUploader/FileUploader';
import * as SC from '../styled';
import MergeDatasetOptions from './MergeDatasetOptions';

const NEXT_STAGE = DatasetCreateSubStage.RESULT;
const PREV_STAGE = DatasetCreateSubStage.NAME;
const HEADER_FIELD = 'headers';
const LIMIT_FIELD = 'limit';
const DELIMITER_FIELD = 'delimiter';
const DEFAULT_LIMIT = 0;
const DEFAULT_DELIMITER = ',';
const DEFAULT_ADDITIONAL_OPTIONS = {
  headers: 'row',
  limit: DEFAULT_LIMIT,
  delimiter: DEFAULT_DELIMITER,
};
const DEFAULT_MERGE_OPTIONS = { mergeCol: '', mergeMode: '' };
const REPLACE_ACTION = 'replace';
const MERGE_ACTION = 'merge';
const BOOLEAN = 'boolean';
const FILE_ID = 'fileId';

export interface SourceDatasetStageProps {
  sourceFile?: File;
  setSourceFile: Dispatch<SetStateAction<File | undefined>>;
  sourceSheet?: string;
  setSourceSheet: Dispatch<SetStateAction<string | undefined>>;
  setSampleData: Dispatch<SetStateAction<SampleDataRow[]>>;
  setSchema: Dispatch<SetStateAction<Interfaces.Field[]>>;
}

export interface AdditionalOptions {
  headers?: string;
  limit?: number;
  delimiter?: string;
}

const SourceDatasetStage: FC<SourceDatasetStageProps> = ({
  sourceFile,
  setSourceFile,
  sourceSheet,
  setSourceSheet,
  setSampleData,
  setSchema,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const {
    datasetMeta,
    datasetMetaAccessLevel,
    activeDataCollectionItem,
    editDatasetMeta,
  } = useDatasetMeta();
  const { createSourceResult } = useCreateDatasetMetaSource();
  const { uploadFile } = useFileUpload();
  const { validateDataset } = useValidation();
  const { collection } = useDatasetCollection();
  const { mergeDataset } = useDataset();
  const [fileId, setFileId] = useState<string>();
  const [sourceType, setSourceType] = useState<Enums.DataSourceType>();
  const [uploadFileType, setUploadFileType] = useState<Enums.DataSourceType>();
  const [accordionStatus, setAccordionStatus] = useState<boolean>(false);
  const [additionalOptions, setAdditionalOptions] = useState<
    AdditionalOptions | undefined
  >(DEFAULT_ADDITIONAL_OPTIONS);
  const [selectedMergeCol, setSelectedMergeCol] = useState<{
    fieldId?: string;
    name?: string;
    type?: MergeMode;
  }>({});
  const [mergeOptions, setMergeOptions] = useState<{
    mergeCol?: string;
    mergeMode?: string;
  }>({
    mergeCol: '',
    mergeMode: '',
  });
  const [newUpload, setNewUpload] = useState<boolean>(false);
  const [mergeComplete, setMergeComplete] = useState<boolean>(false);
  const [validationComplete, setValidationComplete] = useState<boolean>(false);

  const getSelectColor = (switchVal): string => {
    const isActive = additionalOptions?.headers === switchVal;

    return isActive
      ? themeContext.colors.general.green
      : themeContext.colors.system.grey;
  };

  const getSelectTextStyle = (switchVal): CSSProperties => {
    const isActive = additionalOptions?.headers === switchVal;

    return isActive
      ? { color: themeContext.colors.system.white }
      : { color: themeContext.colors.system.offBlack };
  };

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const currentDataSourceRoot = coll;

  const isReplacing = location.pathname.includes(REPLACE_ACTION);
  const isMerging = location.pathname.includes(MERGE_ACTION);
  const isUsingTableBuilder = sourceType === Enums.DataSourceType.INTERNAL;
  const delimiter = additionalOptions?.delimiter || DEFAULT_DELIMITER;
  const headers =
    (additionalOptions?.headers as Enums.FileHeaderType) ||
    Enums.FileHeaderType.NONE;
  const mergeField = mergeOptions.mergeCol;

  // Get fileId
  useEffect(() => {
    if (datasetMeta && uploadFileType) {
      const source = currentDataSourceRoot?.dataSource?.[uploadFileType] || {
        fileId: '',
      };

      if (typeof source !== BOOLEAN) {
        setFileId(source[FILE_ID]);
      }
    }
  }, [
    currentDataSourceRoot?.dataSource,
    datasetMeta,
    sourceType,
    uploadFileType,
  ]);

  // Get File Type if Already Uploaded
  useEffect(() => {
    setSourceType(currentDataSourceRoot?.dataSource?.dataSourceType);
  }, [currentDataSourceRoot]);

  // Set merge col
  useEffect(() => {
    if (
      isMerging &&
      mergeField &&
      datasetMeta?._id &&
      collection?.collectionId
    ) {
      const field = datasetMeta.dataCollections
        .find((c) => c._id === collection.collectionId)
        ?.schemaData.find((f) => f.fieldId === mergeField);

      setSelectedMergeCol({
        fieldId: mergeField,
        name: field?.name,
        type: (mergeOptions.mergeMode as Enums.MergeMode) || MergeMode.ALL,
      });
    }
  }, [
    collection?.collectionId,
    datasetMeta?._id,
    datasetMeta?.dataCollections,
    isMerging,
    mergeField,
    mergeOptions.mergeMode,
  ]);

  // Validates merge
  useEffect(() => {
    if (mergeComplete && !validationComplete) {
      (async () => {
        setValidationComplete(true);
        await validateDataset();

        dispatch(updateActiveDatasetStage(Enums.DatasetStage.VALIDATION));
      })();
    }
  }, [dispatch, mergeComplete, validateDataset, validationComplete]);

  // Handle merge options change
  const handleMergeChange = (field: string, value: unknown) => {
    setMergeOptions({ ...mergeOptions, [field]: value });
  };

  // Handle changing additional options for file import
  const handleAdditionalOptionsChange = (field, val: string | number) => {
    switch (field) {
      case HEADER_FIELD:
        setAdditionalOptions((additionalOptions) => ({
          ...additionalOptions,
          headers: val.toString(),
        }));
        break;
      case LIMIT_FIELD:
        setAdditionalOptions((additionalOptions) => ({
          ...additionalOptions,
          limit: val as number,
        }));
        break;
      case DELIMITER_FIELD:
        setAdditionalOptions((additionalOptions) => ({
          ...additionalOptions,
          delimiter: val.toString(),
        }));
        break;
    }
  };

  // Handle when using table builder icon
  const runTableBuilder = async (val: Enums.DataSourceType) => {
    const cloned = cloneDeep(datasetMeta);
    const clonedColl = cloned.dataCollections.find(
      (c) => c._id === collection?.collectionId,
    );

    // Clear source options
    clonedColl.dataSource = {
      dataSourceType: undefined,
      internal: false,
    };
    clonedColl.dataSource.dataSourceType = val;
    clonedColl.dataSource.internal = val === Enums.DataSourceType.INTERNAL;
    clonedColl.stages.creation = {
      status: Enums.StageStatus.COMPLETED,
    };
    // If table builder, save datasetMeta and continue to structure
    dispatch(showLoading({ text: 'Saving Dataset...' }));
    await editDatasetMeta(cloned);
    dispatch(hideLoading());
    dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE));
  };

  // Set states when using file uploader
  const handleFileSelection = (file: File, sheet?: string) => {
    setUploadFileType(undefined);
    setSourceFile(file);

    if (sheet) {
      setSourceSheet(sheet);
    }
    if (file?.type) {
      setUploadFileType(convertFileTypeToEnum(file.type));
    }
  };

  // Handle accordion status
  const toggleAccordion = () => {
    setAccordionStatus((current) => !current);
  };

  // Clear data on click of use new source button
  const clearData = () => {
    const cloned = cloneDeep(datasetMeta);
    const clonedColl = cloned.dataCollections.find(
      (c) => c._id === collection?.collectionId,
    );

    clonedColl.dataSource.dataSourceType = undefined;

    // Mark create stage as in progress
    clonedColl.stages.creation = {
      status: Enums.StageStatus.IN_PROGRESS,
    };
    // Clear structure results
    clonedColl.stages.structure = {
      status: Enums.StageStatus.NOT_STARTED,
    };
    // Clear validation results
    clonedColl.stages.validation = {
      status: Enums.StageStatus.NOT_STARTED,
    };
    dispatch(
      fetchDatasetMetaSuccess({
        entity: cloned,
        accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  // Handle final submission of data
  const handleFinalSubmission = async () => {
    const cloned = cloneDeep(datasetMeta);
    const clonedColl = cloned.dataCollections.find(
      (c) => c._id === collection?.collectionId,
    );

    clonedColl.dataSource = {
      dataSourceType: uploadFileType,
      internal: false,
    };
    if (!newUpload && sourceType) {
      history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
      dispatch(updateActiveDatasetSubStage(NEXT_STAGE));
      return;
    }
    clonedColl.dataSource[uploadFileType] = additionalOptions;
    if (newUpload && !clonedColl.dataSource[uploadFileType]) {
      clonedColl.dataSource[uploadFileType].fileId = fileId;
    }

    if (isReplacing) {
      clonedColl.dataSource.dataSourceActionType =
        Enums.DataSourceActionType.REPLACE;
      // Revert schema to active data collection
      clonedColl.schemaData = activeDataCollectionItem?.schemaData || [];
    }
    if (isMerging) {
      clonedColl.dataSource[uploadFileType] = {
        ...additionalOptions,
        ...mergeOptions,
      };
      clonedColl.dataSource.dataSourceActionType =
        Enums.DataSourceActionType.MERGE;
      // Revert schema to active data collection
      clonedColl.schemaData = activeDataCollectionItem?.schemaData || [];
    } else {
      // Clear schema data
      clonedColl.schemaData = [];
    }

    dispatch(
      fetchDatasetMetaSuccess({
        entity: cloned,
        accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );

    // Upload File
    const uploadedFile = await uploadFile(sourceFile, sourceSheet);

    // Create Source Result
    const sampleData = await createSourceResult(
      uploadedFile as CreateFileDocumentResponse,
      sourceSheet,
      uploadFileType,
      headers,
      delimiter,
    );

    if (sampleData) {
      setSampleData(sampleData.rows as SampleDataRow[]);
      setSchema(sampleData.schema);
    }

    // Merging
    if (isMerging) {
      if (
        selectedMergeCol?.fieldId &&
        selectedMergeCol.name &&
        datasetMeta?._id &&
        collection?.collectionId
      ) {
        const mergeResult = await mergeDataset(
          datasetMeta._id,
          collection?.collectionId,
          selectedMergeCol.fieldId,
          selectedMergeCol.name,
          1,
          headers,
          headers === Enums.FileHeaderType.NONE,
          delimiter,
          sourceSheet,
          selectedMergeCol.type,
        );

        if (mergeResult) {
          setMergeComplete(true);
        }
      }
    }
    // Replacing
    else if (isReplacing) {
      // Move on to the next stage
      history.push(
        `${RouteName.DATASET_ITEM}/${datasetMeta?._id}${`/${REPLACE_ACTION}`}`,
      );
      dispatch(updateActiveDatasetSubStage(NEXT_STAGE));
    }
    // Creating
    else {
      history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
      dispatch(updateActiveDatasetSubStage(NEXT_STAGE));
    }
  };

  return (
    <StageWrapper
      style={{
        marginTop: themeContext.margin.small,
      }}
    >
      <StageInner>
        {(sourceType === Enums.DataSourceType.INTERNAL ||
          sourceType === Enums.DataSourceType.CSV ||
          sourceType === Enums.DataSourceType.XLS ||
          sourceType === Enums.DataSourceType.TXT) && (
          <>
            <StyledH2>Existing Source</StyledH2>
            <StageBodyText>
              It looks like you already have an existing source selected for
              this dataset.
            </StageBodyText>
            <StageBodyText>
              To start again with a different source, click below. This will
              clear any previously uploaded file and structure.
            </StageBodyText>
            <FeatureButton
              action={() => {
                setFileId(undefined);
                setUploadFileType(undefined);
                setSourceFile(undefined);
                setSourceSheet(undefined);
                setSourceType(undefined);
                setAdditionalOptions(DEFAULT_ADDITIONAL_OPTIONS);
                setMergeOptions(DEFAULT_MERGE_OPTIONS);
                setNewUpload(true);
                clearData();
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Use a new source'}
            />
          </>
        )}
        {!sourceType && (
          <SC.Container>
            <StageBodyText
              style={{
                marginBottom: themeContext.margin.xxxlarge,
              }}
            >
              To get started, we'll need either an original data source that you
              wish to import, or you can use our Table Builder to start from
              scratch.
            </StageBodyText>
            <SC.StageRow
              style={{
                width: '100%',
                marginBottom: themeContext.margin.small,
              }}
            >
              <SC.StageColumn>
                <>
                  <>
                    <StyledSubHeader>File Upload</StyledSubHeader>

                    <StageBodyText>
                      Select the type of data source you'd like to use.
                    </StageBodyText>
                  </>

                  <FileUploader
                    acceptedTypes={['.csv', '.xls', '.xlsx', '.txt']}
                    sourceType={uploadFileType || sourceType}
                    action={handleFileSelection}
                    file={sourceFile}
                    sheet={sourceSheet}
                  />
                </>
              </SC.StageColumn>

              {!isReplacing && !isMerging && (
                <SC.StageColumn>
                  <StyledSubHeader>Table Builder</StyledSubHeader>
                  <StageBodyText>
                    Use our Table Builder to create any type of structure you'd
                    like.
                  </StageBodyText>
                  <ActionWrapper>
                    <div>
                      <FeatureButton
                        action={() =>
                          runTableBuilder(Enums.DataSourceType.INTERNAL)
                        }
                        text={'Use Table Builder'}
                        size={FeatureButtonSize.WIDE}
                        color={themeContext.colors.general.blue}
                      />
                    </div>
                  </ActionWrapper>
                </SC.StageColumn>
              )}
            </SC.StageRow>

            {uploadFileType && (
              <>
                {isMerging && (
                  <div style={{ marginBottom: themeContext.margin.xlarge }}>
                    <MergeDatasetOptions
                      handleFieldChange={handleMergeChange}
                    />
                  </div>
                )}
                <StyledAccordion
                  style={{
                    width: '100%',
                    marginTop: themeContext.margin.xxlarge,
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledAccordionTitle onClick={toggleAccordion}>
                    <StyledSubHeader>Additional Options</StyledSubHeader>
                    <FontAwesomeIcon
                      icon={accordionStatus ? faChevronUp : faChevronDown}
                      color={defaultTheme.colors.system.offBlack}
                    />
                  </StyledAccordionTitle>
                  <SC.StyledAccordionContent
                    style={{
                      padding: themeContext.padding.xxlarge,
                    }}
                    active={accordionStatus}
                  >
                    <SC.Container>
                      <StyledSubHeader
                        style={{
                          marginTop: themeContext.margin.xxlarge,
                        }}
                      >
                        Data Headers
                      </StyledSubHeader>
                      <StageBodyText>
                        Select whether your data source has headers, and if they
                        run across the top row, or down the first column.
                      </StageBodyText>

                      <div>
                        <FeatureButtonSelect
                          buttons={[
                            {
                              isActive:
                                additionalOptions?.headers ===
                                Enums.FileHeaderType.ROW,
                              action: () =>
                                handleAdditionalOptionsChange(
                                  HEADER_FIELD,
                                  Enums.FileHeaderType.ROW,
                                ),
                              size: FeatureButtonSize.WIDE_SMALL,
                              color: getSelectColor(Enums.FileHeaderType.ROW),
                              text: 'Row',
                              textStyle: getSelectTextStyle(
                                Enums.FileHeaderType.ROW,
                              ),
                            },
                            {
                              isActive:
                                additionalOptions?.headers ===
                                Enums.FileHeaderType.COLUMN,
                              action: () =>
                                handleAdditionalOptionsChange(
                                  HEADER_FIELD,
                                  Enums.FileHeaderType.COLUMN,
                                ),
                              size: FeatureButtonSize.WIDE_SMALL,
                              color: getSelectColor(
                                Enums.FileHeaderType.COLUMN,
                              ),
                              text: 'Column',
                              textStyle: getSelectTextStyle(
                                Enums.FileHeaderType.COLUMN,
                              ),
                            },
                            {
                              isActive:
                                additionalOptions?.headers ===
                                Enums.FileHeaderType.NONE,
                              action: () =>
                                handleAdditionalOptionsChange(
                                  HEADER_FIELD,
                                  Enums.FileHeaderType.NONE,
                                ),
                              size: FeatureButtonSize.WIDE_SMALL,
                              color: getSelectColor(Enums.FileHeaderType.NONE),
                              text: 'None',
                              textStyle: getSelectTextStyle(
                                Enums.FileHeaderType.NONE,
                              ),
                            },
                          ]}
                        />
                      </div>

                      <SC.OptionalWrapper>
                        <StyledH4>Optional Settings</StyledH4>

                        <SC.OptionalFieldWrapper>
                          <SC.OptionalField
                            fullWidth={
                              uploadFileType !== Enums.DataSourceType.CSV
                            }
                          >
                            <StyledH5>Row Limit</StyledH5>
                            <StyledText>
                              Enter a number if you would like to limit the
                              amount of rows that are processed.
                            </StyledText>

                            <StyledInput
                              type={'number'}
                              placeholder={DEFAULT_LIMIT}
                              value={additionalOptions?.limit}
                              onChange={(event, data) => {
                                handleAdditionalOptionsChange(
                                  LIMIT_FIELD,
                                  data.value,
                                );
                              }}
                            />
                          </SC.OptionalField>

                          {uploadFileType === Enums.DataSourceType.TXT ||
                            (uploadFileType === Enums.DataSourceType.CSV && (
                              <SC.OptionalField>
                                <StyledH5>Delimiter</StyledH5>
                                <StyledText>
                                  If you want to split data rows on a character
                                  other than the standard comma, just enter it
                                  below.
                                </StyledText>

                                <StyledInput
                                  placeholder={DEFAULT_DELIMITER}
                                  value={additionalOptions?.delimiter}
                                  onChange={(event, data) => {
                                    handleAdditionalOptionsChange(
                                      DELIMITER_FIELD,
                                      data.value,
                                    );
                                  }}
                                />
                              </SC.OptionalField>
                            ))}
                        </SC.OptionalFieldWrapper>
                      </SC.OptionalWrapper>
                    </SC.Container>
                  </SC.StyledAccordionContent>
                </StyledAccordion>
              </>
            )}
          </SC.Container>
        )}
      </StageInner>

      <ActionBar
        text={`Shall we move on?`}
        primaryButton={
          !isUsingTableBuilder ? (
            <FeatureButton
              isDisabled={
                (!uploadFileType && !sourceType) ||
                (isMerging && !mergeOptions.mergeCol && !sourceType) ||
                (isMerging && !mergeOptions.mergeMode && !sourceType)
              }
              action={handleFinalSubmission}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={
                currentDataSourceRoot?.dataSource?.dataSourceType &&
                !newUpload &&
                sourceFile
                  ? 'Continue to results'
                  : isMerging
                  ? 'Merge Dataset'
                  : isReplacing
                  ? 'Replace Dataset'
                  : 'Create Dataset'
              }
            />
          ) : (
            <FeatureButton
              action={() =>
                dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE))
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue to Structure'}
            />
          )
        }
        backButton={
          isReplacing || isMerging ? (
            sourceType && !fileId ? (
              <FeatureButton
                action={() => {
                  setFileId(undefined);
                  setSourceFile(undefined);
                  setSourceSheet(undefined);
                  setSourceType(undefined);
                  history.push(`${RouteName.DATASETS}/`);
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Back to Datasets'}
              />
            ) : undefined
          ) : (
            <FeatureButton
              action={() => {
                if (fileId) {
                  return dispatch(
                    updateActiveDatasetSubStage(DatasetCreateSubStage.NAME),
                  );
                }

                if (sourceType) {
                  setFileId(undefined);
                  setSourceFile(undefined);
                  setSourceSheet(undefined);
                  setSourceType(undefined);
                  dispatch(updateActiveDatasetSubStage(PREV_STAGE));
                } else {
                  dispatch(updateActiveDatasetSubStage(PREV_STAGE));
                }
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to Name'}
            />
          )
        }
      />
    </StageWrapper>
  );
};

export default SourceDatasetStage;
