import styled from 'styled-components';
import { StageImageProps } from './StageImage';

export const Wrapper = styled.div<StageImageProps>`
  width: ${({ wideImage }) => (wideImage ? '362px' : '60px')};
  height: 60px;

  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${({ theme }) => theme.colors.system.white}
    url(${({ image }) => image}) no-repeat center / 75%;
`;
