import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteName } from '../enums/RouteName';
import APIsPage from '../pages/APIs/APIsPage';
import CMSPage from '../pages/CMS/CMSPage';
import DatasetItemPage from '../pages/DatasetItem/DatasetItemPage';
import DatasetsPage from '../pages/Datasets/DatasetsPage';
import DatasetsExportPage from '../pages/DatasetsExport/DatasetsExportPage';
import FormsPage from '../pages/Forms/FormsPage';
import IntegrationCentrePage from '../pages/IntegrationCentre/IntegrationCentrePage';
import IntegrationItemPage from '../pages/IntegrationItem/IntegrationItemPage';
import ListsPage from '../pages/List/ListPage';
import ListItemPage from '../pages/ListItem/ListItemPage';
import NotificationRedirectPage from '../pages/NotificationRedirect/NotificationRedirectPage';
import NotificationCentrePage from '../pages/NotificationCentre/NotificationCentrePage';
import NotificationsPage from '../pages/Notifications/NotificationsPage';
import OAuthCallbackPage from '../pages/OAuthCallback/OAuthCallbackPage';
import OrganisationItemPage from '../pages/OrganisationItem/OrganisationItemPage';
import OrganisationUsagePage from '../pages/OrganisationUsage/OrganisationUsagePage';
import PipelineItemPage from '../pages/PipelineItem/PipelineItemPage';
import PortalAPIsPage from '../pages/Portal/PortalAPIsPage';
import PortalDataViewsPage from '../pages/Portal/PortalDataViewsPage';
import PortalFormsPage from '../pages/Portal/PortalFormsPage';
import PortalPage from '../pages/Portal/PortalPage';
import PortalQueriesPage from '../pages/Portal/PortalQueriesPage';
import ProjectItemPage from '../pages/ProjectItem/ProjectItemPage';
import ProjectsPage from '../pages/Projects/ProjectsPage';
import SignupPage from '../pages/Signup/SignupPage';
import TeamItemPage from '../pages/TeamItem/TeamItemPage';
import TeamsPage from '../pages/Teams/TeamsPage';
import UserItemPage from '../pages/UserItem/UserItemPage';
import UsersPage from '../pages/Users/UsersPage';
import WelcomePage from '../pages/Welcome/WelcomePage';
import OrgManagerRoute from './OrgManagerRoute';
import ProTierRoute from './ProTierRoute';

const Routes: FC = () => {
  return (
    <Switch>
      {/*APIs*/}
      <Route path={RouteName.APIS} exact={true} component={APIsPage} />
      {/*Forms*/}
      <Route path={RouteName.FORMS} exact={true} component={FormsPage} />
      {/*Notifications Centre*/}
      <Route
        path={RouteName.NOTIFICATIONS}
        exact={true}
        component={NotificationsPage}
      />
      {/*Projects*/}
      <Route path={RouteName.PROJECTS} exact={true} component={ProjectsPage} />
      <Route
        path={`${RouteName.PROJECT_ITEM}`}
        exact={true}
        component={ProjectsPage}
      />
      <Route
        path={`${RouteName.PROJECT_ITEM}/:id`}
        exact={true}
        component={ProjectItemPage}
      />
      {/*CMS*/}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CMS}/:cmsId`}
        exact={true}
        component={CMSPage}
      />
      {/*CMS - DATASET*/}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CMS}/:cmsId/:groupId/dataset/:datasetMetaId`}
        exact={true}
        component={CMSPage}
      />
      {/*CMS - AGGREGATION*/}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CMS}/:cmsId/:groupId/query/:aggregationId`}
        exact={true}
        component={CMSPage}
      />
      {/*CMS - CHART */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CMS}/:cmsId/:groupId${RouteName.CHART_ITEM}/:chartId`}
        exact={true}
        component={CMSPage}
      />
      {/* CHARTS */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CHARTS}`}
        exact={true}
      />
      {/* CHART CREATE */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CHART_ITEM}`}
        exact={true}
      />
      {/* CHART EDIT */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CHART_ITEM}/:chartId`}
        exact={true}
      />
      {/* CHART - GRAPH CREATE */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CHART_ITEM}/:chartId${RouteName.GRAPH_ITEM}`}
        exact={true}
      />
      {/* CHART - GRAPH EDIT */}
      <Route
        path={`${RouteName.PROJECT_ITEM}/:projectId${RouteName.CHART_ITEM}/:chartId${RouteName.GRAPH_ITEM}:/graphId`}
        exact={true}
      />
      {/*Datasets*/}
      <Route path={RouteName.DATASETS} exact={true} component={DatasetsPage} />
      <Route
        path={`${RouteName.DATASET_ITEM}`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/edit`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/replace`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/advanced`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/merge`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/duplicate`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/integration`}
        exact={true}
        component={DatasetItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId/view`}
        exact={true}
        component={CMSPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId${RouteName.PIPELINE_ITEM}`}
        exact={true}
        component={PipelineItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId${RouteName.PIPELINE_ITEM}/:pipelineId`}
        exact={true}
        component={PipelineItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId${RouteName.PIPELINE_ITEM}/:pipelineId${RouteName.EVENTS}`}
        exact={true}
        component={PipelineItemPage}
      />
      <Route
        path={`${RouteName.DATASET_ITEM}/:datasetMetaId${RouteName.PIPELINE_ITEM}/:pipelineId${RouteName.EVENT_ITEM}/:eventId`}
        exact={true}
        component={PipelineItemPage}
      />
      {/*Lists*/}
      <Route
        path={`${RouteName.DATASETS}${RouteName.LISTS}`}
        exact={true}
        component={ListsPage}
      />
      <Route
        path={`${RouteName.DATASETS}${RouteName.LIST_ITEM}`}
        exact={true}
        component={ListItemPage}
      />
      <Route
        path={`${RouteName.DATASETS}${RouteName.LIST_ITEM}/:listId`}
        exact={true}
        component={ListItemPage}
      />
      {/*Data Export*/}
      <Route
        path={`${RouteName.DATASETS}${RouteName.EXPORT}`}
        exact={true}
        component={DatasetsExportPage}
      />
      {/*Notifications*/}
      <Route
        path={`${RouteName.DATASETS}${RouteName.NOTIFICATIONS}`}
        exact={true}
        component={NotificationsPage}
      />
      {/*Integration Centre*/}
      <Route
        path={`${RouteName.INTEGRATION_CENTRE}`}
        exact={true}
        component={IntegrationCentrePage}
      />
      {/*Integration Item*/}
      <Route
        path={`${RouteName.INTEGRATION_ITEM}`}
        exact={true}
        component={IntegrationItemPage}
      />
      <Route
        path={`${RouteName.INTEGRATION_ITEM}/:integrationTemplateId`}
        exact={true}
        component={IntegrationItemPage}
      />
      {/* OAUTH CALLBACK */}
      <Route
        path={`${RouteName.OAUTH_CALLBACK}`}
        exact={true}
        component={OAuthCallbackPage}
      />
      {/*Portals*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId`}
        exact={true}
        component={PortalPage}
      />
      {/*Portals - CMS List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms`}
        exact={true}
        component={PortalDataViewsPage}
      />
      {/*Portals - CMS View*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Chart*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId${RouteName.CMS}/:cmsId/:groupId${RouteName.CHART_ITEM}/:chartId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Dataset*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId/:groupId/dataset/:datasetMetaId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Aggregations*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId/:groupId/query/:aggregationId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portal - Form List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/form`}
        exact={true}
        component={PortalFormsPage}
      />
      {/*Portal - API List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/api`}
        exact={true}
        component={PortalAPIsPage}
      />
      {/*Portal - Query List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/query`}
        exact={true}
        component={PortalQueriesPage}
      />
      {/*Portal - Query Builder*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/query/:aggregationId`}
        exact={true}
        component={PortalQueriesPage}
      />
      {/*Portal - User Profile*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/user`}
        exact={true}
        component={UserItemPage}
      />
      {/*Teams*/}
      <ProTierRoute path={RouteName.TEAMS} exact={true} component={TeamsPage} />
      <ProTierRoute
        path={`${RouteName.TEAM_ITEM}`}
        exact={true}
        component={TeamItemPage}
      />
      <ProTierRoute
        path={`${RouteName.TEAM_ITEM}/:id`}
        exact={true}
        component={TeamItemPage}
      />
      {/*Users*/}
      <OrgManagerRoute
        path={RouteName.USERS}
        exact={true}
        component={UsersPage}
      />
      <OrgManagerRoute
        path={`${RouteName.USER_ITEM}`}
        exact={true}
        component={UserItemPage}
      />
      <Route
        path={`${RouteName.USER_ITEM}/:id`}
        exact={true}
        component={UserItemPage}
      />
      {/*Organisation*/}
      <OrgManagerRoute
        path={`${RouteName.ORGANISATION_ITEM}`}
        exact={true}
        component={OrganisationItemPage}
      />
      <OrgManagerRoute
        path={`${RouteName.ORGANISATION_ITEM}${RouteName.USAGE}`}
        exact={true}
        component={OrganisationUsagePage}
      />
      {/*Notification Centre*/}
      <Route
        path={RouteName.NOTIFICATION_CENTRE}
        exact={true}
        component={NotificationCentrePage}
      />
      {/*Notification Redirect*/}
      <Route
        path={`${RouteName.NOTIFICATION_REDIRECT}/:notificationId`}
        exact={true}
        component={NotificationRedirectPage}
      />
      {/*Signup*/}
      <Route path={RouteName.SIGNUP} exact={true} component={SignupPage} />
      {/*Welcome*/}
      <Route path={RouteName.WELCOME} component={WelcomePage} />
    </Switch>
  );
};

export default Routes;
