import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../store/modal';
import FadeIn from '../FadeIn/FadeIn';
import AggregationAddMeasureModal, {
  AggregationAddMeasureModalProps,
} from './aggregation/AggregationAddMeasureModal';
import AggregationColumnAliasModal, {
  AggregationColumnAliasModalProps,
} from './aggregation/AggregationColumnAliasModal';
import ChartAddAreaModal, {
  ChartAddAreaModalProps,
} from './chart/ChartAddAreaModal';
import CMSAddRowModal, { CMSAddRowModalProps } from './cms/CMSAddRowModal';
import CMSMultiRowModal, {
  CMSMultiRowModalProps,
} from './cms/CMSMultiRowModal';
import CMSQueryMultiLink, {
  CMSQueryLinkedDatasetsModalProps,
} from './cms/CMSQueryLinkedDatasetsModal';
import ConnectionAPIModal, {
  ConnectionAPIModalProps,
} from './connection/api/ConnectionAPIModal';
import CMSAddEntityModal, {
  CMSAddEntityModalProps,
} from './connection/cms/CMSAddEntityModal';
import CMSCreateGroupModal, {
  CMSCreateGroupModalProps,
} from './connection/cms/CMSCreateGroupModal';
import DeletionModal, { DeletionModalProps } from './deletion/DeletionModal';
import DuplicationModal, {
  DuplicationModalProps,
} from './duplication/DuplicationModal';
import EnhancementAddColumnModal, {
  EnhancementAddColumnModalProps,
} from './enhancement/EnhancementAddColumnModal';
import ErrorModal, { ErrorModalProps } from './error/ErrorModal';
import ExportModal, { ExportModalProps } from './export/ExportModal';
import FilterModal, { FilterModalProps } from './filter/FilterModal';
import FormAddGroupModal, {
  FormAddGroupModalProps,
} from './form/FormAddGroupModal';
import GraphModal, { GraphModalProps } from './graph/GraphModal';
import IntegrationEndpointMainModal from './integration-centre/integration-stages/IntegrationEndpointMainModal';
import IntegrationEndpointRequestModal from './integration-centre/integration-stages/IntegrationEndpointRequestModal';
import IntegrationCentreManageModal from './integration-centre/IntegrationManageModal';
import ListAddItemModal, {
  ListAddItemModalProps,
} from './list/ListAddItemModal';
import ModellingAddLinkModal, {
  ModellingAddLinkModalProps,
} from './modelling/ModellingAddLinkModal';
import NotificationAlertModal, {
  NotificationAlertModalProps,
} from './notification-alert/NotificationAlertModal';
import OnboardingModal, {
  OnboardingModalProps,
} from './onboarding/OnboardingModal';
import PipelineJobsModal from './pipeline/PipelineJobsModal';
import DatasetPublishedModal, {
  DatasetPublishedModalProps,
} from './publish/DatasetPublishedModal';
import SnapshotModal, { SnapshotModalProps } from './snapshot/SnapshotModal';
import * as SC from './styled';
import ValidationDataTypeModal, {
  ValidationDataTypeModalProps,
} from './validation/ValidationDataTypeModal';

const ESC_KEY = 27;

export enum ModalTypes {
  VALIDATION_DATA_TYPE = 'validation-data-type',
  ENHANCEMENT_ADD_COLUMN = 'enhancement-add-column',
  MODELLING_ADD_LINK = 'modelling-add-link',
  CMS_ADD_ROW = 'cms-add-row',
  CMS_MULTIPLE_ROWS = 'cms-multiple-rows',
  CONNECTION_API = 'connection-api',
  CONNECTION_CMS_ADD_ENTITY = 'connection-cms-add-entity',
  CONNECTION_CMS_CREATE_GROUP = 'connection-cms-create-group',
  DATASET_PUBLISHED = 'dataset-published',
  DELETION = 'deletion',
  ERROR = 'error',
  AGGREGATION_ADD_MEASURE = 'aggregation-add-measure',
  AGGREGATION_COLUMN_ALIAS = 'aggregation-column-alias',
  FORM_ADD_GROUP = 'form-add-group',
  EXPORT_DATA = 'export-data',
  ADD_FILTER = 'add-filter,',
  ONBOARDING = 'onboarding',
  SNAPSHOT = 'snapshot',
  LIST_ADD_ITEM = 'list-add-item',
  CHART_ADD_AREA = 'chart-add-area',
  GRAPH = 'graph',
  DATASET_META_DUPLICATION = 'dataset-meta-duplication',
  INTEGRATION_CENTRE_MANAGE = 'integration-centre-manage',
  INTEGRATION_ENDPOINT_MAIN_MODAL = 'integration-endpoint-main-modal',
  INTEGRATION_ENDPOINT_REQUEST_MODAL = 'integration-endpoint-request-modal',
  PIPELINE_JOBS_MODAL = 'pipeline-jobs-modal',
  CMS_QUERY_LINKED_DATASETS = 'cms-query-linked-datasets',
  NOTIFICATION_ALERT = 'notification-alert',
}

const SCROLLABLE_MODALS = [
  ModalTypes.VALIDATION_DATA_TYPE,
  ModalTypes.ENHANCEMENT_ADD_COLUMN,
  ModalTypes.ADD_FILTER,
  ModalTypes.INTEGRATION_ENDPOINT_MAIN_MODAL,
  ModalTypes.INTEGRATION_ENDPOINT_REQUEST_MODAL,
  ModalTypes.PIPELINE_JOBS_MODAL,
];

export interface ModalProps {
  visible: boolean;
  modal?: ModalTypes;
  additionalProps?: Record<string, unknown>;
  style?: React.CSSProperties;
  fullScreen?: boolean;
  forceOpen?: boolean;
}

const getModal = (
  modal: ModalTypes,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  additionalProps?: Record<string, unknown>,
) => {
  let addProps;
  switch (modal) {
    case ModalTypes.VALIDATION_DATA_TYPE:
      addProps = additionalProps as unknown as ValidationDataTypeModalProps;
      return (
        <ValidationDataTypeModal setShowModal={setShowModal} {...addProps} />
      );
    case ModalTypes.ENHANCEMENT_ADD_COLUMN:
      addProps = additionalProps as unknown as EnhancementAddColumnModalProps;
      return (
        <EnhancementAddColumnModal setShowModal={setShowModal} {...addProps} />
      );
    case ModalTypes.MODELLING_ADD_LINK:
      addProps = additionalProps as unknown as ModellingAddLinkModalProps;
      return (
        <ModellingAddLinkModal setShowModal={setShowModal} {...addProps} />
      );
    case ModalTypes.CMS_ADD_ROW:
      addProps = additionalProps as unknown as CMSAddRowModalProps;
      return <CMSAddRowModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CMS_MULTIPLE_ROWS:
      addProps = additionalProps as unknown as CMSMultiRowModalProps;
      return <CMSMultiRowModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CMS_QUERY_LINKED_DATASETS:
      addProps = additionalProps as unknown as CMSQueryLinkedDatasetsModalProps;
      return <CMSQueryMultiLink setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CONNECTION_API:
      addProps = additionalProps as unknown as ConnectionAPIModalProps;
      return <ConnectionAPIModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CONNECTION_CMS_ADD_ENTITY:
      addProps = additionalProps as unknown as CMSAddEntityModalProps;
      return <CMSAddEntityModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CONNECTION_CMS_CREATE_GROUP:
      addProps = additionalProps as unknown as CMSCreateGroupModalProps;
      return <CMSCreateGroupModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.DATASET_PUBLISHED:
      addProps = additionalProps as unknown as DatasetPublishedModalProps;
      return (
        <DatasetPublishedModal setShowModal={setShowModal} {...addProps} />
      );
    case ModalTypes.DELETION:
      addProps = additionalProps as unknown as DeletionModalProps;
      return <DeletionModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.ERROR:
      addProps = additionalProps as unknown as ErrorModalProps;
      return <ErrorModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.AGGREGATION_ADD_MEASURE:
      addProps = additionalProps as unknown as AggregationAddMeasureModalProps;
      return (
        <AggregationAddMeasureModal setShowModal={setShowModal} {...addProps} />
      );
    case ModalTypes.AGGREGATION_COLUMN_ALIAS:
      addProps = additionalProps as unknown as AggregationColumnAliasModalProps;
      return (
        <AggregationColumnAliasModal
          setShowModal={setShowModal}
          {...addProps}
        />
      );
    case ModalTypes.FORM_ADD_GROUP:
      addProps = additionalProps as unknown as FormAddGroupModalProps;
      return <FormAddGroupModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.EXPORT_DATA:
      addProps = additionalProps as unknown as ExportModalProps;
      return <ExportModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.ADD_FILTER:
      addProps = additionalProps as unknown as FilterModalProps;
      return <FilterModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.ONBOARDING:
      addProps = additionalProps as unknown as OnboardingModalProps;
      return <OnboardingModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.SNAPSHOT:
      addProps = additionalProps as unknown as SnapshotModalProps;
      return <SnapshotModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.LIST_ADD_ITEM:
      addProps = additionalProps as unknown as ListAddItemModalProps;
      return <ListAddItemModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CHART_ADD_AREA:
      addProps = additionalProps as unknown as ChartAddAreaModalProps;
      return <ChartAddAreaModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.GRAPH:
      addProps = additionalProps as unknown as GraphModalProps;
      return <GraphModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.DATASET_META_DUPLICATION:
      addProps = additionalProps as unknown as DuplicationModalProps;
      return <DuplicationModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.INTEGRATION_CENTRE_MANAGE:
      addProps = additionalProps as unknown as DuplicationModalProps;
      return (
        <IntegrationCentreManageModal
          setShowModal={setShowModal}
          {...addProps}
        />
      );
    case ModalTypes.INTEGRATION_ENDPOINT_MAIN_MODAL:
      addProps = additionalProps as unknown as DuplicationModalProps;
      return (
        <IntegrationEndpointMainModal
          setShowModal={setShowModal}
          {...addProps}
        />
      );
    case ModalTypes.INTEGRATION_ENDPOINT_REQUEST_MODAL:
      addProps = additionalProps as unknown as DuplicationModalProps;
      return (
        <IntegrationEndpointRequestModal
          setShowModal={setShowModal}
          {...addProps}
        />
      );
    case ModalTypes.PIPELINE_JOBS_MODAL:
      addProps = additionalProps as unknown as DuplicationModalProps;
      return <PipelineJobsModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.NOTIFICATION_ALERT:
      addProps = additionalProps as unknown as NotificationAlertModalProps;
      return (
        <NotificationAlertModal setShowModal={setShowModal} {...addProps} />
      );
  }
};

const Modal: FC<ModalProps> = ({
  visible,
  modal,
  additionalProps,
  style,
  fullScreen,
  forceOpen,
}) => {
  const dispatch = useDispatch();
  const clickRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!forceOpen) {
      const close = (e) => {
        // Use ESC to close modal
        if (e.keyCode === ESC_KEY) {
          dispatch(hideModal());
        }
      };

      // Click outside of the modal to also close it
      const handleClick = (e) => {
        if (clickRef.current && !clickRef.current?.contains(e.target)) {
          dispatch(hideModal());
        }
      };

      document.addEventListener('keydown', close);
      document.addEventListener('mousedown', handleClick);

      return () => {
        document.removeEventListener('keydown', close);
        document.removeEventListener('mousedown', handleClick);
      };
    }
  }, [dispatch, forceOpen]);

  if (!visible || !modal) {
    return null;
  }

  return (
    <FadeIn>
      <SC.Overlay>
        <SC.Wrapper
          ref={clickRef}
          showModal={showModal}
          scrollable={SCROLLABLE_MODALS.includes(modal)}
          style={style}
          fullScreen={fullScreen}
          modal={modal}
        >
          {getModal(modal, setShowModal, additionalProps)}
        </SC.Wrapper>
      </SC.Overlay>
    </FadeIn>
  );
};

export default Modal;
