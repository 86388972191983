import React, { FC } from 'react';
import * as SC from './styled';

export interface StageImageProps {
  image: string;
  wideImage?: boolean;
}

const StageImage: FC<StageImageProps> = ({ image, wideImage }) => {
  return <SC.Wrapper image={image} wideImage={wideImage} />;
};

export default StageImage;
