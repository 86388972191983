import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import ProjectItemConnectionFormNameStage from '../../components/ProjectItem/ProjectItemFormStages/1-name/ProjectItemFormNameStage';
import ProjectItemConnectionFormDataStage from '../../components/ProjectItem/ProjectItemFormStages/2-datasets-fields/ProjectItemFormDataStage';
import ProjectItemFormBuildStage from '../../components/ProjectItem/ProjectItemFormStages/3-build-form/ProjectItemFormBuildStage';
import ProjectItemFormConfigurationStage from '../../components/ProjectItem/ProjectItemFormStages/4-configuration/ProjectItemFormConfigurationStage';
import ProjectItemConnectionFormStage from '../../components/ProjectItem/ProjectItemFormStages/ProjectItemFormStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import { RouteName } from '../../enums';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { updateActiveProjectStage } from '../../store/project-stage';
import { ProjectConnectionFormSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const FormsPage: FC = () => {
  const dispatch = useDispatch();
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ConnectionType.FORM;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);
  const isBuilding =
    activeSubStage === ProjectConnectionFormSubStage.FORM_BUILDER;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectConnectionFormSubStage.NAME:
        return <ProjectItemConnectionFormNameStage />;
      case ProjectConnectionFormSubStage.DATASET:
        return <ProjectItemConnectionFormDataStage />;
      case ProjectConnectionFormSubStage.FORM_BUILDER:
        return <ProjectItemFormBuildStage />;
      case ProjectConnectionFormSubStage.CONFIGURATION:
        return <ProjectItemFormConfigurationStage />;
      default:
        return <ProjectItemConnectionFormNameStage />;
    }
  };

  const stageContentTitle = stageContent.title;

  useEffect(() => {
    if (projectStageState.activeStage !== Enums.ConnectionType.FORM) {
      dispatch(updateActiveProjectStage(Enums.ConnectionType.FORM));
    }
  }, [dispatch, projectStageState.activeStage]);

  return (
    <>
      <PrimaryNavigationContainer
        route={RouteName.PROJECT_ITEM}
        connectionType={Enums.ConnectionType.FORM}
      />

      <PageContainer>
        <Page>
          <PageFeatureHeader
            pageHeader={stageTitle}
            subStages={
              activeSubStage &&
              activeSubStage !== ProjectConnectionFormSubStage.CONFIGURATION
                ? {
                    activeSubStage: activeSubStage,
                    subStageItems: projectStageState.stages[
                      activeStage
                    ].subStages.filter(
                      (stage) =>
                        stage.subStage !==
                        ProjectConnectionFormSubStage.CONFIGURATION,
                    ) as SubStageItem[],
                  }
                : undefined
            }
            image={isBuilding ? undefined : stageContent.image}
            title={isBuilding ? undefined : stageContentTitle}
          />

          {activeSubStage ? (
            getSubStageComponent()
          ) : (
            <ProjectItemConnectionFormStage />
          )}
        </Page>
      </PageContainer>
    </>
  );
};

export default FormsPage;
