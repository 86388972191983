import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface ListItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.List;
}

export interface UserListOutput extends Interfaces.ListOutput {
  users: {
    _id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  }[];
  teams: {
    _id: string;
    name: string;
    avatar: string;
  }[];
}

export interface ListItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.ListOutput | UserListOutput;
}

// TODO - Should be accessed from upit-core-types when available
export interface ListsItemOutput {
  data: ListItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const endpoint = process.env['REACT_APP_CRUD_LIST'] || '';

export default class ListService {
  public static async getLists(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<ListsItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<ListsItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getListsForResource(
    token: string,
    resourceType: string,
    resourceId: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<ListsItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<ListsItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}?resourceType=${resourceType}&resourceId=${resourceId}`);
  }

  public static async getList(
    token: string,
    id: string,
  ): Promise<ListItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<ListItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${id}`, { singleDocument: true });

    return result?.data as ListItemOutput;
  }

  public static async postList(
    token: string,
    list: Interfaces.List,
    userId: string,
    portalId?: string,
  ): Promise<ListItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<ListItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: list, userId, portalId });

    return result?.data as ListItemOutput;
  }

  public static async putList(
    token: string,
    list: Interfaces.ListOutput,
    userId: string,
    portalId?: string,
  ): Promise<ListItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ListItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${list._id}`, { data: list, userId, portalId });

    return result?.data as ListItemOutput;
  }

  public static async deleteList(
    token: string,
    listId: string,
    userId: string,
    portalId?: string,
  ): Promise<ListItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<ListItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${listId}`, userId, portalId);

    return result?.data as ListItemOutput;
  }
}
