import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums';
import useIntegrationTemplate from '../../hooks/integration-template/UseIntegrationTemplate';
import PageContainer from '../../main/PageContainer';
import { IntegrationStage } from '../../store/integration-stage/initial-state';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import IntegrationItemConfiguration from './IntegrationItemConfiguration';
import IntegrationItemCreation from './IntegrationItemCreation';
import * as SC from './styled';

const IntegrationItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const {
    integrationTemplate,
    integrationTemplateError,
    getIntegrationTemplate,
  } = useIntegrationTemplate();

  const [fetchingIntegrationTemplate, setFetchingIntegrationTemplate] =
    useState<boolean>(false);

  const integrationStageState = useSelector(
    (state: RootState) => state.integrationStage,
  );

  const activeStage = integrationStageState.activeStage;

  // Get an existing IntegrationTemplate if requested
  useEffect(() => {
    if (
      !fetchingIntegrationTemplate &&
      params?.integrationTemplateId &&
      params?.integrationTemplateId !== integrationTemplate?._id
    ) {
      (async () => {
        setFetchingIntegrationTemplate(true);
        dispatch(
          showLoading({
            text: `Loading Integration Template...`,
          }),
        );

        await getIntegrationTemplate(params.integrationTemplateId);

        dispatch(hideLoading());
      })();
    }
  }, [
    params,
    integrationTemplate?._id,
    dispatch,
    fetchingIntegrationTemplate,
    getIntegrationTemplate,
  ]);

  // Error fetching IntegrationTemplate
  useEffect(() => {
    if (fetchingIntegrationTemplate && integrationTemplateError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'Integration Not Found',
            errorText:
              'This integration could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Integration Centre',
            errorActionRedirectRoute: RouteName.INTEGRATION_CENTRE,
          },
        }),
      );
    }
  }, [integrationTemplateError, dispatch, fetchingIntegrationTemplate]);

  const getStageComponent = () => {
    switch (activeStage) {
      case IntegrationStage.CREATION:
        return <IntegrationItemCreation />;
      case IntegrationStage.CONFIGURATION:
        return <IntegrationItemConfiguration />;
      default:
        return <IntegrationItemCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.INTEGRATION_ITEM} />
      <PageContainer>
        <SC.ContentWrapper>{getStageComponent()}</SC.ContentWrapper>
      </PageContainer>
    </>
  );
};

export default IntegrationItemPage;
