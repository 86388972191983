import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemAggregationNameStage from '../../components/ProjectItem/ProjectItemAggregationStage/1-name/ProjectItemAggregationNameStage';
import ProjectItemAggregationDataStage from '../../components/ProjectItem/ProjectItemAggregationStage/2-datasets-fields/ProjectitemAggregationDataStage';
import ProjectItemAggregationAggregateStage from '../../components/ProjectItem/ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import ProjectItemAggregationConfigurationStage from '../../components/ProjectItem/ProjectItemAggregationStage/4-configuration/ProjectItemAggregationConfigurationStage';
import ProjectAggregationStage from '../../components/ProjectItem/ProjectItemAggregationStage/ProjectItemAggregationStage';
import ProjectItemNameStage from '../../components/ProjectItem/ProjectItemCreationStages/1-name/ProjectItemNameStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import { ProjectAggregationSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectItemAggregation: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ProjectStage.AGGREGATION;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);
  const stageContentTitle = stageContent?.title;
  const isAggregating = activeSubStage === ProjectAggregationSubStage.AGGREGATE;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectAggregationSubStage.NAME:
        return <ProjectItemAggregationNameStage />;
      case ProjectAggregationSubStage.DATASETS:
        return <ProjectItemAggregationDataStage />;
      case ProjectAggregationSubStage.AGGREGATE:
        return <ProjectItemAggregationAggregateStage />;
      case ProjectAggregationSubStage.CONFIGURATION:
        return <ProjectItemAggregationConfigurationStage />;
      default:
        return <ProjectItemNameStage />;
    }
  };

  return (
    <>
      {!isAggregating && (
        <PageFeatureHeader
          pageHeader={stageTitle}
          subStages={
            activeSubStage &&
            activeSubStage !== ProjectAggregationSubStage.CONFIGURATION
              ? {
                  activeSubStage: activeSubStage,
                  subStageItems: projectStageState.stages[
                    activeStage
                  ].subStages.filter(
                    (stage) =>
                      stage.subStage !==
                      ProjectAggregationSubStage.CONFIGURATION,
                  ) as SubStageItem[],
                }
              : undefined
          }
          image={stageContent.image}
          title={stageContentTitle}
        />
      )}

      <Page style={isAggregating ? { overflow: 'hidden' } : undefined}>
        {activeSubStage ? getSubStageComponent() : <ProjectAggregationStage />}
      </Page>
    </>
  );
};

export default ProjectItemAggregation;
