import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';

import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import {
  EXTRACT_IDENTIFIER,
  HOUR_IDENTIFIER,
} from '../../../../const/DateConst';
import { RouteName } from '../../../../enums';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getLookupFieldType } from '../../../../util/lookup/GetLookupFieldType';

const QUERY_ROUTE = 'query';
const isQuery =
  location.pathname.includes(RouteName.PROJECT_ITEM) ||
  location.pathname.includes(QUERY_ROUTE);

const HELPER_DATE_FORMAT = [
  Enums.DateFormat.DATE_SHORT,
  Enums.DateFormat.DATE_SHORT_US,
  Enums.DateFormat.DATE_SHORT_REVERSED,
  Enums.DateFormat.DATE_MED,
  Enums.DateFormat.DATE_MED_WITH_WEEKDAY,
  Enums.DateFormat.DATE_FULL,
  Enums.DateFormat.DATE_HUGE,
  Enums.DateFormat.DATETIME_SHORT,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_MED,
  Enums.DateFormat.DATETIME_FULL,
  Enums.DateFormat.DATETIME_HUGE,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_24_MED,
  Enums.DateFormat.DATETIME_24_FULL,
  Enums.DateFormat.DATETIME_24_HUGE,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS,
];

export const HELPER_EXTRACT_TYPE = [
  Enums.DateConversionOperation.EXTRACT_DATE,
  Enums.DateConversionOperation.EXTRACT_DAY,
  Enums.DateConversionOperation.EXTRACT_WEEK_NUM,
  Enums.DateConversionOperation.EXTRACT_MONTH,
  Enums.DateConversionOperation.EXTRACT_YEAR,
  Enums.DateConversionOperation.EXTRACT_HOUR,
  Enums.DateConversionOperation.EXTRACT_MINUTE,
  Enums.DateConversionOperation.EXTRACT_SECOND,
  Enums.DateConversionOperation.EXTRACT_TIME,
];

const HELPER_EXTRACT_TYPE_WITH_TIME = [
  Enums.DateConversionOperation.EXTRACT_HOUR,
  Enums.DateConversionOperation.EXTRACT_MINUTE,
  Enums.DateConversionOperation.EXTRACT_SECOND,
  Enums.DateConversionOperation.EXTRACT_TIME,
];

interface DateExtractProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
}

const dateExtractTypeOptions = Object.entries(
  Enums.DateConversionOperation,
).reduce((acc, [key, val]) => {
  if (
    val !== Enums.DateConversionOperation.UNKNOWN &&
    key.startsWith(EXTRACT_IDENTIFIER)
  ) {
    const type = startCase(key.toLowerCase());
    return acc.concat({
      key: `column-type-${type}-${val}`,
      value: val,
      text: type,
    });
  }
  return acc;
}, [] as DropdownItemProps[]);

const dateFormatTypeOptions = Object.entries(Enums.DateFormat).map(
  ([key, val], i) => {
    const type = startCase(key.toLowerCase());

    return {
      key: `date-format-${type}-${i}`,
      text: DateTime.now().toFormat(val),
      value: val,
      description: type,
    };
  },
);

const filterByExtractType = (
  extractType: Enums.DateConversionOperation | undefined,
) => {
  if (!extractType) {
    return [];
  }
  switch (extractType) {
    case Enums.DateConversionOperation.EXTRACT_YEAR:
      return [Enums.DateFormat.YEAR_FULL, Enums.DateFormat.YEAR_SHORT];
    case Enums.DateConversionOperation.EXTRACT_DAY:
      return [Enums.DateFormat.DAY_PADDED, Enums.DateFormat.DAY_UNPADDED];
    case Enums.DateConversionOperation.EXTRACT_WEEK_NUM:
      return [
        Enums.DateFormat.WEEK_NUM_PADDED,
        Enums.DateFormat.WEEK_NUM_UNPADDED,
      ];
    case Enums.DateConversionOperation.EXTRACT_MONTH:
      return [
        Enums.DateFormat.MONTH_FULL_NAME,
        Enums.DateFormat.MONTH_NUM_PADDED,
        Enums.DateFormat.MONTH_NUM_UNPADDED,
        Enums.DateFormat.MONTH_SHORT_NAME,
      ];
    case Enums.DateConversionOperation.EXTRACT_HOUR:
      return [
        Enums.DateFormat.HOUR_12_UNPADDED,
        Enums.DateFormat.HOUR_12_PADDED,
        Enums.DateFormat.HOUR_24_UNPADDED,
        Enums.DateFormat.HOUR_24_PADDED,
      ];
    case Enums.DateConversionOperation.EXTRACT_MINUTE:
      return [Enums.DateFormat.MINUTE_PADDED, Enums.DateFormat.MINUTE_UNPADDED];
    case Enums.DateConversionOperation.EXTRACT_SECOND:
      return [Enums.DateFormat.SECOND_PADDED, Enums.DateFormat.SECOND_UNPADDED];
    case Enums.DateConversionOperation.EXTRACT_TIME:
      return [
        Enums.DateFormat.TIME_24_SIMPLE,
        Enums.DateFormat.TIME_24_WITH_SECONDS,
        Enums.DateFormat.TIME_SIMPLE,
        Enums.DateFormat.TIME_WITH_SECONDS,
      ];
    case Enums.DateConversionOperation.EXTRACT_DATE:
      return HELPER_DATE_FORMAT;
    default:
      return [];
  }
};

const DateExtract: FC<DateExtractProps> = ({
  field,
  schema,
  setDataValidation,
  setSuperColumnValid,
  loaded,
}) => {
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [columnNameOptions, setColumnNameOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [selectedColumnName, setSelectedColumnName] = useState<string>();

  const [extractType, setExtractType] =
    useState<Enums.DateConversionOperation>();

  const [formatType, setFormatType] = useState<Enums.DateFormat>();

  const filter: Enums.DateFormat[] = filterByExtractType(extractType);

  const resetColumnNames = () => {
    setFormatType(undefined);
    setSelectedColumnName('');
  };

  // Set column name options
  useEffect(() => {
    if (!schema || !loaded) {
      return;
    }

    const extractTypeRequiresTime =
      extractType && HELPER_EXTRACT_TYPE_WITH_TIME.includes(extractType);

    setColumnNameOptions(
      schema
        .filter((s) =>
          extractTypeRequiresTime ||
          formatType?.toLowerCase().includes(HOUR_IDENTIFIER)
            ? s.dataValidation?.dataValidationType &&
              s.dataValidation?.dataValidationType ===
                Enums.ValueDataType.DATE &&
              s.dataValidation?.constraints?.format
                ?.toLowerCase()
                .includes(HOUR_IDENTIFIER)
            : s.dataValidation?.dataValidationType &&
              [Enums.ValueDataType.DATE].includes(
                getLookupFieldType(s, datasetMetas) ||
                  s.dataValidation?.dataValidationType,
              ),
        )
        .map((schemaField, i) => {
          return {
            key: `column-name-${schemaField.name}-${i}`,
            value: isQuery
              ? schemaField.name
              : schemaField.fieldId || schemaField.name,
            text: schemaField.name,
          };
        }),
    );
  }, [schema, extractType, formatType, datasetMetas, loaded]);

  // Set values if updating existing
  useEffect(() => {
    if (!field) {
      return;
    }

    const opts = field.dataValidation
      ?.dateConversion as Interfaces.DateExtractionOp;

    if (opts) {
      setSelectedColumnName(opts.params.field);
      setFormatType(opts.params.format);
      setExtractType(opts.type);
    }
  }, [field]);

  // Set selected
  useEffect(() => {
    if (
      extractType === Enums.DateConversionOperation.DATE_DIFF ||
      extractType === Enums.DateConversionOperation.REFORMAT ||
      extractType === Enums.DateConversionOperation.TODAY ||
      extractType === Enums.DateConversionOperation.UNKNOWN ||
      !extractType ||
      !formatType ||
      !selectedColumnName
    ) {
      setSuperColumnValid(false);
    } else {
      setSuperColumnValid(true);
      setDataValidation({
        constraints: field.dataValidation?.constraints,
        dataValidationType: Enums.ValueDataType.DATE_CONVERSION,
        dateConversion: {
          type: extractType,
          params: {
            format: formatType,
            field: selectedColumnName,
          },
        },
      });
    }
  }, [
    extractType,
    field.dataValidation?.constraints,
    formatType,
    selectedColumnName,
    setDataValidation,
    setSuperColumnValid,
  ]);

  return (
    <>
      <StyledBodySubHeader>Extract</StyledBodySubHeader>

      <StyledText>
        Select which part of the date you would like to extract.
      </StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a date extract type'}
        options={dateExtractTypeOptions}
        selection
        value={extractType}
        onChange={(e, data) => {
          resetColumnNames();
          setExtractType(data.value);
        }}
        style={{ marginTop: 0 }}
      />

      <StyledText>Select a date format.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a date format'}
        options={dateFormatTypeOptions.filter((o) => filter.includes(o.value))}
        selection
        value={formatType}
        onChange={(e, data) => setFormatType(data.value)}
        style={{ marginTop: 0 }}
      />

      <StyledBodySubHeader>Columns</StyledBodySubHeader>

      <StyledText>
        Select which date column you would like to extract from.
      </StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select column to extract from'}
        options={columnNameOptions}
        selection
        search
        value={selectedColumnName}
        onChange={(e, data) => setSelectedColumnName(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />
    </>
  );
};

export default DateExtract;
