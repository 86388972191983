import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  QuotaLimits,
  BannerType,
  AlertStatus,
  ResourceType,
} from '../../enums';

import { StageBodyText, StyledSubHeader } from '../../main/theme';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import NavTag, { NavTagType } from '../Navigation/NavTag';
import * as SC from './styled';

interface AdvancedSettingProps {
  action: () => void;
  headerText: string;
  headerTag?: NavTagType;
  subHeaderText: string;
  bodyText: string;
  actionButtonText: string;
  actionButtonColor?: string;
  isDisabled?: boolean;
  usage?: number;
}

const AdvancedSetting: FC<AdvancedSettingProps> = ({
  action,
  headerText,
  headerTag,
  subHeaderText,
  bodyText,
  actionButtonText,
  actionButtonColor,
  isDisabled,
  usage,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <SC.SettingWrapper>
      <SC.SettingTitleWrapper>
        <StyledSubHeader>{headerText}</StyledSubHeader>
        {headerTag && <NavTag type={NavTagType.NEW} />}
      </SC.SettingTitleWrapper>
      <StageBodyText>{subHeaderText}</StageBodyText>

      <StageBodyText>{bodyText}</StageBodyText>

      {(usage as number) >= QuotaLimits.WARNING && (
        <div style={{ marginBottom: themeContext.margin.xlarge }}>
          {BuildBanner.generateBanner(
            BannerType.QUOTA,
            (usage as number) >= QuotaLimits.FULL
              ? AlertStatus.ERROR
              : AlertStatus.WARNING,
            ResourceType.DATASET_METAS,
          )}
        </div>
      )}

      <FeatureButton
        action={action}
        size={FeatureButtonSize.WIDE}
        color={actionButtonColor || themeContext.colors.general.blue}
        text={actionButtonText}
        isDisabled={isDisabled || (usage as number) >= QuotaLimits.FULL}
      />
    </SC.SettingWrapper>
  );
};

export default AdvancedSetting;
