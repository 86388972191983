import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import useUser from '../../../hooks/user/UseUser';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../../main/theme';
import { hideLoading, showLoading } from '../../../store/loading';
import { fetchLoggedInUserSuccess } from '../../../store/logged-in-user';
import { hideModal } from '../../../store/modal';
import { fetchOrganisationSuccess } from '../../../store/organisation';
import { fetchUserSuccess } from '../../../store/user';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import IconSelector from '../../IconSelector/IconSelector';

export interface OnboardingModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const STAGE_ZERO = 0;
const STAGE_ONE = 1;
const STAGE_TWO = 2;
const STAGE_THREE = 3;
const STAGE_FOUR = 4;

const CONFETTI_COUNT = 500;
const AVATAR_SELECTOR_HEIGHT = 300;

const FIRST_NAME_FIELD = 'firstName';
const LAST_NAME_FIELD = 'lastName';
const JOB_ROLE_FIELD = 'jobRole';
const TERMS_FIELD = 'field';
const AVATAR_FIELD = 'avatar';

const ORG_NAME_FIELD = 'name';

const TERMS_URL = 'https://goconfigur.com/terms-of-service/';
const ACCEPTABLE_USE_URL = 'https://goconfigur.com/acceptable-use-policy/';
const PRIVACY_URL = 'https://goconfigur.com/privacy-policy/';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const NameWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.standard};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: ${({ theme }) => `0 ${theme.margin.standard}`};
  }
`;

const OnboardingModal: FC<OnboardingModalProps> = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { organisation, organisationAccessLevel, editOrganisation } =
    useOrganisation();
  const { loggedInUser, loggedInUserAccessLevel } = useLoggedInUser();
  const { editUser } = useUser();

  const [userState, setUserState] = useState<Interfaces.UserOutput>();
  const [orgState, setOrgState] = useState<Interfaces.OrganisationOutput>();
  const [userOnboarding, setUserOnboarding] = useState<boolean>(false);
  const [orgOnboarding, setOrgOnboarding] = useState<boolean>(false);

  const [activeStage, setActiveStage] = useState<number>(STAGE_ZERO);
  const [confettiCount, setConfettiCount] = useState<number>(0);

  const [latestTermsAgreed, setLatestTermsAgreed] = useState<boolean>(false);

  const checkLatestTermsAgreed = useCallback(
    (user: Interfaces.UserOutput): boolean => {
      const today = new Date().toLocaleDateString('en-GB');
      const latestAgreed = user?.termsAccepted.length
        ? new Date(
            user?.termsAccepted[user.termsAccepted.length - 1].acceptedOn,
          ).toLocaleDateString('en-GB')
        : null;

      return today === latestAgreed;
    },
    [],
  );

  // Confetti timer
  useEffect(() => {
    if (confettiCount) {
      const timer = setTimeout(() => {
        setConfettiCount(0);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [confettiCount]);

  // Get user from state
  useEffect(() => {
    if (loggedInUser?._id && !userState) {
      setUserState({
        ...loggedInUser,
        firstName:
          loggedInUser.firstName === 'TBA' ? '' : loggedInUser.firstName,
        lastName: loggedInUser.firstName === 'TBA' ? '' : loggedInUser.lastName,
        avatar: loggedInUser.firstName === 'TBA' ? '' : loggedInUser.avatar,
        jobRole: loggedInUser.firstName === 'TBA' ? '' : loggedInUser.jobRole,
      });

      if (!loggedInUser.signupComplete) {
        setUserOnboarding(true);
      }
    }
  }, [loggedInUser, userState]);

  // Get org from state
  useEffect(() => {
    if (organisation?._id && !orgState) {
      setOrgState({ ...organisation, name: '' });

      if (!organisation.signupComplete) {
        setOrgOnboarding(true);
      }
    }
  }, [organisation, orgState]);

  // Check if latest terms agreed
  useEffect(() => {
    if (userState) {
      setLatestTermsAgreed(checkLatestTermsAgreed(userState));
    }
  }, [checkLatestTermsAgreed, userState]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const handleUserChange = (
    field: string,
    val: string | string[] | boolean,
  ) => {
    const cloned = cloneDeep(userState);

    if (field === TERMS_FIELD) {
      const acceptedDate = new Date().toISOString();

      cloned.termsAccepted = !val
        ? (cloned.termsAccepted = cloned.termsAccepted.slice(
            0,
            cloned.termsAccepted.length - 3,
          ))
        : cloned.termsAccepted.concat([
            {
              acceptedOn: acceptedDate,
              termsURL: TERMS_URL,
            },
            {
              acceptedOn: acceptedDate,
              termsURL: ACCEPTABLE_USE_URL,
            },
            {
              acceptedOn: acceptedDate,
              termsURL: PRIVACY_URL,
            },
          ]);
    } else {
      cloned[field] = val;
    }

    setUserState(cloned);
    setLatestTermsAgreed(checkLatestTermsAgreed(cloned));
  };

  const handleOrgChange = (field: string, val: string | string[] | boolean) => {
    const cloned = cloneDeep(orgState);

    cloned[field] = val;

    setOrgState(cloned);
  };

  const saveUser = () => {
    if (userState) {
      dispatch(
        fetchUserSuccess({
          accessLevel: loggedInUserAccessLevel || Enums.AccessLevel.EDIT,
          entity: userState,
        }),
      );
    }
  };

  const saveOrg = () => {
    if (orgState) {
      dispatch(
        fetchOrganisationSuccess({
          accessLevel: organisationAccessLevel || Enums.AccessLevel.MANAGE,
          entity: orgState,
        }),
      );
    }
  };

  const completeOnboarding = async () => {
    dispatch(showLoading({ text: 'Starting engines...' }));

    if (userOnboarding && userState) {
      await editUser({
        _id: userState._id,
        firstName: userState.firstName,
        lastName: userState.lastName,
        avatar: userState.avatar,
        jobRole: userState.jobRole,
        signupComplete: true,
        termsAccepted: userState.termsAccepted,
      });

      // Update logged in user
      if (userState._id === loggedInUser?._id) {
        dispatch(
          fetchLoggedInUserSuccess({
            accessLevel: loggedInUserAccessLevel || Enums.AccessLevel.EDIT,
            entity: { ...userState, signupComplete: true },
          }),
        );
      }
    }

    if (orgOnboarding && orgState) {
      await editOrganisation({
        _id: orgState._id,
        name: orgState.name,
        signupComplete: true,
      });
    }

    dispatch(hideModal());
    dispatch(hideLoading());
  };

  return (
    <Wrapper>
      {activeStage === STAGE_ZERO && (
        <>
          <Header>Welcome To Configur!</Header>

          <StyledText>
            We just need a few extra details to get you set up and then you can
            jump straight into managing your data like a pro.
          </StyledText>

          <StyledText>
            This won't take long, so tap the button below to kick things off.
          </StyledText>

          <ActionButtonWrapper>
            <FeatureButton
              action={() =>
                setActiveStage(userOnboarding ? STAGE_ONE : STAGE_THREE)
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Start'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {activeStage === STAGE_ONE && (
        <>
          <Header>Introduce Yourself</Header>

          <StyledText>Tell us a little about yourself.</StyledText>

          <NameWrapper>
            <div>
              <StyledBodySubHeader>First Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter your first name`}
                value={userState?.firstName || ''}
                onChange={(event, data) =>
                  handleUserChange(FIRST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>

            <div>
              <StyledBodySubHeader>Last Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter your last name`}
                value={userState?.lastName || ''}
                onChange={(event, data) =>
                  handleUserChange(LAST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>
          </NameWrapper>

          <StyledBodySubHeader>Job Role</StyledBodySubHeader>
          <StyledInput
            placeholder={`Enter your job role`}
            value={userState?.jobRole || ''}
            onChange={(event, data) =>
              handleUserChange(JOB_ROLE_FIELD, data.value)
            }
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Our Policies</StyledBodySubHeader>
          <StyledText>
            Take a look at our{' '}
            <a target={'_blank'} href={TERMS_URL}>
              Terms of Service
            </a>
            {', '}
            <a target={'_blank'} href={ACCEPTABLE_USE_URL}>
              Acceptable User Policy
            </a>{' '}
            and{' '}
            <a target={'_blank'} href={PRIVACY_URL}>
              Privacy Policy
            </a>{' '}
            and check the box below to agree to them.
          </StyledText>
          <Checkbox
            label={'I agree to the above policies'}
            checked={latestTermsAgreed}
            onChange={(e, data) =>
              handleUserChange(TERMS_FIELD, data.checked || false)
            }
          />

          <ActionButtonWrapper>
            <FeatureButton
              isDisabled={
                !userState?.firstName ||
                !userState?.lastName ||
                !userState?.jobRole ||
                !userState?.termsAccepted.length ||
                !latestTermsAgreed
              }
              action={() => {
                saveUser();
                setActiveStage(STAGE_TWO);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {activeStage === STAGE_TWO && (
        <>
          <Header>Let's Put A Face To The Name</Header>

          <StyledText>
            Select an avatar below so you can be recognised across the platform.
          </StyledText>

          <IconSelector
            type={EntityType.USER}
            onChange={(icon) => handleUserChange(AVATAR_FIELD, icon)}
            selectedIcon={userState?.avatar}
            maxHeight={AVATAR_SELECTOR_HEIGHT}
          />

          <ActionButtonWrapper>
            <FeatureButton
              isDisabled={!userState?.avatar}
              action={() => {
                saveUser();

                if (orgOnboarding) {
                  setActiveStage(STAGE_THREE);
                } else {
                  setActiveStage(STAGE_FOUR);
                  setConfettiCount(CONFETTI_COUNT);
                }
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {activeStage === STAGE_THREE && (
        <>
          <Header>About Your Organisation</Header>

          <StyledText>
            Give your organisation a name so you can share your data externally
            later on.
          </StyledText>

          <StyledBodySubHeader>Organisation Name</StyledBodySubHeader>
          <StyledInput
            placeholder={`Enter your organisation name`}
            value={orgState?.name || ''}
            onChange={(event, data) =>
              handleOrgChange(ORG_NAME_FIELD, data.value)
            }
          />

          <ActionButtonWrapper>
            <FeatureButton
              isDisabled={!orgState?.name}
              action={() => {
                saveOrg();
                setActiveStage(STAGE_FOUR);
                setConfettiCount(CONFETTI_COUNT);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {activeStage === STAGE_FOUR && (
        <>
          <Confetti numberOfPieces={confettiCount} />

          <Header>Onboarding Complete!</Header>

          <StyledText>
            You're now all set up and ready to start beautifying your data and
            streamlining your processes.
          </StyledText>

          <StyledText>
            We're here to help every step of the way so don't hesitate to reach
            out if you need any help making your data sparkle.
          </StyledText>

          <StyledText>To get started, tap the button below!</StyledText>

          <ActionButtonWrapper>
            <FeatureButton
              action={completeOnboarding}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Get Started'}
            />
          </ActionButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default OnboardingModal;
