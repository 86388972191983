import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import CmsHeader from '../CMS/CMSHeader/CMSHeader';
import DatasetActionHeader from '../DatasetHeader/DatasetActionHeader';
import DatasetViewActionHeader from '../DatasetHeader/DatasetViewActionHeader';
import UserNavigation from '../UserNavigation/UserNavigation';

const Wrapper = styled.div<{ lightTheme?: boolean }>`
  display: flex;
  justify-content: space-between;

  background-color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.white : theme.colors.system.darkBlue};

  border-bottom: 1px solid
    ${({ theme, lightTheme }) =>
      lightTheme ? theme.colors.system.grey : theme.colors.system.darkBlue};

  > :last-of-type {
    margin-left: auto;
  }
`;

const PageHeader: FC = () => {
  const location = useLocation();

  const isViewingSingleDataset =
    location.pathname.includes(RouteName.DATASET_ITEM) &&
    location.pathname.includes('view');

  const isViewingCMS =
    location.pathname.includes(RouteName.PROJECT_ITEM) &&
    location.pathname.includes(RouteName.CMS);

  const isManagingDataset = location.pathname.includes(
    `${RouteName.DATASET_ITEM}/`,
  );

  const isPortal = location.pathname.includes(RouteName.PORTAL);

  let header;

  if (isViewingSingleDataset) {
    header = <DatasetViewActionHeader />;
  } else if (isPortal) {
    header = null;
  } else if (isViewingCMS) {
    header = <CmsHeader />;
  } else if (isManagingDataset) {
    header = <DatasetActionHeader />;
  }

  return (
    <Wrapper lightTheme={isPortal}>
      {header}

      <UserNavigation
        lightTheme={location.pathname.includes(RouteName.PORTAL)}
      />
    </Wrapper>
  );
};

export default PageHeader;
