import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const DefaultNavigation: FC = () => {
  const history = useHistory();

  return (
    <>
      <NavItem
        isActive={true}
        key={`not-authorised-back`}
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Go back'} />
      </NavItem>
    </>
  );
};

export default DefaultNavigation;
