import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

interface FilterMatchConditionsProps {
  filter: DynamicConditionalField;
  setFilter: React.Dispatch<React.SetStateAction<DynamicConditionalField>>;
  isDisabled?: boolean;
  smallHeadings?: boolean;
  hideIntroText?: boolean;
}

const matchDropdownOptions = [
  {
    key: 'match-all',
    value: Enums.AggregationFilterOperator.AND,
    text: 'Match All Filters',
  },
  {
    key: 'match-any',
    value: Enums.AggregationFilterOperator.OR,
    text: 'Match Any Filters',
  },
];

const FilterMatchConditions: FC<FilterMatchConditionsProps> = ({
  filter,
  setFilter,
  isDisabled,
  smallHeadings,
  hideIntroText,
}) => {
  const themeContext = useContext(ThemeContext);

  const addFilterRow = () => {
    const cloned = cloneDeep(filter);
    cloned.value.push({
      operator: Enums.AggregationFilterOperator.IN,
    });

    setFilter(cloned);
  };

  const handleConditionChange = (
    condition:
      | Enums.AggregationFilterOperator.OR
      | Enums.AggregationFilterOperator.AND,
  ) => {
    const cloned = cloneDeep(filter);
    cloned.operator = condition;

    setFilter(cloned);
  };

  return (
    <>
      {smallHeadings && !hideIntroText && (
        <StyledBodySubHeader>Match Condition</StyledBodySubHeader>
      )}
      {!smallHeadings && !hideIntroText && (
        <StyledSubHeader>Match Condition</StyledSubHeader>
      )}
      {!hideIntroText && (
        <StyledText>How would you like to match your filters?</StyledText>
      )}
      {!hideIntroText && (
        <StyledDropdown
          selectOnBlur={false}
          placeholder={'Select a match condition'}
          options={matchDropdownOptions}
          selection
          value={filter?.operator}
          onChange={(e, data) => handleConditionChange(data.value)}
          style={{ marginTop: 0, marginBottom: themeContext.margin.xxlarge }}
        />
      )}

      {smallHeadings && !hideIntroText && (
        <StyledBodySubHeader style={{ marginBottom: 0 }}>
          Match Filters
        </StyledBodySubHeader>
      )}
      {!smallHeadings && !hideIntroText && (
        <StyledSubHeader>Match Filters</StyledSubHeader>
      )}

      {(filter?.value as DynamicConditionalField[]).length === 0 && (
        <FeatureButton
          action={addFilterRow}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.blue}
          text={'Add Filter'}
          style={{ marginTop: themeContext.margin.standard }}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default FilterMatchConditions;
