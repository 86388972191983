export interface Pagination {
  currentPageNum: number;
  nextPageNum: number | null;
  prevPageNum: number | null;
  totalCount: number;
  totalPages: number;
}

export const PAGE = 'page';
export const PAGE_SIZE = 'pageSize';
