import { Enums } from '@configur-tech/upit-core-types';
import { UserItem } from '../../services/user/UserService';

const InitialUser: UserItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',
    signupComplete: false,
    termsAccepted: [],

    userType: '' as Enums.UserType,

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    firstName: '',
    lastName: '',
    avatar: '',

    email: '',

    jobRole: '',
  },
};

export default InitialUser;
