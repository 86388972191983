import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../assets/icons/loading/default-loading-icon.gif';
import { ROW_NUMBER_HEADER } from '../../../const/DataSample';
import useCommentData from '../../../hooks/comment/UseCommentData';
import { DefaultPopupStyles } from '../../../main/theme';
import { CommentItemOutput } from '../../../store/comments';
import { RootState } from '../../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { Header } from '../CMSFilter/styled';
import Comment from './Comment';
import CommentInput from './CommentInput';
import * as SC from './styled';

const CMSComments: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { state } = useLocation();
  const history = useHistory();
  const { commentsFetching, reloadCommentData, loadNextPage } =
    useCommentData();
  const commentsData = useSelector((state: RootState) => state.comments)?.data;

  const tableRowId = state?.tableRowId;
  const [comments, setComments] = useState<CommentItemOutput[]>();
  const rowId = new URLSearchParams(location.search).get(ROW_NUMBER_HEADER);

  const [ref, , entry] = useInView({
    triggerOnce: false,
    delay: 1000,
    onChange: () => loadComments(entry),
  });

  const loadComments = (entry: IntersectionObserverEntry | undefined) => {
    if (!entry) {
      return;
    }
    setTimeout(() => {
      loadNextPage();
    }, 500);
  };

  // Get comments from store
  useEffect(() => {
    if (commentsData) {
      setComments(commentsData.data);
    }
  }, [commentsData]);

  const showDatasetComments = () => {
    const params = new URLSearchParams(history.location.search);
    params.delete(ROW_NUMBER_HEADER);
    history.push({ search: params.toString() });
  };

  return (
    <SC.Wrapper>
      <SC.SidebarWrapper>
        <SC.CommentsHeaderWrapper>
          <Header>{tableRowId && `Row ${tableRowId} `}Comments</Header>
          {rowId && (
            <Popup
              content={'Clear Row Selection'}
              position="top left"
              style={DefaultPopupStyles}
              trigger={
                <div>
                  <FeatureButton
                    action={showDatasetComments}
                    size={FeatureButtonSize.EXTRA_SMALL}
                    height={30}
                    color={themeContext.colors.general.sea}
                    icon={
                      <FontAwesomeIcon
                        icon={faClose}
                        color={themeContext.colors.system.white}
                        size={'1x'}
                      />
                    }
                  />
                </div>
              }
            />
          )}
        </SC.CommentsHeaderWrapper>

        <SC.CommentsWrapper>
          {commentsFetching && (
            <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />
          )}
          {comments?.map((comment) => {
            return <Comment comment={comment} key={comment.entity._id} />;
          })}
          {!commentsFetching && !comments?.length && <p>No comments yet</p>}
          <SC.Loader
            ref={ref}
            src={DefaultLoadingIcon}
            alt={'Load next page'}
            style={{
              display: commentsData?.pagination?.nextPageNum ? 'block' : 'none',
            }}
          />
        </SC.CommentsWrapper>
        <CommentInput
          loading={commentsFetching}
          reloadComments={() => reloadCommentData()}
        />
      </SC.SidebarWrapper>
    </SC.Wrapper>
  );
};

export default CMSComments;
