import styled from 'styled-components';

export const ProjectIconWrapper = styled.div`
  min-width: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    margin: ${({ theme }) => theme.margin.small};
  }
`;
