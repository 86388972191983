import { Interfaces } from '@configur-tech/upit-core-types/';
import { FC, useEffect, useState } from 'react';
import { EntityType } from '../../../enums';
import { SelectedResource } from '../../../hooks/cms/useCMSData';
import WysiwygEditor from '../../ChartBuilder/WysiwygEditor';
import * as SC from './styled';

const PANEL_ID = 'panel-description';
const DESCRIPTION_ID = 'portal-description';
const AREA_ID = 'portal-description-area';

interface CMSDescriptionProps {
  cms?: Interfaces.ConnectionOutput;
  selectedResource?: SelectedResource;
}

const CMSDescription: FC<CMSDescriptionProps> = ({ cms, selectedResource }) => {
  const [activeItem, setActiveItem] = useState<
    Interfaces.CMSConfigurationDataGroupItem | undefined
  >();

  useEffect(() => {
    if (cms && selectedResource) {
      const group = (
        cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
      ).groups.find((group) => group._id === selectedResource?.groupId);

      const item = group?.items.find(
        (item) =>
          item.datasetMetaId === selectedResource?.resourceId ||
          item.aggregationId === selectedResource?.resourceId ||
          item.chartId === selectedResource?.resourceId,
      );

      setActiveItem(item);
    }
  }, [
    activeItem,
    cms,
    selectedResource,
    selectedResource?.groupId,
    selectedResource?.resourceId,
  ]);

  return (
    <SC.Wrapper>
      <SC.StickyWrapper>
        <SC.HeaderWrapper>
          <SC.Header>Description</SC.Header>
        </SC.HeaderWrapper>
        {!activeItem?.description?.length && (
          <SC.DescriptionPlaceholder>
            No description available, you can set a description for this portal
            item using the workspace section of the CMS.
          </SC.DescriptionPlaceholder>
        )}
        {activeItem?.description && (
          <SC.Panel key={PANEL_ID} readOnly={true}>
            <WysiwygEditor
              refreshOnStateChange={true}
              page={EntityType.PORTAL}
              readOnly={true}
              areaId={AREA_ID}
              panelId={DESCRIPTION_ID}
              content={activeItem?.description}
            />
          </SC.Panel>
        )}
      </SC.StickyWrapper>
    </SC.Wrapper>
  );
};

export default CMSDescription;
