import styled from 'styled-components';

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.xxlarge} 0 0`};

  > h1 {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }

  > textarea {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }
`;

export const NameActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;

export const CopyActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;
