import React, { FC } from 'react';
import { EntityType } from '../../../enums/EntityType';
import getDefaultAvatar from '../../../util/default-avatar/DefaultAvatar';
import { AvatarIcon } from '../../../util/icon-helpers/AvatarMap';
import { UserIcon } from '../../../util/icon-helpers/UserIconMap';
import { Cell } from '../../DataSample/DataSample';
import * as SC from './styled';

interface AvatarNameTableCellProps extends Cell {
  avatar: string;
  name: string;
  iconMap?: Record<AvatarIcon | UserIcon, string>;
}

const AvatarNameTableCell: FC<AvatarNameTableCellProps> = ({
  name,
  avatar,
  iconMap,
}) => {
  const renderAvatar = () => {
    if (avatar.includes('http')) {
      return <SC.Image src={avatar} alt={`${name} Avatar`} isCustomAvatar />;
    }
    if (avatar && iconMap && iconMap[avatar]) {
      return (
        <SC.Image
          src={avatar ? iconMap[avatar] : getDefaultAvatar(EntityType.PROJECT)}
          alt={`${avatar} Avatar`}
        />
      );
    }
  };
  return (
    <SC.AvatarNameWrapper>
      {renderAvatar()}
      {name}
    </SC.AvatarNameWrapper>
  );
};

export default AvatarNameTableCell;
