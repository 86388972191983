import { Enums } from '@configur-tech/upit-core-types';
import { UsageQuotas } from '../../services/usage/UsageService';

const InitialUsage: UsageQuotas = {
  staticResources: {
    datasetMetas: {
      used: 0,
      available: 0,
    },
    dataPoints: {
      used: 0,
      available: 0,
    },
    projects: {
      used: 0,
      available: 0,
    },
    portals: {
      used: 0,
      available: 0,
    },
    apis: {
      used: 0,
      available: 0,
    },
    users: {
      used: 0,
      available: 0,
    },
    teams: {
      used: 0,
      available: 0,
    },
    snapshots: {
      used: 0,
      available: 0,
    },
    pipelines: {
      used: 0,
      available: 0,
    },
    customPipelineIntegrations: {
      used: 0,
      available: 0,
    },
  },
  dynamicResources: {
    apiCalls: {
      used: 0,
      available: 0,
      period: Enums.QuotaPeriod.MONTHLY,
      periodDates: {
        start: '' as unknown as Date,
        end: '' as unknown as Date,
      },
    },
    pipelineRuns: {
      used: 0,
      available: 0,
      period: Enums.QuotaPeriod.MONTHLY,
      periodDates: {
        start: '' as unknown as Date,
        end: '' as unknown as Date,
      },
    },
  },
};

export default InitialUsage;
