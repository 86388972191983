import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

export interface SitewideBannerState {
  isSitewideBanner: boolean;
}

const initialState: SitewideBannerState = {
  isSitewideBanner: false,
};

const SitewideBannerSlice = createSlice({
  name: 'sitewide banner',
  initialState,
  reducers: {
    // Fetch
    updateSitewideBanner(state, action: PayloadAction<boolean>) {
      state.isSitewideBanner = action.payload;
    },
  },
});

export const { updateSitewideBanner } = SitewideBannerSlice.actions;

export default SitewideBannerSlice.reducer;

export const setSiteWideBanner =
  (bannerStatus: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(updateSitewideBanner(bannerStatus));
  };
