import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatasetStage } from '@configur-tech/upit-core-types/lib/enums';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { ERROR_VALIDATION_LIMIT } from '../../../../const/ErrorConst';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import useValidation from '../../../../hooks/dataset-meta/UseValidation';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useDiscovery from '../../../../hooks/dataset-meta/UseDiscovery';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import useSchema from '../../../../hooks/schema/UseSchema';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageInner,
  StageWrapper,
  StyledH2,
  StyledText,
} from '../../../../main/theme';
import ActionBar from '../../../ActionBar/ActionBar';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../../store/dataset-stage';
import { DatasetCreateSubStage } from '../../../../store/dataset-stage/initial-state';
import { showModal } from '../../../../store/modal';
import { ModalTypes } from '../../../Modal/Modal';
import { ErrorType } from '../../../Modal/error/ErrorModal';
import buildTableData from '../../../../util/build-table-data/BuildTableData';
import * as SC from '../styled';

const NEXT_STAGE = DatasetStage.STRUCTURE;
const PREV_STAGE = DatasetCreateSubStage.NAME;
const TABLE_HEIGHT = 440;

interface ResultDatasetStageProps {
  sampleData?: SampleDataRow[];
  schema?: Interfaces.Field[];
  setSampleData: Dispatch<SetStateAction<SampleDataRow[]>>;
  setSchema: Dispatch<SetStateAction<Interfaces.Field[]>>;
}

const ResultDatasetStage: FC<ResultDatasetStageProps> = ({
  schema,
  setSchema,
  sampleData,
  setSampleData,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { discoverSchema } = useSchema();
  const { datasetMeta, editDatasetMeta, activeDataCollectionItem } =
    useDatasetMeta();
  const { collection } = useDatasetCollection();
  const { discoverDataset } = useDiscovery();
  const { createTableData } = useTableData();
  const { validateDataset } = useValidation();

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const sourceType = coll?.dataSource?.dataSourceType;
  const source = sourceType && coll?.dataSource?.[sourceType];

  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');

  useEffect(() => {
    // If viewing an existing datasetMeta, create sample data
    if (
      !schema?.length &&
      [
        Enums.DataSourceType.CSV,
        Enums.DataSourceType.TXT,
        Enums.DataSourceType.XLS,
      ].includes(sourceType as Enums.DataSourceType)
    ) {
      (async () => {
        const sourceFile = source as Interfaces.DataSourceFile;

        // Build and return sample data
        const head =
          sourceFile.headers === Enums.FileHeaderType.NONE
            ? undefined
            : sourceFile.headers;

        const result = await discoverSchema(
          sourceFile.fileId,
          head,
          sourceFile.headers === Enums.FileHeaderType.NONE,
          sourceFile.delimiter,
          sourceFile.sheetName,
        );

        if (result) {
          const tableData = await buildTableData(result.schema, result.sample);

          if (tableData.schema && tableData.rows) {
            setSampleData(tableData.rows);
            setSchema(tableData.schema);
          }
        }
      })();
    }
  }, [
    discoverSchema,
    schema?.length,
    setSampleData,
    setSchema,
    source,
    sourceType,
  ]);

  const processAction = async () => {
    const discoveryData = await discoverDataset();
    if (!discoveryData) {
      return;
    }

    const tableData = await createTableData(
      discoveryData.schema,
      discoveryData.sample,
    );
    if (!tableData.rows) {
      return;
    }

    setSampleData(tableData.rows);

    const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

    const coll = collection?.collectionId
      ? cloned.dataCollections.find((c) => c._id === collection?.collectionId)
      : cloned.dataCollections?.[0];

    if (coll) {
      if (isReplacing || isMerging) {
        // Revert schema to active data collection
        coll.schemaData = activeDataCollectionItem?.schemaData || [];
      } else {
        // Clear schema data
        coll.schemaData = [];
      }
      // Save schema - concat newly discovered fields
      coll.schemaData = coll.schemaData.concat(
        (tableData.schema as Interfaces.FieldOutput[]).reduce((acc, field) => {
          const exists = coll.schemaData.find((f) => f.name === field.name);

          if (exists) {
            return acc;
          }

          // Default any formula columns
          if (
            field.dataValidation?.dataValidationType ===
              Enums.ValueDataType.FORMULA &&
            !field.dataValidation.formula
          ) {
            field.dataValidation.formula = [];
          }

          return [...acc, field];
        }, [] as Interfaces.FieldOutput[]),
      );
      // Save datasetMeta
      await editDatasetMeta(cloned);

      if (isMerging || isReplacing) {
        (
          coll.stages[
            Enums.DatasetStage.STRUCTURE
          ] as Interfaces.DatasetMetaStage
        ).status = Enums.StageStatus.COMPLETED;

        (
          coll.stages[
            Enums.DatasetStage.VALIDATION
          ] as Interfaces.DatasetMetaStage
        ).status = Enums.StageStatus.IN_PROGRESS;

        // Validate dataset
        try {
          await validateDataset();

          dispatch(
            updateActiveDatasetStage(
              isMerging || isReplacing
                ? Enums.DatasetStage.VALIDATION
                : Enums.DatasetStage.STRUCTURE,
            ),
          );
        } catch (err) {
          // Handle error
          const error = err as Error;
          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.ERROR,
              additionalProps: {
                errorType:
                  error.message === ERROR_VALIDATION_LIMIT
                    ? ErrorType.VALIDATION_LIMIT
                    : ErrorType.VALIDATION_FAILED,
              },
            }),
          );
        }
      } else {
        // Update stage status
        (
          coll.stages[
            Enums.DatasetStage.STRUCTURE
          ] as Interfaces.DatasetMetaStage
        ).status = Enums.StageStatus.IN_PROGRESS;
        dispatch(updateActiveDatasetStage(NEXT_STAGE));
      }
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.HeaderWrapper>
          <StyledH2>
            {coll?.stages?.[Enums.DatasetStage.CREATION]?.result?.total} Rows
            Found
          </StyledH2>
          <SC.HeaderResult>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={themeContext.colors.general.green}
            />
          </SC.HeaderResult>
        </SC.HeaderWrapper>

        <StyledText>
          Here are the first few rows of your data for you to review.
        </StyledText>
        <StyledText>
          Not happy? Click 'Back to the start' and try again.
        </StyledText>

        {sampleData && schema && (
          <DataSample
            sampleColumns={schema}
            sampleRows={sampleData}
            fixedHeightReduction={TABLE_HEIGHT}
          />
        )}
      </StageInner>

      <ActionBar
        text={
          isMerging || isReplacing
            ? `Let's validate`
            : `Let's structure your data`
        }
        primaryButton={
          <FeatureButton
            isDisabled={!datasetMeta?.name.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={
              isMerging || isReplacing
                ? 'Continue to validation'
                : 'Continue to structure'
            }
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              isReplacing || isMerging
                ? dispatch(
                    updateActiveDatasetSubStage(DatasetCreateSubStage.TYPE),
                  )
                : dispatch(updateActiveDatasetSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to the start'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ResultDatasetStage;
