import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faCog,
  faImage,
  faInfoCircle,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useTeam from '../../hooks/team/UseTeam';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import { RootState } from '../../store/rootReducer';
import {
  updateActiveTeamStage,
  updateActiveTeamSubStage,
} from '../../store/team-stage';
import {
  TeamConfigureSubStage,
  TeamCreationSubStage,
  TeamStage,
  TeamSubStages,
} from '../../store/team-stage/initial-state';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [TeamCreationSubStage.NAME]: faInfoCircle,
  [TeamCreationSubStage.AVATAR]: faImage,
  [TeamCreationSubStage.USERS]: faUsers,
};
const TeamItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { teamAccessLevel, team } = useTeam();
  const themeContext = useContext(ThemeContext);

  const canManage = teamAccessLevel === Enums.AccessLevel.MANAGE;

  const teamStageState = useSelector((state: RootState) => state.teamStage);
  const activeStage = teamStageState.activeStage;
  const activeSubStage = teamStageState.stages[activeStage].activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [TeamStage.CONFIGURATION]: true });

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`teams-back`}
        onClick={() => history.push(`${RouteName.TEAMS}`)}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Back To Teams'} />
      </BackNavigationItem>

      {!canManage && (
        <NavItem
          key={`team-stage-${TeamCreationSubStage.AVATAR}`}
          onClick={() => {
            dispatch(updateActiveTeamStage(TeamStage.CREATION));
            dispatch(updateActiveTeamSubStage(TeamCreationSubStage.AVATAR));
          }}
          isActive={activeSubStage === TeamCreationSubStage.AVATAR}
        >
          <FontAwesomeIcon
            icon={NavIcons[TeamCreationSubStage.AVATAR]}
            color={defaultTheme.colors.system.white}
          />

          <NavTitle
            text={
              teamStageState.stages[TeamStage.CREATION].subStages.find(
                (s) => s.subStage === TeamCreationSubStage.AVATAR,
              )?.title || ''
            }
          />
        </NavItem>
      )}

      {canManage &&
        Object.values(teamStageState.stages[TeamStage.CREATION].subStages).map(
          (subStage) => {
            const team = subStage.subStage as TeamSubStages;
            return (
              <NavItem
                key={`team-stage-${team}`}
                onClick={() => {
                  dispatch(updateActiveTeamStage(TeamStage.CREATION));
                  dispatch(updateActiveTeamSubStage(team));
                }}
                isActive={activeSubStage === team}
              >
                <FontAwesomeIcon
                  icon={NavIcons[team]}
                  color={defaultTheme.colors.system.white}
                />

                <NavTitle text={subStage.title} />
              </NavItem>
            );
          },
        )}

      {/* Configuration accordion nav */}
      {team?._id && canManage && (
        <NavigationAccordion>
          <div key={`${TeamStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={!accordionGroups[TeamStage.CONFIGURATION]}
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [TeamStage.CONFIGURATION]:
                    !accordionGroups[TeamStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle text={TeamStage.CONFIGURATION || 'Config'} />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[TeamStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={!accordionGroups[TeamStage.CONFIGURATION]}
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(updateActiveTeamStage(TeamStage.CONFIGURATION));
                    dispatch(
                      updateActiveTeamSubStage(TeamConfigureSubStage.ADVANCED),
                    );
                  }}
                  isActive={activeSubStage === TeamConfigureSubStage.ADVANCED}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={TeamConfigureSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default TeamItemNavigation;
