import { Enums } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ROW_NUMBER_HEADER } from '../../const/DataSample';
import { resetComments } from '../../store/comments';
import { RootState } from '../../store/rootReducer';
import useComment from './UseComment';

interface useCommentDataResult {
  commentsFetching?: boolean;
  reloadCommentData: () => void;
  loadNextPage: () => void;
}

const useCommentData = (): useCommentDataResult => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getComments } = useComment();
  const { datasetMetaId, chartId, aggregationId, portalId, cmsId, groupId } =
    useParams();

  const [commentsFetching, setCommentsFetching] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>();

  const commentsData = useSelector((state: RootState) => state.comments)?.data;

  const rowId =
    useMemo(
      () => new URLSearchParams(location.search).get(ROW_NUMBER_HEADER),
      [location.search],
    ) || undefined;

  const fetchComments = useCallback(
    async (
      resourceType: Enums.SchemaName,
      entityId: string,
      rowId: string | undefined,
      pagination?: number,
    ) => {
      setCommentsFetching(true);
      await getComments(
        resourceType,
        entityId,
        pagination || 1,
        rowId,
        portalId,
        cmsId,
        groupId,
      );
      setCommentsFetching(false);
    },
    [cmsId, getComments, groupId, portalId],
  );

  // Fetch comments for dataset
  useEffect(() => {
    const entityId = datasetMetaId || chartId || aggregationId;
    if (!entityId) {
      return;
    }

    // Reset comments state
    dispatch(resetComments());
    fetchComments(
      datasetMetaId
        ? Enums.SchemaName.DATASET_META
        : chartId
        ? Enums.SchemaName.CHART
        : Enums.SchemaName.QUERY,
      entityId,
      rowId,
    );
  }, [
    datasetMetaId,
    rowId,
    refresh,
    dispatch,
    fetchComments,
    chartId,
    aggregationId,
  ]);

  const reloadCommentData = () => {
    setRefresh(!refresh);
  };

  const loadNextPage = () => {
    const entityId = datasetMetaId || chartId || aggregationId;
    if (commentsFetching || !entityId || !commentsData.pagination.nextPageNum) {
      return;
    }

    fetchComments(
      datasetMetaId
        ? Enums.SchemaName.DATASET_META
        : chartId
        ? Enums.SchemaName.CHART
        : Enums.SchemaName.QUERY,
      entityId,
      rowId,
      commentsData.pagination.nextPageNum,
    );
  };

  return {
    commentsFetching,
    reloadCommentData,
    loadNextPage,
  };
};
export default useCommentData;
