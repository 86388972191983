import {
  faClipboard,
  faClipboardCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { DefaultPopupStyles, defaultTheme } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

interface CopyCodeBoxProps {
  text: string;
}

const CodeBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.system.offBlack};
  color: ${({ theme }) => theme.colors.system.white};

  border-radius: ${({ theme }) => theme.borders.radius};

  > span {
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;

// noinspection CssInvalidPropertyValue
const CodeBox = styled.pre`
  text-align: left;
  white-space: nowrap;
  font-family: monospace;
  padding: ${({ theme }) => theme.padding.xlarge};
  overflow-x: auto;
  margin: 0 ${({ theme }) => theme.margin.standard} 0 0;
`;

const CopyCodeBox: FC<CopyCodeBoxProps> = ({ text }) => {
  const themeContext = useContext(ThemeContext);

  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <CodeBoxWrapper>
      <CodeBox>{text}</CodeBox>
      <Popup
        open={isCopied}
        content={'Copied!'}
        position="top center"
        style={DefaultPopupStyles}
        trigger={
          <CopyToClipboard text={text} onCopy={onCopyText}>
            <span>
              <div>
                <FeatureButton
                  size={FeatureButtonSize.EXTRA_SMALL}
                  color={
                    isCopied
                      ? themeContext.colors.general.green
                      : themeContext.colors.general.blue
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={isCopied ? faClipboardCheck : faClipboard}
                      color={defaultTheme.colors.system.white}
                    />
                  }
                  action={() => null}
                />
              </div>
            </span>
          </CopyToClipboard>
        }
      />
    </CodeBoxWrapper>
  );
};

export default CopyCodeBox;
