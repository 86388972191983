import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SearchQuery } from '../../interfaces/Search';
import {
  IntegrationTemplateOutput,
  IntegrationTemplatesOutput,
} from '../../services/integration/IntegrationTemplateService';
import {
  createIntegrationTemplate,
  deleteIntegrationTemplate,
  fetchIntegrationTemplate,
  updateIntegrationTemplate,
} from '../../store/integration-template';
import {
  fetchIntegrationTemplates,
  fetchIntegrationTemplatesSuccess,
} from '../../store/integration-templates';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useIntegrationTemplateResult {
  integrationTemplate?: Interfaces.IntegrationTemplateOutput;
  integrationTemplateAccessLevel?: Enums.AccessLevel;
  integrationTemplateError?: string;

  getIntegrationTemplates: (
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    page?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<void>;
  getIntegrationTemplate: (integrationTemplateId: string) => void;
  addIntegrationTemplate: (
    integrationTemplate: Interfaces.IntegrationTemplate,
  ) => void;
  editIntegrationTemplate: (
    integrationTemplate: Interfaces.IntegrationTemplateOutput,
  ) => void;
  removeIntegrationTemplate: (integrationTemplateId: string) => void;
}

const useIntegrationTemplate = (): useIntegrationTemplateResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [integrationTemplate, setIntegrationTemplate] =
    useState<Interfaces.IntegrationTemplateOutput>();
  const [integrationTemplateAccessLevel, setIntegrationTemplateAccessLevel] =
    useState<Enums.AccessLevel>();
  const [integrationTemplateError, setIntegrationTemplateError] =
    useState<string>();

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplateState = useSelector(
    (state: RootState) => state.integrationTemplate,
  );
  const integrationTemplateObj =
    integrationTemplateState.data as IntegrationTemplateOutput;
  const integrationTemplatesObj =
    integrationTemplatesState.data as IntegrationTemplatesOutput;
  const integrationTemplateErr = integrationTemplatesState.error;

  const getIntegrationTemplates = useCallback(
    async (
      query?: Record<string, unknown>,
      searchQuery?: SearchQuery,
      page?: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          await dispatch(
            fetchIntegrationTemplates(
              token,
              query,
              searchQuery,
              page,
              limit,
              orderBy,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load integration templates.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getIntegrationTemplate = useCallback(
    async (integrationTemplateId: string) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplateId) {
        try {
          await dispatch(
            fetchIntegrationTemplate(token, integrationTemplateId),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load integration template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addIntegrationTemplate = useCallback(
    async (integrationTemplate: Interfaces.IntegrationTemplate) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplate && loggedInUser) {
        try {
          await dispatch(
            createIntegrationTemplate(
              token,
              integrationTemplate,
              loggedInUser._id,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to add integration template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editIntegrationTemplate = useCallback(
    async (integrationTemplate: Interfaces.IntegrationTemplateOutput) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplate && loggedInUser) {
        try {
          await dispatch(
            updateIntegrationTemplate(
              token,
              integrationTemplate,
              loggedInUser._id,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit integration template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeIntegrationTemplate = useCallback(
    async (integrationTemplateId: string) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplateId && loggedInUser) {
        try {
          await dispatch(
            deleteIntegrationTemplate(
              token,
              integrationTemplateId,
              loggedInUser._id,
            ),
          );

          // Remove project from current integration templates
          const cloned = cloneDeep(integrationTemplatesObj);
          cloned.data = cloned.data.filter(
            (e) => e.entity._id !== integrationTemplateId,
          );

          await dispatch(fetchIntegrationTemplatesSuccess(cloned));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to delete integration template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, integrationTemplatesObj],
  );

  useEffect(() => {
    if (integrationTemplateObj) {
      // Complete model
      setIntegrationTemplate(integrationTemplateObj.entity);
      setIntegrationTemplateAccessLevel(integrationTemplateObj.accessLevel);
    }
    if (integrationTemplateErr) {
      setIntegrationTemplateError(integrationTemplateErr);
    }
  }, [integrationTemplateErr, integrationTemplateObj]);

  return {
    integrationTemplate,
    integrationTemplateAccessLevel,
    integrationTemplateError,

    getIntegrationTemplates,
    getIntegrationTemplate,
    addIntegrationTemplate,
    editIntegrationTemplate,
    removeIntegrationTemplate,
  };
};

export default useIntegrationTemplate;
