import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const SortableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 650px;
  min-height: 38px;
  background: ${({ theme }) => theme.colors.general.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.small};
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => `${theme.margin.small}`};
  background: ${({ color, theme }) => color || theme.colors.system.grey};
  color: ${({ color, theme }) =>
    color ? theme.colors.system.white : 'inherit'};

  border-radius: ${({ theme }) => `${theme.borders.radius}`};
  border: 1px solid;
  border-color: ${({ color, theme }) => color || theme.colors.system.midGrey};
  cursor: pointer;
`;

export const TagText = styled.div`
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard}}`};
`;

export const TagIcon = styled(FontAwesomeIcon)`
  line-height: 1;
`;

export const TagButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  line-height: 1;
  padding: 0 ${({ theme }) => `${theme.padding.standard}`} 0 0;

  svg {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
  }
`;

export const Avatar = styled.img`
  max-width: 20px;
  margin: ${({ theme }) => theme.margin.small} 0
    ${({ theme }) => theme.margin.small} ${({ theme }) => theme.margin.small};
  width: auto;
  vertical-align: middle;
  display: inline-block;
`;
