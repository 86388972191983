import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FORMULA_TYPES } from '../../../const/FormulaConst';
import { PAGE_SIZE } from '../../../const/PaginationConst';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetCollection from '../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useInput from '../../../hooks/input/useInput';
import { DEFAULT_DATA_PAGE_SIZE } from '../../../hooks/pagination/UsePagination';
import validateValue from '../../../util/data-validator/DataValidator';
import { formatNumber } from '../../../util/format-number/format-number';
import { EditableCellProps } from '../EditableTableCell/EditableTableCell';

const ROW_NUMBER = 'row_id';

const LiveEditTableCell: FC<EditableCellProps> = ({
  value,
  row,
  column,
  editAction,
}) => {
  const { datasetMeta } = useDatasetMeta();
  const { collection } = useDatasetCollection();
  const { buildInput } = useInput();
  const { listPreFilters, getDynamicPreFilterValue } = useCMS();
  const { cmsId } = useParams();

  const pageSize = useMemo(
    () => new URLSearchParams(location.search).get(PAGE_SIZE),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  const [currentVal, setCurrentVal] = useState(value as unknown);
  const [preFilters, setPreFilters] = useState<Interfaces.DynamicConditional[]>(
    [],
  );

  const dataCollection =
    (collection?.collectionId &&
      datasetMeta?.dataCollections.find(
        (c) => c._id === collection.collectionId,
      )) ||
    datasetMeta?.dataCollections[0];
  const schemaData = dataCollection?.schemaData;

  const cellType = schemaData?.find((f) => f.name === column.id.toString());

  const [errorFields, setErrorFields] = useState<Record<string, boolean>>({});

  const handleBlur = (field: string, val: unknown) => {
    if (errorFields[column.id]) {
      setErrorFields({});
      setCurrentVal(value);
    } else {
      editAction(+(row.values[ROW_NUMBER] as string), column.id, val as string);
    }
  };

  const handleChange = useCallback(
    (field: string, val: unknown) => {
      const schemaField = field;
      const dataValidation = cellType?.dataValidation;

      if (schemaField) {
        const dataType = dataValidation?.dataValidationType;
        let isValid = true;
        if (dataType) {
          isValid = validateValue(dataType, val, dataValidation?.constraints);
        }
        const clonedErrors = cloneDeep(errorFields);

        if (isValid) {
          if (clonedErrors[field]) {
            delete clonedErrors[field];
            setErrorFields(clonedErrors);
          }
        } else {
          clonedErrors[field] = true;
          setErrorFields(clonedErrors);
        }
        setCurrentVal(val);
      }
    },
    [cellType, errorFields],
  );

  // If value is changed externally, sync it back up
  useEffect(() => {
    const type = cellType?.dataValidation?.dataValidationType;
    if (type === Enums.ValueDataType.NUMBER) {
      return setCurrentVal(formatNumber(value));
    }

    setCurrentVal(value);
  }, [cellType?.dataValidation?.dataValidationType, value]);

  // Get Prefilters
  useEffect(() => {
    if (datasetMeta?._id) {
      setPreFilters(listPreFilters(datasetMeta._id));
    }
  }, [datasetMeta?._id, listPreFilters]);

  if (cellType) {
    const type = cellType?.dataValidation?.dataValidationType;

    const preFilterValue = getDynamicPreFilterValue(
      preFilters,
      cellType.fieldId,
      true,
    );

    if (type) {
      return buildInput(
        cellType,
        type,
        currentVal,
        handleChange,
        errorFields[column.id],
        FORMULA_TYPES.includes(type) ||
          cellType.dataValidation?.permissions?.allowManualEdits === false,
        cmsId ? preFilterValue : undefined,
        true,
        handleBlur,
        row.index,
        pageSize ? +pageSize : DEFAULT_DATA_PAGE_SIZE,
      );
    }
  }
  return null;
};

export default LiveEditTableCell;
