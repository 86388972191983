import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NotificationService, {
  NotificationItem,
  NotificationItemOutput,
} from '../../services/notification/NotificationService';
import { AppThunk } from '../store';
import InitialNotification from './initial-notification';

type Notification = NotificationItem | NotificationItemOutput;

export interface ResourceDetails {
  _id: string;
  name: string;
  avatar?: string;
  description?: string;
  schema?: Interfaces.SchemaField[];
}

export interface NotificationEventWithDetails
  extends Interfaces.NotificationEvent {
  additionalDetails?: ResourceDetails;
}

export interface NotificationEventOutputWithDetails
  extends Interfaces.NotificationEventOutput {
  additionalDetails?: ResourceDetails;
}

export interface NotificationState {
  data: Notification;
  loading: boolean;
  error: string | null;
}

const initialState: NotificationState = {
  data: InitialNotification,
  loading: false,
  error: null,
};

const NotificationSlice = createSlice({
  name: 'notification-events',
  initialState,
  reducers: {
    // Reset
    resetNotification(state) {
      state.data = InitialNotification;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchNotificationStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchNotificationSuccess(state, action: PayloadAction<Notification>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchNotificationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateNotificationStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateNotificationSuccess(state, action: PayloadAction<Notification>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateNotificationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetNotification,
  fetchNotificationStart,
  fetchNotificationSuccess,
  fetchNotificationFailure,
  updateNotificationStart,
  updateNotificationSuccess,
  updateNotificationFailure,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

export const fetchNotification =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchNotificationStart());
      const fetched = await NotificationService.getNotification(token, id);
      dispatch(fetchNotificationSuccess(fetched));
    } catch (err) {
      dispatch(fetchNotificationFailure((err as string).toString()));
      throw err;
    }
  };

export const updateNotification =
  (
    token: string,
    notification: Interfaces.NotificationEventOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateNotificationStart());
      const updated = await NotificationService.putNotification(
        token,
        userId,
        notification,
      );
      dispatch(updateNotificationSuccess(updated));
    } catch (err) {
      dispatch(updateNotificationFailure((err as string).toString()));
    }
  };
