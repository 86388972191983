import { UserType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import useSiteWideBanner from '../../hooks/sitewide-banner/useSitewideBanner';
import PortalSystemActions from '../PortalSystemActions/PortalSystemActions';
import PortalUserSystemActions from '../PortalSystemActions/PortalUserSystemActions';
import PortalSystemShortcuts from '../PortalSystemShortcuts/PortalSystemShortcuts';
import PortalUserSystemShortcuts from '../PortalSystemShortcuts/PortalUserSystemShortcuts';
import SystemActions from '../SystemActions/SystemActions';
import SystemShortcuts from '../SystemShortcuts/SystemShortcuts';

const Wrapper = styled.div<{ isPortal?: boolean; showBanner?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 26;

  ${({ showBanner }) =>
    showBanner ? ` height: calc(100vh - 58px);` : `height: 100vh;`};

  padding: ${({ theme }) => theme.padding.standard};

  border-right: 1px solid ${({ theme }) => theme.colors.system.navy};

  background-color: ${({ theme }) => theme.colors.system.navy};
`;

interface SystemNavigationContainerProps {
  toggleNotificationPanel?: () => void;
}

const SystemNavigationContainer: FC<SystemNavigationContainerProps> = ({
  toggleNotificationPanel,
}) => {
  const location = useLocation();
  const { loggedInUser } = useLoggedInUser();
  const isPortal = location.pathname.includes(RouteName.PORTAL);
  const { isSitewideBanner } = useSiteWideBanner();

  if (isPortal) {
    return (
      <Wrapper isPortal={isPortal}>
        <PortalSystemShortcuts />
        <PortalSystemActions />
      </Wrapper>
    );
  }

  if (loggedInUser?.userType === UserType.PORTAL) {
    return (
      <Wrapper isPortal={isPortal}>
        <PortalUserSystemShortcuts />
        <PortalUserSystemActions />
      </Wrapper>
    );
  }

  return (
    <Wrapper showBanner={isSitewideBanner}>
      <SystemShortcuts />
      <SystemActions toggleNotificationPanel={toggleNotificationPanel} />
    </Wrapper>
  );
};

export default SystemNavigationContainer;
