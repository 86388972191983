import { DateTime } from 'luxon';
import { FC } from 'react';
import * as SC from './styled';
import { GanttFormattedTask } from '@configur-tech/upit-design-library';

const DAYS = 'days';
const DAY_DIFF_REGEX = /[.,]0$/;
const DURATION = 'duration';
const DAYS_FIXED = 1;

interface TooltipContentProps {
  task: GanttFormattedTask;
}

const TooltipContent: FC<TooltipContentProps> = ({ task }) => {
  const startDate = DateTime.fromJSDate(task.start);
  const endDate = DateTime.fromJSDate(task.end);

  return (
    <SC.TaskTooltip>
      <p>{task.name}</p>

      <SC.Status color={task.styles?.backgroundColor}>{task.status}</SC.Status>
      <SC.TaskTooltipDetails>
        <span>Start date: {task.startFormatted}</span>
        <span>End date: {task.endFormatted}</span>
        <span>
          {`${DURATION}: 
                ${endDate
                  .diff(startDate, [DAYS])
                  .days.toFixed(DAYS_FIXED)
                  .replace(DAY_DIFF_REGEX, '')}
                ${DAYS}`}
        </span>
      </SC.TaskTooltipDetails>
    </SC.TaskTooltip>
  );
};

export default TooltipContent;
