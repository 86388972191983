import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  GanttGraphParams,
  GraphMetric,
  MapGraphParams,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GraphOutputWithDetails, fetchGraphSuccess } from '../../store/graph';
import { hideLoading, showLoading } from '../../store/loading';
import { hideModal } from '../../store/modal';
import { QueryOutputWithDetails } from '../../store/queries';
import { RootState } from '../../store/rootReducer';
import {
  mapQueryFilters,
  renameGraphGanttFields,
  renameGraphMapFields,
  renameGraphXMetricField,
  renameQueryFields,
  renameQuerySupercolumns,
} from '../../util/data-structure/DataStructureUtils';
import useChart from '../chart/UseChart';
import useDataset from '../dataset/UseDataset';
import useFilter from '../filter/UseFilter';
import useGraph from '../graph/UseGraph';
import usePortal from '../portal/UsePortal';
import useQuery from '../query/UseQuery';

interface useAggregationResult {
  aggregation?: Interfaces.AggregationOutput;

  updateQueryColumnAlias: (
    query: QueryOutputWithDetails,
    updatedColumns: Interfaces.Field[],
    originalColumns: Interfaces.Field[],
  ) => Promise<QueryOutputWithDetails>;

  updateGraphColumnAlias: (
    graph: GraphOutputWithDetails,
    updatedColumns: Interfaces.Field[],
    originalColumns: Interfaces.Field[],
  ) => Promise<GraphOutputWithDetails>;
}

const useAggregation = (): useAggregationResult => {
  const dispatch = useDispatch();
  const { editQuery } = useQuery();
  const { chart, editChart } = useChart();
  const { graphAccessLevel } = useGraph();
  const { getAggregatedDataset, getGraphDataset } = useDataset();
  const { getDatasetFilters, replaceDatasetFilters } = useFilter();
  const { portal } = usePortal();

  const [aggregation, setAggregation] =
    useState<Interfaces.AggregationOutput>();
  const aggregationState = useSelector((state: RootState) => state.aggregation);
  const aggregationObj = aggregationState.data as Interfaces.AggregationOutput;

  const updateGraphColumnAlias = async (
    graph: GraphOutputWithDetails,
    updatedColumns: Interfaces.Field[],
    originalColumns: Interfaces.Field[],
  ) => {
    if (!chart?._id) {
      return;
    }

    dispatch(showLoading({ text: 'Saving Graph...' }));

    const clonedGraph = cloneDeep(graph);

    let savedFilters = getDatasetFilters(graph._id);

    originalColumns.map((c, i) => {
      const originalValue = c.name;
      const newValue = updatedColumns[i].name;

      // Update graph map fields
      if (graph.graphParams.graphType === Enums.GraphType.MAP) {
        clonedGraph.graphParams = renameGraphMapFields(
          originalValue,
          newValue,
          clonedGraph.graphParams as MapGraphParams,
        );
      }

      // Update graph gantt fields
      if (graph.graphParams.graphType === Enums.GraphType.GANTT) {
        clonedGraph.graphParams = renameGraphGanttFields(
          originalValue,
          newValue,
          clonedGraph.graphParams as GanttGraphParams,
        );
      }

      if (
        graph.graphParams.graphType !== Enums.GraphType.GANTT &&
        graph.graphParams.graphType !== Enums.GraphType.MAP
      ) {
        // Update graph metrics
        clonedGraph.graphParams.graphMetrics = renameQueryFields(
          originalValue,
          newValue,
          clonedGraph.graphParams.graphMetrics,
        );

        // Update graph xAxis metric
        clonedGraph.graphParams.xAxisMetric = renameGraphXMetricField(
          originalValue,
          newValue,
          clonedGraph.graphParams.xAxisMetric,
        );
      }

      // Update Fields
      clonedGraph.queryParams.fields = renameQueryFields(
        originalValue,
        newValue,
        clonedGraph.queryParams.fields as GraphMetric[],
      );

      // Update Filters
      // In query
      clonedGraph.queryParams.filters = mapQueryFilters(
        originalValue,
        newValue,
        clonedGraph.queryParams.filters,
      );

      // Update Groups
      clonedGraph.queryParams.groups = renameQueryFields(
        originalValue,
        newValue,
        clonedGraph.queryParams.groups,
      );

      // Update Measures
      clonedGraph.queryParams.measures = renameQueryFields(
        originalValue,
        newValue,
        clonedGraph.queryParams.measures,
      );

      // Update Sorts
      clonedGraph.queryParams.sort = renameQueryFields(
        originalValue,
        newValue,
        clonedGraph.queryParams.sort,
      );

      // Update DisplayOrder
      clonedGraph.queryParams.displayOrder = renameQueryFields(
        originalValue,
        newValue,
        clonedGraph.queryParams.displayOrder,
      );

      // Update Supercolumns
      clonedGraph.queryParams.supercolumns = renameQuerySupercolumns(
        originalValue,
        newValue,
        clonedGraph.queryParams.supercolumns,
      );

      // In state
      const mapStateAlias = (filter) => {
        const allFields = clonedGraph.queryParams.fields.concat(
          clonedGraph.queryParams.measures,
        );
        const updatedAlias = allFields.find(
          (f) => f.field === filter.field && f.alias === newValue,
        );

        const updatedFilter = {
          ...filter,
          value:
            !Array.isArray(filter.value) || !filter.value[0]?.operator
              ? filter.value
              : filter.value.map((v) => mapStateAlias(v)),
        };

        if (updatedAlias || filter.alias) {
          updatedFilter.alias = updatedAlias
            ? newValue?.trim()
            : updatedFilter.alias?.trim();
        }

        if (filter.column) {
          updatedFilter.column = {
            ...updatedFilter.column,
            alias: updatedAlias
              ? newValue?.trim()
              : updatedFilter.alias?.trim(),
          };
        }

        if (filter.fields) {
          updatedFilter.fields = updatedFilter.fields.map((field) => {
            const updatedAlias = clonedGraph.queryParams.fields.find(
              (f) => f.field === field.field && f.alias === newValue,
            );

            return {
              ...field,
              alias: updatedAlias ? newValue?.trim() : field.alias,
            };
          });
        }

        return updatedFilter;
      };

      savedFilters = savedFilters.map((f) => mapStateAlias(f));
    });

    replaceDatasetFilters(
      graph._id,
      (clonedGraph.queryParams.filters || []).map((f) => ({
        active: true,
        ...f,
      })),
    );

    // Save chart containing graph
    const clonedChart = cloneDeep(chart);
    const editingGraphIndex = clonedChart.graphs.findIndex(
      (g) => g._id === graph._id,
    );
    clonedChart.graphs[editingGraphIndex] = clonedGraph;
    await editChart(clonedChart);

    // Update Graph in store
    dispatch(
      fetchGraphSuccess({
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        entity: clonedGraph,
      }),
    );

    // Refetch graph dataset to update table column names
    await getGraphDataset(graph._id, [], undefined, undefined, true);

    dispatch(hideModal());
    dispatch(hideLoading());

    return clonedGraph;
  };

  const updateQueryColumnAlias = async (
    query: QueryOutputWithDetails,
    updatedColumns: Interfaces.Field[],
    originalColumns: Interfaces.Field[],
  ) => {
    dispatch(showLoading({ text: 'Saving Query...' }));

    const cloned = cloneDeep(query);

    let savedFilters = getDatasetFilters(cloned._id);

    originalColumns.map((c, i) => {
      const originalValue = c.name;
      const newValue = updatedColumns[i].name;

      // Update Fields
      cloned.queryParams.fields = renameQueryFields(
        originalValue,
        newValue,
        cloned.queryParams.fields,
      );

      // Update Filters
      // In query
      cloned.queryParams.filters = mapQueryFilters(
        originalValue,
        newValue,
        cloned.queryParams.filters,
      );

      // In state
      const mapStateAlias = (filter) => {
        const updatedAlias = cloned.queryParams.fields.find((f) => {
          return f.field === filter.field && f.alias === newValue;
        });

        return {
          ...filter,
          alias: updatedAlias ? newValue?.trim() : filter.alias?.trim(),
          column: {
            ...filter.column,
            alias: updatedAlias ? newValue?.trim() : filter.alias?.trim(),
          },
          value:
            !Array.isArray(filter.value) || !filter.value[0]?.operator
              ? filter.value
              : filter.value.map((v) => mapStateAlias(v)),
        };
      };

      savedFilters = savedFilters.map((f) => mapStateAlias(f));
      replaceDatasetFilters(cloned._id, savedFilters);

      // Update Groups
      cloned.queryParams.groups = renameQueryFields(
        originalValue,
        newValue,
        cloned.queryParams.groups,
      );

      // Update Measures
      cloned.queryParams.measures = renameQueryFields(
        originalValue,
        newValue,
        cloned.queryParams.measures,
      );

      // Update Sorts
      cloned.queryParams.sort = renameQueryFields(
        originalValue,
        newValue,
        cloned.queryParams.sort,
      );

      // Update DisplayOrder
      cloned.queryParams.displayOrder = renameQueryFields(
        originalValue,
        newValue,
        cloned.queryParams.displayOrder,
      );

      // Update Supercolumns
      cloned.queryParams.supercolumns = renameQuerySupercolumns(
        originalValue,
        newValue,
        cloned.queryParams.supercolumns,
      );
    });

    await editQuery(cloned);

    getAggregatedDataset(
      query._id,
      getDatasetFilters(query._id, true),
      undefined,
      portal?._id,
    );

    dispatch(hideModal());
    dispatch(hideLoading());

    return cloned;
  };

  useEffect(() => {
    if (aggregationObj) {
      // Complete model
      setAggregation(aggregationObj);
    }
  }, [aggregationObj]);

  return {
    aggregation,
    updateGraphColumnAlias,
    updateQueryColumnAlias,
  };
};

export default useAggregation;
