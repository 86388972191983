import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { GanttFormattedTask } from '@configur-tech/upit-design-library';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { RouteName } from '../../enums';
import useTableData from '../../hooks/dataset-meta/UseTableData';
import { GanttDataOutput } from '../../interfaces/Gantt';
import { SampleData } from '../../interfaces/SampleData';
import { defaultTheme } from '../../main/theme';
import { FormattedData } from '../../store/graph';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import DataSample from '../DataSample/DataSample';
import Gantt from './Gantt';
import GoogleMap, { GoogleMapData } from './GoogleMap';

export type FormattedGraphData = Record<string, FormattedData>;

interface GraphProps {
  graph: Interfaces.GraphOutput;
  data?: FormattedGraphData[];
  dataDrilldown?: (rowId: string) => void;
  dataDrilldownGantt?: (task: GanttFormattedTask) => void;
  schema?: Interfaces.SchemaField[];
  tableData?: SampleData;
}

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = 500;
const GRAPH_COLOR_MONOTONE = 'monotone';
const GRAPH_STACK_ID = 'a';
const GRAPH_X_LABEL_POSITION = 'insideBottom';
const GRAPH_Y_LABEL_POSITION = 'end';
const GRAPH_LEGEND_VERTICAL_ALIGN = 'top';
const GRAPH_LEGEND_HEIGHT = 36;
const GRAPH_STROKE_DASH_ARRAY = '3 3';
const GRAPH_LEGEND_TYPE = 'circle';
const GRAPH_MARGIN_CALC = 7;
const GRAPH_MARGIN_SMALL_OFFSET = 10;
const GRAPH_MARGIN_LARGE_OFFSET = 30;
const GRAPH_LABEL_MARGIN_OFFSET = 20;
const MAP_ADDITIONAL_MARKER_KEY = 'additionalMarkers';
const ROW_ID_FIELD = 'row_id';
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const LOCAL = 'local';

const CONTAINER_HEIGHT = '100%';
const CONTAINER_WIDTH = '100%';

const THEME_COLOUR_ARRAY = Object.keys(defaultTheme.colors.general)
  .filter((c) => c !== 'white')
  .map((k) => defaultTheme.colors.general[k]);

const Graph: FC<GraphProps> = ({
  graph,
  data = [],
  dataDrilldown,
  dataDrilldownGantt,
  schema,
  tableData,
}) => {
  const isDashboard = location.pathname.includes(RouteName.CHART_ITEM);
  const { createTableData } = useTableData();
  const [previewTableData, setPreviewTableData] = useState<SampleData>();

  const unformattedData = useMemo(
    () =>
      data.map((item) =>
        Object.keys(item).reduce((newItem, key) => {
          const field = schema?.find((f) => f.field === key);
          const val = item[key]?.unformatted ?? item[key];

          newItem[key] =
            (field?.formatting as Interfaces.DateFormatting)?.isLocalised && val
              ? DateTime.fromJSDate(new Date(val), {
                  zone: LOCAL,
                }).toFormat(field?.constraints?.format || DEFAULT_DATE_FORMAT)
              : val;

          return newItem;
        }, {}),
      ),
    [data, schema],
  );

  const formattedData = useMemo(
    () =>
      data.map((item) =>
        Object.keys(item).reduce((newItem, key) => {
          if (key === MAP_ADDITIONAL_MARKER_KEY) {
            newItem[key] = (item[key] as unknown as FormattedGraphData[]).map(
              (marker) =>
                Object.keys(marker).reduce((newMarker, key) => {
                  newMarker[key] = marker[key].formatted;
                  return newMarker;
                }, {}),
            );
          } else {
            const field = schema?.find((f) => f.field === key);
            const val = item[key]?.formatted ?? item[key];

            newItem[key] =
              (field?.formatting as Interfaces.DateFormatting)?.isLocalised &&
              val
                ? DateTime.fromJSDate(new Date(val), {
                    zone: LOCAL,
                  }).toFormat(field?.constraints?.format || DEFAULT_DATE_FORMAT)
                : val;
          }
          return newItem;
        }, {}),
      ),
    [data, schema],
  );

  useEffect(() => {
    (async () => {
      if (data && schema && !tableData) {
        const tData = createTableData(schema, data);

        setPreviewTableData(tData);
      }
    })();
  }, [createTableData, data, schema, tableData]);

  return useMemo(() => {
    if (graph.graphParams.graphType === Enums.GraphType.GANTT) {
      return (
        <Gantt
          graphData={data as unknown as GanttDataOutput[]}
          dataDrilldown={(task: GanttFormattedTask) =>
            dataDrilldownGantt && dataDrilldownGantt(task)
          }
        />
      );
    }

    if (graph?.graphParams.graphType === Enums.GraphType.MAP) {
      const markerColourValues = schema?.find(
        (field) => field.constraints?.listValues,
      )?.constraints?.listValues;

      return (
        <ResponsiveContainer width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT}>
          <GoogleMap
            data={formattedData as unknown as [GoogleMapData]}
            dataDrilldown={(rowId: string) =>
              dataDrilldown && dataDrilldown(rowId)
            }
            radius={graph.graphParams.radiusMeters}
            markerColour={graph.graphParams.markerColourMetric}
            markerColourValues={markerColourValues as Interfaces.ListValue[]}
          />
        </ResponsiveContainer>
      );
    }

    let leftMargin = 0;
    const graphParams = graph?.graphParams as Interfaces.GraphParams;
    const metricNames = graphParams?.graphMetrics?.map((m) => m.alias);

    unformattedData?.map((value: Record<string, unknown>) => {
      metricNames?.map((metric) => {
        if (
          value[metric] &&
          (value[metric] as number).toString().length * GRAPH_MARGIN_CALC >
            leftMargin
        ) {
          leftMargin =
            (value[metric] as number).toString().length * GRAPH_MARGIN_CALC;
        }
      });
    });

    const graphMargin = {
      top: GRAPH_MARGIN_SMALL_OFFSET,
      right: GRAPH_MARGIN_SMALL_OFFSET,
      left: graphParams.graphTextOpts?.yAxisLabel
        ? leftMargin
        : leftMargin - GRAPH_MARGIN_LARGE_OFFSET,
      bottom: graphParams.graphTextOpts?.xAxisLabel
        ? GRAPH_MARGIN_LARGE_OFFSET
        : 0,
    };

    const getFormattedData = (value, name, payload) => {
      const dataIndex = unformattedData.findIndex((data) => data === payload);

      if (
        graph.graphParams.graphType === Enums.GraphType.PIE_CHART ||
        graph.graphParams.graphType === Enums.GraphType.DOUGHNUT_CHART
      ) {
        const pieMetric = graphParams.graphMetrics.filter((g) => g.active)[0]
          .alias;

        return (
          (formattedData[dataIndex] && formattedData[dataIndex][pieMetric]) ||
          value
        );
      }

      const formattedVal = formattedData[dataIndex][name];
      return [formattedVal || value, name];
    };

    const graphContent = (
      <>
        <CartesianGrid strokeDasharray={GRAPH_STROKE_DASH_ARRAY} />
        {graphParams?.graphTextOpts?.showLegend && (
          <Legend
            verticalAlign={GRAPH_LEGEND_VERTICAL_ALIGN}
            height={GRAPH_LEGEND_HEIGHT}
          />
        )}
        <Tooltip
          cursor={{ fill: defaultTheme.colors.system.grey }}
          formatter={(value, name, { payload }) =>
            getFormattedData(value, name, payload)
          }
        />
        <XAxis dataKey={graphParams?.xAxisMetric?.field?.alias}>
          {graphParams?.graphTextOpts?.xAxisLabel && (
            <Label
              value={graphParams.graphTextOpts?.xAxisLabel}
              offset={-20}
              position={GRAPH_X_LABEL_POSITION}
            />
          )}
        </XAxis>
        <YAxis type="number">
          {graphParams?.graphTextOpts?.yAxisLabel && (
            <Label
              value={graphParams?.graphTextOpts?.yAxisLabel}
              angle={-90}
              position={GRAPH_Y_LABEL_POSITION}
              dx={-leftMargin - GRAPH_LABEL_MARGIN_OFFSET}
            />
          )}
        </YAxis>
      </>
    );

    switch (graph?.graphParams?.graphType) {
      case Enums.GraphType.SIMPLE_LINE:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <LineChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              data={unformattedData}
              margin={graphMargin}
            >
              {graphContent}
              {graphParams.graphMetrics
                .filter((g) => g.active)
                .map((metric, index) => {
                  return (
                    <Line
                      key={`metric-${metric.alias}-${index}`}
                      type={GRAPH_COLOR_MONOTONE}
                      dataKey={metric.alias}
                      stroke={metric.colour}
                      legendType={GRAPH_LEGEND_TYPE}
                    />
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.SIMPLE_AREA:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <AreaChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              data={unformattedData}
              margin={graphMargin}
            >
              {graphContent}
              {graphParams.graphMetrics
                .filter((g) => g.active)
                .map((metric, index) => {
                  return (
                    <Area
                      key={`metric-${metric.alias}-${index}`}
                      type={GRAPH_COLOR_MONOTONE}
                      dataKey={`${metric.alias}`}
                      stroke={metric.colour}
                      fill={metric.colour}
                      fillOpacity={0.8}
                      legendType={GRAPH_LEGEND_TYPE}
                    />
                  );
                })}
            </AreaChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.SIMPLE_BAR:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <BarChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              data={unformattedData}
              margin={graphMargin}
            >
              {graphContent}
              {graphParams.graphMetrics
                .filter((g) => g.active)
                .map((metric, index) => {
                  return (
                    <Bar
                      key={`metric-${metric.alias}-${index}`}
                      dataKey={`${metric.alias}`}
                      fill={metric.colour}
                      legendType={GRAPH_LEGEND_TYPE}
                    />
                  );
                })}
            </BarChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.STACKED_BAR:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <BarChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              data={unformattedData}
              margin={graphMargin}
            >
              {graphContent}
              {graphParams.graphMetrics
                .filter((g) => g.active)
                .map((metric, index) => {
                  return (
                    <Bar
                      key={`metric-${metric.alias}-${index}`}
                      dataKey={`${metric.alias}`}
                      stackId={GRAPH_STACK_ID}
                      fill={metric.colour}
                      legendType={GRAPH_LEGEND_TYPE}
                    />
                  );
                })}
            </BarChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.SIMPLE_SCATTER:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <ScatterChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              data={unformattedData}
              margin={graphMargin}
            >
              {graphContent}
              {graphParams.graphMetrics
                .filter((g) => g.active)
                .map((metric, index) => {
                  return (
                    <Scatter
                      key={`metric-${metric.alias}-${index}`}
                      dataKey={metric.alias}
                      fill={metric.colour}
                      legendType={GRAPH_LEGEND_TYPE}
                    />
                  );
                })}
            </ScatterChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.PIE_CHART:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <PieChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              margin={graphMargin}
            >
              {graph?.graphParams.graphTextOpts?.showLegend && (
                <Legend
                  verticalAlign={GRAPH_LEGEND_VERTICAL_ALIGN}
                  height={GRAPH_LEGEND_HEIGHT}
                />
              )}
              <Tooltip
                formatter={(val, name, { payload }) =>
                  getFormattedData(val, name, payload.payload)
                }
              />
              <Pie
                key={`metric-${graphParams.graphMetrics[0].alias}`}
                data={unformattedData}
                dataKey={
                  graphParams.graphMetrics.filter((g) => g.active).length &&
                  unformattedData.length
                    ? Object.keys(unformattedData[0]).filter(
                        (k) => k === graphParams?.graphMetrics[0]?.alias,
                      )[0]
                    : ''
                }
                nameKey={graphParams?.xAxisMetric?.field?.alias}
                legendType={GRAPH_LEGEND_TYPE}
              >
                {unformattedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={THEME_COLOUR_ARRAY[index % THEME_COLOUR_ARRAY.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.DOUGHNUT_CHART:
        return (
          <ResponsiveContainer
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
          >
            <PieChart
              width={GRAPH_WIDTH}
              height={GRAPH_HEIGHT}
              margin={graphMargin}
            >
              {graph?.graphParams.graphTextOpts?.showLegend && (
                <Legend
                  verticalAlign={GRAPH_LEGEND_VERTICAL_ALIGN}
                  height={GRAPH_LEGEND_HEIGHT}
                />
              )}
              <Tooltip
                formatter={(val, name, { payload }) =>
                  getFormattedData(val, name, payload.payload)
                }
              />
              <Pie
                key={`metric-${graphParams.graphMetrics[0].alias}`}
                innerRadius={'55%'}
                outerRadius={'80%'}
                paddingAngle={5}
                data={unformattedData}
                dataKey={
                  graphParams.graphMetrics.filter((g) => g.active).length &&
                  unformattedData.length
                    ? Object.keys(unformattedData[0]).filter(
                        (k) => k === graphParams?.graphMetrics[0]?.alias,
                      )[0]
                    : ''
                }
                nameKey={graphParams?.xAxisMetric?.field?.alias}
                legendType={GRAPH_LEGEND_TYPE}
              >
                {unformattedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={THEME_COLOUR_ARRAY[index % THEME_COLOUR_ARRAY.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        );
      case Enums.GraphType.TABLE:
        return dataDrilldown && isDashboard ? (
          <DataSample
            schema={schema}
            sampleColumns={tableData?.schema || previewTableData?.schema || []}
            sampleRows={tableData?.rows || previewTableData?.rows || []}
            fullWidth={true}
            iconMap={{ ...UserIconMap, ...AvatarIconMap }}
            pageSize={
              process.env['REACT_APP_GRAPH_PAGE_SIZE']
                ? parseInt(process.env['REACT_APP_GRAPH_PAGE_SIZE'])
                : undefined
            }
            clickableRows={{
              valueField: ROW_ID_FIELD,
              action: (rowId: string) => dataDrilldown && dataDrilldown(rowId),
            }}
          ></DataSample>
        ) : (
          <DataSample
            schema={schema}
            sampleColumns={tableData?.schema || previewTableData?.schema || []}
            sampleRows={tableData?.rows || previewTableData?.rows || []}
            fullWidth={true}
            iconMap={{ ...UserIconMap, ...AvatarIconMap }}
            pageSize={
              process.env['REACT_APP_GRAPH_PAGE_SIZE']
                ? parseInt(process.env['REACT_APP_GRAPH_PAGE_SIZE'])
                : undefined
            }
          ></DataSample>
        );
      default:
        return null;
    }
  }, [
    data,
    dataDrilldown,
    dataDrilldownGantt,
    formattedData,
    graph.graphParams,
    isDashboard,
    previewTableData?.rows,
    previewTableData?.schema,
    schema,
    tableData?.rows,
    tableData?.schema,
    unformattedData,
  ]);
};

export default Graph;
