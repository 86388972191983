import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { StyledBodySubHeader, StyledInput, StyledText } from '../../main/theme';
import * as SC from './styled';

const SEARCH_TYPING_TIMEOUT = 1000;

interface EntityFilterProps {
  baseRoute: string;
  title: string;
  inputPlaceholder: string;
  totalResultCount: number;
  filteredResultCount: number;
  onSearchValueChange?: (value: string) => void;
  loading?: boolean;
  search?: string;
}

const EntityFilter: FC<EntityFilterProps> = ({
  baseRoute,
  title,
  inputPlaceholder,
  onSearchValueChange,
  totalResultCount,
  filteredResultCount,
  loading,
  search,
}) => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState<string>('');

  // Set searchValue
  useEffect(() => {
    setSearchValue(search || '');
  }, [search]);

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Handling both cases whilst we migrate all pages across
      if (!onSearchValueChange) {
        history.push({
          pathname: baseRoute,
          search: !searchValue ? '' : `search=${searchValue}`,
        });
      } else {
        onSearchValueChange(searchValue);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, onSearchValueChange, history, baseRoute]);

  return (
    <SC.FilterWrapper>
      <StyledBodySubHeader>{title}</StyledBodySubHeader>

      <SC.ColumnWrapper>
        <SC.ResultsWrapper>
          <StyledText>
            Showing <strong>{filteredResultCount || 0}</strong> of{' '}
            <strong>{totalResultCount || 0}</strong> results
          </StyledText>
        </SC.ResultsWrapper>

        <SC.InputWrapper>
          <StyledInput
            loading={loading}
            placeholder={inputPlaceholder}
            value={searchValue}
            onChange={(event, data) => setSearchValue(data.value)}
            iconPosition={'left'}
            icon={<Icon name={'search' as SemanticICONS} />}
          />
        </SC.InputWrapper>

        <SC.ViewWrapper />
      </SC.ColumnWrapper>
    </SC.FilterWrapper>
  );
};

export default EntityFilter;
