import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

export interface GetFileUploadUrlResponse {
  uploadURL: string;
  uploadId: string;
}

export interface CreateFileDocumentResponse {
  fileId: string;
  file: Interfaces.FileOutput;
}

export interface FileItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.FileOutput;
}

export interface FileItemsOutput {
  data: FileItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class FileService {
  public static async getFiles(
    token: string,
    filePurpose: Enums.FilePurpose,
  ): Promise<Promise<FileItemsOutput> | Interfaces.ErrorResponseBody> {
    const endpoint = process.env['REACT_APP_CRUD_FILE'] || '';

    if (!endpoint) {
      throw Error('No endpoint found');
    }

    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<FileItemsOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { filePurpose });

    return result?.data as FileItemsOutput;
  }

  public static async getFileUploadUrl(
    token: string,
    fileName: string,
    mimeType: string,
    organisationId: string,
    userId: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<GetFileUploadUrlResponse>
    | Interfaces.ErrorResponseBody
  > {
    const endpoint = process.env['REACT_APP_FILE_UPLOAD_URL'] || '';

    if (!endpoint) {
      throw Error('No endpoint found');
    }

    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<GetFileUploadUrlResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, { fileName, mimeType, organisationId, userId });
  }

  public static async uploadFileToS3(url: string, file: File): Promise<void> {
    const ah = new HttpHelper();

    return await ah.put<void>(url, file, false);
  }

  public static async createFileDocument(
    token: string,
    fileName: string,
    mimeType: string,
    uploadId: string,
    organisationId: string,
    userId: string,
    sheetName?: string,
    filePurpose?: Enums.FilePurpose,
  ): Promise<
    | Interfaces.SuccessResponseBody<CreateFileDocumentResponse>
    | Interfaces.ErrorResponseBody
  > {
    const endpoint = process.env['REACT_APP_FILE_HANDLER'] || '';

    if (!endpoint) {
      throw Error('No endpoint found');
    }

    const ah = new HttpHelper(token);

    return await ah.post<
      | Interfaces.SuccessResponseBody<CreateFileDocumentResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      fileName,
      mimeType,
      uploadId,
      organisationId,
      userId,
      sheetName,
      filePurpose,
    });
  }
}
