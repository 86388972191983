import React, { FC, ReactElement, useContext, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import PopupIconSelector from '../../components//IconSelector/PopupIconSelector';
import { EntityType } from '../../enums/EntityType';
import { StyledH2, StyledH3 } from '../../main/theme';
import FeatureButton from '../FeatureButton/FeatureButton';
import StageImage from '../StageImage/StageImage';
import SubStageMarkers, { SubStages } from '../SubStageMarkers/SubStageMarkers';
import * as SC from './styled';

interface HeaderProps {
  pageHeader: string;
  subStages?: SubStages;
  title?: string | ReactElement;
  content?: ReactElement;
  image?: string;
  wideImage?: boolean;
  hideAvatarPopup?: boolean;
  avatarPopupEntityType?: EntityType;
  avatarPopupValue?: string;
  avatarPopupOnChange?: (icon) => void;
  avatarPopupLoading?: boolean;
}

const PageFeatureHeader: FC<HeaderProps> = ({
  pageHeader,
  subStages,
  title,
  content,
  image,
  wideImage,
  hideAvatarPopup,
  avatarPopupEntityType,
  avatarPopupValue,
  avatarPopupOnChange,
  avatarPopupLoading,
}) => {
  const themeContext = useContext(ThemeContext);

  const avatarRef = useRef<HTMLInputElement>(null);
  const [avatarButtonMouseOver, setAvatarButtonMouseOver] =
    useState<boolean>(false);
  const [avatarPopupOpen, setAvatarPopupOpen] = useState<boolean>(false);

  return (
    <SC.Wrapper minimal={!image && !title}>
      <SC.HeaderRow>
        <div>
          {image && !avatarPopupEntityType && !avatarPopupOnChange && (
            <StageImage image={image} wideImage={wideImage} />
          )}
          <div>
            <StyledH3>{pageHeader}</StyledH3>
            {title && typeof title === 'string' && <StyledH2>{title}</StyledH2>}
          </div>
        </div>

        {subStages && <SubStageMarkers subStages={subStages} />}
      </SC.HeaderRow>

      {!hideAvatarPopup && avatarPopupEntityType && avatarPopupOnChange && (
        <>
          <PopupIconSelector
            context={avatarRef}
            open={avatarPopupOpen}
            setOpenStatus={setAvatarPopupOpen}
            entityType={EntityType.PROJECT}
            action={(icon) => avatarPopupOnChange(icon)}
            selectedIcon={avatarPopupValue}
          />

          <div
            ref={avatarRef}
            onMouseEnter={() => {
              if (!avatarPopupOpen && !avatarPopupLoading) {
                setAvatarButtonMouseOver(true);
              }
            }}
            onMouseLeave={() => {
              setAvatarButtonMouseOver(false);
            }}
          >
            <FeatureButton
              isActive={avatarPopupOpen}
              isDisabled={avatarPopupLoading}
              action={() => setAvatarPopupOpen(true)}
              color={
                avatarButtonMouseOver
                  ? themeContext.colors.general.blue
                  : themeContext.colors.system.white
              }
              image={!avatarButtonMouseOver ? image : undefined}
              text={avatarButtonMouseOver ? 'Edit Avatar' : undefined}
              size={100}
              faceStyle={
                image?.includes('http')
                  ? {
                      backgroundSize: 'contain',
                    }
                  : {}
              }
            />
          </div>
        </>
      )}

      {title && typeof title !== 'string' && title}
      {content}
    </SC.Wrapper>
  );
};

export default PageFeatureHeader;
