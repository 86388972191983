import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import useGraph from '../../../../hooks/graph/UseGraph';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { fetchGraphSuccess } from '../../../../store/graph';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectGraphSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import StateManagedInput from '../../../StateManagedInput/StateManagedInput';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectGraphSubStage.DATA;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemAggregationNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { graph, graphAccessLevel } = useGraph();

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(graph);
    cloned[field] = val;

    dispatch(
      fetchGraphSuccess({
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Graph Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new graph.
          </StageBodyText>
          {graph && (
            <StateManagedInput
              placeholder={'Enter your graph name'}
              initialValue={graph.name || ''}
              onChange={(value) => handleChange(NAME_FIELD, value)}
              style={{ marginBottom: themeContext.margin.xxlarge }}
            />
          )}
          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this graph?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={graph?.description || ''}
            onChange={(event) =>
              handleChange(DESCRIPTION_FIELD, event.target.value)
            }
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!graph?.name.length}
            action={() => dispatch(updateActiveProjectSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Data'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAggregationNameStage;
