import { Enums } from '@configur-tech/upit-core-types';
import { DatasetMetaItem } from '../../services/dataset-meta/DatasetMetaService';

const InitialDatasetMeta: DatasetMetaItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    name: '',
    organisationId: '',
    avatar: '',
    tags: [],
  },
};

export default InitialDatasetMeta;
