import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import {
  resetDataviewStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectConnectionCMSSubStage } from '../../../../store/project-stage/initial-state';
import { RootState } from '../../../../store/rootReducer';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const PREV_STAGE = ProjectConnectionCMSSubStage.DATA;

const ProjectItemCMSAccessStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, editProject } = useProject();

  const cmsState = useSelector((state: RootState) => state.cms);
  const cmsData = cmsState.data as Interfaces.ConnectionOutput;

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update cms
      const cloned: Interfaces.ConnectionOutput = cloneDeep(cmsData);

      cloned.access[type][group] = updated;

      // Remove if present in another access type
      Object.entries(cloned.access).forEach(([cType, cAccess]) => {
        if (cType !== type) {
          cAccess[group] = cAccess[group].filter((e) => !updated.includes(e));
        }
      });

      // Save CMS
      dispatch(fetchCMSSuccess(cloned));
    }
  };

  const processAction = async (finished?: boolean) => {
    if (project && cmsData) {
      dispatch(showLoading({ text: 'Updating Workspace...' }));

      const clonedProject = cloneDeep(project);
      clonedProject.connections[Enums.ConnectionType.CMS] =
        clonedProject.connections[Enums.ConnectionType.CMS].map((c) => {
          if (c._id === (cmsData as Interfaces.ConnectionOutput)._id) {
            return cmsData;
          }

          return c;
        });

      await editProject(clonedProject);

      dispatch(hideLoading());

      if (finished) {
        dispatch(resetDataviewStage());
      }
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Set Permissions</StyledH2>
        <StageBodyText>
          Add users, teams or the whole organisation to the fields below to
          control who can access your Workspace and what permissions they have.
        </StageBodyText>

        <AccessSelection
          access={cmsData?.access}
          onChange={handleChange}
          hideTypes={[AccessType.MANAGE]}
        />
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            action={() => processAction(true)}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save & Finish'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to connections'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemCMSAccessStage;
