import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ModalTypes } from '../../components/Modal/Modal';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import useUser from '../../hooks/user/UseUser';
import PageContainer from '../../main/PageContainer';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import { UserStage } from '../../store/user-stage/initial-state';
import UserConfiguration from './UserConfiguration';
import UserCreation from './UserCreation';

const UserItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { user, getUser, userError } = useUser();

  const userStageState = useSelector((state: RootState) => state.userStage);

  const activeStage = userStageState.activeStage;

  // Get an existing user if requested
  useEffect(() => {
    if (params?.id && params?.id !== user?._id) {
      (async () => {
        dispatch(showLoading({ text: 'Loading User...' }));
        await getUser(params.id);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getUser, params.id, user?._id]);

  // Error fetching User
  useEffect(() => {
    if (userError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'User Not Found',
            errorText:
              'This user could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Users',
            errorActionRedirectRoute: RouteName.USERS,
          },
        }),
      );
    }
  }, [userError, dispatch]);

  const getStageComponent = () => {
    switch (activeStage) {
      case UserStage.CREATION:
        return <UserCreation />;
      case UserStage.CONFIGURATION:
        return <UserConfiguration />;
      default:
        return <UserCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.USER_ITEM} />
      <PageContainer>{getStageComponent()}</PageContainer>
    </>
  );
};

export default UserItemPage;
