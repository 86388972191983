import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import {
  StageBodyText,
  StyledBodySubHeader,
  StyledDropdown,
} from '../../main/theme';

interface DataExportProps {
  exportFormat: Enums.AcceptedFileType;
  setExportFormat: (data) => void;
  applyFormatting: boolean;
  setApplyFormatting: (data) => void;
  bodyText: string;
}

const EXPORT_FORMAT_OPTIONS = [
  {
    key: 'format-csv',
    value: Enums.AcceptedFileType.CSV,
    text: 'CSV',
  },
  {
    key: 'format-xlsx',
    value: Enums.AcceptedFileType.XLSX,
    text: 'XLSX',
  },
];

const APPLY_FORMATTING_OPTIONS = [
  {
    key: 'yes',
    value: true,
    text: 'Yes, apply formatting',
  },
  {
    key: 'no',
    value: false,
    text: 'No, export raw data',
  },
];

const DataExport: FC<DataExportProps> = ({
  exportFormat,
  setExportFormat,
  applyFormatting,
  setApplyFormatting,
  bodyText,
}) => {
  return (
    <>
      <StageBodyText>{bodyText}</StageBodyText>

      <StyledBodySubHeader>Export Format</StyledBodySubHeader>

      <StyledDropdown
        selectOnBlur={false}
        placeholder={'Select an export format'}
        options={EXPORT_FORMAT_OPTIONS}
        selection
        value={exportFormat}
        onChange={(e, data) => setExportFormat(data.value)}
      />

      <StyledBodySubHeader>Formatting</StyledBodySubHeader>

      <StageBodyText>
        Would you like to apply formatting to the exported data?
      </StageBodyText>

      <StyledDropdown
        selectOnBlur={false}
        placeholder={'Select if you want to apply formatting'}
        options={APPLY_FORMATTING_OPTIONS}
        selection
        value={applyFormatting}
        onChange={(e, data) => setApplyFormatting(data.value)}
      />
    </>
  );
};

export default DataExport;
