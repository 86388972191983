import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import { StyledH1 } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { ProjectsItemOutput } from '../../services/project/ProjectService';
import { RootState } from '../../store/rootReducer';
import Flowchart from '../FlowChart/Flowchart';

interface WelcomeProps {
  title?: string | ReactElement;
  hideCheckList?: boolean;
}

export interface ChecklistItem {
  content: string;
  isChecked: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.darkBlueHighlight};

  padding: ${({ theme }) => `${theme.padding.xxxlarge} 0`};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};

  > h1 {
    color: ${({ theme }) => theme.colors.system.white};
    margin-bottom: ${({ theme }) => theme.margin.xlarge};
  }
`;

const ChecklistWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const WelcomeHeader: FC<WelcomeProps> = ({ title, hideCheckList }) => {
  const { loggedInUser } = useLoggedInUser();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const projectsState = useSelector((state: RootState) => state.projects);
  const projects: ProjectsItemOutput = projectsState.data;

  const isAnyDatasetMetasCreatedBy = datasetMetas.some(
    (item) => item.entity.meta?.createdBy === loggedInUser?._id,
  );
  const projectsCreatedBy = projects?.data?.filter(
    (item) => item.entity.meta?.createdBy === loggedInUser?._id,
  );
  const hasAnyAggregations = projectsCreatedBy?.some(
    (project) =>
      project.entity.queries?.length && project.entity.queries?.length > 0,
  );

  const hasAnyConnections = projectsCreatedBy?.some(
    (project) =>
      project.entity.connections &&
      Object.values(project.entity.connections)?.some((arr) => arr?.length),
  );

  const checklistItems: ChecklistItem[] = [
    {
      content: 'Create Dataset',
      isChecked: isAnyDatasetMetasCreatedBy,
    },
    {
      content: 'Create Project',
      isChecked: !!projectsCreatedBy?.length,
    },
    {
      content: 'Create Query',
      isChecked: isAnyDatasetMetasCreatedBy && hasAnyAggregations,
    },
    {
      content: 'Create Connection',
      isChecked: isAnyDatasetMetasCreatedBy && hasAnyConnections,
    },
  ];

  return (
    <Wrapper>
      <StyledH1>{title}</StyledH1>

      {!hideCheckList && checklistItems && (
        <ChecklistWrapper>
          <Flowchart items={checklistItems} />
        </ChecklistWrapper>
      )}
    </Wrapper>
  );
};

export default WelcomeHeader;
