import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { StyledBodySubHeader } from '../../main/theme';

interface ItemProps {
  isStart: boolean;
  isEnd: boolean;
  isComplete: boolean;
  isNextItemComplete: boolean;
  areAllPredecessorsComplete;
  message?: string;
}

const StyledIcon = styled(({ ...props }) => {
  delete props.isStart;
  delete props.isEnd;
  delete props.isGreen;
  return <FontAwesomeIcon {...props} />;
})`
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ isStart }) => isStart && `margin-left: calc(50% - 25px);`};
  ${({ isEnd }) => isEnd && `margin-right: calc(50% - 25px);`};
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  color: ${({ isGreen, theme }) =>
    isGreen ? theme.colors.general.green : theme.colors.system.grey};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  > p {
    margin-top: ${({ theme }) => theme.margin.standard};
    align-self: center;
    color: ${({ theme }) => theme.colors.system.white};
  }
`;

const DividingLine = styled.hr<{ isGreen: boolean }>`
  background-color: ${({ isGreen, theme }) =>
    isGreen ? theme.colors.general.green : theme.colors.system.grey};
  height: 5px;
  width: 100%;
  border: none;
`;

const FlowchartItem = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item: FC<ItemProps> = ({
  isStart,
  isEnd,
  isComplete,
  isNextItemComplete,
  message,
  areAllPredecessorsComplete,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <ItemWrapper>
      <FlowchartItem>
        {!isStart && (
          <DividingLine isGreen={isComplete && areAllPredecessorsComplete} />
        )}
        <StyledIcon
          isGreen={isComplete}
          isStart={isStart}
          isEnd={isEnd}
          icon={faCheckCircle}
          color={themeContext.colors.system.white}
          size={'3x'}
        />
        {!isEnd && (
          <DividingLine
            isGreen={
              isComplete && isNextItemComplete && areAllPredecessorsComplete
            }
          />
        )}
      </FlowchartItem>
      <StyledBodySubHeader>{message}</StyledBodySubHeader>
    </ItemWrapper>
  );
};
export default Item;
