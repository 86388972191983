import React, { FC } from 'react';
import { Popup } from 'semantic-ui-react';
import { DefaultPopupStyles, StyledText } from '../../../main/theme';
import { Cell } from '../../DataSample/DataSample';
import * as SC from './styled';
import TagComponent from './TagComponent';

const TAG_DISPLAY_LIMIT = 3;

interface TagTableCellProps extends Cell {
  tags: string;
  activeTagValue?: string;
  route: string;
}

const TagTableCell: FC<TagTableCellProps> = ({
  tags,
  activeTagValue,
  route,
}) => {
  if (!tags?.length) {
    return null;
  }

  const tagSplit: string[] = tags?.split(',');

  return (
    <Popup
      disabled={tagSplit.length < TAG_DISPLAY_LIMIT}
      hoverable
      content={
        <SC.TagWrapper>
          {tagSplit
            .sort((tag) => (tag === activeTagValue ? -1 : 1))
            .map((tag: string, i) => (
              <TagComponent
                route={route}
                tag={tag}
                key={`${tag}-${i}`}
                active={tag === activeTagValue}
              />
            ))}
        </SC.TagWrapper>
      }
      position="right center"
      style={DefaultPopupStyles}
      hideOnScroll={true}
      trigger={
        <SC.TagWrapper>
          {[...tagSplit]
            .sort((tag) => (tag === activeTagValue ? -1 : 1))
            .splice(0, TAG_DISPLAY_LIMIT)
            .map((tag: string, i) => (
              <TagComponent
                route={route}
                tag={tag}
                key={`${tag}-${i}`}
                active={tag === activeTagValue}
              />
            ))}

          {tagSplit.length > TAG_DISPLAY_LIMIT && (
            <StyledText>
              + {tagSplit.length - TAG_DISPLAY_LIMIT} more
            </StyledText>
          )}
        </SC.TagWrapper>
      }
    />
  );
};

export default TagTableCell;
