import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserItemOutput } from '../../services/user/UserService';
import { fetchLoggedInUser } from '../../store/logged-in-user';
import { RootState } from '../../store/rootReducer';

interface useUserResult {
  loggedInUser?: Interfaces.UserOutput;
  loggedInUserAccessLevel?: Enums.AccessLevel;

  getLoggedInUser: () => void;
}

const useLoggedInUser = (): useUserResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently, user } = useAuth0();
  const [loggedInUser, setLoggedInUser] = useState<Interfaces.UserOutput>();
  const [loggedInUserAccessLevel, setLoggedInUserAccessLevel] =
    useState<Enums.AccessLevel>();

  const userState = useSelector((state: RootState) => state.loggedInUser);
  const userObj = userState.data as UserItemOutput;

  const getLoggedInUser = async () => {
    const token = await getAccessTokenSilently();

    if (token && user?.sub) {
      await dispatch(fetchLoggedInUser(token, user.sub));
    }
  };

  useEffect(() => {
    if (userObj) {
      // Complete model
      setLoggedInUser(userObj.entity);
      // Access Level
      setLoggedInUserAccessLevel(userObj.accessLevel);
    }
  }, [userObj]);

  return {
    loggedInUser,
    loggedInUserAccessLevel,
    getLoggedInUser,
  };
};

export default useLoggedInUser;
