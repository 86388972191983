export enum TeamStage {
  CREATION = 'creation',
  CONFIGURATION = 'configuration',
}

export enum TeamCreationSubStage {
  NAME = 'name',
  AVATAR = 'avatar',
  USERS = 'users',
}

export enum TeamConfigureSubStage {
  ADVANCED = 'advanced',
}

interface TeamSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type TeamSubStages = TeamCreationSubStage | TeamConfigureSubStage;

export interface TeamStages {
  activeStage: TeamStage;
  stages: {
    [TeamStage.CREATION]: {
      activeSubStage: TeamCreationSubStage;
      subStages: TeamSubStage[];
    };
    [TeamStage.CONFIGURATION]: {
      activeSubStage: TeamConfigureSubStage;
      subStages: TeamSubStage[];
    };
  };
}

const InitialTeamStage: TeamStages = {
  activeStage: TeamStage.CREATION,
  stages: {
    [TeamStage.CREATION]: {
      activeSubStage: TeamCreationSubStage.NAME,
      subStages: [
        {
          subStage: TeamCreationSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: TeamCreationSubStage.AVATAR,
          stage: 2,
          title: 'Avatar',
        },
        {
          subStage: TeamCreationSubStage.USERS,
          stage: 3,
          title: 'Members',
        },
      ],
    },
    [TeamStage.CONFIGURATION]: {
      activeSubStage: TeamConfigureSubStage.ADVANCED,
      subStages: [
        {
          subStage: TeamConfigureSubStage.ADVANCED,
          stage: 1,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialTeamStage;
