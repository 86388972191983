import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SearchQuery } from '../../interfaces/Search';
import HttpHelper from '../../util/http-helper/HttpHelper';

const CMS_CONNECTIONS_FIELD = 'connections.cms';
const API_CONNECTIONS_FIELD = 'connections.api';
const FORM_CONNECTIONS_FIELD = 'connections.form';
const PORTAL_CONNECTIONS_FIELD = 'connections.portal';

const endpoint = process.env['REACT_APP_CRUD_PROJECT'] || '';

export interface ProjectItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Project;
}

export interface ProjectItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.ProjectOutput;
}

export interface ProjectsItemOutput {
  data: ProjectItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class ProjectService {
  public static async getProjects(
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum = 1,
    limit?: number,
    orderBy?: Record<string, number>,
  ): Promise<
    | Interfaces.SuccessResponseBody<ProjectsItemOutput[]>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<ProjectsItemOutput[]>
      | Interfaces.ErrorResponseBody
    >(endpoint, { ...query, searchQuery, pageNum, limit, orderBy });
  }

  public static async getProject(
    token: string,
    id: string,
    addCMSData?: boolean,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, addCMSData: addCMSData, singleDocument: true });

    return result?.data as ProjectItemOutput;
  }

  public static async postProject(
    token: string,
    project: Interfaces.Project,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: project, userId });

    return result?.data as ProjectItemOutput;
  }

  public static async putProject(
    token: string,
    project: Interfaces.ProjectOutput,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${project._id}`, {
      data: project,
      userId,
    });

    return result?.data as ProjectItemOutput;
  }

  public static async deleteProject(
    token: string,
    projectId: string,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${projectId}`, userId);

    return result?.data as ProjectItemOutput;
  }

  public static async createCMSConnection(
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${project._id}`, {
      data: {
        $push: {
          [CMS_CONNECTIONS_FIELD]: connection,
        },
      },
      userId,
    });

    return result?.data as ProjectItemOutput;
  }

  public static async createAPIConnection(
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${project._id}`, {
      data: {
        $push: {
          [API_CONNECTIONS_FIELD]: connection,
        },
      },
      userId,
    });

    return result?.data as ProjectItemOutput;
  }

  public static async createFormConnection(
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${project._id}`, {
      data: {
        $push: {
          [FORM_CONNECTIONS_FIELD]: connection,
        },
      },
      projectId: project._id,
      userId,
    });

    return result?.data as ProjectItemOutput;
  }

  public static async createPortalConnection(
    token: string,
    project: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
    userId: string,
  ): Promise<ProjectItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ProjectItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${project._id}`, {
      data: {
        $push: {
          [PORTAL_CONNECTIONS_FIELD]: connection,
        },
      },
      projectId: project._id,
      userId,
    });

    return result?.data as ProjectItemOutput;
  }
}
