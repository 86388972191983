import { Enums } from '@configur-tech/upit-core-types';
import { NotificationItem } from '../../services/notification/NotificationService';

const InitialNotification: NotificationItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    organisationId: '',

    env: Enums.Env.DEV,
    eventOrigin: '',
    notificationAlertParams: {
      notificationAlertId: '',
      conditions: {},
      resource: {
        resourceType: Enums.SchemaName.DATASET_META,
      },
      trigger: Enums.NotificationTrigger.CREATE,
    },
    isInfoMessage: false,
  },
};

export default InitialNotification;
