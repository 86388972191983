import { Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_ORGANISATION'] || '';

export interface OrganisationItem {
  accessLevel: number;
  entity: Interfaces.Organisation;
}

export interface OrganisationItemOutput {
  accessLevel: number;
  entity: Interfaces.OrganisationOutput;
}

export default class OrganisationService {
  public static async getOrganisation(
    token: string,
    id: string,
  ): Promise<OrganisationItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<OrganisationItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as OrganisationItemOutput;
  }

  public static async putOrganisation(
    token: string,
    organisation: Partial<Interfaces.OrganisationOutput>,
    userId: string,
  ): Promise<OrganisationItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<OrganisationItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${organisation._id}`, { data: organisation, userId });

    return result?.data as OrganisationItemOutput;
  }
}
