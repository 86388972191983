import { truncate } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import {
  DefaultPopupStyles,
  TopBarHeaderLabel,
  TopBarHeaderTextWrapper,
  TopBarHeaderTitle,
} from '../../main/theme';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import EntityColorMap from '../../util/color-helpers/EntityColorMap';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const TRUNCATE_LIMIT = 25;

const Wrapper = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.darkBlueShadow};
`;

const DatasetHeader: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta } = useDatasetMeta();
  const { lockCollection } = useDatasetCollection();

  const isEditing = location.pathname.includes('edit');
  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');

  if (!datasetMeta?._id || datasetMeta.meta.deleted) {
    return <Wrapper />;
  }

  const action = () => {
    if (!isEditing && !isReplacing && !isMerging) {
      // Reset stages
      dispatch(resetStagesAndSubStages());
      // Reset collection
      lockCollection();
      history.push(`${RouteName.DATASET_ITEM}/${datasetMeta._id}`);
    }
  };

  return (
    <Wrapper>
      <FeatureButton
        isDisabled={isEditing || isReplacing || isMerging}
        isActive={location.pathname === RouteName.USER_ITEM}
        style={{ marginBottom: themeContext.margin.standard }}
        action={action}
        size={FeatureButtonSize.EXTRA_SMALL}
        color={EntityColorMap[EntityType.DATASET]}
        borderColor={themeContext.colors.system.white}
        image={
          datasetMeta.avatar.includes('http')
            ? datasetMeta?.avatar
            : { ...UserIconMap, ...AvatarIconMap }[datasetMeta.avatar]
        }
      />
      <Popup
        content={datasetMeta.name}
        position="right center"
        style={DefaultPopupStyles}
        disabled={datasetMeta.name.length <= TRUNCATE_LIMIT}
        trigger={
          <TopBarHeaderTextWrapper
            disabled={isEditing || isReplacing || isMerging}
            onClick={action}
          >
            <TopBarHeaderLabel>Active Dataset</TopBarHeaderLabel>
            <TopBarHeaderTitle>
              {truncate(datasetMeta.name, {
                length: TRUNCATE_LIMIT,
              })}
            </TopBarHeaderTitle>
          </TopBarHeaderTextWrapper>
        }
      />
    </Wrapper>
  );
};

export default DatasetHeader;
