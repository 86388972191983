import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import usePipelineEntityHelper, {
  MappingDirection,
} from '../../../hooks/pipeline-mapping/UsePipelineEntityHelper';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { initialMappingJobParams } from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import DatasetSelectDropdown from '../../DatasetSelectDropdown/DatasetSelectDropdown';
import SchemaMapper from '../../SchemaMapper/SchemaMapper';
import JobIntegrationSelector, {
  INTEGRATION_BASE_ENDPOINT_FIELD,
  INTEGRATION_CUSTOM_ACTION_FIELD,
  INTEGRATION_REQUEST_ENDPOINT_FIELD,
  INTEGRATION_TEMPLATE_FIELD,
} from './JobIntegrationSelector';
import * as SC from './styled';

export const SUPER_COL_TYPES = [
  Enums.ValueDataType.DATE_CONVERSION,
  Enums.ValueDataType.FIELD_LOOKUP,
  Enums.ValueDataType.FORMULA,
  Enums.ValueDataType.TEXT_TRANSFORMATION,
];

const TARGET_TYPE_OPTIONS = Object.values(Enums.MappingSourceType)
  .filter((type) => type !== Enums.MappingSourceType.QUERY)
  .map((type, index) => ({
    key: `target-type-${type}-${index}`,
    value: type,
    text: startCase(type.toLowerCase()),
  }));

interface JobMappingModalComponentProps {
  jobIndex: number;
  onChange: (data) => void;
}

const JobMappingModalComponent: FC<JobMappingModalComponentProps> = ({
  jobIndex,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const { pipelineTemplate } = usePipelineTemplate();
  const { datasetMeta, activeDataCollectionItem, getDatasetMetas } =
    useDatasetMeta();
  const { getLatestEntityId, getLastDataJob, getEntitySchema } =
    usePipelineEntityHelper();

  const [pipelineJob, setPipelineJob] = useState<Interfaces.MappingJobParams>(
    initialMappingJobParams,
  );
  const [prevJob, setPrevJob] = useState<Interfaces.PipelineJob>();
  const [prevDataJob, setPrevDataJob] = useState<Interfaces.PipelineJob>();

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [mappingTarget, setMappingTarget] = useState<Interfaces.MappingSource>({
    type: '' as Enums.MappingSourceType,
    entityId: '',
  });

  const [activeCollectionSchemaFields, setActiveCollectionSchemaFields] =
    useState<Interfaces.SchemaField[]>([]);
  const [inputSchema, setInputSchema] = useState<Interfaces.SchemaField[]>([]);
  const [outputSchema, setOutputSchema] = useState<Interfaces.SchemaField[]>(
    [],
  );

  const [integrationType, setIntegrationType] =
    useState<Enums.IntegrationType>();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplates: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  const isEditing = jobIndex > -1;

  // Re-map activeDataCollection schema and filter out super cols
  useEffect(() => {
    if (activeDataCollectionItem?._id) {
      setActiveCollectionSchemaFields(
        activeDataCollectionItem.schemaData.map((field) =>
          Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
        ),
      );
    }
  }, [activeDataCollectionItem?._id, activeDataCollectionItem?.schemaData]);

  // If editing existing, set data
  useEffect(() => {
    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      const job = pipelineTemplate.jobs[jobIndex];

      setPipelineJob(job.jobParams as Interfaces.MappingJobParams);

      setMappingTarget((job.jobParams as Interfaces.MappingJobParams)?.output);

      setIntegrationType(
        (job.jobParams as Interfaces.MappingJobParams)?.output?.customActionId
          ? Enums.IntegrationType.CUSTOM
          : Enums.IntegrationType.HTTP,
      );
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // Get last job from pipeline
  useEffect(() => {
    if (pipelineTemplate?._id) {
      const lastJob =
        pipelineTemplate.jobs[
          isEditing ? jobIndex - 1 : pipelineTemplate.jobs.length - 1
        ];

      if (lastJob) {
        setPrevJob(lastJob);

        setPrevDataJob(getLastDataJob(pipelineTemplate.jobs, lastJob));
      }
    }
  }, [
    getLastDataJob,
    isEditing,
    jobIndex,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // If mapping is first job, set input schema from origin DatasetMeta
  useEffect(() => {
    if (!prevDataJob && activeCollectionSchemaFields) {
      setInputSchema(activeCollectionSchemaFields);
    }
  }, [
    activeCollectionSchemaFields,
    jobIndex,
    pipelineTemplate?.jobs.length,
    prevDataJob,
  ]);

  // If previous job was a dataset, set the inputSchema
  useEffect(() => {
    if (
      prevDataJob?.jobType === Enums.PipelineJobType.DATASET &&
      datasetMetas.length
    ) {
      const datasetSchema =
        getEntitySchema(
          prevDataJob,
          datasetMetas,
          undefined,
          undefined,
          undefined,
          MappingDirection.INPUT,
        ) || [];

      setInputSchema(datasetSchema || []);
    }
  }, [getEntitySchema, datasetMetas, prevDataJob]);

  // If previous job was an integration, set the inputSchema
  useEffect(() => {
    if (
      prevDataJob?.jobType === Enums.PipelineJobType.INTEGRATION &&
      integrationTemplates.length
    ) {
      const integrationSchema =
        getEntitySchema(
          prevDataJob,
          undefined,
          integrationTemplates,
          undefined,
          undefined,
          MappingDirection.INPUT,
        ) || [];

      setInputSchema(integrationSchema || []);
    }
  }, [getEntitySchema, integrationTemplates, prevDataJob]);

  // If prev job is query, set input schema from query
  useEffect(() => {
    if (prevDataJob?.jobType === Enums.PipelineJobType.QUERY) {
      const querySchema =
        getEntitySchema(
          prevDataJob,
          undefined,
          undefined,
          queries,
          undefined,
          MappingDirection.INPUT,
        ) || [];

      setInputSchema(querySchema);
    }
  }, [getEntitySchema, prevDataJob, queries]);

  // If conditional or filter, use origin dataset if first job, or dataset/query from previous job
  useEffect(() => {
    if (
      prevJob?.jobType &&
      [
        Enums.PipelineJobType.CONDITIONAL,
        Enums.PipelineJobType.FILTER,
      ].includes(prevJob.jobType)
    ) {
      const dep = prevJob.jobIdsJobDependsOn?.[0];

      if (!dep) {
        setInputSchema(activeCollectionSchemaFields);
      }

      switch (prevDataJob?.jobType) {
        case Enums.PipelineJobType.DATASET: {
          const datasetSchema =
            getEntitySchema(
              prevDataJob,
              datasetMetas,
              undefined,
              undefined,
              undefined,
              MappingDirection.INPUT,
            ) || [];

          setInputSchema(datasetSchema);
          break;
        }
        case Enums.PipelineJobType.INTEGRATION: {
          const integrationSchema =
            getEntitySchema(
              prevDataJob,
              undefined,
              integrationTemplates,
              undefined,
              undefined,
              MappingDirection.OUTPUT,
            ) || [];

          setInputSchema(integrationSchema || []);
          break;
        }
        case Enums.PipelineJobType.QUERY: {
          const querySchema =
            getEntitySchema(
              prevDataJob,
              undefined,
              undefined,
              queries,
              undefined,
              MappingDirection.INPUT,
            ) || [];

          setInputSchema(querySchema);
          break;
        }
      }
    }
  }, [
    activeCollectionSchemaFields,
    datasetMetas,
    getEntitySchema,
    integrationTemplates,
    prevDataJob,
    prevJob?.jobIdsJobDependsOn,
    prevJob?.jobType,
    queries,
  ]);

  // If mapping is first job, and mapping to a DATASET set output schema from targeted DatasetMeta
  useEffect(() => {
    if (
      datasetMetas.length &&
      mappingTarget?.type === Enums.MappingSourceType.DATASET &&
      mappingTarget?.entityId
    ) {
      // Fetch dataset if doesn't exist (because search dropdown as only retained _ids instead of whole objects when re-searching)
      const exists = datasetMetas.find(
        (dsm) => dsm.entity._id === mappingTarget.entityId,
      );

      if (!exists) {
        (async () => {
          setLoadingEntities(true);
          await getDatasetMetas({ _id: mappingTarget.entityId });
          setLoadingEntities(false);
        })();
      }

      const datasetSchema = getEntitySchema(
        {
          jobType: Enums.PipelineJobType.MAPPING,
          jobParams: { ...pipelineJob, output: mappingTarget },
        } as unknown as Interfaces.PipelineJob,
        datasetMetas,
        undefined,
        undefined,
        false,
        MappingDirection.OUTPUT,
      );

      setOutputSchema(datasetSchema || []);
    }
  }, [
    datasetMetas,
    getDatasetMetas,
    getEntitySchema,
    mappingTarget,
    pipelineJob,
  ]);

  // If mapping is first job, and mapping to an INTEGRATION set output schema from targeted endpoint
  useEffect(() => {
    if (
      (mappingTarget?.type === Enums.MappingSourceType.INTEGRATION &&
        mappingTarget?.entityId &&
        mappingTarget?.baseEndpointId &&
        mappingTarget?.requestEndpointId) ||
      (mappingTarget?.type === Enums.MappingSourceType.INTEGRATION &&
        mappingTarget?.entityId &&
        mappingTarget?.customActionId)
    ) {
      const integrationSchema = getEntitySchema(
        {
          jobType: Enums.PipelineJobType.MAPPING,
          jobParams: { ...pipelineJob, output: mappingTarget },
        } as unknown as Interfaces.PipelineJob,
        undefined,
        integrationTemplates,
        undefined,
        false,
        MappingDirection.OUTPUT,
      );

      setOutputSchema(integrationSchema || []);
    }
  }, [getEntitySchema, integrationTemplates, mappingTarget, pipelineJob]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  const handleIntegrationSelection = (
    field: string,
    value: string,
    type?: Enums.IntegrationType,
  ) => {
    if (type) {
      setIntegrationType(type);
    }

    switch (field) {
      case INTEGRATION_TEMPLATE_FIELD:
        setMappingTarget({ type: mappingTarget.type, entityId: value });
        setPipelineJob({ ...pipelineJob, mappings: [] });
        break;
      case INTEGRATION_BASE_ENDPOINT_FIELD:
        setMappingTarget({
          type: mappingTarget.type,
          entityId: mappingTarget.entityId,
          baseEndpointId: value,
        });
        setPipelineJob({ ...pipelineJob, mappings: [] });
        break;
      case INTEGRATION_REQUEST_ENDPOINT_FIELD:
        setMappingTarget({ ...mappingTarget, requestEndpointId: value });
        setPipelineJob({ ...pipelineJob, mappings: [] });
        break;
      case INTEGRATION_CUSTOM_ACTION_FIELD:
        setMappingTarget({ ...mappingTarget, customActionId: value });
        setPipelineJob({ ...pipelineJob, mappings: [] });
        break;
    }
  };

  const handleSchemaChange = (mappings: Interfaces.Mapping[]) => {
    const cloned: Interfaces.MappingJobParams = cloneDeep(pipelineJob);

    const lastJob = prevDataJob || prevJob;

    const {
      integrationId,
      integrationEndpointId,
      integrationRequestEndpointId,
      integrationCustomActionId,
    } = (lastJob?.jobParams as Interfaces.IntegrationJobParams) || {};

    const { queryId } = (lastJob?.jobParams as Interfaces.QueryJobParams) || {};

    let inputType = Enums.MappingSourceType.DATASET;

    if (lastJob?.jobType === Enums.PipelineJobType.INTEGRATION) {
      inputType = Enums.MappingSourceType.INTEGRATION;
    } else if (lastJob?.jobType === Enums.PipelineJobType.QUERY) {
      inputType = Enums.MappingSourceType.QUERY;
    }

    cloned.input = {
      type: inputType,
      entityId: getLatestEntityId(
        pipelineTemplate?.jobs as Interfaces.PipelineJob[],
        prevDataJob as Interfaces.PipelineJob,
        datasetMeta?._id,
        integrationId,
        queryId,
      ) as string,
      baseEndpointId:
        lastJob?.jobType === Enums.PipelineJobType.INTEGRATION
          ? integrationEndpointId
          : undefined,
      requestEndpointId:
        lastJob?.jobType === Enums.PipelineJobType.INTEGRATION
          ? integrationRequestEndpointId
          : undefined,
      customActionId:
        lastJob?.jobType === Enums.PipelineJobType.INTEGRATION
          ? integrationCustomActionId
          : undefined,
    };

    cloned.output = mappingTarget;
    cloned.mappings = mappings;

    setPipelineJob(cloned);
  };

  return (
    <>
      <SC.InputWrapper>
        <SC.InputContainer>
          <StyledBodySubHeader
            style={{ marginTop: themeContext.margin.xlarge }}
          >
            Mapping Target
          </StyledBodySubHeader>
          <StyledText style={{ marginBottom: themeContext.margin.xlarge }}>
            Select which output target you'd like to map this data against.
          </StyledText>

          <StyledDropdown
            selectOnBlur={false}
            upward={true}
            selection
            value={mappingTarget?.type || ''}
            placeholder={'Please select a target type'}
            options={TARGET_TYPE_OPTIONS}
            style={{ marginTop: 0 }}
            onChange={(e, { value }) => {
              setOutputSchema([]);
              setMappingTarget({
                type: value,
                entityId: '',
              });
              setPipelineJob({ ...pipelineJob, mappings: [] });
            }}
          />

          {mappingTarget?.type === Enums.MappingSourceType.DATASET && (
            <>
              <StyledBodySubHeader
                style={{ marginTop: themeContext.margin.xlarge }}
              >
                Target Dataset
              </StyledBodySubHeader>
              <StyledText
                style={{ marginBottom: themeContext.margin.xxxlarge }}
              >
                Select which dataset you would like to target.
              </StyledText>

              <DatasetSelectDropdown
                onChange={(value) => {
                  setMappingTarget({
                    type: Enums.MappingSourceType.DATASET,
                    entityId: value,
                  });
                  setPipelineJob({ ...pipelineJob, mappings: [] });
                }}
                initialDatasetMetaId={mappingTarget?.entityId || ''}
              />
            </>
          )}

          {mappingTarget?.type === Enums.MappingSourceType.INTEGRATION && (
            <JobIntegrationSelector
              integrationType={integrationType}
              integrationValue={mappingTarget.entityId}
              baseEndpointValue={mappingTarget.baseEndpointId}
              requestEndpointValue={mappingTarget.requestEndpointId}
              customActionValue={mappingTarget.customActionId}
              onChange={handleIntegrationSelection}
            />
          )}
        </SC.InputContainer>
      </SC.InputWrapper>

      {((mappingTarget?.type === Enums.MappingSourceType.DATASET &&
        mappingTarget?.entityId) ||
        (mappingTarget?.type === Enums.MappingSourceType.INTEGRATION &&
          mappingTarget.entityId &&
          mappingTarget.baseEndpointId &&
          mappingTarget.requestEndpointId) ||
        (mappingTarget?.type === Enums.MappingSourceType.INTEGRATION &&
          mappingTarget.entityId &&
          mappingTarget.customActionId)) && (
        <SchemaMapper
          loading={loadingEntities}
          inputSchema={inputSchema}
          outputSchema={outputSchema}
          onChange={handleSchemaChange}
          existingMappings={pipelineJob.mappings}
          isQueryJob={prevDataJob?.jobType === Enums.PipelineJobType.QUERY}
        />
      )}
    </>
  );
};

export default JobMappingModalComponent;
