import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SANDBOX_PROJECT } from '../../const/ProjectConst';
import { SearchQuery } from '../../interfaces/Search';
import {
  ProjectItemOutput,
  ProjectsItemOutput,
} from '../../services/project/ProjectService';
import {
  createAPIConnection,
  createCMSConnection,
  createFormConnection,
  createPortalConnection,
  createProject,
  deleteProject,
  fetchProject,
  updateProject,
} from '../../store/project';
import { ProjectStages } from '../../store/project-stage/initial-state';
import { fetchProjects, fetchProjectsSuccess } from '../../store/projects';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useProjectResult {
  project?: Interfaces.ProjectOutput;
  projectAccessLevel?: Enums.AccessLevel;
  projectError?: string;
  activeConnection?: Interfaces.ConnectionOutput;
  connections?: Interfaces.ProjectOutput['connections'];
  aggregations?: Interfaces.ProjectOutput['aggregations'];
  sandbox?: Interfaces.ProjectOutput;

  getProjects: (
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    page?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<void>;
  getProject: (projectId: string, addCMSData?: boolean) => void;
  addProject: (projectObj: Interfaces.Project) => void;
  editProject: (projectObj: Interfaces.ProjectOutput) => void;
  removeProject: (projectId: string) => void;
  removeAggregation: (aggregationId: string) => void;
  removeProjectQuery: (queryId: string) => void;
  removeProjectChart: (chartId: string) => void;
  removeConnection: (
    connectionType: Enums.ConnectionType,
    connectionId: string,
  ) => void;

  addApiConnection: (
    projectObj: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
  ) => void;
  addCmsConnection: (
    projectObj: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
  ) => void;
  addFormConnection: (
    projectObj: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
  ) => void;
  addPortalConnection: (
    projectObj: Interfaces.ProjectOutput,
    connection: Interfaces.Connection,
  ) => void;
}

const useProject = (): useProjectResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [project, setProject] = useState<Interfaces.ProjectOutput>();
  const [projectAccessLevel, setProjectAccessLevel] =
    useState<Enums.AccessLevel>();
  const [projectError, setProjectError] = useState<string>();

  const [connections, setConnections] =
    useState<Interfaces.ProjectOutput['connections']>();
  const [activeConnection, setActiveConnection] =
    useState<Interfaces.ConnectionOutput>();

  const [aggregations, setAggregations] =
    useState<Interfaces.ProjectOutput['aggregations']>();
  const [sandbox, setSandbox] = useState<Interfaces.ProjectOutput>();

  const projectsState = useSelector((state: RootState) => state.projects);
  const projects: ProjectsItemOutput = projectsState?.data;
  const projectState = useSelector((state: RootState) => state.project);
  const projectObj = projectState.data as ProjectItemOutput;
  const projectErr = projectState.error;
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const projectStageObj = projectStageState as ProjectStages;

  const getProjects = useCallback(
    async (
      query?: Record<string, unknown>,
      searchQuery?: SearchQuery,
      page?: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(
          fetchProjects(token, query, searchQuery, page, limit, orderBy),
        );
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const getProject = useCallback(
    async (projectId: string, addCMSData?: boolean) => {
      const token = await getAccessTokenSilently();

      if (token && projectId) {
        await dispatch(fetchProject(token, projectId, addCMSData));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addProject = useCallback(
    async (projectObj: Interfaces.Project) => {
      const token = await getAccessTokenSilently();

      if (token && projectObj && loggedInUser) {
        await dispatch(createProject(token, projectObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editProject = useCallback(
    async (projectObj: Interfaces.ProjectOutput) => {
      const token = await getAccessTokenSilently();

      if (token && projectObj && loggedInUser) {
        await dispatch(updateProject(token, projectObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeProject = useCallback(
    async (projectId: string) => {
      const token = await getAccessTokenSilently();

      if (token && projectId && loggedInUser) {
        await dispatch(deleteProject(token, projectId, loggedInUser._id));

        // Remove project from current projects
        const cloned = cloneDeep(projects);
        cloned.data = cloned.data.filter((e) => e.entity._id !== projectId);
        await dispatch(fetchProjectsSuccess(cloned));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, projects],
  );

  const removeAggregation = useCallback(
    async (aggregationId: string) => {
      const token = await getAccessTokenSilently();

      if (token && aggregationId && loggedInUser) {
        const cloned = cloneDeep(projectObj).entity;

        cloned.aggregations = cloned.aggregations.filter(
          (a) => a._id !== aggregationId,
        );

        await dispatch(updateProject(token, cloned, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, projectObj],
  );

  const removeProjectQuery = useCallback(
    async (queryId: string) => {
      const token = await getAccessTokenSilently();

      if (token && queryId && loggedInUser) {
        const cloned = cloneDeep(projectObj).entity;

        cloned.queries = cloned.queries.filter((a) => a._id !== queryId);

        await dispatch(updateProject(token, cloned, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, projectObj],
  );

  const removeProjectChart = useCallback(
    async (chartId: string) => {
      const token = await getAccessTokenSilently();

      if (token && chartId && loggedInUser) {
        const cloned = cloneDeep(projectObj).entity;
        cloned.charts = cloned.charts.filter((a) => a !== chartId);

        await dispatch(updateProject(token, cloned, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, projectObj],
  );

  const removeConnection = useCallback(
    async (connectionType: Enums.ConnectionType, connectionId: string) => {
      const token = await getAccessTokenSilently();

      if (token && connectionType && connectionId && loggedInUser) {
        const cloned = cloneDeep(projectObj).entity;

        cloned.connections[connectionType] = cloned.connections[
          connectionType
        ].filter((c) => c._id !== connectionId);

        await dispatch(updateProject(token, cloned, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, projectObj],
  );

  const addApiConnection = useCallback(
    async (
      projectObj: Interfaces.ProjectOutput,
      connection: Interfaces.Connection,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && projectObj && connection && loggedInUser) {
        await dispatch(
          createAPIConnection(token, projectObj, connection, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addCmsConnection = useCallback(
    async (
      projectObj: Interfaces.ProjectOutput,
      connection: Interfaces.Connection,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && projectObj && connection && loggedInUser) {
        await dispatch(
          createCMSConnection(token, projectObj, connection, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addFormConnection = useCallback(
    async (
      projectObj: Interfaces.ProjectOutput,
      connection: Interfaces.Connection,
    ) => {
      const token = await getAccessTokenSilently();
      if (token && projectObj && connection && loggedInUser) {
        await dispatch(
          createFormConnection(token, projectObj, connection, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addPortalConnection = useCallback(
    async (
      projectObj: Interfaces.ProjectOutput,
      connection: Interfaces.Connection,
    ) => {
      const token = await getAccessTokenSilently();
      if (token && projectObj && connection && loggedInUser) {
        await dispatch(
          createPortalConnection(
            token,
            projectObj,
            connection,
            loggedInUser._id,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (projectObj) {
      // Complete model
      setProject(projectObj?.entity);
      setProjectAccessLevel(projectObj.accessLevel);
      // Connections
      if (projectObj.entity?.connections) {
        setConnections((projectObj as ProjectItemOutput).entity.connections);
      }
      // Aggregations
      if (projectObj.entity?.aggregations) {
        setAggregations((projectObj as ProjectItemOutput).entity.aggregations);
      }
    }

    if (projectStageObj) {
      // Active Connection
      if (projectStageObj.activeConnection) {
        setActiveConnection(projectStageObj.activeConnection);
      }
    }

    if (projectErr) {
      setProjectError(projectErr);
    }

    const sandbox =
      projectObj.entity?.name === SANDBOX_PROJECT
        ? projectObj.entity
        : projects?.data?.find((p) => p.entity.name === SANDBOX_PROJECT)
            ?.entity;

    if (sandbox) {
      setSandbox(sandbox as Interfaces.ProjectOutput);
    }
  }, [projectErr, projectObj, projectStageObj, projects]);

  return {
    project,
    projectAccessLevel,
    projectError,
    connections,
    activeConnection,
    aggregations,
    sandbox,

    getProjects,
    getProject,
    addProject,
    editProject,
    removeProject,
    removeAggregation,
    removeProjectChart,
    removeProjectQuery,
    removeConnection,

    addApiConnection,
    addCmsConnection,
    addFormConnection,
    addPortalConnection,
  };
};

export default useProject;
