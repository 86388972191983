import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  position: relative;

  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const StickyWrapper = styled.div`
  height: calc(100vh - 70px);
  overflow-y: auto;
  align-items: start;

  padding: ${({ theme }) => theme.padding.standard};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  color: ${({ theme }) => theme.colors.system.offBlack};
  height: 37px;
  display: flex;
  align-items: center;
`;

export const DescriptionPlaceholder = styled(StyledText)`
  color: ${({ theme }) => theme.colors.system.darkGrey};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const Panel = styled.div<{ readOnly?: boolean }>`
  ${({ theme, readOnly }) =>
    readOnly
      ? `border: none`
      : `border: 2px dashed ${theme.colors.system.grey};`};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 100%;
  ${({ readOnly }) => (readOnly ? `min-height: auto` : `min-height: 100px;`)};
  margin-top: ${({ theme }) => theme.margin.small};

  .codex-editor__loader {
    max-height: 100px !important;
  }
  .ce-block__content {
    max-width: 100%;
  }
  .image-tool__caption {
    border: none;
    box-shadow: none;
    text-align: center;
    font-weight: bold;

    // Caption placeholder
    &:before {
      text-align: center;
      width: 100%;
      padding-right: ${({ theme }) => theme.padding.large};
      font-weight: bold !important;
    }
  }
  .image-tool--withBackground .image-tool__image {
    background: ${({ theme }) => theme.colors.system.white};
  }

  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 0;
  }

  ${({ readOnly }) =>
    readOnly &&
    `.codex-editor__redactor {
      padding-bottom: 0 !important;
    }
    .image-tool__caption:empty {
    display: none !important;
  }
  `};

  .codex-editor__redactor {
    padding-bottom: ${({ theme }) => `${theme.padding.xxlarge} !important`};
  }

  display: flex;
  flex-direction: column;
`;
