import {
  faBell,
  faLightbulb,
  faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import logo from '../../assets/icons/system/codex-icon.svg';
import { RouteName } from '../../enums';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePermission from '../../hooks/permission/UsePermission';
import { DefaultPopupStyles } from '../../main/theme';
import { NotificationItemsOutput } from '../../services/notification/NotificationService';
import { RootState } from '../../store/rootReducer';
import FAButton from '../FaButton/FAButton';
import FeatureButton from '../FeatureButton/FeatureButton';
import * as SC from './styled';

export const SUPPORT_URL = 'https://help.goconfigur.com/knowledge';
export const FEATURE_REQUEST_FORM_ID =
  '612e6d6ae1ed100009112f3b/612f757f7879e50009dfd86b';

interface SystemActionsProps {
  toggleNotificationPanel?: () => void;
}

const SystemActions: FC<SystemActionsProps> = ({ toggleNotificationPanel }) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();
  const { organisation } = useOrganisation();
  const { isOrgManager } = usePermission();

  const notificationEventsState = useSelector(
    (state: RootState) => state.notificationEvents,
  );
  const notificationEvents: NotificationItemsOutput =
    notificationEventsState.unread;

  return (
    <SC.Wrapper>
      {toggleNotificationPanel && (
        <SC.PopupWrapper>
          <Popup
            disabled={true}
            position="right center"
            style={DefaultPopupStyles}
            trigger={
              <SC.NotificationIconContainer
                hasNotifications={
                  notificationEvents?.pagination?.totalCount > 0
                }
              >
                <FAButton
                  action={toggleNotificationPanel}
                  icon={faBell}
                  color={
                    location.pathname.includes(RouteName.NOTIFICATION_CENTRE)
                      ? themeContext.colors.general.yellow
                      : themeContext.colors.system.white
                  }
                  size={'2x'}
                />
              </SC.NotificationIconContainer>
            }
          />
        </SC.PopupWrapper>
      )}

      <SC.PopupWrapper>
        <Popup
          content={'Get Help'}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FAButton
                action={() => window.open(SUPPORT_URL)}
                icon={faQuestion}
                color={themeContext.colors.system.white}
                size={'2x'}
              />
            </div>
          }
        />
      </SC.PopupWrapper>

      <Popup
        disabled={true}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FeatureButton
              isActive={location.pathname === RouteName.WELCOME}
              action={() => history.push(RouteName.WELCOME)}
              size={50}
              color={themeContext.colors.system.white}
              borderColor={themeContext.colors.system.white}
              image={organisation?.theme?.logo || logo}
              faceStyle={
                organisation?.theme?.logo
                  ? {
                      background: `${themeContext.colors.system.white} url(${organisation?.theme?.logo}) no-repeat center / 85%`,
                      padding: 0,
                    }
                  : {}
              }
            />
          </div>
        }
      />
    </SC.Wrapper>
  );
};

export default SystemActions;
