import { Enums } from '@configur-tech/upit-core-types';
import { DatasetStage } from '@configur-tech/upit-core-types/lib/enums';
import {
  faArrowLeft,
  faChartBar,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faClipboardList,
  faCog,
  faDatabase,
  faEye,
  faGlobe,
  faLayerGroup,
  faLink,
  faSearch,
  faShieldAlt,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { ProjectStage, RouteName } from '../../../enums';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import useProject from '../../../hooks/project/UseProject';
import { BackNavigationItem, defaultTheme } from '../../../main/theme';
import {
  resetAggregationStage,
  resetChartsStage,
  resetDataviewStage,
  resetFormStage,
  resetPortalStage,
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectConfigureSubStage } from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import { getProjectStageTitle } from '../../../util/project-stage-content/ProjectStageContent';
import NavItem from '../../Navigation/NavItem';
import NavTitle from '../../Navigation/NavTitle';
import * as SC from './styled';

interface ProjectNavGroup {
  title: string;
  items: string[];
}

const NavIcons = {
  [ProjectStage.CREATION]: faWrench,
  [ProjectStage.DATASETS]: faDatabase,
  [ProjectStage.MODELLING]: faLink,
  [ProjectStage.AGGREGATION]: faSearch,
  [ProjectStage.CHARTS]: faChartBar,
  [Enums.DatasetStage.CONFIGURATION]: faCog,
  [Enums.ConnectionType.CMS]: faLayerGroup,
  [Enums.ConnectionType.API]: faGlobe,
  [Enums.ConnectionType.FORM]: faClipboardList,
  [Enums.ConnectionType.PORTAL]: faCircleNotch,
  [ProjectConfigureSubStage.ACCESS]: faShieldAlt,
  [ProjectConfigureSubStage.ADVANCED]: faCog,
};

const viewAccessProjectStages = [
  ProjectStage.CREATION,
  ProjectStage.AGGREGATION,
];

const SELECT_AND_LINK_TITLE = 'Select & Link';
const ANALYSE_AND_VISUALISE_TITLE = 'Analyse & Visualise';
const GROUP_TITLE = 'Group';
const SHARE_TITLE = 'Share';
const CONFIG_TITLE = 'Configuration';

const PROJECT_NAV_GROUPS: ProjectNavGroup[] = [
  {
    title: SELECT_AND_LINK_TITLE,
    items: [ProjectStage.DATASETS, ProjectStage.MODELLING],
  },
  {
    title: ANALYSE_AND_VISUALISE_TITLE,
    items: [ProjectStage.AGGREGATION, ProjectStage.CHARTS],
  },
  {
    title: GROUP_TITLE,
    items: [Enums.ConnectionType.CMS],
  },
  {
    title: SHARE_TITLE,
    items: [
      // Deprecated
      // Enums.ConnectionType.API,
      // Enums.ConnectionType.FORM,
      Enums.ConnectionType.PORTAL,
    ],
  },
  {
    title: CONFIG_TITLE,
    items: [ProjectStage.CONFIGURATION],
  },
];

const ProjectItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { project, projectAccessLevel } = useProject();
  const { hasAccessToDashboards } = useOrganisation();
  const themeContext = useContext(ThemeContext);

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({
    [SELECT_AND_LINK_TITLE]: false,
    [ANALYSE_AND_VISUALISE_TITLE]: false,
    [GROUP_TITLE]: false,
    [SHARE_TITLE]: false,
    [CONFIG_TITLE]: false,
  });

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const activeStage = projectStageState.activeStage;
  const activeSubStage = projectStageState.stages[activeStage]?.activeSubStage;

  const applyAdditionalStageAction = (
    stage: ProjectStage | Enums.ConnectionType,
  ) => {
    switch (stage) {
      case ProjectStage.AGGREGATION:
        return dispatch(resetAggregationStage());
      case ProjectStage.CHARTS:
        return dispatch(resetChartsStage());
      case Enums.ConnectionType.CMS:
        return dispatch(resetDataviewStage());
      case Enums.ConnectionType.FORM:
        return dispatch(resetFormStage());
      case Enums.ConnectionType.PORTAL:
        return dispatch(resetPortalStage());
    }
  };

  if (projectAccessLevel !== Enums.AccessLevel.MANAGE) {
    return (
      <>
        <BackNavigationItem
          className={'back'}
          key={`form-stage-back`}
          onClick={() => history.push(RouteName.PROJECTS)}
          isActive={false}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={defaultTheme.colors.system.white}
          />

          <NavTitle text={'Back To Projects'} />
        </BackNavigationItem>

        {viewAccessProjectStages.map((k) => {
          const stageName = k as ProjectStage;

          return (
            <NavItem
              key={`project-stage-${stageName}`}
              onClick={() => {
                applyAdditionalStageAction(stageName);
                dispatch(updateActiveProjectStage(stageName));
              }}
              isActive={projectStageState.activeStage === stageName}
            >
              <FontAwesomeIcon
                icon={NavIcons[stageName]}
                color={defaultTheme.colors.system.white}
              />

              <NavTitle text={getProjectStageTitle(stageName)} />
            </NavItem>
          );
        })}
      </>
    );
  }

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`form-stage-back`}
        onClick={() => history.push(RouteName.PROJECTS)}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Back To Projects'} />
      </BackNavigationItem>

      {/* Project overview */}
      <NavItem
        key={`project-stage-overview`}
        onClick={() =>
          dispatch(updateActiveProjectStage(ProjectStage.CREATION))
        }
        isActive={projectStageState.activeStage === ProjectStage.CREATION}
        disabled={false}
      >
        <FontAwesomeIcon
          icon={project?._id ? faEye : NavIcons[DatasetStage.CREATION]}
          color={defaultTheme.colors.system.white}
          style={{ marginRight: themeContext.margin.standard }}
        />
        <NavTitle
          text={
            project?._id
              ? 'Overview'
              : getProjectStageTitle(ProjectStage.CREATION)
          }
        />
      </NavItem>

      {project?._id && (
        <SC.NavigationAccordion>
          {PROJECT_NAV_GROUPS.map((group, i) => (
            <div key={`group-${group.title}-${i}`}>
              <SC.NavigationAccordionTitle
                active={!accordionGroups[group.title]}
                index={i}
                onClick={() =>
                  setAccordionGroups({
                    ...accordionGroups,
                    [group.title]: !accordionGroups[group.title],
                  })
                }
              >
                <div style={{ width: '100%', display: 'flex' }}>
                  <NavTitle text={group.title} />

                  <FontAwesomeIcon
                    icon={
                      !accordionGroups[group.title]
                        ? faChevronUp
                        : faChevronDown
                    }
                    color={themeContext.colors.system.white}
                  />
                </div>
              </SC.NavigationAccordionTitle>

              <SC.NavigationAccordionContent
                active={!accordionGroups[group.title]}
              >
                <div>
                  {Object.values(group.items).map((k, index) => {
                    const stageName = k as ProjectStage;

                    let disabled = false;
                    if (stageName !== ProjectStage.CREATION) {
                      if (
                        project?.stages?.[ProjectStage.CREATION]?.status !==
                        Enums.StageStatus.COMPLETED
                      ) {
                        disabled = true;
                      }
                    }

                    if (stageName === ProjectStage.CHARTS) {
                      if (!hasAccessToDashboards) {
                        return;
                      }

                      return (
                        <NavItem
                          key={`project-stage-${stageName}`}
                          onClick={() => {
                            applyAdditionalStageAction(stageName);
                            dispatch(updateActiveProjectStage(stageName));
                          }}
                          isActive={projectStageState.activeStage === stageName}
                          disabled={disabled}
                        >
                          <FontAwesomeIcon
                            icon={NavIcons[stageName]}
                            color={defaultTheme.colors.system.white}
                            style={{
                              marginRight: themeContext.margin.standard,
                            }}
                          />

                          <NavTitle text={getProjectStageTitle(stageName)} />
                        </NavItem>
                      );
                    }

                    if (stageName === ProjectStage.CONFIGURATION) {
                      return (
                        <div
                          key={`project-stage-accordion-${ProjectStage.CONFIGURATION}`}
                        >
                          <NavItem
                            key={`project-stage-${ProjectConfigureSubStage.ACCESS}`}
                            onClick={() => {
                              dispatch(
                                updateActiveProjectStage(
                                  ProjectStage.CONFIGURATION,
                                ),
                              );
                              dispatch(
                                updateActiveProjectSubStage(
                                  ProjectConfigureSubStage.ACCESS,
                                ),
                              );
                            }}
                            isActive={
                              projectStageState.activeStage === stageName &&
                              activeSubStage == ProjectConfigureSubStage.ACCESS
                            }
                          >
                            <FontAwesomeIcon
                              icon={NavIcons[ProjectConfigureSubStage.ACCESS]}
                              color={defaultTheme.colors.system.white}
                              style={{
                                marginRight: themeContext.margin.standard,
                              }}
                            />

                            <NavTitle
                              text={getProjectStageTitle(
                                ProjectConfigureSubStage.ACCESS,
                              )}
                            />
                          </NavItem>

                          <NavItem
                            key={`project-stage-${ProjectConfigureSubStage.ADVANCED}`}
                            onClick={() => {
                              dispatch(
                                updateActiveProjectStage(
                                  ProjectStage.CONFIGURATION,
                                ),
                              );
                              dispatch(
                                updateActiveProjectSubStage(
                                  ProjectConfigureSubStage.ADVANCED,
                                ),
                              );
                            }}
                            isActive={
                              projectStageState.activeStage === stageName &&
                              activeSubStage ==
                                ProjectConfigureSubStage.ADVANCED
                            }
                          >
                            <FontAwesomeIcon
                              icon={NavIcons[ProjectConfigureSubStage.ADVANCED]}
                              color={defaultTheme.colors.system.white}
                              style={{
                                marginRight: themeContext.margin.standard,
                              }}
                            />

                            <NavTitle
                              text={getProjectStageTitle(
                                ProjectConfigureSubStage.ADVANCED,
                              )}
                            />
                          </NavItem>
                        </div>
                      );
                    }

                    return (
                      <SC.NavigationNavItem
                        key={`project-stage-${stageName}-${index}`}
                        onClick={() => {
                          applyAdditionalStageAction(stageName);
                          dispatch(updateActiveProjectStage(stageName));
                        }}
                        isActive={projectStageState.activeStage === stageName}
                        disabled={disabled}
                      >
                        <FontAwesomeIcon
                          icon={NavIcons[stageName]}
                          color={defaultTheme.colors.system.white}
                          style={{ marginRight: themeContext.margin.standard }}
                        />

                        <NavTitle text={getProjectStageTitle(stageName)} />
                      </SC.NavigationNavItem>
                    );
                  })}
                </div>
              </SC.NavigationAccordionContent>
            </div>
          ))}
        </SC.NavigationAccordion>
      )}
    </>
  );
};

export default ProjectItemNavigation;
