export enum ListStage {
  CREATION = 'creation',
  CONFIGURATION = 'configuration',
}

export enum ListCreateSubStage {
  NAME = 'name',
  VALUES = 'values',
}

export enum ListConfigureSubStage {
  ACCESS = 'access',
  ADVANCED = 'advanced',
}

export type ListSubStages = ListCreateSubStage | ListConfigureSubStage;

interface ListSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export interface ListStages {
  activeStage: ListStage;
  stages: {
    [ListStage.CREATION]: {
      activeSubStage: ListCreateSubStage;
      subStages: ListSubStage[];
    };
    [ListStage.CONFIGURATION]: {
      activeSubStage: ListConfigureSubStage;
      subStages: ListSubStage[];
    };
  };
}

const InitialListStage: ListStages = {
  activeStage: ListStage.CREATION,
  stages: {
    [ListStage.CREATION]: {
      activeSubStage: ListCreateSubStage.NAME,
      subStages: [
        {
          subStage: ListCreateSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ListCreateSubStage.VALUES,
          stage: 2,
          title: 'Options',
        },
      ],
    },
    [ListStage.CONFIGURATION]: {
      activeSubStage: ListConfigureSubStage.ACCESS,
      subStages: [
        {
          subStage: ListConfigureSubStage.ACCESS,
          stage: 1,
          title: 'Access',
        },
        {
          subStage: ListConfigureSubStage.ADVANCED,
          stage: 2,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialListStage;
