import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { updateActiveDatasetStage } from '../../../../store/dataset-stage';
import { AdditionalDatasetStages } from '../../../../store/dataset-stage/initial-state';
import { showModal } from '../../../../store/modal';
import {
  resetStagesAndSubStages,
  updateActivePipelineSubStage,
} from '../../../../store/pipeline-stage';
import { PipelineCreationSubStage } from '../../../../store/pipeline-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from '../../styled';
import ConditionalJob from './ConditionalJob';
import DatasetJob from './DatasetJob';
import FilterJob from './FilterJob';
import IntegrationJob from './IntegrationJob';
import MappingJob from './MappingJob';
import QueryJob from './QueryJob';

const PREV_STAGE = PipelineCreationSubStage.TRIGGER;

const JobsPipelineStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { pipelineTemplate } = usePipelineTemplate();
  const { getIntegrationTemplates } = useIntegrationTemplate();
  const { datasetMeta } = useDatasetMeta();

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);

  // Fetch integrations if step present
  useEffect(() => {
    if (pipelineTemplate?.jobs.length) {
      (async () => {
        const integrationTemplateIds = pipelineTemplate.jobs.reduce(
          (acc: string[], job) => {
            if (job.jobType !== Enums.PipelineJobType.INTEGRATION) {
              return acc;
            }

            return [
              ...acc,
              (job.jobParams as Interfaces.IntegrationJobParams).integrationId,
            ];
          },
          [],
        );

        if (integrationTemplateIds.length) {
          setLoadingEntities(true);
          await getIntegrationTemplates({
            _id: { $in: integrationTemplateIds },
          });
          setLoadingEntities(false);
        }
      })();
    }
  }, [getIntegrationTemplates, pipelineTemplate?.jobs]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Pipeline jobs are individual steps that power your pipeline.
          </StageBodyText>
          <StageBodyText>
            Data is passed between each step allowing you to create powerful
            automations.
          </StageBodyText>
          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Create Pipeline Job'}
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.PIPELINE_JOBS_MODAL,
                  fullScreen: true,
                }),
              )
            }
          />

          {(pipelineTemplate?.jobs?.length || 0) > 0 &&
            pipelineTemplate?.jobs?.map((job, jobIndex) => (
              <SC.GroupAccordion
                key={`job-${job.name}-${jobIndex}`}
                style={{ marginTop: themeContext.margin.xxlarge }}
              >
                <SC.AccordionTitle
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <SC.AccordionTitleWrapper>
                    <StyledSubHeader>
                      Pipeline Job {jobIndex + 1}: {job.name}{' '}
                      <span
                        style={{ color: themeContext.colors.system.darkGrey }}
                      >
                        - {startCase(job.jobType.toLowerCase())}
                      </span>
                    </StyledSubHeader>

                    <SC.AccordionTitleButtons>
                      <FeatureButton
                        size={FeatureButtonSize.EXTRA_SMALL}
                        color={themeContext.colors.general.blue}
                        icon={
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            color={themeContext.colors.system.white}
                          />
                        }
                        action={() =>
                          dispatch(
                            showModal({
                              visible: true,
                              modal: ModalTypes.PIPELINE_JOBS_MODAL,
                              additionalProps: {
                                jobIndex,
                              },
                              fullScreen: true,
                            }),
                          )
                        }
                      />
                    </SC.AccordionTitleButtons>
                  </SC.AccordionTitleWrapper>
                </SC.AccordionTitle>
                <SC.AccordionContentWrapper active={true}>
                  <div>
                    {job.description && job.description.length > 0 && (
                      <StyledText
                        style={{
                          marginBottom: themeContext.margin.xlarge,
                          textAlign: 'center',
                        }}
                      >
                        {job.description}
                      </StyledText>
                    )}

                    <SC.AccordionContentJobWrapper>
                      {job.jobType === Enums.PipelineJobType.INTEGRATION && (
                        <IntegrationJob job={job} loading={loadingEntities} />
                      )}
                      {job.jobType === Enums.PipelineJobType.MAPPING && (
                        <MappingJob job={job} />
                      )}
                      {job.jobType === Enums.PipelineJobType.DATASET && (
                        <DatasetJob job={job} jobIndex={jobIndex} />
                      )}
                      {job.jobType === Enums.PipelineJobType.CONDITIONAL && (
                        <ConditionalJob job={job} />
                      )}
                      {job.jobType === Enums.PipelineJobType.FILTER && (
                        <FilterJob job={job} />
                      )}
                      {job.jobType === Enums.PipelineJobType.QUERY && (
                        <QueryJob job={job} />
                      )}
                    </SC.AccordionContentJobWrapper>
                  </div>
                </SC.AccordionContentWrapper>
              </SC.GroupAccordion>
            ))}
        </SC.Section>
      </StageInner>
      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save Pipeline'}
            isDisabled={!pipelineTemplate?.jobs.length}
            action={() => {
              dispatch(resetStagesAndSubStages());
              dispatch(
                updateActiveDatasetStage(AdditionalDatasetStages.PIPELINES),
              );
              history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
            }}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActivePipelineSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Triggers'}
          />
        }
      />
    </StageWrapper>
  );
};

export default JobsPipelineStage;
