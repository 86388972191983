import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialTeamStage, {
  TeamCreationSubStage,
  TeamStage,
  TeamSubStages,
} from './initial-state';

const TeamStageSlice = createSlice({
  name: 'teamStage',
  initialState: InitialTeamStage,
  reducers: {
    updateActiveTeamStage(state, action: PayloadAction<TeamStage>) {
      state.activeStage = action.payload;
    },
    updateActiveTeamSubStage(state, action: PayloadAction<TeamSubStages>) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },

    resetStagesAndSubStages(state) {
      state.activeStage = TeamStage.CREATION;
      state.stages[TeamStage.CREATION].activeSubStage =
        TeamCreationSubStage.NAME;
    },
  },
});

export const {
  updateActiveTeamStage,
  updateActiveTeamSubStage,
  resetStagesAndSubStages,
} = TeamStageSlice.actions;

export default TeamStageSlice.reducer;
