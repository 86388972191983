import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PortalService, {
  PortalItem,
  PortalItemOutput,
  PortalOutputWithAdditionalData,
} from '../../services/portal/PortalService';
import { AppThunk } from '../store';
import InitialPortal from './initial-portal';

type Portal = PortalItem | PortalItemOutput | PortalOutputWithAdditionalData;

export interface PortalState {
  data: Portal;
  loading: boolean;
  error: string | null;
}

const initialState: PortalState = {
  data: InitialPortal,
  loading: false,
  error: null,
};

const PortalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    // Reset
    resetPortal(state) {
      state.data = InitialPortal;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchPortalStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPortalSuccess(state, action: PayloadAction<Portal>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchPortalFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createPortalStart(state) {
      state.loading = true;
      state.error = null;
    },
    createPortalSuccess(state, action: PayloadAction<Portal>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createPortalFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updatePortalStart(state) {
      state.loading = true;
      state.error = null;
    },
    updatePortalSuccess(state, action: PayloadAction<Portal>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updatePortalFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deletePortalStart(state) {
      state.loading = true;
      state.error = null;
    },
    deletePortalSuccess(state, action: PayloadAction<Portal>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deletePortalFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetPortal,
  fetchPortalStart,
  fetchPortalSuccess,
  fetchPortalFailure,
  createPortalStart,
  createPortalSuccess,
  createPortalFailure,
  updatePortalStart,
  updatePortalSuccess,
  updatePortalFailure,
  deletePortalStart,
  deletePortalSuccess,
  deletePortalFailure,
} = PortalSlice.actions;

export default PortalSlice.reducer;

export const fetchPortal =
  (token: string, id: string, internal?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchPortalStart());
      const fetched = await PortalService.getPortal(token, id, internal);
      dispatch(fetchPortalSuccess(fetched));
    } catch (err) {
      dispatch(fetchPortalFailure((err as string).toString()));
    }
  };

export const createPortal =
  (
    token: string,
    portal: Interfaces.Portal,
    projectId: string,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPortalStart());
      const created = await PortalService.postPortal(
        token,
        portal,
        projectId,
        userId,
      );
      dispatch(
        createPortalSuccess({
          accessLevel: Enums.AccessLevel.MANAGE,
          entity: created,
        }),
      );
    } catch (err) {
      dispatch(createPortalFailure((err as string).toString()));
    }
  };

export const updatePortal =
  (
    token: string,
    portal: Interfaces.PortalOutput,
    projectId: string,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePortalStart());
      const updated = await PortalService.putPortal(
        token,
        portal,
        projectId,
        userId,
      );
      dispatch(
        updatePortalSuccess({
          accessLevel: Enums.AccessLevel.MANAGE,
          entity: updated,
        }),
      );
    } catch (err) {
      dispatch(updatePortalFailure((err as string).toString()));
    }
  };

export const deletePortal =
  (token: string, portalId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deletePortalStart());
      const deleted = await PortalService.deletePortal(token, portalId, userId);
      dispatch(
        deletePortalSuccess({
          accessLevel: Enums.AccessLevel.MANAGE,
          entity: deleted,
        }),
      );
    } catch (err) {
      dispatch(deletePortalFailure((err as string).toString()));
    }
  };
