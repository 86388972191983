import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType, RouteName } from '../../../../enums';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import { updateActiveIntegrationSubStage } from '../../../../store/integration-stage';
import { IntegrationCreationSubStage } from '../../../../store/integration-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const PREV_STAGE = IntegrationCreationSubStage.ENDPOINTS;

const AccessIntegrationStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { integrationTemplate, editIntegrationTemplate } =
    useIntegrationTemplate();

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (integrationTemplate && updated && type && group) {
      // Update integration template
      const cloned: Interfaces.IntegrationTemplateOutput =
        cloneDeep(integrationTemplate);

      cloned.access[type][group] = updated;

      // Save integration template
      await editIntegrationTemplate(cloned);
    }
  };

  const processAction = async () => {
    if (integrationTemplate) {
      // Save integration template
      dispatch(
        showLoading({
          text: `Saving Integration...`,
        }),
      );
      await editIntegrationTemplate(integrationTemplate);
      dispatch(hideLoading());

      history.push(`${RouteName.INTEGRATION_CENTRE}`);
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Set Permissions</StyledH2>
        <StageBodyText>
          Add users, teams or the whole organisation to the fields below to
          control who can access your integration and what permissions they
          have.
        </StageBodyText>

        <AccessSelection
          access={integrationTemplate?.access}
          onChange={handleChange}
        />
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save & Finish'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveIntegrationSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Endpoints'}
          />
        }
      />
    </StageWrapper>
  );
};

export default AccessIntegrationStage;
