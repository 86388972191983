import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  INTEGRATION_CONFIG_ID,
  INTEGRATION_TEMPLATE_REF,
} from '../../components/Modal/integration-centre/IntegrationManageModal';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums';
import useOAuth from '../../hooks/oauth/UseOAuth';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StyledText } from '../../main/theme';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import { PageInner } from '../styled';

const CODE_KEY = 'code';

const OAuthCallback: FC = () => {
  const history = useHistory();
  const { handleCallback } = useOAuth();
  const { organisation } = useOrganisation();

  const [callbackComplete, setCallbackComplete] = useState<boolean>(false);

  const search = location.search;
  const authCode = new URLSearchParams(search).get(CODE_KEY);

  useEffect(() => {
    const integrationTemplateRef = localStorage.getItem(
      `${INTEGRATION_TEMPLATE_REF}`,
    );
    const integrationConfigId = localStorage.getItem(
      `${INTEGRATION_CONFIG_ID}`,
    );
    if (!integrationTemplateRef || !integrationConfigId) {
      return;
    }

    (async () => {
      const updatedIntegration = await handleCallback(
        JSON.parse(integrationTemplateRef),
        JSON.parse(integrationConfigId),
        window.location.href,
        authCode || undefined,
      );
      if (updatedIntegration) {
        setCallbackComplete(true);
      }
    })();
  }, [authCode, handleCallback]);

  useEffect(() => {
    if (callbackComplete) {
      localStorage.removeItem(`${INTEGRATION_TEMPLATE_REF}`);
      localStorage.removeItem(`${INTEGRATION_CONFIG_ID}`);

      const customDomain = organisation?.customDomain;

      if (!customDomain) {
        history.push(RouteName.INTEGRATION_CENTRE);
      }

      window.location.href = `${customDomain}${RouteName.INTEGRATION_CENTRE}`;
    }
  }, [callbackComplete, history, organisation?.customDomain]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.OAUTH_CALLBACK} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={AvatarIconMap.password}
          title={'Integration Authentication'}
        />

        <Page>
          <PageInner>
            <StyledText>
              Configuring Integration Authentication. You will be redirected
              shortly.
            </StyledText>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default OAuthCallback;
