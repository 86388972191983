import { Enums } from '@configur-tech/upit-core-types';
import { AggregationType } from '@configur-tech/upit-core-types/lib/enums';
import { QueryItem } from '../queries';

const InitialQuery: QueryItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    queryParams: {
      aggregationType: AggregationType.SINGLE,
      fields: [],
      filters: [],
      groups: [],
      measures: [],
      sort: [],
      displayOrder: [],
    },
  },
};

export default InitialQuery;
