import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ApiRequestType } from '@configur-tech/upit-core-types/lib/enums';
import { IntegrationTemplateItem } from '../../services/integration/IntegrationTemplateService';

export const initialBaseEndpoint: Interfaces.IntegrationEndpoint = {
  name: '',
  description: '',
  baseUrl: '',
  endpoints: [],
};

export const initialRequestEndpoint: Interfaces.RequestEndpoint = {
  name: '',
  description: '',
  path: '',
  httpMethod: ApiRequestType.GET,
};

export const initialSchemaRowState: Interfaces.SchemaField = {
  field: '',
  type: [],
};
export const initialResponseOptions: Interfaces.ResponseInfo = {
  isArray: false,
  dataInTopLevel: true,
  dataFieldKey: '',
  paginationNextPageKey: '',
  responseSchema: [],
};

export const initialRequestParamsState: Interfaces.RequestParams = {
  queryParams: [],
  headers: [],
  pathParams: [],
};

export const initialQueryParamsRowState: Interfaces.EndpointParam = {
  field: '',
  value: {} as Interfaces.DynamicConditionalValue,
  type: [],
};
export const initialHeadersRowState: Interfaces.EndpointParam = {
  field: '',
  value: {} as Interfaces.DynamicConditionalValue,
  type: [],
};
export const initialPathParamsRowState: Interfaces.EndpointParam = {
  field: '',
  value: {} as Interfaces.DynamicConditionalValue,
  type: [],
};

const InitialIntegrationTemplate: IntegrationTemplateItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    description: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    isPublic: false,
    status: Enums.IntegrationStatus.ACTIVE,
    integrationType: Enums.IntegrationType.HTTP,
    endpoints: [],
    customConfig: [],
    customActions: [],
  },
};

export default InitialIntegrationTemplate;
