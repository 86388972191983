import styled from 'styled-components';

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.small}`};
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 20%;

  > p {
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 2;
  > .input {
    width: 400px;
  }
`;

export const ViewWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 20%;
`;
