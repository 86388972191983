import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import useFilterInput from '../../../../hooks/filter/useFilterInput';
import { StyledBodySubHeader, StyledDropdown } from '../../../../main/theme';
import * as SC from '../../../Modal/pipeline/styled';
import { ParamType } from './EndpointParamsAccordion';

const VALUE_KEY = 'value';

interface DynamicFieldProps {
  field?: Interfaces.SchemaField;
  customField?: Interfaces.SchemaField;
  dynamicFields: Interfaces.SchemaField[];
  showDynamicField: boolean;
  handleFieldChange: (
    index: number,
    key: string,
    value: unknown,
    paramType?: ParamType,
  ) => void;
  paramType: string;
  index: number;
  value: Interfaces.FieldConditionalValue | Interfaces.ConstantConditionalValue;
}

const DynamicField: FC<DynamicFieldProps> = ({
  field,
  customField,
  dynamicFields,
  showDynamicField,
  handleFieldChange,
  paramType,
  index,
  value,
}) => {
  const { buildFilterInput } = useFilterInput();

  const dynamicFieldOptions = dynamicFields
    ?.filter(
      (opt) =>
        opt.type.find((opt) =>
          !field || field.type.includes(Enums.ValueDataType.TEXT)
            ? true
            : field.type.includes(opt as Enums.ValueDataType),
        ) &&
        (opt.constraints?.format
          ? opt.constraints.format === field?.constraints?.format
          : true),
    )
    ?.map((m) => ({
      key: m.fieldId || m.field,
      text: `Value of "${m.field}"`,
      value: m.fieldId || m.field,
    }));

  const handleFilterInputChange = (value: unknown) => {
    handleFieldChange(index, VALUE_KEY, value);
  };

  return (
    <SC.InputContainer>
      <StyledBodySubHeader>Value</StyledBodySubHeader>
      {!showDynamicField && (
        <>
          {field &&
            buildFilterInput(
              Mappers.SchemaFieldMapper.domainSchemaToDbSchema(field),
              (field?.type?.[0] as Enums.ValueDataType) ||
                Enums.ValueDataType.TEXT,
              (value as Interfaces.ConstantConditionalValue).value || '',
              handleFilterInputChange,
            )}

          {customField &&
            buildFilterInput(
              Mappers.SchemaFieldMapper.domainSchemaToDbSchema(customField),
              (customField?.type?.[0] as Enums.ValueDataType) ||
                Enums.ValueDataType.TEXT,
              (value as Interfaces.ConstantConditionalValue).value || '',
              (value) =>
                handleFieldChange(
                  index,
                  VALUE_KEY,
                  value,
                  paramType as ParamType,
                ),
            )}
        </>
      )}

      {showDynamicField && !!dynamicFieldOptions?.length && (
        <StyledDropdown
          search
          selectOnBlur={false}
          placeholder={'Select value'}
          options={dynamicFieldOptions}
          selection
          value={(value as Interfaces.FieldConditionalValue)?.field || ''}
          onChange={(e, { value }) => {
            handleFieldChange(index, VALUE_KEY, value, paramType as ParamType);
          }}
          style={{ margin: 0 }}
          upward={true}
        />
      )}
    </SC.InputContainer>
  );
};

export default DynamicField;
