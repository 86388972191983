export enum EntityType {
  AGGREGATION = 'query',
  AGGREGATION_MEASURE = 'measure',
  API = 'api',
  API_KEY = 'api key',
  CMS = 'cms',
  COLUMN = 'column',
  DATASET = 'dataset',
  DATA_GROUP = 'data group',
  DATA_LINK = 'data link',
  DATA_VIEW = 'workspace',
  FORM = 'form',
  INTEGRATION = 'integration',
  LIST = 'list',
  ORGANISATION = 'organisation',
  PIPELINE = 'pipeline',
  PORTAL = 'portal',
  PROJECT = 'project',
  TEAM = 'team',
  SUPER_COLUMN = 'super column',
  USER = 'user',
  CHART = 'dashboard',
  GRAPH = 'graph',
  NOTIFICATION = 'notification',
}
