import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  defaultTheme,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import {
  initialBaseJob,
  initialConditionalJobParams,
  initialDatasetJobParams,
  initialFilterJobParams,
  initialIntegrationJobParams,
  initialMappingJobParams,
  initialQueryJobParams,
} from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import PipelineJobOptionBuilder from '../../../util/pipeline-job/PipelineJobOptionBuilder';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import JobConditionalModalComponent from './JobConditionalModalComponent';
import JobDatasetModalComponent from './JobDatasetModalComponent';
import JobFilterModalComponent from './JobFilterModalComponent';
import JobIntegrationModalComponent from './JobIntegrationModalComponent';
import JobMappingModalComponent from './JobMappingModalComponent';
import * as SC from './styled';
import JobQueryModalComponent from './JobQueryModalComponent';

export interface PipelineJobsModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  pipelineTemplate: Interfaces.Pipeline;
  jobIndex: number;
}

const PipelineJobsModal: FC<PipelineJobsModalProps> = ({
  setShowModal,
  jobIndex,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { pipelineTemplate, editPipelineTemplate } = usePipelineTemplate();
  const { loggedInUser } = useLoggedInUser();

  const isEditing = !!jobIndex || jobIndex == 0;

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplates: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const [pipelineJob, setPipelineJob] =
    useState<Interfaces.PipelineJob>(initialBaseJob);

  const [jobTypeOptions, setJobTypeOptions] = useState<DropdownItemProps[]>([]);

  const [datasetJobParams, setDatasetJobParams] =
    useState<Interfaces.DatasetJobParams>(initialDatasetJobParams);

  const [integrationJobParams, setIntegrationJobParams] =
    useState<Interfaces.IntegrationJobParams>(initialIntegrationJobParams);

  const [mappingJobParams, setMappingJobParams] =
    useState<Interfaces.MappingJobParams>(initialMappingJobParams);

  const [conditionalJobParams, setConditionalJobParams] =
    useState<Interfaces.ConditionalJobParams>(initialConditionalJobParams);

  const [filterJobParams, setFilterJobParams] =
    useState<Interfaces.FilterJobParams>(initialFilterJobParams);

  const [queryJobParams, setQueryJobParams] =
    useState<Interfaces.QueryJobParams>(initialQueryJobParams);

  const [customActionParams, setCustomActionParams] =
    useState<Interfaces.EndpointParam[]>();

  // If editing, get previous data
  useMemo(() => {
    if (isEditing) {
      const jobQueryIndex = jobIndex.toString();
      if (pipelineTemplate?.jobs[jobQueryIndex]) {
        setPipelineJob(pipelineTemplate?.jobs[jobQueryIndex]);
      }
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // Build Job Type dropdown depending on Trigger Type selected previously
  useEffect(() => {
    if (pipelineTemplate?.trigger) {
      setJobTypeOptions(
        PipelineJobOptionBuilder(
          pipelineTemplate.trigger,
          pipelineTemplate.jobs,
          jobIndex,
        ),
      );
    }
  }, [jobIndex, pipelineTemplate?.jobs, pipelineTemplate?.trigger]);

  // Set any custom action params
  useEffect(() => {
    if (pipelineJob) {
      const targetIntegration = integrationTemplates.find(
        (int) =>
          int.entity._id ===
          (pipelineJob.jobParams as Interfaces.IntegrationJobParams)
            .integrationId,
      );

      const customAction = targetIntegration?.entity.customActions.find(
        (action) =>
          action._id ===
          (pipelineJob.jobParams as Interfaces.IntegrationJobParams)
            .integrationCustomActionId,
      );

      if (customAction?.actionParams) {
        setCustomActionParams(customAction.actionParams);
      }
    }
  }, [integrationTemplates, pipelineJob]);

  // Handle General input change
  const handleGeneralInputChange = (field: string, value: string) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[field] = value;

    cloned['jobAllowedFailure'] =
      field === 'jobType' &&
      [
        Enums.PipelineJobType.FILTER,
        Enums.PipelineJobType.CONDITIONAL,
        Enums.PipelineJobType.QUERY,
      ].includes(value as Enums.PipelineJobType);

    setPipelineJob(cloned);
  };

  // Handle Delete
  const deleteJob = async () => {
    const cloned = cloneDeep(pipelineTemplate);
    cloned.jobs.splice(jobIndex, 1);

    dispatch(showLoading({ text: 'Deleting Pipeline Job...' }));

    await editPipelineTemplate(cloned);

    dispatch(hideLoading());
    dispatch(hideModal());
  };

  // Confirm configuration is complete
  const configurationComplete = useCallback((): boolean => {
    if (!pipelineJob) {
      return false;
    }

    const completeGeneral = !!(pipelineJob.name && pipelineJob.jobType);

    let completeParams = false;

    switch (pipelineJob.jobType) {
      case Enums.PipelineJobType.DATASET: {
        const dataJobParams = datasetJobParams;

        completeParams = !!(
          dataJobParams.datasetMetaId && dataJobParams.action
        );

        if (
          [
            Enums.DatasetJobAction.UPDATE,
            Enums.DatasetJobAction.DELETE,
          ].includes(dataJobParams.action) &&
          !dataJobParams.query?.conditions?.length
        ) {
          completeParams = false;
        }

        break;
      }
      case Enums.PipelineJobType.INTEGRATION: {
        const intJobParams = integrationJobParams;

        completeParams = !!(
          intJobParams.integrationId &&
          ((intJobParams.integrationEndpointId &&
            intJobParams.integrationRequestEndpointId) ||
            (intJobParams.integrationId &&
            intJobParams.integrationCustomActionId &&
            !customActionParams
              ? true
              : customActionParams
                  ?.filter((param) => param.constraints?.isRequired)
                  ?.every((param) =>
                    intJobParams.customConfig?.find((config) => {
                      return !!(
                        config.field === param.field &&
                        ((
                          (config.value as Interfaces.ConstantConditionalValue)
                            ?.value as string
                        )?.length ||
                          (config.value as Interfaces.FieldConditionalValue)
                            ?.field?.length)
                      );
                    }),
                  )))
        );
        break;
      }
      case Enums.PipelineJobType.MAPPING: {
        const mapJobParams = mappingJobParams;

        completeParams = !!(
          mapJobParams.input &&
          mapJobParams.input.type &&
          mapJobParams.input.entityId &&
          (mapJobParams.input.type === Enums.MappingSourceType.INTEGRATION
            ? (mapJobParams.input.baseEndpointId &&
                mapJobParams.input.requestEndpointId) ||
              mapJobParams.input.customActionId
            : true) &&
          mapJobParams.output &&
          mapJobParams.output.type &&
          mapJobParams.output.entityId &&
          (mapJobParams.output.type === Enums.MappingSourceType.INTEGRATION
            ? (mapJobParams.output.baseEndpointId &&
                mapJobParams.output.requestEndpointId) ||
              mapJobParams.output.customActionId
            : true) &&
          mapJobParams.mappings.length
        );
        break;
      }

      case Enums.PipelineJobType.CONDITIONAL: {
        const condJobParams = conditionalJobParams;

        const conditionalJob = (
          condJobParams as Interfaces.ConditionalJobParams
        ).condition[0]?.value as Interfaces.DynamicConditionalField[];

        completeParams = !!(
          condJobParams.matchType &&
          condJobParams.condition &&
          condJobParams.condition.length &&
          conditionalJob?.length &&
          conditionalJob?.every(
            (c: Interfaces.DynamicConditionalField) =>
              c.operator &&
              (Array.isArray(c.value)
                ? c.value.length
                : (
                    (c.value as Interfaces.ConstantConditionalValue)
                      ?.value as string
                  )?.toString()?.length),
          )
        );
        break;
      }

      case Enums.PipelineJobType.FILTER: {
        const filJobParams = filterJobParams;

        const filterJob = (filJobParams as Interfaces.FilterJobParams)
          .condition[0]?.value as Interfaces.DynamicConditionalField[];

        completeParams = !!(
          filJobParams.condition &&
          filJobParams.condition.length &&
          filterJob?.length &&
          filterJob?.every(
            (c: Interfaces.DynamicConditionalField) =>
              c.operator &&
              (Array.isArray(c.value)
                ? c.value.length
                : (
                    (c.value as Interfaces.ConstantConditionalValue)
                      ?.value as string
                  )?.toString()?.length),
          )
        );
        break;
      }

      case Enums.PipelineJobType.QUERY: {
        completeParams = !!queryJobParams.queryId;
        break;
      }
    }

    return completeGeneral && completeParams;
  }, [
    conditionalJobParams,
    customActionParams,
    datasetJobParams,
    filterJobParams,
    integrationJobParams,
    mappingJobParams,
    pipelineJob,
    queryJobParams,
  ]);

  // Handle Submission
  const processAction = async () => {
    dispatch(showLoading({ text: 'Updating Pipeline...' }));
    if (loggedInUser) {
      const cloned = cloneDeep(pipelineTemplate);
      const metaSubmission = {
        created: new Date(),
        createdBy: loggedInUser._id,
        lastUpdated: new Date(),
        lastUpdatedBy: loggedInUser._id,
      };
      let params;
      switch (pipelineJob.jobType) {
        case Enums.PipelineJobType.DATASET:
          params = datasetJobParams;
          break;
        case Enums.PipelineJobType.INTEGRATION:
          params = integrationJobParams;
          break;
        case Enums.PipelineJobType.MAPPING:
          params = mappingJobParams;
          break;
        case Enums.PipelineJobType.CONDITIONAL:
          params = conditionalJobParams;
          break;
        case Enums.PipelineJobType.FILTER:
          params = filterJobParams;
          break;
        case Enums.PipelineJobType.QUERY:
          params = queryJobParams;
          break;
      }

      // Update dependencies
      const jobDependencies = !isEditing
        ? pipelineTemplate?.jobs.length
          ? [pipelineTemplate?.jobs[pipelineTemplate.jobs.length - 1]._id]
          : []
        : pipelineTemplate?.jobs.length && jobIndex !== 0
        ? [pipelineTemplate?.jobs[jobIndex - 1]._id]
        : [];

      const jobSubmission: Interfaces.PipelineJob = {
        ...pipelineJob,
        meta: metaSubmission,
        jobParams: params,
        jobIdsJobDependsOn: jobDependencies,
      };

      if (isEditing) {
        cloned.jobs[jobIndex] = jobSubmission;
      } else {
        cloned.jobs.push(jobSubmission);
      }

      await editPipelineTemplate(cloned);
      dispatch(hideModal());
      dispatch(hideLoading());
    }
  };

  const getCurrentEntityId = (): string => {
    const selectedJob =
      pipelineTemplate?.jobs[
        jobIndex !== undefined ? jobIndex : pipelineTemplate?.jobs?.length - 1
      ];

    const jobEntityId = selectedJob?.jobParams['datasetMetaId' || 'entityId'];

    if (!jobEntityId) {
      const dep = selectedJob?.jobIdsJobDependsOn?.[0];

      if (dep) {
        const depJob = pipelineTemplate?.jobs.find((job) => job._id === dep);
        return depJob?.jobParams['datasetMetaId' || 'entityId'] as string;
      }
    }

    return jobEntityId as string;
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>{isEditing ? 'Update' : 'Create'} Pipeline Job</SC.Header>

        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>
      <SC.ContentContainer>
        <SC.Content>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xlarge,
            }}
          >
            General
          </StyledSubHeader>
          <StyledText
            style={{
              marginBottom: themeContext.margin.xxlarge,
            }}
          >
            First, name your job and then tell us what type you want to create.
          </StyledText>

          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader>Name</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a name'}
                value={pipelineJob?.name || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('name', value)
                }
              />
            </SC.InputContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Description</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a description'}
                value={pipelineJob?.description || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('description', value)
                }
              />
            </SC.InputContainer>
          </SC.InputWrapper>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xxxlarge,
            }}
          >
            Pipeline Job Options
          </StyledSubHeader>
          <StyledText>
            Select the type of pipeline job you'd like to create and then
            configure it.
          </StyledText>
          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader
                style={{ marginTop: themeContext.margin.xlarge }}
              >
                Job Type
              </StyledBodySubHeader>
              <StyledDropdown
                selectOnBlur={false}
                upward={true}
                selection
                value={pipelineJob.jobType || ''}
                placeholder="Please select a job type"
                options={jobTypeOptions}
                style={{ marginTop: 0 }}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('jobType', value)
                }
              />
            </SC.InputContainer>
          </SC.InputWrapper>
          {pipelineJob.jobType === Enums.PipelineJobType.MAPPING && (
            <JobMappingModalComponent
              jobIndex={jobIndex}
              onChange={setMappingJobParams}
            />
          )}
          {pipelineJob.jobType === Enums.PipelineJobType.INTEGRATION && (
            <JobIntegrationModalComponent
              jobIndex={jobIndex}
              onChange={setIntegrationJobParams}
            />
          )}
          {pipelineJob.jobType === Enums.PipelineJobType.DATASET && (
            <JobDatasetModalComponent
              jobIndex={jobIndex}
              onChange={setDatasetJobParams}
            />
          )}
          {pipelineJob.jobType === Enums.PipelineJobType.CONDITIONAL && (
            <JobConditionalModalComponent
              jobIndex={jobIndex}
              onChange={setConditionalJobParams}
              datasetMetaId={getCurrentEntityId()}
            />
          )}

          {pipelineJob.jobType === Enums.PipelineJobType.FILTER && (
            <JobFilterModalComponent
              jobIndex={jobIndex}
              onChange={setFilterJobParams}
              datasetMetaId={getCurrentEntityId()}
            />
          )}

          {pipelineJob.jobType === Enums.PipelineJobType.QUERY && (
            <JobQueryModalComponent
              jobIndex={jobIndex}
              onChange={setQueryJobParams}
            />
          )}
        </SC.Content>
      </SC.ContentContainer>
      <SC.ActionButtonWrapper multipleButtons={isEditing}>
        {isEditing && (
          <FeatureButton
            action={deleteJob}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={'Delete'}
          />
        )}
        <FeatureButton
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Save'}
          action={processAction}
          isDisabled={!configurationComplete()}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default PipelineJobsModal;
