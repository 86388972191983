import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';

export const getLookupFieldType = (
  lookupField: Interfaces.Field,
  datasetMetas: DatasetMetaItemOutput[],
): Enums.ValueDataType | undefined => {
  const lookupDetail = lookupField.dataValidation?.fieldLookup;
  const lookupDsm = datasetMetas.find(
    (d) => d.entity._id === lookupDetail?.target.datasetMetaId,
  );
  const lookupSchema = lookupDsm?.entity.dataCollections.find(
    (c) => c._id === lookupDsm.entity.activeDataCollection,
  );
  return lookupSchema?.schemaData.find(
    (f) => f.fieldId === lookupDetail?.target.outputFieldId,
  )?.dataValidation?.dataValidationType;
};
