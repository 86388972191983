import accessIcon from '../../assets/icons/stages/configuration/access.svg';
import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import optionsIcon from '../../assets/icons/stages/create-dataset/options.svg';
import createIcon from '../../assets/icons/stages/create-project/create.svg';

import {
  ListConfigureSubStage,
  ListCreateSubStage,
  ListStage,
  ListSubStages,
} from '../../store/list-stage/initial-state';
import { SubStageContent } from '../stage-content/StageHeaderContent';

interface ListStageMeta {
  [key: string]: {
    title: string;
  };
}

interface ListStageContent {
  stage: number;
  image: string;
  title: string;
}

interface ListSubStageContent {
  [ListStage.CREATION]: {
    subStages: Record<string, SubStageContent>;
  };
  [ListStage.CONFIGURATION]: {
    subStages: Record<string, SubStageContent>;
  };
}

const listStageMeta: ListStageMeta = {
  [ListStage.CREATION]: {
    title: 'Create List',
  },
  [ListStage.CONFIGURATION]: {
    title: 'Configuration',
  },
};

export const getListStageTitle = (stage: ListStage): string => {
  return listStageMeta[stage].title;
};

export const getListStageContent = (stage: ListStage): ListStageContent => {
  const listStageContent = {
    [ListStage.CREATION]: {
      stage: 1,
      image: createIcon,
      title: `Give That List A Title`,
    },
    [ListStage.CONFIGURATION]: {
      stage: 1,
      image: configurationAdvancedIcon,
      title: 'Configuration',
    },
  };

  return listStageContent[stage] || listStageContent[0];
};

export const getListSubStageContent = (
  stage: ListStage,
  subStage: ListSubStages,
): SubStageContent => {
  const subStageContent: ListSubStageContent = {
    [ListStage.CREATION]: {
      subStages: {
        [ListCreateSubStage.NAME]: {
          stage: 1,
          image: createIcon,
          title: `Give That List A Title`,
        },
        [ListCreateSubStage.VALUES]: {
          stage: 2,
          image: optionsIcon,
          title: `So Many Choices`,
        },
      },
    },
    [ListStage.CONFIGURATION]: {
      subStages: {
        [ListConfigureSubStage.ACCESS]: {
          stage: 1,
          image: accessIcon,
          title: `Access Control`,
        },
        [ListConfigureSubStage.ADVANCED]: {
          stage: 2,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
