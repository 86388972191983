import { Interfaces } from '@configur-tech/upit-core-types';
import {
  FormulaOperandType,
  FormulaOperation,
} from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import formulaOperationDisplayMap from '../../util/build-formula/FormulaOperationDisplayMap';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const NEST_START = '(';
const NEST_END = ')';

interface LogicOperatorInputsProps {
  addFormulaItem: (item: Interfaces.FormulaOperandValue) => void;
  endNesting: () => void;
  operatorStatus: boolean;
  startNestStatus: boolean;
  endNestStatus: boolean;
}

const LogicOperatorInputs: FC<LogicOperatorInputsProps> = ({
  addFormulaItem,
  endNesting,
  operatorStatus,
  startNestStatus,
  endNestStatus,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <FeatureButton
        isDisabled={!operatorStatus}
        action={() =>
          addFormulaItem({
            type: FormulaOperandType.OPERATION,
            value: { operation: FormulaOperation.ADD },
          })
        }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.purple}
        text={formulaOperationDisplayMap(FormulaOperation.ADD)}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
      <FeatureButton
        isDisabled={!operatorStatus}
        action={() =>
          addFormulaItem({
            type: FormulaOperandType.OPERATION,
            value: { operation: FormulaOperation.SUBTRACT },
          })
        }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.purple}
        text={formulaOperationDisplayMap(FormulaOperation.SUBTRACT)}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
      <FeatureButton
        isDisabled={!operatorStatus}
        action={() =>
          addFormulaItem({
            type: FormulaOperandType.OPERATION,
            value: { operation: FormulaOperation.MULTIPLY },
          })
        }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.purple}
        text={formulaOperationDisplayMap(FormulaOperation.MULTIPLY)}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
      <FeatureButton
        isDisabled={!operatorStatus}
        action={() =>
          addFormulaItem({
            type: FormulaOperandType.OPERATION,
            value: { operation: FormulaOperation.DIVIDE },
          })
        }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.purple}
        text={formulaOperationDisplayMap(FormulaOperation.DIVIDE)}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
      <FeatureButton
        isDisabled={!startNestStatus}
        action={() =>
          addFormulaItem({
            type: FormulaOperandType.NESTED,
            value: { formula: [] },
          })
        }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.yellow}
        text={NEST_START}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
      <FeatureButton
        isDisabled={!endNestStatus}
        action={() => endNesting()}
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.yellow}
        text={NEST_END}
        textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
      />
    </>
  );
};

export default LogicOperatorInputs;
