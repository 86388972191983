import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ListService, {
  ListItemOutput,
  ListsItemOutput,
} from '../../services/list/ListService';
import {
  createList,
  deleteList,
  fetchList,
  updateList,
} from '../../store/list';
import {
  fetchLists,
  fetchListsForResource,
  fetchListsSuccess,
} from '../../store/lists';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useListResult {
  list?: Interfaces.ListOutput;
  listAccessLevel?: Enums.AccessLevel;

  getLists: (query?: Record<string, unknown>) => Promise<void>;
  getListsForResource: (
    resourceType: Enums.SchemaName,
    resourceId: string,
  ) => Promise<void>;
  getListsForMultipleResources: (
    resourceType: Enums.SchemaName,
    resourceId: string[],
  ) => void;
  getList: (listId: string) => void;
  addList: (listObj: Interfaces.List, portalId?: string) => void;
  editList: (listObj: Interfaces.ListOutput, portalId?: string) => void;
  removeList: (listId: string, portalId?: string) => void;
}

const useList = (): useListResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [list, setList] = useState<Interfaces.ListOutput>();
  const [listAccessLevel, setListAccessLevel] = useState<Enums.AccessLevel>();

  const listsState = useSelector((state: RootState) => state.lists);
  const listState = useSelector((state: RootState) => state.list);
  const listObj = listState.data as ListItemOutput;
  const listsObj = listsState.data as ListsItemOutput;

  const getLists = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          await dispatch(fetchLists(token, query));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load lists.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getListsForResource = useCallback(
    async (resourceType: Enums.SchemaName, resourceId: string) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          await dispatch(
            fetchListsForResource(token, resourceType, resourceId),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load lists.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getListsForMultipleResources = useCallback(
    async (resourceType: Enums.SchemaName, resourceId: string[]) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        const combinedLists = cloneDeep(listsState);

        await Promise.all(
          resourceId.map(async (id) => {
            try {
              const fetched = await ListService.getListsForResource(
                token,
                resourceType,
                id,
              );

              if (fetched.data?.data) {
                combinedLists.data.data = [
                  ...combinedLists.data.data,
                  ...(fetched.data.data as ListItemOutput[]),
                ];
              }
            } catch (err) {
              if (
                (err as AxiosError)?.response?.status !==
                Enums.StatusCode.NOT_FOUND
              ) {
                toast.error(
                  `${
                    (err as AxiosError)?.response?.data?.statusCode || 'Error'
                  } - Failed to load lists.`,
                );
              }
            }
          }),
        );

        dispatch(
          fetchListsSuccess(combinedLists.data as unknown as ListsItemOutput),
        );
      }
    },
    [dispatch, getAccessTokenSilently, listsState, loggedInUser],
  );

  const getList = useCallback(
    async (listId: string) => {
      const token = await getAccessTokenSilently();

      if (token && listId) {
        try {
          await dispatch(fetchList(token, listId));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load list.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addList = useCallback(
    async (listObj: Interfaces.List, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && listObj && loggedInUser) {
        // Remove values from Dataset lists
        const processedList: Interfaces.List = cloneDeep(listObj);
        if (processedList.listType === Enums.ListType.DATASET) {
          delete processedList.values;
        }

        try {
          await dispatch(
            createList(token, processedList, loggedInUser._id, portalId),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to add list.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editList = useCallback(
    async (listObj: Interfaces.ListOutput, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && listObj && loggedInUser) {
        // Remove values from Dataset lists
        const processedList: Interfaces.ListOutput = cloneDeep(listObj);
        if (processedList.listType === Enums.ListType.DATASET) {
          delete processedList.values;
        }

        try {
          await dispatch(
            updateList(token, processedList, loggedInUser._id, portalId),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit list.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeList = useCallback(
    async (listId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && listId && loggedInUser) {
        try {
          await dispatch(deleteList(token, listId, loggedInUser._id, portalId));

          // Remove project from current lists
          const cloned = cloneDeep(listsObj);
          cloned.data = cloned.data.filter((e) => e.entity._id !== listId);

          await dispatch(fetchListsSuccess(cloned));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to delete list.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, listsObj],
  );

  useEffect(() => {
    if (listObj) {
      // Complete model
      setList(listObj.entity);
      setListAccessLevel(listObj.accessLevel);
    }
  }, [listObj]);

  return {
    list,
    listAccessLevel,

    getLists,
    getListsForResource,
    getListsForMultipleResources,
    getList,
    addList,
    editList,
    removeList,
  };
};

export default useList;
