import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

export const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 50vh;

  padding: ${({ theme }) => theme.margin.xlarge}
    ${({ theme }) => theme.margin.xlarge} 0;

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ColumnWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;
