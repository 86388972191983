import styled from 'styled-components';
import { StyledDropdown, StyledText } from '../../main/theme';

interface TableCellProps {
  isError?: boolean;
  isComplete?: boolean;
  isActive?: boolean;
  isLiveEditing?: boolean;
  isBold?: boolean;
  textColor?: string;
}

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const Outer = styled.div<{ fullWidth: boolean }>`
  display: block;
  ${({ fullWidth }) => (fullWidth ? `width: 100%;` : '')};
  max-width: 100%;
  margin-top: ${({ theme }) => `${theme.margin.large}`};
`;

export const Wrapper = styled.div<{
  noBorder?: boolean;
  fixedHeight?: number;
  fixedHeightReduction?: number;
  showBanner?: boolean;
}>`
  display: block;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;

  border: ${({ theme, noBorder }) =>
    noBorder ? '' : `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme }) => theme.borders.radius};

  height: ${({ fixedHeight, fixedHeightReduction }) =>
    fixedHeightReduction
      ? `calc(100vh - ${fixedHeightReduction}px)`
      : fixedHeight
      ? ` ${fixedHeight}px`
      : 'none'};
`;

export const Table = styled.table`
  width: 100%;
  background: ${({ theme }) => theme.colors.system.white};
  border-spacing: 0;
`;

export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  position: sticky;
  top: 0;
  z-index: 4;
`;

export const TableRow = styled.tr<{
  isClickable: boolean;
  lastItem?: boolean;
  isLiveEditing?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  transition: opacity 0.5s;

  td {
    background-color: ${({ theme }) => theme.colors.system.white};
  }

  &:nth-of-type(even) td {
    background-color: ${({ theme }) => theme.colors.system.offWhite};
  }

  ${({ lastItem }) =>
    lastItem
      ? `&:last-of-type {
          border-bottom: none;
        }`
      : ''};

  ${({ isClickable, isLiveEditing }) =>
    isClickable && !isLiveEditing
      ? `&:hover {
          cursor: pointer;
          opacity: 0.3;
        }`
      : ''};
`;

export const TableHeader = styled.th<{
  sortable: boolean;
  isSorted: boolean;
  $$isActive?: boolean;
  $$hasDropdown?: boolean;
  $$hasAdditionalIcons?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 65px;

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  color: ${({ theme }) => theme.colors.system.offBlack};

  ${({ theme }) => theme.typography.header};

  padding: ${({ theme, $$hasDropdown, $$hasAdditionalIcons }) =>
    $$hasDropdown && !$$hasAdditionalIcons
      ? `0 ${theme.padding.standard} 0 ${theme.padding.xxlarge}`
      : `0 ${theme.padding.standard}`};

  border-right: 1px solid ${({ theme }) => theme.colors.system.grey};

  transition: opacity 0.5s;

  z-index: ${({ $$isActive }) => $$isActive && '4 !important'};

  &:last-of-type {
    border-right: none;
  }

  &[data-sticky-first-right-td='true'] {
    border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
    background-color: ${({ theme }) => theme.colors.system.offWhite};
  }

  > svg {
    color: white;
    margin-bottom: 10px;
  }

  &:first-of-type {
    > svg {
      margin: 0;
    }
  }
`;

export const TableHeaderIcon = styled.div<{
  iconColour?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15px;

  > svg {
    color: ${({ iconColour, theme }) =>
      iconColour ? iconColour : `${theme.colors.system.grey}`};
  }
`;

export const Resizer = styled.div<{ lastItem: boolean; isResizing?: boolean }>`
  display: inline-block;
  background-color: ${({ theme, isResizing }) =>
    isResizing ? theme.colors.general.purple : theme.colors.general.blue};
  opacity: 0;
  width: 6px;
  height: 100%;
  position: absolute;
  right: ${({ lastItem }) => (lastItem ? '3px' : 0)};
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  transition: opacity 0.25s, background-color 0.25s;

  &:hover {
    opacity: 100;
  }
`;

export const HeaderCell = styled.div<{ hasAdditionalIcons?: boolean }>`
  flex-grow: 2;
  padding: ${({ theme }) => `${theme.padding.standard} 0`};
`;

export const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge}`};
`;

export const TableCell = styled.td<TableCellProps>`
  border-right: 1px solid ${({ theme }) => theme.colors.system.grey};

  padding: ${({ theme, isLiveEditing }) =>
    isLiveEditing ? 0 : theme.padding.standard};

  text-align: center;
  word-wrap: break-word;

  ${({ textColor }) => textColor && `color: ${textColor} ;`};

  ${({ color, theme }) =>
    color &&
    `color: ${theme.colors.system.white}; background-color: ${color} !important`};

  ${({ isError, isComplete, theme }) =>
    (isError || isComplete) &&
    `padding: 0;
     background-color: ${
       isComplete ? theme.colors.general.green : theme.colors.general.red
     }  !important;`};

  ${({ isActive, theme }) =>
    isActive &&
    `background-color: ${theme.colors.general.purple}  !important; 
    color: ${theme.colors.system.offWhite} `};

  > a {
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.3;
    }
  }

  &:first-of-type {
    ${({ theme }) => theme.typography.header};
    padding: ${({ theme }) => theme.padding.standard};
    background-color: ${({ theme }) => theme.colors.system.offWhite};
  }

  ${({ theme, isBold }) => isBold && `${theme.typography.header}`}
  padding: ${({ theme, isBold }) => isBold && theme.padding.standard};

  &:last-of-type {
    border-right: none;
  }

  &[data-sticky-first-right-td='true'] {
    border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
    background-color: ${({ theme }) => theme.colors.system.offWhite};
  }

  &:last-of-type {
    ${({ theme, isBold }) =>
      isBold &&
      `${theme.typography.header}; padding: ${theme.padding.standard}`}
  }
`;

export const SkipPageDropdown = styled(StyledDropdown)`
  margin: 0;
  width: 80px;
  min-width: 80px !important;
  max-width: 80px !important;
  text-align: center !important;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${({ theme }) => theme.margin.large};

  > p {
    margin: 0;
  }
`;

export const PaginationInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 25%;

  margin-top: 0 > p {
    margin: 0;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 25%;

  > div {
    margin-left: ${({ theme }) => theme.margin.standard};

    &:first-of-type {
      margin: 0;
    }
  }
`;

export const PaginationNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(2) {
    margin-left: ${({ theme }) => theme.margin.standard};
    > p {
      margin: 0 ${({ theme }) => theme.margin.standard} 0
        ${({ theme }) => theme.margin.small};
    }
  }

  > p {
    margin: 0 ${({ theme }) => theme.margin.standard} 0 0;
  }
`;

export const RowActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  height: 47px;

  width: 100%;

  margin-top: ${({ theme }) => theme.margin.large};
  border-radius: ${({ theme }) => `${theme.borders.radius}`};
`;

export const RowActionPanel = styled.div<{
  wide?: boolean;
  backgroundColor?: string;
}>`
  height: 100%;
  color: ${({ theme }) => theme.colors.system.offWhite};
  display: flex;
  align-items: center;
  justify-content: ${({ wide }) => (wide ? 'space-between' : 'center')};

  width: ${({ wide }) => wide && '100%'};
  padding: ${({ theme }) => `0 ${theme.padding.large}`};
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  border: ${({ theme, wide }) =>
    wide && `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme, wide }) =>
    !wide
      ? `${theme.borders.radius} 0 0 ${theme.borders.radius}`
      : `0 ${theme.borders.radius} ${theme.borders.radius} 0`};
`;

export const RowActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const SelectedRowCount = styled.p`
  color: ${({ theme }) => theme.colors.system.white};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  font-weight: bold;
`;

export const RowActionDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-content: start;
`;

export const RowActionDetailText = styled(StyledText)`
  margin: 0;
  justify-content: center;
`;

export const TableHeaderDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }

  > svg {
    width: 15px;
    transition: color 0.25s;
    font-size: 1.5em;
  }
`;
