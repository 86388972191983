import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { ValueDataType } from '@configur-tech/upit-core-types/lib/enums';

const validator = Services.validators.DataValidator;

const validateValue = (
  dataType: ValueDataType,
  value: unknown,
  constraints?: Interfaces.SchemaFieldConstraints,
  listValues?: Interfaces.ListValue[],
): boolean => {
  switch (dataType) {
    case ValueDataType.TEXT:
      if (!constraints?.isRequired && !(value as string)?.length) {
        return true;
      }

      if (constraints?.listValues && listValues?.length) {
        return (
          validator.isText(value) && listValues.includes({ value: `${value}` })
        );
      }

      return validator.isText(value);

    case ValueDataType.NUMBER:
      if (
        (constraints?.min && (value as number) < constraints.min) ||
        (constraints?.max && (value as number) > constraints.max)
      ) {
        return false;
      }

      if (!constraints?.isRequired && !value) {
        return true;
      }

      return validator.isNumber(value);

    case ValueDataType.BOOL:
      if (!constraints?.isRequired && !value) {
        return true;
      }

      return validator.isBoolean(value);

    case ValueDataType.DATE: {
      if (!constraints?.isRequired && !value) {
        return true;
      }

      const opts: { format?: string } = {};
      if (constraints?.format) {
        opts.format = constraints.format;
      }

      try {
        return validator.isValidDate(value, opts);
      } catch (err) {
        return false;
      }
    }

    case ValueDataType.EMAIL:
      if (!constraints?.isRequired && !value) {
        return true;
      }

      try {
        return validator.isEmail(value);
      } catch (err) {
        return false;
      }

    case ValueDataType.POSTCODE:
      if (!constraints?.isRequired && !value) {
        return true;
      }

      try {
        return validator.isPostcode(value, {
          country: Enums.SupportedCountry.UK,
        });
      } catch (err) {
        return false;
      }

    case ValueDataType.PHONE_NUM:
      if (!constraints?.isRequired && !value) {
        return true;
      }

      try {
        return validator.isPhoneNum(value, {
          country: Enums.SupportedCountry.UK,
        });
      } catch (err) {
        return false;
      }

    default:
      return false;
  }
};

export default validateValue;
