import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import logo from '../../assets/icons/system/codex-icon.svg';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import { DefaultPopupStyles } from '../../main/theme';
import FAButton from '../FaButton/FAButton';
import FeatureButton from '../FeatureButton/FeatureButton';
import { SUPPORT_URL } from '../SystemActions/SystemActions';
import * as SC from './styled';

const PortalSystemActions: FC = () => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const { organisation } = useOrganisation();

  return (
    <SC.Wrapper>
      <SC.PopupWrapper>
        <Popup
          content={'Get Help'}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <div>
              <FAButton
                action={() => window.open(SUPPORT_URL)}
                icon={faQuestion}
                color={themeContext.colors.system.white}
                size={'2x'}
              />
            </div>
          }
        />
      </SC.PopupWrapper>

      <Popup
        content={'Dashboard'}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FeatureButton
              action={() => history.push(RouteName.WELCOME)}
              size={50}
              color={themeContext.colors.system.white}
              image={organisation?.theme?.logo || logo}
              faceStyle={
                organisation?.theme?.logo
                  ? {
                      background: `${themeContext.colors.system.white} url(${organisation?.theme?.logo}) no-repeat center / 85%`,
                      padding: 0,
                    }
                  : {}
              }
            />
          </div>
        }
      />
    </SC.Wrapper>
  );
};

export default PortalSystemActions;
