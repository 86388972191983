import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledAccordion, StyledAccordionContent } from '../../../main/theme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const FieldAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 800px !important;
`;

export const FieldAccordionContent = styled(StyledAccordionContent)`
  display: flex !important;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    width: 25%;
    margin: ${({ theme }) => `${theme.margin.standard} 0`};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const GridWrapper = styled.div<{ showFilters?: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr) ${({ showFilters }) =>
      showFilters ? '260px' : 0};

  width: 100%;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 150px);
  overflow-y: auto;
  align-items: start;
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const FilterAccordion = styled(StyledAccordion)`
  width: 100%;
  min-width: 100%;
  border: none;
`;

export const FilterAccordionContent = styled(StyledAccordionContent)`
  padding: 0 !important;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const SubtotalWrapper = styled.div`
  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const MeasureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const AggregateSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;

  padding: ${({ theme }) => `0 ${theme.margin.standard}`} !important;

  > div {
    &:last-of-type {
      padding-bottom: ${({ theme }) => theme.padding.large};
    }
  }
`;

export const PageInner = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xlarge}`};

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > p {
    margin: 0;
  }

  > div {
    display: flex;
    > div {
      margin-left: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const StyledTab = styled(Tab)`
  width: 100%;

  .segment {
    padding: 0;
  }

  .ui.attached.item.menu {
    margin: 0 !important;
    ${({ theme }) => theme.typography.header};
  }
  .ui.tabular.menu .active.item {
    background: ${({ theme }) => theme.colors.system.white};
  }

  .ui.bottom.loading {
    background: none;
    border: none;

    &:before,
    &:after {
      background: none;
    }
  }
`;

export const GraphConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start !important;
  padding: ${({ theme }) => theme.padding.large}
    ${({ theme }) => theme.padding.standard};
  max-width: 100%;
`;

export const GroupButton = styled.div<{ active: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 48px;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard}`};
  margin-bottom: ${({ theme }) => theme.margin.standard};

  background-color: ${({ theme, active }) =>
    active ? theme.colors.general.purple : theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  > div.checkbox {
    width: 75%;

    > label {
      color: ${({ theme, active }) =>
        active ? theme.colors.system.white : theme.colors.system.offBlack};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${({ disabled, active }) =>
    !disabled &&
    active &&
    `> svg {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }`};
`;

export const GraphHeading = styled.h3`
  text-align: center;
  width: 100%;
  padding-top: ${({ theme }) => theme.padding.xlarge};
`;
