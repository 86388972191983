import { Enums } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import IntegrationConfigService, {
  IntegrationConfigOutput,
  IntegrationConfigsOutput,
} from '../../services/integration/IntegrationConfigService';
import { AppThunk } from '../store';

export interface IntegrationConfigsState {
  data: {
    data: IntegrationConfigOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  loading: boolean;
  error: string | null;
}

const initialState: IntegrationConfigsState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const IntegrationConfigsSlice = createSlice({
  name: 'integration-Configs',
  initialState,
  reducers: {
    // Fetch
    fetchIntegrationConfigsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchIntegrationConfigsSuccess(
      state,
      action: PayloadAction<IntegrationConfigsOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchIntegrationConfigsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchIntegrationConfigsStart,
  fetchIntegrationConfigsSuccess,
  fetchIntegrationConfigsFailure,
} = IntegrationConfigsSlice.actions;

export default IntegrationConfigsSlice.reducer;

export const fetchIntegrationConfigs =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchIntegrationConfigsStart());
      const fetched = await IntegrationConfigService.getIntegrationConfigs(
        token,
        query,
      );
      dispatch(
        fetchIntegrationConfigsSuccess(
          fetched.data as unknown as IntegrationConfigsOutput,
        ),
      );
    } catch (err) {
      dispatch(fetchIntegrationConfigsFailure((err as string).toString()));
      if (
        (err as AxiosError)?.response?.data.statusCode !==
        Enums.StatusCode.NOT_FOUND
      ) {
        throw err;
      }
    }
  };
