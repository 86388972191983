import { Interfaces } from '@configur-tech/upit-core-types';

const InitialForm: Interfaces.Form = {
  organisationId: '',

  name: '',

  avatar: '',

  description: '',

  access: {
    manage: {
      organisation: [],
      team: [],
      user: [],
    },
    edit: { organisation: [], team: [], user: [] },
    view: { organisation: [], team: [], user: [] },
  },

  datasetMetaId: '',

  questionGroups: [],
};

export default InitialForm;
