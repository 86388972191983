import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import * as SC from './styled';

export enum DraggableTypes {
  COLUMN = 'column',
}

interface DraggableTableCellProps {
  column;
  index;
  reorder;
}

const DraggableTableCell: FC<DraggableTableCellProps> = ({
  column,
  index,
  reorder,
}) => {
  const ref = useRef();
  const { id, Header } = column;

  const [, drop] = useDrop({
    accept: DraggableTypes.COLUMN,
    drop: (item) => {
      reorder(item, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DraggableTypes.COLUMN,
    item: () => {
      return {
        id,
        index,
        header: Header,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(drop(ref));

  const memoizedColumn = useMemo(() => column.render('Header'), [column]);

  return (
    <SC.StyledTh ref={ref} isDragging={isDragging} {...column.getHeaderProps()}>
      {memoizedColumn}
    </SC.StyledTh>
  );
};

export default DraggableTableCell;
