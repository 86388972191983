import { Enums } from '@configur-tech/upit-core-types';
import { truncate } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../../enums/RouteName';
import useProject from '../../../hooks/project/UseProject';
import {
  TopBarHeaderLabel,
  TopBarHeaderTextWrapper,
  TopBarHeaderTitle,
} from '../../../main/theme';
import { updateActiveDatasetStage } from '../../../store/dataset-stage';
import { fetchCMSSuccess } from '../../../store/project-cms';
import {
  resetDataviewStage,
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import {
  ProjectAggregationSubStage,
  ProjectConnectionCMSSubStage,
} from '../../../store/project-stage/initial-state';
import { fetchQuerySuccess } from '../../../store/query';
import { RootState } from '../../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

const Wrapper = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.darkBlueShadow};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTextWrapper = styled(TopBarHeaderTextWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: ${({ theme }) => theme.margin.large};
`;

const CmsHeader: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { cmsId, datasetMetaId, aggregationId } = useParams();
  const { connections, project } = useProject();

  const cmsConns = connections?.[Enums.ConnectionType.CMS];
  const cms = cmsConns?.find((c) => c._id === cmsId);

  const queriesState = useSelector((state: RootState) => state.queries);
  const query = queriesState?.data?.data?.find(
    (q) => q.entity._id === aggregationId,
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        {cms && (
          <HeaderTextWrapper
            onClick={() =>
              history.push(
                `${RouteName.PROJECT_ITEM}/${project?._id}/cms/${cms?._id}`,
              )
            }
          >
            <TopBarHeaderLabel>Active Workspace</TopBarHeaderLabel>
            <TopBarHeaderTitle>
              {truncate(cms?.name, {
                length: 35,
              })}
            </TopBarHeaderTitle>
          </HeaderTextWrapper>
        )}

        {project?._id && cmsId && cms && (
          <FeatureButton
            action={() => {
              dispatch(resetDataviewStage());
              dispatch(fetchCMSSuccess(cms));
              dispatch(
                updateActiveProjectSubStage(ProjectConnectionCMSSubStage.DATA),
              );
              history.push(`${RouteName.PROJECT_ITEM}/${project?._id}`);
            }}
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.green}
            text={`Manage Workspace`}
            style={{ marginRight: themeContext.margin.large }}
          />
        )}

        {datasetMetaId && (
          <FeatureButton
            action={() => {
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
              history.push(`${RouteName.DATASET_ITEM}/${datasetMetaId}`);
            }}
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.sea}
            text={`Manage Dataset`}
          />
        )}

        {aggregationId && query && (
          <FeatureButton
            action={() => {
              dispatch(
                updateActiveProjectStage(Enums.ProjectStage.AGGREGATION),
              );
              dispatch(fetchQuerySuccess(query));
              dispatch(
                updateActiveProjectSubStage(
                  ProjectAggregationSubStage.AGGREGATE,
                ),
              );

              history.push(`${RouteName.PROJECT_ITEM}/${project?._id}`);
            }}
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.sea}
            text={`Manage Query`}
          />
        )}
      </HeaderWrapper>
    </Wrapper>
  );
};

export default CmsHeader;
