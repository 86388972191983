import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
} from '../../../../main/theme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
  max-width: 100%;
`;

export const GroupAccordion = styled(StyledAccordion)`
  width: 2000px;
  max-width: 100% !important;
`;

export const AccordionTitle = styled(StyledAccordionTitle)`
  & :hover {
    cursor: default;
  }
`;

export const AccordionTitleWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin-right: ${({ theme }) => theme.margin.standard};
    margin-bottom: 0;
  }
`;

export const AccordionTitleButtons = styled.div`
  display: flex;

  > div,
  > button {
    margin-left: ${({ theme }) => theme.margin.standard};

    & :hover {
      cursor: pointer;
    }
  }
`;

export const AccordionContentWrapper = styled(StyledAccordionContent)`
  background: ${({ theme }) => theme.colors.system.white};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;

export const SortableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 38px;
  background: ${({ theme }) => theme.colors.general.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.small};
`;
