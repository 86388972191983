import { Enums } from '@configur-tech/upit-core-types';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase, truncate } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FORMULA_TYPES } from '../../const/FormulaConst';
import { defaultTheme, StyledText } from '../../main/theme';
import { showModal } from '../../store/modal';
import namesRequiresCleansing from '../../util/column-name/CleanseColumnName';
import getDataTypeIcon from '../../util/data-type-icon/DataTypeIcon';
import { ModalTypes } from '../Modal/Modal';

interface DataSampleHeaderCellProps {
  field: string;
  type: Enums.ValueDataType;
  dataCollectionId?: string;
  isDisabled?: boolean;
  formulaComplete?: boolean;
  dateComplete?: boolean;
  hasConstraints?: boolean;
  showingEmptyHeaders?: boolean;
}

interface DataTypeHeaderCellProps {
  isDisabled?: boolean;
  showingEmptyHeaders?: boolean;
}

interface DataTypeIconProps {
  isIncomplete?: boolean;
  hasConstraints?: boolean;
}

const TRUNCATE_LIMIT = 45;

const DataTypeHeaderCell = styled.button<DataTypeHeaderCellProps>`
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.padding.standard};

  width: 100%;

  text-align: left;

  outline: none;
  border: none;

  transition: opacity 0.3s;

  ${({ isDisabled }) =>
    !isDisabled
      ? `&:hover {
          cursor: pointer;
          opacity: 0.7;
        }`
      : ''}
`;

const DataTypeIcon = styled.div<DataTypeIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  background: ${({ isIncomplete, hasConstraints, theme }) =>
    isIncomplete
      ? theme.colors.general.yellow
      : hasConstraints
      ? theme.colors.general.blue
      : theme.colors.general.green};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.standard};
  margin-right: ${({ theme }) => theme.margin.standard};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
`;

const SubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.colors.system.darkGrey};
`;

const DataSampleDataTypeHeaderCell: FC<DataSampleHeaderCellProps> = ({
  field,
  type,
  dataCollectionId,
  isDisabled = false,
  formulaComplete,
  dateComplete,
  hasConstraints,
  showingEmptyHeaders,
}) => {
  const dispatch = useDispatch();

  return (
    <DataTypeHeaderCell
      isDisabled={isDisabled}
      showingEmptyHeaders={showingEmptyHeaders}
      onClick={() => {
        if (!isDisabled && dataCollectionId) {
          dispatch(
            showModal({
              visible: true,
              modal: FORMULA_TYPES.includes(type)
                ? ModalTypes.ENHANCEMENT_ADD_COLUMN
                : ModalTypes.VALIDATION_DATA_TYPE,
              fullScreen: FORMULA_TYPES.includes(type),
              additionalProps: {
                field,
                type,
                dataCollectionId,
              },
            }),
          );
        }
      }}
    >
      <DataTypeIcon
        isIncomplete={
          type === Enums.ValueDataType.UNKNOWN ||
          (type === Enums.ValueDataType.DATE && !dateComplete) ||
          (FORMULA_TYPES.includes(type) && !formulaComplete) ||
          namesRequiresCleansing(field)
        }
        hasConstraints={hasConstraints}
      >
        <FontAwesomeIcon
          icon={getDataTypeIcon(type as Enums.ValueDataType) as IconDefinition}
          color={defaultTheme.colors.system.white}
        />
      </DataTypeIcon>

      <div>
        <Header>{truncate(field, { length: TRUNCATE_LIMIT })}</Header>
        <SubHeader>
          {startCase(
            FORMULA_TYPES.includes(type) ? 'Super' : type?.toLowerCase(),
          )}
        </SubHeader>
      </div>
    </DataTypeHeaderCell>
  );
};

export default DataSampleDataTypeHeaderCell;
