import { Enums } from '@configur-tech/upit-core-types';
import { CMSConnectionConfigurationOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/icons/system/codex-icon.svg';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType } from '../../enums';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePortal from '../../hooks/portal/UsePortal';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StyledText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import { PageInner } from '../styled';

const CMS = 'cms';

const PortalPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { portalId } = useParams();

  const { portal, getPortal } = usePortal();
  const { organisation } = useOrganisation();

  const NoResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: ${({ theme }) =>
      `${theme.padding.standard} ${theme.padding.xxxlarge}`};
    border: 1px solid ${({ theme }) => theme.colors.system.grey};
    border-radius: ${({ theme }) => theme.borders.radius};
  `;

  // Fetch portal
  useEffect(() => {
    if (
      location.pathname.includes(RouteName.PORTAL) &&
      portalId &&
      portal &&
      portal._id !== portalId
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Portal...' }));
        await getPortal(portalId, true);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getPortal, portal, portal?._id, portalId]);

  useEffect(() => {
    if (portal?._id === portalId) {
      const types: Enums.ConnectionType[] = [];
      if (portal?.connections.cmsIds.length) {
        types.push(Enums.ConnectionType.CMS);

        const activeItem = portal.connectionItems?.cms.find(
          (item) => item.status === Enums.ConnectionStatus.ACTIVE,
        );

        if (activeItem) {
          const firstGroup = (
            activeItem?.configuration as CMSConnectionConfigurationOutput
          ).groups[0];

          const dataType = firstGroup.items[0].datasetMetaId
            ? EntityType.DATASET
            : firstGroup.items[0].aggregationId
            ? EntityType.AGGREGATION
            : EntityType.CHART;

          switch (dataType) {
            case EntityType.DATASET:
              history.push(
                `${RouteName.PORTAL}/${portal._id}/${CMS}/${activeItem?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].datasetMetaId}`,
              );
              break;
            case EntityType.AGGREGATION:
              history.push(
                `${RouteName.PORTAL}/${portal._id}/${CMS}/${activeItem?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].aggregationId}`,
              );
              break;
            case EntityType.CHART:
              history.push(
                `${RouteName.PORTAL}/${portal._id}/${CMS}/${activeItem?._id}/${firstGroup._id}/${dataType}/${firstGroup.items[0].chartId}`,
              );
              break;
          }
        }
      }

      if (portal?.connections.formIds.length) {
        types.push(Enums.ConnectionType.FORM);
      }

      if (portal?.connections.apiIds.length) {
        types.push(Enums.ConnectionType.API);
      }
    }
  }, [history, portal, portalId]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.PORTAL} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={
            organisation?.theme?.logoWide || organisation?.theme?.logo || logo
          }
          wideImage={!!organisation?.theme?.logoWide}
          title={portal?.name}
        />
        <Page>
          <PageInner>
            <NoResults>
              <StyledText>No results found</StyledText>
            </NoResults>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default PortalPage;
