export const FILTER_SUFFIX = '_FILTER';

const getStoredFilters = Object.fromEntries(
  Object.entries({ ...localStorage })
    .filter(([key]) => key.includes(FILTER_SUFFIX))
    .map(([key, val]) => [key.replace(FILTER_SUFFIX, ''), JSON.parse(val)]),
);

const InitialFilters = {
  data: getStoredFilters || {},
};

export default InitialFilters;
