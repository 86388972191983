import { faEyeDropper } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { ThemeContext } from 'styled-components';
import { ListOptionColor } from '../../enums';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

const EVENT_MOUSE_DOWN = 'mousedown';
const EVENT_CLICK = 'click';
const EVENT_TOUCH_START = 'touchstart';

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside: boolean;
    let startedWhenMounted: boolean;

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener(EVENT_MOUSE_DOWN, validateEventStart);
    document.addEventListener(EVENT_TOUCH_START, validateEventStart);
    document.addEventListener(EVENT_CLICK, listener);

    return () => {
      document.removeEventListener(EVENT_MOUSE_DOWN, validateEventStart);
      document.removeEventListener(EVENT_TOUCH_START, validateEventStart);
      document.removeEventListener(EVENT_CLICK, listener);
    };
  }, [ref, handler]);
};

interface PopupColorPickerProps {
  color?: string;
  onChange: (color?: string, index?: number) => void;
  disabled: boolean;
  displayAsButton?: boolean;
  isDroppedColour?: boolean;
  setIsDroppedColour?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

export const PopupColorPicker: FC<PopupColorPickerProps> = ({
  color,
  onChange,
  disabled,
  displayAsButton,
  isDroppedColour,
  setIsDroppedColour,
}) => {
  const themeContext = useContext(ThemeContext);
  const popover = useRef(null);
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const handleClickedDropped = () => {
    toggle(true);
    if (setIsDroppedColour) {
      setIsDroppedColour(true);
    }
  };

  return (
    <SC.Picker>
      {displayAsButton ? (
        <FeatureButton
          isDisabled={disabled}
          isActive={
            isDroppedColour ||
            !!(
              color &&
              !Object.values(ListOptionColor).includes(color as ListOptionColor)
            )
          }
          action={() => (!disabled ? handleClickedDropped() : undefined)}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={
            (color &&
              (!Object.values(ListOptionColor).includes(
                color as ListOptionColor,
              ) ||
                isDroppedColour)) ||
            isOpen
              ? color
              : themeContext.colors.system.offWhite
          }
          containerStyle={{ margin: themeContext.margin.standard }}
          icon={
            <FontAwesomeIcon
              icon={faEyeDropper}
              color={themeContext.colors.system.offBlack}
              size={'lg'}
            />
          }
        />
      ) : (
        <SC.PickerSwatch
          style={{ backgroundColor: color }}
          onClick={() => (!disabled ? toggle(true) : undefined)}
        />
      )}

      {isOpen && !disabled && (
        <SC.PickerPopOver ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />
        </SC.PickerPopOver>
      )}
    </SC.Picker>
  );
};
