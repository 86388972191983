import styled from 'styled-components';
import { StyledText } from '../../main/theme';

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.large} 0`};

  > div:last-of-type {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.margin.xxxlarge}`};

  > .input {
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
`;

export const IconWrapper = styled.div<{ maxHeight?: number }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  gap: ${({ theme }) => theme.margin.standard};

  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '')};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
`;

export const ErrorText = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.general.red};
  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;
