import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { ERROR_VALIDATION_LIMIT } from '../../../../const/ErrorConst';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import usePublish from '../../../../hooks/dataset-meta/UsePublish';
import useSample from '../../../../hooks/dataset-meta/UseSample';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import useValidation from '../../../../hooks/dataset-meta/UseValidation';
import useList from '../../../../hooks/list/UseList';
import { SampleData, SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { fetchDatasetMetaSuccess } from '../../../../store/dataset-meta';
import {
  resetStagesAndSubStages,
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../../store/dataset-stage';
import { DatasetValidateSubStage } from '../../../../store/dataset-stage/initial-state';
import { showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import { RootState } from '../../../../store/rootReducer';
import namesRequiresCleansing from '../../../../util/column-name/CleanseColumnName';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import ActionBar from '../../../ActionBar/ActionBar';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import { ErrorType } from '../../../Modal/error/ErrorModal';
import * as SC from './styled';

const TABLE_HEIGHT_LOCKED = 475;
const TABLE_HEIGHT_UNLOCKED = 570;
const TABLE_HEIGHT_INITIAL = 490;

export interface DiscoveryDatasetStageProps {
  schema?: Interfaces.Field[];
  setSchema: React.Dispatch<React.SetStateAction<Interfaces.Field[]>>;
  sampleData?: SampleDataRow[];
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const DiscoveryConfirmationDatasetStage: FC<DiscoveryDatasetStageProps> = ({
  schema,
  setSchema,
  sampleData,
  setSampleData,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const {
    datasetMeta,
    datasetMetaAccessLevel,
    activeDataCollectionItem,
    editDatasetMeta,
  } = useDatasetMeta();
  const { collection, collectionLockStatus, activeCollectionSelected } =
    useDatasetCollection();
  const { publishDataset } = usePublish();
  const { validateDataset } = useValidation();
  const { getLists } = useList();
  const { createTableData } = useTableData();
  const { createSampleData } = useSample();

  const dataCollection = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const hasPublishedData = !!datasetMeta?.activeDataCollection;
  const isUsingTableBuilder =
    dataCollection?.dataSource?.dataSourceType ===
      Enums.DataSourceType.INTERNAL ||
    (dataCollection?.dataSource?.dataSourceType ===
      Enums.DataSourceType.DATASET &&
      !datasetMeta?.activeDataCollection);
  const isEditing = location.pathname.includes('edit');
  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');
  const isDuplicating = location.pathname.includes('duplicate');
  const isIntegration = location.pathname.includes('integration');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchingSample, setFetchingSample] = useState<boolean>(false);
  const [columnsComplete, setColumnsComplete] = useState<boolean>(false);
  const [fetchedSample, setFetchedSample] =
    useState<Record<string, unknown>[]>();
  const [tableData, setTableData] = useState<SampleData>();
  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );
  const [isValidating, setIsValidating] = useState<boolean>(false);

  // If schema changes at all, fetch sample
  useEffect(() => {
    if (schema) {
      setFetchedSample(undefined);
    }
  }, [schema]);

  useEffect(() => {
    if (
      fetchedSample ||
      !datasetMeta?._id ||
      !schema ||
      (isUsingTableBuilder && !hasPublishedData) ||
      isDuplicating ||
      isIntegration
    ) {
      return;
    }
    (async () => {
      setFetchedSample(
        await createSampleData(datasetMeta._id, schema, undefined, true),
      );
    })();
  }, [
    createSampleData,
    datasetMeta?._id,
    fetchedSample,
    hasPublishedData,
    isDuplicating,
    isIntegration,
    isUsingTableBuilder,
    schema,
  ]);

  useEffect(() => {
    if (!schema || !fetchedSample) {
      return;
    }
    setTableData(
      createTableData(
        schema,
        fetchedSample,
        dataCollection?.stages.validation?.corrected,
      ),
    );
  }, [
    createTableData,
    dataCollection?.stages.validation?.corrected,
    fetchedSample,
    schema,
  ]);

  // Set schema & fetch lists
  useEffect(() => {
    if (
      dataCollection &&
      datasetMeta?.dataCollections?.length &&
      !isValidating
    ) {
      if (dataCollection?.schemaData) {
        (async () => {
          const lists = dataCollection?.schemaData.reduce(
            (acc: string[], field) => {
              const listValues = field.dataValidation?.constraints?.listValues;
              if (listValues && typeof listValues === 'string') {
                return acc.concat(listValues);
              }
              return acc;
            },
            [],
          );

          if (lists.length) {
            await getLists({ _id: { $in: lists } });
          }

          setSchema(dataCollection.schemaData);
        })();
      }
    }
  }, [
    dataCollection,
    datasetMeta?.dataCollections?.length,
    getLists,
    isValidating,
    setSchema,
  ]);

  useEffect(() => {
    if (schema) {
      setFetchingSample(true);
    }
  }, [schema]);

  // If no sample data, go get it
  useEffect(() => {
    if (!sampleData?.length && datasetMeta?._id && !fetchingSample) {
      setFetchingSample(true);
    }
  }, [datasetMeta?._id, fetchingSample, sampleData?.length]);

  // Set newly fetched tableData as sampleData
  useEffect(() => {
    setIsLoading(false);

    if (!tableData) {
      return;
    }
    if (tableData.rows?.length) {
      setSampleData(tableData.rows);
    } else {
      setSampleData([]);
    }
  }, [setSampleData, tableData]);

  // Confirm all columns have a data type
  useEffect(() => {
    if (schema?.length) {
      const complete =
        schema?.every(
          (i) =>
            i.dataValidation?.dataValidationType !==
              Enums.ValueDataType.UNKNOWN &&
            !(
              i.dataValidation?.dataValidationType ===
                Enums.ValueDataType.DATE &&
              !i.dataValidation?.constraints?.format
            ) &&
            !(
              i.dataValidation?.dataValidationType &&
              i.dataValidation?.dataValidationType ===
                Enums.ValueDataType.FORMULA &&
              !i.dataValidation?.formula?.length
            ) &&
            !(
              i.dataValidation?.dataValidationType &&
              i.dataValidation?.dataValidationType ===
                Enums.ValueDataType.TEXT_TRANSFORMATION &&
              (!i.dataValidation?.textTransformation?.params ||
                !i.dataValidation?.textTransformation?.type)
            ) &&
            !(
              i.dataValidation?.dataValidationType &&
              i.dataValidation?.dataValidationType ===
                Enums.ValueDataType.DATE_CONVERSION &&
              (!i.dataValidation?.dateConversion?.params ||
                !i.dataValidation?.dateConversion?.type)
            ) &&
            !(
              i.dataValidation?.dataValidationType &&
              i.dataValidation?.dataValidationType ===
                Enums.ValueDataType.FIELD_LOOKUP &&
              (!i.dataValidation?.fieldLookup?.local ||
                !i.dataValidation?.fieldLookup?.target)
            ) &&
            !namesRequiresCleansing(i.name),
        ) || false;

      setColumnsComplete(complete);
    }
  }, [schema]);

  const reorderColumns = async (newOrder: string[]) => {
    const cloned = cloneDeep(datasetMeta);

    const collectionIndex = collection?.collectionId
      ? cloned.dataCollections.findIndex(
          (c) => c._id === collection?.collectionId,
        )
      : 0;

    if (
      cloned.dataCollections[collectionIndex] &&
      cloned.dataCollections[collectionIndex].schemaData
    ) {
      cloned.dataCollections[collectionIndex].schemaData =
        cloned.dataCollections[collectionIndex].schemaData.sort((a, b) => {
          const A = a.name;
          const B = b.name;

          if (newOrder.indexOf(A) > newOrder.indexOf(B)) {
            return 1;
          } else {
            return -1;
          }
        });

      // Dispatch to state immediately so no lag for user
      dispatch(
        fetchDatasetMetaSuccess({
          entity: cloned,
          accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );

      // Save datasetMeta
      await editDatasetMeta(cloned);
    }
  };

  useEffect(() => {
    if (isIntegration) {
      (async () => {
        const cloned = cloneDeep(datasetMeta);
        const currentCollection = collection?.collectionId
          ? cloned.dataCollections.find(
              (c) => c._id === collection?.collectionId,
            )
          : cloned.dataCollections?.[0];

        if (currentCollection) {
          currentCollection.stages[Enums.DatasetStage.CREATION].status =
            Enums.StageStatus.COMPLETED;
          currentCollection.stages[Enums.DatasetStage.STRUCTURE].status =
            Enums.StageStatus.IN_PROGRESS;

          currentCollection.stages[Enums.DatasetStage.VALIDATION].status =
            Enums.StageStatus.IN_PROGRESS;
        }
        await editDatasetMeta(cloned);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection?.collectionId, editDatasetMeta, isIntegration]);

  const handleValidateDataset = async () => {
    setIsValidating(true);
    dispatch(showLoading({ text: 'Updating Dataset...' }));
    // Update current status
    const cloned = cloneDeep(datasetMeta);

    const currentCollection = collection?.collectionId
      ? cloned.dataCollections.find((c) => c._id === collection?.collectionId)
      : cloned.dataCollections?.[0];

    if (currentCollection) {
      currentCollection.stages[Enums.DatasetStage.STRUCTURE].status =
        Enums.StageStatus.COMPLETED;

      currentCollection.stages[Enums.DatasetStage.VALIDATION].status =
        Enums.StageStatus.IN_PROGRESS;

      if (isUsingTableBuilder && !sampleData?.length) {
        currentCollection.stages[Enums.DatasetStage.VALIDATION].status =
          Enums.StageStatus.IN_PROGRESS;
        currentCollection.stages[Enums.DatasetStage.VALIDATION].pagedResults = [
          {
            status: Enums.StageStatus.IN_PROGRESS,
            pageNum: 1,
            result: { total: 0, completed: 0, failed: 0 },
            corrected: [],
            failed: [],
          },
        ];
      }
    }

    // Save datasetMeta
    await editDatasetMeta(cloned);

    // Validate dataset
    try {
      // Don't validate data for table builder and not editing
      let validation;

      if (!isUsingTableBuilder || isEditing) {
        validation = await validateDataset();
      }

      if (datasetStageState.activeStage !== Enums.DatasetStage.VALIDATION) {
        dispatch(updateActiveDatasetStage(Enums.DatasetStage.VALIDATION));
      }
      dispatch(updateActiveDatasetSubStage(DatasetValidateSubStage.RESULT));

      if (validation?.failed && validation?.failed?.length) {
        throw new Error('Validation Failed');
      }
    } catch (err) {
      const error = err as Error;
      if (!isEditing && error) {
        // Handle error
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.ERROR,
            additionalProps: {
              errorType:
                error.message === ERROR_VALIDATION_LIMIT
                  ? ErrorType.VALIDATION_LIMIT
                  : ErrorType.VALIDATION_FAILED,
            },
          }),
        );
      }
      throw error;
    }
  };

  const handlePublishDataset = async () => {
    // Validate dataset
    await handleValidateDataset();

    try {
      const publishResult = await publishDataset();

      if (publishResult) {
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.DATASET_PUBLISHED,
            forceOpen: true,
          }),
        );
        dispatch(resetStagesAndSubStages());
      }
    } catch (err) {
      // Handle error
      const error = err as Error;
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          additionalProps: {
            errorType:
              error.message === ERROR_VALIDATION_LIMIT
                ? ErrorType.VALIDATION_LIMIT
                : ErrorType.VALIDATION_FAILED,
          },
        }),
      );
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        {(!activeDataCollectionItem ||
          ((isReplacing || isMerging) && !activeCollectionSelected)) && (
          <>
            {!isUsingTableBuilder && (
              <>
                <StageBodyText>
                  Our super smart AI has analysed your dataset.
                </StageBodyText>
                <StageBodyText>
                  Review your columns below, and simply tap the column header to
                  edit them, or hit a button below to add new columns.
                </StageBodyText>
              </>
            )}

            {isUsingTableBuilder && (
              <>
                <StageBodyText>
                  Use our Table Builder to create any type of structure you'd
                  like.
                </StageBodyText>
                <StageBodyText>
                  Tap a button below to create columns of all sorts of data
                  types, constraints and formats as well as dynamic super
                  columns.
                </StageBodyText>
              </>
            )}

            <SC.ButtonWrapper>
              <FeatureButton
                action={() => {
                  dispatch(
                    showModal({
                      visible: true,
                      modal: ModalTypes.VALIDATION_DATA_TYPE,
                      additionalProps: {
                        field: undefined,
                        type: undefined,
                        dataCollectionId: collection?.collectionId,
                      },
                    }),
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                text={'Create Column'}
                style={{
                  marginBottom: themeContext.margin.standard,
                  marginRight: themeContext.margin.standard,
                }}
              />
              <FeatureButton
                isDisabled={!schema?.length}
                action={() => {
                  dispatch(
                    showModal({
                      visible: true,
                      modal: ModalTypes.ENHANCEMENT_ADD_COLUMN,
                      fullScreen: true,
                      additionalProps: {
                        field: undefined,
                        type: undefined,
                        dataCollectionId: collection?.collectionId,
                      },
                    }),
                  );
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.yellow}
                text={'Create Super Column'}
                style={{ marginBottom: themeContext.margin.standard }}
              />
            </SC.ButtonWrapper>
          </>
        )}

        {!isReplacing && !isMerging && activeDataCollectionItem && (
          <>
            {collectionLockStatus && (
              <>
                <SC.SubHeader>Dataset Locked</SC.SubHeader>
                <StageBodyText>
                  This dataset has been published and so cannot be edited. If
                  you'd like to make changes, tap the toggle above and we'll
                  create an editable version that you can update safely.
                </StageBodyText>
              </>
            )}

            {!collectionLockStatus && (
              <>
                <SC.SubHeader>Update Your Data Structure</SC.SubHeader>

                <StageBodyText>
                  You're now editing a new version of your dataset and can
                  safely make changes to your data structure.
                </StageBodyText>
                <StageBodyText>
                  Any changes you make here won't be made public until you
                  complete the validation process and publish your updated
                  dataset.
                </StageBodyText>

                <SC.ButtonWrapper>
                  <FeatureButton
                    action={() => {
                      dispatch(
                        showModal({
                          visible: true,
                          modal: ModalTypes.VALIDATION_DATA_TYPE,
                          additionalProps: {
                            field: undefined,
                            type: undefined,
                            dataCollectionId: collection?.collectionId,
                          },
                        }),
                      );
                    }}
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.blue}
                    text={'Create Column'}
                    style={{
                      marginBottom: themeContext.margin.standard,
                      marginRight: themeContext.margin.standard,
                    }}
                  />

                  <FeatureButton
                    isDisabled={!schema?.length}
                    action={() => {
                      dispatch(
                        showModal({
                          visible: true,
                          modal: ModalTypes.ENHANCEMENT_ADD_COLUMN,
                          fullScreen: true,
                          additionalProps: {
                            field: undefined,
                            type: undefined,
                            dataCollectionId: collection?.collectionId,
                          },
                        }),
                      );
                    }}
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.yellow}
                    text={'Create Super Column'}
                    style={{ marginBottom: themeContext.margin.standard }}
                  />
                </SC.ButtonWrapper>
              </>
            )}
          </>
        )}

        {schema && schema?.length > 0 && sampleData && (
          <DataSample
            schema={schema}
            sampleColumns={schema}
            sampleRows={sampleData}
            showHeadersWhenNoData={true}
            isEditingDataTypes={true}
            isDraggable={hasPublishedData ? !collectionLockStatus : true}
            reorderAction={reorderColumns}
            locked={hasPublishedData ? collectionLockStatus : false}
            additionalProps={{
              dataCollectionId: collection?.collectionId,
            }}
            iconMap={{ ...UserIconMap, ...AvatarIconMap }}
            fixedHeightReduction={
              !activeDataCollectionItem
                ? TABLE_HEIGHT_INITIAL
                : collectionLockStatus
                ? TABLE_HEIGHT_LOCKED
                : TABLE_HEIGHT_UNLOCKED
            }
            loading={isLoading}
          />
        )}
      </StageInner>

      <ActionBar
        backButton={
          isReplacing || isMerging ? (
            <FeatureButton
              action={() =>
                dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION))
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={`Back to source type`}
            />
          ) : hasPublishedData && !isEditing ? (
            <FeatureButton
              action={() =>
                dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION))
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={`Back to Overview`}
            />
          ) : undefined
        }
        text={
          (hasPublishedData && !collectionLockStatus) || !hasPublishedData
            ? (isUsingTableBuilder &&
                !hasPublishedData &&
                !sampleData?.length) ||
              (hasPublishedData && !collectionLockStatus && !sampleData?.length)
              ? `Let's publish your dataset!`
              : `Shall we move on?`
            : undefined
        }
        primaryButton={
          (hasPublishedData && !collectionLockStatus) || !hasPublishedData ? (
            <FeatureButton
              isDisabled={!schema || !columnsComplete}
              action={handlePublishDataset}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Validate & Publish'}
            />
          ) : undefined
        }
        secondaryButton={
          (hasPublishedData && !collectionLockStatus && !isUsingTableBuilder) ||
          (!hasPublishedData && !isUsingTableBuilder) ? (
            <FeatureButton
              isDisabled={!schema || !columnsComplete}
              action={handleValidateDataset}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Validate Dataset'}
            />
          ) : undefined
        }
      />
    </StageWrapper>
  );
};

export default DiscoveryConfirmationDatasetStage;
