import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NotificationService, {
  NotificationItemOutput,
} from '../../services/notification/NotificationService';
import { fetchNotification } from '../../store/notification';
import {
  fetchNotifications,
  fetchUnreadNotificationsSuccess,
  updateUnreadNotificationToRead,
} from '../../store/notifications';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useNotificationResult {
  notification?: Interfaces.NotificationEventOutput;
  notificationAccessLevel?: Enums.AccessLevel;
  getNotifications: (query?: Record<string, unknown>) => Promise<void>;
  getNotification: (notificationEventId: string) => void;
  editNotification: (
    notificationObj: Interfaces.NotificationEventOutput,
  ) => Promise<Interfaces.NotificationEventOutput>;
  markAllNotificationsAsRead: () => Promise<void>;
  markNotificationAsRead: (notificationEventId: string) => void;
}

const useNotification = (): useNotificationResult => {
  const dispatch = useDispatch();
  const { loggedInUser } = useLoggedInUser();

  const { getAccessTokenSilently } = useAuth0();
  const [notification, setNotification] =
    useState<Interfaces.NotificationEventOutput>();
  const [notificationAccessLevel, setNotificationAccessLevel] =
    useState<Enums.AccessLevel>();

  const notificationsState = useSelector(
    (state: RootState) => state.notificationEvents,
  );

  const notificationState = useSelector(
    (state: RootState) => state.notificationEvent,
  );
  const notificationObj = notificationState.data as NotificationItemOutput;

  const getNotifications = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(fetchNotifications(token, query));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const getNotification = useCallback(
    async (notificationId: string) => {
      const token = await getAccessTokenSilently();

      if (token && notificationId) {
        try {
          await dispatch(fetchNotification(token, notificationId));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load notification.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const editNotification = useCallback(
    async (
      notificationObj: Interfaces.NotificationEventOutput,
    ): Promise<Interfaces.NotificationEventOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && notificationObj && loggedInUser) {
        result = await NotificationService.putNotification(
          token,
          loggedInUser._id,
          notificationObj,
        );
      }
      return result;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const markNotificationAsRead = useCallback(
    (notificationEventId: string) => {
      const filteredState = cloneDeep(notificationsState);

      filteredState.unread.data = notificationsState.unread.data.filter(
        (o) => o.entity._id !== notificationEventId,
      );

      filteredState.unread.pagination.totalCount =
        notificationsState.unread.pagination.totalCount - 1;

      dispatch(fetchUnreadNotificationsSuccess(filteredState.unread));
    },
    [dispatch, notificationsState],
  );

  const markAllNotificationsAsRead = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (token && loggedInUser) {
      await dispatch(updateUnreadNotificationToRead(token, loggedInUser._id));
    }
  }, [dispatch, getAccessTokenSilently, loggedInUser]);

  useEffect(() => {
    if (notificationObj) {
      // Complete model
      setNotification(notificationObj.entity);
      setNotificationAccessLevel(notificationObj.accessLevel);
    }
  }, [notificationObj]);

  return {
    notification,
    notificationAccessLevel,
    getNotifications,
    getNotification,
    editNotification,
    markAllNotificationsAsRead,
    markNotificationAsRead,
  };
};

export default useNotification;
