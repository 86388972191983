import { Enums } from '@configur-tech/upit-core-types';
import analyticsIcon from '../../assets/icons/avatars/icon-analytics-graph.svg';
import ApiConnectionIcon from '../../assets/icons/connections/api-connection-icon.svg';
import FormConnectionIcon from '../../assets/icons/connections/form-connection-icon.svg';
import aggregateIcon from '../../assets/icons/stages/aggregate-project/aggregate.svg';
import queryIcon from '../../assets/icons/stages/aggregate-project/query.svg';
import chartsIcon from '../../assets/icons/stages/chart-project/charts.svg';
import configurationAccessIcon from '../../assets/icons/stages/configuration/access.svg';
import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import createIcon from '../../assets/icons/stages/create-project/create.svg';
import createAvatarIcon from '../../assets/icons/stages/create-team/avatar.svg';
import datasetIcon from '../../assets/icons/stages/dataset-project/dataset.svg';
import modelIcon from '../../assets/icons/stages/model-project/model.svg';
import { ProjectStage } from '../../enums/ProjectStage';
import {
  ProjectAggregationSubStage,
  ProjectChartSubStage,
  ProjectConfigureSubStage,
  ProjectConnectionCMSSubStage,
  ProjectConnectionFormSubStage,
  ProjectConnectionPortalSubStage,
  ProjectCreationSubStage,
  ProjectGraphSubStage,
  ProjectSubStages,
} from '../../store/project-stage/initial-state';
import { SubStageContent } from '../stage-content/StageHeaderContent';

import CmsConnectionIcon from '../../assets/icons/connections/cms-connection-icon.svg';
import PortalConnectionIcon from '../../assets/icons/connections/portal-connection-icon.svg';

interface ProjectStageMeta {
  [key: string]: {
    title: string;
  };
}

interface ProjectStageContent {
  stage: number;
  image: string;
  title: string;
}

interface ProjectSubStageContent {
  [ProjectStage.CREATION]: {
    subStages: Record<string, SubStageContent>;
  };
  [ProjectStage.AGGREGATION]: {
    subStages: Record<string, SubStageContent>;
  };
  [ProjectStage.CHARTS]: {
    subStages: Record<string, SubStageContent>;
  };
  [ProjectStage.GRAPH]: {
    subStages: Record<string, SubStageContent>;
  };
  [Enums.ConnectionType.CMS]: {
    subStages: Record<string, SubStageContent>;
  };
  [Enums.ConnectionType.FORM]: {
    subStages: Record<string, SubStageContent>;
  };
  [Enums.ConnectionType.PORTAL]: {
    subStages: Record<string, SubStageContent>;
  };
  [ProjectStage.CONFIGURATION]: {
    subStages: Record<string, SubStageContent>;
  };
}

const projectStageMeta: ProjectStageMeta = {
  [ProjectStage.CREATION]: {
    title: 'Create Project',
  },
  [ProjectStage.DATASETS]: {
    title: 'Datasets',
  },
  [ProjectStage.MODELLING]: {
    title: 'Data Model',
  },
  [ProjectStage.AGGREGATION]: {
    title: 'Queries',
  },
  [ProjectStage.CONFIGURATION]: {
    title: 'Configuration',
  },
  [ProjectStage.CHARTS]: {
    title: 'Dashboards',
  },
  [ProjectStage.GRAPH]: {
    title: 'Graph',
  },
  [Enums.ConnectionType.API]: {
    title: 'API',
  },
  [Enums.ConnectionType.CMS]: {
    title: 'Workspaces',
  },
  [Enums.ConnectionType.FORM]: {
    title: 'Forms',
  },
  [Enums.ConnectionType.PORTAL]: {
    title: 'Portals',
  },
  [ProjectConfigureSubStage.ACCESS]: {
    title: 'Access',
  },
  [ProjectConfigureSubStage.ADVANCED]: {
    title: 'Advanced',
  },
};

export const getProjectStageTitle = (
  stage: ProjectStage | Enums.ConnectionType | ProjectConfigureSubStage,
): string => {
  return projectStageMeta[stage].title;
};

export const getProjectStageContent = (
  stage: ProjectStage | Enums.ConnectionType,
): ProjectStageContent => {
  const projectStageContent = {
    [ProjectStage.CREATION]: {
      stage: 1,
      image: createIcon,
      title: `Build your project`,
    },
    [ProjectStage.DATASETS]: {
      stage: 1,
      image: datasetIcon,
      title: `Select your data`,
    },
    [ProjectStage.MODELLING]: {
      stage: 1,
      image: modelIcon,
      title: `Join your data`,
    },
    [ProjectStage.AGGREGATION]: {
      stage: 1,
      image: queryIcon,
      title: `Analyse your data`,
    },
    [ProjectStage.CONFIGURATION]: {
      stage: 1,
      image: configurationAccessIcon,
      title: `Configure your project`,
    },
    [ProjectStage.CHARTS]: {
      stage: 1,
      image: chartsIcon,
      title: `Visualise your data`,
    },
    [ProjectStage.GRAPH]: {
      stage: 1,
      image: analyticsIcon,
      title: `Graph`,
    },
    [Enums.ConnectionType.API]: {
      stage: 1,
      image: ApiConnectionIcon,
      title: `Integrate your data`,
    },
    [Enums.ConnectionType.CMS]: {
      stage: 1,
      image: CmsConnectionIcon,
      title: `Group your data`,
    },
    [Enums.ConnectionType.FORM]: {
      stage: 1,
      image: FormConnectionIcon,
      title: `Populate your data`,
    },
    [Enums.ConnectionType.PORTAL]: {
      stage: 1,
      image: PortalConnectionIcon,
      title: `Share your data`,
    },
  };

  return projectStageContent[stage] || projectStageContent[0];
};

export const getProjectSubStageContent = (
  stage: ProjectStage | Enums.ConnectionType,
  subStage: ProjectSubStages | ProjectConnectionCMSSubStage,
): SubStageContent => {
  const subStageContent: ProjectSubStageContent = {
    [ProjectStage.CREATION]: {
      subStages: {
        [ProjectCreationSubStage.NAME]: {
          stage: 1,
          image: createIcon,
          title: `It All Starts With A Name`,
        },
        [ProjectCreationSubStage.AVATAR]: {
          stage: 2,
          image: createAvatarIcon,
          title: `Pick The Perfect Picture`,
        },
      },
    },

    [ProjectStage.AGGREGATION]: {
      subStages: {
        [ProjectAggregationSubStage.NAME]: {
          stage: 1,
          image: aggregateIcon,
          title: `Let's Get Queryious`,
        },
        [ProjectAggregationSubStage.DATASETS]: {
          stage: 2,
          image: datasetIcon,
          title: `Take Your Pick`,
        },
        [ProjectAggregationSubStage.AGGREGATE]: {
          stage: 3,
          image: datasetIcon,
          title: `Let's Get Queryious`,
        },
        [ProjectAggregationSubStage.CONFIGURATION]: {
          stage: 4,
          image: configurationAdvancedIcon,
          title: `Caution Advised`,
        },
      },
    },

    [ProjectStage.CONFIGURATION]: {
      subStages: {
        [ProjectConfigureSubStage.ACCESS]: {
          stage: 1,
          image: configurationAccessIcon,
          title: `Safe & Secure`,
        },
        [ProjectConfigureSubStage.ADVANCED]: {
          stage: 2,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },

    [ProjectStage.CHARTS]: {
      subStages: {
        [ProjectChartSubStage.NAME]: {
          stage: 1,
          image: analyticsIcon,
          title: `Display your data in a chart layout`,
        },
        [ProjectChartSubStage.CHART]: {
          stage: 2,
          image: datasetIcon,
          title: `Create a chart here`,
        },
        [ProjectChartSubStage.CONFIGURATION]: {
          stage: 3,
          image: configurationAdvancedIcon,
          title: `Caution Advised`,
        },
      },
    },

    [ProjectStage.GRAPH]: {
      subStages: {
        [ProjectGraphSubStage.NAME]: {
          stage: 1,
          image: analyticsIcon,
          title: `Create graph`,
        },
        [ProjectGraphSubStage.DATA]: {
          stage: 2,
          image: datasetIcon,
          title: `Create or select graph data`,
        },
        [ProjectGraphSubStage.GRAPH]: {
          stage: 3,
          image: datasetIcon,
          title: `Setup your graph`,
        },
      },
    },

    [Enums.ConnectionType.CMS]: {
      subStages: {
        [ProjectConnectionCMSSubStage.NAME]: {
          stage: 1,
          image: aggregateIcon,
          title: `A Window To Your Data`,
        },
        [ProjectConnectionCMSSubStage.DATA]: {
          stage: 2,
          image: datasetIcon,
          title: `Take Your Pick`,
        },
        [ProjectConnectionCMSSubStage.ACCESS]: {
          stage: 3,
          image: configurationAccessIcon,
          title: `Access Control`,
        },
        [ProjectConnectionCMSSubStage.CONFIGURATION]: {
          stage: 4,
          image: configurationAdvancedIcon,
          title: `Caution Advised`,
        },
      },
    },

    [Enums.ConnectionType.FORM]: {
      subStages: {
        [ProjectConnectionFormSubStage.NAME]: {
          stage: 1,
          image: aggregateIcon,
          title: `In Top Form`,
        },
        [ProjectConnectionFormSubStage.DATASET]: {
          stage: 2,
          image: datasetIcon,
          title: `Take Your Pick`,
        },
        [ProjectConnectionFormSubStage.FORM_BUILDER]: {
          stage: 3,
          image: datasetIcon,
          title: ``,
        },
        [ProjectConnectionFormSubStage.CONFIGURATION]: {
          stage: 4,
          image: configurationAdvancedIcon,
          title: `Caution Advised`,
        },
      },
    },

    [Enums.ConnectionType.PORTAL]: {
      subStages: {
        [ProjectConnectionPortalSubStage.NAME]: {
          stage: 1,
          image: aggregateIcon,
          title: `Now You're Playing With Portals`,
        },
        [ProjectConnectionPortalSubStage.CONNECTIONS]: {
          stage: 2,
          image: datasetIcon,
          title: `Make A Connection`,
        },
        [ProjectConnectionPortalSubStage.ACCESS]: {
          stage: 3,
          image: configurationAccessIcon,
          title: `Access Control`,
        },
        [ProjectConnectionPortalSubStage.CONFIGURATION]: {
          stage: 4,
          image: configurationAdvancedIcon,
          title: `Caution Advised`,
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
