import { Enums } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useDatasetExporter from '../../../hooks/dataset-exporter/UseDatasetExporter';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import { StyledText } from '../../../main/theme';
import { DatasetExportQuery } from '../../../services/dataset-exporter/DatasetExporterService';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import DataExport from '../../DataExport/DataExport';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

export interface ExportModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  entityType: EntityType;
  datasetMetas?: DatasetExportQuery[];
  portalId?: string;
  groupId?: string;
  query?: {
    _id: string;
    conditions: DynamicConditionalField[];
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const ExportModal: FC<ExportModalProps> = ({
  setShowModal,
  entityType,
  datasetMetas,
  portalId,
  groupId,
  query,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { downloadDatasets, downloadQuery } = useDatasetExporter();

  const [exportFormat, setExportFormat] = useState<Enums.AcceptedFileType>(
    Enums.AcceptedFileType.CSV,
  );

  const [applyFormatting, setApplyFormatting] = useState<boolean>(true);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const processExport = async () => {
    if (loggedInUser?._id && exportFormat) {
      dispatch(showLoading({ text: 'Exporting Data...' }));
      try {
        switch (entityType) {
          case EntityType.DATASET:
            if (datasetMetas) {
              await downloadDatasets(
                datasetMetas,
                exportFormat,
                applyFormatting,
                portalId,
                groupId,
              );
            }
            break;
          case EntityType.AGGREGATION:
            if (query?._id) {
              await downloadQuery(
                query,
                exportFormat,
                applyFormatting,
                portalId,
                groupId,
              );
            }
            break;
        }

        toast.success(
          'Export requested successfully! Your file will be emailed to you once ready.',
        );
      } catch (err) {
        toast.error('Export requested failed. Please try again later.');
      }

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  return (
    <Wrapper>
      <Header>Export {startCase(entityType)}</Header>

      <>
        <DataExport
          exportFormat={exportFormat}
          setExportFormat={setExportFormat}
          applyFormatting={applyFormatting}
          setApplyFormatting={setApplyFormatting}
          bodyText="Which format would you like to export this data in?"
        />

        <ActionButtonWrapper>
          <FeatureButton
            action={() => dispatch(hideModal())}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Cancel'}
          />
          <FeatureButton
            isDisabled={!exportFormat}
            action={processExport}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={`Export Data`}
          />
        </ActionButtonWrapper>
      </>
    </Wrapper>
  );
};

export default ExportModal;
