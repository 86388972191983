import styled from 'styled-components';

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > p {
    margin: ${({ theme }) => `0 0 0 ${theme.margin.small} `};
  }
`;

export const TagBox = styled.div<{ isSelected: boolean }>`
  display: flex;
  margin: ${({ theme }) => theme.margin.small};
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  padding: ${({ theme }) => theme.padding.small};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: solid 1px ${({ theme }) => theme.colors.system.grey};
  cursor: pointer;

  transition: opacity 0.25s, background-color 0.25s, border 0.25s, color 0.25s;

  ${({ theme, isSelected }) =>
    isSelected &&
    `
    background-color: ${theme.colors.general.purple};
    border: solid 1px ${theme.colors.general.purple};
    color: ${theme.colors.system.white};
  `};

  :hover {
    opacity: 0.5;
  }
`;
