import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import OverviewIcon from '../../assets/icons/stages/overview-team/overview.svg';
import DataSample from '../../components/DataSample/DataSample';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import useTeam from '../../hooks/team/UseTeam';
import useUsage from '../../hooks/usage/useUsage';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import {
  TeamItemOutput,
  TeamsItemOutput,
} from '../../services/team/TeamService';
import InitialTeam from '../../store//team/initial-team';
import { RootState } from '../../store/rootReducer';
import { fetchTeamSuccess } from '../../store/team';
import { resetStagesAndSubStages } from '../../store/team-stage';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const ID_FIELD = '_id';
const NAME_FIELD = 'Team Name';
const DESCRIPTION_FIELD = 'Description';

const TABLE_SCHEMA = [{ name: NAME_FIELD }, { name: DESCRIPTION_FIELD }];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TeamsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { getTeams } = useTeam();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const teamsState = useSelector((state: RootState) => state.teams);
  const teams: TeamsItemOutput = teamsState.data;

  const [searchVal, setSearchVal] = useState<string>();
  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<TeamItemOutput[]>(
    teams.data,
  );
  const [teamUsage, setTeamUsage] = useState<number>(0);

  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoadingTeams(true);
      await getTeams();
      setLoadingTeams(false);
    })();
  }, [dispatch, getTeams]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const teamUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.TEAMS,
        );
        setTeamUsage(teamUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  useEffect(() => {
    if (filteredTeams) {
      const tableData: SampleDataRow[] = [];

      filteredTeams.map((i) => {
        const ent = i.entity;
        if (ent._id) {
          tableData.push({
            [ID_FIELD]: { value: ent._id },
            [NAME_FIELD]: { value: ent.name, avatar: ent.avatar },
            [DESCRIPTION_FIELD]: { value: ent.description || '' },
          });
        }
      });

      setTableData(tableData);
    }
  }, [dispatch, filteredTeams]);

  useEffect(() => {
    if (teams.data.length) {
      setFilteredTeams(
        teams.data
          .filter((t) =>
            searchVal
              ? t.entity.name.toLowerCase().includes(searchVal.toLowerCase())
              : true,
          )
          .sort((a, b) => {
            return new Date(a.entity.meta?.lastUpdated).getTime() >
              new Date(b.entity.meta?.lastUpdated).getTime()
              ? -1
              : 1;
          }),
      );
    }
  }, [searchVal, teams]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.TEAMS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'View Teams'}
          image={OverviewIcon}
          title={'Meet The Teams'}
        />

        <Page>
          <PageInner>
            <StageBodyText>
              The table below shows all of the teams you're apart of.
            </StageBodyText>
            <StageBodyText>
              Tap the button below if you'd like to create a new team, or select
              one to update an existing one.
            </StageBodyText>

            {teamUsage >= QuotaLimits.WARNING && (
              <div style={{ marginBottom: themeContext.margin.xlarge }}>
                {BuildBanner.generateBanner(
                  BannerType.QUOTA,
                  teamUsage >= QuotaLimits.FULL
                    ? AlertStatus.ERROR
                    : AlertStatus.WARNING,
                  ResourceType.TEAMS,
                )}
              </div>
            )}

            <FeatureButton
              action={() => {
                // Reset team and stage data
                dispatch(resetStagesAndSubStages());
                dispatch(fetchTeamSuccess(InitialTeam));
                history.push(RouteName.TEAM_ITEM);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create new team'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
              isDisabled={teamUsage >= QuotaLimits.FULL}
            />

            {teams.data.length > 0 && (
              <EntityFilter
                baseRoute={RouteName.TEAMS}
                title={'Search Teams'}
                inputPlaceholder={'Enter a team name'}
                onSearchValueChange={(value) => setSearchVal(value)}
                totalResultCount={teams.data.length}
                filteredResultCount={filteredTeams?.length}
              />
            )}

            <TableWrapper>
              <DataSample
                loading={loadingTeams}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (value: string) => {
                    dispatch(resetStagesAndSubStages());
                    history.push(`${RouteName.TEAM_ITEM}/${value}`);
                  },
                }}
                iconMap={AvatarIconMap}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default TeamsPage;
