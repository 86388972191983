import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faEdit, faTimes } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import * as SC from '../styled';

export interface SortableItemProps {
  removeAction: () => void;
  editAction?: () => void;
  id: string;
  value: string;
  text?: string;
  color?: string;
  avatar?: string;
}

const SortableItem: FC<SortableItemProps> = ({
  removeAction,
  editAction,
  id,
  text,
  value,
  color,
  avatar,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const themeContext = useContext(ThemeContext);

  return (
    <SC.Tag ref={setNodeRef} style={style} {...attributes} color={color}>
      {avatar && <SC.Avatar src={avatar} alt={`${text} avatar`} />}

      <SC.TagText {...listeners}>{text ? text : value}</SC.TagText>

      {editAction && (
        <SC.TagButton color={color} onClick={editAction}>
          <SC.TagIcon
            icon={faEdit}
            color={
              color
                ? themeContext.colors.system.white
                : themeContext.colors.system.darkGrey
            }
          />
        </SC.TagButton>
      )}
      <SC.TagButton color={color} onClick={removeAction}>
        <SC.TagIcon
          icon={faTimes}
          color={
            color
              ? themeContext.colors.system.white
              : themeContext.colors.system.darkGrey
          }
        />
      </SC.TagButton>
    </SC.Tag>
  );
};

export default SortableItem;
