import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType, RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledDropdownWide,
  StyledH5,
  StyledInput,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { updateDatasetCollection } from '../../../../store/dataset-collection';
import { fetchDatasetMetaSuccess } from '../../../../store/dataset-meta';
import { updateActiveDatasetSubStage } from '../../../../store/dataset-stage';
import { DatasetCreateSubStage } from '../../../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { RootState } from '../../../../store/rootReducer';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import getRandomUserIcon from '../../../../util/user-icon-randomiser/UserIconRandomiser';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import PopupIconSelector from '../../../IconSelector/PopupIconSelector';

const NEXT_STAGE = DatasetCreateSubStage.TYPE;
const GROUP_KEY = 'group';

// Build Dropdown Options from Existing Tags
const buildDatasetDropdownOptions = (
  tag: Interfaces.TagV1[],
): DropdownItemProps[] =>
  tag.map((d) => {
    return {
      key: d.value,
      text: d.value,
      value: d.value,
    };
  });

const NameDatasetStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    datasetMeta,
    datasetMetaAccessLevel,
    activeDataCollectionItem,
    addDatasetMetaCollection,
    editDatasetMeta,
    addDatasetMeta,
  } = useDatasetMeta();
  const avatarRef = useRef<HTMLInputElement>(null);
  const themeContext = useContext(ThemeContext);
  const [datasetName, setDatasetName] = useState<string>();
  const [datasetAvatar, setDatasetAvatar] = useState<string | undefined>();
  const [avatarPopupOpen, setAvatarPopupOpen] = useState<boolean>(false);
  const [avatarButtonMouseOver, setAvatarButtonMouseOver] =
    useState<boolean>(false);
  const [datasetTagOptions, setDatasetTagOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [processComplete, setProcessComplete] = useState<boolean>(false);
  const { organisation } = useOrganisation();
  const [datasetMetaCreated, setDatasetMetaCreated] = useState<boolean>(false);
  const [datasetCollectionCreated, setDatasetCollectionCreated] =
    useState<boolean>(false);
  const [datasetTags, setDatasetTags] = useState<Interfaces.TagV1[]>([]);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );

  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const randomIcon = getRandomUserIcon();

  useEffect(() => {
    if (datasetMeta?.name) {
      setDatasetName(datasetMeta.name);
    }
  }, [datasetMeta?.name]);

  // Create Data Collection
  useEffect(() => {
    if (
      datasetMetaCreated &&
      datasetMeta?._id &&
      !datasetCollectionCreated &&
      !location.pathname.includes('integration')
    ) {
      (async () => {
        const collection = {
          dataSource: {
            dataSourceActionType: Enums.DataSourceActionType.CREATE,
          },
        };
        await addDatasetMetaCollection(datasetMeta, collection);
        setDatasetCollectionCreated(true);
        dispatch(hideLoading());
      })();
    }
  }, [
    addDatasetMetaCollection,
    datasetCollectionCreated,
    datasetMeta,
    datasetMetaCreated,
    dispatch,
  ]);

  // Update created DatasetMeta status
  useEffect(() => {
    if (datasetCollectionCreated && !processComplete) {
      (async () => {
        const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

        // Update current status
        const collection = activeDataCollectionItem
          ? cloned.dataCollections.find(
              (dataCollection) =>
                dataCollection._id === activeDataCollectionItem._id,
            )
          : cloned.dataCollections?.[0];

        if (collection) {
          const currentStatus =
            collection.stages[Enums.DatasetStage.CREATION]?.status;

          (
            collection.stages[
              Enums.DatasetStage.CREATION
            ] as Interfaces.DatasetMetaStage
          ).status =
            currentStatus === Enums.StageStatus.COMPLETED
              ? currentStatus
              : Enums.StageStatus.IN_PROGRESS;

          // Update datasetMeta
          await editDatasetMeta(cloned);

          // Update dataset collection
          dispatch(
            updateDatasetCollection({
              datasetMetaId: datasetMeta?._id,
              collectionId:
                datasetMeta?.dataCollections?.[
                  datasetMeta?.dataCollections.length - 1
                ]?._id,
            }),
          );

          setProcessComplete(true);
          dispatch(hideLoading());
        }
      })();
    }
  }, [
    activeDataCollectionItem,
    datasetCollectionCreated,
    datasetMeta,
    dispatch,
    editDatasetMeta,
    processComplete,
  ]);

  // Process complete
  useEffect(() => {
    if (processComplete && datasetMeta?._id) {
      // Creation/Update has completed successfully
      // Move on to next stage
      history.push(`${RouteName.DATASET_ITEM}/${datasetMeta._id}`);
      dispatch(updateActiveDatasetSubStage(NEXT_STAGE));
    }
  }, [processComplete, datasetMeta?._id, dispatch, history]);

  const processAction = async () => {
    if (datasetMeta) {
      const cloned = cloneDeep(datasetMeta);
      cloned.name = datasetName;
      cloned.avatar = datasetAvatar;
      cloned.tags = datasetTags;

      if (!datasetMeta._id) {
        // Create datasetMeta
        dispatch(showLoading({ text: 'Creating Dataset...' }));

        await addDatasetMeta(cloned);

        return setDatasetMetaCreated(true);
      }

      // Update datasetMeta
      dispatch(showLoading({ text: 'Updating Dataset...' }));

      await editDatasetMeta(cloned);

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  // Collate tags from existing datasetMetas
  useEffect(() => {
    const dsmTags = datasetMetas?.reduce((acc: Interfaces.TagV1[], dsm) => {
      if (!dsm.entity.tags) {
        return acc;
      }

      return [
        ...acc,
        ...dsm.entity.tags.filter(
          (tag) => !acc.find((t) => t.value === tag.value),
        ),
      ];
    }, []);

    setDatasetTagOptions(
      buildDatasetDropdownOptions(dsmTags.concat(datasetTags)),
    );
  }, [datasetMetas, datasetTags]);

  useEffect(() => {
    setDatasetAvatar(
      datasetMeta?.avatar && datasetMeta?.avatar.includes('http')
        ? datasetMeta.avatar
        : datasetMeta?.avatar
        ? { ...UserIconMap, ...AvatarIconMap }[datasetMeta?.avatar]
        : organisation?.theme?.logo
        ? organisation.theme.logo
        : randomIcon,
    );
  }, [
    datasetAvatar,
    datasetMeta?.avatar,
    datasetMeta?.name,
    organisation?.theme?.logo,
    randomIcon,
  ]);

  useEffect(() => {
    if (datasetMeta?.tags) {
      setDatasetTags(datasetMeta.tags);
    }
  }, [datasetMeta?.name, datasetMeta?.tags]);

  // Handle Adding Tags and Dropdown Formatting
  const handleTagAdd = (value: string) => {
    const cloned = cloneDeep(datasetTags);
    cloned.push({ key: GROUP_KEY, value });
    setDatasetTags(cloned);
  };

  // Handle Avatar change for Popup Selector
  const handleAvatarChange = (icon: string) => {
    setDatasetAvatar(icon);
    const cloned = cloneDeep(datasetMeta);
    cloned.avatar = icon;
    cloned.tags = datasetTags;
    dispatch(
      fetchDatasetMetaSuccess({
        entity: cloned,
        accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  // Handle Tag Change and Correct formatting for DatasetMeta
  const handleTagChange = (field, value) => {
    const cloned = cloneDeep(datasetMeta);
    const valueCopy = value.map((tag) => {
      return { key: GROUP_KEY, value: tag.toString() };
    });
    const concatTags = valueCopy;
    cloned[field] = concatTags;
    setDatasetTags(concatTags);
  };

  return (
    <>
      <StageWrapper>
        <StageInner>
          <div
            style={{ marginBottom: themeContext.margin.xlarge }}
            ref={avatarRef}
            onMouseEnter={() => {
              if (!avatarPopupOpen) {
                setAvatarButtonMouseOver(true);
              }
            }}
            onMouseLeave={() => {
              setAvatarButtonMouseOver(false);
            }}
          >
            <FeatureButton
              text={avatarButtonMouseOver ? 'Edit Avatar' : undefined}
              isActive={avatarPopupOpen}
              action={() => setAvatarPopupOpen(true)}
              color={
                avatarButtonMouseOver
                  ? themeContext.colors.general.blue
                  : themeContext.colors.system.white
              }
              image={!avatarButtonMouseOver ? datasetAvatar : undefined}
              size={100}
              faceStyle={
                datasetAvatar?.includes('http')
                  ? {
                      backgroundSize: 'contain',
                    }
                  : {}
              }
            />
          </div>

          <PopupIconSelector
            context={avatarRef}
            open={avatarPopupOpen}
            setOpenStatus={setAvatarPopupOpen}
            entityType={EntityType.DATASET}
            action={(icon) => handleAvatarChange(icon)}
            selectedIcon={datasetAvatar}
          />

          <StageBodyText>
            Naming your dataset is the first step in your validation journey.
          </StageBodyText>
          <StageBodyText>
            This has to be unique, but don’t worry you can always rename it
            later.
          </StageBodyText>

          <StyledH5>Dataset Name</StyledH5>
          <StyledInput
            placeholder={'Enter your dataset name'}
            value={datasetName || ''}
            onChange={(event, data) => setDatasetName(data.value)}
          />
          <StyledH5
            style={{
              marginTop: themeContext.margin.large,
              marginBottom: themeContext.margin.small,
            }}
          >
            Tags
          </StyledH5>
          <StyledDropdownWide
            style={{
              marginTop: themeContext.margin.small,
            }}
            selectOnBlur={false}
            placeholder={`Enter your tags`}
            noResultsMessage={'Type to add a new tag'}
            text={'Add tags to easily group and search your datasets.'}
            search
            allowAdditions
            selection
            multiple
            options={datasetTagOptions}
            value={datasetTags?.map((tag) => tag.value) || []}
            onAddItem={(event, data) => handleTagAdd(data.value)}
            onChange={(e, { value }) => handleTagChange('tags', value)}
          />
        </StageInner>

        <ActionBar
          text={`Ready to carry on?`}
          primaryButton={
            <FeatureButton
              isDisabled={!datasetName}
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue to Source'}
            />
          }
        />
      </StageWrapper>
    </>
  );
};

export default NameDatasetStage;
