import ApiConnectionIcon from '../../assets/icons/connections/api-connection-icon.svg';
import accessIcon from '../../assets/icons/stages/configuration/access.svg';
import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import createIcon from '../../assets/icons/stages/create-project/create.svg';
import createAvatarIcon from '../../assets/icons/stages/create-team/avatar.svg';
import {
  IntegrationConfigurationSubStage,
  IntegrationCreationSubStage,
  IntegrationStage,
  IntegrationSubStages,
} from '../../store/integration-stage/initial-state';
import { SubStageContent } from '../stage-content/StageHeaderContent';

interface IntegrationStageMeta {
  [key: string]: {
    title: string;
  };
}

interface IntegrationStageContent {
  stage: number;
  image: string;
  title: string;
}

interface IntegrationSubStageContent {
  [IntegrationStage.CREATION]: {
    subStages: Record<string, SubStageContent>;
  };
  [IntegrationStage.CONFIGURATION]: {
    subStages: Record<string, SubStageContent>;
  };
}

const integrationStageMeta: IntegrationStageMeta = {
  [IntegrationStage.CREATION]: {
    title: 'Create Integration',
  },
  [IntegrationStage.CONFIGURATION]: {
    title: 'Caution Advised',
  },
};

export const getIntegrationStageTitle = (stage: IntegrationStage): string => {
  return integrationStageMeta[stage].title;
};

export const getIntegrationStageContent = (
  stage: IntegrationStage,
): IntegrationStageContent => {
  const integrationStageContent = {
    [IntegrationStage.CREATION]: {
      stage: 1,
      image: createIcon,
      title: `It All Starts With A Name`,
    },
    [IntegrationStage.CONFIGURATION]: {
      stage: 1,
      image: configurationAdvancedIcon,
      title: 'Caution Advised',
    },
  };

  return integrationStageContent[stage] || integrationStageContent[0];
};

export const getIntegrationSubStageContent = (
  stage: IntegrationStage,
  subStage: IntegrationSubStages,
): SubStageContent => {
  const subStageContent: IntegrationSubStageContent = {
    [IntegrationStage.CREATION]: {
      subStages: {
        [IntegrationCreationSubStage.META]: {
          stage: 1,
          image: createIcon,
          title: `It All Starts With A Name`,
        },
        [IntegrationCreationSubStage.AVATAR]: {
          stage: 2,
          image: createAvatarIcon,
          title: `Pick The Perfect Picture`,
        },
        [IntegrationCreationSubStage.ENDPOINTS]: {
          stage: 3,
          image: ApiConnectionIcon,
          title: `Create your Endpoints`,
        },
        [IntegrationCreationSubStage.ACCESS]: {
          stage: 4,
          image: accessIcon,
          title: `Access Control`,
        },
      },
    },

    [IntegrationStage.CONFIGURATION]: {
      subStages: {
        [IntegrationConfigurationSubStage.ADVANCED]: {
          stage: 1,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
