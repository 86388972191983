import { Enums } from '@configur-tech/upit-core-types';
import { PortalItem } from '../../services/portal/PortalService';

const InitialPortal: PortalItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    organisationId: '',

    name: '',

    avatar: '',

    description: '',

    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    connections: {
      cmsIds: [],
      formIds: [],
      apiIds: [],
    },

    allowAggregations: false,
  },
};

export default InitialPortal;
