import { Enums } from '@configur-tech/upit-core-types';

export enum DatasetCreateSubStage {
  NAME = 'name',
  AVATAR = 'avatar',
  TYPE = 'type',
  CONFIGURE = 'configure',
  CONFIRM = 'confirm',
  RESULT = 'result',
}

export enum DatasetStructureSubStage {
  DISCOVERY = 'discovery',
  CONFIRMATION = 'confirmation',
}

export enum DatasetValidateSubStage {
  RESULT = 'result',
}

export enum DatasetEnhanceSubStage {
  ENHANCE = 'enhance',
}

export enum DatasetEventsSubStage {
  VIEW = 'view',
}

export enum DatasetConfigureSubStage {
  ACCESS = 'access',
  SNAPSHOTS = 'snapshots',
  ADVANCED = 'advanced',
}

export type DatasetSubStages =
  | DatasetCreateSubStage
  | DatasetStructureSubStage
  | DatasetValidateSubStage
  | DatasetEnhanceSubStage
  | DatasetEventsSubStage
  | DatasetConfigureSubStage;

interface DatasetSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export enum AdditionalDatasetStages {
  EVENTS = 'events',
  PIPELINES = 'pipelines',
}

export interface DatasetStages {
  activeStage: Enums.DatasetStage | AdditionalDatasetStages;
  stages: {
    [Enums.DatasetStage.CREATION]: {
      activeSubStage: DatasetCreateSubStage;
      subStages: DatasetSubStage[];
    };
    [Enums.DatasetStage.STRUCTURE]: {
      activeSubStage: DatasetStructureSubStage;
      subStages: DatasetSubStage[];
    };
    [Enums.DatasetStage.VALIDATION]: {
      activeSubStage: DatasetValidateSubStage;
      subStages: DatasetSubStage[];
    };
    [Enums.DatasetStage.CONFIGURATION]: {
      activeSubStage: DatasetConfigureSubStage;
      subStages: DatasetSubStage[];
    };
    [AdditionalDatasetStages.EVENTS]: {
      activeSubStage: DatasetEventsSubStage;
      subStages: DatasetSubStage[];
    };
    [AdditionalDatasetStages.PIPELINES]: {
      activeSubStage: DatasetEventsSubStage;
      subStages: DatasetSubStage[];
    };
  };
}

const InitialDatasetStage: DatasetStages = {
  activeStage: Enums.DatasetStage.CREATION,
  stages: {
    [Enums.DatasetStage.CREATION]: {
      activeSubStage: DatasetCreateSubStage.NAME,
      subStages: [
        {
          subStage: DatasetCreateSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: DatasetCreateSubStage.TYPE,
          stage: 2,
          title: 'Source',
        },
        {
          subStage: DatasetCreateSubStage.RESULT,
          stage: 3,
          title: 'Results',
        },
      ],
    },
    [Enums.DatasetStage.STRUCTURE]: {
      activeSubStage: DatasetStructureSubStage.DISCOVERY,
      subStages: [
        {
          subStage: DatasetStructureSubStage.DISCOVERY,
          stage: 1,
          title: 'Discover',
        },
        {
          subStage: DatasetStructureSubStage.CONFIRMATION,
          stage: 2,
          title: 'Confirm',
        },
      ],
    },
    [Enums.DatasetStage.VALIDATION]: {
      activeSubStage: DatasetValidateSubStage.RESULT,
      subStages: [
        {
          subStage: DatasetValidateSubStage.RESULT,
          stage: 1,
          title: 'Results',
        },
      ],
    },
    [Enums.DatasetStage.CONFIGURATION]: {
      activeSubStage: DatasetConfigureSubStage.ACCESS,
      subStages: [
        {
          subStage: DatasetConfigureSubStage.ACCESS,
          stage: 1,
          title: 'Access',
        },
        {
          subStage: DatasetConfigureSubStage.SNAPSHOTS,
          stage: 2,
          title: 'Snapshots',
        },
        {
          subStage: DatasetConfigureSubStage.ADVANCED,
          stage: 3,
          title: 'Advanced Settings',
        },
      ],
    },
    [AdditionalDatasetStages.EVENTS]: {
      activeSubStage: DatasetEventsSubStage.VIEW,
      subStages: [
        {
          subStage: DatasetEventsSubStage.VIEW,
          stage: 1,
          title: 'View',
        },
      ],
    },
    [AdditionalDatasetStages.PIPELINES]: {
      activeSubStage: DatasetEventsSubStage.VIEW,
      subStages: [
        {
          subStage: DatasetEventsSubStage.VIEW,
          stage: 1,
          title: 'View',
        },
      ],
    },
  },
};

export default InitialDatasetStage;
