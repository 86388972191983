import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums';
import { EntityType } from '../../../../enums/EntityType';
import useTeam from '../../../../hooks/team/UseTeam';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../../main/theme';
import { showModal } from '../../../../store/modal';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from '../../styled';

const TeamItemAdvancedStage: FC = () => {
  const dispatch = useDispatch();
  const { team } = useTeam();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        <SC.SettingWrapper>
          <StyledSubHeader>Delete Team</StyledSubHeader>

          <StageBodyText>
            This will destroy all data. Be absolutely sure you wish to delete
            this team before proceeding.
          </StageBodyText>

          <FeatureButton
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.DELETION,
                  additionalProps: {
                    entityType: EntityType.TEAM,
                    entityId: team?._id,
                    entityName: team?.name,
                  },
                }),
              )
            }
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={'Delete Team'}
          />
        </SC.SettingWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              history.push(`${RouteName.TEAMS}`);
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Teams'}
          />
        }
      />
    </StageWrapper>
  );
};

export default TeamItemAdvancedStage;
