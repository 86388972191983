import { Enums } from '@configur-tech/upit-core-types';
import { DropdownItemProps } from 'semantic-ui-react';
import { startCase } from 'lodash';

export const HOUR_IDENTIFIER = 'h';
export const EXTRACT_IDENTIFIER = 'EXTRACT';

export const dynamicDate = Object.entries(Enums.DynamicDateValue).map(
  ([key, val]) => {
    const type = startCase(key.toLowerCase());

    return {
      key: `date-value-${type}`,
      value: val,
      text: type,
    };
  },
  [],
) as DropdownItemProps[];

export const dateExtractMonthShort = (name: string): DropdownItemProps[] => [
  {
    key: `date-month-short-${name}-jan`,
    value: 'jan',
    text: 'Jan',
  },
  {
    key: `date-month-short-${name}-feb`,
    value: 'feb',
    text: 'Feb',
  },
  {
    key: `date-month-short-${name}-mar`,
    value: 'mar',
    text: 'Mar',
  },
  {
    key: `date-month-short-${name}-apr`,
    value: 'apr',
    text: 'Apr',
  },
  {
    key: `date-month-short-${name}-may`,
    value: 'may',
    text: 'May',
  },
  {
    key: `date-month-short-${name}-jun`,
    value: 'jun',
    text: 'Jun',
  },
  {
    key: `date-month-short-${name}-jul`,
    value: 'jul',
    text: 'Jul',
  },
  {
    key: `date-month-short-${name}-aug`,
    value: 'aug',
    text: 'Aug',
  },
  {
    key: `date-month-short-${name}-sep`,
    value: 'sep',
    text: 'Sep',
  },
  {
    key: `date-month-short-${name}-oct`,
    value: 'oct',
    text: 'Oct',
  },
  {
    key: `date-month-short-${name}-nov`,
    value: 'nov',
    text: 'Nov',
  },
  {
    key: `date-month-short-${name}-dec`,
    value: 'dec',
    text: 'Dec',
  },
];

export const dateExtractMonthFull = (name: string): DropdownItemProps[] => [
  {
    key: `date-month-full-${name}-january`,
    value: 'january',
    text: 'January',
  },
  {
    key: `date-month-full-${name}-february`,
    value: 'february',
    text: 'February',
  },
  {
    key: `date-month-full-${name}-march`,
    value: 'march',
    text: 'March',
  },
  {
    key: `date-month-full-${name}-april`,
    value: 'april',
    text: 'April',
  },
  {
    key: `date-month-full-${name}-may`,
    value: 'may',
    text: 'May',
  },
  {
    key: `date-month-full-${name}-june`,
    value: 'june',
    text: 'June',
  },
  {
    key: `date-month-full-${name}-july`,
    value: 'july',
    text: 'July',
  },
  {
    key: `date-month-full-${name}-august`,
    value: 'august',
    text: 'August',
  },
  {
    key: `date-month-full-${name}-september`,
    value: 'september',
    text: 'September',
  },
  {
    key: `date-month-full-${name}-october`,
    value: 'october',
    text: 'October',
  },
  {
    key: `date-month-full-${name}-november`,
    value: 'november',
    text: 'November',
  },
  {
    key: `date-month-full-${name}-december`,
    value: 'december',
    text: 'December',
  },
];

export const dateExtractMonthSingle = (name: string): DropdownItemProps[] => [
  {
    key: `date-month-single-${name}-j`,
    value: 'j',
    text: 'J',
  },
  {
    key: `date-month-single-${name}-f`,
    value: 'f',
    text: 'F',
  },
  {
    key: `date-month-single-${name}-m`,
    value: 'm',
    text: 'M',
  },
  {
    key: `date-month-single-${name}-a`,
    value: 'a',
    text: 'A',
  },
  {
    key: `date-month-single-${name}-s`,
    value: 's',
    text: 'S',
  },
  {
    key: `date-month-single-${name}-o`,
    value: 'o',
    text: 'O',
  },
  {
    key: `date-month-single-${name}-n`,
    value: 'n',
    text: 'N',
  },
  {
    key: `date-month-single-${name}-d`,
    value: 'd',
    text: 'D',
  },
];
