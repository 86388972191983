import { faClose, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DefaultPopupStyles } from '../../main/theme';
import { DatasetState } from '../../store/dataset';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';
import * as SC from './styled';

interface DataSampleRowActionBarProps {
  selectedRows: string[];
  resetSelectedRows: (set: boolean) => void;
  refreshDatasetEntries?: () => void;
}

const DataSampleRowActionBar: FC<DataSampleRowActionBarProps> = ({
  selectedRows,
  resetSelectedRows,
  refreshDatasetEntries,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { cmsId, datasetMetaId, portalId, groupId } = useParams();

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );

  const getDatasetRowId = (rowId: string) =>
    datasetState.data[datasetMetaId].entries.find(
      (e, i) => i.toString() === rowId,
    )?.row_id;

  return (
    <SC.RowActionWrapper>
      <SC.RowActionPanel backgroundColor={themeContext.colors.general.blue}>
        <SC.SelectedRowCount>{selectedRows?.length}</SC.SelectedRowCount>
      </SC.RowActionPanel>
      <SC.RowActionPanel wide>
        <SC.RowActionDetail>
          <SC.RowActionDetailText>
            {`${selectedRows.length > 1 ? 'Rows' : 'Row'} selected`}
          </SC.RowActionDetailText>
        </SC.RowActionDetail>
        <SC.RowActionButtons>
          <Popup
            content={`Edit ${selectedRows.length > 1 ? 'Rows' : 'Row'}`}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  action={() =>
                    dispatch(
                      showModal({
                        visible: true,
                        modal:
                          selectedRows.length > 1
                            ? ModalTypes.CMS_MULTIPLE_ROWS
                            : ModalTypes.CMS_ADD_ROW,
                        additionalProps: {
                          rowId:
                            selectedRows.length === 1 &&
                            getDatasetRowId(selectedRows[0]),
                          rowIds: selectedRows.length > 1 && selectedRows,
                          canEditData: true && selectedRows.length === 1,
                          cmsId,
                          portalId,
                          groupId,
                        },
                        style: {
                          overflow: 'hidden',
                          padding: 0,
                        },
                      }),
                    )
                  }
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.sea}
                  icon={
                    <FontAwesomeIcon
                      icon={faPen}
                      color={themeContext.colors.system.white}
                      size={'lg'}
                    />
                  }
                />
              </div>
            }
          />
          <Popup
            content={`Delete ${selectedRows.length > 1 ? 'Rows' : 'Row'}`}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  action={() =>
                    dispatch(
                      showModal({
                        visible: true,
                        modal:
                          selectedRows.length > 1
                            ? ModalTypes.CMS_MULTIPLE_ROWS
                            : ModalTypes.CMS_ADD_ROW,
                        additionalProps: {
                          rowId:
                            selectedRows.length === 1 &&
                            getDatasetRowId(selectedRows[0]),
                          rowIds: selectedRows.length > 1 && selectedRows,
                          cmsId,
                          portalId,
                          groupId,
                          deletionOnly: true,
                          forceRefresh: () =>
                            refreshDatasetEntries && refreshDatasetEntries(),
                        },
                        style: {
                          overflow: 'hidden',
                          padding: 0,
                        },
                      }),
                    )
                  }
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.sea}
                  icon={
                    <FontAwesomeIcon
                      icon={faTrash}
                      color={themeContext.colors.system.white}
                      size={'lg'}
                    />
                  }
                />
              </div>
            }
          />
          <Popup
            content={'Clear Selection'}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <FeatureButton
                  action={() => resetSelectedRows(false)}
                  size={FeatureButtonSize.EXTRA_SMALL}
                  height={30}
                  color={themeContext.colors.general.red}
                  icon={
                    <FontAwesomeIcon
                      icon={faClose}
                      color={themeContext.colors.system.white}
                      size={'lg'}
                    />
                  }
                />
              </div>
            }
          />
        </SC.RowActionButtons>
      </SC.RowActionPanel>
    </SC.RowActionWrapper>
  );
};

export default DataSampleRowActionBar;
