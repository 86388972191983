import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import { DatasetMetaOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MergeMode, RouteName } from '../../enums';
import DatasetService, {
  DatasetGetResponse,
  DatasetMergeResponse,
} from '../../services/dataset/DatasetService';
import {
  createDatasetRow,
  deleteDatasetRow,
  deleteDatasetRows,
  fetchAggregatedDataset,
  fetchDataset,
  fetchGraphDataset,
  mergeSilentUpdateRows,
  updateDatasetRow,
  updateDatasetRows,
} from '../../store/dataset';
import { updateDatasetCollection } from '../../store/dataset-collection';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import { hideLoading, showLoading } from '../../store/loading';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../dataset-meta/UseDatasetMeta';
import useFilter, { DynamicConditionalField } from '../filter/UseFilter';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

const PAGE_SIZE = 250;

interface useDatasetResult {
  getDataset: (
    datasetMetaId,
    page?: number,
    sortBy?: { id: string; desc: boolean },
    limit?: number,
    cmsId?: string,
    groupId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
  ) => void;

  getAggregatedDataset: (
    aggregationId: string,
    filters: DynamicConditionalField[],
    page?: number,
    portalId?: string,
  ) => void;

  getGraphDataset: (
    graphId: string,
    filters: DynamicConditionalField[],
    page?: number,
    portalId?: string,
    showRawGraphData?: boolean,
    limit?: number,
  ) => void;

  getDatasetDirect: (
    datasetMetaId: string,
    filters: DynamicConditionalField[],
    page?: number,
    connectionId?: string,
    groupId?: string,
    portalId?: string,
  ) => Promise<DatasetGetResponse>;

  addRow: (
    datasetMetaId: string,
    datasetMetaAccessLevel: Enums.AccessLevel,
    entries: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
    ignoreDatasetMetaUpdate?: boolean,
    preserveRowIds?: boolean,
  ) => void;

  editRow: (
    datasetMetaId: string,
    updateData: Record<string, unknown>,
    rowId: number,
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
    isLiveEditing?: boolean,
  ) => void;

  editRows: (
    datasetMetaId: string,
    updateData: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
  ) => void;

  removeRow: (
    datasetMetaId: string,
    datasetMetaAccessLevel: Enums.AccessLevel,
    rowId: number,
    connectionId?: string,
    portalId?: string,
  ) => void;

  removeRows: (
    datasetMetaId: string,
    datasetMetaAccessLevel: Enums.AccessLevel,
    rowIds: number[],
    connectionId?: string,
    portalId?: string,
  ) => void;

  mergeDataset: (
    datasetMetaId: string,
    collectionId: string,
    mergeDatasetFieldId: string,
    mergeFileField: string,

    pageNum: number,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    sheetName?: string,
    mergeMode?: MergeMode,

    totalCount?: number,
  ) => Promise<DatasetMergeResponse | undefined>;
}

const useDataset = (limit?: number): useDatasetResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const history = useHistory();
  const { lockCollection } = useDatasetCollection();
  const { datasetMeta, activeDataCollectionItem, editDatasetMeta } =
    useDatasetMeta();

  const { getDatasetFilters, stripMeasureFieldDetails } = useFilter();

  const getDataset = useCallback(
    async (
      datasetMetaId,
      page = 1,
      sortBy?: { id: string; desc: boolean },
      limit?: number,
      cmsId?: string,
      groupId?: string,
      portalId?: string,
      includeCommentsCounts?: boolean,
    ) => {
      const token = await getAccessTokenSilently();
      if (token && datasetMetaId) {
        await dispatch(
          fetchDataset(
            token,
            datasetMetaId,
            getDatasetFilters(datasetMetaId, true),
            page,
            cmsId,
            groupId,
            portalId,
            sortBy,
            limit,
            includeCommentsCounts,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, getDatasetFilters],
  );

  const getGraphDataset = useCallback(
    async (
      graphId: string,
      filters: DynamicConditionalField[],
      page = 1,
      portalId?: string,
      showRawGraphData?: boolean,
      limit?: number,
    ) => {
      const token = await getAccessTokenSilently();

      // Strip unrequired measure details
      const updatedFilters = filters.map((f) => stripMeasureFieldDetails(f));

      if (token && graphId) {
        try {
          await dispatch(
            fetchGraphDataset(
              token,
              graphId,
              updatedFilters,
              page,
              portalId,
              showRawGraphData,
              limit,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load graph data.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, stripMeasureFieldDetails],
  );

  const getAggregatedDataset = useCallback(
    async (
      aggregationId: string,
      filters: DynamicConditionalField[],
      page = 1,
      portalId?: string,
    ) => {
      const token = await getAccessTokenSilently();

      // Strip unrequired measure details
      const updatedFilters = filters.map((f) => stripMeasureFieldDetails(f));

      if (token && aggregationId) {
        try {
          await dispatch(
            fetchAggregatedDataset(
              token,
              aggregationId,
              updatedFilters,
              page,
              portalId,
            ),
          );
        } catch (err) {
          const errMessage = (err as AxiosError)?.response?.data?.error;
          const shortErr = errMessage?.split(';')[0];

          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load query data. ${
              shortErr?.length
                ? `${shortErr}. Please remove or rename this column.`
                : ''
            }`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, stripMeasureFieldDetails],
  );

  const getDatasetDirect = useCallback(
    async (
      datasetMetaId: string,
      filters: DynamicConditionalField[],
      page = 1,
      connectionId?: string,
      groupId?: string,
      portalId?: string,
      sortBy?: { id: string; desc: boolean },
    ): Promise<DatasetGetResponse> => {
      const token = await getAccessTokenSilently();

      let dataset;
      if (token && datasetMetaId) {
        dataset = DatasetService.fetchDataset(
          token,
          datasetMetaId,
          filters,
          page,
          connectionId,
          groupId,
          portalId,
          sortBy,
          limit,
        );
      }

      return dataset;
    },
    [getAccessTokenSilently, limit],
  );

  const addRow = useCallback(
    async (
      datasetMetaId: string,
      datasetMetaAccessLevel: Enums.AccessLevel,
      entries: Record<string, unknown>[],
      connectionId?: string,
      portalId?: string,
      includeCommentsCounts?: boolean,
      ignoreDatasetMetaUpdate?: boolean,
      preserveRowIds?: boolean,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && entries && loggedInUser) {
        try {
          await dispatch(
            createDatasetRow(
              token,
              loggedInUser._id,

              datasetMetaId,
              datasetMetaAccessLevel,
              entries,
              connectionId,
              portalId,
              includeCommentsCounts,
              ignoreDatasetMetaUpdate,
              preserveRowIds,
            ),
          );
          toast.success(
            preserveRowIds
              ? `Successfully restored row${entries?.length > 1 ? 's' : ''}.`
              : `Successfully added row.`,
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to add row. Values entered may not match the dataset constraints.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editRow = useCallback(
    async (
      datasetMetaId: string,
      updateData: Record<string, unknown>,
      rowId: number,
      connectionId?: string,
      portalId?: string,
      includeCommentsCounts?: boolean,
      isLiveEditing?: boolean,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && updateData && rowId && loggedInUser) {
        try {
          await dispatch(
            updateDatasetRow(
              token,
              loggedInUser._id,
              datasetMetaId,
              updateData,
              rowId,
              connectionId,
              portalId,
              includeCommentsCounts,
              isLiveEditing,
            ),
          );
          if (isLiveEditing) {
            dispatch(
              mergeSilentUpdateRows({
                datasetMetaId: datasetMetaId,
              }),
            );
          }
          if (!isLiveEditing) {
            toast.success(`Successfully edited row.`);
          }
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit row. Values entered may not match the dataset constraints.`,
          );
          if (isLiveEditing) {
            throw err as string;
          }
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editRows = useCallback(
    async (
      datasetMetaId: string,
      updateData: Record<string, unknown>[],
      connectionId?: string,
      portalId?: string,
      includeCommentsCounts?: boolean,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && updateData && loggedInUser) {
        try {
          await dispatch(
            updateDatasetRows(
              token,
              loggedInUser._id,
              datasetMetaId,
              updateData,
              connectionId,
              portalId,
              includeCommentsCounts,
            ),
          );
          toast.success(`Successfully edited rows.`);
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit rows. Values entered may not match the dataset constraints.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeRow = useCallback(
    async (
      datasetMetaId: string,
      datasetMetaAccessLevel: Enums.AccessLevel,
      rowId: number,
      connectionId?: string,
      portalId?: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && rowId && loggedInUser) {
        try {
          return dispatch(
            deleteDatasetRow(
              token,
              loggedInUser._id,

              datasetMetaId,
              datasetMetaAccessLevel,
              rowId,
              connectionId,
              portalId,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to remove row.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeRows = useCallback(
    async (
      datasetMetaId: string,
      datasetMetaAccessLevel: Enums.AccessLevel,
      rowIds: number[],
      connectionId?: string,
      portalId?: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && rowIds && loggedInUser) {
        try {
          return dispatch(
            deleteDatasetRows(
              token,
              loggedInUser._id,

              datasetMetaId,
              datasetMetaAccessLevel,
              rowIds,
              connectionId,
              portalId,
            ),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to remove rows.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const mergeDataset = useCallback(
    async (
      datasetMetaId: string,
      collectionId: string,
      mergeDatasetFieldId: string,
      mergeFileField: string,

      pageNum = 1,
      entryType?: string,
      hasNoHeadings?: boolean,
      delimiter?: string,
      sheetName?: string,
      mergeMode = MergeMode.ALL,

      totalCount?: number,
    ): Promise<DatasetMergeResponse | undefined> => {
      const token = await getAccessTokenSilently();

      if (
        !token ||
        !loggedInUser?._id ||
        !datasetMetaId ||
        !collectionId ||
        !mergeDatasetFieldId ||
        !mergeFileField
      ) {
        dispatch(hideLoading());
        throw Error('Missing required param or incorrect source type');
      }

      dispatch(
        showLoading({
          text: totalCount
            ? `Merging rows ${Math.min(
                PAGE_SIZE * pageNum,
                totalCount,
              )} of ${totalCount}`
            : 'Merging Dataset...',
        }),
      );

      try {
        const mergeResult = await DatasetService.mergeDataset(
          token,
          loggedInUser._id,

          datasetMetaId,
          collectionId,
          mergeDatasetFieldId,
          mergeFileField,

          pageNum,
          entryType,
          hasNoHeadings,
          delimiter,
          sheetName,
          mergeMode,
        );

        if (mergeResult.pagination.nextPageNum) {
          return mergeDataset(
            datasetMetaId,
            collectionId,
            mergeDatasetFieldId,
            mergeFileField,

            mergeResult.pagination.nextPageNum,
            entryType,
            hasNoHeadings,
            delimiter,
            sheetName,
            mergeMode,

            mergeResult.pagination.totalCount,
          );
        }

        // Update datasetMeta with new source file
        dispatch(
          fetchDatasetMetaSuccess({
            accessLevel: Enums.AccessLevel.MANAGE,
            entity: mergeResult.datasetMeta,
          }),
        );

        dispatch(hideLoading());

        return mergeResult;
      } catch (err) {
        dispatch(hideLoading());
        toast.error(
          `${
            (err as AxiosError)?.response?.data?.statusCode || 'Error'
          } - Failed to merge dataset.`,
        );

        // Remove collection
        await editDatasetMeta(
          (err as AxiosError)?.response?.data?.datasetMeta as DatasetMetaOutput,
        );

        dispatch(
          updateDatasetCollection({
            datasetMetaId: datasetMeta?._id,
            collectionId: activeDataCollectionItem?._id,
          }),
        );
        lockCollection();
        dispatch(resetStagesAndSubStages());
        history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
        dispatch(hideLoading());
      }
    },
    [
      activeDataCollectionItem?._id,
      datasetMeta,
      dispatch,
      editDatasetMeta,
      getAccessTokenSilently,
      history,
      lockCollection,
      loggedInUser?._id,
    ],
  );

  return {
    getDataset,
    getAggregatedDataset,
    getGraphDataset,
    getDatasetDirect,

    addRow,
    editRow,
    editRows,
    removeRow,
    removeRows,

    mergeDataset,
  };
};

export default useDataset;
