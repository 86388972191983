import { FC, useState } from 'react';
import { StyledInput } from '../../main/theme';

export interface StateManagedInputProps {
  placeholder: string;
  initialValue: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const StateManagedInput: FC<StateManagedInputProps> = ({
  placeholder,
  initialValue,
  onChange,
  disabled,
  style,
}) => {
  const [val, setVal] = useState(initialValue);

  const updateVal = (val) => {
    setVal(val);
    onChange(val);
  };

  return (
    <StyledInput
      disabled={disabled}
      placeholder={placeholder}
      style={style}
      value={val}
      onChange={(e) => updateVal(e.target.value)}
    />
  );
};

export default StateManagedInput;
