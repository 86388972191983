import React, { FC } from 'react';
import styled from 'styled-components';
import { DatasetSubStages } from '../../store/dataset-stage/initial-state';
import {
  IntegrationConfigurationSubStage,
  IntegrationCreationSubStage,
} from '../../store/integration-stage/initial-state';
import { ListSubStages } from '../../store/list-stage/initial-state';
import {
  PipelineConfigurationSubStage,
  PipelineCreationSubStage,
} from '../../store/pipeline-stage/initial-state';
import {
  ProjectAggregationSubStage,
  ProjectChartSubStage,
  ProjectConnectionCMSSubStage,
  ProjectConnectionFormSubStage,
  ProjectConnectionPortalSubStage,
  ProjectCreationSubStage,
  ProjectGraphSubStage,
} from '../../store/project-stage/initial-state';
import { TeamSubStages } from '../../store/team-stage/initial-state';
import { UserCreationSubStage } from '../../store/user-stage/initial-state';
import SubStageMarker, { SubStageItem } from '../SubStageMarker/SubStageMarker';

export interface SubStages {
  activeSubStage:
    | DatasetSubStages
    | TeamSubStages
    | ProjectCreationSubStage
    | ProjectAggregationSubStage
    | ProjectConnectionCMSSubStage
    | ProjectConnectionFormSubStage
    | ProjectConnectionPortalSubStage
    | UserCreationSubStage
    | ListSubStages
    | ProjectChartSubStage
    | ProjectGraphSubStage
    | IntegrationCreationSubStage
    | IntegrationConfigurationSubStage
    | PipelineCreationSubStage
    | PipelineConfigurationSubStage;
  subStageItems: SubStageItem[];
}

export interface StageMarkersProps {
  subStages: SubStages;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubStageMarkers: FC<StageMarkersProps> = ({ subStages }) => {
  const markers = subStages.subStageItems.map((i, index) => {
    return (
      <SubStageMarker
        key={`sub-stage-marker-${index}`}
        subStageItem={i}
        index={index}
        activeSubStageNumber={
          subStages.subStageItems.find(
            (ssItem) => ssItem.subStage === subStages.activeSubStage,
          )?.stage
        }
        totalSubStages={subStages.subStageItems.length}
      />
    );
  });

  return <Wrapper>{markers}</Wrapper>;
};

export default SubStageMarkers;
