import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faPen,
  faPlus,
  faSquareCheck,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, isEqual } from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import CMSComments from '../../components/CMS/CMSComments/CMSComments';
import CMSDescription from '../../components/CMS/CMSDescriptions/CMSDescription';
import CMSFilter from '../../components/CMS/CMSFilter/CMSFilter';
import ChartBuilder from '../../components/ChartBuilder/ChartBuilder';
import ContextMenu, {
  ContextMenuHandle,
  ContextMenuItem,
} from '../../components/ContextMenu/ContextMenu';
import DataSample from '../../components/DataSample/DataSample';
import { LoadingIcon, NoResults } from '../../components/DataSample/styled';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import {
  COMMENT_COUNT_HEADER,
  ROW_NUMBER_HEADER,
  SELECTION_HEADER,
} from '../../const/DataSample';
import { FORMULA_TYPES } from '../../const/FormulaConst';
import { PAGE } from '../../const/PaginationConst';
import { EntityType, RouteName } from '../../enums';
import useCMS from '../../hooks/cms/useCMS';
import useCMSData from '../../hooks/cms/useCMSData';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useTableData from '../../hooks/dataset-meta/UseTableData';
import useDataset from '../../hooks/dataset/UseDataset';
import usePagination from '../../hooks/pagination/UsePagination';
import usePortal from '../../hooks/portal/UsePortal';
import useQuery from '../../hooks/query/UseQuery';
import useSiteWideBanner from '../../hooks/sitewide-banner/useSitewideBanner';
import useUsage from '../../hooks/usage/useUsage';
import { SampleData, SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { DefaultPopupStyles, StyledText, defaultTheme } from '../../main/theme';
import { DatasetState, updateDatasetRowSuccess } from '../../store/dataset';
import { showModal } from '../../store/modal';
import { QueryWithDetails } from '../../store/queries';
import { RootState } from '../../store/rootReducer';
import formatEntries from '../../util/entries/FormatEntries';
import { formatNumber } from '../../util/format-number/format-number';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import queryParamsToFilter from '../../util/query-params-to-filter/QueryParamsToFilter';
import CMSHeader from './CMSHeader';
import * as SC from './styled';

const ROW_ID_FIELD = 'row_id';
export const ORIGINAL_ROW_ID_FIELD = 'original_row_id';
export const DEFAULT_PAGE_SIZE = 50;
const PORTAL_CMS = 'portal-cms';
const LOADER_DEBOUNCE_TIME = 500;

export enum Sidebar {
  FILTERS = 'filters',
  COMMENTS = 'comments',
  DESCRIPTION = 'description',
}

const CMSPage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { isSitewideBanner } = useSiteWideBanner();

  const {
    projectId,
    chartId,
    cmsId,
    datasetMetaId,
    aggregationId,
    portalId,
    groupId,
  } = useParams();
  const { activeDataCollectionItem, datasetMetaAccessLevel } = useDatasetMeta();
  const { portal, portalAccessLevel } = usePortal();
  const { query } = useQuery();
  const { createTableData } = useTableData();
  const { cms, checkCommentsEnabledForItem } = useCMS();
  const {
    selectedResource,
    updateCMSSort,
    selectedResourceFetching,
    refreshDatasetEntries,
  } = useCMSData();
  const { datapointQuota } = useUsage();
  const { editRow } = useDataset();
  const isPortal = location.pathname.includes(RouteName.PORTAL);

  const rowId = new URLSearchParams(location.search).get(ROW_NUMBER_HEADER);
  const canEditData = !!(
    (datasetMetaAccessLevel &&
      [Enums.AccessLevel.EDIT, Enums.AccessLevel.MANAGE].includes(
        datasetMetaAccessLevel,
      )) ||
    (portalAccessLevel &&
      [Enums.AccessLevel.EDIT, Enums.AccessLevel.MANAGE].includes(
        portalAccessLevel,
      ))
  );

  const [sidebar, setSidebar] = useState<Sidebar | undefined>(
    rowId ? Sidebar.COMMENTS : Sidebar.FILTERS,
  );
  const [aggregationSchema, setAggregationSchema] = useState<
    Interfaces.SchemaField[]
  >([]);
  const [resetPagination, setResetPagination] = useState<boolean>(true);
  const [tableData, setTableData] = useState<SampleData>();
  const [portalEntries, setPortalEntries] = useState<number>();

  const [displayCheckboxes, setDisplayCheckboxes] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [liveEditing, setLiveEditing] = useState<boolean>(false);

  const rowContextMenuRef = useRef<ContextMenuHandle>(null);

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );
  const filtersState = useSelector((state: RootState) => state.filters);
  const commentsState = useSelector((state: RootState) => state.comments);

  const { calculateRowNumber, calculateRowIndex } = usePagination();

  const entityId = aggregationId || datasetMetaId || chartId;

  const isViewingDataset = location.pathname.includes('view');

  const datasets = useSelector((state: RootState) => state.dataset)?.data;

  const [loading, setLoading] = useState<boolean>(true);

  // Memoise entries
  const entries = useMemo(() => {
    if (
      selectedResource?.resourceId &&
      datasetState?.data?.[selectedResource?.resourceId]?.entries
    ) {
      const ents = datasetState.data[selectedResource?.resourceId].entries;
      setPortalEntries(
        datasetState.data[selectedResource?.resourceId].totalCount,
      );

      const rows = ents.map((entry, index) => {
        if (!aggregationId) {
          entry = {
            ...entry,
            [ORIGINAL_ROW_ID_FIELD]: entry[ROW_ID_FIELD],
          };
        }
        return {
          ...entry,
          [ROW_ID_FIELD]: calculateRowNumber(
            selectedResource?.page || 1,
            index,
            selectedResource?.pageSize || DEFAULT_PAGE_SIZE,
          ),
        };
      });
      return rows;
    }
    return [];
  }, [
    aggregationId,
    calculateRowNumber,
    datasetState.data,
    selectedResource?.page,
    selectedResource?.pageSize,
    selectedResource?.resourceId,
  ]);

  // Build tableData
  useEffect(() => {
    if (selectedResourceFetching) {
      return;
    }

    (async () => {
      const schema =
        selectedResource?.resourceType === EntityType.AGGREGATION
          ? aggregationSchema
          : activeDataCollectionItem?.schemaData;

      if (entries && schema) {
        await setTableData(createTableData(schema, entries));
      }
    })();
  }, [
    activeDataCollectionItem?.schemaData,
    aggregationSchema,
    createTableData,
    entries,
    selectedResource?.resourceId,
    selectedResource?.resourceType,
    selectedResourceFetching,
  ]);

  // Add a delay to loading to allow table to calculate entries
  useEffect(() => {
    if (selectedResourceFetching) {
      setLoading(true);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (!selectedResourceFetching) {
        setLoading(false);
      }
    }, LOADER_DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [tableData, selectedResourceFetching]);

  useEffect(() => {
    if (location.pathname.includes(RouteName.PORTAL)) {
      localStorage.setItem(PORTAL_CMS, location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Reset pagination when filters change
  useEffect(() => {
    setResetPagination(true);
  }, [filtersState]);

  // Reset live edit and multiselect toggle
  useEffect(() => {
    if (aggregationId) {
      setLiveEditing(false);
      setDisplayCheckboxes(false);
    }
  }, [aggregationId]);

  // Set entries
  useEffect(() => {
    if (entries) {
      if (selectedResource?.resourceType === EntityType.AGGREGATION) {
        setAggregationSchema(formatEntries(entries, query));
      }

      setResetPagination(false);
    }
  }, [entries, query, selectedResource?.resourceType]);

  // DatasetMetaId or AggregationId has changed, reset pagination
  useEffect(() => {
    if (entityId && !isViewingDataset) {
      setResetPagination(true);

      if (
        sidebar === Sidebar.COMMENTS &&
        !checkCommentsEnabledForItem(entityId, groupId)
      ) {
        setSidebar(Sidebar.FILTERS);
      }
    }
  }, [
    isViewingDataset,
    sidebar,
    checkCommentsEnabledForItem,
    entityId,
    groupId,
  ]);

  // Remove supercols from Filters
  const filterKeys = entries.length
    ? Object.keys(entries[0]).filter(
        (key) =>
          !query?.queryParams?.supercolumns?.some(
            (supercolumn) => supercolumn.alias === key,
          ),
      )
    : [];

  const updatePagination = useCallback(
    (page: number) => {
      const params = new URLSearchParams(history.location.search);
      params.set(PAGE, page.toString());
      history.push({
        search: params.toString(),
        state: history.location.state,
      });
    },
    [history],
  );

  const handleClickedCommentCell = useCallback(
    (rowId: string, tableRowId: string) => {
      if (commentsState.loading) {
        return;
      }
      let state = {};
      const params = new URLSearchParams(history.location.search);

      if (rowId === params.get(ROW_ID_FIELD)) {
        params.delete(ROW_ID_FIELD);
      } else {
        params.set(ROW_ID_FIELD, rowId);
        state = { tableRowId };
      }
      history.push({
        search: params.toString(),
        state,
      });
    },
    [commentsState.loading, history],
  );

  const handleClickedAggregationRow = (rowId: number) => {
    if (!tableData?.rows || !query?.queryParams?.fields) {
      return;
    }

    const queryHasGroupsOrFilters =
      !!query.queryParams.groups.length || !!query.queryParams.filters.length;

    const combinedQueryFilters = queryParamsToFilter(
      query as QueryWithDetails,
      tableData?.rows.find(
        (row) => (row['row_id'].value as string) === rowId.toString(),
      ) as SampleDataRow,
    );

    // Check if all datasetMetas exist in this workspace
    const cmsConfGroupsDatasetIds = (
      cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
    ).groups
      .flatMap((conf) => conf.items)
      .map((item) => item.datasetMetaId);

    if (
      combinedQueryFilters
        .map((filter) => filter.datasetMetaId)
        .some((dsmId) => !cmsConfGroupsDatasetIds.includes(dsmId))
    ) {
      return;
    }

    // If single query, navigate to dataset with built filters
    if (query?.queryParams.aggregationType === Enums.AggregationType.SINGLE) {
      history.push({
        pathname: portalId
          ? `${RouteName.PORTAL}/${portal?._id}/cms/${cmsId}/${groupId}/dataset/${query?.queryParams?.datasetMetaId}`
          : `${RouteName.PROJECT_ITEM}/${projectId}/cms/${cmsId}/${groupId}/dataset/${query?.queryParams?.datasetMetaId}`,
        state:
          combinedQueryFilters && queryHasGroupsOrFilters
            ? {
                filters: JSON.stringify(
                  combinedQueryFilters.find(
                    (f) =>
                      f.datasetMetaId === query?.queryParams?.datasetMetaId,
                  )?.filters,
                ),
              }
            : undefined,
      });
    } else {
      // Show the linked dataset modal for multi dataset queries
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.CMS_QUERY_LINKED_DATASETS,
          fullScreen: true,
          additionalProps: {
            queryFilters: combinedQueryFilters,
            cmsId: cms?._id,
            portalId,
          },
        }),
      );
    }
  };

  const handleRowContextMenu = (
    e: React.MouseEvent<HTMLTableCellElement>,
    selectedRowId: number,
    tableRowId: number,
  ) => {
    rowContextMenuRef?.current?.initialiseMenu(e, selectedRowId, tableRowId);
  };

  const rowContextMenuItems: ContextMenuItem[] = [
    {
      text: 'Copy Row',
      icon: 'copy outline',
      action: (rowId) => {
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.CMS_ADD_ROW,
            additionalProps: {
              cmsId,
              portalId,
              groupId,
              canEditData,
              duplicationId: rowId,
            },
            style: {
              overflow: 'hidden',
              padding: 0,
            },
          }),
        );
      },
    },
    {
      text: 'Delete Row',
      icon: 'trash alternate outline',
      action: (rowId, tableRowId) => {
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.CMS_ADD_ROW,
            additionalProps: {
              rowId: rowId,
              cmsId,
              portalId,
              groupId,
              canEditData,
              deletionOnly: true,
              tableRowId: tableRowId,
            },
            style: {
              overflow: 'hidden',
              padding: 0,
            },
          }),
        );
      },
    },
  ];

  const handleToggleAction = (rowIds: string[]) => {
    if (!isEqual(rowIds, selectedRows)) {
      setSelectedRows(rowIds);
    }
  };

  const processRowEntry = (rowEntry: Record<string, string | number>) =>
    Object.entries(rowEntry).reduce((acc, [k, v]) => {
      const field = activeDataCollectionItem?.schemaData?.find(
        (f) => f.name === k,
      );
      const fieldType = field?.dataValidation?.dataValidationType;

      const value =
        fieldType === Enums.ValueDataType.NUMBER ? formatNumber(v) : v;

      const isFormula =
        !fieldType || (fieldType && FORMULA_TYPES.includes(fieldType));

      const isPercentage =
        (field?.dataValidation?.formatting as Interfaces.NumericalFormatting)
          ?.format === Enums.DataDisplayFormat.PERCENTAGE;

      if (isPercentage) {
        return {
          ...acc,
          [k]: (value ? +value : 0) / 100,
        };
      }

      if (!isFormula) {
        return {
          ...acc,
          [k]: value,
        };
      }

      return acc;
    }, {});

  const handleEditInput = async (
    rowIndex: number,
    columnKey: string,
    value: string,
  ) => {
    const schemaField = activeDataCollectionItem?.schemaData?.find(
      (f) => f.name === columnKey,
    );

    if (schemaField) {
      const calculatedRowIndex = calculateRowIndex(
        selectedResource?.page || 1,
        rowIndex,
        selectedResource?.pageSize,
      );

      const editedRow = datasets[datasetMetaId].entries[calculatedRowIndex - 1];

      if (editedRow) {
        const actualRowId = editedRow.row_id as number;

        // Check for any previous silent updates
        const previousSilentUpdatedRow = datasets[
          `silent-${datasetMetaId}`
        ]?.entries.find((row) => row.row_id === actualRowId);

        const cloned = cloneDeep(previousSilentUpdatedRow || editedRow);
        cloned[columnKey] = value;

        try {
          await editRow(
            datasetMetaId,
            processRowEntry(cloned),
            actualRowId,
            cmsId,
            portalId,
            isViewingDataset || !!cmsId,
            true,
          );
        } catch (err) {
          // Revert live edit if saving failed
          dispatch(
            updateDatasetRowSuccess({
              datasetMetaId,
              row: previousSilentUpdatedRow || editedRow,
              silentUpdate: true,
            }),
          );
        }
      }
    }
  };

  const toggleLiveEditing = () => {
    setLiveEditing(!liveEditing);
    setDisplayCheckboxes(liveEditing && false);
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.CMS} />
      <PageContainer>
        <Page>
          {(datasetMetaId || aggregationId || chartId) && (
            <SC.PageOuter sidebarVisible={!!sidebar}>
              <SC.PageInner showBanner={isSitewideBanner}>
                <CMSHeader sidebar={sidebar} setSidebar={setSidebar} />
                <SC.PageContent>
                  {!chartId && tableData && !loading && (
                    <SC.DatasetActions>
                      <div>
                        <StyledText>
                          Showing{' '}
                          <strong>{tableData?.rows?.length || 0}</strong> of{' '}
                          <strong>
                            {(isPortal
                              ? portalEntries
                              : datasetMetaId
                              ? activeDataCollectionItem?.rowCount ||
                                datasetState.data[datasetMetaId]?.totalCount
                              : datasetState.data[aggregationId]?.totalCount) ||
                              0}
                          </strong>{' '}
                          rows
                        </StyledText>

                        <FeatureButton
                          action={() => {
                            // Refresh page to reload datasetMeta for count updates
                            history.go();
                          }}
                          size={FeatureButtonSize.EXTRA_SMALL}
                          height={30}
                          color={themeContext.colors.general.blue}
                          icon={
                            <FontAwesomeIcon
                              icon={faSyncAlt}
                              color={defaultTheme.colors.system.white}
                            />
                          }
                        />
                      </div>

                      <SC.DatasetActionsButtons>
                        {!aggregationId && canEditData && (
                          <>
                            <Popup
                              content={'Live Editing'}
                              position="top center"
                              style={DefaultPopupStyles}
                              trigger={
                                <div>
                                  <FeatureButton
                                    action={toggleLiveEditing}
                                    isActive={liveEditing}
                                    size={FeatureButtonSize.EXTRA_SMALL}
                                    height={30}
                                    color={themeContext.colors.general.purple}
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        color={themeContext.colors.system.white}
                                        size={'lg'}
                                      />
                                    }
                                  />
                                </div>
                              }
                            />
                            <Popup
                              content={'Multi-Select'}
                              position="top center"
                              style={DefaultPopupStyles}
                              trigger={
                                <div>
                                  <FeatureButton
                                    action={() => {
                                      setDisplayCheckboxes(!displayCheckboxes);
                                      setLiveEditing(
                                        displayCheckboxes && false,
                                      );
                                    }}
                                    isActive={displayCheckboxes}
                                    size={FeatureButtonSize.EXTRA_SMALL}
                                    height={30}
                                    color={themeContext.colors.general.blue}
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faSquareCheck}
                                        color={themeContext.colors.system.white}
                                        size={'lg'}
                                      />
                                    }
                                  />
                                </div>
                              }
                            />
                          </>
                        )}
                        {datasetMetaId && canEditData && (
                          <Popup
                            content={'Add Row'}
                            position="top center"
                            style={DefaultPopupStyles}
                            trigger={
                              <div>
                                <FeatureButton
                                  action={() =>
                                    dispatch(
                                      showModal({
                                        visible: true,
                                        modal: ModalTypes.CMS_ADD_ROW,
                                        additionalProps: {
                                          cmsId,
                                          portalId,
                                          groupId,
                                          creating: true,
                                          canEditData,
                                        },
                                        style: {
                                          overflow: 'hidden',
                                          padding: 0,
                                        },
                                      }),
                                    )
                                  }
                                  size={FeatureButtonSize.EXTRA_SMALL}
                                  isDisabled={
                                    datapointQuota?.isDatapointQuotaLimit
                                  }
                                  height={30}
                                  color={themeContext.colors.general.green}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      color={themeContext.colors.system.white}
                                      size={'lg'}
                                    />
                                  }
                                />
                              </div>
                            }
                          />
                        )}
                      </SC.DatasetActionsButtons>
                    </SC.DatasetActions>
                  )}

                  {chartId && selectedResourceFetching && (
                    <NoResults>
                      <LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
                    </NoResults>
                  )}

                  {chartId && !selectedResourceFetching && (
                    <ChartBuilder readOnly={true} />
                  )}

                  {!chartId && (
                    <DataSample
                      showRowSelectionActionBar={
                        displayCheckboxes && !!selectedRows.length
                      }
                      hideSortOnCols={[SELECTION_HEADER]}
                      selectedRows={selectedRows}
                      contextMenuAction={
                        !aggregationId ? handleRowContextMenu : undefined
                      }
                      toggleAction={
                        displayCheckboxes ? handleToggleAction : undefined
                      }
                      loading={loading}
                      allowColumnLock={true}
                      schema={
                        aggregationId
                          ? query?.additionalDetails
                              ?.map((detail) => detail.schemaData)
                              .flat()
                          : tableData?.schema
                      }
                      fullWidth={true}
                      showPagination={true}
                      sampleColumns={tableData?.schema || []}
                      sampleRows={tableData?.rows || []}
                      resetPagination={resetPagination}
                      pageSize={selectedResource?.pageSize || DEFAULT_PAGE_SIZE}
                      paginationAction={updatePagination}
                      totalRows={
                        datasetMetaId
                          ? datasetState?.data?.[datasetMetaId]?.totalCount
                          : (query?._id &&
                              datasetState?.data?.[query._id]?.totalCount) ||
                            0
                      }
                      sortAction={
                        !selectedResourceFetching
                          ? aggregationId
                            ? undefined
                            : updateCMSSort
                          : undefined
                      }
                      resetSort={!selectedResource?.sort}
                      clickableRows={
                        !aggregationId
                          ? {
                              valueField: ORIGINAL_ROW_ID_FIELD,
                              action: (rowId: string) =>
                                dispatch(
                                  showModal({
                                    visible: true,
                                    modal: ModalTypes.CMS_ADD_ROW,
                                    additionalProps: {
                                      rowId: rowId,
                                      cmsId,
                                      portalId,
                                      groupId,
                                      canEditData,
                                      forceRefresh: () =>
                                        refreshDatasetEntries(),
                                    },
                                    style: {
                                      overflow: 'hidden',
                                      padding: 0,
                                    },
                                  }),
                                ),
                            }
                          : {
                              valueField: ROW_ID_FIELD,
                              action: (rowId: string) =>
                                handleClickedAggregationRow(parseInt(rowId)),
                            }
                      }
                      clickableColumn={{
                        columnName: COMMENT_COUNT_HEADER,
                        valueField: ORIGINAL_ROW_ID_FIELD,
                        action: (rowId: string, tableId: string) => {
                          handleClickedCommentCell(rowId, tableId);
                        },
                      }}
                      iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                      entityId={entityId}
                      fixedHeightReduction={275}
                      showPageSize={true}
                      showCommentCount={
                        sidebar === Sidebar.COMMENTS && !aggregationId
                      }
                      isLiveEditing={liveEditing}
                      editAction={handleEditInput}
                      style={
                        !loading && entries.length
                          ? {
                              backgroundColor: defaultTheme.colors.system.grey,
                            }
                          : {}
                      }
                      refreshDatasetEntries={() => refreshDatasetEntries()}
                    />
                  )}
                </SC.PageContent>
              </SC.PageInner>

              {sidebar === Sidebar.FILTERS && (
                <CMSFilter
                  type={
                    aggregationId
                      ? EntityType.AGGREGATION
                      : chartId
                      ? EntityType.CHART
                      : EntityType.DATASET
                  }
                  filterKeys={filterKeys}
                />
              )}
              {sidebar === Sidebar.COMMENTS && <CMSComments />}
              {sidebar === Sidebar.DESCRIPTION && (
                <CMSDescription selectedResource={selectedResource} cms={cms} />
              )}
            </SC.PageOuter>
          )}
        </Page>
      </PageContainer>
      <ContextMenu items={rowContextMenuItems} ref={rowContextMenuRef} />
    </>
  );
};

export default CMSPage;
