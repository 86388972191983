import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_USER'] || '';

export interface UserItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.User;
}

export interface UserItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.UserOutput;
}

export interface UsersItemOutput {
  data: UserItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export interface MeItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.User & {
    organisation: Interfaces.Organisation;
  };
}

export interface MeItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.UserOutput & {
    organisation: Interfaces.OrganisationOutput;
  };
}

export default class UserService {
  public static async getUsers(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<UsersItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<UsersItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getUser(
    token: string,
    id: string,
  ): Promise<UserItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as UserItemOutput;
  }

  public static async getMe(token: string): Promise<MeItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/me}`);

    return result?.data as MeItemOutput;
  }

  public static async getUserByAuth0Id(
    token: string,
    auth0Id: string,
  ): Promise<UserItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { auth0Id: auth0Id, singleDocument: true });

    return result?.data as UserItemOutput;
  }

  public static async postUser(
    token: string,
    user: Interfaces.User,
    userId: string,
  ): Promise<UserItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: user, userId });

    return result?.data as UserItemOutput;
  }

  public static async putUser(
    token: string,
    user: Partial<Interfaces.UserOutput>,
    userId: string,
  ): Promise<UserItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${user._id}`, {
      data: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        jobRole: user.jobRole,
        userType: user.userType,
        avatar: user.avatar,
        email: user.email,
        signupComplete: user.signupComplete,
        termsAccepted: user.termsAccepted,
      },
      userId,
    });

    return result?.data as UserItemOutput;
  }

  public static async deleteUser(
    token: string,
    userToDeleteId: string,
    userId: string,
  ): Promise<UserItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<UserItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${userToDeleteId}`, userId);

    return result?.data as UserItemOutput;
  }
}
