import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../../enums';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import { updateActiveProjectStage } from '../../../../store/project-stage';
import BuildBanner from '../../../../util/buildBanner/BuildBanner';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import Banner from '../../../BannerComponent/Banner';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const ProjectConfigureAccessStage: FC = () => {
  const dispatch = useDispatch();
  const { project, editProject } = useProject();
  const themeContext = useContext(ThemeContext);

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (updated && type && group) {
      // Update project
      const cloned: Interfaces.ProjectOutput = cloneDeep(project);

      cloned.access[type][group] = updated;

      // Save project
      await editProject(cloned);
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <Banner
          message={
            <p>
              Projects have been removed from Configur and these options are now
              read-only.
            </p>
          }
          alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
          alertStatus={AlertStatus.INFO}
        />

        <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
          Add users, teams or the whole organisation to the fields below to
          control who can access your data and what permissions they have.
        </StageBodyText>

        <AccessSelection
          disabled={true}
          access={project?.access}
          onChange={handleChange}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(Enums.ProjectStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectConfigureAccessStage;
