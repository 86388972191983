import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as SC from './styled';

interface TagProps {
  tag: string;
  active: boolean;
  route: string;
}

const TagComponent: FC<TagProps> = ({ tag, active, route }) => {
  const history = useHistory();

  const handleClick = (event) => {
    if (!active) {
      history.push({
        pathname: route,
        search: `search=${event.currentTarget.textContent}`,
      });
    } else {
      history.push({
        pathname: route,
      });
    }
  };

  return (
    <SC.TagBox isSelected={active} onClick={handleClick}>
      {tag}
    </SC.TagBox>
  );
};

export default TagComponent;
