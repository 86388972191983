import { Enums } from '@configur-tech/upit-core-types';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../../enums';
import { EntityType } from '../../../../enums/EntityType';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../../main/theme';
import { showModal } from '../../../../store/modal';
import { updateActiveProjectStage } from '../../../../store/project-stage';
import BuildBanner from '../../../../util/buildBanner/BuildBanner';
import ActionBar from '../../../ActionBar/ActionBar';
import Banner from '../../../BannerComponent/Banner';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from './styled';

const ProjectConfigureAdvancedStage: FC = () => {
  const dispatch = useDispatch();
  const { project } = useProject();
  const themeContext = useContext(ThemeContext);

  return (
    <StageWrapper>
      <StageInner>
        <Banner
          message={
            <p>
              Projects have been removed from Configur and these options are now
              read-only.
            </p>
          }
          alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
          alertStatus={AlertStatus.INFO}
        />

        <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        <SC.SettingWrapper>
          <StyledSubHeader>Delete Project</StyledSubHeader>
          <StageBodyText>Delete your project completely.</StageBodyText>

          <StageBodyText>
            This will destroy all data. Be absolutely sure you wish to delete
            this project before proceeding.
          </StageBodyText>

          {/*Deprecated*/}
          {/*<FeatureButton*/}
          {/*  action={() =>*/}
          {/*    dispatch(*/}
          {/*      showModal({*/}
          {/*        visible: true,*/}
          {/*        modal: ModalTypes.DELETION,*/}
          {/*        additionalProps: {*/}
          {/*          entityType: EntityType.PROJECT,*/}
          {/*          entityId: project?._id,*/}
          {/*          entityName: project?.name,*/}
          {/*        },*/}
          {/*      }),*/}
          {/*    )*/}
          {/*  }*/}
          {/*  size={FeatureButtonSize.WIDE}*/}
          {/*  color={themeContext.colors.general.red}*/}
          {/*  text={'Delete Project'}*/}
          {/*/>*/}
        </SC.SettingWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(Enums.ProjectStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectConfigureAdvancedStage;
