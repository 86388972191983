import { Interfaces } from '@configur-tech/upit-core-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import styled, { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import { AccessEntityType, AccessType } from '../../enums/Access';
import { EntityType } from '../../enums/EntityType';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import useTeam from '../../hooks/team/UseTeam';
import useUser from '../../hooks/user/UseUser';
import {
  defaultTheme,
  StageBodyText,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
} from '../../main/theme';
import { TeamsItemOutput } from '../../services/team/TeamService';
import { UserItemOutput } from '../../services/user/UserService';
import { RootState } from '../../store/rootReducer';
import { TeamsState } from '../../store/teams';
import { UsersState } from '../../store/users';
import resolveAvatar from '../../util/avatar/ResolveAvatar';
import { Loader, LoaderContainer } from '../CMS/CMSLinkedDatasets/styled';

interface AccessSelectionProps {
  access?: Interfaces.Access;
  onChange: (val: string[], type: AccessType, group: AccessEntityType) => void;
  disabled?: boolean;
  hideTypes?: AccessType[];
  hideOrganisation?: boolean;
  hideTeams?: boolean;
  hideUsers?: boolean;
  modifyText?: { type: AccessType; title?: string; body?: string }[];
}

const AccessAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 800px !important;
`;

const SwitchLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.large};

  > span {
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;

export const formatTeamAndUserOptions = (
  type: EntityType.TEAM | EntityType.USER,
  arr: Interfaces.TeamOutput[] | Interfaces.UserOutput[],
) => {
  return arr.map((e, i) => {
    return {
      key: `entry-${e._id}-${i}`,
      value: e._id,
      text: type === EntityType.TEAM ? e.name : `${e.firstName} ${e.lastName}`,
      image: {
        avatar: true,
        src: resolveAvatar(e.avatar),
      },
      description: startCase(type),
    };
  });
};

const AccessSelection: FC<AccessSelectionProps> = ({
  access,
  onChange,
  disabled,
  hideTypes,
  hideOrganisation,
  hideTeams,
  hideUsers,
  modifyText,
}) => {
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { getUsers } = useUser();
  const { getTeams } = useTeam();

  const teamsState: TeamsState = useSelector((state: RootState) => state.teams);
  const teams: TeamsItemOutput = teamsState.data;

  const usersState: UsersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  const [saving, setSaving] = useState<boolean>(false);
  const [teamOptions, setTeamOptions] = useState<DropdownItemProps[]>([]);
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([]);

  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [teamsLoaded, setTeamsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [accordionStage, setAccordionStage] = useState<number[]>([0]);

  // If no teams, fetch them
  useEffect(() => {
    if (!teams.data.length) {
      (async () => {
        await getTeams();
        setTeamsLoaded(true);
      })();
    } else {
      setTeamsLoaded(true);
    }
  }, [getTeams, teams]);

  // Create team options
  useEffect(() => {
    if (teams?.data.length) {
      const formattedTeams = formatTeamAndUserOptions(
        EntityType.TEAM,
        teams.data.map((t) => t.entity),
      );
      setTeamOptions(formattedTeams);
    }
  }, [teams]);

  // If no users, fetch them
  useEffect(() => {
    if (!users.length) {
      (async () => {
        await getUsers();
        setUsersLoaded(true);
      })();
    } else {
      setUsersLoaded(true);
    }
  }, [getUsers, users.length]);

  // Create user options
  useEffect(() => {
    if (users?.length) {
      const formattedUsers = formatTeamAndUserOptions(
        EntityType.USER,
        users.map((u) => u.entity),
      );
      setUserOptions(formattedUsers);
    }
  }, [users]);

  // Access has changed
  useEffect(() => {
    setSaving(false);
  }, [access]);

  useEffect(() => {
    if (usersLoaded && teamsLoaded) {
      setIsLoading(false);
    }
  }, [teamsLoaded, usersLoaded]);

  const buildAccessPanel = (type: AccessType) => {
    const modifiedBodyText = modifyText?.find((m) => m.type === type)?.body;

    return (
      <div>
        {type === AccessType.MANAGE && (
          <StageBodyText>
            {modifiedBodyText ||
              `These users will have full control and be able to manage access directly.`}
          </StageBodyText>
        )}

        {type === AccessType.EDIT && (
          <StageBodyText>
            {modifiedBodyText ||
              `These users will have limited access and whilst they can make edits, but won't be able to delete anything or change access permissions.`}
          </StageBodyText>
        )}

        {type === AccessType.VIEW && (
          <StageBodyText>
            {modifiedBodyText ||
              `These users will have read only access and won't be able to make any changes.`}
          </StageBodyText>
        )}

        {!hideOrganisation && (
          <>
            <StyledBodySubHeader>Organisation</StyledBodySubHeader>

            <SwitchLabel>
              <span>Allow access to entire Organisation</span>
              <Switch
                disabled={saving || disabled}
                onChange={(val) => {
                  setSaving(true);

                  onChange(
                    val && loggedInUser?.organisationId
                      ? [loggedInUser.organisationId]
                      : [],
                    type,
                    AccessEntityType.ORGANISATION,
                  );
                }}
                checked={
                  (loggedInUser &&
                    access?.[type][AccessEntityType.ORGANISATION].includes(
                      loggedInUser.organisationId,
                    )) ||
                  false
                }
                uncheckedIcon={false}
                checkedIcon={false}
                offColor={themeContext.colors.general.sea}
                onColor={themeContext.colors.general.green}
              />
            </SwitchLabel>
          </>
        )}

        {!hideTeams && (
          <>
            <StyledBodySubHeader>Teams</StyledBodySubHeader>
            <StyledDropdownUltraWide
              selectOnBlur={false}
              style={{ marginBottom: themeContext.margin.standard }}
              loading={saving}
              disabled={
                disabled ||
                (loggedInUser &&
                  access?.[type][AccessEntityType.TEAM].includes(
                    loggedInUser.organisationId,
                  )) ||
                saving
              }
              placeholder={`Select a ${startCase(AccessEntityType.TEAM)}`}
              search
              selection
              multiple
              options={teamOptions}
              value={access?.[type][AccessEntityType.TEAM]}
              onChange={(e, data) => {
                setSaving(true);
                onChange(data.value, type, AccessEntityType.TEAM);
              }}
            />
          </>
        )}

        {!hideUsers && (
          <>
            <StyledBodySubHeader>Users</StyledBodySubHeader>
            <StyledDropdownUltraWide
              selectOnBlur={false}
              loading={saving}
              disabled={
                disabled ||
                (loggedInUser &&
                  access?.[type][AccessEntityType.USER].includes(
                    loggedInUser.organisationId,
                  )) ||
                saving
              }
              placeholder={`Select a ${startCase(AccessEntityType.USER)}`}
              selection
              search
              multiple
              options={userOptions}
              value={access?.[type][AccessEntityType.USER]}
              onChange={(e, data) => {
                if (
                  loggedInUser?._id &&
                  access?.[type][AccessEntityType.USER].includes(
                    loggedInUser._id,
                  ) &&
                  !data.value.includes(loggedInUser._id)
                ) {
                  return null;
                }

                setSaving(true);

                onChange(data.value, type, AccessEntityType.USER);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const accessPanels = Object.values(AccessType)
    .filter((a) => (hideTypes ? !hideTypes.includes(a) : true))
    ?.map((t, i) => {
      const modifiedTitleText = modifyText?.find((m) => m.type === t)?.title;

      return {
        key: `access-panel-${t}-${i}`,
        title: (
          <StyledAccordionTitle
            onClick={() => {
              let cloned = cloneDeep(accordionStage);
              if (accordionStage.includes(i)) {
                cloned = cloned.filter((e) => e !== i);
              } else {
                cloned.push(i);
              }
              setAccordionStage(cloned);
            }}
          >
            <StyledBodySubHeader>
              {startCase(modifiedTitleText || t)}
            </StyledBodySubHeader>
            <FontAwesomeIcon
              icon={accordionStage.includes(i) ? faChevronUp : faChevronDown}
              color={defaultTheme.colors.system.offBlack}
            />
          </StyledAccordionTitle>
        ),
        content: (
          <StyledAccordionContent>{buildAccessPanel(t)}</StyledAccordionContent>
        ),
      };
    });

  return (
    <>
      {isLoading && (
        <LoaderContainer>
          <Loader src={DefaultLoadingIcon} alt={'Loading'} />
        </LoaderContainer>
      )}
      {!isLoading && (
        <AccessAccordion
          defaultActiveIndex={[0]}
          exclusive={false}
          panels={accessPanels}
        />
      )}
    </>
  );
};

export default AccessSelection;
