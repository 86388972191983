import styled from 'styled-components';

export const Wrapper = styled.div<{ minimal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  padding: ${({ theme }) => theme.padding.xlarge};
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > div {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.margin.large};

    > div {
      display: flex;
      flex-direction: column;

      > h2 {
        margin: 0;
      }

      > h3 {
        color: ${({ theme }) => theme.colors.system.darkGrey};
        margin: 0;
      }
    }
  }

  > h2 {
    margin: 0;
  }
`;
