import { isEmpty } from 'lodash';
import { useCallback } from 'react';

export const DEFAULT_DATA_PAGE_SIZE = 50;
export const DEFAULT_META_PAGE_SIZE = 25;

export interface SortByItem {
  id: string;
  desc: boolean;
}

interface usePaginationResult {
  calculateRowNumber: (
    pageNum: number,
    rowIndex: number,
    pageSize?: number,
  ) => string;

  calculateRowIndex: (
    pageNum: number,
    rowIndex: number,
    pageSize?: number,
  ) => number;

  updateSortBy: (
    sortFieldMap: Record<string, string>,
    sortBy: SortByItem,
  ) => Record<string, number> | undefined;
}

const usePagination = (): usePaginationResult => {
  const calculateRowNumber = useCallback(
    (pageNum: number, rowIndex: number, pageSize?: number): string => {
      const displayedRowIndex = rowIndex + 1;
      const initialRowIndex =
        (pageNum - 1) * (pageSize || DEFAULT_DATA_PAGE_SIZE);

      return (displayedRowIndex + initialRowIndex).toString();
    },
    [],
  );

  const calculateRowIndex = useCallback(
    (pageNum: number, rowIndex: number, pageSize?: number): number => {
      const displayedRowIndex = rowIndex;
      const initialRowIndex =
        (pageNum - 1) * (pageSize || DEFAULT_DATA_PAGE_SIZE);

      return displayedRowIndex - initialRowIndex;
    },
    [],
  );

  const updateSortBy = useCallback(
    (
      sortFieldMap: Record<string, string>,
      sortBy: SortByItem,
    ): Record<string, number> | undefined => {
      if (isEmpty(sortBy) || !sortFieldMap[sortBy.id]) {
        return undefined;
      }

      return { [sortFieldMap[sortBy.id]]: sortBy.desc ? -1 : 1 };
    },
    [],
  );

  return {
    calculateRowNumber,
    calculateRowIndex,
    updateSortBy,
  };
};

export default usePagination;
