import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NameDatasetStage from '../../components/DatasetStages/DatasetCreationStages/1-name/NameDatasetStage';
import SourceDatasetStage from '../../components/DatasetStages/DatasetCreationStages/2-source/SourceDatasetStage';
import ResultDatasetStage from '../../components/DatasetStages/DatasetCreationStages/3-result/ResultDatasetStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import { RouteName } from '../../enums/RouteName';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import { updateDatasetCollection } from '../../store/dataset-collection';
import { updateActiveDatasetSubStage } from '../../store/dataset-stage';
import { DatasetCreateSubStage } from '../../store/dataset-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getStageTitle,
  getSubStageContent,
} from '../../util/stage-content/StageHeaderContent';

const REPLACE_ACTION = 'replace';
const MERGE_ACTION = 'merge';

interface DatasetItemCreationProps {
  schema: Interfaces.Field[];
  setSchema: React.Dispatch<React.SetStateAction<Interfaces.Field[]>>;
  sampleData: SampleDataRow[];
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const DatasetItemCreation: FC<DatasetItemCreationProps> = ({
  schema,
  setSchema,
  sampleData,
  setSampleData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const [sourceFile, setSourceFile] = useState<File>();
  const [sourceSheet, setSourceSheet] = useState<string>();

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = Enums.DatasetStage.CREATION;
  const activeSubStage = datasetStageState.stages[activeStage].activeSubStage;

  const isReplacing = location.pathname.includes(REPLACE_ACTION);
  const isMerging = location.pathname.includes(MERGE_ACTION);

  // If merging/replacing - redirect to source stage
  useEffect(() => {
    if (
      datasetMeta?._id &&
      activeDataCollectionItem?._id &&
      (isMerging || isReplacing) &&
      [DatasetCreateSubStage.NAME, DatasetCreateSubStage.TYPE].includes(
        activeSubStage,
      )
    ) {
      const collection = datasetMeta.dataCollections.find(
        (c) =>
          c.status === Enums.CollectionStatus.IN_PROGRESS &&
          c.dataSource?.dataSourceActionType ===
            (isMerging
              ? Enums.DataSourceActionType.MERGE
              : Enums.DataSourceActionType.REPLACE),
      );

      // If an in-progress collection exists, use it and redirect
      if (collection) {
        dispatch(
          updateDatasetCollection({
            datasetMetaId: datasetMeta._id,
            collectionId: collection._id,
          }),
        );
        dispatch(updateActiveDatasetSubStage(DatasetCreateSubStage.TYPE));
      } else {
        // Otherwise redirect them to the overview screen
        history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
      }
    }
  }, [
    activeDataCollectionItem?._id,
    activeSubStage,
    datasetMeta?._id,
    datasetMeta?.dataCollections,
    dispatch,
    history,
    isMerging,
    isReplacing,
  ]);

  const stageTitle = getStageTitle(activeStage, isReplacing, isMerging);
  const subStageContent = getSubStageContent(
    activeStage,
    activeSubStage,
    datasetMeta,
    collection?.collectionId,
  );
  let deleteTitle;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case DatasetCreateSubStage.NAME:
        return <NameDatasetStage />;

      case DatasetCreateSubStage.TYPE:
        return (
          <SourceDatasetStage
            sourceFile={sourceFile}
            setSourceFile={setSourceFile}
            sourceSheet={sourceSheet}
            setSourceSheet={setSourceSheet}
            setSampleData={setSampleData}
            setSchema={setSchema}
          />
        );
      case DatasetCreateSubStage.RESULT:
        return (
          <ResultDatasetStage
            sampleData={sampleData}
            setSampleData={setSampleData}
            schema={schema}
            setSchema={setSchema}
          />
        );
      default:
        return <NameDatasetStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems:
            isReplacing || isMerging
              ? (datasetStageState.stages[activeStage].subStages
                  .slice(2)
                  .map((stage, i) => ({
                    ...stage,
                    stage: i + 1,
                  })) as SubStageItem[])
              : (datasetStageState.stages[activeStage]
                  .subStages as SubStageItem[]),
        }}
        image={subStageContent.image}
        title={deleteTitle || subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default DatasetItemCreation;
