import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums';
import useGraph from '../../hooks/graph/UseGraph';
import useProject from '../../hooks/project/UseProject';
import useQuery from '../../hooks/query/UseQuery';
import { DefaultPopupStyles } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';

interface AggregationMeasureProps {
  measureItems: Interfaces.MeasureField[] | [];
  isMultipleDatasetQuery: boolean;
  entityType: EntityType;
}

const AggregationMeasure: FC<AggregationMeasureProps> = ({
  measureItems,
  isMultipleDatasetQuery,
  entityType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { query } = useQuery();
  const { graph } = useGraph();
  const { projectAccessLevel } = useProject();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  return (
    <>
      {measureItems?.map((measure, index) => {
        return (
          <Popup
            key={`measure-item-${measure.datasetMetaId}-${measure.field}-${measure.measure}-${index}`}
            content={
              isMultipleDatasetQuery
                ? measure.alias
                : datasetMetas?.find(
                    (d) => d.entity._id === measure.datasetMetaId,
                  )?.entity.name
            }
            position="left center"
            style={DefaultPopupStyles}
            hideOnScroll={true}
            trigger={
              <div>
                <FeatureButton
                  isDisabled={!isProjectManager}
                  isActive={!isProjectManager}
                  action={() =>
                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.AGGREGATION_ADD_MEASURE,
                        additionalProps: {
                          existingMeasure: {
                            index,
                            measure,
                          },
                          entityType: entityType || EntityType.AGGREGATION,
                        },
                      }),
                    )
                  }
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.purple}
                  text={measure.alias}
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  faceStyle={{ width: '100%' }}
                />
              </div>
            }
          />
        );
      })}

      {isProjectManager && (
        <FeatureButton
          isDisabled={
            entityType === EntityType.GRAPH
              ? !graph?.queryParams?.groups?.length
              : !query?.queryParams.groups.length
          }
          action={() =>
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.AGGREGATION_ADD_MEASURE,
                additionalProps: {
                  entityType: entityType || EntityType.AGGREGATION,
                },
              }),
            )
          }
          size={FeatureButtonSize.WIDE_SMALL}
          color={themeContext.colors.general.blue}
          text={'Add Measure'}
          containerStyle={{ width: '100%' }}
          style={{ width: '100%' }}
          faceStyle={{
            width: '100%',
          }}
        />
      )}
    </>
  );
};

export default AggregationMeasure;
