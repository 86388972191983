import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledSubHeader,
} from '../../../main/theme';
import AdditionalValidationOptions from '../validation/OptionPanels/AdditionalValidationOptions';

interface FormattingOptionsProps {
  active: boolean;
  hideDisplayAsFormats?: boolean;
  activeToggle: () => void;
  schemaField: Interfaces.Field;
  onChange: (updatedSchemaField: Interfaces.Field) => void;
  superColumnValueType?: Enums.ValueDataType;
}

const ValidationOptions: FC<FormattingOptionsProps> = ({
  active,
  activeToggle,
  schemaField,
  onChange,
  superColumnValueType,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <StyledAccordionTitle
        active={active}
        onClick={activeToggle}
        style={{
          backgroundColor: themeContext.colors.system.offWhite,
        }}
      >
        <StyledSubHeader>Validation Options</StyledSubHeader>
        <FontAwesomeIcon
          icon={active ? faChevronUp : faChevronDown}
          color={defaultTheme.colors.system.offBlack}
        />
      </StyledAccordionTitle>
      <StyledAccordionContent active={active}>
        <AdditionalValidationOptions
          schemaField={schemaField}
          onChange={(updatedSchemaField) => {
            onChange(updatedSchemaField);
          }}
          superColumnValueType={superColumnValueType}
        />
      </StyledAccordionContent>
    </>
  );
};

export default ValidationOptions;
