import { Enums } from '@configur-tech/upit-core-types';
import { truncate } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useIntegrationConfig from '../../../hooks/integration-config/UseIntegrationConfig';
import useIntegrationTemplate from '../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledDropdownWide,
} from '../../../main/theme';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { RootState } from '../../../store/rootReducer';
import * as SC from './styled';

export const INTEGRATION_TEMPLATE_FIELD = 'integrationId';
export const INTEGRATION_BASE_ENDPOINT_FIELD = 'integrationEndpointId';
export const INTEGRATION_REQUEST_ENDPOINT_FIELD =
  'integrationRequestEndpointId';
export const INTEGRATION_CUSTOM_ACTION_FIELD = 'integrationCustomActionId';
export const INTEGRATION_CONFIG_FIELD = 'integrationConfigId';

const DESCRIPTION_TRUNCATE_LIMIT = 60;
const BASE_URL_FIELD = 'baseUrl';
const PATH_FIELD = 'path';
const TEMP_FETCH_LIMIT = 100;

enum DropdownType {
  INTEGRATION = 'integration',
  BASE_URL = 'base',
  REQUEST_URL = 'request',
}

// Build dropdown options
const buildDropdownOptions = (
  options: Record<string, unknown>[],
  type?: DropdownType,
): DropdownItemProps[] => {
  const typeVal =
    type === DropdownType.BASE_URL
      ? BASE_URL_FIELD
      : type === DropdownType.REQUEST_URL
      ? PATH_FIELD
      : '';
  return options.map((opt, index: number) => {
    return {
      key: `${type}-${opt._id}-${index}`,
      text: opt.name,
      description: type
        ? opt[typeVal]
        : truncate(opt.description, { length: DESCRIPTION_TRUNCATE_LIMIT }) ||
          '',
      value: opt._id,
      id: opt._id,
    } as DropdownItemProps;
  });
};

interface JobIntegrationSelectorProps {
  integrationType?: Enums.IntegrationType;
  integrationValue?: string;
  baseEndpointValue?: string;
  requestEndpointValue?: string;
  customActionValue?: string;
  onChange: (
    field: string,
    value: string,
    type?: Enums.IntegrationType,
  ) => void;
}

const JobIntegrationSelector: FC<JobIntegrationSelectorProps> = ({
  integrationType,
  integrationValue,
  baseEndpointValue,
  requestEndpointValue,
  customActionValue,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const { getIntegrationConfigs } = useIntegrationConfig();
  const { getIntegrationTemplates } = useIntegrationTemplate();

  const [loading, setLoading] = useState<boolean>(false);
  const [integrationOptions, setIntegrationOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [baseOptions, setBaseOptions] = useState<DropdownItemProps[]>([]);
  const [requestOptions, setRequestOptions] = useState<DropdownItemProps[]>([]);
  const [customActionOptions, setCustomActionOptions] = useState<
    DropdownItemProps[]
  >([]);

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplateEnts: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const integrationConfigState = useSelector(
    (state: RootState) => state.integrationConfigs,
  );
  const integrationConfigEnts = integrationConfigState.data.data;

  // Fetch active Integration Configs
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getIntegrationConfigs();
      setLoading(false);
    })();
  }, [getIntegrationConfigs]);

  // Fetch active Integration Templates
  useEffect(() => {
    (async () => {
      if (integrationConfigEnts?.length) {
        setLoading(true);
        await getIntegrationTemplates(
          {
            status: Enums.IntegrationStatus.ACTIVE,
            _id: {
              $in: integrationConfigEnts.map(
                (config) => config.entity.integrationTemplateId,
              ),
            },
          },
          undefined,
          undefined,
          // TODO - Remove limits once template active status is cleaned up
          TEMP_FETCH_LIMIT,
        );
        setLoading(false);
      }
    })();
  }, [getIntegrationTemplates, integrationConfigEnts]);

  // Build integration dropdown options
  useEffect(() => {
    if (integrationTemplateEnts?.length) {
      setIntegrationOptions(
        buildDropdownOptions(
          integrationTemplateEnts.map(
            (int) => int.entity as unknown as Record<string, unknown>,
          ),
          DropdownType.INTEGRATION,
        ),
      );
    }
  }, [integrationTemplateEnts]);

  // Build base endpoint and custom action dropdown options
  useEffect(() => {
    if (integrationTemplateEnts?.length && integrationValue) {
      const baseEndpoints =
        integrationTemplateEnts.find(
          (int) => int.entity._id === integrationValue,
        )?.entity.endpoints || [];
      setBaseOptions(
        buildDropdownOptions(
          baseEndpoints as unknown as Record<string, unknown>[],
          DropdownType.BASE_URL,
        ),
      );

      const integrationCustomActions =
        integrationTemplateEnts.find((i) => i.entity._id === integrationValue)
          ?.entity.customActions || [];
      setCustomActionOptions(
        buildDropdownOptions(
          integrationCustomActions as unknown as Record<string, unknown>[],
        ),
      );
    }
  }, [integrationTemplateEnts, integrationValue]);

  // Build request endpoint dropdown options
  useEffect(() => {
    if (
      integrationTemplateEnts?.length &&
      integrationValue &&
      baseEndpointValue
    ) {
      const baseEndpoints =
        integrationTemplateEnts.find(
          (int) => int.entity._id === integrationValue,
        )?.entity.endpoints || [];

      const requestEndpoints =
        baseEndpoints.find((base) => base._id === baseEndpointValue)
          ?.endpoints || [];

      setRequestOptions(
        buildDropdownOptions(
          requestEndpoints as unknown as Record<string, unknown>[],
          DropdownType.REQUEST_URL,
        ),
      );
    }
  }, [baseEndpointValue, integrationTemplateEnts, integrationValue]);

  return (
    <SC.InputWrapperCol>
      <SC.InputContainer>
        <StyledBodySubHeader style={{ marginTop: themeContext.margin.xlarge }}>
          Integration
        </StyledBodySubHeader>
        <StyledDropdownWide
          loading={loading}
          selectOnBlur={false}
          selection
          value={integrationValue || ''}
          options={integrationOptions}
          placeholder={'Select an integration'}
          upward={true}
          style={{ marginTop: 0 }}
          onChange={(e, { value }) =>
            onChange(
              INTEGRATION_TEMPLATE_FIELD,
              value,
              integrationTemplateEnts.find((i) => i.entity._id === value)
                ?.entity.integrationType,
            )
          }
        />
      </SC.InputContainer>
      {integrationType !== Enums.IntegrationType.CUSTOM && (
        <div>
          <SC.InputContainer>
            <StyledBodySubHeader
              style={{ marginTop: themeContext.margin.xlarge }}
            >
              Base Endpoint
            </StyledBodySubHeader>
            <StyledDropdownUltraWide
              selectOnBlur={false}
              selection
              disabled={!integrationValue}
              placeholder={'Select a base endpoint'}
              value={baseEndpointValue || ''}
              options={baseOptions}
              upward={true}
              style={{ marginTop: 0 }}
              onChange={(e, { value }) =>
                onChange(INTEGRATION_BASE_ENDPOINT_FIELD, value)
              }
            />
          </SC.InputContainer>
          <SC.InputContainer>
            <StyledBodySubHeader
              style={{ marginTop: themeContext.margin.xlarge }}
            >
              Request Endpoint
            </StyledBodySubHeader>
            <StyledDropdownUltraWide
              selectOnBlur={false}
              selection
              placeholder={'Select a request endpoint'}
              disabled={!baseEndpointValue}
              value={requestEndpointValue || ''}
              options={requestOptions}
              upward={true}
              style={{ marginTop: 0 }}
              onChange={(e, { value }) =>
                onChange(INTEGRATION_REQUEST_ENDPOINT_FIELD, value)
              }
            />
          </SC.InputContainer>
        </div>
      )}
      {integrationType === Enums.IntegrationType.CUSTOM && (
        <>
          <SC.InputContainer>
            <StyledBodySubHeader
              style={{ marginTop: themeContext.margin.xlarge }}
            >
              Custom Action
            </StyledBodySubHeader>
            <StyledDropdownWide
              selectOnBlur={false}
              selection
              disabled={!integrationValue}
              placeholder={'Select a custom action'}
              value={customActionValue || ''}
              options={customActionOptions}
              upward={true}
              style={{ marginTop: 0 }}
              onChange={(e, { value }) =>
                onChange(INTEGRATION_CUSTOM_ACTION_FIELD, value)
              }
            />
          </SC.InputContainer>
        </>
      )}
    </SC.InputWrapperCol>
  );
};

export default JobIntegrationSelector;
