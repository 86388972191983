import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createComment,
  deleteComment,
  fetchComment,
  updateComment,
} from '../../store/comment';
import {
  CommentItemOutput,
  fetchComments,
  fetchCommentsSuccess,
} from '../../store/comments';
import { DatasetState, fetchDatasetSuccess } from '../../store/dataset';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useCommentResult {
  comment?: Interfaces.CommentOutput;
  commentAccessLevel?: Enums.AccessLevel;

  getComments: (
    resourceType: Enums.SchemaName,
    resourceId: string,
    pageNum: number,
    childId?: string,
    portalId?: string,
    cmsId?: string,
    groupId?: string,
    projectId?: string,
  ) => Promise<void>;
  getComment: (commentId: string) => void;
  addComment: (commentObj: Interfaces.Comment, portalId?: string) => void;
  editComment: (
    commentObj: Interfaces.CommentOutput,
    portalId?: string,
  ) => void;
  removeComment: (commentObj: string, portalId?: string) => void;
}

const useComment = (): useCommentResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [comment, setComment] = useState<Interfaces.CommentOutput>();
  const [commentAccessLevel, setCommentAccessLevel] =
    useState<Enums.AccessLevel>();

  const commentsState = useSelector((state: RootState) => state.comments);
  const commentState = useSelector((state: RootState) => state.comment);
  const commentObj = commentState.data as CommentItemOutput;

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );

  const getComments = useCallback(
    async (
      resourceType: Enums.SchemaName,
      resourceId: string,
      pageNum: number,
      childId?: string,
      portalId?: string,
      cmsId?: string,
      groupId?: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        await dispatch(
          fetchComments(
            token,
            resourceType,
            resourceId,
            pageNum,
            childId,
            portalId,
            cmsId,
            groupId,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getComment = useCallback(
    async (commentId: string) => {
      const token = await getAccessTokenSilently();

      if (token && commentId && loggedInUser) {
        await dispatch(fetchComment(token, commentId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addComment = useCallback(
    async (commentObj: Interfaces.Comment, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && commentObj && loggedInUser) {
        await dispatch(
          createComment(token, commentObj, loggedInUser._id, portalId),
        );

        // Increment the column comment count
        if (
          commentObj.resourceType === Enums.SchemaName.DATASET_META &&
          commentObj.datasetRowId
        ) {
          const cloned = cloneDeep(datasetState);
          const datasetData = cloned?.data?.[commentObj?.resourceId];
          const commentedRow = datasetData.entries.find(
            (e) => e.row_id === commentObj.datasetRowId,
          );
          const currentCommentCount = commentedRow?.rowMeta?.commentCount;

          if (commentedRow) {
            commentedRow.rowMeta.commentCount = currentCommentCount + 1;
          }

          dispatch(
            fetchDatasetSuccess({
              ...cloned?.data?.[commentObj?.resourceId],
              datasetMetaId: commentObj.resourceId,
            }),
          );
        }
      }
    },
    [datasetState, dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editComment = useCallback(
    async (commentObj: Interfaces.CommentOutput, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && commentObj && loggedInUser) {
        await dispatch(
          updateComment(token, commentObj, loggedInUser._id, portalId),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeComment = useCallback(
    async (commentId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && commentId && loggedInUser) {
        await dispatch(
          deleteComment(token, commentId, loggedInUser._id, portalId),
        );
      }

      // Remove comment from current comments
      await dispatch(
        fetchCommentsSuccess({
          pagination: {
            ...commentsState.data.pagination,
            totalCount: commentsState.data.pagination.totalCount - 1,
          },
          data: commentsState.data?.data.filter(
            (d) => d.entity._id !== commentId,
          ),
        }),
      );
    },
    [
      commentsState.data?.data,
      commentsState.data.pagination,
      dispatch,
      getAccessTokenSilently,
      loggedInUser,
    ],
  );

  useEffect(() => {
    if (commentObj) {
      // Complete model
      setComment(commentObj.entity);
      setCommentAccessLevel(commentObj.accessLevel);
    }
  }, [commentObj]);

  return {
    comment,
    commentAccessLevel,

    getComments,
    getComment,
    addComment,
    editComment,
    removeComment,
  };
};

export default useComment;
