import {
  faBell,
  faClipboardCheck,
  faDownload,
  faListUl,
  faPlusSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums/RouteName';
import useUsage from '../../hooks/usage/useUsage';
import { defaultTheme } from '../../main/theme';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import InitialDatasetMeta from '../../store/dataset-meta/initial-dataset-meta';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import NavItem from '../Navigation/NavItem';
import NavTag, { NavTagType } from '../Navigation/NavTag';
import NavTitle from '../Navigation/NavTitle';

const NavIcons = {
  [RouteName.DATASETS]: faListUl,
  [RouteName.DATASET_ITEM]: faPlusSquare,
  [RouteName.LISTS]: faClipboardCheck,
  [RouteName.EXPORT]: faDownload,
  [RouteName.NOTIFICATIONS]: faBell,
};

const DatasetsNavigation: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { datapointQuota } = useUsage();

  return (
    <>
      <NavItem
        key={`dataset-view`}
        onClick={() => history.push(RouteName.DATASETS)}
        isActive={location.pathname === RouteName.DATASETS}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.DATASETS]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'View Datasets'} />
      </NavItem>

      <NavItem
        key={`dataset-create`}
        onClick={() => {
          // Reset datasetMeta and stage data
          dispatch(resetStagesAndSubStages());
          dispatch(fetchDatasetMetaSuccess(InitialDatasetMeta));
          history.push(RouteName.DATASET_ITEM);
        }}
        isActive={location.pathname === RouteName.DATASET_ITEM}
        disabled={datapointQuota?.isDatapointQuotaLimit}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.DATASET_ITEM]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'Create New Dataset'} />
      </NavItem>

      <NavItem
        key={`dataset-lists`}
        onClick={() => {
          // Reset list and stage data
          dispatch(resetStagesAndSubStages());
          dispatch(fetchDatasetMetaSuccess(InitialDatasetMeta));
          history.push(`${RouteName.DATASETS}${RouteName.LISTS}`);
        }}
        isActive={
          location.pathname === `${RouteName.DATASETS}${RouteName.LISTS}`
        }
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.LISTS]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'Validation Lists'} />
      </NavItem>

      <NavItem
        key={`dataset-export`}
        onClick={() => {
          history.push(`${RouteName.DATASETS}${RouteName.EXPORT}`);
        }}
        isActive={
          location.pathname === `${RouteName.DATASETS}${RouteName.EXPORT}`
        }
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.EXPORT]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'Export Data'} />
      </NavItem>
    </>
  );
};

export default DatasetsNavigation;
