import { faInfinity, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import {
  DefaultPopupStyles,
  StyledBodySubHeader,
  StyledH3,
  StyledText,
} from '../../main/theme';
import { MetricNumberFormatter } from '../../util/metric-formatter/MetricNumberFormatter';
import * as SC from './styled';

interface UsageProgressCardProps {
  title: string;
  description?: string;
  icon: IconDefinition;
  usage: number;
  quota: number;
  iconColor?: string;
}

const UsageProgressCard: FC<UsageProgressCardProps> = ({
  title,
  description,
  icon,
  usage,
  quota,
  iconColor,
}) => {
  const themeContext = useContext(ThemeContext);
  const percentage = (Number(usage) / Number(quota)) * 100;
  const formattedQuota = MetricNumberFormatter(quota, 1);
  const formattedUsage = MetricNumberFormatter(usage, 1);
  const formattedPopoverQuota = quota.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    { minimumFractionDigits: 0 },
  );

  const formattedPopoverUsage = usage.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    { minimumFractionDigits: 0 },
  );

  return (
    <>
      {quota !== 0 && (
        <SC.CardContainer>
          <SC.CardHeader>
            <FontAwesomeIcon size={'2x'} color={iconColor} icon={icon} />
            <StyledH3 style={{ marginLeft: themeContext.margin.standard }}>
              {title}
            </StyledH3>
          </SC.CardHeader>
          <StyledText style={{ marginBottom: themeContext.margin.xxlarge }}>
            {description}
          </StyledText>
          <div>
            {quota !== -1 ? (
              <SC.StyledProgress
                progress={'percent'}
                precision={0}
                indicating={true}
                value={usage}
                total={quota}
                label={`${
                  usage >= quota && quota != -1
                    ? formattedQuota
                    : formattedUsage
                } / ${formattedQuota}`}
                completion={percentage}
              ></SC.StyledProgress>
            ) : (
              <Popup
                content={`${formattedPopoverUsage}/${
                  quota === -1 ? '∞' : formattedPopoverQuota
                }`}
                position="top center"
                style={DefaultPopupStyles}
                trigger={
                  <StyledBodySubHeader>
                    <SC.LargeMetric>{formattedUsage}</SC.LargeMetric>
                    <SC.SmallMetric>
                      /
                      <FontAwesomeIcon icon={faInfinity} />
                    </SC.SmallMetric>
                  </StyledBodySubHeader>
                }
              />
            )}
          </div>
        </SC.CardContainer>
      )}
    </>
  );
};

export default UsageProgressCard;
