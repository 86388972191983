import { Enums } from '@configur-tech/upit-core-types';
import {
  faSortAmountDown,
  faSortAmountUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { AggregateStage } from '../ProjectItem/ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import { GroupItem } from './AggregationGroup';

interface AggregationOptionButtonProps {
  type: AggregateStage;
  item: GroupItem;
  index: number;
  onCheckedChange: (itemAlias: string, checked: boolean) => void;
  onDirChange: (
    itemAlias: string,
    direction: Enums.ProjectionSortOrder,
  ) => void;
  disabled?: boolean;
  hideDirectionButton?: boolean;
}

const AggregationOptionButton: FC<AggregationOptionButtonProps> = ({
  type,
  item,
  index,
  onCheckedChange,
  onDirChange,
  disabled,
  hideDirectionButton,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <Checkbox
        disabled={disabled}
        key={`${type}-checkbox-${item.field}-${index}`}
        label={item.alias || item.field}
        checked={item.active}
        onChange={(e, data) =>
          onCheckedChange(item.alias || item.field, data.checked || false)
        }
      />

      {!hideDirectionButton && (
        <FontAwesomeIcon
          onClick={() =>
            !disabled && item.active
              ? onDirChange(
                  item.alias || item.field,
                  item.direction === Enums.ProjectionSortOrder.ASCENDING
                    ? Enums.ProjectionSortOrder.DESCENDING
                    : Enums.ProjectionSortOrder.ASCENDING,
                )
              : null
          }
          icon={
            item.direction === Enums.ProjectionSortOrder.ASCENDING
              ? faSortAmountUp
              : faSortAmountDown
          }
          color={
            item.active
              ? themeContext.colors.system.white
              : themeContext.colors.system.darkGrey
          }
        />
      )}
    </>
  );
};

export default AggregationOptionButton;
