import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import createNameIcon from '../../assets/icons/stages/create-dataset/name.svg';
import createAvatarIcon from '../../assets/icons/stages/create-team/avatar.svg';
import createMembersIcon from '../../assets/icons/stages/create-team/members.svg';
import {
  TeamConfigureSubStage,
  TeamCreationSubStage,
  TeamStage,
  TeamSubStages,
} from '../../store/team-stage/initial-state';
import {
  StageMeta,
  SubStageContent,
  SubStageMeta,
} from '../stage-content/StageHeaderContent';

const teamStageMeta: StageMeta = {
  [TeamStage.CREATION]: {
    title: 'Create Team',
  },
  [TeamStage.CONFIGURATION]: {
    title: 'Configuration',
  },
};

export const getTeamStageTitle = (stage: TeamStage): string => {
  return teamStageMeta[stage].title;
};

export const getTeamSubStageContent = (
  stage: TeamStage,
  subStage: TeamSubStages,
): SubStageContent => {
  const subStageContent: SubStageMeta = {
    [TeamStage.CREATION]: {
      subStages: {
        [TeamCreationSubStage.NAME]: {
          stage: 1,
          image: createNameIcon,
          title: `Name That Dream Team`,
        },
        [TeamCreationSubStage.AVATAR]: {
          stage: 2,
          image: createAvatarIcon,
          title: `Pick The Perfect Picture`,
        },
        [TeamCreationSubStage.USERS]: {
          stage: 3,
          image: createMembersIcon,
          title: `Select Your Squad`,
        },
      },
    },
    [TeamStage.CONFIGURATION]: {
      subStages: {
        [TeamConfigureSubStage.ADVANCED]: {
          stage: 1,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
