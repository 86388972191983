import { Enums } from '@configur-tech/upit-core-types';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { V2_URL } from '../../const/SiteUrl';
import { EntityType, RouteName } from '../../enums';
import useNotification from '../../hooks/notification/UseNotification';
import { NotificationItemsOutput } from '../../services/notification/NotificationService';
import { hideLoading, showLoading } from '../../store/loading';
import { RootState } from '../../store/rootReducer';

const NotificationRedirectPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getNotification } = useNotification();
  const { notificationId } = useParams();

  const notificationEventState = useSelector(
    (state: RootState) => state.notificationEvent,
  );
  const notificationEvent = notificationEventState.data;

  // Load notification event from notificationId
  useEffect(() => {
    dispatch(showLoading({ text: 'Loading Notification Dataset...' }));
    if (notificationId) {
      getNotification(notificationId);
    }
  }, [dispatch, getNotification, notificationId]);

  useEffect(() => {
    // TODO Update to use just notificationEvent - notification event response should not be returned as an array
    try {
      const eventData = (
        notificationEvent as unknown as NotificationItemsOutput
      )?.data;

      const eventEntity = eventData && eventData[0] && eventData[0]?.entity;

      if (eventEntity?._id === notificationId) {
        if (
          !eventEntity?.notificationAlertParams.conditions?.queryConditions ||
          !eventEntity?.notificationAlertParams.resource?.resourceId
        ) {
          return;
        }

        const filters =
          eventEntity.notificationAlertParams.conditions.queryConditions;

        const formattedNotificationFilter = [
          {
            operator: Enums.AggregationFilterOperator.AND,
            value: [{ ...filters, active: true, value: filters.value }],
            active: true,
          },
        ];

        // Redirecting to V2 but need to set up access to filters etc.
        window.location.href = `${V2_URL}datasets/${eventEntity?.notificationAlertParams.resource?.resourceId}/view`;

        // history.push({
        //   pathname: `${RouteName.DATASET_ITEM}/${eventEntity?.notificationAlertParams.resource?.resourceId}/view`,
        //   search: '?page=1&pageSize=50',
        //   state: {
        //     filters: JSON.stringify(formattedNotificationFilter),
        //     filterType: EntityType.NOTIFICATION,
        //   },
        // });
        // dispatch(hideLoading());
      }
    } catch {
      history.push({
        pathname: RouteName.WELCOME,
      });
      dispatch(hideLoading());
    }
  }, [dispatch, history, notificationEvent, notificationId]);

  useEffect(() => {
    if (notificationEventState.error) {
      history.push({
        pathname: RouteName.WELCOME,
      });
      dispatch(hideLoading());
    }
  }, [dispatch, history, notificationEventState.error]);

  return null;
};

export default NotificationRedirectPage;
