import styled from 'styled-components';
import { AlertStatus } from '../../enums/AlertStatus';

export const BannerContainer = styled.div<{ alertStatus: AlertStatus }>`
  display: flex;
  background: ${({ alertStatus, theme }) =>
    alertStatus === AlertStatus.ERROR
      ? theme.colors.general.fadedRed
      : alertStatus === AlertStatus.WARNING
      ? theme.colors.general.fadedYellow
      : alertStatus === AlertStatus.SUCCESS
      ? theme.colors.general.fadedGreen
      : alertStatus === AlertStatus.INFO
      ? theme.colors.general.fadedBlue
      : theme.colors.system.white};
  padding: ${({ theme }) => `${theme.padding.small} ${theme.padding.xlarge}`};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid
    ${({ alertStatus, theme }) =>
      alertStatus === AlertStatus.ERROR
        ? theme.colors.general.red
        : alertStatus === AlertStatus.WARNING
        ? theme.colors.general.darkYellow
        : alertStatus === AlertStatus.SUCCESS
        ? theme.colors.general.green
        : alertStatus === AlertStatus.INFO
        ? theme.colors.general.blue
        : theme.colors.system.white};
  color: ${({ alertStatus, theme }) =>
    alertStatus === AlertStatus.ERROR
      ? theme.colors.general.red
      : alertStatus === AlertStatus.WARNING
      ? theme.colors.general.darkYellow
      : alertStatus === AlertStatus.SUCCESS
      ? theme.colors.general.green
      : alertStatus === AlertStatus.INFO
      ? theme.colors.general.blue
      : theme.colors.system.white};
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
`;

export const BannerText = styled.p`
  margin-left: ${({ theme }) => theme.margin.large};
`;
