import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ThemeContext } from 'styled-components';
import useForm from '../../../../hooks/form/UseForm';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { fetchFormSuccess } from '../../../../store/project-form';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectConnectionFormSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../styled';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectConnectionFormSubStage.DATASET;

const ProjectItemConnectionFormNameStage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { form, getForm } = useForm();

  const [formName, setFormName] = useState<string>();
  const [formDescription, setFormDescription] = useState<string>();

  // Set initial Form values
  useEffect(() => {
    setFormName(form?.name);
    setFormDescription(form?.description);
  }, [form]);

  useEffect(() => {
    if (params.formId) {
      (async () => {
        getForm(params.formId);
      })();
    }
  }, [getForm, params.formId]);

  const updateFormDetails = () => {
    const cloned = cloneDeep(form);
    cloned[NAME_FIELD] = formName;
    cloned[DESCRIPTION_FIELD] = formDescription;
    dispatch(fetchFormSuccess(cloned));
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StyledBodySubHeader>Form Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new form.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your form name'}
            value={formName}
            onChange={(event, data) => setFormName(data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this form?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={formDescription}
            onChange={(event) => setFormDescription(event.target.value)}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!formName?.length}
            action={() => {
              updateFormDetails();
              dispatch(updateActiveProjectSubStage(NEXT_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Select'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemConnectionFormNameStage;
