import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemChartNameStage from '../../components/ProjectItem/ProjectItemChartStage/1-name/ProjectItemChartNameStage';
import ProjectitemChartBuilderStage from '../../components/ProjectItem/ProjectItemChartStage/2-builder/ProjectitemChartBuilderStage';
import ProjectItemChartConfigurationStage from '../../components/ProjectItem/ProjectItemChartStage/3-configuration/ProjectItemChartConfigurationStage';
import ProjectItemChartStage from '../../components/ProjectItem/ProjectItemChartStage/ProjectItemChartStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import { ProjectStage } from '../../enums/ProjectStage';
import Page from '../../main/Page';
import { ProjectChartSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectCharts: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = ProjectStage.CHARTS;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);

  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);
  const isChartBuilder = activeSubStage === ProjectChartSubStage.CHART;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectChartSubStage.NAME:
        return <ProjectItemChartNameStage />;
      case ProjectChartSubStage.CHART:
        return <ProjectitemChartBuilderStage />;
      case ProjectChartSubStage.CONFIGURATION:
        return <ProjectItemChartConfigurationStage />;
      default:
        return <ProjectItemChartNameStage />;
    }
  };

  const stageContentTitle = stageContent.title;

  return (
    <>
      {!isChartBuilder && (
        <PageFeatureHeader
          pageHeader={stageTitle}
          subStages={
            activeSubStage &&
            activeSubStage !== ProjectChartSubStage.CONFIGURATION
              ? {
                  activeSubStage: activeSubStage,
                  subStageItems: projectStageState.stages[
                    activeStage
                  ].subStages.filter(
                    (stage) =>
                      stage.subStage !== ProjectChartSubStage.CONFIGURATION,
                  ) as SubStageItem[],
                }
              : undefined
          }
          image={stageContent.image}
          title={stageContentTitle}
        />
      )}

      <Page style={isChartBuilder ? { overflow: 'hidden' } : undefined}>
        {activeSubStage ? getSubStageComponent() : <ProjectItemChartStage />}
      </Page>
    </>
  );
};

export default ProjectCharts;
