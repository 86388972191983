import { Interfaces } from '@configur-tech/upit-core-types';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import { StageWrapper } from '../../../../main/theme';
import { showModal } from '../../../../store/modal';
import DataSample from '../../../DataSample/DataSample';
import { ModalTypes } from '../../../Modal/Modal';

export interface EndpointTableProps {
  endpoint: Interfaces.IntegrationEndpoint;
  endpointIndex: number;
}

const ROW_NUMBER = 'row_id';
const NAME_FIELD = 'Request Name';
const HTTP_FIELD = 'HTTP Method';
const PATH_FIELD = 'Path';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: HTTP_FIELD },
  { name: PATH_FIELD },
];

const EndpointTable: FC<EndpointTableProps> = ({ endpoint, endpointIndex }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const calculateRowNumber = useCallback((rowIndex) => {
    const displayedRowIndex = rowIndex + 1;

    return displayedRowIndex.toString();
  }, []);

  useEffect(() => {
    if (endpoint) {
      const tableData: SampleDataRow[] = [];

      endpoint.endpoints.map((agg, requestIndex) => {
        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(requestIndex) },
          [NAME_FIELD]: { value: agg.name },
          [HTTP_FIELD]: { value: agg.httpMethod.toUpperCase() || '' },
          [PATH_FIELD]: { value: agg.path || '' },
        });
      });

      setTableData(tableData);
    }
  }, [calculateRowNumber, endpoint]);
  return (
    <StageWrapper>
      <DataSample
        sampleColumns={TABLE_SCHEMA}
        sampleRows={tableData}
        showPagination={false}
        clickableRows={{
          valueField: ROW_NUMBER,
          action: (requestIndex: string) => {
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.INTEGRATION_ENDPOINT_REQUEST_MODAL,
                fullScreen: true,
                additionalProps: {
                  endpointIndex,
                  requestIndex: parseInt(requestIndex) - 1,
                },
              }),
            );
          },
        }}
      />
    </StageWrapper>
  );
};

export default EndpointTable;
