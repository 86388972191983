import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialPipelineStage, {
  PipelineConfigurationSubStage,
  PipelineCreationSubStage,
  PipelineStage,
  PipelineSubStages,
} from './initial-state';

const PipelineStageSlice = createSlice({
  name: 'pipelineStage',
  initialState: InitialPipelineStage,
  reducers: {
    updateActivePipelineStage(state, action: PayloadAction<PipelineStage>) {
      state.activeStage = action.payload;
    },
    updateActivePipelineSubStage(
      state,
      action: PayloadAction<PipelineSubStages>,
    ) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },
    resetStagesAndSubStages(state) {
      state.activeStage = PipelineStage.CREATION;
      state.stages[PipelineStage.CREATION].activeSubStage =
        PipelineCreationSubStage.META;
      state.stages[PipelineStage.CONFIGURATION].activeSubStage =
        PipelineConfigurationSubStage.ADVANCED;
    },
  },
});

export const {
  updateActivePipelineStage,
  updateActivePipelineSubStage,
  resetStagesAndSubStages,
} = PipelineStageSlice.actions;

export default PipelineStageSlice.reducer;
