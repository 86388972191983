import React, { FC, useContext } from 'react';
import { useDragLayer } from 'react-dnd';
import { ThemeContext } from 'styled-components';

const DragPreview: FC = () => {
  const themeContext = useContext(ThemeContext);

  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const { x, y } = currentOffset || {};

  return isDragging ? (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        transform: `translate(${x}px, ${y}px) rotate(5deg)`,
        borderRadius: themeContext.borders.radius,
        border: `1px solid ${themeContext.colors.system.grey}`,
      }}
    >
      {item.header()}
    </div>
  ) : null;
};

export default DragPreview;
