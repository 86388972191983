import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FormulaOperandType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { DefaultPopupStyles } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../store/rootReducer';
import { getLookupFieldType } from '../../util/lookup/GetLookupFieldType';
import { getMissingLookupFieldDatasetIds } from '../../util/lookup/GetMissingLookupFieldDatasetIds';
import getFormulaDependencies from '../../util/validate-formula/getFormulaDependencies';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

interface FieldInputsProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  addFormulaItem: (item: Interfaces.FormulaOperandValue) => void;
  columnStatus: boolean;
}

const FieldInputs: FC<FieldInputsProps> = ({
  field,
  schema,
  addFormulaItem,
  columnStatus,
}) => {
  const themeContext = useContext(ThemeContext);

  const { getDatasetMetas } = useDatasetMeta();
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const QUERY_ROUTE = 'query';
  const isQuery =
    location.pathname.includes(RouteName.PROJECT_ITEM) ||
    location.pathname.includes(QUERY_ROUTE);

  // Fetch any missing lookup datasetMetas
  useEffect(() => {
    (async () => {
      if (!loading && !loaded) {
        setLoading(true);

        // Fetch any missing lookup datasets
        const lookupIds = getMissingLookupFieldDatasetIds(schema, datasetMetas);
        if (lookupIds.length) {
          await getDatasetMetas({ _id: { $in: lookupIds } });
        }

        setLoading(false);
        setLoaded(true);
      }
    })();
  });

  return (
    <>
      {loaded &&
        schema
          .filter(
            (f) =>
              (field.fieldId !== f.fieldId || field.name !== f.name) &&
              f.dataValidation?.dataValidationType &&
              [
                Enums.ValueDataType.NUMBER,
                Enums.ValueDataType.FORMULA,
              ].includes(
                getLookupFieldType(f, datasetMetas) ||
                  f.dataValidation?.dataValidationType,
              ),
          )
          .map((f, i) => {
            const deps = getFormulaDependencies(
              f.dataValidation?.formula || [],
            );
            const hasCircularDeps = deps.includes(field.fieldId || field.name);

            return (
              <Popup
                disabled={!hasCircularDeps}
                position="top center"
                style={DefaultPopupStyles}
                content={'Column already referenced in formula'}
                trigger={
                  <div>
                    <FeatureButton
                      key={`${f.name}-${i}-${Math.random() * 1000}`}
                      isDisabled={hasCircularDeps || !columnStatus}
                      action={() =>
                        addFormulaItem({
                          type: FormulaOperandType.FIELD,
                          value: isQuery
                            ? { field: f.name || (f.fieldId as string) }
                            : { field: f.fieldId || f.name },
                        })
                      }
                      size={FeatureButtonSize.WIDE}
                      color={themeContext.colors.general.blue}
                      text={f.name}
                    />
                  </div>
                }
              />
            );
          })}
    </>
  );
};

export default FieldInputs;
