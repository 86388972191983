import styled from 'styled-components';
import { StyledText } from '../../main/theme';

const GRAPH_BOTTOM_MARGIN = '70px';
const TOOLTIP_INDICATOR_SIZE = '16px';

export const GraphWrapper = styled.div<{ readOnly?: boolean }>`
  ${({ readOnly }) =>
    readOnly !== undefined ? 'max-height: 85%' : 'height: 100%'};
`;

export const GraphHeading = styled(StyledText)<{ isEditing?: boolean }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h3};
  text-align: center;
  width: 100%;
  padding: 0 ${({ theme }) => theme.margin.xlarge};
  padding-top: ${({ theme, isEditing }) =>
    isEditing ? theme.margin.standard : 0};
  margin-left: ${({ theme, isEditing }) =>
    isEditing ? 0 : theme.margin.xlarge};
`;

export const GraphInfo = styled(StyledText)`
  text-align: center;
  width: 100%;
  padding-top: ${({ theme }) => theme.padding.xlarge};
`;

export const GraphWarning = styled(StyledText)`
  text-align: center;
  padding-top: ${({ theme }) => theme.padding.xlarge};
  background-color: ${({ theme }) => theme.colors.general.yellow};
  padding: ${({ theme }) => theme.padding.large};
  margin: ${({ theme }) => theme.margin.xlarge};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const LoadingContainer = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const ExpandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.margin.standard};
  padding-top: ${({ theme }) => theme.padding.small};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 350px;
  img {
    width: 15%;
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
  p {
    color: ${({ theme }) => theme.colors.system.darkGrey};
  }
`;

export const RefreshText = styled.span<{ isDisabled: boolean }>`
  color: ${({ theme }) => theme.colors.general.blue};
  text-decoration: underline;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const GraphContainer = styled.div`
  height: 60vh;
  max-height: 40vw;
  padding: ${({ theme }) =>
    ` ${theme.padding.xlarge} ${theme.padding.xlarge} ${GRAPH_BOTTOM_MARGIN}`};
  margin: ${({ theme }) => theme.margin.xlarge};
  border: ${({ theme }) => `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const GoogleMapContainer = styled.div`
  height: 400px;
  width: 100%;

  #title {
    ${({ theme }) => theme.typography.header};
    font-size: ${({ theme }) => theme.typography.sizes.h4};
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.general.sea};
    text-align: left;
    width: 100%;
  }

  #list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
`;

export const GoogleMapError = styled(StyledText)`
  text-align: center;
  margin: 0;
  height: 400px;
  width: 100%;
`;

export const GanttError = styled(StyledText)`
  text-align: center;
  margin: 0;
  height: 400px;
  width: 100%;
`;

export const GanttDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const GanttContainer = styled.div<{ width?: string; height?: string }>`
  display: block;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  padding-top: ${({ theme }) => theme.padding.small};
`;

export const TaskTooltip = styled.div`
  background: ${({ theme }) => theme.colors.system.white};
  padding: ${({ theme }) => theme.padding.standard};
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.system.midGrey};
  border-radius: ${({ theme }) => theme.borders.radius};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.standard};

  p {
    margin: 0;
  }
`;

export const TaskTooltipDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.small};
  color: ${({ theme }) => theme.colors.system.darkGrey};
  font-size: ${({ theme }) => theme.typography.sizes.p};
`;

export const Status = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before {
    content: '';
    background-color: ${({ color, theme }) =>
      color ? color : theme.colors.system.grey};
    width: ${TOOLTIP_INDICATOR_SIZE};
    height: ${TOOLTIP_INDICATOR_SIZE};
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;
