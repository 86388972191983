import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialAggregation from './initial-aggregation';

type Aggregation = Interfaces.Aggregation | Interfaces.AggregationOutput;

export interface AggregationState {
  data: Aggregation;
  loading: boolean;
  error: string | null;
}

const initialState: AggregationState = {
  data: InitialAggregation,
  loading: false,
  error: null,
};

const AggregationSlice = createSlice({
  name: 'aggregation',
  initialState,
  reducers: {
    // Set
    setAggregation(state, action: PayloadAction<Aggregation>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },

    // Reset
    resetAggregation(state) {
      state.data = InitialAggregation;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { setAggregation, resetAggregation } = AggregationSlice.actions;

export default AggregationSlice.reducer;
