import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { PAGE_SIZE } from '../../const/PaginationConst';
import { defaultTheme, StyledText } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

interface DataSamplePaginationProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (pageIndex: number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
  pageSize: number;
  previousPage: () => void;
  setPageSize?: (pageSize: number) => void;
  totalRows: number;
  showPageSize?: boolean;
}

const FILTER_RANGE = [50, 100, 150, 200];

const DataSamplePagination: FC<DataSamplePaginationProps> = ({
  totalRows,
  setPageSize,
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  nextPage,
  showPageSize,
}) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const handleUpdatePageSize = (pageSize: number) => {
    setPageSize && setPageSize(pageSize);

    const params = new URLSearchParams(history.location.search);
    params.set(PAGE_SIZE, pageSize.toString());
    history.push({ search: params.toString() });
  };

  return (
    <SC.PaginationWrapper>
      <SC.PaginationInputWrapper>
        <SC.PaginationNavigation>
          {totalRows > 0 && (
            <>
              <StyledText>Page</StyledText>
              <SC.SkipPageDropdown
                options={[...Array(pageOptions.length)].map((num, i) => {
                  return {
                    key: `page-${i + 1}-${i}`,
                    value: i,
                    text: i + 1,
                  };
                })}
                selection
                value={pageIndex}
                onChange={(e, data) => gotoPage(data.value)}
              />
            </>
          )}
        </SC.PaginationNavigation>
        <SC.PaginationNavigation>
          {totalRows > 0 && showPageSize && (
            <>
              <StyledText>Items</StyledText>
              <SC.SkipPageDropdown
                options={FILTER_RANGE.map((range) => {
                  return {
                    key: range,
                    text: range,
                    value: range,
                  };
                })}
                selection
                value={pageSize || FILTER_RANGE[0]}
                onChange={(e, data) => handleUpdatePageSize(data.value)}
              />
            </>
          )}
        </SC.PaginationNavigation>
      </SC.PaginationInputWrapper>

      <StyledText>
        {totalRows > 0 && (
          <>
            Page <strong>{pageIndex + 1}</strong> of{' '}
            <strong>{pageOptions.length}</strong>
          </>
        )}
        {totalRows === 0 && (
          <>
            Page <strong>{pageIndex + 1}</strong>
          </>
        )}
      </StyledText>
      <SC.PaginationButtons>
        {totalRows > 0 && (
          <FeatureButton
            isDisabled={!canPreviousPage}
            action={() => gotoPage(0)}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.sea}
            icon={
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                color={defaultTheme.colors.system.white}
                size={'lg'}
              />
            }
          />
        )}

        <FeatureButton
          isDisabled={!canPreviousPage}
          action={() => previousPage()}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faAngleLeft}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
        <FeatureButton
          isDisabled={!canNextPage}
          action={() => nextPage()}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faAngleRight}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
        {totalRows > 0 && (
          <FeatureButton
            isDisabled={!canNextPage}
            action={() => gotoPage(pageCount - 1)}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.sea}
            icon={
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                color={defaultTheme.colors.system.white}
                size={'lg'}
              />
            }
          />
        )}
      </SC.PaginationButtons>
    </SC.PaginationWrapper>
  );
};

export default DataSamplePagination;
