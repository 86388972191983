import { Interfaces } from '@configur-tech/upit-core-types';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { DefaultPopupStyles, defaultTheme, StyledText } from '../../main/theme';
import { showModal } from '../../store/modal';
import { ModalTypes } from '../Modal/Modal';

interface DataSampleQueryColumnHeaderCellProps {
  fieldName: string;
  allColumns: Interfaces.Field[];
}

const DataTypeHeaderCell = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => theme.padding.standard};

  text-align: left;

  outline: none;
  border: none;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.3;
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  margin-right: ${({ theme }) => theme.margin.standard};
  margin-bottom: 0;
`;

const DataSampleQueryColumnHeaderCell: FC<
  DataSampleQueryColumnHeaderCellProps
> = ({ fieldName, allColumns }) => {
  const dispatch = useDispatch();

  return (
    <DataTypeHeaderCell
      onClick={() => {
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.AGGREGATION_COLUMN_ALIAS,
            additionalProps: {
              fieldName,
              allColumns,
            },
          }),
        );
      }}
    >
      <Popup
        disabled={fieldName.length < 20}
        content={fieldName}
        position="top center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <Header>{truncate(fieldName, { length: 20 })}</Header>
            <FontAwesomeIcon
              icon={faPencilAlt}
              color={defaultTheme.colors.system.offBlack}
            />
          </div>
        }
      />
    </DataTypeHeaderCell>
  );
};

export default DataSampleQueryColumnHeaderCell;
