export enum PipelineStage {
  CREATION = 'creation',
  CONFIGURATION = 'configuration',
}

export enum PipelineCreationSubStage {
  META = 'meta',
  TRIGGER = 'trigger',
  JOBS = 'jobs',
  EVENTS = 'events',
}

export enum PipelineConfigurationSubStage {
  ADVANCED = 'advanced',
}

interface PipelineSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type PipelineSubStages =
  | PipelineCreationSubStage
  | PipelineConfigurationSubStage;

export interface PipelineStages {
  activeStage: PipelineStage;
  stages: {
    [PipelineStage.CREATION]: {
      activeSubStage: PipelineCreationSubStage;
      subStages: PipelineSubStage[];
    };
    [PipelineStage.CONFIGURATION]: {
      activeSubStage: PipelineConfigurationSubStage;
      subStages: PipelineSubStage[];
    };
  };
}

const InitialPipelineStage: PipelineStages = {
  activeStage: PipelineStage.CREATION,
  stages: {
    [PipelineStage.CREATION]: {
      activeSubStage: PipelineCreationSubStage.META,
      subStages: [
        {
          subStage: PipelineCreationSubStage.META,
          stage: 1,
          title: 'Meta',
        },
        {
          subStage: PipelineCreationSubStage.TRIGGER,
          stage: 2,
          title: 'Trigger',
        },
        {
          subStage: PipelineCreationSubStage.JOBS,
          stage: 3,
          title: 'Jobs',
        },
        {
          subStage: PipelineCreationSubStage.EVENTS,
          stage: 4,
          title: 'Events',
        },
      ],
    },
    [PipelineStage.CONFIGURATION]: {
      activeSubStage: PipelineConfigurationSubStage.ADVANCED,
      subStages: [
        {
          subStage: PipelineConfigurationSubStage.ADVANCED,
          stage: 1,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialPipelineStage;
