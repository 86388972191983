import { AdditionalDatasetStages } from '../../store/dataset-stage/initial-state';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import EventsDatasetStage from '../../components/DatasetItemEventsStages/DatasetItemEventsView';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { RootState } from '../../store/rootReducer';
import Page from '../../main/Page';
import {
  getStageTitle,
  getSubStageContent,
} from '../../util/stage-content/StageHeaderContent';

const DatasetItemEvents: FC = () => {
  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = AdditionalDatasetStages.EVENTS;

  const activeSubStage = datasetStageState.stages[activeStage].activeSubStage;

  const stageTitle = getStageTitle(activeStage);
  const subStageContent = getSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      default:
        return <EventsDatasetStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: datasetStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default DatasetItemEvents;
