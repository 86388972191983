import { DynamicConditionalField } from '../../hooks/filter/UseFilter';

function cleanGraphFilters(
  filters: DynamicConditionalField[],
  datasetMetaIds: string[],
) {
  return filters.map((filter) => {
    if (Array.isArray(filter.value)) {
      const filteredValue = filter.value
        .map((v) => {
          if (Array.isArray(v.value)) {
            const nestedValue = v.value.filter((nv) => {
              return datasetMetaIds?.includes(nv.datasetMetaId);
            });
            return {
              ...v,
              value: nestedValue,
            };
          }
          return v;
        })
        .filter((v) => Array.isArray(v.value) && v.value.length > 0); // Remove empty child arrays
      return {
        ...filter,
        value: filteredValue,
      };
    }
    return filter;
  });
}

export default cleanGraphFilters;
