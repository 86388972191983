import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  EntityType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../../../enums';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import useUsage from '../../../../hooks/usage/useUsage';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { updateDatasetCollection } from '../../../../store/dataset-collection';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../../store/dataset-stage';
import { DatasetCreateSubStage } from '../../../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import ActionBar from '../../../ActionBar/ActionBar';
import AdvancedSetting from '../../../AdvancedSetting/AdvancedSetting';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';

const ConfigureAdvancedStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const {
    datasetMeta,
    addDatasetMetaCollection,
    activeDataCollectionItem,
    editDatasetMeta,
  } = useDatasetMeta();

  const { unlockCollection, lockCollection } = useDatasetCollection();

  const [transformingCollectionType, setTransformingCollectionType] =
    useState<Enums.DataSourceActionType>();

  const { datapointQuota, checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const [datasetMetaUsage, setDatasetMetaUsage] = useState<number>(0);

  // Dispatch collection if new dataCollection added
  useEffect(() => {
    if (transformingCollectionType && datasetMeta?.dataCollections.length) {
      const collection = datasetMeta.dataCollections.find(
        (c) =>
          c.status === Enums.CollectionStatus.IN_PROGRESS &&
          c.dataSource?.dataSourceActionType === transformingCollectionType,
      );

      if (collection) {
        dispatch(
          updateDatasetCollection({
            datasetMetaId: datasetMeta._id,
            collectionId: collection?._id,
          }),
        );

        unlockCollection();
        dispatch(hideLoading());
        dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
        dispatch(updateActiveDatasetSubStage(DatasetCreateSubStage.TYPE));
        history.push(
          `${RouteName.DATASET_ITEM}/${
            datasetMeta._id
          }/${collection?.dataSource?.dataSourceActionType?.toLowerCase()}`,
        );
      }
    }
  }, [
    transformingCollectionType,
    datasetMeta?._id,
    datasetMeta?.dataCollections,
    dispatch,
    history,
    unlockCollection,
  ]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const datasetUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.DATASET_METAS,
        );
        setDatasetMetaUsage(datasetUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  const beginDataTransformation = async (
    transformType: Enums.DataSourceActionType,
  ) => {
    if (datasetMeta?._id && activeDataCollectionItem) {
      dispatch(showLoading({ text: 'Creating Collection...' }));

      // Check if existing replacement exists
      const inProgressCollection = cloneDeep(datasetMeta.dataCollections)
        .reverse()
        .find(
          (c) =>
            c.status === Enums.CollectionStatus.IN_PROGRESS &&
            c.dataSource?.dataSourceActionType === transformType,
        );

      if (inProgressCollection) {
        // Remove collection that is in progress
        const cloned = cloneDeep(datasetMeta);
        cloned.dataCollections = cloned.dataCollections.filter(
          (c) => c._id !== inProgressCollection._id,
        );

        await editDatasetMeta(cloned);

        // Set activeDataCollection as selected
        dispatch(
          updateDatasetCollection({
            datasetMetaId: datasetMeta?._id,
            collectionId: activeDataCollectionItem?._id,
          }),
        );

        lockCollection();
      }

      // Mark new collection as in-progress replacement
      const clonedColl = cloneDeep(activeDataCollectionItem);
      delete clonedColl._id;
      delete clonedColl.dataCollection;
      clonedColl.dataSource = {
        dataSourceActionType: transformType,
      };
      clonedColl.columnCount = 0;
      clonedColl.rowCount = 0;
      clonedColl.status = Enums.CollectionStatus.IN_PROGRESS;
      clonedColl.stages = {
        creation: {
          status: Enums.StageStatus.NOT_STARTED,
        },
        structure: {
          status: [
            Enums.DataSourceActionType.MERGE,
            Enums.DataSourceActionType.REPLACE,
          ].includes(transformType)
            ? Enums.StageStatus.COMPLETED
            : Enums.StageStatus.NOT_STARTED,
        },
        validation: {
          status: Enums.StageStatus.NOT_STARTED,
        },
        enhancement: {
          status: Enums.StageStatus.NOT_STARTED,
        },
      };
      await addDatasetMetaCollection(datasetMeta, clonedColl);

      setTransformingCollectionType(transformType);
      dispatch(hideLoading());
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        {datasetMeta?.activeDataCollection && (
          <>
            <AdvancedSetting
              action={() =>
                beginDataTransformation(Enums.DataSourceActionType.REPLACE)
              }
              headerText={'Data Replacement'}
              subHeaderText={
                'Replace your data by uploading a new source file.'
              }
              bodyText={`You will be able to retain your current schema, but all data will be replaced. We recommend creating a snapshot of your data first.`}
              actionButtonText={'Replace Data'}
            />

            <AdvancedSetting
              action={() =>
                beginDataTransformation(Enums.DataSourceActionType.MERGE)
              }
              headerText={'Data Merge'}
              subHeaderText={
                'Merge or append data to your dataset from a new source file.'
              }
              bodyText={`In order to update existing rows, you'll need to select a unique column that we can use to match up the datasets.`}
              actionButtonText={'Merge Data'}
            />

            <AdvancedSetting
              action={() =>
                dispatch(
                  showModal({
                    visible: true,
                    modal: ModalTypes.DATASET_META_DUPLICATION,
                    additionalProps: {
                      entityId: datasetMeta?._id,
                      entityName: datasetMeta?.name,
                      entityType: EntityType.DATASET,
                    },
                  }),
                )
              }
              headerText={'Duplicate Dataset'}
              isDisabled={
                datapointQuota?.isDatapointQuotaLimit ||
                datasetMetaUsage >= QuotaLimits.FULL
              }
              subHeaderText={`Make a copy of your dataset's configuration and/or data.`}
              bodyText={`Duplicate your dataset's schema to be used as a starting point and optionally also duplicate the existing dataset's data.`}
              actionButtonText={'Duplicate Dataset'}
              usage={datasetMetaUsage}
            />
          </>
        )}

        <AdvancedSetting
          action={() =>
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.DELETION,
                additionalProps: {
                  entityType: EntityType.DATASET,
                  entityId: datasetMeta?._id,
                  entityName: datasetMeta?.name,
                },
              }),
            )
          }
          headerText={'Delete Dataset'}
          subHeaderText={`Delete your dataset completely.`}
          bodyText={`This will destroy all data and may break any projects, queries or connections that this dataset is linked to. Be absolutely sure you wish to delete this dataset before proceeding.`}
          actionButtonText={'Delete Dataset'}
          actionButtonColor={themeContext.colors.general.red}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ConfigureAdvancedStage;
