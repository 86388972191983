import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faSend } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, KeyboardEvent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../assets/icons/loading/default-loading-icon.gif';
import { ROW_NUMBER_HEADER } from '../../../const/DataSample';
import useComment from '../../../hooks/comment/UseComment';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import { resetComment } from '../../../store/comment';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

interface CommentInputProps {
  loading?: boolean;
  reloadComments: () => void;
}

const ORG_ID = 'organisationId';

const CommentInput: FC<CommentInputProps> = ({ loading, reloadComments }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const { addComment, editComment, comment } = useComment();

  const { chartId, cmsId, datasetMetaId, aggregationId, portalId, groupId } =
    useParams();
  const rowId = new URLSearchParams(location.search).get(ROW_NUMBER_HEADER);

  const [messageText, setMessageText] = useState<string>();
  const [commentSaving, setCommentSaving] = useState<boolean>();

  const submitDisabled =
    !messageText || loading || commentSaving || !messageText.trim();

  const processComment = async () => {
    if (!loggedInUser) {
      return;
    }

    setCommentSaving(true);

    const cloned: Interfaces.CommentOutput = cloneDeep(comment);
    cloned.message.text = messageText;

    if (!comment?._id) {
      // Using reflection as a means to mutate a readonly prop
      Reflect.set(cloned, ORG_ID, loggedInUser.organisationId);
      cloned.resourceId = datasetMetaId || aggregationId || chartId;
      cloned.datasetRowId = rowId ? +rowId : undefined;
      cloned.cmsId = cmsId;
      cloned.groupId = groupId;
      cloned.portalId = portalId;
      cloned.resourceType = datasetMetaId
        ? Enums.SchemaName.DATASET_META
        : aggregationId
        ? Enums.SchemaName.QUERY
        : Enums.SchemaName.CHART;
      await addComment(cloned);
    } else {
      await editComment(cloned);
    }

    dispatch(resetComment());
    setMessageText('');
    setCommentSaving(false);
    reloadComments();
  };

  const handleInputKeypress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && submitDisabled) {
      e.preventDefault();
    }
    if (e.key === 'Enter' && e.shiftKey == false && !submitDisabled) {
      processComment();
    }
  };

  return (
    <SC.CommentInputWrapper>
      <SC.CommentInput
        onChange={(e) => setMessageText(e.target.value)}
        value={messageText}
        placeholder={'Start typing...'}
        onKeyPress={handleInputKeypress}
        disabled={commentSaving}
      />
      {commentSaving && (
        <SC.Loader
          style={{ position: 'absolute' }}
          src={DefaultLoadingIcon}
          alt={'Loading'}
        />
      )}
      <SC.CommentInputFooter>
        <FeatureButton
          isDisabled={submitDisabled}
          action={processComment}
          size={FeatureButtonSize.EXTRA_SMALL}
          height={32}
          color={themeContext.colors.general.blue}
          icon={
            <FontAwesomeIcon
              icon={faSend}
              color={themeContext.colors.general.white}
              size={'1x'}
            />
          }
        />
      </SC.CommentInputFooter>
    </SC.CommentInputWrapper>
  );
};

export default CommentInput;
