import { Interfaces } from '@configur-tech/upit-core-types';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
} from '../../../../main/theme';
import FeatureButton from '../../../FeatureButton/FeatureButton';
import * as SC from '../../../Modal/pipeline/styled';

const PARAMETER_TYPE_KEY = 'parameterType';
const PARAMETER_NAME_KEY = 'field';
const PAGE_SIZE_KEY = 'pageSize';
const INITIAL_PAGE_NUMBER_KEY = 'initialPageNum';
const PAGE_PARAM_KEY = 'pageParam';
const PAGE_BASED_PARAMS_KEY = 'pageBasedPaginationParams';

enum PaginationType {
  HEADER = 'headers',
  PATH = 'pathParams',
  QUERY = 'queryParams',
}

const DROPDOWN_PAGINATION_TYPE_OPTIONS = Object.entries(PaginationType).map(
  ([k, v]) => {
    return {
      key: k,
      text: startCase(k.toLowerCase()),
      value: v,
    };
  },
);

interface PaginationParamsProps {
  onChange: (params?: Interfaces.PaginationParams) => void;
  paramsData: Interfaces.PaginationParams;
}

const PaginationParams: FC<PaginationParamsProps> = ({
  onChange,
  paramsData,
}) => {
  const themeContext = useContext(ThemeContext);
  const [paginationType, setPaginationType] = useState<PaginationType>(
    PaginationType.QUERY,
  );

  useEffect(() => {
    setPaginationType(
      paramsData[PAGE_PARAM_KEY][PaginationType.QUERY]
        ? PaginationType.QUERY
        : paramsData[PAGE_PARAM_KEY][PaginationType.HEADER]
        ? PaginationType.HEADER
        : PaginationType.PATH,
    );
  }, [paramsData]);

  const handlePaginationFieldChange = (field: string, value: unknown) => {
    const cloned = cloneDeep(paramsData);

    if (field === PARAMETER_TYPE_KEY) {
      cloned[PAGE_PARAM_KEY][value] = cloned[PAGE_PARAM_KEY][paginationType];
      delete cloned[PAGE_PARAM_KEY][paginationType];
    } else {
      if (field === PARAMETER_NAME_KEY) {
        cloned[PAGE_PARAM_KEY][paginationType][0][field] = value;
      } else if (field === PAGE_SIZE_KEY || field === INITIAL_PAGE_NUMBER_KEY) {
        if (!cloned[PAGE_BASED_PARAMS_KEY]) {
          cloned[PAGE_BASED_PARAMS_KEY] = {};
        }

        cloned[PAGE_BASED_PARAMS_KEY][field] = value;
      } else {
        cloned[field] = value;
      }
    }

    onChange(cloned);
  };

  const handleRemoveRow = () => {
    onChange(undefined);
  };

  return (
    <SC.InputRowContainer>
      <SC.InputWrapper key={`inputs`}>
        <SC.InputContainer>
          <StyledBodySubHeader>Parameter Type</StyledBodySubHeader>
          <StyledDropdown
            selectOnBlur={false}
            placeholder={'Select a parameter type'}
            selection
            value={paginationType}
            options={DROPDOWN_PAGINATION_TYPE_OPTIONS}
            onChange={(e, { value }) =>
              handlePaginationFieldChange(PARAMETER_TYPE_KEY, value)
            }
            style={{ margin: 0 }}
            upward={true}
          />
        </SC.InputContainer>

        <SC.InputContainer>
          <StyledBodySubHeader>Parameter Name</StyledBodySubHeader>
          <StyledInput
            value={
              paramsData.pageParam &&
              paramsData.pageParam[paginationType] &&
              (paramsData.pageParam[paginationType] as any)[0]?.field
            }
            placeholder={'Enter a parameter name'}
            onChange={(e, { value }) =>
              handlePaginationFieldChange(PARAMETER_NAME_KEY, value)
            }
          />
        </SC.InputContainer>

        <SC.InputContainer>
          <StyledBodySubHeader>Page Size</StyledBodySubHeader>
          <StyledInput
            value={paramsData.pageBasedPaginationParams?.pageSize}
            placeholder={'Enter a page size'}
            type={'number'}
            onChange={(e, { value }) =>
              handlePaginationFieldChange(PAGE_SIZE_KEY, value)
            }
          />
        </SC.InputContainer>

        <SC.InputContainer>
          <StyledBodySubHeader>Starting Page Number</StyledBodySubHeader>
          <StyledInput
            value={paramsData.pageBasedPaginationParams?.initialPageNum}
            placeholder={'Enter a starting page number'}
            type={'number'}
            onChange={(e, { value }) =>
              handlePaginationFieldChange(INITIAL_PAGE_NUMBER_KEY, value)
            }
          />
        </SC.InputContainer>

        <FeatureButton
          action={handleRemoveRow}
          size={34}
          height={34}
          color={themeContext.colors.general.red}
          icon={
            <FontAwesomeIcon
              icon={faTrashAlt}
              color={defaultTheme.colors.system.white}
            />
          }
        />
      </SC.InputWrapper>
    </SC.InputRowContainer>
  );
};

export default PaginationParams;
