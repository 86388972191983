import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums';
import usePipelineTemplate from '../../hooks/pipeline-template/UsePipelineTemplate';
import PageContainer from '../../main/PageContainer';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { PipelineStage } from '../../store/pipeline-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import * as SC from '../PipelineItem/styled';
import PipelineItemConfiguration from './PipelineItemConfiguration';
import PipelineItemCreation from './PipelineItemCreation';

const PipelineItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { pipelineTemplate, pipelineTemplateError, getPipelineTemplate } =
    usePipelineTemplate();

  const [fetchingPipelineTemplate, setFetchingPipelineTemplate] =
    useState<boolean>(false);

  const pipelineStageState = useSelector(
    (state: RootState) => state.pipelineStage,
  );

  const activeStage = pipelineStageState.activeStage;

  // Get an existing PipelineTemplate if requested
  useEffect(() => {
    if (
      !fetchingPipelineTemplate &&
      params?.pipelineTemplateId &&
      params?.pipelineTemplateId !== pipelineTemplate?._id
    ) {
      (async () => {
        setFetchingPipelineTemplate(true);
        dispatch(
          showLoading({
            text: `Loading Pipeline Template...`,
          }),
        );

        await getPipelineTemplate(params.pipelineTemplateId);

        dispatch(hideLoading());
      })();
    }
  }, [
    params?.pipelineTemplateId,
    pipelineTemplate?._id,
    dispatch,
    fetchingPipelineTemplate,
    getPipelineTemplate,
  ]);

  // Error fetching PipelineTemplate
  useEffect(() => {
    if (fetchingPipelineTemplate && pipelineTemplateError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'Pipeline Not Found',
            errorText:
              'This pipeline could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Pipeline Centre',
            errorActionRedirectRoute: RouteName.DATASET_ITEM,
          },
        }),
      );
    }
  }, [
    pipelineTemplateError,
    dispatch,
    fetchingPipelineTemplate,
    pipelineTemplate,
  ]);

  const getStageComponent = () => {
    switch (activeStage) {
      case PipelineStage.CREATION:
        return <PipelineItemCreation />;
      case PipelineStage.CONFIGURATION:
        return <PipelineItemConfiguration />;
      default:
        return <PipelineItemCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.PIPELINE_ITEM} />
      <PageContainer>
        <SC.ContentWrapper>{getStageComponent()}</SC.ContentWrapper>
      </PageContainer>
    </>
  );
};

export default PipelineItemPage;
