import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useDiscovery from '../../../../hooks/dataset-meta/UseDiscovery';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../../store/dataset-stage';
import { DatasetStructureSubStage } from '../../../../store/dataset-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NEXT_STAGE = DatasetStructureSubStage.CONFIRMATION;

export interface DiscoveryDatasetStageProps {
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const DiscoveryDatasetStage: FC<DiscoveryDatasetStageProps> = ({
  setSampleData,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();
  const { collection } = useDatasetCollection();
  const { discoverDataset } = useDiscovery();
  const { createTableData } = useTableData();

  const processAction = async () => {
    const discoveryData = await discoverDataset();
    if (!discoveryData) {
      return;
    }

    const tableData = await createTableData(
      discoveryData.schema,
      discoveryData.sample,
    );
    if (!tableData.rows) {
      return;
    }

    setSampleData(tableData.rows);

    const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

    const coll = collection?.collectionId
      ? cloned.dataCollections.find((c) => c._id === collection?.collectionId)
      : cloned.dataCollections?.[0];

    if (coll) {
      // Save schema - concat newly discovered fields
      coll.schemaData = coll.schemaData.concat(
        (tableData.schema as Interfaces.FieldOutput[]).reduce((acc, field) => {
          const exists = coll.schemaData.find((f) => f.name === field.name);

          if (exists) {
            return acc;
          }

          // Default any formula columns
          if (
            field.dataValidation?.dataValidationType ===
              Enums.ValueDataType.FORMULA &&
            !field.dataValidation.formula
          ) {
            field.dataValidation.formula = [];
          }

          return [...acc, field];
        }, [] as Interfaces.FieldOutput[]),
      );

      // Update stage status
      (
        coll.stages[Enums.DatasetStage.STRUCTURE] as Interfaces.DatasetMetaStage
      ).status = Enums.StageStatus.IN_PROGRESS;

      // Save datasetMeta
      await editDatasetMeta(cloned);

      dispatch(updateActiveDatasetSubStage(NEXT_STAGE));
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Analyse Your Data Set</StyledH2>
        <StageBodyText>
          Simply tap the button below and our clever AI will analyse your entire
          dataset.
        </StageBodyText>
        <StageBodyText>
          Once it’s finished, you'll be able to see what kind of data we think
          you're storing and set a host of validation rules.
        </StageBodyText>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <FeatureButton
            action={() => processAction()}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Analyse Dataset'}
            containerStyle={{ marginTop: themeContext.margin.standard }}
          />
        </div>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to data creation'}
          />
        }
      />
    </StageWrapper>
  );
};

export default DiscoveryDatasetStage;
