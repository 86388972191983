import { DatasetApiOutput } from '@configur-tech/upit-core-types/lib/interfaces';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import ApiConnectionIcon from '../../assets/icons/connections/api-connection-icon.svg';
import DataSample from '../../components/DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import { ModalTypes } from '../../components/Modal/Modal';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useApi from '../../hooks/api/UseApi';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePermission from '../../hooks/permission/UsePermission';
import useUsage from '../../hooks/usage/useUsage';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../main/theme';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import * as SC from './styled';

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';

const API_TABLE_SCHEMA = [{ name: NAME_FIELD }];

const APIsPage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getApis } = useApi();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();
  const { isOrgManager } = usePermission();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);

  const [fetchingAPIs, setIsFetchingAPIs] = useState<boolean>(false);
  const [apiUsage, setApiUsage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const apisState = useSelector((state: RootState) => state.apis);
  const apis: DatasetApiOutput[] = apisState.data;

  // Get APIs
  useEffect(() => {
    (async () => {
      if (!fetchingAPIs) {
        setIsFetchingAPIs(true);
        await getApis();
        setIsLoading(false);
      }
    })();
  }, [getApis, fetchingAPIs]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const apiUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.APIS,
        );
        setApiUsage(apiUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, organisation?._id]);

  useEffect(() => {
    if (apis) {
      const tableData: SampleDataRow[] = [];

      apis?.map((api) => {
        tableData.push({
          [ID_FIELD]: { value: api._id },
          [NAME_FIELD]: { value: api.alias || '' },
        });
      });

      setTableData(tableData);
    }
  }, [apis]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.APIS} />

      <PageContainer>
        <Page>
          <PageFeatureHeader
            pageHeader={'APIs'}
            image={ApiConnectionIcon}
            title={'Integrate your data'}
          />
          <StageWrapper>
            <StageInner>
              <SC.ConnectionTypeWrapper>
                <StyledSubHeader>
                  Application Programming Interface (API)
                </StyledSubHeader>

                <StageBodyText>
                  Build REST APIs in minutes and connect with infinite
                  platforms, chatbots, voice assistants and more.
                </StageBodyText>

                {isOrgManager && (
                  <>
                    {apiUsage >= QuotaLimits.WARNING && (
                      <div style={{ marginBottom: themeContext.margin.xlarge }}>
                        {BuildBanner.generateBanner(
                          BannerType.QUOTA,
                          apiUsage >= QuotaLimits.FULL
                            ? AlertStatus.ERROR
                            : AlertStatus.WARNING,
                          ResourceType.APIS,
                        )}
                      </div>
                    )}

                    <FeatureButton
                      action={() => {
                        dispatch(
                          showModal({
                            visible: true,
                            modal: ModalTypes.CONNECTION_API,
                          }),
                        );
                      }}
                      size={FeatureButtonSize.WIDE}
                      color={themeContext.colors.general.blue}
                      text={'Add API'}
                      containerStyle={{
                        marginBottom: themeContext.margin.large,
                      }}
                      isDisabled={apiUsage >= QuotaLimits.FULL}
                    />
                  </>
                )}

                {isOrgManager && !tableData.length && !isLoading && (
                  <StageBodyText>
                    <strong>No APIs found</strong>
                  </StageBodyText>
                )}

                {(tableData.length > 0 || isLoading) && (
                  <SC.TableWrapper>
                    <DataSample
                      totalRows={tableData.length}
                      sampleColumns={API_TABLE_SCHEMA}
                      sampleRows={tableData}
                      clickableRows={{
                        valueField: ID_FIELD,
                        action: (apiId) => {
                          const existingAPI = apis?.find(
                            (a) => a._id === apiId,
                          );
                          dispatch(
                            showModal({
                              visible: true,
                              modal: ModalTypes.CONNECTION_API,
                              additionalProps: {
                                existingAPI,
                              },
                            }),
                          );
                        },
                      }}
                      loading={isLoading}
                    />
                  </SC.TableWrapper>
                )}
              </SC.ConnectionTypeWrapper>
            </StageInner>
          </StageWrapper>
        </Page>
      </PageContainer>
    </>
  );
};

export default APIsPage;
