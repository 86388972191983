import { Enums } from '@configur-tech/upit-core-types';
import { NotificationAlertItem } from '../../services/notification-alert/NotificationAlertService';

const InitialNotificationAlert: NotificationAlertItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },

    name: '',
    organisationId: '',

    channel: Enums.NotificationChannel.EMAIL,
    conditions: {},
    frequency: Enums.NotificationFrequency.DAILY,
    recipients: [],
    resource: { resourceType: Enums.SchemaName.DATASET_META, resourceId: '' },
    trigger: Enums.NotificationTrigger.DSM_DATA_UPSERTED,
  },
};

export default InitialNotificationAlert;
