import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep, isEqual, uniq } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import DataSamplePagination from '../../components/DataSample/DataSamplePagination';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import {
  SLACK,
  TEAMS,
} from '../../components/Modal/integration-centre/IntegrationManageModal';
import { ModalTypes } from '../../components/Modal/Modal';
import NotificationCard from '../../components/NotificationAlert/NotificationAlertCard';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType } from '../../enums';
import { RouteName } from '../../enums/RouteName';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useIntegrationConfig from '../../hooks/integration-config/UseIntegrationConfig';
import UseNotificationAlert from '../../hooks/notification-alert/UseNotificationAlert';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StyledText } from '../../main/theme';
import { NotificationAlertItemOutput } from '../../services/notification-alert/NotificationAlertService';
import { showModal } from '../../store/modal';
import { resetNotificationAlert } from '../../store/notification-alert';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import { DEFAULT_PAGE_SIZE } from '../CMS/CMSPage';
import { PageInner } from '../styled';
import * as SC from './styled';

// TODO - change this to dataset when available
const SEARCH_FIELD = 'channel';
const SEARCH_PARAM = 'search';

interface NotificationFetchParams {
  [SEARCH_FIELD]: string;
  pageNum: number;
}

const NotificationsPage: FC = () => {
  const { getDatasetMetas } = useDatasetMeta();
  const location = useLocation();
  const { getIntegrationConfigs } = useIntegrationConfig();
  const { getNotificationAlerts } = UseNotificationAlert();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const notificationAlertsState = useSelector(
    (state: RootState) => state.notificationAlerts,
  );
  const notificationAlerts: NotificationAlertItemOutput[] =
    notificationAlertsState.data.data;

  const [alertData, setAlertData] = useState<NotificationAlertItemOutput[]>();

  const [loadingNotifications, setLoadingNotification] =
    useState<boolean>(true);

  const [fetchParams, setFetchParams] = useState<NotificationFetchParams>({
    [SEARCH_FIELD]: '',
    pageNum: 0,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<
    NotificationFetchParams | undefined
  >();

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get(SEARCH_PARAM);

    setFetchParams((state) => ({
      ...state,
      [SEARCH_FIELD]:
        searchParams === null
          ? ''
          : decodeURI(searchParams as string).toString(),
    }));
  }, [location.search]);

  // Fetch Notification Alerts
  useEffect(() => {
    if (isEqual(fetchParams, lastFetchedParams) || loadingNotifications) {
      return;
    }
    (async () => {
      setLoadingNotification(true);
      setLastFetchedParams(fetchParams);

      const formattedSearchParam = cloneDeep(fetchParams);
      formattedSearchParam.pageNum = fetchParams.pageNum + 1;
      !formattedSearchParam[[SEARCH_FIELD]] &&
        delete formattedSearchParam[[SEARCH_FIELD]];

      await getNotificationAlerts(formattedSearchParam);
    })();
  }, [
    fetchParams,
    getNotificationAlerts,
    lastFetchedParams,
    loadingNotifications,
  ]);

  useEffect(() => {
    if (isEqual(notificationAlerts, alertData)) {
      return;
    }

    setAlertData(notificationAlerts);
  }, [alertData, notificationAlerts]);

  // Fetch additional data
  useEffect(() => {
    if (!alertData) {
      return;
    }

    const datasetMetaIds = uniq(
      notificationAlerts
        .filter(
          (alert) =>
            alert.entity.resource.resourceType ===
            Enums.SchemaName.DATASET_META,
        )
        .map((alert) => alert.entity.resource.resourceId),
    );

    (async () => {
      // Fetch any missing lookup datasets
      if (datasetMetaIds.length) {
        await getDatasetMetas({ _id: { $in: datasetMetaIds } });
      }

      setLoadingNotification(false);
    })();
  }, [alertData, getDatasetMetas, notificationAlerts]);

  // Load Slack and Microsoft Teams notification configs
  useEffect(() => {
    (async () => {
      await getIntegrationConfigs({
        name: { $in: [SLACK, TEAMS] },
      });
    })();
  }, [getIntegrationConfigs]);

  const generatePageArray = (totalCount: number) =>
    Array.from({ length: totalCount }, (_, i) => i + 1);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.NOTIFICATION_CENTRE} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Notifications'}
          image={getDefaultAvatar(EntityType.DATASET)}
          title={'Get Notified When Things Change'}
        />
        <Page>
          <PageInner>
            <StyledText>
              Notifications can be used to automatically alert you or your team
              to changes in your datasets.
            </StyledText>
            <StyledText>
              Tap the button below to create a new notification or manage your
              existing notifications from the list further down.
            </StyledText>
            <FeatureButton
              action={() => {
                dispatch(resetNotificationAlert());
                dispatch(
                  showModal({
                    visible: true,
                    modal: ModalTypes.NOTIFICATION_ALERT,
                    fullScreen: true,
                  }),
                );
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create new notification'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
            />

            {/* TODO - Awaiting lambda */}
            {/* <EntityFilter
              baseRoute={`${RouteName.DATASETS}${RouteName.NOTIFICATIONS}`}
              title={'Search Notifications'}
              search={fetchParams[SEARCH_FIELD]}
              inputPlaceholder={'Search by dataset name'}
              totalResultCount={
                notificationAlertsState.data.pagination.totalCount
              }
              filteredResultCount={notificationAlerts?.length}
              loading={loadingNotifications}
            /> */}

            {loadingNotifications && (
              <SC.LoaderContainer>
                <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />
              </SC.LoaderContainer>
            )}

            {!loadingNotifications && (
              <SC.NotificationCardWrapper>
                {notificationAlerts?.map((notification) => {
                  return <NotificationCard notification={notification} />;
                })}

                <DataSamplePagination
                  canNextPage={
                    !!notificationAlertsState.data.pagination.nextPageNum
                  }
                  canPreviousPage={
                    !!notificationAlertsState.data.pagination.prevPageNum
                  }
                  gotoPage={(pageIndex: number) =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: pageIndex,
                    })
                  }
                  nextPage={() =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: fetchParams.pageNum + 1,
                    })
                  }
                  pageCount={notificationAlertsState.data.pagination.totalPages}
                  pageIndex={fetchParams.pageNum}
                  pageOptions={generatePageArray(
                    notificationAlertsState.data.pagination.totalPages,
                  )}
                  pageSize={DEFAULT_PAGE_SIZE}
                  previousPage={() =>
                    setFetchParams({
                      ...fetchParams,
                      pageNum: fetchParams.pageNum - 1,
                    })
                  }
                  totalRows={notificationAlertsState.data.pagination.totalCount}
                />
              </SC.NotificationCardWrapper>
            )}
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default NotificationsPage;
