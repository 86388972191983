import { useCallback } from 'react';

export interface PinnedColumn {
  id: number;
  desc: boolean;
}

interface usePinnedResult {
  getPinnedColumn: (datasetMetaId: string) => PinnedColumn;
  updatePinnedColumn: (
    datasetMetaId: string,
    sort?: PinnedColumn | undefined,
  ) => void;
  removePinnedColumn: (datasetMetaId: string) => void;
}

const PINNED_SUFFIX = '__PINNED';

const usePinned = (): usePinnedResult => {
  const getPinnedColumn = useCallback((datasetMetaId: string) => {
    if (!datasetMetaId) {
      return;
    }
    const storedSort = localStorage.getItem(`${datasetMetaId}${PINNED_SUFFIX}`);
    return storedSort ? JSON.parse(storedSort) : undefined;
  }, []);

  const updatePinnedColumn = (datasetMetaId: string, sort?: PinnedColumn) => {
    if (!datasetMetaId || !sort) {
      return;
    }

    localStorage.setItem(
      `${datasetMetaId}${PINNED_SUFFIX}`,
      JSON.stringify(sort),
    );
  };

  const removePinnedColumn = (datasetMetaId: string) => {
    if (!datasetMetaId) {
      return;
    }

    localStorage.removeItem(`${datasetMetaId}${PINNED_SUFFIX}`);
  };

  return { getPinnedColumn, updatePinnedColumn, removePinnedColumn };
};

export default usePinned;
