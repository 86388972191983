import cat from './cat-user-icon.svg';
import chicken from './chicken-user-icon.svg';
import dog from './dog-user-icon.svg';
import fox from './fox-user-icon.svg';
import lion from './lion-user-icon.svg';
import monkey from './monkey-user-icon.svg';
import mouse from './mouse-user-icon.svg';
import octopus from './octopus-user-icon.svg';
import owl from './owl-user-icon.svg';
import panda from './panda-user-icon.svg';
import pig from './pig-user-icon.svg';
import squid from './squid-user-icon.svg';
import unicorn from './unicorn-user-icon.svg';

const userIcons: Record<string, string> = {
  cat,
  chicken,
  dog,
  fox,
  lion,
  monkey,
  mouse,
  octopus,
  owl,
  panda,
  pig,
  squid,
  unicorn,
};

export default userIcons;
