import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const Wrapper = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.darkBlueShadow};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 ${({ theme }) => theme.margin.standard};
`;

const DatasetViewActionHeader: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, datasetMetaAccessLevel } = useDatasetMeta();

  const canEditData =
    datasetMetaAccessLevel &&
    [Enums.AccessLevel.EDIT, Enums.AccessLevel.MANAGE].includes(
      datasetMetaAccessLevel,
    );

  if (!datasetMeta?._id || !canEditData) {
    return null;
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <SectionWrapper>
          <FeatureButton
            action={() => {
              dispatch(resetStagesAndSubStages());
              history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
            }}
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.green}
            text={`Manage Dataset`}
          />
        </SectionWrapper>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default DatasetViewActionHeader;
