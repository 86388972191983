import React, { CSSProperties, FC } from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Accordion,
  AccordionContent,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import NavItem, { NavItemProps } from '../components/Navigation/NavItem';

interface ThemeProps {
  children: React.ReactNode;
}

export const defaultTheme: DefaultTheme = {
  colors: {
    system: {
      darkBlue: '#272C34',
      darkBlueHighlight: '#3D4857',
      darkBlueShadow: '#1B1F24',
      darkGrey: '#808080',
      darkOffBlack: '#23282F',
      darkSea: '#3E5775',
      grey: '#E4E4E4',
      midGrey: '#cfcfcf',
      navy: '#01203A',
      offBlack: '#2F3640',
      offWhite: '#F0EEEE',
      white: '#F8F8F8',
    },
    general: {
      blue: '#3498DB',
      green: '#2ECC71',
      purple: '#7d317d',
      red: '#E74C3C',
      sea: '#3C6382',
      yellow: '#F7C535',
      white: '#FFFFFF',
      fadedRed: 'rgba(231, 76, 60, .2)',
      fadedYellow: 'rgba(247, 197, 53, .2)',
      fadedGreen: 'rgba(46, 204, 113, .2)',
      fadedBlue: 'rgba(52, 152, 219, .2)',
      darkYellow: '#d7aa23',
    },
  },

  typography: {
    header: `
      font-family: 'Roboto', 'sans-serif';
      font-weight: bold;
      margin: 0;
      padding: 0;
    `,
    body: `
      font-size: 0.9em;
      font-family: 'Lato', 'sans-serif';
      font-weight: regular;
      margin: 0 0 1em 0;
      padding: 0;
    `,
    sizes: {
      p: '1.0em',
      smaller: '0.85em',
      small: '0.75em',
      tiny: '0.65em',
      h1: '1.8em',
      h2: '1.6em',
      h3: '1.4em',
      h4: '1.2em',
      h5: '1.0em',
    },
  },

  margin: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
  },

  padding: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
  },

  borders: {
    radius: '5px',
  },
};

export const StyledText = styled.p`
  ${defaultTheme.typography.body};
  font-size: ${defaultTheme.typography.sizes.p};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledSubHeader = styled(StyledText)<{
  marginBottom?: string;
}>`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h4};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledBodySubHeader = styled(StyledText)`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h5};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH1 = styled.h1`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h1};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH2 = styled.h2`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h2};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH3 = styled.h3`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h3};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH4 = styled.h4`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h4};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH5 = styled.h5`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h5};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledErrorText = styled.p`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.p};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.general.red};
`;

// Top Bar Headers
export const TopBarHeaderTextWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.95s;

  ${({ disabled }) =>
    disabled
      ? ''
      : `&:hover {
    cursor: pointer;
    opacity: 0.5;
  }`};
`;

export const TopBarHeaderLabel = styled(StyledText)<{ lightTheme?: boolean }>`
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  ${({ theme }) => theme.typography.header};
  text-transform: uppercase;

  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};

  margin-left: ${({ theme, lightTheme }) =>
    lightTheme ? 0 : theme.margin.standard};
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const TopBarHeaderTitle = styled(StyledText)<{ lightTheme?: boolean }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};

  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};

  margin-left: ${({ theme }) => theme.margin.standard};
`;

export const StyledInputHeader = styled(StyledText)`
  text-transform: uppercase;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.offBlack};

  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const StyledInput = styled(Input)`
  width: 250px;
  max-width: 100%;

  > input {
    text-align: center !important;

    &:focus {
      border-color: ${({ theme }) => theme.colors.general.blue};
      outline: none;
    }
  }
`;

export const StyledTextArea = styled(TextareaAutosize)`
  width: 400px;
  max-width: 100%;

  padding: ${({ theme }) => `${theme.padding.standard} ${theme.margin.large}`};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:focus {
    border-color: ${({ theme }) => theme.colors.general.blue};
    outline: none;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 250px;
  max-width: 100%;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};

  .menu {
    max-height: 150px !important;
  }
`;

export const StyledDropdownWide = styled(Dropdown)`
  width: 400px;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
`;

export const StyledDropdownUltraWide = styled(Dropdown)`
  width: 650px;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
`;

export const StageWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

export const StageBodyText = styled(StyledText)`
  width: 100%;
  max-width: 1300px;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
  text-align: center;
`;

export const StyledAccordion = styled(Accordion)`
  min-width: 350px;

  background-color: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.large}`} !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > div {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.margin.large};
  }

  > p {
    margin: 0;
  }
`;

export const StyledAccordionRequirement = styled(StyledBodySubHeader)`
  color: ${({ theme }) => theme.colors.system.darkGrey};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

export const StyledAccordionContent = styled(AccordionContent)`
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  padding: ${({ theme }) => theme.padding.large} !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  &:last-of-type {
    border: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dropdown {
      margin: 0;
    }
  }
`;

export const CardEntityWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    margin: ${({ theme }) => theme.margin.standard};
  }
`;

export const ProjectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  > div {
    margin: ${({ theme }) => theme.margin.standard};
  }
`;

export const StyledQuestionBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    margin-bottom: ${({ theme }) => theme.margin.standard};
  }

  > div:nth-last-child(2) {
    margin-top: ${({ theme }) => theme.margin.xlarge};
  }
  > div:last-child {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const EndInputButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.margin.large};
  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const StyledFormHeader = styled(StyledH2)`
  margin-bottom: ${({ theme }) => theme.margin.xxlarge};
`;

export const DefaultPopupStyles: CSSProperties = {
  border: 'none',
  borderRadius: defaultTheme.borders.radius,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

export const BackNavigationItem = styled(NavItem)<NavItemProps>`
  background: ${({ isActive, theme, lightTheme }) =>
    isActive
      ? lightTheme
        ? theme.colors.system.darkSea
        : theme.colors.system.darkSea
      : lightTheme
      ? theme.colors.system.offWhite
      : theme.colors.system.darkSea};
`;

const Theme: FC<ThemeProps> = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <GoogleFontLoader
      fonts={[
        { font: 'Lato', weights: [400] },
        { font: 'Roboto', weights: [700] },
      ]}
    />
    {children}
  </ThemeProvider>
);

export default Theme;
