import { FormattedData } from '../store/graph';

export enum TaskType {
  TASK = 'task',
  PROJECT = 'project',
}

export interface GanttDataOutput {
  start: FormattedData;
  end: FormattedData;
  startFormatted: FormattedData;
  endFormatted: FormattedData;
  hideChildren: boolean;
  id: string;
  type: TaskType;
  name: string;
  progress: number;
  status: string;
}
