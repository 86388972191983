import styled from 'styled-components';

export const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

export const TableWrapper = styled.div`
  width: 100%;
`;
