import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faChevronDown,
  faChevronUp,
  faList,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledAccordion,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../../Modal/pipeline/styled';
import DynamicFieldInput from './DynamicField';
import PaginationParams from './PaginationParams';

const VALUE_KEY = 'value';
const FIELD_KEY = 'field';
const TYPE_KEY = 'type';
const CONSTRAINTS_KEY = 'constraints';
const FORMAT_KEY = 'format';

export enum ParamType {
  HEADERS = 'headers',
  PATH = 'pathParams',
  QUERY = 'queryParams',
  PAGINATION = 'paginationParams',
}

const PARAM_TEXT_MAP = {
  [ParamType.QUERY]: 'Query',
  [ParamType.HEADERS]: 'Header',
  [ParamType.PATH]: 'Path',
  [ParamType.PAGINATION]: 'Pagination',
};

const DROPDOWN_FIELD_TYPE_OPTIONS = Object.values(Enums.ValueDataType)
  .filter(
    (i) =>
      ![
        Enums.ValueDataType.UNKNOWN,
        Enums.ValueDataType.EMPTY,
        Enums.ValueDataType.FORMULA,
        Enums.ValueDataType.TEXT_TRANSFORMATION,
        Enums.ValueDataType.DATE_CONVERSION,
        Enums.ValueDataType.FIELD_LOOKUP,
      ].includes(i),
  )
  .map((i) => {
    return {
      key: i,
      text: startCase(i.toLowerCase()),
      value: i,
    };
  });

const DROPDOWN_DATE_FORMAT_OPTIONS = Object.entries(Enums.DateFormat).map(
  ([key, value], i) => {
    return {
      key: `date-format-${key}-${i}`,
      text: DateTime.fromISO('2021-01-25T14:24:15.123').toFormat(value),
      value: value,
    };
  },
);

interface EndpointParamsAccordionProps {
  onChange: (params: Interfaces.RequestParams) => void;
  onChangePagination: (params?: Interfaces.PaginationParams) => void;
  paramsData?: Interfaces.RequestParams;
  paginationParamsData?: Interfaces.PaginationParams;
  dynamicFields?: Interfaces.SchemaField[];
}

const EndpointParamsAccordion: FC<EndpointParamsAccordionProps> = ({
  onChange,
  onChangePagination,
  paramsData = {},
  paginationParamsData,
  dynamicFields,
}) => {
  const dynamicFieldOptions = dynamicFields?.map((m) => {
    return {
      key: m.fieldId || m.field,
      text: `${m.field} value`,
      value: m.fieldId || m.field,
    };
  });

  const themeContext = useContext(ThemeContext);

  const [activeAccordionIndices, setActiveAccordionIndices] = useState<
    number[]
  >([]);

  // Add additional param row
  const handleAddRow = (paramType: ParamType) => {
    const cloned = cloneDeep(paramsData);

    if (!cloned[paramType]) {
      cloned[paramType] = [];
    }

    if (paramType === ParamType.PAGINATION) {
      onChangePagination({
        paginationType: Enums.PaginationType.PAGE_NUM,
        pageParam: {
          queryParams: [
            {
              field: '',
              type: [Enums.ValueDataType.NUMBER],
              constraints: {
                isRequired: true,
              },
            },
          ],
        },
      });
      return;
    } else {
      cloned[paramType]?.push({
        field: '',
        value: '',
        type: [Enums.ValueDataType.TEXT],
      });
    }

    onChange(cloned);
  };

  // Remove param row
  const handleRemoveRow = (paramType: ParamType, rowIndex: number) => {
    const cloned = cloneDeep(paramsData);
    cloned[paramType].splice(rowIndex, 1);

    onChange(cloned);
  };

  // Update param field
  const handleFieldChange = (
    rowIndex: number,
    field: string,
    value: unknown,
    paramType?: ParamType,
  ) => {
    const cloned = cloneDeep(paramsData);

    if (field === FORMAT_KEY) {
      if (!cloned[paramType][rowIndex][CONSTRAINTS_KEY]) {
        cloned[paramType][rowIndex][CONSTRAINTS_KEY] = {};
      }
      cloned[paramType][rowIndex][CONSTRAINTS_KEY][field] = value;
    } else if (field !== FIELD_KEY && field !== TYPE_KEY) {
      dynamicFieldOptions?.map((d) => d.key).includes(value as string)
        ? (cloned[paramType][rowIndex][field] = {
            type: Enums.DynamicConditionalType.FIELD,
            field: value,
          })
        : (cloned[paramType][rowIndex][field] = {
            type: Object.values(Enums.DynamicDateValue).includes(
              value as Enums.DynamicDateValue,
            )
              ? Enums.DynamicConditionalType.DATE
              : Enums.DynamicConditionalType.CONSTANT,
            value: value,
          });
    } else {
      delete cloned[paramType][rowIndex][CONSTRAINTS_KEY];
      // Clear value on type change
      if (field === TYPE_KEY) {
        cloned[paramType][rowIndex][VALUE_KEY] = {
          type: Enums.DynamicConditionalType.CONSTANT,
          value: '',
        };
      }
      cloned[paramType][rowIndex][field] = value;
    }

    onChange(cloned);
  };

  // Toggle dynamic field option
  const handleToggleFieldConditionalValue = (
    active: boolean,
    paramType: ParamType,
    rowIndex: number,
  ) => {
    const cloned = cloneDeep(paramsData);

    if (!active) {
      delete cloned[paramType][rowIndex].value.field;
    } else {
      cloned[paramType][rowIndex].value = {
        type: Enums.DynamicConditionalType.FIELD,
        field: dynamicFieldOptions?.[0].key,
      };
    }

    onChange(cloned);
  };

  return (
    <StyledAccordion
      style={{
        marginTop: themeContext.margin.xxlarge,
        backgroundColor: themeContext.colors.system.offWhite,
      }}
      fluid
    >
      {Object.values(ParamType).map((paramType: string, index) => {
        const accordionIndex = index + 1;
        const paramTypeText = PARAM_TEXT_MAP[paramType];

        return (
          <div key={`acc-${paramType}-${index}`}>
            <StyledAccordionTitle
              active={activeAccordionIndices.includes(accordionIndex)}
              index={accordionIndex}
              onClick={() => {
                if (activeAccordionIndices.includes(accordionIndex)) {
                  setActiveAccordionIndices((indices) =>
                    indices.filter((index) => index !== accordionIndex),
                  );
                } else {
                  setActiveAccordionIndices((indices) => [
                    ...indices,
                    accordionIndex,
                  ]);
                }
              }}
            >
              <StyledSubHeader>{paramTypeText} Parameters</StyledSubHeader>
              <FontAwesomeIcon
                icon={
                  activeAccordionIndices.includes(accordionIndex)
                    ? faChevronUp
                    : faChevronDown
                }
                color={defaultTheme.colors.system.offBlack}
              />
            </StyledAccordionTitle>
            <SC.StyledAccordionContent
              active={activeAccordionIndices.includes(accordionIndex)}
              style={{
                backgroundColor: themeContext.colors.system.white,
              }}
            >
              <SC.StyledInputColumn>
                <SC.InputContainer>
                  {paramType === ParamType.PAGINATION && (
                    <StyledText
                      style={{
                        marginBottom: themeContext.margin.xxlarge,
                      }}
                    >
                      If the data is paginated, tell us what field we can use to
                      retrieve the correct page.
                    </StyledText>
                  )}
                  {paramType !== ParamType.PAGINATION && (
                    <StyledText
                      style={{
                        marginBottom: themeContext.margin.xxlarge,
                      }}
                    >
                      If this endpoint requires additional{' '}
                      {paramTypeText.toLowerCase()} parameters, they can be
                      added here.
                    </StyledText>
                  )}

                  <FeatureButton
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.blue}
                    text={`Add ${paramTypeText} Parameter`}
                    action={() => handleAddRow(paramType as ParamType)}
                    style={{
                      marginBottom: themeContext.margin.large,
                    }}
                    isDisabled={
                      paramType === ParamType.PAGINATION &&
                      !!paginationParamsData
                    }
                  />
                  {paramType === ParamType.PAGINATION &&
                    paginationParamsData && (
                      <PaginationParams
                        onChange={onChangePagination}
                        paramsData={paginationParamsData}
                      />
                    )}

                  {(paramsData[paramType] || [])?.length > 0 &&
                    paramType !== ParamType.PAGINATION && (
                      <SC.InputRowContainer>
                        {paramsData[paramType]?.map((row, index) => {
                          const showDynamicField = !!(
                            row.value as Interfaces.FieldConditionalValue
                          )?.field;

                          return (
                            <SC.InputWrapper key={`inputs-${index}`}>
                              <SC.InputContainer>
                                <StyledBodySubHeader>
                                  Field Name
                                </StyledBodySubHeader>
                                <StyledInput
                                  value={row.field}
                                  placeholder={'Enter a field name'}
                                  onChange={(e, { value }) =>
                                    handleFieldChange(
                                      index,
                                      FIELD_KEY,
                                      value,
                                      paramType as ParamType,
                                    )
                                  }
                                />
                              </SC.InputContainer>

                              <SC.InputContainer>
                                <StyledBodySubHeader>
                                  Field Type
                                </StyledBodySubHeader>
                                <StyledDropdown
                                  selectOnBlur={false}
                                  placeholder={'Select a data type'}
                                  selection
                                  value={row.type.toString()}
                                  options={DROPDOWN_FIELD_TYPE_OPTIONS}
                                  onChange={(e, { value }) =>
                                    handleFieldChange(
                                      index,
                                      TYPE_KEY,
                                      [value],
                                      paramType as ParamType,
                                    )
                                  }
                                  style={{ margin: 0 }}
                                  upward={true}
                                />
                              </SC.InputContainer>

                              {row.type[0] === Enums.ValueDataType.DATE && (
                                <SC.InputContainer>
                                  <StyledBodySubHeader>
                                    Date Format
                                  </StyledBodySubHeader>
                                  <StyledDropdown
                                    selectOnBlur={false}
                                    placeholder={'Select a date format'}
                                    selection
                                    value={
                                      row[CONSTRAINTS_KEY] &&
                                      row[CONSTRAINTS_KEY][
                                        FORMAT_KEY
                                      ].toString()
                                    }
                                    options={DROPDOWN_DATE_FORMAT_OPTIONS}
                                    onChange={(e, { value }) =>
                                      handleFieldChange(
                                        index,
                                        FORMAT_KEY,
                                        value,
                                        paramType as ParamType,
                                      )
                                    }
                                    style={{ margin: 0 }}
                                    upward={true}
                                  />
                                </SC.InputContainer>
                              )}

                              <DynamicFieldInput
                                handleFieldChange={handleFieldChange}
                                showDynamicField={showDynamicField}
                                paramType={paramType}
                                index={index}
                                value={row.value}
                                customField={row}
                                dynamicFields={
                                  dynamicFields || [
                                    {
                                      field: '',
                                      type: [
                                        Enums.DynamicConditionalType.CONSTANT,
                                      ],
                                    },
                                  ]
                                }
                              />

                              {dynamicFields && (
                                <FeatureButton
                                  isDisabled={!dynamicFieldOptions?.length}
                                  isActive={showDynamicField}
                                  action={() =>
                                    handleToggleFieldConditionalValue(
                                      !showDynamicField,
                                      paramType as ParamType,
                                      index,
                                    )
                                  }
                                  size={36}
                                  height={34}
                                  color={themeContext.colors.general.purple}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faList}
                                      color={defaultTheme.colors.system.white}
                                    />
                                  }
                                  style={{
                                    marginRight: defaultTheme.margin.standard,
                                  }}
                                />
                              )}

                              <FeatureButton
                                action={() =>
                                  handleRemoveRow(paramType as ParamType, index)
                                }
                                size={34}
                                height={34}
                                color={themeContext.colors.general.red}
                                icon={
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={defaultTheme.colors.system.white}
                                  />
                                }
                              />
                            </SC.InputWrapper>
                          );
                        })}
                      </SC.InputRowContainer>
                    )}
                </SC.InputContainer>
              </SC.StyledInputColumn>
            </SC.StyledAccordionContent>
          </div>
        );
      })}
    </StyledAccordion>
  );
};

export default EndpointParamsAccordion;
