import styled from 'styled-components';

export const PageOuter = styled.div<{ sidebarVisible: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr) ${({ sidebarVisible }) =>
      sidebarVisible ? '260px' : 0};

  height: 100%;
`;

export const CMSHomeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export const PageInner = styled.div<{ showBanner?: boolean }>`
  width: 100%;
  ${({ showBanner }) =>
    showBanner
      ? `height: calc(100vh - 128px);`
      : `height: calc(100vh - 70px);`};

  overflow-y: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `${theme.padding.large} ${theme.padding.xlarge}`};
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xlarge}`};

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > p {
    margin: 0;
  }

  > div {
    display: flex;
    > div {
      margin-left: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const DatasetActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > div:first-of-type {
    display: flex;
    align-items: center;

    > p {
      margin: 0 ${({ theme }) => theme.margin.standard} 0 0;
    }
  }
`;

export const DatasetActionsButtons = styled.div`
  display: flex;

  > div {
    margin-left: ${({ theme }) => theme.margin.standard};
  }
`;
