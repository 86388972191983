import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NotificationAlertService, {
  NotificationAlertItemOutput,
  NotificationAlertItemsOutput,
} from '../../services/notification-alert/NotificationAlertService';
import { AppThunk } from '../store';

export interface NotificationsAlertsState {
  data: {
    data: NotificationAlertItemOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  loading: boolean;
  error: string | null;
}

const initialState: NotificationsAlertsState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const NotificationsAlertsSlice = createSlice({
  name: 'notification-alerts',
  initialState,
  reducers: {
    // Fetch
    fetchNotificationAlertsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchNotificationAlertsSuccess(
      state,
      action: PayloadAction<NotificationAlertItemsOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchNotificationAlertsFailure(state, action: PayloadAction<string>) {
      state.data = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchNotificationAlertsStart,
  fetchNotificationAlertsSuccess,
  fetchNotificationAlertsFailure,
} = NotificationsAlertsSlice.actions;

export default NotificationsAlertsSlice.reducer;

export const fetchNotificationAlerts =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchNotificationAlertsStart());
      const fetched = await NotificationAlertService.getNotificationAlerts(
        token,
        query,
      );
      dispatch(
        fetchNotificationAlertsSuccess(
          fetched.data as unknown as NotificationAlertItemsOutput,
        ),
      );
    } catch (err) {
      dispatch(fetchNotificationAlertsFailure((err as string).toString()));
    }
  };
