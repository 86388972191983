import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { isEmpty, startCase } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../enums';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../hooks/project/UseProject';
import useQuery from '../../../hooks/query/UseQuery';
import { SampleDataRow } from '../../../interfaces/SampleData';
import { StageBodyText, StageInner, StageWrapper } from '../../../main/theme';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectAggregationSubStage } from '../../../store/project-stage/initial-state';
import { fetchQueriesSuccess } from '../../../store/queries';
import { fetchQuerySuccess, resetQuery } from '../../../store/query';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

const PAGE_SIZE = 25;
const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const DESCRIPTION_FIELD = 'Description';
const TYPE_FIELD = 'Query Type';
const ORDER_BY_PARAM = 'orderBy';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: TYPE_FIELD },
];

enum SortTypes {
  'Name' = 'name',
  'Description' = 'description',
  'Query Type' = 'queryParams.aggregationType',
}

const PREV_STAGE = Enums.ProjectStage.CREATION;

const AggregationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const ProjectAggregationStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, projectAccessLevel } = useProject();
  const { getDatasetMetas } = useDatasetMeta();
  const { getQueries } = useQuery();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [datasetMetasLoaded, setDatasetMetasLoaded] = useState<boolean>(false);
  const [queriesLoaded, setQueriesLoaded] = useState<boolean>(false);
  const [pagination, setPagination] = useState(0);
  const [sortBy, setSortBy] = useState<Record<string, number>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas = datasetMetasState?.data;

  const queriesState = useSelector((state: RootState) => state.queries);
  const queries = queriesState?.data;

  const updateSortBy = useCallback(
    (sortItem: { id: string; desc: boolean }) => {
      if (isEmpty(sortItem)) {
        setSortBy(undefined);
        return;
      }

      const sortProperty = SortTypes[sortItem.id];

      switch (sortItem.desc) {
        case true:
          setSortBy({ [sortProperty]: -1 });
          break;
        case false:
          setSortBy({ [sortProperty]: 1 });
          break;
      }
    },
    [],
  );

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  const calculateRowNumber = useCallback(
    (rowIndex) => {
      const displayedRowIndex = rowIndex + 1;
      const initialRowIndex = (pagination - 1) * PAGE_SIZE;

      return (displayedRowIndex + initialRowIndex).toString();
    },
    [pagination],
  );

  // Get datasetMetas
  useEffect(() => {
    if (project?.datasetMetaId?.length) {
      (async () => {
        await getDatasetMetas({ _id: { $in: project.datasetMetaId } });
        setDatasetMetasLoaded(true);
      })();
    }
  }, [dispatch, getDatasetMetas, project?.datasetMetaId]);

  // Get queries
  useEffect(() => {
    if (project?._id) {
      if (project?.queries?.length) {
        (async () => {
          const queryParams = {
            projectOriginId: project?._id,
            pageNum: pagination,
            limit: PAGE_SIZE,
          };

          if (!isEmpty(sortBy)) {
            queryParams[ORDER_BY_PARAM] = sortBy;
          }

          await getQueries(queryParams);
          setQueriesLoaded(true);
        })();
      } else {
        dispatch(
          fetchQueriesSuccess({
            data: [],
            pagination: {
              currentPageNum: 1,
              prevPageNum: null,
              nextPageNum: null,
              totalCount: 0,
              totalPages: 1,
            },
          }),
        );
        setQueriesLoaded(true);
      }
    }
  }, [
    dispatch,
    getQueries,
    pagination,
    project?._id,
    project?.queries,
    sortBy,
  ]);

  // Build table data
  useEffect(() => {
    if (project?._id && queries) {
      const tableData: SampleDataRow[] = [];

      queries?.data.map((agg, index) => {
        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(index) },
          [ID_FIELD]: { value: (agg.entity as Interfaces.QueryOutput)._id },
          [NAME_FIELD]: { value: agg.entity.name },
          [DESCRIPTION_FIELD]: { value: agg.entity.description || '' },
          [TYPE_FIELD]: {
            value: `${startCase(
              agg.entity.queryParams.aggregationType,
            )} Dataset`,
          },
        });
      });

      setTableData(tableData);
    }
  }, [calculateRowNumber, datasetMetas, project?._id, queries]);

  useEffect(() => {
    if (datasetMetasLoaded && queriesLoaded) {
      setIsLoading(false);
    }
  }, [datasetMetasLoaded, queriesLoaded]);

  return (
    <StageWrapper>
      <StageInner>
        <Banner
          message={
            <p>
              Queries have been updated to be even easier to build and can now
              be found{' '}
              <a href={'https://portal.goconfigur.com/queries/view'}>here</a>.
            </p>
          }
          alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
          alertStatus={AlertStatus.INFO}
        />

        <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
          Query your datasets to create subsets and aggregations to unlock even
          more from your data.
        </StageBodyText>

        <AggregationWrapper>
          {/*Deprecated*/}
          {/*{projectAccessLevel === Enums.AccessLevel.MANAGE && (*/}
          {/*  <FeatureButton*/}
          {/*    action={() => {*/}
          {/*      dispatch(resetQuery());*/}
          {/*      dispatch(*/}
          {/*        updateActiveProjectStage(Enums.ProjectStage.AGGREGATION),*/}
          {/*      );*/}
          {/*      dispatch(*/}
          {/*        updateActiveProjectSubStage(ProjectAggregationSubStage.NAME),*/}
          {/*      );*/}
          {/*    }}*/}
          {/*    size={FeatureButtonSize.WIDE}*/}
          {/*    color={themeContext.colors.general.blue}*/}
          {/*    text={'Add Query'}*/}
          {/*    containerStyle={{ marginBottom: themeContext.margin.large }}*/}
          {/*  />*/}
          {/*)}*/}

          {projectAccessLevel !== Enums.AccessLevel.MANAGE &&
            !tableData.length &&
            !isLoading && (
              <StageBodyText>
                <strong>No Queries found</strong>
              </StageBodyText>
            )}

          {(tableData.length > 0 || isLoading) && (
            <TableWrapper>
              <DataSample
                showPagination={true}
                paginationAction={updatePagination}
                sortAction={updateSortBy}
                pageSize={PAGE_SIZE}
                totalRows={queries?.pagination?.totalCount}
                hideSortOnCols={[ROW_NUMBER]}
                fullWidth={true}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                // Deprecated
                // clickableRows={{
                //   valueField: ID_FIELD,
                //   action: (aggregationId: string) => {
                //     const agg = queries?.data.find(
                //       (a) =>
                //         (a.entity as Interfaces.QueryOutput)._id ===
                //         aggregationId,
                //     );
                //
                //     if (agg) {
                //       dispatch(fetchQuerySuccess(agg));
                //     }
                //
                //     dispatch(
                //       updateActiveProjectStage(Enums.ProjectStage.AGGREGATION),
                //     );
                //     dispatch(
                //       updateActiveProjectSubStage(
                //         ProjectAggregationSubStage.AGGREGATE,
                //       ),
                //     );
                //   },
                // }}
                loading={isLoading}
              />
            </TableWrapper>
          )}
        </AggregationWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectAggregationStage;
