import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LOCAL_STORAGE_TEMP_FILTERS_KEY } from '../../components/Modal/graph/GraphModal';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { updateFilters } from '../../store/filters';
import { FILTER_SUFFIX } from '../../store/filters/initial-state';
import { RootState } from '../../store/rootReducer';

export interface fields {
  field: string;
  datasetMetaId?: string;
  alias?: string;
  isMeasure?: boolean;
}

export interface DynamicConditionalField {
  active?: boolean;

  fields?: [fields];

  field?: string;
  datasetMetaId?: string;
  alias?: string;
  isMeasure?: boolean;

  operator: Enums.AggregationFilterOperator;
  value: unknown;
  name?: string;
}

interface useFilterResult {
  getDatasetFilters: (
    datasetMetaId: string,
    filterActive?: boolean,
  ) => DynamicConditionalField[];
  deleteDatasetFilters: (
    datasetMetaId: string,
    ignoreLocalStorage?: boolean,
  ) => void;
  replaceDatasetFilters: (
    datasetMetaId: string,
    filter: DynamicConditionalField | DynamicConditionalField[],
  ) => void;
  createFilterGroup: (
    datasetMetaId: string,
    filter: DynamicConditionalField | DynamicConditionalField[],
    ignoreLocalStorage?: boolean,
  ) => void;
  updateFilterGroup: (
    datasetMetaId: string,
    filter: DynamicConditionalField | DynamicConditionalField[],
    filterIndex?: number,
    ignoreLocalStorage?: boolean,
  ) => void;
  deleteFilterGroup: (
    datasetMetaId: string,
    filterIndex: number,
    ignoreLocalStorage?: boolean,
  ) => void;

  // Additional Mappers
  conditionalFieldToFilterFieldMap: (
    filter: DynamicConditionalField,
    multiple?: boolean,
  ) => Interfaces.FilterField;
  filterFieldToConditionalFieldMap: (
    filter: Interfaces.StandardFilterField & { datasetMetaId?: string },
  ) => DynamicConditionalField;
  stripMeasureFieldDetails: (ConditionalField) => DynamicConditionalField;
  checkForDeletedColumnsInFilter: (
    datasetMetaId: string,
    schemaFieldIds: string[],
  ) => DynamicConditionalField[] | undefined;
}

const useFilter = (): useFilterResult => {
  const dispatch = useDispatch();
  const history = useHistory();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [filters, setFilters] = useState<
    Record<string, DynamicConditionalField[]> | []
  >();

  const [filterParams, setFilterParams] = useState<string>();

  const filtersState = useSelector((state: RootState) => state.filters);

  const filterByActive = (
    filters: DynamicConditionalField[],
  ): DynamicConditionalField[] =>
    filters.reduce((acc: DynamicConditionalField[], filter) => {
      if ((filter.value as DynamicConditionalField[]).length) {
        const active = (filter.value as DynamicConditionalField[]).filter(
          (f) => f.active,
        );
        if (active.length) {
          return [...acc, { ...filter, value: active }];
        }
      }
      return acc;
    }, []) || [];

  const getDatasetFilters = useCallback(
    (datasetMetaId: string, filterActive?: boolean) => {
      if (!datasetMetaId) {
        return;
      }

      return filterParams
        ? JSON.parse(filterParams)
        : filterActive
        ? filterByActive(filters?.[datasetMetaId] || [])
        : filters?.[datasetMetaId] || [];
    },
    [filters, filterParams],
  );

  const deleteDatasetFilters = useCallback(
    (datasetMetaId: string, ignoreLocalStorage?: boolean) => {
      if (!datasetMetaId) {
        return;
      }

      if (!ignoreLocalStorage) {
        localStorage.removeItem(`${datasetMetaId}${FILTER_SUFFIX}`);
      }

      dispatch(
        updateFilters({
          datasetMetaId,
          filters: [],
        }),
      );
    },
    [dispatch],
  );

  const createFilterGroup = (
    datasetMetaId: string,
    filter: DynamicConditionalField | DynamicConditionalField[],
    ignoreLocalStorage?: boolean,
  ) => {
    if (!datasetMetaId) {
      return;
    }

    const cloned = cloneDeep(filters);

    if (!cloned[datasetMetaId]?.length) {
      cloned[datasetMetaId] = [
        {
          operator: Enums.AggregationFilterOperator.AND,
          value: [],
        },
      ];
    }

    cloned[datasetMetaId][0].value.push(filter);

    if (!ignoreLocalStorage) {
      localStorage.setItem(
        `${datasetMetaId}${FILTER_SUFFIX}`,
        JSON.stringify(cloned[datasetMetaId]),
      );
    }

    dispatch(
      updateFilters({
        datasetMetaId: datasetMetaId,
        filters: cloned[datasetMetaId],
      }),
    );
  };

  const updateFilterGroup = (
    datasetMetaId: string,
    filter: DynamicConditionalField | DynamicConditionalField[],
    filterIndex?: number,
    ignoreLocalStorage?: boolean,
  ) => {
    if (!datasetMetaId) {
      return;
    }

    if (!filterParams) {
      const cloned = cloneDeep(filters);

      if (typeof filterIndex !== 'undefined') {
        cloned[datasetMetaId][0].value[filterIndex] = filter;
      } else {
        cloned[datasetMetaId] = filter;
      }

      if (!ignoreLocalStorage) {
        localStorage.setItem(
          `${datasetMetaId}${FILTER_SUFFIX}`,
          JSON.stringify(cloned[datasetMetaId]),
        );
      }

      dispatch(
        updateFilters({
          datasetMetaId: datasetMetaId,
          filters: cloned[datasetMetaId],
        }),
      );
    } else {
      const tempFilter = cloneDeep(JSON.parse(filterParams));
      tempFilter[0].value[filterIndex] = filter;

      history.push({
        pathname: history.location.pathname,
        state: {
          filters: JSON.stringify(tempFilter),
        },
      });
    }
  };

  const deleteFilterGroup = (
    datasetMetaId: string,
    filterIndex: number,
    ignoreLocalStorage?: boolean,
  ) => {
    if (!datasetMetaId) {
      return;
    }

    if (!filterParams) {
      const cloned = cloneDeep(filters);

      cloned[datasetMetaId][0].value.splice(filterIndex);

      // Delete parent if no values
      if (!cloned[datasetMetaId][0].value.length) {
        cloned[datasetMetaId] = [];
        if (!ignoreLocalStorage) {
          localStorage.removeItem(`${datasetMetaId}${FILTER_SUFFIX}`);
        }
      } else if (!ignoreLocalStorage) {
        localStorage.setItem(
          `${datasetMetaId}${FILTER_SUFFIX}`,
          JSON.stringify(cloned[datasetMetaId]),
        );
      }

      dispatch(
        updateFilters({
          datasetMetaId: datasetMetaId,
          filters: cloned[datasetMetaId],
        }),
      );
    } else {
      history.push({
        pathname: history.location.pathname,
      });
    }
  };

  const replaceDatasetFilters = useCallback(
    (
      datasetMetaId: string,
      filters: DynamicConditionalField | DynamicConditionalField[],
    ) => {
      if (!datasetMetaId) {
        return;
      }

      const cloned = cloneDeep(filters);

      cloned[datasetMetaId] = Array.isArray(filters)
        ? filters
        : [
            {
              operator: Enums.AggregationFilterOperator.AND,
              value: [filters],
            },
          ];

      dispatch(
        updateFilters({
          datasetMetaId: datasetMetaId,
          filters: cloned[datasetMetaId],
        }),
      );
    },
    [dispatch],
  );

  const conditionalFieldToFilterFieldMap = useCallback(
    (filter: DynamicConditionalField, multiple?: boolean) => {
      const { field, datasetMetaId, alias, operator, value, isMeasure } =
        filter;
      const item = {
        operator,
        value:
          Array.isArray(value) && value[0]?.operator
            ? value.map((v) => conditionalFieldToFilterFieldMap(v, multiple))
            : value,
      };

      return {
        ...item,
        field: !isMeasure ? field : undefined,
        datasetMetaId,
        alias,
        isMeasure,
      };
    },
    [],
  );

  const filterFieldToConditionalFieldMap = useCallback(
    (
      filter: Interfaces.StandardFilterField & {
        datasetMetaId?: string;
        isMeasure?: boolean;
      },
    ) => {
      const dsm = datasetMetas.find(
        (d) => d.entity._id === filter.datasetMetaId,
      );

      return {
        active: true,

        field: filter.field,
        datasetMetaId: dsm?.entity._id,
        alias: filter.alias,
        isMeasure: filter.isMeasure,

        operator: filter.operator,
        value:
          Array.isArray(filter.value) && filter.value[0]?.operator
            ? filter.value.map((v) => filterFieldToConditionalFieldMap(v))
            : filter.value,
      };
    },
    [datasetMetas],
  );

  const stripMeasureFieldDetails = useCallback(
    (filter: DynamicConditionalField) => {
      return {
        ...filter,
        field: filter?.isMeasure ? undefined : filter.field,
        datasetMetaId: filter?.isMeasure ? undefined : filter?.datasetMetaId,
        value:
          Array.isArray(filter.value) && filter.value[0]?.operator
            ? filter.value.map((v) => stripMeasureFieldDetails(v))
            : filter.value,
      };
    },
    [],
  );

  const checkForLegacyFilter = useCallback(
    (filterValue: DynamicConditionalField & { column?: unknown }): boolean => {
      if (Array.isArray(filterValue.value)) {
        return filterValue.value.some((fVal) => checkForLegacyFilter(fVal));
      }

      return !!filterValue.column;
    },
    [],
  );

  const removeLegacyFilters = useCallback(
    (filtersState: Record<string, DynamicConditionalField[]>) => {
      const legacyFilterIds = Object.entries(filtersState).reduce(
        (acc: string[], [filterId, filter]) => {
          if (
            filter &&
            Array.isArray(filter) &&
            filter.some((f) => checkForLegacyFilter(f))
          ) {
            return [...acc, filterId];
          }
          return acc;
        },
        [],
      );

      if (legacyFilterIds.length) {
        legacyFilterIds.map((id) => deleteDatasetFilters(id));
      }
    },
    [checkForLegacyFilter, deleteDatasetFilters],
  );

  const checkForDeletedColumnsInFilter = (
    datasetMetaId: string,
    schemaFieldIds: string[],
  ) => {
    const filters = getDatasetFilters(datasetMetaId);

    const cleanFilters = (filters) =>
      filters
        ?.map((filter) => {
          if (filter.value && Array.isArray(filter.value)) {
            const updatedValue = cleanFilters(filter.value);
            return !updatedValue.length
              ? null
              : { ...filter, value: updatedValue };
          } else {
            return !schemaFieldIds.includes(filter.field) ? null : filter;
          }
        })
        .filter(Boolean);

    const cleansedFilters = cleanFilters(filters);

    if (!isEqual(filters, cleansedFilters)) {
      return cleansedFilters;
    }
  };

  useEffect(() => {
    if (filtersState) {
      // Remove legacy filters
      removeLegacyFilters(filtersState.data);

      // Complete model
      setFilters(filtersState.data);
    }
  }, [filtersState, removeLegacyFilters]);

  // Store filter params if they exist
  useEffect(() => {
    const localStorageTempFilters = localStorage.getItem(
      LOCAL_STORAGE_TEMP_FILTERS_KEY,
    );

    if (localStorageTempFilters && !history.location.state) {
      history.push({
        pathname: history.location.pathname,
        state: JSON.parse(localStorageTempFilters),
      });
    }

    setFilterParams(history.location.state?.filters);
  }, [history, history.location.state?.filters]);

  return {
    getDatasetFilters,
    deleteDatasetFilters,
    replaceDatasetFilters,
    createFilterGroup,
    updateFilterGroup,
    deleteFilterGroup,

    conditionalFieldToFilterFieldMap,
    filterFieldToConditionalFieldMap,
    stripMeasureFieldDetails,
    checkForDeletedColumnsInFilter,
  };
};

export default useFilter;
