import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import InitialFilters, { FILTER_SUFFIX } from './initial-state';

const FiltersSlice = createSlice({
  name: 'filters',
  initialState: InitialFilters,
  reducers: {
    updateFilters(
      state,
      action: PayloadAction<{
        datasetMetaId: string;
        filters: DynamicConditionalField[];
      }>,
    ) {
      state.data[action.payload.datasetMetaId] = action.payload.filters;
    },

    resetFilters(state) {
      const getStoredFilters = Object.fromEntries(
        Object.entries({ ...localStorage })
          .filter(([key]) => key.includes(FILTER_SUFFIX))
          .map(([key, val]) => [
            key.replace(FILTER_SUFFIX, ''),
            JSON.parse(val),
          ]),
      );

      state.data = getStoredFilters || {};
    },
  },
});

export const { updateFilters, resetFilters } = FiltersSlice.actions;

export default FiltersSlice.reducer;
