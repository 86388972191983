import FileService, {
  GetFileUploadUrlResponse,
} from '../../services/file/FileService';

/**
 * Gets a signed upload URL from S3
 *
 * @param {string} token - Auth0 Token
 * @param {string} fileName - Name of the file
 * @param {string} mimeType - Mime type of the file
 * @param {string} organisationId - Organisation ID of the uploader
 * @param {string} userId - User ID of the uploader
 *
 * returns {GetFileUploadUrlResponse} - Signed upload URL and ID
 */
const getUploadUrl = async (
  token: string,
  fileName: string,
  mimeType: string,
  organisationId: string,
  userId: string,
): Promise<GetFileUploadUrlResponse> => {
  const uploadUrlResponse = await FileService.getFileUploadUrl(
    token,
    fileName,
    mimeType,
    organisationId,
    userId,
  );

  if (!uploadUrlResponse?.data) {
    throw Error('An upload URL could not be obtained');
  }

  return uploadUrlResponse?.data as GetFileUploadUrlResponse;
};

export default getUploadUrl;
