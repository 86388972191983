import { Interfaces } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import { SampleData } from '../../interfaces/SampleData';
import buildTableData from '../../util/build-table-data/BuildTableData';

interface useTableDataResponse {
  createTableData: (
    schema: Interfaces.SchemaField[] | Interfaces.Field[],
    sample: Record<string, unknown>[],
    corrected?: Interfaces.RowValidationResult[],
  ) => SampleData;
}

const useTableData = (): useTableDataResponse => {
  const createTableData = useCallback(
    (
      schema: Interfaces.SchemaField[] | Interfaces.Field[] = [],
      sample: Record<string, unknown>[] = [],
      corrected?: Interfaces.RowValidationResult[],
    ): SampleData => {
      // Set corrected values if provided
      if (corrected) {
        corrected.forEach((c) => {
          // Rows start at 1
          if (sample[c.rowId - 1]) {
            c.fields.forEach((f) => {
              sample[c.rowId - 1][f.fieldName] = f.updatedValue as string;
            });
          }
        });
      }

      return buildTableData(schema, sample);
    },
    [],
  );

  return {
    createTableData,
  };
};

export default useTableData;
