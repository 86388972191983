import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemCMSNameStage from '../../components/ProjectItem/ProjectItemCmsStages/1-name/ProjectItemCMSNameStage';
import ProjectItemCMSDataStage from '../../components/ProjectItem/ProjectItemCmsStages/2-data/ProjectItemCMSDataStage';
import ProjectItemCMSAccessStage from '../../components/ProjectItem/ProjectItemCmsStages/3-access/ProjectItemCMSAccessStage';
import ProjectItemCMSConfigurationStage from '../../components/ProjectItem/ProjectItemCmsStages/4-configuration/ProjectItemCMSConfigurationStage';
import ProjectItemCMSStage from '../../components/ProjectItem/ProjectItemCmsStages/ProjectItemCMSStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import { ProjectConnectionCMSSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectCMS: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const activeStage = Enums.ConnectionType.CMS;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectConnectionCMSSubStage.NAME:
        return <ProjectItemCMSNameStage />;
      case ProjectConnectionCMSSubStage.DATA:
        return <ProjectItemCMSDataStage />;
      case ProjectConnectionCMSSubStage.ACCESS:
        return <ProjectItemCMSAccessStage />;
      case ProjectConnectionCMSSubStage.CONFIGURATION:
        return <ProjectItemCMSConfigurationStage />;
      default:
        return <ProjectItemCMSNameStage />;
    }
  };

  const stageContentTitle = stageContent.title;

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={
          activeSubStage &&
          activeSubStage !== ProjectConnectionCMSSubStage.CONFIGURATION
            ? {
                activeSubStage: activeSubStage,
                subStageItems: projectStageState.stages[
                  activeStage
                ].subStages.filter(
                  (stage) =>
                    stage.subStage !==
                    ProjectConnectionCMSSubStage.CONFIGURATION,
                ) as SubStageItem[],
              }
            : undefined
        }
        image={stageContent.image}
        title={stageContentTitle}
      />

      <Page>
        {activeSubStage ? getSubStageComponent() : <ProjectItemCMSStage />}
      </Page>
    </>
  );
};

export default ProjectCMS;
