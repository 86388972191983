import React, { FC, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

interface RollbackConfirmationProps {
  entityType: EntityType;
  entityName?: string;
  cancelAction: () => void;
  rollbackAction: () => void;
}

const CONFIRMATION_VALUE = 'confirm';

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const RollbackConfirmation: FC<RollbackConfirmationProps> = ({
  entityType,
  entityName,
  cancelAction,
  rollbackAction,
}) => {
  const themeContext = useContext(ThemeContext);

  const [confirmationValue, setConfirmationValue] = useState<string>();

  return (
    <>
      <StyledText>
        Are you sure you wish to rollback the dataset to{' '}
        {entityName ? (
          <strong>{entityName}</strong>
        ) : (
          <>
            this <strong>{entityType}</strong>
          </>
        )}
        ?
      </StyledText>
      <StyledText>
        To confirm this,{' '}
        {entityName ? (
          <>enter the {entityType}'s name</>
        ) : (
          <strong>{CONFIRMATION_VALUE}</strong>
        )}{' '}
        in the box below.
      </StyledText>

      <StyledBodySubHeader>Confirm Rollback</StyledBodySubHeader>

      <StyledInput
        placeholder={
          entityName
            ? `Enter the ${entityType}'s name`
            : `Enter '${CONFIRMATION_VALUE}'`
        }
        value={confirmationValue || ''}
        onChange={(e, data) => setConfirmationValue(data.value)}
      />

      <ActionButtonWrapper>
        <FeatureButton
          action={cancelAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={
            entityName
              ? confirmationValue !== entityName
              : confirmationValue !== CONFIRMATION_VALUE
          }
          action={rollbackAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.blue}
          text={`Rollback to ${entityType}`}
        />
      </ActionButtonWrapper>
    </>
  );
};

export default RollbackConfirmation;
