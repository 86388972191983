import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled, { ThemeContext } from 'styled-components';
import usePortal from '../../../../hooks/portal/UsePortal';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { fetchPortalSuccess } from '../../../../store/project-portal';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectConnectionPortalSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectConnectionPortalSubStage.CONNECTIONS;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemPortalNameStage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { portal, portalAccessLevel, getPortal } = usePortal();

  const [portalName, setPortalName] = useState<string>('');
  const [portalDesc, setPortalDesc] = useState<string>('');

  useEffect(() => {
    if (params.portalId) {
      (async () => {
        await getPortal(params.portalId);
      })();
    }
  }, [getPortal, params.portalId]);

  useEffect(() => {
    if (portal?.name) {
      setPortalName(portal.name);
    }
    if (portal?.description) {
      setPortalDesc(portal.description);
    }
  }, [portal]);

  const processAction = () => {
    const cloned = cloneDeep(portal);
    cloned[NAME_FIELD] = portalName;
    cloned[DESCRIPTION_FIELD] = portalDesc;

    dispatch(
      fetchPortalSuccess({
        accessLevel: portalAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
    dispatch(updateActiveProjectSubStage(NEXT_STAGE));
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Portal Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new portal.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your portal name'}
            value={portalName}
            onChange={(_e, data) => setPortalName(data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this portal?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={portalDesc}
            onChange={(event) => setPortalDesc(event.target.value)}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!portalName.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Connections'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemPortalNameStage;
