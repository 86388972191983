import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../assets/icons/loading/default-loading-icon.gif';
import { EntityType, RouteName } from '../../../enums';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useDataset from '../../../hooks/dataset/UseDataset';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import { StyledSubHeader } from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import buildTableData from '../../../util/build-table-data/BuildTableData';
import AvatarIconMap from '../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';
import { QueryFiltersForDataset } from '../../../util/query-params-to-filter/QueryParamsToFilter';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { Loader } from '../../styled';
import * as SC from './styled';
import useList from '../../../hooks/list/UseList';

export interface CMSQueryLinkedDatasetsModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cmsId: string;
  portalId?: string;
  queryFilters: QueryFiltersForDataset[];
}

const CMSQueryLinkedDatasetsModal: FC<CMSQueryLinkedDatasetsModalProps> = ({
  setShowModal,
  cmsId,
  portalId,
  queryFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { project } = useProject();
  const { portal } = usePortal();
  const { datasetMeta, getDatasetMetas } = useDatasetMeta();
  const { getDatasetDirect } = useDataset();
  const { cms } = useCMS();
  const { getListsForMultipleResources } = useList();

  const [fetchingLinkedData, setFetchingLinkedData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [loadingDatasets, setLoadingDatasets] = useState<boolean>(true);
  const [loadedLinkedLists, setLoadedLinkedLists] = useState<boolean>(false);

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [linkedDatasets, setLinkedDatasets] = useState<
    Record<string, Record<string, unknown>[]>
  >({});

  // Load any linked DatasetMetas and Lists
  useEffect(() => {
    (async () => {
      const linkedDatasetIds = Array.from(Object.keys(linkedDatasets));

      const loadedDsmIds = datasetMetas.map((dsm) => dsm.entity._id);
      const linkedLoadingComplete = linkedDatasetIds?.every((id) =>
        loadedDsmIds.includes(id),
      );

      if (linkedDatasetIds) {
        if (!linkedLoadingComplete) {
          await getDatasetMetas(
            {
              _id: { $in: [...(linkedDatasetIds || [])] },
            },
            undefined,
            undefined,
            undefined,
            undefined,
            portalId,
          );
        }
        if (!loadedLinkedLists) {
          await getListsForMultipleResources(
            Enums.SchemaName.DATASET_META,
            linkedDatasetIds,
          );

          setLoadedLinkedLists(true);
        }
      }

      setLoadingDatasets(false);
    })();
  }, [
    datasetMetas,
    getDatasetMetas,
    getListsForMultipleResources,
    linkedDatasets,
    loadedLinkedLists,
    portalId,
  ]);

  // Check data model for linked datasets
  useEffect(() => {
    if (
      !fetchingLinkedData &&
      (portalId ? portal?.modellingItems?.length : project?.dataModel.length) &&
      cmsId &&
      !linkedDatasets.length &&
      datasetMeta
    ) {
      const clonedLinked = cloneDeep(linkedDatasets);

      (async () => {
        setFetchingLinkedData(true);

        const datasetQueries: {
          dataset: string;
          query: DynamicConditionalField[];
        }[] = [];

        queryFilters.map((filter) => {
          datasetQueries.push({
            dataset: filter.datasetMetaId,
            query: filter.filters,
          });
        });

        await Promise.all(
          datasetQueries.map(async (dq) => {
            try {
              const fetched = await getDatasetDirect(
                dq.dataset,
                dq.query,
                1,
                cmsId,
                portalId,
              );

              clonedLinked[dq.dataset] = fetched.entries?.length
                ? fetched.entries
                : [];
            } catch (err) {
              clonedLinked[dq.dataset] = [];
            }
          }),
        );

        setLinkedDatasets(clonedLinked);
        setLoading(false);
      })();
    }
  }, [
    cmsId,
    datasetMeta,
    fetchingLinkedData,
    getDatasetDirect,
    linkedDatasets,
    portal?.modellingItems?.length,
    portalId,
    project?.dataModel.length,
    queryFilters,
  ]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.LinkedWrapper>
      <SC.HeaderContainer>
        <SC.Header>Query Linked Datasets</SC.Header>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={themeContext.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>

      <SC.ContentContainer>
        <SC.Content>
          {(loading || loadingDatasets) && (
            <Loader src={DefaultLoadingIcon} alt={'Loading'} />
          )}
          {!loading &&
            !loadingDatasets &&
            Object.entries(linkedDatasets).length === 0 && (
              <SC.NoResultsText>No linked data found r</SC.NoResultsText>
            )}

          {!loading &&
            !loadingDatasets &&
            Object.entries(linkedDatasets).map(([dsmId, re], i) => {
              const dsm: Interfaces.DatasetMetaOutput | undefined =
                datasetMetas.find((d) => d.entity._id === dsmId)?.entity;

              if (!dsm) {
                return;
              }

              const schema = dsm?.dataCollections.find(
                (c) => c._id === dsm.activeDataCollection,
              )?.schemaData;

              if (schema) {
                const tableData = buildTableData(schema, re);

                if (tableData.schema) {
                  return (
                    <SC.LinkedDataset key={`linked-dataset-${dsm.name}-${i}`}>
                      <SC.LinkedDatasetHeader>
                        <StyledSubHeader>{dsm.name}</StyledSubHeader>

                        {cmsId && (
                          <FeatureButton
                            action={() => {
                              dispatch(hideModal());

                              // Find relevant group
                              const group = (
                                cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
                              ).groups.find((group) =>
                                group.items.find(
                                  (item) => item.datasetMetaId === dsm._id,
                                ),
                              );

                              history.push({
                                pathname: portalId
                                  ? `${RouteName.PORTAL}/${portal?._id}/cms/${cmsId}/${group?._id}/dataset/${dsm._id}`
                                  : `${RouteName.PROJECT_ITEM}/${project?._id}/cms/${cmsId}/${group?._id}/dataset/${dsm._id}`,
                                state: {
                                  filters: JSON.stringify(
                                    queryFilters.find(
                                      (f) => f.datasetMetaId === dsm._id,
                                    )?.filters,
                                  ),
                                  filterType: EntityType.AGGREGATION,
                                },
                              });
                            }}
                            size={FeatureButtonSize.WIDE_SMALL}
                            color={themeContext.colors.general.blue}
                            text={'View Dataset'}
                          />
                        )}
                      </SC.LinkedDatasetHeader>

                      <DataSample
                        schema={tableData.schema}
                        showPagination={false}
                        sampleColumns={schema}
                        sampleRows={tableData.rows || []}
                        iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                      />
                    </SC.LinkedDataset>
                  );
                }
              }
            })}
        </SC.Content>
      </SC.ContentContainer>
    </SC.LinkedWrapper>
  );
};

export default CMSQueryLinkedDatasetsModal;
