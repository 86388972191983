import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../../../enums';
import { EntityType } from '../../../../enums/EntityType';
import useChart from '../../../../hooks/chart/UseChart';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { showModal } from '../../../../store/modal';
import { updateActiveProjectStage } from '../../../../store/project-stage';
import ActionBar from '../../../ActionBar/ActionBar';
import AdvancedSetting from '../../../AdvancedSetting/AdvancedSetting';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';

const ProjectItemChartConfigurationStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { chart } = useChart();

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          These settings should only be changed if you're absolutely positive on
          what they do.
        </StageBodyText>
        <StageBodyText>
          Some of these operations are <strong>irreversible</strong>.
        </StageBodyText>

        <AdvancedSetting
          action={() =>
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.DELETION,
                additionalProps: {
                  entityType: EntityType.CHART,
                  entityId: chart?._id,
                  entityName: chart?.name,
                },
              }),
            )
          }
          headerText={'Delete Dashboard'}
          subHeaderText={`Delete your dashboard completely.`}
          bodyText={`Be absolutely sure you wish to delete this dashboard before
          proceeding.`}
          actionButtonText={'Delete Dashboard'}
          actionButtonColor={themeContext.colors.general.red}
        />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(ProjectStage.CREATION));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemChartConfigurationStage;
