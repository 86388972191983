import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from '../ProjectActions/styled';

interface ProjectActionProps {
  entityId: string;
}

const ProjectActions: FC<ProjectActionProps> = ({ entityId }) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  return (
    <SC.ProjectIconWrapper>
      <FeatureButton
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.green}
        action={() => {
          history.push(`${RouteName.PROJECT_ITEM}/${entityId}/`);
        }}
        icon={
          <FontAwesomeIcon
            icon={faEye}
            color={themeContext.colors.general.white}
            size={'1x'}
          />
        }
      />
    </SC.ProjectIconWrapper>
  );
};

export default ProjectActions;
