import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledText } from '../main/theme';

export const DropdownLabel = styled(Label)`
  background-color: ${({ indicatorcolor }) =>
    indicatorcolor as string} !important;
  border-color: ${({ indicatorcolor }) => indicatorcolor as string} !important;
  min-width: 1.2em !important;
  min-height: 1.2em !important;
  width: 1.2em;
  height: 1.2em;
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
`;
