import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DataValidation } from '@configur-tech/upit-core-types/lib/interfaces';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums';
import useChart from '../../../hooks/chart/UseChart';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useFilter, {
  DynamicConditionalField,
} from '../../../hooks/filter/UseFilter';
import useGraph from '../../../hooks/graph/UseGraph';
import useQuery from '../../../hooks/query/UseQuery';
import {
  StyledInput,
  StyledSubHeader,
  StyledText,
  defaultTheme,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { GraphOutputWithDetails } from '../../../store/graph';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import { getMissingLookupFieldDatasetIds } from '../../../util/lookup/GetMissingLookupFieldDatasetIds';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { ROW_ID_FIELD } from '../cms/CMSAddRowModal';
import FilterBuilder from './FilterBuilder';
import * as SC from './styled';

interface FieldOutputWithDatasetDetail extends Interfaces.FieldOutput {
  datasetMetaName: string;
  datasetMetaId: string;
  alias?: string | undefined;
  isMeasure?: boolean;
}

export interface FilterModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  type:
    | EntityType.DATASET
    | EntityType.AGGREGATION
    | EntityType.CHART
    | EntityType.GRAPH;
  existingFilterIndex?: number;
  fields?: FieldOutputWithDatasetDetail[];
  filterKeys?: string[];
  cmsGroupId?: string;
  ignoreLocalStorage?: boolean;
}

export interface DropdownItemPropsWithValidation extends DropdownItemProps {
  dataValidation?: DataValidation;
}

const MEASURE_TYPE = 'Measure';

const FilterModal: FC<FilterModalProps> = ({
  setShowModal,
  type,
  existingFilterIndex,
  fields,
  filterKeys,
  cmsGroupId,
  ignoreLocalStorage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { datasetMeta, activeDataCollectionItem, getDatasetMetas } =
    useDatasetMeta();
  const { query } = useQuery();
  const { graph } = useGraph();
  const { chart } = useChart();
  const {
    getDatasetFilters,
    createFilterGroup,
    updateFilterGroup,
    deleteFilterGroup,
  } = useFilter();

  const { listPreFilters } = useCMS();
  const [preFilterFieldIds, setPreFilterFieldIds] = useState<string[]>();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [loadedDsm, setLoadedDsm] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);

  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();

  const [filter, setFilter] = useState<DynamicConditionalField>({
    operator: Enums.AggregationFilterOperator.AND,
    value: [{ operator: Enums.AggregationFilterOperator.IN }],
    active: true,
  });
  const [filterName, setFilterName] = useState<string>();
  const [hasFilterParams, setHasFilterParams] = useState<boolean>();
  const [entity, setEntity] = useState<string>();
  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();

  // Set Entity
  useEffect(() => {
    if (type) {
      setEntity(
        type === EntityType.DATASET
          ? datasetMeta?._id
          : type === EntityType.AGGREGATION
          ? query?._id
          : type === EntityType.CHART
          ? chart?._id
          : graph?._id,
      );
    }
  }, [datasetMeta?._id, chart?._id, query?._id, type, graph?._id]);

  // Set Entity Schema
  useEffect(() => {
    if (!entitySchema?.length) {
      if (type === EntityType.CHART && chart?.graphs) {
        const combinedSchemaData: Interfaces.FieldOutput[] = (
          chart.graphs as GraphOutputWithDetails[]
        ).flatMap(
          (g) => g.additionalDetails?.flatMap((a) => a.schemaData) || [],
        );

        setEntitySchema(combinedSchemaData);
        return;
      }

      let filteredSchemaData: Interfaces.FieldOutput[] =
        (type === EntityType.DATASET
          ? activeDataCollectionItem?.schemaData
          : type === EntityType.AGGREGATION
          ? query?.additionalDetails
              ?.map((details) => details.schemaData)
              .flat() || []
          : graph?.additionalDetails
              ?.map((details) => details.schemaData)
              .flat()) || [];

      if (filterKeys?.length) {
        filteredSchemaData = filteredSchemaData?.filter((f) =>
          filterKeys.includes(f.name),
        );
      }

      if (filteredSchemaData.length) {
        setEntitySchema(filteredSchemaData);
      }
    }
  }, [
    activeDataCollectionItem?.schemaData,
    entitySchema,
    filterKeys,
    graph?.additionalDetails,
    query?.additionalDetails,
    type,
    chart,
  ]);

  // If editing existing, get from state
  useEffect(() => {
    if (entity && typeof existingFilterIndex !== 'undefined') {
      const dsFilters = getDatasetFilters(entity);

      if (
        (dsFilters?.[0]?.value as DynamicConditionalField[])?.[
          existingFilterIndex
        ]
      ) {
        setFilter(
          (dsFilters?.[0]?.value as DynamicConditionalField[])?.[
            existingFilterIndex
          ],
        );
        setFilterName(
          (dsFilters?.[0]?.value as DynamicConditionalField[])?.[
            existingFilterIndex
          ].name,
        );
      }
    }
  }, [entity, existingFilterIndex, getDatasetFilters, type]);

  // Confirm filters are complete
  useEffect(() => {
    if (
      filter?.operator &&
      (filter?.value as DynamicConditionalField[]).length
    ) {
      if (
        (filter.value as DynamicConditionalField[]).every(
          (c) =>
            (c.operator || c.fields) &&
            (Array.isArray(c.value)
              ? c.value.length
              : (
                  (c.value as Interfaces.ConstantConditionalValue)
                    ?.value as string
                )?.toString()?.length),
        )
      ) {
        return setCanProgress(true);
      }
    }

    setCanProgress(false);
  }, [existingFilterIndex, filter.operator, filter.value]);

  // Get any prefilter field Ids
  useEffect(() => {
    const fieldIds: string[] = [];
    const preFilters = listPreFilters(
      (datasetMeta?._id || datasetMeta?._id) as string,
      cmsGroupId,
    );

    preFilters.map((f) => {
      (f.value as unknown as DynamicConditionalField[]).map((m) => {
        m.field && fieldIds.push(m.field);
      });
    });

    if (fieldIds?.length) {
      setPreFilterFieldIds(fieldIds);
    }
  }, [datasetMeta?._id, listPreFilters, cmsGroupId]);

  // Get fields from schema
  useEffect(() => {
    if (!fields && entitySchema) {
      const options: DropdownItemProps[] = [];

      if (type == EntityType.DATASET && datasetMeta?._id && entitySchema) {
        options.push(
          ...entitySchema
            .filter((f) => !preFilterFieldIds?.includes(f.fieldId))
            .map((f, i) => {
              return {
                key: `field-${f.name}-${i}`,
                value: `${f.fieldId || f.name}***${datasetMeta._id}***${
                  f.name
                }`,
                text: f.name,
              };
            }),
        );
      }

      if (type == EntityType.AGGREGATION) {
        const aggOptions: DropdownItemProps[] = [];

        query?.additionalDetails?.map((details) => {
          return aggOptions.push(
            ...details.schemaData
              .filter((f) =>
                filterKeys?.length ? filterKeys.includes(f.name) : true,
              )
              .map((f, i) => ({
                key: `field-${f.name}-${i}`,
                value: `${f.fieldId || f.name}***${details?.datasetMetaId}***${
                  f.name
                }`,
                text: f.name,
              })),
          );
        });

        options.push(...aggOptions);
      }

      if (type == EntityType.GRAPH) {
        const aggOptions: DropdownItemProps[] = [];

        graph?.additionalDetails?.map((details) => {
          return aggOptions.push(
            ...details.schemaData
              .filter((f) =>
                filterKeys?.length ? filterKeys.includes(f.name) : true,
              )
              .map((f, i) => ({
                key: `field-${f.name}-${i}`,
                value: `${f.fieldId || f.name}***${details?.datasetMetaId}***${
                  f.name
                }`,
                text: f.name,
              })),
          );
        });

        options.push(...aggOptions);
      }

      if (type == EntityType.CHART && chart?.graphs) {
        let chartOptions: DropdownItemProps[] = [];

        const combinedAdditionalDetails = (
          chart.graphs as GraphOutputWithDetails[]
        ).flatMap((g) => g.additionalDetails?.flatMap((a) => a) || []);

        combinedAdditionalDetails?.map((details, index) => {
          return chartOptions.push(
            ...details.schemaData.map((f, i) => ({
              key: `field-${f.name}-${i}-${details?.datasetMetaId}`,
              value: `${f.fieldId || f.name}***${details?.datasetMetaId}***${
                f.name
              }`,
              text: f.name,
              description: chart.graphs[index]?.name || f.name,
              'data-validation': f.dataValidation,
              alias: f.name,
            })),
          );
        });

        // Remove dupes
        chartOptions = chartOptions.filter(
          (opt, index, array) =>
            array.findIndex((o) => o.value === opt.value) == index,
        );

        options.push(...chartOptions);
      }

      return setFieldOptions(options);
    }

    if (fields) {
      let fieldsAndMeasures = fields;
      if (
        [EntityType.AGGREGATION, EntityType.GRAPH, EntityType.CHART].includes(
          type,
        )
      ) {
        const measures = (
          (type === EntityType.AGGREGATION ? query : graph)?.queryParams
            .measures || []
        ).map((measure) => ({
          field: measure.alias,
          fieldId: measure.field,
          alias: measure.alias,
          datasetMetaId: measure.datasetMetaId,
          dataValidation: {
            dataValidationType: Enums.ValueDataType.NUMBER,
          },
          isMeasure: true,
        })) as unknown as FieldOutputWithDatasetDetail[];

        fieldsAndMeasures = fieldsAndMeasures.concat(measures);
      }
      const options = fieldsAndMeasures.map((f, i) => {
        const queryParams =
          type === EntityType.AGGREGATION
            ? query?.queryParams
            : graph?.queryParams;

        let desc = '';

        if (f.isMeasure) {
          desc = MEASURE_TYPE;
        } else if (
          !queryParams ||
          queryParams?.aggregationType !== Enums.AggregationType.MULTIPLE
        ) {
          desc = f.datasetMetaName;
        }

        return {
          key: `field-${f.name}-${i}`,
          value: `${f.fieldId || f.name}***${f.datasetMetaId}***${f.alias}`,
          text: f.alias || f.name,
          description: desc,
        };
      });

      setFieldOptions(options);
    }
  }, [
    datasetMeta?._id,
    entitySchema,
    fields,
    filterKeys,
    query?.additionalDetails,
    query?.queryParams.aggregationType,
    graph?.additionalDetails,
    graph?.queryParams.aggregationType,
    type,
    chart?.graphs,
    preFilterFieldIds,
    query?.queryParams.measures,
    query,
    graph,
  ]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Fetch any datasetMetas for lookup columns
  useEffect(() => {
    (async () => {
      if (!loadedDsm && entitySchema && datasetMeta?._id) {
        const lookupIds = [datasetMeta._id].concat(
          getMissingLookupFieldDatasetIds(entitySchema, datasetMetas),
        );

        // Check if they are already in state
        const lookupsNotInState = lookupIds.reduce((acc: string[], id) => {
          const inState = datasetMetas.find((dsm) => dsm.entity._id === id);

          if (inState) {
            return acc;
          }

          return [...acc, id];
        }, []);

        if (lookupsNotInState.length > 1) {
          await getDatasetMetas({
            _id: {
              $in: lookupIds.concat(datasetMetas.map((dsm) => dsm.entity._id)),
            },
          });
        }
        setLoadedDsm(true);
      }
    })();
  }, [
    datasetMeta?._id,
    datasetMetas,
    entitySchema,
    getDatasetMetas,
    loadedDsm,
  ]);

  // Check for filter params
  useEffect(() => {
    setHasFilterParams(!!history.location.state?.filters);
  }, [history.location.state?.filters]);

  const handleNameChange = (value: string) => {
    const cloned = cloneDeep(filter);
    value ? (cloned.name = value) : delete cloned.name;
    setFilter(cloned);
    setFilterName(value);
  };

  const saveFilter = () => {
    const entity =
      type === EntityType.DATASET
        ? datasetMeta?._id
        : type === EntityType.AGGREGATION
        ? query?._id
        : type === EntityType.CHART
        ? chart?._id
        : graph?._id;

    if (entity) {
      if (typeof existingFilterIndex === 'undefined') {
        createFilterGroup(entity, filter, ignoreLocalStorage);
      } else {
        updateFilterGroup(
          entity,
          filter,
          existingFilterIndex,
          ignoreLocalStorage,
        );
      }
    }

    dispatch(hideModal());
  };

  const removeFilter = () => {
    const entity =
      type === EntityType.DATASET
        ? datasetMeta?._id
        : type === EntityType.AGGREGATION
        ? query?._id
        : type === EntityType.CHART
        ? chart?._id
        : graph?._id;

    if (entity && typeof existingFilterIndex !== 'undefined') {
      deleteFilterGroup(entity, existingFilterIndex, ignoreLocalStorage);
    }
    dispatch(hideModal());
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>
          {existingFilterIndex ? 'Update' : 'Create'} Filter Group
        </SC.Header>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>

      <SC.ContentContainer>
        <SC.Content>
          {!hasFilterParams && (
            <>
              <StyledSubHeader>Filter Name</StyledSubHeader>

              <StyledText>Add a name for your filter</StyledText>

              <StyledInput
                placeholder={'Optional - Enter your filter name'}
                value={filterName ? filterName : ''}
                onChange={(event, data) => {
                  handleNameChange(data.value);
                }}
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </>
          )}
          <FilterBuilder
            entitySchema={entitySchema}
            fields={fields}
            filter={filter}
            fieldOptions={fieldOptions}
            setFilter={setFilter}
            type={type}
            hiddenFieldIds={[ROW_ID_FIELD]}
          />
        </SC.Content>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper
        multipleButtons={typeof existingFilterIndex !== 'undefined'}
      >
        {typeof existingFilterIndex !== 'undefined' && (
          <FeatureButton
            action={removeFilter}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={`Delete Filter`}
          />
        )}

        <FeatureButton
          isDisabled={!canProgress}
          action={saveFilter}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={`Save Filter Group`}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default FilterModal;
