import { cloneDeep, isEqual } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import IntegrationTable from '../../components/IntegrationTable/IntegrationTable';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import {
  AlertStatus,
  BannerType,
  EntityType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useIntegrationConfig from '../../hooks/integration-config/UseIntegrationConfig';
import useIntegrationTemplate from '../../hooks/integration-template/UseIntegrationTemplate';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import useUsage from '../../hooks/usage/useUsage';
import { FetchParams } from '../../interfaces/Search';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { IntegrationConfigItem } from '../../services/integration/IntegrationConfigService';
import { IntegrationTemplateItem } from '../../services/integration/IntegrationTemplateService';
import { resetStagesAndSubStages } from '../../store/integration-stage';
import { fetchIntegrationTemplateSuccess } from '../../store/integration-template';
import InitialIntegrationTemplate from '../../store/integration-template/inital-state';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import * as SC from '../styled';

const SEARCH_FIELD = 'integrationTemplates';
const SEARCH_ACTIVE_VAL = 'Active';
const DEFAULT_PAGE_SIZE = 24;

const IntegrationCentrePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();
  const themeContext = useContext(ThemeContext);

  const { getIntegrationTemplates } = useIntegrationTemplate();
  const { getIntegrationConfigs } = useIntegrationConfig();

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplateEnts: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const integrationConfigsState = useSelector(
    (state: RootState) => state.integrationConfigs,
  );
  const integrationConfigEnts: IntegrationConfigItem[] =
    integrationConfigsState.data.data;

  const [loading, setLoading] = useState<boolean>(false);
  const [integrationUsage, setIntegrationUsage] = useState<number>(0);

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 0,
    sortBy: undefined,
  });

  const [lastFetchedParams, setLastFetchedParams] = useState<FetchParams>({
    searchQuery: { index: SEARCH_FIELD, query: '' },
    pageNum: 1,
    sortBy: undefined,
  });

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get('search');
    setFetchParams((state) => ({
      ...state,
      searchQuery: {
        ...state.searchQuery,
        query:
          searchParams === null
            ? ''
            : decodeURI(searchParams as string).toString(),
      },
    }));
  }, [location.search]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const integrationUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.CUSTOM_PIPELINE_INTEGRATIONS,
        );
        setIntegrationUsage(integrationUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  // Fetch Config documents
  useEffect(() => {
    (async () => {
      await getIntegrationConfigs();
    })();
  }, [getIntegrationConfigs]);

  // Fetch Template documents
  useEffect(() => {
    (async () => {
      if (!isEqual(fetchParams, lastFetchedParams)) {
        setLastFetchedParams(fetchParams);

        setLoading(true);

        const isSearchingActive =
          fetchParams.searchQuery.query === SEARCH_ACTIVE_VAL;

        await getIntegrationTemplates(
          !isSearchingActive
            ? undefined
            : {
                _id: {
                  $in: integrationConfigEnts.map(
                    (config) => config.entity.integrationTemplateId,
                  ),
                },
              },
          fetchParams.searchQuery?.query?.length && !isSearchingActive
            ? fetchParams.searchQuery
            : undefined,
          fetchParams.pageNum,
          DEFAULT_PAGE_SIZE,
          fetchParams.sortBy,
        );

        setLoading(false);
      }
    })();
  }, [
    dispatch,
    fetchParams,
    getIntegrationTemplates,
    integrationConfigEnts,
    lastFetchedParams,
  ]);

  const onPageNumberChange = useCallback(
    (page: number) => {
      if (page !== fetchParams.pageNum) {
        const cloned: FetchParams = cloneDeep(fetchParams);
        cloned.pageNum = page;

        setFetchParams(cloned);
      }
    },
    [fetchParams],
  );

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.INTEGRATION_CENTRE} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Houston'}
          image={getDefaultAvatar(EntityType.INTEGRATION)}
          title={'Ground Control For Your Integrations'}
        />

        <Page>
          <SC.PageInner>
            <StageBodyText>
              Connect your data to our available integrations or build your own
              custom connection in minutes.
            </StageBodyText>

            {integrationUsage >= QuotaLimits.WARNING && (
              <div style={{ marginBottom: themeContext.margin.xlarge }}>
                {BuildBanner.generateBanner(
                  BannerType.QUOTA,
                  integrationUsage >= QuotaLimits.FULL
                    ? AlertStatus.ERROR
                    : AlertStatus.WARNING,
                  ResourceType.CUSTOM_PIPELINE_INTEGRATIONS,
                )}
              </div>
            )}

            <FeatureButton
              action={() => {
                // Reset integration template and stage data
                dispatch(resetStagesAndSubStages());
                dispatch(
                  fetchIntegrationTemplateSuccess(InitialIntegrationTemplate),
                );
                history.push(RouteName.INTEGRATION_ITEM);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create integration'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
              isDisabled={integrationUsage >= QuotaLimits.FULL}
            />

            <EntityFilter
              baseRoute={RouteName.INTEGRATION_CENTRE}
              loading={loading}
              title={'Search Integrations'}
              search={fetchParams.searchQuery.query}
              inputPlaceholder={'Enter an integration name or category'}
              totalResultCount={
                integrationTemplatesState.data.pagination.totalCount
              }
              filteredResultCount={integrationTemplateEnts.length}
            />

            <SC.TableWrapper>
              <IntegrationTable
                loading={loading}
                integrationTemplates={integrationTemplateEnts}
                integrationConfigs={integrationConfigEnts}
                showPagination={true}
                paginationAction={onPageNumberChange}
                totalRows={integrationTemplatesState.data.pagination.totalCount}
                pageSize={DEFAULT_PAGE_SIZE}
                resetPagination={
                  location.search !== lastFetchedParams.searchQuery.query
                }
                integrationUsage={integrationUsage}
              />
            </SC.TableWrapper>
          </SC.PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default IntegrationCentrePage;
