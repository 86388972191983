import { forwardRef, useImperativeHandle, useState } from 'react';
import { Icon, Menu, Popup, SemanticICONS } from 'semantic-ui-react';
import { DefaultPopupStyles } from '../../main/theme';
import { menuItemStyles, popupStyles } from './styled';

export interface ContextMenuItem {
  text: string;
  icon: SemanticICONS;
  action: (selectedRowId?: number, tableRowId?: number) => void;
}

export type ContextMenuHandle = {
  initialiseMenu: (
    e: React.MouseEvent<HTMLElement>,
    selectedRowId: number,
    tableRowId: number,
  ) => void;
};

interface ContextMenuProps {
  items: ContextMenuItem[];
}

const createContextFromEvent = (e: React.MouseEvent<HTMLElement>) => {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;
  const height = 0;
  const width = 0;

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,
      height,
      width,
    }),
  };
};

const ContextMenu = forwardRef<ContextMenuHandle, ContextMenuProps>(
  ({ items }, ref) => {
    const [selectedRowId, setSelectedRowId] = useState<number>();
    const [tableRowId, setTableRowId] = useState<number>();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<HTMLElement>();

    useImperativeHandle(ref, () => ({
      initialiseMenu(
        e: React.MouseEvent<HTMLElement>,
        rowId: number,
        tableRowId: number,
      ) {
        setMenuOpen(true);
        setMenuPosition(createContextFromEvent(e) as HTMLElement);
        if (rowId) {
          setSelectedRowId(rowId);
          setTableRowId(tableRowId);
        }
      },
    }));

    return (
      <Popup
        basic
        context={menuPosition}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        style={{
          ...DefaultPopupStyles,
          ...popupStyles,
        }}
        position="bottom left"
      >
        <Menu secondary vertical>
          {items?.map((item) => (
            <Menu.Item
              style={menuItemStyles}
              key={`context-menu-item-${item.text}`}
              name={item.text}
              onClick={() => {
                setMenuOpen(false);
                item.action(selectedRowId, tableRowId);
              }}
            >
              {item.icon && <Icon name={item.icon} />}
              {item.text} {tableRowId}
            </Menu.Item>
          ))}
        </Menu>
      </Popup>
    );
  },
);

export default ContextMenu;
