import { UserType } from '@configur-tech/upit-core-types/lib/enums';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PortalUserItemNameStage from '../../components/PortalUser/1-name/PortalUserItemNameStage';
import PortalUserItemAvatarStage from '../../components/PortalUser/2-avatar/PortalUserItemAvatarStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import UserItemNameStage from '../../components/UserItemStages/UserItemCreationStages/1-name/UserItemNameStage';
import UserItemAvatarStage from '../../components/UserItemStages/UserItemCreationStages/2-avatar/UserItemAvatarStage';
import { RouteName } from '../../enums/RouteName';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import Page from '../../main/Page';
import { RootState } from '../../store/rootReducer';
import {
  UserCreationSubStage,
  UserStage,
} from '../../store/user-stage/initial-state';
import {
  getUserStageTitle,
  getUserSubStageContent,
} from '../../util/user-stage-content/UserStageContent';

const UserCreation: FC = () => {
  const location = useLocation();
  const { loggedInUser } = useLoggedInUser();

  const isPortal = location.pathname.includes(RouteName.PORTAL);
  const isPortalUser = loggedInUser?.userType === UserType.PORTAL;

  const userStageState = useSelector((state: RootState) => state.userStage);

  const activeStage = UserStage.CREATION;
  const activeSubStage = userStageState.stages[activeStage].activeSubStage;

  const stageTitle = getUserStageTitle(activeStage);
  const stageContent = getUserSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case UserCreationSubStage.NAME:
        if (isPortal || isPortalUser) {
          return <PortalUserItemNameStage />;
        }
        return <UserItemNameStage />;
      case UserCreationSubStage.AVATAR:
        if (isPortal || isPortalUser) {
          return <PortalUserItemAvatarStage />;
        }
        return <UserItemAvatarStage />;
      default:
        if (isPortal || isPortalUser) {
          return <PortalUserItemNameStage />;
        }
        return <UserItemNameStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={isPortal ? '' : stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: userStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={stageContent.image}
        title={stageContent.title}
        content={stageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default UserCreation;
