import styled from 'styled-components';

export const Image = styled.img<{ isCustomAvatar?: boolean }>`
  max-width: 30px;
  padding: ${({ isCustomAvatar, theme }) =>
    isCustomAvatar ? 0 : theme.padding.small};
  background-color: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-right: ${({ theme }) => theme.margin.small};
`;

export const AvatarNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
