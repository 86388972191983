import { Interfaces } from '@configur-tech/upit-core-types';
import { Pagination } from '../../const/PaginationConst';
import { MergeMode } from '../../enums';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import HttpHelper from '../../util/http-helper/HttpHelper';

const PAGE_SIZE = 50;
const GRAPH_PAGE_SIZE = process.env['REACT_APP_GRAPH_PAGE_SIZE'] || PAGE_SIZE;
export const MAP_GRAPH_PAGE_SIZE = 1200;

export interface DatasetGetResponse {
  entries: Record<string, unknown>[];
  next: { hasMoreEntries: boolean; totalCount: number };
}

interface DatasetPostResponse {
  datasetMeta: Interfaces.DatasetMetaOutput;
  entries: Record<string, unknown>[];
}

interface DatasetPutResponse {
  datasetMeta: Interfaces.DatasetMetaOutput;
  entry: Record<string, unknown>;
  entries: Record<string, unknown>[];
}

interface DatasetDeleteResponse {
  datasetMeta: Interfaces.DatasetMetaOutput;
}

interface DatasetCreateResponse {
  tableName: string;
  datasetMeta: Interfaces.DatasetMetaOutput;
  pagination: Pagination;
}

export interface DatasetMergeResponse {
  datasetMeta: Interfaces.DatasetMetaOutput;
  mergeFile: Interfaces.File;
  pagination: Pagination;
}

export default class DatasetService {
  public static async createDataset(
    token: string,
    dataMetaId: string,
    userId: string,
    organisationId: string,
    pageNum: number,
    fileId?: string,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    collectionId?: string,
    sheetName?: string,
    copyDataMetaId?: string,
  ): Promise<DatasetCreateResponse> {
    const endpoint = process.env['REACT_APP_CREATE_DATASET'] || '';

    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<DatasetCreateResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      fileId,
      dataMetaId,
      userId,
      organisationId,
      pageNum,
      entryType,
      hasNoHeadings,
      delimiter,
      collectionId,
      sheetName,
      copyDataMetaId,
    });

    return result?.data as DatasetCreateResponse;
  }

  public static async fetchDataset(
    token: string,
    datasetMetaId: string,
    filters: DynamicConditionalField[] = [],
    page = 1,
    connectionId?: string,
    groupId?: string,
    portalId?: string,
    sortBy?: { id: string; desc: boolean },
    limit?: number,
    includeCommentsCounts?: boolean,
  ): Promise<DatasetGetResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    let orderBy;
    if (sortBy) {
      orderBy = `${sortBy.desc ? '-' : ''}${sortBy.id}`;
    }

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}`
      : `${endpoint}internal/${datasetMetaId}`;

    const result = await ah.get<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      limit: limit ? limit : PAGE_SIZE,
      pageNum: page,
      orderBy,
      conditions: JSON.stringify(filters),
      connectionId,
      groupId,
      includeCommentsCounts,
    });

    return result?.data as DatasetGetResponse;
  }

  public static async fetchGraphDataset(
    token: string,
    graphId: string,
    filters: DynamicConditionalField[] = [],
    page = 1,
    portalId?: string,
    showRawGraphData?: boolean,
    limit?: number,
  ): Promise<DatasetGetResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portal/${portalId}/aggregation/${graphId}`
      : `${endpoint}internal/${graphId}`;

    const result = await ah.get<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      graphId: graphId,
      limit: limit || GRAPH_PAGE_SIZE,
      pageNum: page,
      conditions: JSON.stringify(filters),
      portalId,
      includeSubtotals: true,
      showRawGraphData,
    });

    return result?.data as DatasetGetResponse;
  }

  public static async fetchAggregatedDataset(
    token: string,
    aggregationId: string,
    filters: DynamicConditionalField[] = [],
    page = 1,
    portalId?: string,
  ): Promise<DatasetGetResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portal/${portalId}/aggregation/${aggregationId}`
      : `${endpoint}internal/${aggregationId}`;

    const result = await ah.get<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      aggregateId: aggregationId,
      limit: PAGE_SIZE,
      pageNum: page,
      conditions: JSON.stringify(filters),
      portalId,
      includeSubtotals: true,
    });

    return result?.data as DatasetGetResponse;
  }

  public static async createDatasetRow(
    token: string,
    userId: string,

    datasetMetaId: string,
    entries: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
    preserveRowIds?: boolean,
  ): Promise<DatasetPostResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}/`
      : `${endpoint}internal/${datasetMetaId}/`;

    const result = await ah.post<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      userId,

      entries,
      connectionId,
      includeCommentsCounts,
      preserveRowIds,
    });

    return result?.data as DatasetPostResponse;
  }

  public static async updateDatasetRow(
    token: string,
    userId: string,

    datasetMetaId: string,
    updateData: Record<string, unknown>,
    rowId: number,
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
  ): Promise<DatasetPutResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}/${rowId}`
      : `${endpoint}internal/${datasetMetaId}/${rowId}`;

    const result = await ah.put<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      userId,

      updateData,
      connectionId,
      includeCommentsCounts,
    });

    return result?.data as DatasetPutResponse;
  }

  public static async updateDatasetRows(
    token: string,
    userId: string,

    datasetMetaId: string,
    updateData: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
  ): Promise<DatasetPutResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}`
      : `${endpoint}internal/${datasetMetaId}`;

    const result = await ah.put<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, {
      userId,

      updateData,
      connectionId,
      includeCommentsCounts,
    });

    return result?.data as DatasetPutResponse;
  }

  public static async deleteDatasetRow(
    token: string,
    userId: string,

    datasetMetaId: string,
    rowId: number,
    connectionId?: string,
    portalId?: string,
  ): Promise<DatasetDeleteResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}/${rowId}`
      : `${endpoint}internal/${datasetMetaId}/${rowId}`;

    const result = await ah.delete<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, userId, undefined, connectionId);

    return result?.data as DatasetDeleteResponse;
  }

  public static async deleteDatasetRows(
    token: string,
    userId: string,

    datasetMetaId: string,
    rowIds: number[],
    connectionId?: string,
    portalId?: string,
  ): Promise<DatasetDeleteResponse> {
    const endpoint = process.env['REACT_APP_DATASET_API'] || '';

    const ah = new HttpHelper(token);

    const url = portalId
      ? `${endpoint}internal/portals/${portalId}/${datasetMetaId}`
      : `${endpoint}internal/${datasetMetaId}`;

    const deleteData = rowIds.map((id) => {
      return { row_id: id };
    });

    const result = await ah.delete<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(url, userId, undefined, connectionId, deleteData);

    return result?.data as DatasetDeleteResponse;
  }

  public static async mergeDataset(
    token: string,
    userId: string,

    datasetMetaId: string,
    collectionId: string,
    mergeDatasetFieldId: string,
    mergeFileField: string,

    pageNum?: number,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    sheetName?: string,
    mergeMode: MergeMode = MergeMode.ALL,
  ): Promise<DatasetMergeResponse> {
    const endpoint = process.env['REACT_APP_DATASET_MERGE'] || '';

    const ah = new HttpHelper(token);

    const result = await ah.post<
      Record<string, unknown> | Interfaces.ErrorResponseBody
    >(endpoint, {
      userId,

      datasetMetaId,
      collectionId,
      mergeDatasetFieldId,
      mergeFileField,

      pageNum,
      entryType,
      hasNoHeadings,
      delimiter,
      sheetName,
      mergeMode,
    });

    return result?.data as DatasetMergeResponse;
  }
}
