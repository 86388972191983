import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;
