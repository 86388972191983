export enum IntegrationCategoryTags {
  ACCOUNTING = 'Accounting',
  CALENDAR = 'Calendar',
  CLOUD_STORAGE = 'Cloud Storage',
  CMS = 'CMS',
  COMMUNICATION = 'Communication',
  CRM = 'CRM',
  CUSTOMER_SUPPORT = 'Customer Support',
  DATA = 'Data',
  DESIGN = 'Design',
  ECOMMERCE = 'Ecommerce',
  EMAIL = 'Email',
  FORM = 'Form',
  FUNDRAISING = 'Fundraising',
  HR = 'HR',
  LEAD_GENERATION = 'Lead Generation',
  MARKETING = 'Marketing',
  PAYMENT = 'Payment',
  PROJECT_MANAGEMENT = 'Project Management',
  SALES = 'Sales',
  SURVEY = 'Survey',
}
