import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import * as SC from './styled';

const DEFAULT_TEXT = 'Last saved:';
const JUST_NOW_REPLACE = '0 seconds ago';
const JUST_NOW_TEXT = 'Just now';
const SECOND_TEXT = 'second';
const SECOND_DELAY = 10000;
const MINUTE_DELAY = 60000;
const DATETIME_FORMAT = 'dd/MM/yyyy hh:mm:ss';

interface TimeAgoProps {
  date: string; // ISO format
  text?: string;
}

const outputRelativeDate = (date) => {
  const olderDate = DateTime.now().minus({ hours: 1 }) > DateTime.fromISO(date);

  if (olderDate) {
    return DateTime.fromISO(date).toFormat(DATETIME_FORMAT);
  }
  const relative = DateTime.fromISO(date).toRelative();

  return relative === JUST_NOW_REPLACE ? JUST_NOW_TEXT : relative;
};

const TimeAgo: FC<TimeAgoProps> = ({ date, text = DEFAULT_TEXT }) => {
  const [lastDate, setLastDate] = useState<string>();
  const [relativeDate, setRelativeDate] = useState<string>(
    outputRelativeDate(date),
  );

  useEffect(() => {
    if (date !== lastDate) {
      setRelativeDate(outputRelativeDate(date));
      setLastDate(date);
    }

    const delay =
      relativeDate.includes(SECOND_TEXT) || relativeDate.includes(JUST_NOW_TEXT)
        ? SECOND_DELAY
        : MINUTE_DELAY;
    const timer = setTimeout(() => {
      setRelativeDate(outputRelativeDate(date));
    }, delay);
    return () => clearTimeout(timer);
  }, [date, lastDate, relativeDate]);

  return <SC.StyledTimeAgo>{`${text} ${relativeDate}`}</SC.StyledTimeAgo>;
};

export default TimeAgo;
