import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChartBar,
  faChevronDown,
  faChevronUp,
  faCog,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../enums';
import useChart from '../../hooks/chart/UseChart';
import useProject from '../../hooks/project/UseProject';
import { BackNavigationItem } from '../../main/theme';
import {
  resetChartsStage,
  updateActiveProjectSubStage,
} from '../../store/project-stage';
import {
  ConfigurationSubStage,
  ProjectChartSubStage,
} from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [ProjectChartSubStage.NAME]: faWrench,
  [ProjectChartSubStage.CHART]: faChartBar,
};

const ChartsItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const { projectAccessLevel } = useProject();
  const { chart } = useChart();
  const themeContext = useContext(ThemeContext);

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const chartStage = projectStageState.stages[ProjectStage.CHARTS];
  const activeSubStage = chartStage.activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [ProjectChartSubStage.CONFIGURATION]: true });

  return (
    <>
      <BackNavigationItem
        key={`chart-stage-back`}
        onClick={() => dispatch(resetChartsStage())}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={themeContext.colors.system.white}
        />

        <NavTitle text={'Back To Dashboards'} />
      </BackNavigationItem>

      {projectAccessLevel === Enums.AccessLevel.MANAGE &&
        Object.values(chartStage.subStages)
          .filter(
            (subStage) =>
              subStage.subStage !== ProjectChartSubStage.CONFIGURATION,
          )
          .map((subStage) => {
            const subStageName = subStage.subStage as ProjectChartSubStage;

            return (
              <NavItem
                disabled={
                  subStageName === ProjectChartSubStage.CHART && !chart?.name
                }
                key={`chart-stage-${subStageName}`}
                onClick={() =>
                  dispatch(updateActiveProjectSubStage(subStageName))
                }
                isActive={activeSubStage === subStageName}
              >
                <FontAwesomeIcon
                  icon={NavIcons[subStageName]}
                  color={themeContext.colors.system.white}
                />

                <NavTitle text={subStage.title} />
              </NavItem>
            );
          })}

      {/* Configuration accordion nav */}
      {chart?._id && (
        <NavigationAccordion>
          <div key={`${ProjectChartSubStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={!accordionGroups[ProjectChartSubStage.CONFIGURATION]}
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [ProjectChartSubStage.CONFIGURATION]:
                    !accordionGroups[ProjectChartSubStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle
                  text={
                    chartStage.subStages.find(
                      (s) => s.subStage === ProjectChartSubStage.CONFIGURATION,
                    )?.title || 'Config'
                  }
                />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[Enums.DatasetStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={!accordionGroups[ProjectChartSubStage.CONFIGURATION]}
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActiveProjectSubStage(
                        ProjectChartSubStage.CONFIGURATION,
                      ),
                    );
                  }}
                  isActive={
                    activeSubStage === ProjectChartSubStage.CONFIGURATION
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={ConfigurationSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default ChartsItemNavigation;
