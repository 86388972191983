import { Enums } from '@configur-tech/upit-core-types';
import { faRefresh, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import { RouteName } from '../../enums';
import useNotification from '../../hooks/notification/UseNotification';
import useUser from '../../hooks/user/UseUser';
import { StyledH3 } from '../../main/theme';
import { TextButton } from '../../pages/NotificationCentre/styled';
import { NotificationItemsOutput } from '../../services/notification/NotificationService';
import { UserItemOutput } from '../../services/user/UserService';
import { RootState } from '../../store/rootReducer';
import { UsersState } from '../../store/users';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import EmptyNotifications from '../Notification/EmptyNotifications';
import NotificationEventCard from '../NotificationEvent/NotificationEventCard';
import * as SC from './styled';

export interface NotificationPanelProps {
  notificationPanelOpen: boolean;
  hideNotificationPanel: () => void;
  siteWideBannerActive?: boolean;
}

const ESC_KEY = 27;
const CLOSE_BUTTON_HEIGHT = 30;
const NOTIFICATION_EVENT_FETCH_INTERVAL = 300000;

const NotificationPanel: FC<NotificationPanelProps> = ({
  notificationPanelOpen,
  hideNotificationPanel,
  siteWideBannerActive = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation().pathname;
  const clickRef = useRef<HTMLDivElement>(null);
  const { getNotifications, markAllNotificationsAsRead } = useNotification();
  const { getUsers } = useUser();

  const [count, setCount] = useState(0);

  const notificationEventsState = useSelector(
    (state: RootState) => state.notificationEvents,
  );
  const notificationEvents: NotificationItemsOutput =
    notificationEventsState.unread;

  const usersState: UsersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === ESC_KEY) {
        hideNotificationPanel();
      }
    };

    // Click outside of the panel to close it
    const handleClick = (e) => {
      if (clickRef.current && !clickRef.current?.contains(e.target)) {
        hideNotificationPanel();
      }
    };

    document.addEventListener('keydown', close);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('keydown', close);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [hideNotificationPanel]);

  // Get notifications on first load
  useEffect(() => {
    getNotifications({
      pageNum: 1,
      readStatus: Enums.NotificationReadStatus.UNREAD,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get notifications on an interval
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!location.includes(RouteName.NOTIFICATION_CENTRE)) {
        getNotifications({
          pageNum: 1,
          readStatus: Enums.NotificationReadStatus.UNREAD,
        });
      }

      setCount(count + 1);
    }, NOTIFICATION_EVENT_FETCH_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [count, getNotifications, location]);

  // If no users, fetch them
  useEffect(() => {
    if (!users.length && !usersState.loading) {
      (async () => {
        await getUsers();
      })();
    }
  }, [getUsers, users, usersState, usersState.loading]);

  const handleMarkAllAsRead = async () => {
    await markAllNotificationsAsRead();
  };

  return (
    <SC.NotificationContainer
      ref={clickRef}
      className={notificationPanelOpen ? 'visible' : ''}
      siteWideBannerActive={siteWideBannerActive}
    >
      <SC.NotificationHeader>
        <div>
          <StyledH3>
            Notifications{' '}
            {notificationEvents?.pagination?.totalCount > 0 &&
              `(${notificationEvents.pagination.totalCount})`}
          </StyledH3>
          {notificationEventsState.unread?.pagination?.totalCount > 0 && (
            <TextButton onClick={() => handleMarkAllAsRead()}>
              Mark all as read
            </TextButton>
          )}
        </div>
        <SC.HeaderButtons>
          <FeatureButton
            action={() =>
              getNotifications({
                pageNum: 1,
                readStatus: Enums.NotificationReadStatus.UNREAD,
              })
            }
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.blue}
            height={CLOSE_BUTTON_HEIGHT}
            icon={
              <FontAwesomeIcon
                icon={faRefresh}
                color={themeContext.colors.system.white}
                size={'lg'}
              />
            }
          />
          <FeatureButton
            action={() => hideNotificationPanel()}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.sea}
            height={CLOSE_BUTTON_HEIGHT}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                color={themeContext.colors.system.white}
                size={'lg'}
              />
            }
          />
        </SC.HeaderButtons>
      </SC.NotificationHeader>
      <SC.NotificationPanelContent>
        {notificationEventsState.loading &&
          notificationEvents?.data?.length !== 0 && (
            <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />
          )}

        {!notificationEventsState.loading &&
          notificationEvents?.data?.map((notificationEvent) => {
            return (
              <NotificationEventCard
                notificationEvent={notificationEvent.entity}
                hideNotificationPanel={() => hideNotificationPanel()}
                key={`notification-card-${notificationEvent.entity._id}`}
              />
            );
          })}

        {!notificationEventsState.loading &&
          notificationEvents?.data?.length === 0 && (
            <SC.EmptyContainer>
              <EmptyNotifications />
              <FeatureButton
                action={() => {
                  history.push(RouteName.NOTIFICATION_CENTRE);
                  hideNotificationPanel();
                }}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.blue}
                height={CLOSE_BUTTON_HEIGHT}
                text={'View all notifications'}
                style={{ marginTop: themeContext.margin.xlarge }}
              />
            </SC.EmptyContainer>
          )}

        {!notificationEventsState.loading &&
          notificationEvents?.data?.length > 0 && (
            <FeatureButton
              action={() => {
                history.push(RouteName.NOTIFICATION_CENTRE);
                hideNotificationPanel();
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              height={CLOSE_BUTTON_HEIGHT}
              text={'View all notifications'}
              style={{ marginTop: themeContext.margin.xlarge }}
            />
          )}
      </SC.NotificationPanelContent>
    </SC.NotificationContainer>
  );
};

export default NotificationPanel;
