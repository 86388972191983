import { Enums } from '@configur-tech/upit-core-types';
import { defaultTheme } from '../../main/theme';

const getStageStatusColor = (status: Enums.StageStatus): string => {
  switch (status) {
    case Enums.StageStatus.COMPLETED:
    case Enums.StageStatus.COMPLETED_WITH_ERRORS:
      return defaultTheme.colors.general.green;
    case Enums.StageStatus.IN_PROGRESS:
      return defaultTheme.colors.general.yellow;
    default:
      return defaultTheme.colors.general.red;
  }
};

export default getStageStatusColor;
