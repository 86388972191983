import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import useTeam from '../../hooks/team/UseTeam';
import PageContainer from '../../main/PageContainer';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import { TeamStage } from '../../store/team-stage/initial-state';
import TeamConfiguration from './TeamConfiguration';
import TeamCreation from './TeamCreation';

const TeamItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { team, getTeam, teamError } = useTeam();

  const teamStageState = useSelector((state: RootState) => state.teamStage);

  const activeStage = teamStageState.activeStage;

  // Get an existing team if requested
  useEffect(() => {
    if (params?.id && params?.id !== team?._id) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Team...' }));
        await getTeam(params.id);
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getTeam, params.id, team?._id]);

  // Error fetching Team
  useEffect(() => {
    if (teamError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'Team Not Found',
            errorText:
              'This team could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Teams',
            errorActionRedirectRoute: RouteName.TEAMS,
          },
        }),
      );
    }
  }, [teamError, dispatch]);

  const getStageComponent = () => {
    switch (activeStage) {
      case TeamStage.CREATION:
        return <TeamCreation />;
      case TeamStage.CONFIGURATION:
        return <TeamConfiguration />;
      default:
        return <TeamCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.TEAM_ITEM} />
      <PageContainer>{getStageComponent()}</PageContainer>
    </>
  );
};

export default TeamItemPage;
