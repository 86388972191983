import { Interfaces } from '@configur-tech/upit-core-types';
import { AggregationType } from '@configur-tech/upit-core-types/lib/enums';

const InitialAggregation: Interfaces.Aggregation = {
  name: '',
  description: '',

  aggregationType: AggregationType.SINGLE,
  fields: [],
  filters: [],
  group: [],
  measures: [],
  sort: [],
};

export default InitialAggregation;
