import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import NameDatasetStage from '../../components/IntegrationItemStages/IntegrationCreationStages/1-meta/MetaIntegrationStage';
import AvatarDatasetStage from '../../components/IntegrationItemStages/IntegrationCreationStages/2-avatar/AvatarIntegrationStage';
import EndpointsIntegrationStage from '../../components/IntegrationItemStages/IntegrationCreationStages/4-endpoints/EndpointIntegrationStage';
import IntegrationItemAccessStage from '../../components/IntegrationItemStages/IntegrationCreationStages/5-access/AccessIntegrationStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import {
  IntegrationCreationSubStage,
  IntegrationStage,
} from '../../store/integration-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getIntegrationStageTitle,
  getIntegrationSubStageContent,
} from '../../util/integration-stage-content/IntegrationStageContent';

const IntegrationItemCreation: FC = () => {
  const integrationStageState = useSelector(
    (state: RootState) => state.integrationStage,
  );

  const activeStage = IntegrationStage.CREATION;
  const activeSubStage =
    integrationStageState.stages[activeStage].activeSubStage;

  const stageTitle = getIntegrationStageTitle(activeStage);
  const subStageContent = getIntegrationSubStageContent(
    activeStage,
    activeSubStage,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case IntegrationCreationSubStage.META:
        return <NameDatasetStage />;
      case IntegrationCreationSubStage.AVATAR:
        return <AvatarDatasetStage />;
      case IntegrationCreationSubStage.ENDPOINTS:
        return <EndpointsIntegrationStage />;
      case IntegrationCreationSubStage.ACCESS:
        return <IntegrationItemAccessStage />;
      default:
        return <NameDatasetStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: integrationStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default IntegrationItemCreation;
