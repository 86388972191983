import { faCog, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import { resetStagesAndSubStages } from '../../store/dataset-stage';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

interface DatasetsActionsProps {
  entityId: string;
}

const DatasetsActions: FC<DatasetsActionsProps> = ({ entityId }) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <SC.DatasetIconWrapper>
      <FeatureButton
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.green}
        action={() => {
          history.push(`${RouteName.DATASET_ITEM}/${entityId}/view?page=1`);
        }}
        icon={
          <FontAwesomeIcon
            icon={faEye}
            color={themeContext.colors.general.white}
            size={'1x'}
          />
        }
      />
      <FeatureButton
        size={FeatureButtonSize.EXTRA_SMALL}
        color={themeContext.colors.general.blue}
        action={() => {
          dispatch(resetStagesAndSubStages());
          history.push(`${RouteName.DATASET_ITEM}/${entityId}`);
        }}
        icon={
          <FontAwesomeIcon
            icon={faCog}
            color={themeContext.colors.general.white}
            size={'1x'}
          />
        }
      />
    </SC.DatasetIconWrapper>
  );
};

export default DatasetsActions;
