import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  ChartArea,
  Panel,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChartService from '../../services/chart/ChartService';
import {
  createChart,
  deleteChart,
  fetchChart,
  fetchChartSuccess,
  updateChart,
} from '../../store/chart';
import {
  ChartItemOutput,
  fetchCharts,
  fetchChartsSuccess,
} from '../../store/charts';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import { EMPTY_PANEL } from '../../components/Modal/chart/ChartAddAreaModal';

export enum AreaMoveDirection {
  UP = 'up',
  DOWN = 'down',
}

interface useChartResult {
  chart?: Interfaces.ChartOutput;
  chartAccessLevel?: Enums.AccessLevel;

  chartSaving?: boolean;

  getCharts: (chart?: Record<string, unknown>) => Promise<void>;
  getChartsDirect: (
    chart?: Record<string, unknown>,
  ) => Promise<ChartItemOutput[]>;
  getChart: (chartId: string, portalId?: string) => void;
  addChart: (chartObj: Interfaces.Chart, portalId?: string) => void;
  editChart: (chartObj: Interfaces.ChartOutput, portalId?: string) => void;
  removeChart: (chartId: string, portalId?: string) => void;

  updateChartContent: (chartObj: Interfaces.ChartOutput) => void;
  updatePanelContent: (areaId, panelId, content) => void;

  clearPanelMapContent: (panelMapId) => void;

  removeGraph: (graphId: string) => void;

  deleteChartArea: (areaId: string) => void;
  moveChartArea: (direction: AreaMoveDirection, areaId: string) => void;
}

const panelMap: Map<string, Interfaces.PanelOutput> = new Map();

const mapPanelContent = (chartState: Interfaces.Chart) => {
  const cloned = cloneDeep(chartState);

  // Fetch panel content from map
  cloned.areas = cloned.areas.map((area) => ({
    ...area,
    panels: area.panels.map((panel) => {
      if (panel.panelType === Enums.PanelType.TEXT) {
        const panelContent = panelMap.get(`${area._id}-${panel._id}`);
        panel.content = panelContent ? panelContent : panel.content;
      }
      return panel;
    }),
  }));

  return cloned;
};

const useChart = (): useChartResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [chart, setChart] = useState<Interfaces.ChartOutput>();
  const [chartSaving, setChartSaving] = useState<boolean>();
  const [chartAccessLevel, setChartAccessLevel] = useState<Enums.AccessLevel>();

  const chartsState = useSelector((state: RootState) => state.charts);
  const chartState = useSelector((state: RootState) => state.chart);
  const chartObj = chartState.data as ChartItemOutput;

  const getCharts = useCallback(
    async (chartQuery?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        await dispatch(fetchCharts(token, chartQuery));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getChartsDirect = useCallback(
    async (
      chartQuery?: Record<string, unknown>,
    ): Promise<ChartItemOutput[]> => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          const fetched = await ChartService.getCharts(token, chartQuery);

          return fetched.data?.data as ChartItemOutput[];
        } catch (err) {
          return [];
        }
      }
      return [];
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const getChart = useCallback(
    async (chartId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && chartId && loggedInUser) {
        await dispatch(fetchChart(token, chartId, portalId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addChart = useCallback(
    async (chartObj: Interfaces.Chart, portalId?: string) => {
      const token = await getAccessTokenSilently();

      // Fetch panel content from map
      const cloned = cloneDeep(chartObj);
      cloned.areas = cloned.areas.map((area) => ({
        ...area,
        panels: area.panels.map((panel) => {
          if (panel.type === Enums.PanelType.TEXT) {
            panel.content = panelMap.get(`${area._id}-${panel.__id}`);
          }
          return panel;
        }),
      }));

      if (token && chartObj && loggedInUser) {
        await dispatch(
          createChart(
            token,
            mapPanelContent(chartObj),
            loggedInUser._id,
            portalId,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editChart = useCallback(
    async (chartObj: Interfaces.ChartOutput, portalId?: string) => {
      const token = await getAccessTokenSilently();

      // Fetch panel content from map
      const cloned = cloneDeep(chartObj);
      cloned.areas = cloned.areas.map((area) => ({
        ...area,
        panels: area.panels.map((panel) => {
          if (panel.type === Enums.PanelType.TEXT) {
            panel.content = panelMap.get(`${area._id}-${panel.__id}`);
          }
          return panel;
        }),
      }));

      if (token && chartObj && loggedInUser) {
        await dispatch(
          updateChart(
            token,
            mapPanelContent(chartObj),
            loggedInUser._id,
            portalId,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const moveChartArea = async (
    direction: AreaMoveDirection,
    areaId: string,
  ) => {
    const cloned = mapPanelContent(cloneDeep(chart));
    const areaIndex = cloned.areas.findIndex((a) => a._id === areaId);

    const newIndex =
      direction === AreaMoveDirection.UP ? areaIndex - 1 : areaIndex + 1;

    [cloned.areas[areaIndex], cloned.areas[newIndex]] = [
      cloned.areas[newIndex],
      cloned.areas[areaIndex],
    ];

    updateChartContent(cloned);
  };

  const deleteChartArea = async (areaId: string) => {
    const cloned = mapPanelContent(cloneDeep(chart));
    cloned.areas = cloned.areas.filter((a) => a._id !== areaId);

    updateChartContent(cloned);
  };

  const removeChart = useCallback(
    async (chartId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && chartId && loggedInUser) {
        await dispatch(deleteChart(token, chartId, loggedInUser._id, portalId));
      }

      // Remove chart from current charts
      await dispatch(
        fetchChartsSuccess({
          pagination: {
            ...chartsState.data.pagination,
            totalCount: chartsState.data.pagination.totalCount - 1,
          },
          data: chartsState.data?.data.filter((d) => d.entity._id !== chartId),
        }),
      );
    },
    [
      chartsState.data?.data,
      chartsState.data.pagination,
      dispatch,
      getAccessTokenSilently,
      loggedInUser,
    ],
  );

  const removeGraph = useCallback(
    async (graphId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (!token || !graphId || !loggedInUser) {
        return;
      }

      // Remove graph from chart object
      const chartObj = cloneDeep(chart);
      chartObj.graphs = chartObj.graphs.filter((g) => g._id !== graphId);

      // Remove graph if used in any areas
      chartObj.areas = chartObj?.areas?.map((area: ChartArea) => {
        const areaClone = cloneDeep(area);
        areaClone.panels = area.panels.map((panel: Panel) =>
          panel.graphId === graphId ? EMPTY_PANEL : panel,
        );

        return areaClone;
      });

      await dispatch(updateChart(token, chartObj, loggedInUser._id, portalId));

      // Remove graph from current charts
      const chartIndex = chartsState?.data?.data?.findIndex(
        (c) => c.entity._id === chart?._id,
      );
      if (chartIndex > -1) {
        const chartsObj = cloneDeep(chartsState.data.data);
        chartsObj[chartIndex].entity = chartObj;

        await dispatch(
          fetchChartsSuccess({
            pagination: chartsState.data.pagination,
            data: chartsObj,
          }),
        );
      }
    },
    [
      chart,
      chartsState.data.data,
      chartsState.data.pagination,
      dispatch,
      getAccessTokenSilently,
      loggedInUser,
    ],
  );

  const updateChartContent = useCallback(
    async (chart: Interfaces.ChartOutput) => {
      if (chartSaving) {
        return;
      }
      setChartSaving(true);
      await dispatch(
        fetchChartSuccess({
          accessLevel: chartAccessLevel || Enums.AccessLevel.MANAGE,
          entity: chart,
        }),
      );
      setChartSaving(false);
    },
    [chartAccessLevel, chartSaving, dispatch],
  );

  const updatePanelContent = useCallback((areaId, panelId, content) => {
    panelMap.set(`${areaId}-${panelId}`, content);
  }, []);

  const clearPanelMapContent = useCallback((panelMapId) => {
    panelMap.delete(panelMapId);
  }, []);

  useEffect(() => {
    if (chartObj) {
      // Complete model
      setChart(chartObj.entity);
      setChartAccessLevel(chartObj.accessLevel);
    }
  }, [chartObj]);

  return {
    chart,
    chartAccessLevel,
    chartSaving,

    getCharts,
    getChartsDirect,
    getChart,
    addChart,
    editChart,
    removeChart,

    updateChartContent,
    updatePanelContent,

    clearPanelMapContent,

    removeGraph,

    deleteChartArea,
    moveChartArea,
  };
};

export default useChart;
