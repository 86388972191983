import { Enums } from '@configur-tech/upit-core-types';
import { truncate } from 'lodash';
import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import sandboxAvatar from '../../assets/icons/project/sandbox-project-icon.svg';
import { SANDBOX_PROJECT } from '../../const/ProjectConst';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import useProject from '../../hooks/project/UseProject';
import {
  DefaultPopupStyles,
  TopBarHeaderLabel,
  TopBarHeaderTextWrapper,
  TopBarHeaderTitle,
} from '../../main/theme';
import { resetStagesAndSubStages } from '../../store/project-stage';
import { RootState } from '../../store/rootReducer';
import EntityColorMap from '../../util/color-helpers/EntityColorMap';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const TRUNCATE_LIMIT = 25;

const Wrapper = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.darkBlueShadow};
`;

const ProjectHeader: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { project } = useProject();
  const themeContext = useContext(ThemeContext);

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  if (
    !project?._id ||
    projectStageState.activeStage === Enums.ConnectionType.FORM
  ) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <FeatureButton
        isActive={location.pathname === RouteName.USER_ITEM}
        action={() => {
          dispatch(resetStagesAndSubStages());
          history.push(`${RouteName.PROJECT_ITEM}/${project._id}`);
        }}
        size={FeatureButtonSize.EXTRA_SMALL}
        color={EntityColorMap[EntityType.PROJECT]}
        borderColor={themeContext.colors.system.white}
        image={
          project.name === SANDBOX_PROJECT
            ? sandboxAvatar
            : project.avatar.includes('http')
            ? project?.avatar
            : { ...UserIconMap, ...AvatarIconMap }[project.avatar]
        }
      />
      <Popup
        content={project.name}
        position="right center"
        style={DefaultPopupStyles}
        disabled={project.name.length <= TRUNCATE_LIMIT}
        trigger={
          <TopBarHeaderTextWrapper
            onClick={() => {
              dispatch(resetStagesAndSubStages());
              history.push(`${RouteName.PROJECT_ITEM}/${project._id}`);
            }}
          >
            <TopBarHeaderLabel>Active Project</TopBarHeaderLabel>
            <TopBarHeaderTitle>
              {truncate(project.name, {
                length: TRUNCATE_LIMIT,
              })}
            </TopBarHeaderTitle>
          </TopBarHeaderTextWrapper>
        }
      />
    </Wrapper>
  );
};

export default ProjectHeader;
