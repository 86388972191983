import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import { RootState } from '../../store/rootReducer';
import { setSiteWideBanner } from '../../store/sitewide-banner';

interface useBannerResult {
  isSitewideBanner?: boolean;
  setSitewideBanner: (bannerStatus: boolean) => void;
}

const useSiteWideBanner = (): useBannerResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [isSitewideBanner, setIsSitewideBanner] = useState<boolean>(false);
  const bannerState = useSelector((state: RootState) => state.sitewideBanner);
  const bannerObj = bannerState.isSitewideBanner as boolean;

  const setSitewideBanner = useCallback(
    async (bannerStatus: boolean) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        dispatch(setSiteWideBanner(bannerStatus));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (bannerObj) {
      // Complete model
      setIsSitewideBanner(bannerObj);
    }
  }, [bannerObj]);

  return {
    isSitewideBanner,
    setSitewideBanner,
  };
};

export default useSiteWideBanner;
