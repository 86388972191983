import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useContext, useEffect, useState } from 'react';

import { DropdownItemProps } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledInput,
  StyledText,
} from '../../../../main/theme';

interface TextSubstringProps {
  field: Interfaces.Field;
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
  columnNameOptions: DropdownItemProps[];
}

const InputWrap = styled.div`
  display: flex;
  justify-content: center;

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const InputSubWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextSubstring: FC<TextSubstringProps> = ({
  field,
  setDataValidation,
  setSuperColumnValid,
  loaded,
  columnNameOptions,
}) => {
  const themeContext = useContext(ThemeContext);

  const [selectedColumnName, setSelectedColumnName] = useState<string>();
  const [startChar, setStartChar] = useState<string>();
  const [endChar, setEndChar] = useState<string>();
  const [numChars, setNumChars] = useState<number>();

  // Set values if updating existing
  useEffect(() => {
    if (field) {
      const opts = field.dataValidation
        ?.textTransformation as Interfaces.SubstringOp;

      if (opts) {
        setSelectedColumnName(opts.params.field);
        setStartChar((opts.params.start as Interfaces.CharToFind)?.match);
        setEndChar((opts.params.end as Interfaces.CharToFind)?.match);
        setNumChars(opts.params.numChars);
      }
    }
  }, [field]);

  // Set selected
  useEffect(() => {
    if (selectedColumnName) {
      setSuperColumnValid(true);
      setDataValidation({
        dataValidationType: Enums.ValueDataType.TEXT_TRANSFORMATION,
        textTransformation: {
          type: Enums.TextTransformOperation.SUBSTRING,
          params: {
            field: selectedColumnName,
            start: { match: startChar, isCaseInsensitive: true },
            end: endChar
              ? { match: endChar, isCaseInsensitive: true }
              : undefined,
            numChars: numChars,
          },
        } as Interfaces.SubstringOp,
      });
    } else {
      setSuperColumnValid(false);
    }
  }, [
    endChar,
    numChars,
    selectedColumnName,
    setDataValidation,
    setSuperColumnValid,
    startChar,
  ]);

  return (
    <>
      <StyledBodySubHeader>Substring</StyledBodySubHeader>

      <StyledText>
        This super column will select a part of the value based on the options
        you provide.
      </StyledText>

      <StyledText>Select which column you would like to transform.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select column'}
        options={columnNameOptions}
        selection
        search
        value={selectedColumnName}
        onChange={(e, data) => setSelectedColumnName(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />

      <StyledBodySubHeader>Start Character</StyledBodySubHeader>

      <StyledText>
        What character do you wish the selection to begin?
      </StyledText>

      <StyledInput
        placeholder={'Enter a start character'}
        value={startChar || ''}
        onChange={(e, data) => setStartChar(data.value)}
        style={{ marginTop: 0, marginBottom: themeContext.margin.large }}
      />

      <StyledBodySubHeader>Selection End</StyledBodySubHeader>

      <StyledText>
        Where would you like the selection to end? You can either enter a
        specific character or the number of characters to count from the start
        character.
      </StyledText>

      <InputWrap>
        <InputSubWrap>
          <StyledBodySubHeader>End Character</StyledBodySubHeader>
          <StyledInput
            disabled={numChars}
            placeholder={'Enter an end character'}
            value={endChar || ''}
            onChange={(e, data) => setEndChar(data.value)}
            style={{ marginTop: 0 }}
          />
        </InputSubWrap>

        <InputSubWrap>
          <StyledBodySubHeader>Character Count</StyledBodySubHeader>
          <StyledInput
            disabled={endChar?.length}
            type={'number'}
            placeholder={'Enter a character count'}
            value={numChars || 0}
            onChange={(e, data) => setNumChars(data.value)}
            style={{ marginTop: 0 }}
          />
        </InputSubWrap>
      </InputWrap>
    </>
  );
};

export default TextSubstring;
