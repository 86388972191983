import styled from 'styled-components';
import { StyledTextArea } from '../../../main/theme';
import { HeaderWrapper } from '../CMSFilter/styled';

interface CommentProps {
  isOwner?: boolean;
}

const COMMENT_META_FONT_SIZE = '12px';

export const CommentsHeaderWrapper = styled(HeaderWrapper)`
  width: 100%;
  padding-right: ${({ theme }) => theme.padding.standard};
  min-height: 37px;
`;

export const SidebarWrapper = styled.div`
  height: calc(100vh - 70px);
  overflow-y: auto;
  align-items: start;
  padding: ${({ theme }) =>
    `${theme.padding.standard} 0 ${theme.padding.standard} ${theme.padding.standard}`};
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
  align-self: center;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const CommentsWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin: ${({ theme }) => `${theme.margin.xxlarge} 0`};
  padding: ${({ theme }) => `0 ${theme.padding.standard} 0 0`};
  height: 80vh;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
`;

export const Comment = styled.li<CommentProps>`
  width: 100%;
  text-align: left;
  margin-bottom: ${({ theme }) => `${theme.margin.xxlarge}`};

  a {
    color: ${({ theme }) => theme.colors.system.white};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const CommentMessage = styled.div<CommentProps>`
  color: ${({ isOwner, theme }) =>
    !isOwner ? theme.colors.system.offBlack : theme.colors.general.white};
  background-color: ${({ isOwner, theme }) =>
    !isOwner ? theme.colors.system.grey : theme.colors.general.blue};
  padding: ${({ theme }) => theme.padding.standard};
  border-radius: ${({ isOwner, theme }) =>
    isOwner
      ? `${theme.borders.radius} ${theme.borders.radius} 0 ${theme.borders.radius}`
      : `${theme.borders.radius} ${theme.borders.radius} ${theme.borders.radius} 0`};
  white-space: pre-wrap;
`;

export const CommentMeta = styled.p<CommentProps>`
  margin-top: ${({ theme }) => `${theme.margin.small}`};
  color: ${({ theme }) => `${theme.colors.system.darkGrey}`};

  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.margin.small}`};
  font-size: ${COMMENT_META_FONT_SIZE};

  justify-content: ${({ isOwner }) => (isOwner ? 'flex-end' : 'flex-start')};
`;

export const CommentMetaName = styled.span`
  font-weight: bold;
`;

export const CommentInputWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.system.white};
  height: 175px;
  width: ${({ theme }) => `calc(100% - ${theme.margin.standard})`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CommentInputFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.system.offBlack};
  padding: ${({ theme }) => `${theme.padding.small}`};
  display: flex;
  justify-content: flex-end;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borders.radius} ${theme.borders.radius}`};
`;

export const CommentInput = styled(StyledTextArea)`
  flex-grow: 1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  resize: none;
`;

export const TextButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.general.blue};
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
`;
