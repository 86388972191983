import { Enums } from '@configur-tech/upit-core-types';
import { UserType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RouteName } from '../../enums/RouteName';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import useSiteWideBanner from '../../hooks/sitewide-banner/useSitewideBanner';
import DatasetHeader from '../DatasetHeader/DatasetHeader';
import PortalCMSHeader from '../PortalCmsHeader/PortalCMSHeader';
import PortalHeader from '../PortalHeader/PortalHeader';
import PrimaryNavigation from '../PrimaryNavigation/PrimaryNavigation';
import ProjectHeader from '../ProjectHeader/ProjectHeader';

interface PrimaryNavigationContainerProps {
  route: RouteName;
  connectionType?: Enums.ConnectionType | 'query';
}

const Wrapper = styled.div<{ lightTheme?: boolean; showBanner?: boolean }>`
  display: grid;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 1fr;

  ${({ showBanner }) =>
    showBanner ? ` height: calc(100vh - 58px);` : `height: 100vh;`};

  border-right: 1px solid
    ${({ theme, lightTheme }) =>
      lightTheme
        ? theme.colors.system.grey
        : theme.colors.system.darkBlueShadow};
`;

const PrimaryNavigationContainer: FC<PrimaryNavigationContainerProps> = ({
  route,
  connectionType,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const location = useLocation();
  const { datasetMeta } = useDatasetMeta();
  const { isSitewideBanner } = useSiteWideBanner();

  const headerComponent = () => {
    if (
      loggedInUser?.userType === UserType.PORTAL &&
      !location.pathname.includes(RouteName.PORTAL) &&
      location.pathname !== RouteName.PORTAL
    ) {
      return null;
    }

    switch (route) {
      case RouteName.CMS:
        if (location.pathname.includes('view')) {
          return <DatasetHeader />;
        }
        if (location.pathname.includes('portal')) {
          return <PortalCMSHeader />;
        }

        return <ProjectHeader />;
      case RouteName.DATASETS:
        if (datasetMeta?._id) {
          return <DatasetHeader />;
        } else {
          return <ProjectHeader />;
        }
      case RouteName.DATASET_ITEM:
      case RouteName.PIPELINE_ITEM:
        return <DatasetHeader />;
      case RouteName.WELCOME:
      case RouteName.APIS:
      case RouteName.NOTIFICATION_CENTRE:
        return null;
      case RouteName.PORTAL:
        return <PortalHeader />;
      default: {
        return <ProjectHeader />;
      }
    }
  };

  return (
    <Wrapper
      showBanner={isSitewideBanner}
      lightTheme={location.pathname.includes(RouteName.PORTAL)}
    >
      {headerComponent()}
      <PrimaryNavigation route={route} connectionType={connectionType} />
    </Wrapper>
  );
};

export default PrimaryNavigationContainer;
