import configurationAdvancedIcon from '../../assets/icons/stages/configuration/advanced.svg';
import createOptionsIcon from '../../assets/icons/stages/create-dataset/options.svg';
import createIcon from '../../assets/icons/stages/create-project/create.svg';
import structureIcon from '../../assets/icons/stages/structure-dataset/structure.svg';
import eventsIcon from '../../assets/icons/stages/dataset-project/events.svg';
import {
  PipelineConfigurationSubStage,
  PipelineCreationSubStage,
  PipelineStage,
  PipelineSubStages,
} from '../../store/pipeline-stage/initial-state';
import { SubStageContent } from '../stage-content/StageHeaderContent';

interface PipelineStageMeta {
  [key: string]: {
    title: string;
  };
}

interface PipelineSubStageContent {
  [PipelineStage.CREATION]: {
    subStages: Record<string, SubStageContent>;
  };
  [PipelineStage.CONFIGURATION]: {
    subStages: Record<string, SubStageContent>;
  };
}

const pipelineStageMeta: PipelineStageMeta = {
  [PipelineStage.CREATION]: {
    title: 'Create Pipeline',
  },
  [PipelineStage.CONFIGURATION]: {
    title: 'Caution Advised',
  },
};

export const getPipelineStageTitle = (stage: PipelineStage): string => {
  return pipelineStageMeta[stage].title;
};

export const getPipelineSubStageContent = (
  stage: PipelineStage,
  subStage: PipelineSubStages,
): SubStageContent => {
  const subStageContent: PipelineSubStageContent = {
    [PipelineStage.CREATION]: {
      subStages: {
        [PipelineCreationSubStage.META]: {
          stage: 1,
          image: createIcon,
          title: `Name Your Pipeline`,
        },
        [PipelineCreationSubStage.TRIGGER]: {
          stage: 2,
          image: createOptionsIcon,
          title: `Select A Trigger`,
        },
        [PipelineCreationSubStage.JOBS]: {
          stage: 3,
          image: structureIcon,
          title: `Build Your Pipeline Jobs`,
        },
        [PipelineCreationSubStage.EVENTS]: {
          stage: 4,
          image: eventsIcon,
          title: `Recent Pipelines`,
        },
      },
    },
    [PipelineStage.CONFIGURATION]: {
      subStages: {
        [PipelineConfigurationSubStage.ADVANCED]: {
          stage: 1,
          image: configurationAdvancedIcon,
          title: 'Caution Advised',
        },
      },
    },
  };

  return (
    subStageContent[stage].subStages[subStage] ||
    subStageContent[stage].subStages[0]
  );
};
