import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import defaultLogo from '../../assets/icons/system/codex-icon.svg';
import Card from '../../components/Card/Card';
import {
  Loader,
  LoaderContainer,
} from '../../components/CMS/CMSLinkedDatasets/styled';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import WelcomeHeader from '../../components/WelcomeHeader/WelcomeHeader';
import { RouteName } from '../../enums/RouteName';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import usePermission from '../../hooks/permission/UsePermission';
import usePortal from '../../hooks/portal/UsePortal';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { CardEntityWrapper, StyledH3, StyledText } from '../../main/theme';
import { PortalItemOutput } from '../../services/portal/PortalService';
import { resetStagesAndSubStages } from '../../store/project-stage';

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.xxxlarge};
`;

const StyledHeaderWelcome = styled(StyledH3)`
  margin: ${({ theme }) => `${theme.margin.standard} 0 0`};
`;

const StyledRecentsWrapper = styled(CardEntityWrapper)`
  width: 100%;
  max-width: 1090px;
  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.xxlarge}`};
`;

const NoResultsFound = styled(StyledText)`
  margin: ${({ theme }) =>
    `${theme.margin.standard} 0 ${theme.margin.xxlarge}`};
`;

const MAXIMUM_RENDERED_RECENT_ITEMS = 4;
const MAXIMUM_RENDERED_PORTAL_ITEMS = 99;

const WelcomePage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getPortals } = usePortal();
  const { isOrgManager } = usePermission();
  const { loggedInUser } = useLoggedInUser();
  const { organisation } = useOrganisation();

  const [portals, setPortals] = useState<PortalItemOutput[]>();
  const [accessiblePortals, setAccessiblePortals] =
    useState<PortalItemOutput[]>();

  const [portalsLoading, setPortalsLoading] = useState<boolean>();

  useEffect(() => {
    (async () => {
      if (typeof isOrgManager !== 'undefined') {
        try {
          setPortalsLoading(true);

          if (isOrgManager) {
            const [fetchedPortals] = await Promise.all([
              getPortals(undefined, true),
            ]);
            setPortals(fetchedPortals.data);
          } else {
            const [fetchedPortals] = await Promise.all([
              getPortals(undefined, true),
            ]);
            setPortals(fetchedPortals.data);
          }

          setPortalsLoading(false);
        } catch {
          setPortals([]);
          setPortalsLoading(false);
        }
      }
    })();
  }, [dispatch, getPortals, isOrgManager, loggedInUser?._id]);

  const orderValuesByDate = (
    values: PortalItemOutput[],
    maxLimit: number = MAXIMUM_RENDERED_RECENT_ITEMS,
  ) => {
    const cloned = cloneDeep(values);
    return cloned
      .sort((a, b) => {
        return new Date(a.entity.meta?.lastUpdated).getTime() >
          new Date(b.entity.meta?.lastUpdated).getTime()
          ? -1
          : 1;
      })
      .slice(0, maxLimit);
  };

  useEffect(() => {
    if (portals?.length) {
      const orderedPortals = orderValuesByDate(
        portals,
        MAXIMUM_RENDERED_PORTAL_ITEMS,
      );
      setAccessiblePortals(orderedPortals);
    }
  }, [portals]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.WELCOME} />
      <PageContainer>
        <Page>
          <PageInner>
            <WelcomeHeader title="Advanced Settings" hideCheckList={true} />

            <StyledHeaderWelcome style={{ marginTop: 60 }}>
              Accessible Portals
            </StyledHeaderWelcome>

            {portalsLoading && (
              <LoaderContainer>
                <Loader src={DefaultLoadingIcon} alt={'Loading'} />
              </LoaderContainer>
            )}

            {!portalsLoading && accessiblePortals?.length ? (
              <StyledRecentsWrapper>
                {accessiblePortals.map((portal, i) => {
                  const ent = portal.entity;
                  return (
                    <Card
                      key={`Portal - ${ent.name}-${i}`}
                      title={`${ent.name}`}
                      body={ent.description || ''}
                      image={organisation?.theme?.logo || defaultLogo}
                      action={() => {
                        dispatch(resetStagesAndSubStages());
                        window.open(
                          `${process.env['REACT_APP_PORTAL_DOMAIN']}${RouteName.PORTAL}/${ent._id}`,
                        );
                      }}
                      style={{
                        background: themeContext.colors.system.grey,
                      }}
                    />
                  );
                })}
              </StyledRecentsWrapper>
            ) : (
              !portalsLoading && (
                <NoResultsFound>No accessible portals found</NoResultsFound>
              )
            )}
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default WelcomePage;
