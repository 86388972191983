import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SchemaService, {
  DiscoveryResponse,
} from '../../services/schema/SchemaService';
import { hideLoading, showLoading } from '../../store/loading';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useDatasetMeta from './UseDatasetMeta';

interface useDiscoveryResponse {
  discoverDataset: () => Promise<DiscoveryResponse | undefined>;
}

const useDiscovery = (): useDiscoveryResponse => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { datasetMeta } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const sourceType = coll?.dataSource?.dataSourceType;
  const source = sourceType && coll?.dataSource?.[sourceType];

  const discoverDataset = useCallback(async (): Promise<
    DiscoveryResponse | undefined
  > => {
    if (loggedInUser) {
      if (
        [
          Enums.DataSourceType.CSV,
          Enums.DataSourceType.TXT,
          Enums.DataSourceType.XLS,
        ].includes(sourceType as Enums.DataSourceType)
      ) {
        dispatch(showLoading({ text: 'Building Schema...' }));

        const sourceFile = source as Interfaces.DataSourceFile;

        // Build and return sample data
        const head =
          sourceFile.headers === Enums.FileHeaderType.NONE
            ? undefined
            : sourceFile.headers;

        const token = await getAccessTokenSilently();
        try {
          const { schema, sample } = await SchemaService.discoverSchema(
            token,
            sourceFile.fileId,
            loggedInUser.organisationId,
            loggedInUser._id,
            head,
            sourceFile.headers === Enums.FileHeaderType.NONE,
            sourceFile.delimiter,
            sourceFile.sheetName,
          );

          dispatch(hideLoading());
          return { schema, sample };
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to analyse dataset.`,
          );
        }
      }
    }
  }, [dispatch, getAccessTokenSilently, loggedInUser, source, sourceType]);

  return {
    discoverDataset,
  };
};

export default useDiscovery;
