import { Enums } from '@configur-tech/upit-core-types';
import { useEffect, useState } from 'react';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useOrganisation from '../organisation/UseOrganisation';

interface usePermissionResult {
  isOrgManager: boolean | undefined;
  isProTier: boolean | undefined;
}

const usePermission = (): usePermissionResult => {
  const { loggedInUser } = useLoggedInUser();
  const { organisation } = useOrganisation();

  const [isOrgManager, setIsOrgManager] = useState<boolean>();
  const [isProTier, setIsProTier] = useState<boolean>();

  useEffect(() => {
    if (loggedInUser?._id && organisation?._id) {
      if (organisation.access.manage.user.includes(loggedInUser._id)) {
        setIsOrgManager(true);
      } else {
        setIsOrgManager(false);
      }

      if (
        [
          Enums.SubscriptionTier.PRO,
          Enums.SubscriptionTier.ENTERPRISE,
        ].includes(organisation.subscriptionDetails.subscriptionTier)
      ) {
        setIsProTier(true);
      }
    }
  }, [
    organisation?._id,
    organisation?.access.manage.user,
    loggedInUser?._id,
    organisation?.subscriptionDetails.subscriptionTier,
  ]);

  return {
    isOrgManager,
    isProTier,
  };
};

export default usePermission;
