import styled from 'styled-components';
import { StyledDropdown, StyledSubHeader } from '../../main/theme';
import { BodyText } from '../Modal/enhancement/styled';

const CARD_IMAGE_SIZE = 80;

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const Outer = styled.div<{ fullWidth: boolean }>`
  display: block;
  ${({ fullWidth }) => (fullWidth ? `width: 100%;` : '')};
  max-width: 100%;
  margin-top: ${({ theme }) => `${theme.margin.large}`};
`;

export const Wrapper = styled.div<{
  noBorder?: boolean;
  fixedHeight?: number;
  fixedHeightReduction?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;

  border: ${({ theme, noBorder }) =>
    noBorder ? '' : `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme }) => theme.borders.radius};

  max-height: ${({ fixedHeight, fixedHeightReduction }) =>
    fixedHeightReduction
      ? `calc(100vh - ${fixedHeightReduction}px)`
      : fixedHeight
      ? ` ${fixedHeight}px`
      : 'none'};

  > div {
    flex: 0 0 25%;
  }
`;

export const NoResults = styled.div`
  flex: 0 0 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge}`};
`;

export const SkipPageDropdown = styled(StyledDropdown)`
  margin: 0;
  width: 80px;
  min-width: 80px !important;
  max-width: 80px !important;
  text-align: center !important;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${({ theme }) => theme.margin.large};

  > p {
    margin: 0;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin-left: ${({ theme }) => theme.margin.standard};

    &:first-of-type {
      margin: 0;
    }
  }
`;

export const PaginationNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    margin: 0 ${({ theme }) => theme.margin.standard} 0 0;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const CardLogo = styled.div<{ image }>`
  width: ${CARD_IMAGE_SIZE}px;
  height: ${CARD_IMAGE_SIZE}px;

  margin-right: ${({ theme }) => theme.margin.standard};

  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${({ theme }) => theme.colors.system.white}
    url(${({ image }) => image}) no-repeat center / 75%;

  flex-shrink: 0;
`;

export const CardTitle = styled(StyledSubHeader)<{ hasBody: boolean }>`
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  ${({ hasBody }) => !hasBody && `margin-bottom: 0;`};
`;

export const CardDescription = styled(BodyText)`
  margin: 0;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const IntegrationButton = styled.div`
  align-self: center;
  justify-self: flex-end;
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
`;

export const TagList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: ${({ theme }) => theme.margin.standard} 0 0 0;
  width: 100%;
`;

export const TagListItem = styled.li`
  display: inline-block;
`;

export const Tag = styled.button<{ active: boolean }>`
  display: inline-block;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.general.purple};
  color: ${({ theme }) => theme.colors.general.white};
  padding: ${({ theme }) => theme.padding.small};
  border-radius: ${({ theme }) => theme.borders.radius};
  margin: ${({ theme }) => `0 ${theme.margin.small} ${theme.margin.small} 0`};
  transition: opacity 0.5s;

  opacity: ${({ active }) => (active ? 0.5 : 1)};

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
