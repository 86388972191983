import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import usePipelineEntityHelper from '../../../hooks/pipeline-mapping/UsePipelineEntityHelper';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { initialConditionalJobParams } from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import FilterBuilder from '../filter/FilterBuilder';
import { DropdownItemPropsWithValidation } from '../filter/FilterModal';

const CONDITIONAL_MATCH_TYPE_FIELD = 'matchType';
const CONDITIONAL_FILTER_FIELD = 'condition';

const ROW_CONDITION_OPTIONS = [
  Enums.ConditionalMatchType.ALL,
  Enums.ConditionalMatchType.ANY,
].map((match, index) => ({
  key: `conditional-match-${match}-${index}`,
  value: match,
  text: startCase(match.toLowerCase()),
}));

export interface JobConditionalModalProps {
  jobIndex: number;
  onChange: (data) => void;
  datasetMetaId?: string;
}

const JobConditionalModalComponent: FC<JobConditionalModalProps> = ({
  jobIndex,
  onChange,
  datasetMetaId,
}) => {
  const themeContext = useContext(ThemeContext);

  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();
  const { getLastDataJob, getEntitySchema } = usePipelineEntityHelper();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplates: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  const { pipelineTemplate } = usePipelineTemplate();

  const [pipelineJob, setPipelineJob] =
    useState<Interfaces.ConditionalJobParams>(initialConditionalJobParams);

  const [prevDataJob, setPrevDataJob] = useState<Interfaces.PipelineJob>();
  const [activeCollectionSchemaFields, setActiveCollectionSchemaFields] =
    useState<Interfaces.SchemaField[]>([]);

  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();
  const [filter, setFilter] = useState<DynamicConditionalField>({
    operator: Enums.AggregationFilterOperator.AND,
    value: [],
    active: true,
  });
  const [entitySchema, setEntitySchema] = useState<Interfaces.SchemaField[]>();

  const isEditing = jobIndex > -1;
  const prevDataJobIsQuery =
    prevDataJob?.jobType === Enums.PipelineJobType.QUERY;

  // If editing existing, set data
  useEffect(() => {
    if (
      pipelineTemplate?.jobs[jobIndex]?.jobType !==
      Enums.PipelineJobType.CONDITIONAL
    ) {
      return;
    }

    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      setPipelineJob(
        pipelineTemplate.jobs[jobIndex]
          .jobParams as Interfaces.ConditionalJobParams,
      );

      setFilter(
        ((
          pipelineTemplate.jobs[jobIndex]
            .jobParams as Interfaces.ConditionalJobParams
        ).condition[0] as unknown as Interfaces.DynamicFilterField) || [
          {
            operator: Enums.AggregationFilterOperator.AND,
            value: [],
            active: true,
          },
        ],
      );
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // Re-map activeDataCollection schema
  useEffect(() => {
    if (activeDataCollectionItem?._id) {
      setActiveCollectionSchemaFields(
        activeDataCollectionItem.schemaData.map((field) =>
          Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
        ),
      );
    }
  }, [activeDataCollectionItem?._id, activeDataCollectionItem?.schemaData]);

  // Get last job from pipeline
  useEffect(() => {
    if (pipelineTemplate?._id) {
      const lastJob =
        pipelineTemplate.jobs[
          isEditing ? jobIndex - 1 : pipelineTemplate.jobs.length - 1
        ];

      if (lastJob) {
        setPrevDataJob(getLastDataJob(pipelineTemplate.jobs, lastJob));
      }
    }
  }, [
    getLastDataJob,
    isEditing,
    jobIndex,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  // Use origin dataset if first job, or data schema from previous job
  useEffect(() => {
    if (activeCollectionSchemaFields) {
      if (!prevDataJob) {
        setEntitySchema(activeCollectionSchemaFields);
        return;
      }

      setEntitySchema(
        getEntitySchema(
          prevDataJob,
          datasetMetas,
          integrationTemplates,
          queries,
        ),
      );
    }
  }, [
    activeCollectionSchemaFields,
    datasetMetas,
    getEntitySchema,
    integrationTemplates,
    prevDataJob,
    queries,
  ]);

  // Set field options for filtering
  useEffect(() => {
    if (entitySchema) {
      const options: DropdownItemProps[] = entitySchema.map((f, i) => {
        return {
          key: `field-${f.field}-${i}`,
          value: `${prevDataJobIsQuery ? f.field : f.fieldId || f.field}***${
            datasetMetaId || datasetMeta?._id
          }***${f.field}`,
          text: f.field,
        };
      });

      setFieldOptions(options);
    }
  }, [
    datasetMeta?._id,
    datasetMetaId,
    entitySchema,
    prevDataJob?.jobType,
    prevDataJobIsQuery,
  ]);

  // Handle field change
  const handleFieldChange = (field: string, value: unknown) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[field] = value;
    setPipelineJob(cloned);
  };

  const handleFilterChange = (filter) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[CONDITIONAL_FILTER_FIELD] = [filter];

    setPipelineJob(cloned);
    setFilter(filter);
  };

  return (
    <>
      <StyledBodySubHeader
        style={{
          marginBottom: themeContext.margin.standard,
          marginTop: themeContext.margin.standard,
        }}
      >
        Row Condition
      </StyledBodySubHeader>
      <StyledText>
        How many rows of data need to pass this condition in order for the
        pipeline to continue?
      </StyledText>

      <StyledDropdown
        selectOnBlur={false}
        upward={true}
        selection
        value={pipelineJob[CONDITIONAL_MATCH_TYPE_FIELD] || ''}
        placeholder={'Please select a row condition'}
        options={ROW_CONDITION_OPTIONS}
        style={{ marginTop: 0 }}
        onChange={(e, { value }) =>
          handleFieldChange(CONDITIONAL_MATCH_TYPE_FIELD, value)
        }
      />

      <FilterBuilder
        entitySchema={entitySchema?.map(
          (field) =>
            Mappers.SchemaFieldMapper.domainSchemaToDbSchema(
              prevDataJobIsQuery ? { ...field, fieldId: field.field } : field,
            ) as Interfaces.FieldOutput,
        )}
        fieldOptions={fieldOptions}
        setFilter={handleFilterChange}
        filter={filter}
        schemaFieldOutput={true}
        smallHeadings={true}
        datasetMetaId={datasetMetaId}
      />
    </>
  );
};

export default JobConditionalModalComponent;
