import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, startCase } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { initialRequestEndpoint } from '../../../../store/integration-template/inital-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { hideModal } from '../../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import EndpointParamsAccordion from '../../../IntegrationItemStages/IntegrationCreationStages/4-endpoints/EndpointParamsAccordion';
import PayloadResponseAccordion from './PayloadResponseAccordion';
import * as SC from './styled';

const API_TYPE_REQUEST_REQUIRED = [
  Enums.ApiRequestType.PATCH,
  Enums.ApiRequestType.PUT,
  Enums.ApiRequestType.POST,
];

export interface IntegrationEndpointRequestModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  integrationTemplate: Interfaces.IntegrationTemplate;
  endpointIndex: number;
  requestIndex: number;
}

const IntegrationEndpointRequestModal: FC<
  IntegrationEndpointRequestModalProps
> = ({ setShowModal, endpointIndex, requestIndex }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { integrationTemplate, editIntegrationTemplate } =
    useIntegrationTemplate();

  const [paramsState, setParamsState] = useState<Interfaces.RequestParams>();
  const [paginationParamsState, setPaginationParamsState] =
    useState<Interfaces.PaginationParams>();
  const [payloadState, setPayloadState] = useState<Interfaces.RequestPayload>();
  const [generalRowInputs, setGeneralRowInputs] =
    useState<Interfaces.RequestEndpoint>(initialRequestEndpoint);
  const [responseInfo, setResponseInfo] = useState<Interfaces.ResponseInfo>({
    isArray: false,
    dataInTopLevel: false,
  });

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Handle General Settings Row
  const handleGeneralInputChange = (field: string, value: string) => {
    const cloned = cloneDeep(generalRowInputs);
    cloned[field] = value;
    setGeneralRowInputs(cloned);
  };

  // Set Values for Editing Endpoint
  useMemo(() => {
    if (requestIndex || requestIndex == 0) {
      const endpointQueryIndex = endpointIndex.toString();
      const requestQueryIndex = requestIndex.toString();
      if (
        integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
          requestQueryIndex
        ]
      ) {
        setGeneralRowInputs(
          integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
            requestQueryIndex
          ],
        );
      }
      if (
        integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
          requestQueryIndex
        ].responseInfo
      ) {
        setResponseInfo(
          integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
            requestQueryIndex
          ].responseInfo,
        );
      }
      if (
        integrationTemplate?.endpoints[endpointQueryIndex]?.endpoints[
          requestQueryIndex
        ].requestParams?.payload
      ) {
        setPayloadState(
          integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
            requestQueryIndex
          ].requestParams.payload,
        );
      }

      if (
        integrationTemplate?.endpoints[endpointQueryIndex]?.endpoints[
          requestQueryIndex
        ].requestParams
      ) {
        setParamsState(
          integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
            requestQueryIndex
          ].requestParams,
        );
      }

      if (
        integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
          requestQueryIndex
        ].paginationParams
      ) {
        setPaginationParamsState(
          integrationTemplate?.endpoints[endpointQueryIndex].endpoints[
            requestQueryIndex
          ].paginationParams,
        );
      }
    }
  }, [integrationTemplate, endpointIndex, requestIndex]);

  // Configure Dropdown Options
  const httpDropdownOptions = Object.values(Enums.ApiRequestType)
    .filter((i) => ![Enums.ApiRequestType.PATCH].includes(i))
    .map((i) => {
      return {
        key: i,
        text: startCase(i.toLowerCase()),
        value: i,
      };
    });

  // Handle Delete
  const deleteEndpoint = async () => {
    const cloned = cloneDeep(integrationTemplate);
    cloned.endpoints[endpointIndex].endpoints.splice(requestIndex, 1);
    await editIntegrationTemplate(cloned);
    dispatch(hideModal());
  };

  // Disable Button if Fields not updated
  const configurationComplete = (): boolean => {
    const paginationValid =
      !paginationParamsState ||
      (!!paginationParamsState.pageBasedPaginationParams?.initialPageNum &&
        !!paginationParamsState?.pageBasedPaginationParams?.pageSize &&
        ((!!paginationParamsState?.pageParam?.headers &&
          !!paginationParamsState?.pageParam?.headers[0]?.field) ||
          (!!paginationParamsState?.pageParam?.pathParams &&
            !!paginationParamsState?.pageParam?.pathParams[0]?.field) ||
          (!!paginationParamsState?.pageParam?.queryParams &&
            !!paginationParamsState?.pageParam?.queryParams[0]?.field)));

    const validGeneral =
      !!(
        generalRowInputs &&
        generalRowInputs.name &&
        generalRowInputs.httpMethod &&
        generalRowInputs.path
      ) && paginationValid;

    const validResponse = !!(
      (responseInfo?.dataInTopLevel ||
        (!responseInfo?.dataInTopLevel && responseInfo?.dataFieldKey)) &&
      responseInfo?.responseSchema?.length &&
      responseInfo.responseSchema.every(
        (schemaField) => schemaField.field?.length && schemaField.type?.length,
      )
    );

    const validRequest = !!(
      !API_TYPE_REQUEST_REQUIRED.includes(generalRowInputs.httpMethod) ||
      (API_TYPE_REQUEST_REQUIRED.includes(generalRowInputs.httpMethod) &&
        (payloadState?.dataInTopLevel ||
          (!payloadState?.dataInTopLevel && payloadState?.dataFieldKey)) &&
        payloadState?.payloadSchema?.length &&
        payloadState.payloadSchema.every(
          (schemaField) =>
            schemaField.field?.length && schemaField.type?.length,
        ))
    );

    const validParams =
      !paramsState ||
      Object.values(paramsState).every(
        (paramArr) =>
          !Array.isArray(paramArr) ||
          paramArr.every(
            (param) => param.field?.length && param.value?.value?.length,
          ),
      );

    return !(validGeneral && validResponse && validRequest && validParams);
  };

  // Handle Payload change
  const handlePayloadChange = useCallback(
    (
      response?: Interfaces.ResponseInfo,
      payload?: Interfaces.RequestPayload,
    ) => {
      if (response) {
        setResponseInfo(response);
      }

      if (payload) {
        setPayloadState(payload);
      }
    },
    [],
  );

  // Handle Params change
  const handleParamsChange = useCallback((params: Interfaces.RequestParams) => {
    setParamsState(params);
  }, []);

  // Handle Pagination Params change
  const handlePaginationChange = useCallback(
    (params?: Interfaces.PaginationParams) => {
      setPaginationParamsState(params);
    },
    [],
  );

  // Handle Submission
  const processAction = async () => {
    const cloned = cloneDeep(integrationTemplate);

    let completeRequestParams =
      paramsState || payloadState
        ? { ...paramsState, payload: payloadState }
        : undefined;
    // If requestParams has been part populated, ensure required fields are present
    if (
      completeRequestParams &&
      completeRequestParams?.payload &&
      Object.keys(completeRequestParams?.payload).length
    ) {
      completeRequestParams = {
        ...completeRequestParams,
        payload: {
          ...completeRequestParams.payload,
          isEntirePayloadArray:
            completeRequestParams.payload?.isEntirePayloadArray || false,
          dataInTopLevel:
            completeRequestParams.payload?.dataInTopLevel || false,
        },
      };
    }

    // Update Endpoint
    if (requestIndex || requestIndex == 0) {
      cloned.endpoints[endpointIndex].endpoints[requestIndex] = {
        ...generalRowInputs,
        requestParams: completeRequestParams,
        paginationParams: paginationParamsState,
        responseInfo,
      };
    }
    // Handle Creating new endpoint
    else {
      cloned.endpoints[endpointIndex].endpoints.push({
        ...generalRowInputs,
        requestParams: completeRequestParams,
        paginationParams: paginationParamsState,
        responseInfo,
      });
    }

    dispatch(showLoading({ text: 'Saving Endpoint...' }));
    await editIntegrationTemplate(cloned);
    dispatch(hideLoading());
    dispatch(hideModal());
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>Manage Request Endpoint</SC.Header>

        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={themeContext.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>
      <SC.ContentContainer>
        <SC.Content>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xlarge,
            }}
          >
            General
          </StyledSubHeader>
          <StyledText
            style={{
              marginBottom: themeContext.margin.xxlarge,
            }}
          >
            First, name your endpoint and tell us what the URL path for the
            endpoint is.
          </StyledText>

          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader>Name</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a name'}
                value={generalRowInputs?.name || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('name', value)
                }
              />
            </SC.InputContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Description</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a description'}
                value={generalRowInputs?.description || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('description', value)
                }
              />
            </SC.InputContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Request Path</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a request path'}
                value={generalRowInputs?.path || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('path', value)
                }
              />
            </SC.InputContainer>
          </SC.InputWrapper>

          <StyledBodySubHeader
            style={{ marginTop: themeContext.margin.xxlarge }}
          >
            HTTP Method
          </StyledBodySubHeader>
          <SC.StyledInputColumn>
            <StyledDropdown
              selectOnBlur={false}
              placeholder={'Select a Data Type'}
              selection
              value={generalRowInputs.httpMethod.toString()}
              options={httpDropdownOptions}
              onChange={(e, { value }) =>
                handleGeneralInputChange('httpMethod', value)
              }
            />
          </SC.StyledInputColumn>

          <PayloadResponseAccordion
            payload={payloadState}
            response={responseInfo}
            onChange={handlePayloadChange}
            payloadRequired={[
              Enums.ApiRequestType.PATCH,
              Enums.ApiRequestType.PUT,
              Enums.ApiRequestType.POST,
            ].includes(generalRowInputs.httpMethod)}
            responseRequired={true}
          />

          <EndpointParamsAccordion
            onChange={handleParamsChange}
            onChangePagination={handlePaginationChange}
            paramsData={paramsState}
            paginationParamsData={paginationParamsState}
          />
        </SC.Content>
      </SC.ContentContainer>
      <SC.ActionButtonWrapper multipleButtons={requestIndex !== undefined}>
        {requestIndex !== undefined && (
          <FeatureButton
            action={deleteEndpoint}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={'Delete'}
          />
        )}
        <FeatureButton
          action={processAction}
          isDisabled={configurationComplete()}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Save'}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default IntegrationEndpointRequestModal;
