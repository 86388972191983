import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NotificationAlertService, {
  NotificationAlertItem,
  NotificationAlertItemOutput,
} from '../../services/notification-alert/NotificationAlertService';
import { ResourceDetails } from '../notification';
import { AppThunk } from '../store';
import InitialNotificationAlert from './initial-notification-alert';

type NotificationAlert = NotificationAlertItem | NotificationAlertItemOutput;

export interface NotificationAlertState {
  data: NotificationAlert;
  loading: boolean;
  error: string | null;
}

export interface NotificationAlertWithDetails
  extends Interfaces.NotificationAlert {
  additionalDetails?: ResourceDetails;
}

export interface NotificationAlertOutputWithDetails
  extends Interfaces.NotificationAlertOutput {
  additionalDetails?: ResourceDetails;
}

const initialState: NotificationAlertState = {
  data: InitialNotificationAlert,
  loading: false,
  error: null,
};

const NotificationAlertSlice = createSlice({
  name: 'notification-alert',
  initialState,
  reducers: {
    // Reset
    resetNotificationAlert(state) {
      state.data = InitialNotificationAlert;
      state.loading = false;
      state.error = null;
    },
    // Fetch
    fetchNotificationAlertStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchNotificationAlertSuccess(
      state,
      action: PayloadAction<NotificationAlert>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchNotificationAlertFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createNotificationAlertStart(state) {
      state.loading = true;
      state.error = null;
    },
    createNotificationAlertSuccess(
      state,
      action: PayloadAction<NotificationAlert>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createNotificationAlertFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateNotificationAlertStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateNotificationAlertSuccess(
      state,
      action: PayloadAction<NotificationAlert>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateNotificationAlertFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteNotificationAlertStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteNotificationAlertSuccess(
      state,
      action: PayloadAction<NotificationAlert>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteNotificationAlertFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetNotificationAlert,
  fetchNotificationAlertStart,
  fetchNotificationAlertSuccess,
  fetchNotificationAlertFailure,
  createNotificationAlertStart,
  createNotificationAlertSuccess,
  createNotificationAlertFailure,
  updateNotificationAlertStart,
  updateNotificationAlertSuccess,
  updateNotificationAlertFailure,
  deleteNotificationAlertStart,
  deleteNotificationAlertSuccess,
  deleteNotificationAlertFailure,
} = NotificationAlertSlice.actions;

export default NotificationAlertSlice.reducer;

export const fetchNotificationAlert =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchNotificationAlertStart());
      const fetched = await NotificationAlertService.getNotificationAlert(
        token,
        id,
      );
      dispatch(fetchNotificationAlertSuccess(fetched));
    } catch (err) {
      dispatch(fetchNotificationAlertFailure((err as string).toString()));
    }
  };

export const createNotificationAlert =
  (
    token: string,
    notification: Interfaces.NotificationAlert,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationAlertStart());
      const created = await NotificationAlertService.postNotificationAlert(
        token,
        notification,
        userId,
      );
      dispatch(createNotificationAlertSuccess(created));
    } catch (err) {
      dispatch(createNotificationAlertFailure((err as string).toString()));
    }
  };

export const updateNotificationAlert =
  (
    token: string,
    notification: Interfaces.NotificationAlertOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateNotificationAlertStart());
      const updated = await NotificationAlertService.putNotificationAlert(
        token,
        userId,
        notification,
      );
      dispatch(updateNotificationAlertSuccess(updated));
    } catch (err) {
      dispatch(updateNotificationAlertFailure((err as string).toString()));
    }
  };

export const deleteNotificationAlert =
  (token: string, notificationId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteNotificationAlertStart());
      const deleted = await NotificationAlertService.deleteNotificationAlert(
        token,
        notificationId,
        userId,
      );
      dispatch(deleteNotificationAlertSuccess(deleted));
    } catch (err) {
      dispatch(deleteNotificationAlertFailure((err as string).toString()));
    }
  };
