import { Enums } from '@configur-tech/upit-core-types';
import { CommentItem } from '../comments';

const InitialComment: CommentItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },
    organisationId: '',
    resourceType: Enums.SchemaName.COMMENT,
    resourceId: '',
    message: {
      type: Enums.CommentMessageType.TEXT,
    },
    status: Enums.CommentStatus.CREATED,
  },
};

export default InitialComment;
