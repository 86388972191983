import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DataLinkRelationship } from '@configur-tech/upit-core-types/lib/interfaces/models/input/data-link/DataLinkRelationship';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../assets/icons/loading/default-loading-icon.gif';
import { EntityType, RouteName } from '../../../enums';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useDataset from '../../../hooks/dataset/UseDataset';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import useList from '../../../hooks/list/UseList';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import { StyledSubHeader } from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import buildTableData from '../../../util/build-table-data/BuildTableData';
import { getActiveDataCollection } from '../../../util/dataset-meta-collection/getActiveDataCollection';
import AvatarIconMap from '../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

const PRIMARY_FIELD = 'primary';
const SECONDARY_FIELD = 'secondary';

interface CMSLinkedDatasetsProps {
  cmsId: string;
  rowEntry: Record<string, string | number>;
  portalId?: string;
  groupId?: string;
  addingLinkedDatasetRow?: string;
  setAddingLinkedDatasetRow?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setLinkedDatasetFilters?: React.Dispatch<
    React.SetStateAction<LinkedDatasetQuery[]>
  >;
}

export interface LinkedDatasetQuery {
  dataset: string;
  query: DynamicConditionalField[];
}

const CMSLinkedDatasets: FC<CMSLinkedDatasetsProps> = ({
  cmsId,
  rowEntry,
  portalId,
  groupId,
  addingLinkedDatasetRow,
  setAddingLinkedDatasetRow,
  setLinkedDatasetFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { project } = useProject();
  const { portal } = usePortal();
  const { datasetMeta } = useDatasetMeta();
  const { getDatasetDirect } = useDataset();
  const { getDatasetMetas } = useDatasetMeta();
  const { cms } = useCMS();
  const { getListsForMultipleResources } = useList();

  const [fetchingLinkedData, setFetchingLinkedData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadedDsm, setLoadedDsm] = useState(false);
  const [loadedLinkedLists, setLoadedLinkedLists] = useState<boolean>(false);

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [linkedDatasets, setLinkedDatasets] = useState<
    Record<string, Record<string, unknown>[]>
  >({});

  const [linkQueries, setLinkQueries] = useState<LinkedDatasetQuery[]>([]);

  const buildSuperColQuery = useCallback(
    (
      entry: Record<string, unknown>,
      value: string,
      lookup: DataLinkRelationship['primary'],
    ): DynamicConditionalField[] | undefined => {
      const lookupFieldDataset = datasetMetas.find(
        (d) => d.entity._id === lookup.datasetMetaId,
      );
      const lookupFieldCollection =
        lookupFieldDataset?.entity.dataCollections.find(
          (c) => lookupFieldDataset.entity.activeDataCollection === c._id,
        );
      const lookupFieldSchemaField = lookupFieldCollection?.schemaData?.find(
        (f) => f.name === lookup.field,
      );

      if (
        lookupFieldSchemaField?.dataValidation?.dataValidationType ===
        Enums.ValueDataType.TEXT_TRANSFORMATION
      ) {
        const textTransOptions =
          lookupFieldSchemaField.dataValidation.textTransformation;

        // Concat
        if (textTransOptions?.type === Enums.TextTransformOperation.CONCAT) {
          if (!value) {
            return;
          }

          const split = value.split(textTransOptions.params.delimiter);

          return textTransOptions.params.fields.reduce(
            (acc: DynamicConditionalField[], field, index) => {
              return [
                ...acc,
                {
                  field,
                  datasetMetaId: lookup.datasetMetaId,

                  operator: Enums.AggregationFilterOperator.EQUAL,
                  value: {
                    type: Enums.DynamicConditionalType.CONSTANT,
                    value: split[index],
                  },
                },
              ];
            },
            [],
          );
        }

        // Case transformations
        if (
          textTransOptions?.type &&
          [
            Enums.TextTransformOperation.UPPER_CASE,
            Enums.TextTransformOperation.LOWER_CASE,
            Enums.TextTransformOperation.SNAKE_CASE,
            Enums.TextTransformOperation.CAMEL_CASE,
          ].includes(textTransOptions?.type)
        ) {
          const type = textTransOptions as Interfaces.SingleFieldOp;

          if (!entry[type.params.field]) {
            return;
          }

          return [
            {
              field: type.params.field,
              datasetMetaId: lookup.datasetMetaId,

              operator: Enums.AggregationFilterOperator.EQUAL,
              value: {
                type: Enums.DynamicConditionalType.CONSTANT,
                value: entry[type.params.field],
              },
            },
          ] as DynamicConditionalField[];
        }
      }
    },
    [datasetMetas],
  );

  // Load any linked DatasetMetas and Lists
  useEffect(() => {
    if (!datasetMeta?._id) {
      return;
    }

    (async () => {
      const modelItems = portalId ? portal?.modellingItems : project?.dataModel;

      const linkedDSIdsMap = new Set<string>();
      modelItems
        ?.filter(
          (item) =>
            item.primary.datasetMetaId === datasetMeta?._id ||
            item.secondary.datasetMetaId == datasetMeta?._id,
        )
        ?.map((item) => {
          linkedDSIdsMap.add(item.primary.datasetMetaId);
          linkedDSIdsMap.add(item.secondary.datasetMetaId);
        });

      const linkedDatasetIds = Array.from(linkedDSIdsMap).filter(
        (id) => id !== datasetMeta?._id,
      );

      const loadedDsmIds = datasetMetas.map((dsm) => dsm.entity._id);
      const linkedLoadingComplete = linkedDatasetIds?.every((id) =>
        loadedDsmIds.includes(id),
      );

      if (linkedDatasetIds) {
        if (!linkedLoadingComplete) {
          await getDatasetMetas(
            {
              _id: { $in: [...(linkedDatasetIds || [])] },
            },
            undefined,
            undefined,
            undefined,
            undefined,
            portalId,
          );
        }
      }
      if (!loadedLinkedLists) {
        await getListsForMultipleResources(
          Enums.SchemaName.DATASET_META,
          linkedDatasetIds,
        );

        setLoadedLinkedLists(true);
      }
      setLoadedDsm(true);
    })();
  }, [
    datasetMeta?._id,
    datasetMetas,
    getDatasetMetas,
    getListsForMultipleResources,
    linkedDatasets,
    loadedDsm,
    loadedLinkedLists,
    portal?.modellingItems,
    portalId,
    project?.dataModel,
  ]);

  // Reload data when addingLinkedDatasetRow changes
  useEffect(() => {
    if (!addingLinkedDatasetRow) {
      setLoadedDsm(false);
      setFetchingLinkedData(false);
    }
  }, [addingLinkedDatasetRow]);

  // Check data model for linked datasets
  useEffect(() => {
    if (
      !fetchingLinkedData &&
      (portalId ? portal?.modellingItems?.length : project?.dataModel.length) &&
      datasetMeta &&
      loadedDsm
    ) {
      (async () => {
        const clonedLinked = cloneDeep(linkedDatasets);

        const modelItems = portalId
          ? portal?.modellingItems
          : project?.dataModel;

        const datasetQueries: {
          dataset: string;
          query: DynamicConditionalField[];
        }[] = [];

        for (const link of modelItems || []) {
          let lookup;
          if (link.primary.datasetMetaId === datasetMeta._id) {
            lookup = SECONDARY_FIELD;
          } else if (link.secondary.datasetMetaId === datasetMeta._id) {
            lookup = PRIMARY_FIELD;
          }

          if (lookup) {
            const loadedLookupDatasetMeta = datasetMetas.find(
              (dsm) => dsm.entity._id === link[lookup].datasetMetaId,
            )?.entity;

            const lookupField =
              link[lookup === PRIMARY_FIELD ? SECONDARY_FIELD : PRIMARY_FIELD]
                .field;

            const lookupCollection =
              loadedLookupDatasetMeta?.dataCollections.find(
                (c) => c._id === loadedLookupDatasetMeta.activeDataCollection,
              );
            const lookupTableName = lookupCollection?.dataCollection?.tableName;
            const lookupFieldOutput = lookupCollection?.schemaData?.find(
              (f) =>
                f.fieldId === link[lookup].field ||
                f.name === link[lookup].field,
            );
            const lookupFieldIsList =
              !!lookupFieldOutput?.dataValidation?.constraints?.listValues;

            const primaryCollection = getActiveDataCollection(datasetMeta);
            const primaryFieldName = primaryCollection?.schemaData?.find(
              (f) => f.fieldId === lookupField || f.name === lookupField,
            )?.name;

            const value = primaryFieldName && rowEntry[primaryFieldName];

            const existingQuery = datasetQueries.find(
              (dq) => dq.dataset === link[lookup].datasetMetaId,
            );

            if (value && lookupTableName) {
              const queryFilters = buildSuperColQuery(
                rowEntry,
                (value as string).toString(),
                link[lookup],
              );

              if (!existingQuery) {
                datasetQueries.push({
                  dataset: link[lookup].datasetMetaId,
                  query: queryFilters || [
                    {
                      field: link[lookup].field,
                      datasetMetaId: link[lookup].datasetMetaId,
                      alias: lookupFieldOutput?.name,

                      operator: lookupFieldIsList
                        ? Enums.AggregationFilterOperator.IN
                        : Enums.AggregationFilterOperator.EQUAL,
                      value: {
                        type: Enums.DynamicConditionalType.CONSTANT,
                        value: lookupFieldIsList
                          ? [(value as string).toString()]
                          : (value as string).toString(),
                      },
                    },
                  ],
                });
              } else {
                if (
                  Array.isArray(
                    (existingQuery.query?.[0] as Interfaces.AggregateFilter)
                      .value,
                  )
                ) {
                  (
                    (existingQuery.query?.[0] as Interfaces.AggregateFilter)
                      .value as Interfaces.StandardFilterField[]
                  ).push({
                    field: link[lookup].field,
                    datasetMetaId: link[lookup].datasetMetaId,

                    operator: lookupFieldIsList
                      ? Enums.AggregationFilterOperator.IN
                      : Enums.AggregationFilterOperator.EQUAL,
                    value: {
                      type: Enums.DynamicConditionalType.CONSTANT,
                      value: lookupFieldIsList
                        ? [(value as string).toString()]
                        : (value as string).toString(),
                    },
                  });
                } else {
                  existingQuery.query = [
                    {
                      operator: Enums.AggregationFilterOperator.OR,
                      value: [
                        {
                          field: link[lookup].field,
                          datasetMetaId: link[lookup].datasetMetaId,
                          operator: lookupFieldIsList
                            ? Enums.AggregationFilterOperator.IN
                            : Enums.AggregationFilterOperator.EQUAL,
                          value: {
                            type: Enums.DynamicConditionalType.CONSTANT,
                            value: lookupFieldIsList
                              ? [(value as string).toString()]
                              : (value as string).toString(),
                          },
                        },
                      ],
                    },
                  ];
                }
              }
            } else {
              if (!existingQuery) {
                clonedLinked[link[lookup].datasetMetaId] = [];
              }
            }
          }
        }

        await Promise.all(
          datasetQueries.map(async (dq) => {
            try {
              const fetched = await getDatasetDirect(
                dq.dataset,
                dq.query,
                1,
                cmsId,
                groupId,
                portalId,
              );

              clonedLinked[dq.dataset] = fetched.entries?.length
                ? fetched.entries
                : [];
            } catch (err) {
              clonedLinked[dq.dataset] = [];
            }
          }),
        );

        setLinkedDatasetFilters && setLinkedDatasetFilters(datasetQueries);
        setLinkedDatasets(clonedLinked);
        setLinkQueries(datasetQueries);
        setLoading(false);
        setFetchingLinkedData(true);
      })();
    }
  }, [
    buildSuperColQuery,
    cmsId,
    datasetMeta,
    datasetMetas,
    fetchingLinkedData,
    setLinkedDatasetFilters,
    getDatasetDirect,
    getDatasetMetas,
    groupId,
    linkedDatasets,
    loadedDsm,
    portal?.modellingItems,
    portalId,
    project?.dataModel,
    rowEntry,
  ]);

  return (
    <SC.LinkedWrapper>
      <SC.Header>Linked Datasets</SC.Header>

      {loading && (
        <SC.LoaderContainer>
          <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />
        </SC.LoaderContainer>
      )}

      {!loading && Object.entries(linkedDatasets).length === 0 && (
        <SC.NoResultsText>No linked data found</SC.NoResultsText>
      )}

      {Object.entries(linkedDatasets).map(([dsmId, re], i) => {
        const dsm: Interfaces.DatasetMetaOutput | undefined = datasetMetas.find(
          (d) => d.entity._id === dsmId,
        )?.entity;

        if (dsm) {
          const schema = dsm?.dataCollections.find(
            (c) => c._id === dsm.activeDataCollection,
          )?.schemaData;

          if (schema) {
            const tableData = buildTableData(schema, re);

            if (tableData.schema) {
              return (
                <SC.LinkedDataset key={`linked-dataset-${dsm.name}-${i}`}>
                  <SC.LinkedDatasetHeader>
                    <StyledSubHeader>{dsm.name}</StyledSubHeader>

                    {cmsId && (
                      <SC.HeaderButtons>
                        <FeatureButton
                          isDisabled={!!addingLinkedDatasetRow?.length}
                          action={() => {
                            setAddingLinkedDatasetRow &&
                              setAddingLinkedDatasetRow(dsm._id);
                          }}
                          size={FeatureButtonSize.WIDE_SMALL}
                          color={themeContext.colors.general.green}
                          text={'Add New Row'}
                        />

                        <FeatureButton
                          action={() => {
                            dispatch(hideModal());

                            const query = linkQueries.find(
                              (q) => q.dataset === dsm._id,
                            );

                            let formattedQuery;
                            if (query) {
                              formattedQuery = [
                                {
                                  operator: Enums.AggregationFilterOperator.AND,
                                  value: [
                                    {
                                      operator:
                                        Enums.AggregationFilterOperator.AND,
                                      value: query.query,
                                      active: true,
                                    },
                                  ],
                                },
                              ];
                            }

                            // Find relevant group
                            const group = (
                              cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
                            ).groups.reduce((acc: string, group) => {
                              const itemWithDataset = group.items.find(
                                (item) => item.datasetMetaId === dsm._id,
                              );

                              if (
                                itemWithDataset &&
                                (!acc || group._id === groupId)
                              ) {
                                return group._id;
                              }

                              return acc;
                            }, '');

                            history.push({
                              pathname: portalId
                                ? `${RouteName.PORTAL}/${portal?._id}/${EntityType.CMS}/${cmsId}/${group}/${EntityType.DATASET}/${dsm._id}`
                                : `${RouteName.PROJECT_ITEM}/${project?._id}/${EntityType.CMS}/${cmsId}/${group}/${EntityType.DATASET}/${dsm._id}`,
                              state: formattedQuery
                                ? {
                                    filters: JSON.stringify(formattedQuery),
                                    filterType: EntityType.DATASET,
                                  }
                                : undefined,
                            });
                          }}
                          size={FeatureButtonSize.WIDE_SMALL}
                          color={themeContext.colors.general.blue}
                          text={'View Dataset'}
                        />
                      </SC.HeaderButtons>
                    )}
                  </SC.LinkedDatasetHeader>

                  <DataSample
                    schema={tableData.schema}
                    showPagination={false}
                    sampleColumns={schema}
                    sampleRows={tableData.rows || []}
                    iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                  />
                </SC.LinkedDataset>
              );
            }
          }
        }

        return null;
      })}
    </SC.LinkedWrapper>
  );
};

export default CMSLinkedDatasets;
