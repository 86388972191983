import { Enums } from '@configur-tech/upit-core-types';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import getDataTypeIcon from '../../util/data-type-icon/DataTypeIcon';
import * as SC from './styled';

interface DateTimePickerProps {
  onChange: (date) => void;
  value?: string;
  dateFormat?: Enums.DateFormat | string;
  hideIcon?: boolean;
  popperPlacement?: string;
  popperModifiers?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  isLiveEditing?: boolean;
}

export const TIME_INTERVAL_HOUR = 60;
export const TIME_INTERVAL_MINUTE = 1;

const ZZ_TIMEZONE = 'ZZ';
const ZZ_TIMEZONE_REPLACEMENT = 'xxx';
const ZZZ_TIMEZONE = 'ZZZ';
const ZZZ_TIMEZONE_REPLACEMENT = 'xx';

export const escapeDateFormatChars = (format: string) => {
  return format
    .replace(ZZZ_TIMEZONE, ZZZ_TIMEZONE_REPLACEMENT)
    .replace(ZZ_TIMEZONE, ZZ_TIMEZONE_REPLACEMENT);
};

export const timeRequiredFormats = [
  Enums.DateFormat.TIME_SIMPLE,
  Enums.DateFormat.TIME_WITH_SECONDS,
  Enums.DateFormat.TIME_24_SIMPLE,
  Enums.DateFormat.TIME_24_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORTEST,
  Enums.DateFormat.DATETIME_SHORTEST_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_US,
  Enums.DateFormat.DATETIME_SHORTEST_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT,
  Enums.DateFormat.DATETIME_SHORT_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_US,
  Enums.DateFormat.DATETIME_SHORT_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_MED,
  Enums.DateFormat.DATETIME_MED_UNPADDED,
  Enums.DateFormat.DATETIME_FULL,
  Enums.DateFormat.DATETIME_FULL_UNPADDED,
  Enums.DateFormat.DATETIME_HUGE,
  Enums.DateFormat.DATETIME_HUGE_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST,
  Enums.DateFormat.DATETIME_24_SHORTEST_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT,
  Enums.DateFormat.DATETIME_24_SHORT_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_US,
  Enums.DateFormat.DATETIME_24_SHORT_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_MED,
  Enums.DateFormat.DATETIME_24_MED_UNPADDED,
  Enums.DateFormat.DATETIME_24_FULL,
  Enums.DateFormat.DATETIME_24_FULL_UNPADDED,
  Enums.DateFormat.DATETIME_24_HUGE,
  Enums.DateFormat.DATETIME_24_HUGE_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.ISO,
  Enums.DateFormat.ISO_SHORT,
  Enums.DateFormat.ISO_8601,
  Enums.DateFormat.ISO_8601_NO_COLON,
  Enums.DateFormat.ISO_8601_NO_MS,
  Enums.DateFormat.ISO_8601_NO_MS_NO_COLON,
];

export const dateTimeFormats = [
  Enums.DateFormat.DATETIME_SHORTEST,
  Enums.DateFormat.DATETIME_SHORTEST_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_US,
  Enums.DateFormat.DATETIME_SHORTEST_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORTEST_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT,
  Enums.DateFormat.DATETIME_SHORT_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_US,
  Enums.DateFormat.DATETIME_SHORT_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_MED,
  Enums.DateFormat.DATETIME_MED_UNPADDED,
  Enums.DateFormat.DATETIME_FULL,
  Enums.DateFormat.DATETIME_FULL_UNPADDED,
  Enums.DateFormat.DATETIME_HUGE,
  Enums.DateFormat.DATETIME_HUGE_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORTEST_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST,
  Enums.DateFormat.DATETIME_24_SHORTEST_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT,
  Enums.DateFormat.DATETIME_24_SHORT_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_US,
  Enums.DateFormat.DATETIME_24_SHORT_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_MED,
  Enums.DateFormat.DATETIME_24_MED_UNPADDED,
  Enums.DateFormat.DATETIME_24_FULL,
  Enums.DateFormat.DATETIME_24_FULL_UNPADDED,
  Enums.DateFormat.DATETIME_24_HUGE,
  Enums.DateFormat.DATETIME_24_HUGE_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORTEST_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_US,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_US,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA_US_UNPADDED,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS_UNPADDED,
  Enums.DateFormat.ISO,
  Enums.DateFormat.ISO_SHORT,
  Enums.DateFormat.ISO_8601,
  Enums.DateFormat.ISO_8601_NO_COLON,
  Enums.DateFormat.ISO_8601_NO_MS,
  Enums.DateFormat.ISO_8601_NO_MS_NO_COLON,
];

export const timeOnlyFormats = [
  Enums.DateFormat.TIME_SIMPLE,
  Enums.DateFormat.TIME_WITH_SECONDS,
  Enums.DateFormat.TIME_24_SIMPLE,
  Enums.DateFormat.TIME_24_WITH_SECONDS,
  Enums.DateFormat.HOUR_12_UNPADDED,
  Enums.DateFormat.HOUR_12_PADDED,
  Enums.DateFormat.HOUR_24_UNPADDED,
  Enums.DateFormat.HOUR_24_PADDED,
  Enums.DateFormat.MINUTE_UNPADDED,
  Enums.DateFormat.MINUTE_PADDED,
  Enums.DateFormat.SECOND_UNPADDED,
  Enums.DateFormat.SECOND_PADDED,
];

export const weekOnlyFormats = [
  Enums.DateFormat.WEEK_NUM_UNPADDED,
  Enums.DateFormat.WEEK_NUM_PADDED,
];

export const monthOnlyFormats = [
  Enums.DateFormat.MONTH_NUM_UNPADDED,
  Enums.DateFormat.MONTH_NUM_PADDED,
  Enums.DateFormat.MONTH_SHORT_NAME,
  Enums.DateFormat.MONTH_FULL_NAME,
];

export const yearOnlyFormats = [
  Enums.DateFormat.YEAR_SHORT,
  Enums.DateFormat.YEAR_FULL,
];

export const hourOnlyFormats = [
  Enums.DateFormat.HOUR_12_PADDED,
  Enums.DateFormat.HOUR_12_UNPADDED,
  Enums.DateFormat.HOUR_24_PADDED,
  Enums.DateFormat.HOUR_24_UNPADDED,
];

export const quarterOnlyFormats = [
  Enums.DateFormat.QUARTER_YEAR,
  Enums.DateFormat.QUARTER_YEAR_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_PREFIXED_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_DASH,
  Enums.DateFormat.QUARTER_YEAR_DASH_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_DASH_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_DASH_PREFIXED_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SPACE,
  Enums.DateFormat.QUARTER_YEAR_SPACE_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SPACE_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_SPACE_PREFIXED_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SHORT,
  Enums.DateFormat.QUARTER_YEAR_SHORT_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SHORT_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_SHORT_PREFIXED_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_DASH_SHORT,
  Enums.DateFormat.QUARTER_YEAR_DASH_SHORT_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_DASH_SHORT_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_DASH_SHORT_PREFIXED_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SPACE_SHORT,
  Enums.DateFormat.QUARTER_YEAR_SPACE_SHORT_UNPADDED,
  Enums.DateFormat.QUARTER_YEAR_SPACE_SHORT_PREFIXED,
  Enums.DateFormat.QUARTER_YEAR_SPACE_SHORT_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER,
  Enums.DateFormat.YEAR_QUARTER_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_DASH,
  Enums.DateFormat.YEAR_QUARTER_DASH_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_DASH_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_DASH_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SPACE,
  Enums.DateFormat.YEAR_QUARTER_SPACE_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SPACE_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_SPACE_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SHORT,
  Enums.DateFormat.YEAR_QUARTER_SHORT_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SHORT_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_SHORT_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_DASH_SHORT,
  Enums.DateFormat.YEAR_QUARTER_DASH_SHORT_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_DASH_SHORT_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_DASH_SHORT_PREFIXED_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SPACE_SHORT,
  Enums.DateFormat.YEAR_QUARTER_SPACE_SHORT_UNPADDED,
  Enums.DateFormat.YEAR_QUARTER_SPACE_SHORT_PREFIXED,
  Enums.DateFormat.YEAR_QUARTER_SPACE_SHORT_PREFIXED_UNPADDED,
];

const DateTimePicker: FC<DateTimePickerProps> = ({
  onChange,
  value,
  dateFormat = Enums.DateFormat.DATE_SHORT,
  hideIcon,
  popperPlacement,
  popperModifiers,
  style,
  disabled,
  isLiveEditing,
}) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  let val = DateTime.fromFormat(value?.toString(), dateFormat);
  if (val.invalid) {
    val = null;
  }

  return (
    <SC.Wrapper
      active={calendarOpen}
      hideIcon={hideIcon}
      style={style}
      disabled={disabled}
      isLiveEditing={isLiveEditing}
    >
      {!hideIcon && (
        <SC.Icon
          icon={getDataTypeIcon(Enums.ValueDataType.DATE) as IconDefinition}
          color={calendarOpen ? '#000000de' : '#9b9b9b'}
        />
      )}
      <DatePicker
        disabled={disabled}
        style={style}
        dateFormat={escapeDateFormatChars(dateFormat)}
        selected={val ? new Date(val.toString()) : null}
        popperPlacement={popperPlacement || 'bottom'}
        popperModifiers={
          !popperModifiers
            ? undefined
            : popperModifiers || {
                offset: {
                  enabled: true,
                  offset: timeRequiredFormats.includes(
                    dateFormat as Enums.DateFormat,
                  )
                    ? '-45px, 10px'
                    : '-5px, 10px',
                },
              }
        }
        onChange={(date) =>
          onChange(date ? DateTime.fromJSDate(date).toFormat(dateFormat) : null)
        }
        onCalendarClose={() => {
          setCalendarOpen(false);
        }}
        onCalendarOpen={() => setCalendarOpen(true)}
        showTimeSelect={
          timeRequiredFormats.includes(dateFormat as Enums.DateFormat) ||
          timeOnlyFormats.includes(dateFormat as Enums.DateFormat)
        }
        timeIntervals={
          hourOnlyFormats.includes(dateFormat as Enums.DateFormat)
            ? TIME_INTERVAL_HOUR
            : TIME_INTERVAL_MINUTE
        }
        showTimeSelectOnly={timeOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showMonthYearPicker={monthOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showYearPicker={yearOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showQuarterYearPicker={quarterOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
      />
    </SC.Wrapper>
  );
};

export default DateTimePicker;
