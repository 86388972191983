import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import UserItemAdvancedStage from '../../components/UserItemStages/UserItemConfigurationStages/1-advanced/UserConfigureAdvancedStage';
import Page from '../../main/Page';
import { RootState } from '../../store/rootReducer';
import {
  UserConfigureSubStage,
  UserStage,
} from '../../store/user-stage/initial-state';
import {
  getUserStageTitle,
  getUserSubStageContent,
} from '../../util/user-stage-content/UserStageContent';

const UserConfiguration: FC = () => {
  const userStageState = useSelector((state: RootState) => state.userStage);

  const activeStage = UserStage.CONFIGURATION;
  const activeSubStage = userStageState.stages[activeStage].activeSubStage;

  const stageTitle = getUserStageTitle(activeStage);
  const subStageContent = getUserSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case UserConfigureSubStage.ADVANCED:
        return <UserItemAdvancedStage />;
      default:
        return <UserItemAdvancedStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={subStageContent.image}
        title={subStageContent.title}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default UserConfiguration;
