import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, get, set } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { MergeMode, RouteName } from '../../../../enums';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { StageBodyText, StyledDropdownUltraWide } from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { updateDatasetCollection } from '../../../../store/dataset-collection';
import { fetchDatasetMetaSuccess } from '../../../../store/dataset-meta';
import {
  resetStagesAndSubStages,
  updateActiveDatasetStage,
} from '../../../../store/dataset-stage';
import { hideModal } from '../../../../store/modal';
import { RootState } from '../../../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import MergeDatasetBodyText from './MergeDatasetBodyText';
import MergeDatasetHeading from './MergeDatasetHeading';
import * as SC from './styled';

const DATA_SOURCE_FIELD = 'dataSource';
const MERGE_COL_FIELD = 'mergeCol';
const MERGE_KEY_FIELD = 'mergeKey';
const MERGE_MODE_FIELD = 'mergeMode';

export const mergeModeOptions: DropdownItemProps[] = [
  {
    key: MergeMode.ALL,
    value: MergeMode.ALL,
    text: 'Merge existing & append new rows',
  },
  {
    key: MergeMode.EXISTING_ONLY,
    value: MergeMode.EXISTING_ONLY,
    text: 'Merge existing rows only',
  },
  {
    key: MergeMode.NEW_ONLY,
    value: MergeMode.NEW_ONLY,
    text: 'Append new rows only',
  },
];

export interface MergeDatasetOptionsProps {
  handleFieldChange?: (field: string, value: unknown) => void;
  pipelineJob?: Interfaces.DatasetJobParams;
  wrapperMargin?: string;
}

const MergeDatasetOptions: FC<MergeDatasetOptionsProps> = ({
  handleFieldChange,
  pipelineJob,
  wrapperMargin,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { activeDataCollectionItem, datasetMeta, datasetMetaAccessLevel } =
    useDatasetMeta();
  const { collection, lockCollection } = useDatasetCollection();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const [activeCollection, setActiveCollection] =
    useState<Interfaces.CollectionOutput>();
  const [uniqueDatasetOptions, setUniqueDatasetOptions] = useState<
    DropdownItemProps[]
  >([]);

  const currentDataSourceRoot = activeCollection;

  const sourceType = currentDataSourceRoot?.dataSource?.dataSourceType;
  const dataMergeCol = `${DATA_SOURCE_FIELD}.${sourceType}.${MERGE_COL_FIELD}`;
  const dataMergeMode = `${DATA_SOURCE_FIELD}.${sourceType}.${MERGE_MODE_FIELD}`;

  useEffect(() => {
    if (pipelineJob?.datasetMetaId) {
      const dsm = datasetMetas.find(
        (dsm) => dsm.entity._id === pipelineJob.datasetMetaId,
      );

      const activeCollection = dsm?.entity.dataCollections.find(
        (collection) => collection._id === dsm?.entity.activeDataCollection,
      );

      if (activeCollection) {
        setActiveCollection(activeCollection);
      }
    } else if (datasetMeta?._id) {
      const activeCollection = datasetMeta?.dataCollections.find(
        (c) => c._id === collection?.collectionId,
      );

      if (activeCollection) {
        setActiveCollection(activeCollection);
      }
    }
  }, [
    collection?.collectionId,
    datasetMeta?._id,
    datasetMeta?.dataCollections,
    datasetMetas,
    pipelineJob?.datasetMetaId,
  ]);

  // Create unique column dropdown options
  useEffect(() => {
    if (activeCollection) {
      setUniqueDatasetOptions(
        activeCollection.schemaData
          .filter(
            (f) =>
              f.dataValidation?.constraints?.isUnique ||
              f.dataValidation?.constraints?.isAutoInc,
          )
          .map((f, i) => ({
            key: `unique-col-${f.name}-${i}`,
            value: f.fieldId,
            text: f.name,
          })),
      );
    }
  }, [activeCollection]);

  const onChange = (field, val: string | number) => {
    if (handleFieldChange) {
      handleFieldChange(field, val);
      return;
    }

    const cloned = cloneDeep(datasetMeta);

    const coll = cloned?.dataCollections.find(
      (c) => c._id === collection?.collectionId,
    );

    switch (field) {
      case MERGE_COL_FIELD:
        set(coll, dataMergeCol, val);
        break;
      case MERGE_MODE_FIELD:
        set(coll, dataMergeMode, val);
        break;
    }

    dispatch(
      fetchDatasetMetaSuccess({
        entity: cloned,
        accessLevel: datasetMetaAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  const cancelMerge = () => {
    // Set activeDataCollection as selected
    dispatch(
      updateDatasetCollection({
        datasetMetaId: datasetMeta?._id,
        collectionId: activeDataCollectionItem?._id,
      }),
    );
    lockCollection();

    // Hide modal
    dispatch(hideModal());

    // Reset stages
    dispatch(resetStagesAndSubStages());
    dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE));
    history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
  };

  return (
    <SC.MergeColumnSelectorWrapper wrapperMargin={wrapperMargin}>
      <MergeDatasetHeading
        titleText="Matching Column"
        smallHeading={!!pipelineJob}
      />

      {!uniqueDatasetOptions.length && (
        <>
          <StageBodyText>
            This dataset has no unique columns. You can set this constraint when
            amending the Data Structure of this dataset.
          </StageBodyText>
          <FeatureButton
            action={cancelMerge}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Edit Data Structure'}
          />
        </>
      )}

      {uniqueDatasetOptions.length > 0 && (
        <>
          <MergeDatasetBodyText
            bodyText={` Tell us which column we should use to match entries between your
            existing
            ${
              !pipelineJob
                ? ' data and your new source file.'
                : ' dataset and your new input data.'
            }`}
            smallHeading={!!pipelineJob}
          />
          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select column'}
            options={uniqueDatasetOptions}
            selection
            search
            value={
              pipelineJob
                ? pipelineJob[MERGE_KEY_FIELD]
                : get(currentDataSourceRoot, dataMergeCol)
            }
            onChange={(e, data) =>
              onChange(
                pipelineJob ? MERGE_KEY_FIELD : MERGE_COL_FIELD,
                data.value,
              )
            }
            style={{ marginTop: 0 }}
          />

          <MergeDatasetHeading
            titleText={`${pipelineJob ? 'Upsert' : 'Merge'} Type`}
            smallHeading={!!pipelineJob}
          />
          <MergeDatasetBodyText
            bodyText={`What type of ${
              pipelineJob ? ' upsert ' : ' merge '
            } would you like to run?`}
            smallHeading={!!pipelineJob}
          />
          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select merge type'}
            options={mergeModeOptions}
            selection
            search
            upward={true}
            value={
              pipelineJob
                ? pipelineJob[MERGE_MODE_FIELD]
                : get(currentDataSourceRoot, dataMergeMode)
            }
            onChange={(e, data) => onChange(MERGE_MODE_FIELD, data.value)}
            style={{ marginTop: 0 }}
          />
        </>
      )}
    </SC.MergeColumnSelectorWrapper>
  );
};

export default MergeDatasetOptions;
