import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ListService, {
  ListItem,
  ListItemOutput,
} from '../../services/list/ListService';
import { AppThunk } from '../store';
import InitialList from './inital-state';

type List = ListItem | ListItemOutput;

export interface ListState {
  data: List;
  loading: boolean;
  error: string | null;
}

const initialState: ListState = {
  data: InitialList,
  loading: false,
  error: null,
};

const ListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    // Reset
    resetList(state) {
      state.data = InitialList;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchListStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchListSuccess(state, action: PayloadAction<List>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchListFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createListStart(state) {
      state.loading = true;
      state.error = null;
    },
    createListSuccess(state, action: PayloadAction<ListItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createListFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateListStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateListSuccess(state, action: PayloadAction<ListItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateListFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteListStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteListSuccess(state, action: PayloadAction<ListItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteListFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetList,
  fetchListStart,
  fetchListSuccess,
  fetchListFailure,
  createListStart,
  createListSuccess,
  createListFailure,
  updateListStart,
  updateListSuccess,
  updateListFailure,
  deleteListStart,
  deleteListSuccess,
  deleteListFailure,
} = ListSlice.actions;

export default ListSlice.reducer;

export const fetchList =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchListStart());
      const fetched = await ListService.getList(token, id);
      dispatch(fetchListSuccess(fetched));
    } catch (err) {
      dispatch(fetchListFailure((err as string).toString()));
      throw Error((err as string).toString());
    }
  };

export const createList =
  (
    token: string,
    list: Interfaces.List,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createListStart());
      const created = await ListService.postList(token, list, userId, portalId);
      dispatch(createListSuccess(created));
    } catch (err) {
      dispatch(createListFailure((err as string).toString()));
      throw err;
    }
  };

export const updateList =
  (
    token: string,
    project: Interfaces.ListOutput,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateListStart());
      const updated = await ListService.putList(
        token,
        project,
        userId,
        portalId,
      );
      dispatch(updateListSuccess(updated));
    } catch (err) {
      dispatch(updateListFailure((err as string).toString()));
      throw err;
    }
  };

export const deleteList =
  (
    token: string,
    projectId: string,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteListStart());
      const deleted = await ListService.deleteList(
        token,
        projectId,
        userId,
        portalId,
      );
      dispatch(deleteListSuccess(deleted));
    } catch (err) {
      dispatch(deleteListFailure((err as string).toString()));
      throw err;
    }
  };
