import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Icon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.margin.standard};
`;

export const PipelineStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
