import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums/RouteName';

const SignupPage: FC = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === RouteName.SIGNUP) {
      history.push(RouteName.WELCOME);
    }
  }, [history, location]);

  return null;
};

export default SignupPage;
