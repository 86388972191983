import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faClipboardList,
  faCog,
  faDatabase,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useForm from '../../../hooks/form/UseForm';
import { BackNavigationItem } from '../../../main/theme';
import {
  resetFormStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import {
  ConfigurationSubStage,
  ProjectConnectionFormSubStage,
} from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import NavItem from '../../Navigation/NavItem';
import NavTitle from '../../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../ProjectItemNavigation/styled';

const NavIcons = {
  [ProjectConnectionFormSubStage.NAME]: faInfoCircle,
  [ProjectConnectionFormSubStage.DATASET]: faDatabase,
  [ProjectConnectionFormSubStage.FORM_BUILDER]: faClipboardList,
};

const ProjectItemConnectionFormNavigation: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { form } = useForm();

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const formStage = projectStageState.stages[Enums.ConnectionType.FORM];
  const activeSubStage = formStage.activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [ProjectConnectionFormSubStage.CONFIGURATION]: true });

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`form-stage-back`}
        onClick={() => dispatch(resetFormStage())}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={themeContext.colors.system.white}
        />

        <NavTitle text={'Back To Forms'} />
      </BackNavigationItem>

      {Object.values(formStage.subStages)
        .filter(
          (subStage) =>
            subStage.subStage !== ProjectConnectionFormSubStage.CONFIGURATION,
        )
        .map((subStage) => {
          const subStageName =
            subStage.subStage as ProjectConnectionFormSubStage;

          return (
            <NavItem
              disabled={
                (subStageName === ProjectConnectionFormSubStage.DATASET &&
                  !form?.name) ||
                (subStageName === ProjectConnectionFormSubStage.FORM_BUILDER &&
                  !form?._id)
              }
              key={`form-stage-${subStageName}`}
              onClick={() =>
                dispatch(updateActiveProjectSubStage(subStageName))
              }
              isActive={activeSubStage === subStageName}
            >
              <FontAwesomeIcon
                icon={NavIcons[subStageName]}
                color={themeContext.colors.system.white}
              />

              <NavTitle text={subStage.title} />
            </NavItem>
          );
        })}

      {/* Configuration accordion nav */}
      {form?._id && (
        <NavigationAccordion>
          <div key={`${ProjectConnectionFormSubStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={
                !accordionGroups[ProjectConnectionFormSubStage.CONFIGURATION]
              }
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [ProjectConnectionFormSubStage.CONFIGURATION]:
                    !accordionGroups[
                      ProjectConnectionFormSubStage.CONFIGURATION
                    ],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle
                  text={
                    formStage.subStages.find(
                      (s) =>
                        s.subStage ===
                        ProjectConnectionFormSubStage.CONFIGURATION,
                    )?.title || 'Config'
                  }
                />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[Enums.DatasetStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={
                !accordionGroups[ProjectConnectionFormSubStage.CONFIGURATION]
              }
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActiveProjectSubStage(
                        ProjectConnectionFormSubStage.CONFIGURATION,
                      ),
                    );
                  }}
                  isActive={
                    activeSubStage ===
                    ProjectConnectionFormSubStage.CONFIGURATION
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={ConfigurationSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default ProjectItemConnectionFormNavigation;
