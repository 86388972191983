import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useEffect, useState } from 'react';

interface InputStatus {
  columnStatus: boolean;
  operatorStatus: boolean;
  startNestStatus: boolean;
  endNestStatus: boolean;
}

const checkFormulaStatus = (
  formula: Interfaces.FormulaOperandValue[],
): InputStatus => {
  let columnStatus = true;
  let operatorStatus = false;
  let startNestStatus = true;
  let endNestStatus = false;

  let isNested = false;

  // Check operator status
  const checkFormula = (formula: Interfaces.FormulaOperandValue[]) => {
    const emptyFormula = !formula.length;
    const lastItem = formula[formula.length - 1];

    // Last item is a nest - loop recursively if multiple
    if (lastItem?.type === Enums.FormulaOperandType.NESTED) {
      isNested = true;
      endNestStatus = false;

      return checkFormula(
        (lastItem as Interfaces.NestedFormulaOperand)?.value.formula,
      );
    }

    // Empty formula
    if (emptyFormula) {
      columnStatus = true;
      operatorStatus = false;
      startNestStatus = true;
      endNestStatus = false;
    }
    // Last item is a field
    else if (
      lastItem &&
      [
        Enums.FormulaOperandType.FIELD,
        Enums.FormulaOperandType.CONSTANT,
      ].includes(lastItem.type)
    ) {
      columnStatus = false;
      operatorStatus = true;
      startNestStatus = false;
      endNestStatus = isNested;
    }
    // Last item is an operation
    else if (
      lastItem &&
      [Enums.FormulaOperandType.OPERATION].includes(lastItem.type)
    ) {
      columnStatus = true;
      operatorStatus = false;
      startNestStatus = true;
      endNestStatus = false;
    }
  };
  checkFormula(formula);

  return { columnStatus, operatorStatus, startNestStatus, endNestStatus };
};

const useInputStatus = (
  formula: Interfaces.FormulaOperandValue[],
): InputStatus => {
  const [columnStatus, setColumnStatus] = useState<boolean>(true);
  const [operatorStatus, setOperatorStatus] = useState<boolean>(false);
  const [startNestStatus, setStartNestStatus] = useState<boolean>(true);
  const [endNestStatus, setEndNestStatus] = useState<boolean>(false);

  useEffect(() => {
    if (formula) {
      const { columnStatus, operatorStatus, startNestStatus, endNestStatus } =
        checkFormulaStatus(formula);

      setColumnStatus(columnStatus);
      setOperatorStatus(operatorStatus);
      setStartNestStatus(startNestStatus);
      setEndNestStatus(endNestStatus);
    }
  }, [formula]);

  return {
    columnStatus,
    operatorStatus,
    startNestStatus,
    endNestStatus,
  };
};

export default useInputStatus;
