import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 300px;

  color: ${({ theme }) => theme.colors.system.white};
`;

export const PopupWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;
