import styled from 'styled-components';

export const NotificationCardWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.standard};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.margin.large};
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
`;
