import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { AccessEntityType, AccessType } from '../../../../enums/Access';
import { RouteName } from '../../../../enums/RouteName';
import useList from '../../../../hooks/list/UseList';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledH2,
} from '../../../../main/theme';
import AccessSelection from '../../../AccessSelection/AccessSelection';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const ListItemAccessStage: FC = () => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const { list, editList } = useList();

  const handleChange = async (
    updated: string[],
    type: AccessType,
    group: AccessEntityType,
  ) => {
    if (list && updated && type && group) {
      // Update list
      const cloned: Interfaces.ListOutput = cloneDeep(list);

      cloned.access[type][group] = updated;

      // Save list
      await editList(cloned);
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <StyledH2>Set Permissions</StyledH2>
        <StageBodyText>
          Add users, teams or the whole organisation to the fields below to
          control who can access your list and what permissions they have.
        </StageBodyText>

        <AccessSelection access={list?.access} onChange={handleChange} />
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              history.push(`${RouteName.DATASETS}${RouteName.LISTS}`);
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Lists'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ListItemAccessStage;
