import styled from 'styled-components';
import { CardEntityWrapper, StyledH3, StyledText } from '../../main/theme';

export const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.margin.xxxlarge};
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  margin: ${({ theme }) => `${theme.margin.xxxlarge} 0`};

  > div {
    text-align: center;
    margin: ${({ theme }) => `0 ${theme.margin.standard}`};
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin-top: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const SelectionSubHeader = styled(StyledText)<{ color?: string }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  ${({ color }) => color && `color: ${color}`};
  width: 50px;
`;

export const StyledHeaderWelcome = styled(StyledH3)`
  margin: ${({ theme }) => `${theme.margin.standard} 0 0`};
`;

export const StyledRecentsWrapper = styled(CardEntityWrapper)`
  width: 100%;
  max-width: 1090px;
  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.xxlarge}`};
  justify-content: center;
`;

export const NoResultsFound = styled(StyledText)`
  margin: ${({ theme }) =>
    `${theme.margin.standard} 0 ${theme.margin.xxlarge}`};
`;
