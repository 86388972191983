import { Accordion, AccordionContent } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledSubHeader, StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.padding.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ErrorText = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.general.red};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const BodyText = styled(StyledText)`
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 30%;
  padding: ${({ theme }) => `${theme.margin.xlarge} ${theme.padding.large}`};
  background: ${({ theme }) => theme.colors.general.white};
  border-right: 1px solid ${({ theme }) => theme.colors.system.grey};

  text-align: center;

  > p {
    margin-bottom: ${({ theme }) => theme.margin.standard};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  > p {
    margin-bottom: ${({ theme }) => theme.margin.standard};
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.standard};
  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  input,
  textarea,
  div.input,
  div.dropdown {
    width: 250px;
  }
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.margin.large};
  margin-right: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

export const InputTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const InputHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.system.offBlack};
`;

export const Image = styled.img`
  max-width: 95px;
  padding: ${({ theme }) => theme.padding.small};
  background-color: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;
export const FilterWrapper = styled.div<{
  applyLeftMargin?: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
  margin-left: ${({ applyLeftMargin }) => (applyLeftMargin ? '45px' : 0)};
  justify-content: center;
  &:first-of-type {
    padding-top: ${({ theme }) => theme.padding.xlarge};
  }
  &:last-of-type {
    padding-bottom: ${({ theme }) => theme.padding.xlarge};
  }
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  margin: ${({ theme }) => theme.margin.standard} 0;
  width: 100%;
`;

export const MainContentContainer = styled.div<{ center?: boolean }>`
  overflow-y: auto;
  width: 100%;
  padding: ${({ theme }) => theme.padding.large} 0;
`;

export const StyledAccordionContent = styled(AccordionContent)`
  background-color: ${({ theme }) => theme.colors.system.white};
  padding: ${({ theme }) => theme.padding.large} !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.offWhite};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &:last-of-type {
    border: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dropdown {
      margin: 0;
    }
  }
`;

export const StyledActionAccordionContent = styled(AccordionContent)`
  background-color: ${({ theme }) => theme.colors.system.white};
  padding: 0 !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.offWhite};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    border: none;
  }
`;

export const AccordionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: flex-start !important;
  width: 100%;
  gap: 5px !important;
`;

export const ActionsIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.margin.large};
  margin-top: ${({ theme }) => theme.margin.xlarge};
  width: 100%;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: ${({ theme }) => theme.margin.standard};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ToggleLabel = styled(StyledSubHeader)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  margin: 0 ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.small};

  &:last-of-type {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.padding.xlarge};
  ${({ theme }) => theme.padding.large};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Actions = styled.div`
  max-height: 300px;
  overflow-x: auto;
`;

export const ActionText = styled(StyledText)`
  max-width: 400px;
  word-wrap: break-word;
`;

export const WebhookList = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.padding.xxlarge};
`;

export const WebhookContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

export const WebhookActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
