import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialPortalStage, {
  PortalStage,
  PortalSubStages,
} from './initial-state';

const PortalStageSlice = createSlice({
  name: 'portalStage',
  initialState: InitialPortalStage,
  reducers: {
    updateActivePortalStage(state, action: PayloadAction<PortalStage>) {
      state.activeStage = action.payload;
    },

    updateActivePortalSubStage(
      state,
      action: PayloadAction<PortalSubStages | undefined>,
    ) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },

    resetStagesAndSubStages(state) {
      state.activeStage = PortalStage.AGGREGATION;
      state.stages[PortalStage.AGGREGATION].activeSubStage = undefined;
    },
  },
});

export const {
  updateActivePortalStage,
  updateActivePortalSubStage,
  resetStagesAndSubStages,
} = PortalStageSlice.actions;

export default PortalStageSlice.reducer;
