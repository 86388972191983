import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
} from '../../../enums/';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import useUsage from '../../../hooks/usage/useUsage';
import { SampleDataRow } from '../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../main/theme';
import {
  PortalItemsOutput,
  PortalOutputWithAdditionalData,
} from '../../../services/portal/PortalService';
import { fetchPortalSuccess, resetPortal } from '../../../store/project-portal';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectConnectionPortalSubStage } from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';
import { resetFilters } from '../../../store/filters';

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const STATUS_FIELD = 'Status';

const PORTAL_TABLE_SCHEMA = [{ name: NAME_FIELD }, { name: STATUS_FIELD }];

const PREV_STAGE = Enums.ProjectStage.CREATION;

const ProjectItemPortalStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { connections, project, projectAccessLevel } = useProject();
  const { getDatasetMetas } = useDatasetMeta();
  const { getPortals } = usePortal();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const [fetchedPortals, setFetchedPortals] = useState<PortalItemsOutput>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [portalConnections, setPortalConnections] =
    useState<Interfaces.ConnectionOutput[]>();
  const [portalUsage, setPortalUsage] = useState<number>(0);

  const datasetMetas = useSelector((state: RootState) => state.datasetMetas)
    ?.data.data;
  const charts = useSelector((state: RootState) => state.charts)?.data.data;
  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  // Get datasetMetas
  useEffect(() => {
    if (project?.datasetMetaId?.length) {
      (async () => {
        await getDatasetMetas({
          projectId: project._id,
          activeDataCollection: { $ne: null },
        });
      })();
    }
  }, [getDatasetMetas, project?._id, project?.datasetMetaId]);

  // Reset Redux Filters
  useEffect(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const portalUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.PORTALS,
        );
        setPortalUsage(portalUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, organisation?._id]);

  // Get Portals
  useEffect(() => {
    if (project?._id && portalConnections) {
      if (portalConnections?.length) {
        (async () => {
          let portals;
          try {
            portals = await getPortals({
              // TEMP FIX - SHOULD PAGINATE
              limit: 100,
              projectId: project._id,
              _id: {
                $in: portalConnections.map(
                  (p) =>
                    (
                      p.configuration as Interfaces.PortalConnectionConfiguration
                    ).portalId,
                ),
              },
            });
          } catch (err) {
            portals = {
              data: [],
              pagination: {
                currentPageNum: 0,
                prevPageNum: null,
                nextPageNum: null,
                totalCount: 0,
                totalPages: 0,
              },
            };
          }

          setFetchedPortals(portals);
        })();
      } else {
        setFetchedPortals({
          data: [],
          pagination: {
            currentPageNum: 0,
            prevPageNum: null,
            nextPageNum: null,
            totalCount: 0,
            totalPages: 0,
          },
        });
      }
    }
  }, [getPortals, portalConnections, project?._id]);

  // Hide Loader
  useEffect(() => {
    if (fetchedPortals) {
      setIsLoading(false);
    }
  }, [fetchedPortals]);

  // Get connections
  useEffect(() => {
    if (connections) {
      setPortalConnections(connections.portal || []);
    }
  }, [connections]);

  useEffect(() => {
    if (portalConnections) {
      const tableData: SampleDataRow[] = [];

      portalConnections?.map((portal) => {
        if (portal) {
          tableData.push({
            [ID_FIELD]: { value: portal._id },
            [NAME_FIELD]: { value: portal.name },
            [STATUS_FIELD]: { value: startCase(portal.status) },
          });
        }
      });

      setTableData(tableData);
    }
  }, [charts, datasetMetas, portalConnections, queries]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.ConnectionTypeWrapper>
          <Banner
            message={
              <p>
                Portals have been updated to be even easier to build and can now
                be found <a href={'https://portal.goconfigur.com'}>here</a>.
              </p>
            }
            alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
            alertStatus={AlertStatus.INFO}
          />

          <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
            Group your Workspaces into Portals to share your data with internal
            and external users.
          </StageBodyText>

          {projectAccessLevel === Enums.AccessLevel.MANAGE && (
            <>
              {portalUsage >= QuotaLimits.WARNING && (
                <div style={{ marginBottom: themeContext.margin.xlarge }}>
                  {BuildBanner.generateBanner(
                    BannerType.QUOTA,
                    portalUsage >= QuotaLimits.FULL
                      ? AlertStatus.ERROR
                      : AlertStatus.WARNING,
                    ResourceType.PORTALS,
                  )}
                </div>
              )}
              {/*Deprecated*/}
              {/*<FeatureButton*/}
              {/*  action={() => {*/}
              {/*    dispatch(resetPortal());*/}
              {/*    dispatch(*/}
              {/*      updateActiveProjectSubStage(*/}
              {/*        ProjectConnectionPortalSubStage.NAME,*/}
              {/*      ),*/}
              {/*    );*/}
              {/*  }}*/}
              {/*  size={FeatureButtonSize.WIDE}*/}
              {/*  color={themeContext.colors.general.blue}*/}
              {/*  text={'Add Portal'}*/}
              {/*  containerStyle={{ marginBottom: themeContext.margin.large }}*/}
              {/*  isDisabled={portalUsage >= QuotaLimits.FULL}*/}
              {/*/>*/}
            </>
          )}

          {projectAccessLevel !== Enums.AccessLevel.MANAGE &&
            !tableData.length &&
            !isLoading && (
              <StageBodyText>
                <strong>No Portals found</strong>
              </StageBodyText>
            )}

          {(tableData.length > 0 || isLoading) && (
            <SC.TableWrapper>
              <DataSample
                sampleColumns={PORTAL_TABLE_SCHEMA}
                sampleRows={tableData}
                // Deprecated
                // clickableRows={{
                //   valueField: ID_FIELD,
                //   action: (connectionId: string) => {
                //     const existingConnection = portalConnections?.find(
                //       (c) => c._id === connectionId,
                //     );
                //
                //     const existingPortal = fetchedPortals?.data.find(
                //       (portal) =>
                //         portal.entity._id ===
                //         (
                //           existingConnection?.configuration as Interfaces.PortalConnectionConfiguration
                //         ).portalId,
                //     );
                //
                //     if (existingPortal) {
                //       dispatch(
                //         fetchPortalSuccess(
                //           existingPortal as unknown as PortalOutputWithAdditionalData,
                //         ),
                //       );
                //       dispatch(
                //         updateActiveProjectSubStage(
                //           ProjectConnectionPortalSubStage.CONNECTIONS,
                //         ),
                //       );
                //     }
                //   },
                // }}
                loading={isLoading}
              />
            </SC.TableWrapper>
          )}
        </SC.ConnectionTypeWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemPortalStage;
