import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { ListOptionColor } from '../../enums/ListOptionColor';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { PopupColorPicker } from '../PopupColorPicker/PopupColorPicker';
import * as SC from './styled';

interface ColorPickerProps {
  onChange: (color?: string) => void;
  selectedColor?: string;
  disabled?: boolean;
}

const ColorPicker: FC<ColorPickerProps> = ({
  onChange,
  selectedColor,
  disabled,
}) => {
  const themeContext = useContext(ThemeContext);

  const [selected, setSelected] = useState<string>();
  const [isDroppedColour, setIsDroppedColour] = useState<boolean>();

  useEffect(() => {
    if (selected !== selectedColor) {
      setSelected(selectedColor);
    }
  }, [selected, selectedColor]);

  const handleSelectColor = (color?: string) => {
    if (!selected || selected !== color) {
      setSelected(color);
      onChange(color);
    } else {
      setSelected(undefined);
      onChange(undefined);
    }
  };

  return (
    <SC.ColorWrapper>
      <SC.InnerWrapper>
        <FeatureButton
          isDisabled={disabled}
          isActive={!selected}
          action={() => {
            handleSelectColor(undefined);
          }}
          size={FeatureButtonSize.EXTRA_SMALL}
          faceStyle={{ padding: 0 }}
          color={themeContext.colors.system.offWhite}
          containerStyle={{ margin: themeContext.margin.standard }}
          icon={
            <FontAwesomeIcon
              icon={faBan}
              color={themeContext.colors.general.red}
              size={'lg'}
            />
          }
        />

        <PopupColorPicker
          color={
            selectedColor && ListOptionColor[selectedColor]
              ? ListOptionColor[selectedColor]
              : selectedColor
          }
          disabled={false}
          onChange={(color) => handleSelectColor(color)}
          isDroppedColour={isDroppedColour}
          setIsDroppedColour={setIsDroppedColour}
          displayAsButton={true}
        />
      </SC.InnerWrapper>

      <SC.InnerWrapper>
        {Object.keys(ListOptionColor).map((color, index) => {
          return (
            <SC.Color>
              <FeatureButton
                isDisabled={disabled}
                key={`${color}-${index}`}
                isActive={
                  !isDroppedColour &&
                  (selected === color || selected === ListOptionColor[color])
                }
                action={() => {
                  setIsDroppedColour(false);
                  handleSelectColor(ListOptionColor[color]);
                }}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={ListOptionColor[color]}
              />
            </SC.Color>
          );
        })}
      </SC.InnerWrapper>
    </SC.ColorWrapper>
  );
};

export default ColorPicker;
