import {
  Snapshot,
  SnapshotOutput,
} from '@configur-tech/upit-core-types/lib/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SnapshotService, {
  SnapshotItemOutput,
} from '../../services/dataset-snapshot/DatasetSnapshotService';
import { AppThunk } from '../store';
import { cloneDeep } from 'lodash';

export interface SnapshotsState {
  data: SnapshotItemOutput[];
  loading: boolean;
  error: string | null;
}

const initialState: SnapshotsState = {
  data: [],
  loading: false,
  error: null,
};

const DatasetSnapshotsSlice = createSlice({
  name: 'datasetSnapshots',
  initialState,
  reducers: {
    // Fetch
    fetchDatasetSnapshotStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetSnapshotSuccess(
      state,
      action: PayloadAction<SnapshotItemOutput[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDatasetSnapshotFailure(state, action: PayloadAction<string>) {
      state.data = [];
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createDatasetSnapshotStart(state) {
      state.loading = true;
      state.error = null;
    },
    createDatasetSnapshotSuccess(
      state,
      action: PayloadAction<SnapshotItemOutput>,
    ) {
      state.data = state.data.concat(action.payload);
      state.loading = false;
      state.error = null;
    },
    createDatasetSnapshotFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateDatasetSnapshotStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateDatasetSnapshotSuccess(
      state,
      action: PayloadAction<SnapshotItemOutput>,
    ) {
      const snapshotIndex = state.data.findIndex(
        (e) => e.entity._id === action.payload.entity._id,
      );

      state.data[snapshotIndex] = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateDatasetSnapshotFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteDatasetSnapshotStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteDatasetSnapshotSuccess(
      state,
      action: PayloadAction<SnapshotItemOutput>,
    ) {
      // Remove snapshot from redux
      const cloned = cloneDeep(state.data);
      const rowId = action.payload.entity._id;

      state.data = cloned.filter((e) => e.entity._id !== rowId);
      state.loading = false;
      state.error = null;
    },
    deleteDatasetSnapshotFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDatasetSnapshotStart,
  fetchDatasetSnapshotSuccess,
  fetchDatasetSnapshotFailure,
  createDatasetSnapshotStart,
  createDatasetSnapshotSuccess,
  createDatasetSnapshotFailure,
  updateDatasetSnapshotStart,
  updateDatasetSnapshotSuccess,
  updateDatasetSnapshotFailure,
  deleteDatasetSnapshotStart,
  deleteDatasetSnapshotSuccess,
  deleteDatasetSnapshotFailure,
} = DatasetSnapshotsSlice.actions;

export default DatasetSnapshotsSlice.reducer;

export const fetchDatasetSnapshot =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatasetSnapshotStart());
      const fetched = await SnapshotService.getDatasetSnapshot(token, query);
      dispatch(
        fetchDatasetSnapshotSuccess(fetched.data?.data as SnapshotItemOutput[]),
      );
    } catch (err) {
      dispatch(fetchDatasetSnapshotFailure((err as string).toString()));
      throw err;
    }
  };

export const createDatasetSnapshot =
  (token: string, datasetSnapshotId: string, data: Snapshot): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createDatasetSnapshotStart());
      const created = await SnapshotService.postDatasetSnapshot(
        token,
        datasetSnapshotId,
        data,
      );
      dispatch(createDatasetSnapshotSuccess(created));
    } catch (err) {
      dispatch(createDatasetSnapshotFailure((err as string).toString()));
      throw err;
    }
  };

export const updateDatasetSnapshot =
  (token: string, datasetSnapshot: SnapshotOutput, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateDatasetSnapshotStart());
      const updated = await SnapshotService.putDatasetSnapshot(
        token,
        datasetSnapshot,
        userId,
      );
      dispatch(updateDatasetSnapshotSuccess(updated));
    } catch (err) {
      dispatch(updateDatasetSnapshotFailure((err as string).toString()));
      throw err;
    }
  };

export const deleteDatasetSnapshot =
  (token: string, datasetSnapshotId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteDatasetSnapshotStart());
      const deleted = await SnapshotService.deleteDatasetSnapshot(
        token,
        datasetSnapshotId,
        userId,
      );
      dispatch(deleteDatasetSnapshotSuccess(deleted));
    } catch (err) {
      dispatch(deleteDatasetSnapshotFailure((err as string).toString()));
      throw err;
    }
  };
