import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DatasetStage } from '@configur-tech/upit-core-types/lib/enums';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { ERROR_VALIDATION_LIMIT } from '../../../../const/ErrorConst';
import { RouteName } from '../../../../enums';
import useDatasetCollection from '../../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import usePublish from '../../../../hooks/dataset-meta/UsePublish';
import useSample from '../../../../hooks/dataset-meta/UseSample';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import useValidation from '../../../../hooks/dataset-meta/UseValidation';
import useList from '../../../../hooks/list/UseList';
import { SampleData, SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { updateActiveDatasetStage } from '../../../../store/dataset-stage';
import { showModal } from '../../../../store/modal';
import buildTableDataFromValidation from '../../../../util/build-table-data/BuildTableDataFromValidation';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import ActionBar from '../../../ActionBar/ActionBar';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import { ErrorType } from '../../../Modal/error/ErrorModal';
import ResultSummary from '../../../ResultSummary/ResultSummary';

const PAGE_SIZE = 250;
const ERROR_COUNT_LIMIT = 500;
const TABLE_HEIGHT_INITIAL = 480;
const TABLE_HEIGHT_INITIAL_ERRORS = 435;
const TABLE_HEIGHT = 525;
const TABLE_HEIGHT_ERRORS = 540;

export interface ValidationResultDatasetStageProps {
  sampleData: SampleDataRow[];
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const ValidationResultDatasetStage: FC<ValidationResultDatasetStageProps> = ({
  sampleData,
  setSampleData,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, activeDataCollectionItem, editDatasetMeta } =
    useDatasetMeta();
  const { collection } = useDatasetCollection();
  const { getLists } = useList();
  const { validateDataset } = useValidation();
  const { publishDataset } = usePublish();
  const { createTableData } = useTableData();
  const { createSampleData } = useSample();
  const history = useHistory();

  const hasPublishedData = !!datasetMeta?.activeDataCollection;
  const isMerging = location.pathname.includes('merge');
  const isReplacing = location.pathname.includes('replace');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchingSample, setFetchingSample] = useState<boolean>(false);
  const [errorSampleData, setErrorSampleData] = useState<SampleDataRow[]>([]);
  const [tableData, setTableData] = useState<SampleData>();
  const [fetchedSample, setFetchedSample] =
    useState<Record<string, unknown>[]>();

  const dataCollection = datasetMeta?.dataCollections?.find(
    (c) => c._id === collection?.collectionId,
  );

  const isDuplicating = location.pathname.includes('duplicate');

  useEffect(() => {
    if (
      !fetchingSample ||
      !datasetMeta?._id ||
      !dataCollection?.schemaData ||
      isDuplicating
    ) {
      return;
    }
    (async () => {
      setFetchedSample(
        await createSampleData(
          datasetMeta._id,
          dataCollection.schemaData,
          undefined,
          true,
        ),
      );
      setIsLoading(false);
    })();
  }, [
    createSampleData,
    datasetMeta?._id,
    fetchingSample,
    dataCollection?.schemaData,
    isDuplicating,
  ]);

  const stage = dataCollection?.stages?.[DatasetStage.VALIDATION];
  const errorCount = stage?.result?.failed || 0;

  // Build tableData
  useEffect(() => {
    if (!dataCollection?.schemaData || !fetchedSample) {
      return;
    }
    setTableData(
      createTableData(
        dataCollection?.schemaData,
        fetchedSample,
        dataCollection?.stages.validation?.corrected,
      ),
    );
  }, [
    createTableData,
    dataCollection?.schemaData,
    dataCollection?.stages.validation?.corrected,
    fetchedSample,
  ]);

  // Build errorSampleData
  useEffect(() => {
    if (errorCount) {
      if (stage?.pagedResults) {
        setErrorSampleData(
          buildTableDataFromValidation(
            dataCollection?.schemaData || [],
            stage.pagedResults.flatMap((page) =>
              !page.failed ? [] : page.failed,
            ),
          ),
        );
      }
    }
  }, [dataCollection?.schemaData, errorCount, stage?.pagedResults]);

  // If schema includes a listValue, go fetch list
  useEffect(() => {
    (async () => {
      if (dataCollection?.schemaData) {
        const listIds = dataCollection?.schemaData?.reduce((acc, f) => {
          const listValues = f.dataValidation?.constraints?.listValues;
          if (listValues && !Array.isArray(listValues)) {
            return [...acc, listValues];
          }
          return acc;
        }, [] as string[]);

        if (listIds?.length) {
          await getLists({ _id: { $in: listIds } });
        }
      }
    })();
  }, [dataCollection?.schemaData, getLists]);

  // If no errors/new result - go get sampleData
  useEffect(() => {
    if (!errorCount || dataCollection?._id !== activeDataCollectionItem?._id) {
      setFetchingSample(true);
    }
  }, [
    activeDataCollectionItem?._id,
    dataCollection?._id,
    errorCount,
    hasPublishedData,
  ]);

  // Set newly fetch tableData as sampleData
  useEffect(() => {
    if (tableData && tableData?.rows?.length) {
      setSampleData(tableData?.rows);
      setFetchingSample(false);
    }
  }, [setSampleData, tableData]);

  const handleCellChange = async (
    rowIndex: number,
    columnKey: string,
    value: string,
  ) => {
    const clonedDatasetMeta: Interfaces.DatasetMetaOutput =
      cloneDeep(datasetMeta);

    const clonedDC = clonedDatasetMeta.dataCollections.find(
      (dc) => dc._id === collection?.collectionId,
    );

    const stage = clonedDC?.stages?.[DatasetStage.VALIDATION];

    if (stage?.pagedResults) {
      const pageNum = Math.ceil(rowIndex / PAGE_SIZE);
      const validationPage = stage.pagedResults.find(
        (page) => page.pageNum === pageNum,
      );
      const failedRow = validationPage?.failed?.find(
        (row) => row.rowId === rowIndex,
      );
      if (failedRow) {
        for (const field of failedRow.fields) {
          if (field.fieldName === columnKey) {
            field.updatedValue = value;
            break;
          }
        }
      }
    }

    await editDatasetMeta(clonedDatasetMeta);
  };

  const revalidateDataset = async () => {
    // Validate dataset
    try {
      await validateDataset();
    } catch (err) {
      // Handle error
      const error = err as Error;
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          additionalProps: {
            errorType:
              error.message === ERROR_VALIDATION_LIMIT
                ? ErrorType.VALIDATION_LIMIT
                : ErrorType.VALIDATION_FAILED,
          },
        }),
      );
    }
  };

  const processAction = async () => {
    const publishResult = await publishDataset();

    if (publishResult) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.DATASET_PUBLISHED,
          forceOpen: true,
        }),
      );
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        {!errorCount && (
          <>
            <ResultSummary
              total={
                dataCollection?.stages?.[Enums.DatasetStage.VALIDATION]?.result
                  ?.total
              }
              completed={
                dataCollection?.stages?.[Enums.DatasetStage.VALIDATION]?.result
                  ?.completed
              }
              failed={
                dataCollection?.stages?.[Enums.DatasetStage.VALIDATION]?.result
                  ?.failed
              }
            />
            <StageBodyText>
              Your data has passed all of your validation checks.
            </StageBodyText>
            <StageBodyText>
              If you are happy with the preview, publish your data to unlock all
              Configur has to offer.
            </StageBodyText>

            {dataCollection?.schemaData && (
              <DataSample
                schema={dataCollection.schemaData}
                sampleColumns={dataCollection.schemaData}
                sampleRows={sampleData}
                isValidatingData={true}
                hideDeleteCol={true}
                iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                fixedHeightReduction={
                  activeDataCollectionItem ? TABLE_HEIGHT : TABLE_HEIGHT_INITIAL
                }
                loading={isLoading}
              />
            )}
          </>
        )}

        {errorCount > 0 && (
          <>
            <StageBodyText>
              {errorCount < ERROR_COUNT_LIMIT
                ? `It looks like you might have a few invalid values. Update the values below and then re-run validation`
                : `You have hit the limit for validation errors. Please check your constraints and column types and then re-run validation.`}
            </StageBodyText>

            {errorCount < ERROR_COUNT_LIMIT && (
              <FeatureButton
                action={revalidateDataset}
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.green}
                text={'Revalidate dataset'}
                containerStyle={{ marginBottom: themeContext.margin.large }}
              />
            )}

            {dataCollection?.schemaData && errorCount < ERROR_COUNT_LIMIT && (
              <DataSample
                schema={dataCollection.schemaData}
                sampleColumns={dataCollection.schemaData}
                sampleRows={errorSampleData}
                isValidatingData={true}
                hideDeleteCol={true}
                editAction={handleCellChange}
                iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                fixedHeightReduction={
                  activeDataCollectionItem
                    ? TABLE_HEIGHT_ERRORS
                    : TABLE_HEIGHT_INITIAL_ERRORS
                }
                loading={isLoading}
              />
            )}

            {dataCollection?.schemaData && errorCount >= ERROR_COUNT_LIMIT && (
              <>
                <StageBodyText>
                  Below is a sample of errors found with your dataset.
                </StageBodyText>
                <DataSample
                  schema={dataCollection.schemaData}
                  sampleColumns={dataCollection.schemaData}
                  sampleRows={errorSampleData.slice(0, 50)}
                  isValidatingData={false}
                  isEditingDataTypes={false}
                  hideDeleteCol={true}
                  iconMap={{ ...UserIconMap, ...AvatarIconMap }}
                  fixedHeightReduction={
                    activeDataCollectionItem
                      ? TABLE_HEIGHT_ERRORS
                      : TABLE_HEIGHT_INITIAL_ERRORS
                  }
                  loading={isLoading}
                />
              </>
            )}
          </>
        )}
      </StageInner>

      <ActionBar
        text={
          activeDataCollectionItem &&
          activeDataCollectionItem._id === dataCollection?._id
            ? ``
            : `Let's publish your dataset!`
        }
        primaryButton={
          !isMerging &&
          activeDataCollectionItem &&
          activeDataCollectionItem._id === dataCollection?._id ? (
            <FeatureButton
              isDisabled={errorCount > 0}
              action={() =>
                dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION))
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Continue to overview'}
            />
          ) : (
            <FeatureButton
              isDisabled={errorCount > 0}
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Publish dataset'}
            />
          )
        }
        backButton={
          <FeatureButton
            action={() => {
              if (isMerging || isReplacing) {
                dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION));
                history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
              } else {
                dispatch(
                  updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE),
                );
              }
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={
              isMerging || isReplacing
                ? `${isReplacing ? 'Cancel Replace' : 'Cancel Merge'}`
                : 'Back to data structure'
            }
          />
        }
      />
    </StageWrapper>
  );
};

export default ValidationResultDatasetStage;
