import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Cell } from '../../DataSample/DataSample';
import * as SC from './styled';

interface PipelineStatusTableCellProps extends Cell {
  pass: boolean;
  text?: string;
}

const PipelineStatusTableCell: FC<PipelineStatusTableCellProps> = ({
  pass,
  text,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <SC.PipelineStatusWrapper>
      <SC.Icon
        icon={pass ? faCircleCheck : faCircleXmark}
        color={
          pass
            ? themeContext.colors.general.green
            : themeContext.colors.general.red
        }
        size={'lg'}
      />
      {text || pass ? 'Success' : 'Fail'}
    </SC.PipelineStatusWrapper>
  );
};

export default PipelineStatusTableCell;
