import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PipelineConfigurationAdvancedStage from '../../components/PipelineStages/PipelineConfigurationStages/1-advanced/PipelineConfigurationAdvancedStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import {
  PipelineConfigurationSubStage,
  PipelineStage,
} from '../../store/pipeline-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getPipelineStageTitle,
  getPipelineSubStageContent,
} from '../../util/pipeline-stage-content/PipelineStageContent';

const PipelineItemConfiguration: FC = () => {
  const pipelineStageState = useSelector(
    (state: RootState) => state.pipelineStage,
  );

  const activeStage = PipelineStage.CONFIGURATION;
  const activeSubStage = pipelineStageState.stages[activeStage].activeSubStage;

  const stageTitle = getPipelineStageTitle(activeStage);
  const subStageContent = getPipelineSubStageContent(
    activeStage,
    activeSubStage,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case PipelineConfigurationSubStage.ADVANCED:
        return <PipelineConfigurationAdvancedStage />;
      default:
        return <PipelineConfigurationAdvancedStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: pipelineStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default PipelineItemConfiguration;
