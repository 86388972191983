import { CommentsItemOutput } from '.';

const InitialComments: CommentsItemOutput = {
  data: [],
  pagination: {
    currentPageNum: 1,
    prevPageNum: null,
    nextPageNum: null,
    totalCount: 0,
    totalPages: 1,
  },
};

export default InitialComments;
