import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ListType } from '@configur-tech/upit-core-types/lib/enums';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { cloneDeep, orderBy, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { ThemeContext } from 'styled-components';
import { ListOptionColor, ListSortOptions, RouteName } from '../../../../enums';
import useList from '../../../../hooks/list/UseList';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import usePermission from '../../../../hooks/permission/UsePermission';
import {
  ListValueWithId,
  ListValueWithUserAndId,
} from '../../../../interfaces/ListValue';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledDropdownWide,
} from '../../../../main/theme';
import { UserListOutput } from '../../../../services/list/ListService';
import { fetchListSuccess } from '../../../../store/list';
import { updateActiveListSubStage } from '../../../../store/list-stage';
import { ListCreateSubStage } from '../../../../store/list-stage/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import ActionBar from '../../../ActionBar/ActionBar';
import DatasetSelectDropdown from '../../../DatasetSelectDropdown/DatasetSelectDropdown';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import SchemaColumnDropdown from '../../../SchemaColumnDropdown/SchemaColumnDropdown';
import * as SC from '../styled';
import SortableItem from './SortableItem';

const VALUE_FIELD = 'value';
const VALUES_FIELD = 'values';
const LIST_TYPE_FIELD = 'listType';
const DATASET_LIST_OPTIONS_FIELD = 'datasetListOptions';
const PREV_STAGE = ListCreateSubStage.NAME;

const CUSTOM_SORT_OPTION = 'custom';
const SortOptions = [
  {
    value: 'asc',
    text: ' Alphabetical (ASC)',
  },
  {
    value: 'desc',
    text: ' Alphabetical (DESC)',
  },
  {
    value: CUSTOM_SORT_OPTION,
    text: 'Custom',
  },
];

const ListItemValuesStage: FC = () => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { list, listAccessLevel, addList, editList } = useList();
  const { loggedInUser } = useLoggedInUser();
  const { isProTier } = usePermission();

  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [processComplete, setProcessComplete] = useState<boolean>(false);
  const [DSMId, setDSMId] = useState<string>();
  const [columnId, setColumnId] = useState<string>();

  const [sort, setSort] = useState<string>(CUSTOM_SORT_OPTION);
  const [initialList, setInitialList] = useState<Interfaces.ListValue[]>([]);
  const [formattedListOptions, setFormattedListOptions] = useState<
    ListValueWithId[] | ListValueWithUserAndId[]
  >([]);

  const canEdit = [Enums.AccessLevel.EDIT, Enums.AccessLevel.MANAGE].includes(
    listAccessLevel || 0,
  );
  const canManage = listAccessLevel === Enums.AccessLevel.MANAGE;

  const listTypeOptions: DropdownItemProps[] = [
    {
      key: `list-type-${Enums.ListType.STANDARD}`,
      value: Enums.ListType.STANDARD,
      text: startCase(Enums.ListType.STANDARD),
    },
    {
      key: `list-type-${Enums.ListType.USER}`,
      value: Enums.ListType.USER,
      text: startCase(Enums.ListType.USER),
    },
    {
      key: `list-type-${Enums.ListType.DATASET}`,
      value: Enums.ListType.DATASET,
      text: startCase(Enums.ListType.DATASET),
    },
  ];
  const [listType, setListType] = useState<Enums.ListType>(
    Enums.ListType.STANDARD,
  );

  // Map list values to drag and drop component
  useEffect(() => {
    if (list?.datasetListOptions) {
      setListType(list.listType);
      setDSMId(list.datasetListOptions.datasetMetaId);
      setColumnId(list.datasetListOptions.fieldId);
    }

    if (list?.values?.length || list?.name) {
      setDataHasChanged(true);

      if (!initialList.length && list.values) {
        setInitialList(list.values);
      }

      setListType(list.listType);

      if (list.listType === ListType.STANDARD && list.values) {
        setFormattedListOptions(
          list.values.map((val, index) => {
            return {
              id: `${list._id}_${index.toString()}`,
              value: val.value ? val.value : val.toString(),
              color: val.color,
            };
          }),
        );
      } else if (list.listType === ListType.USER && list.values) {
        const userList = list as UserListOutput;

        setFormattedListOptions(
          list.values.map((val, index) => {
            const user = userList?.users?.find((u) => u._id === val.value);
            const team = userList?.teams?.find((t) => t._id === val.value);

            let avatar;
            if (user) {
              avatar = UserIconMap[user.avatar];
            } else if (team) {
              avatar = AvatarIconMap[team?.avatar];
            } else {
              avatar = val['avatar'];
            }

            return {
              id: `${list._id}_${index.toString()}`,
              text: user
                ? `${user?.firstName} ${user?.lastName}`
                : team?.name || val['name'],
              value: user?._id || team?._id || val.value,
              avatar,
            };
          }),
        );
      }
    }
  }, [
    list?.values,
    list?._id,
    list?.listType,
    list,
    formattedListOptions?.length,
    initialList,
  ]);

  useEffect(() => {
    if (processComplete && list?._id) {
      history.push(`${RouteName.DATASETS}${RouteName.LISTS}`);
    }
  }, [processComplete, list?._id, dispatch, history]);

  const processAction = async () => {
    if (list && dataHasChanged) {
      dispatch(
        showLoading({
          text: `Saving List...`,
        }),
      );

      // Save list
      if (!list?._id) {
        const cloned = cloneDeep(list);
        cloned.organisationId = loggedInUser?.organisationId;

        await addList(cloned);
      } else {
        await editList(list);
      }

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  const handleDragEnd = ({ active, over }) => {
    const oldIndex = formattedListOptions.findIndex((i) => i.id == active.id);
    const newIndex = formattedListOptions.findIndex((i) => i.id == over.id);

    if (list?.values) {
      const clone = cloneDeep(list);
      clone[VALUES_FIELD] = arrayMove(clone.values, oldIndex, newIndex);

      setFormattedListOptions(clone[VALUES_FIELD]);

      dispatch(
        fetchListSuccess({
          entity: clone,
          accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
        }),
      );

      setDataHasChanged(true);

      setSort(CUSTOM_SORT_OPTION);
    }
  };

  const handleRemoveListItem = (index) => {
    const cloned = cloneDeep(list);
    cloned.values.splice(index, 1);

    dispatch(
      fetchListSuccess({
        entity: cloned,
        accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );

    setDataHasChanged(true);
  };

  const handleEditListItem = (index) => {
    if (list?.values?.length) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.LIST_ADD_ITEM,
          additionalProps: {
            listType: listType,
            existingListItem: { index: index, listItem: list.values[index] },
          },
        }),
      );
    }
  };

  const sortListItems = (sort) => {
    setSort(sort);
    const cloned = cloneDeep(list);

    switch (sort) {
      case ListSortOptions.ASCENDING:
        cloned.values = orderBy(
          cloned.values,
          [VALUE_FIELD],
          [ListSortOptions.ASCENDING],
        );

        dispatch(
          fetchListSuccess({
            entity: cloned,
            accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
          }),
        );

        dispatch(
          fetchListSuccess({
            entity: cloned,
            accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
          }),
        );
        setDataHasChanged(true);
        break;
      case ListSortOptions.DESCENDING:
        cloned.values = orderBy(
          cloned.values,
          [VALUE_FIELD],
          [ListSortOptions.DESCENDING],
        );

        dispatch(
          fetchListSuccess({
            entity: cloned,
            accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
          }),
        );
        setDataHasChanged(true);
        break;
    }
  };

  const handleChangeListType = (type: Enums.ListType) => {
    setListType(type);

    const cloned = cloneDeep(list);
    cloned[LIST_TYPE_FIELD] = type;
    cloned[DATASET_LIST_OPTIONS_FIELD] = undefined;

    dispatch(
      fetchListSuccess({
        entity: cloned,
        accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  const handleDatasetSelection = (val: string) => {
    setColumnId(undefined);
    setDSMId(val);
  };

  const handleColumnSelection = (val: string) => {
    setColumnId(val);
    setDataHasChanged(true);

    // Update stored list item
    const cloned = cloneDeep(list);

    cloned.datasetListOptions = {
      datasetMetaId: DSMId,
      fieldId: val,
    };

    dispatch(
      fetchListSuccess({
        entity: cloned,
        accessLevel: listAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  const listIsValid =
    (list?.values && list?.values?.length > 0) || (DSMId && columnId);

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          {!canEdit && (
            <StageBodyText>
              You currently have <strong>view</strong> access to this list and
              cannot edit the available list options.
            </StageBodyText>
          )}
          {canEdit && (
            <StageBodyText>
              Manage the options available in this list by adding them to the
              input below.
            </StageBodyText>
          )}
          {canEdit && (
            <>
              <StyledBodySubHeader>List Type</StyledBodySubHeader>
              <StyledDropdownWide
                selectOnBlur={false}
                disabled={list?.values?.length !== 0 || DSMId || columnId}
                placeholder={'Select List Type'}
                selection
                options={listTypeOptions}
                value={listType}
                onChange={(e, data) => {
                  handleChangeListType(data.value);
                }}
              />
              <SC.Section>
                {listType !== Enums.ListType.DATASET && (
                  <FeatureButton
                    text={
                      listType === Enums.ListType.STANDARD
                        ? 'Add List Item'
                        : isProTier
                        ? 'Add User Or Team'
                        : 'Add User'
                    }
                    size={FeatureButtonSize.WIDE_SMALL}
                    color={themeContext.colors.general.blue}
                    action={() =>
                      dispatch(
                        showModal({
                          visible: true,
                          modal: ModalTypes.LIST_ADD_ITEM,
                          additionalProps: {
                            listType: listType,
                          },
                        }),
                      )
                    }
                  />
                )}
                {listType === Enums.ListType.DATASET && (
                  <>
                    <DatasetSelectDropdown
                      onChange={handleDatasetSelection}
                      initialDatasetMetaId={DSMId}
                    />
                    <SchemaColumnDropdown
                      initialColumnId={columnId}
                      datasetMetaId={DSMId || ''}
                      onChange={handleColumnSelection}
                    />
                  </>
                )}
              </SC.Section>
            </>
          )}
          {list?.values?.length !== 0 && listType !== Enums.ListType.DATASET && (
            <>
              <StyledDropdownUltraWide
                selectOnBlur={false}
                placeholder={'Select a List Sort'}
                selection
                options={SortOptions}
                value={sort}
                onChange={(e, data) => sortListItems(data.value)}
              />

              <SC.SortableContainer>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={formattedListOptions}
                    strategy={rectSortingStrategy}
                  >
                    {list?.listType === ListType.STANDARD &&
                      (formattedListOptions as ListValueWithId[]).map(
                        ({ id, color, value }, index) => (
                          <SortableItem
                            removeAction={() => handleRemoveListItem(index)}
                            editAction={() =>
                              canEdit ? handleEditListItem(index) : undefined
                            }
                            key={`${index}_${id}`}
                            id={id}
                            color={
                              color && ListOptionColor[color]
                                ? ListOptionColor[color]
                                : color
                            }
                            value={value}
                          />
                        ),
                      )}

                    {list?.listType === ListType.USER &&
                      (formattedListOptions as ListValueWithUserAndId[]).map(
                        ({ id, value, text, avatar }, index) => (
                          <SortableItem
                            removeAction={() => handleRemoveListItem(index)}
                            editAction={() =>
                              canEdit ? handleEditListItem(index) : undefined
                            }
                            key={`${index}_${id}`}
                            id={id}
                            text={text}
                            value={value}
                            avatar={avatar}
                          />
                        ),
                      )}
                  </SortableContext>
                </DndContext>
              </SC.SortableContainer>
            </>
          )}
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            isDisabled={!listIsValid}
            action={canEdit ? processAction : () => setProcessComplete(true)}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Save & Finish'}
          />
        }
        backButton={
          canManage ? (
            <FeatureButton
              action={() => {
                dispatch(updateActiveListSubStage(PREV_STAGE));
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to name'}
            />
          ) : (
            <FeatureButton
              action={() => setProcessComplete(true)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Back to lists'}
            />
          )
        }
      />
    </StageWrapper>
  );
};

export default ListItemValuesStage;
