import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceType } from '../../enums/ResourceType';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import UsageService, {
  UsageCheckResponse,
  UsageQuotas,
} from '../../services/usage/UsageService';
import { RootState } from '../../store/rootReducer';
import { fetchOrgUsage } from '../../store/usage';
import { datapointQuotaCheck, QuotaState } from '../../store/quota';

interface useUsageResult {
  usage?: UsageQuotas;
  datapointQuota?: QuotaState;
  getOrgUsage: (organisationId: string) => void;
  checkResourceUsage: (
    organisationId: string,
    resourceName: ResourceType,
  ) => Promise<UsageCheckResponse>;
  checkDatapointQuota: (organisationId: string) => void;
}

const useUsage = (): useUsageResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [usage, setUsage] = useState<UsageQuotas>();
  const [datapointQuota, setDatapointQuota] = useState<QuotaState>();
  const usageState = useSelector((state: RootState) => state.usage);
  const quotaState = useSelector((state: RootState) => state.quota);
  const usageObj = usageState.data as UsageQuotas;
  const quotaObj = quotaState;

  const getOrgUsage = useCallback(
    async (organisationId: string) => {
      const token = await getAccessTokenSilently();

      if (token && organisationId && loggedInUser) {
        await dispatch(fetchOrgUsage(token, organisationId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const checkResourceUsage = useCallback(
    async (organisationId: string, resourceName: ResourceType) => {
      let resourceCheck;
      const token = await getAccessTokenSilently();

      if (token && organisationId && loggedInUser && resourceName) {
        resourceCheck = UsageService.checkResourceUsage(
          token,
          organisationId,
          resourceName,
        );
      }
      return resourceCheck;
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const checkDatapointQuota = useCallback(
    async (organisationId: string) => {
      const token = await getAccessTokenSilently();

      if (token && organisationId && loggedInUser) {
        dispatch(datapointQuotaCheck(token, organisationId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (usageObj) {
      // Complete model
      setUsage(usageObj);
    }
  }, [usageObj]);

  useEffect(() => {
    if (quotaObj) {
      // Complete model
      setDatapointQuota(quotaObj);
    }
  }, [quotaObj]);

  return {
    usage,
    datapointQuota,
    getOrgUsage,
    checkResourceUsage,
    checkDatapointQuota,
  };
};

export default useUsage;
