import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EventsService, {
  PipelineEventsItemOutput,
} from '../../services/events/EventsService';
import {
  PipelineTemplateOutput,
  PipelineTemplatesOutput,
} from '../../services/pipeline/PipelineTemplateService';
import {
  createPipelineTemplate,
  deletePipelineTemplate,
  fetchPipelineTemplate,
  updatePipelineTemplate,
} from '../../store/pipeline-template';
import {
  fetchPipelineTemplates,
  fetchPipelineTemplatesSuccess,
} from '../../store/pipeline-templates';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface usePipelineTemplateResult {
  pipelineTemplate?: Interfaces.PipelineOutput;
  pipelineTemplateAccessLevel?: Enums.AccessLevel;
  pipelineTemplateError?: string;

  getPipelineTemplates: (query?: Record<string, unknown>) => Promise<void>;
  getPipelineTemplate: (pipelineTemplateId: string) => void;
  addPipelineTemplate: (pipelineTemplate: Interfaces.Pipeline) => void;
  editPipelineTemplate: (pipelineTemplate: Interfaces.PipelineOutput) => void;
  removePipelineTemplate: (pipelineTemplateId: string) => void;

  getPipelineEvents: (
    resourceId: string,
    page: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<PipelineEventsItemOutput | undefined>;
}

const usePipelineTemplate = (): usePipelineTemplateResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [pipelineTemplate, setPipelineTemplate] =
    useState<Interfaces.PipelineOutput>();
  const [pipelineTemplateAccessLevel, setPipelineTemplateAccessLevel] =
    useState<Enums.AccessLevel>();
  const [pipelineTemplateError, setPipelineTemplateError] = useState<string>();

  const pipelineTemplatesState = useSelector(
    (state: RootState) => state.pipelineTemplates,
  );
  const pipelineTemplateState = useSelector(
    (state: RootState) => state.pipelineTemplate,
  );
  const pipelineTemplateObj =
    pipelineTemplateState.data as PipelineTemplateOutput;
  const pipelineTemplatesObj =
    pipelineTemplatesState.data as PipelineTemplatesOutput;
  const pipelineTemplateErr = pipelineTemplatesState.error;

  const getPipelineTemplates = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          await dispatch(fetchPipelineTemplates(token, query));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load pipeline templates.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getPipelineTemplate = useCallback(
    async (pipelineTemplateId: string) => {
      const token = await getAccessTokenSilently();

      if (token && pipelineTemplateId) {
        try {
          await dispatch(fetchPipelineTemplate(token, pipelineTemplateId));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load pipeline template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addPipelineTemplate = useCallback(
    async (pipelineTemplate: Interfaces.Pipeline) => {
      const token = await getAccessTokenSilently();

      if (token && pipelineTemplate && loggedInUser) {
        try {
          await dispatch(
            createPipelineTemplate(token, pipelineTemplate, loggedInUser._id),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to add pipeline template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editPipelineTemplate = useCallback(
    async (pipelineTemplate: Interfaces.PipelineOutput) => {
      const token = await getAccessTokenSilently();

      if (token && pipelineTemplate && loggedInUser) {
        try {
          await dispatch(
            updatePipelineTemplate(token, pipelineTemplate, loggedInUser._id),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit pipeline template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removePipelineTemplate = useCallback(
    async (pipelineTemplateId: string) => {
      const token = await getAccessTokenSilently();

      if (token && pipelineTemplateId && loggedInUser) {
        try {
          await dispatch(
            deletePipelineTemplate(token, pipelineTemplateId, loggedInUser._id),
          );

          // Remove project from current pipeline templates
          const cloned = cloneDeep(pipelineTemplatesObj);
          cloned.data = cloned.data.filter(
            (e) => e.entity._id !== pipelineTemplateId,
          );

          await dispatch(fetchPipelineTemplatesSuccess(cloned));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to delete pipeline template.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, pipelineTemplatesObj],
  );

  const getPipelineEvents = useCallback(
    async (
      pipelineId: string,
      page: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          return await EventsService.getPipelineEvents(
            token,
            pipelineId,
            page,
            limit,
            orderBy,
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to get pipeline events.`,
          );
        }
      }
    },
    [getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (pipelineTemplateObj) {
      // Complete model
      setPipelineTemplate(pipelineTemplateObj.entity);
      setPipelineTemplateAccessLevel(pipelineTemplateObj.accessLevel);
    }
    if (pipelineTemplateErr) {
      setPipelineTemplateError(pipelineTemplateErr);
    }
  }, [pipelineTemplateErr, pipelineTemplateObj]);

  return {
    pipelineTemplate,
    pipelineTemplateAccessLevel,
    pipelineTemplateError,

    getPipelineTemplates,
    getPipelineTemplate,
    addPipelineTemplate,
    editPipelineTemplate,
    removePipelineTemplate,

    getPipelineEvents,
  };
};

export default usePipelineTemplate;
