import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TeamService, {
  TeamItem,
  TeamItemOutput,
} from '../../services/team/TeamService';
import { AppThunk } from '../store';
import InitialTeam from './initial-team';

type Team = TeamItem | TeamItemOutput;

export interface TeamState {
  data: Team;
  loading: boolean;
  error: string | null;
}

const initialState: TeamState = {
  data: InitialTeam,
  loading: false,
  error: null,
};

const TeamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // Fetch
    fetchTeamStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchTeamSuccess(state, action: PayloadAction<Team>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchTeamFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createTeamStart(state) {
      state.loading = true;
      state.error = null;
    },
    createTeamSuccess(state, action: PayloadAction<Team>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createTeamFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateTeamStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateTeamSuccess(state, action: PayloadAction<Team>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateTeamFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteTeamStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteTeamSuccess(state, action: PayloadAction<Team>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteTeamFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTeamStart,
  fetchTeamSuccess,
  fetchTeamFailure,
  createTeamStart,
  createTeamSuccess,
  createTeamFailure,
  updateTeamStart,
  updateTeamSuccess,
  updateTeamFailure,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
} = TeamSlice.actions;

export default TeamSlice.reducer;

export const fetchTeam =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchTeamStart());
      const fetched = await TeamService.getTeam(token, id);
      dispatch(fetchTeamSuccess(fetched));
    } catch (err) {
      dispatch(fetchTeamFailure((err as string).toString()));
    }
  };

export const createTeam =
  (token: string, project: Interfaces.Team, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTeamStart());
      const created = await TeamService.postTeam(token, project, userId);
      dispatch(createTeamSuccess(created));
    } catch (err) {
      dispatch(createTeamFailure((err as string).toString()));
    }
  };

export const updateTeam =
  (token: string, project: Interfaces.TeamOutput, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateTeamStart());
      const updated = await TeamService.putTeam(token, project, userId);
      dispatch(updateTeamSuccess(updated));
    } catch (err) {
      dispatch(updateTeamFailure((err as string).toString()));
    }
  };

export const deleteTeam =
  (token: string, teamId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteTeamStart());
      const deleted = await TeamService.deleteTeam(token, teamId, userId);
      dispatch(deleteTeamSuccess(deleted));
    } catch (err) {
      dispatch(deleteTeamFailure((err as string).toString()));
    }
  };
