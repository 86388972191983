import { FC } from 'react';
import PostboxImage from '../../assets/icons/notifications/postbox.svg';
import * as SC from './styled';

const EmptyNotifications: FC = () => {
  return (
    <SC.EmptyNotifications>
      <SC.EmptyNotificationsImage src={PostboxImage} alt="Sparkling postbox" />
      <SC.EmptyNotificationsTitle>
        Nothing to see here!
      </SC.EmptyNotificationsTitle>
      <SC.EmptyNotificationsText>
        You've got no new notifications
      </SC.EmptyNotificationsText>
    </SC.EmptyNotifications>
  );
};

export default EmptyNotifications;
