import { Enums } from '@configur-tech/upit-core-types';

const convertFileTypeToEnum = (
  sourceType: string,
): Enums.DataSourceType | undefined => {
  switch (sourceType) {
    case 'text/csv':
      return Enums.DataSourceType.CSV;
    case 'application/vnd.ms-excel':
      return Enums.DataSourceType.XLS;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return Enums.DataSourceType.XLS;
    case 'text/plain':
      return Enums.DataSourceType.TXT;
    default:
      return undefined;
  }
};

export default convertFileTypeToEnum;
