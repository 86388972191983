import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TeamService, { TeamsItemOutput } from '../../services/team/TeamService';
import { AppThunk } from '../store';

export interface TeamsState {
  data: TeamsItemOutput;
  loading: boolean;
  error: string | null;
}

const initialState: TeamsState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const TeamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // Fetch
    fetchTeamsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchTeamsSuccess(state, action: PayloadAction<TeamsItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchTeamsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchTeamsStart, fetchTeamsSuccess, fetchTeamsFailure } =
  TeamsSlice.actions;

export default TeamsSlice.reducer;

export const fetchTeams =
  (
    token: string,
    query?: Record<string, unknown>,
    pageNum?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchTeamsStart());
      const fetched = await TeamService.getTeams(
        token,
        query,
        pageNum,
        limit,
        orderBy,
      );
      dispatch(fetchTeamsSuccess(fetched.data as unknown as TeamsItemOutput));
    } catch (err) {
      dispatch(fetchTeamsFailure((err as string).toString()));
    }
  };
