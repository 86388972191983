import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import styled from 'styled-components';
import getStageStatusColor from '../../util/status-color/StageStatusColor';

interface NavStatusProps {
  status: Enums.StageStatus;
}

const Wrapper = styled.div<NavStatusProps>`
  background: ${({ status }) => getStageStatusColor(status)};

  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

const NavStatus: FC<NavStatusProps> = ({ status }) => {
  return <Wrapper status={status} />;
};

export default NavStatus;
