import { faListUl, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums/RouteName';
import { fetchProjectSuccess } from '../../store/project';
import { resetStagesAndSubStages } from '../../store/project-stage';
import InitialProject from '../../store/project/initial-project';
import { defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavIcons = {
  [RouteName.PROJECTS]: faListUl,
  [RouteName.PROJECT_ITEM]: faPlusSquare,
};

const ProjectsNavigation: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <NavItem
        key={`projects-view`}
        onClick={() => history.push(RouteName.PROJECTS)}
        isActive={location.pathname === RouteName.PROJECTS}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.PROJECTS]}
          color={defaultTheme.colors.system.white}
        />
        <NavTitle text={'View Projects'} />
      </NavItem>

      {/*Deprecated*/}
      {/*<NavItem*/}
      {/*  key={`projects-create`}*/}
      {/*  onClick={() => {*/}
      {/*    // Reset project data*/}
      {/*    dispatch(resetStagesAndSubStages());*/}
      {/*    dispatch(fetchProjectSuccess(InitialProject));*/}
      {/*    history.push(RouteName.PROJECT_ITEM);*/}
      {/*  }}*/}
      {/*  isActive={location.pathname === RouteName.PROJECT_ITEM}*/}
      {/*>*/}
      {/*  <FontAwesomeIcon*/}
      {/*    icon={NavIcons[RouteName.PROJECT_ITEM]}*/}
      {/*    color={defaultTheme.colors.system.white}*/}
      {/*  />*/}
      {/*  <NavTitle text={'Create New Project'} />*/}
      {/*</NavItem>*/}
    </>
  );
};

export default ProjectsNavigation;
