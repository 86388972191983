import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import { DEFAULT_DATA_PAGE_SIZE } from '../../hooks/pagination/UsePagination';
import { defaultTheme, StyledText } from '../../main/theme';
import { IntegrationConfigItem } from '../../services/integration/IntegrationConfigService';
import { IntegrationTemplateItem } from '../../services/integration/IntegrationTemplateService';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import IntegrationCard from './IntegrationCard';
import * as SC from './styled';

const DEFAULT_PAGE_SIZE = 24;

interface IntegrationTableProps {
  loading?: boolean;
  integrationTemplates: IntegrationTemplateItem[];
  integrationConfigs: IntegrationConfigItem[];
  iconMap?: Record<string, string>;
  showPagination?: boolean;
  resetPagination?: boolean;
  paginationAction?: (page: number) => void;
  totalRows?: number;
  pageSize?: number;
  integrationUsage: number;
}

const IntegrationTable: FC<IntegrationTableProps> = ({
  integrationTemplates,
  integrationConfigs,
  loading,
  showPagination = false,
  paginationAction,
  resetPagination,
  totalRows = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  integrationUsage,
}) => {
  const themeContext = useContext(ThemeContext);

  const [page, setPage] = useState<number>(1);

  const totalPageCount = Math.ceil(totalRows / pageSize);

  // Fetch new data
  useEffect(() => {
    if (paginationAction) {
      paginationAction(page);
    }
  }, [page, paginationAction]);

  // Reset pagination
  useEffect(() => {
    if (resetPagination) {
      setPage(1);
    }
  }, [resetPagination]);

  return (
    <SC.Outer fullWidth={true}>
      <SC.Wrapper noBorder={loading || integrationTemplates?.length > 0}>
        {loading && (
          <SC.NoResults>
            <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
          </SC.NoResults>
        )}

        {!loading && integrationTemplates.length === 0 && (
          <SC.NoResults>
            <StyledText>No results found</StyledText>
          </SC.NoResults>
        )}

        {!loading &&
          integrationTemplates.length > 0 &&
          integrationTemplates.map((integrationTemplate, index) => {
            const integrationConfig = integrationConfigs.find(
              (config) =>
                config.entity.integrationTemplateId ===
                integrationTemplate.entity._id,
            );

            return (
              <IntegrationCard
                key={`integration-${integrationTemplate.entity._id}-${index}`}
                integrationTemplate={integrationTemplate}
                integrationConfig={integrationConfig}
                usage={integrationUsage}
              />
            );
          })}
      </SC.Wrapper>

      {!loading &&
        (showPagination ||
          integrationTemplates.length > DEFAULT_DATA_PAGE_SIZE) &&
        paginationAction && (
          <SC.PaginationWrapper>
            <SC.PaginationNavigation>
              <>
                <StyledText>Skip to page</StyledText>
                <SC.SkipPageDropdown
                  options={[...Array(totalPageCount)].map((num, i) => {
                    return {
                      key: `page-${i + 1}-${i}`,
                      value: i + 1,
                      text: i + 1,
                    };
                  })}
                  value={page}
                  selection
                  onChange={(e, data) => setPage(data.value)}
                />
              </>
            </SC.PaginationNavigation>

            <StyledText>
              {totalRows > 0 && (
                <>
                  Page <strong>{page}</strong> of{' '}
                  <strong>{totalPageCount}</strong>
                </>
              )}
              {totalRows === 0 && (
                <>
                  Page <strong>{page}</strong>
                </>
              )}
            </StyledText>
            <SC.PaginationButtons>
              <FeatureButton
                isDisabled={page === 1}
                action={() => setPage(1)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.sea}
                icon={
                  <FontAwesomeIcon
                    icon={faAngleDoubleLeft}
                    color={defaultTheme.colors.system.white}
                    size={'lg'}
                  />
                }
              />

              <FeatureButton
                isDisabled={page === 1}
                action={() => setPage(page - 1)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.sea}
                icon={
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    color={defaultTheme.colors.system.white}
                    size={'lg'}
                  />
                }
              />
              <FeatureButton
                isDisabled={!totalRows || page === totalPageCount}
                action={() => setPage(page + 1)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.sea}
                icon={
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    color={defaultTheme.colors.system.white}
                    size={'lg'}
                  />
                }
              />
              <FeatureButton
                isDisabled={!totalRows || page === totalPageCount}
                action={() => setPage(totalPageCount)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.sea}
                icon={
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={defaultTheme.colors.system.white}
                    size={'lg'}
                  />
                }
              />
            </SC.PaginationButtons>
          </SC.PaginationWrapper>
        )}
    </SC.Outer>
  );
};

export default IntegrationTable;
