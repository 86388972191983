import { Interfaces } from '@configur-tech/upit-core-types';
import { ChartItemOutput, ChartsItemOutput } from '../../store/charts';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_CHART'] || '';

export default class ChartService {
  public static async getCharts(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<ChartsItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<ChartsItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getChart(
    token: string,
    _id: string,
    portalOriginId?: string,
    internal?: boolean,
  ): Promise<ChartItemOutput> {
    const ah = new HttpHelper(token);

    const url = internal ? `${endpoint}internal` : endpoint;
    const chart = internal
      ? { _id, portalOriginId }
      : {
          _id,
          portalOriginId,
          singleDocument: true,
        };

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<ChartItemOutput>
      | Interfaces.ErrorResponseBody
    >(url, chart);

    return result?.data as ChartItemOutput;
  }

  public static async postChart(
    token: string,
    chart: Interfaces.Chart,
    userId: string,
    portalId?: string,
  ): Promise<ChartItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<ChartItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: chart, userId, portalId });

    return result?.data as ChartItemOutput;
  }

  public static async putChart(
    token: string,
    chart: Interfaces.ChartOutput,
    userId: string,
    portalId?: string,
  ): Promise<ChartItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<ChartItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${chart._id}`, { data: chart, userId, portalId });

    return result?.data as ChartItemOutput;
  }

  public static async deleteChart(
    token: string,
    chartId: string,
    userId: string,
    projectId?: string,
  ): Promise<ChartItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<ChartItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${chartId}`, userId, projectId);

    return result?.data as ChartItemOutput;
  }
}
