import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChartService from '../../services/chart/ChartService';
import { ChartItem, ChartItemOutput } from '../charts';
import { fetchGraphSuccess } from '../graph';
// import { Chart } from '../charts/initial-state';
import { AppThunk } from '../store';
import InitialChart from './initial-state';

type Chart = ChartItem | ChartItemOutput;

export interface ChartState {
  data: Chart;
  loading: boolean;
  error: string | null;
}

const initialState: ChartState = {
  data: InitialChart,
  loading: false,
  error: null,
};

const ChartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    // Reset
    resetChart(state) {
      state.data = InitialChart;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchChartStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchChartSuccess(state, action: PayloadAction<Chart>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchChartFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createChartStart(state) {
      state.loading = true;
      state.error = null;
    },
    createChartSuccess(state, action: PayloadAction<ChartItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createChartFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateChartStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateChartSuccess(state, action: PayloadAction<ChartItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateChartFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteChartStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteChartSuccess(state, action: PayloadAction<ChartItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteChartFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetChart,
  fetchChartStart,
  fetchChartSuccess,
  fetchChartFailure,
  createChartStart,
  createChartSuccess,
  createChartFailure,
  updateChartStart,
  updateChartSuccess,
  updateChartFailure,
  deleteChartStart,
  deleteChartSuccess,
  deleteChartFailure,
} = ChartSlice.actions;

export default ChartSlice.reducer;

export const fetchChart =
  (token: string, id: string, portalId?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchChartStart());
      const fetched = await ChartService.getChart(token, id, portalId);
      dispatch(fetchChartSuccess(fetched));
    } catch (err) {
      dispatch(fetchChartFailure((err as string).toString()));
    }
  };

export const createChart =
  (
    token: string,
    chart: Interfaces.Chart,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createChartStart());
      const created = await ChartService.postChart(
        token,
        chart,
        userId,
        portalId,
      );
      dispatch(createChartSuccess(created));
    } catch (err) {
      dispatch(createChartFailure((err as string).toString()));
    }
  };

export const updateChart =
  (
    token: string,
    chart: Interfaces.ChartOutput,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateChartStart());
      const updated = await ChartService.putChart(
        token,
        chart,
        userId,
        portalId,
      );
      dispatch(updateChartSuccess(updated));
    } catch (err) {
      dispatch(updateChartFailure((err as string).toString()));
    }
  };

export const deleteChart =
  (
    token: string,
    chartId: string,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteChartStart());
      const deleted = await ChartService.deleteChart(
        token,
        chartId,
        userId,
        portalId,
      );
      dispatch(deleteChartSuccess(deleted));
    } catch (err) {
      dispatch(deleteChartFailure((err as string).toString()));
    }
  };
