import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { GraphType } from '@configur-tech/upit-core-types/lib/enums';
import {
  faChartLine,
  faTable,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../assets/icons/loading/default-loading-icon.gif';
import { IconSize } from '../../../enums';
import useTableData from '../../../hooks/dataset-meta/UseTableData';
import useDataset from '../../../hooks/dataset/UseDataset';
import useFilter, {
  DynamicConditionalField,
} from '../../../hooks/filter/UseFilter';
import { SampleData } from '../../../interfaces/SampleData';
import { defaultTheme } from '../../../main/theme';
import { MAP_GRAPH_PAGE_SIZE } from '../../../services/dataset/DatasetService';
import { DatasetState, GRAPH_RAW_DATA } from '../../../store/dataset';
import { GraphOutputWithDetails } from '../../../store/graph';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import AvatarIconMap from '../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import GraphDisplay from '../../GraphDisplay/GraphDisplay';
import { ROW_ID_FIELD } from '../cms/CMSAddRowModal';
import * as SC from './styled';

export interface GraphModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  graph: GraphOutputWithDetails;
  graphData: Record<string, unknown>[];
  dataDrilldown: (rowId: number) => void;
  params: Params;
}

export interface Params {
  projectId: string;
  cmsId: string;
  groupId: string;
  portalId: string;
}

export const LOCAL_STORAGE_TEMP_FILTERS_KEY = 'TEMP_FILTERS';
export const LOCAL_STORAGE_DASHBOARD_URL = 'DASHBOARD_URL';

const MODAL_CONTAINER_REDUCTION = 60;

const GraphModal: FC<GraphModalProps> = ({
  setShowModal,
  graph,
  graphData,
  dataDrilldown,
  params,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getDatasetFilters } = useFilter();
  const { getGraphDataset } = useDataset();
  const { createTableData } = useTableData();
  const elementRef = useRef(null);

  const [graphDisplayed, setGraphDisplayed] = useState<boolean>(true);
  const [filters, setFilters] = useState<DynamicConditionalField[]>();
  const [sampleData, setSampleData] = useState<Record<string, unknown>[]>();
  const [loadedSampleData, setLoadedSampleData] = useState<boolean>();
  const [entrySchema, setEntrySchema] = useState<Interfaces.SchemaField[]>([]);
  const [tableData, setTableData] = useState<SampleData>();

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );

  const graphDataRaw = useMemo(() => {
    return datasetState?.data?.[`${graph._id}${GRAPH_RAW_DATA}`]?.entries || [];
  }, [datasetState?.data, graph._id]);

  const graphModalHeight =
    elementRef && elementRef.current?.['clientHeight']
      ? elementRef.current?.['clientHeight']
      : 0;

  const dataSampleHeight = graphModalHeight
    ? graphModalHeight - MODAL_CONTAINER_REDUCTION
    : 0;

  // Build tableData
  useEffect(() => {
    if (!entrySchema || !sampleData) {
      return;
    }
    setTableData(createTableData(entrySchema, sampleData));
  }, [entrySchema, sampleData, createTableData]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Fetch active filters
  useEffect(() => {
    if (!graph?._id) {
      return;
    }

    const activeFilters = getDatasetFilters(graph._id).reduce((acc, filter) => {
      if ((filter.value as DynamicConditionalField[]).length) {
        const active = (filter.value as DynamicConditionalField[]).filter(
          (f) => f.active,
        );

        if (active.length) {
          return [...acc, { ...filter, value: active }];
        }
      }

      return acc;
    }, [] as DynamicConditionalField[]);

    setFilters(activeFilters);
  }, [getDatasetFilters, graph?._id, graph]);

  // Fetch initial sample data
  useEffect(() => {
    if (entrySchema.length) {
      return;
    }

    if (graphDataRaw.length) {
      // Get graph and sample data from datasetState
      setSampleData(graphDataRaw);

      let schema: Interfaces.SchemaField[];

      if (graph?.queryParams.displayOrder?.length) {
        // Has user defined display order
        schema = graph.queryParams.displayOrder
          ?.map((displayOrderItem) => {
            const entry = Object.entries(graphDataRaw[0]).find(
              ([key]) => key === displayOrderItem.alias,
            );

            return {
              field: displayOrderItem.alias || displayOrderItem.field,
              alias: displayOrderItem.alias || displayOrderItem.field,
              type: [
                Services.validators.DataValidator.isNumber(entry?.[1])
                  ? Enums.ValueDataType.NUMBER
                  : Enums.ValueDataType.TEXT,
              ],
            };
          })
          .concat(
            // Add additional measures that haven't been ordered
            (graph?.queryParams.measures || [])
              .filter(
                (measure) =>
                  !graph.queryParams.displayOrder?.find(
                    (displayOrderItem) =>
                      displayOrderItem.alias === measure.alias,
                  ),
              )
              .map((measure) => {
                return {
                  field: measure.alias || measure.field,
                  alias: measure.alias || measure.field,
                  type: [Enums.ValueDataType.TEXT],
                };
              }),
          )
          .concat(
            // Add additional measures that haven't been ordered
            (graph?.queryParams.supercolumns || [])
              .filter(
                (supercol) =>
                  !graph.queryParams.displayOrder?.find(
                    (displayOrderItem) =>
                      displayOrderItem.alias === supercol.alias,
                  ),
              )
              .map((supercol) => {
                return {
                  field: supercol.alias || supercol.field,
                  alias: supercol.alias || supercol.field,
                  type: [Enums.ValueDataType.TEXT],
                };
              }),
          );
      } else {
        schema = Object.keys(graphDataRaw[0]).map((k) => {
          return {
            field: k,
            alias: k,
            type: [
              Services.validators.DataValidator.isNumber(k)
                ? Enums.ValueDataType.NUMBER
                : Enums.ValueDataType.TEXT,
            ],
          };
        });
      }

      setEntrySchema(schema);
      setLoadedSampleData(true);
      return;
    }

    if (graph?._id && filters && !loadedSampleData) {
      (async () => {
        await getGraphDataset(
          graph._id,
          filters || [],
          undefined,
          undefined,
          true,
          graph.graphParams.graphType === GraphType.MAP
            ? MAP_GRAPH_PAGE_SIZE
            : undefined,
        );
      })();
    }
  }, [
    datasetState.data,
    entrySchema,
    filters,
    getGraphDataset,
    graph?._id,
    graph?.queryParams.displayOrder,
    graph?.queryParams.measures,
    graph?.queryParams.supercolumns,
    graphDataRaw,
    graphDataRaw.length,
    loadedSampleData,
  ]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>
          {graph?.graphParams?.graphTextOpts?.title || 'Viewing Graph'}
        </SC.Header>

        <div style={{ display: 'flex' }}>
          {graph?.graphParams.graphType !== Enums.GraphType.TABLE &&
            graph?.graphParams.graphType !== Enums.GraphType.GANTT && (
              <FeatureButton
                action={() => setGraphDisplayed(!graphDisplayed)}
                size={FeatureButtonSize.EXTRA_SMALL}
                color={themeContext.colors.general.blue}
                icon={
                  graphDisplayed ? (
                    <FontAwesomeIcon
                      icon={faTable}
                      color={themeContext.colors.system.white}
                      size={IconSize.LARGE}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChartLine}
                      color={themeContext.colors.system.white}
                      size={IconSize.LARGE}
                    />
                  )
                }
                style={{ marginRight: defaultTheme.margin.standard }}
              />
            )}

          <FeatureButton
            action={() => dispatch(hideModal())}
            size={FeatureButtonSize.EXTRA_SMALL}
            color={themeContext.colors.general.sea}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                color={themeContext.colors.system.white}
                size={IconSize.LARGE}
              />
            }
          />
        </div>
      </SC.HeaderContainer>
      <SC.ContentContainer ref={elementRef}>
        {graphDisplayed && (
          <GraphDisplay graph={graph} graphData={graphData} params={params} />
        )}
        {!graphDisplayed && !loadedSampleData && (
          <SC.LoadingContainer>
            <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
          </SC.LoadingContainer>
        )}
        {!graphDisplayed && (
          <DataSample
            schema={entrySchema}
            fullWidth={true}
            sampleColumns={tableData?.schema?.length ? tableData?.schema : []}
            sampleRows={tableData?.rows || []}
            iconMap={{ ...UserIconMap, ...AvatarIconMap }}
            fixedHeight={dataSampleHeight}
            pageSize={
              process.env['REACT_APP_GRAPH_PAGE_SIZE']
                ? parseInt(process.env['REACT_APP_GRAPH_PAGE_SIZE'])
                : undefined
            }
            loading={!loadedSampleData}
            clickableRows={{
              valueField: ROW_ID_FIELD,
              action: (rowId: string) => dataDrilldown(parseInt(rowId) - 1),
            }}
          />
        )}
      </SC.ContentContainer>
    </SC.Wrapper>
  );
};

export default GraphModal;
