import { useAuth0 } from '@auth0/auth0-react';
import { UserType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import defaultLogo from '../../assets/icons/system/codex-icon.svg';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import usePortal from '../../hooks/portal/UsePortal';
import useUser from '../../hooks/user/UseUser';
import EntityColorMap from '../../util/color-helpers/EntityColorMap';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

interface UserNavigationProps {
  lightTheme?: boolean;
}

const Wrapper = styled.div<UserNavigationProps>`
  display: flex;
  justify-content: flex-end;

  padding: ${({ theme }) => theme.padding.standard};

  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  padding-right: ${({ theme }) => theme.padding.standard};
`;

const UserName = styled.p`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  padding-bottom: ${({ theme }) => theme.padding.small};
`;

const LogOutButton = styled.button<UserNavigationProps>`
  background: none;
  border: none;
  outline: none;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};

  &:hover {
    cursor: pointer;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const UserNavigation: FC<UserNavigationProps> = ({ lightTheme }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const { logout } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { user } = useUser();
  const { portal } = usePortal();

  const domain = process.env['REACT_APP_DOMAIN'] || window.location.pathname;

  const isPortal = location.pathname.includes(RouteName.PORTAL);

  const loggedInUserAvatar =
    loggedInUser?.avatar && loggedInUser?.avatar.includes('http')
      ? loggedInUser?.avatar
      : loggedInUser?.avatar
      ? { ...UserIconMap, ...AvatarIconMap }[loggedInUser?.avatar]
      : defaultLogo;

  return (
    <Wrapper lightTheme={lightTheme}>
      <UserInfo>
        {loggedInUser?.signupComplete && (
          <UserName>
            {`${loggedInUser?.firstName || ''} ${loggedInUser?.lastName || ''}`}
          </UserName>
        )}
        <LogOutButton
          lightTheme={lightTheme}
          onClick={() =>
            logout({
              returnTo: domain,
            })
          }
        >
          LOG OUT
        </LogOutButton>
      </UserInfo>

      <FeatureButton
        isActive={
          location.pathname.includes(RouteName.USER_ITEM) &&
          (params?.id || user?._id) === loggedInUser?._id
        }
        style={{ marginBottom: themeContext.margin.standard }}
        // Deprecated
        // action={() =>
        //   isPortal
        //     ? history.push(
        //         `${RouteName.PORTAL}/${portal?._id}${RouteName.USER_ITEM}`,
        //       )
        //     : loggedInUser?.userType === UserType.PORTAL
        //     ? history.push(`${RouteName.USER_ITEM}`)
        //     : history.push(`${RouteName.USER_ITEM}/${loggedInUser?._id}`)
        // }
        size={FeatureButtonSize.EXTRA_SMALL}
        color={EntityColorMap[EntityType.USER]}
        borderColor={themeContext.colors.system.white}
        image={loggedInUserAvatar}
      />
    </Wrapper>
  );
};

export default UserNavigation;
