import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import OverviewIcon from '../../assets/icons/stages/overview-user/overview.svg';
import Banner from '../../components/BannerComponent/Banner';
import DataSample from '../../components/DataSample/DataSample';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import {
  AlertStatus,
  BannerType,
  QuotaLimits,
  ResourceType,
  RouteName,
} from '../../enums';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import useUsage from '../../hooks/usage/useUsage';
import useUser from '../../hooks/user/UseUser';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { UserItemOutput } from '../../services/user/UserService';
import { RootState } from '../../store/rootReducer';
import { fetchUserSuccess } from '../../store/user';
import { resetStagesAndSubStages } from '../../store/user-stage';
import InitialUser from '../../store/user/initial-user';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';

const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const EMAIL_FIELD = 'Email';
const JOB_ROLE_FIELD = 'Job Role';
const USER_TYPE_FIELD = 'Type';
const STATUS_FIELD = 'Status';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: EMAIL_FIELD },
  { name: JOB_ROLE_FIELD },
  { name: USER_TYPE_FIELD },
  { name: STATUS_FIELD },
];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const UsersPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { getUsers } = useUser();
  const { checkResourceUsage } = useUsage();
  const { organisation } = useOrganisation();

  const usersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  const [searchVal, setSearchVal] = useState<string>();
  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserItemOutput[]>(users);
  const [userUsage, setUserUsage] = useState<number>(0);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoadingUsers(true);
      await getUsers();
      setLoadingUsers(false);
    })();
  }, [dispatch, getUsers]);

  // Get usage and show banner if approaching quota
  useEffect(() => {
    if (organisation?._id) {
      (async () => {
        const userUsage = await checkResourceUsage(
          organisation._id,
          ResourceType.USERS,
        );
        setUserUsage(userUsage.usagePercentage);
      })();
    }
  }, [checkResourceUsage, dispatch, organisation?._id]);

  useEffect(() => {
    if (filteredUsers) {
      const tableData: SampleDataRow[] = [];

      filteredUsers.map((u) => {
        const ent = u.entity;
        if (ent._id) {
          tableData.push({
            [ID_FIELD]: { value: ent._id },
            [NAME_FIELD]: {
              value: `${ent.firstName} ${ent.lastName}`,
              avatar: ent.avatar,
            },
            [EMAIL_FIELD]: { value: ent.email },
            [JOB_ROLE_FIELD]: { value: ent.jobRole || '' },
            [USER_TYPE_FIELD]: { value: startCase(ent.userType) || '' },
            [STATUS_FIELD]: {
              value: ent.signupComplete ? 'Active' : 'Invite Pending',
            },
          });
        }
      });

      setTableData(tableData);
    }
  }, [dispatch, filteredUsers]);

  useEffect(() => {
    if (users.length) {
      setFilteredUsers(
        users
          .filter((u) =>
            searchVal
              ? `${u.entity.firstName} ${u.entity.lastName}`
                  .toLowerCase()
                  .includes(searchVal.toLowerCase())
              : true,
          )
          .sort((a, b) => {
            return new Date(a.entity.meta?.lastUpdated).getTime() >
              new Date(b.entity.meta?.lastUpdated).getTime()
              ? -1
              : 1;
          }),
      );
    }
  }, [searchVal, users]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.USERS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'View Users'}
          image={OverviewIcon}
          title={'Say Hello To The Squad'}
        />

        <Page>
          <PageInner>
            <Banner
              message={
                <p>
                  Users have been made even easier to manage and can now be
                  found{' '}
                  <a href={'https://portal.goconfigur.com/organisation/users'}>
                    here
                  </a>
                  .
                </p>
              }
              alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
              alertStatus={AlertStatus.INFO}
            />

            <StageBodyText style={{ marginTop: 20 }}>
              The table below shows all of the users in your organisation.
            </StageBodyText>

            {/*Deprecated*/}
            {/*<StageBodyText>*/}
            {/*  Tap the button below if you'd like to create a new user, or select*/}
            {/*  one to update an existing one.*/}
            {/*</StageBodyText>*/}

            {/*{userUsage >= QuotaLimits.WARNING && (*/}
            {/*  <div style={{ marginBottom: themeContext.margin.xlarge }}>*/}
            {/*    {BuildBanner.generateBanner(*/}
            {/*      BannerType.QUOTA,*/}
            {/*      userUsage >= QuotaLimits.FULL*/}
            {/*        ? AlertStatus.ERROR*/}
            {/*        : AlertStatus.WARNING,*/}
            {/*      ResourceType.USERS,*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*<FeatureButton*/}
            {/*  action={() => {*/}
            {/*    // Reset user and stage data*/}
            {/*    dispatch(resetStagesAndSubStages());*/}
            {/*    dispatch(fetchUserSuccess(InitialUser));*/}
            {/*    history.push(RouteName.USER_ITEM);*/}
            {/*  }}*/}
            {/*  size={FeatureButtonSize.WIDE}*/}
            {/*  color={themeContext.colors.general.blue}*/}
            {/*  text={'Create new user'}*/}
            {/*  containerStyle={{ marginBottom: themeContext.margin.large }}*/}
            {/*  isDisabled={userUsage >= QuotaLimits.FULL}*/}
            {/*/>*/}

            {users.length > 0 && (
              <EntityFilter
                baseRoute={RouteName.USERS}
                title={'Search Users'}
                inputPlaceholder={'Enter a user name'}
                onSearchValueChange={(value) => setSearchVal(value)}
                totalResultCount={users?.length}
                filteredResultCount={filteredUsers?.length}
              />
            )}

            <TableWrapper>
              <DataSample
                pageSize={300}
                loading={loadingUsers}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                // Deprecated
                // clickableRows={{
                //   valueField: ID_FIELD,
                //   action: (value: string) => {
                //     dispatch(resetStagesAndSubStages());
                //     history.push(`${RouteName.USER_ITEM}/${value}`);
                //   },
                // }}
                iconMap={{ ...UserIconMap, ...AvatarIconMap }}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default UsersPage;
