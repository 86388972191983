import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums';
import useGraph from '../../hooks/graph/UseGraph';
import useProject from '../../hooks/project/UseProject';
import useQuery from '../../hooks/query/UseQuery';
import { DefaultPopupStyles } from '../../main/theme';
import { showModal } from '../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';

interface AggregationSupercolumnProps {
  supercolumnItems: Interfaces.SupercolumnField[];
  isMultipleDatasetQuery: boolean;
  entityType: EntityType;
}

const AggregationSupercolumn: FC<AggregationSupercolumnProps> = ({
  supercolumnItems,
  entityType,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { query } = useQuery();
  const { graph } = useGraph();
  const { projectAccessLevel } = useProject();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  return (
    <>
      {supercolumnItems?.map((supercolumn, index) => {
        return (
          <Popup
            key={`supercolumn-item-${supercolumn.datasetMetaId}-${supercolumn.field}-${supercolumn.alias}-${index}`}
            content={supercolumn.alias}
            position="left center"
            style={DefaultPopupStyles}
            hideOnScroll={true}
            trigger={
              <div>
                <FeatureButton
                  isDisabled={!isProjectManager}
                  isActive={!isProjectManager}
                  action={() => {
                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.ENHANCEMENT_ADD_COLUMN,
                        fullScreen: true,
                        additionalProps: {
                          existingSupercol: {
                            index,
                            supercolumn,
                          },
                          dataCollectionId:
                            entityType === EntityType.GRAPH
                              ? graph?.queryParams.datasetMetaId
                              : query?.queryParams.datasetMetaId,
                          query:
                            entityType === EntityType.GRAPH ? graph : query,
                          entityType:
                            entityType === EntityType.GRAPH
                              ? EntityType.GRAPH
                              : EntityType.AGGREGATION,
                        },
                      }),
                    );
                  }}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.purple}
                  text={supercolumn.alias}
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  faceStyle={{ width: '100%' }}
                />
              </div>
            }
          />
        );
      })}

      {isProjectManager && (
        <FeatureButton
          action={() => {
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.ENHANCEMENT_ADD_COLUMN,
                fullScreen: true,
                additionalProps: {
                  field: undefined,
                  type: undefined,
                  dataCollectionId: query?.queryParams.datasetMetaId,
                  query: query,
                  entityType: entityType,
                },
              }),
            );
          }}
          size={FeatureButtonSize.WIDE_SMALL}
          color={themeContext.colors.general.blue}
          text={'Add Super Column'}
          containerStyle={{ width: '100%' }}
          style={{ width: '100%' }}
          faceStyle={{
            width: '100%',
          }}
        />
      )}
    </>
  );
};

export default AggregationSupercolumn;
