import { Enums } from '@configur-tech/upit-core-types';
import styled from 'styled-components';
import { StyledAccordion, StyledText } from '../../../../main/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin: 0;
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const AdvancedAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 100%;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const AreaWrapper = styled.div<{
  readOnly?: boolean;
  hideBorder?: boolean;
}>`
  margin-top: ${({ theme }) => theme.margin.standard};
  margin-bottom: ${({ theme }) => theme.margin.xxxlarge};
  width: 100%;

  border-radius: ${({ theme }) => theme.borders.radius};

  ${({ theme, readOnly, hideBorder }) =>
    readOnly && !hideBorder
      ? `border: 1px solid ${theme.colors.system.grey};`
      : !hideBorder && `border: 2px dashed ${theme.colors.system.grey};`};

  padding: ${({ theme }) => theme.padding.standard};
`;

export const Area = styled.div`
  display: flex;
  justify-content: space-between;

  > div:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.padding.large};
  }
`;

export const AreaControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const Panel = styled.div<{ readOnly?: boolean }>`
  ${({ theme, readOnly }) =>
    readOnly
      ? `border: none`
      : `border: 2px dashed ${theme.colors.system.grey};`};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.standard}
    ${({ theme }) => theme.padding.standard} 0;
  width: 100%;
  ${({ readOnly }) => (readOnly ? `min-height: auto` : `min-height: 100px;`)};
  background-color: ${({ theme }) => theme.colors.system.white};

  .codex-editor__loader {
    max-height: 100px !important;
  }
  #react-editor-description-description-panel {
    width: 100%;
    max-width: 100%;
  }
  .ce-block__content {
    max-width: 100%;
  }
  .ce-toolbar__content {
    max-width: 96%;
  }
  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 90px;
  }

  .image-tool__caption {
    border: none;
    box-shadow: none;
    text-align: center;
    font-weight: bold;

    // Caption placeholder
    &:before {
      text-align: center;
      width: 100%;
      padding-right: ${({ theme }) => theme.padding.large};
      font-weight: bold !important;
    }
  }
  .image-tool--withBackground .image-tool__image {
    background: ${({ theme }) => theme.colors.system.white};
  }

  ${({ readOnly }) =>
    readOnly &&
    `.codex-editor__redactor {
      padding-bottom: 0 !important;
    }
    .image-tool__caption:empty {
    display: none !important;
  }
  `};

  .codex-editor__redactor {
    padding-bottom: ${({ theme }) => `${theme.padding.xxlarge} !important`};
  }

  display: flex;
  flex-direction: column;
`;

export const PanelButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const EmptyPanel = styled.div<{ areaSize: Enums.AreaSize }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;

  padding: 0 > p {
    padding: ${({ theme, areaSize }) =>
      areaSize === Enums.AreaSize.ONE_COLUMN ||
      areaSize === Enums.AreaSize.TWO_COLUMN
        ? `0 ${theme.padding.xxxlarge}`
        : 0};
  }
`;
