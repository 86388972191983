import {
  faCircleNotch,
  faDatabase,
  faGlobe,
  faProjectDiagram,
  faRocket,
  faSync,
  faTimeline,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import UsageMetricCard from '../../components/UsageMetricCard/UsageMetricCard';
import UsageProgressCard from '../../components/UsageProgressCard/UsageProgressCard';
import { EntityType, RouteName } from '../../enums';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import useUsage from '../../hooks/usage/useUsage';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import {
  StageInner,
  StageWrapper,
  StyledH1,
  StyledText,
} from '../../main/theme';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import * as SC from './styled';

const DATE_FORMAT = 'dd/MM/yyyy';

const OrganisationUsagePage: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { organisation } = useOrganisation();
  const { usage, getOrgUsage } = useUsage();
  const dispatch = useDispatch();

  // Fetch Org Usage
  useEffect(() => {
    (async () => {
      if (!organisation?._id) {
        return;
      }

      await getOrgUsage(organisation._id);
    })();
  }, [dispatch, getOrgUsage, organisation?._id]);

  const renewalDate = DateTime.fromISO(
    usage?.dynamicResources.apiCalls.periodDates.end,
  )
    .plus({ days: 1 })
    .toFormat(DATE_FORMAT)
    .toString();

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.ORGANISATION_ITEM} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'Organisation Usage'}
          image={getDefaultAvatar(EntityType.ORGANISATION)}
          title={'Insight Into Your Organisations Usage'}
        />
        <Page>
          <StageWrapper>
            <StageInner>
              {usage && usage.staticResources.datasetMetas.available !== 0 && (
                <>
                  <StyledH1>Lifetime</StyledH1>
                  <SC.CardRows>
                    <UsageMetricCard
                      title={'Data Points'}
                      icon={faProjectDiagram}
                      usage={usage.staticResources.dataPoints.used}
                      quota={usage.staticResources.dataPoints.available}
                    />
                    <UsageMetricCard
                      title={'Datasets'}
                      icon={faDatabase}
                      iconColor={themeContext.colors.general.green}
                      usage={usage.staticResources.datasetMetas.used}
                      quota={usage.staticResources.datasetMetas.available}
                    />
                    <UsageMetricCard
                      title={'Users'}
                      icon={faUser}
                      usage={usage.staticResources.users.used}
                      quota={usage.staticResources.users.available}
                      iconColor={themeContext.colors.general.blue}
                    />
                    <UsageMetricCard
                      title={'Projects'}
                      icon={faProjectDiagram}
                      usage={usage.staticResources.projects.used}
                      quota={usage.staticResources.projects.available}
                      iconColor={themeContext.colors.general.purple}
                    />
                    <UsageMetricCard
                      title={'Portals'}
                      icon={faCircleNotch}
                      usage={usage.staticResources.portals.used}
                      quota={usage.staticResources.portals.available}
                    />
                    <UsageMetricCard
                      title={'Teams'}
                      icon={faUsers}
                      usage={usage.staticResources.teams.used}
                      quota={usage.staticResources.teams.available}
                      iconColor={themeContext.colors.general.yellow}
                    />
                    <UsageMetricCard
                      title={'Custom APIs'}
                      icon={faGlobe}
                      usage={usage.staticResources.apis.used}
                      quota={usage.staticResources.apis.available}
                    />
                    <UsageMetricCard
                      title={'Snapshots'}
                      icon={faSync}
                      usage={usage.staticResources.snapshots.used}
                      quota={usage.staticResources.snapshots.available}
                    />
                    <UsageMetricCard
                      title={'Pipelines'}
                      usage={usage.staticResources.pipelines.used}
                      quota={usage.staticResources.pipelines.available}
                      icon={faTimeline}
                    />
                    <UsageMetricCard
                      title={'Custom Integrations'}
                      icon={faRocket}
                      usage={
                        usage.staticResources.customPipelineIntegrations.used
                      }
                      quota={
                        usage.staticResources.customPipelineIntegrations
                          .available
                      }
                      iconColor={themeContext.colors.general.red}
                    />
                  </SC.CardRows>
                  <StyledH1
                    style={{
                      marginTop: themeContext.margin.large,
                      marginBottom: themeContext.margin.small,
                    }}
                  >
                    {startCase(usage.dynamicResources.apiCalls.period)}
                  </StyledH1>
                  <StyledText
                    style={{
                      marginTop: themeContext.margin.small,
                      color: themeContext.colors.system.darkGrey,
                    }}
                  >
                    Usage Resets: {renewalDate}
                  </StyledText>
                  <SC.CardRows>
                    <UsageProgressCard
                      title={'API Calls'}
                      icon={faGlobe}
                      usage={usage.dynamicResources.apiCalls.used}
                      quota={usage.dynamicResources.apiCalls.available}
                    />
                    <UsageProgressCard
                      title={'Pipeline Runs'}
                      icon={faTimeline}
                      usage={usage.dynamicResources.pipelineRuns.used}
                      quota={usage.dynamicResources.pipelineRuns.available}
                    />
                  </SC.CardRows>
                </>
              )}
            </StageInner>
          </StageWrapper>
        </Page>
      </PageContainer>
    </>
  );
};

export default OrganisationUsagePage;
