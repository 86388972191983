import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DatasetMetaService, {
  DatasetMetaItem,
  DatasetMetaItemOutput,
} from '../../services/dataset-meta/DatasetMetaService';
import DatasetService from '../../services/dataset/DatasetService';
import { AppThunk } from '../store';
import InitialDatasetMeta from './initial-dataset-meta';

type DatasetMeta = DatasetMetaItem | DatasetMetaItemOutput;

export interface DatasetMetaState {
  data: DatasetMeta;
  loading: boolean;
  error: string | null;
}

const initialState: DatasetMetaState = {
  data: InitialDatasetMeta,
  loading: false,
  error: null,
};

const DatasetMetaSlice = createSlice({
  name: 'datasetMeta',
  initialState,
  reducers: {
    // Fetch
    fetchDatasetMetaStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetMetaSuccess(state, action: PayloadAction<DatasetMeta>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDatasetMetaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createDatasetMetaStart(state) {
      state.loading = true;
      state.error = null;
    },
    createDatasetMetaSuccess(state, action: PayloadAction<DatasetMeta>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createDatasetMetaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateDatasetMetaStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateDatasetMetaSuccess(state, action: PayloadAction<DatasetMeta>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateDatasetMetaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteDatasetMetaStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteDatasetMetaSuccess(state, action: PayloadAction<DatasetMeta>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteDatasetMetaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDatasetMetaStart,
  fetchDatasetMetaSuccess,
  fetchDatasetMetaFailure,
  createDatasetMetaStart,
  createDatasetMetaSuccess,
  createDatasetMetaFailure,
  updateDatasetMetaStart,
  updateDatasetMetaSuccess,
  updateDatasetMetaFailure,
  deleteDatasetMetaStart,
  deleteDatasetMetaSuccess,
  deleteDatasetMetaFailure,
} = DatasetMetaSlice.actions;

export default DatasetMetaSlice.reducer;

export const fetchDatasetMeta =
  (
    token: string,
    datasetMetaId: string,
    portalId?: string,
    projectId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatasetMetaStart());
      const fetched = await DatasetMetaService.getDatasetMeta(
        token,
        datasetMetaId,
        portalId,
        projectId,
      );
      dispatch(fetchDatasetMetaSuccess(fetched));
    } catch (err) {
      dispatch(fetchDatasetMetaFailure((err as string).toString()));
    }
  };

export const createDatasetMeta =
  (
    token: string,
    datasetMeta: Interfaces.DatasetMeta,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createDatasetMetaStart());
      const created = await DatasetMetaService.postDatasetMeta(
        token,
        datasetMeta,
        userId,
      );
      dispatch(createDatasetMetaSuccess(created));
    } catch (err) {
      dispatch(createDatasetMetaFailure((err as string).toString()));
    }
  };

export const updateDatasetMeta =
  (
    token: string,
    datasetMeta: Interfaces.DatasetMetaOutput,
    userId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateDatasetMetaStart());
      const updated = await DatasetMetaService.putDatasetMeta(
        token,
        datasetMeta,
        userId,
      );
      dispatch(updateDatasetMetaSuccess(updated));
    } catch (err) {
      dispatch(updateDatasetMetaFailure((err as string).toString()));
    }
  };

export const deleteDatasetMeta =
  (token: string, datasetMetaId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteDatasetMetaStart());
      const deleted = await DatasetMetaService.deleteDatasetMeta(
        token,
        datasetMetaId,
        userId,
      );
      dispatch(deleteDatasetMetaSuccess(deleted));
    } catch (err) {
      dispatch(deleteDatasetMetaFailure((err as string).toString()));
      throw err;
    }
  };

export const createDatasetMetaCollection =
  (
    token: string,
    datasetMeta: Interfaces.DatasetMetaOutput,
    userId: string,
    collection?: Partial<Interfaces.CollectionOutput>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createDatasetMetaStart());
      const created = await DatasetMetaService.postDatasetMetaCollection(
        token,
        datasetMeta,
        userId,
        collection,
      );
      dispatch(createDatasetMetaSuccess(created));
    } catch (err) {
      dispatch(createDatasetMetaFailure((err as string).toString()));
    }
  };

export const createDataset =
  (
    token: string,
    fileId: string,
    dataMetaId: string,
    userId: string,
    organisationId: string,
    pageNum: number,
    entryType?: string,
    hasNoHeadings?: boolean,
    delimiter?: string,
    collectionId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateDatasetMetaStart());
      const result = await DatasetService.createDataset(
        token,
        dataMetaId,
        userId,
        organisationId,
        pageNum,
        fileId,
        entryType,
        hasNoHeadings,
        delimiter,
        collectionId,
      );
      dispatch(
        updateDatasetMetaSuccess({
          accessLevel: Enums.AccessLevel.MANAGE,
          entity: result.datasetMeta,
        }),
      );
    } catch (err) {
      dispatch(updateDatasetMetaFailure((err as string).toString()));
    }
  };
