import { Interfaces } from '@configur-tech/upit-core-types';
import {
  Snapshot,
  SnapshotOutput,
} from '@configur-tech/upit-core-types/lib/interfaces';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_SNAPSHOTS'] || '';

export interface SnapshotItemOutput {
  accessLevel: number;
  entity: Interfaces.SnapshotOutput;
}

// TODO - Should be accessed from upit-core-types when available
export interface SnapshotItemsOutput {
  data: SnapshotItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export interface DatasetRollbackPayload {
  datasetMeta: Interfaces.DatasetMetaOutput;
}

export default class SnapshotService {
  public static async getDatasetSnapshot(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<SnapshotItemsOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<SnapshotItemsOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async postDatasetSnapshot(
    token: string,
    datasetMetaId: string,
    snapshotData: Snapshot,
  ): Promise<SnapshotItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<SnapshotItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: snapshotData, datasetMetaId });

    return result?.data as SnapshotItemOutput;
  }

  public static async putDatasetSnapshot(
    token: string,
    datasetSnapshot: SnapshotOutput,
    userId: string,
  ): Promise<SnapshotItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<SnapshotItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${datasetSnapshot._id}`, {
      data: {
        name: datasetSnapshot.name,
        description: datasetSnapshot.description,
      },
      userId,
    });

    return result?.data as SnapshotItemOutput;
  }

  public static async deleteDatasetSnapshot(
    token: string,
    snapshotId: string,
    userId: string,
  ): Promise<SnapshotItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<SnapshotItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${snapshotId}`, userId);

    return result?.data as SnapshotItemOutput;
  }

  public static async postDatasetSnapshotRollback(
    token: string,
    snapshotId: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<DatasetRollbackPayload>
    | Interfaces.ErrorResponseBody
  > {
    const endpoint = process.env['REACT_APP_DATASET_SNAPSHOT_ROLLBACK'] || '';
    const ah = new HttpHelper(token);

    return await ah.post<
      | Interfaces.SuccessResponseBody<DatasetRollbackPayload>
      | Interfaces.ErrorResponseBody
    >(endpoint, { snapshotId: snapshotId });
  }
}
