import { Enums } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialDatasetStage, {
  AdditionalDatasetStages,
  DatasetCreateSubStage,
  DatasetEventsSubStage,
  DatasetStructureSubStage,
  DatasetSubStages,
  DatasetValidateSubStage,
} from './initial-state';

const DatasetStageSlice = createSlice({
  name: 'datasetStage',
  initialState: InitialDatasetStage,
  reducers: {
    updateActiveDatasetStage(
      state,
      action: PayloadAction<Enums.DatasetStage | AdditionalDatasetStages>,
    ) {
      state.activeStage = action.payload;
    },
    updateActiveDatasetSubStage(
      state,
      action: PayloadAction<DatasetSubStages>,
    ) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },
    resetStagesAndSubStages(state) {
      state.activeStage = Enums.DatasetStage.CREATION;
      state.stages[Enums.DatasetStage.CREATION].activeSubStage =
        DatasetCreateSubStage.NAME;
      state.stages[Enums.DatasetStage.STRUCTURE].activeSubStage =
        DatasetStructureSubStage.DISCOVERY;
      state.stages[Enums.DatasetStage.VALIDATION].activeSubStage =
        DatasetValidateSubStage.RESULT;
      state.stages[AdditionalDatasetStages.EVENTS].activeSubStage =
        DatasetEventsSubStage.VIEW;
    },
  },
});

export const {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
  resetStagesAndSubStages,
} = DatasetStageSlice.actions;

export default DatasetStageSlice.reducer;
