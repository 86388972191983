import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DatasetStage } from '@configur-tech/upit-core-types/lib/enums';
import {
  faArrowLeft,
  faCalendar,
  faChevronDown,
  faChevronUp,
  faClipboardCheck,
  faCog,
  faEye,
  faPlusSquare,
  faShieldAlt,
  faSync,
  faTimeline,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import {
  resetStagesAndSubStages,
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../store/dataset-stage';
import {
  AdditionalDatasetStages,
  DatasetConfigureSubStage,
  DatasetCreateSubStage,
  DatasetValidateSubStage,
} from '../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../store/loading';
import { RootState } from '../../store/rootReducer';
import { getStageTitle } from '../../util/stage-content/StageHeaderContent';
import NavErrorCount from '../Navigation/NavErrorCount';
import NavItem from '../Navigation/NavItem';
import NavStatus from '../Navigation/NavStatus';
import NavTag, { NavTagType } from '../Navigation/NavTag';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationItems,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [Enums.DatasetStage.CREATION]: faPlusSquare,
  [Enums.DatasetStage.STRUCTURE]: faWrench,
  [Enums.DatasetStage.VALIDATION]: faClipboardCheck,
  [Enums.DatasetStage.CONFIGURATION]: faCog,
};

const DatasetItemNavigation: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const {
    datasetMeta,
    datasetMetaAccessLevel,
    activeDataCollectionItem,
    removeDatasetMeta,
  } = useDatasetMeta();
  const { datasetMetaId } = useParams();
  const { collection } = useDatasetCollection();
  const { hasAccessToHouston } = useOrganisation();

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const isAdvanced = location.pathname.includes('advanced');
  const isEditing = location.pathname.includes('edit');
  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');
  const isDuplicating = location.pathname.includes('duplicate');
  const isIntegration = location.pathname.includes('integration');

  const activeSubStage =
    datasetStageState.stages[Enums.DatasetStage.CONFIGURATION].activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [Enums.DatasetStage.CONFIGURATION]: true });

  const revertIntegrationDataset = async () => {
    dispatch(showLoading({ text: 'Deleting Dataset...' }));
    await removeDatasetMeta(datasetMetaId);
    history.push(RouteName.INTEGRATION_CENTRE);
  };

  useEffect(() => {
    if (isAdvanced && activeSubStage !== DatasetConfigureSubStage.ADVANCED) {
      dispatch(updateActiveDatasetStage(Enums.DatasetStage.CONFIGURATION));
      dispatch(updateActiveDatasetSubStage(DatasetConfigureSubStage.ADVANCED));
    }
  }, [activeSubStage, dispatch, isAdvanced]);

  if (datasetMetaAccessLevel !== Enums.AccessLevel.MANAGE) {
    return (
      <NavigationItems>
        <NavItem
          key={`dataset-stage-overview`}
          onClick={() =>
            dispatch(updateActiveDatasetStage(Enums.DatasetStage.CREATION))
          }
          isActive={
            datasetStageState.activeStage === Enums.DatasetStage.CREATION
          }
        >
          <FontAwesomeIcon
            icon={NavIcons[DatasetStage.CREATION]}
            color={defaultTheme.colors.system.white}
          />
          <NavTitle text={'Overview'} />
        </NavItem>
      </NavigationItems>
    );
  }

  const coll = collection?.collectionId
    ? datasetMeta?.dataCollections?.find(
        (c) => c._id === collection?.collectionId,
      )
    : datasetMeta?.dataCollections?.[0];

  const stages: Interfaces.CollectionOutput['stages'] =
    coll?.stages ||
    (Object.values(Enums.DatasetStage)
      .filter((v) => v !== Enums.DatasetStage.CONFIGURATION)
      .reduce((stages, stage) => {
        return {
          ...stages,
          [stage]: {
            status: Enums.StageStatus.NOT_STARTED,
          },
        };
      }, {}) as Interfaces.CollectionOutput['stages']);

  if (
    (isEditing || isReplacing || isMerging) &&
    datasetMeta &&
    activeDataCollectionItem
  ) {
    return (
      <NavigationItems>
        <BackNavigationItem
          className={'back'}
          key={`form-stage-back`}
          onClick={() => {
            (async () => {
              dispatch(
                showLoading({
                  text: `Cancelling ${
                    isEditing ? 'Edit' : isReplacing ? 'Replace' : 'Merge'
                  }...`,
                }),
              );

              dispatch(hideLoading());
              history.push(`${RouteName.DATASET_ITEM}/${datasetMeta?._id}`);
            })();
          }}
          isActive={false}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={defaultTheme.colors.system.white}
          />

          <NavTitle
            text={
              isEditing
                ? 'Cancel Edit Dataset'
                : isReplacing
                ? 'Cancel Replace Dataset'
                : 'Cancel Merge Dataset'
            }
          />
        </BackNavigationItem>

        {!isMerging &&
          !isReplacing &&
          Object.entries(stages)
            .filter(
              ([k]) =>
                ![
                  isEditing ? Enums.DatasetStage.CREATION : '',
                  Enums.DatasetStage.ENHANCEMENT,
                  isMerging || isReplacing ? Enums.DatasetStage.STRUCTURE : '',
                ].includes(k),
            )
            .map(([k, stage]) => {
              const stageName = k as Enums.DatasetStage;

              let disabled = false;
              if (stageName !== Enums.DatasetStage.CREATION) {
                if (
                  coll?.stages[Enums.DatasetStage.CREATION]?.status !==
                  Enums.StageStatus.COMPLETED
                ) {
                  disabled = true;
                }

                if (
                  stageName === Enums.DatasetStage.VALIDATION &&
                  coll?.stages[
                    isMerging
                      ? Enums.DatasetStage.CREATION
                      : Enums.DatasetStage.STRUCTURE
                  ]?.status !== Enums.StageStatus.COMPLETED
                ) {
                  disabled = true;
                }
              }

              return (
                <NavItem
                  key={`dataset-stage-${stageName}`}
                  onClick={() => {
                    dispatch(resetStagesAndSubStages());
                    dispatch(updateActiveDatasetStage(stageName));

                    if (
                      stageName === Enums.DatasetStage.CREATION &&
                      stage.result?.total
                    ) {
                      dispatch(
                        updateActiveDatasetSubStage(DatasetCreateSubStage.TYPE),
                      );
                    }

                    if (
                      stageName === Enums.DatasetStage.VALIDATION &&
                      stage.result?.total
                    ) {
                      dispatch(
                        updateActiveDatasetSubStage(
                          DatasetValidateSubStage.RESULT,
                        ),
                      );
                    }
                  }}
                  isActive={datasetStageState.activeStage === stageName}
                  disabled={disabled}
                >
                  <FontAwesomeIcon
                    icon={NavIcons[stageName]}
                    color={defaultTheme.colors.system.white}
                  />

                  <NavTitle
                    text={getStageTitle(stageName, isReplacing, isMerging)}
                  />

                  {stage.result?.failed ? (
                    <NavErrorCount errorCount={stage.result?.failed} />
                  ) : (
                    <NavStatus
                      status={stage.status || Enums.StageStatus.NOT_STARTED}
                    />
                  )}
                </NavItem>
              );
            })}
      </NavigationItems>
    );
  }

  return (
    <NavigationItems>
      <BackNavigationItem
        className={'back'}
        key={`form-stage-back`}
        onClick={() =>
          history.push(
            isIntegration ? revertIntegrationDataset() : RouteName.DATASETS,
          )
        }
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle
          text={isIntegration ? 'Back to Integrations' : 'Back To Datasets'}
        />
      </BackNavigationItem>

      {Object.entries(stages)
        .filter(
          ([s]) =>
            !(isDuplicating && s === Enums.DatasetStage.CREATION) &&
            !(isDuplicating && s === Enums.DatasetStage.VALIDATION) &&
            !(isIntegration && s === Enums.DatasetStage.CREATION) &&
            !(isIntegration && s === Enums.DatasetStage.VALIDATION) &&
            s !== Enums.DatasetStage.ENHANCEMENT,
        )
        .map(([k, stage]) => {
          const stageName = k as Enums.DatasetStage;

          let disabled = false;
          if (stageName !== Enums.DatasetStage.CREATION) {
            if (
              coll?.stages[Enums.DatasetStage.CREATION]?.status !==
              Enums.StageStatus.COMPLETED
            ) {
              disabled = true;
            }

            if (
              stageName === Enums.DatasetStage.VALIDATION &&
              coll?.stages[Enums.DatasetStage.STRUCTURE]?.status !==
                Enums.StageStatus.COMPLETED
            ) {
              disabled = true;
            }
          }

          if (
            stageName === Enums.DatasetStage.CREATION &&
            activeDataCollectionItem?._id
          ) {
            // Published dataset
            return (
              <NavItem
                key={`dataset-stage-overview`}
                onClick={() => dispatch(updateActiveDatasetStage(stageName))}
                isActive={datasetStageState.activeStage === stageName}
                disabled={disabled}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  color={defaultTheme.colors.system.white}
                />
                <NavTitle text={'Overview'} />
              </NavItem>
            );
          }

          if (
            stageName === Enums.DatasetStage.VALIDATION &&
            activeDataCollectionItem?._id
          ) {
            return;
          }

          return (
            <NavItem
              key={`dataset-stage-${stageName}`}
              onClick={() => {
                dispatch(resetStagesAndSubStages());
                dispatch(updateActiveDatasetStage(stageName));

                if (stage.result?.total) {
                  dispatch(
                    updateActiveDatasetSubStage(DatasetValidateSubStage.RESULT),
                  );
                }
              }}
              isActive={datasetStageState.activeStage === stageName}
              disabled={disabled}
            >
              <FontAwesomeIcon
                icon={NavIcons[stageName]}
                color={defaultTheme.colors.system.white}
              />

              <NavTitle text={getStageTitle(stageName)} />

              {stage.result?.failed ? (
                <NavErrorCount errorCount={stage.result?.failed} />
              ) : (
                <NavStatus
                  status={stage.status || Enums.StageStatus.NOT_STARTED}
                />
              )}
            </NavItem>
          );
        })}

      {activeDataCollectionItem?._id && (
        <NavItem
          key={`dataset-stage-${AdditionalDatasetStages.EVENTS}`}
          onClick={() =>
            dispatch(updateActiveDatasetStage(AdditionalDatasetStages.EVENTS))
          }
          isActive={
            datasetStageState.activeStage === AdditionalDatasetStages.EVENTS
          }
        >
          <FontAwesomeIcon
            icon={faCalendar}
            color={defaultTheme.colors.system.white}
          />
          <NavTitle text="Events" />
        </NavItem>
      )}
      {activeDataCollectionItem?._id && hasAccessToHouston && (
        <NavItem
          key={`pipeline-stage-${AdditionalDatasetStages.PIPELINES}`}
          onClick={() =>
            dispatch(
              updateActiveDatasetStage(AdditionalDatasetStages.PIPELINES),
            )
          }
          disabled={!hasAccessToHouston}
          isActive={
            datasetStageState.activeStage === AdditionalDatasetStages.PIPELINES
          }
        >
          <FontAwesomeIcon
            icon={faTimeline}
            color={defaultTheme.colors.system.white}
          />
          <NavTitle text="Pipelines" />

          <NavTag type={NavTagType.BETA} />
        </NavItem>
      )}

      {/* Configuration accordion nav */}
      {datasetMeta?._id && (
        <NavigationAccordion>
          <div key={`${Enums.DatasetStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={!accordionGroups[Enums.DatasetStage.CONFIGURATION]}
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [Enums.DatasetStage.CONFIGURATION]:
                    !accordionGroups[Enums.DatasetStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle
                  text={getStageTitle(Enums.DatasetStage.CONFIGURATION)}
                />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[Enums.DatasetStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={!accordionGroups[Enums.DatasetStage.CONFIGURATION]}
            >
              <div>
                {datasetMeta?.activeDataCollection && (
                  <>
                    <NavigationNavItem
                      onClick={() => {
                        dispatch(
                          updateActiveDatasetStage(DatasetStage.CONFIGURATION),
                        );
                        dispatch(
                          updateActiveDatasetSubStage(
                            DatasetConfigureSubStage.ACCESS,
                          ),
                        );
                      }}
                      isActive={
                        datasetStageState.activeStage ==
                          DatasetStage.CONFIGURATION &&
                        activeSubStage == DatasetConfigureSubStage.ACCESS
                      }
                    >
                      <FontAwesomeIcon
                        icon={faShieldAlt}
                        color={themeContext.colors.system.white}
                        style={{ marginRight: themeContext.margin.standard }}
                      />
                      <NavTitle
                        text={DatasetConfigureSubStage.ACCESS}
                        isActive={
                          datasetStageState.activeStage ==
                            DatasetStage.CONFIGURATION &&
                          activeSubStage == DatasetConfigureSubStage.ACCESS
                        }
                      />
                    </NavigationNavItem>

                    <NavigationNavItem
                      onClick={() => {
                        dispatch(
                          updateActiveDatasetStage(DatasetStage.CONFIGURATION),
                        );
                        dispatch(
                          updateActiveDatasetSubStage(
                            DatasetConfigureSubStage.SNAPSHOTS,
                          ),
                        );
                      }}
                      isActive={
                        datasetStageState.activeStage ==
                          DatasetStage.CONFIGURATION &&
                        activeSubStage == DatasetConfigureSubStage.SNAPSHOTS
                      }
                    >
                      <FontAwesomeIcon
                        icon={faSync}
                        color={themeContext.colors.system.white}
                        style={{ marginRight: themeContext.margin.standard }}
                      />

                      <NavTitle
                        text={DatasetConfigureSubStage.SNAPSHOTS}
                        isActive={
                          datasetStageState.activeStage ==
                            DatasetStage.CONFIGURATION &&
                          activeSubStage == DatasetConfigureSubStage.SNAPSHOTS
                        }
                      />
                    </NavigationNavItem>
                  </>
                )}

                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActiveDatasetStage(DatasetStage.CONFIGURATION),
                    );
                    dispatch(
                      updateActiveDatasetSubStage(
                        DatasetConfigureSubStage.ADVANCED,
                      ),
                    );
                  }}
                  isActive={
                    datasetStageState.activeStage ==
                      DatasetStage.CONFIGURATION &&
                    activeSubStage == DatasetConfigureSubStage.ADVANCED
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={DatasetConfigureSubStage.ADVANCED}
                    isActive={
                      datasetStageState.activeStage ==
                        DatasetStage.CONFIGURATION &&
                      activeSubStage == DatasetConfigureSubStage.ADVANCED
                    }
                  />
                  {datasetMeta?.activeDataCollection && (
                    <NavTag type={NavTagType.NEW} />
                  )}
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </NavigationItems>
  );
};

export default DatasetItemNavigation;
