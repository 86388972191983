import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
} from '../../../../main/theme';

export const LastSavedWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.small};

  > p {
    margin: 0;
  }
`;

export const GridWrapper = styled.div<{ showFilters?: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr) ${({ showFilters }) =>
      showFilters ? '260px' : 0};

  width: 100%;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 150px);
  overflow-y: auto;
  align-items: start;
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const FilterAccordion = styled(StyledAccordion)`
  width: 100%;
  min-width: 100%;
  border: none;
`;

export const FilterAccordionContent = styled(StyledAccordionContent)`
  padding: 0 !important;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const DisplayOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const TotalWrapper = styled.div`
  > div {
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const MeasureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    margin-top: ${({ theme }) => theme.margin.standard};
  }
`;

export const AggregateSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;

  padding: ${({ theme }) => `0 ${theme.margin.standard}`} !important;

  > div {
    &:last-of-type {
      padding-bottom: ${({ theme }) => theme.padding.large};
    }
  }
`;

export const PageInner = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `${theme.padding.large} ${theme.padding.xlarge}`};
`;

export const PageHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
min-height: 58px;

padding: ${({ theme }) => `${theme.padding.standard} ${theme.padding.xlarge}`};

background-color ${({ theme }) => theme.colors.system.offWhite};
border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

> p {
 margin: 0
}

> div {
display: flex;
  > div {
    margin-left: ${({ theme }) => theme.margin.standard};
  }
}
`;

export const DatasetActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > div:first-of-type {
    display: flex;
    align-items: center;

    > p {
      margin: 0 ${({ theme }) => theme.margin.standard} 0 0;
    }
  }
`;

export const QueryWarning = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  margin: ${({ theme }) => theme.margin.standard};
  padding: ${({ theme }) => theme.margin.large};
`;

export const QueryWarningImage = styled.div<{ image: string }>`
  width: 60px;
  height: 60px;

  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${({ theme }) => theme.colors.system.white}
    url(${({ image }) => image}) no-repeat center / 75%;

  margin-bottom: ${({ theme }) => theme.margin.standard};
`;
