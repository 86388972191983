import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faBolt, faComment } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useColumnOrder,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import {
  COMMENT_COUNT_HEADER,
  MAX_COL_WIDTH,
  MIN_COL_WIDTH,
  RELATIVE,
  ROW_ID_COL_WIDTH,
  ROW_NUMBER_HEADER,
  SELECTION_COL_WIDTH,
  SELECTION_HEADER,
  STICKY,
  STICKY_LEFT,
} from '../../const/DataSample';
import useDataSample from '../../hooks/data-sample/UseDataSample';
import { DEFAULT_DATA_PAGE_SIZE } from '../../hooks/pagination/UsePagination';
import usePinned, { PinnedColumn } from '../../hooks/pinned/UsePinned';
import useSiteWideBanner from '../../hooks/sitewide-banner/useSitewideBanner';
import useSort from '../../hooks/sort/UseSort';
import { SampleDataRow } from '../../interfaces/SampleData';
import { DefaultPopupStyles, StyledText } from '../../main/theme';
import { reorder } from '../../util/data-sample/DataSampleUtils';
import IndeterminateCheckbox from '../DataSampleCheckbox/IndeterminateCheckbox';
import DragPreview from '../TableCell/DraggableTableCell/DragPreview';
import DraggableTableCell from '../TableCell/DraggableTableCell/DraggableTableCell';
import DataSampleContextMenu from './DataSampleContextMenu';
import DataSamplePagination from './DataSamplePagination';
import DataSampleRowActionBar from './DataSampleRowActionBar';
import * as SC from './styled';
export const SUPER_COLUMN_TYPES = [
  Enums.ValueDataType.DATE_CONVERSION,
  Enums.ValueDataType.FIELD_LOOKUP,
  Enums.ValueDataType.FORMULA,
  Enums.ValueDataType.TEXT_TRANSFORMATION,
];

export interface Cell {
  value: string;
  row: { index: number; values: Record<string, unknown> };
  column: { id: number };
}

interface DataSampleProps {
  additionalProps?: Record<string, unknown>;
  allowColumnLock?: boolean;
  entityId?: string;
  fixedHeight?: number;
  fixedHeightReduction?: number;
  fullWidth?: boolean;
  hideDeleteCol?: boolean;
  hideSortOnCols?: string[];
  iconMap?: Record<string, string>;
  isAddingSuperColumns?: boolean;
  isDraggable?: boolean;
  isEditingDataTypes?: boolean;
  isEditingQueryColumn?: boolean;
  isValidatingData?: boolean;
  isLiveEditing?: boolean;
  loading?: boolean;
  locked?: boolean;
  pageSize?: number;
  resetPagination?: boolean;
  resetSort?: boolean;
  sampleColumns: Interfaces.Field[];
  sampleRows: SampleDataRow[];
  schema?: Interfaces.SchemaField[] | Interfaces.Field[];
  showHeadersWhenNoData?: boolean;
  showPageSize?: boolean;
  showPagination?: boolean;
  showCommentCount?: boolean;
  totalRows?: number;
  selectedRows?: string[];
  showRowSelectionActionBar?: boolean;
  style?: React.CSSProperties;

  actions?: (entityId: string) => JSX.Element;
  clickableRows?: {
    valueField: string;
    action: (value: string) => void;
  };
  clickableColumn?: {
    columnName: string;
    valueField: string;
    action: (value: string, tableId: string) => void;
  };
  editAction?: (rowIndex: number, columnKey: string, value: string) => void;
  paginationAction?: (page: number) => void;
  reorderAction?: (newOrder: string[]) => void;
  sortAction?: (sortItem: { id: string; desc: boolean }) => void;
  toggleAction?: (rowIds: string[]) => void;
  contextMenuAction?: (
    e: React.MouseEvent<HTMLTableCellElement>,
    selectedRowId: number,
    tableRowId: number,
  ) => void;
  refreshDatasetEntries?: () => void;
}

const DataSample: FC<DataSampleProps> = ({
  loading,
  sampleColumns,
  sampleRows,
  showHeadersWhenNoData,
  isEditingDataTypes = false,
  isDraggable = false,
  reorderAction,
  isValidatingData = false,
  isLiveEditing = false,
  isAddingSuperColumns = false,
  isEditingQueryColumn = false,
  hideDeleteCol = false,
  editAction,
  clickableRows,
  clickableColumn,
  locked = false,
  fullWidth = false,
  showPagination = false,
  resetPagination,
  paginationAction,
  sortAction,
  resetSort,
  toggleAction,
  totalRows = 0,
  additionalProps,
  iconMap,
  schema,
  pageSize,
  hideSortOnCols,
  fixedHeight,
  fixedHeightReduction,
  allowColumnLock,
  entityId,
  actions,
  showPageSize,
  showCommentCount,
  contextMenuAction,
  selectedRows,
  showRowSelectionActionBar,
  style,
  refreshDatasetEntries,
}) => {
  const themeContext = useContext(ThemeContext);
  const { getSort } = useSort();
  const { getPinnedColumn } = usePinned();
  const { isSitewideBanner } = useSiteWideBanner();
  const { buildCells, buildColumns, buildRows } = useDataSample();

  const [pinnedColumn, setPinnedColumn] = useState<PinnedColumn | undefined>(
    entityId ? getPinnedColumn(entityId) : undefined,
  );

  const [activeDropdownColumn, setActiveDropdownColumn] = useState<string>();

  const columns = useMemo(
    () =>
      buildColumns(
        sampleColumns,
        sampleRows,
        isEditingDataTypes,
        isValidatingData,
        isAddingSuperColumns,
        isEditingQueryColumn,
        hideDeleteCol,
        locked,
        additionalProps,
        !!toggleAction,
        pinnedColumn,
        actions,
        allowColumnLock,
        showCommentCount,
      ),
    [
      buildColumns,
      sampleColumns,
      sampleRows,
      isEditingDataTypes,
      isValidatingData,
      isAddingSuperColumns,
      isEditingQueryColumn,
      hideDeleteCol,
      locked,
      additionalProps,
      toggleAction,
      pinnedColumn,
      actions,
      allowColumnLock,
      showCommentCount,
    ],
  );

  const data = useMemo(
    () => buildRows(sampleRows, isValidatingData, hideDeleteCol, actions),
    [buildRows, isValidatingData, hideDeleteCol, sampleRows, actions],
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: ROW_ID_COL_WIDTH,
      width: MIN_COL_WIDTH,
      maxWidth: MAX_COL_WIDTH,
    }),
    [],
  );

  const initialSortBy = entityId
    ? getSort(entityId)
      ? [getSort(entityId)]
      : []
    : [];

  const initialPinned = entityId
    ? getPinnedColumn(entityId)
      ? [getPinnedColumn(entityId)]
      : []
    : [];

  const initialColumnOrder = sampleRows.length
    ? [ROW_NUMBER_HEADER, ...sampleColumns.map((c) => c.name)]
    : sampleColumns.map((c) => c.name);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    nextPage,
    previousPage,
    setColumnOrder,
    setSortBy,
    state,
    toggleAllRowsSelected,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      entityId,
      columns,
      data,
      defaultColumn,
      editAction,
      initialState: {
        pageIndex: 0,
        pageSize: pageSize || DEFAULT_DATA_PAGE_SIZE,
        columnOrder: initialColumnOrder,
        sortBy: initialSortBy,
        sticky: initialPinned,
      },
      manualPagination: !!paginationAction,
      pageCount: Math.ceil(totalRows / (pageSize || DEFAULT_DATA_PAGE_SIZE)),
      autoResetPage: false,
      manualSortBy: !!sortAction,
      disableMultiSort: true,

      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            sticky: pinnedColumn,
          }),
          [state],
        );
      },
    },
    useFlexLayout,
    useSortBy,
    useSticky,
    usePagination,
    useColumnOrder,
    useRowSelect,
    useResizeColumns,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (toggleAction) {
          return [
            // Let's make a column for selection
            {
              id: SELECTION_HEADER,
              width: SELECTION_COL_WIDTH,
              sticky: STICKY_LEFT,
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div data-action={SELECTION_HEADER}>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        }
        return columns;
      });
    },
  );

  // If pageSize set it to state
  useEffect(() => {
    if (pageSize && pageSize !== state.pageSize) {
      state.page = pageSize;
    }
  }, [pageSize, state]);

  // Fetch new data
  useEffect(() => {
    if (paginationAction) {
      paginationAction(state.pageIndex + 1);
    }
  }, [state.pageIndex, paginationAction]);

  // Sort data
  useEffect(() => {
    if (sortAction) {
      sortAction(state.sortBy[0]);
    }
  }, [sortAction, state.sortBy]);

  // Reset sort
  useEffect(() => {
    if (resetSort && entityId) {
      setSortBy(entityId && getSort(entityId) ? [getSort(entityId)] : []);

      setPinnedColumn(
        entityId && getPinnedColumn(entityId)
          ? getPinnedColumn(entityId)
          : undefined,
      );
    }
  }, [entityId, getPinnedColumn, getSort, resetSort, setSortBy]);

  // Toggle
  useEffect(() => {
    if (toggleAction) {
      toggleAction(Object.keys(state.selectedRowIds));
    }
  }, [toggleAction, state.selectedRowIds]);

  // Reset pagination
  useEffect(() => {
    if (resetPagination && state.pageIndex) {
      gotoPage(0);
    }
  });

  // On column change, reorder
  useEffect(() => {
    if (sampleColumns) {
      if (sampleRows?.length) {
        setColumnOrder([
          ROW_NUMBER_HEADER,
          ...sampleColumns.map((c) => c.name),
        ]);
      } else {
        setColumnOrder(sampleColumns.map((c) => c.name));
      }
    }
  }, [sampleColumns, sampleRows?.length, setColumnOrder]);

  return (
    <SC.Outer fullWidth={fullWidth}>
      <SC.Wrapper
        noBorder={loading}
        fixedHeight={fixedHeight}
        fixedHeightReduction={fixedHeightReduction}
        showBanner={isSitewideBanner}
        style={style}
      >
        {loading && (
          <SC.NoResults>
            <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
          </SC.NoResults>
        )}

        {!loading && !showHeadersWhenNoData && page.length === 0 && (
          <SC.NoResults>
            <StyledText>No results found</StyledText>
          </SC.NoResults>
        )}

        {!loading && (page.length > 0 || showHeadersWhenNoData) && (
          <DndProvider backend={HTML5Backend}>
            <SC.Table {...getTableProps()}>
              <SC.TableHead>
                {headerGroups.map((headerGroup) => (
                  <SC.TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => {
                      const isPinned =
                        entityId && getPinnedColumn(entityId)
                          ? getPinnedColumn(entityId).id >= i
                          : false;

                      if (
                        isEditingDataTypes ||
                        isValidatingData ||
                        isAddingSuperColumns
                      ) {
                        if (
                          isDraggable &&
                          (column.id !== ROW_NUMBER_HEADER || !column.id)
                        ) {
                          return (
                            <DraggableTableCell
                              reorder={(item, newIndex) =>
                                reorder(
                                  item.index,
                                  newIndex,
                                  state.columnOrder,
                                  setColumnOrder,
                                  reorderAction,
                                )
                              }
                              key={column.id}
                              column={column}
                              index={i}
                            />
                          );
                        }

                        return (
                          <SC.TableHeader
                            {...column.getHeaderProps({
                              style: {
                                position:
                                  column.id === ROW_NUMBER_HEADER ||
                                  column.id === COMMENT_COUNT_HEADER
                                    ? STICKY
                                    : RELATIVE,
                              },
                            })}
                          >
                            {column.render('Header')}
                          </SC.TableHeader>
                        );
                      }

                      const hideSortOptions =
                        !!hideSortOnCols?.length &&
                        hideSortOnCols.includes(column.id);

                      const columnDataValidationType = (
                        schema as unknown as Interfaces.Field[]
                      )?.find((s) => s.name === column.id)?.dataValidation
                        ?.dataValidationType;

                      let lastColumnIndex = 1;
                      showCommentCount && lastColumnIndex++;
                      toggleAction && lastColumnIndex--;

                      const isLastColumn =
                        i === columns.length - lastColumnIndex;

                      return (
                        <SC.TableHeader
                          {...column.getHeaderProps({
                            style: {
                              position: STICKY,
                            },
                          })}
                          sortable={false}
                          sticky={isPinned ? STICKY_LEFT : undefined}
                          $$isActive={activeDropdownColumn === column.id}
                          $$hasDropdown={
                            (entityId || !hideSortOptions) &&
                            column.id !== ROW_NUMBER_HEADER &&
                            column.id !== COMMENT_COUNT_HEADER &&
                            column.id !== SELECTION_HEADER
                          }
                          $$hasAdditionalIcons={
                            columnDataValidationType &&
                            SUPER_COLUMN_TYPES.includes(
                              columnDataValidationType,
                            )
                          }
                        >
                          {column.id === SELECTION_HEADER && (
                            <IndeterminateCheckbox
                              {...getToggleAllRowsSelectedProps()}
                            />
                          )}

                          {column.id === COMMENT_COUNT_HEADER &&
                            showCommentCount && (
                              <SC.TableHeaderIcon>
                                <FontAwesomeIcon icon={faComment} size={'lg'} />
                              </SC.TableHeaderIcon>
                            )}

                          {columnDataValidationType &&
                            SUPER_COLUMN_TYPES.includes(
                              columnDataValidationType,
                            ) && (
                              <SC.TableHeaderIcon
                                iconColour={themeContext.colors.general.yellow}
                              >
                                <Popup
                                  content={startCase(
                                    columnDataValidationType
                                      .replace('_', ' ')
                                      .toLowerCase(),
                                  )}
                                  position="top center"
                                  style={DefaultPopupStyles}
                                  hideOnScroll={true}
                                  trigger={
                                    <FontAwesomeIcon
                                      icon={faBolt}
                                      size={'sm'}
                                    />
                                  }
                                />
                              </SC.TableHeaderIcon>
                            )}

                          {column.id !== SELECTION_HEADER &&
                            column.render('Header')}

                          {column.id !== SELECTION_HEADER &&
                            column.id !== COMMENT_COUNT_HEADER &&
                            (entityId || !hideSortOptions) && (
                              <DataSampleContextMenu
                                allowColumnLock={allowColumnLock}
                                column={column}
                                columnIndex={toggleAction ? i - 1 : i}
                                entityId={entityId}
                                hideSortOptions={hideSortOptions}
                                isLastColumn={isLastColumn}
                                setPinnedColumn={setPinnedColumn}
                                setActiveDropdownColumn={
                                  setActiveDropdownColumn
                                }
                              />
                            )}

                          <SC.Resizer
                            {...column.getResizerProps()}
                            isResizing={column.isResizing}
                            lastItem={i === headerGroup.headers.length - 1}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </SC.TableHeader>
                      );
                    })}
                  </SC.TableRow>
                ))}
              </SC.TableHead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <SC.TableRow
                      lastItem={i === page.length - 1}
                      {...row.getRowProps()}
                      isClickable={clickableRows}
                      isLiveEditing={isLiveEditing}
                    >
                      {buildCells(
                        sampleRows,
                        row.cells,
                        iconMap,
                        schema,
                        clickableRows,
                        clickableColumn,
                        showCommentCount,
                        contextMenuAction,
                        isLiveEditing,
                      )}
                    </SC.TableRow>
                  );
                })}
              </tbody>
            </SC.Table>
            <DragPreview />
          </DndProvider>
        )}
      </SC.Wrapper>

      {!loading &&
        !showRowSelectionActionBar &&
        page.length !== 0 &&
        (showPagination ||
          sampleRows.length >
            (pageSize ? pageSize : DEFAULT_DATA_PAGE_SIZE)) && (
          <DataSamplePagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={() => nextPage()}
            pageCount={pageCount}
            pageIndex={state.pageIndex}
            pageOptions={pageOptions}
            pageSize={state.pageSize}
            previousPage={() => previousPage()}
            setPageSize={setPageSize}
            totalRows={totalRows}
            showPageSize={showPageSize}
          />
        )}
      {!loading && showRowSelectionActionBar && (
        <DataSampleRowActionBar
          selectedRows={selectedRows || []}
          resetSelectedRows={() => toggleAllRowsSelected(false)}
          refreshDatasetEntries={() =>
            refreshDatasetEntries && refreshDatasetEntries()
          }
        />
      )}
    </SC.Outer>
  );
};

export default DataSample;
