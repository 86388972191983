import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import styled, { ThemeContext } from 'styled-components';
import XLSX from 'xlsx';
import { StyledDropdown, StyledH4, StyledText } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

interface FileUploaderProps {
  acceptedTypes: string[];
  action: (file: File, sheet?: string) => void;
  sourceType?: Enums.DataSourceType;
  file?: File;
  sheet?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 400px;

  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${({ theme }) => theme.colors.system.offWhite};
  outline: none;

  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    outline: none;

    :hover {
      cursor: pointer;
    }
  }
`;

const AcceptedTypesText = styled(StyledText)`
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.darkGrey};
`;

const UploadHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const FileUploader: FC<FileUploaderProps> = ({
  acceptedTypes,
  sourceType,
  action,
  file,
  sheet,
}) => {
  const themeContext = useContext(ThemeContext);

  const [currentFile, setCurrentFile] = useState<File>();

  const [xlsSheetOptions, setXlsSheetOptions] = useState<DropdownItemProps[]>(
    [],
  );
  const [selectedXlsSheet, setSelectedXlsSheet] = useState<string>();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
  });

  useEffect(() => {
    if (file) {
      setCurrentFile(file);
    }
  }, [file]);

  useEffect(() => {
    if (acceptedFiles.length) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const res = e.target?.result;
        const wb = XLSX.read(res, {
          type: rABS ? 'binary' : 'array',
          bookSheets: true,
        });

        if (!xlsSheetOptions.length || currentFile !== file) {
          setXlsSheetOptions(
            wb.SheetNames.map((s, i) => {
              return {
                key: `${s}-${i}`,
                value: s,
                text: s,
              };
            }),
          );
        }

        if (!selectedXlsSheet) {
          setSelectedXlsSheet(wb.SheetNames[0]);
        }
      };
      if (rABS) {
        reader.readAsBinaryString(acceptedFiles[0]);
      } else {
        reader.readAsArrayBuffer(acceptedFiles[0]);
      }
      if (selectedXlsSheet) {
        action(acceptedFiles[0], selectedXlsSheet);
      }
    }
  }, [
    acceptedFiles,
    action,
    currentFile,
    file,
    selectedXlsSheet,
    xlsSheetOptions.length,
  ]);

  return (
    <Wrapper>
      <UploadBox>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <UploadHeader>You can drag and drop your file here</UploadHeader>
          <FeatureButton
            action={() => null}
            size={FeatureButtonSize.WIDE_SMALL}
            text={'Select a file'}
            containerStyle={{ marginBottom: themeContext.margin.large }}
            color={themeContext.colors.general.blue}
          />
          <AcceptedTypesText>
            Accepted file type(s):
            {acceptedTypes.map((type) => ` ${type} `)}, (Max 20MB)
          </AcceptedTypesText>

          {file && (
            <>
              <StyledH4>File To Upload</StyledH4>
              <StyledText>{file.name}</StyledText>
            </>
          )}

          {sourceType === Enums.DataSourceType.XLS && (
            <>
              {sheet && xlsSheetOptions.length < 2 && (
                <>
                  <StyledH4>Sheet To Upload</StyledH4>
                  <StyledText>{sheet}</StyledText>
                </>
              )}

              {xlsSheetOptions.length > 1 && (
                <>
                  <StyledH4>Sheet To Upload</StyledH4>
                  <StyledDropdown
                    selectOnBlur={false}
                    placeholder={'Select a sheet'}
                    options={xlsSheetOptions}
                    selection
                    value={selectedXlsSheet || xlsSheetOptions[0].value}
                    onChange={(e, data) => setSelectedXlsSheet(data.value)}
                    style={{ marginTop: 0, marginBottom: 0 }}
                    upward={true}
                  />
                </>
              )}
            </>
          )}
        </div>
      </UploadBox>
    </Wrapper>
  );
};

export default FileUploader;
