import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { ProjectStage } from '../../enums/ProjectStage';

export enum ProjectCreationSubStage {
  NAME = 'name',
  AVATAR = 'avatar',
}

export enum ProjectAggregationSubStage {
  NAME = 'name',
  DATASETS = 'datasets',
  AGGREGATE = 'aggregate',
  CONFIGURATION = 'configuration',
}

export enum ProjectConnectionCMSSubStage {
  NAME = 'name',
  DATA = 'data',
  ACCESS = 'access',
  CONFIGURATION = 'configuration',
}

export enum ProjectConnectionFormSubStage {
  NAME = 'name',
  DATASET = 'dataset',
  FORM_BUILDER = 'form-builder',
  CONFIGURATION = 'configuration',
}

export enum ProjectConnectionPortalSubStage {
  NAME = 'name',
  CONNECTIONS = 'connections',
  ACCESS = 'access',
  CONFIGURATION = 'configuration',
}

export enum ProjectChartSubStage {
  NAME = 'name',
  CHART = 'dashboard',
  CONFIGURATION = 'configuration',
}

export enum ProjectGraphSubStage {
  NAME = 'name',
  DATA = 'data',
  GRAPH = 'graph',
}

export enum ConfigurationSubStage {
  ADVANCED = 'advanced',
}

export enum ProjectConfigureSubStage {
  ACCESS = 'access',
  ADVANCED = 'advanced',
}

interface ProjectSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type ProjectSubStages =
  | ProjectCreationSubStage
  | ProjectAggregationSubStage
  | ProjectConnectionCMSSubStage
  | ProjectConnectionFormSubStage
  | ProjectConnectionPortalSubStage
  | ProjectChartSubStage
  | ProjectGraphSubStage
  | ProjectConfigureSubStage;

export interface ProjectStages {
  activeStage: ProjectStage | Enums.ConnectionType;
  stages: {
    [ProjectStage.CREATION]: {
      activeSubStage: ProjectCreationSubStage;
      subStages: ProjectSubStage[];
    };
    [ProjectStage.CHARTS]: {
      activeSubStage: ProjectChartSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [ProjectStage.GRAPH]: {
      activeSubStage: ProjectGraphSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [ProjectStage.AGGREGATION]: {
      activeSubStage: ProjectAggregationSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [Enums.ConnectionType.CMS]: {
      activeSubStage: ProjectConnectionCMSSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [Enums.ConnectionType.FORM]: {
      activeSubStage: ProjectConnectionFormSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [Enums.ConnectionType.PORTAL]: {
      activeSubStage: ProjectConnectionPortalSubStage | undefined;
      subStages: ProjectSubStage[];
    };
    [ProjectStage.CONFIGURATION]: {
      activeSubStage: ProjectConfigureSubStage | undefined;
      subStages: ProjectSubStage[];
    };
  };
  activeConnection?: Interfaces.ConnectionOutput;
  connectionType?: Enums.ConnectionType;
}

const InitialProjectStage: ProjectStages = {
  activeStage: ProjectStage.CREATION,
  stages: {
    [Enums.ConnectionType.CMS]: {
      activeSubStage: undefined,
      subStages: [
        {
          subStage: ProjectConnectionCMSSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectConnectionCMSSubStage.DATA,
          stage: 2,
          title: 'Data',
        },
        {
          subStage: ProjectConnectionCMSSubStage.ACCESS,
          stage: 3,
          title: 'Access',
        },
        {
          subStage: ProjectConnectionCMSSubStage.CONFIGURATION,
          stage: 4,
          title: 'Configuration',
        },
      ],
    },
    [Enums.ConnectionType.FORM]: {
      activeSubStage: undefined,
      subStages: [
        {
          subStage: ProjectConnectionFormSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectConnectionFormSubStage.DATASET,
          stage: 2,
          title: 'Dataset',
        },
        {
          subStage: ProjectConnectionFormSubStage.FORM_BUILDER,
          stage: 3,
          title: 'Build',
        },
        {
          subStage: ProjectConnectionFormSubStage.CONFIGURATION,
          stage: 4,
          title: 'Configuration',
        },
      ],
    },
    [Enums.ConnectionType.PORTAL]: {
      activeSubStage: undefined,
      subStages: [
        {
          subStage: ProjectConnectionPortalSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectConnectionPortalSubStage.CONNECTIONS,
          stage: 2,
          title: 'Connections',
        },
        {
          subStage: ProjectConnectionPortalSubStage.ACCESS,
          stage: 3,
          title: 'Access',
        },
        {
          subStage: ProjectConnectionPortalSubStage.CONFIGURATION,
          stage: 4,
          title: 'Configuration',
        },
      ],
    },
    [ProjectStage.CHARTS]: {
      activeSubStage: ProjectChartSubStage.NAME,
      subStages: [
        {
          subStage: ProjectChartSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectChartSubStage.CHART,
          stage: 2,
          title: 'Dashboard',
        },
        {
          subStage: ProjectChartSubStage.CONFIGURATION,
          stage: 3,
          title: 'Configuration',
        },
      ],
    },
    [ProjectStage.GRAPH]: {
      activeSubStage: ProjectGraphSubStage.NAME,
      subStages: [
        {
          subStage: ProjectGraphSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectGraphSubStage.DATA,
          stage: 2,
          title: 'Data',
        },
        {
          subStage: ProjectGraphSubStage.GRAPH,
          stage: 3,
          title: 'Graph',
        },
      ],
    },
    [ProjectStage.CREATION]: {
      activeSubStage: ProjectCreationSubStage.NAME,
      subStages: [
        {
          subStage: ProjectCreationSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectCreationSubStage.AVATAR,
          stage: 2,
          title: 'Avatar',
        },
      ],
    },
    [ProjectStage.AGGREGATION]: {
      activeSubStage: undefined,
      subStages: [
        {
          subStage: ProjectAggregationSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: ProjectAggregationSubStage.DATASETS,
          stage: 2,
          title: 'Select',
        },
        {
          subStage: ProjectAggregationSubStage.AGGREGATE,
          stage: 3,
          title: 'Query',
        },
        {
          subStage: ProjectAggregationSubStage.CONFIGURATION,
          stage: 4,
          title: 'Configuration',
        },
      ],
    },
    [ProjectStage.CONFIGURATION]: {
      activeSubStage: ProjectConfigureSubStage.ACCESS,
      subStages: [
        {
          subStage: ProjectConfigureSubStage.ACCESS,
          stage: 1,
          title: 'Access',
        },
        {
          subStage: ProjectConfigureSubStage.ADVANCED,
          stage: 2,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialProjectStage;
