import { Enums } from '@configur-tech/upit-core-types';
import { AreaSize, PanelType } from '@configur-tech/upit-core-types/lib/enums';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Wrapper as GoogleMapWrapper } from '@googlemaps/react-wrapper';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums';
import useChart from '../../hooks/chart/UseChart';
import FeatureButton from '../FeatureButton/FeatureButton';
import GraphDisplay from '../GraphDisplay/GraphDisplay';
import AreaControls from './AreaControls';
import EmptyPanelContent from './EmptyPanelContent';
import * as SC from './styled';
import WysiwygEditor from './WysiwygEditor';

export interface ChartBuilderProps {
  readOnly: boolean;
}

export const AREA_CONTROL_BUTTON_SIZE = 20;

const ChartBuilder: FC<ChartBuilderProps> = ({ readOnly = true }) => {
  const { chart, updateChartContent, clearPanelMapContent, chartSaving } =
    useChart();
  const themeContext = useContext(ThemeContext);

  const handleDeleteArea = (areaIndex: number, panelIndex: number) => {
    const cloned = cloneDeep(chart);

    cloned.areas[areaIndex].panels[panelIndex] = {
      ...cloned.areas[areaIndex].panels[panelIndex],
      panelType: Enums.PanelType.EMPTY,
      content: undefined,
      graphId: undefined,
    };

    updateChartContent(cloned);
  };

  return (
    <SC.Wrapper>
      <GoogleMapWrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
      >
        {chart?.areas.map((area, areaIndex) => {
          return (
            <SC.AreaWrapper
              key={`wrapper-${area}-${areaIndex}`}
              readOnly={readOnly}
              hideBorder={
                area.areaSize === AreaSize.ONE_COLUMN &&
                area.panels[0].panelType === PanelType.TEXT
              }
            >
              {!readOnly && <AreaControls area={area} />}
              <SC.Area>
                {area.panels?.map((panel, panelIndex) => {
                  if (panel.panelType === Enums.PanelType.EMPTY && !readOnly) {
                    return (
                      <SC.Panel key={`panel-${panel._id}`}>
                        <EmptyPanelContent
                          areaSize={area.areaSize}
                          id={panel._id}
                          panelId={panel._id}
                          areaId={area._id}
                        />
                      </SC.Panel>
                    );
                  }
                  if (panel.panelType === Enums.PanelType.GRAPH) {
                    const graph = chart.graphs.find(
                      (graph) => graph._id === panel.graphId,
                    );

                    return (
                      <SC.Panel
                        key={`panel-${panel._id}`}
                        readOnly={readOnly}
                        graph={true}
                        table={
                          graph?.graphParams.graphType === Enums.GraphType.TABLE
                        }
                      >
                        {graph && (
                          <GraphDisplay
                            graph={graph}
                            readOnly={readOnly}
                            handleDeleteArea={() =>
                              handleDeleteArea(areaIndex, panelIndex)
                            }
                          />
                        )}
                      </SC.Panel>
                    );
                  }
                  if (panel.panelType === Enums.PanelType.TEXT) {
                    return (
                      <SC.Panel key={`panel-${panel._id}`} readOnly={readOnly}>
                        {!readOnly && (
                          <FeatureButton
                            action={() => {
                              handleDeleteArea(areaIndex, panelIndex);
                              clearPanelMapContent(`${area._id}-${panel._id}`);
                            }}
                            size={AREA_CONTROL_BUTTON_SIZE}
                            color={themeContext.colors.general.red}
                            icon={
                              <FontAwesomeIcon
                                icon={faTrash}
                                color={themeContext.colors.general.white}
                                size={'sm'}
                              />
                            }
                            style={{ width: '100%' }}
                            containerStyle={{ alignSelf: 'flex-end' }}
                            isDisabled={chartSaving}
                          />
                        )}
                        {!readOnly && (
                          <WysiwygEditor
                            page={EntityType.CHART}
                            readOnly={false}
                            areaId={area._id}
                            panelId={panel._id}
                            content={panel.content ? panel.content : undefined}
                          />
                        )}
                        {readOnly && (
                          <WysiwygEditor
                            page={EntityType.CHART}
                            readOnly={true}
                            areaId={area._id}
                            panelId={panel._id}
                            content={panel.content ? panel.content : undefined}
                          />
                        )}
                      </SC.Panel>
                    );
                  }
                })}
              </SC.Area>
            </SC.AreaWrapper>
          );
        })}
      </GoogleMapWrapper>
    </SC.Wrapper>
  );
};

export default ChartBuilder;
