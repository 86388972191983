import { Enums } from '@configur-tech/upit-core-types';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview';
import { ProjectStage } from '../../enums/ProjectStage';
import { RouteName } from '../../enums/RouteName';
import useProject from '../../hooks/project/UseProject';
import PageContainer from '../../main/PageContainer';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import ProjectItemAggregation from './ProjectAggregation';
import ProjectItemAPI from './ProjectAPI';
import ProjectItemCharts from './ProjectCharts';
import ProjectItemConfiguration from './ProjectConfiguration';
import ProjectItemCreation from './ProjectCreation';
import ProjectItemDatasets from './ProjectDatasets';
import ProjectItemDataViews from './ProjectDataviews';
import ProjectItemForm from './ProjectForm';
import ProjectItemGraph from './ProjectGraph';
import ProjectItemModelling from './ProjectModelling';
import ProjectItemPortals from './ProjectPortals';

const ProjectItemPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { project, getProject, projectError } = useProject();

  const [fetchingProjects, setFetchingProject] = useState<boolean>(false);

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = projectStageState.activeStage;

  // Get an existing project if requested
  useEffect(() => {
    if (!fetchingProjects && params?.id && params?.id !== project?._id) {
      (async () => {
        setFetchingProject(true);
        dispatch(showLoading({ text: 'Loading Project...' }));
        await getProject(params.id);
        dispatch(hideLoading());
      })();
    }
  }, [params?.id, project?._id, dispatch, getProject, fetchingProjects]);

  // Error fetching Project
  useEffect(() => {
    if (projectError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'Project Not Found',
            errorText:
              'This project could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Projects',
            errorActionRedirectRoute: RouteName.PROJECTS,
          },
        }),
      );
    }
  }, [projectError, dispatch]);

  const getStageComponent = () => {
    switch (activeStage) {
      case ProjectStage.CREATION:
        return params?.id && project?._id ? (
          <ProjectOverview />
        ) : (
          <ProjectItemCreation />
        );
      case ProjectStage.DATASETS:
        return <ProjectItemDatasets />;
      case ProjectStage.MODELLING:
        return <ProjectItemModelling />;
      case ProjectStage.AGGREGATION:
        return <ProjectItemAggregation />;
      case ProjectStage.CONFIGURATION:
        return <ProjectItemConfiguration />;
      case ProjectStage.CHARTS:
        return <ProjectItemCharts />;
      case ProjectStage.GRAPH:
        return <ProjectItemGraph />;
      case Enums.ConnectionType.CMS:
        return <ProjectItemDataViews />;
      case Enums.ConnectionType.API:
        return <ProjectItemAPI />;
      case Enums.ConnectionType.FORM:
        return <ProjectItemForm />;
      case Enums.ConnectionType.PORTAL:
        return <ProjectItemPortals />;

      default:
        return <ProjectItemCreation />;
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.PROJECT_ITEM} />
      <PageContainer>{getStageComponent()}</PageContainer>
    </>
  );
};

export default ProjectItemPage;
