import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SupercolumnField } from '@configur-tech/upit-core-types/lib/interfaces';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import AggregationDisplayOrder from '../../components/Aggregation/AggregationDisplayOrder';
import AggregationGroup from '../../components/Aggregation/AggregationGroup';
import AggregationMeasure from '../../components/Aggregation/AggregationMeasure';
import AggregationSort, {
  SortItem,
} from '../../components/Aggregation/AggregationSort';
import AggregationSupercolumn from '../../components/Aggregation/AggregationSupercolumn';
import AggregationFilter from '../../components/Aggregation/filter/AggregationFilter';
import { AggregateStage } from '../../components/ProjectItem/ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import * as SC from '../../components/ProjectItem/ProjectItemAggregationStage/3-aggregate/styled';
import { EntityType } from '../../enums';
import {
  StyledAccordionTitle,
  StyledBodySubHeader,
  defaultTheme,
} from '../../main/theme';
import useProject from '../project/UseProject';
import useQuery from './UseQuery';
import useQueryBuilder from './UseQueryBuilder';

interface useQueryBuilderAccordionResult {
  buildAccordionPanel: (
    stage: AggregateStage,
    index: number,
  ) => Record<string, unknown> | null;
}

const useQueryBuilderAccordion = (): useQueryBuilderAccordionResult => {
  const { query } = useQuery();
  const {
    queryOptions,
    handleGroupChange,
    handleSortChange,
    handleSubtotalChange,
    handleTotalChange,
    handleDisplayOrderChange,
  } = useQueryBuilder();
  const { projectAccessLevel } = useProject();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const [accordionStage, setAccordionStage] = useState<number[]>([
    0, 1, 2, 3, 4, 5,
  ]);

  const buildAccordionPanel = useCallback(
    (stage: AggregateStage, index: number): Record<string, unknown> | null => {
      if (
        !query?.queryParams?.datasetMetaId &&
        !query?.queryParams?.dataModelLinkIds?.length
      ) {
        return null;
      }

      let content;
      switch (stage) {
        case AggregateStage.FILTER:
          content = (
            <SC.AggregateSection>
              <AggregationFilter
                fields={
                  queryOptions.fieldItems as unknown as Interfaces.FieldOutput[]
                }
                queryId={query._id}
              />
            </SC.AggregateSection>
          );
          break;

        case AggregateStage.GROUP: {
          content = (
            <SC.AggregateSection>
              <div>
                <SC.GroupWrapper>
                  <AggregationGroup
                    groupItems={queryOptions.groupItems}
                    onChange={handleGroupChange}
                    disabled={!isProjectManager}
                  />
                </SC.GroupWrapper>
              </div>
            </SC.AggregateSection>
          );
          break;
        }

        case AggregateStage.MEASURE:
          content = (
            <SC.AggregateSection>
              <SC.MeasureWrapper>
                <AggregationMeasure
                  entityType={EntityType.AGGREGATION}
                  measureItems={query.queryParams?.measures}
                  isMultipleDatasetQuery={
                    query?.queryParams?.aggregationType ===
                    Enums.AggregationType.MULTIPLE
                  }
                />
              </SC.MeasureWrapper>
            </SC.AggregateSection>
          );
          break;

        case AggregateStage.SORT:
        case AggregateStage.DISPLAY_ORDER: {
          const activeItems =
            ((stage === AggregateStage.SORT
              ? query.queryParams.sort
              : query.queryParams.displayOrder
            )?.map((item) => ({ ...item, active: true })) as SortItem[]) || [];

          const allItems = (
            stage === AggregateStage.SORT
              ? queryOptions.sortItems
              : queryOptions.displayOrderItems
          )
            .concat(
              query?.queryParams.measures.map((measure) => {
                const measureItem = {
                  field: measure.field,
                  alias: measure.alias || `${measure.field}_${measure.measure}`,
                  datasetMetaId: measure.datasetMetaId,
                  direction: Enums.ProjectionSortOrder.ASCENDING,
                } as SortItem;

                if (stage === AggregateStage.SORT) {
                  measureItem.isMeasure = true;
                }

                return measureItem;
              }),
            )
            .concat(
              query.queryParams.supercolumns
                ? query?.queryParams.supercolumns.map((supercolumn) => {
                    const supercolumnItem = {
                      field: supercolumn.field,
                      alias: supercolumn.alias || `${supercolumn.field}`,
                      datasetMetaId: supercolumn.datasetMetaId,
                      direction: Enums.ProjectionSortOrder.ASCENDING,
                    } as SortItem;

                    return supercolumnItem;
                  })
                : [],
            );

          const uniqueItems = activeItems.concat(
            allItems.filter(
              (item) =>
                !activeItems.find(
                  (activeItem) => activeItem.alias === item.alias,
                ),
            ),
          );

          const cleanDisplay = uniqueItems.filter((item) => {
            // remove supercolumns
            if (query.queryParams.groups.length) {
              // Check if measure or active group
              return (
                !!query.queryParams.measures.find(
                  (m) => m.alias === item.alias,
                ) ||
                !!query.queryParams.groups.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                ) ||
                !!query?.queryParams?.supercolumns?.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                )
              );
            }
            return true;
          });

          const cleanSort = uniqueItems.filter((item) => {
            if (query.queryParams.groups.length) {
              // Check if measure or active group
              return (
                !!query.queryParams.measures.find(
                  (m) => m.alias === item.alias,
                ) ||
                !!query.queryParams.groups.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                )
              );
            }
            // return all fields besides supercolumns
            return !query?.queryParams?.supercolumns?.find(
              (so) =>
                so.datasetMetaId === item.datasetMetaId &&
                so.alias === item.alias,
            );
          });

          content =
            stage === AggregateStage.SORT ? (
              <SC.AggregateSection>
                <div>
                  <SC.SortWrapper>
                    <AggregationSort
                      sortItems={cleanSort}
                      onChange={handleSortChange}
                      disabled={
                        !isProjectManager ||
                        query.queryParams.includeSubtotals ||
                        query.queryParams.includeGrandTotals
                      }
                    />
                  </SC.SortWrapper>
                </div>
              </SC.AggregateSection>
            ) : (
              <SC.AggregateSection>
                <div>
                  <SC.DisplayOrderWrapper>
                    <AggregationDisplayOrder
                      displayOrderItems={cleanDisplay}
                      onChange={handleDisplayOrderChange}
                      disabled={!isProjectManager}
                    />
                  </SC.DisplayOrderWrapper>
                </div>
              </SC.AggregateSection>
            );
          break;
        }
        case AggregateStage.SUPERCOLUMN:
          content = (
            <SC.AggregateSection>
              <SC.MeasureWrapper>
                <AggregationSupercolumn
                  supercolumnItems={
                    (query.queryParams.supercolumns as SupercolumnField[]) || []
                  }
                  entityType={EntityType.AGGREGATION}
                  isMultipleDatasetQuery={
                    query?.queryParams?.aggregationType ===
                    Enums.AggregationType.MULTIPLE
                  }
                />
              </SC.MeasureWrapper>
            </SC.AggregateSection>
          );
          break;
        case AggregateStage.TOTALS: {
          content = (
            <SC.AggregateSection>
              <div>
                <SC.TotalWrapper>
                  <Checkbox
                    disabled={
                      !isProjectManager || !query.queryParams?.measures.length
                    }
                    label={'Generate Subtotals'}
                    checked={query.queryParams.includeSubtotals}
                    onChange={(e, data) => handleSubtotalChange(!!data.checked)}
                  />
                </SC.TotalWrapper>
                <SC.TotalWrapper>
                  <Checkbox
                    disabled={
                      !isProjectManager || !query.queryParams?.measures.length
                    }
                    label={'Generate Grand Totals'}
                    checked={query.queryParams.includeGrandTotals}
                    onChange={(e, data) => handleTotalChange(!!data.checked)}
                  />
                </SC.TotalWrapper>
              </div>
            </SC.AggregateSection>
          );
          break;
        }
      }

      return {
        key: `aggregate-panel-${stage}-${index}`,
        title: (
          <StyledAccordionTitle
            onClick={() => {
              let cloned = cloneDeep(accordionStage);
              if (accordionStage.includes(index)) {
                cloned = cloned.filter((e) => e !== index);
              } else {
                cloned.push(index);
              }
              setAccordionStage(cloned);
            }}
          >
            <StyledBodySubHeader>{stage}</StyledBodySubHeader>
            <FontAwesomeIcon
              icon={
                accordionStage.includes(index) ? faChevronUp : faChevronDown
              }
              color={defaultTheme.colors.system.offBlack}
            />
          </StyledAccordionTitle>
        ),
        content: (
          <SC.FilterAccordionContent>
            <div>{content}</div>
          </SC.FilterAccordionContent>
        ),
      };
    },
    [
      accordionStage,
      handleDisplayOrderChange,
      handleGroupChange,
      handleSortChange,
      handleSubtotalChange,
      handleTotalChange,
      isProjectManager,
      query?._id,
      query?.queryParams?.aggregationType,
      query?.queryParams?.dataModelLinkIds?.length,
      query?.queryParams?.datasetMetaId,
      query?.queryParams.displayOrder,
      query?.queryParams.groups,
      query?.queryParams.includeGrandTotals,
      query?.queryParams.includeSubtotals,
      query?.queryParams.measures,
      query?.queryParams.sort,
      query?.queryParams.supercolumns,
      queryOptions.displayOrderItems,
      queryOptions.fieldItems,
      queryOptions.groupItems,
      queryOptions.sortItems,
    ],
  );

  return {
    buildAccordionPanel,
  };
};

export default useQueryBuilderAccordion;
