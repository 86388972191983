import { FC } from 'react';
import styled from 'styled-components';

export enum NavTagType {
  NEW = 'new',
  BETA = 'beta',
  UPCOMING = 'upcoming',
}

interface NavTagProps {
  type?: NavTagType;
}

const Wrapper = styled.div<{ type?: NavTagType }>`
  background: ${({ theme, type }) => {
    switch (type) {
      case NavTagType.NEW:
        return theme.colors.general.green;
      case NavTagType.BETA:
        return theme.colors.general.sea;
      case NavTagType.UPCOMING:
        return theme.colors.system.navy;
      default:
        return theme.colors.general.sea;
    }
  }};

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 25px;
  min-width: 25px;
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colors.system.white};

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.white};
  text-transform: uppercase;

  padding: ${({ theme }) => `${theme.padding.small} ${theme.padding.standard}`};
`;

const NavTag: FC<NavTagProps> = ({ type }) => {
  return <Wrapper type={type}>{type}</Wrapper>;
};

export default NavTag;
