import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

interface FAButtonProps {
  // Action
  action: () => void;

  // FontAwesome Icon
  icon: IconDefinition;

  // Icon color
  color: string; // Hex code

  // Size
  size: SizeProp;

  // Content Override
  contentStyle?: React.CSSProperties;

  // Container Override
  style?: React.CSSProperties;
}

interface WrapperProps {
  style?: React.CSSProperties;
}

const Wrapper = styled.button<WrapperProps>`
  background: none;
  border: none;
  outline: none;

  width: 100%;
  padding: ${({ theme }) => theme.padding.standard};
  text-align: center;

  transition: opacity 0.95s;

  &:hover {
    cursor: pointer;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const FAButton: FC<FAButtonProps> = ({
  action,
  icon,
  color,
  size,
  contentStyle,
  style,
}) => {
  return (
    <Wrapper style={style} onClick={action}>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        style={contentStyle}
        size={size}
      />
    </Wrapper>
  );
};

export default FAButton;
