import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import { useDispatch } from 'react-redux';
import FileService, {
  CreateFileDocumentResponse,
} from '../../services/file/FileService';
import { showLoading } from '../../store/loading';
import createFileDocument from '../../util/create-file-document/CreateFileDocument';
import getUploadUrl from '../../util/get-file-upload-url/GetFileUploadUrl';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useFileUploadResult {
  uploadFile: (
    file?: File,
    sheetName?: string,
    filePurpose?: Enums.FilePurpose,
  ) => Promise<CreateFileDocumentResponse | undefined>;
}
const useFileUpload = (): useFileUploadResult => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  /**
   * Completes file upload to S3 and File document creation in the database
   *
   * @param {File} file - File to upload
   * @param {string} [sheetName] - Name of sheet in File to upload
   * @param {Enums.FilePurpose} filePurpose - Purpose of file being uploaded
   *
   * returns {CreateFileDocumentResponse | undefined} - File ID and lineCount of the created File document or undefined if failure
   */
  const uploadFile = async (
    file?: File,
    sheetName?: string,
    filePurpose: Enums.FilePurpose = Enums.FilePurpose.DATA,
  ): Promise<CreateFileDocumentResponse | undefined> => {
    if (file && loggedInUser) {
      dispatch(showLoading({ text: 'Uploading File...' }));

      const token = await getAccessTokenSilently();

      // Get upload URL
      const uploadUrl = await getUploadUrl(
        token,
        file.name,
        file.type,
        loggedInUser.organisationId,
        loggedInUser._id,
      );

      // Upload file to S3
      await FileService.uploadFileToS3(uploadUrl.uploadURL, file);

      dispatch(showLoading({ text: 'Processing File...' }));

      // Create File document
      return await createFileDocument(
        token,
        file.name,
        file.type,
        uploadUrl.uploadId,
        loggedInUser.organisationId,
        loggedInUser._id,
        sheetName,
        filePurpose,
      );
    }
  };

  return { uploadFile };
};

export default useFileUpload;
