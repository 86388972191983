import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingProps } from '../../components/Loading/Loading';
import InitialLoading from './initial-state';

const LoadingSlice = createSlice({
  name: 'loading',
  initialState: InitialLoading,
  reducers: {
    showLoading(state, action: PayloadAction<LoadingProps>) {
      state.loading = true;
      state.image = action?.payload.image;
      state.text = action?.payload.text;
    },
    hideLoading(state) {
      state.loading = false;
      state.image = undefined;
      state.text = undefined;
    },
  },
});

export const { showLoading, hideLoading } = LoadingSlice.actions;

export default LoadingSlice.reducer;
