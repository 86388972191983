import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StyledText } from '../../main/theme';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const NotAuthorisedPage: FC = () => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.NOT_AUTHORISED} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={''}
          image={AvatarIconMap.alarm}
          title={'Halt! Who Goes There?'}
        />

        <Page>
          <PageInner>
            <StyledText>
              You don't have the correct access permissions to view this page.
            </StyledText>

            <StyledText>
              If you think you should, get in contact with your Organisation
              manager.
            </StyledText>

            <FeatureButton
              action={() => {
                history.goBack();
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Head Back'}
              containerStyle={{ marginTop: themeContext.margin.large }}
            />
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default NotAuthorisedPage;
