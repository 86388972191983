import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useTeam from '../../../../hooks/team/UseTeam';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { fetchTeamSuccess } from '../../../../store/team';
import { updateActiveTeamSubStage } from '../../../../store/team-stage';
import { TeamCreationSubStage } from '../../../../store/team-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../styled';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = TeamCreationSubStage.AVATAR;

const TeamItemNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { team, teamAccessLevel } = useTeam();
  const isTeamManager = teamAccessLevel === Enums.AccessLevel.MANAGE;

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(team);
    cloned[field] = val;
    dispatch(
      fetchTeamSuccess({
        accessLevel: teamAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StyledBodySubHeader>Team Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new team.
          </StageBodyText>

          <StyledInput
            disabled={!isTeamManager}
            placeholder={'Enter your team name'}
            value={team?.name || ''}
            onChange={(event, data) => handleChange(NAME_FIELD, data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what is this team's goal and purpose?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            disabled={!isTeamManager}
            placeholder={'Enter a description'}
            value={team?.description || ''}
            onChange={(event) =>
              handleChange(DESCRIPTION_FIELD, event.target.value)
            }
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!team?.name.length}
            action={() => dispatch(updateActiveTeamSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default TeamItemNameStage;
