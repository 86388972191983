import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/upit-core-types';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import EditorJS, { OutputBlockData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Image from '@editorjs/image';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import { FC, useCallback, useEffect, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { EntityType } from '../../enums';
import useChart from '../../hooks/chart/UseChart';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import FileService from '../../services/file/FileService';
import createFileDocument from '../../util/create-file-document/CreateFileDocument';
import getUploadUrl from '../../util/get-file-upload-url/GetFileUploadUrl';

const EDITOR_JS_MIN_HEIGHT = 100;
const ALIGNMENT_TOOL_NAME = 'alignment';

interface WysiwygContent {
  id?: string;
  type: string;
  data: unknown;
  tunes?: unknown;
}

interface WysiwygEditorProps {
  readOnly: boolean;
  page: EntityType;
  content?: Interfaces.PanelContent[];
  areaId?: string;
  panelId?: string;
  onChange?: (arg: WysiwygContent[]) => void;
  refreshOnStateChange?: boolean;
}

const WysiwygEditor: FC<WysiwygEditorProps> = ({
  readOnly,
  content,
  areaId,
  panelId,
  page,
  onChange,
  refreshOnStateChange,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const { chartSaving, updatePanelContent } = useChart();
  const { organisation } = useOrganisation();
  const { getAccessTokenSilently } = useAuth0();

  const ReactEditorJS = createReactEditorJS();
  const editorCore = useRef<EditorJS | null>();

  const handleInitialize = useCallback((instance: EditorJS) => {
    editorCore.current = instance;
  }, []);

  useEffect(() => {
    if (editorCore.current && content && refreshOnStateChange) {
      editorCore.current.render({ blocks: content as OutputBlockData[] });
    }
  }, [content, refreshOnStateChange]);

  const handleSave = async () => {
    if (page === EntityType.CHART) {
      if (chartSaving) {
        return;
      }

      if (editorCore && editorCore.current) {
        const savedData = await editorCore.current.save();

        await updatePanelContent(areaId, panelId, savedData.blocks);
      }
    }
    if (page === EntityType.PORTAL) {
      if (editorCore && editorCore.current) {
        const savedData = await editorCore.current.save();
        if (savedData && onChange) {
          onChange(savedData.blocks as WysiwygContent[]);
        }
      }
    }
  };

  const uploadImageToS3 = async (
    file: File,
    organisationId: string,
    userId: string,
  ) => {
    const token = await getAccessTokenSilently();

    // Get upload URL
    const uploadUrl = await getUploadUrl(
      token,
      file.name,
      file.type,
      organisationId,
      userId,
    );

    // Upload file to S3
    await FileService.uploadFileToS3(uploadUrl.uploadURL, file);

    // Create File document
    const result = await createFileDocument(
      token,
      file.name,
      file.type,
      uploadUrl.uploadId,
      organisationId,
      userId,
    );

    return {
      success: 1,
      file: {
        url: `${organisation?.mediaDomain}/${result.file.s3.key}`,
      },
    };
  };

  const EDITOR_JS_TOOLS = loggedInUser
    ? {
        header: {
          class: Header,
          tunes: [ALIGNMENT_TOOL_NAME],
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: false,
          tunes: [ALIGNMENT_TOOL_NAME],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        code: Code,
        image: {
          class: Image,
          config: {
            uploader: {
              uploadByFile(file: File) {
                return uploadImageToS3(
                  file,
                  loggedInUser?.organisationId,
                  loggedInUser?._id,
                );
              },
            },
          },
        },
        quote: Quote,
        delimiter: Delimiter,
        alignment: {
          class: AlignmentTuneTool,
          config: {
            default: 'left',
            blocks: {
              header: 'center',
            },
          },
        },
      }
    : {};

  if (loggedInUser) {
    return (
      <ReactEditorJS
        holder={`react-editor-${areaId}-${panelId}`}
        style={{ height: '100px' }}
        autofocus={true}
        readOnly={readOnly}
        minHeight={EDITOR_JS_MIN_HEIGHT}
        tools={EDITOR_JS_TOOLS}
        onInitialize={handleInitialize}
        onChange={() => handleSave()}
        defaultValue={{
          blocks: content,
        }}
      />
    );
  }
  return null;
};

export default WysiwygEditor;
