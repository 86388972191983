import { Interfaces } from '@configur-tech/upit-core-types';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../../../assets/icons/loading/default-loading-icon.gif';
import useQuery from '../../../../hooks/query/UseQuery';
import { StageBodyText, StyledBodySubHeader } from '../../../../main/theme';
import { QueryItemOutput } from '../../../../store/queries';
import { RootState } from '../../../../store/rootReducer';
import * as SC from '../../styled';

const PAGE_SIZE = 300;
const PAGE_NUM = 1;

interface QueryJobProps {
  job: Interfaces.PipelineJob;
}

const QueryJob: FC<QueryJobProps> = ({ job }) => {
  const themeContext = useContext(ThemeContext);
  const { getQueries } = useQuery();

  const [loading, setLoading] = useState<boolean>(true);
  const [outputQueryName, setOutputQueryName] = useState<string>();

  const queries: QueryItemOutput[] = useSelector(
    (state: RootState) => state.queries,
  )?.data.data;

  const jobParams = job.jobParams as Interfaces.QueryJobParams;
  const query = jobParams.queryId;
  const filter = jobParams.filters as Interfaces.DynamicFilterField[];

  // Set mapping job ouput dataset name
  useEffect(() => {
    if (!outputQueryName && query) {
      (async () => {
        let outputDsm = queries.find((ds) => ds.entity._id === query);

        if (outputDsm) {
          setOutputQueryName(outputDsm?.entity.name);
          setLoading(false);
        } else {
          const queryParams = {
            pageNum: PAGE_NUM,
            limit: PAGE_SIZE,
          };

          await getQueries(queryParams);

          outputDsm = queries.find((ds) => ds.entity._id === query);
          setOutputQueryName(outputDsm?.entity.name);
        }
      })();
    }
  }, [getQueries, outputQueryName, queries, query]);

  return (
    <>
      {loading && (
        <>
          <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
        </>
      )}
      {!loading && (
        <div>
          <StyledBodySubHeader
            style={{ marginBottom: themeContext.margin.standard }}
          >
            Query Name
          </StyledBodySubHeader>
          <StageBodyText>{outputQueryName}</StageBodyText>
        </div>
      )}
    </>
  );
};

export default QueryJob;
