import styled from 'styled-components';

export const MergeColumnSelectorWrapper = styled.div<{
  wrapperMargin?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${({ theme, wrapperMargin }) =>
    wrapperMargin ? wrapperMargin : theme.margin.xxlarge};
`;

export const OptionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90%;
  max-width: 900px;

  margin-top: ${({ theme }) => theme.margin.xxlarge};
  padding: ${({ theme }) => theme.padding.xlarge};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const OptionalFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionalField = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : '40%')};

  text-align: center;
`;
