import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { showModal } from '../../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';

const AddGroupButton: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  return (
    <FeatureButton
      action={() =>
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.FORM_ADD_GROUP,
          }),
        )
      }
      size={FeatureButtonSize.WIDE_SMALL}
      color={themeContext.colors.general.blue}
      text={'Add Group'}
      containerStyle={{ padding: themeContext.padding.standard }}
      style={{ width: '100%' }}
      faceStyle={{ width: '100%' }}
    />
  );
};

export default AddGroupButton;
