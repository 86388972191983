import { Interfaces } from '@configur-tech/upit-core-types';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { EntityType, ListOptionColor } from '../../../../enums';
import useCMS from '../../../../hooks/cms/useCMS';
import { StyledSubHeader, StyledText } from '../../../../main/theme';
import { showModal } from '../../../../store/modal';
import { fetchCMSSuccess } from '../../../../store/project-cms';
import { RootState } from '../../../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import SortableItem from '../../../ListItemStages/ListItemCreationStages/2-values/SortableItem';
import { ModalTypes } from '../../../Modal/Modal';
import * as SC from './styled';

interface CMSEntitySelectorProps {
  group: {
    groupName: string;
    items: Interfaces.CMSConfigurationDataGroupItem[];
  };
}

interface EntityItem {
  entityType: EntityType.DATASET | EntityType.AGGREGATION | EntityType.CHART;
  id: string;
  value: string;
  text: string;
  avatar?: string;
}

const CMSEntitySelector: FC<CMSEntitySelectorProps> = ({ group }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { cms } = useCMS();

  const datasetMetas = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data;
  const queries = useSelector((state: RootState) => state.queries)?.data;
  const charts = useSelector((state: RootState) => state.charts)?.data;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [entityOptions, setEntityOptions] = useState<EntityItem[]>([]);
  const [groupHasChanged, setGroupHasChanged] = useState<boolean>(false);

  // Check if group length has changed
  useEffect(() => {
    if (
      group?.items ||
      datasetMetas?.data.length ||
      queries?.data.length ||
      charts?.data.length
    ) {
      setGroupHasChanged(true);
    }
  }, [
    datasetMetas?.data.length,
    group?.items,
    queries?.data.length,
    charts?.data.length,
  ]);

  // Format items to dropdown options
  useEffect(() => {
    if (groupHasChanged) {
      setGroupHasChanged(false);
      setEntityOptions(
        group.items.reduce((acc: EntityItem[], item) => {
          if (!item.datasetMetaId && !item.aggregationId && !item.chartId) {
            return acc;
          }

          const isDSM = !!item.datasetMetaId;
          const isQuery = !!item.aggregationId;

          const exists = entityOptions.find((ent) =>
            isDSM
              ? ent.value === item.datasetMetaId
              : isQuery
              ? ent.value === item.aggregationId
              : ent.value === item.chartId,
          );

          if (exists) {
            return acc.concat([exists]);
          }

          const ent = (
            isDSM
              ? datasetMetas?.data.find(
                  (d) => d.entity._id === item.datasetMetaId,
                )
              : isQuery
              ? queries?.data.find((d) => d.entity._id === item.aggregationId)
              : charts?.data.find((d) => d.entity._id === item.chartId)
          )?.entity;

          if (!ent) {
            return acc;
          }

          return acc.concat([
            {
              entityType: isDSM
                ? EntityType.DATASET
                : isQuery
                ? EntityType.AGGREGATION
                : EntityType.CHART,
              id: ent._id,
              value: ent._id,
              text: ent.name,
            },
          ]);
        }, []),
      );
    }
  }, [
    datasetMetas?.data,
    entityOptions,
    group.items,
    groupHasChanged,
    queries?.data,
    charts?.data,
  ]);

  const handleDragEnd = ({ active, over }) => {
    const oldIndex = entityOptions.findIndex((i) => i.id == active.id);
    const newIndex = entityOptions.findIndex((i) => i.id == over.id);

    const cloned = cloneDeep(cms);
    const cGroup = cloned.configuration.groups.find(
      (cGroup) => cGroup.groupName === group.groupName,
    );

    cGroup.items = arrayMove(
      cGroup.items.filter((item) =>
        entityOptions.find((ent) =>
          [item.datasetMetaId, item.aggregationId, item.chartId].includes(
            ent.id,
          ),
        ),
      ),
      oldIndex,
      newIndex,
    );

    dispatch(fetchCMSSuccess(cloned));
  };

  const handleRemoveListItem = (id: string) => {
    const cloned = cloneDeep(cms);

    const cGroup = (
      cloned.configuration as Interfaces.CMSConnectionConfiguration
    ).groups.find((g) => g.groupName === group.groupName);

    if (cGroup) {
      const itemIndex = cGroup.items.findIndex(
        (item) =>
          item.datasetMetaId === id ||
          item.aggregationId === id ||
          item.chartId === id,
      );
      cGroup.items.splice(itemIndex, 1);

      dispatch(fetchCMSSuccess(cloned));
    }
  };

  const handleEditListItem = (entityType: EntityType, id: string) => {
    dispatch(
      showModal({
        visible: true,
        modal: ModalTypes.CONNECTION_CMS_ADD_ENTITY,
        fullScreen: true,
        additionalProps: {
          groupName: group.groupName,
          id,
        },
      }),
    );
  };

  return (
    <>
      <StyledSubHeader>Available Content</StyledSubHeader>

      <StyledText>
        Select which of your content you would like to be made available in this
        Workspace?
      </StyledText>

      <FeatureButton
        action={() => {
          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.CONNECTION_CMS_ADD_ENTITY,
              fullScreen: true,
              additionalProps: { groupName: group.groupName },
            }),
          );
        }}
        size={FeatureButtonSize.WIDE}
        color={themeContext.colors.general.blue}
        text={`Add Content`}
        style={{ marginBottom: themeContext.margin.xlarge }}
      />

      {entityOptions.length > 0 && (
        <SC.SortableContainer>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={entityOptions}
              strategy={rectSortingStrategy}
            >
              {entityOptions.map(
                ({ entityType, id, value, text, avatar }, index) => (
                  <SortableItem
                    removeAction={() => handleRemoveListItem(id)}
                    editAction={() => handleEditListItem(entityType, id)}
                    key={`${index}_${id}`}
                    id={id}
                    value={value}
                    text={text}
                    avatar={avatar}
                    color={
                      entityType === EntityType.DATASET
                        ? ListOptionColor.GREEN_SEA
                        : entityType === EntityType.AGGREGATION
                        ? ListOptionColor.BELIZE_HOLE
                        : ListOptionColor.AMETHYST
                    }
                  />
                ),
              )}
            </SortableContext>
          </DndContext>
        </SC.SortableContainer>
      )}
    </>
  );
};

export default CMSEntitySelector;
