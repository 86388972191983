import styled from 'styled-components';
import { StyledText } from '../../../../main/theme';

export const SubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;
