import styled from 'styled-components';
import { StyledSubHeader } from '../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled(StyledSubHeader)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  margin: 0 ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.small};
`;
