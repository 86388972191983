import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useChart from '../../../../hooks/chart/UseChart';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectChartSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectChartSubStage.CHART;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemChartNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { chart, updateChartContent } = useChart();

  const [chartName, setChartName] = useState<string>('');
  const [chartDescription, setChartDescription] = useState<string>('');

  const handleUpdateChart = async () => {
    const cloned = cloneDeep(chart);
    cloned[NAME_FIELD] = chartName;
    cloned[DESCRIPTION_FIELD] = chartDescription;

    await updateChartContent(cloned);
  };

  useEffect(() => {
    if (chart) {
      setChartName(chart.name);
      setChartDescription(chart.description || '');
    }
  }, [chart]);

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledBodySubHeader>Dashboard Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new dashboard.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your dashboard name'}
            value={chartName}
            onChange={(event, data) => setChartName(data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this dashboard?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={chartDescription || ''}
            onChange={(event) => setChartDescription(event.target.value)}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!chartName.length}
            action={() => {
              handleUpdateChart();
              dispatch(updateActiveProjectSubStage(NEXT_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Dashboard'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemChartNameStage;
