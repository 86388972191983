import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, ReactElement, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../enums';
import { StyledBodySubHeader, StyledText } from '../../../main/theme';
import { hideModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

export enum ErrorAction {
  LOGOUT = 'logout',
  REDIRECT = 'redirect',
  CLOSE = 'close',
}

export enum ErrorType {
  GENERAL = 'general',
  NO_USER_FOUND = 'no-user-found',
  NO_ORGANISATION_FOUND = 'no-organisation-found',
  NON_ACTIVE_SUBSCRIPTION = 'non-active-subscription',
  PERMISSION_DENIED = 'permission-denied',
  PUBLISH_FAILED = 'publish-failed',
  VALIDATION_FAILED = 'validation-failed',
  ENTITY_NOT_FOUND = 'entity-not-found',
  VALIDATION_LIMIT = 'validation-limit',
  DUPLICATE_COLUMNS = 'duplicate-columns',
}

export interface ErrorModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  errorType?: ErrorType;
  errorHeading?: string;
  errorText?: string | ReactElement;
  errorCancel?: () => void;
  errorCancelText?: string;
  errorAction?: ErrorAction | (() => void);
  errorActionText?: string;
  errorActionRedirectRoute?: string;
  errorData?: Record<string, unknown>;
}

interface ErrorMap {
  [key: string]: {
    errorHeading?: string;
    errorText?: string | ReactElement;
    errorCancel?: () => void;
    errorCancelText?: string;
    errorAction?: ErrorAction | (() => void);
    errorActionText?: string;
    errorActionRedirectRoute?: string;
    errorData?: Record<string, unknown>;
  };
}

interface DuplicateColumnErrorData {
  duplicateColumns: string[];
}

const ErrorModal: FC<ErrorModalProps> = ({
  setShowModal,
  errorType,
  errorHeading,
  errorText,
  errorCancel,
  errorCancelText,
  errorAction,
  errorActionText,
  errorActionRedirectRoute,
  errorData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { logout } = useAuth0();

  const domain = process.env['REACT_APP_DOMAIN'] || window.location.pathname;

  const closeModal = () => {
    dispatch(hideModal());
  };

  const redirect = () => {
    history.push(errorActionRedirectRoute || RouteName.WELCOME);
    dispatch(hideModal());
  };

  const errorMessageMap: ErrorMap = {
    [ErrorType.GENERAL]: {
      errorHeading: `Something Went Wrong`,
      errorText: `That didn't go quite to plan. Please try again later.`,
      errorAction: () => closeModal(),
      errorActionText: 'Okay',
    },
    [ErrorType.NO_USER_FOUND]: {
      errorHeading: 'No User Found',
      errorText:
        'No user account could be found. Please double check your login credentials or try again later.',
      errorAction: () =>
        logout({
          returnTo: domain,
        }),
      errorActionText: 'Login',
    },
    [ErrorType.NO_ORGANISATION_FOUND]: {
      errorHeading: 'No Organisation Found',
      errorText:
        'No organisation could be found. Please contact your organisation manager or try again later.',
      errorAction: () =>
        logout({
          returnTo: domain,
        }),
      errorActionText: 'Logout',
    },
    [ErrorType.NON_ACTIVE_SUBSCRIPTION]: {
      errorHeading: 'No Active Subscription Found',
      errorText:
        'Your subscription could not be found or has expired. Please contact your organisation manager.',
      errorAction: () =>
        logout({
          returnTo: domain,
        }),
      errorActionText: 'Logout',
    },
    [ErrorType.PERMISSION_DENIED]: {
      errorHeading: 'Nothing To See Here',
      errorText: `This page or portal either doesn't exist or you don't have the correct permissions to be able to access it. Please contact your organisation manager.`,
      errorAction: () =>
        logout({
          returnTo: domain,
        }),
      errorActionText: 'Logout',
    },
    [ErrorType.PUBLISH_FAILED]: {
      errorHeading: 'Publish Failed',
      errorText:
        'Something went wrong when trying to publish this data. Please try again later.',
      errorCancel: closeModal,
      errorCancelText: 'Close',
    },
    [ErrorType.VALIDATION_FAILED]: {
      errorHeading: `Validation Failed`,
      errorText:
        'Something went wrong when validating your data. Please check your source or try again later.',
      errorCancel: closeModal,
      errorCancelText: 'Close',
    },
    [ErrorType.VALIDATION_LIMIT]: {
      errorHeading: 'Validation Failed',
      errorText:
        'Dataset to validate has too many errors, please address them before trying again',
      errorCancel: closeModal,
      errorCancelText: 'Close',
    },
    [ErrorType.ENTITY_NOT_FOUND]: {
      errorHeading: `We Can't Find What You're Looking For`,
      errorText:
        'That item may have been deleted or you do not have access to it.',
      errorAction: () => redirect(),
      errorActionText: 'Go Back',
    },
    [ErrorType.DUPLICATE_COLUMNS]: {
      errorHeading: `Duplicate Columns`,
      errorText: (
        <>
          <StyledText>
            We have found the following duplicate column headers in your
            dataset:
          </StyledText>
          <StyledBodySubHeader>
            {(
              errorData as unknown as DuplicateColumnErrorData
            )?.duplicateColumns?.join(', ')}
          </StyledBodySubHeader>
          <StyledText>
            These must be removed before you can continue.
          </StyledText>
        </>
      ),
      errorCancel: closeModal,
      errorCancelText: 'Confirm',
    },
  };

  const errorObj = errorMessageMap[errorType || ''];
  const errorTextIsString =
    typeof (errorText || errorObj?.errorText) === 'string';

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.Header>{errorHeading || errorObj?.errorHeading || 'Error'}</SC.Header>

      {
        <SC.ErrorBodyText>
          {(errorText || errorObj?.errorText) && (
            <>
              {errorTextIsString && (
                <StyledText>{errorText || errorObj?.errorText}</StyledText>
              )}

              {!errorTextIsString && (errorText || errorObj?.errorText)}
            </>
          )}

          {!errorText && !errorObj?.errorText && (
            <StyledText>
              That's not gone quite to plan. Let's try again.
            </StyledText>
          )}
        </SC.ErrorBodyText>
      }

      <SC.ActionButtonWrapper>
        {(errorCancel || errorObj?.errorCancel) && (
          <FeatureButton
            action={errorObj?.errorCancel || closeModal}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={errorCancelText || errorObj?.errorCancelText || 'Cancel'}
          />
        )}

        {typeof (errorAction || errorObj?.errorAction) === 'function' && (
          <FeatureButton
            action={(errorObj?.errorAction as () => void) || closeModal}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={errorActionText || errorObj?.errorActionText || 'Confirm'}
          />
        )}
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default ErrorModal;
