import { EventType } from '@configur-tech/upit-core-types/lib/enums';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RouteName } from '../../../../enums/RouteName';
import usePagination from '../../../../hooks/pagination/UsePagination';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import { SampleDataRow } from '../../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import {
  PipelineEventItemOutput,
  PipelineEventsItemOutput,
} from '../../../../services/events/EventsService';
import { hideLoading, showLoading } from '../../../../store/loading';
import { getDuration } from '../../../../util/data-sample/DataSampleUtils';
import DataSample from '../../../DataSample/DataSample';
import * as SC from '../../styled';
import EventPipelineStage from './EventPipelineStage';

const PAGE_SIZE = 25;
const ROW_NUMBER = 'row_id';
const STATUS_FIELD = 'Status';
const START_TIME_FIELD = 'Pipeline Started';
const DURATION_FIELD = 'Duration';
const EVENT_ID_FIELD = 'eventId';
const SORT_KEY = 'pipelineEvents.0.meta.created';

const TABLE_SCHEMA_EVENTS = [
  { name: STATUS_FIELD },
  { name: START_TIME_FIELD },
  { name: DURATION_FIELD },
];

const EventsPipelineStage: FC = () => {
  const { datasetMetaId, pipelineId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { getPipelineEvents } = usePipelineTemplate();
  const { calculateRowNumber } = usePagination();

  const [eventTableData, setEventTableData] = useState<SampleDataRow[]>([]);
  const [pagination, setPagination] = useState(0);
  const [pipelineEvents, setPipelineEvents] =
    useState<PipelineEventsItemOutput>();
  const [selectedEvent, setSelectedEvent] = useState<PipelineEventItemOutput>();

  useEffect(() => {
    if (pipelineId) {
      (async () => {
        try {
          await dispatch(showLoading({ text: 'Loading Events...' }));
          setPipelineEvents(
            await getPipelineEvents(pipelineId, pagination, PAGE_SIZE, {
              [SORT_KEY]: -1,
            }),
          );
        } finally {
          await dispatch(hideLoading());
        }
      })();
    }
  }, [dispatch, getPipelineEvents, pagination, pipelineId]);

  useEffect(() => {
    if (pipelineEvents) {
      const tableData: SampleDataRow[] = [];

      pipelineEvents.data.map((i, index) => {
        const ent = i.entity;

        const startTime = ent.pipelineEvents[0].meta.created;
        const endTime =
          ent?.pipelineEvents[ent.pipelineEvents.length - 1].meta.created;

        const pipelinePassed = ent.pipelineEvents.some(
          (e) => e.eventType === EventType.PIPELINE_SUCCESS,
        );

        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(pagination, index) },
          [STATUS_FIELD]: {
            value: pipelinePassed,
            isPipelineStatus: true,
          },
          [START_TIME_FIELD]: {
            value: DateTime.fromISO(startTime).toFormat('dd/MM/yyyy HH:mm:ss'),
          },
          [DURATION_FIELD]: {
            value: getDuration(startTime, endTime),
          },
          [EVENT_ID_FIELD]: {
            value: ent._id,
          },
        });
      });

      setEventTableData(tableData);
    }
  }, [calculateRowNumber, pipelineEvents, pagination]);

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  return (
    <StageWrapper>
      {selectedEvent ? (
        <EventPipelineStage
          selectedEvent={selectedEvent}
          resetSelectedEvent={() => setSelectedEvent(undefined)}
        />
      ) : (
        <StageInner>
          <SC.Section>
            <StageBodyText>
              Events that have fired on your Pipeline.
            </StageBodyText>
            <StageBodyText>
              Drill down into your pipeline events to see more details.
            </StageBodyText>

            <DataSample
              sampleColumns={TABLE_SCHEMA_EVENTS}
              sampleRows={eventTableData}
              showPagination={true}
              paginationAction={updatePagination}
              pageSize={PAGE_SIZE}
              totalRows={pipelineEvents?.pagination.totalCount}
              hideSortOnCols={[
                ROW_NUMBER,
                STATUS_FIELD,
                START_TIME_FIELD,
                DURATION_FIELD,
              ]}
              clickableRows={{
                valueField: EVENT_ID_FIELD,
                action: (pipelineEventId: string) => {
                  if (!pipelineEventId) {
                    return;
                  }

                  const pipelineEvent = pipelineEvents?.data.find(
                    (e) => e.entity._id === pipelineEventId,
                  );
                  setSelectedEvent(pipelineEvent);
                  history.push(
                    `${RouteName.DATASET_ITEM}/${datasetMetaId}${RouteName.PIPELINE_ITEM}/${pipelineId}${RouteName.EVENT_ITEM}/${pipelineEventId}`,
                  );
                },
              }}
            />
          </SC.Section>
        </StageInner>
      )}
    </StageWrapper>
  );
};

export default EventsPipelineStage;
