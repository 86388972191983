import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemAPIStage from '../../components/ProjectItem/ProjectItemAPIStage/ProjectItemAPIStage';
import Page from '../../main/Page';
import {
  getProjectStageContent,
  getProjectStageTitle,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectAPI: FC = () => {
  const activeStage = Enums.ConnectionType.API;
  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = getProjectStageContent(activeStage);

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={stageContent.image}
        title={stageContent.title}
      />

      <Page>
        <ProjectItemAPIStage />
      </Page>
    </>
  );
};

export default ProjectAPI;
