import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums/EntityType';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import useTeam from '../../../../hooks/team/UseTeam';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchTeamSuccess } from '../../../../store/team';
import { updateActiveTeamSubStage } from '../../../../store/team-stage';
import { TeamCreationSubStage } from '../../../../store/team-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import IconSelector from '../../../IconSelector/IconSelector';
import * as SC from '../../styled';

const AVATAR_FIELD = 'avatar';
const NEXT_STAGE = TeamCreationSubStage.USERS;
const PREV_STAGE = TeamCreationSubStage.NAME;

const TeamItemAvatarStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { team, teamAccessLevel, editTeam, addTeam } = useTeam();
  const { loggedInUser } = useLoggedInUser();
  const isTeamManager = teamAccessLevel === Enums.AccessLevel.MANAGE;

  const [processComplete, setProcessComplete] = useState<boolean>(false);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(team);
    cloned[field] = val;
    dispatch(
      fetchTeamSuccess({
        accessLevel: teamAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const processAction = async () => {
    if (loggedInUser) {
      dispatch(
        showLoading({
          text: `${team?._id ? 'Updating' : 'Creating'} Team...`,
        }),
      );

      // Create team
      const cloned = cloneDeep(team);

      // Add organisation
      cloned.organisation = loggedInUser.organisationId;

      // Save team
      team?._id ? await editTeam(cloned) : await addTeam(cloned);

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  useEffect(() => {
    if (processComplete && team?._id) {
      dispatch(updateActiveTeamSubStage(NEXT_STAGE));
    }
  }, [dispatch, processComplete, team?._id]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StageBodyText>
            Pick the perfect avatar to represent your team.
          </StageBodyText>

          <StageBodyText>
            This will make it easier to identify your squad across projects and
            datasets.
          </StageBodyText>

          <IconSelector
            disabled={!isTeamManager}
            type={EntityType.TEAM}
            onChange={(icon) => handleChange(AVATAR_FIELD, icon)}
            selectedIcon={team?.avatar}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Keep it going`}
        primaryButton={
          <FeatureButton
            isDisabled={!team?.avatar?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Members'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveTeamSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default TeamItemAvatarStage;
