import { Interfaces } from '@configur-tech/upit-core-types';
import { QueriesItemOutput, QueryItemOutput } from '../../store/queries';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_QUERY'] || '';

export default class QueryService {
  public static async getQueries(
    token: string,
    query?: Record<string, unknown>,
  ): Promise<
    | Interfaces.SuccessResponseBody<QueriesItemOutput>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<QueriesItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, query);
  }

  public static async getQuery(
    token: string,
    _id: string,
    portalOriginId?: string,
    internal?: boolean,
  ): Promise<QueryItemOutput> {
    const ah = new HttpHelper(token);

    const url = internal ? `${endpoint}internal` : endpoint;
    const query = internal
      ? { _id, portalOriginId }
      : { _id, portalOriginId, singleDocument: true };

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<QueryItemOutput>
      | Interfaces.ErrorResponseBody
    >(url, query);

    return result?.data as QueryItemOutput;
  }

  public static async postQuery(
    token: string,
    query: Interfaces.Query,
    userId: string,
    portalId?: string,
  ): Promise<QueryItemOutput> {
    const ah = new HttpHelper(token);
    const result = await ah.post<
      | Interfaces.SuccessResponseBody<QueryItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: query, userId, portalId });

    return result?.data as QueryItemOutput;
  }

  public static async putQuery(
    token: string,
    query: Interfaces.QueryOutput,
    userId: string,
    portalId?: string,
  ): Promise<QueryItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<QueryItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${query._id}`, { data: query, userId, portalId });

    return result?.data as QueryItemOutput;
  }

  public static async deleteQuery(
    token: string,
    queryId: string,
    userId: string,
    portalId?: string,
  ): Promise<QueryItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<QueryItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${queryId}`, userId, portalId);

    return result?.data as QueryItemOutput;
  }
}
