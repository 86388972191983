import { Enums } from '@configur-tech/upit-core-types';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { ModalTypes } from '../../components/Modal/Modal';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { PAGE, PAGE_SIZE } from '../../const/PaginationConst';
import { EntityType, RouteName } from '../../enums';
import { DEFAULT_PAGE_SIZE } from '../../pages/CMS/CMSPage';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { fetchCMSSuccess } from '../../store/project-cms';
import { updateActiveConnection } from '../../store/project-stage';
import { RootState } from '../../store/rootReducer';
import useChart from '../chart/UseChart';
import useDatasetMeta from '../dataset-meta/UseDatasetMeta';
import useDataset from '../dataset/UseDataset';
import useFilter from '../filter/UseFilter';
import useList from '../list/UseList';
import usePortal from '../portal/UsePortal';
import useProject from '../project/UseProject';
import useQuery from '../query/UseQuery';
import useSort, { SortItem } from '../sort/UseSort';
import useCMS from './useCMS';

export interface SelectedResource {
  resourceType: EntityType;
  resourceId: string;
  page: number;
  pageSize: number;
  sort?: SortItem;
  projectId?: string;
  cmsId?: string;
  groupId?: string;
  portalId?: string;
}

interface useCMSDataResult {
  selectedResource?: SelectedResource;
  selectedResourceFetching?: boolean;

  // Utils
  updateCMSSort: (sort: SortItem) => void;
  refreshDatasetEntries: () => void;
}

const useCMSData = (): useCMSDataResult => {
  const dispatch = useDispatch();

  const {
    projectId,
    chartId,
    cmsId,
    datasetMetaId,
    aggregationId,
    portalId,
    groupId,
  } = useParams();
  const location = useLocation();

  const { project, getProject } = useProject();
  const { getDatasetMeta } = useDatasetMeta();
  const { getChart } = useChart();
  const { getQuery } = useQuery();
  const { getDataset, getAggregatedDataset } = useDataset();
  const { portal, getPortal } = usePortal();
  const { getSort, updateSort } = useSort();
  const { getDatasetFilters } = useFilter();
  const { getListsForResource } = useList();
  const { checkCommentsEnabledForItem, cmsConfig } = useCMS();

  const [selectedResource, setSelectedResource] = useState<SelectedResource>();
  const [selectedResourceFetching, setSelectedResourceFetching] =
    useState<boolean>();

  const isCMS =
    location.pathname.includes(RouteName.PROJECT_ITEM) &&
    location.pathname.includes(RouteName.CMS);

  const isViewingDataset = location.pathname.includes('view');

  const datasetState = useSelector((state: RootState) => state.dataset);
  const chartState = useSelector((state: RootState) => state.chart);

  /**
   * Fetches Query and Dataset
   *
   * @param {string} resourceId - ResourceId
   * @param {string} page - Current page
   */
  const fetchQuery = useCallback(
    async (resourceId: string, page: number) => {
      getQuery(resourceId, portalId);
      getAggregatedDataset(
        resourceId,
        await getDatasetFilters(resourceId, true),
        page,
        portalId,
      );
    },
    [getAggregatedDataset, getDatasetFilters, getQuery, portalId],
  );

  /**
   * Displays an Error Modal
   *
   * @param {EntityType} entityType
   */
  const showErrorModal = useCallback(
    (entityType: EntityType) => {
      const type =
        entityType === EntityType.AGGREGATION
          ? 'Query'
          : entityType === EntityType.CHART
          ? 'Chart'
          : entityType === EntityType.PROJECT
          ? 'Project'
          : 'Dataset';

      const err = {
        visible: true,
        modal: ModalTypes.ERROR,
        forceOpen: true,
        additionalProps: {
          errorType: ErrorType.ENTITY_NOT_FOUND,
          errorHeading: `${type} Not Found`,
          errorText: `This ${type.toLowerCase()} could not be found, it may have been deleted or you do not have access to view it.`,
          errorActionText: portalId
            ? 'Return to Portal'
            : isCMS
            ? 'Return to Workspace'
            : 'Return to Datasets',
          errorActionRedirectRoute: portalId
            ? `${RouteName.PORTAL}/${portalId}`
            : isCMS
            ? `${RouteName.PROJECT_ITEM}/${projectId}${RouteName.CMS}/${cmsId}`
            : RouteName.DATASETS,
        },
      };
      dispatch(showModal(err));
    },
    [portalId, isCMS, projectId, cmsId, dispatch],
  );

  /**
   * Updates CMS Sort state
   *
   * @param {sort: SortItem} sort - New sort state
   */
  const updateCMSSort = useCallback(
    (sort: SortItem) => {
      if (
        selectedResource?.resourceType === EntityType.DATASET &&
        selectedResource &&
        !isEqual(sort, selectedResource.sort)
      ) {
        setSelectedResource({ ...selectedResource, sort });
        updateSort(selectedResource?.resourceId, sort);
      }
    },
    [selectedResource, updateSort],
  );

  const page = useMemo(
    () => new URLSearchParams(location.search).get(PAGE),
    [location.search],
  );

  const pageSize = useMemo(
    () => new URLSearchParams(location.search).get(PAGE_SIZE),
    [location.search],
  );

  const includeCommentsCounts = useCallback(
    (resourceId: string, groupId?: string) =>
      checkCommentsEnabledForItem(resourceId || isViewingDataset, groupId),
    [checkCommentsEnabledForItem, isViewingDataset],
  );

  const refreshDatasetEntries = useCallback(async () => {
    if (selectedResource && selectedResource.resourceId) {
      setSelectedResourceFetching(true);
      await Promise.all([
        getDataset(
          selectedResource.resourceId,
          selectedResource.page,
          selectedResource.sort,
          selectedResource.pageSize,
          selectedResource.cmsId,
          selectedResource.groupId,
          selectedResource.portalId,
          includeCommentsCounts(
            selectedResource.resourceId,
            selectedResource.groupId,
          ),
        ),
      ]);
      setSelectedResourceFetching(false);
    }
  }, [getDataset, includeCommentsCounts, selectedResource]);

  // Fetching data and entities when `selectedResource` changes
  useEffect(() => {
    (async () => {
      // Return if portal not yet loaded
      if (selectedResource?.portalId && !cmsConfig?.groups.length) {
        return;
      }

      if (selectedResource && selectedResource.resourceId) {
        switch (selectedResource?.resourceType) {
          case EntityType.DATASET:
            {
              await Promise.all([
                getDatasetMeta(
                  selectedResource.resourceId,
                  selectedResource.portalId,
                ),
                getDataset(
                  selectedResource.resourceId,
                  selectedResource.page,
                  selectedResource.sort,
                  selectedResource.pageSize,
                  selectedResource.cmsId,
                  selectedResource.groupId,
                  selectedResource.portalId,
                  includeCommentsCounts(
                    selectedResource.resourceId,
                    selectedResource.groupId,
                  ),
                ),
                getListsForResource(
                  Enums.SchemaName.DATASET_META,
                  selectedResource.resourceId,
                ),
              ]);
            }
            break;
          case EntityType.CHART:
            await Promise.all([
              getChart(selectedResource.resourceId, selectedResource.portalId),
              getListsForResource(
                Enums.SchemaName.CHART,
                selectedResource.resourceId,
              ),
            ]);
            break;
          case EntityType.AGGREGATION:
            await Promise.all([
              fetchQuery(selectedResource.resourceId, selectedResource.page),
              getListsForResource(
                Enums.SchemaName.QUERY,
                selectedResource.resourceId,
              ),
            ]);
            break;
        }
        setSelectedResourceFetching(false);
      }
    })();
  }, [
    cmsConfig,
    fetchQuery,
    getAggregatedDataset,
    getChart,
    getDataset,
    getDatasetMeta,
    getListsForResource,
    getQuery,
    includeCommentsCounts,
    selectedResource,
    showErrorModal,
  ]);

  // Error handling
  useEffect(() => {
    if (selectedResourceFetching) {
      return;
    }
    if (
      datasetState.error &&
      datasetMetaId &&
      datasetState.error.includes(Enums.StatusCode.NOT_FOUND.toString())
    ) {
      showErrorModal(EntityType.DATASET);
    }
    if (datasetState.error && aggregationId) {
      showErrorModal(EntityType.AGGREGATION);
    }
    if (chartState.error && chartId) {
      showErrorModal(EntityType.CHART);
    }
  }, [
    aggregationId,
    chartId,
    chartState.error,
    datasetMetaId,
    datasetState.error,
    selectedResourceFetching,
    showErrorModal,
  ]);

  // Fetch Project if required
  useEffect(() => {
    if (selectedResource?.projectId) {
      getProject(selectedResource?.projectId, true);
    }
  }, [dispatch, getProject, selectedResource?.projectId]);

  // Fetch Portal if required
  useEffect(() => {
    (async () => {
      if (
        selectedResource?.portalId &&
        selectedResource?.portalId !== portal?._id
      ) {
        dispatch(showLoading({ text: 'Loading Portal' }));
        await getPortal(selectedResource?.portalId, true);
      }
    })();
  }, [dispatch, getPortal, portal?._id, selectedResource?.portalId]);

  useEffect(() => {
    if (
      selectedResource?.portalId &&
      selectedResource?.portalId === portal?._id
    ) {
      dispatch(hideLoading());
    }
  }, [dispatch, portal?._id, selectedResource?.portalId]);

  // Set CMS Conf from Project if present
  useEffect(() => {
    if (projectId) {
      const conf = project?.connections?.[Enums.ConnectionType.CMS]?.find(
        (c) => c._id === cmsId,
      );

      if (conf && conf.status === Enums.ConnectionStatus.ACTIVE) {
        dispatch(fetchCMSSuccess(conf));
      }
    }
  }, [cmsId, dispatch, project?.connections, projectId]);

  // Set CMS Conf from Portal if present
  useEffect(() => {
    if (portalId) {
      // If portal set connection as cmsConf
      const conn = portal?.connectionItems?.cms.find((c) => c._id === cmsId);
      if (conn) {
        dispatch(fetchCMSSuccess(conn));
        dispatch(updateActiveConnection(conn));
      }
    }
  }, [cmsId, dispatch, portal?.connectionItems?.cms, portalId]);

  useEffect(() => {
    const resourceId = datasetMetaId || aggregationId || chartId;
    const resourceType = datasetMetaId
      ? EntityType.DATASET
      : aggregationId
      ? EntityType.AGGREGATION
      : EntityType.CHART;

    setSelectedResource({
      cmsId: cmsId,
      projectId: projectId,
      portalId: portalId,
      groupId: groupId,
      resourceType: resourceType,
      resourceId: resourceId,
      page: page ? +page : 1,
      pageSize: pageSize ? +pageSize : DEFAULT_PAGE_SIZE,
      sort: getSort(resourceId),
    });
    setSelectedResourceFetching(true);
  }, [
    aggregationId,
    chartId,
    cmsId,
    datasetMetaId,
    getPortal,
    getProject,
    getSort,
    groupId,
    page,
    pageSize,
    portalId,
    projectId,
  ]);

  return {
    selectedResource,
    selectedResourceFetching,

    // Utils
    updateCMSSort,
    refreshDatasetEntries,
  };
};

export default useCMSData;
