import styled from 'styled-components';
import { StyledBodySubHeader } from '../../main/theme';

export const StyledPanelSubHeader = styled(StyledBodySubHeader)`
  color: ${({ theme }) => theme.colors.system.offBlack};
  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;

export const GroupButton = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard}`};
  margin-bottom: ${({ theme }) => theme.margin.standard};

  background-color: ${({ theme, active }) =>
    active ? theme.colors.general.purple : theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  > div.checkbox {
    width: 75%;

    > label {
      color: ${({ theme, active }) =>
        active ? theme.colors.system.white : theme.colors.system.offBlack};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${({ disabled, active }) =>
    !disabled &&
    active &&
    `> svg {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }`};
`;

export const SortButton = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard}`};
  margin-bottom: ${({ theme }) => theme.margin.standard};

  background-color: ${({ theme, active }) =>
    active ? theme.colors.general.purple : theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  > div.checkbox {
    width: 75%;

    > label {
      color: ${({ theme, active }) =>
        active ? theme.colors.system.white : theme.colors.system.offBlack};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${({ disabled, active }) =>
    !disabled &&
    active &&
    `> svg {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }`};
`;

export const DisplayOrderButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard}`};
  margin-bottom: ${({ theme }) => theme.margin.standard};

  background-color: ${({ theme }) => theme.colors.general.purple};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colors.system.white};

  > svg {
    color: ${({ theme }) => theme.colors.system.white};
    margin-right: ${({ theme }) => theme.margin.standard};
  }

  &:hover {
    cursor: grab;
    color: ${({ theme }) => theme.colors.system.darkOffBlack};
  }
`;
