import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalTypes } from '../../components/Modal/Modal';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { RouteName } from '../../enums/RouteName';
import { SearchQuery } from '../../interfaces/Search';
import DatasetMetaService, {
  DatasetMetaItemOutput,
  DatasetMetasItemOutput,
} from '../../services/dataset-meta/DatasetMetaService';
import DatasetSnapshotService from '../../services/dataset-snapshot/DatasetSnapshotService';
import EventsService, {
  DatasetEventsItemOutput,
} from '../../services/events/EventsService';
import {
  createDatasetMeta,
  createDatasetMetaCollection,
  deleteDatasetMeta,
  fetchDatasetMeta,
  fetchDatasetMetaSuccess,
  updateDatasetMeta,
} from '../../store/dataset-meta';
import {
  fetchDatasetMetas,
  fetchDatasetMetasSuccess,
} from '../../store/dataset-metas';
import {
  createDatasetSnapshot,
  deleteDatasetSnapshot,
  fetchDatasetSnapshot,
  updateDatasetSnapshot,
} from '../../store/dataset-snapshots';
import { hideLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

const MERGE_ACTION = 'merge';
const REPLACE_ACTION = 'replace';

interface useDatasetMetaResult {
  datasetMeta?: Interfaces.DatasetMetaOutput;
  datasetMetaAccessLevel?: Enums.AccessLevel;
  datasetMetaError?: string;
  activeDataCollectionItem?: Interfaces.CollectionOutput;
  datasetSnapshot?: Interfaces.Snapshot;

  getDatasetMetas: (
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    page?: number,
    limit?: number,
    orderBy?: Record<string, number>,
    portalId?: string,
  ) => Promise<void>;
  getDatasetMetasDirect: (
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    page?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<DatasetMetaItemOutput[]>;
  getDatasetMeta: (
    datasetMetaId: string,
    portalId?: string,
    projectId?: string,
  ) => void;
  getDatasetMetaDirect: (
    datasetMetaId: string,
  ) => Promise<DatasetMetaItemOutput>;
  addDatasetMeta: (datasetMetaObj: Interfaces.DatasetMeta) => void;
  addDatasetMetaFromCustomIntegration: (
    integrationTemplateId: string,
    integrationTemplateName: string,
    actionId: string,
    actionName: string,
    responseSchema: string[],
    avatar: string,
  ) => Promise<DatasetMetaItemOutput | undefined>;
  addDatasetMetaFromHttpIntegration: (
    integrationTemplateId: string,
    actionId: string,
    actionName: string,
    responseSchema: string[],
    avatar: string,
    integrationTemplateName: string,
    endpointName: string,
  ) => Promise<DatasetMetaItemOutput | undefined>;
  addDatasetMetaCollection: (
    datasetMetaObj: Interfaces.DatasetMetaOutput,
    collection?: Partial<Interfaces.CollectionOutput>,
  ) => void;
  editDatasetMeta: (datasetMetaObj: Interfaces.DatasetMetaOutput) => void;
  removeDatasetMeta: (datasetMetaId: string) => void;

  getDatasetEvents: (
    resourceId: string,
    page: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<DatasetEventsItemOutput | undefined>;

  getDatasetSnapshot: (query?: Record<string, unknown>) => Promise<void>;
  addDatasetSnapshot: (
    datasetMetaId: string,
    datasetSnapshotObj: { name: string; description?: string },
  ) => void;
  editDatasetSnapshot: (datasetSnapshotObj: Interfaces.SnapshotOutput) => void;
  removeDatasetSnapshot: (datasetSnapshotId: string) => void;

  rollbackDatasetToSnapshot: (datasetSnapshotId: string) => void;
}

const useDatasetMeta = (): useDatasetMetaResult => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [datasetMeta, setDatasetMeta] =
    useState<Interfaces.DatasetMetaOutput>();
  const [datasetMetaAccessLevel, setDatasetMetaAccessLevel] =
    useState<number>();
  const [datasetMetaError, setDatasetMetaError] = useState<string>();
  const [activeDataCollectionItem, setActiveDataCollectionItem] =
    useState<Interfaces.CollectionOutput>();

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetaState = useSelector((state: RootState) => state.datasetMeta);
  const datasetMetaObj = datasetMetaState.data as DatasetMetaItemOutput;
  const datasetMetaErr = datasetMetaState.error;
  const datasetMetasObj = datasetMetasState.data as DatasetMetasItemOutput;

  const getDatasetMetas = useCallback(
    async (
      query?: Record<string, unknown>,
      searchQuery?: SearchQuery,
      page?: number,
      limit?: number,
      orderBy?: Record<string, number>,
      portalId?: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          await dispatch(
            fetchDatasetMetas(
              token,
              query,
              searchQuery,
              page,
              limit,
              orderBy,
              portalId,
            ),
          );
        } catch (err) {
          if (
            (err as AxiosError)?.response?.status !== Enums.StatusCode.NOT_FOUND
          ) {
            dispatch(
              showModal({
                visible: true,
                modal: ModalTypes.ERROR,
                forceOpen: true,
                additionalProps: {
                  errorType: ErrorType.ENTITY_NOT_FOUND,
                },
              }),
            );
          }
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const getDatasetMetasDirect = useCallback(
    async (
      query?: Record<string, unknown>,
      searchQuery?: SearchQuery,
      page?: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ): Promise<DatasetMetaItemOutput[]> => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          const fetched = await DatasetMetaService.getDatasetMetas(
            token,
            query,
            searchQuery,
            page,
            limit,
            orderBy,
          );

          return fetched.data?.data as DatasetMetaItemOutput[];
        } catch (err) {
          return [];
        }
      }

      return [];
    },
    [getAccessTokenSilently],
  );

  const getDatasetEvents = useCallback(
    async (
      resourceId: string,
      page: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          return await EventsService.getDatasetEvents(
            token,
            resourceId,
            page,
            limit,
            orderBy,
          );
        } catch (err) {
          if (
            (err as AxiosError)?.response?.data?.statusCode !==
            Enums.StatusCode.NOT_FOUND
          ) {
            toast.error(
              `${
                (err as AxiosError)?.response?.data?.statusCode
                  ? (err as AxiosError)?.response?.data?.statusCode
                  : 'Error'
              } - Failed to load recent events.`,
            );
          }
        }
      }
    },
    [getAccessTokenSilently],
  );

  const getDatasetSnapshot = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          await dispatch(fetchDatasetSnapshot(token, query));
        } catch (err) {
          if (
            (err as AxiosError)?.response?.data?.statusCode !==
            Enums.StatusCode.NOT_FOUND
          ) {
            toast.error(
              `${
                (err as AxiosError)?.response?.data?.statusCode
                  ? (err as AxiosError)?.response?.data?.statusCode
                  : 'Error'
              } - Failed to load Snapshots.`,
            );
          }
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addDatasetSnapshot = useCallback(
    async (datasetMetaId: string, datasetSnapshotObj) => {
      const token = await getAccessTokenSilently();

      if (token && datasetSnapshotObj && loggedInUser) {
        try {
          await dispatch(
            createDatasetSnapshot(token, datasetMetaId, datasetSnapshotObj),
          );
          toast.success('Snapshot created successfully!');
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to create Snapshot.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editDatasetSnapshot = useCallback(
    async (datasetSnapshotObj: Interfaces.SnapshotOutput) => {
      const token = await getAccessTokenSilently();

      if (token && datasetSnapshotObj && loggedInUser) {
        try {
          await dispatch(
            updateDatasetSnapshot(token, datasetSnapshotObj, loggedInUser._id),
          );
          toast.success('Snapshot updated successfully!');
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to update Snapshot.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeDatasetSnapshot = useCallback(
    async (datasetSnapshotId: string) => {
      const token = await getAccessTokenSilently();

      if (token && datasetSnapshotId && loggedInUser) {
        try {
          await dispatch(
            deleteDatasetSnapshot(token, datasetSnapshotId, loggedInUser._id),
          );
          toast.success('Snapshot deleted successfully!');
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to delete Snapshot.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const rollbackDatasetToSnapshot = useCallback(
    async (datasetSnapshotId: string) => {
      const token = await getAccessTokenSilently();

      if (token && datasetSnapshotId && loggedInUser) {
        try {
          const rollback =
            await DatasetSnapshotService.postDatasetSnapshotRollback(
              token,
              datasetSnapshotId,
            );

          if (rollback.data && datasetMetaAccessLevel) {
            dispatch(
              fetchDatasetMetaSuccess({
                entity: rollback.data
                  .datasetMeta as Interfaces.DatasetMetaOutput,
                accessLevel: datasetMetaAccessLevel,
              }),
            );
          }
          toast.success('Snapshot rollback successful!');
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to rollback to Snapshot.`,
          );
        }
      }
    },
    [loggedInUser, datasetMetaAccessLevel, getAccessTokenSilently, dispatch],
  );

  const getDatasetMeta = useCallback(
    async (datasetMetaId: string, portalId?: string, projectId?: string) => {
      const token = await getAccessTokenSilently();

      const isMerging = location.pathname.includes(MERGE_ACTION);
      const isReplacing = location.pathname.includes(REPLACE_ACTION);

      // If merging or replacing don't allow user to refresh page

      if (isMerging || isReplacing) {
        history.push(`${RouteName.DATASET_ITEM}/${datasetMetaId}`);
      }

      if (token && datasetMetaId) {
        await dispatch(
          fetchDatasetMeta(token, datasetMetaId, portalId, projectId),
        );
      }
    },
    [dispatch, getAccessTokenSilently, history],
  );

  const getDatasetMetaDirect = useCallback(
    async (datasetMetaId: string): Promise<DatasetMetaItemOutput> => {
      const token = await getAccessTokenSilently();

      let fetched;
      if (token && datasetMetaId) {
        fetched = await DatasetMetaService.getDatasetMeta(token, datasetMetaId);
      }
      return fetched as DatasetMetaItemOutput;
    },
    [getAccessTokenSilently],
  );

  const addDatasetMeta = useCallback(
    async (datasetMetaObj: Interfaces.DatasetMeta) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaObj && loggedInUser) {
        await dispatch(
          createDatasetMeta(token, datasetMetaObj, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addDatasetMetaFromCustomIntegration = useCallback(
    async (
      integrationTemplateId: string,
      integrationTemplateName: string,
      actionId: string,
      actionName: string,
      responseSchema: string[],
      avatar: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplateId && loggedInUser) {
        try {
          const integration =
            await DatasetMetaService.postDatasetMetaFromCustomIntegration(
              token,
              integrationTemplateName,
              integrationTemplateId,
              actionId,
              actionName,
              responseSchema,
              avatar,
            );
          dispatch(
            fetchDatasetMetaSuccess({
              entity: integration.entity,
              accessLevel: Enums.AccessLevel.MANAGE,
            }),
          );

          return integration;
        } catch (err) {
          dispatch(hideLoading());
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to create Dataset.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addDatasetMetaFromHttpIntegration = useCallback(
    async (
      integrationTemplateId: string,
      endpointId: string,
      reqEndpointId: string,
      schemaFieldIds: string[],
      avatar: string,
      integrationTemplateName: string,
      endpointName: string,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && integrationTemplateId && loggedInUser) {
        try {
          const integration =
            await DatasetMetaService.postDatasetMetaFromHttpIntegration(
              token,
              integrationTemplateId,
              endpointId,
              reqEndpointId,
              schemaFieldIds,
              avatar,
              integrationTemplateName,
              endpointName,
            );
          dispatch(
            fetchDatasetMetaSuccess({
              entity: integration.entity,
              accessLevel: Enums.AccessLevel.MANAGE,
            }),
          );

          return integration;
        } catch (err) {
          dispatch(hideLoading());
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to create Dataset.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addDatasetMetaCollection = useCallback(
    async (
      datasetMetaObj: Interfaces.DatasetMetaOutput,
      collection?: Partial<Interfaces.CollectionOutput>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaObj && loggedInUser) {
        await dispatch(
          createDatasetMetaCollection(
            token,
            datasetMetaObj,
            loggedInUser._id,
            collection,
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editDatasetMeta = useCallback(
    async (datasetMetaObj: Interfaces.DatasetMetaOutput) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaObj && loggedInUser) {
        await dispatch(
          updateDatasetMeta(token, datasetMetaObj, loggedInUser._id),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeDatasetMeta = useCallback(
    async (datasetMetaId: string) => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaId && loggedInUser) {
        try {
          await dispatch(
            deleteDatasetMeta(token, datasetMetaId, loggedInUser._id),
          );

          // Remove datasetMeta from current datasetMetas
          const cloned = cloneDeep(datasetMetasObj);
          cloned.data = cloned.data.filter(
            (e) => e.entity._id !== datasetMetaId,
          );

          await dispatch(fetchDatasetMetasSuccess(cloned));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode
                ? (err as AxiosError)?.response?.data?.statusCode
                : 'Error'
            } - Failed to delete Dataset.`,
          );
        }
      }
    },
    [datasetMetasObj, dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (datasetMetaObj) {
      // Complete model
      setDatasetMeta(datasetMetaObj.entity);
      setDatasetMetaAccessLevel(datasetMetaObj.accessLevel);

      // Active data collection
      const activeDataCollection = datasetMetaObj.entity.activeDataCollection;
      const dataCollection = datasetMetaObj.entity.dataCollections?.find(
        (c) => c._id === activeDataCollection,
      );

      setActiveDataCollectionItem(dataCollection || undefined);
    }

    if (datasetMetaErr) {
      setDatasetMetaError(datasetMetaErr);
    }
  }, [datasetMetaErr, datasetMetaObj]);

  return {
    datasetMeta,
    datasetMetaAccessLevel,
    datasetMetaError,
    activeDataCollectionItem,

    getDatasetMetas,
    getDatasetMetasDirect,
    getDatasetMeta,
    getDatasetMetaDirect,
    addDatasetMeta,
    addDatasetMetaCollection,
    addDatasetMetaFromCustomIntegration,
    addDatasetMetaFromHttpIntegration,
    editDatasetMeta,
    removeDatasetMeta,

    getDatasetEvents,

    getDatasetSnapshot,
    addDatasetSnapshot,
    editDatasetSnapshot,
    removeDatasetSnapshot,

    rollbackDatasetToSnapshot,
  };
};

export default useDatasetMeta;
