import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useForm from '../../../hooks/form/UseForm';
import { hideModal } from '../../../store/modal';
import { fetchFormSuccess } from '../../../store/project-form';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';

const STAGE_ONE = 1;
const STAGE_DELETE = 2;

export interface FormAddGroupModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingGroup?: {
    index: number;
    groupName: string;
  };
}

const StyledMessageWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.standard};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 800px;
  max-width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ActionButtonWrapper = styled.div`
  display: flex;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const FormAddGroupModal: FC<FormAddGroupModalProps> = ({
  setShowModal,
  existingGroup,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { form } = useForm();

  const [modalStage, setModalStage] = useState<number>(STAGE_ONE);
  const [groupName, setGroupName] = useState<string>(
    existingGroup?.groupName || '',
  );

  const handleColumnChange = (value: string) => {
    if (value) {
      setGroupName(value);
    }
  };

  const deleteGroup = (existingGroupName: string) => {
    const cloned = cloneDeep(form);
    const filteredQuestionGroups = cloned.questionGroups.filter(
      (questionGroup) => questionGroup.groupName !== existingGroupName,
    );
    cloned.questionGroups = filteredQuestionGroups;

    dispatch(fetchFormSuccess(cloned));
  };

  const insertItemAtIndex = (item, index: number, array) => {
    const prev = array.slice(0, index);
    const post = array.slice(index + 1);

    return [...prev, item, ...post];
  };

  const updateGroup = (existingGroupName: string) => {
    const cloned = cloneDeep(form);
    const existingGroupIndex = cloned.questionGroups.findIndex(
      (group) => group.groupName === existingGroupName,
    );
    const updatedGroup = (cloned.questionGroups[existingGroupIndex] = {
      ...cloned.questionGroups[existingGroupIndex],
      groupName,
    });
    const updatedQuestionGroups = insertItemAtIndex(
      updatedGroup,
      existingGroupIndex,
      cloned.questionGroups,
    );
    cloned.questionGroups = updatedQuestionGroups;

    dispatch(fetchFormSuccess(cloned));
    dispatch(hideModal());
  };

  const processAction = () => {
    if (groupName) {
      const cloned = cloneDeep(form);
      const clonedGroupName = cloneDeep(groupName);
      cloned.questionGroups.push({
        groupName: clonedGroupName,
        questions: [],
      });

      dispatch(fetchFormSuccess(cloned));
    }

    dispatch(hideModal());
  };

  useEffect(() => {
    if (existingGroup) {
      setGroupName(groupName);
    }
  }, [existingGroup, groupName]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const isGroupNameUnique = (groupName: string, form: Interfaces.FormOutput) =>
    form.questionGroups
      .filter((qG) => qG.groupName !== existingGroup?.groupName)
      .findIndex((group) => group.groupName === groupName) === -1;

  const isNotFilledOrUnique =
    (groupName && form && !isGroupNameUnique(groupName, form)) || !groupName;

  return (
    <Wrapper>
      <HeaderWrapper>
        {modalStage === STAGE_ONE && (
          <Header>{existingGroup ? 'Update' : 'Add'} Group</Header>
        )}

        {modalStage === STAGE_DELETE && <Header>Delete Group</Header>}

        {modalStage !== STAGE_DELETE && existingGroup && (
          <DeleteButton>
            <FeatureButton
              action={() => setModalStage(STAGE_DELETE)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.red}
              text={'Delete'}
            />
          </DeleteButton>
        )}
      </HeaderWrapper>

      {modalStage === STAGE_ONE && (
        <>
          <StyledText>
            Add a question group for your questions to sit in.
          </StyledText>

          <StyledBodySubHeader>Group Name</StyledBodySubHeader>

          <StyledInput
            placeholder={'Enter a group name'}
            value={(groupName && groupName) || ''}
            onChange={(_, data) => handleColumnChange(data.value)}
          />
          {isNotFilledOrUnique && groupName && (
            <StyledMessageWrapper>
              <StyledBodySubHeader>
                {'Group names must be unique'}
              </StyledBodySubHeader>
            </StyledMessageWrapper>
          )}

          <ActionButtonWrapper>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={isNotFilledOrUnique}
              action={() => {
                existingGroup
                  ? updateGroup(existingGroup.groupName)
                  : processAction();
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Save Group'}
            />
          </ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <>
          <StyledText>
            Are you sure you wish to delete <strong>{groupName}</strong>?
          </StyledText>

          <StyledText>
            This will also delete <strong>all</strong> questions within this
            group.
          </StyledText>

          <ActionButtonWrapper>
            <FeatureButton
              action={() => setModalStage(STAGE_ONE)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'CANCEL'}
            />
            <FeatureButton
              action={() => {
                existingGroup && deleteGroup(existingGroup.groupName);
                dispatch(hideModal());
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.red}
              text={'DELETE QUESTION GROUP'}
            />
          </ActionButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default FormAddGroupModal;
