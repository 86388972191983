import { FC } from 'react';
import * as SC from './styled';

export interface NotificationEventReadToggleProps {
  showReadEvents: boolean;
  setShowReadEvents: (isRead: boolean) => void;
  unreadCount: number;
}

const MAX_UNREAD_COUNT = 999;

const NotificationEventReadToggle: FC<NotificationEventReadToggleProps> = ({
  showReadEvents,
  setShowReadEvents,
  unreadCount,
}) => {
  return (
    <SC.ReadToggleContainer>
      <SC.ReadToggleButton
        onClick={() => setShowReadEvents(false)}
        className={!showReadEvents ? 'active' : ''}
      >
        Unread{' '}
        {unreadCount > MAX_UNREAD_COUNT &&
          `(${
            unreadCount < MAX_UNREAD_COUNT
              ? unreadCount
              : `${MAX_UNREAD_COUNT}+`
          })`}
      </SC.ReadToggleButton>
      <SC.ReadToggleButton
        onClick={() => setShowReadEvents(true)}
        className={showReadEvents ? 'active' : ''}
      >
        Read
      </SC.ReadToggleButton>
    </SC.ReadToggleContainer>
  );
};

export default NotificationEventReadToggle;
