import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect, useState } from 'react';

import { DropdownItemProps } from 'semantic-ui-react';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';

interface TextUpperCaseProps {
  field: Interfaces.Field;
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
  columnNameOptions: DropdownItemProps[];
}

const TextUpperCase: FC<TextUpperCaseProps> = ({
  field,
  setDataValidation,
  setSuperColumnValid,
  loaded,
  columnNameOptions,
}) => {
  const [selectedColumnName, setSelectedColumnName] = useState<string>();

  // Set values if updating existing
  useEffect(() => {
    if (field) {
      const opts = field.dataValidation
        ?.textTransformation as Interfaces.SingleFieldOp;

      if (opts) {
        setSelectedColumnName(opts.params.field);
      }
    }
  }, [field]);

  // Set selection
  useEffect(() => {
    if (selectedColumnName) {
      setSuperColumnValid(true);
      setDataValidation({
        dataValidationType: Enums.ValueDataType.TEXT_TRANSFORMATION,
        textTransformation: {
          type: Enums.TextTransformOperation.UPPER_CASE,
          params: {
            field: selectedColumnName,
          },
        },
      });
    } else {
      setSuperColumnValid(false);
    }
  }, [selectedColumnName, setDataValidation, setSuperColumnValid]);

  return (
    <>
      <StyledBodySubHeader>Upper Case</StyledBodySubHeader>

      <StyledText>
        This super column will transform all values to be upper case.
      </StyledText>

      <StyledText>Select which column you would like to transform.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select column'}
        options={columnNameOptions}
        selection
        search
        value={selectedColumnName}
        onChange={(e, data) => setSelectedColumnName(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />
    </>
  );
};

export default TextUpperCase;
