import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import CopyCodeBox from '../../components/CopyCodeBox/CopyCodeBox';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemPortalNameStage from '../../components/ProjectItem/ProjectItemPortalStages/1-name/ProjectItemPortalNameStage';
import ProjectItemPortalConnectionsStage from '../../components/ProjectItem/ProjectItemPortalStages/2-connections/ProjectItemPortalConnectionsStage';
import ProjectItemPortalAccessStage from '../../components/ProjectItem/ProjectItemPortalStages/3-access/ProjectitemPortalAccessStage';
import ProjectItemPortalConfigurationStage from '../../components/ProjectItem/ProjectItemPortalStages/4-configuration/ProjectItemPortalConfigurationStage';
import ProjectItemPortalStage from '../../components/ProjectItem/ProjectItemPortalStages/ProjectItemPortalStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import { RouteName } from '../../enums/RouteName';
import usePortal from '../../hooks/portal/UsePortal';
import Page from '../../main/Page';
import { StyledBodySubHeader, StyledH1 } from '../../main/theme';
import { ProjectConnectionPortalSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';
import * as SC from './styled';

const ProjectPortals: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { portal } = usePortal();
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ConnectionType.PORTAL;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectConnectionPortalSubStage.NAME:
        return <ProjectItemPortalNameStage />;
      case ProjectConnectionPortalSubStage.CONNECTIONS:
        return <ProjectItemPortalConnectionsStage />;
      case ProjectConnectionPortalSubStage.ACCESS:
        return <ProjectItemPortalAccessStage />;
      case ProjectConnectionPortalSubStage.CONFIGURATION:
        return <ProjectItemPortalConfigurationStage />;
      default:
        return <ProjectItemPortalNameStage />;
    }
  };

  let stageContentTitle: React.ReactElement =
    stageContent.title as unknown as React.ReactElement;

  if (
    portal?._id &&
    activeSubStage === ProjectConnectionPortalSubStage.CONNECTIONS
  ) {
    stageContentTitle = (
      <SC.NameWrapper>
        <StyledH1>{stageContent.title}</StyledH1>

        <SC.CopyActionsWrapper>
          <StyledBodySubHeader>Portal URL</StyledBodySubHeader>
          <CopyCodeBox
            text={`${process.env['REACT_APP_PORTAL_DOMAIN']}${RouteName.PORTAL}/${portal?._id}`}
          />
        </SC.CopyActionsWrapper>

        <FeatureButton
          action={() =>
            window.open(
              `${process.env['REACT_APP_PORTAL_DOMAIN_LEGACY']}${RouteName.PORTAL}/${portal?._id}`,
            )
          }
          size={FeatureButtonSize.WIDE_SMALL}
          color={themeContext.colors.general.sea}
          text={'View Legacy Portal'}
        />
      </SC.NameWrapper>
    );
  }

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={
          activeSubStage &&
          activeSubStage !== ProjectConnectionPortalSubStage.CONFIGURATION
            ? {
                activeSubStage: activeSubStage,
                subStageItems: projectStageState.stages[
                  activeStage
                ].subStages.filter(
                  (stage) =>
                    stage.subStage !==
                    ProjectConnectionPortalSubStage.CONFIGURATION,
                ) as SubStageItem[],
              }
            : undefined
        }
        image={stageContent.image}
        title={stageContentTitle}
      />

      <Page>
        {activeSubStage ? getSubStageComponent() : <ProjectItemPortalStage />}
      </Page>
    </>
  );
};

export default ProjectPortals;
