import styled from 'styled-components';

const NOTIFICATION_COLOUR = '#d65745';
const NOTIFICATION_WIDTH = 16;
const NOTIFICATION_HEIGHT = 16;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 300px;

  color: ${({ theme }) => theme.colors.system.white};
`;

export const PopupWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

export const NotificationIconContainer = styled.div<{
  hasNotifications: boolean;
}>`
  position: relative;

  &:after {
    content: ${({ hasNotifications }) => hasNotifications && `''`};
    display: block;
    width: ${NOTIFICATION_WIDTH}px;
    height: ${NOTIFICATION_HEIGHT}px;
    background: ${NOTIFICATION_COLOUR};
    border-radius: 50%;
    position: absolute;
    top: ${({ theme }) => theme.margin.small};
    right: ${({ theme }) => theme.margin.small};
  }
`;
