import { Enums } from '@configur-tech/upit-core-types';
import {
  faBolt,
  faCalculator,
  faCalendarDay,
  faEnvelope,
  faFont,
  faMapMarkerAlt,
  faPhone,
  faQuestion,
  faToggleOn,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

const getDataTypeIcon = (
  dataType: Enums.ValueDataType,
  nameOnly = false,
): IconDefinition | string => {
  switch (dataType) {
    case Enums.ValueDataType.BOOL:
      if (nameOnly) {
        return 'toggle on';
      }
      return faToggleOn;
    case Enums.ValueDataType.DATE:
      if (nameOnly) {
        return 'calendar';
      }
      return faCalendarDay;
    case Enums.ValueDataType.EMAIL:
      if (nameOnly) {
        return 'envelope';
      }
      return faEnvelope;
    case Enums.ValueDataType.NUMBER:
      if (nameOnly) {
        return 'calculator';
      }
      return faCalculator;
    case Enums.ValueDataType.PHONE_NUM:
      if (nameOnly) {
        return 'phone';
      }
      return faPhone;
    case Enums.ValueDataType.POSTCODE:
      if (nameOnly) {
        return 'map marker';
      }
      return faMapMarkerAlt;
    case Enums.ValueDataType.TEXT:
      if (nameOnly) {
        return 'font';
      }
      return faFont;
    case Enums.ValueDataType.FIELD_LOOKUP:
    case Enums.ValueDataType.DATE_CONVERSION:
    case Enums.ValueDataType.FORMULA:
    case Enums.ValueDataType.TEXT_TRANSFORMATION:
      if (nameOnly) {
        return 'bolt';
      }
      return faBolt;
    default:
      if (nameOnly) {
        return 'question';
      }
      return faQuestion;
  }
};
export default getDataTypeIcon;
