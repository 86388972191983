import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import DatasetService from '../../services/dataset/DatasetService';
import { fetchDatasetMetaSuccess } from '../dataset-meta';
import { AppThunk } from '../store';

export interface DatasetPayload {
  datasetMetaId: string;
  entries: Record<string, unknown>[];
  hasMoreEntries: boolean;
  totalCount?: number;
}

export interface DatasetCreatePayload {
  datasetMeta: Interfaces.DatasetMetaOutput;
  entries: Record<string, unknown>[];
}

export interface DatasetUpdatePayload {
  datasetMetaId: string;
  row: Record<string, unknown>;
  silentUpdate?: boolean;
}

export interface DatasetUpdateRowsPayload {
  datasetMetaId: string;
  rows: Record<string, unknown>[];
}

export interface DatasetDeletePayload {
  datasetMetaId: string;
  rowId: string | number;
}

export interface DatasetDeleteRowsPayload {
  datasetMetaId: string;
  rowIds: number[];
}

export interface DatasetDeleteRowOutput {
  datasetMeta: Interfaces.DatasetMetaOutput;
  deletedEntries: Record<string, unknown>[];
}

export interface DatasetStateItem {
  entries: Record<string, unknown>[];
  hasMoreEntries: boolean;
  totalCount?: number;
}

export interface DatasetState {
  data: Record<string, DatasetStateItem>;
  loading: boolean;
  error: string | null;
}

export interface MergeSilentUpdateRowsPayload {
  datasetMetaId: string;
}

export const GRAPH_RAW_DATA = '_raw';

const initialState: DatasetState = {
  data: {},
  loading: false,
  error: null,
};

const DatasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    // Fetch
    fetchDatasetStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetSuccess(state, action: PayloadAction<DatasetPayload>) {
      state.data[action.payload.datasetMetaId] = {
        entries: action.payload.entries,
        hasMoreEntries: action.payload.hasMoreEntries,
        totalCount: action.payload.totalCount,
      };
      state.loading = false;
      state.error = null;
    },
    fetchDatasetFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createDatasetRowStart(state) {
      state.loading = true;
      state.error = null;
    },
    createDatasetRowSuccess(
      state,
      action: PayloadAction<DatasetCreatePayload>,
    ) {
      if (state.data[action.payload.datasetMeta._id]) {
        state.data[action.payload.datasetMeta._id].entries = state.data[
          action.payload.datasetMeta._id
        ].entries.concat(action.payload.entries);
      }
      state.loading = false;
      state.error = null;
    },
    createDatasetRowFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateDatasetRowStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateDatasetRowSuccess(
      state,
      action: PayloadAction<DatasetUpdatePayload>,
    ) {
      const dirPath = action.payload.silentUpdate
        ? `silent-${action.payload.datasetMetaId}`
        : action.payload.datasetMetaId;

      const cloned = cloneDeep(state.data[dirPath] || { entries: [] });
      const rowId = action.payload.row.row_id;

      // Silent update - keep record of any updated rows but do not update primary state
      if (action.payload.silentUpdate) {
        const previousUpdatedRowIndex = cloned.entries.findIndex(
          (row) => row.row_id === rowId,
        );

        if (previousUpdatedRowIndex !== -1) {
          cloned.entries[previousUpdatedRowIndex] = action.payload.row;
        } else {
          cloned.entries.push(action.payload.row);
        }
      } else {
        // Standard update, update primary state directly
        cloned.entries = cloned.entries.map((row) => {
          if (row.row_id === rowId) {
            return action.payload.row;
          }

          return row;
        });
      }

      state.data[dirPath] = cloned;
      state.loading = false;
      state.error = null;
    },
    updateDatasetRowsSuccess(
      state,
      action: PayloadAction<DatasetUpdateRowsPayload>,
    ) {
      const cloned = cloneDeep(state.data[action.payload.datasetMetaId]);
      const rows = action.payload.rows;

      rows.map((row) => {
        cloned.entries = cloned.entries.map((r) => {
          if (r.row_id === row.row_id) {
            return row;
          }
          return r;
        });
      });

      state.data[action.payload.datasetMetaId] = cloned;
      state.loading = false;
      state.error = null;
    },
    updateDatasetRowFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteDatasetRowStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteDatasetRowSuccess(
      state,
      action: PayloadAction<DatasetDeletePayload>,
    ) {
      const cloned = cloneDeep(state.data[action.payload.datasetMetaId]);
      const rowId = action.payload.rowId;

      cloned.entries = cloned.entries.filter((e) => e.row_id !== rowId);

      state.data[action.payload.datasetMetaId] = cloned;
      state.loading = false;
      state.error = null;
    },
    deleteDatasetRowFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteDatasetRowsSuccess(
      state,
      action: PayloadAction<DatasetDeleteRowsPayload>,
    ) {
      const cloned = cloneDeep(state.data[action.payload.datasetMetaId]);
      const rowIds = action.payload.rowIds;
      cloned.entries = cloned.entries.filter((e) => !rowIds.includes(e.row_id));

      state.data[action.payload.datasetMetaId] = cloned;
      state.loading = false;
      state.error = null;
    },

    mergeSilentUpdateRows(
      state,
      action: PayloadAction<MergeSilentUpdateRowsPayload>,
    ) {
      const updatedEntries =
        state.data[`silent-${action.payload.datasetMetaId}`]?.entries || [];

      const mergedEntries = cloneDeep(
        state.data[action.payload.datasetMetaId]?.entries,
      ).map((row) => {
        const updatedRow = updatedEntries?.find(
          (upRow) => upRow.row_id === row.row_id,
        );
        return updatedRow || row;
      });

      state.data[action.payload.datasetMetaId] = {
        ...state.data[action.payload.datasetMetaId],
        entries: mergedEntries,
      };

      // Now clear silent update rows
      state.data[`silent-${action.payload.datasetMetaId}`] = {
        entries: [],
        hasMoreEntries: false,
      };
    },
  },
});

export const {
  fetchDatasetStart,
  fetchDatasetSuccess,
  fetchDatasetFailure,
  createDatasetRowStart,
  createDatasetRowSuccess,
  createDatasetRowFailure,
  updateDatasetRowStart,
  updateDatasetRowSuccess,
  updateDatasetRowsSuccess,
  updateDatasetRowFailure,
  deleteDatasetRowStart,
  deleteDatasetRowSuccess,
  deleteDatasetRowFailure,
  deleteDatasetRowsSuccess,
  mergeSilentUpdateRows,
} = DatasetSlice.actions;

export default DatasetSlice.reducer;

export const fetchDataset =
  (
    token: string,
    datasetMetaId: string,
    filters: DynamicConditionalField[],
    page = 1,
    connectionId?: string,
    groupId?: string,
    portalId?: string,
    sortBy?: { id: string; desc: boolean },
    limit?: number,
    includeCommentsCounts?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatasetStart());
      const fetched = await DatasetService.fetchDataset(
        token,
        datasetMetaId,
        filters,
        page,
        connectionId,
        groupId,
        portalId,
        sortBy,
        limit,
        includeCommentsCounts,
      );

      dispatch(
        fetchDatasetSuccess({
          datasetMetaId,
          entries: fetched.entries,
          hasMoreEntries: fetched.next?.hasMoreEntries,
          totalCount: fetched.next?.totalCount,
        }),
      );
    } catch (err) {
      if (
        (err as AxiosError)?.response?.data.statusCode ===
        Enums.StatusCode.NOT_FOUND
      ) {
        return dispatch(
          fetchDatasetSuccess({
            datasetMetaId,
            entries: [],
            hasMoreEntries: false,
          }),
        );
      }

      dispatch(fetchDatasetFailure((err as string).toString()));
    }
  };

export const fetchAggregatedDataset =
  (
    token: string,
    aggregationId: string,
    filters: DynamicConditionalField[],
    page = 1,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatasetStart());
      const fetched = await DatasetService.fetchAggregatedDataset(
        token,
        aggregationId,
        filters,
        page,
        portalId,
      );

      dispatch(
        fetchDatasetSuccess({
          datasetMetaId: aggregationId,
          entries: fetched.entries,
          hasMoreEntries: fetched.next?.hasMoreEntries,
          totalCount: fetched.next?.totalCount,
        }),
      );
    } catch (err) {
      if (
        (err as AxiosError)?.response?.data.statusCode ===
        Enums.StatusCode.NOT_FOUND
      ) {
        return dispatch(
          fetchDatasetSuccess({
            datasetMetaId: aggregationId,
            entries: [],
            hasMoreEntries: false,
          }),
        );
      }
      dispatch(fetchDatasetFailure((err as string).toString()));
      throw err;
    }
  };

export const fetchGraphDataset =
  (
    token: string,
    graphId: string,
    filters: DynamicConditionalField[],
    page = 1,
    portalId?: string,
    showRawGraphData?: boolean,
    limit?: number,
  ): AppThunk =>
  async (dispatch) => {
    const graphStoreId = showRawGraphData
      ? `${graphId}${GRAPH_RAW_DATA}`
      : graphId;

    try {
      dispatch(fetchDatasetStart());
      const fetched = await DatasetService.fetchGraphDataset(
        token,
        graphId,
        filters,
        page,
        portalId,
        showRawGraphData,
        limit,
      );

      dispatch(
        fetchDatasetSuccess({
          datasetMetaId: graphStoreId,
          entries: fetched.entries,
          hasMoreEntries: fetched.next?.hasMoreEntries,
        }),
      );
    } catch (err) {
      if (
        (err as AxiosError)?.response?.data.statusCode ===
        Enums.StatusCode.NOT_FOUND
      ) {
        return dispatch(
          fetchDatasetSuccess({
            datasetMetaId: graphStoreId,
            entries: [],
            hasMoreEntries: false,
          }),
        );
      }
      dispatch(fetchDatasetFailure((err as string).toString()));
      throw err;
    }
  };

export const createDatasetRow =
  (
    token: string,
    userId: string,

    datasetMetaId: string,
    datasetMetaAccessLevel: Enums.AccessLevel,
    entries: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
    ignoreDatasetMetaUpdate?: boolean,
    preserveRowIds?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createDatasetRowStart());
      const created = await DatasetService.createDatasetRow(
        token,
        userId,

        datasetMetaId,
        entries,
        connectionId,
        portalId,
        includeCommentsCounts,
        preserveRowIds,
      );

      if (!preserveRowIds) {
        dispatch(createDatasetRowSuccess(created));
      }

      // Update datasetMeta with new counts
      if (!ignoreDatasetMetaUpdate) {
        dispatch(
          fetchDatasetMetaSuccess({
            entity: created.datasetMeta,
            accessLevel: datasetMetaAccessLevel,
          }),
        );
      }
    } catch (err) {
      dispatch(createDatasetRowFailure((err as string).toString()));
      throw err;
    }
  };

export const updateDatasetRow =
  (
    token: string,
    userId: string,

    datasetMetaId: string,
    updateData: Record<string, unknown>,
    rowId: number,
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
    silentUpdate?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      await dispatch(updateDatasetRowStart());
      const updated = await DatasetService.updateDatasetRow(
        token,
        userId,

        datasetMetaId,
        updateData,
        rowId,
        connectionId,
        portalId,
        includeCommentsCounts,
      );

      await dispatch(
        updateDatasetRowSuccess({
          datasetMetaId,
          row: updated.entry,
          silentUpdate,
        }),
      );
    } catch (err) {
      dispatch(updateDatasetRowFailure((err as string).toString()));
      throw err;
    }
  };

export const updateDatasetRows =
  (
    token: string,
    userId: string,

    datasetMetaId: string,
    updateData: Record<string, unknown>[],
    connectionId?: string,
    portalId?: string,
    includeCommentsCounts?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      await dispatch(updateDatasetRowStart());
      const updated = await DatasetService.updateDatasetRows(
        token,
        userId,

        datasetMetaId,
        updateData,
        connectionId,
        portalId,
        includeCommentsCounts,
      );

      await dispatch(
        updateDatasetRowsSuccess({
          datasetMetaId,
          rows: updated.entries,
        }),
      );
    } catch (err) {
      dispatch(updateDatasetRowFailure((err as string).toString()));
      throw err;
    }
  };

export const deleteDatasetRow = (
  token: string,
  userId: string,

  datasetMetaId: string,
  datasetMetaAccessLevel: Enums.AccessLevel,
  rowId: number,
  connectionId?: string,
  portalId?: string,
): AppThunk => {
  return async (dispatch) => {
    try {
      await dispatch(deleteDatasetRowStart());
      const deleted = await DatasetService.deleteDatasetRow(
        token,
        userId,
        datasetMetaId,
        rowId,
        connectionId,
        portalId,
      );

      await dispatch(deleteDatasetRowSuccess({ datasetMetaId, rowId }));

      // Update datasetMeta with new counts
      dispatch(
        fetchDatasetMetaSuccess({
          entity: deleted.datasetMeta,
          accessLevel: datasetMetaAccessLevel,
        }),
      );
      return deleted;
    } catch (err) {
      dispatch(deleteDatasetRowFailure((err as string).toString()));
      throw err;
    }
  };
};

export const deleteDatasetRows = (
  token: string,
  userId: string,

  datasetMetaId: string,
  datasetMetaAccessLevel: Enums.AccessLevel,
  rowIds: number[],
  connectionId?: string,
  portalId?: string,
): AppThunk => {
  return async (dispatch) => {
    try {
      await dispatch(deleteDatasetRowStart());
      const deleted = await DatasetService.deleteDatasetRows(
        token,
        userId,
        datasetMetaId,
        rowIds,
        connectionId,
        portalId,
      );

      await dispatch(deleteDatasetRowsSuccess({ datasetMetaId, rowIds }));

      // Update datasetMeta with new counts
      dispatch(
        fetchDatasetMetaSuccess({
          entity: deleted.datasetMeta,
          accessLevel: datasetMetaAccessLevel,
        }),
      );
      return deleted;
    } catch (err) {
      dispatch(deleteDatasetRowFailure((err as string).toString()));
      throw err;
    }
  };
};
