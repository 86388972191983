import {
  faArrowLeft,
  faCalendarDay,
  faChevronDown,
  faChevronUp,
  faCirclePlay,
  faCog,
  faListOl,
  faPlusSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import usePipelineTemplate from '../../hooks/pipeline-template/UsePipelineTemplate';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import { updateActiveDatasetStage } from '../../store/dataset-stage';
import { AdditionalDatasetStages } from '../../store/dataset-stage/initial-state';
import { IntegrationConfigurationSubStage } from '../../store/integration-stage/initial-state';
import {
  updateActivePipelineStage,
  updateActivePipelineSubStage,
} from '../../store/pipeline-stage';
import {
  PipelineConfigurationSubStage,
  PipelineCreationSubStage,
  PipelineStage,
} from '../../store/pipeline-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [PipelineCreationSubStage.META]: faPlusSquare,
  [PipelineCreationSubStage.TRIGGER]: faCirclePlay,
  [PipelineCreationSubStage.JOBS]: faListOl,
  [PipelineCreationSubStage.EVENTS]: faCalendarDay,
};

const PipelineItemNavigation: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { pipelineTemplate } = usePipelineTemplate();

  const [configurationStatus, setConfigurationStatus] =
    useState<boolean>(false);

  const pipelineStageState = useSelector(
    (state: RootState) => state.pipelineStage,
  );

  const activeStage = pipelineStageState.activeStage;
  const activeSubStage = pipelineStageState.stages[activeStage].activeSubStage;

  const isDisabled = (stage: PipelineCreationSubStage) => {
    switch (stage) {
      case PipelineCreationSubStage.TRIGGER:
        return !pipelineTemplate?._id;
      case PipelineCreationSubStage.JOBS:
        return !pipelineTemplate?._id || !pipelineTemplate?.trigger;
      default:
        return false;
    }
  };

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`form-stage-back`}
        onClick={() => {
          dispatch(updateActiveDatasetStage(AdditionalDatasetStages.PIPELINES));
          history.push(
            `${RouteName.DATASET_ITEM}/${pipelineTemplate?.datasetMetaId}`,
          );
        }}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={themeContext.colors.system.white}
        />

        <NavTitle text={'Back To Pipelines'} />
      </BackNavigationItem>

      {Object.values(PipelineCreationSubStage).map((stage) => {
        return (
          <NavItem
            key={`pipeline-create-${stage}`}
            onClick={() => {
              dispatch(updateActivePipelineStage(PipelineStage.CREATION));
              dispatch(updateActivePipelineSubStage(stage));
            }}
            isActive={stage === activeSubStage}
            disabled={isDisabled(stage)}
          >
            <FontAwesomeIcon
              icon={NavIcons[stage]}
              color={defaultTheme.colors.system.white}
            />
            <NavTitle text={stage} />
          </NavItem>
        );
      })}

      {/* Configuration accordion nav */}
      {pipelineTemplate?._id && (
        <NavigationAccordion>
          <div key={`configuration`}>
            <NavigationAccordionTitle
              active={configurationStatus}
              index={0}
              onClick={() => setConfigurationStatus(!configurationStatus)}
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle text={'Configuration'} />

                <FontAwesomeIcon
                  icon={configurationStatus ? faChevronUp : faChevronDown}
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent active={configurationStatus}>
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(
                      updateActivePipelineStage(PipelineStage.CONFIGURATION),
                    );
                    dispatch(
                      updateActivePipelineSubStage(
                        PipelineConfigurationSubStage.ADVANCED,
                      ),
                    );
                  }}
                  isActive={
                    activeStage === PipelineStage.CONFIGURATION &&
                    activeSubStage == PipelineConfigurationSubStage.ADVANCED
                  }
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={IntegrationConfigurationSubStage.ADVANCED}
                    isActive={
                      activeStage === PipelineStage.CONFIGURATION &&
                      activeSubStage == PipelineConfigurationSubStage.ADVANCED
                    }
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default PipelineItemNavigation;
