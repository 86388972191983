import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
} from '../../main/theme';
import NavItem from '../Navigation/NavItem';

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const NavigationAccordion = styled(StyledAccordion)<{
  $lightTheme?: boolean;
}>`
  background: ${({ theme, $lightTheme }) =>
    $lightTheme
      ? theme.colors.system.offWhite
      : theme.colors.system.darkBlueShadow};
  border: unset;
  border-radius: unset;
  min-width: 100%;
`;

export const NavigationAccordionTitle = styled(StyledAccordionTitle)<{
  $lightTheme?: boolean;
}>`
  height: 46px;
  border-bottom: 1px solid
    ${({ theme, $lightTheme }) =>
      $lightTheme
        ? theme.colors.system.grey
        : theme.colors.system.darkOffBlack};
`;

export const NavigationAccordionContent = styled(StyledAccordionContent)`
  padding: 0 !important;
  border: unset;

  > div {
    > button {
      width: 100%;
    }
  }
`;

export const NavigationNavItem = styled(NavItem)`
  > svg {
    margin-right: 0;
  }

  > p {
    line-height: 0;
  }
`;

export const NavigationAccordionHeader = styled.div`
  width: 100%;
  display: flex;
`;
