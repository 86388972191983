import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faClipboardCheck,
  faCog,
  faInfoCircle,
  faShieldAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useList from '../../hooks/list/UseList';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import {
  updateActiveListStage,
  updateActiveListSubStage,
} from '../../store/list-stage';
import {
  ListConfigureSubStage,
  ListCreateSubStage,
  ListStage,
  ListSubStages,
} from '../../store/list-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [ListCreateSubStage.NAME]: faInfoCircle,
  [ListCreateSubStage.VALUES]: faClipboardCheck,
};
const ListItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { listAccessLevel, list } = useList();
  const themeContext = useContext(ThemeContext);

  const canManage = listAccessLevel === Enums.AccessLevel.MANAGE;

  const listStageState = useSelector((state: RootState) => state.listStage);
  const activeStage = listStageState.activeStage;
  const activeSubStage = listStageState.stages[activeStage].activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [ListStage.CONFIGURATION]: true });

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`list-back`}
        onClick={() => history.push(`${RouteName.DATASETS}${RouteName.LISTS}`)}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Back To Lists'} />
      </BackNavigationItem>

      {!canManage && (
        <NavItem
          key={`list-stage-${ListCreateSubStage.VALUES}`}
          onClick={() => {
            dispatch(updateActiveListStage(ListStage.CREATION));
            dispatch(updateActiveListSubStage(ListCreateSubStage.VALUES));
          }}
          isActive={activeSubStage === ListCreateSubStage.VALUES}
        >
          <FontAwesomeIcon
            icon={NavIcons[ListCreateSubStage.VALUES]}
            color={defaultTheme.colors.system.white}
          />

          <NavTitle
            text={
              listStageState.stages[ListStage.CREATION].subStages.find(
                (s) => s.subStage === ListCreateSubStage.VALUES,
              )?.title || ''
            }
          />
        </NavItem>
      )}

      {canManage &&
        Object.values(listStageState.stages[ListStage.CREATION].subStages).map(
          (subStage) => {
            const list = subStage.subStage as ListSubStages;
            return (
              <NavItem
                key={`list-stage-${list}`}
                onClick={() => {
                  dispatch(updateActiveListStage(ListStage.CREATION));
                  dispatch(updateActiveListSubStage(list));
                }}
                isActive={activeSubStage === list}
              >
                <FontAwesomeIcon
                  icon={NavIcons[list]}
                  color={defaultTheme.colors.system.white}
                />

                <NavTitle text={subStage.title} />
              </NavItem>
            );
          },
        )}

      {/* Configuration accordion nav */}
      {list?._id && canManage && (
        <NavigationAccordion>
          <div key={`${ListStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={!accordionGroups[ListStage.CONFIGURATION]}
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [ListStage.CONFIGURATION]:
                    !accordionGroups[ListStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle text={ListStage.CONFIGURATION || 'Config'} />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[ListStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={!accordionGroups[ListStage.CONFIGURATION]}
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(updateActiveListStage(ListStage.CONFIGURATION));
                    dispatch(
                      updateActiveListSubStage(ListConfigureSubStage.ACCESS),
                    );
                  }}
                  isActive={activeSubStage === ListConfigureSubStage.ACCESS}
                >
                  <FontAwesomeIcon
                    icon={faShieldAlt}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={ListConfigureSubStage.ACCESS}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(updateActiveListStage(ListStage.CONFIGURATION));
                    dispatch(
                      updateActiveListSubStage(ListConfigureSubStage.ADVANCED),
                    );
                  }}
                  isActive={activeSubStage === ListConfigureSubStage.ADVANCED}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={ListConfigureSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default ListItemNavigation;
