import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
} from '../../main/theme';

export const CronContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};

  .c-host {
    width: 700px;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.system.grey};
    border-radius: ${({ theme }) => theme.borders.radius};
    select {
      border-radius: ${({ theme }) => theme.borders.radius};
      border: 1px solid ${({ theme }) => theme.colors.system.darkGrey};
    }
    .nav-tabs {
      padding: 0;
      flex-direction: row;
      display: flex;
      margin: 0;
    }
    .c-tab-content {
      padding: ${({ theme }) => theme.padding.large};
    }

    .nav-link {
      background-color: ${({ theme }) => theme.colors.system.offWhite};
      padding: ${({ theme }) => theme.padding.standard};
      flex-grow: 1;
      border: 1px solid ${({ theme }) => theme.colors.system.grey};
      cursor: pointer;
    }
    .active {
      font-weight: bold;
      background-color: ${({ theme }) => theme.colors.system.white};
    }
    .c-every-check,
    .c-every-weekday-check {
      margin-top: ${({ theme }) => theme.margin.standard};
      margin-bottom: ${({ theme }) => theme.margin.standard};
    }
    input {
      margin-right: ${({ theme }) => theme.margin.small};
    }
    .c-segment,
    .c {
      margin-bottom: ${({ theme }) => theme.margin.standard};
      select {
        margin-left: ${({ theme }) => theme.margin.standard};
        margin-right: ${({ theme }) => theme.margin.standard};
      }
    }
    .form-inline {
      display: flex;
    }
    .c-and-list,
    .c-and-monthday-list,
    .c-and-weekday-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: ${({ theme }) => theme.margin.standard};
      margin-bottom: ${({ theme }) => theme.margin.standard};
      .col-1 {
        margin-top: ${({ theme }) => theme.margin.small};
        margin-bottom: ${({ theme }) => theme.margin.small};
        max-width: 13.333333%;
        flex: 0 0 13.333333%;

        label {
          padding: ${({ theme }) => theme.padding.small};
        }
      }
      .col-2 {
        margin-top: ${({ theme }) => theme.margin.small};
        margin-bottom: ${({ theme }) => theme.margin.small};
        label {
          padding: ${({ theme }) => theme.padding.small};
        }
      }
    }
    .c-and-monthday-item-field,
    .c-and-item-field {
      margin: 0;
    }

    .c-tab-content[tab-name='MINUTES'] {
      .c-every-check,
      .c-range {
        display: none;
      }

      .c-increment {
        .c-increment-from {
          display: none;
        }

        .c-increment-every {
          option[value='1'],
          option[value='2'],
          option[value='3'],
          option[value='4'],
          option[value='5'],
          option[value='6'],
          option[value='7'],
          option[value='8'],
          option[value='9'],
          option[value='10'],
          option[value='11'],
          option[value='12'],
          option[value='13'],
          option[value='14'],
          option[value='16'],
          option[value='17'],
          option[value='18'],
          option[value='19'],
          option[value='20'],
          option[value='21'],
          option[value='22'],
          option[value='23'],
          option[value='24'],
          option[value='25'],
          option[value='26'],
          option[value='27'],
          option[value='28'],
          option[value='29'],
          option[value='31'],
          option[value='32'],
          option[value='33'],
          option[value='34'],
          option[value='35'],
          option[value='36'],
          option[value='37'],
          option[value='38'],
          option[value='39'],
          option[value='40'],
          option[value='41'],
          option[value='42'],
          option[value='43'],
          option[value='43'],
          option[value='44'],
          option[value='45'],
          option[value='46'],
          option[value='47'],
          option[value='48'],
          option[value='49'],
          option[value='50'],
          option[value='51'],
          option[value='52'],
          option[value='53'],
          option[value='54'],
          option[value='55'],
          option[value='56'],
          option[value='57'],
          option[value='58'],
          option[value='59'],
          option[value='60'] {
            display: none;
          }
        }
      }

      .c-and-list {
        .c-and-item[item-value='1'],
        .c-and-item[item-value='2'],
        .c-and-item[item-value='3'],
        .c-and-item[item-value='4'],
        .c-and-item[item-value='5'],
        .c-and-item[item-value='6'],
        .c-and-item[item-value='7'],
        .c-and-item[item-value='8'],
        .c-and-item[item-value='9'],
        .c-and-item[item-value='10'],
        .c-and-item[item-value='11'],
        .c-and-item[item-value='12'],
        .c-and-item[item-value='13'],
        .c-and-item[item-value='14'],
        .c-and-item[item-value='16'],
        .c-and-item[item-value='17'],
        .c-and-item[item-value='18'],
        .c-and-item[item-value='19'],
        .c-and-item[item-value='20'],
        .c-and-item[item-value='21'],
        .c-and-item[item-value='22'],
        .c-and-item[item-value='23'],
        .c-and-item[item-value='24'],
        .c-and-item[item-value='25'],
        .c-and-item[item-value='26'],
        .c-and-item[item-value='27'],
        .c-and-item[item-value='28'],
        .c-and-item[item-value='29'],
        .c-and-item[item-value='31'],
        .c-and-item[item-value='32'],
        .c-and-item[item-value='33'],
        .c-and-item[item-value='34'],
        .c-and-item[item-value='35'],
        .c-and-item[item-value='36'],
        .c-and-item[item-value='37'],
        .c-and-item[item-value='38'],
        .c-and-item[item-value='39'],
        .c-and-item[item-value='40'],
        .c-and-item[item-value='41'],
        .c-and-item[item-value='42'],
        .c-and-item[item-value='43'],
        .c-and-item[item-value='43'],
        .c-and-item[item-value='44'],
        .c-and-item[item-value='46'],
        .c-and-item[item-value='47'],
        .c-and-item[item-value='48'],
        .c-and-item[item-value='49'],
        .c-and-item[item-value='50'],
        .c-and-item[item-value='51'],
        .c-and-item[item-value='52'],
        .c-and-item[item-value='53'],
        .c-and-item[item-value='54'],
        .c-and-item[item-value='55'],
        .c-and-item[item-value='56'],
        .c-and-item[item-value='57'],
        .c-and-item[item-value='58'],
        .c-and-item[item-value='59'] {
          display: none;
        }
      }
    }

    .c-tab-content[tab-name='HOURS'] {
      .c-range {
        display: none;
      }

      .c-increment {
        .c-increment-from {
          display: none;
        }

        .c-increment-every {
          option[value='5'],
          option[value='7'],
          option[value='9'],
          option[value='10'],
          option[value='11'],
          option[value='13'],
          option[value='14'],
          option[value='15'],
          option[value='16'],
          option[value='17'],
          option[value='18'],
          option[value='19'],
          option[value='20'],
          option[value='21'],
          option[value='22'],
          option[value='23'],
          option[value='24'] {
            display: none;
          }
        }
      }

      .c-and-list {
        .c-and-item[item-value='24'],
        .c-and-item[item-value='25'],
        .c-and-item[item-value='26'],
        .c-and-item[item-value='27'],
        .c-and-item[item-value='28'],
        .c-and-item[item-value='29'],
        .c-and-item[item-value='31'],
        .c-and-item[item-value='32'],
        .c-and-item[item-value='33'],
        .c-and-item[item-value='34'],
        .c-and-item[item-value='35'],
        .c-and-item[item-value='36'],
        .c-and-item[item-value='37'],
        .c-and-item[item-value='38'],
        .c-and-item[item-value='39'],
        .c-and-item[item-value='40'],
        .c-and-item[item-value='41'],
        .c-and-item[item-value='42'],
        .c-and-item[item-value='43'],
        .c-and-item[item-value='43'],
        .c-and-item[item-value='44'],
        .c-and-item[item-value='46'],
        .c-and-item[item-value='47'],
        .c-and-item[item-value='48'],
        .c-and-item[item-value='49'],
        .c-and-item[item-value='50'],
        .c-and-item[item-value='51'],
        .c-and-item[item-value='52'],
        .c-and-item[item-value='53'],
        .c-and-item[item-value='54'],
        .c-and-item[item-value='55'],
        .c-and-item[item-value='56'],
        .c-and-item[item-value='57'],
        .c-and-item[item-value='58'],
        .c-and-item[item-value='59'] {
          display: none;
        }
      }
    }

    .c-tab-content[tab-name='DAY'] {
      .c-range {
        display: none;
      }

      .c-last-monthday,
      .c-last-weekday,
      .c-last-nth,
      .c-day-before-end,
      .c-nearest,
      .c-nth,
      .c-increment-weekday,
      .c-increment-monthday {
        display: none;
      }
    }
  }
`;

export const GroupAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 100% !important;
`;

export const AccordionTitle = styled(StyledAccordionTitle)`
  & :hover {
    cursor: default;
  }
`;

export const AccordionTitleWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin-right: ${({ theme }) => theme.margin.standard};
    margin-bottom: 0;
  }
`;

export const AccordionTitleButtons = styled.div`
  display: flex;

  > div,
  > button {
    margin-left: ${({ theme }) => theme.margin.standard};

    & :hover {
      cursor: pointer;
    }
  }
`;

export const AccordionContentJobWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: ${({ theme }) => theme.margin.xxlarge};

  text-align: center;
`;

export const AccordionContentInfoWrapper = styled.div<{
  removePadding?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: ${({ theme }) => theme.margin.xxlarge};
  padding: ${({ theme, removePadding }) =>
    removePadding ? 0 : theme.padding.standard};

  text-align: center;
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const MappingFieldWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const MappingField = styled.div`
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.standard};
`;

export const LoadingIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const PipeLineInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.system.offWhite};
`;

export const PipeLineInfoRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  pre {
    margin-top: 0;
  }
`;

export const EventAccordion = styled(StyledAccordion)`
  width: 100%;
`;

export const PipelineIcon = styled(FontAwesomeIcon)<{
  removeOutline?: boolean;
}>`
  border: ${({ removeOutline }) =>
    removeOutline
      ? 'none'
      : ` 1px solid ${({ theme }) => theme.colors.system.grey}`};
  background: ${({ theme }) => theme.colors.system.white};
  padding: ${({ removeOutline }) => (removeOutline ? 0 : '8px')};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.margin.standard};
`;

export const PipelineAccordionContentWrapper = styled(StyledAccordionContent)<{
  backgroundColor: string;
}>`
  padding: 0 !important;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.system.white};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;

export const AccordionContentWrapper = styled(StyledAccordionContent)<{
  backgroundColor: string;
}>`
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.system.white};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;
