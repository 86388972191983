import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
`;
