import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { StyledText } from '../../main/theme';

interface ResultsSummaryProps {
  total?: number;
  completed?: number;
  failed?: number;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin: ${({ theme }) => `0 ${theme.margin.standard} ${theme.margin.large}`};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 ${({ theme }) => theme.margin.standard};
  text-align: center;
`;

const Header = styled(StyledText)<{ color?: string }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  ${({ color }) => color && `color: ${color}`};
`;

const ResultSummary: FC<ResultsSummaryProps> = ({
  total = 0,
  completed = 0,
  failed = 0,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      <Item>
        <Header>Total Rows</Header>
        <StyledText>{total}</StyledText>
      </Item>
      <Item>
        <Header color={themeContext.colors.general.green}>
          Completed Rows
        </Header>
        <StyledText>{completed}</StyledText>
      </Item>
      <Item>
        <Header color={themeContext.colors.general.red}>Failed Rows</Header>
        <StyledText>{failed}</StyledText>
      </Item>
    </Wrapper>
  );
};

export default ResultSummary;
