import { Enums, Interfaces } from '@configur-tech/upit-core-types';

import { startCase } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { HOUR_IDENTIFIER } from '../../../../const/DateConst';
import { RouteName } from '../../../../enums';
import {
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledText,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getLookupFieldType } from '../../../../util/lookup/GetLookupFieldType';

const QUERY_ROUTE = 'query';
const isQuery =
  location.pathname.includes(RouteName.PROJECT_ITEM) ||
  location.pathname.includes(QUERY_ROUTE);

enum DurationUnit {
  YEARS = 'years',
  QUARTERS = 'quarters',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  MILLISECONDS = 'milliseconds',
}

const HELPER_DURATION_UNIT_WITH_TIME = [
  DurationUnit.HOURS,
  DurationUnit.MINUTES,
  DurationUnit.SECONDS,
  DurationUnit.MILLISECONDS,
];

interface DateDiffProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  loaded: boolean;
}

const timeUnitOptions = Object.entries(DurationUnit).map(([key, val], i) => {
  const type = startCase(key.toLowerCase());

  return {
    key: `time-unit-${type}-${i}`,
    text: type,
    value: val,
  };
});

const DateDiff: FC<DateDiffProps> = ({
  field,
  schema,
  setDataValidation,
  setSuperColumnValid,
  loaded,
}) => {
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [columnNameOptions, setColumnNameOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [selectedColumnNameMinuend, setSelectedColumnNameMinuend] =
    useState<string>();
  const [
    selectedColumnNameSubtrahendField,
    setSelectedColumnNameSubtrahendField,
  ] = useState<string>();

  const [timeUnit, setTimeUnit] = useState<DurationUnit>();

  const resetColumnNames = () => {
    setSelectedColumnNameMinuend('');
    setSelectedColumnNameSubtrahendField('');
  };

  // Set column name options
  useEffect(() => {
    if (!schema || !loaded) {
      return;
    }

    const extractTypeRequiresTime =
      timeUnit && HELPER_DURATION_UNIT_WITH_TIME.includes(timeUnit);

    setColumnNameOptions(
      schema
        .filter((s) =>
          extractTypeRequiresTime
            ? s.dataValidation?.dataValidationType &&
              s.dataValidation?.dataValidationType ===
                Enums.ValueDataType.DATE &&
              s.dataValidation?.constraints?.format
                ?.toLowerCase()
                .includes(HOUR_IDENTIFIER)
            : s.dataValidation?.dataValidationType &&
              [Enums.ValueDataType.DATE].includes(
                getLookupFieldType(s, datasetMetas) ||
                  s.dataValidation?.dataValidationType,
              ),
        )
        .map((schemaField, i) => {
          return {
            key: `column-name-${schemaField.name}-${i}`,
            value: isQuery
              ? schemaField.name
              : schemaField.fieldId || schemaField.name,
            text: schemaField.name,
          };
        }),
    );
  }, [datasetMetas, loaded, schema, timeUnit]);

  // Set values if updating existing
  useEffect(() => {
    if (!field) {
      return;
    }

    const opts = field.dataValidation?.dateConversion as Interfaces.DateDiffOp;

    if (opts) {
      setSelectedColumnNameMinuend(opts.params.minuendField);
      setSelectedColumnNameSubtrahendField(opts.params.subtrahendField);
      setTimeUnit(opts.params.timeUnit);
    }
  }, [field]);

  useEffect(() => {
    if (
      !timeUnit ||
      !selectedColumnNameMinuend ||
      !selectedColumnNameSubtrahendField
    ) {
      setSuperColumnValid(false);
      return;
    }

    setSuperColumnValid(true);
    setDataValidation({
      dataValidationType: Enums.ValueDataType.DATE_CONVERSION,
      dateConversion: {
        type: Enums.DateConversionOperation.DATE_DIFF,
        params: {
          minuendField: selectedColumnNameMinuend,
          subtrahendField: selectedColumnNameSubtrahendField,
          timeUnit: timeUnit,
        },
      },
      formatting: field.dataValidation?.formatting,
    });
  }, [
    timeUnit,
    selectedColumnNameMinuend,
    selectedColumnNameSubtrahendField,
    setSuperColumnValid,
    setDataValidation,
    field.dataValidation?.formatting,
  ]);

  return (
    <>
      <StyledBodySubHeader>Date Difference</StyledBodySubHeader>

      <StyledText>Select a time duration unit.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        placeholder={'Select a time duration unit'}
        options={timeUnitOptions}
        selection
        value={timeUnit}
        onChange={(e, data) => {
          resetColumnNames();
          setTimeUnit(data.value);
        }}
        style={{ marginTop: 0 }}
      />
      <StyledText>Select initial date column.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select initial date column'}
        options={columnNameOptions}
        selection
        search
        value={selectedColumnNameMinuend}
        onChange={(e, data) => setSelectedColumnNameMinuend(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />

      <StyledText>Select date column to subtract from initial date.</StyledText>

      <StyledDropdownUltraWide
        selectOnBlur={false}
        loading={!loaded}
        placeholder={'Select second date column'}
        options={columnNameOptions.filter(
          (o) => o.value !== selectedColumnNameMinuend,
        )}
        selection
        search
        value={selectedColumnNameSubtrahendField}
        onChange={(e, data) => setSelectedColumnNameSubtrahendField(data.value)}
        style={{ marginTop: 0 }}
        upward={true}
      />
    </>
  );
};

export default DateDiff;
