import { AccordionContent } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledSubHeader, StyledText } from '../../../main/theme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 90%;
  max-width: 800px;

  margin: ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.xxlarge};

  padding: ${({ theme }) => theme.padding.xlarge};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 150px;
  overflow-wrap: break-word;

  margin: 0 ${({ theme }) => theme.margin.standard};
  text-align: center;
`;

export const ConfirmHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h3};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const ConfirmSubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  margin-bottom: ${({ theme }) => theme.margin.standard};

  > h2 {
    margin: 0;
  }
`;

export const HeaderResult = styled(StyledSubHeader)`
  color: ${({ theme }) => theme.colors.general.green};
  margin: 0 0 0 ${({ theme }) => theme.margin.standard};
`;

export const StageColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.system.midGrey};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.large};
  justify-content: center;
  flex: 1;
  > div {
    justify-content: center;
    max-width: 100%;
  }
  > p {
    text-align: center;
  }
`;

export const StageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.margin.xxxlarge};
`;

export const MergeColumnSelectorWrapper = styled.div<{
  wrapperMargin?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${({ theme, wrapperMargin }) =>
    wrapperMargin ? wrapperMargin : theme.margin.xxlarge};
`;

export const OptionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90%;
  max-width: 900px;

  margin-top: ${({ theme }) => theme.margin.xxlarge};
  padding: ${({ theme }) => theme.padding.xlarge};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const OptionalFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionalField = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : '40%')};

  text-align: center;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
`;

export const StyledAccordionContent = styled(AccordionContent)`
  background-color: ${({ theme }) => theme.colors.system.white};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
