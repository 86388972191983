import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  faChevronDown,
  faChevronUp,
  faList,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledAccordion,
  StyledAccordionRequirement,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../../Modal/pipeline/styled';
import DynamicFieldInput from './DynamicField';

const FIELD_KEY = 'field';

interface CustomActionsAccordionProps {
  onChange: (params: Interfaces.CustomConfigParam) => void;
  paramsData: Interfaces.CustomConfigParam[];
  dynamicFields: Interfaces.SchemaField[];
  customActionParams: Interfaces.EndpointParam[];
}

const CustomActionsAccordion: FC<CustomActionsAccordionProps> = ({
  onChange,
  paramsData,
  dynamicFields,
  customActionParams,
}) => {
  const themeContext = useContext(ThemeContext);

  const [activeAccordionIndices, setActiveAccordionIndices] = useState<
    number[]
  >([1]);

  const [customActionParamOptions, setCustomActionParamOptions] =
    useState<DropdownItemProps[]>();

  useEffect(() => {
    setCustomActionParamOptions(
      customActionParams.map((param) => ({
        value: param.field,
        key: param.field,
        text: param.field,
        description: param.constraints?.isRequired ? 'Required' : '',
      })),
    );
  }, [customActionParams]);

  // Add additional param row
  const handleAddRow = () => {
    const cloned = cloneDeep(paramsData);
    cloned.push({
      field: '',
      value: '',
      type: [Enums.ValueDataType.TEXT],
    });

    onChange(cloned);
  };

  // Remove param row
  const handleRemoveRow = (rowIndex: number) => {
    const cloned = cloneDeep(paramsData);
    cloned.splice(rowIndex, 1);

    onChange(cloned);
  };

  // Update param field
  const handleFieldChange = (
    rowIndex: number,
    field: string,
    value: unknown,
  ) => {
    const cloned = cloneDeep(paramsData);

    if (field !== FIELD_KEY) {
      dynamicFieldOptions?.map((d) => d.key).includes(value as string)
        ? (cloned[rowIndex][field] = {
            type: Enums.DynamicConditionalType.FIELD,
            field: value,
          })
        : (cloned[rowIndex][field] = {
            ...cloned[rowIndex][field],
            type:
              customActionParams.find(
                (param) => param.field === cloned[rowIndex].field,
              )?.type?.[0] === Enums.ValueDataType.DATE &&
              Object.values(Enums.DynamicDateValue).includes(
                value as Enums.DynamicDateValue,
              )
                ? Enums.DynamicConditionalType.DATE
                : Enums.DynamicConditionalType.CONSTANT,
            value: value,
          });
    } else {
      cloned[rowIndex] = {
        ...cloned[rowIndex],
        ...customActionParams.find((action) => action.field === value),
      };
    }

    onChange(cloned);
  };

  const index = 0;
  const accordionIndex = index + 1;

  const dynamicFieldOptions = dynamicFields?.map((m) => {
    return {
      key: m.fieldId || m.field,
      text: `${m.field} value`,
      value: m.fieldId || m.field,
    };
  });

  // Toggle dynamic field option
  const handleToggleFieldConditionalValue = (
    active: boolean,
    rowIndex: number,
  ) => {
    const cloned = cloneDeep(paramsData);

    if (!active) {
      delete cloned[rowIndex].value.field;
    } else {
      cloned[rowIndex].value = {
        type: Enums.DynamicConditionalType.FIELD,
        field: dynamicFieldOptions[0].key,
      };
    }

    onChange(cloned);
  };

  return (
    <StyledAccordion
      style={{
        marginTop: themeContext.margin.xxlarge,
        backgroundColor: themeContext.colors.system.offWhite,
      }}
      fluid
    >
      <StyledAccordionTitle
        active={activeAccordionIndices.includes(accordionIndex)}
        index={accordionIndex}
        onClick={() => {
          if (activeAccordionIndices.includes(accordionIndex)) {
            setActiveAccordionIndices((indices) =>
              indices.filter((index) => index !== accordionIndex),
            );
          } else {
            setActiveAccordionIndices((indices) => [
              ...indices,
              accordionIndex,
            ]);
          }
        }}
      >
        <StyledSubHeader>Action Parameters</StyledSubHeader>

        <div>
          {customActionParams.some(
            (param) => param.constraints?.isRequired,
          ) && (
            <StyledAccordionRequirement>Required</StyledAccordionRequirement>
          )}

          <FontAwesomeIcon
            icon={
              activeAccordionIndices.includes(accordionIndex)
                ? faChevronUp
                : faChevronDown
            }
            color={defaultTheme.colors.system.offBlack}
          />
        </div>
      </StyledAccordionTitle>
      <SC.StyledAccordionContent
        active={activeAccordionIndices.includes(accordionIndex)}
        style={{
          backgroundColor: themeContext.colors.system.white,
        }}
      >
        <SC.StyledInputColumn>
          <SC.InputContainer>
            <StyledText
              style={{
                marginBottom: themeContext.margin.xxlarge,
              }}
            >
              If this endpoint requires additional action parameters, they can
              be added here.
            </StyledText>

            <FeatureButton
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={`Add Action Parameter`}
              action={handleAddRow}
              style={{
                marginBottom: themeContext.margin.large,
              }}
            />

            {paramsData?.length > 0 && (
              <SC.InputRowContainer>
                {paramsData.map((row, index) => {
                  const showDynamicField = !!(
                    row.value as Interfaces.FieldConditionalValue
                  )?.field;

                  return (
                    <SC.InputWrapper key={`inputs-${index}`}>
                      <SC.InputContainer>
                        <StyledBodySubHeader>Field Name</StyledBodySubHeader>
                        <StyledDropdownWide
                          search
                          upward={true}
                          selectOnBlur={false}
                          placeholder={'Select a field'}
                          options={
                            customActionParamOptions?.filter(
                              (param) =>
                                row.field === param.value ||
                                !paramsData.find(
                                  (data) => data.field === param.value,
                                ),
                            ) || []
                          }
                          selection
                          value={row.field}
                          onChange={(e, { value }) =>
                            handleFieldChange(index, FIELD_KEY, value)
                          }
                        />
                      </SC.InputContainer>

                      <DynamicFieldInput
                        field={row}
                        handleFieldChange={handleFieldChange}
                        showDynamicField={showDynamicField}
                        paramType={FIELD_KEY}
                        index={index}
                        value={row.value as Interfaces.ConstantConditionalValue}
                        dynamicFields={dynamicFields}
                      />

                      <FeatureButton
                        isDisabled={!dynamicFieldOptions?.length}
                        isActive={showDynamicField}
                        action={() =>
                          handleToggleFieldConditionalValue(
                            !showDynamicField,
                            index,
                          )
                        }
                        size={36}
                        height={34}
                        color={themeContext.colors.general.purple}
                        icon={
                          <FontAwesomeIcon
                            icon={faList}
                            color={defaultTheme.colors.system.white}
                          />
                        }
                        style={{
                          marginRight: defaultTheme.margin.standard,
                        }}
                      />

                      <FeatureButton
                        action={() => handleRemoveRow(index)}
                        size={34}
                        height={34}
                        color={themeContext.colors.general.red}
                        icon={
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color={defaultTheme.colors.system.white}
                          />
                        }
                      />
                    </SC.InputWrapper>
                  );
                })}
              </SC.InputRowContainer>
            )}
          </SC.InputContainer>
        </SC.StyledInputColumn>
      </SC.StyledAccordionContent>
    </StyledAccordion>
  );
};

export default CustomActionsAccordion;
