import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteName } from '../enums';
import CMSPage from '../pages/CMS/CMSPage';
import PortalAPIsPage from '../pages/Portal/PortalAPIsPage';
import PortalDataViewsPage from '../pages/Portal/PortalDataViewsPage';
import PortalFormsPage from '../pages/Portal/PortalFormsPage';
import PortalPage from '../pages/Portal/PortalPage';
import PortalQueriesPage from '../pages/Portal/PortalQueriesPage';
import PortalUserWelcomePage from '../pages/PortalUserWelcome/PortalUserWelcomePage';
import UserItemPage from '../pages/UserItem/UserItemPage';

const PortalUserRoutes: FC = () => {
  return (
    <Switch>
      {/*Portals*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId`}
        exact={true}
        component={PortalPage}
      />
      {/*Portals - CMS List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms`}
        exact={true}
        component={PortalDataViewsPage}
      />
      {/*Portals - CMS View*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Chart*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId${RouteName.CMS}/:cmsId/:groupId${RouteName.CHART_ITEM}/:chartId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Dataset*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId/:groupId/dataset/:datasetMetaId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portals - CMS View - Aggregations*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/cms/:cmsId/:groupId/query/:aggregationId`}
        exact={true}
        component={CMSPage}
      />
      {/*Portal - Form List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/form`}
        exact={true}
        component={PortalFormsPage}
      />
      {/*Portal - API List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/api`}
        exact={true}
        component={PortalAPIsPage}
      />
      {/*Portal - Query List*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/query`}
        exact={true}
        component={PortalQueriesPage}
      />
      {/*Portal - Query Builder*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/query/:aggregationId`}
        exact={true}
        component={PortalQueriesPage}
      />
      {/*Portal - User Profile*/}
      <Route
        path={`${RouteName.PORTAL}/:portalId/user`}
        exact={true}
        component={UserItemPage}
      />
      {/*Portal User Welcome - User Profile*/}
      <Route
        path={`${RouteName.USER_ITEM}`}
        exact={true}
        component={UserItemPage}
      />
      {/*Default*/}
      <Route path={RouteName.WELCOME} component={PortalUserWelcomePage} />
    </Switch>
  );
};

export default PortalUserRoutes;
