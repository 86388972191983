import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import DataSample from '../../components/DataSample/DataSample';
import EntityFilter from '../../components/EntityFilter/EntityFilter';
import FeatureButton, {
  FeatureButtonSize,
} from '../../components/FeatureButton/FeatureButton';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { EntityType } from '../../enums/EntityType';
import { RouteName } from '../../enums/RouteName';
import useList from '../../hooks/list/UseList';
import { SampleDataRow } from '../../interfaces/SampleData';
import Page from '../../main/Page';
import PageContainer from '../../main/PageContainer';
import { StageBodyText } from '../../main/theme';
import { ListItemOutput } from '../../services/list/ListService';
import { resetList } from '../../store/list';
import {
  resetStagesAndSubStages,
  updateActiveListStage,
  updateActiveListSubStage,
} from '../../store/list-stage';
import {
  ListCreateSubStage,
  ListStage,
} from '../../store/list-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';

const ID_FIELD = '_id';
const NAME_FIELD = 'List Name';
const VALUE_COUNT_FIELD = 'Option Count';

const TABLE_SCHEMA = [{ name: NAME_FIELD }, { name: VALUE_COUNT_FIELD }];

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const ListsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { getLists } = useList();

  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  const [isLoading, setIsLoading] = useState<boolean>();
  const [searchVal, setSearchVal] = useState<string>();
  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [filteredLists, setFilteredLists] = useState<ListItemOutput[]>();

  useEffect(() => {
    (async () => {
      await getLists();
    })();
  }, [getLists]);

  useEffect(() => {
    if (filteredLists) {
      const tableData: SampleDataRow[] = [];

      filteredLists.map((list) => {
        const ent = list.entity;
        tableData.push({
          [ID_FIELD]: { value: ent._id },
          [NAME_FIELD]: { value: ent.name },
          [VALUE_COUNT_FIELD]: {
            value: ent?.values?.length.toString() || '0',
          },
        });
      });

      setTableData(tableData);
    }
  }, [filteredLists, listsState]);

  // Handle loading state
  useEffect(() => {
    if (!isLoading && listsState.loading) {
      setIsLoading(true);
    }
    if (isLoading && !listsState.loading) {
      setIsLoading(false);
    }
  }, [isLoading, listsState.loading]);

  useEffect(() => {
    if (lists.length) {
      setFilteredLists(
        lists.filter((t) =>
          searchVal
            ? t.entity.name.toLowerCase().includes(searchVal.toLowerCase())
            : true,
        ),
      );
    }
  }, [searchVal, lists]);

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.LISTS} />
      <PageContainer>
        <PageFeatureHeader
          pageHeader={'View Lists'}
          image={getDefaultAvatar(EntityType.DATASET)}
          title={'Make A List, Check It Twice'}
        />

        <Page>
          <PageInner>
            <StageBodyText>
              Lists can be used to validate columns in your dataset and allow
              users to select values from predefined options.
            </StageBodyText>

            {lists.length > 0 && (
              <>
                <StageBodyText>
                  Tap the button below to add a new list or click on a row in
                  the table below if you'd like to manage one of your existing
                  lists.
                </StageBodyText>
              </>
            )}

            {lists.length === 0 && (
              <>
                <StageBodyText>
                  Just tap the button below and we'll start building one.
                </StageBodyText>
              </>
            )}

            <FeatureButton
              action={() => {
                // Reset list and stage data
                dispatch(resetStagesAndSubStages());
                dispatch(resetList());
                history.push(`${RouteName.DATASETS}${RouteName.LIST_ITEM}`);
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Create new list'}
              containerStyle={{ marginBottom: themeContext.margin.large }}
            />

            {lists.length > 0 && (
              <>
                <EntityFilter
                  baseRoute={RouteName.LISTS}
                  title={'Search Lists'}
                  inputPlaceholder={'Enter a list name'}
                  onSearchValueChange={(value) => setSearchVal(value)}
                  totalResultCount={lists?.length}
                  filteredResultCount={filteredLists?.length || 0}
                  loading={isLoading}
                />
              </>
            )}

            <TableWrapper>
              <DataSample
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                clickableRows={{
                  valueField: ID_FIELD,
                  action: (listId: string) => {
                    dispatch(resetList());
                    dispatch(updateActiveListStage(ListStage.CREATION));
                    dispatch(
                      updateActiveListSubStage(ListCreateSubStage.VALUES),
                    );
                    history.push(
                      `${RouteName.DATASETS}${RouteName.LIST_ITEM}/${listId}`,
                    );
                  },
                }}
                iconMap={AvatarIconMap}
                loading={isLoading}
              />
            </TableWrapper>
          </PageInner>
        </Page>
      </PageContainer>
    </>
  );
};

export default ListsPage;
