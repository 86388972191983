import {
  faChevronDown,
  faChevronUp,
  faListUl,
  faPlusSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IntegrationCategoryTags, RouteName } from '../../enums';
import getIntegrationCategoryIcon from '../../util/integration-category-icon/IntegrationCategoryIcon';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import * as SC from './styled';

const SEARCH_PARAM_KEY = 'search';
const SEARCH_ACTIVE_VAL = 'Active';

const NavIcons = {
  [RouteName.INTEGRATION_CENTRE]: faListUl,
  [RouteName.INTEGRATION_ITEM]: faPlusSquare,
};

const IntegrationCentreNavigation: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [RouteName.INTEGRATION_CENTRE]: false });

  const [searchCat, setSearchCat] = useState<string>();

  // Get Search Value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get(SEARCH_PARAM_KEY);
    setSearchCat(searchParams as string);
  }, [location.search]);

  return (
    <SC.NavigationItems>
      <NavItem
        key={`integration-centre-create`}
        onClick={() => {
          history.push(RouteName.INTEGRATION_ITEM);
        }}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={NavIcons[RouteName.INTEGRATION_ITEM]}
          color={themeContext.colors.system.white}
        />
        <NavTitle text={'Create Integration'} />
      </NavItem>

      {/* Categories accordion nav */}
      <SC.NavigationAccordion>
        <div key={`${RouteName.INTEGRATION_CENTRE}-0`}>
          <SC.NavigationAccordionTitle
            active={!accordionGroups[RouteName.INTEGRATION_CENTRE]}
            index={0}
            onClick={() =>
              setAccordionGroups({
                ...accordionGroups,
                [RouteName.INTEGRATION_CENTRE]:
                  !accordionGroups[RouteName.INTEGRATION_CENTRE],
              })
            }
          >
            <SC.NavigationAccordionHeader>
              <NavTitle text="Categories" />

              <FontAwesomeIcon
                icon={
                  !accordionGroups[RouteName.INTEGRATION_CENTRE]
                    ? faChevronUp
                    : faChevronDown
                }
                color={themeContext.colors.system.white}
              />
            </SC.NavigationAccordionHeader>
          </SC.NavigationAccordionTitle>

          <SC.NavigationAccordionContent
            active={!accordionGroups[RouteName.INTEGRATION_CENTRE]}
          >
            <div>
              <SC.NavigationNavItem
                key={`integration-centre-category-all`}
                onClick={() => {
                  history.push(RouteName.INTEGRATION_CENTRE);
                }}
                isActive={searchCat == undefined}
              >
                <NavTitle
                  text={'View all integrations'}
                  isActive={searchCat == undefined}
                />
              </SC.NavigationNavItem>

              <SC.NavigationNavItem
                key={`integration-centre-view-active`}
                onClick={() =>
                  history.push({
                    pathname: `${RouteName.INTEGRATION_CENTRE}`,
                    search: `search=${SEARCH_ACTIVE_VAL}`,
                  })
                }
                isActive={searchCat === SEARCH_ACTIVE_VAL}
              >
                <NavTitle text={'View Active Integrations'} />
              </SC.NavigationNavItem>

              {/* Loop through categories */}
              {Object.values(IntegrationCategoryTags).map((categoryName) => {
                return (
                  <SC.NavigationNavItem
                    key={`integration-centre-category-${categoryName}`}
                    onClick={() => {
                      history.push({
                        pathname: `${RouteName.INTEGRATION_CENTRE}`,
                        search: `search=${categoryName}`,
                      });
                    }}
                    isActive={searchCat === categoryName}
                  >
                    <FontAwesomeIcon
                      icon={getIntegrationCategoryIcon(categoryName)}
                      color={themeContext.colors.system.white}
                      style={{ marginRight: themeContext.margin.standard }}
                    />

                    <NavTitle
                      text={categoryName}
                      isActive={searchCat === categoryName}
                    />
                  </SC.NavigationNavItem>
                );
              })}
            </div>
          </SC.NavigationAccordionContent>
        </div>
      </SC.NavigationAccordion>
    </SC.NavigationItems>
  );
};

export default IntegrationCentreNavigation;
