import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useProject from '../../../../hooks/project/UseProject';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { ProjectItemOutput } from '../../../../services/project/ProjectService';
import { fetchProjectSuccess } from '../../../../store/project';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectCreationSubStage } from '../../../../store/project-stage/initial-state';
import { RootState } from '../../../../store/rootReducer';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../styled';

const NAME_FIELD = 'name';
const TAG_FIELD = 'tags';
const DESCRIPTION_FIELD = 'description';
const NEXT_STAGE = ProjectCreationSubStage.AVATAR;
const GROUP = 'group';

// Build Dropdown Options from Existing Tags
const buildProjectDropdownOptions = (
  tag: Interfaces.TagV1[],
): DropdownItemProps[] =>
  tag.map((d) => {
    return {
      key: d.value,
      text: d.value,
      value: d.value,
    };
  });

const ProjectItemNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, projectAccessLevel } = useProject();
  const projectsState = useSelector((state: RootState) => state.projects);
  const projects: ProjectItemOutput[] = projectsState.data.data;

  const [projectTagOptions, setProjectTagOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [projectName, setProjectName] = useState<string>();
  const [projectDescription, setProjectDescription] = useState<string>();
  const [projectTags, setProjectTags] = useState<Interfaces.TagV1[]>();

  // Set initial Project values
  useEffect(() => {
    setProjectName(project?.name);
    setProjectDescription(project?.description);
    setProjectTags(project?.tags);
  }, [project]);

  // Collate tags from existing Projects
  useEffect(() => {
    const projectTags = projects?.reduce((acc: Interfaces.TagV1[], project) => {
      if (!project.entity.tags) {
        return acc;
      }
      return [
        ...acc,
        ...project.entity.tags.filter(
          (tag) => !acc.find((t) => t.value === tag.value),
        ),
      ];
    }, []);

    setProjectTagOptions(buildProjectDropdownOptions(projectTags));
  }, [projects]);

  // Handle Adding Tags and Dropdown Formatting
  const handleTagAdd = (value: string) => {
    const cloned = cloneDeep(projectTagOptions);
    cloned.push({ key: value, value, text: value });
    setProjectTagOptions(cloned);
  };

  // Handle Tag Change and Correct formatting for Project
  const handleTagChange = (value) => {
    const formattedTags = value.map((tag) => {
      return { key: GROUP, value: tag.toString() };
    });
    setProjectTags(formattedTags);
  };

  const updateProjectDetails = () => {
    const cloned = cloneDeep(project);
    cloned[NAME_FIELD] = projectName;
    cloned[DESCRIPTION_FIELD] = projectDescription;
    cloned[TAG_FIELD] = projectTags;

    dispatch(
      fetchProjectSuccess({
        entity: cloned,
        accessLevel: projectAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StyledBodySubHeader>Project Name</StyledBodySubHeader>
          <StageBodyText>
            Enter an easily identifiable name for your new project.
          </StageBodyText>

          <StyledInput
            placeholder={'Enter your project name'}
            value={projectName || ''}
            onChange={(event, { value }) => setProjectName(value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Description</StyledBodySubHeader>
          <StageBodyText>
            Briefly, what are you trying to achieve with this project?
          </StageBodyText>
          <StyledTextArea
            minRows={5}
            maxRows={10}
            placeholder={'Enter a description'}
            value={projectDescription || ''}
            onChange={(event) => setProjectDescription(event.target.value)}
          />
          <StyledBodySubHeader
            style={{
              marginTop: themeContext.margin.large,
              marginBottom: themeContext.margin.small,
            }}
          >
            Tags
          </StyledBodySubHeader>
          <StyledDropdownWide
            style={{
              marginTop: themeContext.margin.small,
            }}
            selectOnBlur={false}
            placeholder={`Enter your tags`}
            noResultsMessage={'Type to add a new tag'}
            text={'Add tags to easily group and search your projects.'}
            search
            allowAdditions
            selection
            multiple
            options={projectTagOptions}
            value={projectTags?.map((tag) => tag.value) || []}
            onAddItem={(event, data) => handleTagAdd(data.value)}
            onChange={(e, { value }) => handleTagChange(value)}
          />
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={!projectName?.length}
            action={() => {
              updateProjectDetails();
              dispatch(updateActiveProjectSubStage(NEXT_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemNameStage;
