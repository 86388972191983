import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep, truncate } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checkbox } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import useChart from '../../../hooks/chart/UseChart';
import useCMSData from '../../../hooks/cms/useCMSData';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useFilter, {
  DynamicConditionalField,
} from '../../../hooks/filter/UseFilter';
import useQuery from '../../../hooks/query/UseQuery';
import useSiteWideBanner from '../../../hooks/sitewide-banner/useSitewideBanner';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledText,
} from '../../../main/theme';
import { GraphOutputWithDetails } from '../../../store/graph';
import { showModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../Modal/Modal';
import { LOCAL_STORAGE_TEMP_FILTERS_KEY } from '../../Modal/graph/GraphModal';
import {
  Trigger,
  friendlyOperator,
} from '../../NotificationAlert/FormattedNotificationContent';
import * as SC from './styled';

interface CMSFilterProps {
  type: EntityType.DATASET | EntityType.AGGREGATION | EntityType.CHART;
  filterKeys?: string[];
}

const TRUNCATE_LIMIT = 60;
const ROW_ID_FIELD = 'row_id';

const matchDropdownOptions = [
  {
    key: 'match-all',
    value: Enums.AggregationFilterOperator.AND,
    text: 'Match All Filter Groups',
  },
  {
    key: 'match-any',
    value: Enums.AggregationFilterOperator.OR,
    text: 'Match Any Filter Groups',
  },
];

const CMSFilter: FC<CMSFilterProps> = ({ type, filterKeys }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { datasetMeta, activeDataCollectionItem, getDatasetMetas } =
    useDatasetMeta();
  const { query } = useQuery();
  const { chart } = useChart();
  const { isSitewideBanner } = useSiteWideBanner();
  const {
    getDatasetFilters,
    deleteDatasetFilters,
    updateFilterGroup,
    checkForDeletedColumnsInFilter,
  } = useFilter();
  const { groupId } = useParams();
  const { selectedResource } = useCMSData();

  const [filters, setFilters] = useState<DynamicConditionalField[]>();

  const [entityId, setEntityId] = useState<string>();
  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();

  const [hasFilterParams, setHasFilterParams] = useState<boolean>();

  // Set Entity
  useEffect(() => {
    if (type) {
      setEntityId(
        type === EntityType.DATASET
          ? datasetMeta?._id
          : type === EntityType.AGGREGATION
          ? query?._id
          : chart?._id,
      );
    }
  }, [datasetMeta?._id, chart?._id, query?._id, type]);

  // Change of dataset, fetch new filters plus new lists maybe required
  useEffect(() => {
    if (entityId) {
      setEntitySchema([]);
    }
  }, [entityId]);

  // Set Entity Schema
  useEffect(() => {
    if (!entitySchema?.length) {
      if (type === EntityType.CHART && chart?.graphs) {
        const combinedSchemaData: Interfaces.FieldOutput[] = (
          chart.graphs as GraphOutputWithDetails[]
        ).flatMap(
          (g) => g.additionalDetails?.flatMap((a) => a.schemaData) || [],
        );

        if (combinedSchemaData.length) {
          setEntitySchema(combinedSchemaData);
        }
        return;
      }

      let filteredSchemaData: Interfaces.FieldOutput[] =
        (type === EntityType.DATASET
          ? activeDataCollectionItem?.schemaData
          : query?.additionalDetails
              ?.map((details) => details.schemaData)
              .flat()) || [];

      if (filterKeys?.length) {
        filteredSchemaData = filteredSchemaData?.filter((f) =>
          filterKeys.includes(f.name),
        );
      }

      if (filteredSchemaData.length) {
        setEntitySchema(filteredSchemaData);
      }
    }
  }, [
    activeDataCollectionItem?.schemaData,
    chart?.graphs,
    entitySchema,
    filterKeys,
    getDatasetMetas,
    query?.additionalDetails,
    type,
  ]);

  // Fetch filters
  useEffect(() => {
    if (entityId && getDatasetFilters(entityId)) {
      setFilters(getDatasetFilters(entityId));
      localStorage.removeItem(LOCAL_STORAGE_TEMP_FILTERS_KEY);
    }
  }, [entityId, getDatasetFilters]);

  // Check for filter params
  useEffect(() => {
    if (entityId) {
      setHasFilterParams(
        !!history.location.state?.filters ||
          !!localStorage.getItem(LOCAL_STORAGE_TEMP_FILTERS_KEY),
      );
    }
  }, [entityId, history.location.state?.filters]);

  const handleFilterStatusChange = (filterIndex: number, active: boolean) => {
    if (
      entityId &&
      (filters?.[0]?.value as DynamicConditionalField[])[filterIndex]
    ) {
      const filter = cloneDeep(
        (filters?.[0]?.value as DynamicConditionalField[])[filterIndex],
      );

      if (filter) {
        filter.active = active;
      }

      updateFilterGroup(entityId, filter, filterIndex);
    }
  };

  const handleFilterMatchOperatorChange = (
    matchOperator:
      | Enums.AggregationFilterOperator.AND
      | Enums.AggregationFilterOperator.OR,
  ) => {
    if (entityId && filters?.length) {
      const cloned = cloneDeep(filters);

      cloned[0].operator = matchOperator;

      updateFilterGroup(entityId, cloned);
    }
  };

  const NotificationFilterListItems = () => {
    const filter = filters?.[0]?.value as DynamicConditionalField[];
    const triggers: Trigger[] = [];

    if (!filter) {
      return;
    }

    (filter[0].value as unknown as Interfaces.StandardFilterField[])
      .filter((row) => row.alias !== ROW_ID_FIELD)
      .map((row) => {
        const fieldId = row.field;
        const name = activeDataCollectionItem?.schemaData?.find(
          (field) => field.fieldId === fieldId,
        )?.name;
        const condition = friendlyOperator(row.operator);
        const value = (row?.value as Interfaces.ConstantConditionalValue)
          ?.value as string;

        name && triggers.push({ condition, name, value });
      });

    return (
      <>
        {triggers.map((c) => {
          return (
            <li>
              {c.name} is {c.condition} {c.value}
            </li>
          );
        })}
      </>
    );
  };

  // Check filters on schema change to clear any deleted columns
  useEffect(() => {
    if (
      !hasFilterParams ||
      !entityId ||
      !entitySchema?.length ||
      entityId !== selectedResource?.resourceId
    ) {
      return;
    }

    const schemaFieldIds = entitySchema?.map((s) => s.fieldId) || [];
    const updatedFilter = checkForDeletedColumnsInFilter(
      entityId,
      schemaFieldIds,
    );

    if (updatedFilter !== undefined) {
      toast.error(
        updatedFilter.length
          ? 'Filter updated - This filter referenced a deleted column'
          : 'Filter deleted - The column no longer exists',
      );
      updateFilterGroup(entityId, updatedFilter);
    }
    // Only want this check to run when schema changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitySchema]);

  return (
    <SC.Wrapper>
      <SC.StickyWrapper showBanner={isSitewideBanner}>
        <SC.HeaderWrapper>
          <SC.Header>Filters</SC.Header>

          {hasFilterParams && (
            <FeatureButton
              isDisabled={!filters?.length}
              action={() =>
                history.push({
                  pathname: history.location.pathname,
                })
              }
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'Clear Temp Filters'}
            />
          )}
          {!hasFilterParams && (
            <FeatureButton
              isDisabled={!filters?.length}
              action={() => entityId && deleteDatasetFilters(entityId)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.red}
              text={'Clear Filters'}
            />
          )}
        </SC.HeaderWrapper>

        {(filters || []).length === 0 && (
          <SC.NoResultsWrapper>
            <StyledText>No active filters found</StyledText>
          </SC.NoResultsWrapper>
        )}

        {(filters || []).length > 0 && (
          <SC.Filters>
            {hasFilterParams &&
              history.location?.state?.filterType ===
                EntityType.NOTIFICATION && (
                <SC.StyledTextPanel>
                  <strong>Notification results for:</strong>
                  <SC.StyledNotificationFilterList>
                    {NotificationFilterListItems()}
                  </SC.StyledNotificationFilterList>
                </SC.StyledTextPanel>
              )}
            {hasFilterParams &&
              history.location?.state?.filterType !==
                EntityType.NOTIFICATION && (
                <SC.StyledTextPanel alignCenter={true}>
                  You are viewing a filtered dataset and any additional filters
                  created here will be lost on moving away from this view.
                </SC.StyledTextPanel>
              )}

            {!hasFilterParams && (
              <>
                <StyledBodySubHeader style={{ margin: 0 }}>
                  Match Group Condition
                </StyledBodySubHeader>
                <StyledDropdown
                  selectOnBlur={false}
                  placeholder={'Select a match condition'}
                  options={matchDropdownOptions}
                  selection
                  value={filters?.[0]?.operator}
                  onChange={(e, data) =>
                    handleFilterMatchOperatorChange(data.value)
                  }
                  style={{ marginBottom: themeContext.margin.small }}
                />
              </>
            )}

            {(filters?.[0]?.value as DynamicConditionalField[]).map((f, i) => {
              const filterColumns = (
                f.value as DynamicConditionalField[]
              ).reduce((acc, val) => {
                const exists = acc.find(
                  (f) => f === val?.alias || f === val?.field,
                );

                if (val.fields?.length) {
                  // If filter is a chart filter, display all multiple column names
                  val.fields?.map((c) => {
                    const exists = acc.find(
                      (f) => f === c?.alias || f === c?.field,
                    );

                    if (!exists) {
                      acc.push(c.alias || c.field || '');
                    }
                  });
                } else if (!exists && (val.alias || val.field)) {
                  acc.push(val.alias || val.field || '');
                }

                return acc;
              }, [] as string[]);

              const filterName = (
                filters?.[0]?.value as DynamicConditionalField[]
              )?.[i].name;

              return (
                <FeatureButton
                  key={`filter-${i}`}
                  action={() =>
                    dispatch(
                      showModal({
                        visible: true,
                        modal: ModalTypes.ADD_FILTER,
                        fullScreen: true,
                        additionalProps: {
                          type,
                          existingFilterIndex: i,
                          filterKeys,
                          cmsGroupId: groupId,
                        },
                      }),
                    )
                  }
                  size={'auto'}
                  height={100}
                  color={
                    f.active
                      ? themeContext.colors.general.purple
                      : themeContext.colors.system.grey
                  }
                  icon={
                    <SC.Filter active={f.active}>
                      <SC.FilterHeader
                        active={f.active}
                        showingMore={filterColumns.length > 3}
                      >
                        <StyledBodySubHeader>
                          {f.operator === Enums.AggregationFilterOperator.AND
                            ? 'Match All'
                            : 'Match Any'}
                        </StyledBodySubHeader>
                        {!hasFilterParams && (
                          <Checkbox
                            toggle={true}
                            key={`sort-checkbox`}
                            checked={f.active}
                            onChange={(e, data) => {
                              e.stopPropagation();
                              handleFilterStatusChange(
                                i,
                                data.checked || false,
                              );
                            }}
                          />
                        )}
                      </SC.FilterHeader>
                      <StyledBodySubHeader>Filtering</StyledBodySubHeader>
                      <StyledText>
                        {history.location?.state?.filterType ===
                        EntityType.NOTIFICATION
                          ? 'Notification filter applied'
                          : filterName
                          ? filterName
                          : truncate(
                              `${filterColumns.slice(0, 3).join(', ')} ${
                                filterColumns.length > 3
                                  ? `+ ${filterColumns.length - 3} more`
                                  : ''
                              }`,
                              { length: TRUNCATE_LIMIT },
                            )}
                      </StyledText>
                    </SC.Filter>
                  }
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  faceStyle={{
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                  containerStyle={{
                    marginTop: themeContext.margin.standard,
                    justifyContent: 'space-between',
                  }}
                />
              );
            })}
          </SC.Filters>
        )}

        {!hasFilterParams && (
          <FeatureButton
            action={() =>
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.ADD_FILTER,
                  fullScreen: true,
                  additionalProps: {
                    type,
                    filterKeys,
                    cmsGroupId: groupId,
                  },
                }),
              )
            }
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.blue}
            text={'Add Filter Group'}
            containerStyle={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          />
        )}
      </SC.StickyWrapper>
    </SC.Wrapper>
  );
};

export default CMSFilter;
