import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { FORMULA_TYPES } from '../../../../const/FormulaConst';
import useChart from '../../../../hooks/chart/UseChart';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useFilter from '../../../../hooks/filter/UseFilter';
import useGraph from '../../../../hooks/graph/UseGraph';
import useProject from '../../../../hooks/project/UseProject';
import useQuery from '../../../../hooks/query/UseQuery';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledSubHeader,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { fetchGraphSuccess } from '../../../../store/graph';
import InitialGraph from '../../../../store/graph/initial-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectGraphSubStage } from '../../../../store/project-stage/initial-state';
import {
  fetchQueriesSuccess,
  QueryItemOutput,
} from '../../../../store/queries';
import { RootState } from '../../../../store/rootReducer';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../styled';

interface FieldPanel {
  key: string;
  active: boolean;
  title: JSX.Element;
  content: JSX.Element;
}

const PAGE_SIZE = 25;
const PAGE_NUM = 1;
const DATASET_LIMIT = 300;

const AGGREGATION_TYPE_FIELD = 'aggregationType';
const DATASET_METAS_FIELD = 'datasetMetaId';
const DATA_MODEL_LINK_FIELD = 'dataModelLinkIds';
const NEXT_STAGE = ProjectGraphSubStage.GRAPH;
const PREV_STAGE = ProjectGraphSubStage.NAME;

export const aggregationTypeOptions = [
  {
    key: `aggregation-type-${Enums.AggregationType.SINGLE}`,
    value: Enums.AggregationType.SINGLE,
    text: 'Single dataset',
  },
  {
    key: `aggregation-type-${Enums.AggregationType.MULTIPLE}`,
    value: Enums.AggregationType.MULTIPLE,
    text: 'Multiple datasets',
  },
];

const ProjectItemGraphDataStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { getQueries } = useQuery();
  const { project } = useProject();
  const { getDatasetMetas } = useDatasetMeta();
  const { getDatasetFilters, updateFilterGroup } = useFilter();
  const { chart, editChart } = useChart();
  const { graph, graphAccessLevel } = useGraph();

  const [datasetOptions, setDatasetOptions] = useState<DropdownItemProps[]>([]);
  const [dataLinkOptions, setDataLinkOptions] = useState<DropdownItemProps[]>(
    [],
  );
  const [existingQueryOptions, setExistingQueryOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [fieldPanels, setFieldPanels] = useState<FieldPanel[]>([]);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [graphCreated, setGraphCreated] = useState<boolean>(false);
  const [graphUpdated, setGraphUpdated] = useState<boolean>(false);
  const [useExistingQuery, setUseExistingQuery] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [queriesLoaded, setQueriesLoaded] = useState<boolean>(false);
  const [datasetsLoaded, setDatasetsLoaded] = useState<boolean>(false);

  const isMultiple =
    graph?.queryParams?.aggregationType === Enums.AggregationType.MULTIPLE;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const queriesState = useSelector((state: RootState) => state.queries);
  const queries: QueryItemOutput[] = queriesState.data.data;

  const handleSelectExistingQuery = (val: string) => {
    const existingQuery = queries.find((q) => q.entity._id === val);
    const cloned = cloneDeep(graph);
    cloned.queryParams = {
      ...existingQuery?.entity.queryParams,
      includeSubtotals: false,
    };

    if (existingQuery) {
      dispatch(
        fetchGraphSuccess({
          accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    }
  };

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(graph);

    cloned.queryParams[field] = val;
    cloned.queryParams.fields = [];

    dispatch(
      fetchGraphSuccess({
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleFieldChange = useCallback(
    (
      datasetMetaId: string,
      datasetMetaName: string,
      field: string[],
      alias: string[],
      val: boolean,
    ) => {
      const cloned = cloneDeep(graph);

      if (!val) {
        cloned.queryParams.fields = cloned.queryParams.fields.filter(
          (f) =>
            !(field.includes(f.field) && f.datasetMetaId === datasetMetaId),
        );
      } else {
        field.map((f, i) => {
          cloned.queryParams.fields.push({
            field: f,
            alias: isMultiple ? `${alias[i]} - ${datasetMetaName}` : alias[i],
            datasetMetaId,
          });
        });
      }

      dispatch(
        fetchGraphSuccess({
          accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [graph, dispatch, graphAccessLevel, isMultiple],
  );

  const handleResetQuery = (useExistingQuery: boolean) => {
    setUseExistingQuery(useExistingQuery);

    const cloned = cloneDeep(graph);
    cloned.queryParams = InitialGraph.entity.queryParams;
    dispatch(
      fetchGraphSuccess({
        accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const processAction = async () => {
    if (graph) {
      const cloned = cloneDeep(graph);

      if (
        ![Enums.GraphType.MAP, Enums.GraphType.GANTT].includes(
          cloned.graphParams.graphType,
        )
      ) {
        // Set an xaxis metric if not set
        cloned.graphParams.xAxisMetric = !cloned.graphParams.xAxisMetric.field
          .alias
          ? (cloned.graphParams.xAxisMetric = {
              field: cloned.queryParams.fields[0],
              colour: themeContext.colors.general.blue,
            })
          : cloned.graphParams.xAxisMetric;
      }

      // Remove any reference to removed fields
      const clearKeys = ['groups', 'sort', 'measures'];

      clearKeys.forEach((k) => {
        cloned.queryParams[k] = cloned.queryParams[k].filter((f) => {
          if (k === 'sort' && f.isMeasure) {
            return true;
          }

          return !!cloned.queryParams.fields.find(
            (af) =>
              af.datasetMetaId === f.datasetMetaId && af.field === f.field,
          );
        });
      });

      // Remove any removed fields from filter query
      const clearRemovedFieldsFromFilterQuery = (filter) => {
        return {
          ...filter,
          value:
            Array.isArray(filter.value) && filter.value[0]?.operator
              ? filter.value
                  .filter((v) => {
                    // If a filter wrapper, check if no values or if single value has it been deleted
                    if (!v.field) {
                      if (Array.isArray(v.value)) {
                        if (!v.value.length) {
                          return false;
                        }

                        if (v.value[0]?.operator && v.value.length === 1) {
                          if (
                            !cloned.queryParams.fields.find(
                              (af) => af.field === v.value[0].field,
                            )
                          ) {
                            return false;
                          }
                        }

                        return true;
                      }
                    }

                    // Otherwise check if field still exists
                    return !!cloned.queryParams.fields.find(
                      (af) => af.field === v.field,
                    );
                  })
                  .map((v) => clearRemovedFieldsFromFilterQuery(v))
              : filter.value,
        };
      };
      cloned.queryParams.filters = cloned.queryParams.filters.map((f) =>
        clearRemovedFieldsFromFilterQuery(f),
      );

      // Remove any removed fields from filter state
      const savedFilters = getDatasetFilters(cloned._id);
      const clearRemovedFieldsFromFilterState = (filter) => {
        return {
          ...filter,
          value:
            Array.isArray(filter.value) && filter.value[0]?.operator
              ? filter.value
                  .filter((v) => {
                    // If a filter wrapper, check if no values or if single value has it been deleted
                    if (!v.field) {
                      if (Array.isArray(v.value)) {
                        if (!v.value.length) {
                          return false;
                        }

                        if (v.value[0]?.operator && v.value.length === 1) {
                          if (
                            !cloned.queryParams.fields.find(
                              (af) => af.field === v.value[0].field,
                            )
                          ) {
                            return false;
                          }
                        }

                        return true;
                      }
                    }

                    // Otherwise check if field still exists
                    return !!cloned.queryParams.fields.find(
                      (af) => af.field === v.field,
                    );
                  })
                  .map((v) => clearRemovedFieldsFromFilterState(v))
              : filter.value,
        };
      };

      const updatedFilters = (savedFilters || []).map((f) =>
        clearRemovedFieldsFromFilterState(f),
      );

      updateFilterGroup(cloned._id, updatedFilters);

      dispatch(showLoading({ text: 'Saving Graph...' }));

      const clonedChart = cloneDeep(chart);
      if (cloned._id) {
        const editingGraphIndex = clonedChart.graphs.findIndex(
          (g) => g._id === cloned._id,
        );
        clonedChart.graphs[editingGraphIndex] = cloned;
      } else {
        clonedChart.graphs = [...clonedChart.graphs, cloned];
      }

      await editChart(clonedChart);

      graph._id ? setGraphUpdated(true) : setGraphCreated(true);

      dispatch(hideLoading());
    }
  };

  // Hide loader
  useEffect(() => {
    if (queriesLoaded && datasetsLoaded) {
      setIsLoading(false);
    }
  }, [queriesLoaded, datasetsLoaded]);

  // Get queries
  useEffect(() => {
    if (project?._id) {
      if (project?.queries?.length) {
        setQueriesLoaded(false);
        (async () => {
          const queryParams = {
            projectOriginId: project?._id,
            pageNum: PAGE_NUM,
            limit: PAGE_SIZE,
          };

          await getQueries(queryParams);

          setQueriesLoaded(true);
        })();
      } else {
        dispatch(
          fetchQueriesSuccess({
            data: [],
            pagination: {
              currentPageNum: 1,
              prevPageNum: null,
              nextPageNum: null,
              totalCount: 0,
              totalPages: 1,
            },
          }),
        );
        setQueriesLoaded(true);
      }
    }
  }, [dispatch, getQueries, project?._id, project?.queries]);

  // Map queries to options
  useEffect(() => {
    if (datasetMetas?.length) {
      setDatasetOptions(
        datasetMetas.map((d) => {
          const ent = d.entity;
          return {
            key: ent._id,
            value: ent._id,
            text: ent.name,
          };
        }),
      );
    }
  }, [datasetMetas]);

  // Update graph state
  useEffect(() => {
    if (graphCreated || graphUpdated) {
      (async () => {
        const updatedGraph = graphCreated
          ? chart?.graphs && chart.graphs.find((g) => g.name === graph?.name)
          : graph;

        if (updatedGraph?._id) {
          dispatch(
            fetchGraphSuccess({
              accessLevel: graphAccessLevel || Enums.AccessLevel.MANAGE,
              entity: updatedGraph,
            }),
          );
          dispatch(updateActiveProjectSubStage(NEXT_STAGE));
        }
      })();
    }
  }, [
    chart?.graphs,
    dispatch,
    graph,
    graphAccessLevel,
    graphCreated,
    graphUpdated,
  ]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          undefined,
          undefined,
          DATASET_LIMIT,
        );
        setDatasetsLoaded(true);
      }
    })();
  }, [dispatch, getDatasetMetas, project]);

  // Map existing query options
  useEffect(() => {
    if (queries?.length) {
      setExistingQueryOptions(
        queries.map((q) => {
          const ent = q.entity;
          return {
            key: ent._id,
            value: ent._id,
            text: ent.name,
          };
        }),
      );
    }
  }, [queries]);

  const getAllDataLinkOptions = useCallback(() => {
    return (
      project?.dataModel?.reduce((acc, d) => {
        const primaryDSM = datasetMetas.find(
          (ds) => ds.entity._id === d.primary.datasetMetaId,
        );

        const primaryCollection = primaryDSM?.entity.dataCollections?.find(
          (c) => c._id === primaryDSM.entity.activeDataCollection,
        );
        const primaryFieldName = primaryCollection?.schemaData?.find(
          (f) => f.fieldId === d.primary.field || f.name === d.primary.field,
        )?.name;

        const secondaryDSM = datasetMetas.find(
          (ds) => ds.entity._id === d.secondary.datasetMetaId,
        );

        const secondaryCollection = secondaryDSM?.entity.dataCollections?.find(
          (c) => c._id === secondaryDSM.entity.activeDataCollection,
        );
        const secondaryFieldName = secondaryCollection?.schemaData?.find(
          (f) =>
            f.fieldId === d.secondary.field || f.name === d.secondary.field,
        )?.name;

        if (
          primaryDSM?.entity.name &&
          primaryFieldName &&
          secondaryDSM?.entity.name &&
          secondaryFieldName
        ) {
          return [
            ...acc,
            {
              key: d._id,
              value: d._id,
              text: `${primaryDSM?.entity.name} (${primaryFieldName}) - ${secondaryDSM?.entity.name} (${secondaryFieldName})`,
            },
          ];
        }

        return acc;
      }, [] as DropdownItemProps[]) || []
    );
  }, [datasetMetas, project?.dataModel]);

  // Map data links to options
  useEffect(() => {
    if (project?.dataModel && datasetMetas) {
      setDataLinkOptions(getAllDataLinkOptions());
    }
  }, [datasetMetas, getAllDataLinkOptions, project?.dataModel]);

  useEffect(() => {
    if (!graph?.queryParams.dataModelLinkIds?.length) {
      return setDataLinkOptions(getAllDataLinkOptions());
    }

    if (project && graph) {
      const allowedDatasets: string[] = [];

      graph.queryParams.dataModelLinkIds.forEach((id) => {
        const dml = project?.dataModel.find((d) => d._id === id);

        if (dml) {
          if (!allowedDatasets.includes(dml.primary.datasetMetaId)) {
            allowedDatasets.push(dml.primary.datasetMetaId);
          }

          if (!allowedDatasets.includes(dml.secondary.datasetMetaId)) {
            allowedDatasets.push(dml.secondary.datasetMetaId);
          }
        }
      });

      const allowedDataLinks = project.dataModel
        .filter((d) => {
          // TODO - Remove when super columns are saved - Filter out super column joins
          const primaryDSM = datasetMetas.find(
            (dsm) => dsm.entity._id === d.primary.datasetMetaId,
          )?.entity;
          const primaryCollection = primaryDSM?.activeDataCollection
            ? primaryDSM.dataCollections.find(
                (c) => c._id === primaryDSM?.activeDataCollection,
              )
            : primaryDSM?.dataCollections?.[0];

          if (!primaryCollection) {
            return false;
          }

          const primaryFieldType = primaryCollection?.schemaData?.find(
            (f) => f.name === d.primary.field,
          )?.dataValidation?.dataValidationType;

          const secondaryDSM = datasetMetas.find(
            (dsm) => dsm.entity._id === d.secondary.datasetMetaId,
          )?.entity;
          const secondaryCollection = secondaryDSM?.activeDataCollection
            ? secondaryDSM.dataCollections.find(
                (c) => c._id === secondaryDSM.activeDataCollection,
              )
            : secondaryDSM?.dataCollections[0];

          const secondaryFieldType = secondaryCollection?.schemaData?.find(
            (f) => f.name === d.secondary.field,
          )?.dataValidation?.dataValidationType;

          if (
            (primaryFieldType && FORMULA_TYPES.includes(primaryFieldType)) ||
            (secondaryFieldType && FORMULA_TYPES.includes(secondaryFieldType))
          ) {
            return false;
          }

          return (
            allowedDatasets.includes(d.primary.datasetMetaId) ||
            allowedDatasets.includes(d.secondary.datasetMetaId)
          );
        })
        ?.map((d) => {
          const primaryDSM = datasetMetas.find(
            (ds) => ds.entity._id === d.primary.datasetMetaId,
          );

          const primaryCollection = primaryDSM?.entity.dataCollections?.find(
            (c) => c._id === primaryDSM.entity.activeDataCollection,
          );
          const primaryFieldName = primaryCollection?.schemaData?.find(
            (f) => f.fieldId === d.primary.field || f.name === d.primary.field,
          )?.name;

          const secondaryDSM = datasetMetas.find(
            (ds) => ds.entity._id === d.secondary.datasetMetaId,
          );

          const secondaryCollection =
            secondaryDSM?.entity.dataCollections?.find(
              (c) => c._id === secondaryDSM.entity.activeDataCollection,
            );
          const secondaryFieldName = secondaryCollection?.schemaData?.find(
            (f) =>
              f.fieldId === d.secondary.field || f.name === d.secondary.field,
          )?.name;

          return {
            key: d._id,
            value: d._id,
            text: `${primaryDSM?.entity.name} (${primaryFieldName}) - ${secondaryDSM?.entity.name} (${secondaryFieldName})`,
          };
        });

      return setDataLinkOptions(allowedDataLinks);
    }
  }, [graph, datasetMetas, getAllDataLinkOptions, project]);

  // Map fields to panels
  useEffect(() => {
    if (graph) {
      let datasets: string[] = [];

      if (
        graph?.queryParams.aggregationType === Enums.AggregationType.SINGLE &&
        graph?.queryParams.datasetMetaId
      ) {
        datasets = [graph.queryParams.datasetMetaId];
      } else {
        const dataLinks = project?.dataModel.filter((dm) =>
          graph.queryParams.dataModelLinkIds?.includes(dm._id),
        );

        dataLinks?.forEach((dl) => {
          if (!datasets.includes(dl.primary.datasetMetaId)) {
            datasets.push(dl.primary.datasetMetaId);
          }

          if (!datasets.includes(dl.secondary.datasetMetaId)) {
            datasets.push(dl.secondary.datasetMetaId);
          }
        });
      }

      const panels: FieldPanel[] = [];

      datasets.map((t, i) => {
        const dsm = datasetMetas.find((d) => d.entity._id === t)?.entity;

        if (dsm?.activeDataCollection) {
          const schema = dsm.dataCollections?.find(
            (c) => c._id === dsm.activeDataCollection,
          )?.schemaData;

          if (schema) {
            const inputs = schema.map((f, i) => {
              const checked = !!graph?.queryParams?.fields?.find(
                (af) =>
                  (af.field === f.fieldId || af.field === f.name) &&
                  af.datasetMetaId === dsm._id,
              );

              return (
                <Checkbox
                  key={`field-checkbox-${dsm._id}-${f.name}-${i}`}
                  label={f.name}
                  checked={checked}
                  onChange={(e, data) =>
                    handleFieldChange(
                      dsm._id,
                      dsm.name,
                      [f.fieldId || f.name],
                      [f.name],
                      data.checked || false,
                    )
                  }
                />
              );
            });

            panels.push({
              key: `field-panel-${t}-${i}`,
              active: true,
              title: (
                <StyledAccordionTitle>
                  <StyledBodySubHeader>{dsm.name}</StyledBodySubHeader>
                  <Checkbox
                    key={`field-checkbox-${dsm._id}-select-all-${i}`}
                    label={'Select All'}
                    checked={schema.every(
                      (f) =>
                        !!graph?.queryParams?.fields?.find(
                          (af) =>
                            (af.field === f.fieldId || af.field === f.name) &&
                            af.datasetMetaId === dsm._id,
                        ),
                    )}
                    onChange={(e, data) =>
                      handleFieldChange(
                        dsm._id,
                        dsm.name,
                        schema.map((f) => f.fieldId || f.name),
                        schema.map((f) => f.name),
                        data.checked || false,
                      )
                    }
                  />
                </StyledAccordionTitle>
              ),
              content: (
                <SC.FieldAccordionContent>{inputs}</SC.FieldAccordionContent>
              ),
            });
          }
        }
      });

      setFieldPanels(panels);
    }
  }, [datasetMetas, handleFieldChange, project?.dataModel, graph]);

  // Confirm user can progress
  useEffect(() => {
    if (graph) {
      setCanProgress(
        !(
          (!graph.queryParams.datasetMetaId &&
            !graph.queryParams.dataModelLinkIds?.length) ||
          !graph?.queryParams?.fields?.length
        ),
      );
    }
  }, [graph, setCanProgress]);

  return (
    <StageWrapper>
      <StageInner>
        <SC.Section>
          <StyledSubHeader>
            Start from scratch or use existing Query?
          </StyledSubHeader>
          <StageBodyText>
            Would you like to create a new query from scratch or use an existing
            query?
          </StageBodyText>

          <SC.ButtonContainer>
            <FeatureButton
              isDisabled={!useExistingQuery}
              action={() => handleResetQuery(false)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'New query'}
              containerStyle={{ marginRight: themeContext.margin.xlarge }}
            />
            <FeatureButton
              isDisabled={useExistingQuery}
              action={() => handleResetQuery(true)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'Existing query'}
            />
          </SC.ButtonContainer>

          {useExistingQuery && (
            <>
              <StyledSubHeader>Existing Query</StyledSubHeader>
              <StyledDropdownUltraWide
                selectOnBlur={false}
                placeholder={'Select an existing Query'}
                selection
                options={existingQueryOptions}
                onChange={(e, data) => {
                  handleSelectExistingQuery(data.value);
                }}
              />
            </>
          )}

          <StyledSubHeader>Query Type</StyledSubHeader>
          <StageBodyText>
            Would you like to query a single dataset or multiple datasets across
            your data model?
          </StageBodyText>

          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select a Query Type'}
            selection
            options={aggregationTypeOptions}
            value={graph?.queryParams?.aggregationType}
            onChange={(e, data) =>
              handleChange(AGGREGATION_TYPE_FIELD, data.value)
            }
            loading={isLoading}
          />

          {graph?.queryParams.aggregationType ===
            Enums.AggregationType.SINGLE && (
            <>
              <StyledSubHeader>Dataset</StyledSubHeader>
              <StageBodyText>
                Which dataset would you like to use in this query?
              </StageBodyText>
              <StyledDropdownUltraWide
                selectOnBlur={false}
                placeholder={'Select a Dataset'}
                selection
                options={datasetOptions}
                value={
                  graph?.queryParams?.datasetMetaId
                    ? graph?.queryParams?.datasetMetaId
                    : ''
                }
                onChange={(e, data) =>
                  handleChange(DATASET_METAS_FIELD, data.value)
                }
                loading={isLoading}
                disabled={isLoading}
              />
            </>
          )}

          {graph?.queryParams.aggregationType ===
            Enums.AggregationType.MULTIPLE && (
            <>
              <StyledSubHeader>Data Link</StyledSubHeader>

              {dataLinkOptions.length === 0 && (
                <>
                  <StageBodyText>
                    A data model has not been created for this project yet. To
                    create one, tap the button below.
                  </StageBodyText>

                  <FeatureButton
                    action={() => {
                      dispatch(
                        updateActiveProjectStage(Enums.ProjectStage.MODELLING),
                      );
                    }}
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.blue}
                    text={'Create Data Model'}
                  />
                </>
              )}

              {dataLinkOptions.length > 0 && (
                <>
                  <StageBodyText>
                    Which data link would you like to use in this query?
                  </StageBodyText>

                  <StyledDropdownUltraWide
                    selectOnBlur={false}
                    upward={true}
                    placeholder={'Select a Data Link'}
                    selection
                    multiple
                    options={dataLinkOptions}
                    value={graph.queryParams?.dataModelLinkIds || []}
                    onChange={(e, data) =>
                      handleChange(DATA_MODEL_LINK_FIELD, data.value)
                    }
                  />
                </>
              )}
            </>
          )}

          {((graph?.queryParams.aggregationType ===
            Enums.AggregationType.SINGLE &&
            graph?.queryParams.datasetMetaId) ||
            (graph?.queryParams.aggregationType ===
              Enums.AggregationType.MULTIPLE &&
              dataLinkOptions.length > 0)) &&
            fieldPanels.length > 0 && (
              <>
                <StyledSubHeader>Columns</StyledSubHeader>
                <StageBodyText>
                  Which columns would you like to use in this query?
                </StageBodyText>

                <SC.FieldAccordion
                  defaultActiveIndex={[0]}
                  exclusive={false}
                  panels={fieldPanels}
                />
              </>
            )}
        </SC.Section>
      </StageInner>

      <ActionBar
        text={`Keep it moving`}
        primaryButton={
          <FeatureButton
            isDisabled={!canProgress}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to graph'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemGraphDataStage;
