import React, { FC, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import logo from '../../assets/icons/system/codex-icon.svg';
import { RouteName } from '../../enums/RouteName';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import { DefaultPopupStyles } from '../../main/theme';
import FeatureButton from '../FeatureButton/FeatureButton';
import * as SC from './styled';

const PortalUserSystemActions: FC = () => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();
  const { organisation } = useOrganisation();

  return (
    <SC.Wrapper>
      <Popup
        disabled={true}
        position="right center"
        style={DefaultPopupStyles}
        trigger={
          <div>
            <FeatureButton
              isActive={location.pathname === RouteName.WELCOME}
              action={() => history.push(RouteName.WELCOME)}
              size={50}
              color={themeContext.colors.system.white}
              image={organisation?.theme?.logo || logo}
              faceStyle={
                organisation?.theme?.logo
                  ? {
                      background: `${themeContext.colors.system.white} url(${organisation?.theme?.logo}) no-repeat center / 85%`,
                      padding: 0,
                    }
                  : {}
              }
            />
          </div>
        }
      />
    </SC.Wrapper>
  );
};

export default PortalUserSystemActions;
