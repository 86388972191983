import { truncate } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DropdownProps, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { PortalConnections } from '../../enums/Connections';
import { RouteName } from '../../enums/RouteName';
import useDataset from '../../hooks/dataset/UseDataset';
import usePortal from '../../hooks/portal/UsePortal';
import useQuery from '../../hooks/query/UseQuery';
import {
  DefaultPopupStyles,
  StyledDropdown,
  TopBarHeaderTextWrapper,
  TopBarHeaderTitle,
} from '../../main/theme';
import {
  updateActivePortalStage,
  updateActivePortalSubStage,
} from '../../store/portal-stage';
import {
  PortalAggregationSubStage,
  PortalStage,
} from '../../store/portal-stage/initial-state';
import { fetchQuerySuccess } from '../../store/query';
import { RootState } from '../../store/rootReducer';

const TRUNCATE_LIMIT = 25;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  width: 100%;
`;

const QueryDropdown = styled(StyledDropdown)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  ${({ theme }) => theme.typography.header};

  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};

  margin-bottom: ${({ theme }) => theme.margin.small};
  width: 100%;
`;

const PortalHeader: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { portal } = usePortal();
  const { query } = useQuery();
  const { getAggregatedDataset } = useDataset();
  const queriesState = useSelector((state: RootState) => state.queries);
  const portalStageState = useSelector((state: RootState) => state.portalStage);
  const queries = queriesState.data;

  const [dropdownOptions, setDropdownOptions] = useState<DropdownProps[]>();
  const [activeQuery, setActiveQuery] = useState<string>();

  const isPortalHomepage =
    location.pathname.includes(RouteName.PORTAL) &&
    !Object.values(PortalConnections).some((c) =>
      location.pathname.includes(c),
    ) &&
    !location.pathname.includes('query');

  const isQuery = location.pathname.includes('query');

  useEffect(() => {
    // Generate dropdown options for queries
    const queryDropdown = queries?.data.map((query) => {
      return {
        key: query.entity.name,
        value: query.entity._id,
        text: query.entity.name,
      };
    });

    setDropdownOptions(queryDropdown);
    if (!portalStageState.stages[PortalStage.AGGREGATION].activeSubStage) {
      setActiveQuery(undefined);
    }
  }, [queries?.data, portalStageState.stages]);

  useEffect(() => {
    if (portalStageState.stages[PortalStage.AGGREGATION].activeSubStage) {
      setActiveQuery(query?.name);
    }
  }, [portalStageState.stages, query?.name]);

  if (!portal?._id || isPortalHomepage) {
    return <Wrapper />;
  }

  // Handle changing query via dropdown
  const handleQueryChange = async (value) => {
    const query = queries?.data.find((c) => c.entity._id === value);
    if (query) {
      dispatch(fetchQuerySuccess(query));
      dispatch(updateActivePortalStage(PortalStage.AGGREGATION));
      dispatch(updateActivePortalSubStage(PortalAggregationSubStage.AGGREGATE));
      await getAggregatedDataset(query?.entity._id, [], 1, portal?._id);
    }
  };

  return (
    <Wrapper>
      {!isQuery && (
        <Popup
          disabled={portal.name.length <= TRUNCATE_LIMIT}
          content={portal.name}
          position="right center"
          style={DefaultPopupStyles}
          trigger={
            <TopBarHeaderTextWrapper
              onClick={() =>
                history.push(`${RouteName.PORTAL}/${portal?._id}}`)
              }
            >
              <TopBarHeaderTitle lightTheme={true}>
                {truncate(portal.name, {
                  length: TRUNCATE_LIMIT,
                })}
              </TopBarHeaderTitle>
            </TopBarHeaderTextWrapper>
          }
        />
      )}
      {isQuery && (
        <QueryDropdown
          selection
          options={dropdownOptions}
          onChange={(e, { value }) => handleQueryChange(value)}
          value={activeQuery}
          placeholder={activeQuery || 'Select a query'}
        />
      )}
    </Wrapper>
  );
};

export default PortalHeader;
