import styled from 'styled-components';

export const PageHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 20;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xlarge}`};

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > p {
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
