import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CommentsService from '../../services/comments/CommentsService';
import { CommentItem, CommentItemOutput } from '../comments';
import { AppThunk } from '../store';
import { default as InitialComment } from './initial-state';

type Comment = CommentItem | CommentItemOutput;

export interface CommentState {
  data: Comment;
  loading: boolean;
  error: string | null;
}

const initialState: CommentState = {
  data: InitialComment,
  loading: false,
  error: null,
};

const CommentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    // Reset
    resetComment(state) {
      state.data = InitialComment;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchCommentStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCommentSuccess(state, action: PayloadAction<Comment>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchCommentFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create
    createCommentStart(state) {
      state.loading = true;
      state.error = null;
    },
    createCommentSuccess(state, action: PayloadAction<CommentItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createCommentFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateCommentStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateCommentSuccess(state, action: PayloadAction<CommentItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateCommentFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete
    deleteCommentStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteCommentSuccess(state, action: PayloadAction<CommentItemOutput>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteCommentFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetComment,
  fetchCommentStart,
  fetchCommentSuccess,
  fetchCommentFailure,
  createCommentStart,
  createCommentSuccess,
  createCommentFailure,
  updateCommentStart,
  updateCommentSuccess,
  updateCommentFailure,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteCommentFailure,
} = CommentSlice.actions;

export default CommentSlice.reducer;

export const fetchComment =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchCommentStart());
      const fetched = await CommentsService.getComment(token, id);
      dispatch(fetchCommentSuccess(fetched));
    } catch (err) {
      dispatch(fetchCommentFailure((err as string).toString()));
    }
  };

export const createComment =
  (
    token: string,
    comment: Interfaces.Comment,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommentStart());
      const created = await CommentsService.postComment(
        token,
        comment,
        userId,
        portalId,
      );
      dispatch(createCommentSuccess(created));
    } catch (err) {
      dispatch(createCommentFailure((err as string).toString()));
    }
  };

export const updateComment =
  (
    token: string,
    comment: Interfaces.CommentOutput,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateCommentStart());
      const updated = await CommentsService.putComment(
        token,
        comment,
        userId,
        portalId,
      );
      dispatch(updateCommentSuccess(updated));
    } catch (err) {
      dispatch(updateCommentFailure((err as string).toString()));
    }
  };

export const deleteComment =
  (
    token: string,
    commentId: string,
    userId: string,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteCommentStart());
      const deleted = await CommentsService.deleteComment(
        token,
        commentId,
        userId,
        portalId,
      );
      dispatch(deleteCommentSuccess(deleted));
    } catch (err) {
      dispatch(deleteCommentFailure((err as string).toString()));
    }
  };
