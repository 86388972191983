import { Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_API'] || '';

export default class APIService {
  public static async getAPIs(
    token: string,
    projectId?: string,
    query?: Record<string, unknown>,
  ): Promise<
    Promise<Interfaces.DatasetApiOutput[]> | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<Interfaces.DatasetApiOutput[]>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, ...query });

    return result?.data as Interfaces.DatasetApiOutput[];
  }

  public static async getAPI(
    token: string,
    id: string,
    projectId?: string,
  ): Promise<Interfaces.DatasetApiOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<Interfaces.DatasetApiOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, _id: id, singleDocument: true });

    return result?.data as Interfaces.DatasetApiOutput;
  }

  public static async postAPI(
    token: string,
    datasetApi: Interfaces.DatasetApi,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.DatasetApiOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<Interfaces.DatasetApiOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { projectId, data: datasetApi, userId });

    return result?.data as Interfaces.DatasetApiOutput;
  }

  public static async putAPI(
    token: string,
    datasetApi: Interfaces.DatasetApiOutput,
    userId: string,
    projectId?: string,
  ): Promise<Interfaces.DatasetApiOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<Interfaces.DatasetApiOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${datasetApi._id}`, { projectId, data: datasetApi, userId });

    return result?.data as Interfaces.DatasetApiOutput;
  }

  public static async deleteAPI(
    token: string,
    datasetApi: Interfaces.DatasetApiOutput,
    userId: string,
  ): Promise<Interfaces.DatasetApiOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<Interfaces.DatasetApiOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${datasetApi._id}`, userId);

    return result?.data as Interfaces.DatasetApiOutput;
  }
}
