import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { isEmpty } from 'lodash';
import React, { FC, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import useFormula from '../../../../hooks/formula/UseFormula';
import useInputStatus from '../../../../hooks/formula/UseInputStatus';
import { StyledText } from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

import FieldInputs from '../../../Formula/FieldInputs';
import LogicOperatorInputs from '../../../Formula/LogicOperatorInputs';
import NumberInputs from '../../../Formula/NumberInputs';
import * as SC from '../styled';

interface FormulaCalcProps {
  field: Interfaces.Field;
  schema: Interfaces.Field[];
  columnName: string;
  setDataValidation: (dataValidation: Interfaces.DataValidation) => void;
  setSuperColumnValid: (valid: boolean) => void;
  existingFormula?: Interfaces.FormulaOperandValue[];
}

const EQUALS = '=';

const FormulaCalc: FC<FormulaCalcProps> = ({
  field,
  schema,
  columnName,
  setDataValidation,
  setSuperColumnValid,
  existingFormula,
}) => {
  const themeContext = useContext(ThemeContext);

  const { addFormulaItem, resetFormula, formula, formulaOutput, endNesting } =
    useFormula(schema, field?.dataValidation?.formula || existingFormula);

  const { columnStatus, operatorStatus, startNestStatus, endNestStatus } =
    useInputStatus(formula);

  useEffect(() => {
    if (formula && !isEmpty(formula)) {
      setSuperColumnValid(true);
      setDataValidation({
        dataValidationType: Enums.ValueDataType.FORMULA,
        formula: formula,
        formatting: field.dataValidation?.formatting,
      });
    } else {
      setSuperColumnValid(false);
    }
  }, [
    field.dataValidation?.formatting,
    formula,
    setDataValidation,
    setSuperColumnValid,
  ]);

  return (
    <SC.FormulaCalcWrapper>
      <SC.FormulaButtonWrapper>
        <SC.SubHeader>Number</SC.SubHeader>

        <div>
          <NumberInputs
            addFormulaItem={addFormulaItem}
            columnStatus={columnStatus}
          />
        </div>

        <SC.SubHeader>Data Columns</SC.SubHeader>

        <div>
          <FieldInputs
            field={field}
            schema={schema || []}
            addFormulaItem={addFormulaItem}
            columnStatus={columnStatus}
          />
        </div>

        <SC.SubHeader>Logic Operators</SC.SubHeader>

        <div>
          <LogicOperatorInputs
            addFormulaItem={addFormulaItem}
            endNesting={endNesting}
            operatorStatus={operatorStatus}
            startNestStatus={startNestStatus}
            endNestStatus={endNestStatus}
          />
        </div>
      </SC.FormulaButtonWrapper>

      <SC.OutputHeader>
        <SC.SubHeader>Output Formula</SC.SubHeader>
        {formula.length > 0 && (
          <FeatureButton
            action={() => resetFormula()}
            size={FeatureButtonSize.WIDE_SMALL}
            color={themeContext.colors.general.red}
            text={'Clear formula'}
          />
        )}
      </SC.OutputHeader>

      <SC.FormulaOutputWrapper>
        {formulaOutput.length === 0 && (
          <StyledText>Use the buttons above to build your formula</StyledText>
        )}

        {formulaOutput}

        {formulaOutput.length > 0 && (
          <>
            <FeatureButton
              isActive={true}
              isDisplayOnly={true}
              size={FeatureButtonSize.EXTRA_SMALL}
              color={themeContext.colors.general.green}
              text={EQUALS}
              textStyle={{ fontSize: themeContext.typography.sizes.h1 }}
            />

            <SC.OutputResultButton>{columnName}</SC.OutputResultButton>
          </>
        )}
      </SC.FormulaOutputWrapper>
    </SC.FormulaCalcWrapper>
  );
};

export default FormulaCalc;
