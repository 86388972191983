import { Enums } from '@configur-tech/upit-core-types';
import { ReCron, Tab } from '@sbzen/re-cron';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import usePipelineTemplate from '../../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdown,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { updateActivePipelineSubStage } from '../../../../store/pipeline-stage';
import { PipelineCreationSubStage } from '../../../../store/pipeline-stage/initial-state';
import { fetchPipelineTemplateSuccess } from '../../../../store/pipeline-template';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../styled';

const DEFAULT_CRON_VAL = '* 0/15 * ? * * *';
const DEFAULT_CRON_VAL_WITHOUT_SECONDS = '0/15 * ? * * *';

const NEXT_STAGE = PipelineCreationSubStage.JOBS;
const PREV_STAGE = PipelineCreationSubStage.META;

const TRUE = 'true';
const FALSE = 'false';

const statusOptions = [
  {
    value: TRUE,
    text: 'Active',
  },
  {
    value: FALSE,
    text: 'Inactive',
  },
];

const triggerPipelinesOptions = [
  {
    value: TRUE,
    text: 'Yes',
  },
  {
    value: FALSE,
    text: 'No',
  },
];

// Configure Dropdown Options
const triggerOptions = Object.values(Enums.PipelineTrigger)
  .filter((i) => ![Enums.PipelineTrigger.WEBHOOK].includes(i))
  .map((i) => {
    return {
      key: i,
      text: startCase(i.toLowerCase()),
      value: i,
    };
  });

const PipelineTriggerStage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { loggedInUser } = useLoggedInUser();
  const {
    pipelineTemplate,
    pipelineTemplateAccessLevel,
    addPipelineTemplate,
    editPipelineTemplate,
  } = usePipelineTemplate();
  const { datasetMeta } = useDatasetMeta();

  const [processComplete, setProcessComplete] = useState<boolean>(false);
  const [activeCronTab, setActiveCronTab] = useState<Tab>(Tab.YEAR);

  // CRON plugin throws an error until after first render so triggering with state change
  useEffect(() => {
    if (pipelineTemplate?.trigger === Enums.PipelineTrigger.SCHEDULE) {
      setActiveCronTab(Tab.MINUTES);
    }
  }, [pipelineTemplate?.trigger]);

  // Process is complete, move to next stage
  useEffect(() => {
    if (processComplete && pipelineTemplate?._id && datasetMeta?._id) {
      history.push(
        `${RouteName.DATASET_ITEM}/${datasetMeta._id}${RouteName.PIPELINE_ITEM}/${pipelineTemplate._id}`,
      );
      dispatch(updateActivePipelineSubStage(NEXT_STAGE));
    }
  }, [
    processComplete,
    pipelineTemplate?._id,
    dispatch,
    history,
    datasetMeta?._id,
  ]);

  const handleStatusChange = (value: string) => {
    const cloned = cloneDeep(pipelineTemplate);
    cloned.isEnabled = value === TRUE;

    dispatch(
      fetchPipelineTemplateSuccess({
        accessLevel: pipelineTemplateAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleTriggerPipelinesChange = (value: string) => {
    const cloned = cloneDeep(pipelineTemplate);
    cloned.canTriggerPipelines = value === TRUE;

    dispatch(
      fetchPipelineTemplateSuccess({
        accessLevel: pipelineTemplateAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleTriggerChange = (value: Enums.PipelineTrigger) => {
    const cloned = cloneDeep(pipelineTemplate);
    cloned.trigger = value;

    if (value !== Enums.PipelineTrigger.SCHEDULE) {
      cloned.schedule = '';
      setActiveCronTab(Tab.YEAR);
    } else {
      cloned.schedule = DEFAULT_CRON_VAL_WITHOUT_SECONDS;
    }

    dispatch(
      fetchPipelineTemplateSuccess({
        accessLevel: pipelineTemplateAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleScheduleChange = (value: string) => {
    const cloned = cloneDeep(pipelineTemplate);
    const oldSplit = `${cloned.schedule}`.split(' ');
    // Remove multiple selected
    const newSplit = value
      // Remove seconds for EventBridge
      .replace('* ', '')
      .split(' ')
      .map((value) => {
        const splitVal = value.split(',');
        return splitVal[splitVal.length - 1];
      });

    const diffIndex = newSplit.findIndex(
      (str, index) => str !== oldSplit[index],
    );

    switch (diffIndex) {
      case 0:
        // Minutes have been updated
        if (newSplit[0].includes('/')) {
          // Set hours to every
          newSplit[1] = '*';
          // Set days to every
          newSplit[2] = '?';
          newSplit[4] = '*';
        }
        break;
      case 1:
        // Hours have been updated
        if (newSplit[1].includes('/') || newSplit[1] === '*') {
          // Set days to every
          newSplit[2] = '?';
          newSplit[4] = '*';

          if (newSplit[0].includes('/')) {
            // Set minutes to 0
            newSplit[0] = '0';
          }
        } else {
          if (newSplit[0].includes('/')) {
            // Set minutes to 0
            newSplit[0] = '0';
          }
        }

        break;
      case 2:
      case 4:
        // Days have been updated to every day
        if (newSplit[2] === '?' && newSplit[4] === '*') {
          if (newSplit[0].includes('/')) {
            // Set minutes to 0
            newSplit[0] = '0';
          }
          if (newSplit[1].includes('/') || newSplit[1] === '*') {
            // Set hours to 0
            newSplit[1] = '0';
          }
        } // Days have been updated to specific day of week
        else if (newSplit[2] === '?' && newSplit[4] !== '*') {
          if (newSplit[0].includes('/') || newSplit[0] === '*') {
            // Set minutes to 0
            newSplit[0] = '0';
          }
          if (newSplit[1].includes('/') || newSplit[1] === '*') {
            // Set hours to 0
            newSplit[1] = '0';
          }
        } else {
          if (newSplit[0].includes('/')) {
            // Set minutes to 0
            newSplit[0] = '0';
          }
          if (newSplit[1].includes('/')) {
            // Set hours to 0
            newSplit[1] = '0';
          }
        }
        break;
    }

    cloned.schedule = newSplit.join(' ');

    dispatch(
      fetchPipelineTemplateSuccess({
        accessLevel: pipelineTemplateAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const processAction = async () => {
    if (loggedInUser && pipelineTemplate?.trigger) {
      if (!pipelineTemplate._id) {
        // Create integration
        dispatch(showLoading({ text: 'Creating Pipeline...' }));

        await addPipelineTemplate(pipelineTemplate);
      } else {
        // Update integration
        dispatch(showLoading({ text: 'Updating Pipeline...' }));

        await editPipelineTemplate(pipelineTemplate);
      }

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          Each pipeline requires an action to make it run. These actions can be
          a scheduled event that you can control, or they can be powered by your
          dataset events.
        </StageBodyText>
        <StyledBodySubHeader
          style={{
            marginBottom: themeContext.margin.standard,
          }}
        >
          Pipeline Status
        </StyledBodySubHeader>
        <StageBodyText>
          Select whether or not this pipeline should actually run
        </StageBodyText>

        <StyledDropdown
          placeholder={'Select pipeline status'}
          selectOnBlur={false}
          selection
          value={pipelineTemplate?.isEnabled ? TRUE : FALSE || ''}
          options={statusOptions}
          style={{ marginBottom: themeContext.margin.xxlarge }}
          upward={false}
          onChange={(e, { value }) => handleStatusChange(value)}
        />

        <StyledBodySubHeader
          style={{
            marginBottom: themeContext.margin.standard,
          }}
        >
          Pipeline Trigger
        </StyledBodySubHeader>
        <StageBodyText>
          Select which event type will trigger your pipeline to run
        </StageBodyText>
        <StyledDropdown
          placeholder={'Select a pipeline trigger'}
          selectOnBlur={false}
          selection
          value={pipelineTemplate?.trigger || ''}
          options={triggerOptions}
          style={{ marginBottom: themeContext.margin.xxlarge }}
          upward={false}
          onChange={(e, { value }) => handleTriggerChange(value)}
        />

        <StyledBodySubHeader
          style={{
            marginBottom: themeContext.margin.standard,
          }}
        >
          Trigger Other Pipelines
        </StyledBodySubHeader>
        <StageBodyText>
          Should this pipeline trigger other pipelines upon completion?
        </StageBodyText>
        <StyledDropdown
          placeholder={'Select an option'}
          selectOnBlur={false}
          selection
          value={pipelineTemplate?.canTriggerPipelines ? TRUE : FALSE || ''}
          options={triggerPipelinesOptions}
          style={{ marginBottom: themeContext.margin.xxlarge }}
          upward={false}
          onChange={(e, { value }) => handleTriggerPipelinesChange(value)}
        />

        {pipelineTemplate?.trigger === Enums.PipelineTrigger.SCHEDULE && (
          <SC.CronContainer>
            <ReCron
              activeTab={activeCronTab}
              value={
                // Adding seconds back in
                pipelineTemplate?.schedule
                  ? `* ${pipelineTemplate?.schedule}`
                  : DEFAULT_CRON_VAL
              }
              onChange={handleScheduleChange}
              tabs={[Tab.MINUTES, Tab.HOURS, Tab.DAY]}
              localization={{
                quartz: {
                  day: {
                    dayOfWeekAnd: {
                      label: 'Specific day of week',
                    },
                    dayOfMonthAnd: {
                      label: 'Specific day of month',
                    },
                  },
                  minute: {
                    increment: {
                      label1: 'Every',
                      label2: 'minute(s)',
                    },
                    and: {
                      label: 'Specific minute',
                    },
                  },
                  hour: {
                    increment: {
                      label1: 'Every',
                      label2: 'hour(s)',
                    },
                    and: {
                      label: 'Specific hour',
                    },
                  },
                },
              }}
            />
          </SC.CronContainer>
        )}
      </StageInner>
      <ActionBar
        text={`Keep it going!`}
        primaryButton={
          <FeatureButton
            size={FeatureButtonSize.WIDE}
            action={processAction}
            color={themeContext.colors.general.green}
            text={'Continue to Jobs'}
            isDisabled={
              !pipelineTemplate?.trigger ||
              (pipelineTemplate.trigger === Enums.PipelineTrigger.SCHEDULE &&
                !pipelineTemplate.schedule)
            }
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActivePipelineSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default PipelineTriggerStage;
