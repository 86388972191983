import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import IntegrationConfigurationAdvancedStage from '../../components/IntegrationItemStages/IntegrationConfigurationStages/1-advanced/IntegrationConfigurationAdvancedStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import {
  IntegrationConfigurationSubStage,
  IntegrationStage,
} from '../../store/integration-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getIntegrationStageTitle,
  getIntegrationSubStageContent,
} from '../../util/integration-stage-content/IntegrationStageContent';

const IntegrationItemConfiguration: FC = () => {
  const integrationStageState = useSelector(
    (state: RootState) => state.integrationStage,
  );

  const activeStage = IntegrationStage.CONFIGURATION;
  const activeSubStage =
    integrationStageState.stages[activeStage].activeSubStage;

  const stageTitle = getIntegrationStageTitle(activeStage);
  const subStageContent = getIntegrationSubStageContent(
    activeStage,
    activeSubStage,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case IntegrationConfigurationSubStage.ADVANCED:
        return <IntegrationConfigurationAdvancedStage />;
      default:
        return <IntegrationConfigurationAdvancedStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: integrationStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default IntegrationItemConfiguration;
