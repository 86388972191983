import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'lodash';
import React, { FC, useContext } from 'react';
import { Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import {
  DefaultPopupStyles,
  StyledSubHeader,
  StyledText,
} from '../../main/theme';
import FeatureButton from '../FeatureButton/FeatureButton';

const CARD_WIDTH = 245;
const CARD_HEIGHT = 100;
const IMAGE_SIZE = 60;
const TITLE_TRUNCATE_LIMIT = 20;
const BODY_TRUNCATE_LIMIT = 45;

interface CardProps {
  title: string;
  body?: string;
  image?: string;
  action?: () => void;
  style?: React.CSSProperties;
  complete?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  > div:last-of-type {
    width: calc(
      100% - (${({ theme }) => parseInt(theme.margin.standard) + IMAGE_SIZE}px)
    );
    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: left;

    > p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Image = styled.div<{ image }>`
  position: relative;

  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;

  margin-right: ${({ theme }) => theme.margin.standard};

  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${({ theme }) => theme.colors.system.white}
    url(${({ image }) => image}) no-repeat center / 75%;

  > svg {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }
`;

const CardTitle = styled(StyledSubHeader)<{ hasBody: boolean }>`
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  ${({ hasBody }) => !hasBody && `margin-bottom: 0;`};
`;

const Card: FC<CardProps> = ({
  title,
  body,
  image,
  action,
  style,
  complete,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <FeatureButton
      action={action}
      size={CARD_WIDTH}
      height={CARD_HEIGHT}
      color={themeContext.colors.system.offWhite}
      icon={
        <Popup
          disabled={title.length <= TITLE_TRUNCATE_LIMIT}
          content={title}
          position="top center"
          style={DefaultPopupStyles}
          hideOnScroll={true}
          trigger={
            <Wrapper>
              {image && (
                <Image image={image}>
                  {complete && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color={themeContext.colors.general.green}
                      size={'lg'}
                      title={'Published'}
                    />
                  )}
                </Image>
              )}
              <div>
                <CardTitle hasBody={!!body?.length}>
                  {truncate(title, { length: TITLE_TRUNCATE_LIMIT })}
                </CardTitle>
                <StyledText>
                  {truncate(body, { length: BODY_TRUNCATE_LIMIT })}
                </StyledText>
              </div>
            </Wrapper>
          }
        />
      }
      style={{ overflow: 'hidden', ...style }}
    />
  );
};

export default Card;
