import { EntityType } from '../../enums';
import { defaultTheme } from '../../main/theme';

const EntityColorMap: Record<EntityType, string> = {
  [EntityType.AGGREGATION]: defaultTheme.colors.general.purple,
  [EntityType.AGGREGATION_MEASURE]: defaultTheme.colors.general.purple,
  [EntityType.API]: defaultTheme.colors.general.purple,
  [EntityType.API_KEY]: defaultTheme.colors.general.purple,
  [EntityType.CMS]: defaultTheme.colors.general.purple,
  [EntityType.COLUMN]: defaultTheme.colors.general.purple,
  [EntityType.DATASET]: defaultTheme.colors.general.green,
  [EntityType.DATA_GROUP]: defaultTheme.colors.general.purple,
  [EntityType.DATA_LINK]: defaultTheme.colors.general.purple,
  [EntityType.DATA_VIEW]: defaultTheme.colors.general.purple,
  [EntityType.FORM]: defaultTheme.colors.general.purple,
  [EntityType.LIST]: defaultTheme.colors.general.green,
  [EntityType.PORTAL]: defaultTheme.colors.general.purple,
  [EntityType.PROJECT]: defaultTheme.colors.general.purple,
  [EntityType.ORGANISATION]: defaultTheme.colors.general.yellow,
  [EntityType.SUPER_COLUMN]: defaultTheme.colors.general.purple,
  [EntityType.TEAM]: defaultTheme.colors.general.blue,
  [EntityType.USER]: defaultTheme.colors.general.sea,
  [EntityType.CHART]: defaultTheme.colors.general.purple,
  [EntityType.GRAPH]: defaultTheme.colors.general.purple,
  [EntityType.INTEGRATION]: defaultTheme.colors.general.red,
  [EntityType.PIPELINE]: defaultTheme.colors.general.red,
  [EntityType.NOTIFICATION]: defaultTheme.colors.general.red,
};

export default EntityColorMap;
