import { Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialCMS from './initial-cms';

type cms = Interfaces.Connection | Interfaces.ConnectionOutput;

export interface CMSState {
  data: cms;
  loading: boolean;
  error: string | null;
}

const initialState: CMSState = {
  data: InitialCMS,
  loading: false,
  error: null,
};

const CMSSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    // Reset
    resetCMS(state) {
      state.data = InitialCMS;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchCMSStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCMSSuccess(state, action: PayloadAction<cms>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchCMSFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { resetCMS, fetchCMSStart, fetchCMSSuccess, fetchCMSFailure } =
  CMSSlice.actions;

export default CMSSlice.reducer;
