import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChartBar,
  faDatabase,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../enums/ProjectStage';
import useProject from '../../hooks/project/UseProject';
import { BackNavigationItem } from '../../main/theme';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../store/project-stage';
import { ProjectGraphSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavIcons = {
  [ProjectGraphSubStage.NAME]: faInfoCircle,
  [ProjectGraphSubStage.DATA]: faDatabase,
  [ProjectGraphSubStage.GRAPH]: faChartBar,
};

const GraphNavigation: FC = () => {
  const dispatch = useDispatch();

  const { projectAccessLevel } = useProject();
  const themeContext = useContext(ThemeContext);

  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );
  const graphStage = projectStageState.stages[ProjectStage.GRAPH];
  const activeSubStage = graphStage.activeSubStage;

  return (
    <>
      <BackNavigationItem
        key={`graph-stage-back`}
        onClick={() => {
          dispatch(updateActiveProjectStage(ProjectStage.CHARTS));
        }}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={themeContext.colors.system.white}
        />

        <NavTitle text={'Back To Dashboard'} />
      </BackNavigationItem>

      {projectAccessLevel === Enums.AccessLevel.MANAGE &&
        Object.values(graphStage.subStages).map((subStage) => {
          const subStageName = subStage.subStage as ProjectGraphSubStage;

          return (
            <NavItem
              key={`graph-stage-${subStageName}`}
              onClick={() =>
                dispatch(updateActiveProjectSubStage(subStageName))
              }
              isActive={activeSubStage === subStageName}
            >
              <FontAwesomeIcon
                icon={NavIcons[subStageName]}
                color={themeContext.colors.system.white}
              />

              <NavTitle text={subStage.title} />
            </NavItem>
          );
        })}
    </>
  );
};

export default GraphNavigation;
