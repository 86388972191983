import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectConfigureAccessStage from '../../components/ProjectItem/ProjectItemConfigurationStages/1-access/ProjectConfigureAccessStage';
import ProjectConfigureAdvancedStage from '../../components/ProjectItem/ProjectItemConfigurationStages/2-advanced/ProjectConfigureAdvancedStage';
import Page from '../../main/Page';
import { ProjectConfigureSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectItemConfiguration: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ProjectStage.CONFIGURATION;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectConfigureSubStage.ACCESS:
        return <ProjectConfigureAccessStage />;
      case ProjectConfigureSubStage.ADVANCED:
        return <ProjectConfigureAdvancedStage />;
      default:
        return <ProjectConfigureAccessStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={stageContent.image}
        title={stageContent.title}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default ProjectItemConfiguration;
