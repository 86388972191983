import { FC, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DefaultPopupStyles, StyledH3, StyledText } from '../../main/theme';
import { MetricNumberFormatter } from '../../util/metric-formatter/MetricNumberFormatter';
import * as SC from './styled';

interface UsageMetricCardProps {
  title: string;
  description?: string;
  icon: IconDefinition;
  usage: number;
  quota: number;
  iconColor?: string;
}

const UsageMetricCard: FC<UsageMetricCardProps> = ({
  title,
  description,
  icon,
  usage,
  quota,
  iconColor,
}) => {
  const themeContext = useContext(ThemeContext);
  const formattedUsage = MetricNumberFormatter(usage, 1);
  const formattedQuota = MetricNumberFormatter(quota, 1);

  const formattedPopoverQuota = quota.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    { minimumFractionDigits: 0 },
  );

  const formattedPopoverUsage = usage.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    { minimumFractionDigits: 0 },
  );

  return (
    <>
      {quota !== 0 && (
        <SC.CardContainer>
          <SC.CardHeader>
            <FontAwesomeIcon size={'2x'} color={iconColor} icon={icon} />
            <StyledH3 style={{ marginLeft: themeContext.margin.standard }}>
              {title}
            </StyledH3>
          </SC.CardHeader>
          {description && (
            <StyledText style={{ marginBottom: themeContext.margin.xxlarge }}>
              {description}
            </StyledText>
          )}
          <Popup
            content={`${
              usage >= quota && quota != -1
                ? formattedPopoverQuota
                : formattedPopoverUsage
            }/${quota === -1 ? '∞' : formattedPopoverQuota}`}
            position="top center"
            style={DefaultPopupStyles}
            trigger={
              <div>
                <SC.LargeMetric>
                  {usage >= quota && quota != -1
                    ? formattedQuota
                    : formattedUsage}
                </SC.LargeMetric>
                {quota !== -1 ? (
                  <SC.SmallMetric>/{formattedQuota}</SC.SmallMetric>
                ) : (
                  <SC.SmallMetric>
                    /
                    <FontAwesomeIcon icon={faInfinity} />
                  </SC.SmallMetric>
                )}
              </div>
            }
          />
        </SC.CardContainer>
      )}
    </>
  );
};

export default UsageMetricCard;
