import styled from 'styled-components';

const NOTIFICATION_PANEL_WIDTH = 490;
const NOTIFICATION_PANEL_LEFT_POS_OPEN = 70;
const NOTIFICATION_PANEL_LEFT_POS_CLOSED = -420;
const NOTIFICATION_PANEL_SHADOW = '4px 0px 10px 0px #00000026';
const SITE_WIDE_BANNER_OFFSET = 54;

export const NotificationContainer = styled.div<{
  siteWideBannerActive?: boolean;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.general.white};
  width: ${NOTIFICATION_PANEL_WIDTH}px;
  height: calc(
    100% -
      ${({ siteWideBannerActive }) =>
        siteWideBannerActive ? `${SITE_WIDE_BANNER_OFFSET}px` : 0}
  );
  left: ${NOTIFICATION_PANEL_LEFT_POS_CLOSED}px;
  top: ${({ siteWideBannerActive }) =>
    siteWideBannerActive ? `${SITE_WIDE_BANNER_OFFSET}px` : 0};
  z-index: 25;
  transition: left 0.5s;
  border-radius: 0 ${({ theme }) => theme.borders.radius}
    ${({ theme }) => theme.borders.radius} 0;
  box-shadow: ${NOTIFICATION_PANEL_SHADOW};

  &.visible {
    left: ${NOTIFICATION_PANEL_LEFT_POS_OPEN}px;
  }

  div {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 0;
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.padding.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const NotificationPanelContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${({ theme }) => theme.padding.xlarge};
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
  align-self: center;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: 'row';

  > div:first-of-type {
    margin-right: ${({ theme }) => theme.margin.standard};
  }
`;
