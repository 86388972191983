import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FormQuestionDisplayType } from '@configur-tech/upit-core-types/lib/enums';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import useForm from '../../../../hooks/form/UseForm';
import useInput from '../../../../hooks/input/useInput';
import {
  EndInputButtonWrapper,
  StageInner,
  StyledBodySubHeader,
  StyledDropdown,
  StyledFormHeader,
  StyledQuestionBuilderContainer,
  StyledTextArea,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchFormSuccess } from '../../../../store/project-form';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { PageType } from './ProjectItemFormBuildStage';

enum HiddenDefaultValueType {
  SCHEMA = 'schema',
  VALUE = 'value',
  DATE = 'date',
}

const emptyQuestion = {
  question: '',
  field: { name: '' },
  displayType: '' as FormQuestionDisplayType,
  status: Enums.FormQuestionStatus.NOT_COMPLETED,
  value: '',
};

const textDisplayTypeOptions = [
  { value: FormQuestionDisplayType.TEXT, text: 'Text' },
  { value: FormQuestionDisplayType.TEXT_AREA, text: 'Text Area' },
];

const listDisplayTypeOptions = [
  { value: FormQuestionDisplayType.MULTIPLE_CHOICE, text: 'Buttons' },
  { value: FormQuestionDisplayType.SELECT, text: 'Dropdown' },
];

const dataValidationTypeToDisplayType = (
  dataValidationType?: Enums.ValueDataType,
): FormQuestionDisplayType => {
  switch (dataValidationType) {
    case Enums.ValueDataType.BOOL:
      return FormQuestionDisplayType.BOOLEAN;
    case Enums.ValueDataType.DATE:
      return FormQuestionDisplayType.DATE;
    case Enums.ValueDataType.NUMBER:
    case Enums.ValueDataType.PHONE_NUM:
      return FormQuestionDisplayType.NUMBER;
    default:
      return FormQuestionDisplayType.TEXT;
  }
};

const StyledFormTextArea = styled(StyledTextArea)`
  width: 100%;
`;

const DeleteButtonWrapper = styled.div`
  width: 100%;
`;

const HiddenValueWrapper = styled.div`
  > div {
    width: 100% !important;
  }
`;

const QuestionEditor: FC<{
  activeQuestionGroup: string;
  setActiveQuestionGroup: (value: string) => void;
  hideEditor: () => void;
  schemaData: Interfaces.Field[] | undefined;
  activeQuestionId: string;
  mode: PageType;
}> = ({
  activeQuestionGroup,
  setActiveQuestionGroup,
  hideEditor,
  schemaData,
  activeQuestionId,
  mode,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { form, editForm } = useForm();
  const { buildInput } = useInput();

  const allQuestions = form?.questionGroups
    .map((questionGroup) => questionGroup.questions)
    .flat();

  const [activeQuestion, setActiveQuestion] =
    useState<Interfaces.FormQuestionOutput>();

  const [activeGroup, setActiveGroup] = useState<string>();

  const [hiddenDefaultValueType, setHiddenDefaultValueType] =
    useState<HiddenDefaultValueType>(HiddenDefaultValueType.SCHEMA);

  const [inProgressQuestion, setInProgressQuestion] = useState<
    Interfaces.FormQuestion | Interfaces.FormQuestionOutput
  >(
    mode === PageType.CREATE_QUESTION || !activeQuestion
      ? emptyQuestion
      : activeQuestion,
  );

  const [questionGroupOptions, setQuestionGroupOptions] = useState<
    DropdownItemProps[]
  >([]);

  useEffect(() => {
    if (activeQuestionId) {
      setActiveQuestion(
        allQuestions?.find((question) => question._id === activeQuestionId),
      );
    }
  }, [activeQuestionId, allQuestions]);

  useEffect(() => {
    setActiveGroup(
      form?.questionGroups.find((group) =>
        group.questions.some((question) => question._id === activeQuestionId),
      )?.groupName || activeQuestionGroup,
    );
  }, [activeQuestionGroup, activeQuestionId, form?.questionGroups]);

  useEffect(() => {
    if (form?.questionGroups) {
      setQuestionGroupOptions(
        form?.questionGroups.map((group, i) => {
          return {
            key: `question-group-${group.groupName}-${i}`,
            value: group.groupName,
            text: group.groupName,
          };
        }),
      );
    }
  }, [form?.questionGroups]);

  useEffect(() => {
    if (mode === PageType.UPDATE_QUESTION && activeGroup) {
      setActiveQuestionGroup(activeGroup);
    }
  }, [activeGroup, mode, setActiveQuestionGroup]);

  useEffect(() => {
    if (mode === PageType.CREATE_QUESTION) {
      setInProgressQuestion(emptyQuestion);
    }
    if (activeQuestion && mode === PageType.UPDATE_QUESTION) {
      setInProgressQuestion(activeQuestion);
    }
  }, [mode, activeQuestion, activeQuestionGroup]);

  useEffect(() => {
    const schemaField = schemaData?.find(
      (schemaItem) => schemaItem.name === inProgressQuestion.field.name,
    );
    const newDisplayType = schemaField?.dataValidation?.dataValidationType;
    let existingDisplayType;

    if (
      newDisplayType === Enums.ValueDataType.TEXT &&
      schemaField?.dataValidation?.constraints?.listValues
    ) {
      existingDisplayType = [
        FormQuestionDisplayType.MULTIPLE_CHOICE,
        FormQuestionDisplayType.SELECT,
      ].includes(inProgressQuestion.displayType)
        ? inProgressQuestion.displayType
        : FormQuestionDisplayType.MULTIPLE_CHOICE;
    } else if (newDisplayType === Enums.ValueDataType.TEXT) {
      existingDisplayType = [
        FormQuestionDisplayType.TEXT,
        FormQuestionDisplayType.TEXT_AREA,
      ].includes(inProgressQuestion.displayType)
        ? inProgressQuestion.displayType
        : FormQuestionDisplayType.TEXT;
    }

    if (inProgressQuestion.displayType === FormQuestionDisplayType.HIDDEN) {
      existingDisplayType = FormQuestionDisplayType.HIDDEN;

      if (inProgressQuestion.setValueAsSubmittedDate) {
        setHiddenDefaultValueType(HiddenDefaultValueType.DATE);
      }

      if ((inProgressQuestion.value as string).length) {
        setHiddenDefaultValueType(HiddenDefaultValueType.VALUE);
      }
    }

    setInProgressQuestion((question) => {
      return {
        ...question,
        field: (schemaField as Interfaces.Field) || question.field,
        displayType:
          existingDisplayType ||
          dataValidationTypeToDisplayType(newDisplayType),
      };
    });
  }, [
    inProgressQuestion.displayType,
    inProgressQuestion.field.name,
    inProgressQuestion.setValueAsSubmittedDate,
    inProgressQuestion.value,
    schemaData,
  ]);

  const generateColumnOptions = () => {
    return schemaData
      ?.filter(
        (sf) =>
          sf.name === inProgressQuestion.field.name ||
          !allQuestions?.find(
            (q) =>
              q._id !==
                (inProgressQuestion as Interfaces.FormQuestionOutput)._id &&
              q.field.name === sf.name,
          ),
      )
      .map((schemaItem) => ({
        key: schemaItem.name,
        value: schemaItem.name,
        text: schemaItem.name,
        description: startCase(
          schemaItem?.dataValidation?.dataValidationType.toLocaleLowerCase(),
        ),
      }));
  };

  const submitQuestion = async (question: Interfaces.FormQuestion) => {
    if (mode === PageType.CREATE_QUESTION) {
      const cloned = cloneDeep(form);
      const clonedQuestion = { ...cloneDeep(question) };
      const groupIndex = cloned.questionGroups.findIndex(
        (group) => group.groupName === activeQuestionGroup,
      );
      cloned.questionGroups[groupIndex].questions.push(clonedQuestion);

      // Save updated form
      dispatch(showLoading({ text: 'Updating Form...' }));
      const updatedForm = await editForm(cloned);

      dispatch(fetchFormSuccess(updatedForm));
      dispatch(hideLoading());
    }

    if (mode === PageType.UPDATE_QUESTION) {
      const cloned = cloneDeep(form);
      const clonedQuestion = { ...cloneDeep(question) };
      const groupIndex = cloned.questionGroups.findIndex(
        (group) => group.groupName === activeQuestionGroup,
      );
      const questionIndex = cloned.questionGroups[
        groupIndex
      ].questions.findIndex((q) => q._id === activeQuestionId);

      const insertItemAtIndex = (item, index: number, array) => {
        const prev = array.slice(0, index);
        const post = array.slice(index + 1);
        return [...prev, item, ...post];
      };

      cloned.questionGroups[groupIndex].questions = insertItemAtIndex(
        clonedQuestion,
        questionIndex,
        cloned.questionGroups[groupIndex].questions,
      );

      // Save updated form
      dispatch(showLoading({ text: 'Updating Form...' }));
      const updatedForm = await editForm(cloned);

      dispatch(fetchFormSuccess(updatedForm));
      dispatch(hideLoading());
      1;
    }
  };

  const deleteQuestion = (id: string) => {
    const cloned = cloneDeep(form);
    const groupIndex = cloned.questionGroups.findIndex(
      (group) => group.groupName === activeQuestionGroup,
    );

    cloned.questionGroups[groupIndex].questions = cloned.questionGroups[
      groupIndex
    ].questions.filter((question) => question._id !== id);

    dispatch(fetchFormSuccess(cloned));
  };

  return (
    <StyledQuestionBuilderContainer>
      <StageInner>
        <StyledFormHeader>
          {mode === PageType.UPDATE_QUESTION
            ? 'Update Question'
            : 'Create Question'}
        </StyledFormHeader>
        <StyledBodySubHeader>
          Which question group does this question belong to?
        </StyledBodySubHeader>
        <StyledDropdown
          selectOnBlur={false}
          disabled={mode === PageType.UPDATE_QUESTION}
          placeholder="Select Question Group"
          fluid
          selection
          options={questionGroupOptions || []}
          value={activeQuestionGroup}
          onChange={(_, data) => setActiveQuestionGroup(data.value)}
        />
        <StyledBodySubHeader>
          Which column in your dataset will this question populate?
        </StyledBodySubHeader>

        <StyledDropdown
          selectOnBlur={false}
          placeholder="Select a column"
          fluid
          selection
          options={generateColumnOptions() || []}
          value={inProgressQuestion.field.name}
          onChange={(e, data) => {
            setInProgressQuestion({
              ...inProgressQuestion,
              field: schemaData?.find((sf) => sf.name === data.value) || {
                name: data.value,
              },
            });
          }}
        />

        <StyledBodySubHeader>Is this a hidden field?</StyledBodySubHeader>

        <StyledDropdown
          selectOnBlur={false}
          disabled={!inProgressQuestion.field.name}
          placeholder="Select a column"
          fluid
          selection
          options={[
            {
              key: `hidden-true`,
              value: true,
              text: 'Yes',
            },
            {
              key: `hidden-false`,
              value: false,
              text: 'No',
            },
          ]}
          value={
            inProgressQuestion.displayType === FormQuestionDisplayType.HIDDEN
          }
          onChange={(e, data) => {
            setInProgressQuestion({
              ...inProgressQuestion,
              question: data.value
                ? `Hidden - ${inProgressQuestion.field.name}`
                : '',
              displayType: data.value
                ? FormQuestionDisplayType.HIDDEN
                : ('' as FormQuestionDisplayType),
            });
          }}
        />

        {inProgressQuestion.displayType === FormQuestionDisplayType.HIDDEN && (
          <>
            <StyledBodySubHeader>
              Would you like to set a default value for this hidden column?
            </StyledBodySubHeader>
            <StyledDropdown
              selectOnBlur={false}
              disabled={!inProgressQuestion.field.name}
              placeholder="Select an option"
              fluid
              selection
              options={[
                {
                  key: `default-value-no`,
                  value: HiddenDefaultValueType.SCHEMA,
                  text: 'No, use schema default',
                },
                {
                  key: `default-value-yes`,
                  value: HiddenDefaultValueType.VALUE,
                  text: 'Yes, provide default value',
                },
                {
                  key: `default-value-data`,
                  value: HiddenDefaultValueType.DATE,
                  text: 'Yes, use submitted date time',
                },
              ]}
              value={hiddenDefaultValueType}
              onChange={(e, data) => {
                setHiddenDefaultValueType(data.value);
                setInProgressQuestion({
                  ...inProgressQuestion,
                  setValueAsSubmittedDate:
                    data.value === HiddenDefaultValueType.DATE,
                });
              }}
            />
          </>
        )}

        {inProgressQuestion.displayType === FormQuestionDisplayType.HIDDEN &&
          hiddenDefaultValueType === HiddenDefaultValueType.VALUE && (
            <HiddenValueWrapper>
              <StyledBodySubHeader>
                What would you like to set as a default value for this hidden
                column?
              </StyledBodySubHeader>

              {buildInput(
                schemaData?.find(
                  (sf) => sf.name === inProgressQuestion.field.name,
                ) || ({} as Interfaces.Field),
                schemaData?.find(
                  (sf) => sf.name === inProgressQuestion.field.name,
                )?.dataValidation?.dataValidationType ||
                  ('' as Enums.ValueDataType),
                inProgressQuestion.value,
                (field, value) =>
                  setInProgressQuestion({
                    ...inProgressQuestion,
                    value,
                  }),
                undefined,
              )}
            </HiddenValueWrapper>
          )}

        {inProgressQuestion.displayType !== FormQuestionDisplayType.HIDDEN && (
          <>
            {inProgressQuestion.field.dataValidation?.dataValidationType ===
              Enums.ValueDataType.TEXT && (
              <>
                <StyledBodySubHeader>
                  How would you like to display this question?
                </StyledBodySubHeader>
                <StyledDropdown
                  selectOnBlur={false}
                  disabled={!inProgressQuestion.field.name}
                  placeholder="Select a display type"
                  fluid
                  selection
                  value={inProgressQuestion.displayType}
                  options={
                    inProgressQuestion.field.dataValidation?.constraints
                      ?.listValues
                      ? listDisplayTypeOptions
                      : textDisplayTypeOptions
                  }
                  onChange={(e, data) =>
                    setInProgressQuestion({
                      ...inProgressQuestion,
                      displayType: data.value,
                    })
                  }
                />
              </>
            )}

            <StyledBodySubHeader>
              What question would you like to display?
            </StyledBodySubHeader>
            <StyledFormTextArea
              minRows={5}
              maxRows={10}
              disabled={!inProgressQuestion.field.name}
              placeholder="Input the question"
              value={inProgressQuestion.question || ''}
              onChange={(e) =>
                setInProgressQuestion({
                  ...inProgressQuestion,
                  question: e.target.value,
                })
              }
            />
          </>
        )}

        <EndInputButtonWrapper>
          <FeatureButton
            action={() => {
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Cancel'}
          />
          <FeatureButton
            action={() => {
              setInProgressQuestion({
                ...inProgressQuestion,
              });
              submitQuestion(inProgressQuestion);
              setInProgressQuestion(emptyQuestion);
              setActiveQuestionGroup('');
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={
              mode === PageType.UPDATE_QUESTION
                ? 'Update Question'
                : 'Create Question'
            }
            isDisabled={
              (mode === PageType.UPDATE_QUESTION &&
                activeQuestion === inProgressQuestion) ||
              (mode === PageType.CREATE_QUESTION &&
                !inProgressQuestion?.displayType) ||
              (inProgressQuestion.displayType ===
                FormQuestionDisplayType.HIDDEN &&
                hiddenDefaultValueType === HiddenDefaultValueType.VALUE &&
                !inProgressQuestion.value) ||
              (inProgressQuestion.displayType !==
                FormQuestionDisplayType.HIDDEN &&
                !inProgressQuestion?.question) ||
              !inProgressQuestion.field
            }
          />
        </EndInputButtonWrapper>

        {mode === PageType.UPDATE_QUESTION && (
          <DeleteButtonWrapper>
            <FeatureButton
              action={() => {
                deleteQuestion(activeQuestionId);
                setInProgressQuestion(emptyQuestion);
                setActiveQuestionGroup('');
                hideEditor();
              }}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.red}
              text={'Delete Question'}
              containerStyle={{ padding: `${themeContext.padding.standard} 0` }}
            />
          </DeleteButtonWrapper>
        )}
      </StageInner>
    </StyledQuestionBuilderContainer>
  );
};

export default QuestionEditor;
