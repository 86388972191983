import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import usePipelineEntityHelper from '../../../hooks/pipeline-mapping/UsePipelineEntityHelper';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import { initialConditionalJobParams } from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import FilterBuilder from '../filter/FilterBuilder';
import { DropdownItemPropsWithValidation } from '../filter/FilterModal';

const FILTER_FIELD = 'condition';

export interface JobFilterModalProps {
  jobIndex: number;
  onChange: (data) => void;
  datasetMetaId?: string;
}

const JobFilterModalComponent: FC<JobFilterModalProps> = ({
  jobIndex,
  onChange,
  datasetMetaId,
}) => {
  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();
  const { getLastDataJob, getEntitySchema } = usePipelineEntityHelper();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const integrationTemplatesState = useSelector(
    (state: RootState) => state.integrationTemplates,
  );
  const integrationTemplates: IntegrationTemplateItem[] =
    integrationTemplatesState.data.data;

  const queries = useSelector((state: RootState) => state.queries)?.data.data;

  const { pipelineTemplate } = usePipelineTemplate();

  const [pipelineJob, setPipelineJob] =
    useState<Interfaces.ConditionalJobParams>(initialConditionalJobParams);

  const [prevDataJob, setPrevDataJob] = useState<Interfaces.PipelineJob>();
  const [activeCollectionSchemaFields, setActiveCollectionSchemaFields] =
    useState<Interfaces.SchemaField[]>([]);

  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();

  const [filter, setFilter] = useState<DynamicConditionalField>({
    operator: Enums.AggregationFilterOperator.AND,
    value: [],
    active: true,
  });
  const [entitySchema, setEntitySchema] = useState<Interfaces.SchemaField[]>();

  const isEditing = jobIndex > -1;
  const prevDataJobIsQuery =
    prevDataJob?.jobType === Enums.PipelineJobType.QUERY;

  // If editing existing, set data
  useEffect(() => {
    if (
      pipelineTemplate?.jobs[jobIndex]?.jobType !== Enums.PipelineJobType.FILTER
    ) {
      return;
    }

    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      setPipelineJob(
        pipelineTemplate.jobs[jobIndex]
          .jobParams as Interfaces.ConditionalJobParams,
      );

      setFilter(
        ((
          pipelineTemplate.jobs[jobIndex]
            .jobParams as Interfaces.ConditionalJobParams
        ).condition[0] as unknown as Interfaces.DynamicFilterField) || [
          {
            operator: Enums.AggregationFilterOperator.AND,
            value: [],
            active: true,
          },
        ],
      );
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // Re-map activeDataCollection schema
  useEffect(() => {
    if (activeDataCollectionItem?._id) {
      setActiveCollectionSchemaFields(
        activeDataCollectionItem.schemaData.map((field) =>
          Mappers.SchemaFieldMapper.dbSchemaToDomainSchema(field),
        ),
      );
    }
  }, [activeDataCollectionItem?._id, activeDataCollectionItem?.schemaData]);

  // Get last job from pipeline
  useEffect(() => {
    if (pipelineTemplate?._id) {
      const lastJob =
        pipelineTemplate.jobs[
          isEditing ? jobIndex - 1 : pipelineTemplate.jobs.length - 1
        ];

      if (lastJob) {
        setPrevDataJob(getLastDataJob(pipelineTemplate.jobs, lastJob));
      }
    }
  }, [
    getLastDataJob,
    isEditing,
    jobIndex,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  // Use origin dataset if first job, or data schema from previous job
  useEffect(() => {
    if (activeCollectionSchemaFields) {
      if (!prevDataJob) {
        setEntitySchema(activeCollectionSchemaFields);
        return;
      }

      setEntitySchema(
        getEntitySchema(
          prevDataJob,
          datasetMetas,
          integrationTemplates,
          queries,
        ),
      );
    }
  }, [
    activeCollectionSchemaFields,
    datasetMetas,
    getEntitySchema,
    integrationTemplates,
    prevDataJob,
    queries,
  ]);

  // Set field options for filtering
  useEffect(() => {
    if (entitySchema) {
      const options: DropdownItemProps[] = entitySchema.map((f, i) => {
        return {
          key: `field-${f.field}-${i}`,
          value: `${prevDataJobIsQuery ? f.field : f.fieldId || f.field}***${
            datasetMetaId || datasetMeta?._id
          }***${f.field}`,
          text: f.field,
        };
      });

      setFieldOptions(options);
    }
  }, [
    datasetMeta?._id,
    datasetMetaId,
    entitySchema,
    prevDataJob?.jobType,
    prevDataJobIsQuery,
  ]);

  const handleFilterChange = (filter) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[FILTER_FIELD] = [filter];

    setPipelineJob(cloned);
    setFilter(filter);
  };

  return (
    <FilterBuilder
      entitySchema={entitySchema?.map(
        (field) =>
          Mappers.SchemaFieldMapper.domainSchemaToDbSchema(
            prevDataJobIsQuery ? { ...field, fieldId: field.field } : field,
          ) as Interfaces.FieldOutput,
      )}
      fieldOptions={fieldOptions}
      setFilter={handleFilterChange}
      filter={filter}
      schemaFieldOutput={true}
      smallHeadings={true}
      datasetMetaId={datasetMetaId}
    />
  );
};

export default JobFilterModalComponent;
