import React, { FC } from 'react';
import OrganisationOverview from '../../components/OrganisationOverview/OrganisationOverview';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums/RouteName';
import PageContainer from '../../main/PageContainer';

const OrganisationItemPage: FC = () => {
  return (
    <>
      <PrimaryNavigationContainer route={RouteName.ORGANISATION_ITEM} />
      <PageContainer>{<OrganisationOverview />}</PageContainer>
    </>
  );
};

export default OrganisationItemPage;
