import { Interfaces } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { EntityType } from '../../../enums/EntityType';
import usePermission from '../../../hooks/permission/UsePermission';
import useTeam from '../../../hooks/team/UseTeam';
import useUser from '../../../hooks/user/UseUser';
import { ListValueWithUser } from '../../../interfaces/ListValue';
import { StyledBodySubHeader, StyledDropdownWide } from '../../../main/theme';
import { TeamsItemOutput } from '../../../services/team/TeamService';
import { UserItemOutput } from '../../../services/user/UserService';
import { hideLoading, showLoading } from '../../../store/loading';
import { RootState } from '../../../store/rootReducer';
import { TeamsState } from '../../../store/teams';
import { UsersState } from '../../../store/users';
import AvatarIconMap from '../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';

const formatOptions = (
  type: EntityType.USER | EntityType.TEAM,
  arr: Interfaces.TeamOutput[] | Interfaces.UserOutput[],
) => {
  return arr.map((e, i) => {
    return {
      key: `entry-${e._id}-${i}`,
      value: e._id,
      text: type === EntityType.TEAM ? e.name : `${e.firstName} ${e.lastName}`,
      image: {
        avatar: true,
        src:
          type === EntityType.TEAM
            ? AvatarIconMap[e.avatar]
            : UserIconMap[e.avatar],
      },
      description: startCase(type),
    };
  });
};

export interface UserListItemSection {
  setListItem: React.Dispatch<React.SetStateAction<ListValueWithUser>>;
  existingListItem?: {
    index: number;
    listItem: Interfaces.ListValue;
  };
}

const UserListItemSection: FC<UserListItemSection> = ({
  existingListItem,
  setListItem,
}) => {
  const dispatch = useDispatch();
  const { getUsers } = useUser();
  const { getTeams } = useTeam();
  const { isProTier } = usePermission();

  const usersState: UsersState = useSelector((state: RootState) => state.users);
  const users: UserItemOutput[] = usersState.data;

  const teamsState: TeamsState = useSelector((state: RootState) => state.teams);
  const teams: TeamsItemOutput = teamsState.data;

  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<DropdownItemProps[]>([]);

  const [selectedUser, setSelectedUser] = useState<Interfaces.ListValue>();
  const [selectedTeam, setSelectedTeam] = useState<Interfaces.ListValue>();

  useEffect(() => {
    const existingUser = users.find(
      (user) => user.entity._id === existingListItem?.listItem.value,
    );

    if (existingUser) {
      setSelectedUser({ value: existingUser.entity._id });
    } else {
      const existingTeam = teams.data.find(
        (team) => team.entity._id === existingListItem?.listItem.value,
      );
      if (existingTeam) {
        setSelectedTeam({ value: existingTeam.entity._id });
      }
    }
  }, [existingListItem, users, teams]);

  useEffect(() => {
    if (!users.length) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Users...' }));
        await getUsers();

        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getUsers, users.length]);

  // Create user options
  useEffect(() => {
    if (users?.length) {
      const formattedUsers = formatOptions(
        EntityType.USER,
        users.map((u) => u.entity),
      );
      setUserOptions(formattedUsers);
    }
  }, [users]);

  useEffect(() => {
    if (isProTier && !teams.data.length) {
      (async () => {
        dispatch(showLoading({ text: 'Loading Teams...' }));
        await getTeams();
        dispatch(hideLoading());
      })();
    }
  }, [dispatch, getTeams, teams.data.length, isProTier]);

  // Create teams options
  useEffect(() => {
    if (isProTier && teams?.data.length) {
      const formattedTeams = formatOptions(
        EntityType.TEAM,
        teams.data.map((u) => u.entity),
      );
      setTeamsOptions(formattedTeams);
    }
  }, [teams, isProTier]);

  return (
    <>
      <StyledBodySubHeader>Select A User</StyledBodySubHeader>
      <StyledDropdownWide
        selectOnBlur={false}
        clearable
        disabled={selectedTeam && selectedTeam?.value !== ''}
        placeholder={'Select a User'}
        selection
        options={userOptions}
        value={selectedUser?.value}
        onChange={(e, data) => {
          setSelectedUser(data);

          const user = userOptions.find((u) => u.value === data.value);
          if (user) {
            setListItem({
              value: user.value as string,
              name: user.text as string,
              avatar: user.image ? user.image['src'] : undefined,
            });
          }
        }}
        search
      />

      {isProTier && (
        <>
          <StyledBodySubHeader>
            Select All Users From A Team
          </StyledBodySubHeader>
          <StyledDropdownWide
            selectOnBlur={false}
            clearable
            disabled={selectedUser && selectedUser?.value !== ''}
            placeholder={'Select a Team'}
            selection
            options={teamsOptions}
            value={selectedTeam?.value}
            onChange={(e, data) => {
              setSelectedTeam(data);

              const team = teamsOptions.find((u) => u.value === data.value);
              if (team) {
                setListItem({
                  value: team.value as string,
                  name: team.text as string,
                  avatar: team.image ? team.image['src'] : undefined,
                });
              }
            }}
            search
          />
        </>
      )}
    </>
  );
};

export default UserListItemSection;
