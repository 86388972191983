import { useCallback } from 'react';

export interface SortItem {
  id: string;
  desc: boolean;
}

interface useSortResult {
  getSort: (datasetMetaId: string) => SortItem;
  updateSort: (datasetMetaId: string, sort?: SortItem | undefined) => void;
}

const SORT_SUFFIX = '_SORT';

const useSort = (): useSortResult => {
  const getSort = useCallback((datasetMetaId: string) => {
    if (!datasetMetaId) {
      return;
    }
    const storedSort = localStorage.getItem(`${datasetMetaId}${SORT_SUFFIX}`);
    return storedSort ? JSON.parse(storedSort) : undefined;
  }, []);

  const updateSort = (datasetMetaId: string, sort?: SortItem) => {
    if (!datasetMetaId) {
      return;
    }

    if (sort) {
      localStorage.setItem(
        `${datasetMetaId}${SORT_SUFFIX}`,
        JSON.stringify(sort),
      );
    } else {
      localStorage.removeItem(`${datasetMetaId}${SORT_SUFFIX}`);
    }
  };

  return { getSort, updateSort };
};

export default useSort;
