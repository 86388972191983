import sandboxAvatar from '../../assets/icons/project/sandbox-project-icon.svg';
import defaultAvatar from '../../assets/icons/system/codex-icon.svg';
import integrationIcon from '../../assets/icons/system/integration-icon.svg';
import defaultDataset from '../../assets/icons/system/navigation/datasets-icon.svg';
import pipelineIcon from '../../assets/icons/system/pipeline-icon.svg';
import notificationIcon from '../../assets/icons/system/notification-icon.svg';
import defaultUser from '../../assets/icons/user/owl-user-icon.svg';
import { EntityType } from '../../enums';

const getDefaultAvatar = (dataType: EntityType): string => {
  switch (dataType) {
    case EntityType.DATASET:
      return defaultDataset;
    case EntityType.PROJECT:
      return sandboxAvatar;
    case EntityType.USER:
      return defaultUser;
    case EntityType.INTEGRATION:
      return integrationIcon;
    case EntityType.PIPELINE:
      return pipelineIcon;
    case EntityType.NOTIFICATION:
      return notificationIcon;
    default:
      return defaultAvatar;
  }
};

export default getDefaultAvatar;
