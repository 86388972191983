import { faArrowsAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

export interface DraggableGraphItemProps {
  text: string;
}

const DraggableGraphItem: FC<DraggableGraphItemProps> = ({ text }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <FeatureButton
      icon={
        <FontAwesomeIcon
          icon={faArrowsAlt}
          color={themeContext.colors.system.darkOffBlack}
        />
      }
      size={FeatureButtonSize.WIDE_SMALL}
      color={themeContext.colors.system.grey}
      text={text}
      containerStyle={{ width: '100%' }}
      style={{ width: '100%' }}
      faceStyle={{
        width: '100%',
      }}
      textStyle={{
        color: themeContext.colors.system.darkOffBlack,
        width: 'calc(100% - 1em)',
        textAlign: 'center',
        textTransform: 'capitalize',
      }}
    />
  );
};
export default DraggableGraphItem;
