import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const INPUT_HEIGHT = '38px';

export const DatePickerWrapper = styled.div`
  height: ${INPUT_HEIGHT};
  text-align: left;

  .react-datepicker-popper {
    top: -35px !important;
    left: 125px !important;
  }
`;

export const CalendarButton = styled.button<{
  disabled?: boolean;
  multiLine?: boolean;
}>`
  position: absolute;
  height: 37px;
  width: 32px;
  top: ${({ multiLine }) => (multiLine ? '-69px' : '-52px')};
  left: 1px;
  background: none;
  border: none;
  padding: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const CalendarIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  left: 10px;
  transition: color 0.3s;
  z-index: 10;
`;

export const StyledDropdownWrapper = styled.div<{
  multiLine?: boolean;
}>`
  position: relative;

  .text {
    line-height: ${({ multiLine }) => (multiLine ? 'initial' : 'inherit')};
  }
`;

export const Wrapper = styled.div<{
  active: boolean;
  hideIcon: boolean | undefined;
  disabled?: boolean;
  isLiveEditing?: boolean;
}>`
  display: flex;
  align-items: center;
  background: ${({ active, disabled, isLiveEditing, theme }) =>
    isLiveEditing && !active
      ? 'none'
      : disabled
      ? 'rgba(239, 239, 239, 0.3)'
      : theme.colors.general.white};

  width: 250px;
  max-width: 100%;
  height: ${({ isLiveEditing }) => (!isLiveEditing ? INPUT_HEIGHT : '100%')};

  padding: ${({ theme, isLiveEditing, active }) =>
    !isLiveEditing
      ? `1px ${theme.margin.standard}`
      : !active
      ? `1px ${theme.margin.standard}`
      : `0 9px`};

  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.general.blue : theme.colors.system.grey};
  ${({ active, isLiveEditing }) => isLiveEditing && !active && `border: 0; `};

  border-radius: ${({ isLiveEditing, theme }) =>
    !isLiveEditing && theme.borders.radius};

  > svg {
    margin-right: ${({ theme }) => theme.margin.standard};
  }

  > div {
    flex-grow: 2;
    width: 100%;
    height: 100%;

    > div {
      &.react-datepicker__tab-loop {
        z-index: 9 !important;
      }

      > input {
        background: 'none';
        background: ${({ active, disabled, isLiveEditing, theme }) =>
          isLiveEditing && !disabled && active
            ? theme.colors.general.white
            : 'none'};

        text-align: center;

        border: none;

        width: 400px;
        max-width: ${({ hideIcon }) =>
          hideIcon ? '100%' : 'calc(100% - 30px)'};
        height: 36px;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  transition: color 0.3s;
`;
