import styled from 'styled-components';

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 480px;
  margin-bottom: ${({ theme }) => theme.margin.large};

  > .input {
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  max-height: 300px;
`;

export const Color = styled.div`
  margin: ${({ theme }) => theme.margin.standard};
`;
