import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faArrowRight, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { V2_URL } from '../../const/SiteUrl';
import { EntityType, RouteName } from '../../enums';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import useNotification from '../../hooks/notification/UseNotification';
import { NotificationEventOutputWithDetails } from '../../store/notification';
import FormattedNotificationContent from '../NotificationAlert/FormattedNotificationContent';
import NotificationIcon from './NotificationEventIcon';
import * as SC from './styled';

interface NotificationEventCardProps {
  notificationEvent?: NotificationEventOutputWithDetails;
  hideNotificationPanel?: () => void;
}

const ENVELOPE_ICON_NAME = 'fa-envelope';

const formatDaysAgoText = (notificationDate: DateTime) => {
  const today = DateTime.fromJSDate(new Date());
  const daysDifference = DateTime.fromISO(today).diff(notificationDate, [
    'days',
  ]);

  return daysDifference.days <= 1
    ? 'Today'
    : daysDifference.days <= 2
    ? 'Yesterday'
    : DateTime.now().minus(daysDifference).toRelative();
};

const NotificationEventCard: FC<NotificationEventCardProps> = ({
  notificationEvent,
  hideNotificationPanel,
}) => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { getNotifications, editNotification, markNotificationAsRead } =
    useNotification();

  const [isUpdating, setItUpdating] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleToggleRead = async () => {
    setItUpdating(true);
    const cloned = cloneDeep(notificationEvent);

    // Toggle read status
    cloned.readStatus =
      cloned.readStatus === Enums.NotificationReadStatus.READ
        ? Enums.NotificationReadStatus.UNREAD
        : Enums.NotificationReadStatus.READ;

    await editNotification(cloned);

    // Refresh notifications
    await getNotifications({
      pageNum: 1,
      readStatus:
        cloned.readStatus === Enums.NotificationReadStatus.READ
          ? Enums.NotificationReadStatus.UNREAD
          : Enums.NotificationReadStatus.READ,
    });

    setItUpdating(false);
  };

  const handleClickedEvent = (e) => {
    // Cancel if user clicks 'Mark as read' button
    if (
      isUpdating ||
      e.target.innerText === 'Mark as read' ||
      e.target.innerText === 'Mark as unread' ||
      e.target?.parentElement?.className?.value?.includes(ENVELOPE_ICON_NAME) ||
      e.target?.nearestViewportElement?.innerHTML.includes(ENVELOPE_ICON_NAME)
    ) {
      return;
    }

    if (
      !notificationEvent?.notificationAlertParams.conditions?.queryConditions ||
      !notificationEvent?.notificationAlertParams.resource?.resourceId
    ) {
      return;
    }

    // Mark notification as read
    if (notificationEvent?.readStatus === Enums.NotificationReadStatus.UNREAD) {
      const cloned = cloneDeep(notificationEvent);
      cloned.readStatus = Enums.NotificationReadStatus.READ;
      editNotification(cloned);
    }

    const filters =
      notificationEvent.notificationAlertParams.conditions.queryConditions;

    const formattedNotificationFilter = [
      {
        operator: Enums.AggregationFilterOperator.AND,
        value: [{ ...filters, active: true, value: filters.value }],
        active: true,
      },
    ];

    // Redirecting to V2 but need to set up access to filters etc.
    window.location.href = `${V2_URL}datasets/${notificationEvent?.notificationAlertParams.resource?.resourceId}/view`;

    // history.push({
    //   pathname: `${RouteName.DATASET_ITEM}/${notificationEvent?.notificationAlertParams.resource?.resourceId}/view`,
    //   search: '?page=1&pageSize=50',
    //   state: {
    //     filters: JSON.stringify(formattedNotificationFilter),
    //     filterType: EntityType.NOTIFICATION,
    //   },
    // });

    // if (hideNotificationPanel) {
    //   hideNotificationPanel();
    //
    //   // Hide notification from redux store
    //   if (
    //     notificationEvent?.readStatus === Enums.NotificationReadStatus.UNREAD
    //   ) {
    //     markNotificationAsRead(notificationEvent._id);
    //   }
    // }
  };

  const getFirstConditionType = useCallback(() => {
    if (!notificationEvent) {
      return;
    }

    const { queryConditions } =
      notificationEvent.notificationAlertParams.conditions;
    const schema = notificationEvent.additionalDetails?.schema;

    if (schema) {
      const condition = (
        queryConditions?.value as Interfaces.StandardFilter[]
      )[0];

      return schema.find(
        (f) => f.fieldId === (condition as DynamicConditionalField).field,
      )?.type[0] as Enums.ValueDataType;
    }
  }, [notificationEvent]);

  return (
    <SC.NotificationCardWrapper
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      onClick={(e) =>
        !notificationEvent?.isInfoMessage && handleClickedEvent(e)
      }
    >
      <SC.NotificationCardContent>
        <NotificationIcon
          dataValidationType={
            !notificationEvent?.isInfoMessage
              ? getFirstConditionType()
              : undefined
          }
        />

        <SC.NotificationCardDetails
          read={
            notificationEvent?.readStatus === Enums.NotificationReadStatus.READ
          }
        >
          <SC.NotificationCardTitle>
            {notificationEvent?.additionalDetails?.name}
          </SC.NotificationCardTitle>

          {notificationEvent?.notificationAlertParams?.conditions && (
            <SC.NotificationCardMessage>
              <FormattedNotificationContent
                notificationAlertParams={
                  notificationEvent.notificationAlertParams
                }
                isInfoMessage={notificationEvent.isInfoMessage}
                createdBy={notificationEvent.meta.createdBy}
              />
            </SC.NotificationCardMessage>
          )}

          <SC.NotificationCardMeta>
            <SC.NotificationReceived
              read={
                notificationEvent?.readStatus ===
                Enums.NotificationReadStatus.READ
              }
            >
              {formatDaysAgoText(
                DateTime.fromISO(notificationEvent?.meta.created),
              )}
            </SC.NotificationReceived>
            {isHovering && (
              <SC.ToggleReadButton onClick={() => handleToggleRead()}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  color={themeContext.colors.system.white}
                  size={'lg'}
                  style={{ pointerEvents: 'none' }}
                />
                <span>
                  Mark as{' '}
                  {notificationEvent?.readStatus ===
                  Enums.NotificationReadStatus.UNREAD
                    ? 'read'
                    : 'unread'}
                </span>
              </SC.ToggleReadButton>
            )}
          </SC.NotificationCardMeta>
        </SC.NotificationCardDetails>

        {!notificationEvent?.isInfoMessage && (
          <SC.NotificationCardArrow
            icon={faArrowRight}
            color={themeContext.colors.general.blue}
          />
        )}
      </SC.NotificationCardContent>
    </SC.NotificationCardWrapper>
  );
};

export default NotificationEventCard;
