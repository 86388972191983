import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  IntegrationConfigOutput,
  IntegrationConfigsOutput,
} from '../../services/integration/IntegrationConfigService';
import {
  createIntegrationConfig,
  deleteIntegrationConfig,
  fetchIntegrationConfig,
  resetIntegrationConfig,
  updateIntegrationConfig,
} from '../../store/integration-config';
import {
  fetchIntegrationConfigs,
  fetchIntegrationConfigsSuccess,
} from '../../store/integration-configs';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useIntegrationConfigResult {
  integrationConfig?: Interfaces.IntegrationConfigOutput;
  integrationConfigAccessLevel?: Enums.AccessLevel;

  getIntegrationConfigs: (query?: Record<string, unknown>) => Promise<void>;
  getIntegrationConfig: (integrationConfigId: string) => void;
  addIntegrationConfig: (
    integrationConfig: Interfaces.IntegrationConfig,
  ) => void;
  editIntegrationConfig: (
    integrationConfig: Interfaces.IntegrationConfigOutput,
  ) => void;
  removeIntegrationConfig: (integrationConfigId: string) => void;
}

const useIntegrationConfig = (): useIntegrationConfigResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [integrationConfig, setIntegrationConfig] =
    useState<Interfaces.IntegrationConfigOutput>();
  const [integrationConfigAccessLevel, setIntegrationConfigAccessLevel] =
    useState<Enums.AccessLevel>();

  const integrationConfigsState = useSelector(
    (state: RootState) => state.integrationConfigs,
  );
  const integrationConfigState = useSelector(
    (state: RootState) => state.integrationConfig,
  );
  const integrationConfigObj =
    integrationConfigState.data as IntegrationConfigOutput;

  const integrationConfigsObj =
    integrationConfigsState.data as IntegrationConfigsOutput;

  const getIntegrationConfigs = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          await dispatch(fetchIntegrationConfigs(token, query));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load integration configs.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getIntegrationConfig = useCallback(
    async (integrationConfigId: string) => {
      const token = await getAccessTokenSilently();

      if (token && integrationConfigId) {
        try {
          await dispatch(fetchIntegrationConfig(token, integrationConfigId));
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to load integration config.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addIntegrationConfig = useCallback(
    async (integrationConfig: Interfaces.IntegrationConfig) => {
      const token = await getAccessTokenSilently();

      if (token && integrationConfig && loggedInUser) {
        try {
          await dispatch(
            createIntegrationConfig(token, integrationConfig, loggedInUser._id),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to add integration config.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editIntegrationConfig = useCallback(
    async (integrationConfig: Interfaces.IntegrationConfigOutput) => {
      const token = await getAccessTokenSilently();

      if (token && integrationConfig && loggedInUser) {
        try {
          await dispatch(
            updateIntegrationConfig(token, integrationConfig, loggedInUser._id),
          );
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to edit integration config.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeIntegrationConfig = useCallback(
    async (integrationConfigId: string) => {
      const token = await getAccessTokenSilently();

      if (token && integrationConfigId && loggedInUser) {
        try {
          await dispatch(
            deleteIntegrationConfig(
              token,
              integrationConfigId,
              loggedInUser._id,
            ),
          );

          // Remove config from current integration Configs
          const cloned = cloneDeep(integrationConfigsObj);
          cloned.data = cloned.data.filter(
            (e) => e.entity._id !== integrationConfigId,
          );

          await dispatch(fetchIntegrationConfigsSuccess(cloned));
          await dispatch(resetIntegrationConfig());
        } catch (err) {
          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to delete integration config.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, integrationConfigsObj],
  );

  useEffect(() => {
    if (integrationConfigObj) {
      // Complete model
      setIntegrationConfig(integrationConfigObj.entity);
      setIntegrationConfigAccessLevel(integrationConfigObj.accessLevel);
    }
  }, [integrationConfigObj]);

  return {
    integrationConfig,
    integrationConfigAccessLevel,

    getIntegrationConfigs,
    getIntegrationConfig,
    addIntegrationConfig,
    editIntegrationConfig,
    removeIntegrationConfig,
  };
};

export default useIntegrationConfig;
