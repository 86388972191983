import { Enums } from '@configur-tech/upit-core-types';

const formulaOperationDisplayMap = (
  operation: Enums.FormulaOperation,
): string => {
  switch (operation) {
    case Enums.FormulaOperation.ADD:
      return '+';
    case Enums.FormulaOperation.SUBTRACT:
      return '-';
    case Enums.FormulaOperation.MULTIPLY:
      return '*';
    case Enums.FormulaOperation.DIVIDE:
      return '/';
    default:
      return '';
  }
};

export default formulaOperationDisplayMap;
