import { Interfaces } from '@configur-tech/upit-core-types';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums';
import * as SC from './styled';

const SEARCH_PARAM_KEY = 'search';

interface IntegrationTagListProps {
  tags?: Interfaces.TagV1[];
}

const IntegrationTagList: FC<IntegrationTagListProps> = ({ tags }) => {
  const location = useLocation();
  const history = useHistory();

  const [searchCat, setSearchCat] = useState<string>();

  // Get active search value
  useEffect(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search).get(SEARCH_PARAM_KEY);
    setSearchCat(searchParams as string);
  }, [location.search]);

  return (
    <SC.TagList>
      {tags?.map((tag, index) => (
        <SC.TagListItem
          key={`int-tag=${tag.value}-${index}`}
          onClick={() => {
            if (tag.value !== searchCat) {
              history.push({
                pathname: RouteName.INTEGRATION_CENTRE,
                search: `search=${tag.value}`,
              });
            } else {
              history.push({
                pathname: RouteName.INTEGRATION_CENTRE,
              });
            }
          }}
        >
          <SC.Tag active={tag.value === searchCat}>{tag.value}</SC.Tag>
        </SC.TagListItem>
      ))}
    </SC.TagList>
  );
};

export default IntegrationTagList;
