import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, isEqual } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import warningIcon from '../../../../assets/icons/stages/validate-dataset/result-failure.svg';
import { EntityType } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useTableData from '../../../../hooks/dataset-meta/UseTableData';
import useDataset from '../../../../hooks/dataset/UseDataset';
import useFilter, {
  DynamicConditionalField,
} from '../../../../hooks/filter/UseFilter';
import useList from '../../../../hooks/list/UseList';
import usePagination from '../../../../hooks/pagination/UsePagination';
import useProject from '../../../../hooks/project/UseProject';
import useQuery from '../../../../hooks/query/UseQuery';
import useQueryBuilder from '../../../../hooks/query/UseQueryBuilder';
import useQueryBuilderAccordion from '../../../../hooks/query/UseQueryBuilderAccordion';
import { SampleData } from '../../../../interfaces/SampleData';
import {
  defaultTheme,
  StageWrapper,
  StyledBodySubHeader,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { ListItemOutput } from '../../../../services/list/ListService';
import { DatasetState } from '../../../../store/dataset';
import { hideLoading, showLoading } from '../../../../store/loading';
import { showModal } from '../../../../store/modal';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectAggregationSubStage } from '../../../../store/project-stage/initial-state';
import { QueryWithDetails } from '../../../../store/queries';
import { RootState } from '../../../../store/rootReducer';
import AvatarIconMap from '../../../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../../../util/icon-helpers/UserIconMap';
import ActionBar from '../../../ActionBar/ActionBar';
import DataSample from '../../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../../Modal/Modal';
import TimeAgo from '../../../TimeAgo/TimeAgo';
import * as SC from './styled';

export enum AggregateStage {
  FILTER = 'Filters',
  GROUP = 'Group By',
  MEASURE = 'Measures',
  SORT = 'Sort By',
  SUPERCOLUMN = 'Super Column',
  TOTALS = 'Totals',
  DISPLAY_ORDER = 'Display Order',
}

const PREV_STAGE = ProjectAggregationSubStage.DATASETS;
const ROW_ID_FIELD = 'row_id';
const PAGE_SIZE = 50;
const DATASET_LIMIT = 300;

const ProjectItemAggregationAggregateStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { query, editQuery, removeMissingFields } = useQuery();
  const { checkForMissingFields, queryOptions } = useQueryBuilder();
  const { buildAccordionPanel } = useQueryBuilderAccordion();
  const { getDatasetMetas } = useDatasetMeta();
  const { project, projectAccessLevel } = useProject();
  const { getAggregatedDataset } = useDataset();
  const { getDatasetFilters, conditionalFieldToFilterFieldMap } = useFilter();
  const { getLists } = useList();
  const { createTableData } = useTableData();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [datasetLoaded, setDatasetLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<number>(1);
  const [entries, setEntries] = useState<Record<string, unknown>[]>([]);
  const [entrySchema, setEntrySchema] = useState<Interfaces.SchemaField[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [isNewQuery, setIsNewQuery] = useState<boolean>(false);
  const [filters, setFilters] = useState<DynamicConditionalField[]>();
  const [filtersHaveChanged, setFiltersHaveChanged] = useState<boolean>(false);

  const [missingFields, setMissingFields] = useState<Interfaces.QueryField[]>(
    [],
  );

  const [lastSavedQuery, setLastSavedQuery] = useState<QueryWithDetails>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );
  const [tableData, setTableData] = useState<SampleData>();
  const { calculateRowNumber } = usePagination();

  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  // Maintain record of last saved query including mapped filters
  useEffect(() => {
    if (!lastSavedQuery && query?._id) {
      setLastSavedQuery(query);
    }
    if (lastSavedQuery) {
      setHasUnsavedChanges(
        !isEqual(lastSavedQuery?.queryParams, {
          ...query?.queryParams,
          filters: filters?.map((f) =>
            conditionalFieldToFilterFieldMap(
              f,
              query?.queryParams.aggregationType ===
                Enums.AggregationType.MULTIPLE,
            ),
          ),
        }),
      );
    }
  }, [
    conditionalFieldToFilterFieldMap,
    filters,
    lastSavedQuery,
    lastSavedQuery?.queryParams,
    query,
    query?.queryParams,
  ]);

  // Check if any fields used in query are missing
  useEffect(() => {
    setMissingFields(checkForMissingFields());
  }, [checkForMissingFields]);

  // Build tableData
  useEffect(() => {
    if (!entrySchema || !entries) {
      return;
    }
    setTableData(createTableData(entrySchema, entries));
  }, [entrySchema, entries, createTableData]);

  // Fetch active filters
  useEffect(() => {
    if (query?._id) {
      const activeFilters = getDatasetFilters(query._id).reduce(
        (acc, filter) => {
          if ((filter.value as DynamicConditionalField[]).length) {
            const active = (filter.value as DynamicConditionalField[]).filter(
              (f) => f.active,
            );

            if (active.length) {
              return [...acc, { ...filter, value: active }];
            }
          }

          return acc;
        },
        [] as DynamicConditionalField[],
      );

      setFilters(activeFilters);

      setFiltersHaveChanged(true);
    }
  }, [query?._id, getDatasetFilters]);

  // If schema contains lists, fetch them
  useEffect(() => {
    if (
      typeof queryOptions.listIds !== 'undefined' &&
      queryOptions.listIds.length &&
      queryOptions.listIds.some(
        (id) => !lists.find((list) => list.entity._id === id),
      ) &&
      query?.additionalDetails?.length
    ) {
      (async () => {
        await getLists({
          $or: JSON.stringify([
            { _id: { $in: queryOptions.listIds } },
            {
              'datasetListOptions.fieldId': {
                $in: query?.additionalDetails
                  ?.map((detail) => detail.schemaData)
                  .flat()
                  .map((f) => f.fieldId),
              },
            },
          ]),
        });
      })();
    }
  }, [getLists, lists, query?.additionalDetails, queryOptions.listIds]);

  // Fetch dataset
  useEffect(() => {
    if (
      !loadingData &&
      query?._id &&
      filters &&
      !missingFields.length &&
      project?.queries?.find((q) => q === query?._id)
    ) {
      setIsLoading(true);
      (async () => {
        setLoadingData(true);
        await getAggregatedDataset(query._id, [], isNewQuery ? 1 : pagination);

        setDatasetLoaded(true);
        setIsNewQuery(false);
      })();
    }
  }, [
    query?._id,
    dispatch,
    getAggregatedDataset,
    loadingData,
    pagination,
    filters,
    isNewQuery,
    missingFields.length,
    project?.queries,
  ]);

  // Set entries
  useEffect(() => {
    if (query?._id && datasetState?.data?.[query._id]?.entries) {
      const ent = datasetState.data[query._id].entries.map((row) => {
        const rowWithoutRowMetaTotal = { ...row };
        delete rowWithoutRowMetaTotal.rowMeta;
        return rowWithoutRowMetaTotal;
      });

      if (ent) {
        setEntries(
          ent.map((e, i) => ({
            ...e,
            [ROW_ID_FIELD]: calculateRowNumber(pagination, i, PAGE_SIZE),
          })),
        );

        if (ent.length) {
          let schema: Interfaces.SchemaField[];
          if (query?.queryParams.displayOrder?.length) {
            // Has user defined display order
            schema = query.queryParams.displayOrder
              ?.map((displayOrderItem) => {
                const entry = Object.entries(ent[0]).find(
                  ([key]) => key === displayOrderItem.alias,
                );

                return {
                  field: displayOrderItem.alias || displayOrderItem.field,
                  alias: displayOrderItem.alias || displayOrderItem.field,
                  type: [
                    Services.validators.DataValidator.isNumber(entry?.[1])
                      ? Enums.ValueDataType.NUMBER
                      : Enums.ValueDataType.TEXT,
                  ],
                };
              })
              .concat(
                // Add additional measures that haven't been ordered
                (query?.queryParams.measures || [])
                  .filter(
                    (measure) =>
                      !query.queryParams.displayOrder?.find(
                        (displayOrderItem) =>
                          displayOrderItem.alias === measure.alias,
                      ),
                  )
                  .map((measure) => {
                    return {
                      field: measure.alias || measure.field,
                      alias: measure.alias || measure.field,
                      type: [Enums.ValueDataType.TEXT],
                    };
                  }),
              )
              .concat(
                // Add additional measures that haven't been ordered
                (query?.queryParams.supercolumns || [])
                  .filter(
                    (supercol) =>
                      !query.queryParams.displayOrder?.find(
                        (displayOrderItem) =>
                          displayOrderItem.alias === supercol.alias,
                      ),
                  )
                  .map((supercol) => {
                    return {
                      field: supercol.alias || supercol.field,
                      alias: supercol.alias || supercol.field,
                      type: [Enums.ValueDataType.TEXT],
                    };
                  }),
              );
          } else {
            // No order specified, output in order data has arrived
            schema = Object.keys(ent[0]).map((k) => {
              return {
                field: k,
                alias: k,
                type: [
                  Services.validators.DataValidator.isNumber(k)
                    ? Enums.ValueDataType.NUMBER
                    : Enums.ValueDataType.TEXT,
                ],
              };
            });
          }

          setEntrySchema(schema);
        }
      }
    }
  }, [
    query?._id,
    datasetState.data,
    dispatch,
    calculateRowNumber,
    pagination,
    query?.queryParams.displayOrder,
    query?.queryParams.measures,
    query?.queryParams.supercolumns,
  ]);

  const updatePagination = useCallback(
    (page: number) => {
      if (!isEqual(page, pagination)) {
        setPagination(page);
        setDatasetLoaded(false);
        setLoadingData(false);
      }
    },
    [pagination],
  );

  const processAction = async (complete?: boolean) => {
    if (query) {
      dispatch(showLoading({ text: 'Saving Query...' }));
      // Save filters
      const cloned = cloneDeep(query);

      // Map to filterField
      cloned.queryParams.filters = filters?.map((f) =>
        conditionalFieldToFilterFieldMap(
          f,
          cloned.queryParams.aggregationType === Enums.AggregationType.MULTIPLE,
        ),
      );

      if (!cloned.queryParams.displayOrder.length) {
        // No groups/measures so use fields
        cloned.queryParams.displayOrder = cloned.queryParams.fields;
      }

      await editQuery(cloned);
      dispatch(hideLoading());
      setLastSavedQuery(cloned);

      if (complete) {
        dispatch(updateActiveProjectSubStage(undefined));
      } else {
        if (filtersHaveChanged) {
          setIsNewQuery(true);
        }
        setLoadingData(false);
        setDatasetLoaded(false);
      }
    }
  };

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          undefined,
          undefined,
          DATASET_LIMIT,
        );
      }
    })();
  }, [dispatch, getDatasetMetas, project]);

  // Hide loader
  useEffect(() => {
    if (loadingData && datasetLoaded) {
      setIsLoading(false);
    }
  }, [datasetLoaded, loadingData]);

  return (
    <StageWrapper>
      <SC.GridWrapper showFilters={showFilters}>
        <SC.PageInner>
          <SC.PageHeader>
            <StyledSubHeader>{query?.name}</StyledSubHeader>

            <div>
              <FeatureButton
                action={() =>
                  dispatch(
                    showModal({
                      visible: true,
                      modal: ModalTypes.EXPORT_DATA,
                      additionalProps: {
                        entityType: EntityType.AGGREGATION,
                        query: {
                          _id: query?._id,
                        },
                      },
                    }),
                  )
                }
                size={FeatureButtonSize.WIDE_SMALL}
                color={themeContext.colors.general.blue}
                text={`Export Data`}
              />

              <FeatureButton
                action={() => setShowFilters(!showFilters)}
                size={FeatureButtonSize.WIDE_SMALL}
                color={themeContext.colors.general.sea}
                text={`${showFilters ? 'Hide' : 'Show'} Filters`}
              />
            </div>
          </SC.PageHeader>

          {query?._id && missingFields.length > 0 && (
            <SC.QueryWarning>
              <SC.QueryWarningImage image={warningIcon} />
              <StyledSubHeader
                style={{ marginBottom: themeContext.margin.standard }}
              >
                Missing Columns
              </StyledSubHeader>
              <StyledText>
                The following column(s) that this query requires are missing and
                may have been deleted.
              </StyledText>
              <StyledBodySubHeader>
                {missingFields.map((field) => field.alias).join(', ')}
              </StyledBodySubHeader>
              <StyledText>
                Click the button below to remove these missing columns from this
                query.
              </StyledText>
              <FeatureButton
                action={() => removeMissingFields(query, missingFields)}
                size={FeatureButtonSize.WIDE_SMALL}
                color={themeContext.colors.general.blue}
                text={'Remove Columns'}
              />
            </SC.QueryWarning>
          )}

          <SC.PageContent>
            <SC.DatasetActions>
              <div>
                {query?._id && datasetState?.data?.[query._id]?.totalCount && (
                  <StyledText>
                    Showing <strong>{tableData?.rows?.length || 0}</strong> of{' '}
                    <strong>
                      {datasetState.data[query._id].totalCount || 0}
                    </strong>{' '}
                    rows
                  </StyledText>
                )}

                <FeatureButton
                  action={async () => {
                    setLoadingData(false);
                    setDatasetLoaded(false);
                  }}
                  size={37}
                  height={30}
                  color={themeContext.colors.general.blue}
                  icon={
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      color={defaultTheme.colors.system.white}
                    />
                  }
                />
              </div>

              {query?._id && (
                <SC.LastSavedWrapper>
                  {hasUnsavedChanges && (
                    <StyledText
                      style={{ color: themeContext.colors.system.darkGrey }}
                    >
                      You have unsaved changes.
                    </StyledText>
                  )}

                  <TimeAgo date={query.meta.lastUpdated.toString()} />
                </SC.LastSavedWrapper>
              )}
            </SC.DatasetActions>

            <DataSample
              schema={query?.additionalDetails
                ?.map((detail) => detail.schemaData)
                .flat()}
              fullWidth={true}
              showPagination={true}
              resetPagination={isNewQuery || !pagination}
              paginationAction={updatePagination}
              totalRows={
                (query?._id && datasetState?.data?.[query._id]?.totalCount) || 0
              }
              sampleColumns={
                tableData?.schema?.length
                  ? tableData?.schema
                  : [{ name: 'dummy' }]
              }
              sampleRows={tableData?.rows || []}
              isEditingQueryColumn={true}
              iconMap={{ ...UserIconMap, ...AvatarIconMap }}
              fixedHeightReduction={355}
              loading={isLoading}
            />
          </SC.PageContent>
        </SC.PageInner>

        <SC.Sidebar>
          <SC.FilterAccordion
            defaultActiveIndex={[0, 1, 2, 3, 4, 5, 6]}
            exclusive={false}
            panels={Object.values(AggregateStage).map((stage, index) =>
              buildAccordionPanel(stage, index),
            )}
          />
        </SC.Sidebar>
      </SC.GridWrapper>

      <ActionBar
        primaryButton={
          isProjectManager ? (
            <FeatureButton
              action={() => processAction(true)}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={'Save & Finish'}
            />
          ) : undefined
        }
        secondaryButton={
          isProjectManager ? (
            <FeatureButton
              isDisabled={!hasUnsavedChanges}
              action={() => processAction()}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.blue}
              text={'Save & Run query'}
            />
          ) : undefined
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(
                updateActiveProjectSubStage(
                  isProjectManager ? PREV_STAGE : undefined,
                ),
              );
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={isProjectManager ? 'Back to select' : 'Back to queries'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAggregationAggregateStage;
