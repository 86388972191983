import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { SANDBOX_PROJECT } from '../../const/ProjectConst';
import { AlertStatus, RouteName } from '../../enums';
import { EntityType } from '../../enums/EntityType';
import { ProjectStage } from '../../enums/ProjectStage';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import useProject from '../../hooks/project/UseProject';
import Page from '../../main/Page';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledH1,
  StyledText,
  StyledTextArea,
} from '../../main/theme';
import { ProjectItemOutput } from '../../services/project/ProjectService';
import {
  resetAggregationStage,
  resetChartsStage,
  updateActiveProjectStage,
} from '../../store/project-stage';
import { RootState } from '../../store/rootReducer';
import BuildBanner from '../../util/buildBanner/BuildBanner';
import getDefaultAvatar from '../../util/default-avatar/DefaultAvatar';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import { getProjectStageContent } from '../../util/project-stage-content/ProjectStageContent';
import Banner from '../BannerComponent/Banner';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import PageFeatureHeader from '../PageFeatureHeader/PageFeatureHeader';
import TagComponent from '../TableCell/TagTableCell/TagComponent';
import * as SC from './styled';

const DEFAULT_TAG_GROUP = 'group';
const TAG_FIELD = 'tags';
const DEPRECATION_URL =
  'https://help.goconfigur.com/knowledge/removing-projects-from-configur';

const buildProjectDropdownOptions = (
  tag: Interfaces.TagV1[],
): DropdownItemProps[] =>
  tag.map((d) => {
    return {
      key: d.value,
      text: d.value,
      value: d.value,
    };
  });

const ProjectOverview: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const projectsState = useSelector((state: RootState) => state.projects);
  const projects: ProjectItemOutput[] = projectsState.data.data;
  const { project, projectAccessLevel, editProject } = useProject();
  const { hasAccessToDashboards } = useOrganisation();

  const [editingDetails, setEditingDetails] = useState<boolean>(false);
  const [updatedName, setUpdatedName] = useState<string>();
  const [updatedDesc, setUpdatedDesc] = useState<string>();
  const [savingProject, setSavingProject] = useState<boolean>(false);
  const [projectTags, setProjectTags] = useState<Interfaces.TagV1[]>([]);
  const [projectTagOptions, setProjectTagOptions] = useState<
    DropdownItemProps[]
  >([]);

  useEffect(() => {
    const pTags = projects?.reduce((acc: Interfaces.TagV1[], projects) => {
      if (!projects.entity.tags) {
        return acc;
      }

      return [
        ...acc,
        ...projects.entity.tags.filter(
          (tag) => !acc.find((t) => t.value === tag.value),
        ),
      ];
    }, []);

    // Create format and append existing tags for Semantic UI

    setProjectTagOptions(
      buildProjectDropdownOptions(pTags.concat(projectTags)),
    );
  }, [projectTags, projects]);

  useEffect(() => {
    setProjectTags(project?.tags || []);
  }, [project?.tags]);

  const handleTagAdd = (value: string) => {
    const cloned = cloneDeep(projectTags);
    cloned.push({ key: DEFAULT_TAG_GROUP, value, text: value });
    setProjectTags(cloned);
  };

  // Handle Tag Change and Correct formatting for DatasetMeta
  const handleTagChange = (field, value) => {
    const cloned = cloneDeep(project);
    const concatTags = value.map((tag) => {
      return { key: DEFAULT_TAG_GROUP, value: tag.toString() };
    });
    cloned[field] = concatTags;
    setProjectTags(concatTags);
  };

  const updateDetails = useCallback(async () => {
    if (
      updatedName?.length &&
      (updatedName !== project?.name ||
        updatedDesc !== project?.description ||
        projectTagOptions !== project?.tags)
    ) {
      const cloned = cloneDeep(project);
      cloned.name = updatedName;
      cloned.description = updatedDesc;
      cloned.tags = projectTags;
      setSavingProject(true);

      await editProject(cloned);
      setSavingProject(false);
      setEditingDetails(false);
    } else {
      if (project?.name) {
        setUpdatedName(project.name);
      }
      if (project?.description) {
        setUpdatedDesc(project.description);
      }
      setEditingDetails(false);
    }
  }, [
    editProject,
    project,
    projectTags,
    updatedDesc,
    updatedName,
    projectTagOptions,
  ]);

  const updateAvatar = useCallback(
    async (icon) => {
      if (icon) {
        const cloned = cloneDeep(project);
        cloned.avatar = icon;
        setSavingProject(true);

        await editProject(cloned);
        setSavingProject(false);
      }
    },
    [editProject, project],
  );

  useEffect(() => {
    if (typeof updatedName === 'undefined' && project?.name) {
      setUpdatedName(project.name);
    }
  }, [project, updatedName]);

  useEffect(() => {
    if (typeof updatedDesc === 'undefined' && project?.description) {
      setUpdatedDesc(project.description);
    }
  }, [project, updatedDesc]);

  const projectAvatar =
    project?.avatar && project?.avatar.includes('http')
      ? project?.avatar
      : project?.avatar
      ? { ...UserIconMap, ...AvatarIconMap }[project?.avatar]
      : getDefaultAvatar(EntityType.DATASET);

  return (
    <>
      {projectAccessLevel !== Enums.AccessLevel.MANAGE && (
        <PageFeatureHeader
          pageHeader={'Overview'}
          image={projectAvatar}
          hideAvatarPopup={true}
          title={
            <SC.NameWrapper>
              <StyledH1>{project?.name}</StyledH1>

              {project?.description && project.description.length > 0 && (
                <StyledText>{project?.description}</StyledText>
              )}
            </SC.NameWrapper>
          }
        />
      )}

      {projectAccessLevel === Enums.AccessLevel.MANAGE && (
        <>
          <PageFeatureHeader
            pageHeader={'Overview'}
            image={projectAvatar}
            hideAvatarPopup={true}
            avatarPopupEntityType={EntityType.PROJECT}
            avatarPopupOnChange={(icon) => updateAvatar(icon)}
            avatarPopupValue={project?.avatar}
            avatarPopupLoading={savingProject}
            title={
              <SC.NameWrapper>
                {!editingDetails ? (
                  <>
                    <StyledH1>{project?.name}</StyledH1>

                    {project?.description && project.description.length > 0 && (
                      <StyledText>{project?.description}</StyledText>
                    )}
                    {project?.tags && project.tags.length > 0 && (
                      <SC.TagWrapper>
                        {project.tags.map((tag: Interfaces.TagV1, i) => (
                          <TagComponent
                            tag={tag.value}
                            key={`${tag.value}-${i}`}
                            active={false}
                            route={RouteName.PROJECTS}
                          />
                        ))}
                      </SC.TagWrapper>
                    )}
                  </>
                ) : (
                  <>
                    <StyledBodySubHeader>Project Name</StyledBodySubHeader>
                    <SC.NameInput
                      loading={savingProject}
                      disabled={savingProject}
                      onChange={(e, data) => setUpdatedName(data.value)}
                      value={updatedName}
                      placeholder={'Enter a name for your project'}
                    />
                    <StyledBodySubHeader>
                      Project Description
                    </StyledBodySubHeader>
                    <StyledTextArea
                      minRows={5}
                      maxRows={10}
                      disabled={savingProject}
                      onChange={(e) => setUpdatedDesc(e.target.value)}
                      value={updatedDesc}
                      placeholder={'Enter a description for your project'}
                    />
                    <StyledBodySubHeader
                      style={{ marginTop: themeContext.margin.large }}
                    >
                      Tags
                    </StyledBodySubHeader>
                    <StyledDropdownWide
                      style={{
                        marginTop: themeContext.margin.small,
                      }}
                      selectOnBlur={false}
                      placeholder={`Enter your tags`}
                      noResultsMessage={'Type to add a new tag'}
                      text={
                        'Add tags to easily group and search your projects.'
                      }
                      search
                      allowAdditions
                      selection
                      multiple
                      disabled={savingProject}
                      options={projectTagOptions}
                      value={projectTags.map((tag) => tag.value) || []}
                      onAddItem={(event, data) => handleTagAdd(data.value)}
                      onChange={(e, { value }) =>
                        handleTagChange(TAG_FIELD, value)
                      }
                    />
                  </>
                )}

                {project?.name !== SANDBOX_PROJECT && (
                  <SC.NameActionsWrapper>
                    {!editingDetails ? (
                      <>
                        {/*Deprecated*/}
                        {/*<FeatureButton*/}
                        {/*  isDisabled={savingProject}*/}
                        {/*  action={() => {*/}
                        {/*    setUpdatedName(project?.name);*/}
                        {/*    setUpdatedDesc(project?.description);*/}
                        {/*    setEditingDetails(true);*/}
                        {/*  }}*/}
                        {/*  color={themeContext.colors.general.blue}*/}
                        {/*  text={'Edit Info'}*/}
                        {/*  size={FeatureButtonSize.WIDE_SMALL}*/}
                        {/*/>*/}
                      </>
                    ) : (
                      <>
                        <FeatureButton
                          isDisabled={savingProject}
                          action={() => {
                            setEditingDetails(false);
                            setUpdatedName(project?.name);
                            setUpdatedDesc(project?.description);
                          }}
                          color={themeContext.colors.general.sea}
                          text={'Cancel'}
                          size={FeatureButtonSize.WIDE_SMALL}
                        />
                        <FeatureButton
                          isDisabled={
                            savingProject ||
                            (!updatedName?.length && !updatedDesc?.length) ||
                            (updatedName === project?.name &&
                              updatedDesc === project?.description &&
                              projectTagOptions === project?.tags)
                          }
                          action={updateDetails}
                          color={themeContext.colors.general.green}
                          text={'Save'}
                          size={FeatureButtonSize.WIDE_SMALL}
                          containerStyle={{
                            marginLeft: themeContext.margin.standard,
                          }}
                        />
                      </>
                    )}
                  </SC.NameActionsWrapper>
                )}
              </SC.NameWrapper>
            }
          />
        </>
      )}

      <Page>
        <StageWrapper>
          <StageInner>
            {projectAccessLevel !== Enums.AccessLevel.MANAGE && (
              <>
                <StageBodyText>
                  You currently have <strong>View</strong> access to this
                  project.
                </StageBodyText>

                <StageBodyText>
                  You can view connections and queries, but you may not be able
                  to edit them.
                </StageBodyText>

                <SC.ActionWrapper>
                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveProjectStage(
                            Enums.ProjectStage.AGGREGATION,
                          ),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getProjectStageContent(Enums.ProjectStage.AGGREGATION)
                          .image
                      }
                    />
                    <SC.ItemHeader>Query</SC.ItemHeader>
                  </div>
                </SC.ActionWrapper>
              </>
            )}
            {projectAccessLevel === Enums.AccessLevel.MANAGE && (
              <>
                <Banner
                  message={
                    <p>
                      Projects have now been removed from Configur.{' '}
                      <a target={'_blank'} href={DEPRECATION_URL}>
                        Learn more
                      </a>
                    </p>
                  }
                  alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
                  alertStatus={AlertStatus.INFO}
                />

                <StageBodyText style={{ marginTop: 20 }}>
                  The building blocks of your project are now set up, but you
                  can always add more datasets, create new data links and build
                  more connections.
                </StageBodyText>
                <StageBodyText>What would you like to do next?</StageBodyText>

                <SC.ActionWrapper>
                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveProjectStage(Enums.ProjectStage.DATASETS),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getProjectStageContent(Enums.ProjectStage.DATASETS)
                          .image
                      }
                    />
                    <SC.ItemHeader>Select Data</SC.ItemHeader>
                  </div>

                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveProjectStage(
                            Enums.ProjectStage.MODELLING,
                          ),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getProjectStageContent(Enums.ProjectStage.MODELLING)
                          .image
                      }
                    />
                    <SC.ItemHeader>Link Data</SC.ItemHeader>
                  </div>

                  <div>
                    <FeatureButton
                      action={() => {
                        dispatch(resetAggregationStage());
                        dispatch(
                          updateActiveProjectStage(
                            Enums.ProjectStage.AGGREGATION,
                          ),
                        );
                      }}
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getProjectStageContent(Enums.ProjectStage.AGGREGATION)
                          .image
                      }
                    />
                    <SC.ItemHeader>Query</SC.ItemHeader>
                  </div>

                  {hasAccessToDashboards && (
                    <div>
                      <FeatureButton
                        action={() => {
                          dispatch(resetChartsStage());
                          dispatch(
                            updateActiveProjectStage(ProjectStage.CHARTS),
                          );
                        }}
                        size={FeatureButtonSize.MEDIUM}
                        color={themeContext.colors.system.grey}
                        image={
                          getProjectStageContent(ProjectStage.CHARTS).image
                        }
                      />
                      <SC.ItemHeader>Dashboard</SC.ItemHeader>
                    </div>
                  )}

                  <div>
                    <FeatureButton
                      action={() =>
                        dispatch(
                          updateActiveProjectStage(
                            Enums.ProjectStage.CONFIGURATION,
                          ),
                        )
                      }
                      size={FeatureButtonSize.MEDIUM}
                      color={themeContext.colors.system.grey}
                      image={
                        getProjectStageContent(Enums.ProjectStage.CONFIGURATION)
                          .image
                      }
                    />
                    <SC.ItemHeader>Secure</SC.ItemHeader>
                  </div>
                </SC.ActionWrapper>
              </>
            )}
          </StageInner>
        </StageWrapper>
      </Page>
    </>
  );
};

export default ProjectOverview;
