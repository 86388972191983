import { cloneDeep } from 'lodash';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import defaultLogo from '../../../../assets/icons/system/codex-icon.svg';
import useForm from '../../../../hooks/form/UseForm';
import useOrganisation from '../../../../hooks/organisation/UseOrganisation';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchFormSuccess } from '../../../../store/project-form';
import { updateActiveProjectSubStage } from '../../../../store/project-stage';
import { ProjectConnectionFormSubStage } from '../../../../store/project-stage/initial-state';
import ActionBar from '../../../ActionBar/ActionBar';
import DatasetSelectDropdown from '../../../DatasetSelectDropdown/DatasetSelectDropdown';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const DATASET_METAS_FIELD = 'datasetMetaId';
const NEXT_STAGE = ProjectConnectionFormSubStage.FORM_BUILDER;
const PREV_STAGE = ProjectConnectionFormSubStage.NAME;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const ProjectItemConnectionFormDataStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { form, addForm, editForm } = useForm();

  const { organisation } = useOrganisation();

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(form);
    cloned.datasetMetaId = val;
    dispatch(fetchFormSuccess(cloned));
  };

  const processAction = async () => {
    if (organisation && form) {
      if (!form._id) {
        dispatch(showLoading({ text: 'Creating Form...' }));
        const clonedForm = cloneDeep(form);

        clonedForm.organisationId = organisation._id;

        const createdForm = clonedForm.avatar
          ? await addForm(clonedForm)
          : await addForm({
              ...clonedForm,
              avatar: organisation?.theme?.logo || defaultLogo,
            });

        if (createdForm) {
          dispatch(fetchFormSuccess(createdForm));
        }
      } else {
        dispatch(showLoading({ text: 'Updating Form...' }));
        const clonedForm = cloneDeep(form);

        const updatedForm = await editForm(clonedForm);

        if (updatedForm) {
          dispatch(fetchFormSuccess(updatedForm));
        }
      }

      dispatch(hideLoading());
      dispatch(updateActiveProjectSubStage(NEXT_STAGE));
    }
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StageBodyText>
            Which dataset would you like to populate with this form?
          </StageBodyText>

          <DatasetSelectDropdown
            onChange={(value) => handleChange(DATASET_METAS_FIELD, value || '')}
            initialDatasetMetaId={form?.datasetMetaId || ''}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Keep it moving`}
        primaryButton={
          <FeatureButton
            isDisabled={!form?.datasetMetaId.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to form builder'}
          />
        }
        backButton={
          <FeatureButton
            action={() => dispatch(updateActiveProjectSubStage(PREV_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemConnectionFormDataStage;
