import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService, { UserItemOutput } from '../../services/user/UserService';
import { RootState } from '../../store/rootReducer';
import {
  createUser,
  deleteUser,
  fetchUser,
  updateUser,
} from '../../store/user';
import { fetchUsers, fetchUsersSuccess } from '../../store/users';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useUserResult {
  user?: Interfaces.UserOutput;
  userAccessLevel?: Enums.AccessLevel;
  userError?: string;
  getUsers: (query?: Record<string, unknown>) => void;
  getUser: (userId: string) => void;
  getMe: () => void;
  addUser: (userObj: Interfaces.User) => void;
  editUser: (userObj: Partial<Interfaces.UserOutput>) => void;
  removeUser: (userId: string) => void;
}

export const AUTH0_CONNECTION_IDENTIFIER = 'auth0|';

const useUser = (): useUserResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [user, setUser] = useState<Interfaces.UserOutput>();
  const [userAccessLevel, setUserAccessLevel] = useState<number>();
  const [userError, setUserError] = useState<string>();

  const usersState = useSelector((state: RootState) => state.users);
  const userState = useSelector((state: RootState) => state.user);
  const userObj = userState.data as UserItemOutput;
  const userErr = userState.error;

  const getUsers = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        await dispatch(fetchUsers(token, query));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getUser = useCallback(
    async (userId: string) => {
      const token = await getAccessTokenSilently();

      if (token && userId && loggedInUser) {
        await dispatch(fetchUser(token, userId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addUser = useCallback(
    async (userObj: Interfaces.User) => {
      const token = await getAccessTokenSilently();

      if (token && userObj && loggedInUser) {
        await dispatch(createUser(token, userObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editUser = useCallback(
    async (userObj: Partial<Interfaces.UserOutput>) => {
      const token = await getAccessTokenSilently();

      if (token && userObj && loggedInUser) {
        const userUpdateObj = { ...userObj };

        // If not a direct Auth0 connection, remove email
        const isAuth0 = userObj.auth0Id?.includes(AUTH0_CONNECTION_IDENTIFIER);

        if (!isAuth0) {
          delete userUpdateObj.email;
        }

        await dispatch(updateUser(token, userUpdateObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeUser = useCallback(
    async (userId: string) => {
      const token = await getAccessTokenSilently();

      if (token && userId && loggedInUser) {
        await dispatch(deleteUser(token, userId, loggedInUser._id));

        // Remove user from current users
        await dispatch(
          fetchUsersSuccess(
            usersState.data.filter((d) => d.entity._id !== userId),
          ),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, usersState.data],
  );

  const getMe = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (token && loggedInUser) {
      let result;
      if (token && loggedInUser) {
        result = await UserService.getMe(token);
      }

      return result;
    }
  }, [getAccessTokenSilently, loggedInUser]);

  useEffect(() => {
    if (userObj) {
      // Complete model
      setUser(userObj.entity);
      setUserAccessLevel(userObj.accessLevel);
    }

    if (userErr) {
      setUserError(userErr);
    }
  }, [userErr, userObj]);

  return {
    user,
    userAccessLevel,
    userError,
    getUsers,
    getUser,
    getMe,
    addUser,
    editUser,
    removeUser,
  };
};

export default useUser;
