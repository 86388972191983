import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import buildFormulaOutput from '../../util/build-formula/BuildFormula';

interface UseFormula {
  addFormulaItem: (item: Interfaces.FormulaOperandValue) => void;
  resetFormula: () => void;
  formula: Interfaces.FormulaOperandValue[];
  formulaOutput: (React.ReactElement | null)[];
  endNesting: () => void;
}

const useFormula = (
  schema: Interfaces.Field[],
  existingFormula?: Interfaces.FormulaOperandValue[],
): UseFormula => {
  const [formula, setFormula] = useState<Interfaces.FormulaOperandValue[]>(
    existingFormula || [],
  );
  const [formulaOutput, setFormulaOutput] = useState<
    (React.ReactElement | null)[]
  >([]);
  const [endedNesting, setEndedNesting] = useState<boolean>(false);

  /**
   * Gets all of the nested layers and then returns the currently active nest
   */
  const getActiveNest = useCallback(
    (formula: Interfaces.FormulaOperandValue[]) => {
      const nestItems: Interfaces.FormulaOperandValue[] = [];

      const getLastItem = (formula) => {
        const lastItem = formula[formula.length - 1];

        if (lastItem?.type === Enums.FormulaOperandType.NESTED) {
          nestItems.push(lastItem);

          if (lastItem.value.formula.length) {
            getLastItem(lastItem.value.formula);
          }
        }
      };
      getLastItem(formula);

      return nestItems[
        endedNesting ? nestItems.length - 2 : nestItems.length - 1
      ];
    },
    [endedNesting],
  );

  /**
   * Adds a formula item to the formula
   */
  const addFormulaItem = useCallback(
    (item: Interfaces.FormulaOperandValue) => {
      const cloned = cloneDeep(formula);
      const lastItem = getActiveNest(cloned);
      const isNesting = lastItem?.type === Enums.FormulaOperandType.NESTED;

      if (lastItem && isNesting) {
        (lastItem as Interfaces.NestedFormulaOperand)?.value.formula?.push(
          item,
        );

        if (endedNesting) {
          setEndedNesting(false);
        }
      } else {
        if (endedNesting) {
          setEndedNesting(false);
        }
        cloned.push(item);
      }

      setFormula(cloned);
    },
    [endedNesting, formula, getActiveNest],
  );

  /**
   * Used when the user wish to stop adding elements to a child nest
   */
  const endNesting = () => {
    setEndedNesting(true);
  };

  /**
   * Used to clear the formula and formula output
   */
  const resetFormula = () => {
    setFormula([]);
    setFormulaOutput([]);
  };

  useEffect(() => {
    if (formula.length) {
      setFormulaOutput(
        buildFormulaOutput(
          formula,
          addFormulaItem,
          getActiveNest(formula),
          schema,
        ),
      );
    }
  }, [addFormulaItem, formula, getActiveNest, schema]);

  return { addFormulaItem, resetFormula, formula, formulaOutput, endNesting };
};

export default useFormula;
