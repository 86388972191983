import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faTimes,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { FORMULA_TYPES } from '../../../const/FormulaConst';
import { EntityType, RouteName } from '../../../enums';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useInput from '../../../hooks/input/useInput';
import useIntegrationConfig from '../../../hooks/integration-config/UseIntegrationConfig';
import useOAuth from '../../../hooks/oauth/UseOAuth';
import useOrganisation from '../../../hooks/organisation/UseOrganisation';
import {
  defaultTheme,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledH5,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import { IntegrationConfigItem } from '../../../services/integration/IntegrationConfigService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import {
  updateActiveDatasetStage,
  updateActiveDatasetSubStage,
} from '../../../store/dataset-stage';
import { DatasetStructureSubStage } from '../../../store/dataset-stage/initial-state';
import { fetchIntegrationConfigSuccess } from '../../../store/integration-config';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import validateValue from '../../../util/data-validator/DataValidator';
import getDefaultAvatar from '../../../util/default-avatar/DefaultAvatar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import IntegrationTagList from '../../IntegrationTable/IntegrationTagList';
import { InputContainer } from '../pipeline/styled';
import * as SC from './styled';

export const INTEGRATION_TEMPLATE_REF = 'INTEGRATION_TEMPLATE_REF';
export const INTEGRATION_CONFIG_ID = 'INTEGRATION_CONFIG_ID';
export const INTEGRATION_ACTION = '/integration';

export interface IntegrationManageModalModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  integrationTemplate: IntegrationTemplateItem;
  integrationConfigDoc?: IntegrationConfigItem;
}

export const TEAMS = 'Microsoft Teams';
export const SLACK = 'Slack';

const IntegrationManageModal: FC<IntegrationManageModalModalProps> = ({
  setShowModal,
  integrationTemplate,
  integrationConfigDoc,
}) => {
  const dispatch = useDispatch();
  const { buildAuthUrl } = useOAuth();
  const themeContext = useContext(ThemeContext);
  const { buildInput } = useInput();
  const { organisation } = useOrganisation();
  const history = useHistory();
  const {
    integrationConfig,
    addIntegrationConfig,
    editIntegrationConfig,
    removeIntegrationConfig,
    getIntegrationConfigs,
  } = useIntegrationConfig();
  const {
    addDatasetMetaFromCustomIntegration,
    addDatasetMetaFromHttpIntegration,
  } = useDatasetMeta();

  const [fields, setFields] = useState<Interfaces.Field[]>();
  const [inputs, setInputs] = useState<React.ReactElement[]>([]);
  const [rowEntry, setRowEntry] = useState<Record<string, string | number>>({});
  const [errorFields, setErrorFields] = useState<Record<string, boolean>>({});
  const [authenticating, setAuthenticating] = useState<boolean>();
  const [configureAccordionStatus, setConfigureAccordionStatus] =
    useState<boolean>(true);
  const [actionAccordionStatus, setActionAccordionStatus] =
    useState<boolean>(false);
  const [endpointAccordionStatus, setEndpointAccordionStatus] =
    useState<number>(0);

  const NAME = 'name';
  const URL = 'url';

  const defaultWebhook =
    integrationTemplate.entity.name === SLACK ||
    integrationTemplate.entity.name === TEAMS
      ? ([
          { field: '', value: '', type: ['TEXT'] },
        ] as unknown as Interfaces.CustomConfigParam[])
      : undefined;

  const [webhooks, setWebhooks] = useState<
    Interfaces.CustomConfigParam[] | undefined
  >(defaultWebhook);

  // Set Config Doc if provided
  useEffect(() => {
    if (integrationConfigDoc) {
      dispatch(fetchIntegrationConfigSuccess(integrationConfigDoc));
    }
  }, [dispatch, integrationConfigDoc]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Map integrationTemplate schemaFields to fields
  useEffect(() => {
    setFields(
      integrationTemplate?.entity.customConfig?.map(
        Mappers.SchemaFieldMapper.domainSchemaToDbSchema,
      ) || [],
    );
  }, [integrationTemplate?.entity.customConfig]);

  // If editing existing Config include values
  useEffect(() => {
    if (integrationConfig?._id && integrationConfig.customConfig) {
      setRowEntry(
        integrationConfig.customConfig?.reduce((acc, config) => {
          return { ...acc, [config.field]: config.value };
        }, {}),
      );
      if (
        (integrationConfig.name === SLACK &&
          !!integrationConfig.customConfig.length) ||
        (integrationConfig.name === TEAMS &&
          !!integrationConfig.customConfig.length)
      ) {
        setWebhooks(integrationConfig.customConfig);
      }
    }
  }, [
    integrationConfig?._id,
    integrationConfig?.customConfig,
    integrationConfig?.name,
  ]);

  const handleChange = useCallback(
    (field: string, value: unknown) => {
      const schemaField = fields?.find((f) => f.name === field);

      if (schemaField) {
        const dataType = schemaField.dataValidation?.dataValidationType;

        let isValid = true;
        if (dataType) {
          isValid = validateValue(dataType, value);
        }
        const cloned = cloneDeep(rowEntry);
        const clonedErrors = cloneDeep(errorFields);

        cloned[field] = value;

        if (isValid) {
          if (clonedErrors[field]) {
            delete clonedErrors[field];
            setErrorFields(clonedErrors);
          }
        } else {
          clonedErrors[field] = true;
          setErrorFields(clonedErrors);
        }

        setRowEntry(cloned);
      }
    },
    [fields, errorFields, rowEntry],
  );

  // Build inputs
  useEffect(() => {
    if (fields && fields?.length) {
      const inputs = fields.reduce((acc, field) => {
        const type = field?.dataValidation?.dataValidationType;
        const val = rowEntry[field.name];

        if (type) {
          if (FORMULA_TYPES.includes(type)) {
            return acc;
          }

          acc.push(
            <SC.InputItem key={`input-field-${field.name}`}>
              <SC.InputTitleWrapper>
                <SC.InputHeader>{field.name}</SC.InputHeader>
              </SC.InputTitleWrapper>

              {buildInput(
                field,
                type,
                val,
                handleChange,
                errorFields[field.name],
              )}
            </SC.InputItem>,
          );
        }

        return acc;
      }, [] as React.ReactElement[]);

      if (inputs) {
        setInputs(inputs);
      }
    }
  }, [buildInput, handleChange, fields, errorFields, rowEntry]);

  const confirmCanProceed = () => {
    if (!integrationTemplate?.entity.customConfig) {
      return true;
    }

    // confirm all webhook fields have a value
    if (webhooks) {
      return webhooks.every((webhook) => webhook.field && webhook.value);
    }

    // Confirm all fields have a value
    return integrationTemplate.entity.customConfig.every(
      (config) => rowEntry[config.field]?.toString().length,
    );
  };

  const processAction = async () => {
    // Create integration config
    if (organisation && integrationTemplate && integrationConfig) {
      const cloned = cloneDeep(integrationConfig);

      // Dupe required info from template
      cloned.organisationId = organisation._id;
      cloned.name = integrationTemplate.entity.name;
      cloned.description = integrationTemplate.entity.description;
      cloned.access = integrationTemplate.entity.access;
      cloned.integrationTemplateId = integrationTemplate.entity._id;

      // Map custom config
      cloned.customConfig = integrationTemplate?.entity.customConfig?.map(
        (config) => {
          return { ...config, value: rowEntry[config.field] };
        },
      );

      // Add webhooks to custom config if they exist
      if (webhooks) {
        cloned.customConfig = webhooks;
      }

      dispatch(
        showLoading({
          text: `${!cloned._id ? 'Creating' : 'Updating'} Integration`,
        }),
      );
      if (cloned._id) {
        await editIntegrationConfig(cloned);
        await getIntegrationConfigs();
      } else {
        await addIntegrationConfig(cloned);
        // Refetch configs
        await getIntegrationConfigs();
      }
      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  const createEmptyCustomIntegrationDataset = async (
    actionId: string,
    actionName: string,
    actionSchema: Interfaces.SchemaField[],
    avatar: string,
  ) => {
    dispatch(showLoading({ text: 'Creating Dataset...' }));

    // create an array of strings containg schema field froma action schema
    const schemaFieldIds = actionSchema.map((field) => field.field);

    const integrationDataset = await addDatasetMetaFromCustomIntegration(
      integrationTemplate.entity._id as string,
      integrationTemplate.entity.name as string,
      actionId,
      actionName,
      schemaFieldIds,
      avatar,
    );

    if (!integrationDataset) {
      return;
    }

    dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE));
    dispatch(
      updateActiveDatasetSubStage(DatasetStructureSubStage.CONFIRMATION),
    );
    history.push(
      `${RouteName.DATASET_ITEM}/${integrationDataset.entity?._id}${INTEGRATION_ACTION}`,
    );

    dispatch(hideModal());
  };

  const createEmptyHttpDataset = async (
    endpointId: string,
    reqEndpointId: string,
    actionSchema: Interfaces.SchemaField[],
    avatar: string,
    endpointName: string,
  ) => {
    dispatch(showLoading({ text: 'Creating Dataset...' }));

    // create an array of strings containg schema field froma action schema
    const schemaFieldIds = actionSchema.map((field) => field.field);

    const httpDataset = await addDatasetMetaFromHttpIntegration(
      integrationTemplate.entity._id as string,
      endpointId,
      reqEndpointId,
      schemaFieldIds,
      avatar,
      integrationTemplate.entity.name,
      endpointName,
    );

    if (!httpDataset) {
      return;
    }

    dispatch(updateActiveDatasetStage(Enums.DatasetStage.STRUCTURE));
    dispatch(
      updateActiveDatasetSubStage(DatasetStructureSubStage.CONFIRMATION),
    );
    history.push(
      `${RouteName.DATASET_ITEM}/${httpDataset.entity?._id}${INTEGRATION_ACTION}`,
    );

    dispatch(hideModal());
  };

  const processDelete = async () => {
    if (integrationConfig?._id) {
      dispatch(
        showLoading({
          text: `Deleting Integration`,
        }),
      );

      await removeIntegrationConfig(integrationConfig?._id);

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  const addWebhookRow = () => {
    const cloned = cloneDeep(webhooks);
    cloned.push({ field: '', value: '', type: ['TEXT'] });
    setWebhooks(cloned);
  };

  const deleteWebhookRow = (index: number) => {
    const cloned = cloneDeep(webhooks);
    cloned.splice(index, 1);
    setWebhooks(cloned);
  };

  const handleFieldChange = (value: string, field: string, index: number) => {
    const cloned = cloneDeep(webhooks);
    if (field === NAME) {
      cloned[index].field = value;
    }
    if (field === URL) {
      cloned[index].value = value;
    }
    setWebhooks(cloned);
  };

  useEffect(() => {
    if (
      authenticating &&
      integrationTemplate?.entity?.integrationRef &&
      integrationConfig?._id
    ) {
      (async () => {
        dispatch(showLoading({ text: 'Authenticating Integration...' }));

        const authUrl = await buildAuthUrl(
          integrationTemplate.entity.integrationRef as string,
          integrationConfig._id,
        );

        if (authUrl) {
          localStorage.setItem(
            `${INTEGRATION_TEMPLATE_REF}`,
            JSON.stringify(integrationTemplate.entity.integrationRef),
          );
          localStorage.setItem(
            `${INTEGRATION_CONFIG_ID}`,
            JSON.stringify(integrationConfig._id),
          );

          window.location.href = authUrl;
        }
      })();
    }
  }, [
    authenticating,
    buildAuthUrl,
    dispatch,
    integrationConfig?._id,
    integrationTemplate?.entity?.integrationRef,
  ]);

  const authenticateIntegration = async () => {
    if (!integrationConfig?._id && organisation && integrationTemplate) {
      // Create integrationConfig doc

      const cloned = cloneDeep(integrationConfig);

      // Dupe required info from template
      cloned.organisationId = organisation._id;
      cloned.name = integrationTemplate.entity.name;
      cloned.description = integrationTemplate.entity.description;
      cloned.access = integrationTemplate.entity.access;
      cloned.integrationTemplateId = integrationTemplate.entity._id;

      // Map custom config
      cloned.customConfig = integrationTemplate?.entity.customConfig?.map(
        (config) => {
          return { ...config, value: rowEntry[config.field] };
        },
      );

      dispatch(
        showLoading({
          text: `${!cloned._id ? 'Creating' : 'Updating'} Integration`,
        }),
      );

      await addIntegrationConfig(cloned);

      dispatch(hideLoading());
    }

    setAuthenticating(true);
  };

  const unauthenticateIntegration = async () => {
    // Remove oauth from doc
    const cloned = cloneDeep(integrationConfig);

    cloned.oAuth = null;

    dispatch(
      showLoading({
        text: `Unlinking account...`,
      }),
    );

    await editIntegrationConfig(cloned);

    dispatch(hideLoading());
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>Manage Integration</SC.Header>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={themeContext.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>

      <SC.ContentContainer>
        <SC.SideBar>
          <SC.Image
            src={
              integrationTemplate?.entity.avatar ||
              getDefaultAvatar(EntityType.INTEGRATION)
            }
            alt={`${integrationTemplate?.entity.name} Avatar`}
          />
          <StyledSubHeader>{integrationTemplate?.entity.name}</StyledSubHeader>
          <SC.BodyText>{integrationTemplate?.entity.description}</SC.BodyText>
          <IntegrationTagList tags={integrationTemplate?.entity.tags} />
        </SC.SideBar>
        <SC.MainContentContainer>
          <SC.Content>
            {(integrationTemplate?.entity.name == SLACK ||
              integrationTemplate?.entity.name == TEAMS) && (
              <>
                <StyledBodySubHeader
                  style={{ marginBottom: defaultTheme.margin.large }}
                >
                  Webhooks
                </StyledBodySubHeader>
                <SC.BodyText>
                  Enter your Channel Name and Webhook URL below to allow us to
                  send notifications to your{' '}
                  {integrationTemplate.entity.name === SLACK && 'Slack '}
                  {integrationTemplate.entity.name === TEAMS && 'Teams '}
                  Channel
                </SC.BodyText>
                <SC.WebhookList>
                  {webhooks?.map((webhook, index) => {
                    return (
                      <SC.WebhookContainer>
                        <InputContainer>
                          <StyledH5>Channel Name</StyledH5>
                          <StyledInput
                            placeholder={'Please enter a Channel name'}
                            value={webhook.field}
                            onChange={(e, data) => {
                              handleFieldChange(data.value, NAME, index);
                            }}
                          />
                        </InputContainer>
                        <InputContainer>
                          <StyledH5>Webhook URL</StyledH5>
                          <StyledInput
                            placeholder={'Please enter a Webhook URL'}
                            value={webhook.value}
                            onChange={(e, data) => {
                              handleFieldChange(data.value, URL, index);
                            }}
                          />
                        </InputContainer>
                        <SC.WebhookActions>
                          {index === webhooks.length - 1 && (
                            <FeatureButton
                              size={34}
                              height={34}
                              color={themeContext.colors.general.blue}
                              icon={
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color={defaultTheme.colors.system.white}
                                />
                              }
                              style={{
                                marginRight: themeContext.margin.standard,
                              }}
                              action={addWebhookRow}
                            />
                          )}
                          <FeatureButton
                            size={34}
                            height={34}
                            color={themeContext.colors.general.red}
                            icon={
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                color={defaultTheme.colors.system.white}
                              />
                            }
                            action={() => deleteWebhookRow(index)}
                            isDisabled={webhooks.length === 1}
                          />
                        </SC.WebhookActions>
                      </SC.WebhookContainer>
                    );
                  })}
                </SC.WebhookList>
              </>
            )}
            {integrationTemplate.entity.customActions &&
              !!integrationTemplate?.entity.customActions.length &&
              integrationTemplate?.entity.oAuth && (
                <>
                  <SC.ActionsIntro>
                    <StyledSubHeader>Integration Actions</StyledSubHeader>
                    <StyledText>
                      You can create an empty dataset using the schema of an
                      integration’s action. <br></br>Simply choose the action
                      you want to base it off and hit the Create empty dataset
                      button to get started.
                    </StyledText>
                  </SC.ActionsIntro>
                  <SC.StyledAccordion
                    marginBotton={themeContext.margin.large}
                    marginTop={themeContext.margin.large}
                  >
                    <StyledAccordionTitle
                      onClick={() => {
                        setActionAccordionStatus(!actionAccordionStatus);
                      }}
                    >
                      <SC.AccordionTitleContainer>
                        <StyledSubHeader>
                          {integrationTemplate.entity.name}
                        </StyledSubHeader>
                        <StyledText>
                          {integrationTemplate.entity.description}
                        </StyledText>
                      </SC.AccordionTitleContainer>
                      <FontAwesomeIcon
                        icon={
                          actionAccordionStatus ? faChevronUp : faChevronDown
                        }
                        color={defaultTheme.colors.system.offBlack}
                      />
                    </StyledAccordionTitle>
                    <SC.StyledActionAccordionContent
                      style={{
                        padding: themeContext.padding.xxlarge,
                      }}
                      active={actionAccordionStatus}
                    >
                      <SC.Actions>
                        {integrationTemplate.entity.customActions.map(
                          (action) => {
                            return (
                              <SC.ActionContainer>
                                <div>
                                  <StyledSubHeader
                                    marginBottom={themeContext.margin.small}
                                  >
                                    {action.name}
                                  </StyledSubHeader>
                                  <SC.ActionText>
                                    {action.description}
                                  </SC.ActionText>
                                </div>
                                <div>
                                  <FeatureButton
                                    size={FeatureButtonSize.WIDE}
                                    color={themeContext.colors.general.blue}
                                    text={
                                      action.responseSchema &&
                                      action.responseSchema.length
                                        ? 'Create Empty Dataset'
                                        : 'No Schema Available'
                                    }
                                    action={() => {
                                      createEmptyCustomIntegrationDataset(
                                        action?.actionName as string,
                                        action.name as string,
                                        action.responseSchema as Interfaces.SchemaField[],
                                        organisation?.theme?.logo as string,
                                      );
                                    }}
                                    isDisabled={
                                      !action.responseSchema ||
                                      !action.responseSchema.length
                                    }
                                  />
                                </div>
                              </SC.ActionContainer>
                            );
                          },
                        )}
                      </SC.Actions>
                    </SC.StyledActionAccordionContent>
                  </SC.StyledAccordion>
                </>
              )}

            {!!integrationTemplate?.entity.endpoints.length && (
              <>
                <SC.ActionsIntro>
                  <StyledSubHeader marginBottom={themeContext.margin.large}>
                    Integration Endpoints
                  </StyledSubHeader>
                  <StyledText
                    style={{ marginBottom: themeContext.margin.small }}
                  >
                    You can create an empty dataset using the schema of an
                    integration’s endpoint.
                  </StyledText>
                  <StyledText>
                    Simply choose the endpoint you want to base it off and hit
                    the Create empty dataset button to get started.
                  </StyledText>
                </SC.ActionsIntro>
                {integrationTemplate?.entity.endpoints.map(
                  (endpointGroup, index) => {
                    return (
                      <SC.StyledAccordion
                        marginBotton={themeContext.margin.large}
                        marginTop={themeContext.margin.large}
                      >
                        <StyledAccordionTitle
                          onClick={() => {
                            setEndpointAccordionStatus(
                              index === endpointAccordionStatus ? -1 : index,
                            );
                          }}
                        >
                          <SC.AccordionTitleContainer>
                            <StyledSubHeader>
                              {endpointGroup.name}
                            </StyledSubHeader>
                            {endpointGroup.description && (
                              <StyledText>
                                {endpointGroup.description}
                              </StyledText>
                            )}
                          </SC.AccordionTitleContainer>
                          <FontAwesomeIcon
                            icon={
                              endpointAccordionStatus === index
                                ? faChevronUp
                                : faChevronDown
                            }
                            color={defaultTheme.colors.system.offBlack}
                          />
                        </StyledAccordionTitle>
                        {endpointGroup.endpoints.map((endpoint) => {
                          return (
                            <SC.StyledActionAccordionContent
                              style={{
                                padding: themeContext.padding.xxlarge,
                              }}
                              active={endpointAccordionStatus === index}
                            >
                              <SC.Actions>
                                <SC.ActionContainer>
                                  <div>
                                    <StyledSubHeader
                                      marginBottom={themeContext.margin.small}
                                    >
                                      {endpoint.name}
                                    </StyledSubHeader>
                                    <SC.ActionText>
                                      {endpoint.description}
                                    </SC.ActionText>
                                  </div>
                                  <div>
                                    <FeatureButton
                                      size={FeatureButtonSize.WIDE}
                                      color={themeContext.colors.general.blue}
                                      text={
                                        !endpoint.responseInfo
                                          ?.responseSchema ||
                                        !endpoint.responseInfo?.responseSchema
                                          .length
                                          ? 'No Schema Available'
                                          : 'Create Empty Dataset'
                                      }
                                      action={() => {
                                        createEmptyHttpDataset(
                                          endpointGroup._id as string,
                                          endpoint._id as string,
                                          endpoint.responseInfo
                                            ?.responseSchema as Interfaces.SchemaField[],
                                          organisation?.theme?.logo as string,
                                          endpoint.name as string,
                                        );
                                      }}
                                      isDisabled={
                                        !endpoint.responseInfo
                                          ?.responseSchema ||
                                        !endpoint.responseInfo?.responseSchema
                                          .length
                                      }
                                    />
                                  </div>
                                </SC.ActionContainer>
                              </SC.Actions>
                            </SC.StyledActionAccordionContent>
                          );
                        })}
                      </SC.StyledAccordion>
                    );
                  },
                )}
              </>
            )}

            {(integrationTemplate?.entity.customConfig?.length ||
              integrationTemplate?.entity.oAuth) && (
              <>
                <SC.StyledAccordion>
                  <StyledAccordionTitle
                    onClick={() => {
                      setConfigureAccordionStatus(!configureAccordionStatus);
                    }}
                  >
                    <StyledSubHeader>Configure Integration</StyledSubHeader>
                    <FontAwesomeIcon
                      icon={
                        configureAccordionStatus ? faChevronUp : faChevronDown
                      }
                      color={defaultTheme.colors.system.offBlack}
                    />
                  </StyledAccordionTitle>
                  <SC.StyledAccordionContent
                    style={{
                      padding: themeContext.padding.xxlarge,
                      textAlign: 'center',
                    }}
                    active={configureAccordionStatus}
                  >
                    {!!integrationTemplate?.entity.customConfig?.length &&
                      integrationTemplate?.entity.customConfig && (
                        <div style={{ margin: 'auto' }}>
                          <SC.BodyText>
                            This integration requires some additional custom
                            configuration.
                          </SC.BodyText>
                          <SC.BodyText>
                            Enter the required information in the fields below.
                          </SC.BodyText>

                          <SC.InputsWrapper>{inputs}</SC.InputsWrapper>
                        </div>
                      )}

                    {!!integrationTemplate?.entity.oAuth && (
                      <>
                        <StyledSubHeader
                          style={{
                            marginTop: themeContext.margin.standard,
                            marginBottom: themeContext.margin.large,
                          }}
                        >
                          Authenticate {integrationTemplate?.entity.name}{' '}
                          Integration
                        </StyledSubHeader>

                        <SC.BodyText
                          style={{
                            marginBottom: themeContext.margin.xxlarge,
                          }}
                        >
                          This integration requires you to authenticate your
                          account. Click the button below to be take to Xero to
                          complete the authentication process.
                        </SC.BodyText>

                        {!integrationConfig?.oAuth && (
                          <FeatureButton
                            action={authenticateIntegration}
                            isDisabled={authenticating}
                            size={FeatureButtonSize.WIDE}
                            color={themeContext.colors.general.blue}
                            text={'Authenticate'}
                          />
                        )}

                        {integrationConfig?.oAuth && (
                          <FeatureButton
                            action={unauthenticateIntegration}
                            size={FeatureButtonSize.WIDE}
                            color={themeContext.colors.general.red}
                            text={'Unlink Account'}
                          />
                        )}
                      </>
                    )}
                  </SC.StyledAccordionContent>
                </SC.StyledAccordion>
              </>
            )}

            {!integrationTemplate?.entity.customConfig?.length &&
              !integrationTemplate?.entity.oAuth && (
                <>
                  <SC.BodyText>
                    This integration doesn't require any additional custom
                    configuration.
                  </SC.BodyText>
                  <SC.BodyText>
                    Simply hit the button below to activate it!
                  </SC.BodyText>
                </>
              )}
          </SC.Content>
        </SC.MainContentContainer>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper multipleButtons={!!integrationConfig?._id}>
        {integrationConfig?._id && (
          <FeatureButton
            action={processDelete}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={'Deactivate Integration'}
          />
        )}

        <FeatureButton
          isDisabled={!confirmCanProceed()}
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Activate & Save'}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default IntegrationManageModal;
