import React, { FC } from 'react';
import { defaultTheme } from '../../main/theme';
import FeatureButton, {
  FeatureButtonProps,
} from '../FeatureButton/FeatureButton';

const firstItemStyle = {
  borderRadius: `${defaultTheme.borders.radius} 0 0 ${defaultTheme.borders.radius}`,
};
const centerItemStyle = { borderRadius: 0 };
const lastItemStyle = {
  borderRadius: `0 ${defaultTheme.borders.radius} ${defaultTheme.borders.radius} 0`,
};

const getItemStyle = (index: number, arrLength: number) => {
  switch (index) {
    case 0:
      return firstItemStyle;
    case arrLength - 1:
      return lastItemStyle;
    default:
      return centerItemStyle;
  }
};

interface FeatureButtonSelectProps {
  buttons: FeatureButtonProps[];
}

const FeatureButtonSelect: FC<FeatureButtonSelectProps> = ({ buttons }) => {
  return (
    <div style={{ display: 'flex' }}>
      {buttons.map((button, index) => {
        const itemStyle = getItemStyle(index, buttons.length);

        return (
          <FeatureButton
            key={`feature-button-select-${index}`}
            isActive={button.isActive}
            action={button.action}
            size={button.size}
            color={button.color}
            text={button.text}
            textStyle={button.textStyle}
            style={{ ...button.style, ...itemStyle }}
            faceStyle={{ ...button.style, ...itemStyle }}
          />
        );
      })}
    </div>
  );
};

export default FeatureButtonSelect;
