import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const LinkedWrapper = styled.div`
  width: 600px;
  height: 100%;

  padding: ${({ theme }) => theme.padding.xxxlarge};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
  background-color: ${({ theme }) => theme.colors.system.offWhite};

  overflow-y: auto;
`;

export const LinkedDataset = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};
`;

export const LinkedDatasetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    margin: 0;
  }
`;

export const HeaderButtons = styled.div`
  display: flex;

  > div:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.margin.standard};
  }
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const NoResultsText = styled(StyledText)`
  text-align: center;
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
`;
