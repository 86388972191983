import { faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DefaultPopupStyles } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../store/rootReducer';
import DroppableComp from '../DragAndDrop/DroppableComp';
import { SortItem } from './AggregationSort';
import * as SC from './styled';

interface AggregationDisplayOrderProps {
  displayOrderItems: SortItem[];
  onChange: (sortItems: SortItem[]) => void;
  disabled?: boolean;
}

const AggregationDisplayOrder: FC<AggregationDisplayOrderProps> = ({
  displayOrderItems,
  onChange,
  disabled,
}) => {
  const themeContext = useContext(ThemeContext);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  if (!displayOrderItems?.length) {
    return null;
  }

  const reorderItems = (list, startIndex, endIndex): SortItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result as SortItem[];
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorderItems(
      displayOrderItems,
      result.source.index,
      result.destination.index,
    );

    onChange(orderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DroppableComp
        dropComp={
          <Droppable droppableId="droppable-displayorder">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {displayOrderItems.map((item, index) => (
                  <Draggable
                    isDragDisabled={disabled}
                    key={`${item.field}-${index}`}
                    draggableId={`${item.field}-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <Popup
                        content={
                          datasetMetas?.find(
                            (d) => d.entity._id === item.datasetMetaId,
                          )?.entity.name
                        }
                        position="left center"
                        style={DefaultPopupStyles}
                        hideOnScroll={true}
                        trigger={
                          <SC.DisplayOrderButton
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <FontAwesomeIcon
                              icon={faGripLines}
                              color={themeContext.colors.system.darkGrey}
                            />
                            {item.alias || item.field}
                          </SC.DisplayOrderButton>
                        }
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }
      />
    </DragDropContext>
  );
};

export default AggregationDisplayOrder;
