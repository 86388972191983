import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import TeamItemAdvancedStage from '../../components/TeamItemStages/TeamItemConfigurationStages/1-advanced/TeamConfigureAdvancedStage';
import Page from '../../main/Page';
import { RootState } from '../../store/rootReducer';
import {
  TeamConfigureSubStage,
  TeamStage,
} from '../../store/team-stage/initial-state';
import {
  getTeamStageTitle,
  getTeamSubStageContent,
} from '../../util/team-stage-content/TeamStageContent';

const TeamConfiguration: FC = () => {
  const teamStageState = useSelector((state: RootState) => state.teamStage);

  const activeStage = TeamStage.CONFIGURATION;
  const activeSubStage = teamStageState.stages[activeStage].activeSubStage;

  const stageTitle = getTeamStageTitle(activeStage);
  const subStageContent = getTeamSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case TeamConfigureSubStage.ADVANCED:
        return <TeamItemAdvancedStage />;
      default:
        return <TeamItemAdvancedStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={subStageContent.image}
        title={subStageContent.title}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default TeamConfiguration;
