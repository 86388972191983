import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamItemOutput } from '../../services/team/TeamService';
import { RootState } from '../../store/rootReducer';
import {
  createTeam,
  deleteTeam,
  fetchTeam,
  updateTeam,
} from '../../store/team';
import { fetchTeams } from '../../store/teams';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useTeamResult {
  team?: Interfaces.TeamOutput;
  teamAccessLevel?: Enums.AccessLevel;
  teamError?: string;

  getTeams: (
    query?: Record<string, unknown>,
    page?: number,
    limit?: number,
    orderBy?: Record<string, number>,
  ) => Promise<void>;
  getTeam: (teamId: string) => void;
  addTeam: (teamObj: Interfaces.Team) => void;
  editTeam: (teamObj: Interfaces.TeamOutput) => void;
  removeTeam: (teamId: string) => void;
}

const useTeam = (): useTeamResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();

  const [team, setTeam] = useState<Interfaces.TeamOutput>();
  const [teamAccessLevel, setTeamAccessLevel] = useState<Enums.AccessLevel>();
  const [teamError, setTeamError] = useState<string>();

  const teamState = useSelector((state: RootState) => state.team);
  const teamObj = teamState.data as TeamItemOutput;
  const teamErr = teamState.error;

  const getTeams = useCallback(
    async (
      query?: Record<string, unknown>,
      page?: number,
      limit?: number,
      orderBy?: Record<string, number>,
    ) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(fetchTeams(token, query, page, limit, orderBy));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const getTeam = useCallback(
    async (teamId: string) => {
      const token = await getAccessTokenSilently();

      if (token && teamId) {
        await dispatch(fetchTeam(token, teamId));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addTeam = useCallback(
    async (teamObj: Interfaces.Team) => {
      const token = await getAccessTokenSilently();

      if (token && teamObj && loggedInUser) {
        await dispatch(createTeam(token, teamObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editTeam = useCallback(
    async (teamObj: Interfaces.TeamOutput) => {
      const token = await getAccessTokenSilently();

      if (token && teamObj && loggedInUser) {
        await dispatch(updateTeam(token, teamObj, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeTeam = useCallback(
    async (teamId: string) => {
      const token = await getAccessTokenSilently();

      if (token && teamId && loggedInUser) {
        await dispatch(deleteTeam(token, teamId, loggedInUser._id));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  useEffect(() => {
    if (teamObj) {
      // Complete model
      setTeam(teamObj.entity);
      setTeamAccessLevel(teamObj.accessLevel);
    }

    if (teamErr) {
      setTeamError(teamErr);
    }
  }, [teamErr, teamObj]);

  return {
    team,
    teamAccessLevel,
    teamError,
    getTeams,
    getTeam,
    addTeam,
    editTeam,
    removeTeam,
  };
};

export default useTeam;
