import { Enums } from '@configur-tech/upit-core-types';
import { isEmpty } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../enums';
import { ProjectStage } from '../../../enums/ProjectStage';
import useChart from '../../../hooks/chart/UseChart';
import useProject from '../../../hooks/project/UseProject';
import { SampleDataRow } from '../../../interfaces/SampleData';
import { StageBodyText, StageInner, StageWrapper } from '../../../main/theme';
import { fetchChartSuccess, resetChart } from '../../../store/chart';
import { fetchChartsSuccess } from '../../../store/charts';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../store/project-stage';
import { ProjectChartSubStage } from '../../../store/project-stage/initial-state';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

const PAGE_SIZE = 25;
const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const DESCRIPTION_FIELD = 'Description';
const ORDER_BY_PARAM = 'orderBy';

const TABLE_SCHEMA = [{ name: NAME_FIELD }, { name: DESCRIPTION_FIELD }];

enum SortTypes {
  'Name' = 'name',
  'Description' = 'description',
}

const PREV_STAGE = Enums.ProjectStage.CREATION;

const ProjectItemChartStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project, projectAccessLevel } = useProject();
  const { getCharts } = useChart();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [pagination, setPagination] = useState(0);
  const [sortBy, setSortBy] = useState<Record<string, number>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const chartsState = useSelector((state: RootState) => state.charts);
  const charts = chartsState?.data;

  const updateSortBy = useCallback(
    (sortItem: { id: string; desc: boolean }) => {
      if (isEmpty(sortItem)) {
        setSortBy(undefined);
        return;
      }

      const sortProperty = SortTypes[sortItem.id];
      setSortBy({ [sortProperty]: sortItem.desc ? -1 : 1 });
    },
    [],
  );

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  const calculateRowNumber = useCallback(
    (rowIndex) => {
      const displayedRowIndex = rowIndex + 1;
      const initialRowIndex = (pagination - 1) * PAGE_SIZE;

      return (displayedRowIndex + initialRowIndex).toString();
    },
    [pagination],
  );

  // Get charts
  useEffect(() => {
    if (project?._id) {
      if (project?.charts?.length) {
        (async () => {
          const chartParams = {
            pageNum: pagination,
            limit: PAGE_SIZE,
            _id: { $in: project.charts },
          };
          if (!isEmpty(sortBy)) {
            chartParams[ORDER_BY_PARAM] = sortBy;
          }

          await getCharts(chartParams);
          setIsLoading(false);
        })();
      } else {
        dispatch(
          fetchChartsSuccess({
            data: [],
            pagination: {
              currentPageNum: 1,
              prevPageNum: null,
              nextPageNum: null,
              totalCount: 0,
              totalPages: 1,
            },
          }),
        );
        setIsLoading(false);
      }
    }
  }, [dispatch, getCharts, pagination, project?._id, project?.charts, sortBy]);

  // Build table data
  useEffect(() => {
    if (project?._id && charts) {
      const tableData: SampleDataRow[] = [];

      charts?.data.map((chart, index) => {
        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(index) },
          [ID_FIELD]: { value: chart.entity._id },
          [NAME_FIELD]: { value: chart.entity.name },
          [DESCRIPTION_FIELD]: { value: chart.entity.description || '' },
        });
      });

      setTableData(tableData);
    }
  }, [calculateRowNumber, project?._id, charts]);

  return (
    <StageWrapper>
      <StageInner>
        <Banner
          message={
            <p>
              Dashboards have been updated to be even easier to build and can
              now be found{' '}
              <a href={'https://portal.goconfigur.com/dashboards/view'}>here</a>
              .
            </p>
          }
          alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
          alertStatus={AlertStatus.INFO}
        />

        <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
          Create a dashboard to display your data.
        </StageBodyText>

        <SC.ChartsWrapper>
          {/*Deprecated*/}
          {/*{projectAccessLevel === Enums.AccessLevel.MANAGE && (*/}
          {/*  <FeatureButton*/}
          {/*    action={() => {*/}
          {/*      dispatch(resetChart());*/}
          {/*      dispatch(updateActiveProjectStage(ProjectStage.CHARTS));*/}
          {/*      dispatch(*/}
          {/*        updateActiveProjectSubStage(ProjectChartSubStage.NAME),*/}
          {/*      );*/}
          {/*    }}*/}
          {/*    size={FeatureButtonSize.WIDE}*/}
          {/*    color={themeContext.colors.general.blue}*/}
          {/*    text={'Add Dashboard'}*/}
          {/*    containerStyle={{ marginBottom: themeContext.margin.large }}*/}
          {/*  />*/}
          {/*)}*/}

          {projectAccessLevel !== Enums.AccessLevel.MANAGE &&
            !tableData.length &&
            !isLoading && (
              <StageBodyText>
                <strong>No Dashboards found</strong>
              </StageBodyText>
            )}

          {(tableData.length > 0 || isLoading) && (
            <SC.TableWrapper>
              <DataSample
                showPagination={true}
                paginationAction={updatePagination}
                sortAction={updateSortBy}
                pageSize={PAGE_SIZE}
                totalRows={charts?.pagination?.totalCount}
                hideSortOnCols={[ROW_NUMBER]}
                fullWidth={true}
                sampleColumns={TABLE_SCHEMA}
                sampleRows={tableData}
                // Deprecated
                // clickableRows={{
                //   valueField: ID_FIELD,
                //   action: (chartId: string) => {
                //     const chart = charts?.data.find(
                //       (chart) => chart.entity._id === chartId,
                //     );
                //
                //     if (chart) {
                //       dispatch(fetchChartSuccess(chart));
                //     }
                //
                //     dispatch(updateActiveProjectStage(ProjectStage.CHARTS));
                //     dispatch(
                //       updateActiveProjectSubStage(ProjectChartSubStage.CHART),
                //     );
                //   },
                // }}
                loading={isLoading}
              />
            </SC.TableWrapper>
          )}
        </SC.ChartsWrapper>
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemChartStage;
