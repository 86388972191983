import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CollectionSelection from '../../components/CollectionSelection/CollectionSelection';
import DatasetOverview from '../../components/DatasetOverview/DatasetOverview';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import { ModalTypes } from '../../components/Modal/Modal';
import PrimaryNavigationContainer from '../../components/PrimaryNavigationContainer/PrimaryNavigationContainer';
import { RouteName } from '../../enums';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import PageContainer from '../../main/PageContainer';
import { updateDatasetCollection } from '../../store/dataset-collection';
import { AdditionalDatasetStages } from '../../store/dataset-stage/initial-state';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import { RootState } from '../../store/rootReducer';
import PipelinesPage from '../Pipeline/PipelinesPage';
import DatasetItemConfiguration from './DatasetItemConfiguration';
import DatasetItemCreation from './DatasetItemCreation';
import DatasetItemEvents from './DatasetItemEvents';
import DatasetItemStructure from './DatasetItemStructure';
import DatasetItemValidation from './DatasetItemValidation';

const EDIT_ACTION = 'edit';
const REPLACE_ACTION = 'replace';
const MERGE_ACTION = 'merge';

const PageHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 20;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xlarge}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  background-color: ${({ theme }) => theme.colors.system.white};

  > p {
    margin: 0;
  }
`;

const ContentWrapper = styled.div<{ isEditing: boolean }>`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

const DatasetItemPage: FC = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    datasetMeta,
    datasetMetaError,
    activeDataCollectionItem,
    getDatasetMeta,
  } = useDatasetMeta();
  const { lockCollection } = useDatasetCollection();

  const [schema, setSchema] = useState<Interfaces.Field[]>([]);
  const [sampleData, setSampleData] = useState<SampleDataRow[]>([]);
  const [fetchingDatasetMeta, setFetchingDatasetMeta] =
    useState<boolean>(false);

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = datasetStageState.activeStage;
  const isUpdating = !!activeDataCollectionItem?._id?.length;
  const isEditing = location.pathname.includes(EDIT_ACTION);
  const isReplacing = location.pathname.includes(REPLACE_ACTION);
  const isMerging = location.pathname.includes(MERGE_ACTION);

  // Get an existing datasetMeta if requested
  useEffect(() => {
    if (
      !fetchingDatasetMeta &&
      params?.datasetMetaId &&
      params?.datasetMetaId !== datasetMeta?._id
    ) {
      (async () => {
        setFetchingDatasetMeta(true);
        dispatch(
          showLoading({
            text: `Loading Dataset...`,
          }),
        );

        await getDatasetMeta(params.datasetMetaId);

        dispatch(hideLoading());
      })();
    }
  }, [params, datasetMeta?._id, dispatch, fetchingDatasetMeta, getDatasetMeta]);

  // Error fetching DatasetMeta
  useEffect(() => {
    if (datasetMetaError) {
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          forceOpen: true,
          additionalProps: {
            errorType: ErrorType.ENTITY_NOT_FOUND,
            errorHeading: 'Dataset Not Found',
            errorText:
              'This dataset could not be found, it may have been deleted or you do not have access to view it.',
            errorActionText: 'Return to Datasets',
            errorActionRedirectRoute: RouteName.DATASETS,
          },
        }),
      );
    }
  }, [datasetMetaError, dispatch]);

  // If not editing/merging/replacing, reset active data collection
  useEffect(() => {
    if (
      !isEditing &&
      !isReplacing &&
      !isMerging &&
      datasetMeta?._id &&
      activeDataCollectionItem?._id
    ) {
      dispatch(
        updateDatasetCollection({
          datasetMetaId: datasetMeta._id,
          collectionId: activeDataCollectionItem._id,
        }),
      );
      lockCollection();
    }
  }, [
    activeDataCollectionItem?._id,
    datasetMeta?._id,
    dispatch,
    isEditing,
    isMerging,
    isReplacing,
    lockCollection,
  ]);

  const getStageComponent = () => {
    switch (activeStage) {
      case Enums.DatasetStage.CREATION:
        return datasetMeta?._id === params?.datasetMetaId &&
          activeDataCollectionItem?._id &&
          !isReplacing &&
          !isMerging ? (
          <DatasetOverview />
        ) : (
          <DatasetItemCreation
            schema={schema}
            setSchema={setSchema}
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
      case Enums.DatasetStage.STRUCTURE:
        return (
          <DatasetItemStructure
            schema={schema}
            setSchema={setSchema}
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
      case Enums.DatasetStage.VALIDATION:
        return (
          <DatasetItemValidation
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
      case Enums.DatasetStage.CONFIGURATION:
        return <DatasetItemConfiguration />;
      case AdditionalDatasetStages.EVENTS:
        return <DatasetItemEvents />;
      case AdditionalDatasetStages.PIPELINES:
        return <PipelinesPage />;
      default:
        return (
          <DatasetItemCreation
            schema={schema}
            setSchema={setSchema}
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
    }
  };

  return (
    <>
      <PrimaryNavigationContainer route={RouteName.DATASET_ITEM} />
      <PageContainer>
        {isUpdating &&
          !isReplacing &&
          !isMerging &&
          [
            Enums.DatasetStage.STRUCTURE,
            Enums.DatasetStage.VALIDATION,
          ].includes(activeStage as Enums.DatasetStage) && (
            <PageHeader>
              <CollectionSelection />
            </PageHeader>
          )}

        <ContentWrapper isEditing={isUpdating}>
          {getStageComponent()}
        </ContentWrapper>
      </PageContainer>
    </>
  );
};

export default DatasetItemPage;
