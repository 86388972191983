import styled from 'styled-components';
import { StyledDropdown } from '../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => theme.padding.standard};

  background-color: ${({ theme }) => theme.colors.system.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  width: 100%;
`;

export const PortalDropdown = styled(StyledDropdown)`
  font-size: ${({ theme }) => theme.typography.sizes.p};
  ${({ theme }) => theme.typography.header};

  color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.colors.system.offBlack : theme.colors.system.white};

  margin-bottom: ${({ theme }) => theme.margin.small};
  width: 100%;
`;
