import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalTypes } from '../../components/Modal/Modal';
import { ErrorType } from '../../components/Modal/error/ErrorModal';
import DatasetService from '../../services/dataset/DatasetService';
import { hideLoading, showLoading } from '../../store/loading';
import { showModal } from '../../store/modal';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useDatasetMeta from './UseDatasetMeta';

const PAGE_SIZE = 250;

const DUPLICATE_ENTRY_TEXT = 'Duplicate entry';
const DUPLICATE_VAL_SPLIT_CHAR = "'";
const DUPLICATE_KEY_SPLIT_START = 'for key ';
const DUPLICATE_KEY_SPLIT_END = ';';
const DUPLICATE_KEY_UNIQUE_SUFFIX = '_unique';

interface usePublishResult {
  publishDataset: (
    pageNum?: number,
    totalCount?: number,
  ) => Promise<Interfaces.DatasetMetaOutput | undefined>;
}

const usePublish = (): usePublishResult => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();
  const { collection, lockCollection } = useDatasetCollection();

  const isReplacing = location.pathname.includes('replace');
  const isMerging = location.pathname.includes('merge');

  const coll = datasetMeta?.dataCollections.find(
    (c) => c._id === collection?.collectionId,
  );

  const sourceType = coll?.dataSource?.dataSourceType;
  const source = sourceType && coll?.dataSource?.[sourceType];
  const hasPublishedData = !!datasetMeta?.activeDataCollection;
  const mergeSource =
    isMerging &&
    coll?.dataSource?.dataSourceActionType ===
      Enums.DataSourceActionType.MERGE &&
    coll?.dataSource.dataMergeFileId;

  const publishDataset = async (
    pageNum = 1,
    totalCount?: number,
  ): Promise<Interfaces.DatasetMetaOutput | undefined> => {
    try {
      const token = await getAccessTokenSilently();
      if (
        !token ||
        !datasetMeta ||
        !loggedInUser ||
        !(
          (sourceType &&
            [
              Enums.DataSourceType.CSV,
              Enums.DataSourceType.TXT,
              Enums.DataSourceType.XLS,
              Enums.DataSourceType.INTERNAL,
              Enums.DataSourceType.DATASET,
            ].includes(sourceType as Enums.DataSourceType)) ||
          hasPublishedData
        )
      ) {
        dispatch(hideLoading());
        throw Error('Missing required param or incorrect source type');
      }

      dispatch(
        showLoading({
          text: totalCount
            ? `Publishing rows ${Math.min(
                PAGE_SIZE * pageNum,
                totalCount,
              )} of ${totalCount}`
            : 'Publishing Dataset...',
        }),
      );

      const sourceFile = source as Interfaces.DataSourceFile;

      // Build and return sample data
      const head =
        sourceFile.headers === Enums.FileHeaderType.NONE
          ? undefined
          : sourceFile.headers;

      const publishResult = await DatasetService.createDataset(
        token,
        datasetMeta._id,
        loggedInUser._id,
        loggedInUser.organisationId,
        pageNum,
        !hasPublishedData || isReplacing || isMerging
          ? isMerging
            ? mergeSource
            : sourceFile.fileId
          : undefined,
        head,
        sourceFile.headers === Enums.FileHeaderType.NONE,
        sourceFile.delimiter,
        collection?.collectionId,
        sourceFile.sheetName,
        hasPublishedData && !isReplacing && !isMerging
          ? datasetMeta._id
          : undefined,
      );

      if (publishResult.pagination.nextPageNum) {
        return publishDataset(
          publishResult.pagination.nextPageNum,
          publishResult.pagination.totalCount,
        );
      }

      dispatch(showLoading({ text: 'Configuring Dataset...' }));

      // Set collectionId as active
      publishResult.datasetMeta.activeDataCollection = collection?.collectionId;

      const updatedDataCollection =
        publishResult.datasetMeta.dataCollections.find(
          (c) => c._id === collection?.collectionId,
        );

      if (updatedDataCollection) {
        updatedDataCollection.status = Enums.CollectionStatus.ACTIVE;

        // Update stage status
        (
          updatedDataCollection.stages[
            Enums.DatasetStage.VALIDATION
          ] as Interfaces.DatasetMetaStage
        ).status = Enums.StageStatus.COMPLETED;

        dispatch(showLoading({ text: 'Saving Dataset...' }));

        await editDatasetMeta(publishResult.datasetMeta);
        lockCollection();

        dispatch(hideLoading());
      }

      return publishResult.datasetMeta;
    } catch (err) {
      dispatch(hideLoading());

      const isDuplicate = (err as AxiosError).response?.data.error.includes(
        DUPLICATE_ENTRY_TEXT,
      );

      if (isDuplicate) {
        const dupeValue = (err as AxiosError).response?.data.error.split(
          DUPLICATE_VAL_SPLIT_CHAR,
        )?.[1];
        const columnId = (err as AxiosError).response?.data.error
          .split(DUPLICATE_KEY_SPLIT_START)?.[1]
          ?.split(DUPLICATE_KEY_SPLIT_END)?.[0]
          ?.replace(DUPLICATE_KEY_UNIQUE_SUFFIX, '')
          ?.replaceAll(DUPLICATE_VAL_SPLIT_CHAR, '');

        const columnName = coll?.schemaData.find(
          (field) => field.fieldId === columnId,
        )?.name;

        const errString = `Duplicate entry "${dupeValue}" ${
          columnName ? `for column "${columnName}"` : ''
        } which must be unique.`;

        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.ERROR,
            additionalProps: {
              errorType: ErrorType.PUBLISH_FAILED,
              errorText: errString,
            },
          }),
        );
        return;
      }

      // Handle error
      dispatch(
        showModal({
          visible: true,
          modal: ModalTypes.ERROR,
          additionalProps: {
            errorType: ErrorType.PUBLISH_FAILED,
          },
        }),
      );
    }
  };

  return {
    publishDataset,
  };
};
export default usePublish;
