import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchQuery } from '../../interfaces/Search';
import DatasetMetaService, {
  DatasetMetaItemOutput,
  DatasetMetasItemOutput,
} from '../../services/dataset-meta/DatasetMetaService';
import { AppThunk } from '../store';

export interface DatasetMetasState {
  data: {
    data: DatasetMetaItemOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  loading: boolean;
  error: string | null;
}

const initialState: DatasetMetasState = {
  data: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const DatasetMetasSlice = createSlice({
  name: 'datasetMetas',
  initialState,
  reducers: {
    // Fetch
    fetchDatasetMetasStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetMetasSuccess(
      state,
      action: PayloadAction<DatasetMetasItemOutput>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDatasetMetasFailure(state, action: PayloadAction<string>) {
      state.data = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDatasetMetasStart,
  fetchDatasetMetasSuccess,
  fetchDatasetMetasFailure,
} = DatasetMetasSlice.actions;

export default DatasetMetasSlice.reducer;

export const fetchDatasetMetas =
  (
    token: string,
    query?: Record<string, unknown>,
    searchQuery?: SearchQuery,
    pageNum?: number,
    limit?: number,
    orderBy?: Record<string, number>,
    portalId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatasetMetasStart());
      const fetched = await DatasetMetaService.getDatasetMetas(
        token,
        query,
        searchQuery,
        pageNum,
        limit,
        orderBy,
        portalId,
      );

      dispatch(
        fetchDatasetMetasSuccess(
          fetched.data as unknown as DatasetMetasItemOutput,
        ),
      );
    } catch (err) {
      dispatch(fetchDatasetMetasFailure((err as string).toString()));
      throw err;
    }
  };
