import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_TEAM'] || '';

export interface TeamItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Team;
}

export interface TeamItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.TeamOutput;
}

export interface TeamsItemOutput {
  data: TeamItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class TeamService {
  public static async getTeams(
    token: string,
    query?: Record<string, unknown>,
    pageNum = 1,
    limit?: number,
    orderBy?: Record<string, number>,
  ): Promise<
    | Interfaces.SuccessResponseBody<TeamsItemOutput[]>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.get<
      | Interfaces.SuccessResponseBody<TeamsItemOutput[]>
      | Interfaces.ErrorResponseBody
    >(endpoint, { ...query, pageNum, limit, orderBy });
  }

  public static async getTeam(
    token: string,
    id: string,
  ): Promise<TeamItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.get<
      | Interfaces.SuccessResponseBody<TeamItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { _id: id, singleDocument: true });

    return result?.data as TeamItemOutput;
  }

  public static async postTeam(
    token: string,
    team: Interfaces.Team,
    userId: string,
  ): Promise<TeamItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.post<
      | Interfaces.SuccessResponseBody<TeamItemOutput>
      | Interfaces.ErrorResponseBody
    >(endpoint, { data: team, userId });

    return result?.data as TeamItemOutput;
  }

  public static async putTeam(
    token: string,
    team: Interfaces.TeamOutput,
    userId: string,
  ): Promise<TeamItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.put<
      | Interfaces.SuccessResponseBody<TeamItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${team._id}`, { data: team, userId });

    return result?.data as TeamItemOutput;
  }

  public static async deleteTeam(
    token: string,
    teamId: string,
    userId: string,
  ): Promise<TeamItemOutput> {
    const ah = new HttpHelper(token);

    const result = await ah.delete<
      | Interfaces.SuccessResponseBody<TeamItemOutput>
      | Interfaces.ErrorResponseBody
    >(`${endpoint}/${teamId}`, userId);

    return result?.data as TeamItemOutput;
  }
}
