import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import { FORMULA_TYPES } from '../../../const/FormulaConst';
import useCMS from '../../../hooks/cms/useCMS';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useDataset from '../../../hooks/dataset/UseDataset';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import useInput from '../../../hooks/input/useInput';
import usePortal from '../../../hooks/portal/UsePortal';
import useProject from '../../../hooks/project/UseProject';
import { defaultTheme, StyledText } from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { DatasetDeleteRowOutput } from '../../../store/dataset';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { fetchCMSSuccess } from '../../../store/project-cms';
import { RootState } from '../../../store/rootReducer';
import validateValue from '../../../util/data-validator/DataValidator';
import clearSuperColumnValues from '../../../util/entries/ClearSuperColumnValues';
import CMSLinkedDatasets, {
  LinkedDatasetQuery,
} from '../../CMS/CMSLinkedDatasets/CMSLinkedDatasets';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { UndoLink } from '../../NotificationAlert/styled';
import * as SC from './styled';

export const ROW_ID_FIELD = 'row_id';

export interface CMSAddRowModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cmsId: string;
  rowId?: number;
  portalId?: string;
  groupId?: string;
  creating?: boolean;
  canEditData?: boolean;
  deletionOnly?: boolean;
  duplicationId?: string;
  tableRowId?: number;
  forceRefresh?: () => void;
}

const removeUniqueValues = (
  rowEntry: Record<string, unknown>,
  uniqueFieldNames: string[],
) => {
  return Object.entries(rowEntry).reduce((acc, [key, value]) => {
    if (uniqueFieldNames.includes(key)) {
      return {
        ...acc,
        [key]: undefined,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

const formatNumbers = (
  rowEntry: Record<string, unknown>,
  schema: Interfaces.FieldOutput[],
) => {
  return Object.entries(rowEntry).reduce((acc, [key, value]) => {
    const field = schema.find((f) => f.name === key);

    if (!field) {
      return {
        ...acc,
        [key]: value,
      };
    }

    if (
      field.dataValidation?.dataValidationType === Enums.ValueDataType.NUMBER
    ) {
      let formattedVal = typeof value !== null ? value : '';

      if (formattedVal) {
        formattedVal = parseFloat(
          (value as string).toString().replace(/[^\d.-]/g, ''),
        );
      }

      return {
        ...acc,
        [key]: formattedVal,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {} as Record<string, unknown>);
};

const formatLinkedQueryVal = (
  field: Interfaces.FieldOutput,
  linkedQueryVal:
    | Interfaces.DynamicConditionalValue
    | Interfaces.DynamicConditionalValue[],
) => {
  const val =
    field.dataValidation?.constraints?.listValues &&
    linkedQueryVal &&
    linkedQueryVal[0]
      ? linkedQueryVal[0]
      : linkedQueryVal;

  return field?.dataValidation?.dataValidationType ===
    Enums.ValueDataType.NUMBER
    ? Number(val)
    : val;
};

const CMSAddRowModal: FC<CMSAddRowModalProps> = ({
  setShowModal,
  rowId,
  cmsId,
  portalId,
  groupId,
  creating,
  canEditData,
  deletionOnly,
  duplicationId,
  tableRowId,
  forceRefresh,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { datasetMeta, datasetMetaAccessLevel, activeDataCollectionItem } =
    useDatasetMeta();
  const { project } = useProject();
  const { addRow, editRow, removeRow } = useDataset();
  const { portalAccessLevel, portal } = usePortal();
  const { cms, listPreFilters, getDynamicPreFilterValue } = useCMS();
  const { buildInput } = useInput();

  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [rowEntry, setRowEntry] = useState<Record<string, string | number>>({});
  const [errorFields, setErrorFields] = useState<Record<string, boolean>>({});
  const [requiredComplete, setRequiredComplete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(deletionOnly || false);
  const [preFilters, setPreFilters] = useState<Interfaces.DynamicConditional[]>(
    [],
  );
  const [linkQueries, setLinkQueries] = useState<LinkedDatasetQuery[]>([]);
  const [addingLinkedRowDatasetId, setAddingLinkedRowDatasetId] =
    useState<string>();
  const [addingLinkedRowDatasetMeta, setAddingLinkedRowDatasetMeta] =
    useState<Interfaces.DatasetMetaOutput>();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const datasets = useSelector((state: RootState) => state.dataset)?.data;
  const [hasLinkedDatasets, setHasLinkedDatasets] = useState<boolean>();

  const isViewingDataset = location.pathname.includes('view');

  const activeDataCollection = useMemo(() => {
    if (addingLinkedRowDatasetId) {
      const dsm: Interfaces.DatasetMetaOutput | undefined = datasetMetas.find(
        (d) => d.entity._id === addingLinkedRowDatasetId,
      )?.entity;
      setAddingLinkedRowDatasetMeta(dsm);

      const activeDataCollection = dsm?.dataCollections.find(
        (c) => c._id === dsm.activeDataCollection,
      );

      return activeDataCollection;
    }

    return activeDataCollectionItem;
  }, [activeDataCollectionItem, addingLinkedRowDatasetId, datasetMetas]);

  const creatingEntityId = useMemo(() => {
    return addingLinkedRowDatasetId || datasetMeta?._id;
  }, [addingLinkedRowDatasetId, datasetMeta?._id]);

  const handleChange = useCallback(
    (field: string, value: unknown) => {
      const schemaField = activeDataCollection?.schemaData?.find(
        (f) => f.name === field,
      );

      if (schemaField) {
        const dataType = schemaField.dataValidation?.dataValidationType;

        let isValid = true;
        if (dataType) {
          isValid = validateValue(
            dataType,
            value,
            schemaField.dataValidation?.constraints,
          );
        }
        const cloned = cloneDeep(rowEntry);
        const clonedErrors = cloneDeep(errorFields);

        cloned[field] = value;

        if (isValid) {
          if (clonedErrors[field]) {
            delete clonedErrors[field];
            setErrorFields(clonedErrors);
          }
        } else {
          clonedErrors[field] = true;
          setErrorFields(clonedErrors);
        }

        setRowEntry(cloned);
        setDataUpdated(true);
      }
    },
    [activeDataCollection?.schemaData, errorFields, rowEntry],
  );

  const processRowEntry = () =>
    Object.entries(rowEntry).reduce((acc, [k, v]) => {
      const field = activeDataCollection?.schemaData?.find((f) => f.name === k);
      const fieldType = field?.dataValidation?.dataValidationType;

      const isFormula =
        !fieldType || (fieldType && FORMULA_TYPES.includes(fieldType));

      const isPercentage =
        (field?.dataValidation?.formatting as Interfaces.NumericalFormatting)
          ?.format === Enums.DataDisplayFormat.PERCENTAGE;

      if (isPercentage) {
        return {
          ...acc,
          [k]: +v / 100,
        };
      }

      if (!isFormula) {
        return {
          ...acc,
          [k]: v,
        };
      }

      return acc;
    }, {});

  const handleUpdate = async () => {
    if (rowId && rowEntry && datasetMeta?._id) {
      dispatch(showLoading({ text: 'Updating Row...' }));

      await editRow(
        datasetMeta._id,
        processRowEntry(),
        rowId,
        cmsId,
        portalId,
        isViewingDataset || !!cmsId,
      );

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  const handleCreate = async () => {
    if (
      creatingEntityId &&
      ((portalId && portalAccessLevel) || datasetMetaAccessLevel)
    ) {
      const entityIdAccessLevel = addingLinkedRowDatasetId
        ? datasetMetas.find((dsm) => dsm.entity._id === creatingEntityId)
            ?.accessLevel || (portalAccessLevel as Enums.AccessLevel)
        : datasetMetaAccessLevel || (portalAccessLevel as Enums.AccessLevel);

      dispatch(showLoading({ text: 'Creating Row...' }));

      await addRow(
        creatingEntityId,
        entityIdAccessLevel,
        [processRowEntry()],
        cmsId,
        portalId,
        isViewingDataset || !!cmsId,
        true,
      );

      dispatch(hideLoading());

      if (!addingLinkedRowDatasetId) {
        dispatch(hideModal());
      } else {
        setAddingLinkedRowDatasetId(undefined);
        setAddingLinkedRowDatasetMeta(undefined);
      }
    }
  };

  const recoverDatasetRow = async (
    deletedEntries: Record<string, unknown>[],
  ) => {
    if (
      creatingEntityId &&
      ((portalId && portalAccessLevel) || datasetMetaAccessLevel) &&
      activeDataCollection?.schemaData
    ) {
      const entityIdAccessLevel = addingLinkedRowDatasetId
        ? datasetMetas.find((dsm) => dsm.entity._id === creatingEntityId)
            ?.accessLevel || (portalAccessLevel as Enums.AccessLevel)
        : datasetMetaAccessLevel || (portalAccessLevel as Enums.AccessLevel);

      await addRow(
        creatingEntityId,
        entityIdAccessLevel,
        clearSuperColumnValues(deletedEntries, activeDataCollection.schemaData),
        cmsId,
        portalId,
        isViewingDataset || !!cmsId,
        true,
        true,
      );

      // refresh entries
      if (forceRefresh) {
        forceRefresh();
      }
    }
  };

  const handleDelete = async () => {
    if (
      rowId &&
      datasetMeta?._id &&
      ((portalId && portalAccessLevel) || datasetMetaAccessLevel)
    ) {
      dispatch(showLoading({ text: 'Deleting Row...' }));

      const deletedRowData = (await removeRow(
        datasetMeta._id,
        datasetMetaAccessLevel || (portalAccessLevel as Enums.AccessLevel),
        rowId,
        cmsId,
        portalId,
      )) as unknown as DatasetDeleteRowOutput;

      toast.success(
        <>
          <span>Successfully removed row.</span>
          <UndoLink
            onClick={() => recoverDatasetRow(deletedRowData.deletedEntries)}
          >
            Undo
          </UndoLink>
        </>,
      );

      dispatch(hideLoading());
      dispatch(hideModal());
    }
  };

  // If no CMS, get it
  useEffect(() => {
    if (project?._id && (!cms?._id || cms._id !== cmsId)) {
      (async () => {
        const existingCMS = project?.connections[Enums.ConnectionType.CMS].find(
          (c) => c._id === cmsId,
        );

        if (existingCMS) {
          dispatch(fetchCMSSuccess(existingCMS));
        }
      })();
    }
  }, [cms?._id, cmsId, dispatch, project?._id, project?.connections]);

  // Get row data if editing
  useEffect(() => {
    if (datasetMeta?._id && datasets && activeDataCollection?.schemaData) {
      let entry;
      const entityId = !addingLinkedRowDatasetId && (rowId || duplicationId);
      // Editing or Duplicating - get row from dataset
      if (entityId) {
        entry = datasets?.[datasetMeta?._id]?.entries?.find(
          (e) => (e.row_id as string).toString() === entityId.toString(),
        );
        if (entry !== undefined) {
          entry = formatNumbers(entry, activeDataCollection.schemaData);

          if (duplicationId) {
            const uniqueFields = activeDataCollection.schemaData
              .filter(
                (field) =>
                  field.dataValidation?.constraints?.isUnique ||
                  field.dataValidation?.constraints?.isAutoInc,
              )
              .map((field) => field.name);

            if (uniqueFields) {
              entry = removeUniqueValues(entry, uniqueFields);
            }
          }
        }
      }

      // Creating - build empty row
      if (!entityId) {
        const tempFilter = history.location.state?.filters;
        let equalFilters: Interfaces.DynamicConditionalField[];

        if (tempFilter) {
          const filterValues = (
            (JSON.parse(tempFilter) as Interfaces.DynamicConditionalField[])[0]
              .value as Interfaces.DynamicConditionalField[]
          )[0].value;

          equalFilters = (
            filterValues as Interfaces.DynamicConditionalField[]
          )?.filter(
            (v) =>
              v.operator === Enums.AggregationFilterOperator.EQUAL ||
              v.operator === Enums.AggregationFilterOperator.IN,
          );
        }

        entry = activeDataCollection?.schemaData.reduce((acc, field) => {
          const tempFilterValue = (
            equalFilters?.find(
              (filter) =>
                (filter as Interfaces.DynamicConditional).field ===
                field.fieldId,
            )?.value as Interfaces.ConstantConditionalValue
          )?.value;

          const tempFilterValueListItem =
            field.dataValidation?.constraints?.listValues &&
            tempFilterValue &&
            (tempFilterValue as string[])[0];

          let val = tempFilter
            ? tempFilterValueListItem || tempFilterValue || ''
            : '';

          // Populate any values used in the linked dataset query
          if (addingLinkedRowDatasetId) {
            const linkedDatasetQuery: DynamicConditionalField[] | undefined =
              linkQueries?.find(
                (q) => q.dataset === addingLinkedRowDatasetId,
              )?.query;

            let linkedQueryField: DynamicConditionalField | undefined;

            if (
              linkedDatasetQuery &&
              linkedDatasetQuery[0].operator ===
                Enums.AggregationFilterOperator.OR
            ) {
              linkedQueryField = (
                linkedDatasetQuery[0]?.value as DynamicConditionalField[]
              )?.find((f) => f.field === field.fieldId);
            } else {
              linkedQueryField = linkedDatasetQuery?.find(
                (f) => f.field === field.fieldId,
              );
            }

            val = formatLinkedQueryVal(
              field,
              (linkedQueryField?.value as Interfaces.DynamicConditional)?.value,
            );
          }

          return {
            ...acc,
            [field.name]: val
              ? val
              : cmsId
              ? getDynamicPreFilterValue(preFilters, field.fieldId, true)
              : '',
          };
        }, {});
      }

      if (entry) {
        setRowEntry(entry);
      }
    }
  }, [
    activeDataCollection?.schemaData,
    addingLinkedRowDatasetId,
    cmsId,
    datasetMeta?._id,
    datasets,
    duplicationId,
    getDynamicPreFilterValue,
    history.location.state?.filters,
    linkQueries,
    preFilters,
    rowId,
  ]);

  // Get Prefilters
  useEffect(() => {
    if (creatingEntityId) {
      setPreFilters(listPreFilters(creatingEntityId));
    }
  }, [creatingEntityId, datasetMeta?._id, listPreFilters]);

  // Check if all required fields are complete
  useEffect(() => {
    if (activeDataCollection?.schemaData) {
      const notComplete = Object.entries(rowEntry).reduce(
        (acc: string[], [k, v]) => {
          const required = activeDataCollection?.schemaData.find(
            (f) => f.name === k,
          )?.dataValidation?.constraints?.isRequired;

          if (
            required &&
            (typeof v === 'undefined' || (typeof v === 'string' && !v.length))
          ) {
            return acc.concat(k);
          }

          return acc;
        },
        [],
      );

      setRequiredComplete(!notComplete.length);
    }
  }, [activeDataCollection?.schemaData, rowEntry]);

  // Show modal
  useEffect(() => {
    if (
      datasetMeta?._id &&
      activeDataCollectionItem?.schemaData?.length &&
      rowEntry &&
      Object.keys(rowEntry).length
    ) {
      setShowModal(true);

      return () => setShowModal(false);
    }
  }, [
    activeDataCollectionItem?.schemaData?.length,
    datasetMeta?._id,
    rowEntry,
    setShowModal,
  ]);

  // Check if all linked datasets are included in workspace
  useEffect(() => {
    if (project?.dataModel?.length) {
      const dataModelDatasetMetaIds = project.dataModel
        .reduce((acc: string[], model) => {
          if (
            model.primary.datasetMetaId === datasetMeta?._id ||
            model.secondary.datasetMetaId === datasetMeta?._id
          ) {
            return [
              ...acc,
              model.primary.datasetMetaId,
              model.secondary.datasetMetaId,
            ];
          }
          return acc;
        }, [])
        .filter((id) => id !== datasetMeta?._id);

      const workspaceDatasetMetaIds =
        project?.connections?.cms?.flatMap((cms) =>
          (
            cms?.configuration as Interfaces.CMSConnectionConfiguration
          )?.groups?.flatMap((group) =>
            group?.items
              ?.filter((item) => item.datasetMetaId)
              .map((item) => item.datasetMetaId),
          ),
        ) || [];

      setHasLinkedDatasets(
        dataModelDatasetMetaIds.some((id) =>
          workspaceDatasetMetaIds.includes(id),
        ),
      );
    }
  }, [datasetMeta?._id, project?.connections?.cms, project?.dataModel]);

  // Check if all linked datasets are included in portal
  useEffect(() => {
    if (portal?.modellingItems?.length && datasetMeta?._id) {
      const dataModelDatasetMetaIds = portal.modellingItems.reduce(
        (acc: string[], model) => [
          ...acc,
          model.primary.datasetMetaId,
          model.secondary.datasetMetaId,
        ],
        [],
      );

      const portalDatasetMetaIds =
        portal?.connectionItems?.cms?.flatMap((cms) =>
          (
            cms?.configuration as Interfaces.CMSConnectionConfiguration
          )?.groups?.flatMap((group) =>
            group?.items
              ?.filter((item) => item.datasetMetaId)
              .map((item) => item.datasetMetaId),
          ),
        ) || [];

      setHasLinkedDatasets(
        dataModelDatasetMetaIds.some((id) =>
          portalDatasetMetaIds.includes(id),
        ) && dataModelDatasetMetaIds.includes(datasetMeta._id),
      );
    }
  }, [datasetMeta?._id, portal?.connectionItems, portal?.modellingItems]);

  return (
    <SC.Wrapper>
      {isDeleting && (
        <SC.FormWrapper>
          <SC.HeaderWrapper>
            <SC.Header>Deleting Row {tableRowId}</SC.Header>
          </SC.HeaderWrapper>

          <StyledText>Are you sure you wish to delete this row?</StyledText>

          <SC.ActionButtonWrapper buttonMargin={true}>
            <FeatureButton
              action={() =>
                deletionOnly ? dispatch(hideModal()) : setIsDeleting(false)
              }
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              action={handleDelete}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.red}
              text={`Delete Row`}
            />
          </SC.ActionButtonWrapper>
        </SC.FormWrapper>
      )}

      {!isDeleting && (
        <>
          <SC.FormWrapper>
            <SC.HeaderWrapper>
              <SC.Header>
                {rowId && !addingLinkedRowDatasetId
                  ? `${canEditData ? 'Updating' : 'Viewing'} Row`
                  : `${duplicationId ? 'Duplicating' : 'Create'} Row${
                      addingLinkedRowDatasetId
                        ? ` on ${addingLinkedRowDatasetMeta?.name}`
                        : ''
                    }`}
              </SC.Header>
              {rowId && canEditData && !addingLinkedRowDatasetId && (
                <FeatureButton
                  action={() => setIsDeleting(true)}
                  size={FeatureButtonSize.EXTRA_SMALL}
                  color={themeContext.colors.general.red}
                  icon={
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color={defaultTheme.colors.system.white}
                    />
                  }
                />
              )}
            </SC.HeaderWrapper>

            {(rowId || duplicationId) && canEditData && (
              <StyledText>
                Simply change any fields you want to update and then hit the
                button below. Easy.
              </StyledText>
            )}

            {!rowId && !duplicationId && (
              <StyledText>
                Simply add your data to the fields below and then hit the button
                below. Easy.
              </StyledText>
            )}

            <SC.InputsWrapper>
              {activeDataCollection?.schemaData.reduce(
                (acc: React.ReactElement[], field) => {
                  if (field.name !== ROW_ID_FIELD) {
                    const type = field?.dataValidation?.dataValidationType;
                    const val = rowEntry[field.name];

                    if (type) {
                      if (FORMULA_TYPES.includes(type)) {
                        return acc;
                      }

                      const preFilterValue = getDynamicPreFilterValue(
                        preFilters,
                        field.fieldId,
                        true,
                      );

                      let linkedQueryField: DynamicConditionalField | undefined;

                      const linkedDatasetQuery:
                        | DynamicConditionalField[]
                        | undefined = linkQueries?.find(
                        (q) => q.dataset === addingLinkedRowDatasetId,
                      )?.query;

                      // Handle multi level filter linked query
                      if (addingLinkedRowDatasetId) {
                        if (
                          linkedDatasetQuery &&
                          linkedDatasetQuery[0].operator ===
                            Enums.AggregationFilterOperator.OR
                        ) {
                          linkedQueryField = (
                            linkedDatasetQuery[0]
                              ?.value as DynamicConditionalField[]
                          )?.find((f) => f.field === field.fieldId);
                        } else {
                          linkedQueryField = linkedDatasetQuery?.find(
                            (f) => f.field === field.fieldId,
                          );
                        }
                      }

                      acc.push(
                        <SC.InputItem key={`input-field-${field.name}`}>
                          <SC.InputTitleWrapper>
                            <SC.InputHeader>{field.name}</SC.InputHeader>
                            {field.dataValidation?.constraints?.isRequired && (
                              <SC.InputHeaderRequired>
                                Required
                              </SC.InputHeaderRequired>
                            )}
                          </SC.InputTitleWrapper>

                          {buildInput(
                            field,
                            type,
                            linkedQueryField
                              ? formatLinkedQueryVal(
                                  field,
                                  (
                                    linkedQueryField?.value as Interfaces.DynamicConditional
                                  )?.value,
                                )
                              : val,
                            handleChange,
                            errorFields[field.name],
                            !!linkedQueryField ||
                              !canEditData ||
                              FORMULA_TYPES.includes(type) ||
                              field.dataValidation?.permissions
                                ?.allowManualEdits === false,
                            cmsId ? preFilterValue : undefined,
                          )}
                        </SC.InputItem>,
                      );
                    }
                  }

                  return acc;
                },
                [],
              )}
            </SC.InputsWrapper>

            <SC.ActionButtonWrapper buttonMargin={canEditData}>
              <FeatureButton
                action={() =>
                  !addingLinkedRowDatasetId
                    ? dispatch(hideModal())
                    : setAddingLinkedRowDatasetId(undefined)
                }
                size={FeatureButtonSize.WIDE}
                color={themeContext.colors.general.sea}
                text={'Cancel'}
              />
              {canEditData && (
                <FeatureButton
                  isDisabled={
                    (!dataUpdated &&
                      !addingLinkedRowDatasetId?.length &&
                      !duplicationId) ||
                    Object.keys(errorFields).length > 0 ||
                    !requiredComplete
                  }
                  action={
                    rowId && !addingLinkedRowDatasetId
                      ? handleUpdate
                      : handleCreate
                  }
                  size={FeatureButtonSize.WIDE}
                  color={themeContext.colors.general.green}
                  text={`${
                    rowId && !addingLinkedRowDatasetId ? 'Update' : 'Create'
                  }`}
                />
              )}
            </SC.ActionButtonWrapper>
          </SC.FormWrapper>

          {!creating &&
            rowEntry &&
            Object.keys(rowEntry).length &&
            cmsId &&
            hasLinkedDatasets &&
            !isViewingDataset &&
            !duplicationId && (
              <CMSLinkedDatasets
                cmsId={cmsId}
                rowEntry={rowEntry}
                portalId={portalId}
                groupId={groupId}
                addingLinkedDatasetRow={addingLinkedRowDatasetId}
                setAddingLinkedDatasetRow={setAddingLinkedRowDatasetId}
                setLinkedDatasetFilters={setLinkQueries}
              />
            )}
        </>
      )}
    </SC.Wrapper>
  );
};

export default CMSAddRowModal;
