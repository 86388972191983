import styled from 'styled-components';

export const ActionButtonWrapper = styled.div<{ fullScreenView?: boolean }>`
  display: flex;
  margin-top: ${({ theme }) => theme.margin.xxxlarge};
  background: ${({ fullScreenView, theme }) =>
    fullScreenView && theme.colors.system.offWhite};
  padding: ${({ fullScreenView, theme }) =>
    fullScreenView && theme.margin.xlarge};
  border: ${({ fullScreenView, theme }) =>
    fullScreenView && `1px solid ${theme.colors.system.grey}`};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }

  ${({ fullScreenView }) =>
    fullScreenView &&
    ` display: flex;
    width: 100%;
    justify-content: space-between;
  `};
`;

export const Container = styled.div<{ fullScreenView?: boolean }>`
  ${({ fullScreenView }) =>
    fullScreenView &&
    ` width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  `};
`;

export const Content = styled.div<{ fullScreenView?: boolean; theme }>`
  padding: ${({ fullScreenView, theme }) =>
    fullScreenView ? theme.margin.xlarge : 0};

  ${({ fullScreenView }) =>
    fullScreenView &&
    ` width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;
