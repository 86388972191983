import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Item from './Item';

const FlowchartWrapper = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
`;

const Flowchart: FC<{
  items: {
    content: string;
    isChecked: boolean;
    link?: string;
  }[];
}> = ({ items }) => {
  const checkIfAllPredecessorsAreComplete = useCallback(
    (index) => {
      const allPredecessors = items.slice(0, index);

      const areAnyIncomplete = allPredecessors.some(
        (predecessor) => !predecessor.isChecked,
      );

      return !areAnyIncomplete;
    },
    [items],
  );

  const [extendedItems, setExtendedItems] = useState(
    items.map((item, index) => {
      return {
        ...item,
        areAllPredecessorsComplete: checkIfAllPredecessorsAreComplete(index),
      };
    }),
  );

  useEffect(() => {
    setExtendedItems(
      items.map((item, index) => {
        return {
          ...item,
          areAllPredecessorsComplete: checkIfAllPredecessorsAreComplete(index),
        };
      }),
    );
  }, [items, checkIfAllPredecessorsAreComplete]);

  return (
    <FlowchartWrapper>
      {extendedItems.map((item, index) => (
        <Item
          key={index}
          isStart={index === 0}
          isEnd={index === items.length - 1}
          isComplete={item.isChecked}
          areAllPredecessorsComplete={item.areAllPredecessorsComplete}
          isNextItemComplete={
            index === items.length - 1 ? false : items[index + 1].isChecked
          }
          message={item.content}
        />
      ))}
    </FlowchartWrapper>
  );
};

export default Flowchart;
