import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { Field } from '@configur-tech/upit-core-types/lib/interfaces';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../../../enums';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import { StyledAccordion } from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { RootState } from '../../../../store/rootReducer';
import { getMissingLookupFieldDatasetIds } from '../../../../util/lookup/GetMissingLookupFieldDatasetIds';
import FormattingOptions from '../../validation/OptionPanels/FormattingOptions';
import { SuperColumnType } from '../EnhancementAddColumnModal';
import ValidationOptions from '../ValidationOptions';
import DateDiff from './DateDiff';
import DateExtract from './DateExtract';
import DateReformat from './DateReformat';

interface AddDateSuperColProps {
  dateColumnType: Enums.DateConversionOperation;
  schemaField: Interfaces.Field;
  schema: Interfaces.Field[];
  setSchemaField: (value: ((prevState: Field) => Field) | Field) => void;
  setDataValidation: React.Dispatch<
    React.SetStateAction<Interfaces.DataValidation | undefined>
  >;
  setSuperColumnValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddDateSuperCol: FC<AddDateSuperColProps> = ({
  dateColumnType,
  schemaField,
  schema,
  setSchemaField,
  setDataValidation,
  setSuperColumnValid,
}) => {
  const themeContext = useContext(ThemeContext);

  const { getDatasetMetas } = useDatasetMeta();
  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState<number[]>(
    [],
  );

  const QUERY_ROUTE = 'query';
  const isQuery =
    location.pathname.includes(RouteName.PROJECT_ITEM) ||
    location.pathname.includes(QUERY_ROUTE);

  // Fetch any missing lookup datasetMetas
  useEffect(() => {
    (async () => {
      if (!loading && !loaded) {
        setLoading(true);

        // Fetch any missing lookup datasets
        const lookupIds = getMissingLookupFieldDatasetIds(schema, datasetMetas);
        if (lookupIds.length) {
          await getDatasetMetas({ _id: { $in: lookupIds } });
        }

        setLoading(false);
        setLoaded(true);
      }
    })();
  });

  const toggleAccordionIndex = (index: number) => {
    if (accordionOpenIndexes.includes(index)) {
      setAccordionOpenIndexes(
        accordionOpenIndexes.filter((aoi) => aoi !== index),
      );
    } else {
      setAccordionOpenIndexes([...accordionOpenIndexes, index]);
    }
  };

  return (
    <>
      {dateColumnType === Enums.DateConversionOperation.EXTRACT_DATE && (
        <DateExtract
          field={schemaField}
          schema={schema || []}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
        />
      )}

      {dateColumnType === Enums.DateConversionOperation.REFORMAT && (
        <DateReformat
          field={schemaField}
          schema={schema || []}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
        />
      )}

      {dateColumnType === Enums.DateConversionOperation.DATE_DIFF && (
        <DateDiff
          field={schemaField}
          schema={schema || []}
          setDataValidation={setDataValidation}
          setSuperColumnValid={setSuperColumnValid}
          loaded={loaded}
        />
      )}
      {!isQuery && (
        <StyledAccordion
          fluid
          style={{ marginTop: themeContext.margin.standard }}
        >
          {dateColumnType === Enums.DateConversionOperation.DATE_DIFF && (
            <FormattingOptions
              active={accordionOpenIndexes.includes(0)}
              activeToggle={() => toggleAccordionIndex(0)}
              schemaField={schemaField}
              onChange={(updatedSchemaField) => {
                setSchemaField(updatedSchemaField);
              }}
              hideDisplayAsFormats={true}
              superColumnType={SuperColumnType.NUMERIC}
            />
          )}

          <ValidationOptions
            active={accordionOpenIndexes.includes(1)}
            activeToggle={() => toggleAccordionIndex(1)}
            schemaField={schemaField}
            superColumnValueType={
              dateColumnType === Enums.DateConversionOperation.DATE_DIFF
                ? Enums.ValueDataType.NUMBER
                : Enums.ValueDataType.DATE
            }
            onChange={(updatedSchemaField) => {
              setSchemaField(updatedSchemaField);
            }}
          />
        </StyledAccordion>
      )}
      {isQuery && dateColumnType === Enums.DateConversionOperation.DATE_DIFF && (
        <StyledAccordion
          fluid
          style={{ marginTop: themeContext.margin.standard }}
        >
          <FormattingOptions
            active={accordionOpenIndexes.includes(0)}
            activeToggle={() => toggleAccordionIndex(0)}
            schemaField={schemaField}
            onChange={(updatedSchemaField) => {
              setSchemaField(updatedSchemaField);
            }}
            hideDisplayAsFormats={true}
          />

          <ValidationOptions
            active={accordionOpenIndexes.includes(1)}
            activeToggle={() => toggleAccordionIndex(1)}
            schemaField={schemaField}
            superColumnValueType={
              dateColumnType === Enums.DateConversionOperation.DATE_DIFF
                ? Enums.ValueDataType.NUMBER
                : Enums.ValueDataType.DATE
            }
            onChange={(updatedSchemaField) => {
              setSchemaField(updatedSchemaField);
            }}
          />
        </StyledAccordion>
      )}
    </>
  );
};

export default AddDateSuperCol;
