import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { CMSConfigurationDataGroup } from '@configur-tech/upit-core-types/lib/interfaces/models/input/project/connection/CMSConfigurationDataGroup';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useCMSResult {
  cms: Interfaces.ConnectionOutput | undefined;
  cmsConfig: Interfaces.CMSConnectionConfiguration | undefined;

  // Utils
  listCmsEntityIds: () => {
    datasetMetaIds: string[];
    queryIds: string[];
    chartIds: string[];
  };
  listPreFilters: (
    entityId: string,
    groupId?: string,
  ) => Interfaces.DynamicConditional[];
  getDynamicPreFilterValue: (
    preFilters: Interfaces.DynamicConditional[],
    fieldId: string,
    equalsFiltersOnly?: boolean,
  ) => string | undefined;
  checkCommentsEnabledForItem: (entityId: string, groupId?: string) => boolean;
}

const useCMS = (): useCMSResult => {
  const { loggedInUser } = useLoggedInUser();

  const cmsState = useSelector((state: RootState) => state.cms);
  const cmsObj = cmsState.data as Interfaces.ConnectionOutput;

  const [cms, setCms] = useState<Interfaces.ConnectionOutput>();
  const [cmsConfig, setCmsConfig] =
    useState<Interfaces.CMSConnectionConfiguration>();

  /**
   * Outputs the _ids of datasetMetas, queries & charts contained within the CMS
   */
  const listCmsEntityIds = useCallback((): {
    datasetMetaIds: string[];
    queryIds: string[];
    chartIds: string[];
  } => {
    return (cmsConfig?.groups || []).reduce(
      (
        acc: {
          datasetMetaIds: string[];
          queryIds: string[];
          chartIds: string[];
        },
        group,
      ) => {
        const groupD: string[] = [];
        const groupQ: string[] = [];
        const groupC: string[] = [];

        group.items.map((gi) => {
          if (gi.datasetMetaId && !groupD.includes(gi.datasetMetaId)) {
            groupD.push(gi.datasetMetaId);
          } else if (gi.aggregationId && !groupQ.includes(gi.aggregationId)) {
            groupQ.push(gi.aggregationId);
          } else if (gi.chartId && !groupC.includes(gi.chartId)) {
            groupC.push(gi.chartId);
          }
        });

        return {
          datasetMetaIds: acc.datasetMetaIds.concat(groupD),
          queryIds: acc.queryIds.concat(groupQ),
          chartIds: acc.chartIds.concat(groupC),
        };
      },
      { datasetMetaIds: [], queryIds: [], chartIds: [] },
    );
  }, [cmsConfig?.groups]);

  /**
   * List all existing preFilters in CMS
   */
  const listPreFilters = useCallback(
    (entityId: string, groupId?: string) => {
      let groups = cmsConfig?.groups || [];

      if (groupId) {
        groups = groups.filter(
          (group) =>
            (group as CMSConfigurationDataGroup & { _id: string })._id ===
            groupId,
        );
      }

      return groups.reduce((acc: Interfaces.DynamicConditional[], group) => {
        const preFilters = group.items.reduce(
          (acc: Interfaces.DynamicConditional[], gi) => {
            if (
              entityId !== (gi.datasetMetaId || gi.aggregationId || gi.chartId)
            ) {
              return acc;
            }

            if (gi.options?.preFilters?.value) {
              return acc.concat([
                gi.options.preFilters
                  .value as unknown as Interfaces.DynamicConditional,
              ]);
            }
            return acc;
          },
          [],
        );

        if (preFilters) {
          return [...acc, ...preFilters.flat()];
        }

        return acc;
      }, []);
    },
    [cmsConfig?.groups],
  );

  const getDynamicPreFilterValue = useCallback(
    (
      preFilters: Interfaces.DynamicConditional[],
      fieldId: string,
      equalsFiltersOnly?: boolean,
    ): string | undefined => {
      const preFilter = preFilters.reduce(
        (acc: Interfaces.DynamicConditional, filter) => {
          if (acc) {
            return acc;
          }

          if (
            (filter.operator as Enums.AggregationFilterOperator) ===
            Enums.AggregationFilterOperator.AND
          ) {
            const childPreFilter = (
              filter.value as unknown as Interfaces.DynamicConditional[]
            ).find((childFilter) => childFilter.field === fieldId);

            return childPreFilter as Interfaces.DynamicConditional;
          }

          return filter.field === fieldId
            ? filter
            : (acc as Interfaces.DynamicConditional);
        },
        undefined as unknown as Interfaces.DynamicConditional,
      );

      // If prefilters, use fixed value
      let value;
      if (
        equalsFiltersOnly &&
        preFilter?.operator !== Enums.AggregationFilterOperator.EQUAL
      ) {
        return value;
      }
      switch ((preFilter?.value as Interfaces.DynamicConditionalValue)?.type) {
        case Enums.DynamicConditionalType.USER:
          value =
            (preFilter.value as Interfaces.UserConditionalValue).field === '_id'
              ? loggedInUser?._id
              : loggedInUser?.email;
          break;
        case Enums.DynamicConditionalType.CONSTANT:
          value = (preFilter.value as Interfaces.ConstantConditionalValue)
            .value;
          break;
      }

      return value;
    },
    [loggedInUser?._id, loggedInUser?.email],
  );

  /**
   * Check if comments are enabled for cms item
   */
  const checkCommentsEnabledForItem = useCallback(
    (entityId: string, groupId?: string) => {
      const configGroup = groupId
        ? (
            cmsConfig as Interfaces.CMSConnectionConfigurationOutput
          )?.groups.filter((g) => g._id === groupId)
        : cmsConfig?.groups;

      return (
        configGroup
          ?.flatMap((conf) => conf.items)
          .find(
            (item) =>
              item.aggregationId === entityId ||
              item.chartId === entityId ||
              item.datasetMetaId === entityId,
          )?.options?.commentsEnabled || false
      );
    },
    [cmsConfig],
  );

  // Complete model & config
  useEffect(() => {
    if (cmsObj) {
      setCms(cmsObj);
      setCmsConfig(
        cmsObj.configuration as Interfaces.CMSConnectionConfiguration,
      );
    }
  }, [cms, cmsObj]);

  return {
    cms,
    cmsConfig,

    // Utils
    listCmsEntityIds,
    listPreFilters,
    getDynamicPreFilterValue,
    checkCommentsEnabledForItem,
  };
};

export default useCMS;
