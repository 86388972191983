import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import InitialGraph from './initial-state';

type GraphType = GraphItem | GraphItemOutput;

export type FormattedData = {
  unformatted: number | string;
  formatted: number | string;
};

export interface GraphItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Graph;
}

export interface GraphItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: GraphOutputWithDetails;
}

export interface GraphOutputWithDetails extends Interfaces.GraphOutput {
  additionalDetails?: {
    name: string;
    tableName: string;
    datasetMetaId: string;
    schemaData: Interfaces.FieldOutput[];
  }[];
}

export interface GraphState {
  data: GraphType;
  loading: boolean;
  error: string | null;
}

const initialState: GraphState = {
  data: InitialGraph,
  loading: false,
  error: null,
};

const GraphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    // Reset
    resetGraph(state) {
      state.data = InitialGraph;
      state.loading = false;
      state.error = null;
    },

    // Fetch
    fetchGraphStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchGraphSuccess(state, action: PayloadAction<GraphType>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchGraphFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetGraph,
  fetchGraphStart,
  fetchGraphSuccess,
  fetchGraphFailure,
} = GraphSlice.actions;

export default GraphSlice.reducer;
