import { Enums } from '@configur-tech/upit-core-types';
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import { EntityType } from '../../enums';
import useFileUpload from '../../hooks/file-upload/UseFileUpload';
import useFile from '../../hooks/file/UseFile';
import useOrganisation from '../../hooks/organisation/UseOrganisation';
import {
  defaultTheme,
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../main/theme';
import { CreateFileDocumentResponse } from '../../services/file/FileService';
import { hideLoading } from '../../store/loading';
import EntityColorMap from '../../util/color-helpers/EntityColorMap';
import AvatarIconMap from '../../util/icon-helpers/AvatarMap';
import UserIconMap from '../../util/icon-helpers/UserIconMap';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import * as SC from './styled';

interface IconSelectorProps {
  type: EntityType;
  onChange: (icon) => void;
  selectedIcon?: string;
  disabled?: boolean;
  maxHeight?: number;
}

interface HiddenFileInputProps extends HTMLInputElement {
  click(): void;
  value: string;
}

const UPLOAD_IMAGE_NAME = 'upload';
const UPLOAD_FILE_SIZE_LIMIT = 2097152; // 2MB
const ORG_LOGO_KEY = 'organisation-logo';

const IconSelector: FC<IconSelectorProps> = ({
  type,
  onChange,
  selectedIcon,
  disabled,
  maxHeight,
}) => {
  const themeContext = useContext(ThemeContext);
  const { organisation } = useOrganisation();
  const dispatch = useDispatch();
  const { getFiles } = useFile();
  const { uploadFile } = useFileUpload();
  const hiddenFileInput = useRef<HiddenFileInputProps>(null);

  const [customIconMap, setCustomIconMap] = useState<Record<string, string>>();
  const [orgIconMap, setOrgIconMap] = useState<Record<string, string>>();
  const [filteredItems, setFilteredItems] = useState<[string, string][]>([]);

  const [selected, setSelected] = useState<string | undefined>();
  const [searchVal, setSearchVal] = useState<string>();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);

  const [uploadedFileResult, setUploadedFileResult] =
    useState<CreateFileDocumentResponse>();

  const color = EntityColorMap[type];

  // Fetch previously uploaded custom avatars
  useEffect(() => {
    (async () => {
      try {
        const uploadedAvatars = await getFiles(Enums.FilePurpose.AVATAR);

        if (uploadedAvatars?.data) {
          setCustomIconMap(
            uploadedAvatars.data.reduce((acc, f) => {
              return {
                ...acc,
                [f.entity
                  .name]: `${organisation?.mediaDomain}/${f.entity.s3.key}`,
              };
            }, {}),
          );
          setLoaded(true);
        }
      } catch (err) {
        setCustomIconMap({});
        setLoaded(true);
      }
    })();
  }, [getFiles, organisation?.mediaDomain]);

  useEffect(() => {
    if (organisation?.theme?.logo) {
      setOrgIconMap({
        [ORG_LOGO_KEY]: organisation?.theme?.logo,
      });
    }
  }, [organisation?.theme?.logo]);

  // Filter displayed icons based on search query
  useEffect(() => {
    if (customIconMap) {
      setFilteredItems(
        Object.entries({
          ...customIconMap,
          ...orgIconMap,
          ...UserIconMap,
          ...AvatarIconMap,
        }).filter(([name]) =>
          searchVal
            ? name.toLowerCase().includes(searchVal.toLowerCase())
            : true,
        ),
      );
    }
  }, [searchVal, customIconMap, orgIconMap]);

  // Add uploaded avatar to custom icon map
  const addImageToMap = useCallback(
    (file: CreateFileDocumentResponse) => {
      if (!uploadedFileResult) {
        return;
      }

      setCustomIconMap({
        [file.file.name]: `${organisation?.mediaDomain}/${file.file.s3.key}`,
        ...customIconMap,
      });

      setUploadedFileResult(undefined);
    },
    [customIconMap, organisation?.mediaDomain, uploadedFileResult],
  );

  // Handle avatar after image upload is complete
  useEffect(() => {
    if (uploadedFileResult) {
      addImageToMap(uploadedFileResult);
      dispatch(hideLoading());
    }
  }, [addImageToMap, dispatch, organisation?.mediaDomain, uploadedFileResult]);

  // Update selected state with selected icon prop
  useEffect(() => {
    if (selectedIcon) {
      setSelected(selectedIcon);
    }
  }, [selectedIcon]);

  // Set search query
  const onSearchValueChange = useCallback(
    (value: string) => {
      setSearchVal(value);

      setFilteredItems(
        Object.entries({
          ...customIconMap,
          ...UserIconMap,
          ...AvatarIconMap,
        }).filter(([name]) =>
          value ? name.toLowerCase().includes(value.toLowerCase()) : true,
        ),
      );
    },
    [customIconMap],
  );

  const handleUploadImage = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = async (event) => {
    const fileToUpload = event.target.files[0];

    if (fileToUpload.size < UPLOAD_FILE_SIZE_LIMIT) {
      const result = await uploadFile(
        fileToUpload,
        undefined,
        Enums.FilePurpose.AVATAR,
      );
      setUploadedFileResult(result);

      setFileSizeError(false);
    } else {
      setFileSizeError(true);
    }
  };

  return (
    <SC.Wrapper>
      {!loaded && <SC.Loader src={DefaultLoadingIcon} alt={'Loading'} />}
      {loaded && (
        <>
          <StyledBodySubHeader>Search Avatars</StyledBodySubHeader>
          <StyledInput
            placeholder={'Type to search avatars'}
            value={searchVal || ''}
            onChange={(e, data) => onSearchValueChange(data.value)}
          />

          {!filteredItems.length && <StyledText>No avatars found</StyledText>}

          {fileSizeError && (
            <SC.ErrorText>
              The image selected is too large. Max file size is 2MB.
            </SC.ErrorText>
          )}

          {filteredItems.length > 0 && (
            <SC.IconWrapper maxHeight={maxHeight}>
              <SC.HiddenInput
                type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={handleFileChange}
              />
              <FeatureButton
                isDisabled={disabled}
                key={UPLOAD_IMAGE_NAME}
                isActive={selected === UPLOAD_IMAGE_NAME}
                action={() => {
                  handleUploadImage();
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faCloudUploadAlt}
                    color={defaultTheme.colors.system.white}
                    size={'3x'}
                  />
                }
                size={FeatureButtonSize.MEDIUM}
                color={
                  selected === UPLOAD_IMAGE_NAME
                    ? color
                    : themeContext.colors.general.blue
                }
              />

              {filteredItems.map(([name, icon], index) => {
                const itemName = icon.includes('http') ? icon : name;

                return (
                  <FeatureButton
                    isDisabled={disabled}
                    key={`${itemName}-${index}`}
                    isActive={selected === itemName}
                    action={() => {
                      setSelected(selected === itemName ? undefined : itemName);
                      onChange(selected === itemName ? undefined : itemName);
                    }}
                    image={icon}
                    size={FeatureButtonSize.MEDIUM}
                    color={
                      selected === itemName
                        ? color
                        : themeContext.colors.system.grey
                    }
                  />
                );
              })}
            </SC.IconWrapper>
          )}
        </>
      )}
    </SC.Wrapper>
  );
};

export default IconSelector;
