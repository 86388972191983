import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { ListType } from '@configur-tech/upit-core-types/lib/enums';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, ReactElement, useCallback } from 'react';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { SUPER_COLUMN_TYPES } from '../../components/DataSample/DataSample';
import * as SC from '../../components/DataSample/styled';
import DataSampleDataTypeHeaderCell from '../../components/DataSampleHeaderCell/DataSampleDataTypeHeaderCell';
import DataSampleQueryColumnHeaderCell from '../../components/DataSampleHeaderCell/DataSampleQueryColumnHeaderCell';
import { ROW_ID_FIELD } from '../../components/Modal/cms/CMSAddRowModal';
import AvatarNameTableCell from '../../components/TableCell/AvatarNameTableCell/AvatarNameTableCell';
import ClickableColumnTableCell from '../../components/TableCell/ClickableColumnTableCell/ClickableColumnTableCell';
import EditableTableCell, {
  EditableCellProps,
} from '../../components/TableCell/EditableTableCell/EditableTableCell';
import ImageTableCell from '../../components/TableCell/ImageTableCell/ImageTableCell';
import LiveEditTableCell from '../../components/TableCell/LiveEditTableCell/LiveEditTableCell';
import PipelineStatusTableCell from '../../components/TableCell/PipelineStatusTableCell/PipelineStatusTableCell';
import TagTableCell from '../../components/TableCell/TagTableCell/TagTableCell';
import {
  ACTIONS_COL_WIDTH,
  ACTION_ROW_HEADER,
  BOOLEAN_TYPE,
  CELL,
  CELL_LINK,
  COMMENT_COUNT_COL_WIDTH,
  COMMENT_COUNT_HEADER,
  DELETE_ROW_HEADER,
  ENTITY_ID,
  MIN_INTERACTIVE_COL_WIDTH,
  ROW_ID_COL_WIDTH,
  ROW_NUMBER_HEADER,
  SELECTION_HEADER,
  STICKY_LEFT,
  STICKY_RIGHT,
  STRING_TYPE,
  TAG_COL_WIDTH,
  UNDEFINED,
} from '../../const/DataSample';
import { FORMULA_TYPES } from '../../const/FormulaConst';
import { ListOptionColor } from '../../enums';
import { SampleDataRow } from '../../interfaces/SampleData';
import { ORIGINAL_ROW_ID_FIELD } from '../../pages/CMS/CMSPage';
import {
  ListItemOutput,
  UserListOutput,
} from '../../services/list/ListService';
import { RootState } from '../../store/rootReducer';
import { getColumnWidth } from '../../util/data-sample/DataSampleUtils';
import { PinnedColumn } from '../pinned/UsePinned';

const validator = Services.validators.DataValidator;

interface TableColumn {
  Header?: string | (() => ReactElement);
  accessor: string;
  sticky?: string;
  width?: number;
}

const COL_NAME_REPLACE_RE = /\./g;
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const LOCAL = 'local';

const DEFAULT_COLUMNS: TableColumn[] = [
  {
    accessor: ROW_NUMBER_HEADER,
    sticky: STICKY_LEFT,
    width: ROW_ID_COL_WIDTH,
  },
];

const COMMENT_COLUMN: TableColumn[] = [
  {
    accessor: COMMENT_COUNT_HEADER,
    sticky: STICKY_RIGHT,
    width: COMMENT_COUNT_COL_WIDTH,
  },
];

export interface ClickableColumn {
  columnName: string;
  valueField: string;
  action: (value: string, tableRow: string) => void;
}

interface useDataSampleResult {
  buildCells: (
    rows: SampleDataRow[],
    cells,
    iconMap?: Record<string, string>,
    schema?: Interfaces.Field[] | Interfaces.SchemaField[],
    clickableRows?: {
      valueField: string;
      action: (value: string) => void;
    },
    clickableColumn?: ClickableColumn,
    showCommentCount?: boolean,
    openContextMenu?: (
      e: React.MouseEvent<HTMLTableCellElement>,
      selectedRowId: number,
      tableRowId: number,
    ) => void,
    isLiveEditing?: boolean,
  ) => ReactElement[];

  buildColumns: (
    sampleColumns: Interfaces.Field[],
    sampleRows: Record<string, unknown>[],
    isEditingDataTypes?: boolean,
    isValidatingData?: boolean,
    isAddingSuperColumns?: boolean,
    isEditingQueryColumn?: boolean,
    hideDeleteCol?: boolean,
    locked?: boolean,
    additionalProps?: Record<string, unknown>,
    isSelecting?: boolean,
    pinnedColumn?: PinnedColumn,
    actions?: (entityId: string) => ReactElement,
    allowColumnLock?: boolean,
    showCommentCount?: boolean,
  ) => TableColumn[];

  buildRows: (
    sampleRows: SampleDataRow[],
    isValidatingData?: boolean,
    hideDeleteCol?: boolean,
    actions?: (entityId: string) => ReactElement,
  ) => unknown[];
}

const UseDataSample = (): useDataSampleResult => {
  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  const formatColumnName = (name: string): string => {
    return name
      .replace(COL_NAME_REPLACE_RE, '')
      .replaceAll('[', '')
      .replaceAll(']', '');
  };

  const buildColumns = useCallback(
    (
      sampleColumns: Interfaces.Field[],
      sampleRows: Record<string, unknown>[],
      isEditingDataTypes?: boolean,
      isValidatingData?: boolean,
      isAddingSuperColumns?: boolean,
      isEditingQueryColumn?: boolean,
      hideDeleteCol?: boolean,
      locked?: boolean,
      additionalProps?: Record<string, unknown>,
      isSelecting?: boolean,
      pinnedColumn?: PinnedColumn,
      actions?: (entityId: string) => ReactElement,
      allowColumnLock?: boolean,
      showCommentCount?: boolean,
    ): TableColumn[] => {
      let cols = sampleColumns;

      if (!cols?.length) {
        cols = cols.concat(
          Object.keys(sampleRows[0] || {}).map((key) => {
            return {
              name: key,
            };
          }),
        );
      }

      const processedCols: TableColumn[] = cols.map((col, i) => {
        const dataValidationType = col.dataValidation?.dataValidationType;

        let header: () => ReactElement = () => (
          <SC.HeaderCell
            hasAdditionalIcons={
              dataValidationType &&
              SUPER_COLUMN_TYPES.includes(dataValidationType)
            }
          >
            {col.name}
          </SC.HeaderCell>
        );

        if ((isEditingDataTypes || isValidatingData) && col.name.length) {
          header = () => {
            return (
              <DataSampleDataTypeHeaderCell
                field={col.name}
                type={
                  col.dataValidation?.dataValidationType ||
                  Enums.ValueDataType.UNKNOWN
                }
                formulaComplete={
                  !!(
                    col.dataValidation?.dataValidationType &&
                    FORMULA_TYPES.includes(
                      col.dataValidation?.dataValidationType,
                    ) &&
                    col.dataValidation?.formula?.length
                  )
                }
                dateComplete={
                  !!(
                    col.dataValidation?.dataValidationType ===
                      Enums.ValueDataType.DATE &&
                    col.dataValidation?.constraints?.format
                  )
                }
                isDisabled={locked || isValidatingData}
                hasConstraints={
                  (col.dataValidation?.constraints &&
                    Object.keys(col.dataValidation?.constraints).filter(
                      (k) =>
                        typeof col.dataValidation?.constraints?.[k] !==
                        UNDEFINED,
                    )?.length > 0) ||
                  (col.dataValidation?.formatting &&
                    Object.keys(col.dataValidation?.formatting).filter(
                      (k) =>
                        typeof col.dataValidation?.formatting?.[k] !==
                        UNDEFINED,
                    )?.length > 0)
                }
                {...(additionalProps as { dataCollectionId: string })}
                showingEmptyHeaders={
                  !Object.keys(sampleRows[0] || {}).length &&
                  i !== cols.length - 1
                }
              />
            );
          };
        } else if (isEditingQueryColumn) {
          header = () => {
            return (
              <DataSampleQueryColumnHeaderCell
                fieldName={col.name}
                allColumns={cols}
              />
            );
          };
        }

        const isTagColumn = (
          sampleRows?.[0]?.[col.name] as Record<string, unknown>
        )?.tags;

        const accessor = formatColumnName(col.name);

        return {
          Header: header,
          accessor: accessor,
          sticky: pinnedColumn && pinnedColumn.id > i ? STICKY_LEFT : undefined,
          width: isTagColumn
            ? TAG_COL_WIDTH
            : getColumnWidth(
                sampleRows,
                accessor,
                col.name,
                col.name.length &&
                  (isEditingDataTypes ||
                    isValidatingData ||
                    isAddingSuperColumns ||
                    allowColumnLock)
                  ? MIN_INTERACTIVE_COL_WIDTH
                  : undefined,
              ),
        };
      });

      // If validating add delete row column
      if (isValidatingData && !hideDeleteCol) {
        processedCols.push({
          accessor: DELETE_ROW_HEADER,
          sticky: STICKY_RIGHT,
        });
      }

      // If actions provided add column
      if (actions) {
        processedCols.push({
          accessor: ACTION_ROW_HEADER,
          sticky: STICKY_RIGHT,
          width: ACTIONS_COL_WIDTH,
        });
      }

      // If no rows don't need the default rowId column
      if (!Object.keys(sampleRows[0] || {}).length) {
        return processedCols;
      }

      return showCommentCount
        ? DEFAULT_COLUMNS.concat(
            ...COMMENT_COLUMN,
            processedCols.filter(
              (col) => (col as TableColumn).accessor !== ROW_NUMBER_HEADER,
            ),
          )
        : DEFAULT_COLUMNS.concat(
            processedCols.filter(
              (col) => (col as TableColumn).accessor !== ROW_NUMBER_HEADER,
            ),
          );
    },
    [],
  );

  const buildRows = useCallback(
    (
      sampleRows: SampleDataRow[],
      isValidatingData?: boolean,
      hideDeleteCol?: boolean,
      actions?: (entityId: string) => ReactElement,
    ): unknown[] => {
      if (!sampleRows?.length) {
        return [];
      }

      return sampleRows.map((row, index) => {
        const formattedRow = Object.entries(row).reduce(
          (formattedRow, [col, colData]) => {
            return {
              ...formattedRow,
              [formatColumnName(col)]:
                (typeof colData.value as unknown) === BOOLEAN_TYPE
                  ? startCase(colData.value?.toString())
                  : colData.value,
            };
          },
          {},
        );

        const processedRow = {
          [ROW_NUMBER_HEADER]: row[ROW_NUMBER_HEADER]?.value || index + 1,
          ...formattedRow,
        };

        if (actions) {
          processedRow[ACTION_ROW_HEADER] = actions(
            row[ENTITY_ID].value as string,
          );
        }

        return processedRow;
      });
    },
    [],
  );

  const buildCells = useCallback(
    (
      rows: SampleDataRow[],
      cells,
      iconMap?: Record<string, string>,
      schema?: Interfaces.Field[] | Interfaces.SchemaField[],
      clickableRows?: {
        valueField: string;
        action: (value: string) => void;
      },
      clickableColumn?: {
        columnName: string;
        valueField: string;
        action: (value: string, tableId: string) => void;
      },
      showCommentCount?: boolean,
      openContextMenu?: (
        e: React.MouseEvent<HTMLTableCellElement>,
        selectedRowId: number,
        tableRowId: number,
      ) => void,
      isLiveEditing?: boolean,
    ): ReactElement[] => {
      return cells.map((cell) => {
        let renderCell: string | FC<EditableCellProps> = CELL;

        const rowId = cell.row.id;
        const columnId = cell.column.id;

        const isEditable = rows[rowId][columnId]?.isEditable;
        const isError = rows[rowId][columnId]?.isError;
        const isComplete = rows[rowId][columnId]?.isComplete;
        const isImage = rows[rowId][columnId]?.isImage;
        const isPipelineStatus = rows[rowId][columnId]?.isPipelineStatus;
        const avatar = rows[rowId][columnId]?.avatar;
        const tags = rows[rowId][columnId]?.tags;

        if (columnId === COMMENT_COUNT_HEADER && !showCommentCount) {
          return;
        }

        if (columnId === SELECTION_HEADER) {
          renderCell = cell.render(CELL);
        } else if (tags) {
          renderCell = () => (
            <TagTableCell
              {...cell.getCellProps}
              tags={rows[rowId][columnId].value}
              activeTagValue={tags.activeTagValue}
            />
          );
        } else if (isEditable && columnId !== ROW_NUMBER_HEADER) {
          renderCell = EditableTableCell;
        } else if (isLiveEditing && columnId !== ROW_NUMBER_HEADER) {
          renderCell = LiveEditTableCell;
        } else if (isImage) {
          renderCell = ImageTableCell;
        } else if (avatar) {
          renderCell = () => (
            <AvatarNameTableCell
              {...cell.getCellProps}
              avatar={avatar}
              name={rows[rowId][columnId]?.value}
              iconMap={iconMap}
            />
          );
        } else if (columnId === clickableColumn?.columnName) {
          return (
            <ClickableColumnTableCell
              {...cell.getCellProps}
              cell={cell}
              clickableColumn={clickableColumn}
              columnId={columnId}
              value={cell.value}
              key={columnId}
            />
          );
        } else if (isPipelineStatus) {
          renderCell = () => (
            <PipelineStatusTableCell
              {...cell.getCellProps}
              pass={rows[rowId][columnId].value}
            />
          );
        }

        let color = '';
        let isValidPhoneNumber = false;
        let isDateTime = false;
        let dateFormat = DEFAULT_DATE_FORMAT;

        if (schema) {
          const cellSchemaData = (schema as unknown as Interfaces.Field[]).find(
            (s) => s.name === cell.column.id,
          );

          if (
            cellSchemaData &&
            cellSchemaData.dataValidation?.constraints &&
            cellSchemaData.dataValidation?.constraints.listValues &&
            typeof cellSchemaData.dataValidation.constraints.listValues ===
              STRING_TYPE
          ) {
            // Get listValues matching the schema contraints
            const matchedList = lists.find(
              (list: ListItemOutput) =>
                list.entity._id ===
                cellSchemaData.dataValidation?.constraints?.listValues,
            );

            if (
              matchedList &&
              matchedList.entity &&
              matchedList.entity.values?.length
            ) {
              if (matchedList.entity.listType === ListType.USER) {
                // Get user by matching value to id from users array
                const user = (matchedList.entity as UserListOutput).users.find(
                  (user) => user._id === cell.value,
                );

                if (user) {
                  renderCell = () => (
                    <AvatarNameTableCell
                      {...cell.getCellProps}
                      avatar={user.avatar}
                      name={`${user.firstName} ${user.lastName}`}
                      iconMap={iconMap}
                    />
                  );
                }
              } else {
                const cellColor = matchedList.entity.values.find(
                  (listItem: Interfaces.ListValue) =>
                    listItem.value === cell.value,
                );

                color = cellColor?.color
                  ? ListOptionColor[cellColor.color] || cellColor.color
                  : '';
              }
            }
          }

          if (
            cell.value &&
            cellSchemaData?.dataValidation?.dataValidationType ===
              Enums.ValueDataType.PHONE_NUM
          ) {
            try {
              isValidPhoneNumber = validator.isPhoneNum(cell.value, {
                country: Enums.SupportedCountry.UK,
              });
            } catch {
              isValidPhoneNumber = false;
            }
          }

          if (
            cell.value &&
            cellSchemaData?.dataValidation?.dataValidationType ===
              Enums.ValueDataType.DATE &&
            (
              cellSchemaData?.dataValidation
                ?.formatting as Interfaces.DateFormatting
            )?.isLocalised
          ) {
            isDateTime = true;
            dateFormat =
              cellSchemaData.dataValidation.constraints?.format ??
              DEFAULT_DATE_FORMAT;
          }
        }

        return (
          <SC.TableCell
            color={color}
            isError={isError}
            isComplete={isComplete}
            isLiveEditing={isLiveEditing}
            onContextMenu={(e) => {
              if (openContextMenu) {
                e.preventDefault();
                openContextMenu(
                  e,
                  cell.row.original[ORIGINAL_ROW_ID_FIELD],
                  cell.row.original[ROW_ID_FIELD],
                );
              }
            }}
            {...cell.getCellProps()}
            onClick={(e, data) => {
              if (
                !data &&
                e.target.dataset.name !== CELL_LINK &&
                e.currentTarget?.firstChild?.dataset?.action !=
                  SELECTION_HEADER &&
                !isLiveEditing
              ) {
                clickableRows?.action(
                  cell.row.original[clickableRows?.valueField],
                );
              }
            }}
            isBold={columnId === ROW_NUMBER_HEADER}
          >
            {cell.render(
              renderCell === CELL ? (
                isValidPhoneNumber ? (
                  <a href={`tel:${cell.value}`} target={'_blank'}>
                    {cell.value}
                  </a>
                ) : isDateTime ? (
                  <p>
                    {DateTime.fromJSDate(new Date(cell.value), {
                      zone: LOCAL,
                    }).toFormat(dateFormat)}
                  </p>
                ) : (
                  () => (
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key,
                      ) => (
                        <a
                          data-name={CELL_LINK}
                          target={'_blank'}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {cell.value}
                    </Linkify>
                  )
                )
              ) : (
                renderCell
              ),
            )}
          </SC.TableCell>
        );
      });
    },
    [lists],
  );

  return { buildCells, buildColumns, buildRows };
};

export default UseDataSample;
