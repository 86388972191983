import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialUserStage, {
  UserCreationSubStage,
  UserStage,
  UserSubStages,
} from './initial-state';

const UserStageSlice = createSlice({
  name: 'userStage',
  initialState: InitialUserStage,
  reducers: {
    updateActiveUserStage(state, action: PayloadAction<UserStage>) {
      state.activeStage = action.payload;
    },
    updateActiveUserSubStage(state, action: PayloadAction<UserSubStages>) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },

    resetStagesAndSubStages(state) {
      state.activeStage = UserStage.CREATION;
      state.stages[UserStage.CREATION].activeSubStage =
        UserCreationSubStage.NAME;
    },
  },
});

export const {
  updateActiveUserStage,
  updateActiveUserSubStage,
  resetStagesAndSubStages,
} = UserStageSlice.actions;

export default UserStageSlice.reducer;
