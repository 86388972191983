import { Interfaces } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import DefaultLoadingIcon from '../../../../assets/icons/loading/default-loading-icon.gif';
import { StyledBodySubHeader, StyledText } from '../../../../main/theme';
import * as SC from '../../styled';

interface ConditionalJobProps {
  job: Interfaces.PipelineJob;
  loading?: boolean;
}

const ConditionalJob: FC<ConditionalJobProps> = ({ job, loading }) => {
  const jobParams = job.jobParams as Interfaces.ConditionalJobParams;
  const filter = jobParams.condition as Interfaces.ConditionalJobFilter[];

  return (
    <>
      {loading && (
        <>
          <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
        </>
      )}
      {!loading && (
        <>
          <div>
            <StyledBodySubHeader>Conditional Filtering On</StyledBodySubHeader>
            {!!(filter?.[0]?.value as Interfaces.DynamicConditionalField[])
              ?.length && (
              <SC.MappingFieldWrapper>
                {(
                  filter[0]?.value as Interfaces.DynamicConditionalField[]
                )?.map((field, index) => {
                  const fieldWithAlias =
                    field as unknown as Interfaces.FieldOutput & {
                      alias?: string;
                    };
                  return (
                    <SC.MappingField
                      key={`map-field-${fieldWithAlias.alias}-${index}`}
                    >
                      <StyledText>{fieldWithAlias.alias}</StyledText>
                    </SC.MappingField>
                  );
                })}
              </SC.MappingFieldWrapper>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ConditionalJob;
