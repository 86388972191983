import { FC } from 'react';
import { useSelector } from 'react-redux';
import NameDatasetStage from '../../components/DatasetStages/DatasetCreationStages/1-name/NameDatasetStage';
import ListItemNameStage from '../../components/ListItemStages/ListItemCreationStages/1-name/ListItemNameStage';
import ListItemValuesStage from '../../components/ListItemStages/ListItemCreationStages/2-values/ListItemValuesStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import {
  ListCreateSubStage,
  ListStage,
} from '../../store/list-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getListStageTitle,
  getListSubStageContent,
} from '../../util/list-stage-content/ListStageContent';

const ListItemCreation: FC = () => {
  const listStageState = useSelector((state: RootState) => state.listStage);

  const activeStage = ListStage.CREATION;
  const activeSubStage = listStageState.stages[activeStage].activeSubStage;

  const stageTitle = getListStageTitle(activeStage);
  const subStageContent = getListSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ListCreateSubStage.NAME:
        return <ListItemNameStage />;
      case ListCreateSubStage.VALUES:
        return <ListItemValuesStage />;
      default:
        return <NameDatasetStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: listStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default ListItemCreation;
