import { Enums, Interfaces, Mappers } from '@configur-tech/upit-core-types';
import { cloneDeep, startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import { DynamicConditionalField } from '../../../hooks/filter/UseFilter';
import usePipelineTemplate from '../../../hooks/pipeline-template/UsePipelineTemplate';
import {
  StyledBodySubHeader,
  StyledDropdown,
  StyledDropdownWide,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import { DatasetMetaItemOutput } from '../../../services/dataset-meta/DatasetMetaService';
import { IntegrationTemplateItem } from '../../../services/integration/IntegrationTemplateService';
import {
  initialDatasetJobParams,
  initialMappingJobParams,
} from '../../../store/pipeline-template/inital-state';
import { RootState } from '../../../store/rootReducer';
import MergeDatasetOptions from '../../DatasetStages/DatasetCreationStages/2-source/MergeDatasetOptions';
import FilterBuilder from '../filter/FilterBuilder';
import { DropdownItemPropsWithValidation } from '../filter/FilterModal';

const DATASET_META_ID = 'datasetMetaId';
const DATASET_ACTION_FIELD = 'action';
const DATASET_CONDITIONS_FIELD = 'conditions';

const DATASET_JOB_ACTION_OPTIONS = [
  Enums.DatasetJobAction.INSERT,
  Enums.DatasetJobAction.UPDATE,
  Enums.DatasetJobAction.UPSERT,
  Enums.DatasetJobAction.DELETE,
].map((action, index) => ({
  key: `dataset-action-${action}-${index}`,
  value: action,
  text: startCase(action.toLowerCase()),
}));

const DELETE_ONLY_ACTION_OPTIONS = [Enums.DatasetJobAction.DELETE].map(
  (action, index) => ({
    key: `dataset-action-${action}-${index}`,
    value: action,
    text: startCase(action.toLowerCase()),
  }),
);

export interface DatasetJobsModalProps {
  jobIndex: number;
  onChange: (data) => void;
}

const JobDatasetModalComponent: FC<DatasetJobsModalProps> = ({
  jobIndex,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const { activeDataCollectionItem, datasetMeta, getDatasetMetas } =
    useDatasetMeta();

  const datasetMetas: DatasetMetaItemOutput[] = useSelector(
    (state: RootState) => state.datasetMetas,
  )?.data.data;

  const integrationTemplates: IntegrationTemplateItem[] = useSelector(
    (state: RootState) => state.integrationTemplates,
  )?.data.data;

  const { pipelineTemplate } = usePipelineTemplate();

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [pipelineJob, setPipelineJob] = useState<Interfaces.DatasetJobParams>(
    initialDatasetJobParams,
  );
  const [prevMappingJob, setPrevMappingJob] =
    useState<Interfaces.MappingJobParams>(initialMappingJobParams);
  const [datasetMetaId, setDatasetMetaId] = useState<string>();
  const [datasetOptions, setDatasetOptions] = useState<DropdownItemProps[]>([]);
  const [fieldOptions, setFieldOptions] =
    useState<DropdownItemPropsWithValidation[]>();
  const [filter, setFilter] = useState<DynamicConditionalField[]>([
    {
      operator: Enums.AggregationFilterOperator.AND,
      value: [],
      active: true,
    },
  ]);
  const [entitySchema, setEntitySchema] = useState<Interfaces.FieldOutput[]>();
  const [pipelineMappings, setPipelineMappings] =
    useState<Interfaces.FieldOutput[]>();

  const isEditing = jobIndex > -1;
  const isSingleDelete =
    !prevMappingJob.mappings.length &&
    pipelineJob.action === Enums.DatasetJobAction.DELETE;

  // If editing existing, set data
  useEffect(() => {
    if (isEditing && pipelineTemplate?.jobs[jobIndex]?.jobParams) {
      setPipelineJob(
        pipelineTemplate.jobs[jobIndex]
          .jobParams as Interfaces.DatasetJobParams,
      );

      setDatasetMetaId(
        (
          pipelineTemplate.jobs[jobIndex]
            .jobParams as Interfaces.DatasetJobParams
        ).datasetMetaId,
      );

      setFilter(
        ((
          pipelineTemplate.jobs[jobIndex]
            .jobParams as Interfaces.DatasetJobParams
        ).query?.conditions as unknown as Interfaces.DynamicFilterField[]) || [
          {
            operator: Enums.AggregationFilterOperator.AND,
            value: [],
            active: true,
          },
        ],
      );
    }
  }, [isEditing, jobIndex, pipelineTemplate?.jobs]);

  // If updated fire onChange
  useEffect(() => {
    if (pipelineJob) {
      onChange(pipelineJob);
    }
  }, [onChange, pipelineJob]);

  // Get datasetMetaId from mapping pipeline job
  useEffect(() => {
    if (pipelineTemplate?._id && !pipelineMappings) {
      const clonedJobs = cloneDeep(pipelineTemplate.jobs);
      const previousMappingJob = clonedJobs
        .slice(0, jobIndex)
        .reverse()
        .find(
          (job) => job.jobType === Enums.PipelineJobType.MAPPING,
        ) as Interfaces.MappingJob;

      const previousMapParams =
        previousMappingJob?.jobParams as Interfaces.MappingJobParams;

      if (previousMappingJob) {
        setPrevMappingJob(previousMapParams);
      }

      if (!isEditing && previousMapParams) {
        setDatasetMetaId(previousMapParams.output.entityId);
      }
    }
  }, [
    isEditing,
    jobIndex,
    pipelineMappings,
    pipelineTemplate?._id,
    pipelineTemplate?.jobs,
  ]);

  // Build pipeline mappings
  useEffect(() => {
    if (prevMappingJob?.mappings.length) {
      let pipelineSchemaMappings;

      switch (prevMappingJob.output.type) {
        case Enums.MappingSourceType.INTEGRATION: {
          const integration = integrationTemplates?.find(
            (integration) =>
              integration.entity._id === prevMappingJob.output.entityId,
          )?.entity;

          if (prevMappingJob.output.baseEndpointId) {
            const baseEndpoint = integration?.endpoints.find(
              (base) => base._id === prevMappingJob.output.baseEndpointId,
            );

            const reqEndpoint = baseEndpoint?.endpoints.find(
              (req) => req._id === prevMappingJob.output.requestEndpointId,
            );

            pipelineSchemaMappings =
              reqEndpoint?.responseInfo?.responseSchema?.map((field) =>
                Mappers.SchemaFieldMapper.domainSchemaToDbSchema(field),
              );
          }

          if (prevMappingJob.output.customActionId) {
            const action = integration?.customActions.find(
              (act) => act._id === prevMappingJob.output.customActionId,
            );

            pipelineSchemaMappings = action?.responseSchema?.map((field) =>
              Mappers.SchemaFieldMapper.domainSchemaToDbSchema(field),
            );
          }

          break;
        }
        case Enums.MappingSourceType.DATASET: {
          const outputDsm = datasetMetas.find(
            (dsm) => dsm.entity._id === prevMappingJob.output.entityId,
          );
          const activeCollection = outputDsm?.entity.dataCollections.find(
            (coll) => coll._id === outputDsm?.entity.activeDataCollection,
          );

          pipelineSchemaMappings = activeCollection?.schemaData;

          break;
        }

        default:
          pipelineSchemaMappings = activeDataCollectionItem?.schemaData;
      }

      if (pipelineSchemaMappings) {
        // Build mapping field schema
        const pipelineSchema = prevMappingJob?.mappings.map((mapping) => {
          return pipelineSchemaMappings?.find(
            (field) =>
              mapping.outputField === field.fieldId ||
              mapping.outputField === field.name,
          );
        });

        setPipelineMappings(
          pipelineSchema as unknown as Interfaces.FieldOutput[],
        );
      }
    }
  }, [
    activeDataCollectionItem?.schemaData,
    datasetMetas,
    integrationTemplates,
    prevMappingJob,
  ]);

  // Get schema and field options from output dataset
  useEffect(() => {
    if (datasetMetaId && datasetMetas.length) {
      const pipelineDataset = datasetMetas.find(
        (d) => d.entity._id === datasetMetaId,
      );

      const pipelineActiveDataCollection =
        pipelineDataset?.entity.dataCollections.find(
          (collection) =>
            collection._id === pipelineDataset?.entity.activeDataCollection,
        );

      if (pipelineActiveDataCollection) {
        const schemaData: Interfaces.FieldOutput[] =
          pipelineActiveDataCollection?.schemaData;

        if (schemaData.length) {
          setEntitySchema(schemaData);

          const options: DropdownItemProps[] = schemaData.map((f, i) => {
            return {
              key: `field-${f.name}-${i}`,
              value: `${f.fieldId || f.name}***${datasetMetaId}***${f.name}`,
              text: f.name,
            };
          });

          setFieldOptions(options);
        }
      }
    }
  }, [datasetMetaId, datasetMetas]);

  // Fetch any datasetMetas for lookup columns
  useEffect(() => {
    (async () => {
      if (
        !entitySchema?.length &&
        (isSingleDelete || (!isSingleDelete && datasetMetaId))
      ) {
        setLoadingEntities(true);
        await getDatasetMetas(isSingleDelete ? {} : { _id: datasetMetaId });
        setLoadingEntities(false);
      }
    })();
  }, [
    activeDataCollectionItem,
    datasetMetaId,
    entitySchema,
    getDatasetMetas,
    isSingleDelete,
  ]);

  // Build dataset options
  useEffect(() => {
    if (datasetMetas?.length) {
      setDatasetOptions(
        datasetMetas
          .filter((dsm) => dsm.entity._id !== datasetMeta?._id)
          .map((dsm, index) => ({
            key: `target-dsm-${dsm.entity._id}-${index}`,
            value: dsm.entity._id,
            text: dsm.entity.name,
          })),
      );
    }
  }, [datasetMeta?._id, datasetMetas]);

  // Handle field change
  const handleFieldChange = (field: string, value: unknown) => {
    const cloned = cloneDeep(pipelineJob);
    cloned[field] = value;

    if (!cloned[DATASET_META_ID]) {
      cloned[DATASET_META_ID] = datasetMetaId;
    }

    if (field === DATASET_META_ID) {
      setDatasetMetaId(value as string);
    }

    setPipelineJob(cloned);
  };

  const handleFilterChange = (filter) => {
    const cloned = cloneDeep(pipelineJob);

    if (!cloned.query) {
      cloned.query = {};
    }

    cloned.query[DATASET_CONDITIONS_FIELD] = [filter];
    setPipelineJob(cloned);

    setFilter([filter]);
  };

  return (
    <>
      <StyledBodySubHeader
        style={{
          marginBottom: themeContext.margin.standard,
          marginTop: themeContext.margin.standard,
        }}
      >
        Dataset Action
      </StyledBodySubHeader>
      <StyledText>
        Which dataset action would you like this job to perform?
      </StyledText>

      <StyledDropdown
        selectOnBlur={false}
        upward={true}
        selection
        value={pipelineJob[DATASET_ACTION_FIELD] || ''}
        placeholder={'Please select a dataset action'}
        options={
          prevMappingJob.mappings.length
            ? DATASET_JOB_ACTION_OPTIONS
            : DELETE_ONLY_ACTION_OPTIONS
        }
        style={{ marginTop: 0 }}
        onChange={(e, { value }) =>
          handleFieldChange(DATASET_ACTION_FIELD, value)
        }
      />

      {isSingleDelete && (
        <>
          <StyledBodySubHeader
            style={{ marginTop: themeContext.margin.xlarge }}
          >
            Target Dataset
          </StyledBodySubHeader>
          <StyledText style={{ marginBottom: themeContext.margin.xxxlarge }}>
            Select which dataset you would like to target.
          </StyledText>

          <StyledDropdownWide
            search
            loading={loadingEntities}
            selectOnBlur={false}
            upward={true}
            selection
            value={pipelineJob[DATASET_META_ID] || ''}
            placeholder={'Please select a target dataset'}
            options={datasetOptions}
            style={{ marginTop: 0 }}
            onChange={(e, { value }) =>
              handleFieldChange(DATASET_META_ID, value)
            }
          />
        </>
      )}

      {pipelineJob[DATASET_ACTION_FIELD] === Enums.DatasetJobAction.UPDATE && (
        <>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.standard,
            }}
          >
            Update Query Filters
          </StyledSubHeader>

          <StyledText
            style={{
              marginBottom: themeContext.margin.xxxlarge,
            }}
          >
            You must add filters to limit which rows will get updated.
          </StyledText>

          <FilterBuilder
            entitySchema={entitySchema}
            fieldOptions={fieldOptions}
            filter={filter?.[0]}
            setFilter={handleFilterChange}
            pipelineMappings={pipelineMappings}
            smallHeadings={true}
          />
        </>
      )}

      {pipelineJob[DATASET_ACTION_FIELD] === Enums.DatasetJobAction.DELETE &&
        pipelineJob[DATASET_META_ID] && (
          <>
            <StyledSubHeader
              style={{
                marginBottom: themeContext.margin.standard,
                marginTop: themeContext.margin.standard,
              }}
            >
              Delete Query Filters
            </StyledSubHeader>

            <StyledText
              style={{
                marginBottom: themeContext.margin.xxxlarge,
              }}
            >
              You must add filters to limit which rows will get deleted.
            </StyledText>

            <FilterBuilder
              entitySchema={
                isSingleDelete
                  ? activeDataCollectionItem?.schemaData
                  : entitySchema
              }
              fieldOptions={fieldOptions}
              filter={filter?.[0]}
              setFilter={handleFilterChange}
              pipelineMappings={
                isSingleDelete
                  ? activeDataCollectionItem?.schemaData
                  : pipelineMappings
              }
              smallHeadings={true}
            />
          </>
        )}

      {pipelineJob[DATASET_ACTION_FIELD] === Enums.DatasetJobAction.UPSERT && (
        <MergeDatasetOptions
          pipelineJob={pipelineJob}
          handleFieldChange={handleFieldChange}
          wrapperMargin={'0'}
        />
      )}
    </>
  );
};

export default JobDatasetModalComponent;
