export enum UserStage {
  CREATION = 'creation',
  CONFIGURATION = 'configuration',
}

export enum UserCreationSubStage {
  NAME = 'name',
  AVATAR = 'avatar',
}

export enum UserConfigureSubStage {
  ADVANCED = 'advanced',
}

interface UserSubStage {
  subStage: string;
  stage: number;
  title: string;
}

export type UserSubStages = UserCreationSubStage | UserConfigureSubStage;

export interface UserStages {
  activeStage: UserStage;
  stages: {
    [UserStage.CREATION]: {
      activeSubStage: UserCreationSubStage;
      subStages: UserSubStage[];
    };
    [UserStage.CONFIGURATION]: {
      activeSubStage: UserConfigureSubStage;
      subStages: UserSubStage[];
    };
  };
}

const InitialUserStage: UserStages = {
  activeStage: UserStage.CREATION,
  stages: {
    [UserStage.CREATION]: {
      activeSubStage: UserCreationSubStage.NAME,
      subStages: [
        {
          subStage: UserCreationSubStage.NAME,
          stage: 1,
          title: 'Name',
        },
        {
          subStage: UserCreationSubStage.AVATAR,
          stage: 2,
          title: 'Avatar',
        },
      ],
    },
    [UserStage.CONFIGURATION]: {
      activeSubStage: UserConfigureSubStage.ADVANCED,
      subStages: [
        {
          subStage: UserConfigureSubStage.ADVANCED,
          stage: 1,
          title: 'Advanced',
        },
      ],
    },
  },
};

export default InitialUserStage;
