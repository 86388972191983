import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IntegrationCategoryTags } from '../../../../enums';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdownWide,
  StyledH5,
  StyledInput,
  StyledTextArea,
} from '../../../../main/theme';
import { updateActiveIntegrationSubStage } from '../../../../store/integration-stage';
import { IntegrationCreationSubStage } from '../../../../store/integration-stage/initial-state';
import { fetchIntegrationTemplateSuccess } from '../../../../store/integration-template';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

const NAME_FIELD = 'name';
const DESCRIPTION_FIELD = 'description';
const TAGS_FIELD = 'tags';
const TAG_KEY = 'category';
const NEXT_STAGE = IntegrationCreationSubStage.AVATAR;

const integrationCategoryTagOptions = Object.values(
  IntegrationCategoryTags,
).map((cat, index) => ({
  key: `int-cat-${cat}-${index}`,
  value: cat,
  text: cat,
}));

const MetaIntegrationStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { integrationTemplate, integrationTemplateAccessLevel } =
    useIntegrationTemplate();

  const [integrationName, setIntegrationName] = useState<string>();
  const [integrationDescription, setIntegrationDescription] =
    useState<string>();

  useEffect(() => {
    setIntegrationName(integrationTemplate?.name);
    setIntegrationDescription(integrationTemplate?.description);
  }, [integrationTemplate?.name, integrationTemplate?.description]);

  const handleChange = (field: string, val: string | string[]) => {
    switch (field) {
      case NAME_FIELD:
        setIntegrationName(val as string);
        break;
      case DESCRIPTION_FIELD:
        setIntegrationDescription(val as string);
    }

    const cloned = cloneDeep(integrationTemplate);
    cloned[field] =
      field === TAGS_FIELD
        ? (val as string[]).map((value) => ({
            key: TAG_KEY,
            value: value.toString(),
          }))
        : val;

    dispatch(
      fetchIntegrationTemplateSuccess({
        entity: cloned,
        accessLevel: integrationTemplateAccessLevel || Enums.AccessLevel.MANAGE,
      }),
    );
  };

  return (
    <StageWrapper>
      <StageInner>
        <StageBodyText>
          Enter an easily identifiable name for your new integration.
        </StageBodyText>

        <StyledBodySubHeader>Name</StyledBodySubHeader>
        <StyledInput
          placeholder={'Enter your integration name'}
          value={integrationName || ''}
          onChange={(event, data) => handleChange(NAME_FIELD, data.value)}
          style={{ marginBottom: themeContext.margin.xxlarge }}
        />

        <StyledBodySubHeader>Description</StyledBodySubHeader>
        <StageBodyText>
          Briefly, what are you trying to achieve with this integration?
        </StageBodyText>
        <StyledTextArea
          minRows={5}
          maxRows={10}
          placeholder={'Enter your integration description'}
          value={integrationDescription || ''}
          onChange={(event) =>
            handleChange(DESCRIPTION_FIELD, event.target.value)
          }
        />

        <StyledH5
          style={{
            marginTop: themeContext.margin.large,
            marginBottom: themeContext.margin.small,
          }}
        >
          Categories
        </StyledH5>
        <StyledDropdownWide
          style={{
            marginTop: themeContext.margin.small,
          }}
          selectOnBlur={false}
          placeholder={`Select a category`}
          noResultsMessage={'No category was found'}
          text={'Add categories to easily search your integrations.'}
          search
          selection
          multiple
          upward={true}
          options={integrationCategoryTagOptions}
          value={integrationTemplate?.tags?.map((tag) => tag.value) || []}
          onChange={(e, { value }) => handleChange(TAGS_FIELD, value)}
        />
      </StageInner>

      <ActionBar
        text={`Ready to carry on?`}
        primaryButton={
          <FeatureButton
            isDisabled={!integrationTemplate?.name.length}
            action={() => dispatch(updateActiveIntegrationSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default MetaIntegrationStage;
