import { Enums } from '@configur-tech/upit-core-types';
import { faPenToSquare, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { EntityType } from '../../enums';
import { NotificationAlertItemOutput } from '../../services/notification-alert/NotificationAlertService';
import { showModal } from '../../store/modal';
import { fetchNotificationAlertSuccess } from '../../store/notification-alert';
import { RootState } from '../../store/rootReducer';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';
import { ModalTypes } from '../Modal/Modal';
import FormattedNotificationContent from './FormattedNotificationContent';
import NotificationIcon from './NotificationIcon';
import * as SC from './styled';

interface NotificationCardProps {
  notification: NotificationAlertItemOutput;
}

const NotificationCard: FC<NotificationCardProps> = ({ notification }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const notificationAlertsState = useSelector(
    (state: RootState) => state.notificationAlerts,
  );
  const notificationAlerts: NotificationAlertItemOutput[] =
    notificationAlertsState.data.data;

  const handleNotificationEdit = (notificationId: string) => {
    const notificationToEdit = notificationAlerts.find(
      (notification) => notification.entity._id === notificationId,
    );
    if (!notificationToEdit) {
      return;
    }

    dispatch(
      fetchNotificationAlertSuccess({
        accessLevel: notificationToEdit?.accessLevel,
        entity: notificationToEdit?.entity,
      }),
    );
    dispatch(
      showModal({
        visible: true,
        modal: ModalTypes.NOTIFICATION_ALERT,
        fullScreen: true,
        additionalProps: {
          entityType: EntityType.AGGREGATION,
          notification: notification.entity,
          isEditing: !!notification.entity._id,
        },
      }),
    );
  };

  const handleNotificationDelete = () => {
    dispatch(
      showModal({
        visible: true,
        modal: ModalTypes.DELETION,
        additionalProps: {
          entityType: EntityType.NOTIFICATION,
          entityId: notification?.entity._id,
          requireConfirmation: false,
        },
      }),
    );
  };

  return (
    <SC.CardWrapper>
      <SC.CardContent>
        <NotificationIcon channel={notification.entity.channel} />
        <SC.CardDetails>
          <SC.CardTitle>
            {notification.entity?.additionalDetails?.name}
          </SC.CardTitle>
          {notification.entity?.conditions && (
            <SC.CardConditionText>
              <FormattedNotificationContent
                createdBy={notification.entity.meta.createdBy}
                notificationAlertParams={{
                  conditions: notification.entity.conditions,
                  notificationAlertId: notification.entity._id,
                  resource: notification.entity.resource,
                  trigger: notification.entity.trigger,
                }}
                notificationAlertActions={{
                  channel: notification.entity.channel,
                  frequency: notification.entity.frequency,
                  recipients: notification.entity.recipients,
                }}
              />
            </SC.CardConditionText>
          )}
        </SC.CardDetails>
      </SC.CardContent>
      <SC.CardActions>
        {notification.accessLevel === Enums.AccessLevel.MANAGE && (
          <>
            <FeatureButton
              action={() => handleNotificationEdit(notification.entity._id)}
              size={FeatureButtonSize.EXTRA_SMALL}
              color={themeContext.colors.general.blue}
              containerStyle={{ marginLeft: themeContext.margin.standard }}
              icon={
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  color={themeContext.colors.system.white}
                  size={'lg'}
                />
              }
            />
            <FeatureButton
              action={handleNotificationDelete}
              size={FeatureButtonSize.EXTRA_SMALL}
              color={themeContext.colors.general.red}
              containerStyle={{ marginLeft: themeContext.margin.standard }}
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  color={themeContext.colors.system.white}
                  size={'lg'}
                />
              }
            />
          </>
        )}
      </SC.CardActions>
    </SC.CardWrapper>
  );
};

export default NotificationCard;
