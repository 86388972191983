import { Enums } from '@configur-tech/upit-core-types';
import { startCase } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AlertStatus } from '../../../enums';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useProject from '../../../hooks/project/UseProject';
import { SampleDataRow } from '../../../interfaces/SampleData';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledSubHeader,
} from '../../../main/theme';
import { showModal } from '../../../store/modal';
import { updateActiveProjectStage } from '../../../store/project-stage';
import { RootState } from '../../../store/rootReducer';
import BuildBanner from '../../../util/buildBanner/BuildBanner';
import { getActiveDataCollection } from '../../../util/dataset-meta-collection/getActiveDataCollection';
import ActionBar from '../../ActionBar/ActionBar';
import Banner from '../../BannerComponent/Banner';
import DataSample from '../../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { ModalTypes } from '../../Modal/Modal';

const ID_FIELD = '_id';
const PRIMARY_DATASET = 'Primary Dataset';
const PRIMARY_FIELD = 'Primary Field';
const SECONDARY_DATASET = 'Secondary Dataset';
const SECONDARY_FIELD = 'Secondary Field';
const LINK_TYPE_FIELD = 'Link Type';

const TABLE_SCHEMA = [
  { name: PRIMARY_DATASET },
  { name: PRIMARY_FIELD },
  { name: SECONDARY_DATASET },
  { name: SECONDARY_FIELD },
  { name: LINK_TYPE_FIELD },
];

const PREV_STAGE = Enums.ProjectStage.CREATION;

const ProjectModellingStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { project } = useProject();
  const { getDatasetMetas } = useDatasetMeta();

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas = datasetMetasState?.data.data;

  // Get datasetMetas
  useEffect(() => {
    if (project?.datasetMetaId?.length) {
      (async () => {
        await getDatasetMetas(
          { _id: { $in: project.datasetMetaId } },
          undefined,
          undefined,
          150,
        );
        setIsLoading(false);
      })();
    }
  }, [dispatch, getDatasetMetas, project?.datasetMetaId]);

  useEffect(() => {
    if (project?.dataModel) {
      const tableData: SampleDataRow[] = [];

      project?.dataModel?.map((link) => {
        const primaryDS = datasetMetas.find(
          (d) => d.entity._id === link.primary.datasetMetaId,
        )?.entity;
        const secondaryDS = datasetMetas.find(
          (d) => d.entity._id === link.secondary.datasetMetaId,
        )?.entity;

        if (primaryDS && secondaryDS) {
          const primaryField = getActiveDataCollection(
            primaryDS,
          )?.schemaData?.find(
            (f) => (f.fieldId || f.name) === link.primary.field,
          );
          const secondaryField = getActiveDataCollection(
            secondaryDS,
          )?.schemaData?.find(
            (f) => (f.fieldId || f.name) === link.secondary.field,
          );

          tableData.push({
            [ID_FIELD]: { value: link._id },
            [PRIMARY_DATASET]: { value: primaryDS.name },
            [PRIMARY_FIELD]: {
              value: primaryField?.name || link.primary.field,
            },
            [SECONDARY_DATASET]: {
              value: secondaryDS.name,
            },
            [SECONDARY_FIELD]: {
              value: secondaryField?.name || link.secondary.field,
            },
            [LINK_TYPE_FIELD]: {
              value: startCase(link.joinType),
            },
          });
        }
      });

      setTableData(tableData);
    }
  }, [datasetMetas, project?.dataModel]);

  return (
    <StageWrapper>
      <StageInner>
        <Banner
          message={
            <p>
              Data models are now managed at an organisational level and can be
              accessed{' '}
              <a href={'https://portal.goconfigur.com/organisation/overview'}>
                here
              </a>
              .
            </p>
          }
          alertIcon={BuildBanner.generateIcon(AlertStatus.INFO)}
          alertStatus={AlertStatus.INFO}
        />

        <StageBodyText style={{ marginTop: themeContext.margin.xlarge }}>
          Link your data to highlight relationships.
        </StageBodyText>
        <StageBodyText>
          When you do you'll unlock a ton of additional benefits in Codex.
        </StageBodyText>

        {/*Deprecated*/}
        {/*{datasetMetas?.length < 2 && (*/}
        {/*  <>*/}
        {/*    <StyledSubHeader style={{ color: themeContext.colors.general.red }}>*/}
        {/*      Not Enough Datasets*/}
        {/*    </StyledSubHeader>*/}
        {/*    <StageBodyText>*/}
        {/*      You'll need at least 2 datasets connected to this project in order*/}
        {/*      to link them up.*/}
        {/*    </StageBodyText>*/}

        {/*    <FeatureButton*/}
        {/*      action={() => {*/}
        {/*        dispatch(updateActiveProjectStage(PREV_STAGE));*/}
        {/*      }}*/}
        {/*      size={FeatureButtonSize.WIDE}*/}
        {/*      color={themeContext.colors.general.sea}*/}
        {/*      text={'Add More Datasets'}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}

        {/*Deprecated*/}
        {/*{datasetMetas?.length >= 2 && (
          <FeatureButton
            isDisabled={datasetMetas?.length < 2}
            action={() => {
              dispatch(
                showModal({
                  visible: true,
                  modal: ModalTypes.MODELLING_ADD_LINK,
                }),
              );
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Add Data Link'}
            containerStyle={{ marginBottom: themeContext.margin.large }}
          />
        )}*/}

        {(tableData.length > 0 || isLoading) && (
          <DataSample
            sampleColumns={TABLE_SCHEMA}
            sampleRows={tableData}
            // Deprecated
            // clickableRows={{
            //   valueField: ID_FIELD,
            //   action: (id: string) =>
            //     dispatch(
            //       showModal({
            //         visible: true,
            //         modal: ModalTypes.MODELLING_ADD_LINK,
            //         additionalProps: { dataLinkId: id },
            //       }),
            //     ),
            // }}
            loading={isLoading}
          />
        )}
      </StageInner>

      <ActionBar
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to overview'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectModellingStage;
