import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuotaLimits, ResourceType } from '../../enums';
import UsageService from '../../services/usage/UsageService';
import { AppThunk } from '../store';

export interface DatapointUsageCheck {
  isDatapointQuotaLimit: boolean;
  percentageUsed: number;
}
export interface QuotaState {
  isDatapointQuotaLimit: boolean;
  percentageUsed: number;
  loading: boolean;
  error: string | null;
}

const initialState: QuotaState = {
  isDatapointQuotaLimit: false,
  percentageUsed: 0,
  loading: false,
  error: null,
};

const QuotaSlice = createSlice({
  name: 'datapoint quota',
  initialState,
  reducers: {
    // Fetch
    fetchDatapointQuotaStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatapointQuotaSuccess(
      state,
      action: PayloadAction<DatapointUsageCheck>,
    ) {
      state.isDatapointQuotaLimit = action.payload.isDatapointQuotaLimit;
      state.percentageUsed = action.payload.percentageUsed;
      state.loading = false;
      state.error = null;
    },
    fetchDatapointQuotaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDatapointQuotaStart,
  fetchDatapointQuotaSuccess,
  fetchDatapointQuotaFailure,
} = QuotaSlice.actions;

export default QuotaSlice.reducer;

export const datapointQuotaCheck =
  (token: string, organisationId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDatapointQuotaStart());
      let isDatapointsQuotaExceeded = false;
      const fetched = await UsageService.checkResourceUsage(
        token,
        organisationId,
        ResourceType.DATA_POINTS,
      );
      fetched.usagePercentage >= QuotaLimits.FULL
        ? (isDatapointsQuotaExceeded = true)
        : (isDatapointsQuotaExceeded = false);
      dispatch(
        fetchDatapointQuotaSuccess({
          isDatapointQuotaLimit: isDatapointsQuotaExceeded,
          percentageUsed: fetched.usagePercentage,
        }),
      );
    } catch (err) {
      dispatch(fetchDatapointQuotaFailure((err as string).toString()));
    }
  };
