import { Interfaces } from '@configur-tech/upit-core-types';

const InitialAPIKey: Interfaces.ApiKey = {
  access: {
    manage: {
      organisation: [],
      team: [],
      user: [],
    },
    edit: {
      organisation: [],
      team: [],
      user: [],
    },
    view: {
      organisation: [],
      team: [],
      user: [],
    },
  },
  alias: '',
  description: '',
  isEnabled: true,
  allowedMethods: {
    httpAll: false,
    httpDelete: false,
    httpPut: false,
    httpPatch: false,
  },
  apiId: '',
  organisationId: '',
};

export default InitialAPIKey;
