import styled from 'styled-components';

export const Picker = styled.div`
  position: relative;

  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: ${({ theme }) => theme.padding.small};
    border: 1px solid ${({ theme }) => theme.colors.system.grey};
    border-radius: ${({ theme }) => theme.borders.radius};
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
  }

  input:focus {
    border-color: ${({ theme }) => theme.colors.general.blue};
  }
`;

export const PickerSwatch = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.general.white};
  cursor: pointer;
`;

export const PickerPopOver = styled.div`
  position: absolute;
  bottom: calc(100% + 2px);
  right: 0;
  border-radius: ${({ theme }) => theme.borders.radius};
  z-index: 2;
`;
