import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  lockDatasetCollection,
  unlockDatasetCollection,
  updateDatasetCollection,
} from '../../store/dataset-collection';
import { DatasetCollectionState } from '../../store/dataset-collection/initial-state';
import { Enums } from '@configur-tech/upit-core-types';
import { RootState } from '../../store/rootReducer';
import useDatasetMeta from '../dataset-meta/UseDatasetMeta';

export const initialDatasetStageValidation = {
  status: Enums.StageStatus.NOT_STARTED,
  result: {
    total: 0,
    completed: 0,
    failed: 0,
  },
  failed: [],
  corrected: [],
};

interface useDatasetCollectionResult {
  collection?: DatasetCollectionState;
  activeCollectionSelected?: boolean;

  collectionLockStatus?: boolean;
  lockCollection: () => void;
  unlockCollection: () => void;
}

const useDatasetCollection = (): useDatasetCollectionResult => {
  const dispatch = useDispatch();
  const { datasetMeta, activeDataCollectionItem } = useDatasetMeta();

  const [collection, setCollection] = useState<DatasetCollectionState>();
  const [activeCollectionSelected, setActiveCollectionSelected] =
    useState<boolean>(true);
  const [collectionLockStatus, setCollectionLockStatus] =
    useState<boolean>(false);

  const collectionState = useSelector(
    (state: RootState) => state.datasetCollection,
  );

  const lockCollection = () => dispatch(lockDatasetCollection());

  const unlockCollection = () => dispatch(unlockDatasetCollection());

  // Set default collection
  useEffect(() => {
    if (
      datasetMeta?._id &&
      datasetMeta?.dataCollections &&
      (!collectionState.datasetMetaId ||
        datasetMeta._id !== collectionState.datasetMetaId)
    ) {
      dispatch(
        updateDatasetCollection({
          datasetMetaId: datasetMeta._id,
          collectionId: (
            activeDataCollectionItem ||
            datasetMeta.dataCollections[datasetMeta.dataCollections.length - 1]
          )?._id,
          collectionLocked: true,
        }),
      );
    }
  }, [
    activeDataCollectionItem,
    collectionState.datasetMetaId,
    datasetMeta,
    dispatch,
  ]);

  // Update hook state
  useEffect(() => {
    if (collectionState.datasetMetaId && collectionState.collectionId) {
      // Complete model
      setCollection(collectionState);

      // Active data collection is selected
      setActiveCollectionSelected(
        activeDataCollectionItem?._id === collection?.collectionId,
      );

      // Lock status
      setCollectionLockStatus(collectionState?.collectionLocked || false);
    }
  }, [activeDataCollectionItem, collection?.collectionId, collectionState]);

  return {
    collection,
    activeCollectionSelected,

    collectionLockStatus,
    lockCollection,
    unlockCollection,
  };
};

export default useDatasetCollection;
