import { Interfaces } from '@configur-tech/upit-core-types';
import { FormulaOperandType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledInput } from '../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

interface NumberInputsProps {
  addFormulaItem: (item: Interfaces.FormulaOperandValue) => void;
  columnStatus: boolean;
}

const NumberInputs: FC<NumberInputsProps> = ({
  addFormulaItem,
  columnStatus,
}) => {
  const themeContext = useContext(ThemeContext);

  const [number, setNumber] = useState<number>();

  return (
    <>
      <StyledInput
        disabled={!columnStatus}
        input={'number'}
        placeholder={'Enter number'}
        value={number || ''}
        onChange={(event, data) => setNumber(data.value)}
      />

      <FeatureButton
        isDisabled={!columnStatus || !number}
        action={() => {
          if (number) {
            addFormulaItem({
              type: FormulaOperandType.CONSTANT,
              value: { value: number },
            });
            setNumber(undefined);
          }
        }}
        size={FeatureButtonSize.WIDE}
        color={themeContext.colors.general.blue}
        text={'Add Number'}
      />
    </>
  );
};

export default NumberInputs;
