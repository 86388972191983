import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useForm from '../../../../hooks/form/UseForm';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchFormSuccess } from '../../../../store/project-form';
import {
  EndInputButtonWrapper,
  StageInner,
  StyledBodySubHeader,
  StyledFormHeader,
  StyledInput,
  StyledQuestionBuilderContainer,
  StyledTextArea,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';

export interface IntroType {
  title: string;
  body: string;
  buttonText?: string;
}

const StyledFormBuilderInput = styled(StyledInput)`
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const StyledFormTextArea = styled(StyledTextArea)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const Intro: FC<{ hideEditor: () => void }> = ({ hideEditor }) => {
  const dispatch = useDispatch();
  const { form, editForm } = useForm();
  const themeContext = useContext(ThemeContext);

  const [introState, setIntroState] = useState<IntroType>({
    title: '',
    body: '',
    buttonText: '',
  });
  const submitIntro = async () => {
    dispatch(
      showLoading({
        text: `Updating Form...`,
      }),
    );

    const cloned = cloneDeep(form);
    if (introState.buttonText && introState.buttonText?.length > 0) {
      cloned.intro = introState;
    } else {
      const newIntroState = { ...introState, buttonText: 'Get Started' };
      setIntroState(newIntroState);
      cloned.intro = newIntroState;
    }

    const updatedForm = await editForm(cloned);
    dispatch(fetchFormSuccess(updatedForm));
    dispatch(hideLoading());
  };

  useEffect(() => {
    if (form && form?.intro) {
      setIntroState(form?.intro);
    }
  }, [form]);

  return (
    <StyledQuestionBuilderContainer>
      <StageInner>
        <StyledFormHeader>Introduction</StyledFormHeader>
        <StyledBodySubHeader>Title</StyledBodySubHeader>
        <StyledFormBuilderInput
          placeholder="Title"
          value={introState.title}
          onChange={(_, data) =>
            setIntroState({ ...introState, title: data.value })
          }
        />
        <StyledBodySubHeader>Description</StyledBodySubHeader>
        <StyledFormTextArea
          minRows={5}
          maxRows={10}
          placeholder="Body"
          value={introState.body}
          onChange={(e) =>
            setIntroState({ ...introState, body: e.target.value })
          }
        />
        <StyledBodySubHeader>Start Form Button Text</StyledBodySubHeader>
        <StyledFormBuilderInput
          placeholder="Start Form Button Text"
          value={introState.buttonText}
          onChange={(_, data) =>
            setIntroState({
              ...introState,
              buttonText: data.value,
            })
          }
        />

        <EndInputButtonWrapper>
          <FeatureButton
            action={() => {
              setIntroState({ title: '', body: '', buttonText: '' });
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Cancel'}
            containerStyle={{ padding: `${themeContext.padding.standard} 0` }}
          />
          <FeatureButton
            action={async () => {
              await submitIntro();
              hideEditor();
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.blue}
            text={'Submit'}
            containerStyle={{ padding: `${themeContext.padding.standard} 0` }}
            isDisabled={!introState.title || !introState.body}
          />
        </EndInputButtonWrapper>
      </StageInner>
    </StyledQuestionBuilderContainer>
  );
};

export default Intro;
