import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PortalService, {
  PortalItemOutput,
  PortalItemsOutput,
  PortalOutputWithAdditionalData,
} from '../../services/portal/PortalService';
import { fetchPortal } from '../../store/project-portal';
import { RootState } from '../../store/rootReducer';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';
import useProject from '../project/UseProject';

interface usePortalResult {
  portal?: PortalOutputWithAdditionalData;
  portalAccessLevel?: Enums.AccessLevel;
  getPortals: (
    query?: Record<string, unknown>,
    internal?: true,
  ) => Promise<PortalItemsOutput>;
  getPortal: (portalId: string, internal?: boolean) => void;
  addPortal: (portalObj: Interfaces.Portal) => Promise<Interfaces.PortalOutput>;
  editPortal: (
    portalObj: Interfaces.PortalOutput,
  ) => Promise<Interfaces.PortalOutput>;
  removePortal: (portalId: string) => Promise<Interfaces.PortalOutput>;
}

const usePortal = (): usePortalResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { project } = useProject();
  const [portal, setPortal] = useState<Interfaces.PortalOutput>();
  const [portalAccessLevel, setPortalAccessLevel] =
    useState<Enums.AccessLevel>();

  const portalState = useSelector((state: RootState) => state.portal);
  const portalObj = portalState.data as PortalItemOutput;

  const getPortals = useCallback(
    async (
      query?: Record<string, unknown>,
      internal?: boolean,
    ): Promise<PortalItemsOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token) {
        result = await PortalService.getPortals(token, query, internal);
      }

      return result;
    },
    [getAccessTokenSilently],
  );

  const getPortal = useCallback(
    async (portalId: string, internal?: boolean) => {
      const token = await getAccessTokenSilently();

      if (token && portalId) {
        await dispatch(fetchPortal(token, portalId, internal));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const addPortal = useCallback(
    async (portalObj: Interfaces.Portal): Promise<Interfaces.PortalOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && portalObj && project?._id && loggedInUser) {
        result = await PortalService.postPortal(
          token,
          portalObj,
          project._id,
          loggedInUser._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  const editPortal = useCallback(
    async (
      portalObj: Interfaces.PortalOutput,
    ): Promise<Interfaces.PortalOutput> => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && portalObj && project?._id && loggedInUser) {
        result = await PortalService.putPortal(
          token,
          portalObj,
          project._id,
          loggedInUser._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  const removePortal = useCallback(
    async (portalId: string) => {
      const token = await getAccessTokenSilently();

      let result;
      if (token && portalId && loggedInUser) {
        result = await PortalService.deletePortal(
          token,
          portalId,
          loggedInUser._id,
          project?._id,
        );
      }

      return result;
    },
    [getAccessTokenSilently, loggedInUser, project?._id],
  );

  useEffect(() => {
    if (portalObj) {
      // Complete model
      setPortal(portalObj.entity);
      setPortalAccessLevel(portalObj.accessLevel);
    }
  }, [portalObj]);

  return {
    portal,
    portalAccessLevel,
    getPortals,
    getPortal,
    addPortal,
    editPortal,
    removePortal,
  };
};

export default usePortal;
