export enum ResourceType {
  API_CALLS = 'apiCalls',
  PIPELINE_RUNS = 'pipelineRuns',
  DATASET_METAS = 'datasetMetas',
  PROJECTS = 'projects',
  PORTALS = 'portals',
  APIS = 'apis',
  USERS = 'users',
  TEAMS = 'teams',
  SNAPSHOTS = 'snapshots',
  PIPELINES = 'pipelines',
  CUSTOM_PIPELINE_INTEGRATIONS = 'customPipelineIntegrations',
  DATA_POINTS = 'dataPoints',
}
