import { Enums } from '@configur-tech/upit-core-types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faCog,
  faImage,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { RouteName } from '../../enums';
import useUser from '../../hooks/user/UseUser';
import { BackNavigationItem, defaultTheme } from '../../main/theme';
import { RootState } from '../../store/rootReducer';
import {
  updateActiveUserStage,
  updateActiveUserSubStage,
} from '../../store/user-stage';
import {
  UserConfigureSubStage,
  UserCreationSubStage,
  UserStage,
  UserSubStages,
} from '../../store/user-stage/initial-state';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';
import {
  NavigationAccordion,
  NavigationAccordionContent,
  NavigationAccordionTitle,
  NavigationNavItem,
} from '../Navigation/styled';

const NavIcons = {
  [UserCreationSubStage.NAME]: faInfoCircle,
  [UserCreationSubStage.AVATAR]: faImage,
};

const UserItemNavigation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userAccessLevel, user } = useUser();
  const themeContext = useContext(ThemeContext);

  const canManage = userAccessLevel === Enums.AccessLevel.MANAGE;

  const userStageState = useSelector((state: RootState) => state.userStage);
  const activeStage = userStageState.activeStage;
  const activeSubStage = userStageState.stages[activeStage].activeSubStage;

  const [accordionGroups, setAccordionGroups] = useState<
    Record<string, boolean>
  >({ [UserStage.CONFIGURATION]: true });

  return (
    <>
      <BackNavigationItem
        className={'back'}
        key={`user-back`}
        onClick={() => history.push(`${RouteName.USERS}`)}
        isActive={false}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={defaultTheme.colors.system.white}
        />

        <NavTitle text={'Back To Users'} />
      </BackNavigationItem>

      {!canManage && (
        <NavItem
          key={`user-stage-${UserCreationSubStage.AVATAR}`}
          onClick={() => {
            dispatch(updateActiveUserStage(UserStage.CREATION));
            dispatch(updateActiveUserSubStage(UserCreationSubStage.AVATAR));
          }}
          isActive={activeSubStage === UserCreationSubStage.AVATAR}
        >
          <FontAwesomeIcon
            icon={NavIcons[UserCreationSubStage.AVATAR]}
            color={defaultTheme.colors.system.white}
          />

          <NavTitle
            text={
              userStageState.stages[UserStage.CREATION].subStages.find(
                (s) => s.subStage === UserCreationSubStage.AVATAR,
              )?.title || ''
            }
          />
        </NavItem>
      )}

      {canManage &&
        Object.values(userStageState.stages[UserStage.CREATION].subStages).map(
          (subStage) => {
            const user = subStage.subStage as UserSubStages;
            return (
              <NavItem
                key={`user-stage-${user}`}
                onClick={() => {
                  dispatch(updateActiveUserStage(UserStage.CREATION));
                  dispatch(updateActiveUserSubStage(user));
                }}
                isActive={activeSubStage === user}
              >
                <FontAwesomeIcon
                  icon={NavIcons[user]}
                  color={defaultTheme.colors.system.white}
                />

                <NavTitle text={subStage.title} />
              </NavItem>
            );
          },
        )}

      {/* Configuration accordion nav */}
      {user?._id && canManage && (
        <NavigationAccordion>
          <div key={`${UserStage.CONFIGURATION}-0`}>
            <NavigationAccordionTitle
              active={!accordionGroups[UserStage.CONFIGURATION]}
              index={0}
              onClick={() =>
                setAccordionGroups({
                  ...accordionGroups,
                  [UserStage.CONFIGURATION]:
                    !accordionGroups[UserStage.CONFIGURATION],
                })
              }
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <NavTitle text={UserStage.CONFIGURATION || 'Config'} />

                <FontAwesomeIcon
                  icon={
                    !accordionGroups[UserStage.CONFIGURATION]
                      ? faChevronUp
                      : faChevronDown
                  }
                  color={themeContext.colors.system.white}
                />
              </div>
            </NavigationAccordionTitle>

            <NavigationAccordionContent
              active={!accordionGroups[UserStage.CONFIGURATION]}
            >
              <div>
                <NavigationNavItem
                  onClick={() => {
                    dispatch(updateActiveUserStage(UserStage.CONFIGURATION));
                    dispatch(
                      updateActiveUserSubStage(UserConfigureSubStage.ADVANCED),
                    );
                  }}
                  isActive={activeSubStage === UserConfigureSubStage.ADVANCED}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    color={themeContext.colors.system.white}
                    style={{ marginRight: themeContext.margin.standard }}
                  />

                  <NavTitle
                    text={UserConfigureSubStage.ADVANCED}
                    isActive={true}
                  />
                </NavigationNavItem>
              </div>
            </NavigationAccordionContent>
          </div>
        </NavigationAccordion>
      )}
    </>
  );
};

export default UserItemNavigation;
