import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import ProjectModellingStage from '../../components/ProjectItem/ProjectItemModellingStage/ProjectItemModellingStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import Page from '../../main/Page';
import {
  getProjectStageContent,
  getProjectStageTitle,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectItemModelling: FC = () => {
  const activeStage = Enums.ProjectStage.MODELLING;
  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = getProjectStageContent(activeStage);

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        image={stageContent.image}
        title={stageContent.title}
      />

      <Page>
        <ProjectModellingStage />
      </Page>
    </>
  );
};

export default ProjectItemModelling;
