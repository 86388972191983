import React, { FC } from 'react';
import styled from 'styled-components';

export interface NavItemProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
  disabled?: boolean;
  className?: string;
  lightTheme?: boolean;
  children: React.ReactNode;
}

const Wrapper = styled.button<NavItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 46px;

  background: ${({ isActive, theme, lightTheme }) =>
    isActive
      ? lightTheme
        ? theme.colors.general.blue
        : theme.colors.system.darkBlueHighlight
      : lightTheme
      ? theme.colors.system.white
      : theme.colors.system.offBlack};

  padding: ${({ theme }) => theme.padding.standard}
    ${({ theme }) => theme.padding.large};

  outline: none;
  border: none;
  border-bottom: 1px solid
    ${({ theme, lightTheme }) =>
      lightTheme
        ? theme.colors.system.offWhite
        : theme.colors.system.darkOffBlack};

  transition: background 0.5s;

  > svg {
    width: 14px !important;
    margin-right: ${({ theme }) => theme.margin.standard};
  }

  ${({ disabled, theme, lightTheme, isActive }) =>
    disabled
      ? `opacity: 0.5`
      : !isActive
      ? `
        :hover {
          background: ${
            lightTheme
              ? theme.colors.system.grey
              : theme.colors.system.darkBlueHighlight
          };
          cursor: pointer;
        }
        `
      : ''}
`;

const NavItem: FC<NavItemProps> = ({
  onClick,
  isActive,
  disabled,
  children,
  className,
  lightTheme,
}) => {
  return (
    <Wrapper
      className={className}
      onClick={onClick}
      isActive={isActive}
      disabled={disabled}
      lightTheme={lightTheme}
    >
      {children}
    </Wrapper>
  );
};

export default NavItem;
