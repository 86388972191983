import { Enums } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NotificationService, {
  NotificationItemOutput,
  NotificationItemsOutput,
} from '../../services/notification/NotificationService';
import { AppThunk } from '../store';

export interface NotificationsState {
  read: {
    data: NotificationItemOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  unread: {
    data: NotificationItemOutput[];
    pagination: {
      currentPageNum: number;
      nextPageNum: number | null;
      prevPageNum: number | null;
      totalCount: number;
      totalPages: number;
    };
  };
  loading: boolean;
  error: string | null;
}

const initialState: NotificationsState = {
  read: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  unread: {
    data: [],
    pagination: {
      currentPageNum: 1,
      nextPageNum: null,
      prevPageNum: null,
      totalCount: 0,
      totalPages: 0,
    },
  },
  loading: false,
  error: null,
};

const NotificationsSlice = createSlice({
  name: 'notification-events',
  initialState,
  reducers: {
    // Fetch Read Notifications
    fetchReadNotificationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchReadNotificationsSuccess(
      state,
      action: PayloadAction<NotificationItemsOutput>,
    ) {
      state.read = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchReadNotificationsFailure(state, action: PayloadAction<string>) {
      state.read = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },
    // Fetch Unread Notifications
    fetchUnreadNotificationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUnreadNotificationsSuccess(
      state,
      action: PayloadAction<NotificationItemsOutput>,
    ) {
      state.unread = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUnreadNotificationsFailure(state, action: PayloadAction<string>) {
      state.unread = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateNotificationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateNotificationsSuccess(state) {
      state.unread = {
        data: [],
        pagination: {
          currentPageNum: 1,
          prevPageNum: null,
          nextPageNum: null,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.loading = false;
      state.error = null;
    },
    updateNotificationsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchReadNotificationsStart,
  fetchReadNotificationsSuccess,
  fetchReadNotificationsFailure,
  fetchUnreadNotificationsStart,
  fetchUnreadNotificationsSuccess,
  fetchUnreadNotificationsFailure,
  updateNotificationsStart,
  updateNotificationsSuccess,
  updateNotificationsFailure,
} = NotificationsSlice.actions;

export default NotificationsSlice.reducer;

export const fetchNotifications =
  (token: string, query?: Record<string, unknown>): AppThunk =>
  async (dispatch) => {
    if (query && query.readStatus === Enums.NotificationReadStatus.READ) {
      try {
        dispatch(fetchReadNotificationsStart());
        const fetched = await NotificationService.getNotifications(
          token,
          query,
        );

        dispatch(
          fetchReadNotificationsSuccess(
            fetched.data as unknown as NotificationItemsOutput,
          ),
        );
      } catch (err) {
        dispatch(fetchReadNotificationsFailure((err as string).toString()));
      }
    } else {
      try {
        const fetched = await NotificationService.getNotifications(
          token,
          query,
        );

        dispatch(
          fetchUnreadNotificationsSuccess(
            fetched.data as unknown as NotificationItemsOutput,
          ),
        );
      } catch (err) {
        dispatch(fetchUnreadNotificationsFailure((err as string).toString()));
      }
    }
  };

export const updateUnreadNotificationToRead =
  (token: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateNotificationsStart());
      await NotificationService.markAllAsRead(token, userId);
      dispatch(updateNotificationsSuccess());
    } catch (err) {
      dispatch(updateNotificationsFailure((err as string).toString()));
    }
  };
