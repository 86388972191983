import { Interfaces } from '@configur-tech/upit-core-types';
import { isEmpty, startCase } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import usePortal from '../../hooks/portal/UsePortal';
import useQuery from '../../hooks/query/UseQuery';
import { SampleDataRow } from '../../interfaces/SampleData';
import { StageBodyText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading';
import {
  updateActivePortalStage,
  updateActivePortalSubStage,
} from '../../store/portal-stage';
import {
  PortalAggregationSubStage,
  PortalStage,
} from '../../store/portal-stage/initial-state';
import { fetchQuerySuccess, resetQuery } from '../../store/query';
import { RootState } from '../../store/rootReducer';
import DataSample from '../DataSample/DataSample';
import FeatureButton, {
  FeatureButtonSize,
} from '../FeatureButton/FeatureButton';

const PAGE_SIZE = 25;
const ROW_NUMBER = 'row_id';
const ID_FIELD = '_id';
const NAME_FIELD = 'Name';
const DESCRIPTION_FIELD = 'Description';
const TYPE_FIELD = 'Query Type';
const ORDER_BY_PARAM = 'orderBy';

const TABLE_SCHEMA = [
  { name: NAME_FIELD },
  { name: DESCRIPTION_FIELD },
  { name: TYPE_FIELD },
];

enum SortTypes {
  'Name' = 'name',
  'Description' = 'description',
  'Query Type' = 'queryParams.aggregationType',
}

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const PortalAggregationMain: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { portal } = usePortal();
  const { getQueries } = useQuery();
  const { loggedInUser } = useLoggedInUser();

  const queriesState = useSelector((state: RootState) => state.queries);
  const queries = queriesState.data;

  const [tableData, setTableData] = useState<SampleDataRow[]>([]);
  const [queriesLoaded, setQueriesLoaded] = useState<boolean>(false);
  const [pagination, setPagination] = useState(0);
  const [sortBy, setSortBy] = useState<Record<string, number>>();

  const updateSortBy = useCallback(
    (sortItem: { id: string; desc: boolean }) => {
      if (isEmpty(sortItem)) {
        setSortBy(undefined);
        return;
      }

      const sortProperty = SortTypes[sortItem.id];

      switch (sortItem.desc) {
        case true:
          setSortBy({ [sortProperty]: -1 });
          break;
        case false:
          setSortBy({ [sortProperty]: 1 });
          break;
      }
    },
    [],
  );

  const updatePagination = useCallback((page: number) => {
    setPagination(page);
  }, []);

  const calculateRowNumber = useCallback(
    (rowIndex) => {
      const displayedRowIndex = rowIndex + 1;
      const initialRowIndex = (pagination - 1) * PAGE_SIZE;

      return (displayedRowIndex + initialRowIndex).toString();
    },
    [pagination],
  );

  // Fetch queries
  useEffect(() => {
    (async () => {
      if (portal?._id && loggedInUser?._id) {
        setQueriesLoaded(false);
        dispatch(showLoading({ text: 'Loading Queries...' }));
        const queryParams = {
          'meta.createdBy': loggedInUser?._id,
          portalId: portal?._id,
          pageNum: pagination,
          limit: PAGE_SIZE,
        };

        if (!isEmpty(sortBy)) {
          queryParams[ORDER_BY_PARAM] = sortBy;
        }

        await getQueries(queryParams);
        setQueriesLoaded(true);
      }
    })();
  }, [
    dispatch,
    getQueries,
    loggedInUser?._id,
    pagination,
    portal?._id,
    sortBy,
  ]);

  // Set table
  useEffect(() => {
    if (queries) {
      const tableData: SampleDataRow[] = [];

      queries?.data.map((query, index) => {
        tableData.push({
          [ROW_NUMBER]: { value: calculateRowNumber(index) },
          [ID_FIELD]: { value: (query.entity as Interfaces.QueryOutput)._id },
          [NAME_FIELD]: { value: query.entity.name },
          [DESCRIPTION_FIELD]: { value: query.entity.description || '' },
          [TYPE_FIELD]: {
            value: `${startCase(
              query.entity.queryParams.aggregationType,
            )} Dataset`,
          },
        });
      });

      setTableData(tableData);
    }
  }, [calculateRowNumber, queries]);

  // Hide loader
  useEffect(() => {
    if (queriesLoaded) {
      dispatch(hideLoading());
    }
  }, [dispatch, queriesLoaded]);

  return (
    <PageInner>
      <StageBodyText>
        Query your datasets to create subsets and aggregations to unlock even
        more from your data.
      </StageBodyText>

      <FeatureButton
        action={() => {
          dispatch(resetQuery());
          dispatch(updateActivePortalStage(PortalStage.AGGREGATION));
          dispatch(updateActivePortalSubStage(PortalAggregationSubStage.NAME));
        }}
        size={FeatureButtonSize.WIDE}
        color={themeContext.colors.general.blue}
        text={'Add Query'}
        containerStyle={{ marginBottom: themeContext.margin.large }}
      />

      <TableWrapper>
        <DataSample
          showPagination={true}
          paginationAction={updatePagination}
          sortAction={updateSortBy}
          pageSize={PAGE_SIZE}
          totalRows={queries?.pagination?.totalCount}
          hideSortOnCols={[ROW_NUMBER]}
          sampleColumns={TABLE_SCHEMA}
          sampleRows={tableData}
          clickableRows={{
            valueField: ID_FIELD,
            action: (queryId: string) => {
              const query = queries?.data.find((c) => c.entity._id === queryId);

              if (query) {
                dispatch(fetchQuerySuccess(query));
                dispatch(updateActivePortalStage(PortalStage.AGGREGATION));
                dispatch(
                  updateActivePortalSubStage(
                    PortalAggregationSubStage.AGGREGATE,
                  ),
                );
              }
            },
          }}
        />
      </TableWrapper>
    </PageInner>
  );
};

export default PortalAggregationMain;
