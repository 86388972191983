import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import {
  DuplicationMode,
  DuplicationTarget,
} from '@configur-tech/upit-core-types/lib/enums/ResourceDuplication';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DuplicationService, {
  DatasetCopyConfig,
  DatasetDuplicationResponse,
} from '../../services/duplication/DuplicationService';
import { fetchDatasetMetaSuccess } from '../../store/dataset-meta';
import { hideLoading } from '../../store/loading';
import { hideModal } from '../../store/modal';

interface useDuplicationResult {
  duplicateDataset: (
    duplicationMode: DuplicationMode,
    duplicationTarget: DuplicationTarget,
    datasetCopyConfig?: DatasetCopyConfig,
  ) => Promise<DatasetDuplicationResponse | undefined>;
}

const useDuplication = (): useDuplicationResult => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const duplicateDataset = useCallback(
    async (
      duplicationMode: DuplicationMode,
      duplicationTarget: DuplicationTarget,
      datasetCopyConfig?: DatasetCopyConfig,
    ) => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          const duplication = await DuplicationService.duplicateDataset(
            token,
            duplicationMode,
            duplicationTarget,
            datasetCopyConfig,
          );

          toast.success('Dataset duplicated succesfully.');

          dispatch(
            fetchDatasetMetaSuccess({
              entity: duplication.datasetMeta,
              accessLevel: Enums.AccessLevel.MANAGE,
            }),
          );

          return duplication;
        } catch (err) {
          dispatch(hideLoading());
          dispatch(hideModal());

          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to duplicate dataset.`,
          );
        }
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  return {
    duplicateDataset,
  };
};

export default useDuplication;
