import { Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { ProjectStage } from '../../../enums';
import useAggregation from '../../../hooks/aggregation/UseAggregation';
import useGraph from '../../../hooks/graph/UseGraph';
import useQuery from '../../../hooks/query/UseQuery';
import {
  StyledBodySubHeader,
  StyledInput,
  StyledText,
} from '../../../main/theme';
import { GraphOutputWithDetails } from '../../../store/graph';
import { hideModal } from '../../../store/modal';
import { QueryOutputWithDetails } from '../../../store/queries';
import { RootState } from '../../../store/rootReducer';
import namesRequiresCleansing from '../../../util/column-name/CleanseColumnName';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

export interface AggregationColumnAliasModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fieldName: string;
  allColumns: Interfaces.Field[];
}

const AggregationColumnAliasModal: FC<AggregationColumnAliasModalProps> = ({
  setShowModal,
  fieldName,
  allColumns,
}) => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { query } = useQuery();
  const { graph } = useGraph();

  const { updateGraphColumnAlias, updateQueryColumnAlias } = useAggregation();

  const [alreadyExists, setAlreadyExists] = useState<number[]>([]);
  const [invalidNames, setInvalidNames] = useState<number[]>([]);
  const [showAllFields, setShowAllFields] = useState<boolean>(false);

  const [clonedQuery, setClonedQuery] = useState<
    QueryOutputWithDetails | GraphOutputWithDetails | undefined
  >();
  const [clonedAllColumns, setClonedAllColumns] =
    useState<Interfaces.Field[]>();

  const querySchemaFields = (
    projectStageState.activeStage === ProjectStage.AGGREGATION ? query : graph
  )?.additionalDetails
    ?.map((detail) => detail.schemaData)
    .flat()
    .reduce((acc, val) => [...acc, val.name], [] as string[]);

  // Clone query and columns for editing
  useEffect(() => {
    switch (projectStageState.activeStage) {
      case ProjectStage.GRAPH: {
        const cloned = cloneDeep(graph);
        setClonedQuery(cloned);
        break;
      }
      case ProjectStage.AGGREGATION: {
        const cloned = cloneDeep(query);
        setClonedQuery(cloned);
        break;
      }
    }

    setClonedAllColumns(allColumns);
  }, [allColumns, graph, projectStageState.activeStage, query]);

  const handleAliasChange = (
    newValue: string,
    originalValue: string,
    columnIndex: number,
  ) => {
    const updatedData = cloneDeep(clonedAllColumns);
    updatedData[columnIndex] = {
      ...updatedData[columnIndex],
      name: newValue,
    };

    setClonedAllColumns(updatedData);

    // Disallow entering dupicate names
    const isDupe =
      newValue !== allColumns[columnIndex].name
        ? !!clonedAllColumns?.map((c) => c.name)?.some((f) => f === newValue) ||
          !!querySchemaFields?.some((f) => f === newValue)
        : false;

    if (isDupe) {
      setAlreadyExists([...alreadyExists, columnIndex]);
    } else {
      const duplicatedValIndex = clonedAllColumns?.findIndex(
        (c) => c.name === originalValue,
      );

      const filteredArray = alreadyExists.filter(
        (o) => o !== duplicatedValIndex && o !== columnIndex,
      );
      setAlreadyExists(filteredArray);
    }
  };

  // Check column name doesn't contain special characters
  useEffect(() => {
    const tempInvalidNames: number[] = [];
    clonedAllColumns?.map((c, i) => {
      if (namesRequiresCleansing(c.name)) {
        tempInvalidNames.push(i);
      }
    });
    setInvalidNames(tempInvalidNames);
  }, [clonedAllColumns]);

  const processAction = async () => {
    if (!clonedQuery || !clonedAllColumns) {
      return;
    }

    switch (projectStageState.activeStage) {
      case ProjectStage.GRAPH: {
        const updatedQuery = await updateGraphColumnAlias(
          clonedQuery as GraphOutputWithDetails,
          clonedAllColumns,
          allColumns,
        );
        setClonedQuery(updatedQuery);
        break;
      }
      case ProjectStage.AGGREGATION: {
        const updatedQuery = await updateQueryColumnAlias(
          clonedQuery as QueryOutputWithDetails,
          clonedAllColumns,
          allColumns,
        );
        setClonedQuery(updatedQuery);
        break;
      }
    }

    dispatch(hideModal());
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const selectedColumnIndex = allColumns.findIndex((c) => c.name === fieldName);

  return (
    <SC.Wrapper>
      <SC.HeaderWrapper>
        <SC.Header>Update Column {showAllFields ? 'Names' : 'Name'}</SC.Header>
      </SC.HeaderWrapper>

      <SC.ContentContainer>
        <SC.Content>
          <StyledText>
            {showAllFields
              ? `If you'd like to update these column names, simply type your desired
              names in the boxes below.`
              : `If you'd like to update this column's name, simply type your desired
            name in the box below.`}
          </StyledText>

          {clonedAllColumns
            ?.filter(
              !showAllFields
                ? (o, i) =>
                    allColumns[i].name === allColumns[selectedColumnIndex].name
                : () => true,
            )
            .map((field, i) => (
              <SC.ColumnWrapper>
                <StyledBodySubHeader>
                  {!showAllFields
                    ? allColumns[selectedColumnIndex].name
                    : allColumns[i].name}
                </StyledBodySubHeader>
                <StyledInput
                  placeholder={'Enter a column name'}
                  value={field.name || ''}
                  onChange={(e, data) =>
                    handleAliasChange(
                      data.value,
                      field.name,
                      showAllFields ? i : selectedColumnIndex,
                    )
                  }
                />
                {alreadyExists.includes(
                  !showAllFields ? selectedColumnIndex : i,
                ) && (
                  <StyledText
                    style={{
                      marginTop: themeContext.margin.standard,
                      marginBottom: 0,
                    }}
                  >
                    A column with this name already exists
                  </StyledText>
                )}

                {invalidNames.includes(
                  !showAllFields ? selectedColumnIndex : i,
                ) && (
                  <StyledText
                    style={{
                      marginTop: themeContext.margin.standard,
                      marginBottom: 0,
                    }}
                  >
                    Column name contains invalid characters
                  </StyledText>
                )}
              </SC.ColumnWrapper>
            ))}

          {!showAllFields && (
            <FeatureButton
              action={() => {
                setShowAllFields(!showAllFields);
              }}
              size={FeatureButtonSize.WIDE_SMALL}
              style={{ marginTop: themeContext.margin.standard }}
              color={themeContext.colors.general.blue}
              text={'Show all columns'}
            />
          )}
        </SC.Content>
      </SC.ContentContainer>

      <SC.ActionButtonWrapper>
        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.sea}
          text={'Cancel'}
        />
        <FeatureButton
          isDisabled={
            !!clonedAllColumns?.filter((c) => !c.name.length).length ||
            !!invalidNames.length ||
            !!alreadyExists.length
          }
          action={processAction}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={`Save ${showAllFields ? 'Names' : 'Name'}`}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default AggregationColumnAliasModal;
