import { Interfaces } from '@configur-tech/upit-core-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import useIntegrationTemplate from '../../../../hooks/integration-template/UseIntegrationTemplate';
import {
  defaultTheme,
  StyledBodySubHeader,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import { initialBaseEndpoint } from '../../../../store/integration-template/inital-state';
import { hideLoading, showLoading } from '../../../../store/loading';
import { hideModal } from '../../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import EndpointParamsAccordion from '../../../IntegrationItemStages/IntegrationCreationStages/4-endpoints/EndpointParamsAccordion';
import * as SC from './styled';
import { isEmpty, startCase } from 'lodash';

export interface IntegrationEndpointMainModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  integrationTemplate: Interfaces.IntegrationTemplate;
  endpointIndex: number;
}

const IntegrationEndpointMainModal: FC<IntegrationEndpointMainModalProps> = ({
  setShowModal,
  endpointIndex,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { integrationTemplate, editIntegrationTemplate } =
    useIntegrationTemplate();

  const [paramsState, setParamsState] = useState<Interfaces.RequestParams>({});
  const [paginationParamsState, setPaginationParamsState] =
    useState<Interfaces.PaginationParams>();
  const [generalRowInputs, setGeneralRowInputs] =
    useState<Interfaces.IntegrationEndpoint>(initialBaseEndpoint);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  // Handle General Settings Row
  const handleGeneralInputChange = (field: string, value: string) => {
    const cloned = cloneDeep(generalRowInputs);
    cloned[field] = value;
    setGeneralRowInputs(cloned);
  };

  // Set Values for Editing Endpoint
  useMemo(() => {
    if (endpointIndex || endpointIndex == 0) {
      const queryIndex = endpointIndex.toString();
      if (integrationTemplate?.endpoints[queryIndex]) {
        setGeneralRowInputs(integrationTemplate?.endpoints[queryIndex]);
      }

      if (integrationTemplate?.endpoints[queryIndex]?.requestParams) {
        setParamsState(
          integrationTemplate?.endpoints[queryIndex].requestParams,
        );
      }
      if (integrationTemplate?.endpoints[queryIndex]?.paginationParams) {
        setPaginationParamsState(
          integrationTemplate?.endpoints[queryIndex].paginationParams,
        );
      }
    }
  }, [integrationTemplate, endpointIndex]);

  // Handle Params change
  const handleParamsChange = useCallback((params: Interfaces.RequestParams) => {
    setParamsState(params);
  }, []);

  // Handle Pagination Params change
  const handlePaginationChange = useCallback(
    (params?: Interfaces.PaginationParams) => {
      setPaginationParamsState(params);
    },
    [],
  );

  // Handle Delete
  const deleteEndpoint = async () => {
    const cloned = cloneDeep(integrationTemplate);
    cloned.endpoints.splice(endpointIndex, 1);
    await editIntegrationTemplate(cloned);
    dispatch(hideModal());
  };

  // Disable Button if Fields not updated
  const configurationComplete = (): boolean => {
    const paginationValid =
      !paginationParamsState ||
      (!!paginationParamsState.pageBasedPaginationParams?.initialPageNum &&
        !!paginationParamsState?.pageBasedPaginationParams?.pageSize &&
        ((!!paginationParamsState?.pageParam?.headers &&
          !!paginationParamsState?.pageParam?.headers[0]?.field) ||
          (!!paginationParamsState?.pageParam?.pathParams &&
            !!paginationParamsState?.pageParam?.pathParams[0]?.field) ||
          (!!paginationParamsState?.pageParam?.queryParams &&
            !!paginationParamsState?.pageParam?.queryParams[0]?.field)));

    const validGeneral =
      !!(
        generalRowInputs &&
        generalRowInputs.name &&
        generalRowInputs.baseUrl
      ) && paginationValid;

    const validParams =
      !paramsState ||
      Object.values(paramsState).every(
        (paramArr) =>
          !Array.isArray(paramArr) ||
          paramArr.every(
            (param) => param.field?.length && param.value?.value?.length,
          ),
      );

    return !(validGeneral && validParams);
  };
  // Handle Submission
  const processAction = async () => {
    const cloned: Interfaces.IntegrationTemplateOutput =
      cloneDeep(integrationTemplate);

    // Update Endpoint
    if (endpointIndex || endpointIndex == 0) {
      cloned.endpoints[endpointIndex] = {
        ...cloned.endpoints[endpointIndex],
        name: generalRowInputs.name,
        description: generalRowInputs.description,
        baseUrl: generalRowInputs.baseUrl,
        requestParams: paramsState,
        paginationParams: paginationParamsState,
      };
    }
    // Handle Creating new endpoint
    else {
      cloned.endpoints.push({
        ...generalRowInputs,
        requestParams: paramsState,
        paginationParams: paginationParamsState,
      } as Interfaces.IntegrationEndpointOutput);
    }

    dispatch(showLoading({ text: 'Saving Endpoint...' }));
    await editIntegrationTemplate(cloned);
    dispatch(hideLoading());
    dispatch(hideModal());
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.Header>Manage Base Endpoint</SC.Header>

        <FeatureButton
          action={() => dispatch(hideModal())}
          size={FeatureButtonSize.EXTRA_SMALL}
          color={themeContext.colors.general.sea}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              color={defaultTheme.colors.system.white}
              size={'lg'}
            />
          }
        />
      </SC.HeaderContainer>
      <SC.ContentContainer>
        <SC.Content>
          <StyledSubHeader
            style={{
              marginBottom: themeContext.margin.standard,
              marginTop: themeContext.margin.xlarge,
            }}
          >
            General
          </StyledSubHeader>
          <StyledText
            style={{
              marginBottom: themeContext.margin.xxlarge,
            }}
          >
            First, name your endpoint and tell us what the base URL for the
            integration is.
          </StyledText>

          <SC.InputWrapper>
            <SC.InputContainer>
              <StyledBodySubHeader>Name</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a name'}
                value={generalRowInputs?.name || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('name', value)
                }
              />
            </SC.InputContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Description</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a description'}
                value={generalRowInputs?.description || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('description', value)
                }
              />
            </SC.InputContainer>
            <SC.InputContainer>
              <StyledBodySubHeader>Base URL</StyledBodySubHeader>
              <StyledInput
                placeholder={'Enter a base URL'}
                value={generalRowInputs?.baseUrl || ''}
                onChange={(e, { value }) =>
                  handleGeneralInputChange('baseUrl', value)
                }
              />
            </SC.InputContainer>
          </SC.InputWrapper>

          <EndpointParamsAccordion
            onChange={handleParamsChange}
            onChangePagination={handlePaginationChange}
            paramsData={paramsState}
            paginationParamsData={paginationParamsState}
          />
        </SC.Content>
      </SC.ContentContainer>
      <SC.ActionButtonWrapper multipleButtons={endpointIndex !== undefined}>
        {endpointIndex !== undefined && (
          <FeatureButton
            action={deleteEndpoint}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.red}
            text={'Delete'}
          />
        )}
        <FeatureButton
          action={processAction}
          isDisabled={configurationComplete()}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Save'}
        />
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default IntegrationEndpointMainModal;
