import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { SupercolumnField } from '@configur-tech/upit-core-types/lib/interfaces';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import AggregationDisplayOrder from '../../components/Aggregation/AggregationDisplayOrder';
import AggregationGroup from '../../components/Aggregation/AggregationGroup';
import AggregationMeasure from '../../components/Aggregation/AggregationMeasure';
import AggregationSort, {
  SortItem,
} from '../../components/Aggregation/AggregationSort';
import AggregationSupercolumn from '../../components/Aggregation/AggregationSupercolumn';
import AggregationFilter from '../../components/Aggregation/filter/AggregationFilter';
import NavTag, { NavTagType } from '../../components/Navigation/NavTag';
import { AggregateStage } from '../../components/ProjectItem/ProjectItemAggregationStage/3-aggregate/ProjectItemAggregationAggregateStage';
import * as SC from '../../components/ProjectItem/ProjectItemAggregationStage/3-aggregate/styled';
import { EntityType } from '../../enums';
import {
  StyledAccordionTitle,
  StyledBodySubHeader,
  defaultTheme,
} from '../../main/theme';
import useGraph from '../graph/UseGraph';
import useGraphBuilder from '../graph/useGraphBuilder';
import useProject from '../project/UseProject';

interface useGraphBuilderAccordionResult {
  buildAccordionPanel: (
    stage: AggregateStage,
    index: number,
  ) => Record<string, unknown> | null;
}

const useGraphBuilderAccordion = (): useGraphBuilderAccordionResult => {
  const { graph } = useGraph();
  const {
    graphOptions,
    handleGroupChange,
    handleSortChange,
    handleDisplayOrderChange,
  } = useGraphBuilder();
  const { projectAccessLevel } = useProject();
  const isProjectManager = projectAccessLevel === Enums.AccessLevel.MANAGE;

  const [accordionStage, setAccordionStage] = useState<number[]>([
    0, 1, 2, 3, 4, 5,
  ]);

  const buildAccordionPanel = useCallback(
    (stage: AggregateStage, index: number): Record<string, unknown> | null => {
      if (
        !graph?.queryParams?.datasetMetaId &&
        !graph?.queryParams?.dataModelLinkIds?.length
      ) {
        return null;
      }

      let content;
      switch (stage) {
        case AggregateStage.FILTER:
          content = (
            <SC.AggregateSection>
              <AggregationFilter
                fields={
                  graphOptions.fieldItems as unknown as Interfaces.FieldOutput[]
                }
                graphId={graph._id}
              />
            </SC.AggregateSection>
          );
          break;

        case AggregateStage.GROUP: {
          content = (
            <SC.AggregateSection>
              <div>
                <SC.GroupWrapper>
                  <AggregationGroup
                    groupItems={graphOptions.groupItems}
                    onChange={handleGroupChange}
                    disabled={!isProjectManager}
                  />
                </SC.GroupWrapper>
              </div>
            </SC.AggregateSection>
          );
          break;
        }

        case AggregateStage.MEASURE:
          content = (
            <SC.AggregateSection>
              <SC.MeasureWrapper>
                <AggregationMeasure
                  measureItems={graph.queryParams?.measures || []}
                  isMultipleDatasetQuery={
                    graph?.queryParams?.aggregationType ===
                    Enums.AggregationType.MULTIPLE
                  }
                  entityType={EntityType.GRAPH}
                />
              </SC.MeasureWrapper>
            </SC.AggregateSection>
          );
          break;

        case AggregateStage.SORT:
        case AggregateStage.DISPLAY_ORDER: {
          const activeItems =
            ((stage === AggregateStage.SORT
              ? graph.queryParams.sort
              : graph.queryParams.displayOrder
            )?.map((item) => ({ ...item, active: true })) as SortItem[]) || [];

          const allItems = (
            stage === AggregateStage.SORT
              ? graphOptions.sortItems
              : graphOptions.displayOrderItems
          )
            .concat(
              graph?.queryParams?.measures?.length
                ? graph.queryParams.measures.map((measure) => {
                    const measureItem = {
                      field: measure.field,
                      alias:
                        measure.alias || `${measure.field}_${measure.measure}`,
                      datasetMetaId: measure.datasetMetaId,
                      direction: Enums.ProjectionSortOrder.ASCENDING,
                    } as SortItem;

                    if (stage === AggregateStage.SORT) {
                      measureItem.isMeasure = true;
                    }

                    return measureItem;
                  })
                : [],
            )
            .concat(
              graph.queryParams.supercolumns
                ? graph?.queryParams.supercolumns.map((supercolumn) => {
                    const supercolumnItem = {
                      field: supercolumn.field,
                      alias: supercolumn.alias || `${supercolumn.field}`,
                      datasetMetaId: supercolumn.datasetMetaId,
                      direction: Enums.ProjectionSortOrder.ASCENDING,
                    } as SortItem;

                    return supercolumnItem;
                  })
                : [],
            );

          const uniqueItems = activeItems.concat(
            allItems.filter(
              (item) =>
                !activeItems.find(
                  (activeItem) => activeItem.alias === item.alias,
                ),
            ),
          );

          const cleanDisplay = uniqueItems.filter((item) => {
            // remove supercolumns
            if (graph?.queryParams?.groups?.length) {
              // Check if measure or active group
              return (
                !!graph?.queryParams?.measures?.find(
                  (m) => m.alias === item.alias,
                ) ||
                !!graph.queryParams.groups.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                ) ||
                !!graph?.queryParams?.supercolumns?.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                )
              );
            }
            return true;
          });

          const cleanSort = uniqueItems.filter((item) => {
            if (graph?.queryParams?.groups?.length) {
              // Check if measure or active group
              return (
                !!graph?.queryParams?.measures?.find(
                  (m) => m.alias === item.alias,
                ) ||
                !!graph.queryParams.groups.find(
                  (so) =>
                    so.datasetMetaId === item.datasetMetaId &&
                    so.alias === item.alias,
                )
              );
            }
            // return all fields besides supercolumns
            return !graph?.queryParams?.supercolumns?.find(
              (so) =>
                so.datasetMetaId === item.datasetMetaId &&
                so.alias === item.alias,
            );
          });

          content =
            stage === AggregateStage.SORT ? (
              <SC.AggregateSection>
                <div>
                  <SC.SortWrapper>
                    <AggregationSort
                      sortItems={cleanSort}
                      onChange={handleSortChange}
                      disabled={
                        !isProjectManager ||
                        graph.queryParams.includeSubtotals ||
                        graph.queryParams.includeGrandTotals
                      }
                    />
                  </SC.SortWrapper>
                </div>
              </SC.AggregateSection>
            ) : (
              <SC.AggregateSection>
                <div>
                  <SC.DisplayOrderWrapper>
                    <AggregationDisplayOrder
                      displayOrderItems={cleanDisplay}
                      onChange={handleDisplayOrderChange}
                      disabled={!isProjectManager}
                    />
                  </SC.DisplayOrderWrapper>
                </div>
              </SC.AggregateSection>
            );
          break;
        }
        case AggregateStage.SUPERCOLUMN:
          content = (
            <SC.AggregateSection>
              <SC.MeasureWrapper>
                <AggregationSupercolumn
                  supercolumnItems={
                    (graph.queryParams.supercolumns as SupercolumnField[]) || []
                  }
                  isMultipleDatasetQuery={
                    graph?.queryParams?.aggregationType ===
                    Enums.AggregationType.MULTIPLE
                  }
                  entityType={EntityType.GRAPH}
                />
              </SC.MeasureWrapper>
            </SC.AggregateSection>
          );
          break;
      }

      return {
        key: `aggregate-panel-${stage}-${index}`,
        title: (
          <StyledAccordionTitle
            onClick={() => {
              let cloned = cloneDeep(accordionStage);
              if (accordionStage.includes(index)) {
                cloned = cloned.filter((e) => e !== index);
              } else {
                cloned.push(index);
              }
              setAccordionStage(cloned);
            }}
          >
            <StyledBodySubHeader>{stage}</StyledBodySubHeader>
            {stage === AggregateStage.SUPERCOLUMN && (
              <NavTag type={NavTagType.BETA} />
            )}
            <FontAwesomeIcon
              icon={
                accordionStage.includes(index) ? faChevronUp : faChevronDown
              }
              color={defaultTheme.colors.system.offBlack}
            />
          </StyledAccordionTitle>
        ),
        content: (
          <SC.FilterAccordionContent>
            <div>{content}</div>
          </SC.FilterAccordionContent>
        ),
      };
    },
    [
      accordionStage,
      handleDisplayOrderChange,
      handleGroupChange,
      handleSortChange,
      isProjectManager,
      graph?._id,
      graph?.queryParams?.aggregationType,
      graph?.queryParams?.dataModelLinkIds?.length,
      graph?.queryParams?.datasetMetaId,
      graph?.queryParams.displayOrder,
      graph?.queryParams.groups,
      graph?.queryParams.includeGrandTotals,
      graph?.queryParams.includeSubtotals,
      graph?.queryParams.measures,
      graph?.queryParams.sort,
      graph?.queryParams.supercolumns,
      graphOptions.displayOrderItems,
      graphOptions.fieldItems,
      graphOptions.groupItems,
      graphOptions.sortItems,
    ],
  );

  return {
    buildAccordionPanel,
  };
};

export default useGraphBuilderAccordion;
