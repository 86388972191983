import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import {
  ListType,
  ValueDataType,
} from '@configur-tech/upit-core-types/lib/enums';
import { Field } from '@configur-tech/upit-core-types/lib/interfaces';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, set, startCase } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import { DropdownItemProps, Popup } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../enums/EntityType';
import { initialDatasetStageValidation } from '../../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../../hooks/dataset-meta/UseDatasetMeta';
import useList from '../../../hooks/list/UseList';
import {
  DefaultPopupStyles,
  defaultTheme,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdown,
  StyledDropdownUltraWide,
  StyledInput,
  StyledSubHeader,
  StyledText,
} from '../../../main/theme';
import {
  ListItemOutput,
  UserListOutput,
} from '../../../services/list/ListService';
import { hideLoading, showLoading } from '../../../store/loading';
import { hideModal } from '../../../store/modal';
import { RootState } from '../../../store/rootReducer';
import CheckColumnUse from '../../../util/check-column-use/CheckColumnUse';
import namesRequiresCleansing from '../../../util/column-name/CleanseColumnName';
import UserIconMap from '../../../util/icon-helpers/UserIconMap';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import { DropdownLabel } from '../../styled';
import AdditionalValidationOptions from './OptionPanels/AdditionalValidationOptions';
import FormattingOptions from './OptionPanels/FormattingOptions';
import PermissionOptions from './OptionPanels/PermissionOptions';
import * as SC from './styled';

const STAGE_ONE = 1;
const STAGE_DELETE = 2;

const DATA_VALIDATION_CONSTRAINTS = 'dataValidation.constraints';
const DEFAULT_VALUE_FIELD = 'defaultValue';
const UNIQUE_FIELD = 'isUnique';
const MINIMUM_FIELD = 'min';
const MAXIMUM_FIELD = 'max';
const INTEGER_FIELD = 'isInt';
const INCREMENT_FIELD = 'isAutoInc';
const DATE_FORMAT_FIELD = 'format';
const LIST_VALUES_FIELD = 'listValues';
const DEFAULT_LOCALE = 'GB';

export interface ValidationDataTypeModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  field?: string;
  type?: Enums.ValueDataType;
  dataCollectionId: string;
}

const ErrorText = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.general.red};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

const dataTypeDropdownOptions = Object.values(Enums.ValueDataType)
  .filter(
    (i) =>
      ![
        Enums.ValueDataType.UNKNOWN,
        Enums.ValueDataType.EMPTY,
        Enums.ValueDataType.FORMULA,
        Enums.ValueDataType.TEXT_TRANSFORMATION,
        Enums.ValueDataType.DATE_CONVERSION,
        Enums.ValueDataType.FIELD_LOOKUP,
      ].includes(i),
  )
  .map((i) => {
    return {
      key: i,
      text: startCase(i.toLowerCase()),
      value: i,
    };
  });

const dateFormatDropdownOptions = Object.entries(Enums.DateFormat).map(
  ([key, value], i) => {
    return {
      key: `date-format-${key}-${i}`,
      text: DateTime.fromISO('2021-01-25T14:24:15.123').toFormat(value),
      value: value,
      description: startCase(key.toLowerCase().replace('us', 'US')),
    };
  },
);

interface DropdownItemPropsWithType extends DropdownItemProps {
  type: ListType;
}

const ValidationDataTypeModal: FC<ValidationDataTypeModalProps> = ({
  setShowModal,
  field,
  type,
  dataCollectionId,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { datasetMeta, editDatasetMeta } = useDatasetMeta();
  const { getLists } = useList();

  const [modalStage, setModalStage] = useState<number>(STAGE_ONE);
  const [fieldName, setFieldName] = useState<string>(field || '');
  const [duplicateName, setDuplicateName] = useState<boolean>(false);
  const [invalidName, setInvalidName] = useState<boolean>(false);
  const [schemaField, setSchemaField] = useState<Field>({ name: '' });
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState<number[]>(
    [],
  );
  const [listValueOptions, setListValueOptions] = useState<DropdownItemProps[]>(
    [],
  );

  const [listOptions, setListOptions] = useState<DropdownItemPropsWithType[]>(
    [],
  );

  const [defaultValueOptions, setDefaultValueOptions] = useState<
    DropdownItemProps[]
  >([]);

  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  const dataType = schemaField?.dataValidation?.dataValidationType;

  const [selectedListId, setSelectedListId] = useState<string | undefined>('');
  const [usedInSuperCol, setUsedInSuperCol] = useState<boolean>(false);

  // Get lists
  useEffect(() => {
    (async () => {
      await getLists();
    })();
  }, [getLists]);

  // Map lists to options
  useEffect(() => {
    if (lists?.length && !listValueOptions.length) {
      setListOptions(
        lists.map((l, i) => {
          return {
            key: `list-${l.entity.name}-${i}`,
            value: l.entity._id,
            text: l.entity.name,
            type: l.entity.listType,
          };
        }),
      );
    }
  }, [listValueOptions.length, lists]);

  // Set schemaField
  useEffect(() => {
    if (datasetMeta) {
      const dataCollection = datasetMeta.dataCollections?.find(
        (c) => c._id === dataCollectionId,
      );

      if (!dataCollection) {
        return;
      }

      const sField = dataCollection?.schemaData?.find((f) => {
        return f.name === field;
      });

      if (sField) {
        setSchemaField(sField);

        // Check if in use by a super column
        if (
          CheckColumnUse.isUsedBySuperColumn(
            dataCollection.schemaData,
            sField.name,
            sField.fieldId,
          )
        ) {
          setUsedInSuperCol(true);
        }
      }
    }
  }, [dataCollectionId, datasetMeta, field]);

  // Set list vals
  useEffect(() => {
    if (datasetMeta) {
      const listVals = get(
        schemaField,
        `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
      );

      if (!listVals || !listVals.length || listValueOptions.length) {
        setSelectedListId(undefined);
        return;
      }

      if (Array.isArray(listVals)) {
        setListValueOptions(
          listVals.map((lv, i) => {
            return {
              key: `listVal-${lv.value}-${i}`,
              value: lv.value,
              text: lv.value,
            };
          }),
        );
      } else if (selectedListId !== listVals) {
        setSelectedListId(listVals);

        // Clear default value
        if (
          get(
            schemaField,
            `${DATA_VALIDATION_CONSTRAINTS}.${DEFAULT_VALUE_FIELD}`,
          )
        ) {
          set(
            schemaField,
            `${DATA_VALIDATION_CONSTRAINTS}.${DEFAULT_VALUE_FIELD}`,
            undefined,
          );
        }
      }
    }
  }, [
    datasetMeta,
    listValueOptions.length,
    lists,
    schemaField,
    selectedListId,
  ]);

  // Set list default values
  useEffect(() => {
    if (selectedListId) {
      const selectedList = lists.find((o) => o.entity._id === selectedListId);

      if (selectedList) {
        const listValuesUsers = (selectedList.entity as UserListOutput) || [];
        const isUserList =
          selectedList.entity?.listType === Enums.ListType.USER;
        const displayColorIndicator = selectedList?.entity?.values?.some(
          (lv) => lv.color,
        );

        const listVals = isUserList
          ? listValuesUsers.users
          : selectedList.entity.values;

        setDefaultValueOptions(
          listVals?.map((lv, i) => {
            const image = isUserList
              ? {
                  avatar: true,
                  src: UserIconMap[lv.avatar],
                }
              : undefined;

            return {
              key: `value-${lv.value}-${i}`,
              value: isUserList ? lv._id : lv.value,
              text: isUserList ? `${lv.firstName} ${lv.lastName}` : lv.value,
              label: displayColorIndicator && (
                <DropdownLabel
                  indicatorcolor={lv.color}
                  circular={true}
                  size={'mini'}
                />
              ),
              image,
            };
          }) || [],
        );
      }
    }
  }, [lists, schemaField, selectedListId]);

  // Check column name is unique
  useEffect(() => {
    if (fieldName) {
      const dataCollection = datasetMeta?.dataCollections?.find(
        (c) => c._id === dataCollectionId,
      );

      setDuplicateName(
        !!dataCollection?.schemaData?.find(
          (f) =>
            f.name.toLowerCase() === fieldName.toLowerCase() &&
            fieldName.toLowerCase() !== schemaField.name.toLowerCase(),
        ),
      );
    }
  }, [
    dataCollectionId,
    datasetMeta?.dataCollections,
    fieldName,
    schemaField.name,
  ]);

  // Check column name doesn't contain special characters
  useEffect(() => {
    if (fieldName) {
      setInvalidName(namesRequiresCleansing(fieldName));
    }
  }, [fieldName]);

  const handleNameChange = (val: string) => {
    setFieldName(val);
    setDataHasChanged(true);
  };

  const handleAddValue = (val: string) => {
    const cloned = cloneDeep(listValueOptions);
    cloned.push({
      key: val,
      value: val,
      text: val,
    });

    setListValueOptions(cloned);
  };

  const handleDataTypeChange = (dataType: ValueDataType) => {
    const cloned = cloneDeep(schemaField);

    set(cloned, `dataValidation.dataValidationType`, dataType);

    if (
      [Enums.ValueDataType.PHONE_NUM, Enums.ValueDataType.POSTCODE].includes(
        dataType,
      )
    ) {
      set(cloned, `dataValidation.constraints.country`, DEFAULT_LOCALE);
    }

    setSchemaField(cloned);
    setDataHasChanged(true);
  };

  const handleConstraintChange = (field: string, value: unknown) => {
    if (field && schemaField) {
      const cloned = { ...cloneDeep(schemaField) };

      if (Array.isArray(value)) {
        const formattedValues = value.map((item) => {
          return { value: item };
        });

        if (!formattedValues.length) {
          delete cloned.dataValidation.constraints[field];
        } else {
          set(
            cloned,
            `${DATA_VALIDATION_CONSTRAINTS}.${field}`,
            formattedValues,
          );
        }
      } else {
        if (!value) {
          delete cloned.dataValidation.constraints[field];
        } else {
          set(cloned, `${DATA_VALIDATION_CONSTRAINTS}.${field}`, value);
        }
      }

      setSchemaField(cloned);
      setDataHasChanged(true);
    }
  };

  const processAction = async () => {
    if (dataHasChanged) {
      const clonedState: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

      const dataCollection = clonedState.dataCollections?.find(
        (c) => c._id === dataCollectionId,
      );

      if (dataCollection?.schemaData && schemaField) {
        dispatch(showLoading({ text: 'Saving Column...' }));

        // Reset validation when changing columns
        dataCollection.stages[Enums.DatasetStage.VALIDATION] =
          initialDatasetStageValidation;

        if (field) {
          // Update existing
          dataCollection.schemaData = dataCollection.schemaData.map((f) => {
            if (f.name === field) {
              return { ...schemaField, name: fieldName.trim() };
            }
            return f;
          }) as Interfaces.FieldOutput[];
        } else {
          dataCollection.schemaData.push({
            ...schemaField,
            name: fieldName.trim(),
          } as Interfaces.FieldOutput);
        }

        await editDatasetMeta(clonedState);
      }
    }

    dispatch(hideModal());
    dispatch(hideLoading());
  };

  const processDelete = async () => {
    dispatch(
      showLoading({
        text: `Deleting Column...`,
      }),
    );
    const cloned: Interfaces.DatasetMetaOutput = cloneDeep(datasetMeta);

    const dataCollection = cloned.dataCollections?.find(
      (c) => c._id === dataCollectionId,
    );

    if (dataCollection?.schemaData) {
      dataCollection.schemaData =
        dataCollection?.schemaData.filter((f) => f.name !== fieldName) || [];

      await editDatasetMeta(cloned);
    }

    dispatch(hideLoading());
    dispatch(hideModal());
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  const toggleAccordionIndex = (index: number) => {
    if (accordionOpenIndexes.includes(index)) {
      setAccordionOpenIndexes(
        accordionOpenIndexes.filter((aoi) => aoi !== index),
      );
    } else {
      setAccordionOpenIndexes([...accordionOpenIndexes, index]);
    }
  };

  let validationListValues = get(
    schemaField,
    `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
  );
  if (
    validationListValues &&
    Array.isArray(validationListValues) &&
    validationListValues.some((lv) => lv.value)
  ) {
    validationListValues = validationListValues.map((o) => o.value);
  }

  return (
    <SC.Wrapper>
      <SC.HeaderWrapper>
        {modalStage !== STAGE_DELETE && (
          <SC.Header>{field ? 'Update' : 'Create'} Column</SC.Header>
        )}

        {modalStage === STAGE_DELETE && <SC.Header>Delete Column</SC.Header>}

        {modalStage !== STAGE_DELETE && field && (
          <Popup
            disabled={!usedInSuperCol}
            content={
              'This column cannot be deleted as it is currently being used in another super column in this dataset.'
            }
            position="left center"
            style={DefaultPopupStyles}
            hideOnScroll={true}
            trigger={
              <SC.DeleteButton>
                <FeatureButton
                  isDisabled={usedInSuperCol}
                  action={() => setModalStage(STAGE_DELETE)}
                  size={FeatureButtonSize.WIDE_SMALL}
                  color={themeContext.colors.general.red}
                  text={'Delete'}
                />
              </SC.DeleteButton>
            }
          />
        )}
      </SC.HeaderWrapper>

      {modalStage === STAGE_ONE && (
        <>
          <StyledBodySubHeader>Column Name</StyledBodySubHeader>
          <StyledInput
            placeholder={'Enter a column name'}
            value={fieldName}
            onChange={(event, data) => handleNameChange(data.value)}
            style={{ marginBottom: themeContext.margin.large }}
          />
          {duplicateName && <ErrorText>Column names must be unique</ErrorText>}
          {invalidName && (
            <ErrorText>Column name contains invalid characters</ErrorText>
          )}

          {type !== Enums.ValueDataType.FORMULA && (
            <>
              <StyledBodySubHeader>Select A Data Type</StyledBodySubHeader>
              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a Data Type'}
                selection
                options={dataTypeDropdownOptions}
                value={dataType}
                onChange={(e, data) => handleDataTypeChange(data.value)}
              />

              {/*Data*/}
              {dataType === Enums.ValueDataType.DATE && (
                <SC.OptionalField>
                  <StyledBodySubHeader>
                    Select A Date Format
                  </StyledBodySubHeader>

                  <StyledDropdownUltraWide
                    selectOnBlur={false}
                    placeholder={'Select a date format'}
                    selection
                    options={dateFormatDropdownOptions}
                    value={get(
                      schemaField,
                      `${DATA_VALIDATION_CONSTRAINTS}.${DATE_FORMAT_FIELD}`,
                    )}
                    onChange={(e, data) =>
                      handleConstraintChange(DATE_FORMAT_FIELD, data.value)
                    }
                  />
                </SC.OptionalField>
              )}

              <StyledAccordion fluid>
                {dataType &&
                  [
                    Enums.ValueDataType.NUMBER,
                    Enums.ValueDataType.FORMULA,
                    Enums.ValueDataType.DATE,
                  ].includes(dataType) && (
                    <FormattingOptions
                      active={accordionOpenIndexes.includes(0)}
                      activeToggle={() => toggleAccordionIndex(0)}
                      schemaField={schemaField}
                      onChange={(updatedSchemaField) => {
                        setSchemaField(updatedSchemaField);
                        setDataHasChanged(true);
                      }}
                    />
                  )}

                {/*Validation*/}
                <StyledAccordionTitle
                  active={accordionOpenIndexes.includes(1)}
                  index={1}
                  onClick={() => toggleAccordionIndex(1)}
                  style={{
                    backgroundColor: themeContext.colors.system.offWhite,
                  }}
                >
                  <StyledSubHeader>Validation Options</StyledSubHeader>
                  <FontAwesomeIcon
                    icon={
                      accordionOpenIndexes.includes(1)
                        ? faChevronUp
                        : faChevronDown
                    }
                    color={defaultTheme.colors.system.offBlack}
                  />
                </StyledAccordionTitle>

                <StyledAccordionContent
                  active={accordionOpenIndexes.includes(1)}
                >
                  {dataType !== Enums.ValueDataType.BOOL && (
                    <SC.OptionalWrapper>
                      <StyledSubHeader>List Content</StyledSubHeader>

                      <SC.OptionalFieldWrapper
                        style={{ justifyContent: 'center' }}
                      >
                        <SC.OptionalField>
                          <StyledText>
                            Limit values to a specific set by selecting a
                            predefined list or entering values directly.
                          </StyledText>

                          <StyledBodySubHeader>
                            Select A Predefined List
                          </StyledBodySubHeader>

                          <StyledDropdownUltraWide
                            selectOnBlur={false}
                            disabled={Array.isArray(
                              get(
                                schemaField,
                                `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
                              ),
                            )}
                            placeholder={'Select a predefined list'}
                            selection
                            search
                            clearable
                            options={
                              dataType === Enums.ValueDataType.TEXT
                                ? listOptions
                                : listOptions.filter(
                                    (l) => l.type === ListType.DATASET,
                                  )
                            }
                            value={
                              Array.isArray(
                                get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
                                ),
                              )
                                ? ''
                                : get(
                                    schemaField,
                                    `${DATA_VALIDATION_CONSTRAINTS}.${LIST_VALUES_FIELD}`,
                                  )
                            }
                            onChange={(event, data) =>
                              handleConstraintChange(
                                LIST_VALUES_FIELD,
                                data.value,
                              )
                            }
                            style={{
                              marginBottom: themeContext.margin.standard,
                            }}
                          />

                          {dataType === Enums.ValueDataType.TEXT && (
                            <>
                              <StyledBodySubHeader>
                                Enter Values Directly
                              </StyledBodySubHeader>

                              <StyledDropdownUltraWide
                                selectOnBlur={false}
                                disabled={
                                  validationListValues &&
                                  !Array.isArray(validationListValues)
                                }
                                clearable
                                placeholder={'Enter list values'}
                                noResultsMessage={
                                  'Type to enter a new list value'
                                }
                                search
                                selection
                                multiple
                                allowAdditions
                                options={listValueOptions}
                                value={
                                  validationListValues &&
                                  Array.isArray(validationListValues)
                                    ? validationListValues
                                    : []
                                }
                                onChange={(event, data) =>
                                  handleConstraintChange(
                                    LIST_VALUES_FIELD,
                                    data.value.length ? data.value : undefined,
                                  )
                                }
                                onAddItem={(e, data) =>
                                  handleAddValue(data.value)
                                }
                              />
                            </>
                          )}
                        </SC.OptionalField>
                      </SC.OptionalFieldWrapper>
                    </SC.OptionalWrapper>
                  )}

                  {/*Number/Text*/}
                  {dataType &&
                    [
                      Enums.ValueDataType.TEXT,
                      Enums.ValueDataType.NUMBER,
                    ].includes(dataType) && (
                      <SC.OptionalWrapper>
                        <StyledSubHeader>
                          {dataType === Enums.ValueDataType.TEXT
                            ? 'Text'
                            : 'Number'}{' '}
                          Requirements
                        </StyledSubHeader>

                        <SC.OptionalFieldWrapper>
                          <SC.OptionalField>
                            {dataType === Enums.ValueDataType.TEXT && (
                              <>
                                <StyledBodySubHeader>
                                  Minimum Length
                                </StyledBodySubHeader>
                                <StyledText>
                                  Only allow values that have more characters
                                  than this value.
                                </StyledText>
                              </>
                            )}

                            {dataType === Enums.ValueDataType.NUMBER && (
                              <>
                                <StyledBodySubHeader>
                                  Minimum Value
                                </StyledBodySubHeader>
                                <StyledText>
                                  Only allow numbers greater than this minimum
                                  value.
                                </StyledText>
                              </>
                            )}

                            <StyledInput
                              type={'number'}
                              min={0}
                              placeholder={
                                dataType === Enums.ValueDataType.TEXT
                                  ? 'Minimum length'
                                  : 'Minimum value'
                              }
                              value={
                                typeof get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${MINIMUM_FIELD}`,
                                ) !== 'undefined'
                                  ? get(
                                      schemaField,
                                      `${DATA_VALIDATION_CONSTRAINTS}.${MINIMUM_FIELD}`,
                                    )
                                  : ''
                              }
                              onChange={(event, data) =>
                                handleConstraintChange(
                                  MINIMUM_FIELD,
                                  data.value.length ? data.value : undefined,
                                )
                              }
                            />
                          </SC.OptionalField>

                          <SC.OptionalField>
                            {dataType === Enums.ValueDataType.TEXT && (
                              <>
                                <StyledBodySubHeader>
                                  Maximum Length
                                </StyledBodySubHeader>
                                <StyledText>
                                  Only allow values that have less characters
                                  than this value.
                                </StyledText>
                              </>
                            )}

                            {dataType === Enums.ValueDataType.NUMBER && (
                              <>
                                <StyledBodySubHeader>
                                  Maximum Value
                                </StyledBodySubHeader>
                                <StyledText>
                                  Only allow numbers less than this maximum
                                  value.
                                </StyledText>
                              </>
                            )}

                            <StyledInput
                              type={'number'}
                              min={0}
                              placeholder={
                                dataType === Enums.ValueDataType.TEXT
                                  ? 'Maximum length'
                                  : 'Maximum value'
                              }
                              value={
                                typeof get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${MAXIMUM_FIELD}`,
                                ) !== 'undefined'
                                  ? get(
                                      schemaField,
                                      `${DATA_VALIDATION_CONSTRAINTS}.${MAXIMUM_FIELD}`,
                                    )
                                  : ''
                              }
                              onChange={(event, data) =>
                                handleConstraintChange(
                                  MAXIMUM_FIELD,
                                  data.value.length ? data.value : undefined,
                                )
                              }
                            />
                          </SC.OptionalField>
                        </SC.OptionalFieldWrapper>

                        {dataType === Enums.ValueDataType.NUMBER && (
                          <SC.OptionalFieldWrapper
                            style={{ marginTop: themeContext.margin.large }}
                          >
                            <SC.OptionalField>
                              <StyledBodySubHeader>
                                Whole Numbers
                              </StyledBodySubHeader>
                              <StyledText>Only allow whole numbers.</StyledText>

                              <Toggle
                                checked={get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${INTEGER_FIELD}`,
                                )}
                                icons={false}
                                onChange={(event) =>
                                  handleConstraintChange(
                                    INTEGER_FIELD,
                                    event.target.checked,
                                  )
                                }
                              />
                            </SC.OptionalField>

                            <SC.OptionalField>
                              <StyledBodySubHeader>
                                Auto Increment
                              </StyledBodySubHeader>
                              <StyledText>
                                Increment number for each inserted row.
                              </StyledText>

                              <Toggle
                                checked={get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${INCREMENT_FIELD}`,
                                )}
                                icons={false}
                                onChange={(event) =>
                                  handleConstraintChange(
                                    INCREMENT_FIELD,
                                    event.target.checked,
                                  )
                                }
                                disabled={get(
                                  schemaField,
                                  `${DATA_VALIDATION_CONSTRAINTS}.${UNIQUE_FIELD}`,
                                )}
                              />
                            </SC.OptionalField>
                          </SC.OptionalFieldWrapper>
                        )}
                      </SC.OptionalWrapper>
                    )}

                  <AdditionalValidationOptions
                    schemaField={schemaField}
                    defaultValueOptions={defaultValueOptions}
                    onChange={(updatedSchemaField) => {
                      setSchemaField(updatedSchemaField);
                      setDataHasChanged(true);
                    }}
                  />
                </StyledAccordionContent>

                {/*Permission Options*/}
                <PermissionOptions
                  active={accordionOpenIndexes.includes(2)}
                  activeToggle={() => toggleAccordionIndex(2)}
                  schemaField={schemaField}
                  onChange={(updatedSchemaField) => {
                    setSchemaField(updatedSchemaField);
                    setDataHasChanged(true);
                  }}
                />
              </StyledAccordion>
            </>
          )}

          <SC.ActionButtonWrapper>
            <FeatureButton
              action={() => dispatch(hideModal())}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.sea}
              text={'Cancel'}
            />
            <FeatureButton
              isDisabled={
                !schemaField.dataValidation?.dataValidationType ||
                schemaField.dataValidation?.dataValidationType ===
                  Enums.ValueDataType.UNKNOWN ||
                !fieldName ||
                duplicateName ||
                invalidName ||
                (schemaField.dataValidation?.dataValidationType ===
                  Enums.ValueDataType.DATE &&
                  !get(
                    schemaField,
                    `${DATA_VALIDATION_CONSTRAINTS}.${DATE_FORMAT_FIELD}`,
                  ))
              }
              action={processAction}
              size={FeatureButtonSize.WIDE}
              color={themeContext.colors.general.green}
              text={
                type === Enums.ValueDataType.FORMULA ? 'Okay' : `Save Column`
              }
            />
          </SC.ActionButtonWrapper>
        </>
      )}

      {modalStage === STAGE_DELETE && (
        <DeleteConfirmation
          entityType={EntityType.COLUMN}
          entityName={fieldName}
          cancelAction={() => setModalStage(STAGE_ONE)}
          deleteAction={processDelete}
        />
      )}
    </SC.Wrapper>
  );
};

export default ValidationDataTypeModal;
