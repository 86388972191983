import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, DropdownItemProps } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { FORMULA_TYPES } from '../../../../const/FormulaConst';
import useDatasetMeta from '../../../../hooks/dataset-meta/UseDatasetMeta';
import useFilter from '../../../../hooks/filter/UseFilter';
import useProject from '../../../../hooks/project/UseProject';
import useQuery from '../../../../hooks/query/UseQuery';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledDropdownUltraWide,
  StyledSubHeader,
} from '../../../../main/theme';
import { DatasetMetaItemOutput } from '../../../../services/dataset-meta/DatasetMetaService';
import { hideLoading, showLoading } from '../../../../store/loading';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../../../store/project-stage';
import { ProjectAggregationSubStage } from '../../../../store/project-stage/initial-state';
import { QueryItemOutput } from '../../../../store/queries';
import { fetchQuerySuccess } from '../../../../store/query';
import InitialQuery from '../../../../store/query/initial-state';
import { RootState } from '../../../../store/rootReducer';
import ActionBar from '../../../ActionBar/ActionBar';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import * as SC from '../../ProjectItemGraphStage/styled';

interface FieldPanel {
  key: string;
  active: boolean;
  title: JSX.Element;
  content: JSX.Element;
}

const AGGREGATION_TYPE_FIELD = 'aggregationType';
const DATASET_METAS_FIELD = 'datasetMetaId';
const DATA_MODEL_LINK_FIELD = 'dataModelLinkIds';
const NEXT_STAGE = ProjectAggregationSubStage.AGGREGATE;
const PREV_STAGE = ProjectAggregationSubStage.NAME;
const DATASET_LIMIT = 300;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const FieldAccordion = styled(StyledAccordion)`
  width: 100%;
  max-width: 800px !important;
`;

const FieldAccordionContent = styled(StyledAccordionContent)`
  display: flex !important;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    width: 25%;
    margin: ${({ theme }) => `${theme.margin.standard} 0`};
  }
`;

const aggregationTypeOptions = [
  {
    key: `aggregation-type-${Enums.AggregationType.SINGLE}`,
    value: Enums.AggregationType.SINGLE,
    text: 'Single dataset',
  },
  {
    key: `aggregation-type-${Enums.AggregationType.MULTIPLE}`,
    value: Enums.AggregationType.MULTIPLE,
    text: 'Multiple datasets',
  },
];

const ProjectItemAggregationDataStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { query, queryAccessLevel, addQuery, editQuery } = useQuery();
  const { project, editProject } = useProject();
  const { getDatasetMetas } = useDatasetMeta();
  const { getDatasetFilters, updateFilterGroup } = useFilter();

  const [datasetOptions, setDatasetOptions] = useState<DropdownItemProps[]>([]);
  const [dataLinkOptions, setDataLinkOptions] = useState<DropdownItemProps[]>(
    [],
  );
  const [existingQueryOptions, setExistingQueryOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [fieldPanels, setFieldPanels] = useState<FieldPanel[]>([]);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [queryCreated, setQueryCreated] = useState<boolean>(false);
  const [queryUpdated, setQueryUpdated] = useState<boolean>(false);
  const [projectUpdated, setProjectUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [useExistingQuery, setUseExistingQuery] = useState<boolean>(false);

  const isMultiple =
    query?.queryParams?.aggregationType === Enums.AggregationType.MULTIPLE;

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;

  const queriesState = useSelector((state: RootState) => state.queries);
  const queries: QueryItemOutput[] = queriesState.data.data;

  const handleSelectExistingQuery = (val: string) => {
    const existingQuery = queries.find((q) => q.entity._id === val);

    if (existingQuery) {
      const cloned = cloneDeep(query);
      cloned.queryParams = existingQuery.entity.queryParams;

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    }
  };

  const handleChange = (field: string, val: string | string[]) => {
    const cloned: Interfaces.Query = cloneDeep(query);
    cloned.queryParams[field] = val;
    // Reset query data
    cloned.queryParams.fields = [];
    cloned.queryParams.filters = [];
    cloned.queryParams.groups = [];
    cloned.queryParams.measures = [];
    cloned.queryParams.sort = [];
    cloned.queryParams.includeSubtotals = false;
    cloned.queryParams.includeGrandTotals = false;

    dispatch(
      fetchQuerySuccess({
        accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const handleFieldChange = useCallback(
    (
      datasetMetaId: string,
      datasetMetaName: string,
      field: string[],
      alias: string[],
      val: boolean,
    ) => {
      const cloned = cloneDeep(query);
      if (!val) {
        cloned.queryParams.fields = cloned.queryParams.fields.filter(
          (f) =>
            !(field.includes(f.field) && f.datasetMetaId === datasetMetaId),
        );
      } else {
        field.map((f, i) => {
          if (!cloned.queryParams.fields.find((field) => field.field === f)) {
            cloned.queryParams.fields.push({
              field: f,
              alias: isMultiple ? `${alias[i]} - ${datasetMetaName}` : alias[i],
              datasetMetaId,
            });
          }
        });
      }

      // Remove deselected field from query params
      if (!val) {
        const deselectedField = field[0];

        cloned.queryParams.groups = cloned.queryParams.groups.filter(
          (group) => group.field !== deselectedField,
        );
        cloned.queryParams.measures = cloned.queryParams.measures.filter(
          (measure) => measure.field !== deselectedField,
        );
        cloned.queryParams.sort = cloned.queryParams.sort.filter(
          (sort) => sort.field !== deselectedField,
        );

        // Remove any removed fields from filter query
        const clearRemovedFieldsFromFilterQuery = (filter) => {
          return {
            ...filter,
            value:
              Array.isArray(filter.value) && filter.value[0]?.operator
                ? filter.value
                    .filter((v) => {
                      // If a filter wrapper, check if no values or if single value has it been deleted
                      if (!v.field) {
                        if (Array.isArray(v.value)) {
                          if (!v.value.length) {
                            return false;
                          }

                          if (v.value[0]?.operator && v.value.length === 1) {
                            if (
                              !cloned.queryParams.fields.find(
                                (af) => af.field === v.value[0].field,
                              )
                            ) {
                              return false;
                            }
                          }

                          return true;
                        }
                      }

                      // Otherwise check if field still exists
                      return !!cloned.queryParams.fields.find(
                        (af) => af.field === v.field,
                      );
                    })
                    .map((v) => clearRemovedFieldsFromFilterQuery(v))
                : filter.value,
          };
        };

        cloned.queryParams.filters = cloned.queryParams.filters.map((f) =>
          clearRemovedFieldsFromFilterQuery(f),
        );

        // Reset subtotals if no measures
        if (!cloned.queryParams.measures.length) {
          cloned.queryParams.includeSubtotals = false;
          cloned.queryParams.includeGrandTotals = false;
        }
      }

      dispatch(
        fetchQuerySuccess({
          accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
          entity: cloned,
        }),
      );
    },
    [query, dispatch, queryAccessLevel, isMultiple],
  );

  const handleResetQuery = (useExistingQuery: boolean) => {
    setUseExistingQuery(useExistingQuery);

    const cloned = cloneDeep(query);
    cloned.queryParams = InitialQuery.entity.queryParams;
    dispatch(
      fetchQuerySuccess({
        accessLevel: queryAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  const processAction = async () => {
    if (query) {
      const cloned = cloneDeep(query);

      if (!cloned.queryParams.displayOrder.length) {
        // No groups/measures so use fields
        cloned.queryParams.displayOrder = cloned.queryParams.fields;
      }

      // Remove any reference to removed fields
      const clearKeys = ['groups', 'sort', 'measures', 'displayOrder'];

      clearKeys.forEach((k) => {
        cloned.queryParams[k] = cloned.queryParams[k].filter((f) => {
          if (k === 'sort' && f.isMeasure) {
            return true;
          }

          return !!cloned.queryParams.fields.find(
            (af) =>
              af.datasetMetaId === f.datasetMetaId && af.field === f.field,
          );
        });
      });

      // Remove any removed fields from filter state
      const savedFilters = getDatasetFilters(cloned._id);
      const clearRemovedFieldsFromFilterState = (filter) => {
        return {
          ...filter,
          value:
            Array.isArray(filter.value) && filter.value[0]?.operator
              ? filter.value
                  .filter((v) => {
                    // If a filter wrapper, check if no values or if single value has it been deleted
                    if (!v.field) {
                      if (Array.isArray(v.value)) {
                        if (!v.value.length) {
                          return false;
                        }

                        if (v.value[0]?.operator && v.value.length === 1) {
                          if (
                            !cloned.queryParams.fields.find(
                              (af) => af.field === v.value[0].field,
                            )
                          ) {
                            return false;
                          }
                        }

                        return true;
                      }
                    }

                    // Otherwise check if field still exists
                    return !!cloned.queryParams.fields.find(
                      (af) => af.field === v.field,
                    );
                  })
                  .map((v) => clearRemovedFieldsFromFilterState(v))
              : filter.value,
        };
      };

      const updatedFilters = (savedFilters || []).map((f) =>
        clearRemovedFieldsFromFilterState(f),
      );

      updateFilterGroup(cloned._id, updatedFilters);

      dispatch(showLoading({ text: 'Saving Query...' }));

      if (query._id) {
        await editQuery(cloned);
        setQueryUpdated(true);
        dispatch(hideLoading());
      } else {
        await addQuery(cloned);
        setQueryCreated(true);
        dispatch(hideLoading());
      }
    }
  };

  // Update aggregation state
  useEffect(() => {
    if (!query?._id || (!queryCreated && !queryUpdated)) {
      return;
    }

    if (queryCreated) {
      (async () => {
        const cloned = cloneDeep(project);
        cloned.queries = cloned.queries
          ? [...cloned.queries, query._id]
          : [query._id];
        await editProject(cloned);
      })();
    }

    setProjectUpdated(true);
  }, [dispatch, editProject, project, query?._id, queryCreated, queryUpdated]);

  useEffect(() => {
    if (projectUpdated) {
      dispatch(updateActiveProjectSubStage(NEXT_STAGE));
    }
  }, [dispatch, projectUpdated]);

  // Get datasetMetas
  useEffect(() => {
    (async () => {
      if (project) {
        await getDatasetMetas(
          {
            projectId: project._id,
            activeDataCollection: { $ne: null },
          },
          undefined,
          undefined,
          DATASET_LIMIT,
        );
        setIsLoading(false);
      }
    })();
  }, [dispatch, getDatasetMetas, project]);

  // Map datasetMetas to options
  useEffect(() => {
    if (datasetMetas?.length) {
      setDatasetOptions(
        datasetMetas.map((d) => {
          const ent = d.entity;
          return {
            key: ent._id,
            value: ent._id,
            text: ent.name,
          };
        }),
      );
    }
  }, [datasetMetas]);

  // Map existing query options
  useEffect(() => {
    if (queries?.length) {
      setExistingQueryOptions(
        queries.map((q) => {
          const ent = q.entity;
          return {
            key: ent._id,
            value: ent._id,
            text: ent.name,
          };
        }),
      );
    }
  }, [queries]);

  const getAllDataLinkOptions = useCallback(() => {
    return (
      project?.dataModel?.reduce((acc, d) => {
        const primaryDSM = datasetMetas.find(
          (ds) => ds.entity._id === d.primary.datasetMetaId,
        );

        const primaryCollection = primaryDSM?.entity.dataCollections?.find(
          (c) => c._id === primaryDSM.entity.activeDataCollection,
        );
        const primaryFieldName = primaryCollection?.schemaData?.find(
          (f) => f.fieldId === d.primary.field || f.name === d.primary.field,
        )?.name;

        const secondaryDSM = datasetMetas.find(
          (ds) => ds.entity._id === d.secondary.datasetMetaId,
        );

        const secondaryCollection = secondaryDSM?.entity.dataCollections?.find(
          (c) => c._id === secondaryDSM.entity.activeDataCollection,
        );
        const secondaryFieldName = secondaryCollection?.schemaData?.find(
          (f) =>
            f.fieldId === d.secondary.field || f.name === d.secondary.field,
        )?.name;

        if (
          primaryDSM?.entity.name &&
          primaryFieldName &&
          secondaryDSM?.entity.name &&
          secondaryFieldName
        ) {
          return [
            ...acc,
            {
              key: d._id,
              value: d._id,
              text: `${primaryDSM?.entity.name} (${primaryFieldName}) - ${secondaryDSM?.entity.name} (${secondaryFieldName})`,
            },
          ];
        }

        return acc;
      }, [] as DropdownItemProps[]) || []
    );
  }, [datasetMetas, project?.dataModel]);

  // Map data links to options
  useEffect(() => {
    if (project?.dataModel && datasetMetas) {
      setDataLinkOptions(getAllDataLinkOptions());
    }
  }, [datasetMetas, getAllDataLinkOptions, project?.dataModel]);

  useEffect(() => {
    if (!query?.queryParams.dataModelLinkIds?.length) {
      return setDataLinkOptions(getAllDataLinkOptions());
    }

    if (project && query) {
      const allowedDatasets: string[] = [];

      query.queryParams.dataModelLinkIds.forEach((id) => {
        const dml = project?.dataModel.find((d) => d._id === id);

        if (dml) {
          if (!allowedDatasets.includes(dml.primary.datasetMetaId)) {
            allowedDatasets.push(dml.primary.datasetMetaId);
          }

          if (!allowedDatasets.includes(dml.secondary.datasetMetaId)) {
            allowedDatasets.push(dml.secondary.datasetMetaId);
          }
        }
      });

      const allowedDataLinks = project.dataModel
        .filter((d) => {
          // TODO - Remove when super columns are saved - Filter out super column joins
          const primaryDSM = datasetMetas.find(
            (dsm) => dsm.entity._id === d.primary.datasetMetaId,
          )?.entity;
          const primaryCollection = primaryDSM?.activeDataCollection
            ? primaryDSM.dataCollections.find(
                (c) => c._id === primaryDSM?.activeDataCollection,
              )
            : primaryDSM?.dataCollections?.[0];

          if (!primaryCollection) {
            return false;
          }

          const primaryFieldType = primaryCollection?.schemaData?.find(
            (f) => f.name === d.primary.field,
          )?.dataValidation?.dataValidationType;

          const secondaryDSM = datasetMetas.find(
            (dsm) => dsm.entity._id === d.secondary.datasetMetaId,
          )?.entity;
          const secondaryCollection = secondaryDSM?.activeDataCollection
            ? secondaryDSM.dataCollections.find(
                (c) => c._id === secondaryDSM.activeDataCollection,
              )
            : secondaryDSM?.dataCollections[0];

          const secondaryFieldType = secondaryCollection?.schemaData?.find(
            (f) => f.name === d.secondary.field,
          )?.dataValidation?.dataValidationType;

          if (
            (primaryFieldType && FORMULA_TYPES.includes(primaryFieldType)) ||
            (secondaryFieldType && FORMULA_TYPES.includes(secondaryFieldType))
          ) {
            return false;
          }

          return (
            allowedDatasets.includes(d.primary.datasetMetaId) ||
            allowedDatasets.includes(d.secondary.datasetMetaId)
          );
        })
        ?.map((d) => {
          const primaryDSM = datasetMetas.find(
            (ds) => ds.entity._id === d.primary.datasetMetaId,
          );

          const primaryCollection = primaryDSM?.entity.dataCollections?.find(
            (c) => c._id === primaryDSM.entity.activeDataCollection,
          );
          const primaryFieldName = primaryCollection?.schemaData?.find(
            (f) => f.fieldId === d.primary.field || f.name === d.primary.field,
          )?.name;

          const secondaryDSM = datasetMetas.find(
            (ds) => ds.entity._id === d.secondary.datasetMetaId,
          );

          const secondaryCollection =
            secondaryDSM?.entity.dataCollections?.find(
              (c) => c._id === secondaryDSM.entity.activeDataCollection,
            );
          const secondaryFieldName = secondaryCollection?.schemaData?.find(
            (f) =>
              f.fieldId === d.secondary.field || f.name === d.secondary.field,
          )?.name;

          return {
            key: d._id,
            value: d._id,
            text: `${primaryDSM?.entity.name} (${primaryFieldName}) - ${secondaryDSM?.entity.name} (${secondaryFieldName})`,
          };
        });

      return setDataLinkOptions(allowedDataLinks);
    }
  }, [query, datasetMetas, getAllDataLinkOptions, project]);

  // Map fields to panels
  useEffect(() => {
    if (query) {
      let datasets: string[] = [];

      if (
        query?.queryParams.aggregationType === Enums.AggregationType.SINGLE &&
        query?.queryParams.datasetMetaId
      ) {
        datasets = [query.queryParams.datasetMetaId];
      } else {
        const dataLinks = project?.dataModel.filter((dm) =>
          query.queryParams.dataModelLinkIds?.includes(dm._id),
        );

        dataLinks?.forEach((dl) => {
          if (!datasets.includes(dl.primary.datasetMetaId)) {
            datasets.push(dl.primary.datasetMetaId);
          }

          if (!datasets.includes(dl.secondary.datasetMetaId)) {
            datasets.push(dl.secondary.datasetMetaId);
          }
        });
      }

      const panels: FieldPanel[] = [];

      datasets.map((t, i) => {
        const dsm = datasetMetas.find((d) => d.entity._id === t)?.entity;

        if (dsm?.activeDataCollection) {
          const schema = dsm.dataCollections?.find(
            (c) => c._id === dsm.activeDataCollection,
          )?.schemaData;

          if (schema) {
            const inputs = schema.map((f, i) => {
              const checked = !!query.queryParams.fields.find(
                (af) =>
                  (af.field === f.fieldId || af.field === f.name) &&
                  af.datasetMetaId === dsm._id,
              );

              return (
                <Checkbox
                  key={`field-checkbox-${dsm._id}-${f.name}-${i}`}
                  label={f.name}
                  checked={checked}
                  onChange={(e, data) =>
                    handleFieldChange(
                      dsm._id,
                      dsm.name,
                      [f.fieldId || f.name],
                      [f.name],
                      data.checked || false,
                    )
                  }
                />
              );
            });

            panels.push({
              key: `field-panel-${t}-${i}`,
              active: true,
              title: (
                <StyledAccordionTitle>
                  <StyledBodySubHeader>{dsm.name}</StyledBodySubHeader>
                  <Checkbox
                    key={`field-checkbox-${dsm._id}-select-all-${i}`}
                    label={'Select All'}
                    checked={schema.every(
                      (f) =>
                        !!query.queryParams.fields.find(
                          (af) =>
                            (af.field === f.fieldId || af.field === f.name) &&
                            af.datasetMetaId === dsm._id,
                        ),
                    )}
                    onChange={(e, data) =>
                      handleFieldChange(
                        dsm._id,
                        dsm.name,
                        schema.map((f) => f.fieldId || f.name),
                        schema.map((f) => f.name),
                        data.checked || false,
                      )
                    }
                  />
                </StyledAccordionTitle>
              ),
              content: <FieldAccordionContent>{inputs}</FieldAccordionContent>,
            });
          }
        }
      });

      setFieldPanels(panels);
    }
  }, [query, datasetMetas, handleFieldChange, project?.dataModel]);

  // Confirm user can progress
  useEffect(() => {
    if (query) {
      setCanProgress(
        !(
          (!query.queryParams.datasetMetaId &&
            !query.queryParams.dataModelLinkIds?.length) ||
          !query.queryParams.fields.length
        ),
      );
    }
  }, [query, setCanProgress]);

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StyledSubHeader>
            Start from scratch or use existing Query?
          </StyledSubHeader>
          <StageBodyText>
            Would you like to create a new query from scratch or use an existing
            query?
          </StageBodyText>

          <SC.ButtonContainer>
            <FeatureButton
              isDisabled={!useExistingQuery}
              action={() => handleResetQuery(false)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'New query'}
              containerStyle={{ marginRight: themeContext.margin.xlarge }}
            />
            <FeatureButton
              isDisabled={useExistingQuery}
              action={() => handleResetQuery(true)}
              size={FeatureButtonSize.WIDE_SMALL}
              color={themeContext.colors.general.blue}
              text={'Existing query'}
            />
          </SC.ButtonContainer>

          {useExistingQuery && (
            <>
              <StyledSubHeader>Existing Query</StyledSubHeader>
              <StyledDropdownUltraWide
                selectOnBlur={false}
                placeholder={'Select an existing Query'}
                selection
                options={existingQueryOptions}
                onChange={(e, data) => {
                  handleSelectExistingQuery(data.value);
                }}
              />
            </>
          )}

          <StyledSubHeader>Query Type</StyledSubHeader>
          <StageBodyText>
            Would you like to query a single dataset or multiple datasets across
            your data model?
          </StageBodyText>

          <StyledDropdownUltraWide
            selectOnBlur={false}
            placeholder={'Select a Query Type'}
            selection
            options={aggregationTypeOptions}
            value={query?.queryParams.aggregationType}
            onChange={(e, data) =>
              handleChange(AGGREGATION_TYPE_FIELD, data.value)
            }
          />

          {query?.queryParams.aggregationType ===
            Enums.AggregationType.SINGLE && (
            <>
              <StyledSubHeader>Dataset</StyledSubHeader>
              <StageBodyText>
                Which dataset would you like to use in this query?
              </StageBodyText>

              <StyledDropdownUltraWide
                selectOnBlur={false}
                placeholder={'Select a Dataset'}
                selection
                options={datasetOptions}
                value={query?.queryParams.datasetMetaId}
                onChange={(e, data) =>
                  handleChange(DATASET_METAS_FIELD, data.value)
                }
                loading={isLoading}
              />
            </>
          )}

          {query?.queryParams.aggregationType ===
            Enums.AggregationType.MULTIPLE && (
            <>
              <StyledSubHeader>Data Link</StyledSubHeader>

              {dataLinkOptions.length === 0 && (
                <>
                  <StageBodyText>
                    A data model has not been created for this project yet. To
                    create one, tap the button below.
                  </StageBodyText>

                  <FeatureButton
                    action={() => {
                      dispatch(
                        updateActiveProjectStage(Enums.ProjectStage.MODELLING),
                      );
                    }}
                    size={FeatureButtonSize.WIDE}
                    color={themeContext.colors.general.blue}
                    text={'Create Data Model'}
                  />
                </>
              )}

              {dataLinkOptions.length > 0 && (
                <>
                  <StageBodyText>
                    Which data link would you like to use in this query?
                  </StageBodyText>

                  <StyledDropdownUltraWide
                    selectOnBlur={false}
                    upward={true}
                    placeholder={'Select a Data Link'}
                    selection
                    multiple
                    options={dataLinkOptions}
                    value={query.queryParams?.dataModelLinkIds || []}
                    onChange={(e, data) =>
                      handleChange(DATA_MODEL_LINK_FIELD, data.value)
                    }
                  />
                </>
              )}
            </>
          )}

          {((query?.queryParams.aggregationType ===
            Enums.AggregationType.SINGLE &&
            query?.queryParams.datasetMetaId) ||
            (query?.queryParams.aggregationType ===
              Enums.AggregationType.MULTIPLE &&
              dataLinkOptions.length > 0)) &&
            fieldPanels.length > 0 && (
              <>
                <StyledSubHeader>Columns</StyledSubHeader>
                <StageBodyText
                  style={{ marginBottom: themeContext.margin.small }}
                >
                  Which columns would you like to use in this query?
                </StageBodyText>

                <StageBodyText>
                  Modifying these columns will clear your existing query data.
                </StageBodyText>

                <FieldAccordion
                  defaultActiveIndex={[0]}
                  exclusive={false}
                  panels={fieldPanels}
                />
              </>
            )}
        </Section>
      </StageInner>

      <ActionBar
        text={`Keep it moving`}
        primaryButton={
          <FeatureButton
            isDisabled={!canProgress}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to query'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveProjectSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default ProjectItemAggregationDataStage;
