import { Enums } from '@configur-tech/upit-core-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import ProjectItemConnectionFormNameStage from '../../components/ProjectItem/ProjectItemFormStages/1-name/ProjectItemFormNameStage';
import ProjectItemConnectionFormDataStage from '../../components/ProjectItem/ProjectItemFormStages/2-datasets-fields/ProjectItemFormDataStage';
import ProjectItemFormBuildStage from '../../components/ProjectItem/ProjectItemFormStages/3-build-form/ProjectItemFormBuildStage';
import ProjectItemFormConfigurationStage from '../../components/ProjectItem/ProjectItemFormStages/4-configuration/ProjectItemFormConfigurationStage';
import ProjectItemConnectionFormStage from '../../components/ProjectItem/ProjectItemFormStages/ProjectItemFormStage';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import Page from '../../main/Page';
import { ProjectConnectionFormSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import {
  getProjectStageContent,
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectForm: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ConnectionType.FORM;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = activeSubStage
    ? getProjectSubStageContent(activeStage, activeSubStage)
    : getProjectStageContent(activeStage);
  const isBuilding =
    activeSubStage === ProjectConnectionFormSubStage.FORM_BUILDER;

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectConnectionFormSubStage.NAME:
        return <ProjectItemConnectionFormNameStage />;
      case ProjectConnectionFormSubStage.DATASET:
        return <ProjectItemConnectionFormDataStage />;
      case ProjectConnectionFormSubStage.FORM_BUILDER:
        return <ProjectItemFormBuildStage />;
      case ProjectConnectionFormSubStage.CONFIGURATION:
        return <ProjectItemFormConfigurationStage />;
      default:
        return <ProjectItemConnectionFormNameStage />;
    }
  };

  const stageContentTitle = stageContent.title;

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={
          activeSubStage &&
          activeSubStage !== ProjectConnectionFormSubStage.CONFIGURATION
            ? {
                activeSubStage: activeSubStage,
                subStageItems: projectStageState.stages[
                  activeStage
                ].subStages.filter(
                  (stage) =>
                    stage.subStage !==
                    ProjectConnectionFormSubStage.CONFIGURATION,
                ) as SubStageItem[],
              }
            : undefined
        }
        image={isBuilding ? undefined : stageContent.image}
        title={isBuilding ? undefined : stageContentTitle}
      />

      <Page>
        {activeSubStage ? (
          getSubStageComponent()
        ) : (
          <ProjectItemConnectionFormStage />
        )}
      </Page>
    </>
  );
};

export default ProjectForm;
