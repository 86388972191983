import styled from 'styled-components';
import { StyledInput, StyledText } from '../../main/theme';

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.xxlarge} 0 0`};

  > h1 {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }

  > textarea {
    margin: ${({ theme }) => `0 0 ${theme.margin.standard}`};
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.padding.small};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const NameActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ theme }) => `${theme.margin.standard} 0`};
`;

export const NameInput = styled(StyledInput)`
  margin: ${({ theme }) => `0 0 ${theme.margin.standard} 0`};
`;

export const StatWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin: ${({ theme }) =>
    `${theme.margin.standard} ${theme.margin.standard} 0`};
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 ${({ theme }) => theme.margin.standard};
  text-align: center;
`;

export const ItemHeader = styled(StyledText)<{ color?: string }>`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  ${({ color }) => color && `color: ${color}`};
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > div {
    text-align: center;
    margin: ${({ theme }) => `0 ${theme.margin.standard}`};

    > p {
      margin-top: ${({ theme }) => theme.margin.standard};
    }
  }
`;

export const RecentEventsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
  text-align: center;
`;
