import styled from 'styled-components';
import { StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  position: relative;

  background-color: ${({ theme }) => theme.colors.system.offWhite};

  border-left: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const StickyWrapper = styled.div<{ showBanner?: boolean }>`
  ${({ showBanner }) =>
    showBanner
      ? `height: calc(100vh - 128px);`
      : `height: calc(100vh - 70px);`};
  overflow-y: auto;
  align-items: start;

  padding: ${({ theme }) => theme.padding.standard};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  color: ${({ theme }) => theme.colors.system.offBlack};
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  margin: ${({ theme }) => `${theme.margin.xxlarge} 0`};
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${({ theme }) => `${theme.margin.large} 0 ${theme.margin.large}`};
`;

export const Filter = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: ${({ theme }) => theme.borders.radius};

  color: ${({ theme, active }) =>
    active ? theme.colors.system.white : theme.colors.system.offBlack};

  > p {
    color: ${({ theme, active }) =>
      active ? theme.colors.system.white : theme.colors.system.offBlack};

    &:first-of-type {
      margin: 0;
    }
  }
`;

export const FilterHeader = styled.div<{
  active?: boolean;
  showingMore?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${({ showingMore, theme }) =>
    !showingMore ? theme.margin.standard : 0};

  > p {
    color: ${({ theme, active }) =>
      active ? theme.colors.system.white : theme.colors.system.offBlack};
    margin: 0;
  }
`;

export const StyledTextPanel = styled(StyledText)<{ alignCenter?: boolean }>`
  background: ${({ theme }) => theme.colors.general.white};
  padding: ${({ theme }) => theme.padding.standard};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
  text-align: ${({ alignCenter }) => alignCenter && 'center'};
`;

export const StyledNotificationFilterList = styled.ul`
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.standard} ${theme.padding.small} ${theme.padding.xxlarge}`};
`;
