import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps, Icon, SemanticICONS } from 'semantic-ui-react';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import * as SC from '../../components/Modal/cms/styled';
import { DropdownLabel } from '../../components/styled';
import { ListOptionColor } from '../../enums';
import { defaultTheme } from '../../main/theme';
import {
  ListItemOutput,
  UserListOutput,
} from '../../services/list/ListService';
import { RootState } from '../../store/rootReducer';
import getDataTypeIcon from '../../util/data-type-icon/DataTypeIcon';
import UserIconMap from '../../util/icon-helpers/UserIconMap';

interface UseInputResult {
  buildInput: (
    field: Interfaces.Field,
    type: Enums.ValueDataType,
    value: unknown,
    onChange: (field: string, value: unknown) => void,
    error?: boolean,
    disabled?: boolean,
    lockValue?: unknown,
    isLiveEditing?: boolean,
    onBlur?: (field: string, value: unknown) => void,
    rowId?: number,
    pageSize?: number,
  ) => React.ReactElement;
}

const TEXT_AREA_MIN_ROWS = 1;
const TEXT_AREA_MAX_ROWS = 10;

const useInput = (): UseInputResult => {
  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  /**
   * Build a user input
   */
  const buildInput = useCallback(
    (
      field: Interfaces.Field,
      type: Enums.ValueDataType,
      value: unknown,
      onChange: (field: string, value: unknown) => void,
      error?: boolean,
      disabled?: boolean,
      lockValue?: unknown,
      isLiveEditing?: boolean,
      onBlur?: (field: string, value: unknown) => void,
      rowId?: number,
      pageSize?: number,
    ): React.ReactElement => {
      const constraints = field.dataValidation?.constraints;
      const formatting = field.dataValidation?.formatting;

      if (constraints?.listValues) {
        let listVals: Interfaces.ListValue[] | Interfaces.User[];
        let displayColorIndicator;
        let isUserList = false;

        if (Array.isArray(constraints?.listValues)) {
          listVals = constraints.listValues;
        } else {
          const listDoc = (lists?.find(
            (l) => l.entity._id === constraints.listValues,
          )?.entity as UserListOutput) || { values: [] };

          const listType = listDoc?.listType;
          isUserList = listType === Enums.ListType.USER;
          displayColorIndicator = listDoc?.values?.some((lv) => lv.color);
          listVals = isUserList
            ? (listDoc.users as Interfaces.User[])
            : listDoc.values || [];

          // If value locked due to prefilter filter available values
          if (lockValue) {
            if (isUserList) {
              listVals = listDoc.users.filter(
                (user) => user._id === lockValue,
              ) as Interfaces.User[];
            } else {
              listVals =
                listDoc.values?.filter((option) =>
                  (lockValue as string[]).includes(option.value),
                ) || [];
            }
          }
        }

        const singleLockValue =
          (lockValue && Array.isArray(lockValue) && lockValue.length === 1) ||
          (lockValue && !Array.isArray(lockValue));

        if (!isLiveEditing) {
          return (
            <SC.InputDropdown
              selectOnBlur={false}
              disabled={disabled || singleLockValue}
              placeholder={'Select an option'}
              options={listVals?.map((lv, i) => {
                const image = isUserList
                  ? {
                      avatar: true,
                      src: UserIconMap[lv.avatar] || lv.avatar,
                    }
                  : undefined;

                return {
                  key: `list-value-${field.name}-${
                    isUserList ? lv._id : lv.value
                  }-${i}`,
                  value: isUserList ? lv._id : lv.value,
                  text: isUserList
                    ? `${lv.firstName} ${lv.lastName}`
                    : lv.value,
                  label: displayColorIndicator && (
                    <DropdownLabel
                      indicatorcolor={ListOptionColor[lv.color] || lv.color}
                      circular={true}
                      size={'mini'}
                    />
                  ),
                  image,
                } as DropdownItemProps;
              })}
              clearable={!lockValue}
              selection
              search
              value={
                singleLockValue
                  ? Array.isArray(lockValue)
                    ? lockValue[0]
                    : lockValue
                  : value || ''
              }
              onChange={(e, data) => onChange(field.name, data.value)}
              style={{ margin: 0 }}
            />
          );
        } else {
          return (
            <SC.EditableDropdown
              selectOnBlur={false}
              disabled={singleLockValue}
              placeholder={'Select an option'}
              options={listVals?.map((lv, i) => {
                const image = isUserList
                  ? {
                      avatar: true,
                      src: UserIconMap[lv.avatar] || lv.avatar,
                    }
                  : undefined;

                return {
                  key: `list-value-${field.name}-${
                    isUserList ? lv._id : lv.value
                  }-${i}`,
                  value: isUserList ? lv._id : lv.value,
                  text: isUserList
                    ? `${lv.firstName} ${lv.lastName}`
                    : lv.value,
                  label: displayColorIndicator && (
                    <DropdownLabel
                      indicatorcolor={ListOptionColor[lv.color] || lv.color}
                      circular={true}
                      size={'mini'}
                    />
                  ),
                  image,
                } as DropdownItemProps;
              })}
              clearable={!lockValue}
              selection
              search
              upward={!!pageSize && !!rowId && rowId > pageSize / 2}
              value={
                singleLockValue
                  ? Array.isArray(lockValue)
                    ? lockValue[0]
                    : lockValue
                  : value || ''
              }
              onChange={(e, data) => {
                onChange(field.name, data.value);
                onBlur && onBlur(field.name, data.value);
              }}
              style={{ margin: 0 }}
            />
          );
        }
      }

      switch (type) {
        case Enums.ValueDataType.NUMBER: {
          const isPercentage =
            (formatting as Interfaces.NumericalFormatting)?.format ===
            Enums.DataDisplayFormat.PERCENTAGE;

          return (
            <SC.InputField
              onBlur={(e) => onBlur && onBlur(field.name, +e.target.value)}
              disabled={disabled || constraints?.isAutoInc || !!lockValue}
              error={error}
              value={
                lockValue ||
                (value = (value as string)?.toString()?.length ? value : '')
              }
              type={'number'}
              onChange={(e, data) =>
                onChange(field.name, data.value === '' ? null : +data.value)
              }
              icon={
                <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
              }
              iconPosition={'left'}
              label={
                isPercentage && !isLiveEditing ? { content: '%' } : undefined
              }
              labelPosition={'right'}
              className={'input-number'}
              $isLiveEditing={isLiveEditing}
              min={constraints?.min}
              max={constraints?.max}
            />
          );
        }

        case Enums.ValueDataType.DATE:
          return (
            <DateTimePicker
              isLiveEditing={isLiveEditing}
              disabled={disabled}
              style={{ width: 400 }}
              value={(value as string) || ''}
              onChange={
                !isLiveEditing
                  ? (date) => onChange(field.name, date)
                  : (date) => {
                      onChange(field.name, date);
                      onBlur && onBlur(field.name, date as string);
                    }
              }
              dateFormat={field.dataValidation?.constraints?.format}
            />
          );

        case Enums.ValueDataType.BOOL:
          return (
            <SC.InputDropdown
              selectOnBlur={false}
              placeholder={'Select an option'}
              options={[
                {
                  key: `boolean-${field.name}-true`,
                  value: 'true',
                  text: 'True',
                },
                {
                  key: `boolean-${field.name}-false`,
                  value: 'false',
                  text: 'False',
                },
              ]}
              selection
              value={
                typeof value !== 'undefined'
                  ? (value as string).toString()
                  : 'false'
              }
              onChange={(e, data) =>
                onChange(field.name, data.value === 'true')
              }
              style={{ margin: 0 }}
            />
          );

        case Enums.ValueDataType.EMAIL:
        case Enums.ValueDataType.PHONE_NUM:
        case Enums.ValueDataType.POSTCODE:
          return (
            <SC.InputField
              $isLiveEditing={isLiveEditing}
              disabled={disabled || !!lockValue}
              value={lockValue || value || ''}
              onChange={(e, data) => onChange(field.name, data.value as string)}
              onBlur={(e) =>
                onBlur && onBlur(field.name, e.target.value as string)
              }
              error={error}
              icon={
                <Icon name={getDataTypeIcon(type, true) as SemanticICONS} />
              }
              iconPosition={'left'}
            />
          );

        default:
          if (!isLiveEditing) {
            return (
              <SC.InputFieldArea
                disabled={disabled || !!lockValue}
                minRows={TEXT_AREA_MIN_ROWS}
                maxRows={TEXT_AREA_MAX_ROWS}
                value={(lockValue as string) || (value as string) || ''}
                onChange={(e) => onChange(field.name, e.target.value as string)}
                style={
                  error
                    ? { border: `1px solid ${defaultTheme.colors.general.red}` }
                    : undefined
                }
              />
            );
          }
          return (
            <SC.EditableInput
              disabled={disabled || !!lockValue}
              value={(lockValue as string) || (value as string) || ''}
              onBlur={(e) =>
                onBlur && onBlur(field.name, e.target.value as string)
              }
              onChange={(e) => onChange(field.name, e.target.value as string)}
              style={
                error
                  ? { border: `1px solid ${defaultTheme.colors.general.red}` }
                  : undefined
              }
            />
          );
      }
    },
    [lists],
  );

  return { buildInput };
};

export default useInput;
