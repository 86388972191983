import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import { GraphType } from '@configur-tech/upit-core-types/lib/enums';
import { MapGraphParams } from '@configur-tech/upit-core-types/lib/interfaces';
import { QueryField } from '@configur-tech/upit-core-types/lib/interfaces/models/input/query/FilterField';
import { faExpand, faTrash, faWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DefaultLoadingIcon from '../../assets/icons/loading/default-loading-icon.gif';
import GraphFailLogo from '../../assets/icons/system/GraphFailIcon.svg';
import { EntityType, ProjectStage, RouteName } from '../../enums';
import useChart from '../../hooks/chart/UseChart';
import useCMS from '../../hooks/cms/useCMS';
import useTableData from '../../hooks/dataset-meta/UseTableData';
import useDataset from '../../hooks/dataset/UseDataset';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import useGraph from '../../hooks/graph/UseGraph';
import useIsVisible from '../../hooks/visibility/UseIsVisible';
import { SampleData, SampleDataRow } from '../../interfaces/SampleData';
import { StyledText } from '../../main/theme';
import { DatasetMetaItemOutput } from '../../services/dataset-meta/DatasetMetaService';
import { MAP_GRAPH_PAGE_SIZE } from '../../services/dataset/DatasetService';
import { ListItemOutput } from '../../services/list/ListService';
import { DatasetState, GRAPH_RAW_DATA } from '../../store/dataset';
import { fetchGraphSuccess, GraphOutputWithDetails } from '../../store/graph';
import { hideModal, showModal } from '../../store/modal';
import {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
} from '../../store/project-stage';
import { ProjectGraphSubStage } from '../../store/project-stage/initial-state';
import { QueryWithDetails } from '../../store/queries';
import { RootState } from '../../store/rootReducer';
import cleanGraphFilters from '../../util/cleanGraphFilters/cleanGraphFilters';
import queryParamsToFilter from '../../util/query-params-to-filter/QueryParamsToFilter';
import { AREA_CONTROL_BUTTON_SIZE } from '../ChartBuilder/ChartBuilder';
import FeatureButton from '../FeatureButton/FeatureButton';
import {
  LOCAL_STORAGE_DASHBOARD_URL,
  LOCAL_STORAGE_TEMP_FILTERS_KEY,
  Params,
} from '../Modal/graph/GraphModal';
import { ModalTypes } from '../Modal/Modal';
import Graph, { FormattedGraphData } from './Graph';
import * as SC from './styled';

interface GraphDisplayProps {
  graph: Interfaces.GraphOutput;
  graphData?: Record<string, unknown>[];
  isEditing?: boolean;
  handleDeleteArea?: () => void;
  readOnly?: boolean;
  params?: Params;
}

const EXPAND_BUTTON_SIZE = 20;

const GraphDisplay: FC<GraphDisplayProps> = ({
  graph,
  graphData,
  isEditing,
  handleDeleteArea,
  readOnly,
  params,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { getGraphDataset } = useDataset();
  const { chartSaving } = useChart();
  const { buildGraphFilters, handleClickedGanttTask } = useGraph();
  const { projectId, cmsId, portalId, groupId } = useParams();
  const { cms } = useCMS();
  const history = useHistory();
  const graphRef = useRef(null);
  const isVisible = useIsVisible(graphRef);

  const datasetMetasState = useSelector(
    (state: RootState) => state.datasetMetas,
  );
  const datasetMetas: DatasetMetaItemOutput[] = datasetMetasState.data.data;
  const datasetState: DatasetState = useSelector(
    (state: RootState) => state.dataset,
  );
  const listsState = useSelector((state: RootState) => state.lists);
  const lists: ListItemOutput[] = listsState.data.data;

  const chartState = useSelector((state: RootState) => state.chart)?.data;

  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, unknown>[]>();
  const [hasMoreData, setHasMoreData] = useState<boolean>();
  const [filters, setFilters] = useState<DynamicConditionalField[]>();
  const [refreshAttempt, setRefreshAttempt] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const { createTableData } = useTableData();
  const [entrySchema, setEntrySchema] = useState<Interfaces.SchemaField[]>([]);
  const [tableData, setTableData] = useState<SampleData>();

  const graphDataRaw = useMemo(() => {
    return datasetState?.data?.[`${graph._id}${GRAPH_RAW_DATA}`]?.entries || [];
  }, [datasetState?.data, graph._id]);

  // Set sample/schema data
  useEffect(() => {
    if (graphDataRaw.length) {
      const schema = Object.keys(graphDataRaw[0]).map((k) => {
        const selectedGraph = chartState?.entity.graphs.find(
          (g) => g._id === graph._id,
        );

        const markerColour = (selectedGraph?.graphParams as MapGraphParams)
          ?.markerColourMetric;

        // Get schema from Graph additionalDetails
        const schemaFields = (
          graph as GraphOutputWithDetails
        )?.additionalDetails?.find(
          (d) =>
            d.datasetMetaId ===
            (markerColour?.field as QueryField)?.datasetMetaId,
        )?.schemaData;

        const schemaField = schemaFields?.find(
          (field) =>
            field.fieldId === (markerColour?.field as QueryField).field,
        );

        const listId = schemaField?.dataValidation?.constraints?.listValues;

        const list = lists.find(
          (l) =>
            l.entity._id === listId &&
            l.entity.listType === Enums.ListType.STANDARD,
        );

        const field = (
          graph as GraphOutputWithDetails
        )?.additionalDetails?.[0]?.schemaData?.find((f) => f.name === k);

        return {
          field: k,
          type: [
            Services.validators.DataValidator.isNumber(k)
              ? Enums.ValueDataType.NUMBER
              : Enums.ValueDataType.TEXT,
          ],
          constraints: {
            ...field?.dataValidation?.constraints,
            listValues: list?.entity.values,
          },
          formatting: field?.dataValidation?.formatting,
        };
      });
      setEntrySchema(schema);
      setTableData(createTableData(schema, graphDataRaw));
    }
  }, [
    chartState?.entity.graphs,
    createTableData,
    datasetMetas,
    graph,
    graph?._id,
    graphDataRaw,
    lists,
  ]);

  // Build and set filters
  useEffect(() => {
    if (graph._id) {
      const fil =
        buildGraphFilters(
          graph._id,
          location.pathname.includes(RouteName.CMS),
        ) || [];
      if (!isEqual(fil, filters)) {
        setFilters(fil);
        setFetchedData(false);
      }
    }
  }, [buildGraphFilters, filters, graph._id]);

  // Fetch data
  useEffect(() => {
    if (!fetchedData && filters && isVisible) {
      (async () => {
        try {
          const datasetMetaIds =
            (graph as GraphOutputWithDetails).additionalDetails?.map(
              (detail) => detail.datasetMetaId,
            ) || undefined;

          const cleanedFilters = cleanGraphFilters(
            filters as DynamicConditionalField[],
            datasetMetaIds || [],
          );

          await getGraphDataset(
            graph._id,
            cleanedFilters,
            undefined,
            undefined,
            undefined,
            graph.graphParams.graphType === GraphType.MAP
              ? MAP_GRAPH_PAGE_SIZE
              : undefined,
          );
          await getGraphDataset(
            graph._id,
            cleanedFilters,
            undefined,
            undefined,
            true,
            graph.graphParams.graphType === GraphType.MAP
              ? MAP_GRAPH_PAGE_SIZE
              : undefined,
          );
          setFetchedData(true);
        } catch (_e) {
          setData([]);
          setLoading(false);
          setFetchedData(true);
        }
      })();
    }
  }, [getGraphDataset, isVisible, graph, fetchedData, filters, graph._id]);

  // Set Graph Data
  useEffect(() => {
    if (fetchedData && isVisible && datasetState?.data?.[graph._id]) {
      setLoading(true);
      setData(datasetState?.data?.[graph._id]?.entries);
      setHasMoreData(datasetState?.data?.[graph._id]?.hasMoreEntries);
      setLoading(false);
    }
  }, [fetchedData, datasetState?.data, graph._id, isVisible]);

  const handleClickedAggregationRow = (rowId: number) => {
    const redirectUrl = localStorage.getItem(LOCAL_STORAGE_DASHBOARD_URL);
    if (!redirectUrl && graph.graphParams.graphType === Enums.GraphType.TABLE) {
      rowId = rowId - 1;
      localStorage.setItem(
        LOCAL_STORAGE_DASHBOARD_URL,
        JSON.stringify(window.location.pathname),
      );
    }

    if (
      !cmsId ||
      !tableData?.rows ||
      !graph?.queryParams?.fields ||
      graph?.queryParams.aggregationType !== Enums.AggregationType.SINGLE
    ) {
      return;
    }

    const customFilters =
      graph?.graphParams.graphType === Enums.GraphType.MAP && filters;

    const combinedQueryFilters = queryParamsToFilter(
      graph as unknown as QueryWithDetails,
      tableData?.rows[rowId] as SampleDataRow,
      customFilters as Interfaces.DynamicConditionalField[],
    );

    // Check if all datasetMetas exist in this workspace
    const cmsConfGroupsDatasetIds = (
      cms?.configuration as Interfaces.CMSConnectionConfigurationOutput
    ).groups
      .flatMap((conf) => conf.items)
      .map((item) => item.datasetMetaId);

    if (
      combinedQueryFilters
        .map((filter) => filter.datasetMetaId)
        .some((dsmId) => !cmsConfGroupsDatasetIds.includes(dsmId))
    ) {
      return;
    }

    // Navigate to dataset with built filters
    const histObj = {
      pathname: portalId
        ? `${RouteName.PORTAL}/${params?.portalId || portalId}/cms/${
            params?.cmsId || cmsId
          }/${params?.groupId || groupId}/dataset/${
            graph?.queryParams?.datasetMetaId
          }`
        : `${RouteName.PROJECT_ITEM}/${params?.projectId || projectId}/cms/${
            params?.cmsId || cmsId
          }/${params?.groupId || groupId}/dataset/${
            graph?.queryParams?.datasetMetaId
          }`,

      state: combinedQueryFilters
        ? {
            filters: JSON.stringify(
              combinedQueryFilters.find(
                (f) => f.datasetMetaId === graph?.queryParams?.datasetMetaId,
              )?.filters,
            ),
            filterType: EntityType.CHART,
          }
        : undefined,
    };

    if (!combinedQueryFilters) {
      return;
    }

    if (graph.graphParams.graphType !== Enums.GraphType.TABLE) {
      localStorage.setItem(
        `${LOCAL_STORAGE_DASHBOARD_URL}`,
        JSON.stringify(window.location.pathname),
      );
    }

    localStorage.setItem(
      `${LOCAL_STORAGE_TEMP_FILTERS_KEY}`,
      JSON.stringify(histObj?.state),
    );

    history.push(histObj);

    dispatch(hideModal());
  };

  const GraphModalButton = () => (
    <>
      {!readOnly && (
        <FeatureButton
          action={() => {
            const selectedGraph = chartState?.entity.graphs.find(
              (g) => g._id === graph._id,
            );

            if (selectedGraph) {
              dispatch(
                fetchGraphSuccess({
                  accessLevel: Enums.AccessLevel.MANAGE,
                  entity: selectedGraph,
                }),
              );

              dispatch(updateActiveProjectStage(ProjectStage.GRAPH));
              dispatch(updateActiveProjectSubStage(ProjectGraphSubStage.GRAPH));
            }
          }}
          size={AREA_CONTROL_BUTTON_SIZE}
          color={themeContext.colors.general.blue}
          icon={
            <FontAwesomeIcon
              icon={faWrench}
              color={themeContext.colors.general.white}
              size={'sm'}
            />
          }
          style={{ width: '100%' }}
          containerStyle={{
            alignSelf: 'flex-start',
            marginLeft: themeContext.margin.small,
            marginRight: themeContext.margin.small,
          }}
          isDisabled={chartSaving}
        />
      )}
      <FeatureButton
        action={() =>
          dispatch(
            showModal({
              visible: true,
              modal: ModalTypes.GRAPH,
              fullScreen: true,
              additionalProps: {
                graph: graph,
                graphData: data,
                params: {
                  projectId,
                  cmsId,
                  groupId,
                  portalId,
                },
                dataDrilldown: (rowId: number) =>
                  handleClickedAggregationRow(rowId),
              },
            }),
          )
        }
        size={EXPAND_BUTTON_SIZE}
        color={themeContext.colors.general.blue}
        icon={
          <FontAwesomeIcon
            icon={faExpand}
            color={themeContext.colors.system.white}
            size={'sm'}
          />
        }
        style={{ width: '100%' }}
        containerStyle={{
          alignSelf: 'flex-start',
        }}
      />
      {!readOnly && handleDeleteArea && (
        <FeatureButton
          action={() => handleDeleteArea()}
          size={AREA_CONTROL_BUTTON_SIZE}
          color={themeContext.colors.general.red}
          icon={
            <FontAwesomeIcon
              icon={faTrash}
              color={themeContext.colors.general.white}
              size={'sm'}
            />
          }
          style={{ width: '100%' }}
          containerStyle={{
            alignSelf: 'flex-start',
            marginLeft: themeContext.margin.small,
          }}
          isDisabled={chartSaving}
        />
      )}
    </>
  );

  const graphParams = graph?.graphParams as Interfaces.GraphParams;
  const mapParams = graph?.graphParams as Interfaces.MapGraphParams;
  const isMap = graph?.graphParams.graphType === Enums.GraphType.MAP;
  const isTable = graph?.graphParams.graphType === Enums.GraphType.TABLE;
  const isGantt = graph?.graphParams.graphType === Enums.GraphType.GANTT;
  const mapComplete =
    isMap &&
    !!mapParams.latitudeMetric?.field?.alias &&
    !!mapParams.longitudeMetric?.field?.alias &&
    !!mapParams.markerTitleMetric?.field?.alias;

  return (
    <SC.GraphWrapper ref={graphRef} readOnly={readOnly}>
      {!fetchedData && (
        <SC.LoadingContainer>
          <SC.LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />
        </SC.LoadingContainer>
      )}
      {!graphParams.graphType && (
        <SC.GraphInfo>Select a Graph Type to display your data</SC.GraphInfo>
      )}
      <>
        {fetchedData && (
          <>
            {isEditing && (
              <>
                {!isMap &&
                  !graphParams?.graphMetrics?.filter((g) => g.active).length &&
                  !isTable &&
                  !isGantt && (
                    <SC.GraphInfo>
                      Select your data and graph options from the sidebar
                    </SC.GraphInfo>
                  )}

                {hasMoreData && (
                  <SC.GraphWarning>
                    This graph has more data which isn't displayed. Group or
                    filter your graph data further.
                  </SC.GraphWarning>
                )}

                {((graphParams.xAxisMetric &&
                  !!graphParams?.graphMetrics?.filter((g) => g.active)
                    .length) ||
                  mapComplete ||
                  isTable ||
                  isGantt) && (
                  <SC.GraphContainer>
                    {!graphData && (
                      <SC.ExpandContainer>
                        {graph?.graphParams.graphTextOpts?.title && (
                          <SC.GraphHeading isEditing={isEditing}>
                            {graph?.graphParams.graphTextOpts?.title}
                          </SC.GraphHeading>
                        )}
                        {!isEditing && <GraphModalButton />}
                      </SC.ExpandContainer>
                    )}
                    <Graph
                      graph={graph}
                      data={data as unknown as FormattedGraphData[]}
                      tableData={tableData}
                      schema={entrySchema}
                    />
                  </SC.GraphContainer>
                )}
              </>
            )}

            {!isEditing &&
              ((graphParams.xAxisMetric &&
                !!graphParams?.graphMetrics?.filter((g) => g.active).length) ||
                mapComplete ||
                isTable ||
                isGantt) && (
                <>
                  {!graphData && (
                    <SC.ExpandContainer>
                      {graph?.graphParams.graphTextOpts?.title && (
                        <SC.GraphHeading isEditing={isEditing}>
                          {graph?.graphParams.graphTextOpts?.title}
                        </SC.GraphHeading>
                      )}
                      {!isEditing && <GraphModalButton />}
                    </SC.ExpandContainer>
                  )}

                  {!data?.length && !isEditing && !loading && (
                    <SC.ErrorContainer>
                      <img src={GraphFailLogo} alt={'Graph failed to load'} />
                      <StyledText>Graph failed to load</StyledText>
                      <SC.RefreshText
                        onClick={() => {
                          if (refreshAttempt >= 3) {
                            return;
                          }
                          setRefreshAttempt(refreshAttempt + 1);
                          setFetchedData(false);
                        }}
                        isDisabled={refreshAttempt >= 3}
                      >
                        {refreshAttempt >= 3
                          ? 'Refresh limit reached'
                          : 'Refresh'}
                      </SC.RefreshText>
                    </SC.ErrorContainer>
                  )}

                  {!!data?.length && !loading && (
                    <Graph
                      graph={graph}
                      data={data as unknown as FormattedGraphData[]}
                      dataDrilldown={(rowId) =>
                        (cmsId &&
                          handleClickedAggregationRow(parseInt(rowId))) ||
                        (graph.graphParams.graphType ===
                          Enums.GraphType.TABLE &&
                          handleClickedAggregationRow(parseInt(rowId)))
                      }
                      schema={entrySchema}
                      dataDrilldownGantt={(task) =>
                        handleClickedGanttTask(graph, task, params)
                      }
                    />
                  )}
                </>
              )}
          </>
        )}
      </>
    </SC.GraphWrapper>
  );
};

export default GraphDisplay;
