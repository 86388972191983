import { StyledText } from '../../../main/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const Header = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h2};
`;

export const ErrorText = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  color: ${({ theme }) => theme.colors.general.red};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const BodyText = styled(StyledText)`
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const ActionButtonWrapper = styled.div<{ multipleButtons?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ multipleButtons }) =>
    multipleButtons ? 'space-between' : 'flex-end'};

  background: ${({ theme }) => theme.colors.system.offWhite};

  padding: ${({ theme }) => theme.margin.xlarge};
  border-top: 1px solid ${({ theme }) => theme.colors.system.grey};
`;

export const ColumnTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.margin.standard};

  > div {
    margin-right: ${({ theme }) => theme.margin.large};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const SubColumnTypeWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxxlarge};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.margin.xlarge};

  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.hr`
  width: 650px;
  border: 1px solid ${({ theme }) => theme.colors.system.offWhite};
  margin: ${({ theme }) => theme.margin.standard} 0
    ${({ theme }) => theme.margin.xlarge};
`;

export const FormulaCalcWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const OutputHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.standard};

  > p {
    margin: 0;
  }

  > div {
    margin-left: ${({ theme }) => theme.margin.standard};
  }
`;

export const SubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-top: ${({ theme }) => theme.margin.standard};
  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const FormulaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.padding.standard};
  margin-bottom: ${({ theme }) => theme.margin.large};

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      display: flex;
      margin: ${({ theme }) => theme.margin.small};
    }
  }
`;

export const FormulaOutputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 79px;

  background-color: ${({ theme }) => theme.colors.system.offWhite};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.padding.standard};
  margin-bottom: ${({ theme }) => theme.margin.large};

  > div {
    margin: ${({ theme }) => theme.margin.small};

    &:last-of-type {
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
`;

export const OutputResultButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  position: relative;

  width: 200px;
  height: 42px;

  padding: 0;

  border: 2px dashed ${({ theme }) => theme.colors.system.darkGrey};
  border-radius: ${({ theme }) => theme.borders.radius};

  color: ${({ theme }) => theme.colors.system.darkOffBlack};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  ${({ theme }) => theme.typography.header};
  text-transform: uppercase;
`;
