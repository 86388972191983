import { Interfaces } from '@configur-tech/upit-core-types';
import { SchemaFieldPermissions } from '@configur-tech/upit-core-types/lib/interfaces/dataset-meta/schema/SchemaFieldPermissions';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, set } from 'lodash';
import { FC, useContext } from 'react';
import Toggle from 'react-toggle';
import { ThemeContext } from 'styled-components';
import {
  defaultTheme,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledBodySubHeader,
  StyledSubHeader,
  StyledText,
} from '../../../../main/theme';
import {
  OptionalField,
  OptionalFieldWrapper,
  OptionalWrapper,
} from '../styled';

interface PermissionOptionsProps {
  active: boolean;
  activeToggle: () => void;
  schemaField: Interfaces.Field;
  onChange: (updatedSchemaField: Interfaces.Field) => void;
  existingPermissions?: SchemaFieldPermissions;
}

const MANUAL_EDIT_FIELD = 'allowManualEdits';

const PermissionOptions: FC<PermissionOptionsProps> = ({
  active,
  activeToggle,
  schemaField,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const handlePermissionChange = (field: string, value: unknown) => {
    if (field && schemaField) {
      const cloned = cloneDeep(schemaField);

      set(cloned, `dataValidation.permissions.${field}`, value);

      onChange(cloned);
    }
  };

  const FieldManualEdits = (
    <OptionalField>
      <StyledBodySubHeader>Allow Manual Updates</StyledBodySubHeader>
      <StyledText>
        Turning this option off will only allow value updates via pipelines and
        APIs.
      </StyledText>

      <Toggle
        checked={
          get(
            schemaField,
            `dataValidation.permissions.${MANUAL_EDIT_FIELD}`,
          ) !== false
        }
        icons={false}
        onChange={(event) =>
          handlePermissionChange(MANUAL_EDIT_FIELD, !!event.target.checked)
        }
      />
    </OptionalField>
  );

  return (
    <>
      <StyledAccordionTitle
        active={active}
        index={0}
        onClick={activeToggle}
        style={{
          backgroundColor: themeContext.colors.system.offWhite,
        }}
      >
        <StyledSubHeader>Permission Options</StyledSubHeader>
        <FontAwesomeIcon
          icon={active ? faChevronUp : faChevronDown}
          color={defaultTheme.colors.system.offBlack}
        />
      </StyledAccordionTitle>

      <StyledAccordionContent active={active}>
        <OptionalWrapper>
          <StyledSubHeader>Edit Options</StyledSubHeader>

          <OptionalFieldWrapper
            style={{
              marginTop: themeContext.margin.large,
            }}
          >
            {FieldManualEdits}
          </OptionalFieldWrapper>
        </OptionalWrapper>
      </StyledAccordionContent>
    </>
  );
};

export default PermissionOptions;
