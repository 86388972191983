import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectStage } from '../../enums/ProjectStage';
import InitialProjectStage, {
  ProjectCreationSubStage,
  ProjectSubStages,
} from './initial-state';

const ProjectStageSlice = createSlice({
  name: 'projectStage',
  initialState: InitialProjectStage,
  reducers: {
    updateActiveProjectStage(
      state,
      action: PayloadAction<ProjectStage | Enums.ConnectionType>,
    ) {
      state.activeStage = action.payload;
    },

    updateActiveProjectSubStage(
      state,
      action: PayloadAction<ProjectSubStages | undefined>,
    ) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },

    updateActiveConnection(
      state,
      action: PayloadAction<Interfaces.ConnectionOutput | undefined>,
    ) {
      state.activeConnection = action.payload;
    },

    resetStagesAndSubStages(state) {
      state.activeStage = ProjectStage.CREATION;
      state.stages[ProjectStage.CREATION].activeSubStage =
        ProjectCreationSubStage.NAME;
    },

    resetAggregationStage(state) {
      state.activeStage = ProjectStage.AGGREGATION;
      state.stages[ProjectStage.AGGREGATION].activeSubStage = undefined;
    },

    resetChartsStage(state) {
      state.activeStage = ProjectStage.CHARTS;
      state.stages[ProjectStage.CHARTS].activeSubStage = undefined;
    },

    resetDataviewStage(state) {
      state.activeStage = Enums.ConnectionType.CMS;
      state.stages[Enums.ConnectionType.CMS].activeSubStage = undefined;
    },

    resetFormStage(state) {
      state.activeStage = Enums.ConnectionType.FORM;
      state.stages[Enums.ConnectionType.FORM].activeSubStage = undefined;
    },

    resetPortalStage(state) {
      state.activeStage = Enums.ConnectionType.PORTAL;
      state.stages[Enums.ConnectionType.PORTAL].activeSubStage = undefined;
    },
  },
});

export const {
  updateActiveProjectStage,
  updateActiveProjectSubStage,
  updateActiveConnection,
  resetStagesAndSubStages,
  resetAggregationStage,
  resetChartsStage,
  resetDataviewStage,
  resetFormStage,
  resetPortalStage,
} = ProjectStageSlice.actions;

export default ProjectStageSlice.reducer;
