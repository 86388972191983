import { Element } from 'domhandler/lib/node';
import parse from 'html-react-parser';
import { DateTime } from 'luxon';
import { FC } from 'react';
import useLoggedInUser from '../../../hooks/logged-in-user/UseLoggedInUser';
import { CommentItemOutput } from '../../../store/comments';
import * as SC from './styled';

interface CMSCommentsProps {
  comment: CommentItemOutput;
}

const DATE_FORMAT = 'HH:mm dd/MM/yyyy';
const COMMENT_OWNER_NAME = 'You';
const TARGET_BLANK = '_blank';
const DOM_TYPE = 'tag';
const DOM_NAME = 'a';

const Comment: FC<CMSCommentsProps> = ({ comment }) => {
  const { loggedInUser } = useLoggedInUser();
  const commentEntity = comment.entity;
  const isOwner = commentEntity.meta.createdBy === loggedInUser?._id;

  return (
    <SC.Comment isOwner={isOwner} key={comment.entity._id}>
      <SC.CommentMessage isOwner={isOwner}>
        {commentEntity.message.text && (
          <p>
            {parse(commentEntity.message.text, {
              replace: (domNode) => {
                const { attribs, name, type } = domNode as Element;

                // Add target="_blank" to any links
                if (type === DOM_TYPE && name === DOM_NAME) {
                  attribs.target = TARGET_BLANK;
                }
              },
            })}
          </p>
        )}
      </SC.CommentMessage>
      <SC.CommentMeta isOwner={isOwner}>
        <SC.CommentMetaName>
          {isOwner
            ? COMMENT_OWNER_NAME
            : `${commentEntity.author.firstName} ${commentEntity.author.lastName}`}
        </SC.CommentMetaName>
        <span>
          {DateTime.fromISO(commentEntity.meta.created).toFormat(DATE_FORMAT)}
        </span>
      </SC.CommentMeta>
    </SC.Comment>
  );
};

export default Comment;
