import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { DynamicConditionalField } from '../../hooks/filter/UseFilter';
import HttpHelper from '../../util/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_DATASET_EXPORTER'] || '';

export interface DatasetExportQuery {
  _id: string;
  columns?: string[];
  conditions?: DynamicConditionalField[];
  limit?: number;
  orderBy?: string;
}

export interface ExporterResponse {
  uploadedFileURLs: string[];
}

export default class DatasetExporterService {
  public static async exportDatasets(
    token: string,
    datasetMetas: DatasetExportQuery[],
    exportFormat: Enums.AcceptedFileType,
    applyFormatting: boolean,
    portalId?: string,
    connectionGroupId?: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<ExporterResponse>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.post<
      | Interfaces.SuccessResponseBody<ExporterResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      datasetMetas,
      exportFormat,
      portalId,
      applyFormatting,
      connectionGroupId,
    });
  }

  public static async exportQuery(
    token: string,
    query: {
      _id: string;
      conditions?: DynamicConditionalField[];
    },
    exportFormat: Enums.AcceptedFileType,
    applyFormatting: boolean,
    portalId?: string,
    connectionGroupId?: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<ExporterResponse>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.post<
      | Interfaces.SuccessResponseBody<ExporterResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      query,
      exportFormat,
      portalId,
      applyFormatting,
      connectionGroupId,
    });
  }

  public static async exportSnapshot(
    token: string,
    exportFormat: Enums.AcceptedFileType,
    applyFormatting: boolean,
    snapshotId: string,
  ): Promise<
    | Interfaces.SuccessResponseBody<ExporterResponse>
    | Interfaces.ErrorResponseBody
  > {
    const ah = new HttpHelper(token);

    return await ah.post<
      | Interfaces.SuccessResponseBody<ExporterResponse>
      | Interfaces.ErrorResponseBody
    >(endpoint, {
      exportFormat,
      snapshotId,
      applyFormatting,
    });
  }
}
