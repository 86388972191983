import { Enums } from '@configur-tech/upit-core-types';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React, { FC, forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { InputProps } from 'semantic-ui-react';
import { dynamicDate } from '../../const/DateConst';
import { defaultTheme, StyledDropdown } from '../../main/theme';
import getDataTypeIcon from '../../util/data-type-icon/DataTypeIcon';
import {
  escapeDateFormatChars,
  hourOnlyFormats,
  monthOnlyFormats,
  quarterOnlyFormats,
  TIME_INTERVAL_HOUR,
  TIME_INTERVAL_MINUTE,
  timeOnlyFormats,
  timeRequiredFormats,
  yearOnlyFormats,
} from './DateTimePicker';
import * as SC from './styled';

interface DateTimePickerDynamicValuesProps {
  onChange: (date) => void;
  value?: string;
  dateFormat?: Enums.DateFormat | string;
  popperPlacement?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const DATE_STRING_MAX_LENGTH = 26;

const DateTimePickerDynamicValues: FC<DateTimePickerDynamicValuesProps> = ({
  onChange,
  value,
  dateFormat = Enums.DateFormat.DATE_SHORT,
  popperPlacement,
  style,
  disabled,
}) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  let val = DateTime.fromFormat(value, dateFormat);
  if (val.invalid) {
    val = null;
  }

  const CalendarPopupButton = forwardRef<HTMLButtonElement, InputProps>(
    ({ onClick }, ref) => (
      <SC.CalendarButton
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        multiLine={!!value && value.length > DATE_STRING_MAX_LENGTH}
      >
        <SC.CalendarIcon
          icon={getDataTypeIcon(Enums.ValueDataType.DATE) as IconDefinition}
          color={calendarOpen ? '#000000de' : '#9b9b9b'}
        />
      </SC.CalendarButton>
    ),
  );

  return (
    <SC.DatePickerWrapper>
      <SC.StyledDropdownWrapper
        multiLine={!!value && value.length > DATE_STRING_MAX_LENGTH}
      >
        <StyledDropdown
          selectOnBlur={false}
          disabled={disabled}
          placeholder={'Select an option'}
          options={
            val
              ? [
                  ...dynamicDate,
                  {
                    key: `date-value-custom`,
                    value: val.toFormat(dateFormat),
                    text: val.toFormat(dateFormat),
                  },
                ]
              : dynamicDate
          }
          value={value || ''}
          onChange={(e, data) => onChange(data.value)}
          upward={true}
          style={{ margin: 0, paddingLeft: defaultTheme.padding.xxxlarge }}
          clearable
          selection
        />
      </SC.StyledDropdownWrapper>

      <DatePicker
        customInput={<CalendarPopupButton />}
        disabled={disabled}
        style={style}
        dateFormat={escapeDateFormatChars(dateFormat)}
        selected={val ? new Date(val.toString()) : null}
        popperPlacement={popperPlacement || 'top'}
        onChange={(date) => {
          onChange(
            date ? DateTime.fromJSDate(date).toFormat(dateFormat) : null,
          );
        }}
        onCalendarClose={() => {
          setCalendarOpen(false);
        }}
        onCalendarOpen={() => setCalendarOpen(true)}
        showTimeSelect={
          timeRequiredFormats.includes(dateFormat as Enums.DateFormat) ||
          timeOnlyFormats.includes(dateFormat as Enums.DateFormat)
        }
        timeIntervals={
          hourOnlyFormats.includes(dateFormat as Enums.DateFormat)
            ? TIME_INTERVAL_HOUR
            : TIME_INTERVAL_MINUTE
        }
        showTimeSelectOnly={timeOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showMonthYearPicker={monthOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showYearPicker={yearOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
        showQuarterYearPicker={quarterOnlyFormats.includes(
          dateFormat as Enums.DateFormat,
        )}
      />
    </SC.DatePickerWrapper>
  );
};

export default DateTimePickerDynamicValues;
