import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserService, {
  UserItem,
  UserItemOutput,
} from '../../services/user/UserService';
import { AppThunk } from '../store';
import InitialUser from '../user/initial-user';

type User = UserItem | UserItemOutput;

export interface LoggedInUserState {
  data: User;
  loading: boolean;
  error: string | null;
}

const initialState: LoggedInUserState = {
  data: InitialUser,
  loading: false,
  error: null,
};

const LoggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    // Fetch
    fetchLoggedInUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLoggedInUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchLoggedInUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update
    updateLoggedInUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateLoggedInUserSuccess(state, action: PayloadAction<User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateLoggedInUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLoggedInUserStart,
  fetchLoggedInUserSuccess,
  fetchLoggedInUserFailure,
} = LoggedInUserSlice.actions;

export default LoggedInUserSlice.reducer;

export const fetchLoggedInUser =
  (token: string, auth0Id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchLoggedInUserStart());
      const fetched = await UserService.getUserByAuth0Id(token, auth0Id);
      dispatch(fetchLoggedInUserSuccess(fetched));
    } catch (err) {
      dispatch(fetchLoggedInUserFailure((err as string).toString()));
    }
  };
