import { Enums, Interfaces } from '@configur-tech/upit-core-types';

/**
 * Gets fields formula depends on
 *
 * @param {Interfaces.FormulaOperandValue[]} formula - Formula to fetch dependencies for
 *
 * @returns {string[]} - List of fields formula depends on
 */
const getFormulaDependencies = (
  formula: Interfaces.FormulaOperandValue[],
): string[] => {
  return Array.from(
    formula.reduce((acc, operand) => {
      switch (operand.type) {
        case Enums.FormulaOperandType.FIELD:
          return acc.add(operand.value.field);
        case Enums.FormulaOperandType.NESTED: {
          const nestedFields = getFormulaDependencies(operand.value.formula);
          nestedFields.map((field) => acc.add(field));
          return acc;
        }
        case Enums.FormulaOperandType.UNARY: {
          const unaryFields = getFormulaDependencies([operand.value.value]);
          unaryFields.map((field) => acc.add(field));
          return acc;
        }
        case Enums.FormulaOperandType.AGGREGATE: {
          const nestedFields = getFormulaDependencies(operand.value.fields);
          nestedFields.map((field) => acc.add(field));
          return acc;
        }
        case Enums.FormulaOperandType.CONSTANT:
        case Enums.FormulaOperandType.OPERATION:
        default:
          return acc;
      }
    }, new Set<string>()),
  );
};

export default getFormulaDependencies;
