import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import ValidationResultDatasetStage from '../../components/DatasetStages/DatasetValidationStages/3-result/ValidationResultDatasetStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import useDatasetCollection from '../../hooks/dataset-collection/UseDatasetCollection';
import useDatasetMeta from '../../hooks/dataset-meta/UseDatasetMeta';
import { SampleDataRow } from '../../interfaces/SampleData';
import { DatasetValidateSubStage } from '../../store/dataset-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import Page from '../../main/Page';
import {
  getStageTitle,
  getSubStageContent,
} from '../../util/stage-content/StageHeaderContent';

interface DatasetItemValidationProps {
  sampleData: SampleDataRow[];
  setSampleData: React.Dispatch<React.SetStateAction<SampleDataRow[]>>;
}

const DatasetItemValidation: FC<DatasetItemValidationProps> = ({
  sampleData,
  setSampleData,
}) => {
  const { datasetMeta } = useDatasetMeta();
  const { collection } = useDatasetCollection();

  const datasetStageState = useSelector(
    (state: RootState) => state.datasetStage,
  );

  const activeStage = Enums.DatasetStage.VALIDATION;
  const activeSubStage = datasetStageState.stages[activeStage].activeSubStage;

  const stageTitle = getStageTitle(activeStage);
  const subStageContent = getSubStageContent(
    activeStage,
    activeSubStage,
    datasetMeta,
    collection?.collectionId,
  );

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case DatasetValidateSubStage.RESULT:
        return (
          <ValidationResultDatasetStage
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
      default:
        return (
          <ValidationResultDatasetStage
            sampleData={sampleData}
            setSampleData={setSampleData}
          />
        );
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: datasetStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={subStageContent.image}
        title={subStageContent.title}
        content={subStageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default DatasetItemValidation;
