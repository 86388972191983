import { Enums } from '@configur-tech/upit-core-types';
import {
  faAt,
  faBellPlus,
  faCalendarDays,
  faInputNumeric,
  faInputText,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-duotone-svg-icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import boolIcon from '../../assets/icons/notifications/bool-icon.svg';
import * as SC from './styled';

export interface NotificationEventIconProps {
  dataValidationType: Enums.ValueDataType | undefined;
}

const NotificationEventIcon: FC<NotificationEventIconProps> = ({
  dataValidationType,
}) => {
  const icon =
    dataValidationType === Enums.ValueDataType.DATE ||
    dataValidationType === Enums.ValueDataType.DATE_CONVERSION
      ? faCalendarDays
      : dataValidationType === Enums.ValueDataType.EMAIL
      ? faAt
      : dataValidationType === Enums.ValueDataType.NUMBER
      ? faInputNumeric
      : dataValidationType === Enums.ValueDataType.PHONE_NUM
      ? faPhone
      : dataValidationType === Enums.ValueDataType.TEXT ||
        dataValidationType === Enums.ValueDataType.TEXT_TRANSFORMATION
      ? faInputText
      : dataValidationType === Enums.ValueDataType.POSTCODE
      ? faLocationDot
      : faBellPlus;

  return (
    <SC.NotificationEventLogoContainer>
      {dataValidationType !== Enums.ValueDataType.BOOL && (
        <SC.NotificationEventLogo icon={icon as IconDefinition} />
      )}
      {dataValidationType === Enums.ValueDataType.BOOL && (
        <SC.NotificationEventLogoSVG image={boolIcon} />
      )}
    </SC.NotificationEventLogoContainer>
  );
};

export default NotificationEventIcon;
