import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InitialIntegrationStage, {
  IntegrationCreationSubStage,
  IntegrationStage,
  IntegrationSubStages,
} from './initial-state';

const IntegrationStageSlice = createSlice({
  name: 'integrationStage',
  initialState: InitialIntegrationStage,
  reducers: {
    updateActiveIntegrationStage(
      state,
      action: PayloadAction<IntegrationStage>,
    ) {
      state.activeStage = action.payload;
    },
    updateActiveIntegrationSubStage(
      state,
      action: PayloadAction<IntegrationSubStages>,
    ) {
      state.stages[state.activeStage].activeSubStage = action.payload;
    },
    resetStagesAndSubStages(state) {
      state.activeStage = IntegrationStage.CREATION;
      state.stages[IntegrationStage.CREATION].activeSubStage =
        IntegrationCreationSubStage.META;
    },
  },
});

export const {
  updateActiveIntegrationStage,
  updateActiveIntegrationSubStage,
  resetStagesAndSubStages,
} = IntegrationStageSlice.actions;

export default IntegrationStageSlice.reducer;
