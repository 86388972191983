import { Enums } from '@configur-tech/upit-core-types';
import { ChartItem } from '../charts';

const InitialChart: ChartItem = {
  accessLevel: Enums.AccessLevel.MANAGE,
  entity: {
    name: '',
    description: '',
    areas: [],
    graphs: [],
    access: {
      manage: {
        organisation: [],
        team: [],
        user: [],
      },
      edit: { organisation: [], team: [], user: [] },
      view: { organisation: [], team: [], user: [] },
    },
    organisationId: '',
  },
};

export default InitialChart;
