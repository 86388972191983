import { useAuth0 } from '@auth0/auth0-react';
import { Enums } from '@configur-tech/upit-core-types';
import { useCallback } from 'react';
import DatasetExporterService, {
  DatasetExportQuery,
  ExporterResponse,
} from '../../services/dataset-exporter/DatasetExporterService';
import { DynamicConditionalField } from '../filter/UseFilter';

interface useDatasetResult {
  downloadDatasets: (
    datasetMetaIds: DatasetExportQuery[],
    format: Enums.AcceptedFileType,
    applyFormatting?: boolean,
    portalId?: string,
    connectionGroupId?: string,
  ) => Promise<ExporterResponse | undefined>;
  downloadQuery: (
    query: {
      _id: string;
      conditions?: DynamicConditionalField[];
    },
    format: Enums.AcceptedFileType,
    applyFormatting,
    portalId?: string,
    connectionGroupId?: string,
  ) => Promise<ExporterResponse | undefined>;
  downloadSnapshot: (
    snapshotId: string,
    format: Enums.AcceptedFileType,
    applyFormatting?: boolean,
  ) => Promise<ExporterResponse | undefined>;
}

const useDatasetExporter = (): useDatasetResult => {
  const { getAccessTokenSilently } = useAuth0();

  const downloadDatasets = useCallback(
    async (
      datasetMetaIds: DatasetExportQuery[],
      format: Enums.AcceptedFileType,
      applyFormatting = true,
      portalId?: string,
      connectionGroupId?: string,
    ): Promise<ExporterResponse | undefined> => {
      const token = await getAccessTokenSilently();

      if (token && datasetMetaIds?.length && format) {
        const response = await DatasetExporterService.exportDatasets(
          token,
          datasetMetaIds,
          format,
          applyFormatting,
          portalId,
          connectionGroupId,
        );

        if (response) {
          return response.data as ExporterResponse;
        }
      }
    },
    [getAccessTokenSilently],
  );

  const downloadQuery = useCallback(
    async (
      query: {
        _id: string;
        conditions?: DynamicConditionalField[];
      },
      format: Enums.AcceptedFileType,
      applyFormatting = true,
      portalId?: string,
      connectionGroupId?: string,
    ): Promise<ExporterResponse | undefined> => {
      const token = await getAccessTokenSilently();

      if (token && query._id && format) {
        const response = await DatasetExporterService.exportQuery(
          token,
          query,
          format,
          applyFormatting,
          portalId,
          connectionGroupId,
        );

        if (response) {
          return response.data as ExporterResponse;
        }
      }
    },
    [getAccessTokenSilently],
  );

  const downloadSnapshot = useCallback(
    async (
      snapshotId: string,
      format: Enums.AcceptedFileType,
      applyFormatting = true,
    ): Promise<ExporterResponse | undefined> => {
      const token = await getAccessTokenSilently();

      if (token && snapshotId && format) {
        const response = await DatasetExporterService.exportSnapshot(
          token,
          format,
          applyFormatting,
          snapshotId,
        );

        if (response) {
          return response.data as ExporterResponse;
        }
      }
    },
    [getAccessTokenSilently],
  );

  return {
    downloadDatasets,
    downloadQuery,
    downloadSnapshot,
  };
};

export default useDatasetExporter;
