import { Enums } from '@configur-tech/upit-core-types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import ProjectItemNameStage from '../../components/ProjectItem/ProjectItemCreationStages/1-name/ProjectItemNameStage';
import ProjectItemAvatarStage from '../../components/ProjectItem/ProjectItemCreationStages/2-avatar/ProjectItemAvatarStage';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { ProjectCreationSubStage } from '../../store/project-stage/initial-state';
import { RootState } from '../../store/rootReducer';
import Page from '../../main/Page';
import {
  getProjectStageTitle,
  getProjectSubStageContent,
} from '../../util/project-stage-content/ProjectStageContent';

const ProjectItemCreation: FC = () => {
  const projectStageState = useSelector(
    (state: RootState) => state.projectStage,
  );

  const activeStage = Enums.ProjectStage.CREATION;
  const activeSubStage = projectStageState.stages[activeStage].activeSubStage;

  const stageTitle = getProjectStageTitle(activeStage);
  const stageContent = getProjectSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case ProjectCreationSubStage.NAME:
        return <ProjectItemNameStage />;
      case ProjectCreationSubStage.AVATAR:
        return <ProjectItemAvatarStage />;
      default:
        return <ProjectItemNameStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: projectStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={stageContent.image}
        title={stageContent.title}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default ProjectItemCreation;
