import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep, startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import useUser, {
  AUTH0_CONNECTION_IDENTIFIER,
} from '../../../../hooks/user/UseUser';
import { fetchUserSuccess } from '../../../../store/user';
import { updateActiveUserSubStage } from '../../../../store/user-stage';
import { UserCreationSubStage } from '../../../../store/user-stage/initial-state';

import {
  StageBodyText,
  StageInner,
  StageWrapper,
  StyledBodySubHeader,
  StyledDropdown,
  StyledInput,
} from '../../../../main/theme';
import validateValue from '../../../../util/data-validator/DataValidator';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import ActionBar from '../../../ActionBar/ActionBar';

const USER_TITLE = `the user's`;
const SELF_TITLE = 'your';

const USER_TYPE_FIELD = 'userType';
const FIRST_NAME_FIELD = 'firstName';
const LAST_NAME_FIELD = 'lastName';
const EMAIL_FIELD = 'email';
const JOB_ROLE_FIELD = 'jobRole';
const NEXT_STAGE = UserCreationSubStage.AVATAR;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const NameWrapper = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: ${({ theme }) => `0 ${theme.margin.standard}`};
  }
`;

const UserTypeDropdownOptions = Object.values(Enums.UserType).map((type, i) => {
  return {
    key: `user-type-${type}-${i}`,
    value: type,
    text: startCase(type),
  };
});

const UserItemNameStage: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { user, userAccessLevel } = useUser();
  const { loggedInUser } = useLoggedInUser();

  const [title, setTitle] = useState<string>(USER_TITLE);
  const [socialConnection, setSocialConnection] = useState<boolean>(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false);

  // Is editing own profile
  useEffect(() => {
    if (user?._id && user._id === loggedInUser?._id) {
      setTitle(SELF_TITLE);
    }
  }, [loggedInUser?._id, user?._id]);

  // Is a social Auth0 connection
  useEffect(() => {
    if (user?._id && !user.auth0Id.includes(AUTH0_CONNECTION_IDENTIFIER)) {
      setSocialConnection(true);
    } else {
      setSocialConnection(false);
    }
  }, [user, user?.auth0Id]);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(user);
    cloned[field] = val;
    dispatch(
      fetchUserSuccess({
        accessLevel: userAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  useEffect(() => {
    setEmailIsInvalid(!validateValue(Enums.ValueDataType.EMAIL, user?.email));
  }, [user?.email]);

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          {(!user?._id || user._id !== loggedInUser?._id) && (
            <>
              <StyledBodySubHeader>User Type</StyledBodySubHeader>
              <StageBodyText>
                A Portal user can only access portals, whilst a Platform user
                has complete access to the Configur platform.
              </StageBodyText>

              <StyledDropdown
                selectOnBlur={false}
                placeholder={'Select a user type'}
                options={UserTypeDropdownOptions}
                selection
                value={user?.userType}
                onChange={(e, data) =>
                  handleChange(USER_TYPE_FIELD, data.value)
                }
              />
            </>
          )}

          <StyledBodySubHeader>Name</StyledBodySubHeader>
          <StageBodyText>What's {title} name?</StageBodyText>

          <NameWrapper>
            <div>
              <StyledBodySubHeader>First Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter ${title} first name`}
                value={user?.firstName || ''}
                onChange={(event, data) =>
                  handleChange(FIRST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>

            <div>
              <StyledBodySubHeader>Last Name</StyledBodySubHeader>
              <StyledInput
                placeholder={`Enter ${title} last name`}
                value={user?.lastName || ''}
                onChange={(event, data) =>
                  handleChange(LAST_NAME_FIELD, data.value)
                }
                style={{ marginBottom: themeContext.margin.xxlarge }}
              />
            </div>
          </NameWrapper>

          <StyledBodySubHeader>Email Address</StyledBodySubHeader>
          <StageBodyText>What's {title} email address?</StageBodyText>

          {socialConnection && (
            <StageBodyText>
              This account was created using a social connection so the email
              address cannot be modified.
            </StageBodyText>
          )}

          <StyledInput
            disabled={socialConnection}
            error={emailIsInvalid}
            placeholder={`Enter ${title} email address`}
            value={user?.email || ''}
            onChange={(event, data) =>
              handleChange(EMAIL_FIELD, data.value.toLowerCase())
            }
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />

          <StyledBodySubHeader>Job Role</StyledBodySubHeader>
          <StageBodyText>What's {title} job role?</StageBodyText>
          <StyledInput
            placeholder={`Enter ${title} job role`}
            value={user?.jobRole || ''}
            onChange={(event, data) => handleChange(JOB_ROLE_FIELD, data.value)}
            style={{ marginBottom: themeContext.margin.xxlarge }}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`Ready to go?`}
        primaryButton={
          <FeatureButton
            isDisabled={
              !user?.userType ||
              !user?.firstName.length ||
              !user?.lastName.length ||
              !user?.email.length ||
              emailIsInvalid
            }
            action={() => dispatch(updateActiveUserSubStage(NEXT_STAGE))}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={'Continue to Avatar'}
          />
        }
      />
    </StageWrapper>
  );
};

export default UserItemNameStage;
