import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageFeatureHeader from '../../components/PageFeatureHeader/PageFeatureHeader';
import { SubStageItem } from '../../components/SubStageMarker/SubStageMarker';
import TeamItemNameStage from '../../components/TeamItemStages/TeamItemCreationStages/1-name/TeamItemNameStage';
import TeamItemAvatarStage from '../../components/TeamItemStages/TeamItemCreationStages/2-avatar/TeamItemAvatarStage';
import TeamItemUsersStage from '../../components/TeamItemStages/TeamItemCreationStages/3-members/TeamItemMembersStage';
import Page from '../../main/Page';
import { RootState } from '../../store/rootReducer';
import {
  TeamCreationSubStage,
  TeamStage,
} from '../../store/team-stage/initial-state';
import {
  getTeamStageTitle,
  getTeamSubStageContent,
} from '../../util/team-stage-content/TeamStageContent';

const TeamCreation: FC = () => {
  const teamStageState = useSelector((state: RootState) => state.teamStage);

  const activeStage = TeamStage.CREATION;
  const activeSubStage = teamStageState.stages[activeStage].activeSubStage;

  const stageTitle = getTeamStageTitle(activeStage);
  const stageContent = getTeamSubStageContent(activeStage, activeSubStage);

  const getSubStageComponent = () => {
    switch (activeSubStage) {
      case TeamCreationSubStage.NAME:
        return <TeamItemNameStage />;
      case TeamCreationSubStage.AVATAR:
        return <TeamItemAvatarStage />;
      case TeamCreationSubStage.USERS:
        return <TeamItemUsersStage />;
      default:
        return <TeamItemNameStage />;
    }
  };

  return (
    <>
      <PageFeatureHeader
        pageHeader={stageTitle}
        subStages={{
          activeSubStage: activeSubStage,
          subStageItems: teamStageState.stages[activeStage]
            .subStages as SubStageItem[],
        }}
        image={stageContent.image}
        title={stageContent.title}
        content={stageContent.headerContent}
      />

      <Page>{getSubStageComponent()}</Page>
    </>
  );
};

export default TeamCreation;
