import { Enums } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { EntityType } from '../../../../enums/EntityType';
import { RouteName } from '../../../../enums/RouteName';
import useLoggedInUser from '../../../../hooks/logged-in-user/UseLoggedInUser';
import useUser from '../../../../hooks/user/UseUser';
import { hideLoading, showLoading } from '../../../../store/loading';
import { fetchLoggedInUserSuccess } from '../../../../store/logged-in-user';
import { fetchUserSuccess } from '../../../../store/user';
import { updateActiveUserSubStage } from '../../../../store/user-stage';
import { UserCreationSubStage } from '../../../../store/user-stage/initial-state';
import {
  StageBodyText,
  StageInner,
  StageWrapper,
} from '../../../../main/theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../../FeatureButton/FeatureButton';
import ActionBar from '../../../ActionBar/ActionBar';
import IconSelector from '../../../IconSelector/IconSelector';

const AVATAR_FIELD = 'avatar';
const PREV_STAGE = UserCreationSubStage.NAME;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const UserItemAvatarStage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { user, addUser, editUser, userAccessLevel } = useUser();
  const { loggedInUser, loggedInUserAccessLevel } = useLoggedInUser();

  const [processComplete, setProcessComplete] = useState<boolean>(false);

  const handleChange = (field: string, val: string | string[]) => {
    const cloned = cloneDeep(user);
    cloned[field] = val;
    dispatch(
      fetchUserSuccess({
        accessLevel: userAccessLevel || Enums.AccessLevel.MANAGE,
        entity: cloned,
      }),
    );
  };

  useEffect(() => {
    if (processComplete && user?._id) {
      // ProcessAction has completed successfully
      history.push(`${RouteName.USERS}`);
    }
  }, [processComplete, user?._id, dispatch, history]);

  const processAction = async () => {
    if (loggedInUser) {
      dispatch(
        showLoading({
          text: `${user?._id ? 'Updating' : 'Creating'} User...`,
        }),
      );

      // Create user
      const cloned = cloneDeep(user);

      // Add organisation
      cloned.organisation = loggedInUser.organisationId;

      // Save user
      user?._id ? await editUser(cloned) : await addUser(cloned);

      // Update logged in user if same _id
      if (cloned._id === loggedInUser?._id) {
        dispatch(
          fetchLoggedInUserSuccess({
            accessLevel: loggedInUserAccessLevel || Enums.AccessLevel.EDIT,
            entity: cloned,
          }),
        );
      }

      dispatch(hideLoading());
    }

    setProcessComplete(true);
  };

  return (
    <StageWrapper>
      <StageInner>
        <Section>
          <StageBodyText>Pick the perfect avatar for this user.</StageBodyText>

          <StageBodyText>
            This will make it easier to identify them across the platform
          </StageBodyText>

          <IconSelector
            type={EntityType.USER}
            onChange={(icon) => handleChange(AVATAR_FIELD, icon)}
            selectedIcon={user?.avatar}
          />
        </Section>
      </StageInner>

      <ActionBar
        text={`All done!`}
        primaryButton={
          <FeatureButton
            isDisabled={!user?.avatar?.length}
            action={processAction}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.green}
            text={user?._id ? 'Update User' : 'Create User'}
          />
        }
        backButton={
          <FeatureButton
            action={() => {
              dispatch(updateActiveUserSubStage(PREV_STAGE));
            }}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.sea}
            text={'Back to Name'}
          />
        }
      />
    </StageWrapper>
  );
};

export default UserItemAvatarStage;
