import {
  faListUl,
  faPlusSquare,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteName } from '../../enums/RouteName';
import useLoggedInUser from '../../hooks/logged-in-user/UseLoggedInUser';
import usePermission from '../../hooks/permission/UsePermission';
import { fetchUserSuccess } from '../../store/user';
import { resetStagesAndSubStages } from '../../store/user-stage';
import InitialUser from '../../store/user/initial-user';
import { defaultTheme } from '../../main/theme';
import NavItem from '../Navigation/NavItem';
import NavTitle from '../Navigation/NavTitle';

const NavIcons = {
  profile: faUser,
  [RouteName.USERS]: faListUl,
  [RouteName.USER_ITEM]: faPlusSquare,
};

const UsersNavigation: FC = () => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedInUser } = useLoggedInUser();
  const { isOrgManager } = usePermission();

  return (
    <>
      {isOrgManager && (
        <>
          <NavItem
            key={`users-view`}
            onClick={() => history.push(RouteName.USERS)}
            isActive={location.pathname === RouteName.USERS}
          >
            <FontAwesomeIcon
              icon={NavIcons[RouteName.USERS]}
              color={defaultTheme.colors.system.white}
            />
            <NavTitle text={'View Users'} />
          </NavItem>
          {/*Deprecated*/}
          {/*<NavItem*/}
          {/*  key={`users-create`}*/}
          {/*  onClick={() => {*/}
          {/*    // Reset user data*/}
          {/*    dispatch(resetStagesAndSubStages());*/}
          {/*    dispatch(fetchUserSuccess(InitialUser));*/}
          {/*    history.push(RouteName.USER_ITEM);*/}
          {/*  }}*/}
          {/*  isActive={location.pathname === RouteName.USER_ITEM}*/}
          {/*>*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={NavIcons[RouteName.USER_ITEM]}*/}
          {/*    color={defaultTheme.colors.system.white}*/}
          {/*  />*/}
          {/*  <NavTitle text={'Create New User'} />*/}
          {/*</NavItem>*/}
        </>
      )}

      {/*Deprecated*/}
      {/*<NavItem*/}
      {/*  key={`users-my-user`}*/}
      {/*  onClick={() =>*/}
      {/*    history.push(`${RouteName.USER_ITEM}/${loggedInUser?._id}`)*/}
      {/*  }*/}
      {/*  isActive={*/}
      {/*    location.pathname.includes(RouteName.USER_ITEM) &&*/}
      {/*    params?.id === loggedInUser?._id*/}
      {/*  }*/}
      {/*>*/}
      {/*  <FontAwesomeIcon*/}
      {/*    icon={NavIcons.profile}*/}
      {/*    color={defaultTheme.colors.system.white}*/}
      {/*  />*/}
      {/*  <NavTitle text={'My Profile'} />*/}
      {/*</NavItem>*/}
    </>
  );
};

export default UsersNavigation;
