import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import CommentsService from '../../services/comments/CommentsService';
import { AppThunk } from '../store';
import InitialComments from './initial-state';

export interface CommentsState {
  data: CommentsItemOutput;
  loading: boolean;
  error: string | null;
}

export interface CommentItem {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.Comment;
}

export interface CommentItemOutput {
  accessLevel: Enums.AccessLevel;
  entity: Interfaces.CommentOutput & {
    author: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface CommentsItemOutput {
  data: CommentItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

const initialState: CommentsState = {
  data: InitialComments,
  loading: false,
  error: null,
};

const CommentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    // Reset
    resetComments(state) {
      state.data = InitialComments;
      state.loading = false;
      state.error = null;
    },

    fetchCommentsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCommentsSuccess(state, action: PayloadAction<CommentsItemOutput>) {
      const clonedPayload = cloneDeep(action.payload);
      clonedPayload.data = [...state.data.data, ...action.payload.data];

      state.data = clonedPayload;
      state.loading = false;
      state.error = null;
    },
    fetchCommentsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  resetComments,
} = CommentsSlice.actions;

export default CommentsSlice.reducer;

export const fetchComments =
  (
    token: string,
    resourceType: Enums.SchemaName,
    resourceId: string,
    pageNum: number,
    childId?: string,
    portalId?: string,
    cmsId?: string,
    groupId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchCommentsStart());
      const fetched = await CommentsService.getComments(
        token,
        resourceType,
        resourceId,
        pageNum,
        childId,
        portalId,
        cmsId,
        groupId,
      );
      dispatch(
        fetchCommentsSuccess(fetched.data as unknown as CommentsItemOutput),
      );
    } catch (err) {
      if (
        (err as AxiosError)?.response?.data.statusCode ===
        Enums.StatusCode.NOT_FOUND
      ) {
        return dispatch(
          fetchCommentsSuccess({
            data: [],
            pagination: {
              currentPageNum: 0,
              prevPageNum: null,
              nextPageNum: null,
              totalCount: 0,
              totalPages: 0,
            },
          }),
        );
      }
      dispatch(fetchCommentsFailure((err as string).toString()));
    }
  };
