import { useAuth0 } from '@auth0/auth0-react';
import { Mappers } from '@configur-tech/upit-core-types';
import { Field } from '@configur-tech/upit-core-types/lib/interfaces';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import SampleService from '../../services/sample/SampleService';
import useDatasetCollection from '../dataset-collection/UseDatasetCollection';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useSampleResponse {
  createSampleData: (
    datasetMetaId: string,
    schema: Field[],
    sampleSize?: number,
    evaluateSuperColumns?: boolean,
  ) => Promise<Record<string, unknown>[] | undefined>;
}

const useSample = (): useSampleResponse => {
  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { collection } = useDatasetCollection();

  const createSampleData = useCallback(
    async (
      datasetMetaId: string,
      schema: Field[],
      sampleSize?: number,
      evaluateSuperColumns?: boolean,
    ): Promise<Record<string, unknown>[] | undefined> => {
      if (loggedInUser && collection?.collectionId) {
        try {
          const token = await getAccessTokenSilently();

          const { sample } = await SampleService.fetchSample(
            token,
            datasetMetaId,
            loggedInUser?.organisationId,
            loggedInUser._id,
            schema?.map(Mappers.SchemaFieldMapper.dbSchemaToDomainSchema) || [],
            collection.collectionId as string,
            sampleSize,
            evaluateSuperColumns,
          );
          return sample;
        } catch (err) {
          if (
            (err as AxiosError)?.response?.data?.error?.includes(
              'Unknown column',
            )
          ) {
            toast.error(
              `${
                (err as AxiosError)?.response?.data?.statusCode || 'Error'
              } - One or more columns has a broken link.`,
            );
            throw err;
          }

          toast.error(
            `${
              (err as AxiosError)?.response?.data?.statusCode || 'Error'
            } - Failed to create sample data.`,
          );
          throw err;
        }
      }
    },
    [collection?.collectionId, getAccessTokenSilently, loggedInUser],
  );

  return { createSampleData };
};

export default useSample;
