import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QueryService from '../../services/query/QueryService';
import { hideLoading, showLoading } from '../../store/loading';
import {
  fetchQueries,
  fetchQueriesSuccess,
  QueriesItemOutput,
  QueryItemOutput,
  QueryOutputWithDetails,
} from '../../store/queries';
import {
  createQuery,
  deleteQuery,
  fetchQuery,
  updateQuery,
} from '../../store/query';
import { RootState } from '../../store/rootReducer';
import remapLegacyFilterToDynamicFilter from '../../util/migration/dynamic-filter/RemapLegacyFilterToDynamicFilter';
import useFilter from '../filter/UseFilter';
import useLoggedInUser from '../logged-in-user/UseLoggedInUser';

interface useQueryResult {
  query?: QueryOutputWithDetails;
  queryAccessLevel?: Enums.AccessLevel;

  getQueries: (query?: Record<string, unknown>) => Promise<void>;
  getQueriesDirect: (
    query?: Record<string, unknown>,
  ) => Promise<QueryItemOutput[]>;
  getQuery: (queryId: string, portalId?: string) => void;
  addQuery: (queryObj: Interfaces.Query, portalId?: string) => void;
  editQuery: (queryObj: Interfaces.QueryOutput, portalId?: string) => void;
  removeQuery: (queryId: string, portalId?: string) => void;

  // Utils
  listQueryDatasetMetas: (
    query: Interfaces.Query | Interfaces.QueryOutput,
  ) => string[];
  removeMissingFields: (
    query: Interfaces.QueryOutput,
    missingFields: Interfaces.QueryField[],
    portalId?: string,
  ) => void;
}

const useQuery = (): useQueryResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const { loggedInUser } = useLoggedInUser();
  const { deleteDatasetFilters } = useFilter();

  const [query, setQuery] = useState<QueryOutputWithDetails>();
  const [queryAccessLevel, setQueryAccessLevel] = useState<Enums.AccessLevel>();

  const queriesState = useSelector((state: RootState) => state.queries);
  const queryState = useSelector((state: RootState) => state.query);
  const queryObj = queryState.data as QueryItemOutput;

  const getQueries = useCallback(
    async (query?: Record<string, unknown>) => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        await dispatch(fetchQueries(token, query));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const getQueriesDirect = useCallback(
    async (query?: Record<string, unknown>): Promise<QueryItemOutput[]> => {
      const token = await getAccessTokenSilently();

      if (token && loggedInUser) {
        try {
          const fetched = await QueryService.getQueries(token, query);

          // Convert to new dynamic value format if required
          return (fetched.data as unknown as QueriesItemOutput).data?.map(
            (q) => {
              q.entity.queryParams.filters = q.entity.queryParams.filters.map(
                (f) =>
                  remapLegacyFilterToDynamicFilter(
                    f as Interfaces.DynamicConditional,
                    q.entity.queryParams.fields,
                    q.entity.queryParams.datasetMetaId,
                  ),
              );
              return q;
            },
          );
        } catch (err) {
          return [];
        }
      }

      return [];
    },
    [getAccessTokenSilently, loggedInUser],
  );

  const getQuery = useCallback(
    async (queryId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && queryId && loggedInUser) {
        await dispatch(fetchQuery(token, queryId, portalId));
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const addQuery = useCallback(
    async (queryObj: Interfaces.Query, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && queryObj && loggedInUser) {
        await dispatch(
          createQuery(token, queryObj, loggedInUser._id, portalId),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const editQuery = useCallback(
    async (queryObj: Interfaces.QueryOutput, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && queryObj && loggedInUser) {
        await dispatch(
          updateQuery(token, queryObj, loggedInUser._id, portalId),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser],
  );

  const removeQuery = useCallback(
    async (queryId: string, portalId?: string) => {
      const token = await getAccessTokenSilently();

      if (token && queryId && loggedInUser) {
        await dispatch(deleteQuery(token, queryId, loggedInUser._id, portalId));

        // Remove project from current queries
        await dispatch(
          fetchQueriesSuccess({
            pagination: {
              ...queriesState.data.pagination,
              totalCount: queriesState.data.pagination.totalCount - 1,
            },
            data: queriesState.data?.data.filter(
              (d) => d.entity._id !== queryId,
            ),
          }),
        );
      }
    },
    [dispatch, getAccessTokenSilently, loggedInUser, queriesState.data],
  );

  // Query Utils

  const listQueryDatasetMetas = useCallback(
    (query: Interfaces.Query | Interfaces.QueryOutput): string[] => {
      if (!query.queryParams.fields) {
        return [];
      }

      return query.queryParams.fields?.reduce((acc: string[], field) => {
        if (!acc.includes(field.datasetMetaId)) {
          return acc.concat([field.datasetMetaId]);
        }
        return acc;
      }, []);
    },
    [],
  );

  const removeMissingFields = useCallback(
    async (
      query: Interfaces.QueryOutput,
      missingFields: Interfaces.QueryField[],
      portalId?: string,
    ) => {
      const cloned: Interfaces.QueryOutput = cloneDeep(query);

      missingFields.map((missingField) => {
        cloned.queryParams = {
          ...cloned.queryParams,
          fields: cloned.queryParams.fields
            ? cloned.queryParams.fields?.filter(
                (field) => field.field !== missingField.field,
              )
            : [],
          // Difficult to traverse multi-filters so just reset
          filters: [],
          groups: cloned.queryParams.groups
            ? cloned.queryParams.groups?.filter(
                (group) => group.field !== missingField.field,
              )
            : [],
          measures: cloned.queryParams.measures
            ? cloned.queryParams.measures?.filter(
                (measure) => measure.field !== missingField.field,
              )
            : [],
          sort: cloned.queryParams.sort
            ? cloned.queryParams.sort?.filter(
                (sortField) => sortField.field !== missingField.field,
              )
            : [],
        };
      });

      // Clear local filter storage
      deleteDatasetFilters(query._id);

      dispatch(showLoading({ text: 'Removing missing columns...' }));
      await editQuery(cloned, portalId);
      dispatch(hideLoading());
    },
    [deleteDatasetFilters, dispatch, editQuery],
  );

  useEffect(() => {
    if (queryObj) {
      // Complete model
      setQuery(queryObj.entity);
      setQueryAccessLevel(queryObj.accessLevel);
    }
  }, [queryObj]);

  return {
    query,
    queryAccessLevel,

    getQueries,
    getQueriesDirect,
    getQuery,
    addQuery,
    editQuery,
    removeQuery,

    listQueryDatasetMetas,
    removeMissingFields,
  };
};

export default useQuery;
